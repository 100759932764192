
import { makeConstant } from "./_helpers";

const constant = makeConstant("chase/meeting");

export const GET_MEETINGS = constant("GET_MEETINGS");
export const SET_MEETINGS = constant("SET_MEETINGS");
export const CREATE_SCHEDULED_MEETING = constant("CREATE_SCHEDULED_MEETING");
export const UPDATE_SCHEDULED_MEETING = constant("UPDATE_SCHEDULED_MEETING");
export const OPEN_CUSTOM_DIALOG = constant("OPEN_CUSTOM_DIALOG");
export const GET_MEETING_DETAILS = constant(" GET_MEETING_DETAILS");
export const UPDATE_MEETING_STATUS = constant("UPDATE_MEETING_STATUS");
export const OPEN_SHARE_MEETING_DIALOG = constant("OPEN_SHARE_MEETING_DIALOG");
export const DELETE_MEETING = constant("DELETE_MEETING");
export const OPEN_DRAWER_COMPONENT = constant("OPEN_DRAWER_COMPONENT");

export const getMeetingsList = (payload) => ({
    type: GET_MEETINGS,
    payload
});

export const setMeetingsList = (payload) => ({
    type: SET_MEETINGS,
    payload
});

export const getMeetingDetails = payload => ({
    type: GET_MEETING_DETAILS, payload
});

export const updateMeetingStatus = (payload) => ({
    type: UPDATE_MEETING_STATUS,
    payload,
});

export const createScheduledMeeting = (payload) => ({
    type: CREATE_SCHEDULED_MEETING,
    payload
});

export const updateScheduledMeeting = (payload) => ({
    type: UPDATE_SCHEDULED_MEETING,
    payload
});

export const openCustomDialog = (payload) => ({
    type: OPEN_CUSTOM_DIALOG,
    payload
});

export const openDrawerComponent = (payload) => ({
    type: OPEN_DRAWER_COMPONENT,
    payload
});

export const openShareMeetingDialog = (payload) => ({
    type: OPEN_SHARE_MEETING_DIALOG,
    payload
});

export const deleteMeeting = (payload) => ({
    type: DELETE_MEETING,
    payload
});

const initialState = {
    customDailog: {
        open: false,
        title: '',
        children: null
    },
    meetingsList: [],
    shareMeetingToChat: {
        open: false,
        details: {}
    },
    drawerComponent: {
        open: false,
        title: '',
        children: null
    }
};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MEETINGS:
            return {
                ...state,
                meetingsList: [
                    ...action.payload
                ]
            }
        case OPEN_CUSTOM_DIALOG:
            return {
                ...state,
                customDailog: {
                    ...action.payload
                }
            }
        case OPEN_SHARE_MEETING_DIALOG:
            return {
                ...state,
                shareMeetingToChat: {
                    ...action.payload
                }
            }
        case OPEN_DRAWER_COMPONENT:
            return {
                ...state,
                drawerComponent: {
                    ...state.drawerComponent,
                    ...action.payload
                }
            }
        default:
            return state;
    }
};