import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { Button, Checkbox, fade, IconButton, InputBase } from '@material-ui/core';
import { ContactlessOutlined, Info } from "@material-ui/icons";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat, getSearchValue, seasonKpiPositionsSort } from "../../helpers";
import loading, { setLoading } from "../../ducks/loading";
import { getTeamRaceLiveScore, getPlayerRaceLiveScore } from "../../ducks/game";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import IllustrationView from "./IllustrationView";
import { groupBy, sortBy } from "underscore";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import { ChevronLeft } from '@material-ui/icons';
import { GetDates } from ".";
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import TeamSummaryDivisionWise from "./TeamSummaryDivisionWise";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";
import qs from 'query-string';
import NotificationDialog from "../GlobalComponents/NotificationDialog";
import SearchIcon from '@material-ui/icons/Search';
import PlayerSummaryDivisionWise from "./PlayerSummaryDivisionWise";
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '8px 0',
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600
    },
    title: {
        // margin: '0 0 10px',
        fontSize: 11,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    searchIcon: {
        color: '#0000004d',
        padding: '0px 8px 0px 25px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.35),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.5),
        },
        width: 'auto',
        maxWidth: 180,
        minWidth: 180,
    },
    inputRoot: {
        color: 'initial',
        height: '100%',
        width: 'inherit'
    },
    inputInput: {
        border: '1px solid #0000003a',
        borderRadius: '3px',
        height: 12,
        margin: '0 10px',
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        fontSize: '12px'
    },
    notification: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        height: '30px',
        fontSize: '12px',
    },
}));

export const isRuleBasedOnActuals = (kpi) => {
    return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
}

const RaceSummary = ({ setLoading, loading, seasonId = '', seasonFormat = '', isPlayer = false, matchId = '', filtersData, userDetails, getTeamRaceLiveScore, getPlayerRaceLiveScore, history, isTeamRacePlayerSprint, TeamPerformance, keycloak, Filter, roles, teams, seasonKpiPostions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataByDiv, setDataByDiv] = useState(null);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);
    const { path } = useRouteMatch();
    const [searchPlayer, setSearchPlayer] = useState([]);
    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');
    const [checkColumn, setCheckColumn] = React.useState([{ isChecked: false }]);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [selectedEmps, setSelectedEmps] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);

    useEffect(() => {
        if (tableData) {
            setSelectedEmps(tableData.filter(ele => ele.isChecked))
        }

    }, [tableData]);

    useEffect(() => {
        if (matchId) {
            setLoading(true);
            if ((seasonFormat === 'TEAM_RACE' && !isPlayer) && !filtersData.season?.divisions_enabled)
                getTeamRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, team_id: Filter.team?._id, role_id: Filter.role?.role_id } });
            else
                getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, team_id: Filter.team?._id, role_id: Filter.role?.role_id } });
        }
    }, [matchId, Filter.role, Filter.team]);



    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_score || item.total_points || 0, selected: (filtersData.match?.type === 'TEAM_SPRINT' && !isPlayer) ? item._id === resheaders?.my_team_id : item.employee_id === userDetails?.username, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'summary_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                // let totalPoints = 0;
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE' && !isPlayer) ? el.score || 0 : el.points || 0);
                    // obj['totalPoints'] = totalPoints += seasonFormat === 'TEAM_RACE' ? el.score : el.points;

                    if (index === 0) {
                        headers.push(el);
                    }
                    if (index === 0) {
                        const mapped_headers = el.detailed_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${el.kpi_name}`,//-target
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            // hidden: isRuleBasedOnActuals(el),
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('TARGET'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                        }, {
                            title: `${el.kpi_name}`, //-Actual
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: el?.based_on === 'ACTUAL' ? { color: el.status_code, whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }, {
                            title: `${el.kpi_name}`,//-Ach %
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: el?.based_on === 'ACHIEVEMENT' ? { color: el.status_code, whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: el?.based_on === 'ACTUAL' ? { color: el.status_code } : {},
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: el?.based_on === 'ACHIEVEMENT' ? { color: el.status_code } : {},
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 9 },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                if (filtersData.season?.divisions_enabled && TeamPerformance)
                    setDataByDiv(groupBy(finalResult, 'division_name'));
                else {
                    setData(finalResult);
                    setTableData(finalResult);
                    setSearchPlayer(finalResult);

                }
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setDataByDiv(null);
                setTableData([]);
                setSearchPlayer(finalResult);
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setDataByDiv(null);
        setLoading(false);
        // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
        //     variant: 'error',
        //     preventDuplicate: true
        // });
    }


    const handleGoBack = () => {
        let qryparams = qs.parse(history.location.search);
        delete qryparams['division'];
        history.push({ pathname: `/leaguess/c2/live-scores/${TeamPerformance ? 'team' : 'player'}`, search: qs.stringify({ ...qryparams }) });
    }

    const handleChange = (row, rowIndex, type) => {
        if (type === 'close') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[0].isChecked = false
            tableDataDup.forEach(player => player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            setTableData([...tableDataDup])


        }
        else if (type === 'all') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[rowIndex].isChecked = !checkColumnDup[rowIndex].isChecked
            tableDataDup.forEach(player => checkColumnDup[rowIndex].isChecked === true ? player.isChecked = true : player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            setTableData([...tableDataDup])

        }
        else {
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            tableDataDup[rowIndex].isChecked = !tableDataDup[rowIndex].isChecked
            setTableData([...tableDataDup])
        }

    };

    const handleCloseNotificationDialog = () => {
        setOpenConfirmDialog(false);
        handleChange('', '', 'close');
        setSelectedEmps([]);
    }

    const handleKeySearch = (searchedVal) => {
        const filteredRows = searchPlayer.filter((row) => {
            return row.player_name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });

        if (searchedVal.length === 0) {
            setData(searchPlayer);
            setTableData(searchPlayer);
        }
        else {
            setData(filteredRows);
            setTableData(filteredRows);
        }
    };

    const onClickBuzz = () => {
        if (selectedEmps.length === 0) {
            enqueueSnackbar(`Make sure at least one user is selected`, {
                variant: "error",
                preventDuplicate: true
            });
        }
        else {
            setOpenConfirmDialog(true);
        }


    }

    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },

        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            hidden: (seasonFormat === 'TEAM_RACE' && !isPlayer),
            render: (rowData) => rowData.player ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} filtersData={filtersData} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            emptyValue: '--',
            hidden: (seasonFormat !== 'PLAYER_RACE'),
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            hidden: (seasonFormat === 'PLAYER_RACE'),
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--',
            templateRender: (rowData) => rowData.team ? (
                <div >
                    {rowData.team && rowData.team.name}
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap' }}>
                        {rowData.captain && <span><span style={{ fontWeight: 600 }}>C</span> : {rowData.captain}</span>}
                        {rowData.mentor && <span style={{ marginLeft: 10 }}><span style={{ fontWeight: 600 }}>M</span> : {rowData.mentor}</span>}
                    </div>
                </div>
            ) : '--',
        },
        {
            title: "Score",
            field: "totalPoints",
            editable: 'never',
            cellStyle: { minWidth: 100 },
            render: (rowData) => <span style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</span>
        },
        {
            title: "Points",
            field: "totalPoints",
            editable: 'never',
            hidden: filtersData.playermatch ? filtersData.playermatch.type !== 'TEAM_MATCH' : filtersData.match ? filtersData.match.type !== 'TEAM_MATCH' : ' ',
            cellStyle: { minWidth: 100 },
            render: (rowData) => <span style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</span>
        },


    ]

    const SummaryTable = ({ data, isAdmin, handleChange, checkColumn }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            isAdmin={isAdmin}
            checkColumn={checkColumn}
            handleChange={handleChange}
            options={{
                search: false,
                actionsColumnIndex: -1,
                showTitle: false,
                addRowPosition: 'first',
                emptyRowsWhenPaging: false,
                toolbar: false,
                pageSizeOptions: [5, 10, 25],
                pageSize: 25,
                paging: false
            }}
            // spanning={true}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {isAdmin &&

                                <TableCell rowSpan={2} style={{ maxWidth: 70, width: 70 }}>
                                    <Checkbox
                                        style={{
                                            width: 5,
                                            height: 5,
                                        }}
                                        checked={checkColumn[0].isChecked}
                                        onChange={() => handleChange('', 0, 'all')}
                                        color="primary"
                                        size="small"

                                    /></TableCell>}
                            {
                                (seasonFormat === 'TEAM_RACE' && !isPlayer) ?
                                    <>
                                        <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[3]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[5]?.title}</TableCell>
                                    </>
                                    :
                                    seasonFormat === 'PLAYER_RACE' ?

                                        <>
                                            <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{columns[1]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{columns[2]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{columns[5]?.title}</TableCell>
                                        </>
                                        :
                                        isPlayer ?
                                            <>
                                                <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                                <TableCell rowSpan={2}>{columns[1]?.title}</TableCell>
                                                <TableCell rowSpan={2}>{columns[2]?.title}</TableCell>
                                                <TableCell rowSpan={2}>{columns[3]?.title}</TableCell>
                                                <TableCell rowSpan={2}>{columns[5]?.title}</TableCell>
                                            </>
                                            :
                                            <>
                                                <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                                <TableCell rowSpan={2}>{columns[1]?.title}</TableCell>
                                                <TableCell rowSpan={2}>{columns[2]?.title}</TableCell>
                                                <TableCell rowSpan={2}>{columns[3]?.title}</TableCell>
                                                <TableCell rowSpan={2}>{columns[5]?.title}</TableCell>
                                            </>

                            }
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                        <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeaders.map((obj) => (
                                    <>
                                        {
                                            obj?.detailed_attributes.length ?
                                                <>
                                                    {
                                                        obj?.detailed_attributes.map(ele => (
                                                            ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                        ))
                                                    }
                                                    {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                </>
                                                :
                                                <>
                                                    {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                    {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>}
                                                    {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                </>
                                        }
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead >
                )
            }}
        />
    )

    return (
        <div style={{ marginBottom: 20, width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
            </div>
            {
                [
                    (filtersData.season?.divisions_enabled && TeamPerformance === true) ?
                        <>
                            {
                                [...filtersData.season?.divisions].filter(obj => obj._id === getSearchValue(history, 'division')).map((ele, index) => (
                                    <TeamSummaryDivisionWise seasonFormat={seasonFormat} key={ele._id} index={index} divisionObj={ele} limit={5000} columns={columns} filtersData={filtersData} type="DETAILED" decimalView={decimalView} setDecimalView={setDecimalView} roles={roles} teams={teams} Filter={Filter} />
                                ))
                            }
                        </>
                        :
                        (filtersData.season?.divisions_enabled && TeamPerformance === false) ?
                            <>
                                {
                                    [...filtersData.season?.divisions].filter(obj => obj._id === getSearchValue(history, 'division')).map((ele, index) => (
                                        <PlayerSummaryDivisionWise seasonFormat={seasonFormat} key={ele._id} index={index} divisionObj={ele} limit={5000} columns={columns} filtersData={filtersData} type="DETAILED" decimalView={decimalView} setDecimalView={setDecimalView} roles={roles} teams={teams} Filter={Filter} divId={ele._id} />
                                    ))
                                }
                            </>
                            :
                            <>
                                <div className={classes.root}>
                                    <p className="table-title decimal-switch-root">
                                        <p>
                                            <span>{(filtersData.match?.type === 'TEAM_SPRINT' && !isPlayer) ? 'Team' : 'Player'} performance : <span style={{ color: '#000', fontSize: 13 }}>{isTeamRacePlayerSprint ? filtersData.playermatch?.title : filtersData.match?.title}</span><GetDates filtersData={isTeamRacePlayerSprint ? { ...filtersData, match: filtersData.playermatch } : filtersData} /></span>
                                        </p>
                                        <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                    </p>
                                    {/* <p className={classes.title}><Info />{"Points will appear only after match is closed"}</p> */}
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
                                        {isAdmin && <div>
                                            <Button variant="contained" className={classes.notification} disabled={tableData.filter(obj => obj.isChecked).length === 0} color="primary" onClick={() => setOpenConfirmDialog(true)}>
                                                Buzz
                                            </Button>
                                        </div>}
                                        <div className={classes.search}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase
                                                placeholder="Search Player.."
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                                inputProps={{ 'aria-label': 'search' }}
                                                onChange={(e) => { handleKeySearch(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    {(data.length === 0 && !filtersData.season?.divisions_enabled) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :

                                        <SummaryTable
                                            data={tableData}
                                            checkColumn={checkColumn}
                                            handleChange={handleChange}
                                            isAdmin={isAdmin}
                                        />}
                                </div>
                                {
                                    openConfirmDialog && <NotificationDialog
                                        open={openConfirmDialog}
                                        handleClose={handleCloseNotificationDialog}
                                        selectedEmps={selectedEmps}
                                        setSelectedEmps={setSelectedEmps}
                                        handleUncheck={handleChange}
                                        from="LiveScores"
                                    />

                                }
                            </>
                ]
            }
        </div>)
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    keycloak: state.keycloak,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamRaceLiveScore: options => dispatch(getTeamRaceLiveScore(options)),
        getPlayerRaceLiveScore: options => dispatch(getPlayerRaceLiveScore(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RaceSummary));