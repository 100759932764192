import React from 'react';
import { makeStyles } from '@material-ui/core';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        '& ul': {
            height: 40,
            padding: '0px 0px',
            // width: '100%',
            alignItems: 'center',
            '& li': {
                marginRight: 10,
                color: '#262626',
                fontSize: 14,
                textAlign: 'center',
                cursor: 'pointer',
                width: '100% !important'
            }
        }
    },
    normalClass: {
        padding: '4px 12px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        // textOverflow: 'ellipsis',
        width: 'auto',  //50,
        // maxWidth: '100px !important',
        color: '#262626',
        fontSize: '13.5px',
        textAlign: 'center',
    },
    ActiveClass: {
        background: theme.palette.primary.main,
        color: 'white',
        padding: '4px 12px',
        borderRadius: 4,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        // textOverflow: 'ellipsis',
        width: 'auto',
        // maxWidth: '100px !important',
        fontSize: '13.5px',
        textAlign: 'center',
    },
    carosels: {
        margin: '0px 20px',
        position: 'initial',
        '& .left': {
            position: 'absolute',
            left: -3,
            cursor: 'pointer'
        },
        '& .right': {
            position: 'absolute',
            right: -3,
            cursor: 'pointer'
        }
    }
}));

export const CustomCarousel = ({ data, selectesData, onclickDiv }) => {
    const classes = useStyles();

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className={classes.root}>
            <Carousel
                className={classes.carosels}
                swipeable={true}
                draggable={true}
                // showDots={false}
                responsive={responsive}
                customLeftArrow={<ArrowLeft className='left' />}
                customRightArrow={<ArrowRight className='right' />}
            // ssr={true} // means to render carousel on server-side.
            // infinite={true}
            // autoPlay={true}
            // autoPlaySpeed={4000}
            // keyBoardControl={true}
            // customTransition="all .5"
            // transitionDuration={500}
            // containerClass="carousel-container"
            // dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-40-px"
            >
                {
                    data.length > 0 && data.map((div) => (
                        <div key={div._id} className={(selectesData?._id === div._id) ? classes.ActiveClass : classes.normalClass} onClick={() => onclickDiv(div)} >{div.name}</div>
                    ))
                }
            </Carousel>
        </div>
    )
}