import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import { Typography, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { getContactList } from "../../selectors";
import contacts, { getContacts } from "../../ducks/contacts";
import { useSnackbar } from 'notistack';
import { ChaseonsTemplate } from './ReactionsDialog';
import { ValidateEmail } from "../../helpers";

const filter = createFilterOptions();

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        }
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => 60}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: 'calc(100% - 50px)',
        alignItems: 'center',
        // padding: '10px 25px 25px',
        padding: 25
    },
    labelText: {
        padding: '11px 20px',
        fontSize: 15,
        backgroundColor: '#E0E0E0'
    },
    customListItem: {
        paddingTop: 15,
        paddingBottom: 15
    },
    autocompleteRoot: {
        width: '100%',
        '& .MuiInputBase-root': {
            padding: '1px 9px',
            '& .MuiChip-root': {
                height: 30
            }
        }
    },
    autocompleteRootSecondary: {
        width: '100%',
        '& .MuiInputBase-root': {
            padding: '9px',
            '& .MuiChip-root': {
                height: 30
            }
        }
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    },
    listbox: {
        boxSizing: "border-box",
        height: 'auto !important',
        "& ul": {
            padding: 0,
            margin: 0
        }
    },
    popper: {
        zIndex: 1302
    }
}));

const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children
];

const ChaseOnsAutocomplete = ({ openChaseons, limitTags = 2, selectedChaseOns, handleChangeAutocomplete, getContacts, startLabelText, inputLabelText, user, keycloak: { tokenParsed: { preferred_username } }, fromMeeting = false }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [options, setOptions] = useState([]);

    useEffect(() => {
        getContacts({ fromArena: true, onGetContactsSuccessCB, onGetContactsErrorCB });
    }, []);

    const onGetContactsSuccessCB = (result) => {
        setOptions(result.filter(item => item.username !== preferred_username))
    }

    const onGetContactsErrorCB = (error) => {
        console.log(error);
        setOptions([]);
        enqueueSnackbar(`Failed to fetch Chaseons.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    return (
        <>
            {
                openChaseons &&
                <div className={classes.root} style={{ padding: inputLabelText && '25px 0', width: inputLabelText && '100%' }}>
                    {startLabelText && <div className={classes.labelText}>{startLabelText}</div>}
                    <Autocomplete
                        multiple
                        noOptionsText={fromMeeting ? "Enter valid email" : 'No options'}
                        limitTags={limitTags}
                        id="virtualize-demo"
                        disableListWrap
                        className={(startLabelText && !inputLabelText) ? classes.autocompleteRoot : classes.autocompleteRootSecondary}
                        classes={{ listbox: classes.listbox, popper: classes.popper }}
                        ListboxComponent={ListboxComponent}
                        disableCloseOnSelect
                        renderGroup={renderGroup}
                        options={options}
                        value={selectedChaseOns}
                        onChange={handleChangeAutocomplete}
                        filterOptions={(options, params) => {

                            const filtered = filter(options, params);

                            if (params.inputValue !== "" & fromMeeting) {
                                if (ValidateEmail(params.inputValue))
                                    filtered.push({
                                        email: params.inputValue,
                                        // username: params.inputValue,
                                        name: params.inputValue
                                    });
                            }

                            return filtered;
                        }}
                        // groupBy={(option) => option[0].toUpperCase()}
                        getOptionLabel={option => (option.name || option.email || 'null')}
                        renderInput={(params) => (
                            <TextField {...params} label={inputLabelText} variant="outlined" />
                        )}
                        renderOption={(option) =>
                            <ChaseonsTemplate
                                details={option}
                                uniqueKey={option.username}
                                onChaseonsClick={() => { }}
                                className={classes.customListItem}
                                noActionButtons={true}
                            />
                        }
                    />
                </div>
            }
        </>
    );
}

const mapStateToProps = (state, props) => ({
    contacts: getContactList(state.contacts, state.client.jid),
    user: state.user,
    keycloak: state.keycloak,
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        getContacts: (options) => dispatch(getContacts(options))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChaseOnsAutocomplete);