import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, IconButton, MenuItem, Menu, TableRow, TableCell, Tooltip, TableHead } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { setLoading } from "../../../ducks/loading";
import { CustomCarousel } from "./CustomCarousel";
import { getDivisionWiseKpi, getPlayerCoreKpisStats, shareLiveScoreDialog } from "../../../ducks/game";
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import SyncIcon from '@material-ui/icons/Sync';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import { downloadFiles, getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort, setSearchQuery } from "../../../helpers";
import { openCreateArenaDialog } from "../../../ducks/arena";
import GetAppIcon from '@material-ui/icons/GetApp';
import { ReactComponent as ArrowForward } from "../../../assets/icons/Arrow-right.svg";
import { ReactComponent as ReactArenaLogo } from "../../../assets/icons/arena-1.svg";
import { toJpeg } from "html-to-image";
import IllustrationView from "../../LiveScores/IllustrationView";
import GetCountryCurrencyFormat from "../../GlobalComponents/GetCountryCurrencyFormat";
import { isRuleBasedOnActuals } from "../../LiveScores/RaceSummary";
import AvatarComponent from "../../AvatarComponent/AvatarComponent";
import PerformanceTableTemplate from "../../GlobalComponents/PerformanceTableTemplate";
import PerformanceTableEmailTemplate from "../../GlobalComponents/PerformanceTableEmailTemplate";
import LiveScoresMuiTable from "../../LiveScores/LiveScoresMuiTable";
import NotificationDialog from "../../GlobalComponents/NotificationDialog";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import qs from 'query-string';
import PerformanceTablePlayersEmailTemplate from "../../GlobalComponents/PerformanceTablePlayersEmailTemplate";
import { resizeImageToSquare } from "../../ArenaComponents/cropImage";



const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginBottom: 35,

    },
    liveScoreTable: {
        '& thead': {
            '& tr': {
                backgroundColor: '#EBEBEB !important',
                '& th': {
                    backgroundColor: '#EBEBEB !important',

                }
            }
        },
        '& .table-footer': {
            background: 'white',
            fontSize: 12,
            padding: '10px 0px !important'
        },
        '& table': {
            '& thead': {
                '& tr': {
                    backgroundColor: '#EBEBEB !important',
                    '& th': {
                        backgroundColor: '#EBEBEB !important',

                    }
                }
            },
            '& .table-footer': {
                background: 'white',
                fontSize: 12
            },
            '& table': {
                '& thead': {
                    '& tr:nth-child(1)': {
                        '& th:nth-last-child(1)': {
                            position: 'sticky !important',
                            right: 0,
                            zIndex: 1,
                            backgroundColor: '#EBEBEB !important',
                        },
                        '& th:nth-child(1)': {
                            position: 'sticky !important',
                            left: 0,
                            zIndex: 1,
                        },
                        '& th:nth-child(2)': {
                            position: 'sticky !important',
                            left: 51,
                            zIndex: 1,
                        },
                    },
                },
                '& tbody': {
                    '& tr': {
                        '& td:nth-last-child(1)': {
                            position: 'sticky !important',
                            right: 0,
                            zIndex: 1,
                            background: 'white'
                        },
                        '& td:nth-child(1)': {
                            position: 'sticky !important',
                            left: 0,
                            zIndex: 1,
                            background: 'white'
                        },
                        '& td:nth-child(2)': {
                            position: 'sticky !important',
                            left: 51,
                            zIndex: 1,
                            background: 'white'
                        },
                    }
                },
            },
        }

    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        // paddingBottom: 10
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        // marginBottom: 5
    },
    season_Title: {
        '& h4': {
            color: '#000000a6',
            margin: 0,
        }
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    divisin: {
        padding: 10
    },
    userHierarchieRoles: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    divisionList: {
        width: '100%',
        maxWidth: '45%',
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        height: 40,
        padding: 1,
        alignItems: 'center',
        marginBottom: 8
    },
    shareing: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'auto',
        '& .sycn': {
            fontStyle: 'italic',
            fontWeight: 400,
            fontSize: 10,
            color: 'rgba(136, 135, 135, 0.9)',
            margin: 0,
            alignItems: 'center',
            paddingRight: 10,
            display: 'flex'
        }
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center',
        '& svg': {
            fontSize: 17,
        }
    },
    shareOptionStyles: {
        fontSize: 14,
        '& #share-to-wall': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& #download': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
        },
        '& #Email': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12
    },
    viewAllButton: {
        // margin: '0 10px',
        color: '#727272',
        fontSize: 13,
        float: 'right',
        cursor: 'pointer',
        border: '1px solid #D3D3D3',
        borderRadius: 4,
        padding: '6px 16px',
        textTransform: 'Capitalize',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
}));

const MyReporteesPerformance = ({ history, userHierarchieRoles, matches, filtersData, getDivisionsList, setLoading, shareLiveScoreDialog, isTeamRacePlayerSprint, loading, getPlayerCoreKpisStats, userDetails, isAdmin, seasonFormat, type, dialog, limit, uploadLogDate, seasonKpiPostions, getDivisionWiseKpi, openCreateArenaDialog, seasonDetails }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([]);
    const [canDownload, setCanDownload] = useState(false);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [decimalView, setDecimalView] = useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [roleObj, setRoleObj] = useState();
    const [checkColumn, setCheckColumn] = React.useState([{ isChecked: false }]);
    const qryparams = qs.parse(history.location.search);
    const [shareAnchorEl, setShareAnchorEl] = React.useState({
        el: null,
        arena: null
    });
    const isShareMenuOpen = Boolean(shareAnchorEl.el);


    useEffect(() => {
        if (filtersData?.season?._id) {
            const selectRole = userHierarchieRoles?.find(obj => obj._id === filtersData?.role);
            let role = roleObj ? roleObj?._id : filtersData?.role ? filtersData.role : qryparams?.role;
            let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
            setRoleObj(selectRole);
            if (role) {
                getDivisionWiseKpi({ seasonId, onSuccessCB, onErrorCB, params: { role_id: role, limit: 5, page: 1 } });
            }
        }
    }, [filtersData?.season?._id, filtersData?.role]);

    useEffect(() => {
        if (roleObj?._id) {
            let seasonId = filtersData?.season?._id;
            let role = roleObj?._id
            setLoading(true);
            getDivisionWiseKpi({ seasonId, onSuccessCB, onErrorCB, params: { role_id: role, limit: 5, page: 1 } });
        }
    }, [roleObj?._id]);


    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (response.length) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'summary_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-pending_actual`] = el.pending_actual;
                    obj[`${el.kpi_id}-color`] = el.status_code;
                    obj[`${el.kpi_id}-planned_color`] = el.status_code;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_pending_actual`] = el.planned_pending_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE') ? el.score || 0 : el.points || 0);
                    if (index === 0) {
                        const mapped_headers = el.summary_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            RAG: false,
                            cellStyle: { minWidth: 70 },
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('TARGET'),
                            render: (rowData) =>
                                (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`, //-Actual
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: el?.indicator_based_on === 'ACTUALS',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-pending_actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            cellStyle: { minWidth: 70 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            RAG: el?.indicator_based_on === 'ACHIEVEMENT',
                            cellStyle: el?.based_on === 'ACHIEVEMENT' ? { minWidth: 75 } : { minWidth: 75 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: false,
                            kpi: el.kpi_id,
                            color_key: 'planned_color',
                            cellStyle: { minWidth: 70 },
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: el?.indicator_based_on === '',
                            kpi: el.kpi_id,
                            color_key: 'planned_color',
                            cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_pending_actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            cellStyle: { minWidth: 70 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PLANNED_PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            RAG: el?.indicator_based_on === 'PLANNED_ACHIEVEMENT',
                            kpi: el.kpi_id,
                            align: 'center',
                            color_key: 'planned_color',
                            cellStyle: el?.based_on === 'PLANNED_ACHIEVEMENT' ? { minWidth: 75 } : { minWidth: 75 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            kpi: el.kpi_id,
                            RAG: false,
                            emptyValue: '--',
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 10 },
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap', minWidth: 70 },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData([...finalResult])
                setTableData([...finalResult]);


            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setTableData([]);
            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
            setTableData([]);
        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setTableData([]);
        setLoading(false);
    }


    const SummaryTable = ({ data, checkColumn, handleChange, isAdmin }) => (
        <div className={classes.liveScoreTable}>

            <LiveScoresMuiTable
                columns={[...columns, ...dynamicColumns, {
                    title: "Points",
                    field: "totalPoints",
                    editable: 'never',
                    templateRender: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>,
                    render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
                }]}
                data={data}
                checkColumn={checkColumn}
                handleChange={handleChange}
                isAdmin={isAdmin}
                options={{
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 25],
                }}
                components={{
                    Header: props => (
                        <TableHead className="custom-headers">
                            <TableRow>
                                <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                <TableCell style={{ textAlign: 'left' }} rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                {filtersData?.season?.season_format !== 'PLAYER_RACE' && <TableCell style={{ textAlign: 'left' }} rowSpan={2}>{props.columns[2]?.title}</TableCell>}
                                {
                                    dynamicHeaders.map((obj, index) => (
                                        <>
                                            <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                            </TableCell>
                                        </>
                                    ))
                                }
                                <TableCell rowSpan={2}>Points</TableCell>
                            </TableRow >
                            <TableRow>
                                {
                                    dynamicHeaders.map((obj) => (
                                        <>
                                            {
                                                obj?.summary_attributes.length ?
                                                    <>
                                                        {
                                                            obj?.summary_attributes.map(ele => (
                                                                ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                            ))
                                                        }
                                                        {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                    </>
                                                    :
                                                    <>
                                                        {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                        {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>}
                                                        {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                    </>
                                            }
                                        </>
                                    ))
                                }
                            </TableRow >
                        </TableHead >
                    )
                }}
            />
        </div >
    );

    const columns = [
        {
            title: "Rank",
            field: "rank",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            cellStyle: { maxWidth: 200, width: 200, minWidth: 160 },
            templateRender: (rowData) => rowData.player?.name.toUpperCase(),
            render: (rowData) => rowData.player ? (
                <div style={{ fontSize: 12 }} className={classes.nameRoot}>
                    <AvatarComponent data={rowData} from='PLAYER' type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            hidden: filtersData?.season?.season_format === 'PLAYER_RACE',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--',
            templateRender: (rowData) => rowData.team ? (
                <div >
                    {rowData.team && rowData.team.name}
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap' }}>
                        {rowData.captain && <span><span style={{ fontWeight: 600 }}>C</span> : {rowData.captain}</span>}
                        {rowData.mentor && <span style={{ marginLeft: 10 }}><span style={{ fontWeight: 600 }}>M</span> : {rowData.mentor}</span>}
                    </div>
                </div>
            ) : '--',
        }
    ]



    // --------share options -----
    const handleShareMenuOpen = (event) => {
        setShareAnchorEl({ el: event.currentTarget, arena: null });
    };
    const handleShareMenuClose = () => {
        setShareAnchorEl({ el: null, arena: null });
    };
    const handleShareScore = (id) => {
        shareLiveScoreDialog({ open: true, fixture: 'ROUND', notify: 'PLAYER', details: { seasonId: filtersData?.season?._id, roundId: filtersData?.round?._id, imageId: id }, filtersData: filtersData });
        handleShareMenuClose();
    }
    const handleDownloadPerformance = async (id, title, type) => {
        await setCanDownload(true);
        toJpeg(document.getElementById(id))
            .then((dataUrl) => {
                setCanDownload(false);
                try {
                    const img = new Image();
                    img.onload = async function () {
                        let high = (this.width > this.height) ? 'width' : 'height';
                        const resultImage = (this.width > 0 && this.height > 0) ? await resizeImageToSquare(dataUrl, { width: this[high], height: this[high] }) : null;
                        if (resultImage) {
                            if (type === 'download') {
                                downloadFiles(resultImage, title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-'));
                                handleShareMenuClose()
                            }
                            else {
                                openCreateArenaDialog({
                                    open: true, arena: {}, stepType: 'crop', attachment: [
                                        { url: resultImage, mimeType: "IMAGE", width: 4, height: 4, fileName: "kpi_performance_HHD-49_kpi_ck_bk -2022-08-18T133448.437.png", fileType: "image/png" }],
                                    postDetails: {
                                        targetPage: "REPORTEES",
                                        details: {
                                            league: filtersData?.league?._id,
                                            season: filtersData?.season?._id,
                                            round: filtersData?.season?.season_format === 'HEAD_TO_HEAD' ? filtersData?.round?._id : null,
                                            match: filtersData?.season?.season_format === 'TEAM_RACE' ? filtersData?.playermatch?._id : filtersData?.match?._id,
                                            roleId: roleObj?._id



                                        }
                                    }
                                });
                                handleShareMenuClose()
                            }

                        }

                    }
                    img.src = dataUrl;
                    return img.onload();
                } catch (e) {
                }
                // downloadFiles(dataUrl, title.replace(".", ""));
            })
    }


    const renderShareOptions = (
        <Menu
            anchorEl={shareAnchorEl.el}
            classes={{ paper: classes.profilePopup }}
            id="share-options-menu"
            keepMounted
            open={isShareMenuOpen}
            onClose={handleShareMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            style={{ zIndex: 1301 }}
        >
            <MenuItem onClick={() => handleDownloadPerformance('player-perfomances-table', `Player_performance_${filtersData.season?.uid}_${filtersData?.round ? filtersData?.round?.name : (isTeamRacePlayerSprint ? filtersData?.playermatch?.title : filtersData?.match?.title)}`, 'download')} className={classes.shareOptionStyles} ><GetAppIcon id="download" />Download</MenuItem>
            <MenuItem onClick={() => handleDownloadPerformance('player-perfomances-table', `Player_performance_${filtersData.season?.uid}_${filtersData?.round ? filtersData?.round?.name : (isTeamRacePlayerSprint ? filtersData?.playermatch?.title : filtersData?.match?.title)}`, 'share')} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-wall" />Share to Wall</MenuItem>
            <MenuItem onClick={() => handleShareScore('player-perfomances-table1')} className={classes.shareOptionStyles}><MailOutlineOutlinedIcon id="Email" />Email </MenuItem>
        </Menu>
    );

    const handleDetailedScore = (roleId) => {
        setSearchQuery(history, { role: roleId });
        history.push(`${path}/detailed-reportees${history.location?.search}`);
    };

    const handleChange = (row, rowIndex, type) => {

    };
    const onclickDiv = (value) => {
        setRoleObj(value);
    }

    const playerEmailTemplateColumns = columns.filter((obj, i) => filtersData?.season?.season_format === "PLAYER_RACE" ? obj.title !== 'Team' : true)

    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <h2>{seasonDetails?.player_role === 'VIEWER' ? 'Performance Report' : 'My Reportees Performance'}</h2>
            </div>
            <div className={classes.performance}>
                {/* <div className={classes.season_head}>
                    <div className={classes.season_Title}>
                        <h4>{filtersData?.league?.league_name}</h4>
                    </div>
                    <div className={classes.season_}>
                        <button>
                            <Typography className="seasnHdng" title={filtersData?.season?.name}>
                                {filtersData?.season && getEllipsisText(filtersData?.season?.name, 12)}
                            </Typography>
                        </button>
                    </div>
                </div> */}
                <div className={classes.divisin}>
                    {(userHierarchieRoles.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                        <>
                            <div className={classes.userHierarchieRoles}>
                                <div className={classes.divisionList}>
                                    <CustomCarousel data={userHierarchieRoles} selectesData={roleObj ? roleObj : userHierarchieRoles[0]} onclickDiv={onclickDiv} />
                                </div>
                                <div className={classes.shareing}>
                                    {isAdmin && data.length > 0 ?
                                        <>
                                            {/* <p className="sycn"> <p style={{ paddingTop: 3, margin: 0, paddingRight: 5 }}><SyncIcon fontSize="small" /></p> Last Updated on April 20, 2023 at 10:35 am </p> */}
                                            <div className={classes.iconDiv}>
                                                <IconButton onClick={(e) => handleShareMenuOpen(e,)} >
                                                    <ShareSharpIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </>
                                        : null}
                                    {renderShareOptions}
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    {
                                        (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                                            data.length > 0 ?
                                                <>
                                                    <div className=''>
                                                        <SummaryTable
                                                            data={tableData}
                                                            checkColumn={checkColumn}
                                                            handleChange={handleChange}
                                                        />
                                                        {(data.length > 0) &&
                                                            <p className="table-footer">
                                                                <span className={classes.viewAllButton} onClick={() => handleDetailedScore(roleObj?._id)}>View Detailed Scorecard <ArrowForward fontSize="" /></span>
                                                            </p>
                                                        }
                                                    </div>
                                                </>
                                                : null
                                    }
                                    {/* <PerformanceTableTemplate
                                        id={`player-perfomances-table`}
                                        from="performance"
                                        filtersData={filtersData}
                                        belongsTo='Players'
                                        dynamicColumns={dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual').map(ele => { return { ...ele, title: ele.short_code, kpiname: ele.title } })}
                                        columns={columns}
                                        data={data.map(ele => { return { ...ele, position: ele.rank } })}
                                        render={(canDownload || dialog?.open)}
                                        isUploadLogRequired={false}
                                        decimalView={decimalView}
                                        reporteeName={roleObj?.name}


                                    /> */}
                                    {/* <PerformanceTablePlayersEmailTemplate
                                        id="player-perfomances-table1"
                                        filtersData={filtersData}
                                        columns={[...playerEmailTemplateColumns, ...dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')]}
                                        belongsTo='Players'
                                        data={data.map(ele => { return { ...ele, position: ele.rank } })}
                                        isUploadLogRequired={false}
                                        render={(canDownload || dialog?.open)}

                                    /> */}


                                </div>
                            </div >
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions,
    seasonDetails: state.game.seasonDetails

});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getPlayerCoreKpisStats: (options) => dispatch(getPlayerCoreKpisStats(options)),
        getDivisionWiseKpi: (options) => dispatch(getDivisionWiseKpi(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyReporteesPerformance));