import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MessageInput from './MessageInput';
import { connect } from 'react-redux';
import { searchHistory, getChatMessages } from '../../ducks/messages';
import { ExpandMore, PlayCircleOutline } from '@material-ui/icons';
import { IconButton, Avatar, Paper, Menu, MenuItem, ListItem, Button } from '@material-ui/core';
import moment from 'moment';
// import DOMPurify from 'dompurify';
import { joinRoom } from '../../ducks/rooms';
import classNames from 'classnames';
import './ChatSection.css';
import { getUser } from '../../selectors';
import DocFormat from '../../assets/images/doc-format.png';
import PdfFormat from '../../assets/images/pdf-format.png';
import ExcelFormat from '../../assets/images/excel.svg';
import LinkIcon from '../../assets/images/link.png';
import loadingGif from '../../assets/images/eclipse-loading.gif';
import { updateChatViewStatus } from '../../ducks/chats';
import GroupHeader from './GroupHeader';
import { handleImageViewer } from '../../ducks/imageViewer';
import { handleVideoPlayer } from '../../ducks/videoPlayer';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { setCurrentSurvey } from '../../ducks/surveys';
import { getTeamMembers } from '../../ducks/common';
import { convertUnicode } from '../../helpers';
import { SendToChatTemplate } from '../Arena/ShareToChatDialog';
import { meetingMessage, SentIcon } from './ChatSection';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'inherit'
    },
    inputRoot: {
        padding: '5px 10px 15px',
        // display: 'flex',
        alignItems: 'flex-end',
        // height: '-webkit-fill-available',
    },
    messageLayout: {
        margin: '15px 25px 5px 16px',
        padding: '9px 20px 10px',
        width: 'auto',
        maxWidth: '60%',
        fontSize: '13px',
        position: 'relative',
        wordBreak: 'break-all',
        textAlign: 'left',
        // borderRadius: '0 15px 15px 15px',
        borderRadius: '0 8px 8px 8px',
        whiteSpace: 'pre-wrap',
        backgroundColor: theme.palette.secondary.light,
        '&:hover #more-icon': {
            visibility: 'visible',
        }
    },
    messageRoot: {
        display: 'flex',
        marginLeft: '25px',
        // justifyContent: 'flex-end',
        // textAlign: 'right',
        // '& > div': {
        //     width: '100%'
        // }
    },
    messageRootRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        '& > div': {
            backgroundColor: theme.palette.primary.light,
            // borderRadius: '15px 15px 0 15px',
            borderRadius: '8px 8px 0 8px',
        }
    },
    timestamp: {
        // margin: '0 10px',
        color: '#838383',
        fontSize: '11px',
        float: 'right',
        margin: '10px -10px 0 20px',
    },
    expandIcon: {
        position: 'absolute',
        right: '2px',
        top: '2px',
        visibility: 'hidden',
        cursor: 'pointer'
    },
    profilePopup: {
        width: '150px'
    },
    fromAvatarRoot: {
        paddingTop: '30px'
    },
    fromName: {
        margin: '0px',
        paddingBottom: '7px',
        fontSize: '15px',
        fontWeight: 600,
        color: theme.palette.primary.main,
        color: '#536DFE'
    },
    hideAvatar: {
        // visibility: "hidden",
        width: '40px'
    },
    reduceGap: {
        marginTop: '3px'
    },
    divider: {
        margin: 25
    },
    imageStyles: {
        maxWidth: '95%',
        maxHeight: '250px',
        cursor: 'pointer'
    },
    imageBody: {
        margin: '10px 0 0'
    },
    videoStyles: {
        width: '150px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6A7279',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    playIcon: {
        color: '#fff'
    },
    quotedMsgRoot: {
        margin: '-12px -20px 18px',
        padding: '9px 20px 12px',
        border: '1.5px solid #E3E3E3',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.primary.main,
        // backgroundColor: theme.palette.primary.light,
        backgroundColor: '#FFF',
        // borderRadius: '0 15px 0 0'
        borderRadius: '0 8px 0 0',
        borderTopLeftRadius: 'inherit'
    },
    quotedMsgRootAlt: {
        margin: '-12px -20px 18px',
        padding: '9px 20px 12px',
        border: '1.5px solid #E3E3E3',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.primary.main,
        // backgroundColor: theme.palette.secondary.light,
        backgroundColor: '#FFF',
        // borderRadius: '15px 15px 0 0'
        borderRadius: '8px 8px 0 0',
        borderTopLeftRadius: 'inherit'
    },
    quoteMsg: {
        margin: '5px 0',
        whiteSpace: 'pre-wrap'
    },
    quoteMsgOf: {
        fontSize: 10,
        color: '#272523',
        marginTop: 2
    },
    fileRoot: {
        display: 'flex',
        // justifyContent: 'space-around',
        alignItems: 'center',
        cursor: 'pointer',
        '& span': {
            marginLeft: 15,
            color: '#00000080'
        }
    },
    linkItemRoot: {
        padding: 0,
        margin: '10px 0',
        height: 80,
        overflow: 'hidden'
    },
    linkItemContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        wordBreak: 'break-all',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#F0F0F0',
        alignItems: 'center'
    },
    linkIconRoot: {
        minWidth: 90,
        backgroundColor: '#E6E6E6',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    primaryText: {
        margin: 0,
        paddingBottom: '7px',
        fontSize: 12,
        color: '#535353'
    },
    linkTag: {
        textDecoration: 'none',
        color: 'inherit'
    },
    quotedLinkRoot: {
        padding: 0,
        margin: '10px 0',
        height: 40,
        overflow: 'hidden'
    },
    quotedImageStyle: {
        width: 50,
        height: 50
    },
    quotedVideoStyle: {
        width: 60,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6A7279',
        position: 'relative',
    },
    extraPadding: {
        marginBottom: 10
    },
    surveyRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    surveyButton: {
        border: '2px solid orange',
        borderRadius: 20,
        marginLeft: 30,
        marginRight: 30,
        whiteSpace: 'nowrap',
        '& span': {
            color: 'orange',
            // fontWeight: 600
        }
    },
    surveyExpired: {
        border: '2px solid red',
        '& span': {
            color: 'red',
        }
    },
    surveyResponded: {
        border: '2px solid green',
        '& span': {
            color: 'green',
        }
    },
    textBelowQuoteMsg: {
        fontSize: 14,
        marginBottom: 0,
        marginTop: 10
    },
    surveyTitle: {
        margin: 0
    },
    fullWidth: {
        width: '100%'
    },
    loadingImageRoot: {
        textAlign: "center",
        margin: 0
    },
    loadingImage: {
        width: 50,
        borderRadius: 25
    },
}));

const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const limit = 30;
let totalPaination = {};

let pagination = {
    page: 0,
    limit: limit
}
let lastPage = 0;

// let totalPages = {};

let prevScrollHeight = 0;

const GroupChatSection = React.memo(function (props) {
    const classes = useStyles();
    console.log('GroupChatSection ');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedMsgDup, setSelectedMsgDup] = React.useState(null);
    const [selectedMsg, setSelectedMsg] = React.useState(null);
    const [members, setMembers] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    // let pagination = {
    //     page: 0,
    //     limit: 15
    // }
    const isMenuOpen = Boolean(anchorEl);
    let scrollListener = null;

    // useEffect(() => {
    //     if (!props.messages.hasOwnProperty(props.match.params.jid)) {
    //         props.searchHistory({
    //             with: props.match.params.jid,
    //             rsm: { max: 50, before: true },
    //             complete: false
    //         })
    //     }
    // }, [props.match.params.jid])


    // useEffect(() => {
    //     const messageId = localStorage.getItem('messageId') || '';
    //     const globalSearchQuery = localStorage.getItem('globalSearchQuery') || '';
    //     if (messageId && messageId.length > 0) {
    //         const section = document.getElementById(messageId);
    //         if (section) {
    //             setTimeout(() => {
    //                 section.scrollIntoView();
    //                 // function highlight(text) {
    //                 var inputText = document.getElementById(messageId);
    //                 if (inputText) {
    //                     inputText.style.backgroundColor = 'yellow'
    //                 }
    //                 localStorage.setItem('messageId', '');
    //                 // var innerHTML = inputText.innerHTML;
    //                 // var index = innerHTML.indexOf(globalSearchQuery);
    //                 // if (index >= 0) {
    //                 // innerHTML = innerHTML.substring(0, index) + "<span class='highlight'>" + innerHTML.substring(index, index + globalSearchQuery.length) + "</span>" + innerHTML.substring(index + globalSearchQuery.length);
    //                 // inputText.innerHTML = innerHTML;
    //                 // }
    //                 //   }
    //             }, 800);
    //             setTimeout(() => {
    //             }, 2000);
    //         }
    //     }

    // })

    // useEffect(() => {
    //     console.log('ChatSection props.messageId ', props.messageId);
    //     if (props.messageId) {
    //         console.log('ChatSection props.messageId ', props.messageId);
    //         const section = document.getElementById(props.messageId);
    //         if (section) {
    //             setTimeout(() => {
    //                 section.scrollIntoView();
    //                 // function highlight(text) {
    //                 var inputText = document.getElementById(props.messageId);
    //                 var innerHTML = inputText.innerHTML;
    //                 var index = innerHTML.indexOf(props.globalSearchQuery);
    //                 if (index >= 0) {
    //                     innerHTML = innerHTML.substring(0, index) + "<span class='highlight'>" + innerHTML.substring(index, index + props.globalSearchQuery.length) + "</span>" + innerHTML.substring(index + 'llo'.length);
    //                     inputText.innerHTML = innerHTML;
    //                 }
    //                 //   }
    //             }, 500);
    //         }
    //     }
    // }, [props.messageId])

    const previousjid = usePrevious(props.targetJid);

    // let prevScrollHeight = 0;
    // let lastPage = 0;
    const handleScroll = () => {
        let lastLi = document.getElementById(props.targetJid);
        // var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        // var pageOffset = window.pageYOffset + window.innerHeight;
        // lastLi.style.scrollBehavior = 'smooth';
        // if (pageOffset > lastLiOffset) {
        // console.log('handleScroll if ', lastLi.scrollHeight, lastLi.clientHeight, lastLi.scrollHeight - lastLi.clientHeight)
        if (lastLi && lastLi.scrollTop === 0 && (lastPage !== pagination.page + 1)) {
            if ((pagination.total_pages - 1) > pagination.page) {
                // lastPage = pagination.page + 1
                setLoading(true);
                setTimeout(() => {
                    props.getChatMessages({ targetJid: props.targetJid, params: { limit: limit, page: pagination.page + 1 }, onGetMessagesSuccessCB, onGetMessagesErrorCB, type: props.type });
                    // lastLi.scrollTop = lastLi.scrollHeight - prevScrollHeight;
                    // prevScrollHeight = lastLi.scrollHeight;
                }, 1000);
            }
        }
        // }
    };

    useEffect(() => {
        if (props.targetJid) props.getTeamMembers({ teamId: props.targetJid.split('@')[0], onGetMembersSuccessCB, onGetMembersErrorCB });

        pagination = {
            page: totalPaination[props.targetJid] ? totalPaination[props.targetJid].page : 0,
            limit: totalPaination[props.targetJid] ? totalPaination[props.targetJid].limit : limit,
            total_pages: totalPaination[props.targetJid] ? totalPaination[props.targetJid].total_pages : 0
        }
        lastPage = totalPaination[props.targetJid] ? totalPaination[props.targetJid].lastPage : 0;
        // lastPage = 0;
        prevScrollHeight = 0;

        if (previousjid && previousjid !== props.targetJid) {
            props.updateChatViewStatus(previousjid.split('@')[0]);
        }

        if (!props.rooms[props.targetJid] || !props.rooms[props.targetJid].joined) {
            const { decoded_token } = props.keycloak;
            // TODO consider fallback global nickname OR saving nickname for bookmarks from previous session
            props.joinRoom(props.targetJid, decoded_token.preferred_username);
        }

        let messageList = document.getElementById(props.targetJid);
        if (messageList)
            messageList.scrollTop = messageList.scrollHeight;
        if (!props.messages.hasOwnProperty(props.targetJid) || (props.messages[props.targetJid] && !props.messages[props.targetJid].history)) {
            // props.searchHistory({
            //     with: props.targetJid,
            //     rsm: { max: 50, before: true },
            //     complete: false
            // })
            props.getChatMessages({ targetJid: props.targetJid, params: { limit: limit, page: 0 }, onGetMessagesSuccessCB, onGetMessagesErrorCB, type: props.type });
            // setTimeout(() => {
            //     let messageList = document.getElementById(props.targetJid);
            //     if (messageList)
            //         messageList.scrollTop = messageList.scrollHeight;
            // }, 500);
        }
        scrollListener = messageList.addEventListener("scroll", e => {
            // console.log('onScroll Listener', e);
            handleScroll(e);
        });
        props.updateChatViewStatus(props.targetJid.split('@')[0]);

    }, [props.targetJid]);

    useEffect(() => {
        // props.updateChatViewStatus(props.targetJid.split('@')[0]);
        console.log('GroupChatSection');

        if (props.messages[props.targetJid] && props.messages[props.targetJid].messages.length <= limit) {
            setTimeout(() => {
                let messageList = document.getElementById(props.targetJid);
                if (messageList)
                    messageList.scrollTop = messageList.scrollHeight;
            }, 500);
            setTimeout(() => {
                let messageList = document.getElementById(props.targetJid);
                if (messageList)
                    messageList.scrollTop = messageList.scrollHeight;
            }, 800);
        }
    }, [props.messages])

    useEffect(() => {

        if (!props.rooms[props.targetJid] || !props.rooms[props.targetJid].joined) {
            const { decoded_token } = props.keycloak;
            // TODO consider fallback global nickname OR saving nickname for bookmarks from previous session
            props.joinRoom(props.targetJid, decoded_token.preferred_username);
        }


        // if (!props.messages.hasOwnProperty(props.targetJid)) {
        // props.searchHistory({
        //     with: props.targetJid,
        //     rsm: { max: 50, before: true },
        //     complete: true
        // })
        // }

        // if (props.match.params.jid) {
        //     props.showRoom(props.match.params.jid);
        // }
        return () => {
            props.updateChatViewStatus(props.targetJid.split('@')[0]);
        }
    }, [])

    useEffect(() => {
        if (props.reconnecting === 'xmpp-reconnected')
            props.getChatMessages({ targetJid: props.targetJid, isReconnected: true, params: { limit: limit, page: 0 }, onGetMessagesSuccessCB, onGetMessagesErrorCB, type: props.type });
    }, [props.reconnecting]);

    const onGetMessagesSuccessCB = (headers) => {
        pagination = {
            limit: parseInt(headers.limit),
            page: parseInt(headers.page),
            total_count: parseInt(headers.total_count),
            total_pages: parseInt(headers.total_pages),
        }
        totalPaination = {
            ...totalPaination,
            [props.targetJid]: {
                limit: parseInt(headers.limit),
                page: parseInt(headers.page),
                total_count: parseInt(headers.total_count),
                total_pages: parseInt(headers.total_pages),
                lastPage: parseInt(headers.page)
            }
        }
        setLoading(false);
        let lastLi = document.getElementById(props.targetJid);
        if (lastLi) {
            if (parseInt(headers.page) === 0) {
                lastLi.scrollTop = lastLi.scrollHeight;
                prevScrollHeight = lastLi.scrollHeight;
            }
            else {
                lastLi.scrollTop = lastLi.scrollHeight - prevScrollHeight;
                prevScrollHeight = lastLi.scrollHeight;
            }
        }
    }

    const onGetMessagesErrorCB = (error) => {
        console.log('Failed to load messages ', error);
    }

    const onGetMembersSuccessCB = (result) => {
        // console.log('get memebrs ', result);
        setMembers(result);
    }

    const onGetMembersErrorCB = (error) => {
        console.log('Failed to get memebrs ', error);
        setMembers(null);
    }

    const handleOnMessageSent = (targetJid) => {
        let messageList = document.getElementById(targetJid);
        // console.log('handleOnMessageSent group ', messageList, targetJid);
        if (messageList) {
            setTimeout(() => {
                messageList.scrollTop = messageList.scrollHeight;
            }, 500);
            setTimeout(() => {
                messageList.scrollTop = messageList.scrollHeight;
            }, 1000);
        }
    }

    const handleMoreMenuOpen = (event, msg) => {
        setSelectedMsgDup(msg);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleQuote = () => {
        setSelectedMsg(selectedMsgDup);
        handleMenuClose();
    }

    const handleOnClearQuote = () => {
        setSelectedMsg(null);
    }

    const menuId = 'expand-more-menu';
    const renderMore = (
        <Menu
            anchorEl={anchorEl}
            classes={{ paper: classes.profilePopup }}
            id={menuId}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => handleQuote()}>Respond</MenuItem>
        </Menu>
    );

    function downloadFiles(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const getFormatIcon = (type) => {
        switch (type) {
            case 'doc':
            case 'docx':
                return DocFormat;
            case 'pdf':
                return PdfFormat;
            case 'xls':
            case 'xlsx':
                return ExcelFormat;
            default:
                break;
        }
    }

    const handleOnSurveyClick = (surveyId) => {
        props.setCurrentSurvey(surveyId);
        props.history.push({
            pathname: `/survey/${surveyId}`,
            // search: `?s_id=${surveyId}`
        })
    }

    const linkMessage = (msg) => (
        <>
            <a href={msg.url} target="_blank" className={classes.linkTag}>
                <ListItem className={classes.linkItemRoot} button >
                    <div className={classes.linkIconRoot}>
                        <img width="25" src={LinkIcon} alt={'Link'} />
                    </div>
                    <div className={classes.linkItemContent}>
                        <div>
                            <p className={classes.primaryText}>{msg.url.length > 100 ? msg.url.substring(0, 100) + '...' : msg.url}</p>
                        </div>
                    </div>
                </ListItem>
            </a>
            {convertUnicode(msg.body)}
        </>
    )

    const videoMessage = (msg) => (
        <>
            <div className={classes.videoStyles} >
                <IconButton onClick={() => props.handleVideoPlayer({ open: true, video: msg.url })}><PlayCircleOutline className={classes.playIcon} /></IconButton>
            </div>
            <p className={classes.imageBody}>{convertUnicode(msg.body)}</p>
        </>
    )

    const imageMessage = (msg) => (
        <>
            <img className={classes.imageStyles} src={msg.url} alt={msg.body} onClick={() => props.handleImageViewer({ open: true, images: [msg.url] })} />
            <p className={classes.imageBody}>{convertUnicode(msg.body)}</p>
        </>
    )

    const quoteMessage = (msg) => {
        const quoteMsg = convertUnicode(msg.quoteMsg);
        return (
            <>
                <div className={(msg.targetJid && msg.targetJid.split('/')[1] === props.client.nickname) ? classes.quotedMsgRoot : classes.quotedMsgRootAlt} >
                    <p className={classes.quoteMsgOf}>{(msg.targetJid && msg.targetJid.split('@')[0] === username) ? 'You' : (members[msg.targetJid] || msg.targetJid.split('@')[0])}</p>
                    {
                        (msg.hasOwnProperty('quoteType') && msg.hasOwnProperty('url')) ?
                            (
                                msg.quoteType === 'image' ?
                                    <><img className={classes.quotedImageStyle} src={msg.url} alt={msg.body} /><br />
                                        <p className={classes.textBelowQuoteMsg}>{quoteMsg}</p>
                                    </>
                                    : (msg.quoteType === 'file') ?
                                        <>
                                            <div className={classes.fileRoot}>
                                                <img width="25" src={getFormatIcon(msg.url.split('.').pop())} alt={msg.fileName} />
                                                <span>{msg.url.split('-').pop()}</span>
                                            </div>
                                            {quoteMsg && <p className={classes.textBelowQuoteMsg}>{quoteMsg}</p>}
                                        </>
                                        : (msg.quoteType === 'link') ?
                                            <ListItem className={classes.quotedLinkRoot} button >
                                                <div className={classes.linkIconRoot}>
                                                    <img width="25" src={LinkIcon} alt={'Link'} />
                                                </div>
                                                <div className={classes.linkItemContent}>
                                                    <div>
                                                        <p className={classes.primaryText}>{msg.url.length > 60 ? msg.url.substring(0, 60) + '...' : msg.url}</p>
                                                    </div>
                                                </div>
                                            </ListItem>
                                            : (msg.quoteType === 'video') ?
                                                <>
                                                    <div className={classes.quotedVideoStyle} >
                                                        <IconButton ><PlayCircleOutline className={classes.playIcon} /></IconButton>
                                                    </div>
                                                    {quoteMsg && <p className={classes.textBelowQuoteMsg}>{quoteMsg}</p>}
                                                </>
                                                : (msg.quoteType === 'post') ?
                                                    <SendToChatTemplate
                                                        photoUrl={msg.url}
                                                        text={msg.quoteMsg}
                                                        postedBy={msg.createdBy}
                                                        textLimit={35}
                                                        quotedTemplate
                                                    />
                                                    :
                                                    < p className={classes.quoteMsg}>{quoteMsg}</p>
                            )
                            :
                            < p className={classes.quoteMsg}>{quoteMsg}</p>
                    }
                </div>
                {convertUnicode(msg.body)}
            </>
        )
    }

    const fileMessage = (msg) => (
        <>
            <div>
                <div className={classes.fileRoot} onClick={() => downloadFiles(msg.url, msg.url.split('-').pop())}>
                    <img width="25" src={getFormatIcon(msg.fileType)} alt={msg.fileName} />
                    <span>{msg.url.split('-').pop()}</span>
                </div>
                {msg.body && <p className={classes.imageBody}>{convertUnicode(msg.body)}</p>}
            </div>
        </>
    )

    const surveyMessage = (msg) => {
        const expired = (new Date(msg.expire_date).getTime()) < (new Date().getTime());
        // const responded = (msg.responded === "true") ? true : false;
        const responded = msg.responded;
        return (
            <>
                <div className={classes.surveyRoot} >
                    {/* <p className={classes.surveyTitle} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg.title) }}></p> */}
                    <p className={classes.surveyTitle} >{msg.title}</p>
                    {
                        expired ?
                            <Button disabled className={classNames(classes.surveyButton, classes.surveyExpired)} variant="outlined">Expired</Button>
                            :
                            <Button onClick={() => handleOnSurveyClick(msg.surveyId)} className={classes.surveyButton} variant="outlined">Respond</Button>
                    }
                </div>
            </>
        )
    }

    const arenaPostMessage = (msg) => (
        <SendToChatTemplate
            photoUrl={msg.thumbnailUrl}
            text={msg.text}
            postedBy={msg.createdBy}
            textLimit={125}
            clickable
            onClick={() => props.history.push(`/wall/view/${msg.postId}`)}
        />
    )

    const username = props.keycloak.decoded_token.preferred_username;

    return (
        <div className={classes.root}>
            <GroupHeader {...props} />
            <div id={`${props.targetJid}`} className="scroller chat-section-scroller" style={{ height: '70vh', minHeight: (!props.hasOwnProperty('active') || props.active === true) ? '70vh' : '78vh' }}>
                {
                    loading &&
                    <p className={classes.loadingImageRoot}><img className={classes.loadingImage} src={loadingGif} alt="Loading" /></p>
                }
                {props.messages[props.targetJid] && props.messages[props.targetJid].messages.map((message, index, list) => {
                    let outgoing = message.from.split('/')[1] === username || message.from.split('/')[1] === props.currentUser.name;
                    let fromLastUser = index > 0 ? (list[index - 1].from.split('/')[1] === message.from.split('/')[1]) ? true : false : false;

                    let past = (index > 0) ? new Date(list[index - 1].time).getTime() : new Date().getTime();
                    let sameday = (new Date(past).toDateString() == new Date(message.time).toDateString());
                    let isToday = new Date(message.time).toDateString() == new Date().toDateString();

                    // let newDay = index > 0 ? (list[index - 1].from.split('/')[1] === message.from.split('/')[1]) ? true : false : false

                    let newMsg = {};
                    if (message.body.startsWith('%%%')) {
                        try {
                            newMsg = JSON.parse(message.body.replace(/%%%/gi, ''));
                        }
                        catch {
                            message.body = 'Invalid Message Body';
                        }
                    }
                    return (
                        <div key={index}>
                            {!sameday && <div className="separator">{isToday ? 'Today' : moment(message.time).format("DD-MMM-YYYY")}</div>}
                            {
                                newMsg && newMsg.msgType === 'call' ?
                                    null
                                    :
                                    <div key={index} className={outgoing ? classes.messageRootRight : classes.messageRoot}>
                                        {/* {(!outgoing) &&
                                    <div className={classes.fromAvatarRoot}>
                                        {
                                            fromLastUser ?
                                                <p className={classes.hideAvatar}></p>
                                                : <Avatar
                                                    alt={message.from.split('/')[1]}
                                                    src={(props.url && props.url.length) ? props.url : '/'}
                                                >{message.from.split('/')[1].substring(0, 1)}</Avatar>
                                        }
                                    </div>
                                } */}
                                        <Paper square key={index} id={message.id} elevation={0} className={classNames(classes.messageLayout, fromLastUser && classes.reduceGap, (newMsg.msgType && newMsg.msgType === 'survey') && classes.fullWidth)}>
                                            {(!outgoing && !fromLastUser) && <p className={classNames(classes.fromName, newMsg.msgType && classes.extraPadding)}>{message.from_name || members[message.from.split('/')[1]] || message.from.split('/')[1]}</p>}
                                            {
                                                newMsg.msgType === 'image' ?
                                                    imageMessage(newMsg)
                                                    : (newMsg.msgType === 'quote') ?
                                                        quoteMessage(newMsg)
                                                        : (newMsg.msgType === 'file') ?
                                                            fileMessage(newMsg)
                                                            : (newMsg.msgType === 'link') ?
                                                                linkMessage(newMsg)
                                                                : (newMsg.msgType === 'video') ?
                                                                    videoMessage(newMsg)
                                                                    : (newMsg.msgType === 'survey') ?
                                                                        surveyMessage(newMsg)
                                                                        : (newMsg.msgType === 'post') ?
                                                                            arenaPostMessage(newMsg)
                                                                            : (newMsg.msgType === 'meet') ?
                                                                                meetingMessage(newMsg)
                                                                                : convertUnicode(message.body)
                                            }
                                            <p className={classes.timestamp}>
                                                {moment(message.time).format("hh:mm A")}
                                                {outgoing && <SentIcon />}
                                            </p>
                                            {
                                                (newMsg.msgType !== 'survey') &&
                                                <span id="more-icon" className={classes.expandIcon}>
                                                    <IconButton onClick={(e) => handleMoreMenuOpen(e, message)} size="small"><ExpandMore /></IconButton>
                                                </span>
                                            }
                                        </Paper>
                                    </div>
                            }
                        </div>

                    )
                })}
                {renderMore}
            </div>
            {
                (!props.hasOwnProperty('active') || props.active === true) &&
                <div className={classes.inputRoot}>
                    <MessageInput chat="group" username={username} members={members} contact={props.targetJid} handleOnMessageSent={handleOnMessageSent}
                        selectedMsg={selectedMsg}
                        targetDetails={{
                            targetJid: props.targetJid,
                            name: props.name,
                            type: props.type === 'group' ? '1' : '0',
                            active: `${props.active}`,
                            image_url: `${props.image_url}`
                        }}
                        handleOnClearQuote={handleOnClearQuote}
                    />
                </div>
            }
        </div>
    );
})

const mapStateToProps = (state, props) => ({
    // xmpp: window.client,
    userStatus: getUser(state.chats, props.targetJid),
    client: state.client,
    rooms: state.rooms,
    messages: state.messages,
    currentUser: state.user,
    keycloak: state.keycloak,
    reconnecting: state.snackbar.type
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        searchHistory: (options) => dispatch(searchHistory(options)),
        getChatMessages: (options) => dispatch(getChatMessages(options)),
        joinRoom: (options, nickname) => dispatch(joinRoom(options, nickname)),
        updateChatViewStatus: (options) => dispatch(updateChatViewStatus(options)),
        handleImageViewer: (options) => dispatch(handleImageViewer(options)),
        handleVideoPlayer: (options) => dispatch(handleVideoPlayer(options)),
        setCurrentSurvey: (options) => dispatch(setCurrentSurvey(options)),
        getTeamMembers: (options) => dispatch(getTeamMembers(options))
    };
};


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(GroupChatSection);