import React from 'react';
import MUIDataTable from 'mui-datatables';
import IllustrationView from '../LiveScores/IllustrationView';

const CustomDataTable = ({ title = '', columns = [], data = [], options = {}, rootProps = {}, loading, ...otherProps }) => {
  return (
    <>
      { (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
        <div className="material-table data-table" {...rootProps}>
          <MUIDataTable
            title={title}
            data={data}
            columns={columns}
            options={{
              rowsSelected: [data.findIndex(obj => obj.selected)],
              sort: false,
              selectableRowsHideCheckboxes: true,
              rowsPerPageOptions: [5, 10, 25],
              rowsPerPage: 25,
              responsive: 'standard',
              textLabels: {
                pagination: {
                  rowsPerPage: 'Rows'
                },
                body: {
                  noMatch: 'No data available'
                }
              },
              ...options
            }}
            {...otherProps}
          />
        </div>
      }
    </>
  );
};

export default CustomDataTable;
