import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from "@material-ui/core";
import classNames from 'classnames';
import { formatDate } from "../../helpers";
import gameIllustration from '../../assets/images/game_Illustration.png';

const useStyles = makeStyles((theme) => ({
    roundIconButton: {
        fontSize: 13,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: 4,
        pointerEvents: 'auto !important',
        color: '#000',
        '&:hover': {
            color: '#000'
        }
    },
    roundIconButtonSelected: {
        // color: 'black',
        // backgroundColor: '#C4C4C4',
        color: '#FFF',
        backgroundColor: theme.palette.primary.main
    },
    roundsLableStyle: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
        // color: '#0000008a'
    },
}));

const MatchListView = ({ title = 'Matches', matches = [], filtersData, type = 'SPRINT', loading, onMatchClick = () => { } }) => {
    const classes = useStyles();
    return (
        <>
            <div style={{ alignItems: 'center', flexWrap: 'wrap', display: matches.length > 0 ? 'flex' : 'block' }}>
                <p className={classes.roundsLableStyle}>{title} : </p>
                {
                    matches.length > 0 ?
                        matches.map((match, index) => (
                            <IconButton
                                key={match._id}
                                size="small"
                                title={match?.status}
                                className={classNames(classes.roundIconButton, { [classes.roundIconButtonSelected]: type === 'BONUS' ? filtersData.bonusMatch?._id === match._id : filtersData.match?._id === match._id })}
                                onClick={() => onMatchClick(match)}
                            >
                                {match.title}
                            </IconButton>
                        )) :
                        (loading && matches.length === 0) ? null :
                            <div style={{ textAlign: 'center' }}>
                                <img src={gameIllustration} />
                                <p style={{ fontSize: 14, color: '#0000008A' }}>No data available</p>
                            </div>
                }
            </div>
            {
                matches.length > 0 &&
                <p style={{ fontSize: 13, margin: '20px 0px 15px', color: '#0000008a' }}>
                    <span>Start Date : <span style={{ color: '#000' }}>{
                        type === 'SPRINT' ?
                            formatDate(filtersData.match?._id === 'ALL' ? filtersData.season?.start_date : filtersData.match?.start_date)
                            :
                            formatDate(filtersData.bonusMatch?._id === 'ALL' ? filtersData.season?.start_date : filtersData.bonusMatch?.start_date)

                    }</span></span>
                    <span style={{ marginLeft: 30 }}>End Date : <span style={{ color: '#000' }}>{
                        type === 'SPRINT' ?
                            formatDate(filtersData.match?._id === 'ALL' ? filtersData.season?.end_date : filtersData.match?.end_date)
                            : formatDate(filtersData.bonusMatch?._id === 'ALL' ? filtersData.season?.end_date : filtersData.bonusMatch?.end_date)}</span></span>
                </p>
            }
        </>
    )
};
export default MatchListView;