import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { connect } from 'react-redux';
import UserProfileLink from '../UserProfileCard/UserProfileLink';
import Moment from 'react-moment';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: "10px 18px",
        justifyContent: 'space-between',
        color: '#262626',
        fontSize: 15
    },
    moreButton: {
        padding: 8
    }
}));

const ArenaSharedPostHeader = ({ name = '', username = '', timestamp = '', handleMoreClick = () => { }, user, showMoreButton = false, ...others }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} {...others}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <UserProfileLink
                    name={name}
                    username={username}
                />
                <span style={{ fontSize: 12, paddingLeft: 12 }}>reshared this post <Moment fromNow>{timestamp}</Moment></span>
            </div>
            {
                showMoreButton &&
                <IconButton
                    onClick={handleMoreClick}
                    className={classes.moreButton}
                    aria-label="menu"
                >
                    <MoreVert />
                </IconButton>
            }
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(ArenaSharedPostHeader);