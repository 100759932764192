import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { setLoading } from "../../ducks/loading";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import BuzzNotificationsList from "./BuzzNotificationsList";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxHeight: 'calc(100vh - 116px)',
        overflowY: 'auto',
        padding: '20px 35px',
        [theme.breakpoints.down('xs')]: {
            padding: '20px 20px',
        },
    },
}));

const Buzz = ({ filtersData, history, userInfo, location }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();



    return (
        <div id="buzz-data" className={classes.root}>
            <div className='' >
                <Switch>
                    <Route path={`${path}/buzznotifications`} >
                        <BuzzNotificationsList />
                    </Route>
                    <Redirect to={{ pathname: `${path}/buzznotifications`, search: location.search }} />
                </Switch>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Buzz));