import React, { useState } from 'react';
import { makeStyles, Grid, TextField, IconButton, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AddCircle, AddCircleRounded, RemoveCircleRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { createPoll } from '../../ducks/arena';
import { setLoading } from '../../ducks/loading';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        width: '100%',
        transition: 'width 0.5s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            // maxWidth: 400,
            maxHeight: 400,
            // minHeight: 400,
            minHeight: 'calc(100vh - 64px)',
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: 400,
            minHeight: '100vh'
        },
    },
    titleRoot: {
        // marginRight: 5,
        backgroundColor: '#FFF',
        fontSize: 18,
        color: '#262626',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        borderBottom: '1px solid #e0dce0',
        '& p': {
            margin: '0px 50px'
        },
        '& .submitBtn': {
            cursor: 'pointer',
            color: '#112386',
            fontSize: 14,
            marginRight: 16,
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    content: {
        padding: 30,
        height: '100%',
        overflowY: 'auto'
    },
    textField: {
        borderRadius: 6
    },
    optionsType: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    iconStyle: {
        marginTop: -22
    },
    autocompleteRoot: {
        borderRadius: 6,
        // padding: '5px 7px !important',
        // width: 280
    },
    previewRoot: {
        width: '40%',
        backgroundColor: '#FFF',
        padding: '0 20px',
        borderLeft: '1px solid #e0dce0',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            width: 'calc(100% - 40px)',
            minHeight: '100vh',
            zIndex: 1
        },
        '& .title': {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            '& p': {
                fontSize: 18,
            },
            '& span': {
                cursor: 'pointer',
                color: '#112386',
                fontSize: 14,
                marginLeft: 15,
                '&:hover': {
                    textDecoration: 'underline'
                }

            }
        }
    },
    note: {
        padding: '10px 30px 15px',
        margin: 0,
        color: '#000000a3',
        fontSize: 14
    },
    card: {
        border: '1px solid #e0dce0',
        borderRadius: 6,
        padding: 16,
        '& p': {
            fontSize: 15,
            marginTop: 0,
        },
        '& button': {
            width: '100%',
            borderRadius: 18,
            marginBottom: 15,
            color: theme.palette.primary.main,
            border: `2px solid ${theme.palette.primary.main} !important`,
            fontWeight: 600,
            fontSize: 13
        },
        '& > span': {
            color: '#0000008a',
            fontSize: 12,
            '&:last-child': {
                marginLeft: 8
            }
        }
    }
}));

const durationOptions = [{ name: '1 day', id: '1d' }, { name: '3 days', id: '3d' }, { name: '7 days', id: '7d' }, { name: '2 weeks', id: '2w' }];

const CreatePoll = ({ handlePoll, createPoll, setLoading }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [details, setDetails] = useState({ title: '', options: [{ label: "", value: "" }, { label: "", value: "" }], duration: { name: '7 days', id: '7d' } });
    const [openPreview, setOpenPreview] = useState(false);

    const onOptionChange = (e, index) => {
        let detailsDup = JSON.parse(JSON.stringify(details));
        detailsDup.options[index].value = e.target.value;
        detailsDup.options[index].label = e.target.value;
        setDetails(detailsDup);
    }
    const onTitleChange = (e) => {
        const value = e.target.value;
        setDetails(prevState => ({ ...prevState, title: value }));
    }

    const addOptions = () => {
        if (details.options.length < 4) {
            setDetails(prevState => ({ ...prevState, options: [...prevState.options, { label: "", value: "" }] }));
        } else {
            enqueueSnackbar("you can use maximum of 4 options for a question", {
                variant: "warning",
                preventDuplicate: true
            });
        }
    };

    const deleteOptions = (e, index) => {
        let detailsDup = JSON.parse(JSON.stringify(details));
        detailsDup.options.splice(index, 1);
        setDetails(detailsDup);
    };

    const handleChangeDuration = (data) => {
        setDetails(prevState => ({ ...prevState, duration: data }));
    }

    const isValidData = (data) => {
        if (!data.title) {
            enqueueSnackbar('Please add your question.', { variant: 'error', });
            return false;
        }
        else if (data.options?.filter(obj => obj.value).length < 2) {
            enqueueSnackbar('Please add atleast two options.', { variant: 'error', });
            return false;
        }
        else
            return true;
    }

    const handlePreview = () => {
        if (isValidData(details)) {
            setOpenPreview(true);
        }
    }

    const handleCreatePoll = () => {
        if (isValidData(details)) {
            const requestObj = {
                title: details.title,
                expire_date: details.duration?.id,
                options: details.options?.filter(obj => obj.label).map((option) => ({
                    option_type: 'text',
                    label: option.label,
                    value: option.value
                }))
            };
            setLoading(true);
            createPoll({ requestObj, onCreateSuccess, onCreateError });
        }
    }

    const onCreateSuccess = (data) => {
        setLoading(false);
        handlePoll({ ...data, question: details });
    }
    const onCreateError = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Create Poll.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    return (
        <div style={{ backgroundColor: '#FFF', width: '100%', display: 'flex' }}>
            <div className={classes.root} style={{ width: openPreview && '60%' }}>
                <div className={classes.titleRoot}>
                    <p>Create a Poll</p>
                    {!openPreview && <span className="submitBtn" variant="text" onClick={() => handlePreview()}>Preview</span>}
                </div>
                <div className={classes.content}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                fullWidth
                                variant="outlined"
                                id="title"
                                rows="3"
                                value={details.title}
                                label="Your Question"
                                onChange={onTitleChange}
                                required
                                inputProps={{ maxLength: 140 }}
                                InputProps={{ classes: { root: classes.textField } }}
                                helperText={details.title ? `${details.title.length}/140` : `0/140`}
                                FormHelperTextProps={{ style: { textAlign: 'right' } }}
                            />
                        </Grid>
                        {details.options.map((option, index) => (
                            <Grid item xs={12}>
                                <div className={classes.optionsType}>
                                    <TextField
                                        // className={classes.margin}
                                        fullWidth
                                        required
                                        size='small'
                                        id={`option-${index}`}
                                        label={"option " + (index + 1)}
                                        variant="outlined"
                                        value={option.value}
                                        inputProps={{ maxLength: 30 }}
                                        InputProps={{ classes: { root: classes.textField } }}
                                        onChange={e => onOptionChange(e, index)}
                                        helperText={option.value ? `${option.value.length}/30` : `0/30`}
                                        FormHelperTextProps={{ style: { textAlign: 'right' } }}
                                    />
                                    <>
                                        <IconButton aria-label="add" className={classes.iconStyle} size="small" onClick={() => addOptions(option)}>
                                            <AddCircleRounded fontSize="small" />
                                        </IconButton>
                                        {details.options.length > 2 && <IconButton
                                            aria-label="delete"
                                            className={classes.iconStyle}
                                            size="small"
                                            onClick={e => deleteOptions(e, index)}
                                        >
                                            <RemoveCircleRounded fontSize="small" />
                                        </IconButton>}
                                    </>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Autocomplete
                                id="unit"
                                options={durationOptions}
                                getOptionLabel={(option) => option.name}
                                value={details.duration}
                                disableClearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} label={'Poll duration'} variant="outlined" size="small" required />}
                                onChange={(...args) => handleChangeDuration(args[1])}
                            />
                        </Grid>
                    </Grid>
                </div>
                {/* <p className={classes.note}>Note* : We don't allow requests for political opinions, medical information or other sensitive data.</p> */}

            </div>
            {
                openPreview &&
                <div className={classes.previewRoot}>
                    <div className="title" >
                        <p>Preview</p>
                        <div>
                            <span title="Close Preview" onClick={() => setOpenPreview(false)}>Close</span>
                            <span onClick={() => handleCreatePoll()}>Next</span>
                        </div>
                    </div>
                    {details.title && <div className={classes.card}>
                        <p>{details.title}</p>
                        {details.options.filter(obj => obj.label).map(option => (
                            <Button variant="outlined" color="primary">
                                {option.label}
                            </Button>
                        ))}
                        <span>0 votes, </span><span> {details.duration?.id} left</span>
                    </div>}

                </div>
            }
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    createPoll: (options) => dispatch(createPoll(options)),
    setLoading: (value) => dispatch(setLoading(value)),
});

export default connect(null, mapDispatchToProps)(CreatePoll);