import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, useMediaQuery, useTheme, IconButton, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { setLoading } from '../../ducks/loading';
import { sendMessage } from '../../ducks/messages';
import { requestPushNotification } from '../../ducks/notification';
import { convertUnicode } from '../../helpers';
import { openShareMeetingDialog } from '../../ducks/meetings';
import ChaseOnsAutocomplete from '../Arena/ChaseOnsAutocomplete';

const useShareMeetingToChatTemplateStyles = makeStyles((theme) => ({
    root: {
        border: '1px solid #dedede',
        padding: 11,
        fontSize: 14,
        color: '#535353'
    },
    text: {
        marginTop: 5
    },
    link: {
        color: "#112386",
        textDecoration: "none",
        marginTop: "12px",
        fontSize: "12px",
        lineHeight: "15px",
        flex: 1,
        //   paddingLeft: "0.6rem",
        paddingTop: "4px"
    },
}));

export const MeetingTemplate = ({ meetingUrl, meetingCode, meetingTitle, meetingId, onClick = () => { }, clickable = false }) => {
    const classes = useShareMeetingToChatTemplateStyles();
    return (
        <div className={classes.root} style={{ cursor: clickable ? 'pointer' : 'default' }} onClick={() => onClick()}>
            <p className={classes.text}>To Join the <span style={{ fontWeight: 600 }}>{meetingTitle}</span> meeting on chase meet, click this link: <span className={classes.link}>{meetingUrl}</span></p>
            <p className={classes.text}>or open chase meet and join with this code: <span className={classes.link}>{meetingCode}</span></p>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        minHeight: 470,
        borderRadius: 15,
        maxWidth: 825
    },
    dialogTitle: {
        padding: '21px 40px',
        fontSize: 22,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogContent: {
        padding: '13px 46px'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 10
    },
    content: {
        marginTop: 15
    },
    buttonRoot: {
        margin: '30px 0 20px',
        textAlign: 'center',
        '& button:nth-child(2)': {
            marginRight: 0
        }
    },
    button: {
        margin: '0 7px',
        width: 120,
        height: 43,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    templateRoot: {
        margin: '20px 0 90px'
    }
}))

const ShareMeetingToChat = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { shareMeetingToChat: { open, details }, openShareMeetingDialog, setLoading, sendMessage, currentUser, requestPushNotification } = props;
    const [selectedChaseOns, setSelectedChaseOns] = useState([]);

    console.log('shareMeetingToChat ', props);

    const handleClose = () => {
        setSelectedChaseOns([]);
        openShareMeetingDialog({ open: false, details: {} });
    }

    const handleSharePost = () => {
        let sampleMsg = {
            msgType: "meet",
            meetingLink: details.meetingUrl,
            meetingCode: details.meetingCode,
            meetingTitle: details.meetingTitle,
            meetingId: details.meetingId
        }
        let generatedMsg = `%%%${JSON.stringify(sampleMsg)}%%%`;
        selectedChaseOns.forEach(user => {
            setLoading(true);
            sendMessage({
                msg: {
                    to: `${user.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                    type: 'chat',
                    body: generatedMsg,
                    from: `${currentUser.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                },
                onMessageSentSuccess, onMessageSentFailed
            });
        });
    }

    const onMessageSentSuccess = (msgObj) => {
        // console.log('onMessageSentSuccess ', msgObj, targetDetails, currentUser);
        requestPushNotification({
            event_type: "new_message",
            members: [
                msgObj.to.split('@')[0]
            ],
            data: {
                body: msgObj.body,
                active: 'true',
                target_type: '0',
                targetJid: `${currentUser.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                name: currentUser.name,
                image_url: currentUser.photoUrl,
                sender: currentUser.name
            }
        });
        setLoading(false);
        handleClose();
        enqueueSnackbar("Meeting Shared successfully", {
            variant: "success",
            preventDuplicate: true
        });
    }
    const onMessageSentFailed = (data) => {
        console.log('onMessageSentFailed ', data);
        enqueueSnackbar('Failed to send Message.', {
            variant: 'error',
            preventDuplicate: true
        });
        setLoading(false);
        handleClose();
    }

    const handleChangeAutocomplete = (e, data) => {
        setSelectedChaseOns(data);
    }

    console.log('ShareToChatDialog ', details);
    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="share-arena-dialog"
                style={{ zIndex: 1301 }}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    Share Meeting in chat
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.content}>
                        <div className={classes.templateRoot}>
                            <MeetingTemplate meetingUrl={details.meetingUrl} meetingCode={details.meetingCode} meetingTitle={details.meetingTitle} meetingId={details.meetingId} />
                        </div>
                        <ChaseOnsAutocomplete openChaseons={true} inputLabelText="Share Meeting invite to" selectedChaseOns={selectedChaseOns} handleChangeAutocomplete={handleChangeAutocomplete} />
                        <div className={classes.buttonRoot} >
                            <Button variant="text" size="medium" className={classes.button} onClick={() => handleClose()}>Cancel</Button>
                            <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleSharePost()}>Send</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const mapStateToProps = (state) => ({
    shareMeetingToChat: state.meetings.shareMeetingToChat,
    currentUser: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        openShareMeetingDialog: (options) => dispatch(openShareMeetingDialog(options)),
        sendMessage: (msg) => dispatch(sendMessage(msg)),
        requestPushNotification: (msg) => dispatch(requestPushNotification(msg)),
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ShareMeetingToChat);