import { Tab, Tabs, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { historyPushWithSearch } from '../../helpers';
import { setLoading } from '../../ducks/loading';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import SeasonRulesList from './SeasonRulesList';
import SeasonPerformanceHealthList from './SeasonPerformanceHealthList';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fafafa',
        width: '100%',
        height: 'calc(100vh - 60px)',
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 80px)',
            maxWidth: `calc(100vw - 150px)`,
            padding: '20px 15px 0px',
        },
    },
    filtersRoot: {
        marginBottom: 15,
        padding: '20px 50px 0px',
        width: 'calc(100% - 100px)'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 5px !important',
        fontSize: 13,
        backgroundColor: '#FFF'
    },
    label: {
        fontSize: 13,
    },
    roundIconButton: {
        fontSize: 12,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: '0 4px',
        pointerEvents: 'auto !important'
    },
    roundIconButtonSelected: {
        color: 'black',
        backgroundColor: '#C4C4C4'
    },
    roundsLableStyle: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
        // color: '#0000008a'
    },
    avatarStyles: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 26,
        width: 26,
        border: '2px solid #FFF'
        // marginRight:5,
    },
    cardHeights: {
        maxWidth: '100%',
        // minHeight: '24vh',
        maxHeight: '60px',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            background: "#EEEEEE",
            borderBottom: `1px solid ${theme.palette.primary.main}`,

        }
        // minHeight: 150,
        // maxHeight: 153
    },
    vsStyles: {
        margin: '0px',
        fontSize: 12,
        // fontStyle: 'oblique',
        padding: '0px',
        // backgroundColor: '#c4c4c4',
        // borderRadius: 10,
        // height: 34,
        // width: 34,
        textAlign: 'center',
        color: '#0000008a'
    },
    teamName: {
        margin: 0,
        width: '75px',
        padding: 5,
        overflow: 'hidden',
        fontSize: '13px',
        // fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            // width: 100
        }
    },
    matchTitle: {
        fontSize: 14,
        margin: '5px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        color: '#000',
        [theme.breakpoints.down('xs')]: {
            margin: '5px 0',
            // display: 'inline-grid'
        }
    },
    matchView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #c4c4c48c',
        borderRadius: 30,
        cursor: 'pointer',
        maxWidth: 270,
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'black'
        }
    },
    selectedMatch: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    filedSetRootStyles: {
        padding: '10px 20px 20px',
    },
    legendRootStyles: {
        fontSize: 14
    },
    viewDetailsButton: {
        // margin: '15px 2px 0px 0',
        color: 'blue', // #112386
        fontSize: 12,
        // float: 'right',
        cursor: 'pointer',
        // fontWeight: 600,
        '&:hover': {
            textDecoration: 'underline',
        },
        '& svg': {
            fontSize: 18,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    },
    leagueFilter: {
        margin: '20px 0px 0px 0px',
        '& .MuiTab-textColorPrimary.Mui-selected': {
            background: theme.palette.primary.main,
            borderRadius: 6,
            color: 'white'
        }
    },
    profileTabsRoot: {
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: '4px',
        padding: 5,
        width: 'max-content',
        height: 'auto',
        minHeight: 'auto'
    },
    tabIndicatorProfile: {
        display: 'none'
    },
    profileTabRoot: {
        minWidth: 55,
        fontSize: 14,
        color: "#535353",
        textTransform: 'capitalize',
        minHeight: '30px',
        padding: '2px 10px'
    },
}));

const SeasonRuleTabs = [{ id: 'performanceHealth', name: 'Performance Health', value: 0 }, { id: 'scoringRules', name: 'Scoring Rule', value: 1 },]

const Rewards = ({ filtersData, location, history, }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [step, setStep] = useState('');
    const [subTab, setSubTab] = useState(0);

    useEffect(() => {
        if (location.pathname.split('/')[4] === 'scoringRules') {
            setSubTab(1);
        }
        else {
            setSubTab(0);
        }


    }, [location.pathname.split('/')[4]])

    const handleChangeTab = (event, newValue) => {
        if (newValue !== subTab) {
            setSubTab(newValue);
            setLoading(true);
            history.push(`${path}/${SeasonRuleTabs[newValue].id}${history.location?.search}`);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.livescores_new} style={{ padding: '0px 50px' }}>
                <div className={classes.leagueFilter}>
                    <Tabs
                        value={subTab}
                        onChange={(event, newValue) => handleChangeTab(event, newValue)}
                        textColor="primary"
                        aria-label="full width tabs example"
                        className={classes.profileTabsRoot}
                        classes={{ indicator: classes.tabIndicatorProfile }}
                    >
                        <Tab classes={{ root: classes.profileTabRoot }} label="Performance Health" />
                        <Tab classes={{ root: classes.profileTabRoot }} label="Scoring Rule" />
                    </Tabs>
                </div>
            </div>

            <div id='season-rules' style={{ width: 'calc(100% - 100px)', padding: '0 50px' }}>
                <Switch>
                    <Route path={`${path}/performanceHealth`} exact>
                        <SeasonPerformanceHealthList filtersData={filtersData} subTab={subTab} />
                    </Route >
                    <Route path={`${path}/scoringRules`} exact>
                        <SeasonRulesList filtersData={filtersData} subTab={subTab} />
                    </Route >
                    <Redirect to={{ pathname: `${path}/performanceHealth`, search: location.search }} />
                </Switch>
            </div >
        </div >


    )
}
const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rewards));