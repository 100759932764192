import React from 'react';
import { makeStyles } from '@material-ui/core';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import UserProfileLink from '../UserProfileCard/UserProfileLink';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px 6px',
        fontSize: 14
    },
    avatarStyles: {
        width: 32,
        height: 32,
        fontSize: 16
    },
    userLink: {
        fontWeight: 600,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
}));

const ArenaLikes = ({ firstLike = { username: '', name: '' }, likesCount = 0, onLikesClick = () => { }, ...others }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} {...others}>
            <AvatarComponent className={classes.avatarStyles} name={firstLike.name} username={firstLike.username} url={firstLike.photoUrl} />
            <span style={{ paddingLeft: 10 }}>Liked by <UserProfileLink username={firstLike.username} name={firstLike.name} /> {likesCount > 1 && <> and <span className={classes.userLink} onClick={onLikesClick}>{likesCount - 1}{`${(likesCount - 1) == 1 ? ' Other' : ' Others'}`}</span></>}</span>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

export default ArenaLikes;