import React from 'react';
import { connect } from "react-redux";
// import Notification from 'react-web-notification';

// import history from '../../history';

import './AppNotification.css';
import { setCurrentChat } from '../../ducks/chats';
import { setSidebarIndex } from '../../ducks/sidebar';

class AppNotification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ignore: true
        };
    }

    render() {
        return (
            <div className="AppNotification">
                {/* <Notification
                    ignore={this.state.ignore && this.props.notification.title !== ''}
                    notSupported={this.handleNotSupported.bind(this)}
                    onPermissionGranted={this.handlePermissionGranted.bind(this)}
                    onPermissionDenied={this.handlePermissionDenied.bind(this)}
                    onShow={this.handleNotificationOnShow.bind(this)}
                    onClose={this.handleNotificationOnClose.bind(this)}
                    onClick={this.handleNotificationOnClick.bind(this)}
                    onError={this.handleNotificationOnError.bind(this)}
                    timeout={5000}
                    title={this.props.notification.title}
                    options={this.buildNotificationOptions()}
                />
                <audio id='notificationSound' preload='auto'>
                    <source src='/assets/sounds/pop.mp3' type='audio/mpeg' />
                </audio> */}
            </div>
        )
    }


    handleNotificationOnClose(e, tag) {
        console.log(tag, 'ignore ', this.state.ignore);
    }

    handlePermissionGranted() {
        console.log('AppNotification 1');
        this.setState({
            ignore: false
        });
    }

    handlePermissionDenied() {
        console.log('AppNotification 2');
        this.setState({
            ignore: true
        });
    }

    handleNotSupported() {
        console.log('AppNotification 3');
        this.setState({
            ignore: true
        });
    }

    handleNotificationOnClick(e, tag) {
        console.log('AppNotification 4');
        // Navigate to room
        // history.push('chats')
        // this.props.history.push(`chats`);
        window.focus();
        this.props.setCurrentChat({ targetJid: this.props.notification.targetJid })
        this.props.setSidebarIndex(0)
    }

    handleNotificationOnError(e, tag) {
        console.log('AppNotification 5');
        console.log(e, 'Notification error tag:' + tag);
    }

    handleNotificationOnShow(e, tag) {
        console.log('AppNotification 6');
        this.playSound();
    }

    // Workaround for lack of Notification.sound support
    playSound(filename) {
        console.log('AppNotification 7');
        document.getElementById('notificationSound').play();
    }

    // TODO make the icon variable based on notification type eg mention
    buildNotificationOptions() {
        console.log('AppNotification 88', this.props, this.state.ignore);
        return {
            body: this.props.notification.body,
            tag: this.props.notification.tag,
            icon: '/assets/images/Chase-Ex.jpg',
            lang: 'en',
            dir: 'ltr',
            sound: '/assets/sounds/pop.mp3'
        }
    }

};

const mapStateToProps = (state, props) => ({
    notification: state.notification
});

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentChat: (options) => dispatch(setCurrentChat(options)),
        setSidebarIndex: (options) => dispatch(setSidebarIndex(options))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNotification);