import React, { useEffect, useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { ReactComponent as GalleryPost } from "../../assets/icons/gallery.svg";
import { ReactComponent as TextPost } from "../../assets/icons/text-post.svg";
import { ReactComponent as CamPost } from "../../assets/icons/camera.svg";
import { ReactComponent as AudioPost } from "../../assets/icons/mic.svg";
import { ReactComponent as Poll } from '../../assets/icons/survey-1.svg'
import { LinkOutlined, PictureAsPdfOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#F1F2F5',
        position: 'relative',
        color: '#262626',
        // display: 'flex',
        // minHeight: 500,
        // minHeight: 'calc(100vh - 64px)',
        // maxWidth: 600,
        maxHeight: 'calc(100vh - 64px)',
        // width: '70%',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            maxHeight: '100vh',
            // width: '100% !important',
            // maxWidth: 400,
            // minHeight: 400,
            // minHeight: 'calc(100vh - 64px)',
        },
        [theme.breakpoints.down('xs')]: {
            // maxHeight: 400,
            // minHeight: '100vh'
        },
    },
    titleRoot: {
        marginRight: 5,
        backgroundColor: '#FFF',
        fontSize: 18,
        color: '#262626',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& p': {
            margin: '0px 50px'
        }
    },
    contentRoot: {
        textAlign: 'center',
        padding: '50px 20px 90px',
        '& .heading': {
            fontSize: 22,
            margin: '0 0 30px   '
        }
    },
    actionsRoot: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    actionItem: {
        margin: '0px 20px 20px',
        width: 100,
        '& button': {
            backgroundColor: '#FFF',
            padding: 25,
            width: 74,
            height: 74
        },
        '& p': {
            fontSize: 14
        }
    },
    disabled: {
        opacity: 0.5,
        '& button': {
            backgroundColor: '#FFF !important'
        },
    }
}));

const PostTypeSelection = ({ handleClick }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {

    }, []);

    // console.log('uploadedAttachments ', uploadedAttachments);
    return (
        <div className={classes.root}>
            <div className={classes.titleRoot}>
                <p>Create a New Post</p>
            </div>
            <div className={classes.contentRoot}>
                <p className="heading">Start Creating your new post</p>
                <div className={classes.actionsRoot} >
                    <div className={classes.actionItem}>
                        <IconButton onClick={() => handleClick('TEXT')}  ><TextPost /></IconButton>
                        <p>Type Text</p>
                    </div>
                    <div className={classes.actionItem}>
                        <IconButton onClick={() => handleClick('POLL')}  ><Poll /></IconButton>
                        <p>Create Poll</p>
                    </div>
                    <div className={classes.actionItem}>
                        <IconButton onClick={() => handleClick('IMAGE')}  ><GalleryPost /></IconButton>
                        <p>Upload Image/Video</p>
                    </div>
                    <div className={classes.actionItem}>
                        <IconButton onClick={() => handleClick('LINK')}  ><LinkOutlined /></IconButton>
                        <p>Link</p>
                    </div>
                    <div className={classes.actionItem}>
                        <IconButton onClick={() => handleClick('PDF')} ><PictureAsPdfOutlined /></IconButton>
                        <p>PDF</p>
                    </div>
                    <div title="Under Development" className={classNames(classes.actionItem, classes.disabled)}>
                        <IconButton disabled onClick={() => handleClick('CAM')}  ><CamPost /></IconButton>
                        <p>Capture Photo/Video</p>
                    </div>
                    {/* <div title="Under Development" className={classNames(classes.actionItem, classes.disabled)}>
                        <IconButton disabled onClick={() => handleClick('AUDIO')}  ><AudioPost width={20} /></IconButton>
                        <p>Record a Podcast</p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default PostTypeSelection;