import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux'
import Posts from './Posts';
import Chaseons from './Chaseons';
import { setLoading } from '../../ducks/loading';
import { setArenaSearchQuery, searchArenaChaseonsOrPosts } from '../../ducks/search';
import { withRouter } from 'react-router-dom';
import Media from './Media';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: "1rem"
  },
  root: {
    background: "#ffffff",
  },
  box: {
    margin: '15px 25px 0',
    borderRadius: 6,
    backgroundColor: "#f7f7f7",
    color: "#535353",
    fontWeight: 600,
    fontSize: 14,
  },
  text: {
    padding: 14
  },
  break: {
    height: "10px",
    backgroundColor: "#f5f5f5",
    // width: "600px",
    marginBottom: "1rem"
  },
}));

const All = (props) => {
  const classes = useStyles();

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    console.log('useEffect All ', urlParams.has('q'), urlParams.get('q'), props);
    if (urlParams.has('q'))
      props.setArenaSearchQuery(urlParams.get('q'));
  }, []);

  return (
    <div className={classes.root} >
      <div className={classes.header}>
        <div className={classes.box}>
          <p className={classes.text}>Chaseons</p>
        </div>
        <Chaseons />
        <div className={classes.break}></div>
        <div className={classes.box}>
          <p className={classes.text}>Photos</p>
        </div>
        <Media mediaType={'IMAGE'} />
        <div className={classes.break}></div>
        <div className={classes.box}>
          <p className={classes.text}>Videos</p>
        </div>
        <Media mediaType={'VIDEO'} />
        <div className={classes.break}></div>
        <div className={classes.box}>
          <p className={classes.text}>Posts</p>
        </div>
        <Posts newPost={props.newPost} handleOnNewPost={props.handleOnNewPost} />
        <div className={classes.break}></div>
      </div>
    </div>
  )

}

const mapStateToProps = (state) => ({
  search: state.search.arena
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    setLoading: (value) => dispatch(setLoading(value)),
    searchArenaChaseonsOrPosts: value => dispatch(searchArenaChaseonsOrPosts(value)),
    setArenaSearchQuery: value => dispatch(setArenaSearchQuery(value)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(All));
