// FieldSet.js
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 1302,
        color: theme.palette.primary.main,
        // position: 'absolute',
        backgroundColor: '#ffffffcc'
    }
}));

const Loading = ({ loading }) => {
    const classes = useStyles();
    return (
        <>
            {loading && <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>}
        </>
    );
};

const mapStateToProps = (state) => ({
    loading: state.loading
});

export default connect(mapStateToProps, null)(Loading);