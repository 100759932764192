import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, makeStyles, IconButton } from "@material-ui/core";
import { Repeat, PlayCircleOutline, CallSplitOutlined } from "@material-ui/icons";
// import { ArenaPreview } from "../Arena/ArenaPreview";
import { useSnackbar } from "notistack";
// import { searchArenaMedia } from "../../ducks/search";
import Skeleton from "@material-ui/lab/Skeleton";
import { getMediaList } from "../../ducks/user";
import { ArenaPreview } from "../Arena/ArenaPreview";
import keycloak from "../../ducks/keycloak";
import { withRouter } from "react-router-dom";
// import { getSearchArenaList } from "../../ducks/search";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ffffff",
    // minHeight: "100vh",
    paddingTop: 15,
  },
  attachmentsRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  attachment: {
    width: 110,
    height: 90,
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    textAlign: "center",
    border: "1px solid #EEEEEE",
    position: "relative",
    margin: "20px 15px 10px",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.5,
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      height: "100%",
      // borderRadius: 7
    },
  },
  videoStyles: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#828282",
    position: "relative",
    textAlign: "center",
    color: "#fff",
    overflow: "hidden",
    // borderRadius: 7,
    "&:hover": {
      cursor: "pointer",
    },
    "& button": {
      position: "absolute",
    },
    "& img": {
      maxWidth: "initial",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100% !important",
      },
    },
  },
  playIcon: {
    color: "#fff",
  },
  seeMoreActionRoot: {
    textAlign: "center",
    margin: "15px 0 30px",
    "& button": {
      color: "#535353",
      borderColor: "#535353",
      maxWidth: 300,
    },
  },
  emptyResultText: {
    textAlign: "center",
    color: "#535353cc",
    fontSize: 15,
    fontWeight: 600,
    margin: 0,
    padding: 40,
    lineHeight: "25px",
  },
}));

const defaultObj = {
  page: 0,
  limit: 20,
  totalCount: 0,
  data: [],
};

const Media = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [mediaData, setMediaData] = useState({data:[] });
  const [preview, setPreview] = useState({
    open: false,
    attachments: [],
  });
  useEffect(() => {
    getMediaListResult(defaultObj.limit, 0);
  }, []);

  const onMediaListSuccess = (result, headers) => {
    console.log("result", result,headers);
    if (parseInt(headers.page) === 0) {
      setMediaData((prevState) => ({
        ...prevState,
        page: parseInt(headers.page) + 1,
        totalCount: parseInt(headers.total_count),
        data: [...result],
      }));
    } else
      setMediaData((prevState) => ({
        ...prevState,
        page: parseInt(headers.page) + 1,
        totalCount: parseInt(headers.total_count),
        data: [...prevState.data, ...result],
      }));
    setSkeletonLoading(false);
  };
  const onMediaListError = (error) => {
    setSkeletonLoading(false);
    enqueueSnackbar(
      error.response && error.response.data
        ? error.response.data.error
        : `Failed to fetch Search Results.`,
      {
        variant: "error",
        preventDuplicate: true,
      }
    );
  };

  const handleSeeMore = () => {
   getMediaListResult(mediaData.limit, mediaData.page);
  };

  const getMediaListResult = (limit, page) => {
    setSkeletonLoading(true);
    props.getMediaList({
      params: {
        type: props.mediaType === "IMAGE" ? "IMAGE" :props.mediaType==="VIDEO"?"VIDEO": "",
        username: props.match.params.id,
        limit,
        page,
      },
      onMediaListSuccess,
      onMediaListError,
    });
  };

  const handlePreview = (attachments, attachmentIndex) => {
    setPreview({ open: true, attachments, attachmentIndex });
  };

  const handlePreviewClose = () => {
    setPreview({ open: false, attachments: [] });
  };

  return (
    <div className={classes.root}>
      <div className={classes.attachmentsRoot}>
        {mediaData.data.length > 0
          ? mediaData.data.map((item, index) => (
              <div
                key={index}
                className={classes.attachment}
                style={{ backgroundColor: "#f7f7f7" }}
                onClick={() => handlePreview(mediaData.data, index)}
              >
                {item.mimeType === "IMAGE" ? (
                  <img
                    alt={item.url.split("-").pop()}
                    src={item.url}
                  />
                )  : (
                  <div className={classes.videoStyles}>
                    <img
                      alt={
                        item.thumbnailUrl && item.thumbnailUrl.split("-").pop()
                      }
                      src={item.thumbnailUrl}
                    />
                    <IconButton>
                      <PlayCircleOutline
                        className={classes.playIcon}
                        fontSize="large"
                      />
                    </IconButton>
                  </div>
                )}
              </div>
            ))
          : !skeletonLoading && (
              <p className={classes.emptyResultText}>No result found...</p>
            )}
        {skeletonLoading &&
          [0, 1, 2, 4].map((i) => (
            <Skeleton
              key={i}
              variant="rect"
              style={{ margin: "20px 15px 10px" }}
              width={110}
              height={90}
            />
          ))}
      </div>
      <div className={classes.seeMoreActionRoot}>
        {console.log("mediaData",mediaData)}
        {mediaData.data.length < mediaData.totalCount && (
          <Button fullWidth variant="outlined" onClick={() => handleSeeMore()}>
            See More
          </Button>
        )}
      </div>
      {preview.open && (
        <ArenaPreview
          open={preview.open}
          attachmentIndex={preview.attachmentIndex}
          arena={{ attachments: preview.attachments }}
          handlePreviewClose={handlePreviewClose}
          isFromProfile={true}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.username,
  keycloak: state.keycloak.decoded_token,
});

const mapDispatchToProps = (dispatch) => ({
  getMediaList: (value) => dispatch(getMediaList(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Media));
