import React from 'react';
import { Card, makeStyles } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    cardRoot: {
        padding: '12px 16px',
        '&:hover': {
            backgroundColor: '#f1f0fd'
        }
    },
    cardRoot2: {
        // padding: '12px 16px',
        padding: '30px 20px 20px 20px',

    },
    cardRoot3: {
        padding: '20px 20px 20px 20px',
        boxShadow: '0px 0px 4px 1px rgba(162, 162, 162, 0.25)',
        borderRadius: '2px'
    },

    selectedCard: {
        padding: '12px 16px',
        backgroundColor: theme.palette.primary.light,
        border: '1px solid #0000001f',
        '& .MuiAvatar-root': {
            border: '1px solid'
        }
    },
    titleRoot: {
        margin: '0 0 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: 14,
        color: "#363636",
        fontWeight: 600
    },
    linkStyles: {
        margin: '0 0 0 10px',
        color: 'blue', // #112386
        fontSize: 11,
        float: 'right',
        cursor: 'pointer',
        // textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 10,
            marginLeft: 2,
            verticalAlign: 'middle'
        }
    },
}));

export const CustomCard = ({ cardTitle = "", linkText = "", onLinkClick, filterTabs, style, selected = false, onCardClick, linkStyles, children, from }) => {
    const classes = useStyles();

    return (
        <div style={style}>
            <p className={classes.titleRoot}><span className={classes.title}>{cardTitle}</span>{filterTabs && filterTabs}{linkText && <span onClick={onLinkClick} className={classes.linkStyles} style={linkStyles}>{linkText}<ArrowForwardIos /></span>}</p>
            <Card className={selected ? classes.selectedCard : (onCardClick ? classes.cardRoot : from === 'PWC' ? classes.cardRoot3 : classes.cardRoot2)} onClick={onCardClick ? onCardClick : () => { }} style={onCardClick && { cursor: 'pointer' }}>
                {children}
            </Card>
        </div>
    )
}