
import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core';
import { ReactComponent as PlayIcon } from "../../../assets/icons/play.svg";
const useStyles= makeStyles(theme=>({
  root:{
    width: "274px",
    height:" 190px",
    background:"#FFCD2E",
    borderRadius:"10px",
    margin:"0 auto"
  },
  title:{
    color:"#020202",
    fontSize:"22px",
    lineHeight:"22px",
    padding:"1rem 19px 0",
    fontWeight:"bolder"
  },
  time:{
    fontSize:"10px",
    lineHeight:"13px",
    color:"#000000",
    padding:"0 1rem 0.6rem",
    fontWeight:"bold"
  },
  activities:{
    color:"#020202",
    fontSize:"9px",
    display:"flex",
    lineHeight:"16px",
    justifyContent:"space-between",
    padding:"0 1rem",
    "& :nth-child(2)":{
      marginRight:"0.5rem"
    }
  },
  names:{
    color:"#000000",
    fontSize:"10px",
    display:"flex",
    lineHeight:"13px",
    justifyContent:"space-between",
    padding:"0 1rem",
    fontWeight:"bold",
  },
  hour:{
    color:"#535353",
    fontSize:"12px",
    lineHeight:"16px",
    display:"inline-block",
    paddingTop:"10px",
    fontWeight:"light"
  },
  playicon:{
     marginLeft:"6rem",
  }
 }))
const Chat = () => {
const classes=useStyles();
   
  return (
    <div className={classes.root}>
    <p className={classes.title}>Development Process</p>
       <IconButton className={classes.playicon}> <PlayIcon/></IconButton>
   
    <div className={classes.time}>
    2020-10-3 12:40 AM
    </div>
    <span className={classes.activities}>
      <span>Recorded By</span>
      <span>Organised by</span>
      <span></span>
    </span>
    <span className={classes.names}>
      <span>Ashish Gupta</span>
      <span>AshishGupta</span>
        <span className={classes.hour}>1hr</span>
    </span>
  
   
    </div>
  )
}

export default Chat
