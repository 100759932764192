import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { formattedValue } from '../../Leagues/MyPerformance';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        padding: '3px 8px'
    },
    card: {
        padding: '10px 8px',
        '& .card': {
            // width: 312,
            height: 92,
            background: '#FFFFFF',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 4,
            padding: '0px 5px',
        },
        '& .cardTitle': {
            fontSize: 14,
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.9)',
            margin: '6px 0px',
            fontWeight: 600,

        },
        '& .cardContent': {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 4,
            padding: '4px 8px',
            '& .values': {
                display: 'flex',
                alignItems: 'center',
                '& span': {
                    color: '#9f9f9fe6',
                    fontSize: 18,
                    padding: '0px 6px'
                },
                '& h1': {
                    color: 'rgba(0, 0, 0, 0.9)',
                    fontSize: 22,
                    margin: 0,
                    fontWeight: 500,
                    lineHeight: '38px'
                },
                '& h6': {
                    color: '#9f9f9fe6',
                    fontSize: 16,
                    margin: 0,
                    fontWeight: 500,
                }
            }
        },
    },
    cardHeader: {
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .titleHdr': {
            display: 'flex',
            alignItems: 'center',
            '& .rank': {
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 4,
                textAlign: 'center',
                padding: '2px 5px',
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 13,
                marginRight: 12,
                '& span': {
                    marginLeft: '-3px'
                }
            },
            '& p': {
                margin: 0
            }
        },
        '& .ColorCode': {
            margin: 0,
            width: 18,
            height: 18,
            borderRadius: 2,
        }
    },
    skeletonRoot: {
        padding: '10px 0px',
        height: 135,
        marginBottom: 150,
        "& .card": {
            bordeRadius: 8,
            padding: '10px 8px',
            borderRadius: 4,
        },
    },
}));


const PlayerPerformanceCard = ({ userDetails, playerDetails }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} >
            <div className={classes.card}>
                <Grid container spacing={1}>
                    {
                        playerDetails?.kpis?.map((item, index) =>
                            <Grid key={index} item lg={3} md={6} sm={6} xs={12}>
                                <Card className='card' variant="outlined">
                                    <div className={classes.cardHeader}>
                                        <div className="titleHdr">
                                            {item?.rank &&
                                                <div className="rank">
                                                    {item.rank} <span>{item?.rank === 1 ? 'st' : item?.rank === 2 ? 'nd' : item?.rank === 3 ? 'rd' : item?.rank > 3 ? 'th' : null}</span>
                                                </div>
                                            }
                                            <Typography className='cardTitle'> {item.kpi_name}</Typography>
                                        </div>
                                        <div className="ColorCode" style={{ background: `${item?.status_code}` }}>
                                        </div>
                                    </div>
                                    <CardContent className="cardContent">
                                        <div className='values' >
                                            <h1>{formattedValue(userDetails, item.actual, item)}</h1>
                                            <span>of</span><h6>{formattedValue(userDetails, item.target, item)}</h6>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    }
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
})

const mapDispatchToProps = (dispatch) => {
    return {
        // setLoading: value => dispatch(setLoading(value)),
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerPerformanceCard));
