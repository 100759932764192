// import orderBy from "lodash/orderBy";
// import differenceBy from "lodash/differenceBy";

import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/contacts");

export const SET_CURRENT = constant("SET_CURRENT");

// export const TEAMS = constant("TEAMS");
export const CONTACTS = constant("CONTACTS");
export const GET_CONTACTS = constant("GET_CONTACTS");
export const CONTACT_PRESENCE = constant("CONTACT_PRESENCE_AVAILABLE");
export const CONTACT_PRESENCE_UNAVAILABLE = constant("CONTACT_PRESENCE_UNAVAILABLE");

export const receivedContacts = contactsList => ({
    type: CONTACTS,
    payload: contactsList
});

export const getContacts = teamsList => ({
    type: GET_CONTACTS,
    payload: teamsList
});


export const receivedContactPresence = presence => ({
    type: CONTACT_PRESENCE,
    payload: presence
});

export const receivedContactPresenceUnavailable = presence => ({
    type: CONTACT_PRESENCE_UNAVAILABLE,
    payload: presence
});

export const setCurrent = (chatDetails) => ({
    type: SET_CURRENT,
    payload: chatDetails
})

const initialState = {};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {

        // case TEAMS : {
        //     return {...action.payload, ...state};
        // }

        case CONTACTS: {
            return { ...state, ...action.payload };
        }

        case SET_CURRENT: {
            const jid = action.payload;
            let newState = state;
            for (const key in newState) {
                if (newState.hasOwnProperty(key)) {
                    const element = newState[key];
                    if (key === jid) {
                        element.isCurrent = true;
                    }
                    else {
                        element.isCurrent = false;
                    }
                }
            }
            return { ...newState };
        }

        case CONTACT_PRESENCE: {

            const presence = action.payload;
            const peerJid = presence.from.split('/')[0];
            const peer = state[peerJid] || {
                chat_id: peerJid
            };

            let show = presence.type;
            if (presence.show) {
                show = (presence.show === 'xa') ? 'away' : (presence.show === 'chat') ? 'available' : presence.show;
            }


            let status = "";
            if (presence.status) {
                status = presence.status;
                if (!presence.show) {
                    show = presence.status === 'Online' ? 'available' : presence.status === 'Away' ? 'away' : presence.status === 'Busy' ? 'dnd' : 'unavailable'
                }
            }

            if (!presence.status && !presence.type && !presence.show) {
                show = 'available'
            }

            return {
                ...state,
                [peer.chat_id]: {
                    ...peer,
                    show: show,
                    status: status
                }
            };


        }

        case CONTACT_PRESENCE_UNAVAILABLE: {

            const presence = action.payload;
            const peerJid = presence.from.bare;
            const peer = state[peerJid];

            let currentMembers = [];
            if (peer.members !== undefined) {
                currentMembers = peer.members;
            }

            let remainingMembers = currentMembers.filter((member) => {
                return member.resource !== presence.from.resource;
            });

            return {
                ...state,
                [peer.jid]: {
                    ...peer,
                    members: remainingMembers
                }
            };

        }

        default:
            return state;
    }
};
