import React, { useState, useEffect } from "react";
import { Link, Breadcrumbs, Tooltip } from "@material-ui/core";
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BugReportIcon from '@material-ui/icons/BugReport';

const useStyles = makeStyles((theme) => ({
  breadcrumbsRoot: {
    marginTop: 2
  },
  breadcrumbsActive: {
    color: "#58595B",
    fontSize: 12,
    margin: "3px 0 0"
  },
  breadcrumbsLink: {
    color: theme.palette.primary.main,
    fontSize: 12,
    textDecoration: "none"
  },
  issueTypeTask: {
    fontSize: "small",
    color: "#0377fc",
    margin: "0px 5px -3px 0px"
  },
  issueTypeStory: {
    fontSize: "small",
    color: "green",
    margin: "0px 5px -3px 0px"
  },
  priorityHigh: {
    fontSize: "small",
    color: "#ff4d4d",
    margin: "0px 5px -3px 0px"
  }
}));

const Breadcrumb = (props) => {
  let classes = useStyles();

  const getStoryLogos = (type) => {
    switch (type) {
      case 'Story':
        return <Tooltip title="Story"><BookmarkIcon className={classes.issueTypeStory} /></Tooltip>
      case 'Task':
        return <Tooltip title="Task"><AssignmentTurnedInIcon className={classes.issueTypeTask} /></Tooltip>
      default:
        return <Tooltip title="Bug"><BugReportIcon className={classes.priorityHigh} /></Tooltip>
    }
  }

  const handleClick = (page) => {
    const route = { ...props.params }
    console.log(props)
    if (page === 'project') {
      props.history.push('/product-management')
    }
    else if (page === 'board') {
      props.history.push(`/product-management/${route.id}/board`)
    }
    else if (page === 'taksdetails') {
      props.history.push(`/product-management/${route.id}/board/${route.taskid}`)
    }
  }
  if (props.component === 'projects') {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsRoot}>
        <Link className={classes.breadcrumbsLink} style={{ color: "inherit", pointerEvents: 'none' }} color="inherit" >Boards </Link>
      </Breadcrumbs>
    )
  }
  else if (props.component === 'board') {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsRoot}>
        <Link className={classes.breadcrumbsLink} style={{ cursor: 'pointer' }} color="inherit" onClick={() => handleClick('project')}>Boards</Link>
        <Link className={classes.breadcrumbsLink} style={{ color: "inherit", pointerEvents: 'none' }} color="inherit" href="/" >{props.projectName}</Link>
      </Breadcrumbs>
    )
  }
  else if (!props.isSubTask) {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsRoot}>
        <Link className={classes.breadcrumbsLink} style={{ cursor: 'pointer' }} color="inherit" onClick={() => handleClick('project')}>Boards</Link>
        <Link className={classes.breadcrumbsLink} style={{ cursor: 'pointer' }} color="inherit" onClick={() => handleClick('board')} >{props.projectName}</Link>
        {/* <Link className={classes.breadcrumbsLink} style={{color:"inherit",pointerEvents:'none'}} >Tasks</Link> */}
        <Link className={classes.breadcrumbsLink} style={{ color: "inherit", pointerEvents: 'none' }}  >
          <span >
            <span>{getStoryLogos(props.type)}</span>{props.params.taskid.toUpperCase()}</span>
        </Link>
      </Breadcrumbs>
    )
  }
  else {
    return (
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsRoot}>
        <Link className={classes.breadcrumbsLink} style={{ cursor: 'pointer' }} color="inherit" onClick={() => handleClick('project')}>Boards</Link>
        <Link className={classes.breadcrumbsLink} style={{ cursor: 'pointer' }} color="inherit" onClick={() => handleClick('board')} >{props.projectName}</Link>
        {/* <Link className={classes.breadcrumbsLink} style={{color:"inherit",pointerEvents:'none'}} >Tasks</Link> */}
        <Link className={classes.breadcrumbsLink} style={{ cursor: 'pointer' }} color="inherit" onClick={() => handleClick('taksdetails')} >
          {
            <span >
              <span>{getStoryLogos(props.type)}</span>
              {props.params.taskid.toUpperCase()}
            </span>
          }
        </Link>
        {/* <Link className={classes.breadcrumbsLink} style={{color:"inherit",pointerEvents:'none'}} >Subtasks</Link> */}
        <Link className={classes.breadcrumbsLink} style={{ pointerEvents: 'none', color: "inherit" }} onClick={() => handleClick('taksdetails')} >
          {/* {
            <span >
              <span>{getStoryLogos(props.type)}</span>
              {props.params.subTaskId.toUpperCase()}
            </span>
          } */}
          {props.params.subTaskId.toUpperCase()}
        </Link>
      </Breadcrumbs>
    )
  }
}
const mapStateToProps = (state) => ({
  project: state.pms.project
})
const mapDispatchToProps = (dispatch) => {
  return {

  }
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Breadcrumb));
