import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLoading } from '../../ducks/loading';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { getMyTeamLivePerformance, getMyLivePerformance } from '../../ducks/game';
import { useSnackbar } from 'notistack';
import { getNumberFormat } from '../../helpers';


const useStyles = makeStyles((theme) => ({
    teamCard: {
        border: '1px solid transparent',
        // borderRadius: 6,
        minWidth: 200,
        margin: '24px 0',
        backgroundColor: '#FFF',
        '& .title': {
            margin: 10,
            fontSize: 13,
            fontWeight: 600,
            color: '#0000008f'
        }
    },
    imageRoot: {
        margin: '0 5px',
        // backgroundColor: theme.palette.primary.light,
        background: 'linear-gradient(181.02deg, rgba(255, 255, 255, 0.97) 0.88%, rgba(255, 255, 255, 0) 129.36%), linear-gradient(0deg, #F0F2F5, #F0F2F5)',
        border: '1px solid #bcbcbd00',
        borderRadius: 4,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between',
        padding: 8,
        '& .name': {
            fontSize: 15,
            margin: 0,
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: '#535353'
        },
        '& .role': {
            margin: 0,
            fontSize: 12,
            color: '#535353'
        }
    },
    teamAvatar: {
        fontSize: 20,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 56,
        width: 56,
        border: '2px solid #FFF',
        // marginRight: 8
    },
    kpisRoot: {
        margin: '5px 0 45px',
        '& .kpi': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
            borderBottom: '1px solid #bcbcbd40',
            color: '#535353',
            fontSize: 12
        },
        '& p': {
            margin: 0,
            color: '#7589a1',
            '&:last-child': {
                color: '#000000c7',
                fontWeight: 600
            }
        },

    }
}));


const MyPerformanceCards = ({ filtersData, userDetails, getMyLivePerformance, getMyTeamLivePerformance, setLoading }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [teamDetails, setTeamDetails] = useState({});
    const [playerDetails, setPlayerDetails] = useState({});

    useEffect(() => {
        if (filtersData.season?._id) {
            setLoading(true);
            getMyTeamLivePerformance({
                seasonId: filtersData.season?._id,
                params: { round_id: filtersData.round?._id },
                onSuccessCB: (result) => {
                    console.log('result ', result);
                    setTeamDetails(result[0]);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch My team performance', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                }
            });
            getMyLivePerformance({
                seasonId: filtersData.season?._id,
                params: { round_id: filtersData.round?._id },
                onSuccessCB: (result) => {
                    setLoading(false);
                    console.log('result ', result);
                    setPlayerDetails(result[0]);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch My performance', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                }
            });
        }
    }, [filtersData.round]);

    const KpiCard = ({ kpi }) => (
        <div className="kpi">
            <p>{kpi.kpi_name}</p>
            <p>{getNumberFormat(userDetails?.countryCode, kpi.actual)}<span style={{ fontWeight: 500, fontSize: 10 }}>/{getNumberFormat(userDetails?.countryCode, kpi.target)} ({getNumberFormat(userDetails?.countryCode, kpi.achieved)}%)</span></p>
        </div>

    )
    return (
        <div >
            <div className={classes.teamCard}>
                <p className="title">My Team Performance</p>
                <div className={classes.imageRoot}>
                    <AvatarComponent name={teamDetails.name} username={teamDetails.team_id} url={teamDetails.logo_url} className={classes.teamAvatar} />
                    <p className="name" style={{ margin: '0 0 0 8px' }}>{teamDetails.name}</p>
                </div>
                <div className={classes.kpisRoot}>
                    <div className="kpi"><p>Position in round</p><p>{teamDetails.my_position}</p></div>
                    <div className="kpi"><p>Overall rank</p><p>{teamDetails.rank}</p></div>
                    {teamDetails.kpis?.map((kpi, i) =>
                        <KpiCard key={i} kpi={kpi} />
                    )}
                </div>
            </div>
            <div className={classes.teamCard}>
                <p className="title">My Performance</p>
                <div className={classes.imageRoot}>
                    <AvatarComponent from='PLAYER' type="PLAYER" name={playerDetails.player_name} username={playerDetails.employee_id} url={playerDetails.player_photo_url} className={classes.teamAvatar} filtersData={filtersData} />
                    <div style={{ margin: '0 0 0 8px' }}>
                        <p className="name">{playerDetails.player_name}</p>
                        <p className="role">Role: <span style={{ color: '#000' }}>{playerDetails.role}</span></p>
                    </div>
                </div>
                <div className={classes.kpisRoot}>
                    <div className="kpi"><p>Team</p><p>{playerDetails.team_name}</p></div>
                    <div className="kpi"><p>Position in round</p><p>{playerDetails.my_position}</p></div>
                    <div className="kpi"><p>Overall rank</p><p>{playerDetails.rank}</p></div>
                    {playerDetails.kpis?.map((kpi, i) =>
                        <KpiCard key={i} kpi={kpi} />
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (options) => dispatch(setLoading(options)),
        getMyTeamLivePerformance: (options) => dispatch(getMyTeamLivePerformance(options)),
        getMyLivePerformance: (options) => dispatch(getMyLivePerformance(options))
    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyPerformanceCards));