import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, useMediaQuery, useTheme, IconButton, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { openShareToChatDialog } from '../../ducks/arena';
import { setLoading } from '../../ducks/loading';
import ChaseOnsAutocomplete from './ChaseOnsAutocomplete';
import { sendMessage } from '../../ducks/messages';
import { requestPushNotification } from '../../ducks/notification';
import { convertUnicode } from '../../helpers';
import noPreview from '../../assets/images/nopreview.png';

const useSendToChatTemplateStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        border: '1px solid #dedede',
        padding: 11,
        fontSize: 14,
        color: '#535353'
    },
    attachment: {
        height: 90,
        width: 90,
        minWidth: 90,
        textAlign: 'center',
        backgroundColor: '#f7f7f7',
        border: '1px solid #dedede',
        marginRight: 15,
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
            height: '100%'
        }
    },
    text: {
        margin: '5px 0'
    },
    postedBy: {
        fontWeight: 600
    }
}));

export const SendToChatTemplate = ({ photoUrl, text, postedBy, textLimit, onClick = () => { }, clickable = false, quotedTemplate = false }) => {
    const classes = useSendToChatTemplateStyles();
    return (
        <div className={classes.root} style={{ display: text ? 'flex' : 'block', cursor: clickable ? 'pointer' : 'default', padding: quotedTemplate && 0, border: quotedTemplate && 'none' }} onClick={() => onClick()}>
            {photoUrl &&
                <div className={classes.attachment} style={quotedTemplate ? { width: 50, height: 50, minWidth: 50 } : {}}>
                    <img src={photoUrl} />
                </div>
            }
            <div>
                <p className={classes.text}>{(text && text.length > textLimit) ? convertUnicode(text.substring(0, textLimit) || '') + '...' : convertUnicode(text || '')}</p>
                <p style={quotedTemplate ? { margin: '10px 0 5px' } : {}}>Post by: <span className={classes.postedBy}>{postedBy}</span></p>
                {/* {!quotedTemplate && <p style={quotedTemplate ? { marginBottom: 0 } : {}}>Post by: <span className={classes.postedBy}>{postedBy}</span></p>} */}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        minHeight: 470,
        borderRadius: 15,
        maxWidth: 825
    },
    dialogTitle: {
        padding: '21px 40px',
        fontSize: 22,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogContent: {
        padding: '13px 46px'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 10
    },
    content: {
        marginTop: 15
    },
    buttonRoot: {
        margin: '30px 0 20px',
        textAlign: 'center',
        '& button:nth-child(2)': {
            marginRight: 0
        }
    },
    button: {
        margin: '0 7px',
        width: 120,
        height: 43,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    templateRoot: {
        margin: '20px 0 90px'
    }
}))

const ShareToChatDialog = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { shareToChat: { open, details }, openShareToChatDialog, setLoading, sendMessage, currentUser, requestPushNotification, history } = props;
    const [selectedChaseOns, setSelectedChaseOns] = useState([]);


    const handleClose = () => {
        setSelectedChaseOns([]);
        openShareToChatDialog({ open: false, details: {} });
    }

    const handleSharePost = () => {
        let sampleMsg = {
            msgType: "post",
            text: details.text,
            thumbnailUrl: (details.attachments && details.attachments[0]) ? ((details.attachments[0].mimeType === 'VIDEO' || details.attachments[0].mimeType === 'LINK') ? details.attachments[0].thumbnailUrl : details.attachments[0].mimeType === 'PDF' ? details.attachments[0].thumbnailUrl : details.attachments[0].attachmentUrl) : "",
            createdBy: details.createdBy.name,
            postId: details.postId
        }
        let generatedMsg = `%%%${JSON.stringify(sampleMsg)}%%%`;
        selectedChaseOns.forEach(user => {
            setLoading(true);
            sendMessage({
                msg: {
                    to: `${user.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                    type: 'chat',
                    body: generatedMsg,
                    from: `${currentUser.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                },
                onMessageSentSuccess, onMessageSentFailed
            });
        });
    }

    const onMessageSentSuccess = (msgObj) => {
        // console.log('onMessageSentSuccess ', msgObj, targetDetails, currentUser);
        enqueueSnackbar(`Post Shared successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        requestPushNotification({
            event_type: "new_message",
            members: [
                msgObj.to.split('@')[0]
            ],
            data: {
                body: msgObj.body,
                active: 'true',
                target_type: '0',
                targetJid: `${currentUser.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                name: currentUser.name,
                image_url: currentUser.photoUrl,
                sender: currentUser.name
            }
        });
        setLoading(false);
        handleClose();
    }
    const onMessageSentFailed = (data) => {
        console.log('onMessageSentFailed ', data);
        enqueueSnackbar('Failed to send Message.', {
            variant: 'error',
            preventDuplicate: true
        });
        setLoading(false);
        handleClose();
    }

    const handleChangeAutocomplete = (e, data) => {
        setSelectedChaseOns(data);
    }

    console.log('ShareToChatDialog ', details);
    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="share-arena-dialog"
                style={{ zIndex: 1301 }}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    Send in chat
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.content}>
                        <div className={classes.templateRoot}>
                            <SendToChatTemplate
                                photoUrl={(details.attachments && details.attachments[0]) && ((details.attachments[0].mimeType === 'VIDEO') ? details.attachments[0].thumbnailUrl : details.attachments[0].mimeType === 'LINK' ? details.attachments[0].thumbnailUrl ? details.attachments[0].thumbnailUrl : noPreview : details.attachments[0].mimeType === 'PDF' ? details.attachments[0].thumbnailUrl : details.attachments[0].attachmentUrl)}
                                text={details.text}
                                postedBy={details.createdBy ? details.createdBy.name : ''}
                                textLimit={250}
                            />
                        </div>
                        <ChaseOnsAutocomplete openChaseons={true} inputLabelText="Send it to" selectedChaseOns={selectedChaseOns} handleChangeAutocomplete={handleChangeAutocomplete} />
                        <div className={classes.buttonRoot} >
                            <Button variant="text" size="medium" className={classes.button} onClick={() => handleClose()}>Cancel</Button>
                            <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleSharePost()}>Send</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const mapStateToProps = (state) => ({
    shareToChat: state.arena.shareToChat,
    currentUser: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        openShareToChatDialog: (options) => dispatch(openShareToChatDialog(options)),
        sendMessage: (msg) => dispatch(sendMessage(msg)),
        requestPushNotification: (msg) => dispatch(requestPushNotification(msg)),
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ShareToChatDialog);