import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFollowingUserList, getFollowUserList, getMutualFollowingUserList } from '../../ducks/favourites';
import { getMediaList } from '../../ducks/user';
import { getEllipsisText } from '../../helpers';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 16px',
        backgroundColor: '#FFF',
        borderRadius: 8,
    },
    avatarStyles: {
        width: 58,
        height: 58,
        fontSize: 20,
        margin: 'auto'
    },
    avatarRoot: {
        background: 'linear-gradient(181.02deg, rgba(255, 255, 255, 0.97) 0.88%, rgba(255, 255, 255, 0) 129.36%), linear-gradient(0deg, #F0F2F5, #F0F2F5)',
        margin: '0px -10px',
        paddingBottom: 4
    },
    profileName: {
        fontSize: 14,
        fontWeight: 600,
        cursor: 'pointer',
        margin: '12px 0px 3px',
        color: '#262626',
        wordBreak: 'break-word',
        '&:hover': {
            // textDecoration: 'underline'
        }
    },
    designation: {
        fontSize: 10,
        color: '#2626266E',
        margin: 0
    },
    favouritesRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        '& div': {
            marginRight: 10,
            '& .name': {
                marginBottom: 0,
                fontSize: 12,
                color: '#262626',
            },
            '& .title': {
                margin: 0,
                fontSize: 10,
                color: '#2626266E',
            }
        },
    }
}));

const ViewShortProfile = ({ user, keycloak, getFollowUserList, getFollowingUserList, staticContext, ...others }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [followerCount, setFollowerCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);

    useEffect(() => {
        if (keycloak.preferred_username) {
            getFollowUserList({
                username: keycloak.preferred_username,
                params: { limit: 5, page: 0 },
                onFollowerUserListSuccess,
                onFollowerUserListError
            });
            getFollowingUserList({
                username: keycloak.preferred_username,
                params: { limit: 5, page: 0 },
                onFollowingUserListSuccess,
                onFollowingUserListError
            });
        }
    }, []);

    const onFollowingUserListSuccess = (result, headers) => {
        setFollowerCount(parseInt(headers.total_count))
    }
    const onFollowingUserListError = (error) => {
        setFollowerCount(0);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Favourites.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const onFollowerUserListSuccess = (result, headers) => {
        setFollowingCount(parseInt(headers.total_count))
    }
    const onFollowerUserListError = (error) => {
        setFollowingCount(0);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Favourites.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }
    return (
        <div className={classes.root} {...others}>
            <div className={classes.avatarRoot}>
                <AvatarComponent className={classes.avatarStyles} name={user.name} username={user.username} url={user.photo_url || user.photoUrl} />
            </div>
            <p className={classes.profileName} onClick={() => { }}>{user.name}</p>
            <p className={classes.designation}>{user.designation}</p>
            <div className={classes.favouritesRoot}>
                <div>
                    <p className="name">{followerCount}</p>
                    <p className="title">Followers</p>
                </div>
                <div>
                    <p className="name">{followingCount}</p>
                    <p className="title">Following</p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    keycloak: state.keycloak.decoded_token
});

const mapDispatchToProps = (dispatch) => {
    return {
        getFollowUserList: value => dispatch(getFollowUserList(value)),
        getFollowingUserList: value => dispatch(getFollowingUserList(value)),
        // getMutualFollowingUserList: (options) => dispatch(getMutualFollowingUserList(options)),
        // getMediaList: value => dispatch(getMediaList(value))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewShortProfile));