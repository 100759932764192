import React from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Equalizer } from '@material-ui/icons';
import OverallStats from './OverallStats';
import TeamStats from './TeamStats';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import PlayerStats from './PlayerStats';
import OverallTrends from './OverallTrends';
import TeamPerformanceTrends from './TeamPerformanceTrends';
import PlayerPerformanceTrends from './PlayerPerformanceTrends';
import { historyPushWithSearch } from '../../helpers';
import { CustomListGroup } from '../LiveScores/RoundsListView';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f0f2f5',
        // backgroundColor: '#FFF',
        width: '100%',
    },
    tabsRoot: {
        // position: 'fixed',
        top: 60,
        left: 0,
        backgroundColor: '#FFF',
        width: '100%',
        maxWidth: '100%',
        minHeight: 45,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
    },
    tabRoot: {
        minHeight: 45,
        textTransform: 'capitalize',
        color: '#262626 !important',
        minWidth: 'auto',
        margin: '0px 12px',
        padding: '6px 2px',
        [theme.breakpoints.up("sm")]: {
            // minWidth: 125
        }
    },
    flexContainerStyles: {
        // justifyContent: 'center',
        marginLeft: 34
    },
    scrollButtons: {
        // width: 25
    }
}))



const PerformanceStats = ({ type, history, location, filtersData, setFiltersData }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();

    const MenuOptions =
        filtersData.season?.season_format === 'HEAD_TO_HEAD' ?
            [
                { label: 'Overall', value: 0, icon: <Equalizer />, pathname: 'overall' },
                { label: 'Team', value: 1, icon: <Equalizer />, pathname: 'team' },
                { label: 'Player', value: 2, icon: <Equalizer />, pathname: 'player' },
            ]
            : filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints ?
                [
                    { label: 'Overall', value: 0, icon: <Equalizer />, pathname: 'overall' },
                    { label: 'Team', value: 1, icon: <Equalizer />, pathname: 'team' },
                    { label: 'Player', value: 2, icon: <Equalizer />, pathname: 'player' },
                ]
                : filtersData.season?.season_format === 'TEAM_RACE' ?
                    [
                        { label: 'Overall', value: 0, icon: <Equalizer />, pathname: 'overall' },
                        { label: 'Team', value: 1, icon: <Equalizer />, pathname: 'team' },
                        // { label: 'Player', value: 2, icon: <Equalizer />, pathname: 'player' },
                    ]
                    :
                    [
                        { label: 'Overall', value: 0, icon: <Equalizer />, pathname: 'overall' },
                        { label: 'Player', value: 1, icon: <Equalizer />, pathname: 'player' },
                    ]

    const handleTabChange = (newValue) => {
        historyPushWithSearch(`${path}/${MenuOptions[newValue?.value].pathname}`, history, location.search);
    }
    return (
        <div className={classes.root}>
            <div>
                {/* <Tabs
                    id="season-matches-tabs"
                    value={MenuOptions.map((option) => { return option.pathname }).indexOf(location.pathname.split('/')[3])}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabsRoot}
                    classes={{ flexContainer: classes.flexContainerStyles, scrollButtons: classes.scrollButtons }}
                >
                    {
                        [...MenuOptions].map((obj, i) =>
                            <Tab className={classes.tabRoot} key={i} label={obj.label} />
                        )}
                </Tabs> */}
                <CustomListGroup data={MenuOptions} selectedItem={MenuOptions.find((option) => option.pathname === location.pathname.split('/')[4])} onClick={handleTabChange} style={{ maxWidth: 'max-content' }} />
                <div id="performance-data" style={{ width: '100%' }}>
                    <Switch>
                        <Route path={`${path}/overall`} exact>
                            {
                                type === 'stats' ?
                                    <OverallStats filtersData={filtersData} setFiltersData={setFiltersData} />
                                    :
                                    <OverallTrends filtersData={filtersData} setFiltersData={setFiltersData} />
                            }
                        </Route>
                        <Route path={`${path}/team`} exact>
                            {
                                type === 'stats' ?
                                    <TeamStats filtersData={filtersData} setFiltersData={setFiltersData} />
                                    :
                                    <TeamPerformanceTrends filtersData={filtersData} setFiltersData={setFiltersData} />
                            }
                        </Route>
                        <Route path={`${path}/player`} exact>
                            {
                                type === 'stats' ?
                                    <PlayerStats filtersData={filtersData} setFiltersData={setFiltersData} />
                                    :
                                    <PlayerPerformanceTrends filtersData={filtersData} setFiltersData={setFiltersData} />
                            }
                        </Route>
                        <Redirect to={{ pathname: `${path}/overall`, search: location.search }} />
                        {/* {
                            type === 'stats' ?
                                <>
                                   
                                </>
                                :
                                <>
                                    <Route path={`${path}/overall`} exact>
                                        <OverallTrends filtersData={filtersData} setFiltersData={setFiltersData} />
                                    </Route>
                                    <Route path={`${path}/team`} exact>
                                        <TeamPerformanceTrends filtersData={filtersData} setFiltersData={setFiltersData} />
                                    </Route>
                                    <Route path={`${path}/player`} exact>
                                        <PlayerPerformanceTrends filtersData={filtersData} setFiltersData={setFiltersData} />
                                    </Route>
                                    <Redirect to={`${path}/overall`} />
                                </>
                        } */}
                    </Switch>
                </div>
            </div>
        </div >
    )
}

export default withRouter(PerformanceStats);