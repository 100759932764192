import { all, call, take, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { GET_FOLLOWER_USERS_API, GET_FOLLOWING_USERS_API, GET_MUTUAL_FOLLOWING_USERS_API, FOLLOW_USER_API, UN_FOLLOW_USER_API, GET_FAVOURITE_STATUS_API } from "../../config";
import { GET_FAVOURITE_STATUS, GET_FOLLOWING_USER_LIST, GET_MUTUAL_FOLLOWING_USER_LIST, FOLLOW_USER, UN_FOLLOW_USER, GET_FOLLOWER_USER_LIST } from "../../ducks/favourites";

const fetchFollowUserList = (username, params) => {
    return Axios.get(GET_FOLLOWER_USERS_API(username), { params });
}

const fetchFollowingUserList = (username, params) => {
    return Axios.get(GET_FOLLOWING_USERS_API(username), { params });
}

const fetchMutualFollowingUserList = (username, params) => {
    return Axios.get(GET_MUTUAL_FOLLOWING_USERS_API(username), { params })
}

const postFollowUserRequest = (payload) => {
    return Axios.post(FOLLOW_USER_API(payload.username), payload);
}

const unFollowUserRequest = (username) => {
    return Axios.delete(UN_FOLLOW_USER_API(username));
}

const fetchFavouriteStatus = (username) => {
    return Axios.get(GET_FAVOURITE_STATUS_API(username));
}

function* watchForFollowerUserList() {
    yield takeEvery(GET_FOLLOWER_USER_LIST, function* getFollowUserList(action) {
        const { params, username, onFollowerUserListSuccess, onFollowerUserListError } = action.payload;
        try {
            let { data, headers } = yield call(fetchFollowUserList, username, params);
            if (data) {
                yield call(onFollowerUserListSuccess, data, headers);
                console.log('comments list data success');
            }
        } catch (e) {
            yield call(onFollowerUserListError, e);
            console.log('comments list catch err ', e);
        }
    });
}

function* watchForFollowingUserList() {
    yield takeEvery(GET_FOLLOWING_USER_LIST, function* getFollowingUserList(action) {
        const { params, username, onFollowingUserListSuccess, onFollowingUserListError } = action.payload;
        try {
            let { data, headers } = yield call(fetchFollowingUserList, username, params);
            if (data) {
                yield call(onFollowingUserListSuccess, data, headers);
                console.log('comments list data success');
            }
        } catch (e) {
            yield call(onFollowingUserListError, e);
            console.log('comments list catch err ', e);
        }
    });
}

function* watchForMutualFollowingUserList() {
    yield takeEvery(GET_MUTUAL_FOLLOWING_USER_LIST, function* getFollowingUserList(action) {
        const { params, username, onMutualUserListSuccess, onMutualUserListError } = action.payload;
        try {
            let { data, headers } = yield call(fetchMutualFollowingUserList, username, params);
            if (data) {
                yield call(onMutualUserListSuccess, data, headers);
                console.log('watchForMutualFollowingUserList data success');
            }
        } catch (e) {
            yield call(onMutualUserListError, e);
            console.log('watchForMutualFollowingUserList catch err ', e);
        }
    });
}

function* watchForFollowUser() {
    yield takeEvery(FOLLOW_USER, function* followUser(action) {
        const { requestObj, onFollowUserSuccess, onFollowUserError } = action.payload;
        try {
            let { data } = yield call(postFollowUserRequest, requestObj);
            if (data) {
                console.log('watchForPostArena data success');
                yield call(onFollowUserSuccess, { data, username: requestObj.username });
            }
        } catch (e) {
            yield call(onFollowUserError, e);
            console.log('watchForPostArena catch err ', e);
        }
    });
}

function* watchForUnFollowUser() {
    yield takeEvery(UN_FOLLOW_USER, function* unFollowUser(action) {
        const { username, onUnFollowUserSuccess, onUnFollowUserError } = action.payload;
        try {
            let { data } = yield call(unFollowUserRequest, username);
            if (data) {
                console.log('watchForUnFollowUser data success');
                yield call(onUnFollowUserSuccess, { data, username });
            }
        } catch (e) {
            yield call(onUnFollowUserError, e);
            console.log('watchForUnFollowUser catch err ', e);
        }
    });
}
function* watchForGetFavouriteStatus() {
    yield takeEvery(GET_FAVOURITE_STATUS, function* getFavouriteStatus(action) {
        const { username, onFavouriteStatusSuccess, onFavouriteStatusError } = action.payload;
        try {
            let { data } = yield call(fetchFavouriteStatus, username);
            if (data) {
                yield call(onFavouriteStatusSuccess, data);
                console.log('watchForGetFavouriteStatus data success');
            }
        } catch (e) {
            yield call(onFavouriteStatusError, e);
            console.log('watchForGetFavouriteStatus catch err ', e);
        }
    });
}

export default function* () {
    yield all([
        watchForFollowerUserList(),
        watchForFollowingUserList(),
        watchForMutualFollowingUserList(),
        watchForFollowUser(),
        watchForUnFollowUser(),
        watchForGetFavouriteStatus(),
    ]);
}
