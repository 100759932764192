import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from "moment";
import { Avatar, Button, ListItem, ListItemAvatar, makeStyles } from "@material-ui/core";
import Moment from "react-moment";
const NotificationStyles = makeStyles((theme) => ({
  avatar: {
    paddingLeft: "1rem",
    paddingTop: "1rem",
  },
  avatarStyles: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    maxWidth: "213px",
    minHeight: "40px",
    fontSize: "13px",
    fontWeight: 550,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    color: "#535353",
    marginLeft: "15px",
    marginTop: "0.6rem"
  },
  timestamp: {
    margin: "5px 10px 0px",
    fontSize: 14,
    color: "#838383",
  },
  both: {
    display: "flex",
    alignItems: "center",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    cursor: "pointer",
    "&:hover": {
      background: "yellow"
    }

  },
  space: {
    flex: 1,
  },

}));
moment.updateLocale("en", {
  relativeTime: {
    dd: "%d d",
    ww: "%d w",
    MM: "%d m",
    yy: "%d y",
    M: "%d mo",
    y: "%d y",
    m: "#d m",
    mm: "%d m",
    H: "%d h",
    HH: "%d,h",
    s: "%d s",
    ss: "%d s"
  },
});
const NormalNotification = () => {
  const classes = NotificationStyles();
  return (
    <div className={classes.both}>
      <div className={classes.avatar}>
        <Avatar
          classes={{ colorDefault: classes.avatarStyles }}
          alt="saivivek"
          src="hi.jpeg"
        >
          SR
          </Avatar>
      </div>
      <div className={classes.text}>
        <p>Congratulate Shamila Roseli for Starting new position</p>
      </div>
      <div className={classes.space}></div>
      <p className={classes.timestamp}>
        <Moment fromNow ago>
          2020-09-18T13:05:51.211+00:00
          </Moment>
      </p>
    </div>
  );
}



const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(NormalNotification)
