import React from "react";
import gameIllustration from '../../assets/images/game_Illustration.png';

const IllustrationView = ({ loading = false, data = [], style = {} }) => {
    return (
        <>
            {(loading && data.length === 0) ? null :
                <div style={{ textAlign: 'center', ...style }}>
                    <img src={gameIllustration} />
                    <p style={{ fontSize: 14, color: '#0000008A' }}>No data available</p>
                </div>}
        </>
    )
}

export default IllustrationView;