import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { useSnackbar } from 'notistack';
import { getRoundTeamStatistics, getTeamCoreKpisStats, getTeamPerformance, getTeamPointsList } from '../../ducks/game';
import { formattedValue } from './MyPerformance';
import { getDurationWithUnit, getNumberFormat } from '../../helpers';
import { setLoading } from '../../ducks/loading';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RankLabelLatest } from '../PointsTables/RankLabel';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F2F5",
    width: "100%",
    maxHeight: "calc(100vh - 116px)",
    // maxHeight: 'calc(100vh - 100px)',
    overflowY: "auto",
    padding: "56px 24px 0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // maxHeight: 'calc(100vh - 80px)',
      // maxWidth: `calc(100vw - 150px)`,
      padding: "56px 0px 0px",
    },
  },
  gridContainer: {
    // width: '80%',
    // width: '90%',
    display: "flex",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1250,
    [theme.breakpoints.down("md")]: {
      // width: '85%',
      // width: '90%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
    },
    [theme.breakpoints.down("xs")]: {
      // width: '100%',
    },
  },
  cardStyle: {
    height: "143px",
    width: "300px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },

  cardHeadertitle: {
    color: "#A0A0A0",
    fontSize: "13px",
    lineHeight: "19.5px",
    fontWeight: "500",
  },
  avatarStyles: {
    width: 36,
    height: 36,
    fontSize: 16,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: "0.785714px solid #C4C4C4",
  },
  orgCardStyle: {
    height: "430px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  playerCardStyle: {
    height: "392px",
    width: "318px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  performanceCardStyle: {
    height: "392px",
    width: "546px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  Box: {
    height: "138px",
    width: "160px",
    //left: "51px",
    //top: "228px",
    //marginTop: "17px",
    //borderRadius: "10px",
    border: "1px solid #EDEDED",
    // borderColor: '#EDEDED'
    margin: 10,
    marginTop: 16,
    boxShadow: "none"
  },

  cardHeader: {
    fontSize: 13, lineHeight: "19.5px", color: "#000000", marginLeft: 15, fontWeight: '600'
  },



  nameRoot: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 600,
    '& .name': {
      fontWeight: 500
    }
  },
  scoreCardsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .scoreCard': {
      minWidth: 150,
      minHeight: 125,
      border: '1px solid #EDEDED',
      borderRadius: 3,
      margin: '16px 10px 10px 0',
      padding: 10
    },
    '& .title': {
      color: '#494949',
      fontWeight: 600,
      fontSize: 11,
      margin: '10px 0'
    },
    '& .rankRoot': {
      fontSize: 11,
      color: '#000',
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .rank': {
        fontSize: 28,
      },
    },
    '& .scoreRoot': {
      fontSize: 11,
      color: '#000',
      margin: 0,
      '& .score': {
        fontSize: 28,
      },
      '& .scoreUnit': {
        fontSize: 12,
        // marginLeft: 5
      }
    },
    '& .achievment': {
      fontSize: 13,
      color: '#000',
      marginBottom: 0,
      fontWeight: 600
    }
  }
}));


function MyTeamPerformance({ userInfo, teamId = "", isSeasonLevel, roundId, getTeamPointsList, getTeamCoreKpisStats, filtersData, userDetails, setLoading, getRoundTeamStatistics, getTeamPerformance }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [teamRank, setTeamRank] = useState({});
  const [teamDetails, setTeamDetails] = useState({});
  const [teamKpiStats, setTeamKpiStats] = useState([]);


  useEffect(() => {
    if (filtersData?.season?._id && (isSeasonLevel ? true : roundId)) {
      let seasonId = filtersData.season?._id;
      getTeamsPoints(seasonId, roundId);
    }
  }, [filtersData?.season?._id, roundId]);

  const getTeamsPoints = (seasonId, roundId) => {
    setLoading(true);
    if (isSeasonLevel) {
      getTeamPerformance({
        params: { season_id: seasonId, limit: 5000, page: 1 },
        onSuccessCB: (res, headers) => {
          if (res) {
            const result = res[0]        // userInfo?.myTeams[0] ? res.find(obj => obj.team_id === userInfo?.myTeams[0]) : res.find(obj => obj.team_id === (teamId || headers.my_team_id));
            if (result) {
              setTeamDetails({ name: result.name, _id: result.team_id, username: result.team_id, logo_url: result.logo_url });
              setTeamRank({ rank: result.rank, prev_rank: result.previous_rank });
              setTeamKpiStats(result.kpis);
            }
          }
          setLoading(false);
        }, onErrorCB: (error) => {
          setLoading(false);
          enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch team points', {
            variant: 'error',
            preventDuplicate: true
          });
        }
      })
    }
    else {
      getTeamPerformance({
        roundId, params: { season_id: seasonId, limit: 5000, page: 1 },
        onSuccessCB: (result, headers) => {
          if (result) {
            const teamPoints = result[0]              //userInfo?.myTeams[0] ? result.find(obj => obj.team_id === userInfo?.myTeams[0]) : result.find(obj => obj.team_id === (teamId || headers.my_team_id));
            if (teamPoints) {
              setTeamDetails({ name: teamPoints.name, _id: teamPoints.team_id, username: teamPoints.team_id, logo_url: teamPoints.logo_url });
              setTeamRank({ rank: teamPoints.rank, prev_rank: teamPoints.previous_rank });
              setTeamKpiStats(teamPoints.kpis);
            }
          }
          setLoading(false);
        }, onErrorCB: (error) => {
          setLoading(false);
          enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
            variant: 'error',
            preventDuplicate: true
          });
        }
      });
    }
  }

  return (
    <>
      {teamDetails?._id ?
        <>
          <div className={classes.nameRoot}>
            <AvatarComponent type='TEAM' name={teamDetails.name} username={teamDetails._id} url={teamDetails.logo_url} onClick={() => { }} style={{ marginRight: 10, width: 34, height: 34, fontSize: 15 }} filtersData={filtersData} />
            <span className="name" style={{ color: '#232637' }}>{teamDetails.name}</span> {/* rowData.team && rowData.team.name */}
          </div>
          <div className={classes.scoreCardsRoot}>
            <div className={'scoreCard'}>
              <p className={'title'}>{'RANK'}</p>
              <p className={'rankRoot'}><span className={'rank'}>{teamRank.rank}<span style={{ fontSize: 12 }}>{teamRank.rank === 1 ? 'st' : teamRank.rank === 2 ? 'nd' : teamRank.rank === 3 ? 'rd' : 'th'}</span></span><span><RankLabelLatest rank={teamRank.rank} prevRank={teamRank.previous_rank} /></span></p>
            </div>
            {
              teamKpiStats?.map((item, index) =>
                <div key={index} className={'scoreCard'}>
                  <p className={'title'}>{`${item.kpi_name}`}</p>
                  {/* <p className={'scoreRoot'}><span className={'score'}>
                    {formattedValue(userDetails, item.actual, item)}
                  </span></p> */}
                  <p className={'scoreRoot'}>
                    <span className={'score'}>{formattedValue(userDetails, item.actual, item, false)}</span>
                    <span className={'scoreUnit'}>{item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? ` ${getDurationWithUnit(item.actual, item.unit)}` : item.unit}</span>
                  </p>
                  <p className={'scoreRoot'} style={{ display: 'flex' }}>
                    <span style={{ paddingRight: 4 }}>of</span>
                    <span>{formattedValue(userDetails, item.target, item)}</span>
                  </p>
                  <p className={'achievment'}>Achieved: {item.unit_of_measurement === 'PERCENTAGE' ? '--' : `${getNumberFormat(userDetails?.countryCode, item.achievement)}%`}</p>
                </div>
              )
            }
          </div>
        </>
        : <p style={{ padding: '100px 24px', textAlign: 'center' }}>No Data Available</p>}
    </>
  )
}

const mapStateToProps = (state) => ({
  userDetails: state.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: value => dispatch(setLoading(value)),
    getTeamPointsList: (options) => dispatch(getTeamPointsList(options)),
    getTeamCoreKpisStats: options => dispatch(getTeamCoreKpisStats(options)),
    getRoundTeamStatistics: options => dispatch(getRoundTeamStatistics(options)),
    getTeamPerformance: (options) => dispatch(getTeamPerformance(options)),

  }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTeamPerformance));
