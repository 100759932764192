import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Backdrop, CircularProgress } from '@material-ui/core';
import LinkIcon from '../../../assets/images/link.png'
import { getProfileMediaFiles } from '../../../ducks/common';
import { connect } from 'react-redux';
import { getSortedFiles } from '../../../selectors';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 15px',
        position: 'relative'
    },
    titleMonth: {
        margin: 0,
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    listItemRoot: {
        padding: 0,
        margin: '10px 0',
        height: 90,
        overflow: 'hidden'
    },
    listItemContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        wordBreak: 'break-all',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#F0F0F0',
        alignItems: 'center'
    },
    linkIconRoot: {
        minWidth: 90,
        backgroundColor: '#E6E6E6',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    primaryText: {
        margin: 0,
        paddingBottom: '7px',
        fontSize: 12,
        color: '#535353'
    },
    linkTag: {
        textDecoration: 'none',
        color: 'inherit'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    emptyListText: {
        textAlign: 'center',
        margin: 30,
        fontSize: 14,
        color: '#58595B'
    }
}));

const Links = ({ links, currentChat, getProfileMediaFiles }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        console.log('Links links', links)
        setLoading(false);
    }, [links])

    useEffect(() => {
        console.log('Links targetJid', currentChat)
        if (currentChat.type === 'group') {
            let teamId = currentChat && currentChat.targetJid.split('@')[0];
            // if (teamId !== links.targetJid) {
            setLoading(true);
            getProfileMediaFiles({
                limit: 200,
                page: 0,
                target_jid: teamId,
                type: 'LINK',
                chat_type: 'team'
            });
            // }
            // else {
            //     setLoading(false);
            // }
        }
        else {
            let username = currentChat && currentChat.targetJid.split('@')[0];
            // if (username !== links.targetJid) {
            setLoading(true);
            getProfileMediaFiles({
                limit: 200,
                page: 0,
                target_jid: username,
                type: 'LINK',
                chat_type: 'employee'
            });
            // }
            // else {
            //     setLoading(false);
            // }
        }

    }, [currentChat.targetJid]);

    return (
        <div id="DocFiles" className="scroller" style={{ height: '70vh', maxHeight: '70vh', position: 'relative' }}>
            {
                (links && links.length > 0) ? links.map(obj => (
                    <div className={classes.root}>
                        <p className={classes.titleMonth}>{obj.month}</p>
                        <List dense>
                            {obj.files && obj.files.map((item, index) => {
                                return (
                                    <a href={item.sourceUrl} target="_blank" className={classes.linkTag}>
                                        <ListItem className={classes.listItemRoot} key={index} button onClick={() => console.log('List Item Click')} >
                                            <div className={classes.linkIconRoot}>
                                                <img width="25" src={LinkIcon} alt={'Link'} />
                                            </div>
                                            <div className={classes.listItemContent}>
                                                <div>
                                                    <p className={classes.primaryText}>{item.sourceUrl && (item.sourceUrl.length > 100 ? item.sourceUrl.substring(0, 100) + '...' : item.sourceUrl)}</p>
                                                </div>
                                            </div>
                                        </ListItem>
                                    </a>
                                );
                            })}
                        </List>
                    </div>
                ))
                :
                <p className={classes.emptyListText}>No Links Found</p>
            }
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

const mapStateToProps = (state) => ({
    links: getSortedFiles(state.common.links.data)
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProfileMediaFiles: (options) => dispatch(getProfileMediaFiles(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Links);