import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, TextareaAutosize, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Chip, Select, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { Close, Check, ExpandMore, Add } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { DatePicker } from "@material-ui/pickers";
import { updateProfile, updateWorkList, updateEducationList, getArenaProfile, updateBasicProfile, deleteEducation, deleteWorkExperience } from "../../ducks/user";
import { getFavouriteStatus, followUser, unFollowUser } from '../../ducks/favourites';
import { uploadFilesToS3 } from "../../ducks/common";
import { ReactComponent as AboutIcon } from "../../assets/icons/about.svg";
import { ReactComponent as CakeIcon } from "../../assets/icons/Cake.svg";
import { ReactComponent as GenderIcon } from "../../assets/icons/gender-icon.svg";
import { ReactComponent as FemaleIcon } from "../../assets/icons/female-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg";
import { ReactComponent as MaleIcon } from "../../assets/icons/male-icon.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/Location.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/Phone-3.svg";
import { ReactComponent as HobbiesIcon } from "../../assets/icons/hobbies-icon.svg";
import { ReactComponent as SchoolIcon } from "../../assets/icons/school-2.svg";
import { ReactComponent as BookIcon } from "../../assets/icons/book.svg";
import { ReactComponent as Business } from "../../assets/icons/business.svg";
import { ReactComponent as CalenderIcon } from "../../assets/icons/Calendar-4.svg";
import { ReactComponent as JobIcon } from "../../assets/icons/Job.svg";
import { ReactComponent as Location } from "../../assets/icons/Location.svg";
import { ReactComponent as CompanyIcon } from "../../assets/icons/company.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg";
import { ReactComponent as PublicIcon } from "../../assets/icons/public.svg";
import { ReactComponent as OnlyMeIcon } from "../../assets/icons/only-me.svg";
import moment from "moment";
import { withRouter } from 'react-router';
import { setCurrentChat } from "../../ducks/chats";
import { ConfirmationDialog } from "../Arena/CreateArenaPost";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    mainroots: {
        marginBottom: '15px'
    },
    detailsbox: {
        borderRadius: 3,
        backgroundColor: '#FFF',
        boxShadow: "0px 0px 3px 1px #EDEDED",
        padding: '10px',
        "& ul": {
            margin: 0,
            padding: 0
        }
    },
    lists: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        "& p": {
            margin: 0,
            minWidth: '150px',
            width: '150px',
            paddingRight: '10px',
            fontSize: 12.5,
            color: '#807a7a',
            textTransform: 'capitalize',
        },
        "& h6": {
            margin: 0,
            fontSize: 13.5,
            color: '#525252',
            textTransform: 'capitalize'
        },
    },
    about: {
        // backgroundColor: "#f7f7f7",
        padding: "0 10px",
        height: 45,
        margin: "24px 0px 10px 0px",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            margin: 0,
            fontSize: 14,
            fontWeight: 600,
            color: '#535353'
        }
    },
    limittext: {
        fontSize: '11px',
        float: 'right'
    },
    svgIcon: {
        overflow: "inherit",
        // fontSize: "1.7rem"
    },
    listItem: {
        padding: "12px 22px 12px 18px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    listItemContent: {
        display: "flex",
        alignItems: "center",
        padding: '0 10px 0 0'
    },
    iconRoot: {
        marginRight: 5
    },
    cardDisplay1: {
        // backgroundColor: "#FFFFFF",
        marginTop: "15px",
        // padding: "10px 25px"
    },
    actionRoot: {
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 22px 12px 18px",
        flexWrap: 'wrap'
    },
    profileDetails: {
        fontSize: "15px",
        color: "#535353",
        textTransform: "capitalize"
    },

    editIconStyles: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        margin: '0 5px',
        '& svg': {
            '& path': {
                fill: '#FFF'
            }
        },
        '& .delete-icon': {
            height: 14,
            '& path': {
                // fill: '#535353'
            }
        }
    },
    actionButtonStyles: {
        padding: 8
    },
    favAlign: {
        display: "flex",
        justifyContent: "center",
        marginTop: "8px"
    },
    favourite: {
        width: "270px",
        height: "41px",
        borderRadius: "6px",
        backgroundColor: "#ffcc00",
        marginBottom: 10,
        border: 'none'
    },
    favouriteText: {
        fontSize: "14px",
        color: "#535353"
    },

    // Edit about 
    fieldRoot: {
        padding: '12px 18px',
        display: 'flex',
        marginBottom: 20,
    },
    textareaStyles: {
        border: 'none',
        fontSize: 15,
        color: '#535353',
        outline: 'none',
        width: '100%',
        resize: 'none',
        lineHeight: '20px',
        borderBottom: '1px solid #535353',
        marginTop: 6,
        padding: 0,
        '&:hover': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        },
        '&:focus': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    },
    fieldLabel: {
        marginLeft: 12,
        width: '100%',
        '& p:first-child': {
            margin: 0,
            fontSize: 14,
            color: '#535353',
            fontWeight: 600,
            '& .mandatory': {
                color: '#ff0202'
            }
        },
    },
    birthDateField: {
        display: 'flex',
        marginLeft: 12,
        // width: '80%',
        // justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        minWidth: '260px',
        '& p': {
            margin: 0,
            fontSize: 14,
            color: '#535353',
            fontWeight: 600,
            minWidth: '90px'
        },
    },
    genderclass: {
        display: 'flex',
        '& p': {
            margin: 0,
            fontSize: 14,
            color: '#535353',
            fontWeight: 600,
            paddingLeft: 12,
        }
    },
    radioLabel: {
        color: '#535353',
        marginTop: 15,
        '& .icon-label': {
            display: 'flex',
            alignItems: 'center',
            width: 80,
            fontSize: 14,
            '& svg': {
                marginRight: 15
            }
        }
    },
    inputStyles: {
        fontSize: 15,
        color: '#535353'
    },
    nameInputStyles: {
        textAlign: 'center',
        color: '#141414',
        fontSize: 16,
        fontWeight: 600
    },
    inputUnderine: {
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    },
    chipsRoot: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '0 18px',
        marginBottom: 30,
    },
    chip: {
        margin: theme.spacing(0.5),
        // color: '#535353'
    },
    datePickerStyles: {
        margin: '10px 25px 0 0',
        width: 90
    },
    datePickerInputStyles: {
        paddingLeft: 8,
        fontSize: 14,
        color: '#535353',
        height: '11px',
        cursor: 'pointer',
    },
    dateInputUnderine: {
        border: '1px solid #0000006b',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:before': {
            border: 'none'
        },
        '&:hover:not(.Mui-disabled):before': {
            border: 'none'
        },
        '& svg': {
            fill: '#535353'
        }
    },
    education_s: {
        alignItems: 'flex-start',
        width: '230px'
    },
    educationdates: {
        alignItems: 'center',
        display: 'inline-flex',
        '& p': {
            minWidth: '55px'
            // paddingRight: '25px'
        }
    },
    // Fav/UnFav Button

    favButton: {
        height: 36,
        '& path': {
            fill: '#FFF'
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '& .fav-button-text': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },

    // Privacy Select
    privacySelect: {
        padding: '4px 32px 4px 8px',
        fontSize: 14,
        '& svg': {
            marginRight: 8
        }
    },

    chatButton: {
        position: 'absolute',
        height: 36,
        minWidth: 55,
        marginLeft: 25,
        backgroundColor: '#a8a8a8',
        '&:hover': {
            boxShadow: 'none'
        },
        '& .MuiButton-startIcon': {
            margin: 0
        }
    },
    chatIconStyle: {
        width: 25,
        height: 25,
        '& path[stroke]': {
            fill: '#FFFFFF',
            stroke: '#FFFFFF'
        }
    },
    primarySvg: {
        '& path': {
            fill: theme.palette.primary.main
        }
    }
}))

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultBirthDate = new Date(new Date().getFullYear() - 1, 11, 31);

const PlayerPersonal = ({ user, username, getArenaProfile, getFavouriteStatus, uploadFilesToS3, updateBasicProfile, updateEducationList, updateWorkList, deleteEducation, deleteWorkExperience, followUser, unFollowUser, setCurrentChat, presence, history }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [details, setDetails] = useState({
        name: "",
        photoUrl: null,
        PhotoBanner: null,
        email: "",
        phoneNo: "",
        designation: "",
        about: "",
        departmentName: null,
        role: '',
        region: ''
    });
    const [editedDetails, setEditedDetails] = useState({})
    const [edit, setEdit] = useState({
        name: false,
        about: false,
        education: false,
        workExperience: false
    });
    const [preview, setPreview] = useState({
        open: false,
        attachments: [],
        attachmentIndex: 0
    });
    const [openConfirmationDialog, setConfirmationDialog] = useState({ open: false, id: "", deleteType: "" });
    const [deleteItem, setDeleteItem] = useState("");
    const userId = username

    useEffect(() => {
        if (isLoggedInUser()) {
            setDetails(user);
        }
    }, [user]);

    useEffect(() => {
        if (!isLoggedInUser() && userId) {
            getArenaProfile({
                username: userId,
                onEmployeeDetailsSuccess,
                onEmployeeDetailsError
            });
            getFavouriteStatus({
                username: userId,
                onFavouriteStatusSuccess,
                onFavouriteStatusError
            })
        }
        else {
            setDetails(user);
        }
    }, [userId]);

    const formatUserInfoProperty = userInfo => {
        if (userInfo) {
            return userInfo;
        } else {
            return "--";
        }
    };

    const handleChange = (event) => {
        let editedDetailsDup = JSON.parse(JSON.stringify(editedDetails));
        let id = event.target.id;
        let value = event.target.value;
        switch (id) {
            case 'name':
                if (value.length <= 100 && !/[~`!@#$%\^&*()+=\-\[\]\\';,./{}|\\":<>\?]/g.test(value)) {
                    editedDetailsDup.name = value;
                }
                break;
            case 'info':
                if (value.length <= 300) {
                    editedDetailsDup.about = value;
                }
                break;
            case 'location':
                editedDetailsDup.location = value;
                break;
            case 'phoneNo':
                if (value.length <= 12) {
                    editedDetailsDup.phoneNo = value;
                }
                break;
            case 'hobbies':
                editedDetailsDup.hobby = value;
                break;
            case 'field':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], fieldOfStudy: value };
                break;
            case 'school':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], schoolOrCollege: value };
                break;
            case 'degree':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], degree: value };
                break;
            case 'university':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], university: value };
                break;
            case 'percentage':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], percentage: value };
                break;
            case 'company':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], company: value };
                break;
            case 'position':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], position: value };
                break;
            case 'workLocation':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], workLocation: value };
                break;

            default:
                break;
        }
        setEditedDetails({ ...editedDetailsDup });
    }

    const handleChangePrivacy = (event, id) => {
        let editedDetailsDup = JSON.parse(JSON.stringify(editedDetails));
        let value = event.target.value;
        switch (id) {
            case 'birth-date-privacy':
                if (editedDetailsDup.privacy)
                    editedDetailsDup.privacy.date = value
                else
                    editedDetailsDup.privacy = { date: value }
                break;
            case 'birth-year-privacy':
                if (editedDetailsDup.privacy)
                    editedDetailsDup.privacy.year = value
                else
                    editedDetailsDup.privacy = { year: value }
                break;
            default:
                break;
        }
        setEditedDetails({ ...editedDetailsDup });
    }

    const handleChangeRadio = (value) => {
        setEditedDetails(prevState => ({ ...prevState, gender: value }));
    }

    const handleChangeHobbiesList = (e) => {
        if (e.nativeEvent.keyCode === 13 && !e.nativeEvent.shiftKey) {
            e.preventDefault();
            setEditedDetails(prevState => ({ ...prevState, hobbies: prevState.hobby ? [...(prevState.hobbies || []), prevState.hobby] : prevState.hobbies, hobby: '' }));
        }
    }

    const handleDeleteHobby = (hobbyToDelete) => {
        setEditedDetails(prevState => ({ ...prevState, hobbies: prevState.hobbies.filter(hobby => hobby !== hobbyToDelete) }))
    }

    const handleDateChange = (date, type) => {
        let editedDetailsDup = JSON.parse(JSON.stringify(editedDetails));
        switch (type) {
            case 'education-from':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], startYear: date._d };
                break;
            case 'education-to':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], endYear: date._d };
                break;
            case 'work-experience-from':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], startYear: date._d };
                break;
            case 'work-experience-to':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], endYear: date._d };
                break;
            case 'birth-month':
            case 'birth-date':
            case 'birth-year':
                editedDetailsDup.dateOfBirth = date._d
                break;
        }
        setEditedDetails({ ...editedDetailsDup });
    }

    const handleUpdate = (type) => {
        let requestObj = {};
        switch (type) {
            case 'about':
            case 'name':
                requestObj = {
                    "about": editedDetails.about,
                    "birthDate": editedDetails.dateOfBirth || defaultBirthDate,
                    "gender": editedDetails.gender,
                    "hobbies": editedDetails.hobbies,
                    "location": editedDetails.location,
                    "name": editedDetails.name,
                    "privacy": editedDetails.privacy,
                    "phoneNo": editedDetails.phoneNo,
                    "photo_url": editedDetails.photoUrl,
                    "roleId": details?.role?.id,
                    'regionId': details?.region?.id
                }
                updateBasicProfile({
                    requestObj,
                    employeeId: editedDetails.employeeId,
                    onBasicProfileUpdateSuccess, onBasicProfileUpdateError
                })
                break;
            case 'education':
                requestObj = {
                    "educationId": editedDetails.educations[0].educationId || editedDetails.educationId,
                    "educations": [
                        {
                            "degree": editedDetails.educations[0].degree,
                            "fieldOfStudy": editedDetails.educations[0].fieldOfStudy,
                            "schoolOrCollege": editedDetails.educations[0].schoolOrCollege,
                            "university": editedDetails.educations[0].university,
                            "percentage": editedDetails.educations[0].percentage,
                            "startYear": new Date(editedDetails.educations[0].startYear).getFullYear(),
                            "endYear": new Date(editedDetails.educations[0].endYear).getFullYear()
                        }
                    ]
                }
                updateEducationList({
                    requestObj,
                    username: details.username,
                    employeeId: editedDetails.employeeId,
                    onEducationUpdateSuccess, onEducationUpdateError
                })
                break;
            case 'workExperience':
                requestObj = {
                    "experienceId": editedDetails.workExperience[0].experienceId || editedDetails.experienceId,
                    "workExperience": [
                        {
                            "company": editedDetails.workExperience[0].company,
                            "position": editedDetails.workExperience[0].position,
                            "workLocation": editedDetails.workExperience[0].workLocation,
                            "startYear": {
                                "month": new Date(editedDetails.workExperience[0].startYear).getMonth() + 1,
                                "year": new Date(editedDetails.workExperience[0].startYear).getFullYear()
                            },
                            "endYear": {
                                "month": new Date(editedDetails.workExperience[0].endYear).getMonth() + 1,
                                "year": new Date(editedDetails.workExperience[0].endYear).getFullYear()
                            },
                        }
                    ]
                }
                updateWorkList({
                    requestObj,
                    username: details.username,
                    employeeId: editedDetails.employeeId,
                    onWorkUpdateSuccess, onWorkUpdateError
                })
                break;

            default:
                break;
        }
    }

    const onBasicProfileUpdateSuccess = (result) => {
        enqueueSnackbar(`Profile details updated successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        setEdit(prevState => ({ ...prevState, about: false, name: false }));
    }
    const onBasicProfileUpdateError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.errors[0] : 'Failed to update details.' ? error.response.data.errors.length > 0 : error.response.data.errors[0], {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const onEducationUpdateSuccess = result => {
        enqueueSnackbar(`Education details updated successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        setEdit(prevState => ({ ...prevState, education: false }));
    };
    const onEducationUpdateError = error => {
        enqueueSnackbar((error.response && error.response.data) ? 'Failed to update Education details.' : '', {
            variant: 'error',
            preventDuplicate: true
        });

    };

    const onWorkUpdateSuccess = result => {
        enqueueSnackbar(`Work experience updated successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        setEdit(prevState => ({ ...prevState, workExperience: false }));
    };
    const onWorkUpdateError = error => {
        enqueueSnackbar((error.response && error.response.data) ? 'Failed to update Work-Experience details.' : '', {
            variant: 'error',
            preventDuplicate: true
        });
    };
    const handleConfirmationDialog = (value) => {
        if (value && openConfirmationDialog.deleteType === "Education") {
            setConfirmationDialog({ open: false, id: "", deleteType: "" });
            deleteEducation({
                details: {
                    employeeId: details.employeeId,
                    educationId: openConfirmationDialog.id,
                    updatedObj: { ...details, educations: [] }
                },
                onEducationDeleteSuccess, onEducationDeleteError
            })
        }
        else if (value && openConfirmationDialog.deleteType === "Experience") {
            setConfirmationDialog({ open: false, id: "", deleteType: "" });
            deleteWorkExperience({
                details: {
                    employeeId: details.employeeId,
                    workExperienceId: openConfirmationDialog.id,
                    updatedObj: { ...details, workExperience: [] }
                },
                onWorkExperienceDeleteSuccess, onWorkExperienceDeleteError
            })
        }
        else {
            setConfirmationDialog({ open: value, id: '', deleteType: '' })
        }
    }
    const handleEditClick = (type, id) => {
        setEdit(prevState => ({ ...prevState, [type]: true }));
        let detailsDup = JSON.parse(JSON.stringify(details));
        detailsDup.educations = (detailsDup.educations && detailsDup.educations.length > 0) ?
            detailsDup.educations.filter(el => el.educationId === id).map(obj => ({
                ...obj, startYear: new Date((obj.startYear ? obj.startYear : new Date().getFullYear()), 0, 1), endYear: new Date((obj.endYear ? obj.endYear : new Date().getFullYear()), 0, 1)
            }))
            :
            detailsDup.educations = [{
                "educationId": "",
                "degree": "",
                "fieldOfStudy": "",
                "schoolOrCollege": "",
                "university": "",
                "percentage": "",
                "startYear": new Date(),
                "endYear": new Date()
            }];

        detailsDup.workExperience = (detailsDup.workExperience && detailsDup.workExperience.length > 0) ?
            detailsDup.workExperience.filter(el => el.experienceId === id).map(obj => ({
                ...obj,
                startYear: new Date((obj.startYear.year ? obj.startYear.year : new Date().getFullYear()), (obj.startYear.month ? (obj.startYear.month - 1) : new Date().getMonth()), 1),
                endYear: new Date((obj.endYear.year ? obj.endYear.year : new Date().getFullYear()), (obj.endYear.month ? (obj.endYear.month - 1) : new Date().getMonth()), 1),
            }))
            :
            detailsDup.workExperience = [{
                "experienceId": "",
                "company": "",
                "position": "",
                "workLocation": "",
                "endYear": new Date(),
                "startYear": new Date()
            }]
        setEditedDetails({ ...detailsDup });
    }

    const handleDeleteClick = (type, id) => {
        switch (type) {
            case 'education':
                // handleConfirmationDialog(true, id, "Education");
                setConfirmationDialog({ open: true, id: id, deleteType: "Education" });
                break;
            case 'workExperience':
                // handleConfirmationDialog(true, id, "Experience");
                setConfirmationDialog({ open: true, id: id, deleteType: "Experience" });
                break;
            default:
                break;
        }
    }

    const onEducationDeleteSuccess = () => {
        enqueueSnackbar(`Education details deleted successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
    }

    const onEducationDeleteError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to delete Education details.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const onWorkExperienceDeleteSuccess = (result) => {
        enqueueSnackbar(`Work-Experience deleted successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
    }
    const onWorkExperienceDeleteError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to delete Work-Experience details.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const ActionButtons = ({ type, data, id, hasDelete, addNewIcon, ...others }) => (
        <div {...others}>
            {edit[type] ?
                <>
                    <IconButton title="Cancel" onClick={() => setEdit(prevState => ({ ...prevState, [type]: false }))} className={classes.actionButtonStyles}><Close /></IconButton>
                    <IconButton title="Save" onClick={() => handleUpdate(type)} className={classes.actionButtonStyles}><Check /></IconButton>
                </>
                :
                <>
                    <IconButton title="Edit" onClick={() => handleEditClick(type, id, data)} className={classes.editIconStyles}>{addNewIcon ? <Add fontSize="small" /> : <EditIcon />}</IconButton>
                    {
                        hasDelete &&
                        <IconButton title="Delete" onClick={() => { handleDeleteClick(type, id) }} className={classes.editIconStyles}><DeleteIcon className="delete-icon" /></IconButton>
                    }
                </>
            }
        </div>
    )
    const handleAddClick = (type) => {
        setEdit(prevState => ({ ...prevState, [type]: true }));
        let detailsDup = JSON.parse(JSON.stringify(details));
        detailsDup.educations =
            detailsDup.educations = [{
                "educationId": "",
                "degree": "",
                "fieldOfStudy": "",
                "schoolOrCollege": "",
                "university": "",
                "percentage": "",
                "startYear": new Date(),
                "endYear": new Date()
            }];

        detailsDup.workExperience =
            detailsDup.workExperience = [{
                "experienceId": "",
                "company": "",
                "position": "",
                "workLocation": "",
                "endYear": new Date(),
                "startYear": new Date()
            }]
        setEditedDetails({ ...detailsDup });
    }
    const ActionButtons1 = ({ type, data, hasDelete, addNewIcon, ...others }) => (
        <div {...others}>
            {edit[type] ?
                <>
                    <IconButton title="Cancel" onClick={() => setEdit(prevState => ({ ...prevState, [type]: false }))} className={classes.actionButtonStyles}><Close /></IconButton>
                    <IconButton title="Save" onClick={() => handleUpdate(type)} className={classes.actionButtonStyles}><Check /></IconButton>
                </>
                :
                <>
                    <IconButton title="Add" onClick={() => handleAddClick(type, data)} className={classes.editIconStyles}><Add fontSize="small" /></IconButton>
                </>
            }
        </div>
    )

    const isLoggedInUser = () => {
        if (userId !== user.username) {
            return false
        }
        else {
            return true;
        }
    }

    const onEmployeeDetailsSuccess = result => {
        setDetails(result);
    };
    const onEmployeeDetailsError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch details.', {
            variant: 'error',
            preventDuplicate: true
        });
    };

    const onFavouriteStatusSuccess = result => {
    }
    const onFavouriteStatusError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch favourite status.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const CustomDatePicker = ({ views, value, onChange, ...others }) => {
        return (
            <DatePicker
                {...others}
                views={views}
                value={value}
                onChange={onChange}
                className={classes.datePickerStyles}
                InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <ExpandMore /> }}
            />
        )
    }


    return (
        <div className={classes.root}>
            <div className={classes.cardDisplay1}>
                <div className={classes.about}><p>About</p>{isLoggedInUser() && <ActionButtons type="about" />}</div>
                {
                    (edit.about && isLoggedInUser()) ?
                        <>
                            <div className={classes.detailsbox}>
                                <div className={classes.fieldRoot}>
                                    <div><AboutIcon /></div>
                                    <div className={classes.fieldLabel}>
                                        <p>Info</p>
                                        <TextareaAutosize
                                            color="primary"
                                            id="info"
                                            maxLength={200}
                                            placeholder="Information about you.."
                                            value={editedDetails.about}
                                            className={classes.textareaStyles}
                                            onChange={handleChange}
                                        />
                                        <span className={classes.limittext}>{editedDetails?.about?.length}/200</span>

                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className={classes.fieldRoot}>
                                        <div><CakeIcon /></div>
                                        <div className={classes.birthDateField}>
                                            <p>Birthday</p>
                                            <CustomDatePicker
                                                views={["month"]}
                                                format={"MMMM"}
                                                maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                                                value={editedDetails.dateOfBirth || defaultBirthDate}
                                                onChange={(e) => handleDateChange(e, 'birth-month')}
                                                style={{ margin: '0px 20px 0px 0px' }}
                                            />
                                            <CustomDatePicker
                                                views={["date"]}
                                                format={"DD"}
                                                maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                                                value={editedDetails.dateOfBirth || defaultBirthDate}
                                                onChange={(e) => handleDateChange(e, 'birth-date')}
                                                style={{ margin: 0, width: 60 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.fieldRoot}>
                                        <Select
                                            id="birth-date-privacy"
                                            value={(editedDetails.privacy && editedDetails.privacy.hasOwnProperty('date')) ? editedDetails.privacy.date : true}
                                            onChange={(e) => handleChangePrivacy(e, 'birth-date-privacy')}
                                            variant={"outlined"}
                                            classes={{ outlined: classes.privacySelect }}
                                            style={{ borderRadius: 4, width: 110, height: 26 }}
                                            IconComponent={ExpandMore}
                                        >
                                            <MenuItem value={true}><PublicIcon style={{ marginRight: 8 }} />Public</MenuItem>
                                            <MenuItem value={false}><OnlyMeIcon style={{ marginRight: 8 }} /><span style={{ color: '#53535380' }}>Only me</span></MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className={classes.fieldRoot}>
                                        <div><CakeIcon /></div>
                                        <div className={classes.birthDateField}>
                                            <p>Birth Year</p>
                                            <CustomDatePicker
                                                views={["year"]}
                                                maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                                                value={editedDetails.dateOfBirth || defaultBirthDate}
                                                onChange={(e) => handleDateChange(e, 'birth-year')}
                                                style={{ margin: 0 }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.fieldRoot}>
                                        <Select
                                            id="birth-year-privacy"
                                            value={(editedDetails.privacy && editedDetails.privacy.hasOwnProperty('year')) ? editedDetails.privacy.year : true}
                                            onChange={(e) => handleChangePrivacy(e, 'birth-year-privacy')}
                                            variant={"outlined"}
                                            classes={{ outlined: classes.privacySelect }}
                                            style={{ borderRadius: 4, width: 110, height: 26 }}
                                            IconComponent={ExpandMore}
                                        >
                                            <MenuItem value={true}><PublicIcon style={{ marginRight: 8 }} />Public</MenuItem>
                                            <MenuItem value={false}><OnlyMeIcon style={{ marginRight: 8 }} /><span style={{ color: '#53535380' }}>Only me</span></MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div className={classes.fieldRoot}>
                                    <div className={classes.genderclass}><GenderIcon /> <p>Gender</p></div>
                                    <div className={classes.fieldLabel}>
                                        {/* <p>Gender<span className="mandatory"> *</span></p> */}
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <RadioGroup aria-label="gender" name="gender1" onChange={(e, value) => handleChangeRadio(value)}>
                                                <FormControlLabel labelPlacement="start"
                                                    className={classes.radioLabel}
                                                    value={"female"}
                                                    checked={editedDetails.gender === 'female'}
                                                    control={<Radio icon={<CheckIcon style={{ visibility: 'hidden' }} />} checkedIcon={<CheckIcon />} />}
                                                    label={<span className={'icon-label'}><FemaleIcon /> Female</span>}
                                                />
                                                <FormControlLabel labelPlacement="start"
                                                    className={classes.radioLabel}
                                                    checked={editedDetails.gender === 'male'}
                                                    value={"male"}
                                                    control={<Radio icon={<CheckIcon style={{ visibility: 'hidden' }} />} checkedIcon={<CheckIcon />} />}
                                                    label={<span className={'icon-label'}><MaleIcon /> Male</span>}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className={classes.fieldRoot}>
                                    <div><LocationIcon /></div>
                                    <div className={classes.fieldLabel}>
                                        <p>Location
                                            {/* <span className="mandatory"> *</span> */}
                                        </p>
                                        <TextField
                                            id="location"
                                            value={editedDetails.location}
                                            fullWidth
                                            placeholder="Location"
                                            // error={!editedDetails.location && true}
                                            // helperText={!editedDetails.location && "Please enter location."}
                                            onChange={handleChange}
                                            InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine, underline: classes.inputUnderine } }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldRoot}>
                                    <div><PhoneIcon /></div>
                                    <div className={classes.fieldLabel}>
                                        <p>Phone
                                            {/* <span className="mandatory"> *</span> */}
                                        </p>
                                        <TextField
                                            id="phoneNo"
                                            value={editedDetails.phoneNo}
                                            fullWidth
                                            disabled
                                            placeholder="Phone number"
                                            // error={(editedDetails.phoneNo && editedDetails.phoneNo.length === 10) ? false : true}
                                            // helperText={(editedDetails.phoneNo && editedDetails.phoneNo.length === 10) ? '' : "Please enter valid Phone number."}
                                            type="number"
                                            onChange={handleChange}
                                            InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldRoot}>
                                    <div><HobbiesIcon /></div>
                                    <div className={classes.fieldLabel}>
                                        <p>Hobbies</p>
                                        <TextField
                                            fullWidth
                                            placeholder="Type your hobby and press enter."
                                            id="hobbies"
                                            value={editedDetails.hobby}
                                            onChange={handleChange}
                                            onKeyUp={handleChangeHobbiesList}
                                            InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                        />
                                    </div>
                                </div>
                                <ul className={classes.chipsRoot}>
                                    {editedDetails.hobbies && editedDetails.hobbies.map((hobby, index) => (
                                        <li key={index}>
                                            <Chip
                                                label={hobby}
                                                onDelete={() => handleDeleteHobby(hobby)}
                                                className={classes.chip}
                                                color="primary"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                        :
                        <>
                            <div className={classes.detailsbox}>
                                <ul>
                                    <li className={classes.lists}>
                                        <p>About</p> <h6>{formatUserInfoProperty(details.about)}</h6>
                                    </li>
                                    <li className={classes.lists}>
                                        <p>Gender</p> <h6>{details.gender ? formatUserInfoProperty(details.gender) : '--'}</h6>
                                    </li>
                                    {
                                        ((details.publicDetails && Object.values(details.publicDetails).some(item => item !== null)) || (details.username === user.username)) &&
                                        <li className={classes.lists}>
                                            <p>Birthday</p>
                                            <h6>{(details.username === user.username)
                                                ? (details.dateOfBirth ? moment(details.dateOfBirth).format("D MMM YYYY") : '--')
                                                :
                                                details.publicDetails &&
                                                `${details.publicDetails.dob_day ? details.publicDetails.dob_day : '-- /'}
                      ${details.publicDetails.dob_month ? months[details.publicDetails.dob_month - 1] : '--- /'}
                      ${details.publicDetails.dob_year ? details.publicDetails.dob_year : ''}`
                                            }</h6>
                                        </li>
                                    }
                                    <li className={classes.lists}>
                                        <p>Hobbies</p>
                                        <h6>{details.hobbies ? details.hobbies.join(" , ") : '--'}</h6>
                                    </li>
                                    <li className={classes.lists}>
                                        <p>Contact</p> <h6>{details.phoneNo ? formatUserInfoProperty(details.phoneNo) : '--'}</h6>
                                    </li>
                                    <li className={classes.lists}>
                                        <p>Location</p> <h6>{details.location ? formatUserInfoProperty(details.location) : '--'}</h6>
                                    </li><li className={classes.lists}>
                                        <p>Designation</p> <h6>{details.designation ? formatUserInfoProperty(details.designation) : '--'}</h6>
                                    </li>
                                    <li className={classes.lists}>
                                        <p>Department</p> <h6>{details.departmentName ? formatUserInfoProperty(details.departmentName) : '--'}</h6>
                                    </li>
                                </ul>
                            </div>
                        </>
                }
            </div>
            <div className={classes.cardDisplay1}>
                <div className={classes.about}><p>Education</p>{isLoggedInUser() && <ActionButtons1 type="education" />}</div>
                {
                    (edit.education && isLoggedInUser()) ?
                        <>
                            <div className={classes.detailsbox}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div className={classes.fieldRoot}>
                                        <div><SchoolIcon className={classes.primarySvg} /></div>
                                        <div className={classes.fieldLabel}>
                                            <p>Degree<span className="mandatory"> *</span></p>
                                            <TextField
                                                id="degree"
                                                fullWidth
                                                value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].degree}
                                                onChange={handleChange}
                                                placeholder="Degree"
                                                error={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].degree) && true}
                                                helperText={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].degree) && "Please enter degree."}
                                                InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div className={classes.fieldRoot}>
                                        <div><SchoolIcon className={classes.primarySvg} /></div>
                                        <div className={classes.fieldLabel}>
                                            <p>School/College<span className="mandatory"> *</span></p>
                                            <TextField
                                                id="school"
                                                fullWidth
                                                value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].schoolOrCollege}
                                                onChange={handleChange}
                                                placeholder="School/College"
                                                error={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].schoolOrCollege) && true}
                                                helperText={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].schoolOrCollege) && "Please enter School/College."}
                                                InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.fieldRoot}>
                                        <div><BookIcon className={classes.primarySvg} /></div>
                                        <div className={classes.fieldLabel}>
                                            <p>Field of Study<span className="mandatory"> *</span></p>
                                            <TextField
                                                id="field"
                                                fullWidth
                                                value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].fieldOfStudy}
                                                onChange={handleChange}
                                                placeholder="Field of study"
                                                error={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].fieldOfStudy) && true}
                                                helperText={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].fieldOfStudy) && "Please enter Field of study."}
                                                InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div className={classes.fieldRoot}>
                                        <div><Business className={classes.primarySvg} /></div>
                                        <div className={classes.fieldLabel}>
                                            <p>University</p>
                                            <TextField
                                                id="university"
                                                fullWidth
                                                value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].university}
                                                onChange={handleChange}
                                                placeholder="University"
                                                // error={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].university) && true}
                                                // helperText={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].university) && "Please enter University."}
                                                InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.fieldRoot}>
                                        <div><BookIcon className={classes.primarySvg} /></div>
                                        <div className={classes.fieldLabel}>
                                            <p>Percentage<span className="mandatory"> *</span></p>
                                            <TextField
                                                id="percentage"
                                                type="number"
                                                fullWidth
                                                value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].percentage}
                                                onChange={handleChange}
                                                placeholder="Percentage"
                                                error={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].percentage) && true}
                                                helperText={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].percentage) && "Please enter Percentage."}
                                                InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div className={classNames(classes.fieldRoot, classes.education_s)}>
                                        <div><CalenderIcon /></div>
                                        <div className={classNames(classes.fieldLabel, classes.educationdates)}>
                                            <p>From</p>
                                            <CustomDatePicker
                                                style={{ margin: '0px' }}
                                                views={["year"]}
                                                maxDate={new Date()}
                                                value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].startYear}
                                                onChange={(e) => handleDateChange(e, 'education-from')}
                                            />
                                        </div>
                                    </div>
                                    <div className={classNames(classes.fieldRoot, classes.education_s)}>
                                        <div><CalenderIcon /></div>
                                        <div className={classNames(classes.fieldLabel, classes.educationdates)}>
                                            <p>To</p>
                                            <CustomDatePicker
                                                style={{ margin: '0px' }}
                                                views={["year"]}
                                                minDate={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].startYear}
                                                maxDate={new Date()}
                                                value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].endYear}
                                                onChange={(e) => handleDateChange(e, 'education-to')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {details.educations && details.educations.map(educationDetails => {
                                return (
                                    <React.Fragment>
                                        <div className={classes.detailsbox}>
                                            <div style={{ float: 'right' }}>
                                                {isLoggedInUser() && <ActionButtons id={educationDetails.educationId} type="education" addNewIcon={(details.educations && details.educations.length === 0) && true} hasDelete={(details.educations && details.educations.length > 0) && true} />}
                                            </div>
                                            <ul>
                                                <li className={classes.lists} style={{ alignItems: 'initial' }}>
                                                    <p>{educationDetails.degree ? formatUserInfoProperty(educationDetails.degree) : '--'}</p>
                                                    <div>
                                                        <h6>{educationDetails.schoolOrCollege ? formatUserInfoProperty(educationDetails.schoolOrCollege) : '--'}</h6>
                                                        <h6>{educationDetails.fieldOfStudy ? formatUserInfoProperty(educationDetails.fieldOfStudy) : '--'}</h6>
                                                        <h6>{educationDetails.university ? formatUserInfoProperty(educationDetails.university) : ''}</h6>
                                                        <h6>{educationDetails.percentage ? formatUserInfoProperty(educationDetails.percentage) + '%' : '--'}</h6>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </>
                }
            </div >
            <div className={classes.cardDisplay1}>
                <div className={classes.about}><p>Work Experience</p>{isLoggedInUser() && <ActionButtons1 type="workExperience" />}</div>
                {
                    (edit.workExperience && isLoggedInUser()) ?
                        <>
                            <div className={classes.detailsbox} style={{ marginTop: '10px' }}>
                                <div className={classes.fieldRoot}>
                                    <div><CompanyIcon className={classes.primarySvg} /></div>
                                    <div className={classes.fieldLabel}>
                                        <p>Company/Organization<span className="mandatory"> *</span></p>
                                        <TextField
                                            id="company"
                                            fullWidth
                                            placeholder="Organization name"
                                            value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].company}
                                            onChange={handleChange}
                                            error={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].company) && true}
                                            helperText={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].company) && "Please enter Company/Organization."}
                                            InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div className={classes.fieldRoot}>
                                        <div><JobIcon style={{ margin: '0 5px' }} /></div>
                                        <div className={classes.fieldLabel}>
                                            <p>Position<span className="mandatory"> *</span></p>
                                            <TextField
                                                id="position"
                                                fullWidth
                                                placeholder="Position"
                                                value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].position}
                                                onChange={handleChange}
                                                error={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].position) && true}
                                                helperText={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].position) && "Please enter Position."}
                                                InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.fieldRoot}>
                                        <div><Location style={{ margin: '0 5px' }} /></div>
                                        <div className={classes.fieldLabel}>
                                            <p>Work Location</p>
                                            <TextField
                                                id="workLocation"
                                                fullWidth
                                                placeholder="Work Location"
                                                value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].workLocation}
                                                onChange={handleChange}
                                                // error={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].workLocation) && true}
                                                // helperText={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].workLocation) && "Please enter Work Location."}
                                                InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div className={classes.fieldRoot} style={{ alignItems: 'flex-start' }}>
                                        <div><CalenderIcon /></div>
                                        <div className={classNames(classes.fieldLabel, classes.educationdates)}>
                                            <p>From</p>
                                            <div style={{ display: 'flex' }}>
                                                <CustomDatePicker
                                                    style={{ margin: '0px' }}
                                                    views={["year"]}
                                                    maxDate={new Date()}
                                                    value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].startYear}
                                                    onChange={(e) => handleDateChange(e, 'work-experience-from')}
                                                />
                                                <CustomDatePicker
                                                    style={{ margin: '0px', width: '120px', marginLeft: '25px' }}
                                                    disableToolbar
                                                    format={"MMMM"}
                                                    views={["month"]}
                                                    maxDate={new Date()}
                                                    value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].startYear}
                                                    onChange={(e) => handleDateChange(e, 'work-experience-from')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.fieldRoot} style={{ alignItems: 'flex-start' }}>
                                        <div><CalenderIcon /></div>
                                        <div className={classNames(classes.fieldLabel, classes.educationdates)}>
                                            <p>To</p>
                                            <div style={{ display: 'flex' }}>
                                                <CustomDatePicker
                                                    style={{ margin: '0px' }}
                                                    views={["year"]}
                                                    minDate={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].startYear}
                                                    maxDate={new Date()}
                                                    value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].endYear}
                                                    onChange={(e) => handleDateChange(e, 'work-experience-to')}
                                                />
                                                <CustomDatePicker
                                                    style={{ margin: '0px', width: '120px', marginLeft: '25px' }}
                                                    disableToolbar
                                                    format={"MMMM"}
                                                    views={["month"]}
                                                    minDate={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].startYear}
                                                    maxDate={new Date()}
                                                    value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].endYear}
                                                    onChange={(e) => handleDateChange(e, 'work-experience-to')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {details.workExperience && details.workExperience?.map(workDetails => {
                                return (
                                    <React.Fragment>
                                        <div className={classes.detailsbox}>
                                            <div style={{ float: 'right' }}>
                                                {isLoggedInUser() && <ActionButtons id={workDetails.experienceId} type="workExperience" addNewIcon={(details.workExperience && details.workExperience.length === 0) && true} hasDelete={(details.workExperience && details.workExperience.length > 0) && true} />}
                                            </div>
                                            <ul>
                                                <li className={classes.lists} style={{ alignItems: 'initial' }}>
                                                    <p>
                                                        {(workDetails.startYear || workDetails.endYear) ? (
                                                            <React.Fragment>
                                                                {(workDetails.startYear.year && workDetails.startYear.month) ? moment(new Date(workDetails.startYear.year, workDetails.startYear.month - 1, 1)).format("YYYY") : "--"}-
                                                                {(workDetails.endYear.year && workDetails.endYear.month) ? moment(new Date(workDetails.endYear.year, workDetails.endYear.month - 1, 1)).format("YYYY") : "--"}
                                                            </React.Fragment>) : "--"
                                                        }
                                                    </p>
                                                    <div>
                                                        <h6>{workDetails.position ? formatUserInfoProperty(workDetails.position) : '--'}</h6>
                                                        <h6>{workDetails.company ? formatUserInfoProperty(workDetails.company) : '--'}</h6>
                                                        <h6>{workDetails.workLocation ? formatUserInfoProperty(workDetails.workLocation) : ''}</h6>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </>
                }
            </div>
            {/* {preview.open && <ArenaPreview open={preview.open} attachmentIndex={preview.attachmentIndex} arena={{ attachments: preview.attachments }} handlePreviewClose={handlePreviewClose} isFromProfile={true} />} */}
            <ConfirmationDialog open={openConfirmationDialog.open} content={`Are you sure you want to Delete ${openConfirmationDialog.deleteType}?`} handleConfirmationDialog={handleConfirmationDialog} />
        </div >
    )
}


const mapStateToProps = (state, props) => ({
    user: state.user,
    presence: state.presence[`${props.match.params.id}@${process.env.REACT_APP_XMPP_DOMAIN}`]
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        updateBasicProfile: options => dispatch(updateBasicProfile(options)),
        updateEducationList: value => dispatch(updateEducationList(value)),
        updateWorkList: value => dispatch(updateWorkList(value)),
        getArenaProfile: value => dispatch(getArenaProfile(value)),
        followUser: options => dispatch(followUser(options)),
        unFollowUser: options => dispatch(unFollowUser(options)),
        updateProfile: options => dispatch(updateProfile(options)),
        uploadFilesToS3: options => dispatch(uploadFilesToS3(options)),
        getFavouriteStatus: options => dispatch(getFavouriteStatus(options)),
        deleteEducation: options => dispatch(deleteEducation(options)),
        deleteWorkExperience: options => dispatch(deleteWorkExperience(options)),
        setCurrentChat: (options) => dispatch(setCurrentChat(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerPersonal));

