import { all, takeEvery, call, select } from "redux-saga/effects";
import Axios from "axios";
import { setDeviceToken, requestPushNotificationAPI, GET_IN_APP_NOTIFICATIONS_API, UPDATE_IN_APP_NOTIFICATION_READ_API } from "../../config";
import { SET_DEVICE_TOKEN, REQUEST_PUSH_NOTIFICATION, DELETE_DEVICE_TOKEN, GET_IN_APP_NOTIFICATIONS, READ_IN_APP_NOTIFICATION, SHARE_LIVE_SCORES } from "../../ducks/notification";
import JwtDecode from 'jwt-decode';

const postPushNotification = (payload) => {
  return Axios.post(requestPushNotificationAPI, payload);
}

const callShareLivescores = (payload) => {
  return Axios.post(GET_IN_APP_NOTIFICATIONS_API, payload)
}

const configureWithDeviceToken = (token, deviceId) => {
  return Axios.put(setDeviceToken(deviceId), {
    device_token: token,
    device_name: navigator.userAgent,
    device_type: 'WEB'
  });
}

const deleteDeviceToken = (deviceId) => {
  return Axios.delete(setDeviceToken(deviceId));
}
const GetInAppNotificationsRequest = (params) => {
  return Axios.get(GET_IN_APP_NOTIFICATIONS_API, { params });
};

const ReadInAPPNotificationRequest = (notificationId) => {
  return Axios.put(UPDATE_IN_APP_NOTIFICATION_READ_API(notificationId));
};
function* watchForConfiguration(client) {
  yield takeEvery(SET_DEVICE_TOKEN, function* setMessagingToken(action) {
    const token = yield select(state => state.keycloak.token);
    const { preferred_username } = JwtDecode(token);
    try {
      let { data } = yield call(configureWithDeviceToken, action.payload, preferred_username);
      // console.log('watchForConfiguration action ', action);
      if (data) {
        console.log('watchForConfiguration success');
      }
      else {
        throw 'Invalid';
      }
    } catch (e) {
      console.log('watchForConfiguration catch err ', e);
    }
  });
}

function* watchForDeleteNotificationAccess(client) {
  yield takeEvery(DELETE_DEVICE_TOKEN, function* deleteMessagingToken(action) {
    const token = yield select(state => state.keycloak.token);
    const { preferred_username } = JwtDecode(token);
    const { client, keycloak, onDeletedTokenSuccessCB, onDeletedTokenErrorCB } = action.payload;
    try {
      let { data } = yield call(deleteDeviceToken, preferred_username);
      console.log('watchForDeleteNotificationAccess action ', action);
      if (data) {
        yield call(onDeletedTokenSuccessCB, client, keycloak);
        console.log('watchForDeleteNotificationAccess success');
      }
      else {
        throw 'Invalid';
      }
    } catch (e) {
      yield call(onDeletedTokenErrorCB, client, keycloak);
      console.log('watchForDeleteNotificationAccess catch err ', e);
    }
  });
}

function* watchForPushRequest() {
  yield takeEvery(REQUEST_PUSH_NOTIFICATION, function* requestPushNotification(action) {
    try {
      let { data } = yield call(postPushNotification, action.payload);
      // console.log('watchForConfiguration action ', action);
      if (data) {
        console.log('watchForConfiguration success');
      }
      else {
        throw 'Invalid';
      }
    } catch (e) {
      console.log('watchForConfiguration catch err ', e);
    }
  });
}
function* watchForGetInAppNotifications() {
  yield takeEvery(GET_IN_APP_NOTIFICATIONS, function* getInAppNotificationsData(action) {
    const {
      params,
      onGetInAppNotificationSuccess,
      onGetInAppNotificationError,
    } = action.payload;
    try {
      let { data, headers } = yield call(GetInAppNotificationsRequest, params);
      console.warn("data", data, headers)
      if (data) {
        yield call(onGetInAppNotificationSuccess, data, headers);
        console.log("watchForGetNotificaions data success");
      }
    }
    catch (e) {
      yield call(onGetInAppNotificationError, e);
      console.log("watchForGetNotificaions catch err ", e);
    }
  });
}
function* watchForInAppReadNotification() {
  yield takeEvery(READ_IN_APP_NOTIFICATION, function* readInAppNotification(action) {
    console.log(action.payload);
    const { notificationId, onReadNotificationSuccess, onReadNotificationError } = action.payload;
    try {
      if (notificationId) {
        console.log(notificationId, "notificationId");
        yield call(ReadInAPPNotificationRequest, notificationId);
        yield call(onReadNotificationSuccess)
        console.log("watchForGetNotificaions data success");
      }
    } catch (e) {
      yield call(onReadNotificationError, e);
      console.log("atchForGetNotificaions catch err ", e);
    }
  });
}

function* watchForShareLiveScores() {
  yield takeEvery(SHARE_LIVE_SCORES, function* readInAppNotification(action) {
    console.log(action.payload);
    const { body, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(callShareLivescores, body);
      console.log('watchForShareLiveScores data success', data)
      if (data) yield call(onSuccessCB, data);
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForShareLiveScores catch err ", e);
    }
  });
}

export default function* (client) {
  yield all([watchForConfiguration(client), watchForPushRequest(), watchForDeleteNotificationAccess(), watchForGetInAppNotifications(), watchForInAppReadNotification(), watchForShareLiveScores()]);
}
