import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import { GET_LINK_PRIVEW } from '../../../config';
import { Skeleton } from '@material-ui/lab';


const LinkPreview = ({ url, fallbackImageSrc, from = '' }) => {
    const [metadata, setMetadata] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const response = await axios.get(GET_LINK_PRIVEW, { params: { url: url } });
                let resp = response.data.data;
                resp['url'] = response.data?.url
                setMetadata(resp);
            } catch (err) {
                console.error('Error fetching metadata:', err);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchMetadata();
    }, [url]);

    if (loading) {
        return (
            <Card sx={{ display: 'flex', maxWidth: 500, margin: '10px 0', borderRadius: 2 }}>
                <Skeleton variant="rectangular" width={150} height={150} />
                <Box sx={{ flex: 1, padding: 2 }}>
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={20} width="80%" />
                    <Skeleton variant="text" height={20} width="60%" />
                </Box>
            </Card>
        );
    }
    if (error) {
        return <Typography color="error">Failed to load link preview.</Typography>;
    }

    return (
        <Card
            sx={{ display: 'flex', maxWidth: 500, margin: '10px 0', borderRadius: 2 }}
            style={from === 'PREVIEW' ? { cursor: 'pointer' } : {}}
            onClick={() => {
                if (from === 'PREVIEW') {
                    window.open(url, '_blank'); // Open URL in a new tab
                }
            }}
        >
            <CardMedia
                component="img"
                sx={{ width: '100%', height: 150 }}
                style={{ width: '100%', height: 150 }}
                image={metadata.image ? metadata.image : fallbackImageSrc}
                alt="Preview"
            />
            <CardContent>
                <Typography variant="h6">{metadata.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {metadata.description}
                </Typography>
                {from !== 'PREVIEW' && <Typography variant="body2" color="primary">
                    <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        {url}
                    </a>
                </Typography>}
            </CardContent>
        </Card>
    );
};

export default LinkPreview;
