import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { setLoading } from "../../../ducks/loading";
import { getDivisionsList, getMatchesList, getSingleSeasonDetails, getUserHierarchies } from "../../../ducks/game";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import PerformanceDashboard from "./PerformanceDashboard";
import MyPerformanceGroupsDetailed from "./myPerformanceGroupsDetailed";
import qs from 'query-string';
import MyReporteesDetails from "./MyReporteesDetails";
import MyTeamPerformanceGroupsDetailed from "./TeamPerformanceDashboard/myTeamPerformanceGroupsDetailed";
import MyTeamsPerformmanceDetailed from "./TeamPerformanceDashboard/myTeamsPerformmanceDetailed";
import MyTeamPlayerPerformanceDetailed from "./TeamPerformanceDashboard/myTeamPlayerPerformanceDetailed";
import KpiPerformance from "../KpiPerformance";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    }
}));

const defaultFilters = {
    league: null,
    season: null,
    round: null,
    status: 'LIVE',
    match: null
};
const DashboardNew = ({ history, matches, filtersData, setFiltersData, divisions, location, userInfo, getDivisionsList, getMatchesList, getSingleSeasonDetails, userHierarchieRoles, isAdmin, getUserHierarchies, teams }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    // const [divisions, setDivisions] = useState([]);
    // const [matches, setMatches] = useState();
    const qryparams = qs.parse(history.location.search);
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });

    useEffect(() => {
        if (!filtersData.role || !filtersData.division) {
            getDivisions(qryparams ? qryparams.season : filtersData.season?._id);
            // getUserHierarchiesList();
        }
    }, []);



    // ----------get divisions------
    const getDivisions = (seasonId) => {
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                setFiltersData(prev => ({ ...prev, division: result[0]?._id }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Divisions', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    // ---user hierarchies----
    const getUserHierarchiesList = () => {
        getUserHierarchies({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                const finalRes = result.map((d => ({ name: d.role, _id: d._id, level: d.level, user: d.name, username: d.username })))
                setFiltersData(prev => ({ ...prev, role: finalRes[0]?._id }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch User Roles', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    return (
        <div className={classes.root}>
            <Switch>
                <Route path={`${path}`} exact>
                    <PerformanceDashboard filtersData={filtersData} userInfo={userInfo} setFiltersData={setFiltersData} divisions={divisions} matches={matches} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} teams={teams} />
                </Route>
                <Route path={`${path}/detailed-performance`} exact>
                    <MyPerformanceGroupsDetailed filtersData={filtersData} userInfo={userInfo} setFiltersData={setFiltersData} divisions={divisions} matches={matches} isAdmin={isAdmin} />
                </Route>
                <Route path={`${path}/team/detailed-performance`} exact>
                    <MyTeamPerformanceGroupsDetailed filtersData={filtersData} userInfo={userInfo} setFiltersData={setFiltersData} divisions={divisions} matches={matches} isAdmin={isAdmin} />
                </Route>
                <Route path={`${path}/team/detailed-teamsperformance`} exact>
                    <MyTeamsPerformmanceDetailed filtersData={filtersData} userInfo={userInfo} setFiltersData={setFiltersData} divisions={divisions} matches={matches} isAdmin={isAdmin} />
                </Route>
                <Route path={`${path}/detailed-reportees`} exact>
                    <MyReporteesDetails filtersData={filtersData} userInfo={userInfo} setFiltersData={setFiltersData} divisions={userHierarchieRoles} isAdmin={isAdmin} />
                </Route>
                <Route path={`${path}/detailed-player-performance`} exact>
                    <MyTeamPlayerPerformanceDetailed filtersData={filtersData} userInfo={userInfo} setFiltersData={setFiltersData} teams={teams} matches={matches} isAdmin={isAdmin} />
                </Route>
                <Route path={`${path}/kpi/:kpiID/player`} exact>
                    <KpiPerformance from='PLAYER' filtersData={filtersData} userInfo={userInfo} setFiltersData={setFiltersData} divisions={divisions} matches={matches} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} teams={teams} />
                </Route>
                <Route path={`${path}/kpi/:kpiID/team`} exact>
                    <KpiPerformance from='TEAM' filtersData={filtersData} userInfo={userInfo} setFiltersData={setFiltersData} divisions={divisions} matches={matches} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} teams={teams} />
                </Route>
                <Redirect to={{ pathname: `${path}` }} />
            </Switch>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options)),
        getUserHierarchies: (options) => dispatch(getUserHierarchies(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),
        getSingleSeasonDetails: (options) => dispatch(getSingleSeasonDetails(options)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardNew));