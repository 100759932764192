import React from "react";
import { Switch, withStyles } from "@material-ui/core";

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 26,
        height: 12,
        padding: 0,
        display: 'flex',
        overflow: 'visible',
        marginRight: 2,
        marginBottom: 2
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 10,
        height: 10,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 12 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

export const DecimalViewSwitch = ({ ...props }) => {
    return (
        <p style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontWeight: 400, fontSize:9, marginRight: 5, color: "rgba(0, 0, 0, 0.54)" }}>
                <span>Show Decimals </span>
                <span> for Scores and Points</span>
            </span>
            <AntSwitch {...props} />
        </p>
    )
}
