import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import { TablePagination, TextField, Button, Avatar, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
// import "./ProjectListLayout.css"
import InfiniteScroll from "react-infinite-scroll-component";
import { useSnackbar } from "notistack";
import Link from '@material-ui/core/Link';
import { withRouter } from "react-router-dom";
import { getProjectList, openTaskDialog, boardDetails, setBreadCrumbsData, projectDetails } from "../../ducks/pms";
import { openCustomDialog } from '../../ducks/meetings';
import { setLoading } from '../../ducks/loading';
import { getColor } from './KanbanBoard'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import BoardDetails from "./BoardDetailsDrawer";
import classNames from 'classnames';
import { getAvatarText } from "../../helpers";
import queryString from "query-string";


const useStyles = makeStyles((theme) => ({
    mainroot: {
        // padding: "0px 35px",
        backgroundColor: "#ffff",
        width: "100%",
        display: 'flex'
        // margin:24
    },
    buttonAlign: {
        textAlign: "right",
        position: "relative",


    },
    gridRoot: {
        flexGrow: 1,
        // marginTop: 15
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    pageTitle: {
        color: '#58595B',
        margin: 0,
        fontSize: 22,
        fontWeight: 600,
    },
    cardHeights: {
        maxWidth: '100%',
        minHeight: '24vh',
        maxHeight: '28vh',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            // background: "#EEEEEE"
        }
        // minHeight: 150,
        // maxHeight: 153
    },
    cardHeading: {
        whiteSpace: 'nowrap',
        width: '170px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#58595B',
        fontSize: '16px',
        fontWeight: 600,
        padding: 6
    },
    cardsActions: {
        display: 'revert',
        textAlign: 'right',
        marginTop: '20px'
    }, buttonAndHeading: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // position: "fixed",
        zIndex: "999",
        width: "100%",
        // boxShadow: "1px 1px 0px #e3e2e23d",
    },
    headingStyles: {
        position: "relative",
        color: '#58595B',
        fontSize: 22,
        fontWeight: 600,
    },
    media: {
        height: '30px',
        width: '30px',
        borderRadius: '6px'
    },
    createButton: {
        marginRight: 3,
        height: 28,
        minWidth: 10,
        width: 28
    },
    noDataStyles: {
        color: '#535353',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    avatarStyles: {
        fontSize: '1rem',
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 30,
        width: 30,
        // marginRight:5,
    },
    avatarGroupSizes: {
        fontSize: '0.7rem',
        height: 25,
        width: 25,
        backgroundColor: theme.palette.primary.main,
    },
    stageCountAligns: {
        maxHeight: 42,
        margin: "-10px 0px 0px -20px"
    },
    ownersViewAlign: {
        float: "right",
        padding: "5px 0px",
        marginTop: "-10px",
        alignItems: "center"
    },
    progressInfoAlign: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: "0px 3px",
        fontSize: 'x-small',
        marginBottom: 1
    },
    addButtonStyles: {
        float: "right",
        border: "1px solid !important",
        borderRadius: 7,
        height: 30,
        minWidth: "30px !important",
    },
    memberNameStyles: {
        color: " #58595B",
        margin: 0,
        fontSize: "15px",
        fontWeight: 600,
        padding: 10
    },
    ownerNameStyles: {
        color: " #58595B",
        margin: 0,
        fontSize: "14px",
        fontWeight: 500,
        padding: 5
    },
    memberRoletStyles: {
        color: " #58595B",
        margin: 0,
        fontSize: "12px",
        fontWeight: 400,
        // padding: 5
    },
    sideDrawerOpenStyles: {
        marginTop: -35,
        marginRight: -50
    }
}));
let pagination = { page: 0, limit: 20 };

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#4a8201',
    },
}))(LinearProgress);

export const GetTasksProgress = ({ stages }) => {
    const classes = useStyles();
    if (stages.length > 0) {
        let totalTaks = stages.reduce(function (sum, current) {
            return sum + current.count;
        }, 0);
        let value = (stages[stages.length - 1].count / totalTaks) * 100;

        return (<div>
            <div className={classes.progressInfoAlign}>
                <span className={classes.detailsFontStyles}>Progress</span>
                <span>{stages[stages.length - 1].count}/{totalTaks}</span>
            </div>
            <div className={classes.root}>
                <BorderLinearProgress variant="determinate" value={totalTaks > 0 ? value : null} />
            </div>
        </div>


        )
    }
    else return (
        <>
        </>)
}

const ProjectList = (props) => {
    const classes = useStyles();
    const [projectList, setProjectList] = useState([]);
    const [message, setMessage] = useState("")
    const { enqueueSnackbar } = useSnackbar();
    const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
    const [selectedBoard, setSelectedBoard] = useState(null)

    useEffect(() => {
        props.setLoading(true);
        props.getProjectList({
            params: { limit: pagination.limit, page: 1 },
            onSuccessCB,
            onErrorCB
        });
        return () => {
            props.boardDetails({ open: false, type: '', noOfBoards: 0, details: { stages: [] } })
        }
    }, [])


    useEffect(() => {
        let scrollElement = document.getElementById('project-list-root');
        if (scrollElement) {
            scrollElement.scrollTop = 0
        }
    }, []);
    //Sucess callback
    const onSuccessCB = (result, headers) => {
        if (result.length <= 0) setMessage("No boards to show !")
        pagination.page = parseInt(headers.current_page);
        pagination.total_count = parseInt(headers.total_count);
        result.forEach((ele, ind) => ele.color = `#${getColor(ind)}`)
        setProjectList(prevState => ([...prevState, ...result]));
        props.setLoading(false)
    };
    //Error Callback
    const onErrorCB = error => {
        props.setLoading(false);
        setMessage("Failed to fetch Boards")
        if (error) {
            enqueueSnackbar(`Failed to fetch Boards.`, {
                variant: "error",
                preventDuplicate: true
            });
        }
    };
    const fetchMoreData = e => {
        props.getProjectList({
            params: { limit: pagination.limit, page: pagination.page + 1 },
            onSuccessCB,
            onErrorCB
        });
        // }
    }

    const navigateToBoard = (project) => {
        if (props.orgDetails?.theme?.view === 'PWC') {
            const query = {
                view: "table",
            };
            const viewString = queryString.stringify(query);
            props.history.push({
                pathname: `/product-management/${project.project_key}/board`,
                search: viewString
            });
        }
        else {
            props.history.push(`/product-management/${project.project_key}/board`)

        }

    }
    const column = [
        {
            title: "Project Name",
            field: "name",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    <Link style={{ color: "blue" }} onClick={() => props.history.push(`/product-management/${rowData.project_key}/board`)}>{rowData.name}</Link>
                </div>
            )
        },
        {
            title: "Key",
            field: "project_key",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {rowData.project_key}
                </div>
            )
        },
        {
            title: "Description",
            field: "description",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {rowData.description}
                </div>
            )
        }];

    const getBoardOwnersView = (board) => {
        let boardOwners = board.boardOwners;
        if (boardOwners.length) {
            return (
                boardOwners.length > 1 ?
                    <AvatarGroup max={3} classes={{ avatar: classes.avatarGroupSizes }} onClick={(e) => viewBoardMembers(e, board, 'Owners')}>
                        {
                            boardOwners.length && boardOwners.map((owner, ind) => (
                                <Tooltip title={owner.name} key={ind}>
                                    <Avatar key={ind}
                                        // alt={user.name}
                                        src={owner.photo_url}
                                        classes={{ colorDefault: classes.avatarStyles }}
                                        style={{ fontSize: '0.7rem', height: 25, width: 25 }}
                                    >
                                        {(owner.name && getAvatarText(owner.name))}
                                    </Avatar>
                                </Tooltip>
                            ))
                        }
                    </AvatarGroup> :
                    <span style={{ display: "flex" }}>
                        <p className={classes.ownerNameStyles}>{boardOwners[0].name}</p>
                        <Avatar
                            onClick={(e) => viewBoardMembers(e, board, 'Owners')}
                            // alt={user.name}
                            src={boardOwners[0].photo_url}
                            classes={{ colorDefault: classes.avatarStyles }}
                            style={{ fontSize: '0.7rem', height: 25, width: 25 }}
                        >
                            {(boardOwners[0].name && getAvatarText(boardOwners[0].name))}
                        </Avatar>
                    </span>
            )
        }
    }
    const getBoardMembersView = (board) => {
        let members = board.members;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <AvatarGroup max={3} classes={{ avatar: classes.avatarGroupSizes }} onClick={(e) => viewBoardMembers(e, board, 'Members')}>
                    {
                        members.length && members.map((owner, ind) => (
                            <Tooltip title={owner.name} key={ind}>
                                <Avatar key={ind}
                                    onClick={(e) => e.preventDefault()}
                                    // alt={user.name}
                                    src={owner.photo_url}
                                    classes={{ colorDefault: classes.avatarStyles }}
                                    style={{ fontSize: '0.7rem', height: 25, width: 25 }}
                                >
                                    {(owner.name && getAvatarText(owner.name))}
                                </Avatar>
                            </Tooltip>
                        ))
                    }
                </AvatarGroup>
                <Button className={classes.addButtonStyles} onClick={(e) => handleClickOpen(e, board)}><span style={{ fontSize: 'x-large' }}>+</span></Button>
            </div>

        )
    }

    const GetBoardMembersList = ({ board }) => {
        return (
            <div>
                <div>
                    {
                        board.boardOwners.length > 0 ?
                            board.boardOwners.map((owner, ind) => (
                                <div style={{ padding: 10, display: 'flex', alignItems: 'center' }} key={ind}>
                                    <Avatar
                                        // alt={user.name}
                                        src={owner.photo_url}
                                        classes={{ colorDefault: classes.avatarStyles }}
                                        style={{ height: 30, width: 30 }}
                                    >
                                        {(owner.name && getAvatarText(owner.name))}
                                    </Avatar>
                                    <p className={classes.memberNameStyles}>{owner.name}</p>
                                    <p className={classes.memberRoletStyles}>(Owner)</p>

                                </div>
                            ))
                            : null
                    }
                </div>
                <div>
                    {
                        board.members.length > 0 ?
                            board.members.map((member, ind) => (
                                <div style={{ padding: 10, display: 'flex', alignItems: 'center' }} key={ind}>
                                    <Avatar
                                        // alt={user.name}
                                        src={member.photo_url}
                                        classes={{ colorDefault: classes.avatarStyles }}
                                        style={{ height: 30, width: 30 }}
                                    >
                                        {(member.name && getAvatarText(member.name))}
                                    </Avatar>
                                    <p className={classes.memberNameStyles}>{member.name}</p>
                                    <span className={classes.memberRoletStyles}>(Member)</span>
                                </div>
                            ))
                            : null
                    }
                </div>


            </div>
        )
    }
    const viewBoardMembers = (e, board, type) => {
        e.stopPropagation()
        props.boardDetails({ open: true, type: type, noOfBoards: projectList.length, details: board });
    }
    const handleClickOpen = (e, board) => {
        e.stopPropagation()
        props.openTaskDialog({ open: true, details: { _id: board._id, name: board.name } })
    };

    return (
        <div className={classes.mainroot} >
            <div className={classes.gridRoot} >
                {
                    projectList && projectList.length > 0 ? (
                        <InfiniteScroll
                            dataLength={projectList.length}
                            next={fetchMoreData}
                            hasMore={projectList.length !== pagination.total_count}
                            scrollableTarget="project-list-root"
                            style={{ overflow: "unset" }}
                        >
                            <Grid container spacing={3}>
                                {projectList.length && projectList.map((project, index) => (
                                    <Grid item lg={3} md={4} xs={12} sm={6} key={index} >
                                        <Card className={classes.cardHeights} style={{ borderLeft: `solid 5px ${project.color}` }} onClick={() => navigateToBoard(project)}>
                                            <CardContent style={{ padding: 10 }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CardMedia>
                                                        <Avatar
                                                            // alt={user.name}
                                                            src={project.photoUrl}
                                                            classes={{ colorDefault: classes.avatarStyles }}
                                                            style={{ height: 30, width: 30 }}
                                                        >
                                                            {(project.name && getAvatarText(project.name))}
                                                        </Avatar>
                                                    </CardMedia>
                                                    <Tooltip title={project.name}>

                                                        <Typography className={classes.cardHeading} variant="h5" component="h2">
                                                            {project.name}</Typography>
                                                    </Tooltip>

                                                </div>

                                                <div className={classes.ownersViewAlign}>
                                                    {
                                                        getBoardOwnersView(project)
                                                    }
                                                </div>
                                                <br />
                                                <div style={{ marginTop: "10px" }}>
                                                    <GetTasksProgress stages={project.stages} />
                                                    {/* {
                                                    getTasksProgress(project.stages)
                                                } */}
                                                </div>
                                                <div style={{ marginTop: "20px" }}>
                                                    {getBoardMembersView(project)}
                                                </div>

                                            </CardContent>

                                            {/* <div className={classes.stageCountAligns}>
                                                    {
                                                        getTasksCountView(project.stages)
                                                    }
                                                </div> */}
                                        </Card>
                                    </Grid>
                                ))
                                }
                            </Grid>
                        </InfiniteScroll>

                    ) :
                        <div className={classes.noDataStyles}>
                            <h3>{message}</h3>
                        </div>
                }
            </div>
            {/* <div className={classNames(openDetailsDrawer && classes.sideDrawerOpenStyles)}>
            {selectedBoard && }

            </div> */}

        </div>
    );
}


const mapStateToProps = (state) => ({
    breadcrumbs: state.pms.breadcrumbs,
    orgDetails: state.common.orgDetails,

    // ProjectList :() => state.getProjectList(value)
})


const mapDispatchToProps = (dispatch) => {
    return {
        openTaskDialog: (options) => dispatch(openTaskDialog(options)),
        openCustomDialog: (options) => dispatch(openCustomDialog(options)),
        boardDetails: (options) => dispatch(boardDetails(options)),
        getProjectList: value => dispatch(getProjectList(value)),
        setBreadCrumbsData: (value) => dispatch(setBreadCrumbsData(value)),
        projectDetails: (value) => dispatch(projectDetails(value)),
        setLoading: (value) => dispatch(setLoading(value))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectList));
