import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewProfilePosts from '../Profile/ViewProfilePosts';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    about: {
        padding: "0 10px",
        height: 45,
        margin: "24px 0px 10px 0px",
        '& p': {
            margin: 0,
            fontSize: 14,
            fontWeight: 600,
            color: '#535353'
        }
    },
    Posts: {
        alignItems: 'center'
    }
}))

const PlayerPosts = (props) => {
    const classes = useStyles();
    const [username, setUsername] = useState(null);

    useEffect(() => {
        if (props.username)
            setUsername(props.username);
        else
            setUsername(props.keycloak.preferred_username);
    }, [props.username]);


    return (
        <div className={classes.root}>
            <div className={classes.about}>
                <p>Posts</p>
            </div>
            <div className={classes.Posts}>
                <ViewProfilePosts username={props.username} />
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    User: state.user,
    keycloak: state.keycloak.decoded_token

});


export default withRouter(connect(mapStateToProps, null)(PlayerPosts));

