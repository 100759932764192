import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { divindentValue, numberFormater } from '../../helpers';

const GetCountryCurrencyFormat = (props) => {

    const selectedSeason = props?.seasonDetails
    const selectedCountry = selectedSeason?.country;
    const seasonNumaration = selectedSeason?.numaration
    const finalValue = props.value

    const intlNumberFormat = () =>
        new Intl.NumberFormat(props?.locale ? (props?.locale) : (selectedCountry ? selectedCountry?.locale : 'en-IN'), {
            style: props?.type === "currency" ? "currency" : undefined,
            notation: 'compact',
            currency: props?.country ? (props?.country) : (selectedCountry ? selectedCountry?.currency_code : 'INR'),
            maximumFractionDigits: 2
        }).format(props.value);

    const formatCurrencyInIndianNotation = () => {
        const formatter = numberFormater(props, selectedCountry)
        const { divisor, label } = divindentValue(seasonNumaration);
        return (divisor && label) ? `${props?.type === "currency" ? selectedCountry?.currency_symbol : ''}${formatter.format(finalValue / divisor)}${label}` : formatter.format(finalValue);
    }

    return (
        <>
            {(seasonNumaration !== 'DEFAULT') ? formatCurrencyInIndianNotation() : intlNumberFormat()}
        </>
    )
}
const mapStateToProps = (state) => ({
    seasonDetails: state.game.seasonDetails,
    countryLists: state.game.countryLists,
});

export default withRouter(connect(mapStateToProps, null)(GetCountryCurrencyFormat));
