import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 15px',
        width: '100%',
        color: '#FFF',
        backgroundColor: '#00000087',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#000000a3'
        }
    }
}));

const leaderboardSubTabs = {
    CORE_KPI: 'core',
    'CORE KPI': 'core',
    BONUS_KPI: 'bonus',
    'BONUS KPI': 'bonus',
    TEAM: 'team',
    ROLE: 'role',
    REGION: 'region'
}

const ViewAllLabel = ({ contestProps, history, postId, ...others }) => {
    const classes = useStyles();
    const targetPage = contestProps.targetPage;
    const { league, season, round, match, division, subTab, surveyId } = contestProps.details;
    let copyContestProps = { ...contestProps.details };
    // if (copyContestProps.division) delete copyContestProps.league;
    let propsObj = {};
    Object.keys(copyContestProps).map(key => {
        if (copyContestProps[key] !== null && key !== 'subTab' && key !== 'league') {
            if (key === 'roleId') {
                propsObj['role'] = contestProps?.details[key]

            }
            else if (key === 'teamId') {
                propsObj['team'] = contestProps?.details[key]

            }
            else {
                propsObj[key] = contestProps?.details[key]

            }
        }
    });
    const query = qs.stringify({
        ...propsObj
    });




    const urls = {
        TEAM_LIVE_SCORES: `/leaguess/c2/live-scores/team/detailed-scorecard?${query}`,
        PLAYER_LIVE_SCORES: `/leaguess/c2/live-scores/player/all-player-performances?${query}`,
        MATCH_DETAILS: `/leaguess/c2/matches?${query}`,
        TEAM_LEADERBOARD: `/leaguess/c2/leaderboard/team/${subTab?.tab === 'BONUS_KPI' ? 'bonus' : subTab?.tab === 'CORE_KPI' ? 'core' : 'division'}/${subTab?.value}?${query}`,
        PLAYER_LEADERBOARD: `/leaguess/c2/leaderboard/player/${subTab?.tab === 'BONUS_KPI' ? 'bonus' : subTab?.tab === 'CORE_KPI' ? 'core' : subTab?.tab === 'TEAM' ? 'team' : subTab?.tab === 'ROLE' ? 'role' : subTab?.tab === 'REGION' ? 'region' : 'division'}/${subTab?.value}?${query}`,
        SURVEY: `/survey/details?${query}`,
        PLAYER_PERFORMANCE_GROUPS: `/leaguess/c2/performance/detailed-performance?${query}`,
        TEAM_PERFORMANCE_GROUPS: `/leaguess/c2/performance/team/detailed-performance?${query}`,
        TEAMS_PERFORMANCE: `/leaguess/c2/performance/team/detailed-teamsperformance?${query}`,
        REPORTEES: `/leaguess/c2/performance/detailed-reportees?${query}`,
        ROLE_WISE_LIVE_SCORES_PLAYER: `/leaguess/c2/live-scores/player/role/detailed-scorecard?${query}`,
        ROLE_WISE_LIVE_SCORES_TEAM: `/leaguess/c2/live-scores/team/role/detailed-scorecard?${query}`,
        DIVISION_WISE_LIVE_SCORES_PLAYER: `/leaguess/c2/live-scores/player/division/detailed-scorecard?${query}`,
        DIVISION_WISE_LIVE_SCORES_TEAM: `/leaguess/c2/live-scores/team/division/detailed-scorecard?${query}`,
        TEAM_WITH_PLAYER_PERFORMANCE: `/leaguess/c2/performance/detailed-player-performance?${query}`,
        TEAM_WISE_LIVE_SCORES: `/leaguess/c2/live-scores/team/teams/detailed-scorecard?${query}`,




    }

    const handleClick = (e) => {
        let targetFinalPage = (targetPage === 'PLAYER_LIVE_SCORES' && propsObj.division) ? 'DIVISION_WISE_LIVE_SCORES_PLAYER' : (targetPage === 'PLAYER_LIVE_SCORES' && propsObj.role) ? 'ROLE_WISE_LIVE_SCORES_PLAYER' : (targetPage === 'TEAM_LIVE_SCORES' && propsObj.role) ? 'ROLE_WISE_LIVE_SCORES_TEAM' : (targetPage === 'TEAM_LIVE_SCORES' && propsObj.division) ? 'DIVISION_WISE_LIVE_SCORES_TEAM' : (targetPage === 'TEAM_LIVE_SCORES' && propsObj.team) ? 'TEAM_WISE_LIVE_SCORES' : targetPage
        history.push(urls[targetFinalPage]);

    }

    return (
        <Button className={classes.root} onClick={handleClick} {...others} >
            {surveyId ? 'Take me to the survey' : 'View All'}
            <ChevronRight />
        </Button>
    )
}

export default withRouter(ViewAllLabel);