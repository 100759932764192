import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { ReactComponent as Like } from "../../assets/icons/heart.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment-1.svg";
import { ReactComponent as Share } from "../../assets/icons/share-post.svg";
import { ReactComponent as Liked } from "../../assets/icons/filled-heart.svg";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        padding: '0px 16px 20px',
        '& button': {
            padding: 0,
            '&:hover': {
                background: 'none'
            }
        }
    },
    likeCommentRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 80
    },
}));

const ArenaActions = ({ isLiked, onLikeClick = () => { }, onCommentClick = () => { }, onShareClick = () => { }, ...others }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} {...others} >
            <div className={classes.likeCommentRoot}>
                <IconButton disableTouchRipple onClick={onLikeClick} >
                    {isLiked ? <Liked width='27' height='24' /> :<Like />}
                </IconButton>
                <IconButton disableTouchRipple onClick={onCommentClick} >
                    <Comment />
                </IconButton>
            </div>
            <IconButton disableTouchRipple onClick={onShareClick} >
                <Share />
            </IconButton>
        </div>
    )
}

export default ArenaActions;