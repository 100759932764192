import React, { Component } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import ArenaList from './ArenaList'

export const ArenaIndividualPostView = (props) => {
  const { postId } = useParams()
  return (
    <>
      {postId && <ArenaList newPost={props.newPost} handleOnNewPost={props.handleOnNewPost} fromIndividual={true} postId={postId} />}
    </>
  )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ArenaIndividualPostView)
