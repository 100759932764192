// import { delay } from "redux-saga";
import { all, delay, take, put, select, race, call } from "redux-saga/effects";

import {
  connected,
  disconnected,
  DISCONNECTED,
  CONNECTED,
  TRY_RECONNECT
} from "../../ducks/client";
import { getAuthenticated } from "../../selectors";

import { makeChannel } from "../_helpers";
import { deleteMessagingDeviceToken } from "../../ducks/notification";
import { fetchAccessKeyApi } from ".";
import { setSnackbar } from "../../ducks/snackbar";

const getDelay = attempts => (Math.pow(2, Math.min(attempts, 7)) - 1) * 500;

function* disconnectLoop(client) {
  let attempts = 0;

  while (true) {
    const wait = getDelay(attempts);

    console.warn(`XMPP Connection lost, retrying in ${wait}ms`, client);

    const result = yield race({
      connected: take(CONNECTED),
      timeout: delay(wait),
      tryNow: take(TRY_RECONNECT)
    });

    console.log('Re connecting ', result);

    if (result.connected) return;
    if (result.timeout) {
      attempts++;
      yield call([client, client.connect]);
    }
    if (result.tryNow) {
      attempts = 0;
      yield call([client, client.connect]);
    }
  }
}

function connectWithCredentials(client, creds) {
  console.log('reconnectOnDrop credentials 3 ', creds);
  // stanza doesn't properly clear credentials, seems to be a bug
  // console.log('connectWithCredentials ', creds, client);
  client.config.credentials = { ...creds };
  // client.on("session:started", function* () { // not working
  //   console.log('isConnected session ');
  //   yield put(setSnackbar({ open: true, message: 'Connected to chat server...', status: 'success', duration: 2000 }));
  // });
  // setTimeout(() => {
  client.connect();
  // }, 3000);
}


function* getUpdatedAccessKey(token) {
  // try {
  //   let { data } = yield call(fetchAccessKeyApi, token)
  //   console.log('getUpdatedAccessKey success');
  //   return data.token;
  // } catch (e) {
  //   console.log('getUpdatedAccessKey catch err ', e);
  // }
}

function* reconnectOnDrop(client, credentials) {
  while (true) {
    yield take(DISCONNECTED);
    yield put(setSnackbar({ open: true, message: 'Reconnecting to chat server...', type: 'xmpp-reconnecting' }));
    // console.log('reconnectOnDrop credentials 1 ', credentials);
    const token = yield select(state => state.keycloak.token);
    // const accesskey = yield select(state => state.client);
    const accesskey = yield call(getUpdatedAccessKey, token);
    console.log('reconnectOnDrop credentials 2 ', credentials, accesskey);
    yield call(connectWithCredentials, client, { ...credentials, password: accesskey });

    // yield put(deleteMessagingDeviceToken('delete'));
    // let keycloak = yield select(state => state.keycloak);
    // keycloak.logout({
    //   redirectUri: window.location.href
    // });
    // if (yield select(getAuthenticated)) {
    //   yield disconnectLoop(client);
    // }
  }
}

function* watchConnectionStatus(client) {
  // console.log('watchConnectionStatus ', client)
  const channel = makeChannel(client, {
    "session:started": emit => emit({ isConnected: true }),
    disconnected: emit => emit({ isConnected: false }),
    "auth:success": emit => setTimeout(() => emit({ isConnected: true }), 1000)
  });

  window.addEventListener('online', () => {
    client.connect();
    console.log('came online xmpp connected');
  });

  while (true) {
    const { isConnected } = yield take(channel);
    console.log('isConnected ', isConnected, client)
    yield put(isConnected ? connected() : disconnected());
    if (isConnected)
      // yield put(setSnackbar({ open: true, message: 'Connected to chat server...', status: 'success', duration: 2000 }));
      yield put(setSnackbar({ open: false, message: '', type: 'xmpp-reconnected' }));
  }
}

export default function* connection(client, credentials) {
  yield all([watchConnectionStatus(window.client), reconnectOnDrop(client, credentials)]);
}
