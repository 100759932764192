import React, { useState, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
// import { withStyles } from '@material-ui/core/styles'
// import ImgDialog from './ImgDialog'
import { IconButton, makeStyles } from '@material-ui/core';
import { ReactComponent as TagsIcons } from "../../assets/icons/tag-chaseons-3.svg";
import getCroppedImg from './cropImage';
import { AspectRatio, Check, Crop } from '@material-ui/icons'
import { cropTypes } from './CreatePost'
// import { styles } from './styles'

const useStyles = makeStyles(theme => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 64px)',
        // background: '#333',
        [theme.breakpoints.down('sm')]: {
            height: 400,
        },
    },
    cropButton: {
        padding: 8,
        backgroundColor: '#000000b3',
        '&:hover': {
            backgroundColor: '#000000b3',
        },
        '& svg': {
            fill: '#FFF'
        }
    },
    doneButton: {
        height: 34,
        '& svg': {
            marginRight: 5,
            width: 18
        }
    },
    controls: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        width: 'calc(100% - 32px)',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 32,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
}));

const dogImg =
    'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'



const ImageCropper = ({ url = '', mimeType = "IMAGE", cropType, setCropType, index, newCrop, onChange, setCroppedImage }) => {
    const classes = useStyles();
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    // const [croppedImage, setCroppedImage] = useState(null);
    const [showAspectRatioButton, setShowAspectRatioButton] = useState(true);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixelsNew) => {
        // console.log('onCropComplete ', croppedArea, croppedAreaPixelsNew);
        // onChange(index, croppedAreaPixelsNew, 'croppedAreaPixels');
        setCroppedAreaPixels(croppedAreaPixelsNew);
    }, [])

    useEffect(() => {
        if (newCrop) {
            // console.log('newCrop ', newCrop);
            setCrop(newCrop)
            setZoom(1);
        }
    }, [newCrop])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                url,
                croppedAreaPixels,
                rotation,
                cropType?.size
            )
            // console.log('donee', { croppedImage })
            setCroppedImage(croppedImage, index);
            setShowAspectRatioButton(false);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    // const onClose = useCallback(() => {
    //     setCroppedImage(null)
    // }, [])

    // const onMediaLoaded = (a, b, c) => {
    //     console.log('onMediaLoaded ', a, b, c);
    // }

    return (
        <div style={{ position: 'relative' }}>
            <div className={classes.cropContainer}>
                <Cropper
                    image={mimeType === 'IMAGE' && url}
                    // video={mimeType === 'VIDEO' && url}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={cropType?.ratio}
                    restrictPosition={true}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                // onMediaLoaded={onMediaLoaded}
                />
            </div>
            <div className={classes.controls}>
                {/* <div className={classes.sliderContainer}>
                    <Typography
                        variant="overline"
                        classes={{ root: classes.sliderLabel }}
                    >
                        Zoom
          </Typography>
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        classes={{ root: classes.slider }}
                        onChange={(e, zoom) => setZoom(zoom)}
                    />
                </div>
                <div className={classes.sliderContainer}>
                    <Typography
                        variant="overline"
                        classes={{ root: classes.sliderLabel }}
                    >
                        Rotation
          </Typography>
                    <Slider
                        value={rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        classes={{ root: classes.slider }}
                        onChange={(e, rotation) => setRotation(rotation)}
                    />
                </div> */}

                {showAspectRatioButton ? <IconButton title="" className={classes.cropButton} onClick={() => setCropType(cropType.ratio === 1 ? cropTypes[1] : cropType.ratio === 1.9 ? cropTypes[2] : cropTypes[0])} >
                    <AspectRatio color={'#FFF'} />
                </IconButton> : <div></div>}
                <Button variant="contained" color="primary" size="small" onClick={showCroppedImage} className={classes.doneButton}><Check fontSize={'small'} />Done</Button>
            </div>
            {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
        </div>
    )
}

export default ImageCropper;
