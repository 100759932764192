import { all, takeEvery, call, select, put } from "redux-saga/effects";
import Axios from "axios";
import { employeeSearch, globalSearch } from "../../config";
import { GET_CONTACT_SEARCH_RESULT, GET_GLOBAL_SEARCH_RESULT, setGlobalSearchResult, setGlobalSearchQuery } from "../../ducks/search";
import { setSidebarIndex } from "../../ducks/sidebar";


const fetchGlobalSearchResult = (query) => {
    return Axios.get(globalSearch, {
        params: {
            queryParam: query,
            limit: 100,
            page: 0,
        }
    })
}

const fetchEmployeeSearchResult = (query) => {
    return Axios.get(employeeSearch, {
        params: {
            q: query,
            limit: 100,
            page: 0,
        }
    })
}

function* watchForEmployeeSearch(client) {
    yield takeEvery(GET_CONTACT_SEARCH_RESULT, function* getContactSearch(action) {
        try {
            let { data } = yield call(fetchEmployeeSearchResult, action.payload.query);
            const presenceList = yield select((state) => {
                return state.presence;
            });
            if (data) {
                let searchResult = data.reduce((result, item) => {
                    let jid = `${item.username}@${process.env.REACT_APP_XMPP_DOMAIN}`
                    return [...result, {
                        ...item,
                        chat_id: jid,
                        show: presenceList[jid] ? presenceList[jid].show : 'unavailable',
                        isCurrent: false
                    }];
                }, []);
                yield call(action.payload.onSearchSuccessCB, searchResult);
            }
            else {
                throw 'Invalid';
            }
            console.log('watchForEmployeeSearch data ', data);

        } catch (e) {
            yield call(action.payload.onSearchErrorCB, e)
            console.log('watchForEmployeeSearch catch err ', e, action.payload);
        }
    });
}

function* watchForGlobalSearch(client) {
    yield takeEvery(GET_GLOBAL_SEARCH_RESULT, function* getGlobalSearch(action) {
        const { query, onSearchSuccessCB, onSearchErrorCB } = action.payload;
        try {
            let { data } = yield call(fetchGlobalSearchResult, query);
            const presenceList = yield select((state) => {
                return state.presence;
            });
            if (data) {
                let searchResult = data.reduce((result, item) => {
                    if (item.messages) {
                        return [...result, {
                            ...item.messages,
                            index: item.index,
                            type: (item.messages.type && (item.messages.type !== null && item.messages.type !== 'USER')) ? 'group' : 'individual',
                            count: 0,
                            show: presenceList[item.messages.targetJid] ? presenceList[item.messages.targetJid].show : 'unavailable',
                            sentdate: item.index === 'messages' ? parseInt(item.messages.sentdate) : null,
                            isCurrent: false,
                            name: (item.messages.name ? item.messages.name : item.messages.targetJid)
                        }];
                    }
                    else if (item.employees) {
                        let jid = `${item.employees.username}@${process.env.REACT_APP_XMPP_DOMAIN}`;
                        return [...result, {
                            ...item.employees,
                            index: item.index,
                            type: 'individual',
                            count: 0,
                            isCurrent: false,
                            show: presenceList[jid] ? presenceList[jid].show : 'unavailable',
                            active: item.employees.active === "true" ? true : false,
                            chat_id: jid
                        }];
                    }
                    else if (item.teams) {
                        let jid = `${item.teams.id}@chaseservice.${process.env.REACT_APP_XMPP_DOMAIN}`;
                        return [...result, {
                            ...item.teams,
                            index: item.index,
                            type: 'group',
                            count: 0,
                            isCurrent: false,
                            show: 'unavailable',
                            targetJid: jid,
                            team_id: item.teams.id
                        }];
                    }
                }, []);
                // yield call(action.payload.onSearchSuccessCB, searchResult);


                yield put(setGlobalSearchQuery(action.payload.query));
                yield put(setGlobalSearchResult(searchResult));
                // yield put(setSidebarIndex(0))
                yield call(onSearchSuccessCB, searchResult);
            }
            else {
                throw 'Invalid';
            }
            console.log('watchForGlobalSearch data ', data);

        } catch (e) {
            yield call(onSearchErrorCB, e);
            // yield call(action.payload.onSearchErrorCB, e)
            yield put(setGlobalSearchResult([]));
            // yield put(setSidebarIndex(0))
            console.log('watchForGlobalSearch catch err ', e, action.payload);
        }
    });
}

export default function* (client) {
    yield all([watchForEmployeeSearch(client), watchForGlobalSearch()]);
}
