import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort } from "../../../helpers";
import { setLoading } from "../../../ducks/loading";
import { getExportPlayerKpisStats, getTeamBonusKpisStats } from "../../../ducks/game";
import AvatarComponent from "../../AvatarComponent/AvatarComponent";
import ArrowRight from '@material-ui/icons/ArrowRight';
import { Button, fade, FormControl, InputAdornment, InputBase, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import IllustrationView from "../../LiveScores/IllustrationView";
import MaterialUITable from "../../GlobalComponents/MaterialUITable";
import { Tooltip } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { CSVLink } from "react-csv";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete } from "@material-ui/lab";
import StatsPlayerFilterModal from "../PlayerStats/StatsPlayerFilterModal";
import CloseIcon from '@material-ui/icons/Close';
import _ from 'underscore';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export.svg'
import GetCountryCurrencyFormat from "../../GlobalComponents/GetCountryCurrencyFormat";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0px 0 0px',
        '& .material-table td div': {
            border: 'none !important'
        },
        '& .Mui-selected': {
            '& td': {
                color: '#000',
                backgroundColor: 'white !important',
            }
        },
    },
    formRoot: {
        width: 250,
        position: 'relative',
        '& .close_icn': {
            margin: 0,
            position: 'absolute',
            top: '11px',
            right: ' 35px',
            color: '#565050',
            cursor: 'pointer'
        }
    },
    baseWidth: { cursor: 'pointer', borderRadius: 3 },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
    },
    sticky1: {
        position: "sticky",
        left: 0,
        background: "white",
    },
    title: {
        // margin: '0 0 10px',
        fontSize: 11,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.35),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.5),
        },
        width: 'auto',
        maxWidth: 180,
        minWidth: 180,
    },
    searchIcon: {
        color: '#0000004d',
        padding: '0px 8px 0px 25px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    inputRoot: {
        color: 'initial',
        height: '100%',
        width: 'inherit'
    },
    inputInput: {
        border: '1px solid #0000003a',
        borderRadius: '3px',
        height: 23,
        margin: '0 10px',
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        fontSize: '12px'
    },
    autocompleteRoot: {
        width: '200px',
        height: '40px',
        borderRadius: 3,
        overflow: 'clip',
        whiteSpace: 'nowrap',
        "& .MuiAutocomplete-tagSizeSmall": {
            width: '65px !important'
        },
        "& .MuiInputBase-root": {
            overflow: 'hidden !important'
        },
    },
    exportButtn: {
        '& .MuiButton-outlinedPrimary': {
            border: '1px solid #0000003a !important'
        }
    }
}));

const TeamBonusKpisStats = ({ setLoading, loading, seasonId = '', matchId = '', userDetails, getTeamBonusKpisStats, kpis, isAdmin, filtersData, seasonFormat, seasonKpiPostions, seasonDetails, getExportPlayerKpisStats }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [downloadTeamStatData, setDownloadTeamStatData] = useState([]);
    const [searchTeam, setSearchTeam] = useState([]);
    const [selectedkpi, setSelectedkpi] = useState([]);
    const [openTeamFilter, setOpenTeamFilter] = useState(false);
    const [basedOn, setBasedOn] = useState(0);
    const [rankLimit, setRankLimit] = useState({ low: "", high: "" });
    const [achivementsSelects, setAchivementsSelects] = useState([{ kpiName: null, percentageRange: [0, 0], percentageHighest: false }]);
    const [actualsSelects, setActualsSelects] = useState([{ kpiName: null, actualMin: 0, actualMax: 0, actualMeasurement: null }]);
    const [plannedAchivementsSelects, setPlannedAchivementsSelects] = useState([{ kpiName: null, percentageRange: [0, 0], percentageHighest: false }]);

    useEffect(() => {
        if (matchId) {
            if (actualsSelects[0].kpiName !== null || rankLimit.low !== '' || rankLimit.high !== '' || achivementsSelects[0].kpiName !== null) {
                let finalParams = {
                    based_on: basedOn == 0 ? 'RANK' : basedOn == 1 ? 'ACHIEVEMENT' : basedOn == 2 ? 'ACTUAL' : basedOn == 3 ? 'PLANNED_ACHIEVEMENT' : null,
                    performance: basedOn == 0 ? { min: rankLimit.low, max: rankLimit.high } :
                        basedOn == 1 ? achivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) :
                            basedOn == 2 ? actualsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.actualMin, max: d?.actualMax })) :
                                basedOn == 3 ? plannedAchivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) : null
                }
                setLoading(true);
                getTeamBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, filters: JSON.stringify(finalParams), limit: 5000, page: 1 } });
            }
            else {
                setLoading(true);
                getTeamBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, limit: 5000, page: 1 } });
            }
        }
    }, [matchId]);

    const onSuccessCB = (response, resheaders) => {
        if (response) {
            let headers = [];
            // ---------get unique kpi ids from response --------
            const uniquIds = _.uniq(_.pluck(_.flatten(_.pluck(response, 'kpis')), 'kpi_id'));
            let kpisResultes = _.flatten(_.pluck(response, 'kpis'));
            let uniqKpis = [];
            uniquIds.forEach(ele => { uniqKpis.push(kpisResultes.find(ukpi => ukpi.kpi_id === ele)) });
            let uniqKpiHeaders = _.compact(uniqKpis);
            const kpis_attributes = seasonKpiPositionsSort(uniqKpis, seasonKpiPostions, 'kpi_id', 'detailed_hidden');
            // ---------get unique kpi ids from response --------
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points || 0, selected: item._id === resheaders?.my_team_id, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                if (index === 0) {
                    kpis_attributes.filter(el => uniquIds.includes(el.kpi_id)).forEach(headerEle => {
                        const mapped_headers = headerEle.detailed_attributes.map(ele => { return ele.attribute });
                        headerEle['mapped_headers'] = mapped_headers;
                        headers.push(headerEle);
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${headerEle.kpi_name}`,//-target
                            field: `${headerEle.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            // hidden: isRuleBasedOnActuals(headerEle),
                            hidden: !mapped_headers.includes('TARGET'),
                            attribute: headerEle.detailed_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 10 },
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-target`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-target`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-target`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-unit`], rowData[`${headerEle.kpi_id}-target`])
                                            :
                                            [
                                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                                //     `No data available`
                                                //     : 
                                                `${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-target`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-target`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`
                                            ]
                                    : ' '
                            // getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-target`])
                        }, {
                            title: `${headerEle.kpi_name}`, //-Actual
                            field: `${headerEle.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-actual`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-actual`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-actual`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-unit`], rowData[`${headerEle.kpi_id}-actual`])
                                            :
                                            [
                                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                                //     (isRuleBasedOnActuals(el) ? `No data available` : '')
                                                //     : 
                                                `${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-actual`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-actual`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`
                                            ] : ' '
                            // getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-actual`])
                        },
                        {
                            title: `${headerEle.kpi_name}`,
                            field: `${headerEle.kpi_id}-pending_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-pending_actual`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-pending_actual`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-`]} />
                                            :
                                            [`${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-pending_actual`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-pending_actual`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`]
                                    : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`,//-Ach %
                            field: `${headerEle.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) =>
                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                //     '' :
                                rowData[`${headerEle.kpi_id}-achieved`] !== undefined ? getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-achieved`]) + ' %' : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`,//-target
                            field: `${headerEle.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            // hidden: isRuleBasedOnActuals(headerEle),
                            attribute: headerEle.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 10 },
                            hidden: (!headerEle.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-planned_target`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-planned_target`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-planned_target`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-unit`], rowData[`${headerEle.kpi_id}-planned_target`])
                                            :
                                            [
                                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                                //     `No data available`
                                                //     : 
                                                `${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-planned_target`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-planned_target`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`
                                            ]
                                    : ' '
                            // getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-planned_target`])
                        }, {
                            title: `${headerEle.kpi_name}`, //-Actual
                            field: `${headerEle.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 10 },
                            hidden: (!headerEle.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-planned_actual`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-planned_actual`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-planned_actual`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-unit`], rowData[`${headerEle.kpi_id}-planned_actual`])
                                            :
                                            [
                                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                                //     (isRuleBasedOnplanned_actuals(el) ? `No data available` : '')
                                                //     : 
                                                `${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-planned_actual`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-planned_actual`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`
                                            ] : ' '
                            // getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-planned_actual`])
                        },
                        {
                            title: `${headerEle.kpi_name}`,
                            field: `${headerEle.kpi_id}-planned_pending_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PLANNED_PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-planned_pending_actual`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-planned_pending_actual`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-planned_pending_actual`]} />
                                            :
                                            [`${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-planned_pending_actual`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-planned_pending_actual`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`]
                                    : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`,//-Ach %
                            field: `${headerEle.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: (!headerEle.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) =>
                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                //     '' :
                                rowData[`${headerEle.kpi_id}-planned_achievement`] !== undefined ? getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-planned_achievement`]) + ' %' : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`,//-Points
                            field: `${headerEle.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: headerEle.detailed_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 10 },
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) => rowData[`${headerEle.kpi_id}-points`] !== undefined ? getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-points`]) : ' '
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),

                        ]
                    })
                }
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-pending_actual`] = el.pending_actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_pending_actual`] = el.planned_pending_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.score;
                    // obj['totalPoints'] = totalPoints += el.score;
                });
                return [...result, { ...obj, team: { name: obj.name, logo_url: obj.logo_url, _id: obj._id } }];
            }, []);
            if (finalResult.length > 0) {
                setData(finalResult);
                setSearchTeam(finalResult);
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setDownloadTeamStatData(finalResult.map(obj => {
                    return getRowData(obj);
                }))
            }
            else {
                setData([]);
                setSearchTeam([]);
                setDynamicColumns([]);
                setDynamicHeaders([]);
            }
        }
        setLoading(false);
    }
    const getRowData = (empObject) => {
        let obj = { 'Rank': empObject.rank, 'Team Name': empObject.name, 'Total Score': empObject.totalPoints };
        empObject.kpis.map((ele) => {
            if (isRuleBasedOnActuals(ele)) {
                obj[`${ele.kpi_code}-actual`] = ele.actual;
                if (seasonFormat === 'HEAD_TO_HEAD') {
                    obj[`${ele.kpi_code}-score`] = ele.score;

                }
                else {
                    obj[`${ele.kpi_code}-points`] = ele.score;

                }
            }
            else {
                if (ele.achievement_plan_enabled) {
                    obj[`${ele.kpi_code}-target`] = ele.target;
                    obj[`${ele.kpi_code}-actual`] = ele.actual;
                    obj[`${ele.kpi_code}-achieved`] = ele.achieved + " %";
                    obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-target`] = ele?.planned_target;
                    obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-actual`] = ele?.planned_actual;
                    obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-achieved`] = ele?.planned_achievement + " %";
                } else {
                    obj[`${ele.kpi_code}-target`] = ele.target;
                    obj[`${ele.kpi_code}-actual`] = ele.actual;
                    obj[`${ele.kpi_code}-achieved`] = ele.achieved;
                }
                if (seasonFormat === 'HEAD_TO_HEAD') {
                    obj[`${ele.kpi_code}-score`] = ele.score;

                }
                else {
                    obj[`${ele.kpi_code}-points`] = ele.score;

                }
            }

        });
        return obj;
    }
    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const isRuleBasedOnActuals = (kpi) => {
        return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
    }

    // ---kpifilter--   
    const handleChange = (data, field) => {
        if (field === "basedon") setOpenTeamFilter(true);
    }
    const filterOnCancel = (e) => {
        setOpenTeamFilter(false);
    };
    const filterOnOk = (filterData) => {
        let finalParams = {
            based_on: basedOn == 0 ? 'RANK' : basedOn == 1 ? 'ACHIEVEMENT' : basedOn == 2 ? 'ACTUAL' : basedOn == 3 ? 'PLANNED_ACHIEVEMENT' : null,
            performance: basedOn == 0 ? { min: rankLimit.low, max: rankLimit.high } :
                basedOn == 1 ? achivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) :
                    basedOn == 2 ? actualsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.actualMin, max: d?.actualMax })) :
                        basedOn == 3 ? plannedAchivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) : null
        }
        setLoading(true);
        if (matchId) {
            setLoading(true);
            getTeamBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, limit: 5000, page: 1, filters: JSON.stringify(finalParams) } });
        }
        setOpenTeamFilter(false);
    };
    //   -----rank filters----
    const handleRankChange = (e, field) => {
        setRankLimit({ ...rankLimit, [field]: e.target.value });
    };
    const handleAchieveChange = (e, i, value, field) => {
        let newAchiveValues = [...achivementsSelects];
        let prevRangeValues = JSON.parse(JSON.stringify(newAchiveValues[i]));
        if (field == "percentageMin") {
            prevRangeValues.percentageRange[0] = e.target.value;
            newAchiveValues[i] = prevRangeValues;
            setAchivementsSelects(newAchiveValues);
        }
        else if (field == "percentageMax") {
            prevRangeValues.percentageRange[1] = e.target.value;
            newAchiveValues[i] = prevRangeValues;
            setAchivementsSelects(newAchiveValues);
        }
        else {
            newAchiveValues[i][field] = value;
            setAchivementsSelects(newAchiveValues);
        }
    };
    const handleActualChange = (e, i, value, field) => {
        let newActualValues = [...actualsSelects];
        if (field === "kpiName") {
            newActualValues[i][field] = value;
            setActualsSelects(newActualValues);
        }
        else {
            newActualValues[i][field] = e.target.value;
            setActualsSelects(newActualValues);
        }
    };
    const addAchievmntFields = () => {
        setAchivementsSelects([...achivementsSelects, { kpiName: null, percentageRange: [0, 0], percentageHighest: false }])
    }
    const addAllAchievmntFields = () => {
        setAchivementsSelects(kpis.map((data) => ({ kpiName: { id: data.kpi_id, label: data.kpi_name }, percentageRange: [0, 0], actualMax: 0, percentageHighest: false })))
    }
    const removeAchievmntFields = (i) => {
        let newAchiveRemoveValues = [...achivementsSelects];
        newAchiveRemoveValues.splice(i, 1);
        setAchivementsSelects(newAchiveRemoveValues);
    }
    //   -----planned achievments filters----
    const handlePlannedAchieveChange = (e, i, value, field) => {
        let newAchiveValues = [...plannedAchivementsSelects];
        let prevRangeValues = JSON.parse(JSON.stringify(newAchiveValues[i]));
        if (field == "percentageMin") {
            prevRangeValues.percentageRange[0] = e.target.value;
            newAchiveValues[i] = prevRangeValues;
            setPlannedAchivementsSelects(newAchiveValues);
        }
        else if (field == "percentageMax") {
            prevRangeValues.percentageRange[1] = e.target.value;
            newAchiveValues[i] = prevRangeValues;
            setPlannedAchivementsSelects(newAchiveValues);
        }
        else {
            newAchiveValues[i][field] = value;
            setPlannedAchivementsSelects(newAchiveValues);
        }
    };
    const addPlannedAchievmntFields = () => {
        setPlannedAchivementsSelects([...plannedAchivementsSelects, { kpiName: null, percentageRange: [0, 0], percentageHighest: false }])
    }
    const addAllPlannedAchievmntFields = () => {
        setPlannedAchivementsSelects(kpis.map((data) => ({ kpiName: { id: data.kpi_id, label: data.kpi_name }, percentageRange: [0, 0], actualMax: 0, percentageHighest: false })))
    }
    const removePlannedAchievmntFields = (i) => {
        let newAchiveRemoveValues = [...plannedAchivementsSelects];
        newAchiveRemoveValues.splice(i, 1);
        setPlannedAchivementsSelects(newAchiveRemoveValues);
    }
    //   -----planned achievments filters----
    const addActualFields = () => {
        setActualsSelects([...actualsSelects, { kpiName: null, actualMin: 0, actualMax: 0, actualMeasurement: null }])
    }
    const addAllActualFields = () => {
        setActualsSelects(kpis.map((data) => ({ kpiName: { id: data.kpi_id, label: data.kpi_name }, actualMin: 0, actualMax: 0, actualMeasurement: data.unit_of_measurement })))
    }
    const removeActualFields = (i) => {
        let newActualRemoveValues = [...actualsSelects];
        newActualRemoveValues.splice(i, 1);
        setActualsSelects(newActualRemoveValues);
    }
    const handleClearBasedon = () => {
        setRankLimit({ low: "", high: "" });
        setAchivementsSelects([{ kpiName: null, percentageRange: [0, 0], percentageHighest: false }]);
        setPlannedAchivementsSelects([{ kpiName: null, percentageRange: [0, 0], percentageHighest: false }]);
        setActualsSelects([{ kpiName: null, actualMin: 0, actualMax: 0, actualMeasurement: null }]);
        setBasedOn('');
        if (matchId) {
            setLoading(true);
            getTeamBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, limit: 5000, page: 1 } });
        }
    }

    const handleKeySearch = (searchedVal) => {
        const filteredRows = searchTeam.filter((row) => {
            return row.name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        if (searchedVal.length === 0) {
            setData(searchTeam);
            setDownloadTeamStatData(searchTeam.map(obj => {
                return getRowData(obj);
            }))
        }
        else {
            setData(filteredRows);
            setDownloadTeamStatData(filteredRows.map(obj => {
                return getRowData(obj);
            }))
        }
    };


    // ---------season players download------
    const downloadStatsData = () => {
        let type = 'TEAM_BONUS'
        if (actualsSelects[0].kpiName !== null || rankLimit.low !== '' || rankLimit.high !== '' || achivementsSelects[0].kpiName !== null) {
            let finalParams = {
                based_on: basedOn == 0 ? 'RANK' : basedOn == 1 ? 'ACHIEVEMENT' : basedOn == 2 ? 'ACTUAL' : basedOn == 3 ? 'PLANNED_ACHIEVEMENT' : null,
                performance: basedOn == 0 ? { min: rankLimit.low, max: rankLimit.high } :
                    basedOn == 1 ? achivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageRange[0], max: d?.percentageRange[1] })) :
                        basedOn == 2 ? actualsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.actualMin, max: d?.actualMax })) :
                            basedOn == 3 ? plannedAchivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) : null
            }
            setLoading(true);
            getExportPlayerKpisStats({
                seasonId, type, params: {
                    match_id: matchId === 'ALL' ? '' : matchId,
                    filters: JSON.stringify(finalParams),
                },
                onSuccessCB: (result) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `teamBonusKpiStats.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setLoading(false);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    if (error.response)
                        enqueueSnackbar((error.response.data && error.response.data.message) ? error.response.data.message : "Failed to export, Please try again", {
                            variant: "error",
                            preventDuplicate: true
                        });
                },
            });
        }
        else {
            setLoading(true);
            getExportPlayerKpisStats({
                seasonId, type, params: {
                    match_id: matchId === 'ALL' ? '' : matchId,
                },
                onSuccessCB: (result) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `teamBonusKpiStats.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setLoading(false);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    if (error.response)
                        enqueueSnackbar((error.response.data && error.response.data.message) ? error.response.data.message : "Failed to export, Please try again", {
                            variant: "error",
                            preventDuplicate: true
                        });
                },
            });
        }
    }

    const columns = [
        {
            title: "Rank",
            field: "rank",
            emptyValue: '--',
            editable: "onAdd",
            cellStyle: {
                minWidth: 70, position: "sticky",
                left: 0,
                background: "white"
            },
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: {
                minWidth: 180, position: "sticky",
                left: 91,
                background: "white"
            },
            emptyValue: '--',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--'
        },
        {
            title: "Total Pts",
            field: "totalPoints",
            editable: 'never',
            cellStyle: {
                minWidth: 120, position: "sticky",
                left: '291px',
                background: "white"
            },
            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData.totalPoints)
        }
    ]


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <FormControl fullWidth className={classes.formRoot}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Filter by"
                            size="small"
                            InputProps={{
                                readOnly: true,
                                classes: { root: classes.baseWidth }, endAdornment: (
                                    <InputAdornment position="end">
                                        <ArrowRight />
                                    </InputAdornment>
                                )
                            }}
                            value={basedOn == 0 ? 'Rank' : basedOn == 1 ? 'Achievement' : basedOn == 2 ? 'Actuals' : basedOn == 3 ? 'Mtd/Wtd Achievement' : ''}
                            onClick={(e) => handleChange(e, "basedon")}
                        />
                        {actualsSelects[0].kpiName !== null || rankLimit.low !== '' || rankLimit.high !== '' || achivementsSelects[0].kpiName !== null || plannedAchivementsSelects[0].kpiName !== null ? <p onClick={() => handleClearBasedon()} className='close_icn'><CloseIcon style={{ fontSize: '18px' }} /></p> : ''}
                    </FormControl>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search Team.."
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => { handleKeySearch(e.target.value) }}
                        />
                    </div>
                    <div className={classes.exportButtn}>
                        {isAdmin &&
                            <Button startIcon={<ExportIcon />}
                                onClick={e => downloadStatsData(e)}
                                variant="outlined" color='primary' style={{ fontSize: '15px', margin: '0px 8px', padding: '0px 12px', height: 40 }}>
                                Export
                            </Button>
                        }
                    </div>
                </div>
            </div>
            {(data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                <div className={classes.root}>
                    <div className="material-table">
                        {/* <p style={{ fontSize: 11, color: '#58595B', margin: '5px 0 10px', textAlign: 'end' }}>*Note: Points will appear only after match is closed.</p> */}
                        {/* <p className={classes.title}><Info />{"Points will appear only after match is closed"}</p> */}
                        <MaterialUITable
                            title={'Match Summary'}
                            columns={[...columns, ...dynamicColumns]}
                            data={data}
                            options={{
                                search: false,
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                emptyRowsWhenPaging: false,
                                toolbar: false,
                                pageSizeOptions: [5, 10, 25],
                                pageSize: 25,
                                paging: data.length > 5 ? true : false
                            }}
                            localization={{ body: { emptyDataSourceMessage: <p style={{ textAlign: 'center' }}>No data available</p> } }}
                            components={{
                                Header: props => (
                                    <TableHead className="custom-headers">
                                        <TableRow>
                                            <TableCell style={{ position: "sticky", left: 0, background: "white" }} rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                            <TableCell style={{ position: "sticky", left: 91, background: "white" }} rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                            {/* <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell> */}
                                            {/* <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell> */}
                                            <TableCell style={{ position: "sticky", left: '291px', background: "white" }} rowSpan={2}>{props.columns[2]?.title}</TableCell>
                                            {/* <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell> */}
                                            {/* <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell> */}
                                            {
                                                dynamicHeaders.map((obj, index) => (
                                                    <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                        {/* <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip> */}
                                                        {obj.kpi_name}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                        <TableRow>
                                            {
                                                dynamicHeaders.map((obj) => (
                                                    <>
                                                        {
                                                            obj?.detailed_attributes.length ?
                                                                <>
                                                                    {
                                                                        obj?.detailed_attributes.map(ele => (
                                                                            ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                        ))

                                                                    }
                                                                    {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                                </>
                                                                :
                                                                <>
                                                                    {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                                    {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>}
                                                                    {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                                </>
                                                        }
                                                        {/* {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                        <TableCell align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>
                                                        {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                        <TableCell align="center">{seasonFormat === 'HEAD_TO_HEAD' ? 'Score' : 'Points'}</TableCell> */}
                                                    </>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                )
                            }}
                        />
                    </div>
                </div>
            }
            {openTeamFilter && (
                <StatsPlayerFilterModal
                    open={openTeamFilter}
                    onClickCancel={filterOnCancel}
                    onClickOk={filterOnOk}
                    dynamicHeaders={dynamicHeaders}
                    kpis={kpis}
                    title={filtersData?.season?.name}
                    basedOn={basedOn}
                    setBasedOn={setBasedOn}                           //based on active tab
                    handleRankChange={handleRankChange}               //rank handlechanges
                    rankLimit={rankLimit}                             //rank selected fields
                    handleAchieveChange={handleAchieveChange}         //achievement fields onchange 
                    achivementsSelects={achivementsSelects}           //achievement selected fields
                    actualsSelects={actualsSelects}                   //actuals selkected fields 
                    handleActualChange={handleActualChange}           //actuals fields onchange 
                    addAchievmntFields={addAchievmntFields}           //achievement add fields
                    addAllAchievmntFields={addAllAchievmntFields}     //all add achvmnts fields
                    removeAchievmntFields={removeAchievmntFields}     //achievement remove fields
                    addActualFields={addActualFields}                 //actual add fields
                    addAllActualFields={addAllActualFields}           //all add actual fields
                    removeActualFields={removeActualFields}           //actual remove fields
                    handlePlannedAchieveChange={handlePlannedAchieveChange} //planned achievement onchange
                    addPlannedAchievmntFields={addPlannedAchievmntFields}  //planned achievement fields onchange
                    addAllPlannedAchievmntFields={addAllPlannedAchievmntFields} //add all planned achievement fields
                    removePlannedAchievmntFields={removePlannedAchievmntFields}  //remove planned ach fields
                    plannedAchivementsSelects={plannedAchivementsSelects}  //planned achievement selects
                />
            )}
        </>)
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonKpiPostions: state.game.seasonKpiPostions,
    seasonDetails: state.game.seasonDetails

});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamBonusKpisStats: options => dispatch(getTeamBonusKpisStats(options)),
        getExportPlayerKpisStats: (options) => dispatch(getExportPlayerKpisStats(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamBonusKpisStats));