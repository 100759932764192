import { all, call, takeEvery, put } from "redux-saga/effects";
import Axios from "axios";
import { GET_NUDGE_LIST, UPDATE_NUDGE_API } from "../../config";
import { GET_NUDGES, UPDATE_NUDGE } from "../../ducks/nudge";


const nudgesList = (params) => {
    return Axios.get(GET_NUDGE_LIST, { params });
};

const updateNudge = (nudgeId, requestObj, params) => {
    return Axios.put(UPDATE_NUDGE_API(nudgeId), { ...requestObj });
};



function* watchForGetListOfLeagues() {
    yield takeEvery(GET_NUDGES, function* getNudgesList(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(nudgesList, params);
            if (data) {
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
        }
    })
};

function* watchForUpdateNudge() {
    yield takeEvery(UPDATE_NUDGE, function* updateNudgeNotify(action) {
        const { nudzeId, reqObj, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data } = yield call(updateNudge, nudzeId, reqObj);
            if (data) {
                yield call(onSuccessCB, data);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
        }
    })
};


export default function* () {
    yield all([
        watchForGetListOfLeagues(),
        watchForUpdateNudge(),
    ])
}