import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableHead, TableRow, IconButton, MenuItem, Menu } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import {
    decodeEnum,
    downloadFiles,
    getCurrencyFormat,
    getDurationWithUnit,
    getNumberFormat,
    seasonKpiPositionsSort,
} from "../../helpers";
import {
    getDivisionsList,
    getMyTeamLivePerformance,
    getRoundTeamStatistics,
    getTeamRaceLiveScore,
    shareLiveScoreDialog,
} from "../../ducks/game";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import IllustrationView from "./IllustrationView";
import { ArrowDownward, ArrowForward, Email, Share } from "@material-ui/icons";
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import { useRouteMatch, withRouter } from "react-router-dom";
import { GetDates } from ".";
import { toJpeg } from "html-to-image";
import PerformanceTableTemplate from "../GlobalComponents/PerformanceTableTemplate";
import PerformanceTableEmailTemplate from "../GlobalComponents/PerformanceTableEmailTemplate";
import "./TablesLayout.css";
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import TeamSummaryDivisionWise from "./TeamSummaryDivisionWise";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";
import { resizeImageToSquare } from "../ArenaComponents/cropImage";
import { ReactComponent as ReactArenaLogo } from "../../assets/icons/arena-1.svg";
import { openCreateArenaDialog } from '../../ducks/arena';
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
    root: {
        // margin: '15px 0 0px',
        marginBottom: 20,
        minHeight: 285,
        // '& .material-table td div': {
        //     border: 'none !important'
        // }
    },
    toolbarRoot: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 7,
    },
    nameRoot: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        fontSize: 12,
        fontWeight: 600,
    },
    title: {
        fontSize: 18,
        // margin: '25px 0 0',
        margin: 0,
    },
    button: {
        // height: 47,
        margin: "0 10px",
        "&:last-child": {
            marginRight: 0,
        },
    },
    viewAllButton: {
        margin: "0 10px",
        color: "blue", // #112386
        fontSize: 11,
        float: "right",
        cursor: "pointer",
        textDecoration: "underline",
        // fontWeight: 600,
        "& svg": {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: "sub",
        },
    },
    radioGroup: {
        alignItems: "center",
        justifyContent: "flex-end",
        "& .viewByText": {
            fontWeight: 600,
            marginRight: 10,
            fontSize: 12,
        },
        "& label": {
            height: 30,
            "&:last-child": {
                marginRight: 0,
            },
        },
    },
    radioLabel: {
        "& span": {
            fontSize: 12,
        },
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: "pointer",
        height: 24,
        width: 24,
        border: '2px solid #FFF',
        fontWeight: 600
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    shareOptionStyles: {
        fontSize: 14,
        '& #share-to-wall': {
            width: 20,
            height: 20,
            marginRight: 10,
            // '& path': {
            //     fill: theme.palette.primary.main
            // },
            // '& path[fill="white"]': {
            //     fill: '#FFF',
            // },
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& #download': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        },
        '& #Email': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        }
    },
}));

let pagination = {
    limit: 5,
    page: 0,
}

const TopFiveTeamSummary = ({ roundId, keycloak, filtersData, getRoundTeamStatistics, getTeamRaceLiveScore, shareLiveScoreDialog, setLoading, matchId = '', userDetails, loading, seasonFormat, dialog, history, openCreateArenaDialog, uploadLogDate, getDivisionList, matchesList, seasonKpiPostions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [canDownload, setCanDownload] = useState(false);

    const [data, setData] = useState([]);
    const [dataByDiv, setDataByDiv] = useState(null);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [teamDetails, setTeamDetails] = useState({});
    const [viewBy, setViewBy] = useState("TEAM");
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);
    const [shareAnchorEl, setShareAnchorEl] = React.useState({
        el: null,
        arena: null
    });
    const isShareMenuOpen = Boolean(shareAnchorEl.el);
    const [divisions, setDivisions] = useState([]);
    const isAdmin =
        keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes("admin");

    // useEffect(() => {
    //     if (roundId) {
    //         setLoading(true);
    //         getMyTeamLivePerformance({
    //             seasonId: filtersData.season?._id,
    //             params: { round_id: roundId, limit: 5, page: 1 },
    //             onSuccessCB: (result) => {
    //                 // console.log('result ', result);
    //                 getRoundTeamStatistics({ roundId, onSuccessCB: (...args) => onSuccessCB(...args, result[0]), onErrorCB, params: { limit: 5, page: 1 } })
    //                 setTeamDetails(result[0]);
    //             }, onErrorCB: (error) => {
    //                 setLoading(false);
    //                 enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch My team performance', {
    //                     variant: 'error',
    //                     preventDuplicate: true
    //                 });
    //             }
    //         });
    //     }
    // }, [roundId]);

    useEffect(() => {
        if (roundId && roundId !== 'ALL' && !filtersData.season?.divisions_enabled) {
            getRoundTeamStatistics({ roundId, onSuccessCB, onErrorCB, params: { limit: 5, page: 1 } })
            // setTeamDetails(result[0]);
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
    }, [roundId]);

    useEffect(() => {
        if (matchId && matchId !== 'ALL' && (seasonFormat === 'TEAM_RACE' && !filtersData.season?.divisions_enabled)) {
            setLoading(true);
            getTeamRaceLiveScore({
                matchId,
                onSuccessCB,
                onErrorCB,
                params: { limit: 5, page: 1 },
            });
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
    }, [matchId]);

    // useEffect(() => {
    //     if (resData.response?.length > 0) {
    //         onSuccessCB(resData.response, resData.headers, true);
    //     }
    // }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        // if (!fromDecimalView) {
        //     setResData({ response, headers: resheaders });
        // }
        if (response.length) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = {
                    ...item,
                    totalPoints: item.total_score,
                    selected: item._id === resheaders?.my_team_id,
                    hilighted: index === 0,
                    kpis: item.kpis.sort((a, b) => (a.kpi_id > b.kpi_id ? 1 : -1)),
                    kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'summary_hidden'),
                };
                // let totalPoints = 0;
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    // obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    // obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    // obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE' || 'HEAD_TO_HEAD') ? el.score || 0 : el.points || 0);
                    if (index === 0) {
                        const mapped_headers = el.summary_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[
                            {
                                title: `${el.kpi_code || el.kpi_name}`,
                                field: `${el.kpi_id}-target`,
                                kpiname: `${el.kpi_name}`,
                                editable: 'never',
                                kpi: el.kpi_id,
                                emptyValue: '--',
                                cellStyle: { maxWidth: 150, minWidth: 100 },
                                attribute: el.summary_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('TARGET'),
                                templateRender: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`,
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_code || el.kpi_name}`,
                                field: `${el.kpi_id}-actual`,
                                kpiname: `${el.kpi_name}`,
                                editable: 'never',
                                emptyValue: '--',
                                cellStyle: el?.based_on === 'ACTUAL' ? { color: el.status_code, maxWidth: 150, minWidth: 100 } : { maxWidth: 150, minWidth: 100 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('ACTUAL'),
                                templateRender: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`,
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                                // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-achieved`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                cellStyle: el?.based_on === 'ACHIEVEMENT' ? { color: el.status_code } : {},
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('ACHIEVEMENT'),
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                            },
                            // {
                            //     title: `${el.kpi_name}`,
                            //     field: `${el.kpi_id}-planned_target`,
                            //     editable: 'never',
                            //     emptyValue: '--',
                            //     align: 'center',
                            //     attribute: el.summary_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 9 },
                            //     hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            //     render: (rowData) =>
                            //         rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                            //             <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                            //             : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                            //                 <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                            //                 : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // },
                            // {
                            //     title: `${el.kpi_name}`,
                            //     field: `${el.kpi_id}-planned_actual`,
                            //     editable: 'never',
                            //     emptyValue: '--',
                            //     align: 'center',
                            //     cellStyle: el?.based_on === 'ACTUAL' ? { color: el.status_code } : {},
                            //     attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 9 },
                            //     hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            //     render: (rowData) =>
                            //         rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                            //             <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                            //             : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                            //                 <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                            //                 : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // },
                            // {
                            //     title: `${el.kpi_name}`,
                            //     field: `${el.kpi_id}-planned_achievement`,
                            //     editable: 'never',
                            //     emptyValue: '--',
                            //     align: 'center',
                            //     cellStyle: el?.based_on === 'ACHIEVEMENT' ? { color: el.status_code } : {},
                            //     attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 9 },
                            //     hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            //     render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                            // },
                            {
                                title: `${el.kpi_name}`,//-Points
                                field: `${el.kpi_id}-points`,
                                editable: 'never',
                                emptyValue: '--',
                                hidden: !mapped_headers.includes('POINTS'),
                                attribute: el.summary_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 9 },
                                align: 'center',
                                cellStyle: { whiteSpace: 'nowrap' },
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                            }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [
                    ...result,
                    {
                        ...obj,
                        team: {
                            name: obj.name || obj.team_name,
                            logo_url: obj.logo_url || obj.team_logo_url,
                            _id: obj._id,
                        },
                    },
                ];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData(finalResult);
            } else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
            }
        } else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
        // enqueueSnackbar(
        //     error.response && error.response.data
        //         ? error.response.data.error_description
        //         : "Failed to fetch summary",
        //     {
        //         variant: "error",
        //         preventDuplicate: true,
        //     }
        // );
    };

    useEffect(() => {
        if (filtersData?.season?._id) {
            setDivisions([]);
            getDivisionList({
                seasonId: filtersData?.season?._id,
                params: { limit: pagination.limit, page: 1 },
                onSuccessCB: onDivisionSuccessCB,
                onErrorCB: onDivisionErrorCB
            })
        }
        else setDivisions([]);
    }, [filtersData.season?._id])


    const getDivisions_List = () => {
        let seasonId = filtersData?.season?._id
        setLoading(true);
        getDivisionList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: pagination.page + 1, },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const onDivisionSuccessCB = (result, headers) => {
        if (result) {
            setDivisions(prevState => ([...prevState, ...result]));
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }
    const onDivisionErrorCB = () => { }

    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: "--",
            editable: "never",
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },
        {
            // title: viewBy === 'CAPTAIN' ? 'Captain' : viewBy === 'MENTOR' ? 'Mentor' : "Team",
            title: "Team",
            field: "team",
            cellStyle: { maxWidth: 220, width: 220 },
            emptyValue: "--",
            editable: "never",
            render: (rowData) =>
                rowData.team ? (
                    <div style={{ display: "flex", alignItems: "center", fontSize: 12 }}>
                        <div className={classes.nameRoot}>
                            <AvatarComponent
                                type='TEAM'
                                name={rowData.team?.name}
                                username={rowData.team?._id}
                                url={rowData.team?.logo_url}
                                className={classes.avatarStyles}
                                onClick={() => { }}
                                style={{ marginRight: 8 }}
                                filtersData={filtersData}
                            />
                        </div>
                        <div style={{ textAlign: "left", fontWeight: 600 }}>
                            {rowData.team && rowData.team.name}
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 9,
                                    fontWeight: 400,
                                }}
                            >
                                {rowData.captain && (
                                    <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>
                                )}
                                {rowData.mentor && <span>M : {rowData.mentor}</span>}
                            </div>
                        </div>
                    </div>
                ) : (
                    "--"
                ),
            templateRender: (rowData) =>
                rowData.team ? (
                    <div>
                        {rowData.team && rowData.team.name.toUpperCase()}
                        <div
                            style={{
                                //display: "flex",
                                alignItems: "center",
                                fontSize: 14,
                                whiteSpace: "pre-wrap",
                                fontWeight: 600,
                            }}
                        >
                            {rowData.captain && (
                                <div
                                    style={{
                                        display: "flex",
                                        whiteSpace: "now-wrap",
                                    }}
                                >
                                    <span>
                                        <span>C</span> : {rowData.captain.toUpperCase()}
                                    </span>
                                </div>
                            )}
                            {rowData.mentor && (
                                <div
                                    style={{
                                        display: "flex",
                                        whiteSpace: "now-wrap",
                                    }}
                                >
                                    <span>
                                        <span>M</span> : {rowData.mentor.toUpperCase()}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    "--"
                ),
            // render: (rowData) => (rowData.team && viewBy === 'TEAM') ? (
            //     <div className={classes.nameRoot}>
            //         {rowData.selected && <p style={{ margin: '0 0 5px', fontSize: 9, textAlign: 'left' }}>My team</p>}
            //         <AvatarComponent name={rowData.team.name} username={rowData.team._id} url={rowData.team.logo_url} onClick={() => { }} style={{ margin: 'auto', border: '1px solid #dfe1e4' }} />
            //         <span >{rowData.team && rowData.team.name}</span>
            //     </div>
            // ) : (viewBy === 'CAPTAIN') ? <span style={{ fontSize: 12 }}>{rowData.captain}</span> : (viewBy === 'MENTOR') ? <span style={{ fontSize: 12 }}>{rowData.mentor}</span> : '--'
        },
        {
            title: "Score",
            field: "totalPoints",
            hidden: filtersData?.season?.season_format !== 'HEAD_TO_HEAD',
            editable: 'never',
            cellStyle: { maxWidth: 100, width: 100 },
            templateRender: (rowData) => (filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
                `${getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)} ${decodeEnum(filtersData.season?.scoring_unit)}${rowData.totalPoints === 1 ? '' : 's'}` : getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView),
            render: (rowData) => <span style={{ fontWeight: 600 }}>{(filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
                `${getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)} ${decodeEnum(filtersData.season?.scoring_unit)}${rowData.totalPoints === 1 ? '' : 's'}` : getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</span>
            // (rowData) => (filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
            //     <div style={{ position: 'relative', margin: '8px 0' }}><div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints)}</div><div style={{ fontSize: 9, position: 'absolute', bottom: -8, left: 0, width: '100%' }}>{decodeEnum(filtersData.season?.scoring_unit)}{(rowData.totalPoints === 1 ? '' : 's')}</div></div>
            //     : <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints)}</div>,
        },
        {
            title: "Points",
            field: "totalPoints",
            hidden: filtersData?.season?.season_format === 'HEAD_TO_HEAD',
            editable: 'never',
            cellStyle: { minWidth: 100, width: 100 },
            templateRender: (rowData) => (
                <span style={{ fontWeight: 600 }}>{filtersData.season?.season_format === "HEAD_TO_HEAD"
                    ? `${getNumberFormat(
                        userDetails?.countryCode,
                        rowData.totalPoints
                    )} ${decodeEnum(filtersData.season?.scoring_unit)}${rowData.totalPoints === 1 ? "" : "s"
                    }`
                    : getNumberFormat(userDetails?.countryCode, rowData.totalPoints)}</span>
            ),
            render: (rowData) => (
                <span style={{ fontWeight: 600 }}>
                    {filtersData.season?.season_format === "HEAD_TO_HEAD"
                        ? `${getNumberFormat(
                            userDetails?.countryCode,
                            rowData.totalPoints,
                            decimalView
                        )} ${decodeEnum(filtersData.season?.scoring_unit)}${rowData.totalPoints === 1 ? "" : "s"
                        }`
                        : getNumberFormat(
                            userDetails?.countryCode,
                            rowData.totalPoints,
                            decimalView
                        )}
                </span>
            ),
            // (rowData) => (filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
            //     <div style={{ position: 'relative', margin: '8px 0' }}><div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints)}</div><div style={{ fontSize: 9, position: 'absolute', bottom: -8, left: 0, width: '100%' }}>{decodeEnum(filtersData.season?.scoring_unit)}{(rowData.totalPoints === 1 ? '' : 's')}</div></div>
            //     : <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints)}</div>,
        },
    ];

    const SummaryTable = ({ data }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns, {
                title: "Points",
                field: "totalPoints",
                editable: 'never',
                templateRender: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>,
                render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
            }]}
            data={data}
            options={{
                // search: false,
                // actionsColumnIndex: -1,
                // showTitle: false,
                // addRowPosition: 'first',
                // emptyRowsWhenPaging: false,
                // toolbar: false,
                // pageSizeOptions: [5, 10, 25],
                pageSize: 5,
            }}
            components={{
                Header: (props) => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {
                                filtersData.season?.season_format === 'TEAM_RACE' ?
                                    <>
                                        <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[1]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[3]?.title}</TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[1]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[2]?.title}</TableCell>
                                    </>
                            }
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                        <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                    </TableCell>
                                ))
                            }
                            <TableCell rowSpan={2}>Points</TableCell>
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeaders.map((obj) => (
                                    <>
                                        {
                                            obj?.summary_attributes.length ?
                                                <>
                                                    {
                                                        obj?.summary_attributes.map(ele => (
                                                            ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                        ))
                                                    }
                                                    {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                </>
                                                :
                                                <>
                                                    {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                    {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: '2px solid #f0f2f5' }} >Actual</TableCell>}
                                                    {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                </>
                                        }
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                ),
            }}
        />
    );
    const handleViewAll = () => {
        history.push(`${path}/all-team-performances${history.location?.search}`);
    };

    const handleDetailedScore = () => {
        history.push(
            `${path}/detailed-team-performances${history.location?.search}`
        );
    };

    const handleShareScore = (id) => {
        shareLiveScoreDialog({ open: true, fixture: 'ROUND', notify: 'TEAM', details: { seasonId: filtersData.season?._id, roundId: filtersData.round?._id, imageId: id }, filtersData: filtersData });
        handleShareMenuClose();
    }

    const handleDownloadPerformance = async (id, title, type) => {
        await setCanDownload(true);
        toJpeg(document.getElementById(id))
            .then((dataUrl) => {
                setCanDownload(false);

                try {
                    const img = new Image();
                    img.onload = async function () {
                        let high = (this.width > this.height) ? 'width' : 'height';
                        const resultImage = (this.width > 0 && this.height > 0) ? await resizeImageToSquare(dataUrl, { width: this[high], height: this[high] }) : null;
                        if (resultImage) {
                            if (type === 'download') {
                                downloadFiles(resultImage, title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-"));
                                handleShareMenuClose();
                            }
                            else {
                                openCreateArenaDialog({
                                    open: true, arena: {}, stepType: 'crop', attachment: [
                                        { url: resultImage, mimeType: "IMAGE", width: 4, height: 4, fileName: "kpi_performance_HHD-49_kpi_ck_bk -2022-08-18T133448.437.png", fileType: "image/png" }],
                                    postDetails: {
                                        targetPage: "TEAM_LIVE_SCORES",
                                        details: {
                                            league: filtersData?.league?._id,
                                            season: filtersData?.season?._id,
                                            round: filtersData?.season?.season_format === 'HEAD_TO_HEAD' ? filtersData?.round?._id : null,
                                            match: filtersData?.season?.season_format === 'TEAM_RACE' ? filtersData?.match?._id : null,

                                        }
                                    }
                                });
                                handleShareMenuClose();
                            }
                        }
                    }
                    img.src = dataUrl;
                    return img.onload();
                } catch (e) {
                }
            })
    }

    const handleShareMenuOpen = (event) => {
        setShareAnchorEl({ el: event.currentTarget, arena: null });
    };

    const handleShareMenuClose = () => {
        setShareAnchorEl({ el: null, arena: null });
    };


    const renderShareOptions = (

        <Menu
            anchorEl={shareAnchorEl.el}
            classes={{ paper: classes.profilePopup }}
            id="share-options-menu"
            keepMounted
            open={isShareMenuOpen}
            onClose={handleShareMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            style={{ zIndex: 1301 }}
        >
            <MenuItem onClick={() => handleDownloadPerformance('team-perfomances-table', `Team_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : filtersData.match?.title}`, 'download')} className={classes.shareOptionStyles} ><GetAppOutlinedIcon id="download" />Download</MenuItem>
            <MenuItem onClick={() => handleDownloadPerformance('team-perfomances-table', `Team_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : filtersData.match?.title}`, 'share')} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-wall" />Share to Wall</MenuItem>
            <MenuItem onClick={() => handleShareScore('team-perfomances-table1')} className={classes.shareOptionStyles}><MailOutlineOutlinedIcon id="Email" />Email </MenuItem>
        </Menu>
    );
    const teamEmailTemplateColumns = columns.filter((obj, i) => filtersData?.season?.season_format === "HEAD_TO_HEAD" ? obj.title !== 'Points' : obj.title !== 'Score')

    return (
        <div>
            {
                (matchesList.length === 0) ? < IllustrationView loading={false} style={{ marginTop: '50px' }} /> :

                    [
                        (filtersData.season?.divisions_enabled) ?
                            <>
                                <InfiniteScroll
                                    dataLength={divisions.length}
                                    next={getDivisions_List}
                                    hasMore={divisions.length !== pagination.total_count}
                                    // loader={skeletonLoading}
                                    scrollableTarget="livescoresRoot"
                                >
                                    {
                                        divisions.map((ele, index) => (
                                            <TeamSummaryDivisionWise key={ele._id} index={index} divisionObj={ele} limit={5} columns={columns} filtersData={filtersData} type="TOPFIVE" decimalView={decimalView} setDecimalView={setDecimalView} uploadLogDate={uploadLogDate} />
                                        ))
                                    }

                                </InfiniteScroll >
                            </>
                            :
                            <div className={classes.root}>
                                {data.length > 0 && shareAnchorEl.el &&
                                    <><PerformanceTableTemplate
                                        id="team-perfomances-table"
                                        filtersData={filtersData}
                                        belongsTo='Teams'
                                        columns={columns}
                                        dynamicColumns={dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')}
                                        data={data}
                                        render={(canDownload)}
                                        userDetails={userDetails}
                                        decimalView={decimalView}
                                        isUploadLogRequired={true}
                                        uploadLogDate={uploadLogDate}


                                    />
                                        <PerformanceTableEmailTemplate
                                            id="team-perfomances-table1"
                                            filtersData={filtersData}
                                            belongsTo='Teams'
                                            columns={[...teamEmailTemplateColumns, ...dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')]}
                                            data={data}
                                            isUploadLogRequired={true}
                                            render={(canDownload || dialog?.open)}

                                        />
                                    </>
                                }
                                {/* <TableViewBy /> */}
                                <p className="table-title decimal-switch-root">
                                    <p>
                                        <span>{(!roundId && filtersData.match?.type === 'PLAYER_SPRINT') ? 'Player' : 'Team'} performance : <span style={{ color: '#000', fontWeight: 500 }}>{filtersData.season?.season_format === 'HEAD_TO_HEAD' ? filtersData.round?.name : filtersData.match?.title}</span></span>
                                        <GetDates filtersData={filtersData} />
                                    </p>
                                    <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                </p>
                                {isAdmin && data.length > 0 && <div className={classes.iconDiv}>
                                    <IconButton onClick={(e) => handleShareMenuOpen(e,)} >
                                        <ShareSharpIcon fontSize="small" />
                                    </IconButton>
                                </div>}
                                {renderShareOptions}
                                {
                                    (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                                        <SummaryTable data={data} />
                                }

                                {data.length > 0 &&
                                    <p className="table-footer">
                                        <span className={classes.viewAllButton} onClick={() => handleDetailedScore()}>View Detailed score<ArrowForward /></span>
                                        <span className={classes.viewAllButton} onClick={() => handleViewAll()}>View all<ArrowForward /></span>
                                    </p>
                                }
                            </div>
                    ]
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundTeamStatistics: options => dispatch(getRoundTeamStatistics(options)),
        getTeamRaceLiveScore: options => dispatch(getTeamRaceLiveScore(options)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getMyTeamLivePerformance: (options) => dispatch(getMyTeamLivePerformance(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),
        getDivisionList: (options) => dispatch(getDivisionsList(options)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TopFiveTeamSummary)
);
