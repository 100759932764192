import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, CardHeader, CardContent, Card, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { setLoading } from "../../../ducks/loading";
import { getPlayersPerformanc } from "../../../ducks/game";
import Skeleton from "@material-ui/lab/Skeleton";
import OrganisationalCard from "./OrganisationalCard";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        // marginTop: 35,
        marginBottom: 35,
    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
    },
    season_Title: {
        '& h4': {
            color: '#000000a6',
            margin: 0,
        }
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    card: {
        padding: '10px 8px',
        '& .card': {
            height: 135,
            background: '#FFFFFF',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 4,
            padding: '0px 5px',
        },
        '& .cardTitle': {
            fontSize: 14,
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.9)',
            margin: '6px 0px',
            fontWeight: 600,

        },
        '& .cardContent': {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 4,
            padding: '4px 8px',
            '& h1': {
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 24,
                margin: 0,
                fontWeight: 500,
                lineHeight: '42px'
            }
        },
        '& .perform': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            '& span': {
                fontSize: 11,
                color: 'rgba(0, 0, 0, 0.5)',
            },
            '& p': {
                margin: 0,
                fontSize: 13,
                color: 'rgba(0, 0, 0, 0.9)',
            },
            '& .values': {
                textAlign: 'center'
            },
            '& .divider': {
                margin: '0px 2px'
            }
        }
    },
    cardHeader: {
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .titleHdr': {
            display: 'flex',
            alignItems: 'center',
            '& .rank': {
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 4,
                textAlign: 'center',
                padding: '2px 5px',
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 13,
                marginRight: 12,
                '& span': {
                    marginLeft: '-3px'
                }
            },
            '& p': {
                margin: 0
            }
        },
        '& .ColorCode': {
            margin: 0,
            width: 18,
            height: 18,
            borderRadius: 2,
        }
    },
    skeletonRoot: {
        padding: '10px 0px',
        height: 135,
        marginBottom: 150,
        "& .card": {
            bordeRadius: 8,
            padding: '10px 8px',
            borderRadius: 4,
        },
    },
}));

const OrganizationalPerformance = ({ filtersData, seasonId, userDetails, getPlayersPerformanc, seasonKpiPostions, orgsStats, loading }) => {
    const classes = useStyles();

    const skeletonLoading = (
        <Grid container spacing={1} className={classes.skeletonRoot}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((ld, id) => (
                <Grid key={id} item lg={3} md={6} sm={6} xs={12}>
                    <Card className="card">
                        <CardHeader style={{ padding: 0 }}
                            avatar={<Skeleton variant="reactangle" width={40} height={30} style={{ borderRadius: 4 }} />}
                            title={<Skeleton height={20} width="98%" />}
                        />
                        <CardContent style={{ padding: 0 }}>
                            <Skeleton height={20} width="100%" />
                            <Skeleton height={60} width="100%" />
                        </CardContent>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );


    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <h2>Organization Performance </h2>
            </div>
            {loading && skeletonLoading}
            <div className={classes.performance}>
                <div className={classes.card}>
                    {orgsStats.length !== 0 ?
                        <Grid container spacing={1}>
                            {
                                orgsStats?.map((org, index) =>
                                    <OrganisationalCard key={index} org={org} />
                                )
                            }
                        </Grid>
                        : <p style={{ padding: '100px 24px', textAlign: 'center' }}>No Data Available</p>
                    }
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPerformanc: (options) => dispatch(getPlayersPerformanc(options)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationalPerformance));