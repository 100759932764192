import React, { useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, fade } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.35),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.5),
        },
        marginTop: 12,
        marginBottom: 12,
        width: '100%',
        height: '42px',
    },
    searchIcon: {
        color: '#0000004d',
        padding: '0px 16px 0px 35px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        // [theme.breakpoints.down('sm')]: {
        //     display: 'none'
        // },
    },
    inputRoot: {
        color: 'initial',
        height: '100%',
        width: 'inherit'
    },
    inputInput: {
        border: '1px solid #0000003a',
        height: 24,
        margin: '0 20px',
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        // [theme.breakpoints.down('sm')]: {
        //     border: 'none'
        // },
    },

}));

const ContactSearch = ({ handleSearch }) => {
    const classes = useStyles();

    const [query, setQuery] = useState('');
    
    const handleChangeQuery = (e) => {
        let value = e.target.value;
        setQuery(value);
        if(value.length === 0){
            handleSearch('');
        }
    }

    const handleKeyPress = e => {
        if (e.nativeEvent.keyCode === 13) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
                handleSearch(query);
                // return false;
            }
        }
    }

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search.."
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={query}
                onChange={handleChangeQuery}
                onKeyUp={handleKeyPress}
            />
        </div>
    )
}

export default ContactSearch;