import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import defaultBanner from '../../assets/images/default-banner.png';
import { openCustomDialog } from '../../ducks/meetings';
import { updateRewardRedumption } from '../../ducks/game';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactComponent as ReactRewardExpire } from "../../assets/images/rewardclock.svg";
import { ReactComponent as ReactRewardCopy } from "../../assets/images/rewardcopy.svg";
import ReactRewardBg from "../../assets/images/reward_bg.png";
import ReactRewardGift from "../../assets/images/rewardGift.png";
import ReactRewardGif from "../../assets/images/rewardGIF-3 1.gif";
import { ArrowForward } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getEllipsisText } from '../../helpers';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    avatarStyles: {
        width: 65,
        height: 65,
        fontSize: 24
    },
    colorDefault: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer'
    },
    imageStyles: {
        '& img': {
            objectFit: 'fill',
            width: '50px',
            height: '50px',
            borderRadius: '50px'
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 15px',
        height: '61px',
        "& .winners_": {
            "& h3": {
                fontSize: 16,
                color: '#000000',
                fontWeight: '500'
            },
            "& h6": {
                fontSize: 12,
                fontWeight: 400,
                color: '#000000E5'
            }
        }
    },
    tabStyles: {
        minHeight: 40,
        textTransform: 'capitalize',
        // padding: '13px',
        fontSize: 13,
        textAlign: 'left',
        opacity: 1,
        borderRadius: 5,
        transition: 'top 0.2s ease-in-out',
        fontFamily: 'Poppins !impotant',
        width: '100%',
        "& .maincard": {
            border: '1px solid #7b7b7b',
            background: '#ffffff',
            borderRadius: '7px',
            // width: '360px',
            // height: '122px',
            padding: 0,
            "& .saperat_div": {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .title_card': {
                    background: '#7B7B7B !important',
                    padding: '7px 12px',
                    color: '#f0f2f5',
                    fontSize: '14px',
                    maxWidth: 110,
                    textOverflow: 'ellipsis',
                    position: 'relative',
                    borderTopLeftRadius: '6px',
                    "&:after": {
                        content: "''",
                        position: 'absolute',
                        top: '0',
                        right: '-10px',
                        transform: 'skewX(-33deg)',
                        width: '20px',
                        background: '#7b7b7b !important',
                        height: '30.2px'
                    },
                },
                '& .title_card_dashboard': {
                    background: '#7B7B7B !important',
                    padding: '7px 12px',
                    color: '#f0f2f5',
                    fontSize: '14px',
                    maxWidth: 110,
                    textOverflow: 'ellipsis',
                    position: 'relative',
                    borderTopLeftRadius: '6px',
                    "&:after": {
                        content: "''",
                        position: 'absolute',
                        top: '0',
                        right: '-9px',
                        transform: 'skewX(-33deg)',
                        width: '20px',
                        background: '#7b7b7b !important',
                        height: '30.2px'
                    },
                },
                '& .title_card1': {
                    display: 'flex',
                    padding: '5px 12px',
                    fontSize: '14px',
                    maxWidth: 150,
                    textOverflow: 'ellipsis',
                    background: '#0000000A',
                    margin: '2px 5px',
                    color: '#000000',
                    borderRadius: 5,
                    textAlign: 'right',
                    "& h5": {
                        margin: 0,
                        minWidth: 0,
                        paddingLeft: 5
                    }
                },
            },
            "& p": {
                margin: 0,
                fontSize: 13,
                fontWeight: 400,
                padding: '4px 8px 4px 30px',
                color: '#C61010',
            },
            "& h6": {
                margin: 0,
                // maxWidth: 220,
                // textOverflow: 'ellipsis',
                // whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            "& h5": {
                margin: 0,
                maxWidth: 220,
                minWidth: 100,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'center',
                textTransform: 'initial'
            },
            "& h3": {
                margin: 0,
                // maxWidth: 210,
                minWidth: 100,
                // textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }
        },

    },
    FooterContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 7px 5px 7px',
        borderRadius: '0px 0px 7px 7px',
        borderTop: '1px dashed #00000029',
        '& .coupn': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& p': {
                display: 'flex',
                alignItems: 'center',
                background: '#0000000A',
                borderRadius: 4,
                padding: '7px 10px',
                color: '#000000',
                margin: '0px 6px',
                '& span': {
                    minWidth: 60,
                }
            },
            '& span': {
                color: '#00000061',
            }
        },
        '& .redirect': {

        }
    },
    rewardAwait: {
        cursor: 'pointer',
        width: '100%',
        // minHeight: 150,
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        borderRadius: 5,
        textAlign: 'center',
        '& .imageBg': {
            width: '100%',
            height: 153,
            objectFit: 'cover',
            borderRadius: 6,
        },
        '& .gift': {
            position: 'absolute',
            background: 'white',
            borderRadius: '50%',
            padding: '0px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        '& p': {
            position: 'absolute',
            right: 15,
            bottom: 15,
            color: 'white',
            margin: 0,
            fontSize: 13
        }
    }
}));

const RewardWinningCard = ({ name = "", obj, history, setRewards, location, bannerUrl = defaultBanner, index, openCustomDialog, rewards, updateRewardRedumption, userDetails, from, ...others }) => {
    const classes = useStyles();
    const [gifView, setGifView] = useState(false);
    const [copy, setCopy] = useState(false);

    const Levels = [
        { title: 'Season Award', id: 'SEASON' },
        { title: 'Round Award', id: 'ROUND' },
        { title: 'Sprint Award', id: 'MATCH' },
        { title: 'Sprint Award', id: 'SPRINT' },
        { title: 'Onspot Award', id: 'ONSPOT' },
    ]

    const redirectUrl = (obj) => {
        window.open(obj?.product_url, "_blank");
    }

    const updateRewardStatus = (product_id) => {
        updateRewardRedumption({ product_id });
    }

    const rewardGiftClick = (obj) => {
        let copy_Rewards = JSON.parse(JSON.stringify(rewards))
        setGifView(true);
        setTimeout(() => {
            copy_Rewards[index].status = 'CLOSED';
            setRewards(copy_Rewards);
            setGifView(false);
            updateRewardStatus(obj?.product_id)
        }, 2000);
    }

    const copyClic = () => {
        setCopy(true);
        setTimeout(() => {
            setCopy(false);
        }, 2000);
    }

    const timeStamp = (date) => {
        const expireDate = new Date(date);
        const currentDate = new Date();
        if (currentDate > expireDate) return 'Expired';
        else return moment(expireDate).format('lll');
    }

    return (
        <>
            {
                obj?.status === 'AWAITING' ?
                    <>
                        <div className={classes.rewardAwait} onClick={() => rewardGiftClick(obj)}>
                            <img src={ReactRewardBg} alt='' className='imageBg' />
                            <div className='gift'>
                                {
                                    gifView ?
                                        <img src={ReactRewardGif} alt='gift' style={{ width: '100px', height: '90px' }} />
                                        :
                                        <img src={ReactRewardGift} alt='gift' style={{ width: '100px', height: '90px' }} />

                                }
                            </div>
                            <p>Click To Redeem</p>
                        </div>
                    </>
                    :
                    <>
                        <div className={classes.tabStyles}>
                            <div className="maincard" style={timeStamp(obj?.expires_on) === 'Expired' ? { pointerEvents: 'none', opacity: '0.7' } : { height: from === 'dashboard' && '125px' }}>
                                <div className="saperat_div">
                                    <div className={from === 'dashboard' ? "title_card_dashboard" : "title_card"}>
                                        <h5>{Levels.find((el) => el.id === obj?.level)['title']}</h5>
                                    </div>
                                    <div className="title_card1">
                                        <ReactRewardExpire />
                                        {<h5>{timeStamp(obj?.expires_on)}</h5>}
                                    </div>
                                </div>
                                <div className={classes.content}>
                                    <div className="winners_">
                                        <h3>{getEllipsisText(obj?.title, 35)}</h3>
                                        <h6>{getEllipsisText(obj?.redemption_instructions, 105)}</h6>
                                    </div>
                                </div>
                                <div className={classes.FooterContent} >
                                    <div className='coupn'>
                                        <span>Coupon Code :</span>
                                        <CopyToClipboard text={obj?.coupon_code}>
                                            <p onClick={() => copyClic()}>
                                                <span>{copy ? 'copied !' : getEllipsisText(obj?.coupon_code, 6)}</span>
                                                <ReactRewardCopy style={{ paddingLeft: 6 }} />
                                            </p>
                                        </CopyToClipboard>
                                    </div>
                                    <div className='redirect' onClick={() => redirectUrl(obj)}>
                                        <Button color='primary' variant='contained'>Redeem Now <ArrowForward fontSize='small' /> </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        openCustomDialog: (options) => dispatch(openCustomDialog(options)),
        updateRewardRedumption: (options) => dispatch(updateRewardRedumption(options)),
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RewardWinningCard));
