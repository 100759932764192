// import orderBy from "lodash/orderBy";
// import differenceBy from "lodash/differenceBy";

import { makeConstant } from "./_helpers";
import { UPDATE_TEAM_DETAILS } from "./common";

const constant = makeConstant("jchat/chats");

export const UPDATE_TEAM_CHAT = constant("UPDATE_TEAM_CHAT");
export const UPDATE_VIEW_STATUS = constant("UPDATE_VIEW_STATUS");
export const UPDATE_CHAT = constant("UPDATE_CHAT");
export const NEW_CHATS = constant("NEW_CHATS");
export const CHAT_PRESENCE = constant("CHAT_PRESENCE");
export const GET_CHATS = constant("GET_CHATS");
export const CHATS_RECEIVED = constant("CHATS_RECEIVED");
export const SET_CURRENT_CHAT = constant("SET_CURRENT_CHAT");

export const updateChatViewStatus = (msg) => ({
    type: UPDATE_VIEW_STATUS,
    payload: msg
})

export const updateTeamChatDetails = (details) => ({
    type: UPDATE_TEAM_CHAT,
    payload: details
})

export const updateRecentChat = (msg) => ({
    type: UPDATE_CHAT,
    payload: msg
})

export const receivedChatPresence = presence => ({
    type: CHAT_PRESENCE,
    payload: presence
});

export const setCurrentChat = (chatDetails) => ({
    type: SET_CURRENT_CHAT,
    payload: chatDetails
})

export const getChats = payload => ({
    type: GET_CHATS,
    payload
});

export const receivedChats = payload => ({
    type: CHATS_RECEIVED,
    payload: payload
});

const initialState = {}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_CHAT: {
            const msg = action.payload;
            // console.log('UPDATE_CHAT msg', action.payload);

            let peerJid = msg.from.split('/')[0];

            const peer = state[peerJid] || {
                targetJid: peerJid
            };
            console.log('UPDATE_CHAT ', action.payload, peer);

            const key = Object.keys(state).find(key => state[key].isCurrent === true);

            return {
                ...state,
                [peer.targetJid]: {
                    ...peer,
                    name: peer.name ? peer.name : peer.targetJid.split('@')[0],
                    sentdate: msg.delay && Date.parse(msg.delay.timestamp) || Date.parse(new Date()),
                    body: msg.body,
                    isCurrent: (key === peer.targetJid) ? true : false,
                    show: peer.show ? peer.show : 'unavailable',
                    isNewChat: false,
                    count: (key === peer.targetJid || msg.msgStatus === 'sent') ? 0 : (peer.count ? peer.count + 1 : 1),
                    messageStatus: msg.messageStatus,
                    outgoing: msg.outgoing
                },
            };
        }

        case SET_CURRENT_CHAT: {
            const user = action.payload;
            let newState = JSON.parse(JSON.stringify(state));

            const isNewChat = Object.keys(newState).find(key => newState[key].isNewChat === true);

            if (isNewChat && isNewChat !== user.targetJid) {
                let newStateDup = JSON.parse(JSON.stringify(newState));
                delete newStateDup[isNewChat];
                newState = JSON.parse(JSON.stringify(newStateDup));
                // console.log('SET_CURRENT_CHAT isNewChat', newState);
            }

            const key = Object.keys(newState).find(key => newState[key].isCurrent === true);
            // console.log('SET_CURRENT_CHAT 1', action.payload, newState)
            // console.log('SET_CURRENT_CHAT 2', newState[user.targetJid], key, isNewChat)

            if (action.payload.chatId && !newState[action.payload.chatId]) {
                // console.log('SET_CURRENT_CHAT NotExisted', newState);
                const name = action.payload.targetName;
                const targetJid = action.payload.chatId;
                const show = action.payload.presence;
                const url = action.payload.photo_url;
                newState = {
                    [targetJid]: {
                        targetJid: targetJid,
                        name: name,
                        body: 'New Chat',
                        stanza: '',
                        image_url: url,
                        isCurrent: true,
                        show: show,
                        sentdate: Date.parse(new Date()),
                        isNewChat: true,
                        count: 0
                    },
                    ...newState,
                }
                if (key !== undefined) {
                    newState[key].isCurrent = false;
                }
                return newState;
            }
            if (action.payload.chatId && newState[action.payload.chatId]) {
                if (key !== undefined) {
                    if (key === action.payload.chatId) {
                        newState[key].isCurrent = true;
                        newState[key].count = 0;
                    }
                    else if (newState[action.payload.chatId]) {
                        newState[action.payload.chatId].isCurrent = true;
                        newState[key].isCurrent = false;
                    }
                    else {
                        newState[key].isCurrent = false;
                    }
                }
                else {
                    newState[action.payload.chatId].isCurrent = true;
                    newState[action.payload.chatId].count = 0;
                }
                return newState;
            }

            if (newState[user.targetJid] === undefined) {
                newState = {
                    ...newState,
                    [user.targetJid]: {
                        ...user,
                        targetJid: user.targetJid,
                        isCurrent: true,
                        count: 0,
                        // show: show,
                        // sentdate: Date.parse(new Date),
                        isNewChat: false,
                    },
                }
                if(newState[key]){
                    newState[key].isCurrent = false;
                }
                return newState;
            }
            else {
                newState[user.targetJid].isCurrent = true;
                newState[user.targetJid].count = 0;
            }

            if (key !== undefined && key !== user.targetJid) {
                newState[key].isCurrent = false;
            }
            return newState;
        }

        case CHATS_RECEIVED: {

            // console.log('CHATS ', action);

            return { ...state, ...action.payload };
        }

        case CHAT_PRESENCE: {

            let newState = JSON.parse(JSON.stringify(state));
            const presence = action.payload;
            const peerJid = presence.from.split('/')[0];
            // const peer = state.find(obj => obj.jid === peerJid);

            // console.log('CHAT_PRESENCE ', action.payload, peerJid);

            let show = presence.type;
            if (presence.show) {
                show = (presence.show === 'xa') ? 'away' : (presence.show === 'chat') ? 'available' : presence.show;
            }


            let status = "";
            if (presence.status) {
                status = presence.status;
                if (!presence.show) {
                    show = presence.status === 'Online' ? 'available' : presence.status === 'Away' ? 'away' : presence.status === 'Busy' ? 'dnd' : 'unavailable'
                }
            }

            if (!presence.status && !presence.type && !presence.show) {
                // console.log('CHAT_PRESENCE available', peerJid);
                show = 'available'
            }

            if (newState[peerJid]) {
                newState[peerJid].show = show;
                newState[peerJid].status = status;
            }

            return newState;
        }

        case UPDATE_TEAM_CHAT: {
            // console.log('UPDATE_TEAM_CHAT ', action)
            const peerJid = action.payload.targetJid;
            return {
                ...state,
                [peerJid]: {
                    ...state[peerJid],
                    targetJid: peerJid,
                    name: action.payload.name,
                    contactName: action.payload.name,
                    image_url: action.payload.logo_url,
                    show: 'unavailable'
                }
            }

        }

        default:
            return state;
    }
};
