import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextareaAutosize, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, IconButton, useMediaQuery, CircularProgress, Backdrop, useTheme } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { Close } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import { openWorkLogDialog, createWorkLog ,updateWorkLog} from '../../ducks/pms';
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { connect } from "react-redux";
let helpertext=''
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    }, dialogPaper: {
        minHeight: 370,
        borderRadius: 15,
        maxWidth: 500
    },
    dialogTitle: {
        padding: '21px 40px',
        fontSize: 22,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogContent: {
        padding: '13px 40px',
        overflow: 'hidden'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 10
    },
    fieldRoot: {
        padding: '12px 0px',
        display: 'flex',
        marginLeft: '-12px',
        // width: '420px'
    },
    fieldLabel: {
        marginLeft: 12,
        width: '100%',
        '& p:first-child': {
            margin: 0,
            fontSize: 14,
            color: '#535353',
            fontWeight: 600,
            '& .mandatory': {
                color: '#ff0202'
            }
        },
    },
    inputStyles: {
        fontSize: 15,
        color: '#535353'
    },
    nameInputStyles: {
        textAlign: 'center',
        color: '#141414',
        fontSize: 16,
        fontWeight: 600
    },
    inputUnderine: {
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    buttonRoot: {
        marginBottom: 30,
        textAlign: 'center',
        '& button:nth-child(2)': {
            marginRight: 0
        },
    },
    fieldWidth: {
        borderRadius: 6
    },
    button: {
        margin: '0 7px',
        width: 120,
        height: 43,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    }
}))

const WorkLogDailog = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { workLog: { open, details, isEdit }, createWorkLog, openWorkLogDialog,handleWorkLog } = props;
    const [initalWorkLog, setInitalWorkLog] = useState({  logged_time: '', comment: "" ,formInValid:false })
    const { enqueueSnackbar } = useSnackbar();
    console.log(props)
    useEffect(()=>{
        if(isEdit){
            setInitalWorkLog({...initalWorkLog,logged_time:details.logged_time,comment:details.comment})
        }
    },[details])

    const handleCreateWorkLog = () => {
        let workLogDup = {...initalWorkLog};
        workLogDup.task_id = details.task_id;
        if(workLogDup.logged_time==='' || workLogDup.logged_time<=0){
            helpertext = (workLogDup.logged_time!=='' && workLogDup.logged_time <=0 )? 'Log Time must be greater than Zero.' : 'Please enter Log Time.'
            setInitalWorkLog(prevState=>({...prevState,formInValid:true}));
        }else{
            delete workLogDup.formInValid
            createWorkLog({
                requestObj: workLogDup, onSuccessCB: workLogSucessCB,
                onErrorCB: workLogErrorCB
            })
        }
        
        // handleClose();
    }
    const handleClose = () => {
        openWorkLogDialog({ open: false, details: {}, isEdit: false });
        setInitalWorkLog({logged_time: '', comment: "" ,formInValid:false})
    }
    const workLogSucessCB = (result) => {
        console.log("result",result)
        const message = isEdit ? 'Work log Updated' :'Work log Created';
        enqueueSnackbar(message, {   
            variant: "success",
            preventDuplicate: true
        });
        handleWorkLog(new Date().getTime())
        handleClose();
    }
    const workLogErrorCB = (error) => {
        console.log("err",error)
        const errMsg = isEdit ? 'failed to update' :'failed to create';
        enqueueSnackbar(errMsg, {   
            variant: "error",
            preventDuplicate: true
        });
    }
    const handleEditWorkLog=()=>{
        let obj ={...initalWorkLog};
        obj.task_id=details.task_id;
        delete obj.logged_by;
        delete obj.formInValid
        if(details.logged_time===initalWorkLog.logged_time && details.comment===initalWorkLog.comment){
            enqueueSnackbar('Please change any value to update', {   
                variant: "warning",
                preventDuplicate: true
            });  
        }
        else if(initalWorkLog.logged_time==='' || initalWorkLog.logged_time<=0){
            setInitalWorkLog(prevState=>({...prevState,formInValid:true}));
        }
        else props.updateWorkLog({requestObj:obj,id:details._id,onSuccessCB:workLogSucessCB,onErrorCB:workLogErrorCB})
    }

    return (
        <div className={classes.root}>
            <Dialog
                // fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='lg'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="share-arena-dialog"
                style={{ zIndex: 1300 }}
                classes={{ paper: classes.dialogPaper }}>

                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    {!isEdit ? 'Log Time' : 'Edit Time log'}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <FormControl fullWidth>
                        <div className={classes.fieldRoot}>
                            <div className={classes.fieldLabel}>
                                <TextField
                                    type="number"
                                    id="Log Time"
                                    label="Log Time in Hrs *"
                                    fullWidth
                                    min={1}
                                    variant="outlined"
                                    value={initalWorkLog.logged_time}
                                    onChange={(e) =>
                                        setInitalWorkLog({ ...initalWorkLog, logged_time: e.target.value,formInValid:false })
                                    }
                                    error={initalWorkLog.formInValid }
                                    helperText={initalWorkLog.formInValid &&  helpertext}
                                    InputProps={{ classes: { root: classes.fieldWidth } }}
                                />
                            </div>
                        </div>
                    </FormControl>
                    <FormControl fullWidth>
                        <div className={classes.fieldRoot}>
                            {/* <div><JobIcon style={{ margin: '0 5px' }} /></div> */}
                            <div className={classes.fieldLabel}>
                                <TextField
                                    id="Comment"
                                    label="Comment"
                                    multiline
                                    rowsMax={2}
                                    fullWidth
                                    variant="outlined"
                                    value={initalWorkLog.comment}
                                    onChange={(e) =>
                                        setInitalWorkLog({ ...initalWorkLog, comment: e.target.value })}
                                        InputProps={{ classes: { root: classes.fieldWidth } }}
                                    />
                            </div>
                        </div>
                    </FormControl>
                </DialogContent>
                <div className={classes.buttonRoot} >
                    <Button variant="text" size="medium" className={classes.button} onClick={() => handleClose()}>Cancel</Button>
                    {
                        isEdit ?
                            <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleEditWorkLog()}>Save</Button>
                            :
                            <Button variant="contained" color="primary" size="medium" onClick={() => handleCreateWorkLog()} className={classes.button}>Save</Button>
                    }
                </div>
            </Dialog>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        workLog: state.pms.workLogDialog
        // tasks: (value) => props.getTasks(value)
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
        openWorkLogDialog: (options) => dispatch(openWorkLogDialog(options)),
        createWorkLog: (options) => dispatch(createWorkLog(options)),
        updateWorkLog:(options)=>dispatch(updateWorkLog(options))
    }
}
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(WorkLogDailog);
