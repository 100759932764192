import { all, takeEvery, call, put } from "redux-saga/effects";
import Axios from "axios";
import { getUserSurveys, getSurveysQuestions, respondSurvey, getSurveyDetails } from "../../config";
import { receivedSurveys, setSurveyQuestions, GET_SURVEY_QUESTIONS, GET_SURVEYS, SEND_SURVEY_RESPONSE, updateSurveyStatus, GET_SURVEY_DETAILS } from "../../ducks/surveys";


const postSurveyResponse = (surveyId, requestObj) => {
    return Axios.post(respondSurvey(surveyId), requestObj);
}

const fetchSurveyQuestions = (surveyId) => {
    return Axios.get(getSurveysQuestions(surveyId));
}

const fetchUserSurveys = () => {
    return Axios.get(getUserSurveys, {
        params: {
            limit: 200,
            current_page: 0
        }
    });
}

const fetchSurveyDetails = (surveyId) => {
    return Axios.get(getSurveyDetails(surveyId)).then(response => response)
        .catch(err => err);
}

function* watchForGetSurveys() {
    yield takeEvery(GET_SURVEYS, function* getSurveys(action) {
        const { currentSurveyId, onGetSurveysSuccessCB, onGetSurveysErrorCB } = action.payload;
        try {
            let { data } = yield call(fetchUserSurveys);
            console.log('watchForGetSurveys action ', action);
            if (data) {
                let surveys = data.reduce((obj, item) => {
                    const expired = (new Date(item.expire_date).getTime()) < (new Date().getTime());
                    // console.log('watchForGetSurveys expire ', parseInt((new Date(item.expire_date) - (new Date())) / (1000 * 60 * 60 * 24)))
                    let newSurvey = {
                        ...item,
                        isCurrent: item._id === currentSurveyId ? true : false,
                        status: item.responded ? 'responded' : expired ? 'expired' : 'expires',
                        // statusText: item.responded ? 'Responded' : expired ? 'Expired' : Math.floor(((new Date(item.expire_date)) - (new Date())) / (1000 * 60 * 60 * 24)) ? `Expires in ${Math.floor(((new Date(item.expire_date)) - (new Date())) / (1000 * 60 * 60 * 24))} days ` : 'Expires today'
                        statusText: item.responded ? 'Responded' : expired ? 'Expired' : `Expires in ${Math.round(((new Date(item.expire_date)) - (new Date())) / (1000 * 60 * 60 * 24))} days`
                    };
                    return { ...obj, [item._id]: newSurvey };
                }, {});
                yield put(receivedSurveys(surveys));
                yield call(onGetSurveysSuccessCB, surveys);
            }
            else {
                throw 'Invalid';
            }
            console.log('watchForGetSurveys data ', data);

        } catch (e) {
            yield call(onGetSurveysErrorCB, e);
            console.log('watchForGetSurveys catch err ', e, action.payload);
        }
    });
}


function* watchForGetSurveyDetails() {
    yield takeEvery(GET_SURVEY_DETAILS, function* getSurveys(action) {
        const { surveyId, onGetSurveysDetailsSuccessCB, onGetSurveysDetailsErrorCB } = action.payload;
        const { response, err } = yield call(fetchSurveyDetails, surveyId);
        console.log('watchForGetSurveyDetails action ', response, err);
        if (response.status === 200) {
            yield call(onGetSurveysDetailsSuccessCB, response.data);
        }
        else
            yield call(onGetSurveysDetailsErrorCB, response);
        // try {
        //     let { data, headers, status } = yield call(fetchSurveyDetails(surveyId));
        //     console.log('watchForGetSurveyDetails action ', data, headers, status);
        //     if (data) {
        //         // yield put(receivedSurveys(surveys));
        //         yield call(onGetSurveysDetailsSuccessCB, data);
        //     }
        //     console.log('watchForGetSurveyDetails data ', data);

        // } catch (e) {
        //     yield call(onGetSurveysDetailsErrorCB, e);
        //     console.log('watchForGetSurveyDetails catch err ', e, action.payload);
        // }
    });
}

function* watchForSurveyQuestions() {
    yield takeEvery(GET_SURVEY_QUESTIONS, function* surveyQuestions(action) {
        const { surveyId, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data } = yield call(fetchSurveyQuestions, surveyId);
            console.log('watchForSurveyQuestions action ', action);
            if (data) {
                yield call(onSuccessCB, data);
                // yield put(setSurveyQuestions({ surveyId, data }));
            }
            else {
                throw 'Invalid';
            }
            console.log('watchForSurveyQuestions data ', data);

        } catch (e) {
            yield call(onErrorCB, e);
            console.log('watchForSurveyQuestions catch err ', e, action.payload);
        }
    });
}

function* watchForSurveyResponse() {
    yield takeEvery(SEND_SURVEY_RESPONSE, function* respondSurvey(action) {
        const { surveyId, requestObj, onPostResponseSuccessCB, onPostResponseErrorCB } = action.payload;
        try {
            let { data } = yield call(postSurveyResponse, surveyId, requestObj);
            console.log('watchForSurveyResponse action ', action);
            if (data) {
                yield put(updateSurveyStatus(surveyId));
                yield call(onPostResponseSuccessCB, data);
            }
            else {
                throw 'Invalid';
            }
            console.log('watchForSurveyResponse data ', data);

        } catch (e) {
            yield call(onPostResponseErrorCB, e);
            console.log('watchForSurveyResponse catch err ', e, action.payload);
        }
    });
}

export default function* (client) {
    yield all([watchForGetSurveys(client), watchForSurveyQuestions(), watchForSurveyResponse(), watchForGetSurveyDetails()]);
}
