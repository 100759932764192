import React from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";

import Posts from "./Posts";
import Chaseons from "./Chaseons";
import All from "./All"
import Media from "./Media";

const useStyles = makeStyles((theme) => ({
  profileTabsRoot: {
    minHeight: "auto",
    margin: "15px 10px 0px",
  },
  profileTabRoot: {
    minWidth: 50,
    minHeight: "auto",
    fontSize: 15,
    textTransform: "capitalize",
    color: "#535353",
    padding: "4px 0",
    opacity: 0.7,
  },
  tabIndicatorProfile: {
    minWidth: 65,
  },
  border: {
    backgroundColor: "#ededed",
    paddingTop: "1px",
    borderRadius: '6px 6px 0px 0px',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export const SearchArena = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className={classes.border}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.profileTabsRoot}
          classes={{ indicator: classes.tabIndicatorProfile }}
        >
          <Tab classes={{ root: classes.profileTabRoot }} label="All" />
          <Tab classes={{ root: classes.profileTabRoot }} label="Chaseons" />
          <Tab classes={{ root: classes.profileTabRoot }} label="Photos" />
          <Tab classes={{ root: classes.profileTabRoot }} label="Videos" />
          <Tab classes={{ root: classes.profileTabRoot }} label="Posts" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <All newPost={props.newPost} handleOnNewPost={props.handleOnNewPost} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Chaseons {...props} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Media mediaType={'IMAGE'} {...props} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Media mediaType={'VIDEO'} {...props} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Posts {...props} />
        </TabPanel>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchArena);
