import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import SeasonFilters from '../SeasonFilters';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getDivisionsList, getPlayersPointsList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import CustomDataTable from '../../GlobalComponents/CustomDataTable';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import RankLabel from '../RankLabel';
import { commonColumnOptions } from '../TeamPointsTable';
import { getNumberFormat, setSearchQuery } from '../../../helpers';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import { DecimalViewSwitch } from '../../LiveScores/CustomSwitch';
import { CustomListGroup } from '../../LiveScores/RoundsListView';
import IllustrationView from '../../LiveScores/IllustrationView';
import { sortBy } from 'underscore';
import qs from 'query-string';

import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
}))

const defaultFilters = {
    league: null,
    season: null,
    division: null
};

const pagination = {
    limit: 5,
    page: 0,
}

const PlayerPointsTable = ({ getPlayersPointsList, filtersData, setFiltersData, setLoading, loading, userDetails, history, getDivisionsList, seasonDetails, seasonDivisions }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [playersPoints, setPlayersPoints] = useState([]);
    const [decimalView, setDecimalView] = useState(false);
    const [currentPlayerTab, setCurrentPlayerTab] = useState(0);
    const [divisionsData, setDivisionsData] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [dubDivisions, setDubDivisions] = useState([]);

    const subTabsPlayer = seasonDivisions?.length !== 0 ?
        [{ label: 'By Season', value: 0, icon: '', path: 'seasonWise' }, { label: 'By Division', value: 1, icon: '', path: 'divisionWise' }]
        : [{ label: 'By Season', value: 0, icon: '', path: 'seasonWise' }]

    useEffect(() => {
        if (filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.auto_player_sprints) {
            setPlayersPoints([]);
        }
        else if (filtersData.season) {
            getPlayersPoints(filtersData.season._id);
            if (filtersData.season?.divisions_enabled && qs.parse(history.location.search)['viewBy'] !== 'season') {
                getDivisions(filtersData.season._id);
                setCurrentPlayerTab(1);
            }
            else {
                getPlayersPoints(filtersData.season?._id);
                setDivisions([]);
            }
            setSearchQuery(history, { viewBy: filtersData.season?.divisions_enabled && qs.parse(history.location.search)['viewBy'] !== 'season' ? 'division' : 'season' });
        }
        else {
            setPlayersPoints([]);
            setDivisionsData([]);
            setDubDivisions([]);
            setDivisions([]);
        }
    }, [filtersData.season]);

    useEffect(() => {
        if (divisions?.length > 0) {
            divisions.map((division, i) => {
                getPlayersPoints(filtersData.season?._id, division, i);
            })
        }
        else {
            setDivisionsData([]);
            setDubDivisions([]);
        }
    }, [divisions]);

    // ----- player subtab changes ------
    const handleSubTabChange = (newValue) => {
        setSearchQuery(history, { viewBy: newValue?.value === 1 ? 'division' : 'season' });
        setCurrentPlayerTab(newValue?.value || 0);
    }

    const getDivisions = (seasonId) => {
        setLoading(true);
        setDivisions([]);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: 1 },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const getDivisionMore = () => {
        let seasonId = filtersData?.season?._id;
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: pagination.page + 1 },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const onDivisionSuccessCB = (result, headers) => {
        if (result) {
            getPlayersPoints(filtersData.season?._id);
            setDivisionsData([]);
            setDivisions([...result]);
            setLoading(true);
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }
    const onDivisionErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Divisions', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const getPlayersPoints = (seasonId, division, divIndex) => {
        setLoading(true);
        getPlayersPointsList({
            params: { season_id: seasonId, division_id: division?._id, limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                // setPlayersPoints([...result].map(obj => ({ ...obj, selected: obj.username === userDetails?.username, match_points: obj.points?.match, bonus_points: obj.points?.bonus, player: { name: obj.name, photo_url: obj.photo_url, _id: obj.username } })));
                const plaerRes = result.map(obj => ({ ...obj, selected: obj.username === userDetails?.username, match_points: obj.points?.match, bonus_points: obj.points?.bonus, player: { name: obj.name, photo_url: obj.photo_url, _id: obj.username } }))
                if (division) {
                    setDivisionsData(prevState => ([...prevState, ({ ...division, data: plaerRes })]));
                    setDubDivisions(prevState => ([...prevState, ({ ...division, data: plaerRes })]))
                }
                else {
                    setPlayersPoints([...plaerRes]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }


    const columns = (tableData) => [
        {
            name: "rank", label: "Rank", options: {
                customBodyRender: (rank, tableMeta) => {
                    const prevRank = playersPoints[tableMeta.rowIndex]?.prev_rank;
                    return <RankLabel rank={rank} prevRank={prevRank} />;
                },
            }
        },
        {
            name: "player", label: "Player", options: {
                customBodyRender: (player) => (
                    <div className={classes.nameRoot}>
                        <AvatarComponent data={player} from='PLAYER' type="PLAYER" name={player.name} username={player._id} url={player.photo_url} onClick={() => { }} style={{ marginRight: 14 }} filtersData={filtersData} />
                        {player && player.name}
                    </div>

                )
            }
        },
        {
            name: "team", label: "Team", options: {
                display: filtersData.season?.season_format !== 'PLAYER_RACE',
                customBodyRender: (team, tableMeta) => (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                        <div className={classes.nameRoot}>
                            <AvatarComponent type="TEAM" name={team?.name} username={team?._id} url={team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                        </div>
                        <div style={{ textAlign: 'left', fontWeight: 600 }}>
                            {team && team.name}
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                                {tableData[tableMeta.rowIndex]?.captain && <span style={{ marginRight: 10 }}>C : {tableData[tableMeta.rowIndex]?.captain}</span>}
                                {tableData[tableMeta.rowIndex]?.mentor && <span>M : {tableData[tableMeta.rowIndex]?.mentor}</span>}
                            </div>
                        </div>
                    </div>

                )
            }
        },
        // { name: "captain", label: "Captain" },
        // { name: "mentor", label: "Mentor" },
        { name: "match_points", label: `Match/${seasonDetails?.labels_config?.kpis?.core} Pts`, options: { ...commonColumnOptions, customBodyRender: (match_points) => getNumberFormat(userDetails?.countryCode, match_points, decimalView) } },
        { name: "bonus_points", label: `${seasonDetails?.labels_config?.kpis?.bonus} Points`, options: { ...commonColumnOptions, customBodyRender: (bonus_points) => getNumberFormat(userDetails?.countryCode, bonus_points, decimalView) } },
        {
            name: "total_points", label: "Total Pts", options: {
                ...commonColumnOptions,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
                customHeadRender: () =>
                    <th className="MuiTableCell-head" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>Total Pts</span>
                        <Tooltip interactive arrow title={<p>Total Pts = Match/Core Pts + Bonus Pts</p>}>
                            <Info style={{ fontSize: 16, marginLeft: 5 }} />
                        </Tooltip>
                    </th>
            },
        },
    ];

    return (
        <>
            {/* <SeasonFilters filtersData={filtersData} setFiltersData={setFiltersData} defaultFilters={defaultFilters} /> */}
            {
                filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.auto_player_sprints ?
                    <div style={{ textAlign: 'center', marginTop: 40 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>Since player performance is not measured as a part of this selected season which is of ‘Team race’ format, we do not have any player points table to show here</p>
                    </div>
                    :
                    <div style={{ width: '100%' }}>
                        <CustomListGroup data={subTabsPlayer} selectedItem={subTabsPlayer.find((option) => option.value === currentPlayerTab)} onClick={handleSubTabChange} style={{ marginLeft: 0 }} />
                        {!loading &&
                            [(currentPlayerTab === 0) ?
                                <div style={{ marginTop: 24 }}>
                                    <p className="table-title decimal-switch-root">
                                        <p></p>
                                        <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                    </p>
                                    <CustomDataTable columns={columns(playersPoints)} data={playersPoints} loading={loading} />
                                </div>
                                :
                                (dubDivisions.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={dubDivisions.length}
                                        next={getDivisionMore}
                                        hasMore={dubDivisions.length !== pagination.total_count}
                                        // loader={skeletonLoading}
                                        scrollableTarget="points_tabledata"
                                    >
                                        {
                                            sortBy(dubDivisions, 'name', 'asc').map((data, i) => (
                                                <div style={{ width: '99%' }}>
                                                    <p className="table-title decimal-switch-root">
                                                        <p style={{ marginBottom: 5, fontWeight: 600, color: '#000000c9' }}>Division : {data.name}</p>
                                                        {i === 0 && < DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />}
                                                    </p>
                                                    <CustomDataTable columns={columns(data.data)} data={data.data} loading={loading} />
                                                </div>
                                            ))
                                        }
                                    </InfiniteScroll >
                                    :
                                    < IllustrationView loading={loading} style={{ marginTop: '91px' }} />
                                )]
                        }
                    </div>
            }
        </>
    );
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading,
    seasonDetails: state.game.seasonDetails
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerPointsTable));

