import React from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { CustomListGroup } from '../LiveScores/RoundsListView';
import { useState } from 'react';
import PlayerPersonal from './PlayerPersonal';
import PlayerPerformance from './PlayerPerformance';
import PlayerPosts from './PlayerPosts';
import PlayerMedia from './PlayerMedia';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 20,
        paddingBottom: '50px'
    },
}))

const MenuOptions = [
    { label: 'Personal', value: 0, pathname: 'personal' },
    { label: 'Performance', value: 1, pathname: 'performance' },
    { label: 'Posts', value: 2, pathname: 'posts' },
    { label: 'Media', value: 3, pathname: 'media' },
];

const ProfileDetail = ({ history, location, username }) => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const { path } = useRouteMatch();
    const { params } = useRouteMatch();

    const handleTabChange = (newValue) => {
        setCurrentTab(newValue?.value || 0);
    }

    return (
        <div className={classes.root}>
            <div>
                <CustomListGroup data={MenuOptions} selectedItem={MenuOptions.find((option) => option.value === currentTab)} onClick={handleTabChange} style={{ maxWidth: 'max-content', marginLeft: 0 }} />
                {currentTab === 0 ?
                    <PlayerPersonal username={username} />
                    : currentTab === 1 ?
                        <PlayerPerformance username={params?.id} />
                        : currentTab === 2 ?
                            <PlayerPosts username={username} />
                            :
                            <PlayerMedia username={username} />}

            </div>
        </div>
    )
}

export default withRouter(ProfileDetail);
