import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const SeasonLabelsCommon = ({ seasonDetails, kpiType }) => {
    switch (kpiType) {
        case 'CORE_KPI':
            return (seasonDetails?.labels_config?.kpis?.core) ? seasonDetails?.labels_config?.kpis?.core : 'core'
        case 'BONUS_KPI':
            return (seasonDetails?.labels_config?.kpis?.bonus) ? seasonDetails?.labels_config?.kpis?.bonus : 'bonus'
        default:
            return seasonDetails;
    }

}
const mapStateToProps = (state) => ({
    seasonDetails: state.game

});

export default withRouter(connect(mapStateToProps, null)(SeasonLabelsCommon));
