import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, useMediaQuery, useTheme, IconButton, DialogTitle, DialogContent, ListItem, ListItemAvatar, Avatar, List, Divider, ListItemText, Button, ListItemSecondaryAction } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { followUser, unFollowUser } from '../../ducks/favourites';
import { getTaggedUsersList, openTaggedUsersList } from "../../ducks/arena";
import { ChaseonsSkeleton, ChaseonsTemplate } from '../Arena/ReactionsDialog';
import UserProfileCard from '../UserProfileCard/UserProfileCard';
import { getProfileUrl } from '../Arena';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        minHeight: 470,
        borderRadius: 15
    },
    dialogTitle: {
        // paddingBottom: 0,
        fontSize: 18,
        color: '#262626'
    },
    dialogContent: {
        paddingTop: 0
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12
    }
}))

const TaggedUsersDialog = ({ taggedUsers: { open, postId }, openTaggedUsersList, getTaggedUsersList, user, history, followUser, unFollowUser }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [usersList, setUsersList] = useState([]);
    const [skeletonLoading, setSkeletonLoading] = useState(false);

    useEffect(() => {
        if (postId) {
            setSkeletonLoading(true);
            getTaggedUsersList({ postId, onGetTaggedUsersSuccessCB, onGetTaggedUsersErrorCB })
        }
    }, [postId]);

    const onGetTaggedUsersSuccessCB = (result) => {
        setUsersList(result);
        setSkeletonLoading(false);
    }

    const onGetTaggedUsersErrorCB = (result) => {
        enqueueSnackbar(`Failed to fetch tagged users.`, {
            variant: "error",
            preventDuplicate: true
        });
        handleClose();
    }

    const handleClose = () => {
        openTaggedUsersList({ open: false, postId: null });
        setUsersList([]);
    }

    const onFollowUserSuccess = result => {
        let usersListDup = JSON.parse(JSON.stringify(usersList));
        const index = usersListDup.findIndex(item => item.userId === result.username);
        usersListDup[index].follower = true;
        setUsersList(usersListDup);
    };
    const onFollowUserError = error => {
        console.log('onFollowUserError ', error.response);
    };

    const onUnFollowUserSuccess = result => {
        let usersListDup = JSON.parse(JSON.stringify(usersList));
        const index = usersListDup.findIndex(item => item.userId === result.username);
        usersListDup[index].follower = false;
        setUsersList(usersListDup);
    }
    const onUnFollowUserError = error => {
        console.log('onUnFollowUserError ', error.response);
    }

    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='xs'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="responsive-dialog-title"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    Tagged people
                    <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers className={classes.dialogContent}>
                    {skeletonLoading && [0, 1, 2, 4, 5].map(i => <ChaseonsSkeleton key={i} />)}
                    <List dense className={classes.root}>
                        {
                            usersList.length ? usersList.map((item, i) => (
                                <Fragment key={i}>
                                    <UserProfileCard
                                        key={i}
                                        index={i}
                                        name={item.name}
                                        followActions={true}
                                        username={item.userId}
                                        avatarUrl={item.photoUrl}
                                        follower={item.follower}
                                        showFollowStatus={true}
                                        isLoggedInUser={user.username === item.userId}
                                        avatarStyles={classes.avatarStyles}
                                        profileNameStyles={classes.profileName}
                                        onProfileClick={() => {
                                            history.push(getProfileUrl(item.userId))
                                            handleClose();
                                        }}
                                        style={{ margin: '18px 0px' }}
                                        followUser={() => followUser({
                                            requestObj: { username: item.userId },
                                            onFollowUserSuccess,
                                            onFollowUserError
                                        })}
                                        unFollowUser={() => unFollowUser({
                                            username: item.userId,
                                            onUnFollowUserSuccess,
                                            onUnFollowUserError
                                        })}
                                    />
                                </Fragment>
                            ))
                                :
                                !skeletonLoading && <p style={{ textAlign: 'center' }}>No Results found</p>
                        }
                    </List>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    taggedUsers: state.arena.taggedUsers
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        openTaggedUsersList: (options) => dispatch(openTaggedUsersList(options)),
        getTaggedUsersList: (options) => dispatch(getTaggedUsersList(options)),
        followUser: options => dispatch(followUser(options)),
        unFollowUser: options => dispatch(unFollowUser(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaggedUsersDialog));