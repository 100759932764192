import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete, TabPanel } from "@material-ui/lab";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash.svg";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  Tab,
  Tabs,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { indexOf } from "underscore";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    width: "100%",
  },
  alignDate: {
    marginTop: "0rem",
    width: "100%",
  },
  datePickerWidth: {
    borderRadius: "4px",
    border: "1px solid #c5c5c5",
  },
  legend: {
    color: theme.palette.primary.main,
  },
  filter_cat: {
    marginTop: "9px",
    marginLeft: "12px",
  },
  modalPosition: {
    minHeight: 470,
    // width: "100%",
    minWidth: 625,
    borderRadius: 5,
    margin: 0,
  },
  textColor: {
    color: theme.palette.primary.main,
    fontSize: "16px",
    fontWeight: 900,
    padding: "10px 15px",
    "& h2": { fontSize: "17px" },
  },
  contents: {
    padding: "8px 0px !important",
    overflowY: "auto",
    "& .MuiDialogContent-root": {
      padding: "8px 0px !important",
    },
    "& .Mui-selected": {
      background: theme.palette.primary.light,
    },
    '& .MuiTabs-flexContainer': {
      display: 'initial'
    }
  },
  SearchIcon: {
    position: "relative",
    top: "6px",
    marginRight: "15px",
  },
  profileTabRoot: {
    minWidth: 145,
    minHeight: "auto",
    fontSize: 14,
    textTransform: "capitalize",
    color: "#535353",
    padding: "6px 6px",
    opacity: 0.9,
    borderBottom: "1px solid #e8e8e8 !important",
    borderTop: "1px solid #e8e8e8 !important",
  },
  profileTabsRoot: {
    minHeight: "auto",
  },
  formRoot: {
    marginRight: "10px",
    marginTop: "10px",
  },
  autocompleteRoot: {
    padding: "3px 7px !important",
    // width: "450px",
    height: "40px",
    borderRadius: "5px !important",
    overflow: "clip",
    whiteSpace: "nowrap",
    "& .MuiAutocomplete-tagSizeSmall": {
      width: "65px !important",
    },
    "& .MuiInputBase-root": {
      overflow: "hidden !important",
    },
  },
  iconButton: {
    border: "1px solid #cec8c8 !important",
    padding: 5,
    borderRadius: "6px",
    width: "40px",
    height: "40px",
    cursor: 'pointer',
    "&.MuiIconButton-root": {
      //   padding: "0px !important",
    },
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // border: "1px solid #cec8c8",
    // width: "40px",
    // borderRadius: "3px",
    // height: "34px",
    // padding: 2,
  },
  kpiContainer: {
    // display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    // padding: "10px 15px",
  },
  kpicontainers: {
    padding: "7px 15px",
    borderBottom: "1px solid #e8e8e8 !important",
    marginBottom: 5,
    marginTop: 5
  },
  iconsConatiner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  maintab_content: {
    padding: "8px 15px",
    "& .MuiOutlinedInput-notchedOutline": { borderRadius: 6 },
  },
  maintab_content1: {
    height: 340,
    overflow: 'auto',
    padding: 0,
    "& .MuiOutlinedInput-notchedOutline": { borderRadius: 6 },
    '& .MuiFormControlLabel-label': { fontSize: 13 }
  },
  rangeSelects: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      margin: 0,
      paddingRight: 15,
      paddingLeft: 5
    },
    '& h6': {
      margin: 0,
      width: 10,
      paddingRight: 10
    }
  },
  selectall_kpi: {
    padding: "8px 15px",
    '& .all_button': {
      fontSize: 15,
      padding: '3px 15px',
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main} !important`,
      '&:hover': {
        background: 'none'
      },
    }
  },
  selectrange: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    "& p": { fontSize: 14, margin: 0 },
    "& .textboxes": {
      width: "100px",
      height: '40px',
      margin: "0px 5px",
      '& .MuiOutlinedInput-input': {}
    },
  },
  slider_style: {
    padding: "0px 12px",
    border: "1px solid #cec8c8 !important",
    borderRadius: 6,
    height: 38,
    '& .MuiSlider-root': { marginTop: 4 },
    '& .MuiSlider-rail ': { height: 3 },
    '& .MuiSlider-track': { height: 3 }
  },
  nokpi: {
    textAlign: 'center',
    paddingTop: 30
  },
  radioranks: {
    display: "flex",
    alignItems: "center",
  },
  customRadio: {
    minWidth: 150,
    "& span": {
      fontSize: "13px",
    },
  },
  rangeText: {
    '& .MuiOutlinedInput-input': {
      paddingLeft: 8,
      paddingRight: 8
    }
  }
}));


const StatsPlayerFilterModal = (props) => {
  const classes = useStyles();
  const kpiList = props?.kpis?.map((data) => ({
    id: data.kpi_id,
    label: data.kpi_name,
    ...data,
  }));

  const achivementsSelect = props?.achivementsSelects;
  const plannedAchivementSelect = props?.plannedAchivementsSelects
  const actualsSelect = props?.actualsSelects;
  const kpiAchievmentsList = kpiList //.filter((obj) => obj.based_on === "ACHIEVEMENT");
  const kpiActualsList = kpiList //.filter((obj) => obj.based_on === "ACTUALS");
  const kpiPlannedAchievementList = kpiList.filter(el => el?.achievement_plan_enabled);

  const rolesList = props.roles && props.roles.map((data) => ({
    id: data.role_id,
    label: data.name,
  }));
  const teamList = props.teams && props.teams.map((data) => ({
    id: data.team_id,
    label: data.name,
  }));
  const [tabValue, setTabValue] = useState(props.basedOn || 0);
  const [kpiFields, setKpiFields] = useState({ kpi: [], isChecked: false });

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    props.setBasedOn(newValue);
  };

  const handleChange = (e, data, field, index) => {
    const uniqueStats = [
      ...new Map(data.map((item) => [item["id"], item])).values(),
    ];
    let values = [...kpiFields];
    values[index].kpi = uniqueStats;
    setKpiFields(values);
  };

  const handleAddKpi = (index) => {
    let values = [...kpiFields];
    values.push({ kpi: index + 1 });
    setKpiFields(values);
  };

  const handleRemoveKpi = (index) => {
    let values = [...kpiFields];
    values.splice(index, 1);
    setKpiFields(values);
  };

  return (
    <>
      <Dialog
        scroll={"paper"}
        classes={{ paper: classes.modalPosition }}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={props.open}
        maxWidth="sm"
      >
        <DialogTitle
          id="confirmation-dialog-title"
          className={classes.textColor}
        >
          {props.title}
        </DialogTitle>
        <DialogContent className={classes.contents}>
          {/* {props.teams?.length === 0 ? '' :
                        < FormControl fullWidth className={classes.root}>
                            <Autocomplete
                                id="teams"
                                multiple
                                options={teamList}
                                disableListWrap
                                disableCloseOnSelect
                                getOptionLabel={option => (option.label || '')}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                value={props.statsPlayerFilter?.teams}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        label="Teams"
                                    />
                                }
                                onChange={(...args) => handleChange(args[1], 'teams')}
                            />
                        </FormControl>
                    }
                    <FormControl fullWidth className={classes.root}>
                        <Autocomplete
                            id="roles"
                            multiple
                            options={rolesList}
                            disableListWrap
                            disableCloseOnSelect
                            getOptionLabel={option => (option.label || '')}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            value={props.statsPlayerFilter?.roles}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="Roles"
                                />
                            }
                            onChange={(...args) => handleChange(args[1], 'roles')}
                        />
                    </FormControl> */}
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
            className={classes.profileTabsRoot}
            classes={{ indicator: classes.tabIndicatorProfile }}
          >
            <Tab classes={{ root: classes.profileTabRoot }} label="Rank" />
            <Tab classes={{ root: classes.profileTabRoot }} label="Achievement %" />
            <Tab classes={{ root: classes.profileTabRoot }} label="Actuals" />
            <Tab classes={{ root: classes.profileTabRoot }} label="MTD/WTD Achievement %" />
          </Tabs>
          {tabValue === 0 ? (
            <>
              <div className={classes.maintab_content}>
                <Grid container spacing={2} style={{ paddingTop: 10, height: 340 }}>
                  <Grid item xs={6}>
                    <div className={classes.rangeSelects}>
                      <p>Ranks</p>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          name="From"
                          label="From"
                          type="number"
                          autoComplete="off"
                          variant="outlined"
                          value={props.rankLimit.low}
                          size="small"
                          onChange={(e) => props.handleRankChange(e, "low")}
                          id="From"
                          InputProps={{ type: "number", inputProps: { min: 0 } }}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className={classes.rangeSelects}>
                      <h6>-</h6>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          name="To"
                          label="To"
                          type="number"
                          variant="outlined"
                          min={props.rankLimit.low}
                          value={props.rankLimit.high}
                          size="small"
                          onChange={(e) => props.handleRankChange(e, "high")}
                          id="To"
                          InputProps={{ type: "number", inputProps: { min: props.rankLimit.low } }}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : tabValue === 1 ? (
            <div className={classes.maintab_content1}>
              {kpiAchievmentsList.length !== 0 ?
                <div className={classes.achivements}>
                  {
                    (kpiAchievmentsList.length !== achivementsSelect.length) && (kpiAchievmentsList.length > 1) &&
                    <div className={classes.selectall_kpi}>
                      <Button variant="outlined" color="primary" size="medium" className="all_button" onClick={() => props.addAllAchievmntFields()}>+ Add All Kpi</Button>
                    </div>
                  }
                  {achivementsSelect?.map((element, index) => (
                    <div className={classes.kpiContainer} key={index}>
                      <div className={classes.kpicontainers}>
                        <Grid container spacing={1}>
                          <Grid item xs={11}>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="kpi"
                                autoComplete
                                options={kpiAchievmentsList.filter(kpi => !achivementsSelect.map(ele => ele.kpiName?.id).includes(kpi.id))}
                                limitTags={1}
                                getOptionLabel={(option) => option.label}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                value={element?.kpiName}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="Select KPI"
                                    size="small"
                                  />
                                )}
                                onChange={(e, val) => props.handleAchieveChange(e, index, val, "kpiName")}
                              />
                            </FormControl>
                          </Grid>
                          {achivementsSelect.length > 1 ?
                            <Grid item xs={1}>
                              <IconButton className={classes.iconButton} value="" onClick={() => props.removeAchievmntFields(index)}>
                                <DeleteIcon
                                  fontSize="small"
                                  color="disabled"
                                  className={classes.icon}
                                />
                              </IconButton>
                            </Grid>
                            :
                            <Grid item xs={1}>
                              <IconButton disabled className={classes.iconButton} style={{ background: '#e4e4e4' }} value="" onClick={() => props.removeAchievmntFields(index)}>
                                <DeleteIcon
                                  fontSize="small"
                                  color="disabled"
                                  className={classes.icon}
                                />
                              </IconButton>
                            </Grid>
                          }
                          <Grid item xs={5}>
                            <div className={classes.selectrange}>
                              <p>Percentage</p>
                              <div className="textboxes">
                                <FormControl fullWidth>
                                  <TextField
                                    fullWidth
                                    className={classes.rangeText}
                                    name="From"
                                    label="min"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={element?.percentageHighest ? 100 : element?.percentageRange[0]}
                                    size="small"
                                    id="from"
                                    type="number"
                                    onInput={(e) => {
                                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                                    }}
                                    InputProps={{
                                      type: "number",
                                      inputProps: {
                                        // readOnly: true,
                                        max: 2000,
                                        min: 0
                                      }
                                    }}
                                    onChange={(e, val) => props.handleAchieveChange(e, index, val, "percentageMin")}
                                  />
                                </FormControl>
                              </div>
                              <div><RemoveIcon style={{ color: 'silver', fontSize: 15 }} /></div>
                              <div className="textboxes">
                                <FormControl fullWidth>
                                  <TextField
                                    className={classes.rangeText}
                                    fullWidth
                                    name="To"
                                    label="max"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={element?.percentageHighest ? '' : element?.percentageRange[1]}
                                    size="small"
                                    id="to"
                                    type="number"
                                    onInput={(e) => {
                                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                                    }}
                                    InputProps={{
                                      type: "number",
                                      inputProps: {
                                        // readOnly: true,
                                        min: 0,
                                        max: 2000,
                                        min: element?.percentageRange[0]
                                      }
                                    }}
                                    onChange={(e, val) => props.handleAchieveChange(e, index, val, "percentageMax")}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            {element?.percentageHighest == false ?
                              <div className={classes.slider_style}>
                                <Slider
                                  value={element?.percentageRange}
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={1000}
                                  aria-labelledby="range-slider"
                                  getAriaValueText={props.valuetext}
                                  onChange={(e, val) => props.handleAchieveChange(e, index, val, "percentageRange")}
                                />
                              </div>
                              :
                              <div className={classes.slider_style}>
                                <Slider disabled
                                  value={element?.percentageRange}
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={1000}
                                  aria-labelledby="range-slider"
                                  getAriaValueText={props.valuetext}
                                  onChange={(e, val) => props.handleAchieveChange(e, index, val, "percentageRange")}
                                />
                              </div>
                            }
                          </Grid>
                          {/* <Grid item xs={6}>
                            <div style={{ marginTop: '-10px' }}>
                              <FormControlLabel
                                control={<Checkbox color="primary" checked={element?.percentageHighest}
                                  onChange={(e, val) => props.handleAchieveChange(e, index, val, "percentageHighest")}
                                  name="checkedA" />}
                                label="Show more then 100%"
                              />
                            </div>
                          </Grid> */}
                        </Grid>
                      </div>
                    </div>
                  ))}
                  {kpiAchievmentsList.length !== achivementsSelect.length &&
                    <div className={classes.selectall_kpi}>
                      <Button variant="outlined" color="primary" size="medium" className="all_button" onClick={() => props.addAchievmntFields()} >+ Add New Kpi</Button>
                    </div>
                  }
                </div>
                :
                <div className={classes.nokpi}>
                  <p>No KPI's available</p>
                </div>
              }
            </div>
          ) : tabValue === 2 ? (
            <div className={classes.maintab_content1}>
              {kpiActualsList.length !== 0 ?
                <div className={classes.achivements}>
                  {(kpiActualsList.length !== actualsSelect.length) && (kpiActualsList.length > 1) &&
                    <div className={classes.selectall_kpi}>
                      <Button variant="outlined" color="primary" size="medium" className="all_button" onClick={() => props.addAllActualFields()}>+ Add All Kpi</Button>
                    </div>
                  }
                  {actualsSelect?.map((element, index) => (
                    <div className={classes.kpiContainer} key={index}>
                      <div className={classes.kpicontainers}>
                        <Grid container spacing={1}>
                          <Grid item xs={11}>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="kpi"
                                autoComplete
                                options={kpiActualsList.filter(kpi => !actualsSelect.map(ele => ele.kpiName?.id).includes(kpi.id))}
                                limitTags={1}
                                getOptionLabel={(option) => option.label}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                value={element?.kpiName}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="Select KPI"
                                    size="small"
                                  />
                                )}
                                onChange={(e, val) => props.handleActualChange(e, index, val, "kpiName")}
                              />
                            </FormControl>
                          </Grid>
                          {actualsSelect.length > 1 ?
                            <Grid item xs={1}>
                              <IconButton className={classes.iconButton} value="" onClick={() => props.removeActualFields(index)}>
                                <DeleteIcon
                                  fontSize="small"
                                  color="disabled"
                                  className={classes.icon}
                                />
                              </IconButton>
                            </Grid>
                            :
                            <Grid item xs={1}>
                              <IconButton disabled className={classes.iconButton} style={{ background: '#e4e4e4' }} value="" onClick={() => props.removeActualFields(index)}>
                                <DeleteIcon
                                  fontSize="small"
                                  color="disabled"
                                  className={classes.icon}
                                />
                              </IconButton>
                            </Grid>
                          }
                          <Grid item xs={6}>
                            <div className={classes.selectrange}>
                              <p>Actuals</p>
                              <div className="textboxes">
                                <FormControl fullWidth>
                                  <TextField
                                    fullWidth
                                    name="From"
                                    label="min"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={element?.actualMin}
                                    size="small"
                                    id="from"
                                    type="number"
                                    InputProps={{
                                      type: "number",
                                      inputProps: { min: 0 },
                                    }}
                                    onChange={(e, val) => props.handleActualChange(e, index, val, "actualMin")}
                                  />
                                </FormControl>
                              </div>
                              <div><RemoveIcon style={{ color: 'silver', fontSize: 15 }} /></div>
                              <div className="textboxes">
                                <FormControl fullWidth>
                                  <TextField
                                    fullWidth
                                    name="To"
                                    label="max"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={element?.actualMax}
                                    size="small"
                                    id="to"
                                    type="number"
                                    InputProps={{
                                      type: "number",
                                      inputProps: { min: element?.actualMin },
                                    }}
                                    onChange={(e, val) => props.handleActualChange(e, index, val, "actualMax")}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={5}>
                            <div className={classes}>
                              <FormControl fullWidth>
                                <TextField
                                  fullWidth
                                  name="Measurement"
                                  label="Measurement"
                                  autoComplete="off"
                                  variant="outlined"
                                  value={element?.kpiName ? kpiList?.filter(d => (d?.kpi_id.includes(element?.kpiName.id))).map(d => d?.unit_of_measurement) : ''}
                                  size="small"
                                  id="Measurement"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onChange={(e, val) => props.handleActualChange(e, index, val, "actualMeasurement")}
                                />
                              </FormControl>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ))}
                  {kpiActualsList.length !== actualsSelect.length &&
                    <div className={classes.selectall_kpi}>
                      <Button variant="outlined" color="primary" size="medium" className="all_button" onClick={() => props.addActualFields()}>+ Add New Kpi</Button>
                    </div>
                  }
                </div>
                :
                <div className={classes.nokpi}>
                  <p>No KPI's available</p>
                </div>
              }
            </div>
          ) : (
            <div className={classes.maintab_content1}>
              {kpiPlannedAchievementList.length !== 0 ?
                <div className={classes.achivements}>
                  {
                    (kpiPlannedAchievementList.length !== plannedAchivementSelect.length) && (kpiPlannedAchievementList.length > 1) &&
                    <div className={classes.selectall_kpi}>
                      <Button variant="outlined" color="primary" size="medium" className="all_button" onClick={() => props.addAllPlannedAchievmntFields()}>+ Add All Kpi</Button>
                    </div>
                  }
                  {plannedAchivementSelect?.map((element, index) => (
                    <div className={classes.kpiContainer} key={index}>
                      <div className={classes.kpicontainers}>
                        <Grid container spacing={1}>
                          <Grid item xs={11}>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="kpi"
                                autoComplete
                                options={kpiPlannedAchievementList.filter(kpi => !plannedAchivementSelect.map(ele => ele.kpiName?.id).includes(kpi.id))}
                                limitTags={1}
                                getOptionLabel={(option) => option.label}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                value={element?.kpiName}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="Select KPI"
                                    size="small"
                                  />
                                )}
                                onChange={(e, val) => props.handlePlannedAchieveChange(e, index, val, "kpiName")}
                              />
                            </FormControl>
                          </Grid>
                          {plannedAchivementSelect.length > 1 ?
                            <Grid item xs={1}>
                              <IconButton className={classes.iconButton} value="" onClick={() => props.removePlannedAchievmntFields(index)}>
                                <DeleteIcon
                                  fontSize="small"
                                  color="disabled"
                                  className={classes.icon}
                                />
                              </IconButton>
                            </Grid>
                            :
                            <Grid item xs={1}>
                              <IconButton disabled className={classes.iconButton} style={{ background: '#e4e4e4' }} value="" onClick={() => props.removePlannedAchievmntFields(index)}>
                                <DeleteIcon
                                  fontSize="small"
                                  color="disabled"
                                  className={classes.icon}
                                />
                              </IconButton>
                            </Grid>
                          }
                          <Grid item xs={5}>
                            <div className={classes.selectrange}>
                              <p>Percentage</p>
                              <div className="textboxes">
                                <FormControl fullWidth>
                                  <TextField
                                    fullWidth
                                    className={classes.rangeText}
                                    name="From"
                                    label="min"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={element?.percentageHighest ? 100 : element?.percentageRange[0]}
                                    size="small"
                                    id="from"
                                    type="number"
                                    onInput={(e) => {
                                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                                    }}
                                    InputProps={{
                                      type: "number",
                                      inputProps: {
                                        // readOnly: true,
                                        max: 2000,
                                        min: 0
                                      }
                                    }}
                                    onChange={(e, val) => props.handlePlannedAchieveChange(e, index, val, "percentageMin")}
                                  />
                                </FormControl>
                              </div>
                              <div><RemoveIcon style={{ color: 'silver', fontSize: 15 }} /></div>
                              <div className="textboxes">
                                <FormControl fullWidth>
                                  <TextField
                                    className={classes.rangeText}
                                    fullWidth
                                    name="To"
                                    label="max"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={element?.percentageHighest ? '' : element?.percentageRange[1]}
                                    size="small"
                                    id="to"
                                    type="number"
                                    onInput={(e) => {
                                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                                    }}
                                    InputProps={{
                                      type: "number",
                                      inputProps: {
                                        // readOnly: true,
                                        min: 0,
                                        max: 2000,
                                        min: element?.percentageRange[0]
                                      }
                                    }}
                                    onChange={(e, val) => props.handlePlannedAchieveChange(e, index, val, "percentageMax")}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            {element?.percentageHighest == false ?
                              <div className={classes.slider_style}>
                                <Slider
                                  value={element?.percentageRange}
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={1000}
                                  aria-labelledby="range-slider"
                                  getAriaValueText={props.valuetext}
                                  onChange={(e, val) => props.handlePlannedAchieveChange(e, index, val, "percentageRange")}
                                />
                              </div>
                              :
                              <div className={classes.slider_style}>
                                <Slider disabled
                                  value={element?.percentageRange}
                                  valueLabelDisplay="auto"
                                  min={0}
                                  max={1000}
                                  aria-labelledby="range-slider"
                                  getAriaValueText={props.valuetext}
                                  onChange={(e, val) => props.handlePlannedAchieveChange(e, index, val, "percentageRange")}
                                />
                              </div>
                            }
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ))}
                  {kpiPlannedAchievementList.length !== plannedAchivementSelect.length &&
                    <div className={classes.selectall_kpi}>
                      <Button variant="outlined" color="primary" size="medium" className="all_button" onClick={() => props.addPlannedAchievmntFields()} >+ Add New Kpi</Button>
                    </div>
                  }
                </div>
                :
                <div className={classes.nokpi}>
                  <p>No KPI's available</p>
                </div>
              }
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => props.onClickCancel()}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => props.onClickOk()}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatsPlayerFilterModal;
