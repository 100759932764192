import React, { useEffect, useState } from "react";
import { getLeaguesList, getSeasonsList, getRoundsList, getMatchesList, getLoggedInUserRole, getRewardsList, getRewardsPositionsList } from "../../ducks/game";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import MentorDashboard from "./MentorDashboard";
import PlayerDashboard from "./PlayerDashboard";
import NonPlayerDashboard from "./NonPlayerDashboard";
import { makeStyles, Tabs, Tab, Accordion, AccordionSummary, Typography, AccordionDetails } from "@material-ui/core";
import RewardsBannerComponent from "./RewardsBanner";
import { Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { openCustomDialog } from "../../ducks/meetings";
import CustomDialogComponent from "../ChaseMeet/CustomDialogComponent";
import moment from 'moment';
import rewardImage from '../../assets/images/rewards-banner.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import qs from 'query-string';
import RewardsDetails from "./RewardsDetails";

const useStyles = makeStyles((theme) => ({
    tabsContainerStyles: {
        // marginLeft: 34,
        justifyContent: 'center'
    },
    tabsRoot: {
        width: '100%',
        maxWidth: '100%',
        zIndex: 11,
        // margin: '-8px -46px 30px',
        // padding: 10,
    },
    tabStyleDiv: {
        minWidth: `calc(${100 / 3}% - 20px)`,
        minHeight: 40,
        textTransform: 'capitalize',
        padding: '6px',
        fontSize: 13,
        textAlign: 'left',
        opacity: 1,
        margin: '0 5px',
        borderRadius: 5,
        transition: 'top 0.2s ease-in-out',
        fontFamily: 'Poppins !impotant',
    },
    expiredLabel: {
        color: 'red',
        fontSize: 11,
        fontWeight: 600,
        borderRadius: 4,
        position: 'absolute',
        top: 7,
        left: 17,
    },
    mainRewards: {
        backgroundImage: `url(${rewardImage})`,
        margin: '-8px -46px 30px',
        '& .MuiAccordion-root': {
            background: 'none',
            '& p': { color: 'white' }
        },
        '& .MuiAccordionDetails-root': { padding: 0, paddingBottom: 12 },
        '& .MuiAccordionSummary-root': { minHeight: 0, height: 43 },
        '& .MuiAccordionSummary-content': { paddingLeft: '45px' },
        '& .MuiAccordionSummary-expandIcon': { color: 'white', left: '-97%' },
        '& .MuiAccordionSummary-content.Mui-expanded': { margin: 0 },
        '& .MuiTab-root': { lineHeight: 'normal' }
    },
    disabled: {
        opacity: 0.5,
        backgroundColor: '#FFF !important'

    }

}));

const Dashboard = ({ history, location, filtersData, rounds, userInfo, getOverallOrgStats, getLoggedInUserRole, getSeasonsList, getRewardsList, getRewardsPositionsList, openCustomDialog }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [rewards, setRewards] = useState([]);

    useEffect(() => {
        if (filtersData?.season?._id)
            getRewardsList({ seasonId: filtersData?.season?._id, onSuccessCB, onErrorCB });
    }, [filtersData?.season?._id]);

    const onSuccessCB = (result, headers) => {
        if (result) {
            setRewards(result.map(reward => ({ ...reward, expired: moment(reward.end_date).isBefore(new Date(), 'day') })));
        }
        setLoading(false);
    }
    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const natigateToAllStats = () => {
        const historyPath = `${history.location?.search}&round_id=ALL`;
        history.push(`/leaguess/c2/stats/overall${historyPath}`)
    }
    const natigateToLiveScores = () => {
        history.push(`/leaguess/c2/live-scores${history.location?.search}`)
    }

    const navigateToTeamLeaderboard = () => {
        history.push(`/leaguess/c2/points-table/team${history.location?.search}`)
    }

    const navigateToPlayerLeaderboard = () => {
        history.push(`/leaguess/c2/points-table/player${history.location?.search}`)
    }

    const navigateToAllMatches = () => {
        history.push(`/leaguess/c2/matches${history.location?.search}`);
    }

    const handleRewardSelect = (index) => {
        openCustomDialog({ open: true, children: <RewardsDetails reward={rewards[index]} rewardIndex={index} filtersData={filtersData} />, title: "Rewards", from: "standings" });
    }

    const natigateToAllPlayers = () => {
        const query = qs.parse(history.location.search);
        let type = filtersData?.season?.season_format === 'HEAD_TO_HEAD' ? 'round=ALL' : filtersData?.season?.season_format === 'TEAM_RACE' ? 'match=ALL' : ''
        filtersData?.season?.season_format === 'HEAD_TO_HEAD' ? history.push({ pathname: `/leagues/stats/player`, search: `?round=ALL&league=${query.league}&season=${query.season}`, state: { teams: userInfo?.myTeams } })
            : history.push({ pathname: `/leagues/stats/player`, search: `?match=ALL&league=${query.league}&season=${query.season}`, state: { teams: userInfo?.myTeams } })
    }
    return (
        <div style={{ padding: '24px 46px' }}>
            {/* {rewards.length > 0 &&
                <>
                    <Tabs
                        id="filter-tabs"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        aria-label="scrollable auto tabs example"
                        className={classes.tabsRoot}
                        classes={{ flexContainer: rewards.length < 3 && classes.tabsContainerStyles }}

                    >
                        <Typography className={classes.heading}>Awards</Typography>
                    </AccordionSummary>
                    <AccordionDetails>


                        {rewards.length > 0 &&
                            <>
                                <Tabs
                                    id="filter-tabs"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="on"
                                    aria-label="scrollable auto tabs example"
                                    className={classes.tabsRoot}
                                    classes={{ flexContainer: rewards.length < 3 && classes.tabsContainerStyles }}

                                >
                                    {console.log('rewards list ', rewards)}
                                    {
                                        [...rewards].map((obj, i) =>

                                            (obj?.reward_status.search("Expired") === -1) ?

                                                <Tab className={classes.tabStyleDiv} key={i} onClick={() => handleRewardSelect(i)} label={

                                                    <div onClick={() => handleRewardSelect(i)} style={{ cursor: 'pointer' }} >
                                                        <RewardsBannerComponent obj={obj} style={{ marginLeft: 10 }} />
                                                    </div>


                                                } />
                                                :
                                                <Tab style={{ cursor: 'auto' }} className={classes.tabStyleDiv} key={i} label={
                                                    <div>
                                                        <RewardsBannerComponent obj={obj} style={{ marginLeft: 10 }} />
                                                    </div>
                                                } />
                                        )
                                    }
                                </Tabs>
                                {rewards?.length > 0 && <CustomDialogComponent />}
                            </>
                        }
                    </Tabs>
                    {rewards?.length > 0 && <CustomDialogComponent />}
                </>
            } */}
            {
                userInfo?.role === 'PLAYER' ?
                    <PlayerDashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToAllMatches={navigateToAllMatches} natigateToAllPlayers={natigateToAllPlayers} />
                    : userInfo?.role === 'MENTOR' ? <MentorDashboard rounds={rounds} filtersData={filtersData} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToAllMatches={navigateToAllMatches} />
                        : <NonPlayerDashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToAllMatches={navigateToAllMatches} />
            }
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLoggedInUserRole: options => dispatch(getLoggedInUserRole(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),
        getRewardsList: (options) => dispatch(getRewardsList(options)),
        getRewardsPositionsList: (options) => dispatch(getRewardsPositionsList(options)),
        openCustomDialog: (options) => dispatch(openCustomDialog(options))
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));