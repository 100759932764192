import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Moment from "react-moment";
import { Avatar, TextareaAutosize, Button, DialogActions, TextField, Tooltip } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createComment, updateComment, deleteComment, getTaskDetails, getComments } from "../../ducks/pms";
import { useSnackbar } from 'notistack';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { getAvatarText, postCreatedAtFormat } from "../../helpers";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
    avatarImageAlign: {
        display: 'flex',
        marginTop: '2%',
        alignItems:'end'
    },
    input: {
        resize: 'none',
        width: "calc(100% - 17px)",
        marginLeft: theme.spacing(1),
        fontSize: 14,
        "&:focus": {
            outline: "none"
        },
        border: 'solid 1px #b4b4b4',
        borderRadius: "10px",
        marginLeft: "10px",
        wordBreak: "break-word",
        color: "#535353",
        fontSize: "15px",
        padding: "10px",
        whiteSpace: 'pre-line'
    },
    button: {
        margin: '0 7px',
        width: 120,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    CommenterName: {
        color: '#58595b',
        fontWeight: 600,
        fontSize: 15,
        margin: '0px 0px 0px 10px',
        display: 'flex',
        alignItems: 'center'
    }, timeStyles: {
        fontWeight: 500,
        fontSize: 12,
        marginLeft: 10
    },
    commentStyle: {
        color: " #535353",
        fontSize: 15,
        margin: '-7px 0 0px 40px',
        wordWrap: 'break-word',
        maxWidth: 580,
        whiteSpace: 'pre-line'
    },
    // edit stylee
    commentsPlaceholder: {
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        width: "100%",
        minHeight: "40px"
    }, avatarStyles: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize:'1.15rem'
    }, actionIcons: {
        marginLeft: 5,
        height: 15,
        width: 15,
        cursor: "pointer"
    }
}))

const Comment = (props) => {
    const classes = useStyles();
    const [previousComment, setPreviousComment] = useState({})
    const [displayButtons, setDisplayButtons] = useState(false)
    const [hideEdit, setHideEdit] = useState(true)
    const { enqueueSnackbar } = useSnackbar()
    const [commentList, setCommentList] = useState([]);
    const [showEditComment, setShowEditComment] = useState({ editButton: false, index: '' });
    const [getComment, setComment] = useState({ comment: "" });
    useEffect(() => {
        props.getComments({ params: { limit: 500, page: 1 }, taskId: props.task_id, onSuccessCB: commentsListSuccessCB, onErrorCB: commentsListErrorCB })
    }, [props.task_id]);
    useEffect(()=>{
        let editCommentField = document.getElementById('editCommentInput');
        if(editCommentField){
            editCommentField.scrollIntoView();
        }
    },[showEditComment])
    const commentsListSuccessCB = (result, headers) => {
        console.log(result, headers)
        setCommentList([...result]);
    }

    const commentsListErrorCB = (error) => {
        console.log(error)
    }

    const handleEditComment = (commentObj, index) => {
        console.log("comment Object___id", commentObj);
        let commentDup = {};
        commentDup.comment = commentObj.comment;
        commentDup['task_id'] = props.task_id;
        if (commentDup.comment != "") {
            props.updateComment({
                requestObj: commentDup, id: commentObj._id, onSuccessCB: editCommentSucessCB,
                onErrorCB: editCommentErrorCB
            })
        }
    }
    const editCommentSucessCB = (result) => {
        enqueueSnackbar(`Comment Updated.`, {
            variant: 'success',
            preventDuplicate: true
        });
        props.getComments({ taskId: props.task_id, params: { limit: 50, page: 1 }, onSuccessCB: commentsListSuccessCB, onErrorCB: commentsListErrorCB })
        setShowEditComment({ editButton: !showEditComment.editButton, index: '' })
    }

    const editCommentErrorCB = (error) => {
        console.log("error------------", error);
    }
    const handleCreateComment = () => {
        let commentDup = {};
        commentDup.comment = getComment.comment; commentDup.task_id = props.task_id;
        console.log("commentDup-------------", commentDup);
        if (getComment.comment.trim()) {
            props.createComment({
                requestObj: commentDup, onSuccessCB: createCommentSucessCB,
                onErrorCB: createCommentErrorCB
            })
        }
    }
    const createCommentSucessCB = (result) => {
        if (result) setDisplayButtons(!displayButtons)
        props.getComments({ taskId: props.task_id, params: { limit: 50, page: 1 }, onSuccessCB: commentsListSuccessCB, onErrorCB: commentsListErrorCB })
        setComment({ ...getComment, comment: "" })
        setHideEdit(!hideEdit)
    }
    const createCommentErrorCB = (error) => {
        console.log("error------------", error);
        enqueueSnackbar(`Failed to comment.`, {
            variant: "error",
            preventDuplicate: true
        });
    }

    const handleDeleteComment = (commentObj, index) => {
        handleCloseEditComment(index)
        props.deleteComment({
            _id: commentObj._id, requestObj: { task_id: props.task_id }, onSuccessCB: deleteCommentSucessCB,
            onErrorCB: deleteCommentErrorCB
        })
        handDeleteComments(commentList.findIndex(ele => ele._id === commentObj._id))
    }
    const deleteCommentSucessCB = (result) => {
        enqueueSnackbar(`Comment Deleted.`, {
            variant: 'success',
            preventDuplicate: true
        });
        console.log("result1------------", result);
    }
    const deleteCommentErrorCB = (error) => {
        console.log("error------------", error);
    }

    const handleShowEditComment = (index) => {
        let commentObj = commentList[index]
        setPreviousComment({ ...commentObj })
        setDisplayButtons(false);
        setComment({ ...getComment, comment: "" })
        if (showEditComment.index == '') { setShowEditComment({ editButton: true, index: `${index}` }) }
        // else if (showEditComment.index == `${index}`) setShowEditComment({ editButton: true, index: '' });
        else setShowEditComment({ editButton: showEditComment.editButton, index: `${index}` });
    }
    const handleCloseEditComment = (index) => {
        let comments = [...commentList];
        let comment = comments[index]
        comment.comment = previousComment.comment ? previousComment.comment : '';
        comments[index] = comment;
        setCommentList(comments);
        setShowEditComment({ editButton: false, index: '' })
    }

    const handleComments = (e, index) => {
        let newComments = [...commentList];
        newComments[index].comment = e.target.value;
        setCommentList(newComments)
    }
    const handDeleteComments = (index) => {
        setCommentList((prevState) => [...prevState.filter((ele, i) => i !== index)])
    }

    const handleDisplayButtons = (action) => {
        if (action === 'focus') {
            if (showEditComment.editButton) setShowEditComment({ editButton: !showEditComment.editButton, index: '' })
            setDisplayButtons(true);
        }
        else if (action === 'cancel') {
            setDisplayButtons(false);
            setComment({ ...getComment, comment: "" })
        }
    }
    return (
        <div>
            <div className={classes.avatarImageAlign} style={{alignItems:'center'}}>
                <Avatar
                    // alt={user.name}
                    src={props.userPhoto_url}
                    classes={{ colorDefault: classes.avatarStyles }}
                    //   onClick={() => history.push(`/wall/profile/${user.username}`)}
                    style={{ height: 30, width: 30 }}
                >
                    {(props.name && getAvatarText(props.name))}

                </Avatar>
                <div className={classes.commentsPlaceholder}>
                    <TextareaAutosize
                        aria-label="Message Input textarea"
                        placeholder="Write a comment..."
                        className={classes.input}
                        rowsMax={2}
                        onFocus={() => {
                            handleDisplayButtons("focus")
                        }}
                        value={getComment.comment}
                        onChange={(e) =>
                            setComment({ comment: e.target.value })
                        }
                    />
                </div>

            </div>
            <div style={{ textAlign: "right", marginTop: "1px" }}>
                {
                    displayButtons ?
                        <div >
                            <span style={{ cursor: 'pointer', color: '#828282', }}><ClearIcon onClick={() => handleDisplayButtons('cancel')} /></span>
                            <span style={{ cursor: 'pointer', color: '#828282', marginLeft: 5 }}><CheckIcon onClick={handleCreateComment} /></span>
                        </div>
                        : null}
            </div>
            <div>
                {
                    commentList && commentList.length > 0 ?
                        commentList.map((comment, index) => (
                            <div key={index}>
                                <div className={classes.avatarImageAlign} >
                                    <Avatar
                                        // alt={user.name}
                                        src={comment.photo_url}
                                        classes={{ colorDefault: classes.avatarStyles }}
                                        style={{ height: 30, width: 30 }}
                                    >
                                        {(comment.name && getAvatarText(comment.name)) || (comment.commented_by && getAvatarText(comment.commented_by))}

                                    </Avatar>
                                    <p className={classes.CommenterName}>{comment.name} {showEditComment.index == `${index}`}
                                        {comment.commented_by === props.user ?
                                            <>
                                                <span><Tooltip title="Edit"><EditOutlinedIcon className={classes.actionIcons} onClick={() => { handleShowEditComment(index) }} /></Tooltip></span>
                                                <span><Tooltip title="Delete"><DeleteOutlineIcon className={classes.actionIcons} onClick={() => { handleDeleteComment(comment, index) }} /></Tooltip></span>
                                            </>
                                            : null
                                        }
                                        <Moment className={classes.timeStyles} fromNow ago format={postCreatedAtFormat(comment.commented_date)}>{comment.commented_date}</Moment>
                                    </p></div>
                                {showEditComment.editButton && (showEditComment.index == `${index}`) ? <div>
                                    <div style={{ transform: "translate(30px, -5px)", width: "calc(100% - 30px)" }} className={classes.commentsPlaceholder}>
                                        <TextareaAutosize
                                            id="editCommentInput"
                                            // autoFocus={comment.comment ? true:false}
                                            aria-label="Message Input textarea"
                                            placeholder="Write a comment..."
                                            className={classes.input}
                                            value={comment.comment}
                                            onChange={(e) => {
                                                handleComments(e, index)
                                            }}
                                        />

                                    </div>
                                    <div style={{ textAlign: "right", marginTop: 0 }}>
                                        <span style={{ cursor: 'pointer', color: '#828282' }}><ClearIcon onClick={() => handleCloseEditComment(index)} /></span>
                                        <span style={{ cursor: 'pointer', color: '#828282', marginLeft: 5 }}><CheckIcon onClick={(e) => handleEditComment(comment, index)} /></span>
                                    </div>
                                </div> : 
                                <div><p className={classes.commentStyle}>{comment.comment}
                                </p>
                                </div>

                                }
                            </div>)) : ""}
            </div>

        </div>
    )
}
const mapStateToProps = (state) => ({
    name: state.user.name,
    user: state.user.username,
    userPhoto_url: state.user.photoUrl
})

const mapDispatchToProps = (dispatch, props) => {
    return {
        createComment: (options) => dispatch(createComment(options)),
        updateComment: (options) => dispatch(updateComment(options)),
        deleteComment: (options) => dispatch(deleteComment(options)),
        getComments: (options) => dispatch(getComments(options)),
        getTaskDetails: (options) => dispatch(getTaskDetails(options)),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Comment));;


