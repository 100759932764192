import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Divider, makeStyles, List, ListItem } from "@material-ui/core";
import SayBirthday from "./SayBirthday";
import NormalNotification from "./NormalNotification";
import FeaturedNotification from "./FeaturedNotification";
import FavouriteNotifation from "./FavouriteNotifation";
import { getNotifications } from "../../ducks/arena";
import { ChaseonsSkeleton } from "../Arena/ReactionsDialog";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSnackbar } from "notistack";
import { setLoading, SET_LOADING } from "../../ducks/loading";
import { Link } from 'react-router-dom'
import { getInAppNotifications, readInAppNotificationsUnreadCount } from "../../ducks/notification";
const NotificationStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: "#ffffff",
  },
  text: {
    width: "87px",
    height: "20px",
    fontSize: "15px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.53,
    letterSpacing: "normal",
    color: "#58595b",
    padding: "1rem 2rem",
    paddingBottom: "2rem",
  },
  emptyResultText: {
    textAlign: 'center',
    color: '#535353cc',
    fontSize: 15,
    fontWeight: 600,
    margin: ' 50px',
    lineHeight: '25px'
  },
}));
let pagination = {
  limit: 10,
  page: 1,
}
const Notifications = ({ getInAppNotifications, readInAppNotificationsUnreadCount }) => {
  const classes = NotificationStyles();
  const [notifications, setNotifications] = useState([]);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setNotificationLoading(true)
    getInAppNotifications({ params: { limit: pagination.limit, page: 1 }, onGetInAppNotificationSuccess, onGetInAppNotificationError });
  }, []);
  useEffect(() => {
    let scrollElement = document.getElementById("arena-list-root");
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
  }, []);
  const fetchMoreData = e => {
    getInAppNotifications({
      params: { limit: pagination.limit, page: pagination.page + 1 },
      onGetInAppNotificationSuccess, onGetInAppNotificationError
    });
  };
  const onGetInAppNotificationSuccess = (data, headers) => {
    pagination.page = parseInt(headers.current_page);
    pagination.total_count = parseInt(headers.total_count);
    readInAppNotificationsUnreadCount(parseInt(headers.unread_count));
    if (parseInt(headers.current_page === 1)) {
      setNotifications(data);
      setNotificationLoading(false)
    } else {
      setNotifications([...notifications, ...data])
      setNotificationLoading(false)
    }
  };
  const onGetInAppNotificationError = (error) => {
    console.warn("error in app", error, typeof error)
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Fetch In App Notification.`, {
      variant: "error",
      preventDuplicate: true
    });
    setLoading(false);
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.text}>
          <p>Notifications</p>
        </div>
        {notificationLoading && [0, 1, 2, 3, 4].map(i => <ChaseonsSkeleton key={i} />)}
        {notifications.length > 0 ? <InfiniteScroll
          dataLength={notifications.length}
          next={fetchMoreData}
          hasMore={notifications.length !== pagination.total_count}
          loader={ChaseonsSkeleton}
          scrollableTarget="arena-list-root"
        >
          {notifications && notifications.map((item) => {
            return (
              <div>
                <Divider />
                {(item.data.featured === "true" || item.data.featured === true) ? (
                  <FeaturedNotification item={item} />
                ) : (
                    <>
                      {" "}
                      <Divider />
                      <FavouriteNotifation item={item} />
                    </>
                  )}
                <Divider />
              </div>
            );
          })
          }
        </InfiniteScroll> : (!notificationLoading && <p className={classes.emptyResultText}>No Notifications Found....</p>)}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  unreadCount: state.notification.inAppNotificationUnreadCount
})

const mapDispatchToProps = (dispatch) => {
  return {
    getInAppNotifications: (options) => dispatch(getInAppNotifications(options)),
    readInAppNotificationsUnreadCount: (options) => dispatch(readInAppNotificationsUnreadCount(options))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
