import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { getAvatarText, getDuration, getUTCFormattedDate } from '../../helpers';
import defaultBanner from '../../assets/images/default-banner.png';
import gift from '../../assets/icons/gift.svg';
import moment from 'moment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { RewardsDetails } from './RewardsDetails';
import { openCustomDialog } from '../../ducks/meetings';
import { useState } from 'react';
import { getRewardStandings } from '../../ducks/game';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    avatarStyles: {
        width: 65,
        height: 65,
        fontSize: 24
    },
    colorDefault: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer'
    },
    imageStyles: {
        '& img': {
            objectFit: 'fill',
            width: '50px',
            height: '50px',
            borderRadius: '50px'
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        height: '61px',
        "& .winners_": {
            paddingLeft: 15,
            "& h3": {
                fontSize: 16,
                fontWeight: 600
            },
            "& h6": {
                fontSize: 12,
                fontWeight: 400,
                color: '#252525'
            }
        }
    },
    tabStyles: {
        minHeight: 40,
        textTransform: 'capitalize',
        // padding: '13px',
        fontSize: 13,
        textAlign: 'left',
        opacity: 1,
        borderRadius: 5,
        transition: 'top 0.2s ease-in-out',
        fontFamily: 'Poppins !impotant',
        "& .maincard": {
            border: '1px solid #7b7b7b',
            background: '#ffffff',
            borderRadius: '7px',
            width: '360px',
            height: '122px',
            padding: 0,
            "& .saperat_div": {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .title_card': {
                    background: '#7B7B7B !important',
                    padding: '2px 12px',
                    color: '#f0f2f5',
                    fontSize: '14px',
                    maxWidth: 110,
                    textOverflow: 'ellipsis',
                    position: 'relative',
                    borderTopLeftRadius: '6px',
                    "&:after": {
                        content: "''",
                        position: 'absolute',
                        top: '0',
                        right: '-10px',
                        transform: 'skewX(-33deg)',
                        width: '20px',
                        background: '#7b7b7b !important',
                        height: '21.2px'
                    },
                },
                '& .title_card_dashboard': {
                    background: '#7B7B7B !important',
                    padding: '2px 12px',
                    color: '#f0f2f5',
                    fontSize: '14px',
                    maxWidth: 110,
                    textOverflow: 'ellipsis',
                    position: 'relative',
                    borderTopLeftRadius: '6px',
                    "&:after": {
                        content: "''",
                        position: 'absolute',
                        top: '0',
                        right: '-9px',
                        transform: 'skewX(-33deg)',
                        width: '20px',
                        background: '#7b7b7b !important',
                        height: '24.2px'
                    },
                },
                '& .title_card1': {
                    padding: '2px 12px',
                    color: '#C54326',
                    fontSize: '14px',
                    maxWidth: 150,
                    textOverflow: 'ellipsis',
                },
            },
            "& .card_footer": {
                alignItems: 'center',

            },
            "& p": {
                margin: 0,
                fontSize: 13,
                fontWeight: 400,
                padding: '4px 8px 4px 30px',
                color: '#C61010',
            },
            "& h6": {
                margin: 0,
                maxWidth: 220,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            "& h5": {
                margin: 0,
                maxWidth: 220,
                minWidth: 100,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'center',
                textTransform: 'initial'
            },
            "& h3": {
                margin: 0,
                maxWidth: 210,
                minWidth: 100,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }
        },

    },
    FooterContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '28px',
        padding: '1px 7px 0px 7px',
        borderRadius: '0px 0px 7px 7px',
        fontStyle: 'italic',
        fontSize: "12px",
        borderTop: '1px solid #F3F3F3',
        backgroundColor: '#f5f5f5',
        textTransform: 'none !important'

    },



}));

const RewardsBannerComponent = ({ name = "", obj, bannerUrl = defaultBanner, index, openCustomDialog, rewards, getRewardStandings, userDetails, from, rewardFrom, ...others }) => {
    const classes = useStyles();
    const Levels = [
        { title: 'Season Award', id: 'SEASON' },
        { title: 'Round Award', id: 'ROUND' },
        { title: 'Sprint Award', id: 'MATCH' },
        { title: 'Sprint Award', id: 'SPRINT' },
        { title: 'Onspot Award', id: 'ONSPOT' },
    ]

    const suffixForPosition = (i) => {
        const j = i % 10;
        const k = i % 100;
        if (j == 1 && k != 11) {
            return "st";
        }
        if (j == 2 && k != 12) {
            return "nd";
        }
        if (j == 3 && k != 13) {
            return "rd";
        }
        return "th";


    }


    return (
        <div className={classes.tabStyles} style={{ padding: rewardFrom === 'WINNING' ? '0px' : '13px' }}>
            <div className="maincard" style={rewardFrom === 'WINNING' ? { height: '150px', width: '100%' } : { height: from === 'dashboard' && '125px' }}>
                <div className="saperat_div">
                    <div className={from === 'dashboard' ? "title_card_dashboard" : "title_card"}>
                        <h5>{Levels.find((el) => el.id === obj?.level)['title']}</h5>
                    </div>
                    <div className="title_card1">
                        {(obj?.reward_status.search("Expired") !== -1) ? <h5 style={{ color: '#7B7B7B', textAlign: 'right' }}>{obj?.reward_status} </h5> : (obj?.reward_status.search("Upcoming") !== -1) ? <h5 style={{ color: '#D43437', textAlign: 'right' }}>{obj?.reward_status}</h5> : <h5 style={{ color: '#1b934c', textAlign: 'right' }}>{obj?.reward_status}</h5>}
                    </div>
                </div>
                <div className={classes.content} style={{ height: rewardFrom === 'WINNING' ? '88px' : '61px' }}>
                    <div className={classes.imageStyles} {...others}>
                        {obj?.banner ? <img src={obj?.banner} alt={name || "banner_img"} /> : <img src={gift} alt={"banner_img"} />
                        }
                    </div>
                    <div className="winners_">
                        <h3>{obj?.title}</h3>
                        <h6>{obj?.actual_reward}</h6>
                    </div>
                </div>
                {
                    obj?.approval_status == 'APPROVED' && obj.winners?.length > 0 ?

                        <div className={classes.FooterContent} style={{ color: (obj?.reward_status.search("Expired") !== -1) ? '#7B7B7B' : '#1b934c', backgroundColor: (obj?.reward_status.search("Expired") === -1) && '#d0e5d0', }}>
                            <div>

                                {(obj?.winners?.slice(0, 3)).map((winner, i) => (
                                    <span className={classes.positionFooter}>{i === 0 && `Won by`} {userDetails.username !== (winner?.name) ? winner?.name : obj?.category === 'TEAM' ? 'your team' : 'you'} <span> {(i !== obj?.winners.slice(0, 3).length - 1) ? `,` : obj?.winners?.length > 3 && ` +${obj?.winners?.length - 3} more`} </span>
                                    </span>
                                ))}
                            </div>


                            {/* <InfoOutlinedIcon fontSize="small" /> */}
                        </div>
                        :
                        obj?.approval_status == 'AWAITING' ?
                            <div className={classes.FooterContent} style={{ color: (obj?.reward_status.search("Expired") !== -1) ? '#7B7B7B' : '#1b934c', backgroundColor: (obj?.reward_status.search("Expired") === -1) && '#d0e5d0', }}>
                                <div>

                                    <span className={classes.positionFooter}>Reward not announced</span>
                                </div>

                                {/* <InfoOutlinedIcon fontSize="small" /> */}
                            </div>
                            :
                            <>
                                {obj?.rank_position || obj?.rank_position === 0 ?
                                    (obj?.category === 'TEAM') ?
                                        <div className={classes.FooterContent} style={{ color: (obj?.reward_status.search("Expired") !== -1) ? '#7B7B7B' : '#1b934c', backgroundColor: (obj?.reward_status.search("Expired") === -1) && '#d0e5d0' }}>
                                            <div>
                                                {
                                                    (obj?.reward_status.search("Expired") !== -1) ?
                                                        <span className={classes.positionFooter}>Position: <b> <b>{obj?.rank_position}<sup>{suffixForPosition(obj?.rank_position)}</sup> </b></b>  of {obj?.teamsCount}</span>
                                                        :
                                                        <span>Current position: <b> <b>{obj?.rank_position}<sup>{suffixForPosition(obj?.rank_position)}</sup> </b> </b>  of {obj?.teamsCount}</span>
                                                }
                                            </div>
                                            <InfoOutlinedIcon fontSize="small" />
                                        </div>
                                        :
                                        <div className={classes.FooterContent} style={{ color: (obj?.reward_status.search("Expired") !== -1) ? '#7B7B7B' : '#1b934c', backgroundColor: obj?.reward_status.search("Expired") === -1 && '#d0e5d0', }}>
                                            <div>
                                                {

                                                    (obj?.reward_status.search("Expired") !== -1) ?
                                                        <span>Position: <b>{obj?.rank_position}<sup>{suffixForPosition(obj?.rank_position)}</sup> </b> of {obj?.playersCount}</span>
                                                        :


                                                        <span>Current position: <b>{obj?.rank_position}<sup>{suffixForPosition(obj?.rank_position)}</sup> </b> of {obj?.playersCount}</span>

                                                }

                                            </div>
                                            <InfoOutlinedIcon fontSize="small" />
                                        </div>
                                    :
                                    (obj?.reward_status.search("Upcoming") !== -1) ?
                                        <div className={classes.FooterContent} style={{ color: (obj?.reward_status.search("Upcoming") !== -1) ? '#7B7B7B' : '#1b934c', backgroundColor: obj?.reward_status.search("Upcoming") === -1 && '#d0e5d0', }}>
                                            <div>
                                                <span>Current position: Yet to start</span>
                                            </div>
                                            <InfoOutlinedIcon fontSize="small" />
                                        </div>
                                        :
                                        <div className={classes.FooterContent} style={{ justifyContent: 'flex-end' }}>
                                        </div>


                                }
                            </>
                }
            </div>
        </div >

    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        openCustomDialog: (options) => dispatch(openCustomDialog(options)),
        getRewardStandings: (options) => dispatch(getRewardStandings(options)),
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RewardsBannerComponent));
