import { all, call, take, put, takeEvery, select } from "redux-saga/effects";
import Axios from "axios";
import { fetchTeamsMembers, fetchTeams, teamDetails, GET_GROUPS_LIST_API } from "../../config";
import { setProfileDetails, GET_TEAM_DETAILS, UPDATE_TEAM_DETAILS, setTeamMembers, GET_TEAM_MEMBERS, GET_GROUPS_LIST } from "../../ducks/common";
import { updateTeamChatDetails } from "../../ducks/chats";
import { updateTeam, GET_TEAMS } from "../../ducks/teams";
import { SET_DETAILS } from "../../ducks/user";
import { AUTHENTICATED } from "../../ducks/client";

const fetchTeamsAPI = () => {
    return Axios.get(fetchTeams, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        params: {
            limit: 100,
            page: 0
        }
    })
}

const fetchTeamDetails = (teamId) => {
    return Axios.get(teamDetails(teamId));
}

const fetchTeamMembers = (teamId) => {
    return Axios.get(`${fetchTeamsMembers}/${teamId}/members`, {
        params: {
            limit: 100,
            page: 0
        }
    });
}

const updateTeamDetails = (teamId, details) => {
    return Axios.put(`${fetchTeamsMembers}/${teamId}`,
        {
            ...details,
        }
    )
}

const fetchGroupsList = () => {
    return Axios.get(GET_GROUPS_LIST_API);
}

function* watchForGetTeamMemebrs() {
    yield takeEvery(GET_TEAM_MEMBERS, function* getTeamMembers(action) {
        const { teamId, onGetMembersSuccessCB, onGetMembersErrorCB } = action.payload;
        try {
            const { data } = yield call(fetchTeamMembers, teamId);

            if (data) {
                const teamMembersResult = data.reduce((obj, item) => {
                    // let jid = `${item.username}@${process.env.REACT_APP_XMPP_DOMAIN}`;
                    return {
                        ...obj,
                        [item.username]: (item.name === null || item.name === undefined || item.name.length === 0) ? item.username : item.name
                    }
                }, {});
                // yield put(receivedHistory(resultData, targetJid));
                yield call(onGetMembersSuccessCB, teamMembersResult);
                console.log('watchForGetTeamMemebrs Success');
            }
        } catch (e) {
            yield call(onGetMembersErrorCB, e);
            console.log('watchForGetTeamMemebrs catch err ', e);
        }
    });
}

function* watchForTeamDetails() {
    yield takeEvery(GET_TEAM_DETAILS, function* getTeamDetails(action) {
        const { teamId, onFetchSuccessCB, onFetchErrorCB } = action.payload;
        try {
            const [teamDetailsData, teamMembersData] = yield all([call(fetchTeamDetails, teamId), call(fetchTeamMembers, teamId)]);


            if (teamDetailsData || teamMembersData) {
                const presenceList = yield select((state) => {
                    return state.presence;
                });
                let teamMembersResult = teamMembersData.data.reduce((obj, item) => {
                    let jid = `${item.username}@${process.env.REACT_APP_XMPP_DOMAIN}`;
                    return {
                        ...obj,
                        [jid]: {
                            ...item,
                            isCurrent: false,
                            targetJid: jid,
                            chat_id: jid,
                            show: presenceList[jid] ? presenceList[jid].show : 'unavailable',
                            name: (item.name === null || item.name === undefined || item.name.length === 0) ? item.targetJid : item.name,
                            type: 'individual'
                        }
                    }
                }, {});

                let resultObj = {
                    ...teamDetailsData.data,
                    type: 'group',
                    members: teamMembersResult
                }

                yield put(setProfileDetails(resultObj));
                yield call(onFetchSuccessCB, resultObj);
                console.log('Fetch others profile Success');
            }
        } catch (e) {
            yield call(onFetchErrorCB, e);
            console.log('Fetch others profile catch err ', e);
        }
    });
}

function* watchForTeamDetailsUpdate() {
    yield takeEvery(UPDATE_TEAM_DETAILS, function* UpdateTeamDetails(action) {
        try {
            let { data } = yield call(updateTeamDetails, action.payload.username, action.payload.details);

            if (data) {
                yield put(setProfileDetails(action.payload.previousDetails));
                yield put(updateTeamChatDetails(action.payload.previousDetails));
                yield put(updateTeam(action.payload.previousDetails));
            }

            console.log('Updated Profile ');

        } catch (e) {
            console.log('updateProfileDetails catch err ', e);
        }
    });
}

function* watchForTeams() {
    // yield takeEvery(SET_DETAILS, function* UpdateProfile(action) {
    const { payload } = yield take(AUTHENTICATED);

    // const { name } = action.payload;
    try {
        let { data } = yield call(fetchTeamsAPI);
        if (data) {
            data.forEach((team) => {
                let jid = `${team.team_id}@chaseservice.${process.env.REACT_APP_XMPP_DOMAIN}`;
                window.client.joinRoom(jid, payload.username, {
                    status: 'online',
                    muc: {
                        history: {
                            maxCharacters: 0,
                        },
                    }
                });
            });
        }
        console.log('watchForTeams Success');
    }
    catch (e) {
        console.log('watchForTeams ', e);
    }
    // })
}
function* watchForUpdatedTeams() {
    yield takeEvery(GET_TEAMS, function* UpdateTeamDetails(action) {
        const { onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data } = yield call(fetchTeamsAPI);
            console.log(data)
            if (data) {
                let newData = data.map((team) => ({
                    ...team, targetJid: `${team.team_id}@chaseservice.${process.env.REACT_APP_XMPP_DOMAIN}`
                }));
                yield call(onSuccessCB, newData)
            }
        }
        catch (e) {
            yield call(onErrorCB, e)
            console.log('watchForUpdatedTeams ', e);
        }
    })
}

function* watchForGetGroupsList() {
    yield takeEvery(GET_GROUPS_LIST, function* getGroupsList(action) {
        const { onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchGroupsList);
            if (data) {
                yield call(onSuccessCB, data, headers);
                console.log("watchForGetGroupsList data success");
            }
        } catch (e) {
            yield call(onErrorCB, e);
            console.log("watchForGetGroupsList catch err ", e);
        }
    });
}

export default function* () {
    yield all([watchForTeamDetailsUpdate(), watchForTeams(), watchForTeamDetails(), watchForGetTeamMemebrs(), watchForUpdatedTeams(), watchForGetGroupsList()]);
}
