import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { capitalize, makeStyles, TablePagination, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { setLoading } from "../../../../ducks/loading";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import { getSeasonKpiTransactionDatasets, getSeasonKpiTransactions } from "../../../../ducks/game";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInput-underline': {
            border: '1px solid #c4c4c4',
            borderRadius: '5px!important',
            padding: '0px 5px',
            width: '200px'
        },
        '& .MuiInput-underline:before': {
            border: 'none !important'
        },
        '& .MuiInput-underline:after': {
            border: 'none !important'
        },
        '& .material-table thead th': {
            padding: '12px !important',
            backgroundColor: `${theme.palette.primary.light} !important`,
            borderColor: `${theme.palette.primary.light} !important`,
            color: '#000000',
            fontWeight: 600,
            lineHeight: '1.2rem',
            fontSize: '12px !important',
        },
        '& .material-table table td': {
            padding: '12px',
            fontSize: '12px !important',
            borderWidth: '0px 0px 1px 0px !important'
        },
    },
    tableRoot: {
        width: '100%',
    },
}));


let total_count = 0;
let total_pages = 0;

const Transactions = ({ match, kpiID, getSeasonKpiTransactions, userDetails, seasonDetails, getSeasonKpiTransactionDatasets }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [transactionsList, setTransactionsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [kpiDatasets, setKpiDatasets] = useState([])



    useEffect(() => {
        (kpiID && seasonDetails?._id) && getTransactionTable(kpiID)
    }, [kpiID, seasonDetails]);

    useEffect(() => {
        (transactionsList?._id && seasonDetails?._id && kpiID) && SeasonKpiTransactionDatasets(transactionsList?._id, kpiID, seasonDetails?._id);
    }, [transactionsList, seasonDetails?._id, kpiID, currentPage, pageSize])


    // -----------Transaction Data--------
    const getTransactionTable = (kpiId) => {
        setLoading(true);
        getSeasonKpiTransactions({
            kpiId: kpiId,
            params: { role_id: seasonDetails?.player_role_id, limit: 500, page: 0 },
            onSuccessCB: (result) => {
                if (result) {
                    setTransactionsList(result);
                    setLoading(false);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Kpi Transactions', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    // -----------Transaction Data sets--------
    const SeasonKpiTransactionDatasets = (DatasetId, kpiID, seasonId) => {
        setLoading(true);
        getSeasonKpiTransactionDatasets({
            params: { kpi_id: kpiID, season_id: seasonId, dataset_id: DatasetId, limit: pageSize, page: currentPage },
            onSuccessCB: (result, headers) => {
                if (result) {
                    // ----set total count and pages ---
                    total_count = headers.total_count;
                    total_pages = headers.total_pages;
                    setKpiDatasets(result);
                    setLoading(false);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Kpi Transaction Datasets', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }



    // -----page on change ----
    const handlePageChange = (value) => {
        setCurrentPage(value === total_pages ? value : value);
    };
    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };


    const column = [
        {
            title: "Component",
            field: "name",
            emptyValue: '--',
        },
        {
            title: "Component Name",
            field: "name",
            emptyValue: '--',
        },
        {
            title: "Status",
            field: "status",
            emptyValue: '--',
            render: (rowData) => (capitalize((rowData.status).toLowerCase()))
        },
        {
            title: "Role",
            field: "roles",
            emptyValue: '--',
            render: (rowData) => rowData.roles.map(role => role.role_name).join(', ')
        },
    ]

    return (
        <div className={classes.root}>
            <div>
                <Typography variant="subtitle1">{transactionsList?.display_name || 'Transactions'}</Typography>
            </div>
            <div className={classNames("material-table", classes.tableRoot)}>
                <MaterialTable
                    key={kpiDatasets.length}
                    title={transactionsList?.title}
                    columns={transactionsList?.column}
                    data={kpiDatasets}
                    options={{
                        actionsColumnIndex: -1,
                        showTitle: false,
                        addRowPosition: 'first',
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [10, 25, 50],
                        pageSize: pageSize,
                        searchFieldStyle: { zIndex: 1, marginLeft: -20, width: 200 },
                        searchFieldAlignment: "right",
                        search: true,
                    }}
                    components={{
                        Pagination: props => {
                            return (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    rowsPerPage={pageSize}
                                    count={total_count} //total count
                                    page={currentPage} //page number 0,1,2,3
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                />
                            )
                        }
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getSeasonKpiTransactions: (options) => dispatch(getSeasonKpiTransactions(options)),
        getSeasonKpiTransactionDatasets: (options) => dispatch(getSeasonKpiTransactionDatasets(options))
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Transactions));