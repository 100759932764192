import { all, select, takeEvery, put } from "redux-saga/effects";
// import find from 'lodash/find';
// import shortid from 'shortid';

import Axios from "axios";

// import { receivedPresenceAvailable, receivedPresenceUnavailable } from "../../ducks/presence";
// import { receivedMessage } from "../../ducks/messages";

import { makeChannel } from "../_helpers";
import { receivedContactPresence } from "../../ducks/contacts";
import { receivedChatPresence } from "../../ducks/chats";
import { receivedIndividualPresence } from "../../ducks/presence";
// import { CONNECTED, AUTHENTICATED } from "../../ducks/client";

function* watchForContactsPresence(client) {

    const availabilityChannel = makeChannel(client, {
        "available": (emit, msg) => {
            emit(msg);
        },
        "unavailable": (emit, msg) => {
            emit(msg);
        },
    })

    const channel = makeChannel(client, {
        // "available": (emit, msg) => {
        //     emit(msg);
        // },
        // "unavailable": (emit, msg) => {
        //     emit(msg);
        // },
        // "subscribed": (emit, msg) => {
        //     emit(msg);
        // },
        // "subscribe": (emit, msg) => {
        //     emit(msg);
        // },
        // "presence": (emit, msg) => {
        //     emit(msg);
        // },
    });

    yield takeEvery(availabilityChannel, function* availability(msg) {
        // console.log('availabilityChannel ', msg);
        console.log('availabilityChannel ', msg.from.split('/')[0], msg.show, msg.status, msg.type);
        const contactJid = msg.from.split('/')[0];
        const existedContact = yield select((state) => {
            if (state.contacts[contactJid]) {
                return true
            }
            return false;
        });

        yield put(receivedChatPresence(msg));
        yield put(receivedIndividualPresence(msg));
        if (existedContact && (msg.show || msg.type || msg.status)) {
            console.log('existedContact if ', existedContact)
            yield put(receivedContactPresence(msg));
        }
        else if (existedContact && (!msg.show && !msg.type && !msg.status)) {
            yield put(receivedContactPresence(msg));
        }
    })


    yield takeEvery(channel, function* eachMessage(presence) {
        console.log('presenceContactObj ', presence.show, presence);
        const contactJid = presence.from.split('/')[0];
        const existedContact = yield select((state) => {
            if (state.contacts[contactJid]) {
                return true
            }
            return false;
        });

        if ((presence.show || presence.status || presence.type === "unavailable") && existedContact) {
            yield put(receivedContactPresence(presence));
        }

        // Ignore own presence messages
        // if (roomNickname && (presence.from.split('/')[1] !== roomNickname)) {

        //     const roomMembers = yield select(function (state) {
        //         let members = [];
        //         if (state.presence[roomJid] && state.presence[roomJid].members) {
        //             members = state.presence[roomJid].members;
        //         }
        //         return members;
        //     });

        //     let memberInRoom = find(roomMembers, function (member) {
        //         return member.resource === presence.from.resource;
        //     });

        //     // Don't show join message if member already in room
        //     if (!memberInRoom || presence.show !== 'available') {
        //         // presence.id = shortid.generate();
        //         console.log('!memberInRoom', presence.show);
        //         //   yield put(receivedMessage(presence));
        //     }

        // }

        // if (presence.show === 'available' || presence.status) {
        //     console.log('presence.show available', presence.show);
        //     //   yield put(receivedPresenceAvailable(presence));
        // } else if (presence.show === 'unavailable') {
        //     console.log('presence.show un-available', presence.show);
        //     //   yield put(receivedPresenceUnavailable(presence));
        // }

        return;

    });
}

export default function* (client) {
    yield all([watchForContactsPresence(client)]);
}
