import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { getLiveMatches } from '../../../ducks/game';
import { decodeEnum, formatDateToMonth, getNumberFormat, getSearchValue, setSearchQuery } from '../../../helpers';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import { CustomCard } from '../../Leagues/CustomCard';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F0F2F5",
        width: "100%",
        maxHeight: "calc(100vh - 116px)",
        // maxHeight: 'calc(100vh - 100px)',
        overflowY: "auto",
        padding: "56px 24px 0px",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            // maxHeight: 'calc(100vh - 80px)',
            // maxWidth: `calc(100vw - 150px)`,
            padding: "56px 0px 0px",
        },
    },
    gridContainer: {
        display: "flex",
        width: "100%",
        margin: "5px 5px 5px 5px",
        maxWidth: 1250,
    },
    cardStyle: {
        height: "143px",
        //width: width * 0.29861111111,
        width: '430px',
        //left: "51px",
        //top: "228px",
        marginTop: "17px",
        //borderRadius: "10px",
        border: "1px",
        padding: "10px",
        boxShadow: "0px 0px 4px 1px #EDEDED",
        borderRadius: "3px",
    },

    cardHeadertitle: {
        color: "#A0A0A0",
        fontSize: "11px",
        lineHeight: "19.5px",
        fontWeight: "500",
    },
    avatarStyles: {
        width: 36,
        height: 36,
        fontSize: 16,
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        border: "0.785714px solid #C4C4C4",
    },
    orgCardStyle: {
        height: "430px",
        width: "430px",
        //left: "51px",
        //top: "228px",
        marginTop: "17px",
        //borderRadius: "10px",
        border: "1px",
        padding: "10px",
        boxShadow: "0px 0px 4px 1px #EDEDED",
        borderRadius: "3px",
    },
    playerCardStyle: {
        height: "392px",
        width: "318px",
        //left: "51px",
        //top: "228px",
        marginTop: "17px",
        //borderRadius: "10px",
        border: "1px",
        padding: "10px",
        boxShadow: "0px 0px 4px 1px #EDEDED",
        borderRadius: "3px",
    },
    performanceCardStyle: {
        height: "392px",
        width: "446px",
        //left: "51px",
        //top: "228px",
        marginTop: "17px",
        //borderRadius: "10px",
        border: "1px",
        padding: "10px",
        boxShadow: "0px 0px 4px 1px #EDEDED",
        borderRadius: "3px",
    },
    circle: {
        display: 'flex',
        width: '22px',
        height: '22px',
        backgroundColor: '#EF5DA8',
        borderRadius: ' 50%',
        marginLeft: -90
    },
    matchItem: {
        margin: '10px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#7D7D7D',
        '& .goals': {
            fontSize: 12
        }
    },
    selectedMatch: {
        margin: '10px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#000',
        '& .goals': {
            fontSize: 12,
            fontWeight: 500,
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 600,
        '& .name': {
            fontSize: 15,
            fontWeight: 500,
            textAlign: 'left'
        }
    },
    goalsDiff: {
        color: '#C20000',
        fontSize: 11,
        marginBottom: 0
    },
    cardRoot: {

        padding: '12px 16px',
        margin: '5px 5px 5px 5px !important'


    },
    gridDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .MuiCard-root': {
            padding: '12px 16px',
            margin: '5px 5px 5px 5px !important',
            height: '150px',
            maxHeight: '200px'



        }
    }
}));


const TeamMatchTab = ({ filtersData, seasonId, teamId, getLiveMatches, userDetails, isAllMatches = false, tab, matchTab, history, location, loading, setLoading, setFiltersData, defaultFilters }) => {
    const [resLive, setResLive] = useState([]);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();




    useEffect(() => {
        if (seasonId && teamId) {
            const params = { page: 1, limit: 5000, team_id: teamId, status: tab, type: ['TEAM_MATCH', 'PLAYER_SPRINT', 'TEAM_SPRINT'] };
            getLiveMatches({ seasonId, params, onSuccessCB, onErrorCB })
        }
    }, [seasonId, teamId, matchTab]);

    const onSuccessCB = (response) => {
        setResLive(response);
        const searchValue = getSearchValue(history, 'match');
        setFiltersData(prevState => ({ ...prevState, match: searchValue ? response?.find(obj => obj._id === searchValue) : response[0] }));
    }

    const onErrorCB = (error) => {
        console.log("on Error CB Dashboard", error)
    }

    const navigateToAllMatches = () => {
        history.push(`/leaguess/c2/matches${history.location?.search}`);
    }

    const handleCardClick = (match) => {
        setSearchQuery(history, { league: filtersData.league?._id, season: filtersData.season?._id, round: match?.round_id });
        navigateToAllMatches();
    }

    const differnce = (a, b) => {
        return (a - b);

    }


    const topTeam = (matchDetails) => {
        for (var i = 0; i < matchDetails.length; i++) {
            for (var j = 1; j < matchDetails.length; j++) {
                if (matchDetails[0].score > matchDetails[1].score) {
                    return ` ${matchDetails[0].name} is leading by ${getNumberFormat(userDetails?.countryCode, differnce(matchDetails[0].score, matchDetails[1].score))}`
                } else if (matchDetails[1].score > matchDetails[0].score) {
                    return ` ${matchDetails[1].name} is leading by ${getNumberFormat(userDetails?.countryCode, differnce(matchDetails[1].score, matchDetails[0].score))}`
                } else {
                    return 0;
                }
            }
        }
    }


    return (
        <div className={classes.gridDiv}>
            {resLive.length > 0 ? resLive.map((match, index) =>
                <Grid item md={4}>
                    <CustomCard selected={isAllMatches ? (match?._id === filtersData.match?._id) : false} linkText={isAllMatches ? "" : (index === 0 && "All Matches")} onLinkClick={navigateToAllMatches} onCardClick={() => handleCardClick(match)} linkStyles={{ position: 'absolute', right: 12, top: 0 }} >
                        <p className={classes.cardHeadertitle}>{match.title} || {`${formatDateToMonth(match.start_date)} - ${formatDateToMonth(match.end_date)}`}</p>
                        {
                            match.teams.map((team) => (
                                <>
                                    <div className={classes.matchItem}>
                                        <div className={classes.nameRoot}>
                                            <AvatarComponent name={team.name} username={team.name} url={team.logo_url} onClick={() => { }} style={{ marginRight: 10, width: 24, height: 24, fontSize: 10 }} />
                                            <span className="name">{team.name}</span>
                                        </div>

                                        <span className="goals">{team.score ? `${getNumberFormat(userDetails?.countryCode, team.score)}` : `${getNumberFormat(userDetails?.countryCode, team.points)}`} {`${decodeEnum(match.scoring_unit)}${team.score === 1 ? '' : 's'}`}</span>
                                    </div>
                                </>
                            ))}

                        {topTeam(match.teams) === 0 ? <span className={classes.goalsDiff}> Both teams are equal </span> : <span className={classes.goalsDiff}>  {topTeam(match.teams)} </span>}
                    </CustomCard>
                </Grid>
            ) :
                <CustomCard linkText={null} onLinkClick={() => { }} >
                    <p style={{ padding: 24, textAlign: 'center' }}>No Data Available</p>
                </CustomCard>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,

})

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveMatches: (options) => dispatch(getLiveMatches(options)),

    }
};
export default withRouter(connect(mapStateToProps,
    mapDispatchToProps
)(TeamMatchTab));
