import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
// import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import { ReactComponent as Share } from "../../../assets/icons/share-4.svg";
import DialogRespond from "./../DialogRespond";
import { ReactComponent as DownArrow } from "../../../assets/icons/downarrow.svg";
import { connect } from "react-redux";
import { getMeetingDetails, openShareMeetingDialog, openCustomDialog, deleteMeeting, setMeetingsList } from "../../../ducks/meetings";
import Moment from 'react-moment';
import classNames from 'classnames'
import { useSnackbar } from "notistack";
import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter } from "react-router-dom";
import { getAvatarText, getTime12Hrs } from "../../../helpers";
import { ConfirmationDialog } from "../../Arena/CreateArenaPost";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
  },
  description: {
    fontSize: "14px",
    lineHeight: "20px",
    padding: "0 25px",
  },
  desctitle: {
    color: "#000000",
    fontWeight: "bold",
    opacity: "0.9"
  },
  paragraph: {
    color: "#535353",
    wordBreak: 'break-word'
  },
  meeting: {
    display: "flex",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#535353",
    padding: "0px 25px",
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  shareMeetingRoot: {
    display: "flex",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#535353",
    padding: "0px 25px",
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f6f7'
    },
    '& p': {
      marginLeft: 10
    }
  },
  shareIcon: {
    padding: "0px 2px",
    paddingRight: "10px"
  },
  link: {
    color: "#112386",
    textDecoration: "none",
    marginTop: "12px",
    fontSize: "12px",
    lineHeight: "15px",
    flex: 1,
    paddingLeft: "0.6rem",
    paddingTop: "4px"
  },
  codeLink: {
    marginTop: "15px"
  },
  refresh: {
    paddingLeft: "0.6rem",
    "& path": {
      fill: "#ABABAC",
    },
  },

  avatarStyles: {
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    marginRight: "0.4rem",
    marginTop: "0.3rem",
    fontSize: "12px",
    width: "29px",
    height: "29px",
    // color: "#535353",
  },
  participants: {
    display: "flex",
    flexDirection: "column",
    color: "#000000",
    fontSize: "14px",
    lineHeight: "22px",
    padding: "3px 25px",
  },
  participantsLength: {
    color: "#000000",
    fontWeight: "bold",
    opacity: "0.8"
  },
  name: {
    marginBottom: "0rem",
    display: "block",
    lineHeight: "19px",
  },
  role: {},
  details: {
    marginTop: "0.5rem",
  },
  hoverButtonJoin: {
    width: "86px",
    height: "32px",
    border: "1px solid #FFCD2E",
    marginRight: "0.7rem",
  },
  hoverButtonRespond: {
    minWidth: "90px",
    height: "32px",
    border: "1px solid #FFCD2E",
    // paddingLeft: "25px",
    "& path": {
      fill: "#ABABAC",
    },
    "& svg": {
      paddingRight: "9px"
    }
  },
  buttons: {
    display: "flex",
    flexDirection: "row-reverse",
    // marginBottom:"2rem",
    padding: "1rem 1rem",
  },
  time: {
    display: "flex",
    height: "70px",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#535353",
    padding: "10px 25px",
  },
  both: {
    display: "flex",
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "0.4rem",
  },
  seeall: {
    color: "#03046C",
    fontSize: "14px",
    lineHeight: "22px",
    padding: "10px 25px",
    cursor: "pointer"
  },
}));

const SkeletonLoading = () => (
  <>
    <Skeleton variant="text" width="50%" height="6%" style={{ marginLeft: "45px" }} />
    <Skeleton variant="text" width="40%" height="6%" style={{ marginLeft: "45px" }} />
    <Skeleton variant="text" width="10%" height="6%" style={{ marginLeft: "45px" }} />
    <Divider style={{ marginBottom: "1rem" }} />
    <Skeleton variant="text" width="20%" height="6%" style={{ marginLeft: "45px" }} />
    {[80, 75, 60, 55, 50].map(item => {
      return <Skeleton variant="text" width={`${item}%`} height="6%" style={{ marginLeft: "45px", marginBottom: "0.6rem" }} />
    })}
    <Divider />
  </>
)
// let participantsLength = 3;
let meetingId = null;
let participantId = null;

const Details = ({ id, getMeetingDetails, keycloak: { tokenParsed: { preferred_username } }, openShareMeetingDialog, history, openCustomDialog, deleteMeeting, meetingsList, updatedMeetingList, setDetails, occurrenceDate, isJoined, user }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [meetingDetails, setMeetingDetails] = useState({
    attachments: [],
    auto_adimt: false,
    created_at: {},
    description: "",
    location: "",
    meeting_code: "",
    meeting_link: "",
    participants: [],
    repeat: "NONE",
    schedule: [{}],
    title: "",
    type: "SCHEDULED",
    updated_at: ""
  });
  const [loading, setLoading] = useState(false);
  const [openConfirmDialog, SetOpenConfirmDialog] = useState(false);
  const [participantsLength, setParticipantsLength] = useState(3)

  useEffect(() => {
    setLoading(true);
    getMeetingDetails({ onSuccessCB, onErrorCB, id });
  }, []);

  const handleChange = (e, item) => {
    meetingId = id;
    participantId = item.participants.find(
      (participant) => participant.username === preferred_username
    )._id
    console.log(meetingId, "meetingId", participantId, "participantId");
    setOpen(true);
  };
  const onSuccessCB = (data) => {
    setLoading(true)
    setMeetingDetails(data);
    setDetails(data);
    setLoading(false)
  };
  const onErrorCB = (error) => {
    setLoading(true)
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Meeting List.`, {
      variant: 'error',
      preventDuplicate: true
    });
    setLoading(false)
  };

  const handleCopy = (text) => {
    window.navigator.clipboard.writeText(text);
    enqueueSnackbar("Copied to clipboard", {
      variant: "success",
      preventDuplicate: true
    });
  }
  const [open, setOpen] = useState(false);

  const handleEdit = (details) => {
    openCustomDialog({ open: false, children: null, title: '' });
    history.push(`/meet/${details._id}/edit`);
  }

  const handleDelete = (details) => {
    SetOpenConfirmDialog(true);
  }

  const handleConfirmationDialog = (value) => {
    console.log('handleConfirmationDialog ', value);
    if (value) {
      // setLoading(true);
      deleteMeeting({ id, onDeleteSuccessCB, onDeleteErrorCB });
    }
    else {
      SetOpenConfirmDialog(false);
    }
  }

  const onDeleteSuccessCB = (data) => {
    SetOpenConfirmDialog(false);
    openCustomDialog({ open: false, children: null, title: '' });
    enqueueSnackbar(data.message ? data.message : `Meeting Deleted Successfully.`, {
      variant: 'success',
      preventDuplicate: true
    });
    // setLoading(false);
  };

  const onDeleteErrorCB = (error) => {
    SetOpenConfirmDialog(false);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Delete Meeting.`, {
      variant: 'error',
      preventDuplicate: true
    });
    // setLoading(false);
  };

  const handleJoin = (details) => {
    window.open(`${details.meeting_link}/${user.name}/meet`, 'Chase-Meet')
    // openCustomDialog({ open: false, children: null, title: '' });
    // history.push(`/meet/${details._id}/preview/${details.title}/${details.meeting_link && details.meeting_link.split("/").pop()}`);
  }

  const handleCloseRespondDialog = (respondedValue) => {
    if (respondedValue) {
      let meetingDetailsDup = { ...meetingDetails };
      const participantIndex = meetingDetailsDup.participants.findIndex(obj => obj.username === preferred_username);
      meetingDetailsDup.participants[participantIndex].joining = respondedValue;
      setMeetingDetails(meetingDetailsDup);

      if (respondedValue === 'no') {
        // let updatedList = meetingsList;
        // const meetingIndex = updatedList.findIndex(obj => obj._id === id);
        // const personIndex = updatedList[meetingIndex].participants.findIndex(obj => obj.username === preferred_username);
        // updatedList[meetingIndex].participants[personIndex].joining = respondedValue;

        // let meetingsListDup = JSON.parse(JSON.stringify(meetingsList));
        let updatedList = meetingsList.reduce((array, item) => {
          if (item._id === id)
            return array;
          else return [...array, item];
        }, [])
        updatedMeetingList(updatedList);
        openCustomDialog({ open: false, children: null, title: '' });
      }
    }
    setOpen(false);
  }

  return (<div>{!loading ?
    <>
      <div className={classes.root}>
        <div className={classes.time}>
          <span><Moment format="MMMM, D dddd">
            {occurrenceDate || meetingDetails.schedule && meetingDetails.schedule[0] && meetingDetails.schedule[0].start_at || meetingDetails.created_at}
          </Moment></span>
          {
            meetingDetails.schedule && meetingDetails.schedule[0].all_day ? 'All day' :
              <span>
                {getTime12Hrs(meetingDetails.schedule && meetingDetails.schedule[0].start_at)}{" "}
          to{" "}
                {getTime12Hrs(meetingDetails.schedule && meetingDetails.schedule[0].end_at)}
                {/* {new Date(meetingDetails.schedule[0].end_at).toString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })} */}
              </span>
          }
          <span>
            {meetingDetails.repeat && (meetingDetails.repeat[0] + meetingDetails.repeat.slice(1).toLowerCase())}
            <span className={classes.refresh}>
              <RefreshIcon />
            </span>{" "}
          </span>{" "}
        </div>
        <Divider />
        <div className={classes.description}>
          <p className={classes.desctitle}>Description</p>
          <p className={classes.paragraph}>
            {" "}
            {meetingDetails.description}
          </p>
        </div>
        <Divider />
        <div className={classes.meeting}>
          <p>Meeting URL : <span className={classes.link}>{meetingDetails.meeting_link && (meetingDetails.meeting_link.length > 30 ? meetingDetails.meeting_link.substring(0, 30) + '...' : meetingDetails.meeting_link)}</span></p>
          <div>
            <IconButton onClick={() => handleCopy(meetingDetails.meeting_link)}>
              <CopyIcon />
            </IconButton>
          </div>
        </div>
        <Divider />
        <div className={classes.meeting}>
          <p>Meeting code : <span className={classNames(classes.link, classes.codeLink)}>{meetingDetails.meeting_code}</span></p>
          <div>
            <IconButton onClick={() => handleCopy(meetingDetails.meeting_code)}>
              <CopyIcon />
            </IconButton>
          </div>
        </div>
        <Divider />
        <div className={classes.shareMeetingRoot} onClick={() => openShareMeetingDialog({ open: true, details: { meetingUrl: meetingDetails.meeting_link, meetingCode: meetingDetails.meeting_code, meetingTitle: meetingDetails.title, meetingId: meetingDetails._id } })}>
          <Share />
          <p>Share meeting invite</p>
        </div>
        <Divider />
        <div className={classes.participants}>
          <p className={classes.participantsLength}>Participants ({meetingDetails.participants ? meetingDetails.participants.length : 0})</p>
          {meetingDetails.participants.slice(0, participantsLength).map((person) => (
            <>
              <div className={classes.both}>
                <Avatar
                  classes={{
                    colorDefault: classes.avatarStyles,
                    root: classes.avatarStyles,
                  }}
                  alt={person.name}
                  src={person.photoUrl}
                >
                  {(person.name || person.email) &&
                    getAvatarText(person.name || person.email)}
                </Avatar>
                <div className={classes.details}>
                  <span className={classes.name}>{person.name || person.email}</span>
                  <span
                    className={classes.role}
                    style={{
                      color:
                        person.organizer
                          ? "#535353"
                          : person.joining === "yes"
                            ? "#008800"
                            : person.joining === "no"
                              ? "#FF3E1A"
                              : "#B1B1B1",
                    }}
                  >
                    {person.organizer ? "(organiser)" : person.joining === 'yes' ? 'Accepted' : person.joining === 'no' ? 'Declined' : 'Unknown'}
                  </span>
                </div>
              </div>
              <Divider />
            </>
          ))}
        </div>
        <div
          className={classes.seeall}
          style={{ display: participantsLength >= meetingDetails.participants.length ? "none" : "block" }}
          onClick={() => {
            setParticipantsLength(meetingDetails.participants.length);
            // setMeetingDetails([...meetingDetails]);
          }}
        >
          see all
      </div>
        {
          !isJoined &&
          <div className={classes.buttons}>
            {
              (meetingDetails.participants && meetingDetails.participants.some(user => user.organizer) && meetingDetails.participants.find(obj => obj.organizer).username === preferred_username) ?
                <>
                  <Button
                    className={classes.hoverButtonRespond}
                    onClick={() => handleDelete(meetingDetails)}
                    variant="outlined"
                  >
                    Delete
                </Button>
                  <Button
                    className={classes.hoverButtonRespond}
                    onClick={() => handleEdit(meetingDetails)}
                    variant="outlined"
                  >
                    Edit
                </Button>
                </>
                :
                <Button
                  endIcon={<DownArrow />}
                  className={classes.hoverButtonRespond}
                  onClick={(e) => handleChange(e, meetingDetails)}
                  variant="outlined"
                >
                  Respond
          </Button>
            }
            <Button className={classes.hoverButtonJoin} variant="contained" color="primary" onClick={() => handleJoin(meetingDetails)}>
              Join
            </Button>
          </div>
        }
      </div>


      <DialogRespond open={open} handleClose={handleCloseRespondDialog} meetingId={id} participantId={participantId} />
      <ConfirmationDialog open={openConfirmDialog} content={`Are you sure you want to Delete this meeting?`} handleConfirmationDialog={handleConfirmationDialog} />
    </>
    : <SkeletonLoading />}</div>
  )
};
const mapStateToProps = (state) => ({
  user: state.user,
  keycloak: state.keycloak,
  meetingsList: state.meetings.meetingsList
});
const mapDispatchToProps = (dispatch) => ({
  getMeetingDetails: (option) => dispatch(getMeetingDetails(option)),
  openShareMeetingDialog: (option) => dispatch(openShareMeetingDialog(option)),
  openCustomDialog: (options) => dispatch(openCustomDialog(options)),
  deleteMeeting: (options) => dispatch(deleteMeeting(options)),
  updatedMeetingList: (options) => dispatch(setMeetingsList(options)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));


// import React from "react";
// import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
// import { makeStyles, Divider, Avatar } from "@material-ui/core";
// const useStyles = makeStyles((theme) => ({
//   time: {
//     display: "flex",
//     height: "70px",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     fontSize: "14px",
//     lineHeight: "22px",
//     color: "#535353",
//     padding: "5px 25px 20px",
//   },
//   refresh: {
//     paddingLeft: "0.6rem",
//     "& path": {
//       fill: "#ABABAC",
//     },
//   },
//   title: {
//     fontSize: "18px",
//     lineHeight: "22px",
//     fontWeight: 600,
//     color: "#000000",
//     padding: "1rem 25px 0",
//   },
//   both: {
//     fontSize: "14px",
//     padding: "1rem 25px",
//   },
//   description: {
//     color: "#000000",
//     lineHeight: "22px",
//     boxSizing: "border-box",
//     paddingBottom: "5px",
//     fontWeight: "bolder",
//   },
//   paragraph: {
//     color: "#535353",
//     lineHeight: "19px",
//     display: "block",
//   },
//   participants: {
//     color: "#000000",
//     fontSize: "14px",
//     lineHeight: "22px",
//     padding: "1rem 25px",
//   },
//   participateNum: {
//     fontWeight: "bolder",
//   },
//   avatarStyles: {
//     backgroundColor: theme.palette.primary.main,
//     cursor: "pointer",
//     marginRight: "0.4rem",
//     marginTop: "0.3rem",
//     width: "29px",
//     height: "29px",
//     // padding:"3px 3px",
//     color:"#535353",
//     fontSize:"12px",
//   },
//   people: {
//     display: "flex",
//     alignItems: "center",
//     paddingBottom: "0.6rem",
//   },
// }));
// const Details = () => {
//   const classes = useStyles();
//   const participants = [
//     { url: "", name: "Ashish Gupta",role:"organiser" },
//     { url: "", name: "Ema John",role:"" },
//     { url: "", name: "mKhan@gmail.com",role:"" },
//   ];
//   const nonParicipants = [
//     { url: "", name: "Andreson beth" },
//     { url: "", name: "Alber Afred" },
//   ];
//   return (
//     <div>
//       <div className={classes.title}>Sales Meet</div>
//       <div className={classes.time}>
//         <span>October 3 tuesday</span>
//         <span>11:00pm to 12:00pm</span>
//         <span>
//           Daily
//           <span className={classes.refresh}>
//             <RefreshIcon />
//           </span>{" "}
//         </span>{" "}
//       </div>
//       <Divider />
//       <div className={classes.both}>
//         <div className={classes.description}>Description</div>
//         <span className={classes.paragraph}>
//           Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium
//           aspernatur tenetur nam iure? Sed voluptate officiis similique,
//           suscipit porro blanditiis veritatis hic labore, assumenda ea harum,
//           earum adipisci sint repellendus.
//         </span>
//       </div>
//       <Divider />
//       <div className={classes.participants}>
//         <p className={classes.participateNum}>
//           Paricipants({participants.length})
//         </p>
//         {participants.map((people) => (
//           <div className={classes.people}>
//             <Avatar
//               classes={{ colorDefault: classes.avatarStyles }}
//               alt={people.name}
//               src={people.url}
//             >
//               {people.name &&
//                 people.name
//                   .match(/\b(\w)/g)
//                   .join("")
//                   .substring(0, 2)}
//             </Avatar>
//             <span>{people.name} {people.role==="organiser"?"(organiser)":""}</span>
//           </div>
//         ))}
//         <p className={classes.participateNum}>
//           Non-paricipants({nonParicipants.length})
//         </p>
//         {nonParicipants.map((people) => (
//           <div className={classes.people}>
//             <Avatar
//               classes={{ colorDefault: classes.avatarStyles,root:classes.avatarStyles }}
//               alt={people.name}
//               src={people.url}
//             >
//               {people.name &&
//                 people.name
//                   .match(/\b(\w)/g)
//                   .join("")
//                   .substring(0, 2)}
//             </Avatar>
//             <span>{people.name}</span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Details;
