import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LiveMatches from "../../Leagues/LiveMatches";
import { setLoading } from "../../../ducks/loading";
import { CustomCard } from "../GlobalComponents/CustomCard";
import DashboardActions from "./DashboardActions";
import MyPerformance from "./MyPerformance";
import ArenaList from "./ArenaList";

const PlayerDashboard = ({ setLoading, filtersData, rounds, userInfo, natigateToAllStats, natigateToLiveScores, navigateToTeamLeaderboard, navigateToPlayerLeaderboard, navigateToAllMatches, history }) => {

    const [selectedPlayerOption, setSelectedPlayerOption] = useState(null);
    const [selectedTeamOption, setSelectedTeamOption] = useState(null);
    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        if (rounds.length > 0 || filtersData?.season?._id) {
            const liveRound = rounds.find(obj => obj.round_status === 'LIVE');
            if (liveRound) {
                setMenuOptions([
                    { label: (liveRound?.name === 'QUARTER_FINALS') ? 'QF' : (liveRound?.name === 'SEMI_FINALS') ? 'SF' : liveRound?.name, value: liveRound?._id, pathname: 'round' },
                    { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' }
                ]);
                setSelectedPlayerOption({ label: liveRound?.name, value: liveRound?._id, pathname: 'round' });
                setSelectedTeamOption({ label: liveRound?.name, value: liveRound?._id, pathname: 'round' });
            }
            else {
                setMenuOptions([
                    { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season', disabled: true }
                ]);
                setSelectedPlayerOption({ label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' });
                setSelectedTeamOption({ label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' });
            }
        }
    }, [rounds, filtersData?.season?._id]);

    const handlePlayerOptionChange = (newValue) => {
        setSelectedPlayerOption(newValue);
    }

    const handleTeamOptionChange = (newValue) => {
        setSelectedTeamOption(newValue);
    }

    const isPlayerSeasonLevel = selectedPlayerOption?.pathname === 'season';
    const isTeamSeasonLevel = selectedTeamOption?.pathname === 'season';

    return (
        <div>
            <CustomCard from='PWC' cardTitle="My Performance"
            >
                <Grid container spacing={3}>
                    <Grid item md={8} >
                        <CustomCard from='PWC' cardTitle=""
                        >
                            <MyPerformance isSeasonLevel={isPlayerSeasonLevel} roundId={!isPlayerSeasonLevel && selectedPlayerOption?.value} filtersData={filtersData} />
                        </CustomCard>
                    </Grid>
                    <Grid item md={4} >
                        <CustomCard from='PWC' cardTitle=""
                        >
                            <ArenaList />
                        </CustomCard>
                    </Grid>

                </Grid>
            </CustomCard>

            <Grid container spacing={3}>
                <Grid item md={9}>
                    <CustomCard from='PWC' cardTitle="Actions Ending Today"
                    >
                        <DashboardActions />
                    </CustomCard>
                </Grid>



            </Grid >

        </div>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),



    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerDashboard));
