// import orderBy from "lodash/orderBy";
// import differenceBy from "lodash/differenceBy";

import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/contacts");

export const SET_CURRENT_TEAM = constant("SET_CURRENT_TEAM");

export const ADD_TEAMS = constant("ADD_TEAMS");
export const UPDATE_TEAM = constant("UPDATE_TEAM");
export const GET_TEAMS = constant("GET_TEAMS")

export const setCurrentTeam = (team) => ({
    type: SET_CURRENT_TEAM,
    payload: team
})

export const updateTeam = (details) => ({
    type: UPDATE_TEAM,
    payload: details
})

export const receivedTeams = teamsList => ({
    type: ADD_TEAMS,
    payload: teamsList
});
export const getTeams = (payload)=>({
    type:GET_TEAMS,
    payload
})

const initialState = {
//     'testroom1@chaseservice.45.33.44.230': {
//         name: "Test Room 1",
//         photo_url: "https://picture-s3-bucket.s3.ap-south-1.amazonaws.com/1589192299913-download1.png",
//         designation: "Manager",
//         chat_id: "testroom1@chaseservice.45.33.44.230",
//         show: 'unavailable',
//         type: 'group',
//         isCurrent: false
//     }
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {

        case ADD_TEAMS : {
            return {...state, ...action.payload};
        }

        case SET_CURRENT_TEAM: {
            const user = action.payload;
            let newState = JSON.parse(JSON.stringify(state));

            if (newState[user.targetJid] !== undefined) {
                newState[user.targetJid] = {
                    ...user,
                    isCurrent: true,
                    // sentdate: Date.parse(new Date),
                    isNewChat: false,
                }
                return newState;
            }
            return state;
        }

        case UPDATE_TEAM: {
            const peerJid = action.payload.targetJid;
            return {
                ...state,
                [peerJid]: {
                    ...state[peerJid],
                    targetJid: peerJid,
                    name: action.payload.name,
                    contactName: action.payload.name,
                    image_url: action.payload.logo_url
                }
            }
        }

        default:
            return state;
    }
};
