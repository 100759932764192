import { all, takeEvery, call, put } from "redux-saga/effects";
import Axios from "axios";
import { GET_TEMPLATE_THEME_API } from "../../config";
import { GET_TEMPLATE_THEME, setTemplateTheme } from "../../ducks/common";



const fetchTemplateTheme = () => {
    return Axios.get(GET_TEMPLATE_THEME_API);
}

function* watchForGetTemplateTheme() {
    yield takeEvery(GET_TEMPLATE_THEME, function* getTemplateTheme(action) {
        try {
            let { data } = yield call(fetchTemplateTheme);
            if (data) {
                console.log('watchForGetTemplateTheme data success', data);
                yield put(setTemplateTheme(data));
            }
        } catch (e) {
            yield put(setTemplateTheme({}));
            console.log('watchForGetTemplateTheme catch err ', e);
        }
    });
}

export default function* (client) {
    yield all([watchForGetTemplateTheme(client)]);
}
