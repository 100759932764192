import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, useMediaQuery, useTheme, IconButton, DialogTitle, DialogContent, Avatar, TextareaAutosize, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { openShareToArenaDialog, sharePostToArena, updateArena } from '../../ducks/arena';
import { ArenaBasicDetailsTemplate } from './ArenaList';
import { setLoading } from '../../ducks/loading';
import { getAvatarText } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        minHeight: 470,
        borderRadius: 15,
        maxWidth: 825
    },
    dialogTitle: {
        padding: '21px 40px',
        fontSize: 22,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogContent: {
        padding: '13px 40px'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 10
    },
    avatarRoot: {
        display: 'flex',
        alignItems: 'center',
        // padding: '10px 15px'
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    },
    profileName: {
        marginLeft: 10,
        color: '#58595B',
        fontSize: 16,
        fontWeight: 600
    },
    content: {
        marginTop: 15
    },
    textareaRoot: {
        marginBottom: 40
    },
    textareaStyles: {
        border: 'none',
        fontSize: 16,
        color: '#838383',
        outline: 'none',
        width: '100%',
        resize: 'none',
        lineHeight: '20px',
        padding: '8px 0',
        '&::placeholder': {
            fontSize: 32
        }
    },
    buttonRoot: {
        margin: '30px 0 20px',
        textAlign: 'center',
        '& button:nth-child(2)': {
            marginRight: 0
        }
    },
    button: {
        margin: '0 7px',
        width: 120,
        height: 43,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
}))

const ShareToArenaDialog = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { shareToArena: { open, details, isEdit }, openShareToArenaDialog, sharePostToArena, setLoading, handleOnNewPost } = props;
    const [text, setText] = useState('');

    useEffect(() => {
        if (isEdit)
            setText(details.text)
    }, [details.text]);

    const handleClose = () => {
        setText('');
        openShareToArenaDialog({ open: false, details: {}, isEdit: false });
    }

    const handleChangeInput = (event) => {
        let value = event.target.value;
        setText(value);
    }

    const handleSharePost = () => {
        setLoading(true);
        sharePostToArena({
            requestObj: {
                text: text,
                postId: details.parentPostId ? details.parentPostId : details.postId
            },
            onSuccessCB, onErrorCB
        })
    }

    const onSuccessCB = (result) => {
        handleClose();
        handleOnNewPost(new Date().getTime())
        enqueueSnackbar(`Post shared successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        setLoading(false);
    }

    const onErrorCB = (error) => {
        handleClose();
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Share Post.`, {
            variant: 'error',
            preventDuplicate: true
        });
        setLoading(false);
    }

    const handleUpdatePost = () => {
        setLoading(true);
        let requestObj = {
            category: details.category,
            type: details.type,
            text: text,
            // deleteAttachmentIds: [],
            // tags: selectedChaseOns.map(user => user.username)
        };
        // if (details.newAttachments.length) requestObj.newAttachments = details.newAttachments;
        props.updateArena({ postId: details.postId, requestObj, onUpdateSuccessCB, onUpdateErrorCB });
    }

    const onUpdateSuccessCB = (result) => {
        enqueueSnackbar(`Post Updated successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        handleClose();
        handleOnNewPost(new Date().getTime());
        setLoading(false);
    }
    const onUpdateErrorCB = (error) => {
        handleClose();
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Update Post.`, {
            variant: 'error',
            preventDuplicate: true
        });
        setLoading(false);
    }

    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="share-arena-dialog"
                style={{ zIndex: 1301 }}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    {!isEdit ? 'Share to Wall' : 'Edit Post'}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.avatarRoot}>
                        <Avatar
                            alt={'User'}
                            src={props.user.photo_url || props.user.photoUrl}
                            classes={{ colorDefault: classes.avatarStyles }}
                        >
                            {props.user.name && getAvatarText(props.user.name)}
                        </Avatar>
                        <span className={classes.profileName}>{props.user.name}</span>
                    </div>
                    <div className={classes.content}>
                        {/* <div className={classes.textareaRoot}>
                            <TextareaAutosize
                                color="primary"
                                id="arena-text-field"
                                placeholder="Share your thoughts..."
                                value={text}
                                // autoFocus
                                className={classes.textareaStyles}
                                onChange={handleChangeInput}
                            />
                        </div> */}
                        <div style={{ border: '1px solid #dbdbdb' }}>
                            {
                                open &&
                                <ArenaBasicDetailsTemplate disableActions={true} arena={!isEdit ? details : details.parentPost} index={details.postId} history={props.history} handlePreview={() => { }} />
                            }
                        </div>
                        <div className={classes.buttonRoot} >
                            <Button variant="text" size="medium" className={classes.button} onClick={() => handleClose()}>Cancel</Button>
                            {
                                isEdit ?
                                    <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleUpdatePost()}>Update</Button>
                                    :
                                    <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleSharePost()}>Share</Button>
                            }
                        </div>
                    </div>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const mapStateToProps = (state) => ({
    shareToArena: state.arena.shareToArena,
    user: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        openShareToArenaDialog: (options) => dispatch(openShareToArenaDialog(options)),
        sharePostToArena: (options) => dispatch(sharePostToArena(options)),
        updateArena: (options) => dispatch(updateArena(options)),
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ShareToArenaDialog);