import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import RewardsBannerComponent from './RewardsBanner';
import { decodeEnum, formatDate } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    mainRoot: {
        // padding: '0 24px'
    },
    expiredLabel: {
        color: 'red',
        fontSize: 10,
        fontWeight: 600,
        marginLeft: 12,
        whiteSpace: 'nowrap'
    },
    imageStyles: {
        height: 80,
        width: 80,
        '& img': {
            objectFit: 'contain'
        }
    }
}));

export const RewardsDetailsDub = ({ rewards = [], rewardIndex }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(rewardIndex || 0);

    useEffect(() => {
        document.getElementById(`reward-${rewardIndex}`).scrollIntoView();
    }, [rewardIndex]);

    const handleChange = (index) => {
        setExpanded(index);
    }

    return (
        <div className={classes.mainRoot}>
            {rewards.map((obj, index) =>
                <Accordion id={`reward-${index}`} key={index} expanded={expanded === index} onChange={() => handleChange(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography style={{ fontSize: 14 }}>
                            {obj.title}
                            {obj.expired && <span className={classes.expiredLabel}>Expired</span>}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block', fontSize: 14 }}>
                        <div style={{ textAlign: 'center', fontWeight: 600 }}>
                            <div className={classes.imageStyles}>
                                <img src={obj?.banner} alt={obj?.name || "banner_img"} height="100%" width="100%" />
                            </div>
                            {/* <RewardsBannerComponent bannerUrl={obj.banner} name={obj.name} style={{ margin: 'auto', width: 'auto', height: 200 }} /> */}
                        </div>
                        <div style={{ marginTop: 24 }}>
                            <p style={{ fontWeight: 600, wordBreak: 'break-all' }}>Reward Value: <span style={{ fontWeight: 500, marginLeft: 5 }}>{obj.actual_reward}</span></p>
                            <p style={{ fontWeight: 600 }}>Reward Criteria: <span style={{ fontWeight: 500, marginLeft: 5 }}>{obj.awardee === 'NA' ? "NA" : decodeEnum(obj.awardee)}</span></p>
                            <p style={{ fontWeight: 600 }}>Reward for: <span style={{ fontWeight: 500, marginLeft: 5 }}>{decodeEnum(obj.category)}</span></p>
                            <p style={{ fontWeight: 600 }}>Reward Duration: <span style={{ fontWeight: 500, marginLeft: 5, fontSize: 12 }}>
                                {formatDate(obj.start_date)} - {formatDate(obj.end_date)}
                            </span></p>
                            {obj.description && <p style={{ fontWeight: 600, wordBreak: 'break-all' }}>Reward Description: <span style={{ fontWeight: 500, marginLeft: 5 }}>{obj.description}</span></p>}
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}

        </div>
    )
}