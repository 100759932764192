import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, TableCell, TableHead, TableRow, Tooltip, makeStyles, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import { getDivisionWiseKpi, getTeamDivisionWiseKpi } from "../../../ducks/game";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useSnackbar } from "notistack";
import qs from 'query-string';
import { getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort } from "../../../helpers";
import GetCountryCurrencyFormat from "../../GlobalComponents/GetCountryCurrencyFormat";
import LiveScoresMuiTable from "../../LiveScores/LiveScoresMuiTable";
import AvatarComponent from "../../AvatarComponent/AvatarComponent";
import { setLoading } from "../../../ducks/loading";
import { connect } from "react-redux";
import { isRuleBasedOnActuals } from "../../LiveScores/RaceSummary";
import IllustrationView from "../../LiveScores/IllustrationView";
import MaterialTable from "material-table";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
    },
    dialogContent: {
        minWidth: 1000,
        minHeight: 200
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    kpiHeading: {
        display: 'flex',
        marginBottom: 10,
        '& p': {
            margin: 0,
        }
    },
    colorHdng: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 6px 4px 6px',
        borderRadius: 4,
        marginLeft: 8,
        background: '#00000014',
        marginTop: '-2px',
        '& p': {
            margin: 0,
            fontSize: 13,
        },
        '& span': {
            width: 14,
            height: 14,
            borderRadius: 2,
            marginRight: 6
        }
    },
    nameRoot: {
        display: "flex",
        alignItems: "center",
    },
}));



const HealthPerformanceDialog = ({ history, from, selectedRow, loading, roleObjHealth, setLoading, userDetails, seasonFormat, seasonKpiPostions, getDivisionWiseKpi, getTeamDivisionWiseKpi, open = false, handleClose, filtersData }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [decimalView, setDecimalView] = useState(false);
    const qryparams = qs.parse(history.location.search);

    useEffect(() => {
        if (from === 'Team') {
            if (selectedRow) {
                let kpi_id = selectedRow?.kpi_id;
                let status_code = selectedRow?.status_code;
                let seasonId = qryparams.season;
                setLoading(true)
                getTeamDivisionWiseKpi({ seasonId, onSuccessCB, onErrorCB, params: { type: selectedRow?.kpi_type, kpi: kpi_id, status_code: status_code, limit: 5000, page: 1 } })
            }
        } else {
            if (selectedRow) {
                let role = roleObjHealth?._id;
                let kpi_id = selectedRow?.kpi_id;
                let status_code = selectedRow?.status_code;
                let seasonId = qryparams.season;
                setLoading(true)
                getDivisionWiseKpi({ seasonId, onSuccessCB, onErrorCB, params: { type: selectedRow?.kpi_type, role_id: role, kpi: kpi_id, status_code: status_code, limit: 5000, page: 1 } });
            }
        }
    }, [selectedRow]);

    const onSuccessCB = (response) => {
        if (response.length) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points, kpis_attributes: seasonKpiPositionsSort(item?.kpis, seasonKpiPostions, 'kpi_id', 'detailed_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-pending_actual`] = el.pending_actual;
                    obj[`${el.kpi_id}-color`] = el.status_code;
                    obj[`${el.kpi_id}-planned_color`] = el.status_code;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_pending_actual`] = el.planned_pending_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE') ? el.score || 0 : el.points || 0);
                    if (index === 0) {
                        const mapped_headers = el.detailed_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'TARGET')?.display_name,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            RAG: false,
                            sorting: true,
                            cellStyle: { minWidth: 70 },
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('TARGET'),
                            render: (rowData) =>
                                (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        }, {
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'ACTUAL')?.display_name,
                            field: `${el.kpi_id}-actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`, //-Actual
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: el?.indicator_based_on === 'ACTUALS',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            sorting: true,
                            cellStyle: { minWidth: 70, color: el?.indicator_based_on === 'ACTUALS' && el?.status_code },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'PENDING_ACTUAL')?.display_name,
                            field: `${el.kpi_id}-pending_actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            sorting: true,
                            cellStyle: { minWidth: 70 },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT')?.display_name,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            sorting: true,
                            RAG: el?.indicator_based_on === 'ACHIEVEMENT',
                            cellStyle: { minWidth: 75, color: el?.indicator_based_on === 'ACHIEVEMENT' && el?.status_code },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET')?.display_name,
                            field: `${el.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: false,
                            kpi: el.kpi_id,
                            sorting: true,
                            color_key: 'planned_color',
                            cellStyle: { minWidth: 70 },
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL')?.display_name,
                            field: `${el.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: el?.indicator_based_on === '',
                            kpi: el.kpi_id,
                            sorting: true,
                            color_key: 'planned_color',
                            cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_PENDING_ACTUAL')?.display_name,
                            field: `${el.kpi_id}-planned_pending_actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            sorting: true,
                            kpi: el.kpi_id,
                            cellStyle: { minWidth: 70 },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PLANNED_PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT')?.display_name,
                            field: `${el.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            RAG: el?.indicator_based_on === 'PLANNED_ACHIEVEMENT',
                            kpi: el.kpi_id,
                            align: 'center',
                            sorting: true,
                            color_key: 'planned_color',
                            cellStyle: { minWidth: 75, color: el?.indicator_based_on === 'PLANNED_ACHIEVEMENT' && el?.status_code },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                        },
                        {
                            title: el.detailed_attributes.find(ele => ele?.attribute === 'POINTS')?.display_name,
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            kpi: el.kpi_id,
                            RAG: false,
                            sorting: true,
                            emptyValue: '--',
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 10 },
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap', minWidth: 70 },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData([...finalResult])
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
    }

    const columns = [
        {
            title: "Rank",
            field: "rank",
            emptyValue: '--',
            editable: 'never',
            sorting: true,
            cellStyle: { maxWidth: 70, width: 70 },
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            hidden: from === 'Team',
            sorting: true,
            cellStyle: { maxWidth: 200, width: 200, minWidth: 160 },
            templateRender: (rowData) => rowData.player?.name.toUpperCase(),
            render: (rowData) => rowData.player ? (
                <div style={{ fontSize: 12 }} className={classes.nameRoot}>
                    <AvatarComponent data={rowData} from='PLAYER' type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            sorting: true,
            hidden: from === 'Team' || !(filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints),
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                    </div>
                </div>
            ) : '--',
        },
        {
            title: "Teams",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            sorting: true,
            hidden: from !== 'Team',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                    </div>
                </div>
            ) : '--',
        }
    ]


    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            width='lg'
            maxWidth='lg'
            minWidth='lg'
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                {from === 'Team' ?
                    <>
                        <div className={classes.kpiHeading}>
                            <p>{selectedRow?.kpi_name}</p>
                            <div className={classes.colorHdng}><span style={{ background: selectedRow?.status_code }}></span><p style={{ color: selectedRow?.status_code }}>{selectedRow?.title}</p></div>
                        </div>
                    </> :
                    <>
                        {roleObjHealth?.name}
                    </>
                }
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div style={{ width: '100%' }}>
                    {
                        from !== 'Team' &&
                        <div className={classes.kpiHeading}>
                            <p>{selectedRow?.kpi_name}</p>
                            <div className={classes.colorHdng}><span style={{ background: selectedRow?.status_code }}></span><p style={{ color: selectedRow?.status_code }}>{selectedRow?.title}</p></div>
                        </div>
                    }
                    <div className="material-table">
                        <MaterialTable
                            title={'Match Summary'}
                            columns={[...columns, ...dynamicColumns]}
                            data={data}
                            options={{
                                search: false,
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                emptyRowsWhenPaging: false,
                                toolbar: false,
                                pageSizeOptions: [25, 50, 100],
                                pageSize: 25,
                                paging: true,
                                sorting: true,
                            }}
                        />
                    </div>
                    {/* {
                        (data?.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '30px' }} /> :
                            <LiveScoresMuiTable
                                columns={[...columns, ...dynamicColumns]}
                                data={data}
                                options={{
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 25],
                                }}
                                components={{
                                    Header: props => (
                                        <TableHead className="custom-headers">
                                            <TableRow>
                                                <TableCell >{props.columns[0]?.title}</TableCell>
                                                {from === 'Team' ? <TableCell align="left" style={{ textAlign: 'left' }} >{props.columns[3]?.title}</TableCell> :
                                                    <TableCell align="left" style={{ textAlign: 'left' }} >{props.columns[1]?.title}</TableCell>
                                                }
                                                {!(from === 'Team') && (filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints) && <TableCell align="left" style={{ textAlign: 'left' }} >{props.columns[2]?.title}</TableCell>}
                                                {
                                                    dynamicHeaders.map((obj) => (
                                                        <>
                                                            {
                                                                obj?.detailed_attributes.length ?
                                                                    <>
                                                                        {
                                                                            obj?.detailed_attributes.map(ele => (
                                                                                ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                                    </>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                    )
                                }}
                            />
                    } */}
                </div>
            </DialogContent>
        </Dialog >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getDivisionWiseKpi: (options) => dispatch(getDivisionWiseKpi(options)),
        getTeamDivisionWiseKpi: (options) => dispatch(getTeamDivisionWiseKpi(options)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HealthPerformanceDialog))
