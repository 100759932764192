import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import LeaguesIcon from "../../assets/images/leagues-logo-1.png";
import LeagueLogo from "../../assets/images/defaultLeague-logo.png";
import { formatDate, getBase64WithUrl, getNumberFormat, getCurrencyFormat } from '../../helpers';
import moment from 'moment';
import { object } from 'underscore';
import { getUploadLogsInfo } from '../../ducks/game';
import GetCountryCurrencyFormat from './GetCountryCurrencyFormat';

const useStyles = makeStyles((theme) => ({
    imageTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '& div:first-child': {
            marginRight: 10,
            textAlign: 'right',
            '& p': {
                margin: 0,
                '&:last-child': {
                    fontSize: 11
                }
            }
        }
    },
    image1Title: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        margin: '-60px 0px',
        '& div:first-child': {
            textAlign: 'right',
            '& p': {
                margin: 0,
                padding: 0,
                '&:last-child': {
                    fontSize: 11
                }
            }
        }
    },

    logo: {
        width: '50px',
        height: '20px'
    },
    tableTitle: {
        fontSize: 30,
        // margin: '80px 0 0px',
        '& p': {
            margin: 0,
            '&:last-child': {
                fontSize: 20,
                marginTop: -10,
                '& .divider': {
                    margin: '0 10px',
                    color: 'orange',
                    fontSize: 30,
                    fontWeight: 600,
                    verticalAlign: 'middle'
                }
            }
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    table: {
        width: '100%',
        padding: '20px'

    },
    leaguesLogo: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 85,
        backgroundColor: '#fff',
        color: '#9262e2',
        padding: 5,
        fontSize: 12,
        borderRadius: 5,
        height: 13,

    },
    leagues: {
        display: 'flex',
        flexDirection: 'row'
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffff',
        // fontFamily: 'Poppins',
        padding: '10px'
    }
}));

const PlayerLeaderboardTemplate = ({ render = false, id = "", tabData, tabType, userDetails, filtersData, data, columns, components, orgDetails, divisionName = '', belongsTo, decimalView, theme, getUploadLogsInfo }) => {
    const classes = useStyles();
    const [leagueLogo, setLeagueLogo] = useState("");
    const [orgLogo, setOrgLogo] = useState("");
    const columns1 = ['RANK', 'PLAYER', 'TARGET', 'ACTUAL', 'ACH%', 'POINTS']
    const columns2 = ['RANK', 'PLAYER', 'ACTUAL', 'POINTS']
    const columns3 = ['RANK', 'PLAYER', 'TEAM', 'POINTS']
    const columns4 = ['RANK', 'PLAYER', 'POINTS']
    const [uploadLogDate, setUploadLogDate] = useState(null)
    const tableData = JSON.parse(JSON.stringify(data))
    const finalData = tableData.sort((p1, p2) => (p1.rank > p2.rank) ? 1 : (p1.rank < p2.rank) ? -1 : 0)
    console.log('tableeeeeeee', finalData, tabType)


    useEffect(() => {
        if (filtersData?.season?.season_format !== 'HEAD_TO_HEAD') {
            getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { type: "PLAYER_SPRINT" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })

        }
        else {
            getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { type: "PLAYER_ROUND" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })
        }


    }, [filtersData?.season?._id])

    const onSuccessUploadLogsInfo = (response) => {
        console.log('onSuccessResponse', response)
        if (response) {
            setUploadLogDate(response)
        }

    }

    const onErrorUploadLogsInfo = (error) => {
        console.log('UploadLogs Error', error)
        setUploadLogDate(null)

    }

    useEffect(() => {
        if (orgDetails?.logo_url) {
            getBase64WithUrl(orgDetails?.logo_url).then((res) => {
                console.log('res org logo ', res);
                setOrgLogo(res);
            }).catch((err) => console.log(err.response));
        }
    }, [orgDetails?.logo_url]);

    useEffect(() => {
        if (filtersData?.league?.logo_url) {
            getBase64WithUrl(filtersData?.league?.logo_url).then((res) => {
                console.log('res league logo ', res);
                setLeagueLogo(res);
            }).catch((err) => console.log(err.response));
        }
    }, [filtersData?.league?.logo_url]);

    const { background: { isGradiant, top, bottom, overAll }, heading, table_head_row, table_data_row } = theme;

    return (
        <div style={{ position: 'absolute', zIndex: -1, top: 0, left: 0 }} >
            {/* style={{ position: 'absolute', zIndex: -1, top: 0, left: 0 }} */}
            <div id={id} className="player-table" style={{ height: render ? null : 0, background: isGradiant ? `linear-gradient(0deg, ${bottom}, ${top})` : overAll, fontFamily: 'BarlowCondensed', }}>
                {filtersData?.league?.logo_url ?
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '5px', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                        <div><img src={leagueLogo} alt="logo" width="150" height="150" /></div>
                        <div><img src={orgLogo} alt="logo" width="150" height="150" /></div>

                    </div>

                    :

                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '5px', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                        <div><img src={LeagueLogo} alt="logo" width="150" height="150" /></div>
                        <div><img src={orgLogo} alt="logo" width="150" height="150" /></div>

                    </div>

                }
                {
                    render &&
                    <>
                        <div>
                            <div>
                                <div className={classes.tableTitle} style={{ color: heading?.color, fontSize: heading?.size }}>
                                    <p style={{ fontFamily: 'Poppins', fontWeight: 'normal' }}>{`${tabData.name}`.toUpperCase()}</p>
                                    <p style={{ marginTop: '-10px', fontSize: '70px', color: '#ffff' }}>{(filtersData.season?.divisions_enabled && divisionName) && <span>TEAM {divisionName} - </span>}PLAYER LEADERBOARD</p>
                                    <div className={classes.detailsContainer}>
                                        <p style={{ fontSize: '35px', paddingBottom: 10, color: '#262348' }}>TOP 5 PLAYERS {uploadLogDate && <span> - TILL {formatDate(uploadLogDate?.created_at)}</span>}</p>
                                        <p style={{ fontSize: '25px', color: '#262348' }}>
                                            <span>{formatDate(filtersData.season?.start_date)} - {formatDate(filtersData.season?.end_date)}</span>
                                            {(filtersData.season?.divisions_enabled && divisionName) && <span><span className="divider">|</span>{divisionName}</span>}
                                        </p>
                                    </div>
                                </div>
                                <table className={classes.table}>
                                    {
                                        components?.Header ?
                                            [components.Header({ columns })]
                                            :
                                            <thead>

                                                {
                                                    (tabType === 'CORE KPI' && tabData?.player_match_rule?.based_on === 'ACHIEVEMENT') ?
                                                        <>
                                                            {tabType === 'CORE KPI' || tabType === 'BONUS KPI' ?
                                                                <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns1.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr> :
                                                                tabType === 'TEAM' ?
                                                                    <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns4.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr> :
                                                                    <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns3.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr>
                                                            }
                                                        </>
                                                        :
                                                        (tabType === 'BONUS KPI' && tabData?.player_bonus_rule?.based_on === 'ACHIEVEMENT') ?
                                                            <>
                                                                {tabType === 'CORE KPI' || tabType === 'BONUS KPI' ?
                                                                    <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns1.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr> :
                                                                    tabType === 'TEAM' ?
                                                                        <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns4.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr> :
                                                                        <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns3.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr>
                                                                }
                                                            </>
                                                            :
                                                            tabType === 'CORE KPI' || tabType === 'BONUS KPI' ?
                                                                <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns2.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr> :
                                                                tabType === 'TEAM' || filtersData.season?.season_format === 'PLAYER_RACE' ?
                                                                    <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns4.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr> :
                                                                    <tr style={{ fontWeight: '1000px', color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{columns3.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr>
                                                }

                                            </thead>
                                    }
                                    <tbody>
                                        {
                                            finalData.map((row, rowIndex) =>
                                                <tr key={row._id} style={{ color: table_data_row?.color || '#000', backgroundColor: table_data_row?.background_color, fontSize: table_data_row?.size }}>
                                                    {tabType === 'CORE KPI' || tabType === 'BONUS KPI' ?
                                                        <>
                                                            <td>{row?.rank}</td>
                                                            {filtersData.season?.season_format === 'PLAYER_RACE' ?
                                                                <td>{row?.player?.name.toUpperCase()}</td> :
                                                                <td><span style={{ display: 'flex', flexDirection: 'column' }}>{row?.player?.name.toUpperCase()}
                                                                    <span style={{ fontSize: '15px' }}>TEAM {row?.team?.name}</span></span>
                                                                </td>}
                                                            {(tabType === 'CORE KPI' && tabData?.player_match_rule?.based_on === 'ACHIEVEMENT') ?
                                                                <>
                                                                    <td>{row?.target
                                                                        // row?.unit_of_measurement === 'CURRENCY' ? <GetCountryCurrencyFormat type="currency" value={row?.target} />
                                                                        //     : row?.unit_of_measurement === 'NUMBER' ? <GetCountryCurrencyFormat type="number" value={row?.target} /> : getNumberFormat(userDetails?.countryCode, row?.target)
                                                                    }</td>

                                                                    <td>{row?.actual
                                                                        // row?.unit_of_measurement === 'CURRENCY' ? <GetCountryCurrencyFormat type="currency" value={row?.actual} />
                                                                        //     : row?.unit_of_measurement === 'NUMBER' ? <GetCountryCurrencyFormat type="number" value={row?.actual} /> : getNumberFormat(userDetails?.countryCode, row?.actual)
                                                                    }</td>

                                                                    <td>{row?.unit_of_measurement === 'CURRENCY' ? getNumberFormat(userDetails?.countryCode, row?.achieved) + '%' : getNumberFormat(userDetails?.countryCode, row?.achieved) + '%'}</td>
                                                                </>
                                                                :
                                                                (tabType === 'BONUS KPI' && tabData?.player_bonus_rule?.based_on === 'ACHIEVEMENT') ?
                                                                    <>
                                                                        <td>{
                                                                            row?.target
                                                                            // row?.unit_of_measurement === 'CURRENCY' ? <GetCountryCurrencyFormat type="currency" value={row?.target} />
                                                                            //     : row?.unit_of_measurement === 'NUMBER' ? <GetCountryCurrencyFormat type="number" value={row?.target} /> : getNumberFormat(userDetails?.countryCode, row?.target)
                                                                        }</td>
                                                                        {/* <td>{row?.unit_of_measurement === 'CURRENCY' ? getNumberFormat(userDetails?.countryCode, row?.achieved) + '%' : getNumberFormat(userDetails?.countryCode, row?.achieved) + '%'}</td> */}

                                                                        <td>{
                                                                            row?.actual
                                                                            // row?.unit_of_measurement === 'CURRENCY' ? <GetCountryCurrencyFormat type="currency" value={row?.actual} />
                                                                            //     : row?.unit_of_measurement === 'NUMBER' ? <GetCountryCurrencyFormat type="number" value={row?.actual} /> : getNumberFormat(userDetails?.countryCode, row?.actual)
                                                                        }</td>

                                                                        <td>{row?.unit_of_measurement === 'CURRENCY' ? getNumberFormat(userDetails?.countryCode, row?.achieved) + '%' : getNumberFormat(userDetails?.countryCode, row?.achieved) + '%'}</td>
                                                                    </>
                                                                    :

                                                                    <>
                                                                        <td>{row?.actual
                                                                            // row?.unit_of_measurement === 'CURRENCY' ? <GetCountryCurrencyFormat type="currency" value={row?.actual} />
                                                                            //     : row?.unit_of_measurement === 'NUMBER' ? <GetCountryCurrencyFormat type="number" value={row?.actual} /> : getNumberFormat(userDetails?.countryCode, row?.actual)
                                                                        }</td>
                                                                        {/* <td>{row?.unit_of_measurement === 'CURRENCY' ? getNumberFormat(userDetails?.countryCode, row?.achieved) + '%' : getNumberFormat(userDetails?.countryCode, row?.achieved) + '%'}</td> */}

                                                                    </>
                                                            }
                                                            <td>{getNumberFormat(userDetails?.countryCode, row?.total_points, decimalView)}</td>
                                                        </>
                                                        :
                                                        tabType === 'TEAM' || filtersData.season?.season_format === 'PLAYER_RACE' ?
                                                            <>
                                                                <td>{row?.rank}</td>
                                                                <td>{row?.player?.name.toUpperCase()}</td>
                                                                <td>{getNumberFormat(userDetails?.countryCode, row?.total_points, decimalView)}</td>
                                                            </> : <>
                                                                <td>{row?.rank}</td>
                                                                <td>{row?.player?.name.toUpperCase()}</td>
                                                                <td>{row?.team?.name.toUpperCase()}</td>
                                                                <td>{getNumberFormat(userDetails?.countryCode, row?.total_points, decimalView)}</td>
                                                            </>}


                                                </tr >
                                            )
                                        }
                                    </tbody >
                                </table >

                            </div >


                        </div >


                    </>}
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({
    orgDetails: state.common.orgDetails,
    theme: state.common.templateTheme
});
const mapDispatchToProps = (dispatch) => {
    return {
        getUploadLogsInfo: (options) => dispatch(getUploadLogsInfo(options))

    }
};

export default connect(mapStateToProps, mapDispatchToProps, null)(PlayerLeaderboardTemplate);
