import React, { useState, useEffect, useRef } from 'react';
import CreateArenaPost from './CreateArenaPost';
import { Dialog, DialogTitle, IconButton, makeStyles, useTheme, useMediaQuery, DialogContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from "notistack";
import { openEditArenaDialog } from '../../ducks/arena';
import { setLoading } from '../../ducks/loading';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        minHeight: 470,
        borderRadius: 15,
        maxWidth: 825
    },
    dialogTitle: {
        padding: '21px 40px',
        fontSize: 22,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogContent: {
        padding: '13px 40px'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 10
    },

}))

const EditArenaPost = ({ editArena: { open, arena }, handleOnNewPost, openEditArenaDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const handleClose = () => {
        openEditArenaDialog({ open: false, arena: {} });
    }

    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="edit-arena-dialog"
                style={{ zIndex: 1301 }}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    Edit Post
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <CreateArenaPost fromEdit={true}
                        arena={{
                            postId: arena.postId,
                            category: arena.category,
                            text: arena.text,
                            attachments: arena.attachments,
                            newAttachments: [],
                            tags: arena.tagUsers ? arena.tagUsers.map(obj => ({ ...obj, username: obj.userId })) : [],
                            type: arena.type,
                            background: arena.background
                        }}
                        handleClose={handleClose}
                        handleOnNewPost={handleOnNewPost} />
                </DialogContent>
            </Dialog >
        </div>
    )
}

const mapStateToProps = (state) => ({
    editArena: state.arena.editArena,
});

const mapDispatchToProps = (dispatch) => {
    return {
        openEditArenaDialog: (options) => dispatch(openEditArenaDialog(options)),
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditArenaPost);