import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getLeaguesList, getSeasonsList, getSeasonTeams, getSeasonKpisList, getTeamKpisTrends } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import FieldSet from '../../GlobalComponents/FieldSet';
import PerformanceGraph from '../OverallTrends/PerformanceGraph';
import { DurationTooltip } from '../DurationTooltip';
import { formatDate, getSearchValue, setSearchQuery } from '../../../helpers';
import DummyGraph from '../../../assets/images/dummy-graph.png';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import IllustrationView from '../../LiveScores/IllustrationView';
import { CustomTabBar } from '../../Leaderboards/TabBar';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    filtersRoot: {
        padding: '0px 40px 20px',
        // padding: '80px 80px 20px',
        // width: 'calc(100% - 160px)'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 5px !important',
        fontSize: 13,
        backgroundColor: '#FFF'
    },
    label: {
        fontSize: 13,
    },
    roundIconButton: {
        fontSize: 12,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: '0 4px',
        pointerEvents: 'auto !important'
    },
    roundIconButtonSelected: {
        color: 'black',
        backgroundColor: '#C4C4C4'
    },
    roundsLableStyles: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
    },
    avatarStyles: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 26,
        width: 26,
        border: '2px solid #FFF'
    },
    cardHeights: {
        maxWidth: '100%',
        maxHeight: '60px',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            background: "#EEEEEE",
            borderBottom: `1px solid ${theme.palette.primary.main}`,

        }
    },
    vsStyles: {
        margin: '0px',
        fontSize: 12,
        padding: '0px',
        textAlign: 'center'
    },
    teamName: {
        margin: 0,
        width: '75px',
        padding: 5,
        overflow: 'hidden',
        fontSize: '13px',
        // fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            // width: 100
        }
    },
    matchView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #c4c4c48c',
        borderRadius: 30,
        cursor: 'pointer',
        maxWidth: 270,
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'black'
        }
    },
    selectedMatch: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    filedSetRootStyles: {
        padding: 20,
    },
    filedSetRootStyles1: {
        padding: 20,
        minHeight: 300
    },
    legendRootStyles: {
        fontSize: 14
    }, legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    }, emptyData: {
        maxHeight: 400,
        width: '100%',
        position: 'relative',
        '& img': {
            opacity: .1,
            width: '100%'
        },
        '& p': {
            fontSize: 12,
            position: 'absolute',
            top: 'calc(50% - 9px)',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
        }
    }
}));

const defaultFilters = {
    league: null,
    season: null,
    kpi: null,
    team: null
};

const TeamPerformanceTrends = ({ filtersData, setFiltersData, getLeaguesList, getSeasonsList, setLoading, loading, getSeasonTeams, getSeasonKpisList, getTeamKpisTrends, history, seasonDetails }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [teams, setTeams] = useState([]);
    const [kpis, setKpis] = useState([]);

    // useEffect(() => {
    //     getLeagues();
    // }, []);

    // useEffect(() => {
    //     if (filtersData.league) getSeasons(filtersData.league._id);
    // }, [filtersData.league]);

    useEffect(() => {
        if (filtersData.season?._id) {
            if (filtersData.season.season_format === 'HEAD_TO_HEAD') {
                getTeamsList(filtersData.season._id);
            }
            else {
                if (filtersData.season?.season_format !== 'PLAYER_RACE') {
                    getTeamsList(filtersData.season._id);
                }
            }
        }
    }, [filtersData.season?._id]);

    useEffect(() => {
        if (filtersData.team) {
            getKpiPerformanceTrends(filtersData.season._id, filtersData.kpi, filtersData.team?.team_id)
        }
    }, [filtersData.team])

    const handleChangeAutocomplete = (data, field) => {
        setSearchQuery(history, { [field]: data?._id });
        if (field === 'season') {
            setFiltersData(prevState => ({ ...prevState, [field]: data, kpi: null }));
        }
        else
            setFiltersData(prevState => ({ ...prevState, [field]: data }));
    };

    const handleSubTabChange = (e, newValue) => {
        setSearchQuery(history, { team: newValue?.team_id });
        setFiltersData(prevState => ({ ...prevState, team: teams[newValue] }));
    }

    const getLeagues = () => {
        setLoading(true);
        getLeaguesList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setLeagues([...result]);
                const searchValue = getSearchValue(history, 'league');
                !searchValue && setSearchQuery(history, { league: result[0]?._id });
                setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0] }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getSeasons = (leagueId) => {
        setLoading(true);
        getSeasonsList({
            leagueId: leagueId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setSeasons([...result]);
                    const searchValue = getSearchValue(history, 'season');
                    !searchValue && setSearchQuery(history, { season: result[0]?._id });
                    setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null, bonusMatch: null }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                    setSeasons([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getTeamsList = (seasonId) => {
        setLoading(true);
        getSeasonTeams({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const formatResult = result.map((obj, index) => ({ label: obj.name, name: obj.name, value: index, team_id: obj.team_id }));
                    setTeams([...formatResult]);
                    const searchValue = getSearchValue(history, 'team');
                    !searchValue && setSearchQuery(history, { team: result[0]?.team_id });
                    setFiltersData((prevState) => ({ ...prevState, team: searchValue ? result.find(obj => obj.team_id === searchValue) : result[0] }))
                }
                else {
                    setTeams([]);
                    setFiltersData((prevState) => ({ ...prevState, team: null }))
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getSeasonKpis = (seasonId) => {
        setLoading(true);
        getSeasonKpisList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const finalResult = result.reduce((arr, item, index) => {
                        const splittedArr = [];
                        const kpiDetails = { _id: item._id, name: item.name, unit: item.unit, unit_of_measurement: item.unit_of_measurement };
                        if (item.tags?.includes('TEAM_MATCH')) splittedArr.push({ ...kpiDetails, tag: 'TEAM_MATCH' });
                        if (item.tags?.includes('TEAM_BONUS')) splittedArr.push({ ...kpiDetails, tag: 'TEAM_BONUS' });
                        return [...arr, ...splittedArr];
                    }, []);
                    setKpis([...finalResult]);
                    setFiltersData(prevState => ({ ...prevState, kpi: finalResult[0] }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                    setKpis([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getKpiPerformanceTrends = (seasonId, kpi, teamId) => {
        setLoading(true);
        getTeamKpisTrends({
            seasonId: seasonId,
            params: { team_id: teamId, limit: 5000, page: 1 }, // kpi_id: kpi._id, kpi_type: kpi.tag, 
            onSuccessCB: (result) => {
                if (result.length > 0) {
                    const resultData = result.map(obj => ({
                        labels: obj.stats?.map((item) => `${formatDate(item.end_date)}`), // (item, i) => `${item.pre_fix}${i + 1}`
                        targets: obj.stats?.map(item => item.target),
                        actuals: obj.stats?.map(item => item.actual),
                        duration: obj.stats[0]?.duration,
                        durations: obj.stats?.map((item, i) => ({ label: `${item.pre_fix}${i + 1}`, startDate: item.start_date, endDate: item.end_date })),
                        kpiName: obj.kpi_name,
                        // kpiCode: obj.kpi_code || obj.kpi_name,
                        kpiType: obj.kpi_type,
                        unitOfMeasurement: obj.unit_of_measurement,
                        unit: obj.unit
                    }))
                    // console.log('finalResult ', resultData);
                    setFiltersData(prevState => ({ ...prevState, trends: resultData }));
                }
                else {
                    setFiltersData(prevState => ({ ...prevState, league: prevState.league, season: prevState.season, kpi: prevState.kpi, trends: null }));
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...prevState, league: prevState.league, season: prevState.season, kpi: prevState.kpi, trends: null }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }
    return (
        <div className={classes.root}>
            <div className={classes.filtersRoot}>
                <CustomTabBar tabs={teams} subTabVal={teams?.findIndex(obj => obj.team_id === filtersData.team?.team_id)} handleSubTabChange={handleSubTabChange} />
                {/* <FieldSet title={"Team & KPI selection"} rootStyles={classes.filedSetRootStyles} legend={classes.legendRootStyles}>
                    <Grid container spacing={2} >
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-leagues"
                                options={leagues}
                                getOptionLabel={(option) => option.name || ''}
                                value={filtersData?.league}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="League" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'league')}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-seasons"
                                options={seasons}
                                getOptionLabel={(option) => option.name || ''}
                                value={seasons.length && filtersData?.season}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Season" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'season')}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-teams"
                                options={teams}
                                getOptionLabel={(option) => option.name || ''}
                                value={filtersData?.team}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Team" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'team')}
                            />
                        </Grid>
                    </Grid>
                </FieldSet> */}
                {
                    (filtersData.season?.season_format !== 'PLAYER_RACE') ?
                        <div style={{ margin: '10px 0' }}>
                            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                                {
                                    !loading &&
                                    [
                                        filtersData.trends?.length > 0 ? filtersData.trends.map((trend, i) => (
                                            <Grid item md={filtersData.trends.length === 1 ? 12 : 6} xs={12} style={{ marginTop: 10 }}>
                                                <FieldSet
                                                    key={i}
                                                    title={<span className={classes.legendStyles}>{trend.kpiName} <span style={{ fontSize: 11, margin: '0 4px', color: '#575d63' }}>({trend?.kpiType === 'Core KPI' ? `${seasonDetails?.labels_config?.kpis?.core}` : `${seasonDetails?.labels_config?.kpis?.bonus}`})</span> {filtersData.team?.name ? `- ${filtersData.team?.name} ${filtersData.team?.captain ? `[C: ${filtersData.team?.captain?.fname}]` : ''}` : ''} {(trend?.durations) && <DurationTooltip trend={trend} matchType={trend?.kpiType === 'Core KPI' ? ((filtersData.season?.season_format === 'HEAD_TO_HEAD') ? 'Match Duration' : 'Sprint Duration') : `Team ${seasonDetails?.labels_config?.kpis?.bonus}`} />}</span>}
                                                    rootStyles={classes.filedSetRootStyles}
                                                >
                                                    {
                                                        (trend.labels?.length > 0 && filtersData.team) ?
                                                            <PerformanceGraph {...trend} />
                                                            :
                                                            <div className={classes.emptyData}>
                                                                <img src={DummyGraph} alt="graph" />
                                                                <p >** Data yet to be made available **</p>
                                                            </div>
                                                    }
                                                </FieldSet>
                                            </Grid>
                                        ))
                                            :
                                            < IllustrationView loading={loading} style={{ marginTop: '50px' }} />
                                        // <p style={{ textAlign: 'center', fontSize: 12, color: '#000000a1', width: '100%', marginTop: 50 }}>** No data available **</p>
                                    ]
                                }
                            </Grid>
                        </div>
                        :
                        <div style={{ textAlign: 'center', marginTop: 35 }}>
                            <img src={gameIllustration} />
                            <p style={{ fontSize: 14, color: '#0000008A' }}>Since selected season is of the format 'Player race', we do not have any team trends to show here</p>
                        </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading,
    seasonDetails: state.game.seasonDetails

})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getSeasonTeams: (options) => dispatch(getSeasonTeams(options)),
        getSeasonKpisList: (options) => dispatch(getSeasonKpisList(options)),
        getTeamKpisTrends: (options) => dispatch(getTeamKpisTrends(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamPerformanceTrends));
