import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { setLoading } from "../../../ducks/loading";
import { Breadcrumbs, Card, CardContent, CardHeader, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import PlayerDetails from './playerDetails';
import PlayerReportees from './PlayerReportees';
import PlayerPerformanceCard from './PlayerPerformanceCard';
import { getBreadcrumsData, getDivisionWiseKpi, getPlayersPerformanc, getSingleSeasonDetails, getSeasonKpiPositionList, getSeasonPlayerRank } from '../../../ducks/game';
import qs from 'query-string';
import { getDurationWithUnit, getNumberFormat, getSearchValue, seasonKpiPositionsSort } from '../../../helpers';
import { ReactComponent as PersonIcon } from "../../../assets/icons/userprofile.svg";
import GetCountryCurrencyFormat from '../../GlobalComponents/GetCountryCurrencyFormat';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxHeight: "calc(100vh - 60px)",
        overflowY: "auto",
        // padding: "25px 40px",
    },
    rootclass: {
        padding: "5px 40px",
    },
    profileSec: {
        margin: '25px 0px',
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginLeft: 10,
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    headers: {
        '& h2': {
            fontSize: 16,
            color: 'rgba(54, 54, 54, 1)',
            margin: '5px 0px',
            lineHeight: '24px',
            marginBottom: 8
        }
    },
    performance: {
        marginBottom: 25,
        '& h5': {
            margin: 0,
            marginBottom: 8
        }
    },
    reportees: {
        marginBottom: 25,
    },
    bredcrms: {
        padding: '8px 40px',
        marginBottom: 10,
        background: '#F0F0F0',
        '& .icon': {
            paddingRight: 5
        },
        '& .MuiLink-underlineHover:hover': {
            textDecoration: 'none'
        },
        '& .MuiBreadcrumbs-ol': {
            alignItems: 'flex-start'
        },
        '& .MuiBreadcrumbs-separator': {
            marginTop: '-2px'
        }
    },
    brdlink: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 14,
        textDecoration: "none",
        cursor: 'pointer',
    },
    breadcrumbsActive: {
        color: "#262626",
        fontSize: 14,
        fontWeight: 500,
        alignItems: 'center',
        display: 'flex',
    },

}));


const PlayerPerformance = ({ history, getPlayersPerformanc, getSeasonPlayerRank, getDivisionWiseKpi, getSeasonKpiPositionList, seasonKpiPostions, seasonFormat, userDetails, setLoading, getBreadcrumsData, breadcrumsData, seasonDetails, getSingleSeasonDetails }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [playerDetails, setPlayerDetails] = useState();
    const [playerBonusDetails, setPlayerBonusDetails] = useState()
    const employee = history.location.pathname.split('/')[2]
    // const [loading, setLoading] = useState(false);
    const [playerRank, setPlayerRank] = useState({});
    const [multiUser, setMultiUser] = useState([]);
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [decimalView, setDecimalView] = useState(false);
    const [seasonKpi, setSeasonKpi] = useState([]);
    const [empId, setEmpId] = useState(null);

    useEffect(() => {
        let seasonId = getSearchValue(history, 'season')
        getSeasonKpiPositionList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                setSeasonKpi(result);
            }, onErrorCB: (error) => { }
        });
        getSeasonDetail(seasonId);
    }, [])

    useEffect(() => {
        let seasonId = getSearchValue(history, 'season');
        getPlayersPoints(seasonId, empId);
        getSeasonPlayerRankDetails(seasonId, empId);
        getSeasonBonusPlayerRankDetails(seasonId, empId)
    }, [empId])

    useEffect(() => {
        let seasonId = getSearchValue(history, 'season');
        getDivisionWiseKpi({ seasonId, onSuccessCB, onErrorCB, params: { employee_id: empId ? empId : employee, limit: 5000, page: 1 } });
    }, [seasonKpi, empId])

    // ----------player-performance -------
    const getPlayersPoints = (seasonId, employee_id) => {
        setLoading(true);
        getPlayersPerformanc({
            params: { season_id: seasonId, employee_id: employee_id ? employee_id : employee, limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                if (result) {
                    setPlayerDetails(result[0]);
                }
                else {
                    setPlayerDetails();
                }
            }, onErrorCB: (error) => {
            }
        })
    }
    const getSeasonDetail = (seasonId) => {
        getSingleSeasonDetails({
            seasonId: seasonId,
            onSuccessCB: (result) => {
            }, onErrorCB: (error) => { }
        })
    }

    //----------------- player profile bonus kpis --------------!
    const getSeasonBonusPlayerRankDetails = (seasonId, employee_id) => {
        setLoading(true);
        getPlayersPerformanc({
            seasonId,
            params: { season_id: seasonId, employee_id: employee_id ? employee_id : employee, type: 'BONUS', limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                if (result) {
                    setPlayerBonusDetails(result[0]);
                }
                else {
                    setPlayerBonusDetails();
                }
                setLoading(false);
            }, onErrorCB: (error) => {
            }
        })

    }

    // -----------------season-player-rank -----------
    const getSeasonPlayerRankDetails = (seasonId, employee_id) => {
        setLoading(true);
        getSeasonPlayerRank({
            seasonId,
            params: { employee_id: employee_id ? employee_id : employee, limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                if (result) {
                    setPlayerRank(result);
                    setMultiUser([...multiUser, result]);
                    getBreadcrumsData([...breadcrumsData, result]);
                }
                else {
                    setPlayerRank({});
                }
                setLoading(false);
            }, onErrorCB: (error) => {
            }
        })
    }

    // --------reportees api ------
    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (response?.length > 0) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points, kpis_attributes: seasonKpiPositionsSort(item?.kpis, seasonKpi, 'kpi_id', 'detailed_hidden'), kpis: item?.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-pending_actual`] = el.pending_actual;
                    obj[`${el.kpi_id}-color`] = el.status_code;
                    obj[`${el.kpi_id}-planned_color`] = el.status_code;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_pending_actual`] = el.planned_pending_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE') ? el.score || 0 : el.points || 0);
                    if (index === 0) {
                        const mapped_headers = el.summary_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            RAG: false,
                            cellStyle: { minWidth: 70 },
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('TARGET'),
                            render: (rowData) =>
                                (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`, //-Actual
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: el?.indicator_based_on === 'ACTUALS',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-pending_actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            cellStyle: { minWidth: 70 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            color_key: 'color',
                            RAG: el?.indicator_based_on === 'ACHIEVEMENT',
                            cellStyle: el?.based_on === 'ACHIEVEMENT' ? { minWidth: 75 } : { minWidth: 75 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: false,
                            kpi: el.kpi_id,
                            color_key: 'planned_color',
                            cellStyle: { minWidth: 70 },
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            RAG: el?.indicator_based_on === '',
                            kpi: el.kpi_id,
                            color_key: 'planned_color',
                            cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_pending_actual`,
                            short_code: `${el.kpi_code || el.kpi_name}`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            kpi: el.kpi_id,
                            cellStyle: { minWidth: 70 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PLANNED_PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            RAG: el?.indicator_based_on === 'PLANNED_ACHIEVEMENT',
                            kpi: el.kpi_id,
                            align: 'center',
                            color_key: 'planned_color',
                            cellStyle: el?.based_on === 'PLANNED_ACHIEVEMENT' ? { minWidth: 75 } : { minWidth: 75 },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            kpi: el.kpi_id,
                            RAG: false,
                            emptyValue: '--',
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 10 },
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap', minWidth: 70 },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            if (finalResult) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData([...finalResult])
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);

            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
    }

    const handleGoBack = () => {
        let qryparams = qs.parse(history.location.search);
        setMultiUser([]);
        setPlayerRank({});
        setPlayerDetails();
        setEmpId(null);
        getBreadcrumsData([]);
        if (breadcrumsData?.length > 1) {
            setEmpId(breadcrumsData[breadcrumsData?.length - 2]?.username);
            getBreadcrumsData(breadcrumsData.splice(0, breadcrumsData?.length - 2))
        }
        else history.push({ pathname: '/leaguess/c2/performance/', search: qs.stringify({ ...qryparams }) });
    }

    const profileClick = (employee_id) => {
        history.push({ pathname: `/profile/${employee_id ? employee_id : employee}` });
        setMultiUser([]);
        setPlayerRank({});
        setPlayerDetails();
        setEmpId(null);
        getBreadcrumsData([]);
    }

    const handleProfileClick = (employee_id) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setEmpId(employee_id);
        // let seasonId = getSearchValue(history, 'season')
        // getPlayersPoints(seasonId, employee_id);
        // getSeasonPlayerRankDetails(seasonId, employee_id);
        // getDivisionWiseKpi({ seasonId, onSuccessCB, onErrorCB, params: { employee_id: employee_id, limit: 5000, page: 1 } })
    }

    const handleProfileClickNavigate = (employee_id, index) => {
        setEmpId(employee_id);
        // handleProfileClick(employee_id);
        let copyUsers = breadcrumsData;
        setMultiUser(copyUsers.splice(index, copyUsers?.length));
    }



    const skeletonLoading = (
        <Grid container spacing={1} className={classes.skeletonRoot}>
            {[1, 2, 3, 4].map((ld, id) => (
                <Grid key={id} item lg={3} md={6} sm={6} xs={12}>
                    <Card className="card">
                        <CardHeader style={{ padding: 0 }}
                            avatar={<Skeleton variant="reactangle" width={40} height={30} style={{ borderRadius: 4 }} />}
                            title={<Skeleton height={20} width="98%" />}
                        />
                        <CardContent style={{ padding: 0 }}>
                            <Skeleton height={60} width="100%" />
                        </CardContent>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );

    return (
        <div className={classes.root} >
            <div className={classes.bredcrms}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumsData?.length > 1 && <PersonIcon fontSize='medium' className='icon' />}
                    {breadcrumsData?.map((el, index) =>
                        <>
                            {
                                index + 1 !== breadcrumsData?.length ?
                                    <Link index={index} key={index} color="inherit" onClick={() => handleProfileClickNavigate(el?.username, index)} className={classes.brdlink}>
                                        {el?.name}
                                    </Link> :
                                    <Typography component="span" color="textPrimary" className={classes.breadcrumbsActive}>
                                        {breadcrumsData?.length === 1 && <PersonIcon fontSize='medium' className='icon' />}
                                        {el?.name}
                                    </Typography>
                            }
                        </>
                    )}
                </Breadcrumbs>
            </div>
            <div className={classes.rootclass}>
                <div className={classes.heading}>
                    <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
                    <h2>Player Details</h2>
                </div>
                <div className={classes.profileSec}>
                    <PlayerDetails playerDetails={playerRank} profileClick={profileClick} />
                </div>
                {/* {loading && skeletonLoading} */}
                {playerDetails?.kpis?.length > 0 &&
                    <div className={classes.performance}>
                        <h5>{`${seasonDetails?.labels_config?.kpis?.core}`}</h5>
                        <PlayerPerformanceCard playerDetails={playerDetails} />
                    </div>
                }
                {playerBonusDetails?.kpis?.length > 0 &&
                    <div className={classes.performance}>
                        <h5>{`${seasonDetails?.labels_config?.kpis?.bonus}`}</h5>
                        <PlayerPerformanceCard playerDetails={playerBonusDetails} />
                    </div>
                }
                {data?.length > 0 &&
                    <div className={classes.reportees}>
                        <div className={classes.headers}>
                            <h2>Player Reportees</h2>
                        </div>
                        <PlayerReportees data={data} dynamicColumns={dynamicColumns} dynamicHeaders={dynamicHeaders} handleProfileClick={handleProfileClick} />
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading,
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    seasonKpiPostions: state.game.seasonKpiPostions,
    breadcrumsData: state.game.breadcrumsData,
    seasonDetails: state.game.seasonDetails

})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPerformanc: (options) => dispatch(getPlayersPerformanc(options)),
        getSeasonPlayerRank: (options) => dispatch(getSeasonPlayerRank(options)),
        getDivisionWiseKpi: (options) => dispatch(getDivisionWiseKpi(options)),
        getSeasonKpiPositionList: (options) => dispatch(getSeasonKpiPositionList(options)),
        getBreadcrumsData: (options) => dispatch(getBreadcrumsData(options)),
        getSingleSeasonDetails: (options) => dispatch(getSingleSeasonDetails(options)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerPerformance));
