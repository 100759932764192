import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/search");

export const GET_GLOBAL_SEARCH_RESULT = constant("GET_GLOBAL_SEARCH_RESULT");
export const SET_GLOBAL_SEARCH_RESULT = constant("SET_GLOBAL_SEARCH_RESULT");
export const SET_GLOBAL_SEARCH_QUERY = constant("SET_GLOBAL_SEARCH_QUERY");
export const GET_CONTACT_SEARCH_RESULT = constant("GET_CONTACT_SEARCH_RESULT");
export const SET_CONTACT_SEARCH_RESULT = constant("SET_CONTACT_SEARCH_RESULT");
export const SET_ARENA_SERACH_QUERY = constant("SET_ARENA_SEARCH_QUERY");
export const SEARCH_ARENA_CHASEONS_OR_POSTS = constant("SEARCH_ARENA_CHASEONS_OR_POSTS");
export const SEARCH_ARENA_MEDIA = constant("SEARCH_ARENA_MEDIA");
export const SET_ARENA_SERACH_RESULT = constant("SET_ARENA_SERACH_RESULT");

export const getGlobalSearchResult = (payload) => ({
    type: GET_GLOBAL_SEARCH_RESULT,
    payload
});

export const setGlobalSearchResult = (payload) => ({
    type: SET_GLOBAL_SEARCH_RESULT,
    payload
});

export const getContactSearchResult = (payload) => ({
    type: GET_CONTACT_SEARCH_RESULT,
    payload
});

export const setContactSearchResult = (payload) => ({
    type: SET_CONTACT_SEARCH_RESULT,
    payload
});

export const setGlobalSearchQuery = (payload) => ({
    type: SET_GLOBAL_SEARCH_QUERY,
    payload
});

export const setArenaSearchQuery = (value) => ({
    type: SET_ARENA_SERACH_QUERY,
    payload: value
})

export const searchArenaChaseonsOrPosts = (payload) => ({
    type: SEARCH_ARENA_CHASEONS_OR_POSTS,
    payload
})

export const searchArenaMedia = (payload) => ({
    type: SEARCH_ARENA_MEDIA,
    payload
})

export const setArenaSearchResult = (payload) => ({
    type: SET_ARENA_SERACH_RESULT,
    payload
})

const initialState = {
    searchQuery: '',
    global: [],
    contacts: [],
    arena: {
        query: "",
        data: []
    }
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_GLOBAL_SEARCH_RESULT: {
            console.log('SET_GLOBAL_SEARCH_RESULT ', action)
            return {
                ...state,
                global: [...action.payload],
            }
        }

        case SET_CONTACT_SEARCH_RESULT: {
            console.log('SET_CONTACT_SEARCH_RESULT ', action)
            return {
                ...state,
                contacts: [...action.payload],
            }
        }

        case SET_GLOBAL_SEARCH_QUERY: {
            return {
                ...state,
                searchQuery: action.payload,
            }
        }

        case SET_ARENA_SERACH_QUERY: {
            return {
                ...state,
                arena: {
                    ...state.arena,
                    query: action.payload
                }
            }
        }
        case SET_ARENA_SERACH_RESULT: {
            return {
                ...state,
                arena: {
                    ...state.arena,
                    data: action.payload
                }
            }
        }

        default:
            return state;
    }
};
