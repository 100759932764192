import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LiveMatches from "../LiveMatches";
import OverallOrgStats from "../OverallOrgStats";
import PlayerStandings from "../PlayerStandings";
import TeamStandings from "../TeamStandings";
import MyPerformance from "../MyPerformance";
import MyTeamPerformance from "../MyTeamPerformance";
import { setLoading } from "../../../ducks/loading";
import { CustomCard } from "../CustomCard";
import { CustomListGroup, RoundsListGroup } from "../../LiveScores/RoundsListView";
import { getSearchValue } from "../../../helpers";
import MyTeamPlayers from "../MyTeamPlayers";

const PlayerDashboard = ({ setLoading, filtersData, rounds, userInfo, natigateToAllStats, natigateToLiveScores, navigateToTeamLeaderboard, navigateToPlayerLeaderboard, navigateToAllMatches, history, natigateToAllPlayers }) => {

    const [selectedPlayerOption, setSelectedPlayerOption] = useState(null);
    const [selectedTeamOption, setSelectedTeamOption] = useState(null);
    const [menuOptions, setMenuOptions] = useState([]);
    const [playerData, setPlayerData] = useState([]);

    useEffect(() => {
        if (rounds.length > 0 || filtersData?.season?._id) {
            const liveRound = rounds.find(obj => obj.round_status === 'LIVE');
            if (liveRound) {
                setMenuOptions([
                    { label: (liveRound?.name === 'QUARTER_FINALS') ? 'QF' : (liveRound?.name === 'SEMI_FINALS') ? 'SF' : liveRound?.name, value: liveRound?._id, pathname: 'round' },
                    { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' }
                ]);
                setSelectedPlayerOption({ label: liveRound?.name, value: liveRound?._id, pathname: 'round' });
                setSelectedTeamOption({ label: liveRound?.name, value: liveRound?._id, pathname: 'round' });
            }
            else {
                setMenuOptions([
                    { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season', disabled: true }
                ]);
                setSelectedPlayerOption({ label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' });
                setSelectedTeamOption({ label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' });
            }
        }
    }, [rounds, filtersData?.season?._id]);

    const handlePlayerOptionChange = (newValue) => {
        setSelectedPlayerOption(newValue);
    }

    const handleTeamOptionChange = (newValue) => {
        setSelectedTeamOption(newValue);
    }

    const isPlayerSeasonLevel = selectedPlayerOption?.pathname === 'season';
    const isTeamSeasonLevel = selectedTeamOption?.pathname === 'season';


    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    {(filtersData?.season?.season_format === 'HEAD_TO_HEAD') &&
                        // <div style={{ marginBottom: 40 }}>
                        <LiveMatches filtersData={filtersData} userInfo={userInfo} navigateToAllMatches={navigateToAllMatches} />
                        // </div>
                    }
                </Grid>
                {((filtersData.season?.season_format === 'HEAD_TO_HEAD' || filtersData.season?.season_format === 'PLAYER_RACE') || (filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints)) &&
                    <Grid item md={6}>
                        <CustomCard cardTitle="My Performance"
                            // filterTabs={<CustomListGroup data={menuOptions} selectedItem={selectedPlayerOption} onClick={handlePlayerOptionChange} style={{ margin: 0, marginTop: -8 }} />}
                            linkText="Live Score" onLinkClick={natigateToLiveScores} >
                            <MyPerformance isSeasonLevel={isPlayerSeasonLevel} roundId={!isPlayerSeasonLevel && selectedPlayerOption?.value} filtersData={filtersData} />
                        </CustomCard>
                    </Grid>
                }
                {(filtersData?.season?.season_format !== 'PLAYER_RACE') &&
                    <Grid item md={6}>
                        <CustomCard cardTitle="My Team Performance"
                            // filterTabs={<CustomListGroup data={menuOptions} selectedItem={selectedTeamOption} onClick={handleTeamOptionChange} style={{ margin: 0, marginTop: -8 }} />}
                            linkText="Live Score" onLinkClick={natigateToLiveScores}>
                            <MyTeamPerformance isSeasonLevel={isTeamSeasonLevel} roundId={!isTeamSeasonLevel && selectedTeamOption?.value} filtersData={filtersData} />
                        </CustomCard>
                    </Grid>
                }
                {/* {(filtersData?.season?.season_format !== 'PLAYER_RACE') &&
                    <Grid item md={12}>
                        <CustomCard cardTitle="My Team Players" linkText={(playerData.length !== 0) && "View Players Detailed"} onLinkClick={natigateToAllPlayers}>
                            <MyTeamPlayers seasonId={filtersData.season?._id}
                                teamId={userInfo?.myTeams} matchId={filtersData.match?._id}
                                seasonFormat={filtersData.season?.season_format}
                                isSeasonLevel={isPlayerSeasonLevel} roundId={!isPlayerSeasonLevel && selectedPlayerOption?.value} filtersData={filtersData} setPlayerData={setPlayerData} />
                        </CustomCard>
                    </Grid>
                } */}
                <Grid item md={6}>
                    <CustomCard cardTitle={"Overall Org Stats"} linkText="Stats" onLinkClick={natigateToAllStats}>
                        <OverallOrgStats filtersData={filtersData} />
                    </CustomCard>
                </Grid>
                <Grid item md={6}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <CustomCard cardTitle="Player Standings" linkText="Full List" onLinkClick={!isPlayerSeasonLevel ? natigateToLiveScores : navigateToPlayerLeaderboard} >
                                <PlayerStandings isSeasonLevel={isPlayerSeasonLevel} roundId={!isPlayerSeasonLevel && selectedPlayerOption?.value} filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                        <Grid item md={6}>
                            <CustomCard cardTitle="Team Standings" linkText="Full List" onLinkClick={!isTeamSeasonLevel ? natigateToLiveScores : navigateToTeamLeaderboard}>
                                <TeamStandings isSeasonLevel={isTeamSeasonLevel} roundId={!isTeamSeasonLevel && selectedTeamOption?.value} filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),



    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerDashboard));
