import React, { useState, useEffect } from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  TextareaAutosize,
  Button,
  Switch,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import { ReactComponent as AcceptIcon } from "../../assets/icons/Accept.svg";
import { ReactComponent as DeclineIcon } from "../../assets/icons/close-7.svg";
import { Close } from "@material-ui/icons";
import { connect } from "react-redux";
import { updateMeetingStatus } from "../../ducks/meetings";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
  },
  dialogPaper: {
    minHeight: 248,
    borderRadius: "6px",
    minWidth: 330,
  },
  dialogTitle: {
    padding: "20px 24px 10px"
  },

  title: {
    color: "#535353",
    fontSize: "13px",
    lineHeight: "10px",
    fontWeight: "bold",
  },
  notify: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "1rem",
    paddingBottom: 0,
  },
  text: {
    border: "none",
    fontSize: 14,
    color: "#535353",
    outline: "none",
    resize: "none",
    lineHeight: "20px",
    backgroundColor: "inherit",
    width: '100%',
    padding: 0
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 15px 0px",
  },
  switch: {
    marginTop: "0.3rem",
  },
  tenativeIcon: {
    color: "#001F86",
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 0,
  },
  buttonText: {
    fontSize: "13px",
    lineHeight: "15px",
    color: "#535353",
  },
  titleRoot: {
    padding: "10px 10px 8px",
  },
  divider: {
    // marginTop: "3.6rem",
  },
}));
let status = "";
function DialogRespond({
  meetingId,
  participantId,
  handleClose,
  open,
  updateMeetingStatus,
}) {
  console.log("meetingId", meetingId, "participantId", participantId);
  const [word, setWord] = useState("");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  
  const onSuccessCB = (data) => {
    enqueueSnackbar((data.message || 'Status updated successfully'), {
      variant: "success",
      preventDuplicate: true,
    });
    handleClose(status);
  };

  const onErrorCB = (error) => {
    enqueueSnackbar(
      error.response && error.response.data
        ? error.response.data.error
        : `Failed to Update Meeting Join Status.`,
      {
        variant: "error",
        preventDuplicate: true,
      }
    );
    handleClose();
  };
  const handleMeetingStatus = () => {
    const reqObject = {
      notes: word,
    };
    updateMeetingStatus({
      meetingId,
      participantId,
      status,
      onSuccessCB,
      onErrorCB,
      reqObject,
    });
    setWord("");
  };
  const handleButton = (key) => {
    switch (key) {
      case "maybe":
        status = "maybe";
        handleMeetingStatus();
        break;
      case "yes":
        status = "yes";
        handleMeetingStatus();
        break;
      case "no":
        status = "no";
        handleMeetingStatus();
        break;
      default:
        break;
    }
  };
  return (
    <div className={classes.root}>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} classes={{ root: classes.dialogTitle }}>
          <Typography className={classes.title}> Respond to Invite</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Close style={{ height: "20px" }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {/* <div className={classes.notify}>
          <p
            className={classes.title}
            style={{ opacity: "0.8", paddingLeft: "5px" }}
          >
            Notify organiser
          </p>
          <Switch
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
            color="primary"
            className={classes.switch}
          />
        </div> */}
        <div style={{ padding: 10 }}>
          <TextareaAutosize
            // color="primary"
            id="arena-background-post-text-field"
            placeholder="Add a note (optional)"
            value={word}
            rowsMin={6}
            rowsMax={15}
            className={classes.text}
            onChange={(e) => {
              setWord(e.target.value);
            }}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.buttons} classes={{ root: classes.buttonText }}>
          <Button
            startIcon={<AcceptIcon />}
            onClick={() => handleButton("yes")}
            classes={{ root: classes.buttonText }}
          >
            Accept
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            startIcon={"?"}
            classes={{ startIcon: classes.tenativeIcon }}
            onClick={() => handleButton("maybe")}
            classes={{ root: classes.buttonText }}
          >
            Tenative
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            startIcon={<DeclineIcon />}
            onClick={() => handleButton("no")}
            classes={{ root: classes.buttonText }}
          >
            Decline
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  updateMeetingStatus: (option) => dispatch(updateMeetingStatus(option)),
});
export default connect(null, mapDispatchToProps)(DialogRespond);
