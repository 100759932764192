import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, IconButton, TableRow, TableCell, Tooltip, TableHead, FormControlLabel, Switch } from "@material-ui/core";
import { setLoading } from "../../../ducks/loading";
import { getPlayerRaceLiveScore, shareLiveScoreDialog } from "../../../ducks/game";
import { getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort, getSearchValue } from "../../../helpers";
import IllustrationView from "../IllustrationView";
import GetCountryCurrencyFormat from "../../GlobalComponents/GetCountryCurrencyFormat";
import { isRuleBasedOnActuals } from "../RaceSummary";
import AvatarComponent from "../../AvatarComponent/AvatarComponent";
import LiveScoresMuiTable from "../LiveScoresMuiTable";
import qs from 'query-string';
import { ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginTop: 15,
        '& thead': {
            '& tr': {
                backgroundColor: '#EBEBEB !important',
                '& th': {
                    backgroundColor: '#EBEBEB !important',
                    zIndex: '10px !important'
                }
            }
        },
        '& .table-footer': {
            background: 'white',
            fontSize: 12
        },
        '& table': {
            '& thead': {
                '& tr:nth-child(1)': {
                    '& th:nth-last-child(1)': {
                        position: 'sticky !important',
                        right: 0,
                        zIndex: 1,
                        backgroundColor: '#EBEBEB !important',
                    },
                    '& th:nth-child(1)': {
                        position: 'sticky !important',
                        left: 0,
                        zIndex: 1,
                    },
                    '& th:nth-child(2)': {
                        position: 'sticky !important',
                        left: 51,
                        zIndex: 1,
                    },
                },
            },
            '& tbody': {
                '& tr': {
                    '& td:nth-last-child(1)': {
                        position: 'sticky !important',
                        right: 0,
                        zIndex: 1,
                        background: 'white'
                    },
                    '& td:nth-child(1)': {
                        position: 'sticky !important',
                        left: 0,
                        zIndex: 1,
                        background: 'white'
                    },
                    '& td:nth-child(2)': {
                        position: 'sticky !important',
                        left: 51,
                        zIndex: 1,
                        background: 'white'
                    },
                }
            },
        },
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginLeft: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        paddingBottom: 10
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 48,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        marginBottom: 5
    },
    season_Title: {
        '& h4': {
            color: '#000000a6',
            margin: 0,
        }
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    divisin: {
        padding: 10
    },
    divisions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    divisionList: {
        width: '100%',
        maxWidth: '45%',
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        height: 40,
        padding: 1,
        alignItems: 'center',
        marginBottom: 8
    },
    shareing: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'auto',
        '& .sycn': {
            fontStyle: 'italic',
            fontWeight: 400,
            fontSize: 10,
            color: 'rgba(136, 135, 135, 0.9)',
            margin: 0,
            alignItems: 'center',
            paddingRight: 10,
            display: 'flex'
        }
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center'
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
}));

const DetailedTeamPlayerSummary = ({ history, teams, filtersData, setLoading, loading, userDetails, getPlayerRaceLiveScore, seasonKpiPostions }) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [detailData, setDetailData] = useState([]);
    const [dynamicColumnsDet, setDynamicColumnsDet] = useState([]);
    const [dynamicHeadersDet, setDynamicHeadersDet] = useState([]);
    const [decimalView, setDecimalView] = useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [checkColumn, setCheckColumn] = React.useState([{ isChecked: false }]);
    const [detailed, setDetailed] = useState(false);
    const selectDiv = teams.find(obj => obj._id === getSearchValue(history, 'team'))

    useEffect(() => {
        let matchId = filtersData?.matches?.PLAYER_SPRINT?._id;
        let teamId = getSearchValue(history, 'team');
        if (teamId && matchId && filtersData?.season?._id) {
            setLoading(true);
            getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, team_id: teamId } });
        }
    }, [filtersData?.season?._id, filtersData?.matches]);


    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (response.length) {
            let headers = [];
            let headersDub = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, rank: item.position, totalPoints: item.total_points, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'summary_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-pending_actual`] = el.pending_actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-color`] = el.status_code;
                    obj[`${el.kpi_id}-planned_color`] = el.status_code;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_pending_actual`] = el.planned_pending_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.points || 0;
                    if (index === 0) {
                        const mapped_headers = el.summary_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-target`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                kpi: el.kpi_id,
                                color_key: 'color',
                                RAG: false,
                                attribute: el.summary_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('TARGET'),
                                cellStyle: { minWidth: 70 },
                                render: (rowData) =>
                                    (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                        rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                            <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                            : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                                <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                                : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-actual`,
                                short_code: `${el.kpi_code || el.kpi_name}`, //-Actual
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                RAG: el?.indicator_based_on === 'ACTUALS',
                                kpi: el.kpi_id,
                                color_key: 'color',
                                cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('ACTUAL'),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-pending_actual`,
                                short_code: `${el.kpi_code || el.kpi_name}`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                kpi: el.kpi_id,
                                cellStyle: { minWidth: 70 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PENDING_ACTUAL') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('PENDING_ACTUAL'),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-achieved`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                kpi: el.kpi_id,
                                color_key: 'color',
                                RAG: el?.indicator_based_on === 'ACHIEVEMENT',
                                cellStyle: el?.based_on === 'ACHIEVEMENT' ? { minWidth: 75 } : { minWidth: 75 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('ACHIEVEMENT'),
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_target`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                RAG: false,
                                kpi: el.kpi_id,
                                color_key: 'planned_color',
                                cellStyle: { minWidth: 70 },
                                attribute: el.summary_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 10 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                                render: (rowData) =>
                                    (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                        rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                            <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                            : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                                <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                                : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_actual`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                RAG: el?.indicator_based_on === '',
                                kpi: el.kpi_id,
                                color_key: 'planned_color',
                                cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 10 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_pending_actual`,
                                short_code: `${el.kpi_code || el.kpi_name}`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                kpi: el.kpi_id,
                                cellStyle: { minWidth: 70 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_PENDING_ACTUAL') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('PLANNED_PENDING_ACTUAL'),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_achievement`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                RAG: el?.indicator_based_on === 'PLANNED_ACHIEVEMENT',
                                kpi: el.kpi_id,
                                color_key: 'planned_color',
                                cellStyle: el?.based_on === 'PLANNED_ACHIEVEMENT' ? { minWidth: 75 } : { minWidth: 75 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 10 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                            },
                            {
                                title: `${el.kpi_name}`,//-Points
                                field: `${el.kpi_id}-points`,
                                editable: 'never',
                                emptyValue: '--',
                                cellStyle: { minWidth: 70, whiteSpace: 'nowrap' },
                                align: 'center',
                                kpi: el.kpi_id,
                                RAG: false,
                                hidden: !mapped_headers.includes('POINTS'),
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 10 },
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                            }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            const finalResult1 = response.reduce((result, item, index) => {
                let obj = { ...item, rank: item.position, totalPoints: item.total_points, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'detailed_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-pending_actual`] = el.pending_actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-color`] = el.status_code;
                    obj[`${el.kpi_id}-planned_color`] = el.status_code;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_pending_actual`] = el.planned_pending_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.score || 0;
                    if (index === 0) {
                        const mapped_headers = el.detailed_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headersDub.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-target`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                kpi: el.kpi_id,
                                color_key: 'color',
                                RAG: false,
                                attribute: el.detailed_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('TARGET'),
                                cellStyle: { minWidth: 70 },
                                render: (rowData) =>
                                    (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                        rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                            <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                            : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                                <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                                : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-actual`,
                                short_code: `${el.kpi_code || el.kpi_name}`, //-Actual
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                RAG: el?.indicator_based_on === 'ACTUALS',
                                kpi: el.kpi_id,
                                color_key: 'color',
                                cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('ACTUAL'),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-pending_actual`,
                                short_code: `${el.kpi_code || el.kpi_name}`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                kpi: el.kpi_id,
                                cellStyle: { minWidth: 70 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PENDING_ACTUAL') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('PENDING_ACTUAL'),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-pending_actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-achieved`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                kpi: el.kpi_id,
                                color_key: 'color',
                                RAG: el?.indicator_based_on === 'ACHIEVEMENT',
                                cellStyle: el?.based_on === 'ACHIEVEMENT' ? { minWidth: 75 } : { minWidth: 75 },
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('ACHIEVEMENT'),
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_target`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                RAG: false,
                                kpi: el.kpi_id,
                                color_key: 'planned_color',
                                cellStyle: { minWidth: 70 },
                                attribute: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 10 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                                render: (rowData) =>
                                    (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                        rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                            <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                            : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                                <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                                : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_actual`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                RAG: el?.indicator_based_on === '',
                                kpi: el.kpi_id,
                                color_key: 'planned_color',
                                cellStyle: el?.based_on === 'ACTUALS' ? { minWidth: 70 } : { minWidth: 70 },
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 10 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_pending_actual`,
                                short_code: `${el.kpi_code || el.kpi_name}`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                kpi: el.kpi_id,
                                cellStyle: { minWidth: 70 },
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_PENDING_ACTUAL') || { sequence_no: 10 },
                                hidden: !mapped_headers.includes('PLANNED_PENDING_ACTUAL'),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_pending_actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_pending_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_pending_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_achievement`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                RAG: el?.indicator_based_on === 'PLANNED_ACHIEVEMENT',
                                kpi: el.kpi_id,
                                color_key: 'planned_color',
                                cellStyle: el?.based_on === 'PLANNED_ACHIEVEMENT' ? { minWidth: 75 } : { minWidth: 75 },
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 10 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                            },
                            {
                                title: `${el.kpi_name}`,//-Points
                                field: `${el.kpi_id}-points`,
                                editable: 'never',
                                emptyValue: '--',
                                cellStyle: { minWidth: 70, whiteSpace: 'nowrap' },
                                align: 'center',
                                kpi: el.kpi_id,
                                RAG: false,
                                hidden: !mapped_headers.includes('POINTS'),
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 10 },
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                            }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData([...finalResult])
                setTableData(finalResult);
                setDetailData(finalResult1);
                setDynamicColumnsDet(finalResult1[0].columns || []);
                setDynamicHeadersDet([...headersDub]);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setDynamicColumnsDet([]);
                setDynamicHeadersDet([]);
                setData([]);
                setDetailData([]);
            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
    }


    const SummaryTable = ({ data, checkColumn, handleChange, isAdmin }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns, {
                title: "Points",
                field: "totalPoints",
                editable: 'never',
                templateRender: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>,
                render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
            }]}
            data={data}
            handleChange={handleChange}
            options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
            }}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            <TableCell align="left" style={{ textAlign: 'left' }} rowSpan={2}>{props.columns[1]?.title}</TableCell>
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <>
                                        <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                            <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                        </TableCell>
                                    </>
                                ))
                            }
                            <TableCell rowSpan={2}>Points</TableCell>
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeaders.map((obj) => (
                                    <>
                                        {
                                            obj?.summary_attributes.length ?
                                                <>
                                                    {
                                                        obj?.summary_attributes.map(ele => (
                                                            ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <>
                                                    {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                    {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>}
                                                    {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                </>
                                        }
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    );

    const SummaryTableDet = ({ data, checkColumn, handleChange, isAdmin }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumnsDet, {
                title: "Points",
                field: "totalPoints",
                editable: 'never',
                templateRender: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>,
                render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
            }]}
            data={data}
            checkColumn={checkColumn}
            handleChange={handleChange}
            isAdmin={isAdmin}
            options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
            }}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            <TableCell align="left" style={{ textAlign: 'left' }} rowSpan={2}>{props.columns[1]?.title}</TableCell>
                            {
                                dynamicHeadersDet.map((obj, index) => (
                                    <>
                                        <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                            <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                        </TableCell>
                                    </>
                                ))
                            }
                            <TableCell rowSpan={2}>Points</TableCell>
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeadersDet.map((obj) => (
                                    <>
                                        {
                                            obj?.detailed_attributes.length ?
                                                <>
                                                    {
                                                        obj?.detailed_attributes.map(ele => (
                                                            ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <>
                                                    {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                    {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>}
                                                    {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                </>
                                        }
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    );

    const columns = [
        {
            title: "Rank",
            field: "rank",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            cellStyle: { maxWidth: 200, width: 200, minWidth: 160 },
            templateRender: (rowData) => rowData.player?.name.toUpperCase(),
            render: (rowData) => rowData.player ? (
                <div style={{ fontSize: 12 }} className={classes.nameRoot}>
                    <AvatarComponent data={rowData} from='PLAYER' type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
    ]


    const handleChange = (row, rowIndex, type) => {

    };

    const handleToggleChange = () => {
        setDetailed((prev) => !prev);
    };

    const playerEmailTemplateColumns = columns.filter((obj, i) => filtersData?.season?.season_format === "PLAYER_RACE" ? obj.title !== 'Team' : true)

    const handleGoBack = () => {
        let qryparams = qs.parse(history.location.search);
        delete qryparams['team'];
        history.push({ pathname: '/leaguess/c2/live-scores/team/teams', search: qs.stringify({ ...qryparams }) });
    }

    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
                <h2>{selectDiv?.name}</h2>
            </div>
            <div className={classes.performance}>
                <div className={classes.divisin}>
                    <div className={classes.divisions}>
                        <div className={classes.shareing}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={detailed}
                                        color="primary"
                                        name="Show Detailed Score"
                                        size="small"
                                        label="Show Detailed Score"
                                        onChange={handleToggleChange}
                                        style={{ fontSize: 12 }}
                                    />}
                                label="Show Detailed Score"
                                labelPlacement="start"
                            />
                        </div>
                    </div>
                    <div className="">
                        <div>
                            {
                                (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                                    data.length > 0 ?
                                        <>
                                            <div className=''>
                                                {detailed ?
                                                    <SummaryTableDet
                                                        data={detailData}
                                                        handleChange={handleChange}
                                                    /> :
                                                    <SummaryTable
                                                        data={tableData}
                                                        handleChange={handleChange}
                                                    />
                                                }
                                            </div>
                                        </>
                                        : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getPlayerRaceLiveScore: (options) => dispatch(getPlayerRaceLiveScore(options)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailedTeamPlayerSummary));