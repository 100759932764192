import { Hi } from "stanza/lib/sasl";
import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/notification");

// Saga actions
export const REQUEST_PUSH_NOTIFICATION = constant("REQUEST_PUSH_NOTIFICATION");
export const SHOW_NOTIFICATION = constant("SHOW_NOTIFICATION");
export const SET_DEVICE_TOKEN = constant("SET_DEVICE_TOKEN");
export const DELETE_DEVICE_TOKEN = constant("DELETE_DEVICE_TOKEN");
export const GET_IN_APP_NOTIFICATIONS = constant("GET_IN_APP_NOTIFICATIONS");
export const READ_IN_APP_NOTIFICATION = constant("READ_IN_APP_NOTIFICATION");
export const READ_IN_APP_NOTIFICATION_COUNT = constant("READ_IN_APP_NOTIFICATION_COUNT");
export const DECREASE_IN_APP_NOTIFICATION_COUNT = constant("DECREASE_IN_APP_NOTIFICATION_COUNT");
export const SHARE_LIVE_SCORES = constant("SHARE_LIVE_SCORES");

export const showNotification = (title, body, tag, targetJid) => ({
  type: SHOW_NOTIFICATION,
  payload: { title, body, tag, targetJid }
});

export const setMessagingDeviceToken = (payload) => ({
  type: SET_DEVICE_TOKEN,
  payload
})

export const deleteMessagingDeviceToken = (payload) => ({
  type: DELETE_DEVICE_TOKEN,
  payload
})

export const requestPushNotification = (payload) => ({
  type: REQUEST_PUSH_NOTIFICATION,
  payload
})
export const getInAppNotifications = (payload) => {
  console.log("getappnotifactioncalled", payload)
  return {
    type: GET_IN_APP_NOTIFICATIONS,
    payload
  }
}

export const shareLiveScores = (payload) => ({
  type: SHARE_LIVE_SCORES,
  payload
})


export const readInAppNotification = (payload) => ({
  type: READ_IN_APP_NOTIFICATION,
  payload
})
export const readInAppNotificationsUnreadCount = (payload) => ({
  type: READ_IN_APP_NOTIFICATION_COUNT,
  payload
})
export const decreaseInAppNotificationCount = () => ({
  type: DECREASE_IN_APP_NOTIFICATION_COUNT
})

const initialState = {
  title: '',
  body: '',
  tag: '',
  inAppNotificationUnreadCount: 0
};

// reducer
export default (state = initialState, action) => {
  switch (action.type) {

    case SHOW_NOTIFICATION: {
      console.log('SHOW_NOTIFICATION ', action);
      return {
        ...state,
        title: action.payload.title,
        body: action.payload.body,
        tag: action.payload.tag,
        targetJid: action.payload.targetJid
      };
    }
    case READ_IN_APP_NOTIFICATION_COUNT: {
      return {
        ...state,
        inAppNotificationUnreadCount: action.payload,
      }
    }
    case DECREASE_IN_APP_NOTIFICATION_COUNT: {
      return {
        ...state,
        inAppNotificationUnreadCount: state.inAppNotificationUnreadCount - 1
      }
    }

    default:
      return state;
  }
};
