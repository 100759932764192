import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Avatar,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Moment from "react-moment";
// import { ReactComponent as ReactFavourite } from "../../assets/images/Favourite.svg";
import { readNotification } from "../../ducks/arena";
import { useSnackbar } from "notistack";
import { Link } from 'react-router-dom'
import { readInAppNotification, decreaseInAppNotificationCount } from "../../ducks/notification";
import { convertUnicode, getAvatarText, postCreatedAtFormat } from "../../helpers";
const NotificationStyles = makeStyles((theme) => ({
  avatar: {
    paddingLeft: "1rem",
    paddingTop: "1rem",
  },
  avatarStyles: {
    backgroundColor: theme.palette.primary.main,
  },
  timestamp: {
    margin: "1.5rem 1rem",
    alignSelf: "center",
    fontSize: 14,
    color: "#838383",
    flex: 1,
  },
  both: {
    display: "flex",
    padding: "10px",
    cursor: "pointer"
  },
  content: {
    marginLeft: "1rem",
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  paragraph: {
    fontSize: "12px",
    lineHeight: 1.57,
    color: " #717171",
    wordBreak: 'break-word'
  },
  name: {
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: 1.18,
    marginBottom: "0.3rem",
    color: "#535353",
  },
  icon: {
    marginLeft: "0.5rem",
  },
  link: {
    textDecoration: "none"
  }
}));
moment.updateLocale("en", {
  relativeTime: {
    DD: "%d d",
    dd: "%d d",
    w: "%d w",
    M: "%d mo",
    y: "%d y",
    m: "%d m",
    mm: "%d m",
    H: "%d h",
    HH: "%d,h",
    s: "%d s",
    ss: "%d s",
  },
});
const FavouriteNotifation = ({
  item: { data, body, event_type, created_at, read, photoUrl, _id, sender_name },
  readInAppNotification, decreaseInAppNotificationCount
}) => {
  const bodySplitted = body.split(":");
  // console.log("bodysplitted", bodySplitted)
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const classes = NotificationStyles();
  const handleClick = (id) => {
    readInAppNotification({ notificationId: id, onReadNotificationSuccess, onReadInAppNotificationError });
  };
  const onReadNotificationSuccess = () => {
    if (read === false) {
      decreaseInAppNotificationCount();
    }
  }
  const onReadInAppNotificationError = (error) => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Fetch Read Notification.`, {
      variant: "error",
      preventDuplicate: true
    });
  };
  return (
    data.hasOwnProperty("postId") ? <Link to={`/wall/view/${data.postId}`} className={classes.link}> <div
      className={classes.both}
      style={read === false ? { background: theme.palette.primary.light } : null}
      onClick={() => handleClick(_id)}
    >
      <div className={classes.avatar}>
        <Avatar
          classes={{ colorDefault: classes.avatarStyles }}
          alt={data.creator}
          src={photoUrl}
        >
          {sender_name &&
            getAvatarText(sender_name)}
        </Avatar>
      </div>
      <div className={classes.content}>
        <p className={classes.name}>
          {sender_name}
          {/* <ReactFavourite className={classes.icon} /> */}
        </p>
        <div className={classes.paragraph}>
          {
            event_type === 'new_post' ?
              [data.hasOwnProperty("featured") ? <> {sender_name + " "}
                <strong className={classes.bold}>{bodySplitted[0]}</strong>:
        {convertUnicode(bodySplitted[1] || "")}
              </> :
                <>
                  {sender_name + " "}
                  <strong className={classes.bold}>shared a post</strong>:
        {convertUnicode(bodySplitted[1] || "")}
                </>
              ]
              :
              <>{convertUnicode(body || "")}</>

          }
        </div>
      </div>
      <div>
        <p className={classes.timestamp}>
          <Moment fromNow ago format={postCreatedAtFormat(created_at)}>
            {created_at}
          </Moment>
        </p>
      </div>
    </div>
    </Link> :
      <div
        className={classes.both}
        style={read === false ? { background: theme.palette.primary.light } : null}
        onClick={() => handleClick(_id)}
      >
        <div className={classes.avatar}>
          <Avatar
            classes={{ colorDefault: classes.avatarStyles }}
            alt={data.creator}
            src={photoUrl}
          >
            {sender_name &&
              getAvatarText(sender_name)}
          </Avatar>
        </div>
        <div className={classes.content}>
          <p className={classes.name}>
            {sender_name}
            {/* <ReactFavourite className={classes.icon} /> */}
          </p>
          <div className={classes.paragraph}>
            {data.hasOwnProperty("featured") ? <> {sender_name + " "}
              <strong >{bodySplitted[0]}</strong>:
       {bodySplitted[1]} </> :
              <>
                {sender_name + " "}
                <strong >shared a post </strong>:
       {bodySplitted[1]}
              </>
            }
          </div>
        </div>
        <div>
          <p className={classes.timestamp}>
            <Moment fromNow ago format={postCreatedAtFormat(created_at)}>
              {created_at}
            </Moment>
          </p>
        </div>
      </div>
  );
};
const mapStateToProps = (state) => ({
  unreadCount: state.notification.inAppNotificationCount
})
const mapDispatchToProps = (dispatch) => {
  return {
    readInAppNotification: (option) => dispatch(readInAppNotification(option)),
    decreaseInAppNotificationCount: (option) => dispatch(decreaseInAppNotificationCount(option))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavouriteNotifation);
