import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import LiveMatches from "../LiveMatches";
import OverallOrgStats from "../OverallOrgStats";
import PlayerStandings from "../PlayerStandings";
import MyPerformance from "../MyPerformance";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoading } from "../../../ducks/loading";
import { CustomCard } from "../CustomCard";
import MyTeamPerformance from "../MyTeamPerformance";
import TeamStandings from "../TeamStandings";
import { CustomListGroup } from "../../LiveScores/RoundsListView";
import { getSearchValue } from "../../../helpers";

const MentorDashboard = ({ setLoading, filtersData, rounds, userInfo, natigateToAllStats, natigateToLiveScores, navigateToPlayerLeaderboard, navigateToTeamLeaderboard, navigateToAllMatches, history }) => {

    const [selectedTeamOption, setSelectedTeamOption] = useState(null);
    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        if (rounds.length > 0 || filtersData?.season?._id) {
            const liveRound = rounds.find(obj => obj.round_status === 'LIVE');
            if (liveRound) {
                setMenuOptions([
                    { label: liveRound?.name, value: liveRound?._id, pathname: 'round' },
                    { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' }
                ]);
                setSelectedTeamOption({ label: liveRound?.name, value: liveRound?._id, pathname: 'round' });
            }
            else {
                setMenuOptions([
                    { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season', disabled: true }
                ]);
                setSelectedTeamOption({ label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' });
            }
        }
    }, [rounds, filtersData?.season?._id]);

    const handleTeamOptionChange = (newValue) => {
        setSelectedTeamOption(newValue);
    }

    const isTeamSeasonLevel = selectedTeamOption?.pathname === 'season';

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    {(filtersData?.season?.season_format === 'HEAD_TO_HEAD') &&
                        // <div style={{ marginBottom: 40 }}>
                        <LiveMatches style={{ marginBottom: 40 }} filtersData={filtersData} userInfo={userInfo} navigateToAllMatches={navigateToAllMatches} />
                        // </div>
                    }
                    <CustomCard selected={false} cardTitle={"Overall Org Stats"} linkText="Stats" onLinkClick={natigateToAllStats}>
                        <OverallOrgStats filtersData={filtersData} />
                    </CustomCard>
                </Grid>
                <Grid item md={8}>
                    <Grid container spacing={3}>
                        <Grid item md={7}>
                            {userInfo.myTeams?.length > 0 ? userInfo.myTeams?.map((teamId, index) =>
                                <CustomCard cardTitle={index === 0 && "My Team Performance"}
                                    filterTabs={index === 0 && <CustomListGroup data={menuOptions} selectedItem={selectedTeamOption} onClick={handleTeamOptionChange} style={{ margin: 0, marginTop: -8 }} />}
                                    linkText={index === 0 && "Live Score"} onLinkClick={natigateToLiveScores}>
                                    <MyTeamPerformance teamId={teamId} isSeasonLevel={isTeamSeasonLevel} roundId={!isTeamSeasonLevel && selectedTeamOption?.value} filtersData={filtersData} />
                                </CustomCard>
                            ) :
                                <CustomCard cardTitle="My Team Performance" linkText={null} onLinkClick={() => { }} >
                                    <p style={{ padding: 24, textAlign: 'center' }}>No Data Available</p>
                                </CustomCard>
                            }
                        </Grid>
                        <Grid item md={5}>
                            <CustomCard cardTitle="Player Standings" linkText="Full List" onLinkClick={!isTeamSeasonLevel ? natigateToLiveScores : navigateToPlayerLeaderboard} >
                                <PlayerStandings isSeasonLevel={isTeamSeasonLevel} roundId={!isTeamSeasonLevel && selectedTeamOption?.value} filtersData={filtersData} />
                            </CustomCard>
                            <CustomCard cardTitle="Team Standings" linkText="Full List" onLinkClick={!isTeamSeasonLevel ? natigateToLiveScores : navigateToTeamLeaderboard} style={{ marginTop: 40 }}>
                                <TeamStandings isSeasonLevel={isTeamSeasonLevel} roundId={!isTeamSeasonLevel && selectedTeamOption?.value} filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};


const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),

    };
};



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MentorDashboard));