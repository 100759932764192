import React, { useEffect, useState } from 'react';
// import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import CommonLayout from './Layouts/CommonLayout';
import { connect } from 'react-redux';
import PrimarySearchAppBar from './components/AppBar/AppBar';
import LoadingComponent from './components/Loading';
import ContactList from './components/CommonListSection/ContactList';
import CommonListLayout from './components/CommonListSection/CommonListLayout';
// import loadingGif from './assets/images/eclipse-loading.gif';
import loadingGif from './assets/images/rocket-loading.gif';
import pushNotificationIcon from './assets/images/Chase-Ex.jpg';
import firebase from "firebase";
import SurveyLayout from './components/Survey/SurveyLayout'
import AppNotification from './components/app/AppNotification';
import SearchLayout from './components/SearchSection'
import { setMessagingDeviceToken } from './ducks/notification';
import { firebaseConfig } from './firebase';
import ArenaLayout from './components/Arena';
import { MeetLayout } from './components/ChaseMeet';
// import KanbanBoard from './components/ProductManagementSystem/KanbanBoard';
import { KanbanLayout } from './components/ProductManagementSystem';
import { getOrgDetails } from './ducks/common';
import { orgRealm } from '.';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './components/Theme/Theme';
import Snackbar from './components/Snackbar';
import NetworkComponent from './components/Network';
import { setNetworkStatus } from './ducks/network';
import LiveScores from './components/LiveScores';
import PerformanceStats from './components/PerformanceStats';
import Leaderboards from './components/Leaderboards';
import PointsTables from './components/PointsTables';
import Leagues from './components/Leagues';
import TeamProfile from './components/TeamProfile';
import PlayerProfile from './components/PlayerProfile';
import PwcDashoboard from './components/Pwc/PwcDashboard';
import PwcPerformance from './components/Pwc/PwcPerformance';
import PwcBuzzList from './components/Pwc/PwcBuzz/PwcBuzzList';
import PwcRewards from './components/Pwc/PwcRewards';
import DashboardNew from './components/Leagues/version1/DashboardNew';
import LeaguesIndex from './components/Leagues/LeaguesIndex';
import PlayerPerformance from './components/Profile Performance/PlayerPerformance';
import Buzz from './components/Buzz';

function App({ clientAuthenticated, setDeviceToken, orgDetails, getOrgDetails, setNetworkStatus }) {
  document.title = orgDetails?.name ? `${orgDetails?.name} Leagues platform` : 'Ryzeup Leagues platform'
  useEffect(() => {
    getOrgDetails({ orgCode: orgRealm });

    window.addEventListener('online', () => {
      setNetworkStatus({ offline: false, message: 'Internet Connected' });
    });

    window.addEventListener('offline', () => {
      setNetworkStatus({ offline: true, message: 'No internet' });
      // client.disconnect();
    });

    if (firebase.messaging.isSupported()) {
      // const config = {
      //   apiKey: "AIzaSyC3zYGnabiCOwGNBQBa7Oh4SSXwwJVL5ko",
      //   authDomain: "fir-89094.firebaseapp.com",
      //   databaseURL: "https://fir-89094.firebaseio.com",
      //   projectId: "fir-89094",
      //   storageBucket: "fir-89094.appspot.com",
      //   messagingSenderId: "1064004104953",
      //   appId: "1:1064004104953:web:ab569307c62c9b5cb72828",
      //   measurementId: "G-6X305799Y2"
      // };
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
      }

      const messaging = firebase.messaging();

      messaging.onMessage(payload => {
        let audio = new Audio(payload.data.audio);
        audio.play();
        var notificationTitle = payload.notification.title;
        var notificationOptions = {
          body: payload.notification.body,
          icon: pushNotificationIcon,
          image: payload.data.icon,
          data: {
            url: window.origin
          },
          // click_action: 'http://dev.chase.wavelabs.in/'
          // 
        };
        var notification = new Notification(
          notificationTitle,
          notificationOptions
        );

        notification.onclick = function (event) {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.open(notificationOptions.data.url, '_self');
          // window.focus('/survey');
          // window.open
          // window.location = notificationOptions.data.url;
          notification.close();
        };
      });
    }



    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.usePublicVapidKey(
        "BDcIVqdnE3qZq_4UU6SKH2xddB32qesPOiFdmbBVTcVfkqgnqGEfM-DCndV24S_qgBivAE-8NN3k2xyALSkFrqo"
      );
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then(token => {
          setDeviceToken(token);
        })
        .catch(error => {
          if (error.code === "messaging/permission-blocked") {
          } else {
          }
        });
    }

  }, []);

  const getRoute = (feature) => {
    // case: "value" should be matched to orgDetails modules
    switch (feature) {
      case 'meet':
        return <Route key='meet' path='/meet' render={() => <MeetLayout />} />;
      case 'performance':
        return <Route key='leagues' path='/leagues' render={(props) => <Leagues {...props} />} />

      case 'wall':
        return <Route key='arena' path='/wall' render={(props) => <ArenaLayout {...props} />} />;
      case 'chat':
        return <Route exact key='chats' path='/chats' render={(props) => <CommonListLayout {...props} />} />
      // return <Fragment key='chats-root'>
      //   <Route exact key='chats' path='/chats' render={(props) => <CommonListLayout {...props} />} />
      //   <Route exact key='search' path='/search' render={(props) => <SearchLayout {...props} />} />
      // </Fragment>;
      case 'survey':
        return <Route key='survey' path='/survey' render={(props) => <SurveyLayout {...props} />} />;
      case 'board':
        return <Route key='product-management' path='/product-management' render={(props) => <KanbanLayout {...props} />} />;
      case 'live scores':
        return <Route key='live-scores' path='/arena/live-scores' render={(props) => <LiveScores {...props} />} />;
      case 'performance stats':
        return <Route key='performance-stats' path='/arena/performance-stats' render={(props) => <PerformanceStats {...props} />} />;
      case 'points table':
        return <Route key='points-table' path='/arena/points-table' render={(props) => <PointsTables {...props} />} />;
      case 'leaderboards':
        return <Route key='leaderboard' path='/arena/leaderboard' render={(props) => <Leaderboards {...props} />} />;
      case 'buzz':
        return <Route key='buzz' path='/buzz' render={(props) => <Buzz {...props} />} />;
      default:
        break;
    }
  }

  const getPWCRoute = (feature) => {
    // case: "value" should be matched to orgDetails modules
    switch (feature) {
      case 'dashboard':
        return <Route key='dashboard' path='/dashboard' render={(props) => <PwcDashoboard />} />;
      case 'performance':
        return <Route key='performance' path='/performance' render={(props) => <PwcPerformance />} />;

      case 'wall':
        return <Route key='arena' path='/wall' render={(props) => <ArenaLayout {...props} />} />;
      case 'action':
        return <Route key='product-management' path='/product-management' render={(props) => <KanbanLayout {...props} />} />;
      case 'review':
        return <Route key='review' path='/review' render={(props) => <h1>Review</h1>} />;
      case 'buzz':
        return <Route exact key='chats' path='/chats' render={(props) => <PwcBuzzList {...props} />} />
      case 'survey':
        return <Route key='survey' path='/survey' render={(props) => <SurveyLayout {...props} />} />;
      case 'incentive':
        return <Route key='incentive' path='/incentive' render={(props) => <h1 >Incentive</h1>} />;
      case 'rewards':
        return <Route key='rewards' path='/rewards' render={(props) => <PwcRewards />} />;

      default:
        break;
    }
  }


  if (Object.keys(orgDetails).length > 0 && orgDetails.modules?.length > 0) {
    return (
      <ThemeProvider theme={theme(orgDetails.theme)}>
        <Router>
          <PrimarySearchAppBar />
          <LoadingComponent />
          {/* <Snackbar /> */}
          <NetworkComponent />
          <Route path="/" render={props => {
            return (
              <CommonLayout>
                <Switch>
                  {
                    orgDetails?.theme?.view === 'PWC' ?

                      orgDetails.pwcModules.map(module => getPWCRoute(module.feature))
                      :
                      orgDetails.modules.map(module => getRoute(module.feature))

                  }
                  <Route exact path='/search' component={SearchLayout} />
                  <Route path='/leaguess/c2' component={LeaguesIndex} />
                  {/* <Route path='/performance' component={DashboardNew} /> */}
                  <Route path='/teamProfile/:teamId/seasons/:seasonId' component={TeamProfile} />
                  <Route path='/profile/:id' component={PlayerProfile} />
                  <Route path='/player-performance/:playerId' component={PlayerPerformance} />
                  {orgDetails?.theme?.view === 'PWC' ?

                    <Redirect path='/' to={`/${orgDetails.pwcModules.length > 0 && orgDetails.pwcModules[0].path}`} /> :
                    <Redirect path='/' to={`/${orgDetails.modules.length > 0 && orgDetails.modules[0].path}`} />

                  }
                </Switch>
              </CommonLayout>
            )
          }} />
          <AppNotification />
        </Router>
      </ThemeProvider>
    );
  }
  else {
    return (
      <div style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f1f2f3',
        width: '100vw',
        // fontFamily: 'Roboto'
      }}>
        {/* <h1>Loading.....</h1> */}
        <p><img width={100} src={loadingGif} /></p>
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  clientAuthenticated: state.client.authenticated,
  orgDetails: state.common.orgDetails


});

const mapDispatchToProps = (dispatch) => {
  return {
    setDeviceToken: (options) => dispatch(setMessagingDeviceToken(options)),
    getOrgDetails: (options) => dispatch(getOrgDetails(options)),
    setNetworkStatus: (options) => dispatch(setNetworkStatus(options))

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
