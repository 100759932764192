import { all, select, call, put, take, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { AUTHENTICATED } from "../../ducks/client";
import JwtDecode from "jwt-decode";
import { receivedChats, UPDATE_VIEW_STATUS, GET_CHATS } from "../../ducks/chats";
import { fetchTeams, fetchRecentChats, saveMedia, chatViewStatus } from "../../config";
import { receivedTeams } from "../../ducks/teams";
import { SAVE_MEDIA_FILE } from "../../ducks/common";
// import { joinRoom } from "../../ducks/rooms";
import xml_to_js from 'xml-js';
import { getStanzaMsgDetails } from "../../helpers";

const fetchChats = (username, params) => {
    return Axios.get(`${fetchRecentChats}/${username}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            // Authorization: 'Bearer ' + token
        },
        params: {
            limit: 1000,
            page: 0
        }
        // params
    });
}

const updateChatViewStatus = (targetJid) => {
    return Axios.post(`${chatViewStatus}/${targetJid}`);
}

const saveMediaFiles = (payload) => {
    return Axios.post(saveMedia, payload);
}

const fetchTeamsAPI = () => {
    return Axios.get(fetchTeams, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        params: {
            limit: 100,
            page: 0
        }
    })
}

function* watchForChats(token) {
    // const decoded_token = JwtDecode(token);
    // const username = decoded_token.preferred_username;
    // const { payload } = yield take(AUTHENTICATED);
    const { payload } = yield take('LOGIN');

    // yield takeEvery(GET_CHATS, function* getRecentChats(action) {
    //     const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchChats, payload.decoded_token.preferred_username);
            // const [chatsData, teamsData] = yield all([call(fetchChats, username), call(fetchTeamsAPI)]);

            if (data) {

                const presenceList = yield select((state) => {
                    return state.presence;
                });
                let chatResult = data.reduce((obj, item) => {
                    // let jid = `${item.username}@${process.env.REACT_APP_XMPP_DOMAIN}`
                    const { messageDisplayed, messageReceived, outgoing } = getStanzaMsgDetails(item.stanza, payload.decoded_token.preferred_username);
                    // const messageDisplayed = isStanzaMsgDisplayed(item.stanza);
                    // const messageReceived = isStanzaMsgReceived(item.stanza);
                    // const outgoing = (messageDisplayed || messageReceived) ? getToJidWithStanza(item.stanza).startsWith(username) : getFromJidWithStanza(item.stanza).startsWith(username) ? true : false;

                    console.log('stanza result ', messageDisplayed, messageReceived, outgoing);

                    obj[item.targetJid] = {
                        ...item,
                        isCurrent: false,
                        show: presenceList[item.targetJid] ? presenceList[item.targetJid].show : 'unavailable',
                        name: (item.name === null || item.name === undefined || item.name.length === 0) ? item.targetJid : item.name,
                        type: (item.type !== null && item.type !== 0) ? 'group' : 'individual',
                        messageStatus: messageDisplayed ? 'displayed' : messageReceived ? 'received' : 'sent',
                        outgoing
                    };
                    return obj;
                }, {});

                // let teamsResult = teamsData.data.reduce((obj, item) => {
                //     let jid = `${item.team_id}@chaseservice.${process.env.REACT_APP_XMPP_DOMAIN}`;
                //     // if (chatResult[jid]) {
                //     //     return obj;
                //     // }
                //     obj[jid] = {
                //         ...item,
                //         show: 'unavailable',
                //         targetJid: jid,
                //         type: 'group',
                //         isCurrent: false
                //     };
                //     return obj;
                // }, {});

                // yiel
                yield put(receivedChats(chatResult));
                // yield call(onSuccessCB, data, headers);
                // yield put(receivedTeams(teamsResult));

                // (Object.keys(teamsResult)).forEach((team) => {
                //     window.client.joinRoom(team, decoded_token.name, {
                //         status: 'online',
                //         muc: {
                //             history: {
                //                 maxCharacters: 0,
                //             },
                //         }
                //     });
                // });

                // yield put(joinRoom, jid, decoded_token.name || decoded_token.given_name);
                console.log('watchForChats data success');
            }
        } catch (e) {
            // yield call(onErrorCB, e);
            console.log('watchForChats catch err ', e);
        }
    // });
}

function* watchForSaveMediaFile() {
    yield takeEvery(SAVE_MEDIA_FILE, function* uploadFiles(action) {
        try {
            let { data } = yield call(saveMediaFiles, action.payload);
            if (data) {
                // yield put();
            }
            console.log('watchForSaveMediaFile data success');

        } catch (e) {
            console.log('watchForSaveMediaFile catch err ', e);
        }
    });
}

function* watchForChatViewStatus() {
    yield takeEvery(UPDATE_VIEW_STATUS, function* updateChatStatus(action) {
        try {
            let { data } = yield call(updateChatViewStatus, action.payload);
            if (data) {
                // yield put();
            }
            console.log('watchForChatViewStatus data success');

        } catch (e) {
            console.log('watchForChatViewStatus catch err ', e);
        }
    });
}

export default function* (token) {
    yield all([watchForChats(token), watchForSaveMediaFile(), watchForChatViewStatus()]);
}
