import React, { useEffect, useState } from "react";
import { Button, FormControl, Grid, IconButton, InputBase, TextField, fade, makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import MyPerformance from "./MyPerformance";
import { setLoading } from "../../../ducks/loading";
import { formatDate, getSearchValue } from "../../../helpers";
import { CustomCard } from "../../Leagues/CustomCard";
import { getPlayerKpisTrends } from "../../../ducks/game";
import { useSnackbar } from "notistack";
import PerformanceGraph from "./PwcPeformanceGraph";
import { BarGraph } from "./PwcPeformanceGraph";
import FieldSet from "../../GlobalComponents/FieldSet";
import IllustrationView from "../../LiveScores/IllustrationView";
import DummyGraph from '../../../assets/images/dummy-graph.png';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import { DurationTooltip } from "../../PerformanceStats/DurationTooltip";
import { ChevronLeft, Search, StarBorder } from "@material-ui/icons";
import { DatePicker } from '@material-ui/pickers';
import StarIcon from '@material-ui/icons/Star';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { Autocomplete } from "@material-ui/lab";



const useStyles = makeStyles((theme) => ({
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
        margin: 12
    },
    kpiName: {
        fontWeight: 600,
    },
    table: {
        '& table': {
            overflowX: 'auto',
            width: '100%',
            borderSpacing: '0px',
            // minHeight: '500px',
            '& thead': {
                // backgroundColor: `${theme.palette.primary.main}4A !important`,
                lineHeight: '2.2rem',
                '& th': {
                    padding: '10px !important',

                    border: '1px solid  #BDBCBC !important',
                    borderWidth: '1px 0px 1px 0px!important',
                    fontWeight: 500,
                    fontSize: '13px',
                    color: '#4B4B4B',
                    textAlign: 'start',
                    // lineHeight: '20px',
                    lineHeight: '2.2rem',
                    '&:first-child': {
                        // borderTopLeftRadius: '5px !important',

                    },
                    '&:last-child': {
                        // borderTopRightRadius: '5px !important',

                    }


                },
            },
            '& tbody': {
                lineHeight: '1.2rem !important',
                '& td': {
                    padding: '10px !important',
                    fontSize: '14px !important',
                    borderWidth: '0px 0px 1px 0px!important',
                    color: '#000000;',
                    textAlign: 'start',
                    border: '1px solid  #BDBCBC !important',

                    // border: '0.3px solid #f3eded !important',
                    // lineHeight: '22px !important',
                    // lineHeight: '3.2rem',

                    '&:first-child': {
                        borderWidth: '0px 0px 1px 0px!important',

                    },
                    '&:last-child': {
                        borderWidth: '0px 0px 1px 0px!important',

                    }

                }
            }
        }
    },

    pwcButton: {
        backgroundColor: '#5D5FEF',
        height: '40px',
        width: '190px',
        whiteSpace: 'nowrap',
        color: '#FFFFFF',
        fontWeight: 600,
        '&:hover': {
            color: '#ffff',
            fontWeight: 600,
            backgroundColor: '#5D5FEF',

        }

    },
    buttonDiv: {
        borderRadius: '5px',
        height: '40px',
        width: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #5D5FEF',
        marginTop: '10px',
        marginLeft: 'auto',
        color: '#5D5FEF',
        '&:hover': {
            backgroundColor: '#5D5FEF'
        },
    },
    search: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: 4,
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: fade("#F1F2F5", 0.75),
        },
        // width: 'calc(100% - 795px)',
        width: '350px',
        border: '1px solid #c4c4c4',
        height: '38px'


        // width: "auto",
        // marginRight: theme.spacing(1),
        // marginLeft: theme.spacing(1),
        // maxWidth: 250,
        // minWidth: 175,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#A4A4A4",
    },
    searchInputRoot: {
        color: "inherit",
        width: "100%",
    },
    searchInputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        height: 20,
        fontSize: 15,
        color: '#000000',

    },
    headerbar: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '0.8px solid #BBB5B5',
        borderRadius: '3px',
        marginBottom: '15px',
        '& p': {
            fontWeight: 600,
            margin: '5px',
            fontSize: '14px',
            '& span': {
                fontWeight: 600,
                color: '#779815'
            }
        }
    },
    submitButton: {

        whiteSpace: 'nowrap',
        '& span': {
            color: '#5D5FEF',
            fontWeight: 600,
            '&:hover': {
                color: '#ffff',
                fontWeight: 600
            }
        },
    },
    datePickerStyles: {
        width: '230px',
        height: '38px',
        marginLeft: '5px'
    },
    datePickerInputStyles: {
        paddingLeft: 8,
        fontSize: 15,
        color: '#00000',
        height: 24,
        cursor: 'pointer',
    },
    dateInputUnderine: {
        border: '1px solid #c2c2c2',
        cursor: 'pointer',
        borderRadius: 4,
        width: '100% important',
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:before': {
            border: 'none'
        },
        '&:hover:not(.Mui-disabled):before': {
            border: 'none'
        },
        '& svg': {
            fill: '#A4A4A4',
            margin: '5px'
        }
    },
    autocompleteRoot: {
        width: "200px",
        height: "39px",
        borderRadius: 3,
        overflow: "clip",
        whiteSpace: "nowrap",
        "& .MuiAutocomplete-tagSizeSmall": {
            width: "65px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
    },
    formRoot: {
        marginLeft: "5px",
        '& .MuiFormControl-root': { '& label': { top: '0px' }, backgroundColor: '#ffffff !important' },
        '& .MuiFormLabel-root': { color: '#767676 !important', fontSize: '15px', }
        // width: 200
    },
}))


const defaultFilters = {
    league: null,
    season: null,
    kpi: null,
    role: null,
    player: null,
    trend: null
};

const PlayerKpiTrends = ({ setLoading, filtersData, rounds, userInfo, natigateToAllStats, natigateToLiveScores, navigateToTeamLeaderboard, navigateToPlayerLeaderboard, navigateToAllMatches, history, setFiltersData, getPlayerKpisTrends, userDetails }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        if (filtersData.season && history.location.pathname.split('/')[2]) {
            getKpiPerformanceTrends(filtersData.season._id, userDetails.username, history.location.pathname.split('/')[2])
        }
    }, [filtersData.season, history.location.pathname.split('/')[2]]);


    const getKpiPerformanceTrends = (seasonId, player_id, kpi_id) => {
        setLoading(true);
        getPlayerKpisTrends({
            seasonId: seasonId,
            params: { player_id: player_id, limit: 5000, page: 1, kpi_id: kpi_id }, // kpi_id: kpi._id, kpi_type: kpi.tag, 
            onSuccessCB: (result) => {
                console.log('kpipropsssssss', filtersData.season, history.location.pathname.split('/')[2])
                if (result.length > 0) {
                    const resultData = result.map(obj => ({
                        labels: obj.stats?.map((item) => `${formatDate(item.end_date)}`), // (item, i) => `${item.pre_fix}${i + 1}`
                        targets: obj.stats?.map(item => item.target),
                        actuals: obj.stats?.map(item => item.actual),
                        duration: obj.stats[0]?.duration,
                        durations: obj.stats?.map((item, i) => ({ label: `${item.pre_fix}${i + 1}`, startDate: item.start_date, endDate: item.end_date })),
                        kpiName: obj.kpi_name,
                        kpiCode: obj.kpi_code || obj.kpi_name,
                        kpiType: obj.kpi_type,
                        unitOfMeasurement: obj.unit_of_measurement,
                        unit: obj.unit
                    }))
                    // console.log('finalResult ', resultData);
                    setFiltersData(prevState => ({ ...prevState, trends: resultData }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season, kpi: prevState.kpi, trends: null }));
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season, kpi: prevState.kpi, trends: null }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const handleGoBack = () => {
        history.push(`/performance/${history.location?.search}`);
    };




    const columns1 = {
        "V2 Frequency Coverage": (tableData) => [

            {
                field: "name",
                title: "Doctor Name",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.name}
                    </div>

                )
            },
            {
                field: "jan",
                title: "Jan",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        <StarIcon style={{ color: '#FCC707' }} />
                        <StarIcon style={{ color: '#FCC707' }} />

                    </div>

                )
            },
            {
                field: "feb",
                title: "Feb",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        <StarIcon style={{ color: '#FCC707' }} />
                        <StarIcon style={{ color: '#FCC707' }} />
                    </div>

                )
            },
            {
                field: "march",
                title: "Mar",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        <StarIcon style={{ color: '#FCC707' }} />
                        <StarIcon style={{ color: '#FCC707' }} />
                    </div>

                )
            },
            {
                field: "april",
                title: "Apr",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        <StarIcon style={{ color: '#FCC707' }} />
                        <StarIcon style={{ color: '#FCC707' }} />
                    </div>

                )
            },
            {
                field: "may",
                title: "May",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />

                    </div>

                )
            },
            {
                field: "june",
                title: "Jun",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />


                    </div>

                )
            },





        ],
        'DOH': (tableData) => [

            {
                field: "name",
                title: "Stockist Name",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.name}
                    </div>

                )
            },
            {
                field: "stockToDate",
                title: "Stock To Date",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.stockToDate}
                    </div>

                )
            },
            {
                field: "currentValue",
                title: "Current Value",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.currentValue}
                    </div>

                )
            },
            {
                field: "actionItemStatus",
                title: "Action item Status",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.actionItemStatus}
                    </div>

                )
            },





        ],

        "V1% Coverage": (tableData) => [

            {
                field: "name",
                title: "Doctor Name",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.name}
                    </div>

                )
            },
            {
                field: "speciality",
                title: "Speciality",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.speciality}

                    </div>

                )
            },
            {
                field: "dateOfVisit",
                title: "Date of visit",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.dateOfVisit}

                    </div>

                )
            },
            {
                field: "comments",
                title: "Comments",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) =>
                (
                    <div className={classes.nameRoot}>
                        {tableData.comments}

                    </div>

                )
            },
            {
                field: "last6visits",
                title: "Last 6 visits(as per plan)",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        <StarIcon style={{ color: '#FCC707' }} />
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />
                        <StarBorder style={{ color: 'rgb(153 150 141)' }} />






                    </div>

                )
            },






        ],
        "RCPA": (tableData) => [

            {
                field: "name",
                title: "L1 Employee",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.name}
                    </div>

                )
            },
            {
                field: "apr",
                title: "Apr 2023",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.apr}
                    </div>

                )
            },
            {
                field: "dec",
                title: "Dec 2023",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.dec}

                    </div>

                )
            },
            {
                field: "jun",
                title: "Jun 2023",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.jun}
                    </div>

                )
            },
            {
                field: "mar",
                title: "Mar 2023",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.mar}
                    </div>

                )
            },
            {
                field: "may",
                title: "May 2023",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.may}

                    </div>

                )
            },
            {
                field: "total",
                title: "Total RCPA Value",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.total}

                    </div>

                )
            },





        ],
        'FDN': (tableData) => [

            {
                field: "name",
                title: "Brand Name",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.name}
                    </div>

                )
            },
            {
                field: "totalMonthlySales",
                title: "Total Monthly Sales",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.totalMonthlySales}
                    </div>

                )
            },
            {
                field: "totalFdn",
                title: "Total FDN/PD",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.totalFdn}
                    </div>

                )
            },
            {
                field: "sales",
                title: "% of Sales",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.sales}
                    </div>

                )
            },





        ],
        "JCC": (tableData) => [

            {
                field: "month",
                title: "Month",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.month}
                    </div>

                )
            },
            {
                field: "noOfDoctorCalls",
                title: " Number of Doctor Calls",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.noOfDoctorCalls}
                    </div>

                )
            },
            {
                field: "noOfWorkingDays",
                title: "No. of Filed Working Days",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.noOfWorkingDays}
                    </div>

                )
            },





        ],


        "% Saleable Returns": (tableData) => [

            {
                field: "name",
                title: "Stockist Name",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.name}
                    </div>

                )
            },
            {
                field: "returnsLastMonth",
                title: "Returns Last Month",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.returnsLastMonth}
                    </div>

                )
            },
            {
                field: "nonSaleableReturns",
                title: "Non Saleable returns",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.nonSaleableReturns}
                    </div>

                )
            },
            {
                field: "currentInventory",
                title: "Current Inventory",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {tableData.currentInventory}
                    </div>

                )
            },





        ],






    }


    const data = {
        'DOH': [
            {
                name: "Stocklist 2406",
                stockToDate: "3L",
                currentValue: "91",
                actionItemStatus: "Added to Action Item"
            },
            {
                name: "Stocklist 1782",
                stockToDate: "2L",
                currentValue: "59",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Stocklist 3373",
                stockToDate: "2.3L",
                currentValue: "49",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Stocklist 2406",
                stockToDate: "4L",
                currentValue: "91",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Stocklist 1782",
                stockToDate: "4L",
                currentValue: "59",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Stocklist 3373",
                stockToDate: "2.5L",
                currentValue: "49",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Stocklist 1782",
                stockToDate: "1L",
                currentValue: "49",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Stocklist 3373",
                stockToDate: "1.5L",
                currentValue: "49",
                actionItemStatus: "Added to Action Item"



            },
        ],
        "V2 Frequency Coverage": [
            {
                name: "Doc.7",
                stockToDate: "3L",
                currentValue: "91",
                actionItemStatus: "Added to Action Item"
            },
            {
                name: "Doc.6",
                stockToDate: "2L",
                currentValue: "59",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Doc.5",
                stockToDate: "2.3L",
                currentValue: "49",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Doc.4",
                stockToDate: "4L",
                currentValue: "91",
                actionItemStatus: "Added to Action Item"



            },
            {
                name: "Doc.3",
                stockToDate: "4L",
                currentValue: "59",
                actionItemStatus: "Added to Action Item"



            }
        ],
        "V1% Coverage": [
            {
                name: "Dr.Rajesh Kurumurthi",
                speciality: "Orthopedics",
                dateOfVisit: "20/3/2023",
                comments: "Rajesh kurumurthi was in workshot with some farma edge company",
                last6visits: ""
            },
            {
                name: "Dr. Vishnu Vardhan",
                speciality: "Internal Medicine",
                dateOfVisit: "20/3/2023",
                comments: "",
                last6visits: ""



            },
            {

                name: "Dr. Durga sharma",
                speciality: "Dermatology",
                dateOfVisit: "20/3/2023",
                comments: "Dr. Durga sharma wants to postponed the meeting to the other day",
                last6visits: ""



            },
            {
                name: "Dr. M. Shiva",
                speciality: "Pediatrics",
                dateOfVisit: "20/3/2023",
                comments: "Dr. Durga sharma wants to postponed the meeting to the other day",
                last6visits: ""


            },
            {
                name: "Dr. Austin Mathew",
                speciality: "Radiology",
                dateOfVisit: "20/3/2023",
                comments: "Dr. Austin Mathew was travelling",
                last6visits: ""


            },
            {
                name: "Dr. Divya Immadi",
                speciality: "General Surgery",
                dateOfVisit: "20/3/2023",
                comments: "",
                last6visits: ""


            },
            {
                name: "Dr. Shiva Sree ",
                speciality: "Ophthalmology",
                dateOfVisit: "20/3/2023",
                comments: "",
                last6visits: ""


            },
            {
                name: "Dr. Nishanth Reddy",
                speciality: "Anesthesia",
                dateOfVisit: "20/3/2023",
                comments: " Dr. Nishanth Reddy wants to postponed the meeting to the other day",
                last6visits: ""


            }
        ],
        "RCPA": [
            {
                name: "Deen987",
                apr: "3L",
                dec: "3L",
                jun: "3L",
                mar: "3L",
                may: "3L",
                total: "3L",
            },
            {
                name: "K Ba987",
                apr: "2L",
                dec: "2L",
                jun: "2L",
                mar: "2L",
                may: "2L",
                total: "2L",


            },
            {

                name: "Mari987",
                apr: "2.3L",
                dec: "2.3L",
                jun: "2.3L",
                mar: "2.3L",
                may: "2.3L",
                total: "2.3L",


            },
            {
                name: "Nave987",
                apr: "4L",
                dec: "4L",
                jun: "4L",
                mar: "4L",
                may: "4L",
                total: "4L",


            },
            {
                name: "RAJ987",
                apr: "4L",
                dec: "4L",
                jun: "4L",
                mar: "4L",
                may: "4L",
                total: "4L",


            },


        ],
        "FDN": [
            {
                name: 'OCOR987',
                totalMonthlySales: '1331354',
                totalFdn: '112932 ',
                sales: '8.5%'
            },
            {
                name: '/ XR987',
                totalMonthlySales: '220804',
                totalFdn: '10137',
                sales: '4.6%'
            },
            {
                name: 'PRIL987',
                totalMonthlySales: '48973 ',
                totalFdn: '1961',
                sales: '4.0%'
            },
            {
                name: '+XR]987',
                totalMonthlySales: '255979',
                totalFdn: '9822',
                sales: '3.8%'
            },
            {
                name: '- H987',
                totalMonthlySales: '239153',
                totalFdn: '6219',
                sales: '2.6%'
            },
            {
                name: '- F987',
                totalMonthlySales: ' 57946',
                totalFdn: '4914',
                sales: '8.5%'
            },
            {
                name: '- AM987',
                totalMonthlySales: '297592',
                totalFdn: '10323',
                sales: '3.5%'
            },
            {
                name: ' RTAN987',
                totalMonthlySales: '911155',
                totalFdn: '22803',
                sales: '2.5%'
            },
            {
                name: 'TRIO987',
                totalMonthlySales: '287',
                totalFdn: '-',
                sales: '0.0%'
            },

        ],
        "JCC": [
            {
                month: "April",
                noOfDoctorCalls: "30",
                noOfWorkingDays: "4",
            },
            {
                month: "May",
                noOfDoctorCalls: "74",
                noOfWorkingDays: "8",



            },
            {
                month: "Jun",
                noOfDoctorCalls: "50",
                noOfWorkingDays: "2",



            },
            {
                month: "July",
                noOfDoctorCalls: "80",
                noOfWorkingDays: "10",



            },
            {
                month: "Aug",
                noOfDoctorCalls: "98",
                noOfWorkingDays: "21",




            }
        ],

        "% Saleable Returns": [
            {
                name: "Stockist A",
                returnsLastMonth: "5%",
                nonSaleableReturns: "1%",
                currentInventory: "50 L (105 days)",
                highlight: true,
            },
            {
                name: "Brand X",
                returnsLastMonth: "10%",
                nonSaleableReturns: "2%",
                currentInventory: "30 L (90 days)"


            },
            {
                name: "Brand Y",
                returnsLastMonth: "1%",
                nonSaleableReturns: "3%",
                currentInventory: "20 L (120 days)"


            },
            {
                name: "Stockist B",
                returnsLastMonth: "10%",
                nonSaleableReturns: " -",
                currentInventory: "30 L (90  days)",
                highlight: true,




            },
            {
                name: "Brand X",
                returnsLastMonth: "10%",
                nonSaleableReturns: "2%",
                currentInventory: "20 L (90 days)"


            },
            {
                name: "Brand Y",
                returnsLastMonth: "1%",
                nonSaleableReturns: "3%",
                currentInventory: "15 L (120 days)"


            },
        ],

    }
    const DoctorsList = ({ data, columns, kpiName }) => (
        <div className={classes.table}>
            <table>
                <thead>
                    <tr>

                        {[...columns].map((column, index) => (
                            !column.hidden && <th key={index} align={column.align}>{column.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        (data
                        ).map((row, rowIndex) => (
                            <tr key={row.desc} selected={row.selected || false} style={{ backgroundColor: row?.highlight ? 'rgb(230 230 252)' : '', }}>
                                {console.log('rowwwwwww', row.highlight)
                                }
                                {
                                    [...columns].map((col, i) => (
                                        !col.hidden && <td style={{ ...col?.cellStyle }} key={i} align={col.align}>{col.render ? col.render({ ...row, tableData: { id: rowIndex } }) : row[col.field]}</td>
                                    ))
                                }
                            </tr>
                        ))


                    }
                </tbody>



            </table>
        </div >


    )

    console.log('kpidataaaa', filtersData)
    return (
        <div style={{ margin: '10px' }}>
            {
                (filtersData.season?.season_format !== 'TEAM_RACE' || filtersData.season?.auto_player_sprints) ?
                    <div style={{ margin: '10px 0' }}>
                        {filtersData.trends?.length > 0 ? filtersData.trends.map((trend, i) =>
                            <>
                                <div style={{ display: 'flex' }}>
                                    <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>

                                    <p className={classes.kpiName}>{trend.kpiName}</p>
                                </div>
                                <CustomCard>
                                    <Grid container>
                                        <Grid item md={(trend.kpiName === "% Non Saleable Return" || trend.kpiName ===
                                            "% Saleable Returns") ? 8 : 12}>

                                            <div className={classes.headerbar}>
                                                <p>{trend.kpiName} : {trend.kpiName === 'DOH' || trend.kpiName === 'JCC' || trend.kpiName === 'RCPA' || trend.kpiName === 'FDN' ? 'Monthly Trends' : 'Monthly Statistics'}</p>
                                                <div style={{ display: 'flex' }}>
                                                    <p>YTD Acheievement: <span>108%</span></p>
                                                    <p>YTD Growth :<span>72%</span></p>

                                                </div>
                                            </div>
                                            {
                                                (trend.labels?.length > 0 && history.location.pathname.split('/')[2]) ?

                                                    <PerformanceGraph {...trend} />
                                                    :
                                                    <div className={classes.emptyData}>
                                                        <img src={DummyGraph} alt="graph" />
                                                        <p >** Data yet to be made available **</p>
                                                    </div>
                                            }

                                            {(trend.kpiName === "% Non Saleable Return" || trend.kpiName ===
                                                "% Saleable Returns") ?
                                                <ul style={{ color: '#303180', fontSize: '12px', display: 'flex' }}>
                                                    <li style={{ margin: '10px' }}>SS</li>
                                                    <li style={{ margin: '10px' }}>PS</li>
                                                    <li style={{ margin: '10px' }}>Growth</li>
                                                    <li style={{ margin: '10px' }}>Achievement</li>



                                                </ul>
                                                :
                                                <ul style={{ color: '#303180', fontSize: '12px' }}>
                                                    <li>Coverage Percentage</li>
                                                </ul>
                                            }
                                        </Grid>
                                        {(trend.kpiName === "% Non Saleable Return" || trend.kpiName ===
                                            "% Saleable Returns") &&
                                            <Grid item md={4} style={{ paddingLeft: '10px' }}>
                                                <div className={classes.headerbar} >
                                                    <p>Key Brand</p>

                                                </div>
                                                <BarGraph />
                                                <ul style={{ color: '#303180', fontSize: '14px' }}>
                                                    <li>Achievement</li>
                                                </ul>
                                            </Grid>}
                                    </Grid>

                                    {trend.kpiName === "V2 Frequency Coverage" || trend.kpiName === "V3 Frequency Coverage" ?
                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <h4>Doctor Coverage Status</h4>

                                            </div>
                                            <Button className={classes.pwcButton} variant="outlined">+ Add New Doctor</Button>
                                        </div>
                                        :
                                        (trend.kpiName === "% Non Saleable Return" || trend.kpiName ===
                                            "% Saleable Returns") ?
                                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <h4>Suggested Stocklists & SKU’s to target</h4>

                                                </div>
                                                <Button className={classes.pwcButton} variant="outlined">+ Add Another Stocklist</Button>
                                            </div>
                                            :
                                            (trend.kpiName === "JCC" || trend.kpiName === "FDN") ?
                                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div className={classes.search}>
                                                            <div className={classes.searchIcon}>
                                                                <Search />
                                                            </div>
                                                            <InputBase
                                                                placeholder="Search"
                                                                classes={{
                                                                    root: classes.searchInputRoot,
                                                                    input: classes.searchInputInput,
                                                                }}
                                                                inputProps={{ "aria-label": "search" }}
                                                            // onChange={(e) => { handleKeySearch(e.target.value) }}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                (trend.kpiName === "RCPA") ?
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className={classes.search}>
                                                                <div className={classes.searchIcon}>
                                                                    <Search />
                                                                </div>
                                                                <InputBase
                                                                    placeholder="Search by stockist"
                                                                    classes={{
                                                                        root: classes.searchInputRoot,
                                                                        input: classes.searchInputInput,
                                                                    }}
                                                                    inputProps={{ "aria-label": "search" }}
                                                                // onChange={(e) => { handleKeySearch(e.target.value) }}
                                                                />
                                                            </div>
                                                            <DatePicker
                                                                views={["date"]}
                                                                format={"dd MMM yyyy"}
                                                                placeholder="Search Month"
                                                                variant="inline"
                                                                autoOk
                                                                // disableToolbar={disableToolbar}
                                                                className={classes.datePickerStyles}
                                                                InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <CalendarTodayOutlinedIcon size="small" /> }}
                                                                // minDate={minDate}
                                                                // maxDate={maxDate}
                                                                value={null}
                                                            // onChange={onChange}
                                                            // {...others}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    (trend.kpiName === "V1% Coverage" || trend.kpiName ===

                                                        "V2% Coverage"
                                                        || trend.kpiName === "V3% Coverage") ?
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className={classes.search}>
                                                                    <div className={classes.searchIcon}>
                                                                        <Search />
                                                                    </div>
                                                                    <InputBase
                                                                        placeholder="Search by stockist"
                                                                        classes={{
                                                                            root: classes.searchInputRoot,
                                                                            input: classes.searchInputInput,
                                                                        }}
                                                                        inputProps={{ "aria-label": "search" }}
                                                                    // onChange={(e) => { handleKeySearch(e.target.value) }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <FormControl className={classes.formRoot}>
                                                                        <Autocomplete
                                                                            id="season"
                                                                            size="small"
                                                                            // options={seasons}
                                                                            getOptionLabel={(option) => option.name || ''}
                                                                            // value={selectedSeason ? selectedSeason : filtersData.season}
                                                                            classes={{ inputRoot: classes.autocompleteRoot }}
                                                                            renderInput={(params) => <TextField {...params} label="Select Doctor" variant="outlined" />}
                                                                        // onChange={(...args) => handleChangeFilter(args[1])}
                                                                        />

                                                                    </FormControl>
                                                                </div>
                                                                <DatePicker
                                                                    views={["date"]}
                                                                    format={"dd MMM yyyy"}
                                                                    placeholder="Search date"
                                                                    variant="inline"
                                                                    autoOk
                                                                    // disableToolbar={disableToolbar}
                                                                    className={classes.datePickerStyles}
                                                                    InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <CalendarTodayOutlinedIcon size="small" /> }}
                                                                    // minDate={minDate}
                                                                    // maxDate={maxDate}
                                                                    value={null}
                                                                // onChange={onChange}
                                                                // {...others}
                                                                />
                                                            </div>
                                                            <Button className={classes.pwcButton} variant="outlined">+ Add New Doctor</Button>

                                                        </div>
                                                        :

                                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className={classes.search}>
                                                                    <div className={classes.searchIcon}>
                                                                        <Search />
                                                                    </div>
                                                                    <InputBase
                                                                        placeholder="Search by stockist"
                                                                        classes={{
                                                                            root: classes.searchInputRoot,
                                                                            input: classes.searchInputInput,
                                                                        }}
                                                                        inputProps={{ "aria-label": "search" }}
                                                                    // onChange={(e) => { handleKeySearch(e.target.value) }}
                                                                    />
                                                                </div>
                                                                <DatePicker
                                                                    views={["date"]}
                                                                    format={"dd MMM yyyy"}
                                                                    placeholder="Search Month"
                                                                    variant="inline"
                                                                    autoOk
                                                                    // disableToolbar={disableToolbar}
                                                                    className={classes.datePickerStyles}
                                                                    InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <CalendarTodayOutlinedIcon size="small" /> }}
                                                                    // minDate={minDate}
                                                                    // maxDate={maxDate}
                                                                    value={null}
                                                                // onChange={onChange}
                                                                // {...others}
                                                                />
                                                            </div>
                                                            <Button className={classes.pwcButton} variant="outlined">+ Add Stockist</Button>
                                                        </div>
                                    }
                                    <DoctorsList
                                        columns={(trend.kpiName === "V2 Frequency Coverage" || trend.kpiName === "V3 Frequency Coverage") ? columns1["V2 Frequency Coverage"](data) : (trend.kpiName === "V1% Coverage" || trend.kpiName ===

                                            "V2% Coverage"
                                            || trend.kpiName === "V3% Coverage") ? columns1["V1% Coverage"](data) : (trend.kpiName === "% Non Saleable Return" || trend.kpiName ===
                                                "% Saleable Returns") ? columns1[
                                                    "% Saleable Returns"](data) : columns1[trend.kpiName](data)}
                                        data={(trend.kpiName === "V2 Frequency Coverage" || trend.kpiName === "V3 Frequency Coverage") ? data["V2 Frequency Coverage"] : (trend.kpiName === "V1% Coverage" || trend.kpiName ===

                                            "V2% Coverage"
                                            || trend.kpiName === "V3% Coverage") ? data["V1% Coverage"] : (trend.kpiName === "% Non Saleable Return" || trend.kpiName ===
                                                "% Saleable Returns") ? data[
                                        "% Saleable Returns"] : data[trend.kpiName]}
                                        kpiName={trend.kpiName}
                                    />
                                    {(trend.kpiName === "V2 Frequency Coverage" || trend.kpiName === "V3 Frequency Coverage") &&
                                        <div className={classes.buttonDiv}>
                                            <Button className={classes.submitButton} variant="outlined">Submit</Button>

                                        </div>}

                                </CustomCard>
                            </>
                        )
                            :
                            < IllustrationView loading={false} style={{ marginTop: '50px' }} />

                        }
                    </div>
                    :
                    <div style={{ textAlign: 'center', marginTop: 35 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>Since player performance is not measured as a part of this selected season which is of 'Team race' format, we do not have any player trends to show here</p>
                    </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayerKpisTrends: (options) => dispatch(getPlayerKpisTrends(options))




    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerKpiTrends));
