import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Divider, Drawer, IconButton, Tabs, Tab, SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import ProfileInfo from './ProfileInfo';
import MediaFiles from './MediaFiles';
import Documents from './Documents';
import Links from './Links';
import { ReactComponent as profileOpen } from '../../../assets/icons/profile-collapsible.svg'
import { ReactComponent as profileClose } from '../../../assets/icons/profile-expandable.svg'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    drawer: {
        // width: 310,
        // marginRight: 30,
        // flexShrink: 0,
        // padding: '20px 0'

        position: 'fixed',
        right: 0,
        height: '100%'
    },
    drawerPaperOpen: {
        width: 290,
        position: 'relative',
        transform: 'none !important',
        visibility: 'visible !important',
        border: 'none',
        transition: 'width 0.5s ease-in-out',
        maxHeight: '85vh',
        padding: '20px 0',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`,
        zIndex: 1099,
        // paddingTop: 40
    },
    drawerPaperClose: {
        width: 0,
        position: 'relative',
        transform: 'none !important',
        visibility: 'visible !important',
        border: 'none',
        transition: 'width 0.5s ease-in-out',
        maxHeight: '85vh',
        padding: '20px 0',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    profileButton: {
        position: 'absolute',
        left: '-48px',
        height: 'fit-content',
        marginTop: 8,
        paddingRight: 0,
        paddingLeft: 0,
        zIndex: 10,
        transition: '0.5s ease-in-out',
        '&:hover': {
            backgroundColor: 'inherit',
        },
        [theme.breakpoints.down('xs')]: {
            top: -12,
        }
    },
    svgIcon: {
        width: 48,
        overflow: 'inherit',
        fontSize: '1.5rem',
        '& rect': {
            fill: theme.palette.primary.main,
        }
    },
    profileTabsRoot: {
        minHeight: 'auto',
        margin: '30px 10px 0px'
    },
    profileTabRoot: {
        minWidth: 50,
        minHeight: 'auto',
        fontSize: 12,
        textTransform: 'capitalize',
        fontWeight: 600,
        padding: '4px 0',
        // [theme.breakpoints.down('sm')]: {
        //     padding: '14px 0'
        // },
    },
    tabIndicatorProfile: {
        minWidth: 65,
    },
    buttonOnOverlay: {
        left: -338,
        [theme.breakpoints.down('xs')]: {
            top: -12,
            left: -302
        },
        // right: 310
    }
}));


const ProfileDetails = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(props.fromSearch ? false : true);
    const [profileValue, setProfileValue] = React.useState(0);
    const [drawerOverlay, setDrawerOverlay] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (!open) {
            setProfileValue(0);
        }
    }, [open])

    useEffect(() => {
        if (window.innerWidth < 1100) {
            setOpen(false);
            setDrawerOverlay(true);
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth < 1100) {
                setOpen(false);
                setDrawerOverlay(true);
            }
            else {
                setOpen(true);
                setDrawerOverlay(false);
            }
        });

        return () => {
            window.removeEventListener("resize", () => { });
        }
    }, []);

    const handleChangeProfile = (event, newValue) => {
        setProfileValue(newValue);
    };

    return (
        <div className={classes.root}>
            <IconButton title="Profile" disableRipple={true} disableFocusRipple={true} className={classNames(classes.profileButton, (drawerOverlay && open) && classes.buttonOnOverlay)} onClick={() => handleDrawerOpen()} >
                <SvgIcon className={classes.svgIcon} component={open ? profileOpen : profileClose} />
            </IconButton>
            <Drawer
                className={drawerOverlay && classes.drawer}
                variant="persistent"
                anchor="right"
                classes={{
                    paper: open ? classes.drawerPaperOpen : classes.drawerPaperClose,
                }}
            >
                <Tabs
                    value={profileValue}
                    onChange={handleChangeProfile}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    className={classes.profileTabsRoot}
                    classes={{ indicator: classes.tabIndicatorProfile }}
                >
                    <Tab classes={{ root: classes.profileTabRoot }} label={props.currentChat.type === 'group' ? 'Info' : 'Profile'} />
                    <Tab classes={{ root: classes.profileTabRoot }} label="Media" />
                    <Tab classes={{ root: classes.profileTabRoot }} label="Docs" />
                    <Tab classes={{ root: classes.profileTabRoot }} label="Links" />
                </Tabs>
                <Divider />
                <TabPanel value={profileValue} index={0}>
                    {
                        open &&
                        <ProfileInfo {...props} />
                    }
                </TabPanel>
                <TabPanel value={profileValue} index={1}>
                    <MediaFiles {...props} />
                </TabPanel>
                <TabPanel value={profileValue} index={2}>
                    <Documents {...props} />
                </TabPanel>
                <TabPanel value={profileValue} index={3}>
                    <Links {...props} />
                </TabPanel>
            </Drawer>
        </div>
    )
}

export default ProfileDetails;