import React from "react";

const imgWithClick = { cursor: "pointer" };

const Photo = ({ index, onClick, photo, width = 100, height = 100, margin, direction, top, left }) => {
    const imgStyle = { margin: margin, objectFit: 'contain', backgroundColor: '#7f7f7f17' };
    if (direction === "column") {
        imgStyle.position = "absolute";
        imgStyle.left = left;
        imgStyle.top = top;
    }

    const handleClick = event => {
        // console.log(event, { photo, index });
        onClick(event, { photo, index });
    };

    return (
        <img
            key={index}
            style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
            //   {...photo}
            src={photo.url}
            width={width}
            height={height}
            onClick={onClick ? handleClick : null}
            alt="img"
        />
    );
};

export default Photo;
