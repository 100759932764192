import React, { useState, useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { setLoading } from "../../ducks/loading";
import { searchArenaChaseonsOrPosts } from "../../ducks/search";
import { ChaseonsTemplate, ChaseonsSkeleton } from "../Arena/ReactionsDialog";
import { followUser, unFollowUser } from "../../ducks/favourites";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ffffff",
    // minHeight: "50vh",
    padding: 10
  },
  seeMoreActionRoot: {
    textAlign: 'center',
    margin: '15px 0 30px',
    '& button': {
      color: '#535353',
      borderColor: '#535353',
      maxWidth: 300
    }
  },
  emptyResultText: {
    textAlign: 'center',
    color: '#535353cc',
    fontSize: 15,
    fontWeight: 600,
    margin: 0,
    padding: 40,
    lineHeight: '25px'
  }
}));

const defaultObj = {
  page: 0,
  limit: 5,
  totalCount: 0,
  data: []
};

const Chaseons = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchResult, setSearchResult] = useState(defaultObj);
  const [skeletonLoading, setSkeletonLoading] = useState(false);

  useEffect(() => {
    if (props.search.query) {
      setSearchResult(defaultObj);
      getSearchResults(props.search.query, searchResult.limit, 0)
    }
  }, [props.search.query]);

  const onSearchSuccessCB = (result, headers) => {
    if (parseInt(headers.page) === 0)
      setSearchResult(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: [...result]
      }));
    else
      setSearchResult(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: [...prevState.data, ...result]
      }));
    setSkeletonLoading(false);
  };
  const onSearchErrorCB = error => {
    setSkeletonLoading(false);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Search Results.`, {
      variant: 'error',
      preventDuplicate: true
    });
  };

  const onFollowUserSuccess = result => {
    let searchResultDup = JSON.parse(JSON.stringify(searchResult));
    if (searchResultDup.some(item => item.userName === result.username)) {
      (searchResultDup.find(item => item.userName === result.username)).follower = true
    }
    setSearchResult(searchResultDup)
  };
  const onFollowUserError = error => {
    console.log('onFollowUserError ', error.response);
  };
  const onUnFollowUserSuccess = result => {
    let searchResultDup = JSON.parse(JSON.stringify(searchResult));
    if (searchResultDup.some(item => item.userName === result.username)) {
      (searchResultDup.find(item => item.userName === result.username)).follower = false
    }
    setSearchResult(searchResultDup)
  }
  const onUnFollowUserError = error => {
    console.log('onUnFollowUserError ', error.response);
  }

  const handleSeeMore = () => {
    getSearchResults(props.search.query, searchResult.limit, searchResult.page)
  }

  const getSearchResults = (query, limit, page) => {
    setSkeletonLoading(true);
    props.searchArenaChaseonsOrPosts({
      params: {
        q: query,
        type: "CHASEONS",
        limit, page
      },
      onSearchSuccessCB,
      onSearchErrorCB
    });
  }

  return (
    <div className={classes.root}>
      {searchResult.data.length > 0 ? (
        <>
          {
            searchResult.data.map(({ employees }) => {
              return (
                <ChaseonsTemplate uniqueKey={employees.username}
                  details={{ name: employees.name, about: employees.about, userId: employees.username, photoUrl: employees.photoUrl, isFavourite: employees.follower }}
                  isLoggedInUser={props.user.username === employees.username}
                  onChaseonsClick={() => {
                    props.history.push(`/wall/profile/${employees.username}`);
                  }}
                  className={classes.customListItem}
                  followUser={() => props.followUser({
                    requestObj: { username: employees.username },
                    onFollowUserSuccess,
                    onFollowUserError
                  })}
                  unFollowUser={() => props.unFollowUser({
                    username: employees.username,
                    onUnFollowUserSuccess,
                    onUnFollowUserError
                  })}
                />
              );
            })
          }
        </>
      ) : (
          !skeletonLoading && <p className={classes.emptyResultText}>No result found...</p>
        )}
      {skeletonLoading && [0, 1, 2, 4, 5].map(i => <ChaseonsSkeleton actionButton={true} key={i} />)}
      <div className={classes.seeMoreActionRoot}>
        {
          (searchResult.data.length < searchResult.totalCount) &&
          <Button fullWidth variant="outlined" onClick={() => handleSeeMore()}>See More</Button>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  search: state.search.arena,
  user: state.user.username
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    setLoading: (value) => dispatch(setLoading(value)),
    searchArenaChaseonsOrPosts: value => dispatch(searchArenaChaseonsOrPosts(value)),
    followUser: options => dispatch(followUser(options)),
    unFollowUser: options => dispatch(unFollowUser(options)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chaseons));
