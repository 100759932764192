import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemAvatar, Avatar, Badge, withStyles, SvgIcon, CircularProgress, Backdrop } from '@material-ui/core';
// import { DoneAll } from '@material-ui/icons';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { setCurrentChat } from '../../ducks/chats';
import { statusList, highlightText } from '../../helpers';
import { getChatList } from '../../selectors';
import moment from 'moment';
import TeamIcon from '../../assets/images/team-icon-4.svg';
// import { ReactComponent as TeamIcon } from '../../assets/images/team-icon-4.svg';
import { setCurrentTeam } from '../../ducks/teams';
import DocFormat from '../../assets/images/doc-format.png';
import PdfFormat from '../../assets/images/pdf-format.png';
import ExcelFormat from '../../assets/images/excel.svg';
import LinkIcon from '../../assets/images/link.png'
import ImageIcon from '../../assets/images/image-icon.svg'
import VideoIcon from '../../assets/images/video-icon.svg'
import { ContactTemplate } from '../CommonListSection/ContactList';
import { setGlobalSearchQuery, getGlobalSearchResult } from '../../ducks/search';
import xml_to_js from 'xml-js';
import { getSearchMessages } from '../../ducks/messages';

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);


const contactTemplateStyles = makeStyles((theme) => ({
    listItemContent: {
        width: '100%',
        display: 'flex',
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
        margin: '10px 0px'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '7px',
        fontSize: 13,
        color: '#58595B'
    },
    secondarytext: {
        margin: 0,
        fontSize: '11px',
        color: '#838383',
        textTransform: 'capitalize'
    },
    statusBadge: {
        border: '2px solid #fff',
        height: '14px',
        width: '14px',
        borderRadius: '7px'
    },
    statusColor: {
        backgroundColor: props => props.statusColor,
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    }
}));

const SearchContactTemplate = ({ contactDetails, setCurrentChat, currentChat, history }) => {
    const classes = contactTemplateStyles();
    console.log('contactDetails ', contactDetails);
    const handleClick = (user) => {
        setCurrentChat({ chatId: user.type === 'group' ? user.targetJid : user.chat_id, targetName: user.name, presence: user.show, show: user.show || 'unavailable', photo_url: user.photoUrl, type: user.type });
        history.push('/chats');
    }

    return (
        <ListItem key={contactDetails.chat_id} button selected={currentChat && (currentChat.targetJid === contactDetails.targetJid)} onClick={() => handleClick(contactDetails)} >
            <div className={classes.listItemContent}>
                <div>
                    <p className={classes.primaryText}>{contactDetails.name}</p>
                    <p className={classes.secondarytext}>
                        {contactDetails.type === 'group' ? (contactDetails.description ? (contactDetails.description.length > 50 ? contactDetails.description.substring(0, 50) + '...' : contactDetails.description) : '') : contactDetails.about ? (contactDetails.about.length > 50 ? contactDetails.about.substring(0, 50) + '...' : contactDetails.about) : (contactDetails.show === 'dnd' ? 'Busy' : contactDetails.show)}
                    </p>
                </div>
            </div>
        </ListItem>
    );
}

const templateStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        // fontFamily: 'Roboto'
    },
    listItemContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0px',
        whiteSpace: 'nowrap'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '7px',
        fontSize: 13,
        color: '#58595B'
    },
    secondarytext: {
        margin: 0,
        fontSize: '11px',
        color: '#838383',
        display: 'flex',
        alignItems: 'center'
    },
    timestamp: {
        margin: 0,
        paddingBottom: '5px',
        fontSize: 11,
        color: '#838383'
    },
    unreadCount: {
        margin: 0,
        textAlign: "right",
        paddingRight: '14px'
    },
    unreadCountColor: {
        color: '#fff',
        // backgroundColor: 'orange'
    },
    statusBadge: {
        border: '2px solid #fff',
        height: '14px',
        width: '14px',
        borderRadius: '7px'
    },
    statusColor: {
        backgroundColor: props => props.statusColor,
    },
    readStatusIcon: {
        fontSize: '1rem',
        verticalAlign: 'middle',
        paddingRight: '5px'
    },
    imageBody: {
        marginLeft: 10
    },
    svgIcon: {
        overflow: 'inherit',
        fontSize: '1.7rem',
        '& path': {
            fill: theme.palette.primary.main,
            // color: theme.palette.primary.main,
            // stroke: theme.palette.primary.main,
        }
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    }
}));

const getParsedMsg = (msg) => {
    try {
        let newMsg = JSON.parse(msg.replace(/%%%/gi, ''));
        switch (newMsg.msgType) {
            case 'quote':
                return <><span>{newMsg.body ? (newMsg.body.length > 22 ? <span className="messageBodyClass">{newMsg.body.substring(0, 22) + '...'}</span> : <span className="messageBodyClass">{newMsg.body}</span>) : 'Quoted'}</span></>
            case 'image':
                return <><img src={ImageIcon} alt='image' /><span style={{ marginLeft: 10 }}>{newMsg.body ? (newMsg.body.length > 22 ? <span className="messageBodyClass">{newMsg.body.substring(0, 22) + '...'}</span> : <span className="messageBodyClass">{newMsg.body}</span>) : 'Photo'}</span></>
            case 'video':
                return <><img src={VideoIcon} alt='video' /><span style={{ marginLeft: 10 }}>{newMsg.body ? (newMsg.body.length > 22 ? <span className="messageBodyClass">{newMsg.body.substring(0, 22) + '...'}</span> : <span className="messageBodyClass">{newMsg.body}</span>) : 'Video'}</span></>
            case 'file':
                if (newMsg.fileType === 'pdf')
                    return <><img width="10" src={PdfFormat} alt='image' /><span style={{ marginLeft: 10 }}>{newMsg.body ? (newMsg.body.length > 22 ? <span className="messageBodyClass">{newMsg.body.substring(0, 22) + '...'}</span> : <span className="messageBodyClass">{newMsg.body}</span>) : 'File'}</span></>
                else if (newMsg.fileType === 'doc' || newMsg.fileType === 'docx')
                    return <><img width="10" src={DocFormat} alt='image' /><span style={{ marginLeft: 10 }}>{newMsg.body ? (newMsg.body.length > 22 ? <span className="messageBodyClass">{newMsg.body.substring(0, 22) + '...'}</span> : <span className="messageBodyClass">{newMsg.body}</span>) : 'File'}</span></>
                else if (newMsg.fileType === 'xls' || newMsg.fileType === 'xlsx')
                    return <><img width="12" src={ExcelFormat} alt='image' /><span style={{ marginLeft: 10 }}>{newMsg.body ? (newMsg.body.length > 22 ? <span className="messageBodyClass">{newMsg.body.substring(0, 22) + '...'}</span> : <span className="messageBodyClass">{newMsg.body}</span>) : 'File'}</span></>
            case 'link':
                return <><img width="18" src={LinkIcon} alt='link' /><span style={{ marginLeft: 10 }}>{newMsg.body ? (newMsg.body.length > 22 ? <span className="messageBodyClass">{newMsg.body.substring(0, 22) + '...'}</span> : <span className="messageBodyClass">{newMsg.body}</span>) : 'Link'}</span></>
            case 'survey':
                return <span>{newMsg.title ? (newMsg.title.length > 22 ? newMsg.title.substring(0, 22) + '...' : newMsg.title) : 'Survey'}</span>
            default:
                break;
        }
    }
    catch{
        return "Invalid Message..."
    }
}

const SearchResultMsgTemplate = ({ details, value, handleChat, globalSearchQuery, currentChat }) => {
    const classes = templateStyles();

    const handleListItemClick = (user) => {

        const messageId = (user.stanza && (xml_to_js.xml2js(user.stanza)) && (xml_to_js.xml2js(user.stanza)).elements[0].attributes) && (xml_to_js.xml2js(user.stanza)).elements[0].attributes.id;
        const chatType = (user.stanza && (xml_to_js.xml2js(user.stanza)) && (xml_to_js.xml2js(user.stanza)).elements[0].attributes) && (xml_to_js.xml2js(user.stanza)).elements[0].attributes.type;
        // getSearchMessages({ timestamp: user.sentdate, targetJid: user.targetJid, type: chatType === 'groupchat' ? 'group' : 'individual', params: { step: 'previous' } });
        localStorage.setItem('messageId', messageId);
        localStorage.setItem('globalSearchQuery', globalSearchQuery);
        // setCurrentChat(user);
        handleChat({ ...user, messageId, globalSearchQuery, fromSearchResult: true });
        // setGlobalSearchQuery('');

    }

    let time = new Date(details.sentdate)
    let isToday = time.toDateString() == new Date().toDateString();

    return (
        <ListItem key={value} button selected={currentChat && (currentChat.messageid === details.messageid)} onClick={() => handleListItemClick(details)}>
            <div className={classes.listItemContent}>
                <div>
                    <p className={classes.primaryText}>{details.name && ((details.name.length > 18) ? details.name.substring(0, 18) + '...' : details.name) || details.targetJid}</p>
                    <p className={classNames(classes.secondarytext)}>
                        {/* {value % 2 == 0 && <DoneAll className={classes.readStatusIcon} />} */}
                        {details.body ? (details.body.startsWith('%%%') ? getParsedMsg(details.body) : (details.body.length > 35 ? <span className="messageBodyClass">{details.body.substring(0, 35) + '...'}</span> : <span className="messageBodyClass">{details.body}</span>)) : <p style={{ visibility: "visible", margin: 0 }}>{(details.type === 'group') ? details.description : 'New_Chat'}</p>}
                        {/* {details.body ? (details.body.length > 22 ? details.body.substring(0, 22) + '...' : details.body) : <p style={{ visibility: "visible", margin: 0 }}>{(details.type === 'group') ? details.description : 'New_Chat'}</p>} */}
                    </p>
                </div>
                <div>
                    <p className={classes.timestamp}>{details.sentdate && (isToday ? moment(details.sentdate).format("hh:mm A") : moment(details.sentdate).format("DD-MMM"))}</p>
                    <p className={classes.unreadCount}>
                        {details.body &&
                            <Badge badgeContent={details.count} invisible={details.count < 1} color="primary" classes={{ colorPrimary: classes.unreadCountColor }} max={99}></Badge>
                        }
                    </p>
                </div>
            </div>
        </ListItem>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        // width: '100%'
    },
    listRoot: {
        // width: '100%',
        // maxWidth: '350px',
        // minWidth: '350px',
        // backgroundColor: '#fff',
        // height: '100%',
        //     -webkit-transition: 'width 1s ease-in-out'
        // -moz-transition:width 1s ease-in-out;
        // -o-transition: width 1s ease-in-out;
        // transition: 'width 0.5s ease-in-out',
        // [theme.breakpoints.down('sm')]: {
        //     width: '0px',
        // },
    },
    scroller: {
        maxHeight: '80vh',
        minHeight: '80vh'
    },
    chatRoot: {
        width: '100%',
        backgroundColor: '#F3F2F1'
    },


    // Chat Template
    listItemContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '8px 0px',
        whiteSpace: 'nowrap'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '4px',
    },
    secondarytext: {
        margin: 0,
        fontSize: '12px',
    },
    timestamp: {
        margin: 0,
        paddingBottom: '5px',
        fontSize: '13px',
    },
    statusBadge: {
        border: '2px solid #fff',
        height: '14px',
        width: '14px',
        borderRadius: '7px'
    },


    unreadCount: {
        margin: 0,
        textAlign: "right",
        paddingRight: '14px'
    },
    unreadCountColor: {
        color: '#fff',
        // backgroundColor: 'orange'
    },
    readStatusIcon: {
        fontSize: '1rem',
        verticalAlign: 'middle',
        paddingRight: '5px'
    },
    teamsTitle: {
        margin: 0,
        color: '#444444',
        backgroundColor: '#F3F2F1',
        padding: '0 20px'
    },
    noResults: {
        marginTop: 30,
        textAlign: 'center'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    searchResultText: {
        fontSize: 20,
        color: '#58595b',
        textAlign: 'center',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1.5px solid #eeeeee',
        zIndex: 1,
        // [theme.breakpoints.down('sm')]: {
        //     border: 'none',
        //     visibility: 'hidden'
        // },
    }
}));

const SearchResultList = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    console.log('SearchResultList props');

    useEffect(() => {

        if (props.chats && props.chats.some(survey => survey.isCurrent)) {
            const isCurrent = props.chats.find(survey => survey.isCurrent);
            if (isCurrent) props.handleChat(isCurrent);
        }
    }, [props.chats]);

    useEffect(() => {

        if (props.globalSearchQuery && props.globalSearchQuery.length) {
            highlightText(props.globalSearchQuery, 'messageBodyClass');
        }
    });

    useEffect(() => {
        // const urlParams = new URLSearchParams(props.location.search);
        // console.log('SearchResultList ', urlParams, urlParams.has('q'), urlParams.get('q'), props);
        // if (urlParams.has('q') && urlParams.get('q').length > 0 && props.globalSearchResult.length === 0) {
        //     setLoading(true);
        //     props.getGlobalSearchResult({ query: urlParams.get('q'), onSearchSuccessCB, onSearchErrorCB });
        // }
        return () => {
            props.setGlobalSearchQuery('');
        }
    }, []);

    const onSearchSuccessCB = (result) => {
        setLoading(false);
    }

    const onSearchErrorCB = (error) => {
        setLoading(false);
    }

    return (
        <div className={classes.root}>
            <div className={classes.listRoot}>
                <div className={classes.searchResultText}>
                    <SearchIcon /><p style={{ marginLeft: 10 }}>Search Results</p>
                </div>
                <div className={classNames("scroller", classes.scroller)}>

                    {
                        (props.globalSearchQuery.length > 0 && props.globalSearchResult.length > 0) ?
                            [
                                <List dense>
                                    {
                                        props.globalSearchResult.map((result, index) => {
                                            return (
                                                <>
                                                    {
                                                        result.index === 'messages' ?
                                                            <SearchResultMsgTemplate currentChat={props.currentChat} fromGlobalSearch={true} globalSearchQuery={props.globalSearchQuery} key={index} details={result} value={index} {...props} getSearchMessages={props.getSearchMessages} />
                                                            :
                                                            (result.index === 'employees' || result.index === 'teams') ?
                                                                <SearchContactTemplate currentChat={props.currentChat} fromGlobalSearch={true} globalSearchQuery={props.globalSearchQuery} key={index} contactDetails={result} {...props} setCurrentChat={props.setCurrentChat} />
                                                                :
                                                                null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </List>
                            ]
                            :
                            <div className={classes.noResults}>
                                <p>No results found</p>
                            </div>
                    }
                </div>
            </div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

const mapStateToProps = (state) => ({
    globalSearchResult: state.search.global,
    globalSearchQuery: state.search.searchQuery
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        getSearchMessages: (options) => dispatch(getSearchMessages(options)),
        getGlobalSearchResult: (options) => dispatch(getGlobalSearchResult(options)),
        setGlobalSearchQuery: (options) => dispatch(setGlobalSearchQuery(options)),
        setCurrentChat: (options) => dispatch(setCurrentChat(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultList);