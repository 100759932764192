import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
// import reduxLogger from "redux-logger";
import { select } from "redux-saga/effects";

import rootReducer from "../reducers";
import sagas from "../sagas";
import axios from 'axios';

// import customLogger from "./customLogger";

export default function configureStore(initialState = {}, token, userName) {

  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  const storeEnhancers = [];

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...storeEnhancers)
  );

  // axios.interceptors.response.use(onResponseSuccess, onResponseError);
  sagaMiddleware.run(sagas, token, userName);
  return store;
}


const onResponseSuccess = response => {
  console.log('onResponseSuccess ', response)
  return response;
};

const onResponseError = err => {
  console.log('onResponseError interceptors ', err.response);
  const status = err.status || err.response.status;
  if (status === 403 || status === 401) {
    alert('no auth');
  }
  if (status >= 500 || status === 400) {
    console.error('[axios-global]invalid request');
  }
  return window.Promise.reject(err);
}

// function* onResponseError(err) {
//   const status = err.status || err.response.status;
//   if (status === 403 || status === 401) {
//     let keycloak = yield select((state) => state.keycloak);
//     keycloak.logout();
//     // alert('no auth');
//     console.log('Un Authorized');
//   }

//   if (status >= 500 || status === 400) {
//     console.error('[axios-global]invalid request');
//   }
//   return window.Promise.reject(err);
// };
