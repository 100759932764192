import { Tab, Tabs, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { setLoading } from '../../ducks/loading';
import { getSeasonRules } from '../../ducks/game';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import gameIllustration from '../../assets/images/game_Illustration.png'


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fafafa',
        paddingBottom: '20px'
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '4px',
        marginTop: 10,
        minHeight: '255px',

    },
    rankingContainer: {
        backgroundColor: '#EBEBEB',
        height: '20px',
        padding: '7px 8px 14px 12px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',

    },
    tabIndicator: {
        height: '3px',
    },
    tabsFixed: {
        maxWidth: 1300
    },
    tabsRoot: {
        minWidth: 280,
        paddingBottom: 0,
        borderBottom: '1px solid #0000003a',
        // marginBottom: '10px'
    },
    tabRoot: {
        padding: '0 20px',
        textTransform: 'capitalize',
        fontSize: 15,
        fontWeight: 500,
        minWidth: 'auto',
        color: '#838383',
    },
    scoringContainer: {
        backgroundColor: '#EBEBEB',
        height: '20px',
        padding: '7px 8px 14px 12px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between'

    },
    scoringTab: {
        background: '#F6F6F6',
        borderRadius: '4px',
        margin: '16px 24px 16px 24px',
        width: '-webkit-fill-available',

    },
    basedon: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14,
            textTransform: 'capitalize'
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    ruleDetails: {
        padding: '10px',
        minHeight: '75px',
        maxHeight: '135px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },



    },
    colorIndicator: {
        backgroundColor: '#D9D9D9',
        height: '18px',
        width: '18px',
        borderRadius: '17px',
        marginLeft: 5
    },
    scoringCondition: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            fontSize: '15px',
            marginBottom: '5px',
        }
    },
    noData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

}));

export const slabTypes = [{ name: 'Between', id: 'BETWEEN' }, { name: 'Above', id: 'ABOVE' }]

export const scoreAllocatesSr1 = [{ name: 'Team meeting scoring condition', id: 'WINNER' }, { name: 'Any team falling in the slab', id: 'BOTH' }];
export const scoreAllocatesSr2Sr3 = [{ name: 'Team/s meeting the scoring condition', id: 'WINNER' }, { name: 'Any team falling in the slab', id: 'BOTH' }];
export const matchScoringConditions = [{ name: "Greater than or equal to opponent’s achievement %", id: "SR1" }, { name: "Greater than or equal to Target", id: "SR2" }, { name: "Less than or equal to target", id: "SR3" }, { name: "Slabwise", id: "SR4" }, { name: "Pro-rata basis", id: "SR5" }, { name: "Slabwise Pro-rata basis", id: "SR6" }];
export const bonusScoringConditions = [{ name: "Slabwise", id: "TBSR1" }, { name: "Pro-rata basis", id: "TBSR2" }, { name: "Base point against target", id: "TBSR3" }, { name: "Slabwise Pro-rata basis", id: "TBSR4" }];
const SeasonRulesList = ({ filtersData, seasonDetails, getSeasonRules, subTab }) => {
    const classes = useStyles();
    const [seasonKpisList, setSeasonKpisList] = useState([]);
    const [viewAddtionalScores, setViewAddtionalScores] = useState('NO');
    const { enqueueSnackbar } = useSnackbar();
    const rulesTabs = [{ label: `Team ${seasonDetails?.labels_config?.kpis?.core}`, name: 'TEAM_MATCH', id: 0 }, { label: ` Team ${seasonDetails?.labels_config?.kpis?.bonus}`, name: 'TEAM_BONUS', id: 1 }, { label: `Player ${seasonDetails?.labels_config?.kpis?.core}`, name: 'PLAYER_MATCH', id: 2 }, { label: `Player ${seasonDetails?.labels_config?.kpis?.bonus}`, name: 'PLAYER_BONUS', id: 3 }]



    useEffect(() => {
        if (filtersData?.season?._id && subTab === 1) {
            getSeasonRules({ seasonId: filtersData?.season?._id, params: { page: 1, limit: 5000 }, onSuccessSeasonRulesCB, onErrorSeasonRulesCB });
        }
    }, [filtersData?.season?._id]);

    const onSuccessSeasonRulesCB = (result, headers) => {
        setLoading(true);
        if (result.length > 0 && headers) {
            setLoading(false);
            const CopyOfKpisList = JSON.parse(JSON.stringify(result))
            let Response = CopyOfKpisList.map((kpi, index) => {
                let obj = {
                    name: kpi.kpi_name,
                    unit_of_measurement: kpi.unit_of_measurement,
                    tags: kpi.tags?.map(ele => ele),
                    tabValue: 0,
                    kpi_id: kpi?.kpi_id


                }
                if (kpi.tags?.some(ele => ele === 'TEAM_MATCH') && kpi?.team_match_rule) {
                    let teamMatchObj = { basedOn: kpi.team_match_rule.based_on };
                    if (seasonDetails.seasonFormat?.id === 'HEAD_TO_HEAD' && (kpi.team_match_rule.condition !== 'SR4' && kpi.team_match_rule.condition !== 'SR6')) {
                        teamMatchObj.score = kpi.team_match_rule.score;
                        teamMatchObj.minimumScore = kpi.team_match_rule.min_eligibility;
                        teamMatchObj.multiplicationFactor = kpi.team_match_rule.multiplication_factor;
                        teamMatchObj.additionalScores = kpi.team_match_rule?.additional_score_config?.map(ele => {
                            ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' }
                            ele.allocate_to = kpi.team_match_rule.condition?.id === 'SR1' ? scoreAllocatesSr1.find(s => s.id === ele.allocate_to) : scoreAllocatesSr2Sr3.find(s => s.id === ele.allocate_to);
                            return ele
                        });
                        teamMatchObj.condition = matchScoringConditions.find(mc => mc.id === kpi.team_match_rule.condition)
                        obj['team_match_rule'] = teamMatchObj

                    }
                    else if (seasonDetails.seasonFormat?.id === 'HEAD_TO_HEAD' && (kpi.team_match_rule.condition === 'SR4' || kpi.team_match_rule.condition === 'SR6')) {
                        teamMatchObj.slabs = kpi.team_match_rule?.slabs?.map(ele => {
                            ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                            return ele
                        });
                        teamMatchObj.condition = matchScoringConditions.find(mc => mc.id === kpi.team_match_rule.condition)
                        teamMatchObj.minimumScore = kpi.team_match_rule.min_eligibility;
                        teamMatchObj.penaltySlabs = kpi.team_match_rule?.penalty_slabs?.map(ele => {
                            ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                            return ele
                        });

                        obj['team_match_rule'] = teamMatchObj
                    }
                    else {
                        teamMatchObj.slabs = kpi.team_match_rule?.slabs?.map(ele => {
                            ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                            return ele
                        });
                        teamMatchObj.points = kpi.team_match_rule.base_points;
                        teamMatchObj.minimumScore = kpi.team_match_rule.min_eligibility;
                        teamMatchObj.multiplicationFactor = kpi.team_match_rule.multiplication_factor;
                        teamMatchObj.condition = bonusScoringConditions.find(mc => mc.id === kpi.team_match_rule.condition);
                        teamMatchObj.penaltySlabs = kpi.team_match_rule?.penalty_slabs?.map(ele => {
                            ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                            return ele
                        });

                        obj['team_race_rule'] = teamMatchObj

                    }
                    if (teamMatchObj?.additionalScores?.length > 0) setViewAddtionalScores('YES')

                }
                if (kpi.tags?.some(ele => ele === 'TEAM_BONUS') && kpi.team_bonus_rule) {
                    let bonusMatchObj = {
                        basedOn: kpi.team_bonus_rule.based_on,
                        points: kpi.team_bonus_rule.base_points,
                        minimumScore: kpi.team_bonus_rule.min_eligibility,
                        multiplicationFactor: kpi.team_bonus_rule.multiplication_factor,
                        condition: bonusScoringConditions.find(bc => bc.id === kpi.team_bonus_rule.condition)
                    }
                    bonusMatchObj.slabs = kpi.team_bonus_rule?.slabs?.map(ele => {
                        ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                        return ele;
                    });
                    bonusMatchObj.penaltySlabs = kpi.team_bonus_rule?.penalty_slabs?.map(ele => {
                        ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                        return ele
                    });
                    obj['team_bonus_rule'] = bonusMatchObj

                }
                if (kpi.tags?.some(ele => ele === 'PLAYER_MATCH') && kpi.player_match_rule) {
                    let bonusMatchObj = {
                        condition: bonusScoringConditions.find(bc => bc.id === kpi.player_match_rule.condition),
                        basedOn: kpi.player_match_rule.based_on,
                        default_role: kpi.player_match_rule.default_role,
                        rules: (kpi.player_match_rule.condition !== 'TBSR1' && kpi.player_match_rule.condition !== 'TBSR4') ? kpi.player_match_rule.rules.map(rule => ({
                            ...rule
                        })) :
                            kpi.player_match_rule.rules?.map(slabRule => ({
                                ...slabRule, minEligibility: obj.min_eligibility, slabs: slabRule.slabs?.map(slab => ({ ...slab, type: slab.type ? slabTypes.find(s => s.id === slab.type) : { name: 'Between', id: 'BETWEEN' } })), penaltySlabs: slabRule.penalty_slabs?.map(slab => ({ ...slab, type: slab.type ? slabTypes.find(s => s.id === slab.type) : { name: 'Between', id: 'BETWEEN' } })),

                            }))
                    }
                    obj['player_match_rule'] = bonusMatchObj
                }
                if (kpi.tags?.some(ele => ele === 'PLAYER_BONUS') && kpi.player_bonus_rule) {
                    let bonusMatchObj = {
                        condition: bonusScoringConditions.find(bc => bc.id === kpi.player_bonus_rule.condition),
                        basedOn: kpi.player_bonus_rule.based_on,
                        default_role: kpi.player_bonus_rule.default_role,
                        rules: (kpi.player_bonus_rule.condition !== 'TBSR1' && kpi.player_bonus_rule.condition !== 'TBSR4') ? kpi.player_bonus_rule.rules :
                            kpi.player_bonus_rule.rules?.map(obj => ({ ...obj, minEligibility: obj.min_eligibility, slabs: obj.slabs?.map(slab => ({ ...slab, type: slab.type ? slabTypes.find(s => s.id === slab.type) : { name: 'Between', id: 'BETWEEN' } })), penaltySlabs: obj.penalty_slabs?.map(slab => ({ ...slab, type: slab.type ? slabTypes.find(s => s.id === slab.type) : { name: 'Between', id: 'BETWEEN' } })) }))
                    }
                    obj['player_bonus_rule'] = bonusMatchObj
                }
                return obj;
            })
            setSeasonKpisList([...Response])
        }
        else {
            setLoading(false);
            setSeasonKpisList([])

        }
    }

    const onErrorSeasonRulesCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rewards List', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleChangeTab = (event, newValue, kpi) => {
        const CopyOfKpisList = JSON.parse(JSON.stringify(seasonKpisList))
        const selectedKpi = CopyOfKpisList.find(item => item.kpi_id === kpi.kpi_id)
        selectedKpi['tabValue'] = newValue
        setSeasonKpisList([...CopyOfKpisList])
    };


    const KpiRuleTab = ({ data, kpi, type }) => {
        return (
            <div style={{ display: 'flex' }}>
                {
                    (data?.condition?.id === 'TBSR1' || data?.condition?.id === 'TBSR4') ?
                        <>

                            <div className={classes.scoringTab}>
                                <div
                                    className={classes.scoringContainer}
                                >
                                    <span>Scoring Condition</span>
                                    <div className={classes.basedon}>
                                        <button>
                                            <Typography className="seasnHdng" title={"Based On"}>
                                                {`${data?.basedOn.toLowerCase()} ${data?.basedOn === 'ACHIEVEMENT' ? '%' : ''}`}
                                            </Typography>
                                        </button>
                                    </div>
                                </div>
                                {((data?.rules) ? ((data?.rules.length > 0) ? data?.rules[0]?.slabs : []) : data?.slabs).length > 0 ?
                                    <div className={classes.ruleDetails}>
                                        {((data?.rules) ? ((data?.rules.length > 0) ? data?.rules[0]?.slabs : []) : data?.slabs).map((slab) => (
                                            slab?.type?.id === 'ABOVE' ? <div className={classes.scoringCondition}>
                                                <span>{`${slab?.type?.name} ${data.basedOn === 'ACHIEVEMENT' ? slab.min + '%' : slab.min} = ${data?.condition?.id === 'TBSR4' ? `* ${slab.multiplication_factor}` : slab?.points}`} {data?.condition?.id === 'TBSR4' ? 'Multiplication Factor' : 'Points'}</span>
                                            </div>
                                                :
                                                <div className={classes.scoringCondition}>
                                                    <span>{`${slab?.type?.name} ${data.basedOn === 'ACHIEVEMENT' ? slab.min + '%' : slab.min} to ${data.basedOn === 'ACHIEVEMENT' ? slab.max + '%' : slab.max} = ${data?.condition?.id === 'TBSR4' ? `* ${slab.multiplication_factor}` : slab?.points}`} {data.condition?.id === 'TBSR4' ? 'Multiplication Factor' : 'Points'}</span>
                                                </div>

                                        ))}
                                    </div>
                                    :
                                    <div className={classes.noData}>
                                        <p>No Data Available</p>

                                    </div>

                                }

                            </div>
                            {(
                                (data?.rules) ? ((data?.rules.length > 0) ? data?.rules[0]?.penaltySlabs : []) : data?.penaltySlabs).length > 0 &&
                                <div className={classes.scoringTab}>
                                    <div
                                        className={classes.scoringContainer}
                                    >
                                        <span>Penalty Condition</span>
                                        <div className={classes.basedon}>
                                            <button>
                                                <Typography className="seasnHdng" title={"basedOn"}>
                                                    {`${data.basedOn.toLowerCase()} ${data.basedOn === 'ACHIEVEMENT' ? '%' : ''}`}
                                                </Typography>
                                            </button>
                                        </div>
                                    </div>
                                    {((data?.rules) ? ((data?.rules.length > 0) ? data?.rules[0]?.penaltySlabs : []) : data?.penaltySlabs).length > 0 &&
                                        <div className={classes.ruleDetails}>
                                            {((data?.rules) ? ((data?.rules.length > 0) ? data?.rules[0]?.penaltySlabs : []) : data?.penaltySlabs).map((slab) => (
                                                slab?.type?.id === 'ABOVE' ? <div className={classes.scoringCondition}>
                                                    <span>{`${slab?.type?.name} ${data.basedOn === 'ACHIEVEMENT' ? slab.min + '%' : slab.min} = `} * {slab?.multiplication_factor} Multiplication Factor</span>
                                                </div>
                                                    :
                                                    <div className={classes.scoringCondition}>
                                                        <span>{`${slab?.type?.name} ${data.basedOn === 'ACHIEVEMENT' ? slab.min + '%' : slab.min} to ${data.basedOn === 'ACHIEVEMENT' ? slab.max + '%' : slab.max} =`} * {slab?.multiplication_factor}  Multiplication Factor</span>
                                                    </div>

                                            ))}
                                        </div>}

                                </div>}

                        </>
                        :
                        data?.condition?.id === 'TBSR2' ?
                            <div className={classes.scoringTab}>
                                <div
                                    className={classes.scoringContainer}
                                >
                                    <span>Scoring Condition</span>
                                    <div className={classes.basedon}>
                                        <button>
                                            <Typography className="seasnHdng" title={"basedOn"}>
                                                {`${data.basedOn.toLowerCase()} ${data.basedOn === 'ACHIEVEMENT' ? '%' : ''}`}
                                            </Typography>
                                        </button>
                                    </div>
                                </div>
                                {
                                    ((data?.rules) && (data?.rules[0]?.min_eligibility && data?.rules[0]?.multiplication_factor)) ?
                                        <div className={classes.ruleDetails}>
                                            <div className={classes.scoringCondition} style={{ display: 'grid' }}>
                                                <span>{`Minimum Eligibility is ${data.basedOn === 'ACHIEVEMENT' ? data?.rules[0]?.min_eligibility + '% Achievement' : data?.rules[0].min_eligibility}`}</span>
                                                <span>{`Greater than or equal to ${data.basedOn === 'ACHIEVEMENT' ? data?.rules[0]?.min_eligibility + '%' : data?.rules[0].min_eligibility}  ${data.basedOn === 'ACHIEVEMENT' ? 'Achievement = Ach%' : '= Actual '} *${data?.rules[0]?.multiplication_factor}`}</span>
                                            </div>
                                        </div>
                                        :
                                        (data?.minimumScore && data?.multiplicationFactor) ?
                                            <div className={classes.ruleDetails}>
                                                <div className={classes.scoringCondition} style={{ display: 'grid' }}>
                                                    <span>{`Minimum Eligibility is ${data.basedOn === 'ACHIEVEMENT' ? data?.minimumScore + '% Achievement' : data?.minimumScore}`}</span>
                                                    <span>{`Greater than or equal to ${data.basedOn === 'ACHIEVEMENT' ? data?.minimumScore + '%' : data?.minimumScore}  ${data.basedOn === 'ACHIEVEMENT' ? 'Achievement = Ach%' : '= Actual '} *${data?.multiplicationFactor}`}</span>
                                                </div>
                                            </div>

                                            :
                                            <div className={classes.noData}>
                                                <p>No Data Available</p>

                                            </div>

                                }

                            </div>
                            :
                            (data?.condition?.id === 'TBSR3') ?

                                <div className={classes.scoringTab}>
                                    <div
                                        className={classes.scoringContainer}
                                    >
                                        <span>Scoring Condition</span>
                                    </div>
                                    {
                                        ((data?.rules) && (data?.rules[0]?.base_points)) ?
                                            <div className={classes.ruleDetails}>
                                                <div className={classes.scoringCondition}>
                                                    <span> Points{` =  ${data?.rules[0].base_points} `} </span>
                                                </div>
                                            </div>
                                            :
                                            (data?.points && data?.points) ?
                                                <div className={classes.ruleDetails}>

                                                    < div className={classes.scoringCondition}>
                                                        <span> Points {` =  ${data?.points} `}</span>
                                                    </div>
                                                </div>

                                                :
                                                <div className={classes.noData}>
                                                    <p>No Data Available</p>

                                                </div>

                                    }

                                </div>
                                :


                                <div className={classes.noData}>
                                    <p>No Data Available</p>

                                </div>
                }
            </div >
        )

    }

    return (
        <div className={classes.root}>
            {
                (seasonKpisList.filter((kpi) => kpi?.tags.length === 0)).length === seasonKpisList.length ?
                    <div style={{ textAlign: 'center', marginTop: 60 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>No Data Available</p>
                    </div> :
                    seasonKpisList.map((kpi, index) => (
                        <div className={classes.performance} key={index}>
                            <div
                                className={classes.rankingContainer}
                            >
                                <span>{kpi.name}</span>
                            </div>
                            <Tabs
                                value={kpi.tabValue}
                                onChange={(event, newValue) => handleChangeTab(event, newValue, kpi)}
                                variant="fullWidth"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="icon label tabs example"
                                className={classes.tabsRoot}
                                classes={{ indicator: classes.tabIndicator, fixed: classes.tabsFixed }}
                            >
                                {
                                    rulesTabs.map(tab => {
                                        if ((kpi.tags && kpi.tags.includes(tab.name))) {
                                            return (
                                                <Tab className={classes.tabRoot} classes={{ wrapper: classes.tabWrapper, labelIcon: classes.labelIcon }} label={tab.label} />
                                            )
                                        }
                                    })
                                }
                            </Tabs>
                            {
                                (((kpi.tags) && (kpi.tags.includes('TEAM_MATCH') && kpi.tabValue === kpi.tags.indexOf('TEAM_MATCH'))) && seasonDetails?.season_format === 'HEAD_TO_HEAD') &&
                                <KpiRuleTab
                                    data={kpi.team_match_rule}
                                    kpi={kpi}
                                    type='TEAM_MATCH'
                                />
                            }
                            {
                                (((kpi.tags) && (kpi.tags.includes('TEAM_MATCH') && kpi.tabValue === kpi.tags.indexOf('TEAM_MATCH'))) && seasonDetails?.season_format === 'TEAM_RACE') &&
                                <KpiRuleTab
                                    data={kpi.team_race_rule}
                                    kpi={kpi}
                                    type='TEAM_MATCH'
                                />

                            }

                            {
                                (((kpi.tags) && (kpi.tags.includes('TEAM_BONUS') && kpi.tabValue === kpi.tags.indexOf('TEAM_BONUS')))) &&

                                <KpiRuleTab
                                    data={kpi.team_bonus_rule}
                                    kpi={kpi}
                                    type='TEAM_BONUS'
                                />

                            }
                            {
                                (((kpi.tags) && (kpi.tags.includes('PLAYER_MATCH') && kpi.tabValue === kpi.tags.indexOf('PLAYER_MATCH')))) &&

                                <KpiRuleTab
                                    data={kpi.player_match_rule}
                                    kpi={kpi}
                                    type='PLAYER_MATCH'
                                />

                            }
                            {
                                (((kpi.tags) && (kpi.tags.includes('PLAYER_BONUS') && kpi.tabValue === kpi.tags.indexOf('PLAYER_BONUS')))) &&

                                <KpiRuleTab
                                    data={kpi.player_bonus_rule}
                                    kpi={kpi}
                                    type='PLAYER_BONUS'

                                />

                            }


                        </div>
                    ))}
        </div >


    )
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getSeasonRules: (options) => dispatch(getSeasonRules(options)),

    };

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeasonRulesList));