import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getLeaguesList, getSeasonsList, getSeasonPlayers, getSeasonPlayersRoles, getSeasonKpisList, getPlayerKpisTrends } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import FieldSet from '../../GlobalComponents/FieldSet';
import PerformanceGraph from '../OverallTrends/PerformanceGraph';
import { DurationTooltip } from '../DurationTooltip';
import { formatDate, getSearchValue, setSearchQuery } from '../../../helpers';
import DummyGraph from '../../../assets/images/dummy-graph.png';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import IllustrationView from '../../LiveScores/IllustrationView';
import { Tooltip } from '@material-ui/core';
import { CustomTabBar } from '../../Leaderboards/TabBar';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    filtersRoot: {
        padding: '0px 40px 20px',
        // padding: '80px 80px 20px',
        // width: 'calc(100% - 160px)'
    },
    autocompleteRoot: {
        borderRadius: 20,
        padding: '5px 5px !important',
        fontSize: 13,
        // backgroundColor: '#FFF',
    },
    label: {
        fontSize: 13,
    },
    roundIconButton: {
        fontSize: 12,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: '0 4px',
        pointerEvents: 'auto !important'
    },
    roundIconButtonSelected: {
        color: 'black',
        backgroundColor: '#C4C4C4'
    },
    roundsLableStyles: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
    },
    avatarStyles: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 26,
        width: 26,
        border: '2px solid #FFF'
    },
    cardHeights: {
        maxWidth: '100%',
        maxHeight: '60px',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            background: "#EEEEEE",
            borderBottom: `1px solid ${theme.palette.primary.main}`,

        }
    },
    vsStyles: {
        margin: '0px',
        fontSize: 12,
        padding: '0px',
        textAlign: 'center'
    },
    teamName: {
        margin: 0,
        width: '75px',
        padding: 5,
        overflow: 'hidden',
        fontSize: '13px',
        // fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            // width: 100
        }
    },
    matchView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #c4c4c48c',
        borderRadius: 30,
        cursor: 'pointer',
        maxWidth: 270,
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'black'
        }
    },
    selectedMatch: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    filedSetRootStyles: {
        padding: 20,
    },
    filedSetRootStyles1: {
        padding: 20,
        minHeight: 300
    },
    legendRootStyles: {
        fontSize: 14
    }, legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    }, emptyData: {
        maxHeight: 400,
        width: '100%',
        position: 'relative',
        '& img': {
            opacity: .1,
            width: '100%'
        },
        '& p': {
            fontSize: 12,
            position: 'absolute',
            top: 'calc(50% - 9px)',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
        }
    }
}));

const defaultFilters = {
    league: null,
    season: null,
    kpi: null,
    role: null,
    player: null,
    trend: null
};

const PlayerPerformanceTrends = ({ filtersData, setFiltersData, getLeaguesList, getSeasonsList, setLoading, loading, getSeasonTeams, getSeasonKpisList, getPlayerKpisTrends, getSeasonPlayers, getSeasonPlayersRoles, history, seasonDetails }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [players, setPlayers] = useState([]);
    const [initialPlayers, setInitialPlayers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [kpis, setKpis] = useState([]);

    // useEffect(() => {
    //     getLeagues();
    // }, []);

    // useEffect(() => {
    //     if (filtersData.league) getSeasons(filtersData.league._id);
    // }, [filtersData.league]);

    useEffect(() => {
        if (filtersData.season?._id) {
            if (filtersData.season.season_format === 'HEAD_TO_HEAD') {
                getRolesList(filtersData.season._id);
                getPlayersList(filtersData.season._id)
            }
            else {
                if (filtersData.season?.season_format !== 'TEAM_RACE' || filtersData.season?.auto_player_sprints) {
                    getRolesList(filtersData.season._id);
                    getPlayersList(filtersData.season._id)
                }
            }
        }
    }, [filtersData.season?._id]);

    useEffect(() => {
        if (filtersData.player) {
            getKpiPerformanceTrends(filtersData.season._id, filtersData.kpi, filtersData.player.employee_id)
        }
    }, [filtersData.player]);

    // useEffect(() => {
    //     if (filtersData.role) {
    //         let playersArr = [...initialPlayers];
    //         playersArr = playersArr.filter((player) => {
    //             return (player.role_id === filtersData.role?.role_id);
    //         })
    //         // if (playersArr.length > 0) {
    //         const searchValue = getSearchValue(history, 'player');
    //         setFiltersData((prevState) => ({ ...prevState, player: (playersArr.length > 0 ? searchValue ? playersArr.find(ele => ele.employee_id === searchValue) : playersArr[0] : null) }))
    //         // }
    //         // else setFiltersData((prevState) => ({ ...prevState, player: null }));
    //         setPlayers([...playersArr]);
    //     }
    //     else {
    //         setPlayers([...initialPlayers])
    //     };

    // }, [filtersData.role])

    const handleChangeAutocomplete = (data, field) => {
        setSearchQuery(history, { [field]: field === 'role' ? data?.role_id : field === 'player' ? data?.employee_id : data?._id });
        if (field === 'season') {
            setFiltersData(prevState => ({ ...prevState, [field]: data, kpi: null, role: null }));
        }
        else
            setFiltersData(prevState => ({ ...prevState, [field]: data }));
    };

    const handleSubTabChange = (e, newValue) => {
        setSearchQuery(history, { player: newValue?.employee_id });
        setFiltersData(prevState => ({ ...prevState, player: players[newValue] }));
    }

    const getLeagues = () => {
        setLoading(true);
        getLeaguesList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setLeagues([...result]);
                const searchValue = getSearchValue(history, 'league');
                !searchValue && setSearchQuery(history, { league: result[0]?._id });
                setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0] }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getSeasons = (leagueId) => {
        setLoading(true);
        getSeasonsList({
            leagueId: leagueId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setSeasons([...result]);
                    const searchValue = getSearchValue(history, 'season');
                    !searchValue && setSearchQuery(history, { season: result[0]?._id });
                    setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0], role: null, player: null }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                    setSeasons([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getPlayersList = (seasonId) => {
        setLoading(true);
        getSeasonPlayers({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const formatResult = result.map((obj, index) => ({ label: obj.name, name: obj.name, value: index, employee_id: obj.employee_id }));
                    setInitialPlayers([...result]);
                    // let playersArr = [];
                    const searchValue = getSearchValue(history, 'player');
                    // const searchRoleValue = getSearchValue(history, 'role');
                    // if (searchRoleValue) {
                    //     playersArr = result.filter((player) => {
                    //         return (player.role_id === searchRoleValue);
                    //     })
                    //     !searchValue && setSearchQuery(history, { player: playersArr[0]?.employee_id });
                    //     setPlayers([...playersArr]);
                    // }
                    // else {
                    !searchValue && setSearchQuery(history, { player: formatResult[0]?.employee_id });
                    setPlayers([...formatResult]);
                    // }
                    setFiltersData((prevState) => ({ ...prevState, player: searchValue ? formatResult.find(obj => obj.employee_id === searchValue) : formatResult[0] }))
                }
                else {
                    setPlayers([]);
                    setInitialPlayers([]);
                    setFiltersData((prevState) => ({ ...prevState, player: null }))
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const getRolesList = (seasonId) => {
        setLoading(true);
        getSeasonPlayersRoles({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setRoles([...result]);
                    const searchValue = getSearchValue(history, 'role');
                    setFiltersData((prevState) => ({ ...prevState, role: (searchValue ? result.find(ele => ele.role_id === searchValue) : null) }))

                }
                else {
                    setRoles([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const getSeasonKpis = (seasonId) => {
        setLoading(true);
        getSeasonKpisList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const finalResult = result.reduce((arr, item, index) => {
                        const splittedArr = [];
                        const kpiDetails = { _id: item._id, name: item.name, unit: item.unit, unit_of_measurement: item.unit_of_measurement };
                        if (item.tags?.includes('PLAYER_MATCH')) splittedArr.push({ ...kpiDetails, tag: 'PLAYER_MATCH' });
                        if (item.tags?.includes('PLAYER_BONUS')) splittedArr.push({ ...kpiDetails, tag: 'PLAYER_BONUS' });
                        return [...arr, ...splittedArr];
                    }, []);
                    setKpis([...finalResult]);
                    setFiltersData(prevState => ({ ...prevState, kpi: finalResult[0] }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                    setKpis([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getKpiPerformanceTrends = (seasonId, kpi, player_id) => {
        setLoading(true);
        getPlayerKpisTrends({
            seasonId: seasonId,
            params: { player_id: player_id, limit: 5000, page: 1 }, // kpi_id: kpi._id, kpi_type: kpi.tag, 
            onSuccessCB: (result) => {
                if (result.length > 0) {
                    const resultData = result.map(obj => ({
                        labels: obj.stats?.map((item) => `${formatDate(item.end_date)}`), // (item, i) => `${item.pre_fix}${i + 1}`
                        targets: obj.stats?.map(item => item.target),
                        actuals: obj.stats?.map(item => item.actual),
                        duration: obj.stats[0]?.duration,
                        durations: obj.stats?.map((item, i) => ({ label: `${item.pre_fix}${i + 1}`, startDate: item.start_date, endDate: item.end_date })),
                        kpiName: obj.kpi_name,
                        // kpiCode: obj.kpi_code || obj.kpi_name,
                        kpiType: obj.kpi_type,
                        unitOfMeasurement: obj.unit_of_measurement,
                        unit: obj.unit
                    }))
                    // console.log('finalResult ', resultData);
                    setFiltersData(prevState => ({ ...prevState, trends: resultData }));
                }
                else {
                    setFiltersData(prevState => ({ ...prevState, league: prevState.league, season: prevState.season, kpi: prevState.kpi, trends: null }));
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...prevState, league: prevState.league, season: prevState.season, kpi: prevState.kpi, trends: null }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    // const getKpiPerformanceTrends = (seasonId, kpi, player_id) => {
    //     setLoading(true);
    //     getPlayerKpisTrends({
    //         seasonId: seasonId,
    //         params: { kpi_id: kpi._id, kpi_type: kpi.tag, player_id: player_id, limit: 5000, page: 1 },
    //         onSuccessCB: (result) => {
    //             if (result.length > 0) {
    //                 const trendData = result.find(obj => obj.kpi_type === (kpi.tag === 'PLAYER_MATCH' ? 'Core KPI' : 'Bonus KPI'));
    //                 let resultTrend = {
    //                     labels: trendData?.stats?.map((item) => `${formatDate(item.end_date)}`), // (item, i) => `${item.pre_fix}${i + 1}`
    //                     targets: trendData?.stats?.map(item => item.target),
    //                     actuals: trendData?.stats?.map(item => item.actual),
    //                     duration: trendData?.stats[0]?.duration,
    //                     durations: trendData?.stats?.map((item, i) => ({ label: `${item.pre_fix}${i + 1}`, startDate: item.start_date, endDate: item.end_date })),
    //                     unitOfMeasurement: kpi.unit_of_measurement,
    //                     unit: kpi.unit
    //                 };
    //                 // console.log('finalResult ', result);
    //                 setFiltersData(prevState => ({ ...prevState, trend: resultTrend }));
    //             }
    //             else {
    //                 setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season, kpi: prevState.kpi, player: prevState.player, trend: null }));
    //             }
    //             setLoading(false);
    //         }, onErrorCB: (error) => {
    //             setLoading(false);
    //             setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season, kpi: prevState.kpi, trend: null }));
    //             enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
    //                 variant: 'error',
    //                 preventDuplicate: true
    //             });
    //         }
    //     })
    // }
    return (
        <div className={classes.root}>
            <div className={classes.filtersRoot}>
                {/* <CustomTabBar tabs={players} subTabVal={players?.findIndex(obj => obj.employee_id === filtersData.player?.employee_id)} handleSubTabChange={handleSubTabChange} /> */}
                {/* <FieldSet title={"Player & KPI selection"} rootStyles={classes.filedSetRootStyles} legend={classes.legendRootStyles}>
                    <Grid container spacing={2} >
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-leagues"
                                options={leagues}
                                getOptionLabel={(option) => option.name || ''}
                                value={filtersData?.league}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="League" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'league')}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-seasons"
                                options={seasons}
                                getOptionLabel={(option) => option.name || ''}
                                value={seasons.length && filtersData?.season}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Season" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'season')}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-roles"
                                options={roles}
                                getOptionLabel={(option) => option.name || ''}
                                value={filtersData?.role}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                // disableClearable
                                renderInput={(params) => <TextField {...params} label="Role" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'role')}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-players"
                                options={players}
                                getOptionLabel={(option) => option.name || ''}
                                value={filtersData?.player}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Player" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'player')}
                            />
                        </Grid>
                    </Grid>
                </FieldSet> */}
                {filtersData?.player &&
                    <Autocomplete
                        id="List-of-players"
                        options={players}
                        getOptionLabel={(option) => option.name || ''}
                        value={filtersData?.player}
                        classes={{ inputRoot: classes.autocompleteRoot }}
                        style={{ width: 300 }}
                        disableClearable
                        renderInput={(params) => <TextField {...params} label="Player" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                        onChange={(...args) => handleChangeAutocomplete(args[1], 'player')}
                    />
                }
                {
                    (filtersData.season?.season_format !== 'TEAM_RACE' || filtersData.season?.auto_player_sprints) ?
                        <div style={{ margin: '10px 0' }}>
                            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                                {
                                    !loading &&
                                    [
                                        filtersData.trends?.length > 0 ? filtersData.trends.map((trend, i) => (
                                            <Grid item md={filtersData.trends.length === 1 ? 12 : 6} xs={12} style={{ marginTop: 10 }}>
                                                <FieldSet
                                                    key={i}
                                                    title={<span className={classes.legendStyles}>{trend.kpiName} <span style={{ fontSize: 11, margin: '0 4px', color: '#575d63' }}>({trend?.kpiType === "Core KPI" ? `${seasonDetails?.labels_config?.kpis?.core}` : `${seasonDetails?.labels_config?.kpis?.bonus}`})</span> {filtersData.player?.name ? `- ${filtersData.player?.name}` : ''} {(trend?.durations) && <DurationTooltip trend={trend} matchType={trend?.kpiType === "Core KPI" ? ((filtersData.season?.season_format === 'HEAD_TO_HEAD') ? 'Match Duration' : 'Sprint Duration') : `Player ${seasonDetails?.labels_config?.kpis?.bonus}`} />}</span>}
                                                    rootStyles={classes.filedSetRootStyles}
                                                >
                                                    {
                                                        (trend.labels?.length > 0 && filtersData.player) ?
                                                            <PerformanceGraph {...trend} />
                                                            :
                                                            <div className={classes.emptyData}>
                                                                <img src={DummyGraph} alt="graph" />
                                                                <p >** Data yet to be made available **</p>
                                                            </div>
                                                    }
                                                </FieldSet>
                                            </Grid>
                                        ))
                                            :
                                            < IllustrationView loading={loading} style={{ marginTop: '50px' }} />
                                        // <p style={{ textAlign: 'center', fontSize: 12, color: '#000000a1', width: '100%', marginTop: 50 }}>** No data available **</p>
                                    ]
                                }
                            </Grid>
                        </div>
                        :
                        <div style={{ textAlign: 'center', marginTop: 35 }}>
                            <img src={gameIllustration} />
                            <p style={{ fontSize: 14, color: '#0000008A' }}>Since player performance is not measured as a part of this selected season which is of 'Team race' format, we do not have any player trends to show here</p>
                        </div>
                }
                {/* {(filtersData.kpi && filtersData.player) &&
                        <FieldSet title={`${filtersData.kpi?.name} (${filtersData.kpi?.tag === 'TEAM_MATCH' ? 'Core KPI' : 'Bonus KPI'}) - ${filtersData.player.name}`} rootStyles={classes.filedSetRootStyles1}>
                            <PerformanceGraph {...filtersData.trend} />
                        </FieldSet>
                    }
                </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading,
    seasonDetails: state.game.seasonDetails,


})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getSeasonPlayers: (options) => dispatch(getSeasonPlayers(options)),
        getSeasonPlayersRoles: (options) => dispatch(getSeasonPlayersRoles(options)),
        getSeasonKpisList: (options) => dispatch(getSeasonKpisList(options)),
        getPlayerKpisTrends: (options) => dispatch(getPlayerKpisTrends(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerPerformanceTrends));
