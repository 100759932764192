import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { setLoading } from "../../../ducks/loading";
import { getSeasonHierarchiePlayersRoles, shareLiveScoreDialog } from "../../../ducks/game";
import InfiniteScroll from "react-infinite-scroll-component";
import TopFiveRolePlayerSummary from "./TopFiveRolePlayerSummary";


const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        // marginTop: 35,
    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        paddingBottom: 10
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 48,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        marginBottom: 5
    },
    season_Title: {
        '& h4': {
            color: '#000000a6',
            margin: 0,
        }
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    divisin: {
        padding: 10
    },
    divisions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    divisionList: {
        width: '100%',
        maxWidth: '45%',
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        height: 40,
        padding: 1,
        alignItems: 'center',
        marginBottom: 8
    },
    shareing: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'auto',
        '& .sycn': {
            fontStyle: 'italic',
            fontWeight: 400,
            fontSize: 10,
            color: 'rgba(136, 135, 135, 0.9)',
            margin: 0,
            alignItems: 'center',
            paddingRight: 10,
            display: 'flex'
        }
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center'
    },
    shareOptionStyles: {
        fontSize: 14,
        '& #share-to-wall': {
            width: 20,
            height: 20,
            marginRight: 10,
            // '& path': {
            //   fill: theme.palette.primary.main
            // },
            // '& path[fill="white"]': {
            //   fill: '#FFF',
            // },
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& #download': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        },
        '& #Email': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12
    },
    viewAllButton: {
        margin: '0 10px',
        color: '#727272',
        fontSize: 13,
        float: 'right',
        cursor: 'pointer',
        border: '1px solid #D3D3D3',
        borderRadius: 4,
        padding: '10px 16px',
        textTransform: 'Capitalize',
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
}));


const pagination = {
    limit: 5,
    page: 0,
}

const RoleSummaryLists = ({ filtersData, setLoading, getSeasonHierarchiePlayersRoles, isAdmin, from }) => {
    const classes = useStyles();
    const [roles, setRoles] = useState([]);


    useEffect(() => {
        if (filtersData?.season?._id) {
            setRoles([]);
            setLoading(true);
            getSeasonHierarchiePlayersRoles({
                seasonId: filtersData?.season?._id,
                params: { limit: pagination.limit, page: 1 },
                onSuccessCB: onRoleSuccessCB,
                onErrorCB: onRoleErrorCB
            })
        }
        else setRoles([]);
    }, [filtersData.season?._id])

    const getRole_List = () => {
        let seasonId = filtersData?.season?._id
        setLoading(true);
        getSeasonHierarchiePlayersRoles({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: pagination.page + 1, },
            onSuccessCB: onRoleSuccessCB,
            onErrorCB: onRoleErrorCB
        })
    }

    const onRoleSuccessCB = (result, headers) => {
        if (result) {
            setRoles(prevState => ([...prevState, ...result]));
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }
    const onRoleErrorCB = () => { }




    return (
        <div className={classes.root}>
            <InfiniteScroll
                dataLength={roles.length}
                next={getRole_List}
                hasMore={roles.length !== pagination.total_count}
                scrollableTarget="livescoresRoot"
            >
                {
                    roles.map((ele, index) => (
                        <TopFiveRolePlayerSummary from={from} key={ele._id} index={index} role={ele} filtersData={filtersData} isAdmin={isAdmin} />
                    ))
                }
            </InfiniteScroll >
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getSeasonHierarchiePlayersRoles: (options) => dispatch(getSeasonHierarchiePlayersRoles(options)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleSummaryLists));