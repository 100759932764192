import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import moment from 'moment';
import GirlImage from '../../assets/images/girl-image.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCurrentSurvey } from '../../ducks/surveys';
import qs from 'query-string';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        // marginLeft: "80px"
    },
    firstP: {
        color: "#838383",
        fontSize: "12px",
        textAlign: "center"
    },
    secondP: {
        color: "#535353",
        fontSize: "14px",
        textAlign: "center"
    },
    button: {
        color: "#FFFFFF",
        height: "44px",
        width: "100%",
        textAlign: "center",
        maxWidth: 302
    },
    surveyImageDescription: {
        marginTop: "10px",
        marginBottom: "30px",
        [theme.breakpoints.down('xs')]: {
            height: 130
        }
    },
    primaryColor: {
        color: theme.palette.primary.main
    }
}));

const SurveyDetails = (props) => {
    const classes = useStyles();
    let surveyIdfromWall = null;
    if (props.history.location.pathname.split('/')[2] === 'details') {
        let surveyfromWall = qs.parse(props.history.location.search)
        surveyIdfromWall = surveyfromWall?.surveyId


    }


    useEffect(() => {
        if (props.match.params.survey_id) {
            const isCurrent = Object.values(props.surveysList).find(survey => survey._id === props.match.params.survey_id)
            if (isCurrent) props.handleOnSurveyClick(isCurrent, true);
        }
    }, [props.surveysList, props.match.params?.survey_id]);

    return (
        <div style={{ background: "#FFFFFF", width: '100%', height: 400 }}>
            {(Object.values(props.surveysList).find(survey => survey._id === surveyIdfromWall)) ?
                <>
                    <p className={classes.primaryColor} style={{ fontSize: "24px", margin: "7px 30px", fontWeight: 600 }}>{props.title}</p>
                    <div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <img className={classes.surveyImageDescription} height="200" src={props.image_url || GirlImage} alt="Landing_Page" ></img>

                            <div style={{ fontSize: "12px", color: "#838383" }}>Description</div>


                            <div style={{ marginBottom: "20px", width: "65%" }}><p style={{ color: "#535353", fontSize: "14px", textAlign: "center", wordBreak: 'break-word' }}>{props.description}</p></div>

                        </div>
                    </div>
                    <div className={classes.root}>
                        <Grid container>
                            <Grid key={1} item md={3} sm={6} xs={6} >
                                <p className={classes.firstP}>Created By</p>
                                <p className={classes.secondP}>{props.created_by || '--'}</p>
                            </Grid>
                            <Grid key={2} item md={3} sm={6} xs={6} >
                                <p className={classes.firstP}>Number Of Questions </p>
                                <p className={classes.secondP}>{props.question_count || '--'} </p>
                            </Grid>
                            <Grid key={3} item md={3} sm={6} xs={6}>
                                <p className={classes.firstP}>Created On</p>
                                <p className={classes.secondP}>{moment(props.publish_date).format("DD-MMM-YYYY")}</p>
                            </Grid>
                            <Grid key={4} item md={3} sm={6} xs={6}>
                                <p className={classes.firstP}>Expires On</p>
                                <p className={classes.secondP}>{moment(props.expire_date).format("DD-MMM-YYYY")} </p>
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{ textAlign: "center", margin: "15px" }}>
                        <Button className={classes.button} disabled={props.status === 'expired' || props.status === 'responded'} color="primary" variant="contained" onClick={() => props.handleStartSurvey()} >Start</Button>
                    </div>
                </>
                :
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: '500px' }}>
                    <img className={classes.surveyImageDescription} height="200" src={props.image_url || GirlImage} alt="Landing_Page" ></img>

                    <div style={{ marginBottom: "20px", width: "65%" }}><p style={{ color: "#535353", fontSize: "20px", textAlign: "center", wordBreak: 'break-word' }}>Sorry!You don't have access to start this survey</p></div>

                </div>
            }
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    surveysList: state.surveys,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentSurvey: (options) => dispatch(setCurrentSurvey(options)),
    };
};

export default withRouter(connect(mapStateToProps, null)(SurveyDetails));
