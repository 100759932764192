import { Avatar, Backdrop, Badge, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, TextField } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Close, CameraAltOutlined, Check, Add } from '@material-ui/icons';
import TeamProfile from '../TeamProfile';
import { useSnackbar } from 'notistack';
import { getAvatarText, getSearchValue } from '../../helpers';
import { connect } from 'react-redux';
import { getTeamProfile, updateEducationList } from '../../ducks/user';
import PlayersList from '../TeamProfile/PlayersList';
import { getSeasonPlayers } from '../../ducks/game';
import { setLoading } from '../../ducks/loading';
import TeamProfileSummary from '../TeamProfile/TeamProfileSummary';
import ProfileDetail from '../TeamProfile/ProfileDetail';
//
import { getArenaProfile } from '../../ducks/user';
import { getFavouriteStatus } from '../../ducks/favourites';
import { setCurrentChat } from "../../ducks/chats";
import { uploadFilesToS3 } from '../../ducks/common';
import { updateBasicProfile } from '../../ducks/user';
import { updateWorkList } from '../../ducks/user';
import { deleteEducation } from '../../ducks/user';
import { deleteWorkExperience } from '../../ducks/user';
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg";
import { followUser } from '../../ducks/favourites';
import { unFollowUser } from '../../ducks/favourites';
import { MarkFavButton, RemoveFavButton } from "../Arena/ReactionsDialog";
import FavouritesLists from '../PlayerProfile/FavouritesLists';
import PlayerProfileDetail from '../PlayerProfile/ProfileDetail';

const useStyles = makeStyles(theme => ({
    root: {
        // padding: "10px",
        width: "100%",
    },
    dialogPaper: {
        minHeight: 300,
        minWidth: '85vw',
        // height: 'calc(100% - 60px)',
        borderRadius: 15,
        margin: 0
    },
    dialogTitle: {
        // paddingBottom: 0,
        // backgroundColor: "#f5f6f7",
        fontSize: 18,
        color: '#262626'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12
    },
    dialogContent: {
        // padding: 20,
        // maxHeight: 300,
        // backgroundColor: '#F0F2F5'
        '& .MuiDialogContent-dividers': {
            padding: '20px 20px',
        }
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 7px !important',
        // width: 280
    },
    dialogueActions: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "none"


    },
    dateInputUnderine: {
        border: '1px solid #c2c2c2',
        // cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:before': {
            border: 'none !important'
        },
        '&:hover:not(.Mui-disabled):before': {
            border: 'none'
        },
        '& svg': {
            fill: '#535353'
        }
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        margin: 'auto',
        width: 50,
        height: 50,
        '& circle': {
            fill: theme.palette.primary.main
        }
    },
    defaultLogoRoot: {
        textAlign: 'center',
        height: 51,
        '& svg': {
            height: 51,
            width: 'auto',
            '& path[fill="#8A2BE2"]': {
                fill: theme.palette.primary.main
            }
        }
    },

    gridContainer: {
        display: 'flex',
        width: '100%',
        margin: '0 auto',
        maxWidth: 1250,
    },
    rightSectionRoot: {
        marginTop: '-10px',
        flexGrow: 0,
        maxWidth: '27.5%',
        flexBasis: '27.5%',
    },
    mainroot: {
        padding: '0px 20px',
        flexGrow: 0,
        maxWidth: '72.5%',
        flexBasis: '72.5%',
    },
    cardDisplay: {
        backgroundColor: "#FFFFFF",
        padding: '0px',
        borderRadius: 3,
        height: '296px',
        boxShadow: "0px 0px 4px 1px #EDEDED",
        position: 'relative',
    },
    profilePicRoot: {
        width: '100%',
        padding: "24px 0 12px",
        position: 'absolute',
        bottom: 0,

    },
    iconRootCam: {
        color: "#fff"
    },
    avatarSize: {
        width: "122px",
        height: "122px",
        fontSize: 60,
        backgroundColor: theme.palette.primary.main,
        border: '2px solid white',
        '& img': {
            objectFit: 'fill'
        }
    },
    avatarSize1: {
        width: "100%",
        height: "180px",
        margin: '0 auto',
        fontSize: 60,
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        '& img': {
            objectFit: 'fill'
        }
    },
    profileNameRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto',
        alignItems: 'center',
        padding: '0px 20px'
    },
    profileNameActions: {
        position: 'absolute',
        marginLeft: 20,
        top: 4,
        right: -110
    },
    profileName: {
        fontSize: 16,
        color: "#141414",
    },
    backdropImage: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        position: "absolute",
        borderRadius: "50%",

    },
    backdropImage1: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        position: "absolute",
    },
    BackgroundCvr: {
        background: '#eefbff',
        height: '180px',
        textAlign: 'center',
        position: 'absolute',
        top: '-84px',
        width: '100%',
    },
    svgIcon: {
        overflow: "inherit",
    },
    iconRoot: {
        marginRight: 5
    },
    favourite: {
        width: "270px",
        height: "41px",
        borderRadius: "6px",
        backgroundColor: "#ffcc00",
        marginBottom: 10,
        border: 'none'
    },
    inputStyles: {
        fontSize: 15,
        color: '#535353'
    },
    nameInputStyles: {
        textAlign: 'center',
        color: '#141414',
        fontSize: 16,
        fontWeight: 600
    },
    inputUnderine: {
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    },

    favButton: {
        height: 36,
        minWidth: 100,
        '& path': {
            fill: '#FFF'
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '& .fav-button-text': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    followbutton: {
        borderRadius: 35,
        height: 30,
        '& path': {
            fill: '#FFF'
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '& .fav-button-text': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    tabStyles: {
        minHeight: 40,
        textTransform: 'capitalize',
        color: '#262626',
        minWidth: 'auto',
        // margin: '0px 12px',
        padding: '6px 12px 0px',
        fontSize: 13,
    },
    selectedTab: {
        fontWeight: 600
    },
    tabsRoot: {
        width: '100%',
        maxWidth: '100%',
        minHeight: 40,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        boxShadow: '-4px 3px 10px -7px #000'
    },
    paperRoot: {
        minWidth: 200
    },
    rightIcon: {
        padding: "0px 0 0 20px",
    },
    button: {
        borderRadius: 30,
        color: "#5D5FEF",
        backgroundColor: "#EBEBFF",
        padding: "5px 14px",
        fontSize: "20",
        fontWeight: 500,
        marginLeft: '-5px',

    },
    chatbutton: {
        borderRadius: 35,
        height: 30,
        minWidth: 100,
        marginRight: '8px',
        '&:hover': {
            boxShadow: 'none'
        },
    },
    iconRootCam: {
        color: "#fff"
    },
    badge1: {
        border: "2px solid #fff",
        height: "35px",
        width: "35px",
        borderRadius: "20px",
        right: '18px',
        top: '17px'
    },
    badge: {
        border: "2px solid #fff",
        height: "35px",
        width: "35px",
        borderRadius: "20px",
    },
    actionButtonStyles: {
        padding: 8
    },
    editIconStyles: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        margin: '0 5px',
        '& svg': {
            '& path': {
                fill: '#FFF'
            }
        },
        '& .delete-icon': {
            height: 14,
            '& path': {
            }
        }
    },
}));

const defaultObj = {
    page: 1,
    limit: 10,
    totalCount: 0,
    data: [],
    loading: false
}
const defaultBirthDate = new Date(new Date().getFullYear() - 1, 11, 31);


const TeamProfileDialog = ({ open = false, handleClose, ProfileDetails, filtersData, username, getTeamProfile, getSeasonPlayers, profileType, user, getArenaProfile, getFavouriteStatus, setCurrentChat, presence, history, uploadFilesToS3, updateBasicProfile, updateEducationList, updateWorkList, deleteWorkExperience, deleteEducation, unFollowUser, followUser }) => {
    const classes = useStyles();
    const [details, setDetails] = useState([]);
    const [seasonId, setSeasonId] = useState(filtersData.season?._id);
    const teamId = username;
    const { enqueueSnackbar } = useSnackbar();
    const [preview, setPreview] = useState(false);
    const bannerImage = 'https://s3.ap-south-1.amazonaws.com/profilepics.prod/leagues_team_profile.png';
    const [playersList, setPlayersList] = useState(defaultObj);
    const [fetchAll, setFetchAll] = useState(false);
    //
    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [uploadingImage1, setUploadingImage1] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [type, setType] = useState(profileType);
    const [playerDetails, setPlayerDetails] = useState({
        name: "",
        photoUrl: null,
        bannerImage: null,
        email: "",
        phoneNo: "",
        designation: "",
        about: "",
        departmentName: null
    });
    const [editedDetails, setEditedDetails] = useState({})
    const [edit, setEdit] = useState({
        name: false,
        about: false,
        education: false,
        workExperience: false
    });
    const [isFavourite, setIsFavourite] = useState(false);
    const [openConfirmationDialog, setConfirmationDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState("");
    const [userId, setUserId] = useState(username);
    const dialogRef = useRef();



    useEffect(() => {
        if (ProfileDetails?.type) {
            setType(ProfileDetails.type)
            setUserId(ProfileDetails.username)
            setSeasonId(ProfileDetails.filtersData.season?._id)
        }
    })


    useEffect(() => {
        if (type === 'TEAM') {
            if (seasonId && teamId) {
                getTeamProfile({
                    seasonId: seasonId,
                    teamId: teamId,
                    onTeamDetailsSuccess,
                    onTeamDetailsError
                });
            }
            else {
                setDetails([]);
            }
        }

    }, [seasonId, teamId]);

    const onTeamDetailsSuccess = result => {
        setDetails(result);
    };

    const onTeamDetailsError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch Team Details.', {
            variant: 'error',
            preventDuplicate: true
        });
    };

    const formatUserInfoProperty = (userInfo) => {
        if (userInfo) {
            return userInfo;
        } else {
            return "--";
        }
    };

    const handlePreview = (attachments, attachmentIndex) => {
        setPreview({ open: true, attachments, attachmentIndex });
    }

    useEffect(() => {
        if (type === 'TEAM') {
            if (teamId && seasonId) {
                if (fetchAll === false) {
                    setPlayersList(prevState => ({ ...prevState, loading: true }))
                    getSeasonPlayers({
                        seasonId: seasonId,
                        params: { limit: defaultObj.limit, page: defaultObj.page, team_id: teamId },
                        onSuccessCB,
                        onErrorCB
                    })
                }
                else if (fetchAll === true) {
                    setPlayersList(prevState => ({ ...prevState, loading: true }))
                    getSeasonPlayers({
                        seasonId: seasonId,
                        params: { limit: 100, page: defaultObj.page, team_id: teamId },
                        onSuccessCB,
                        onErrorCB
                    })
                }






            }
        }


    }, [teamId && seasonId, fetchAll])


    const onSuccessCB = (result, headers) => {
        if (result.length > 0) {
            setPlayersList(prevState => ({
                ...prevState, loading: false, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
                data: [...result]
            }));

        }
        else {
            setPlayersList(defaultObj)
            setLoading(false);
        }
        setLoading(false);
    }


    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Players', {
            variant: 'error',
            preventDuplicate: true
        });
    }
    ///

    useEffect(() => {
        if (type === 'PLAYER') {
            if (isLoggedInUser()) {
                setPlayerDetails(user);
            }
        }

    }, [user]);

    useEffect(() => {
        if (type === 'PLAYER') {
            if (!isLoggedInUser() && userId) {
                getArenaProfile({
                    username: userId,
                    onEmployeeDetailsSuccess,
                    onEmployeeDetailsError
                });
                getFavouriteStatus({
                    username: userId,
                    onFavouriteStatusSuccess,
                    onFavouriteStatusError
                })
            }
            else {
                setPlayerDetails(user);
            }
        }

    }, [userId]);

    const isLoggedInUser = () => {
        if (userId !== user.username) {
            return false
        }
        else {
            return true;
        }
    }

    const onEmployeeDetailsSuccess = result => {
        setPlayerDetails(result);
    };
    const onEmployeeDetailsError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch details.', {
            variant: 'error',
            preventDuplicate: true
        });
    };

    const onFavouriteStatusSuccess = result => {
        setIsFavourite(result.follower);
    }

    const onFavouriteStatusError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch favourite status.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleChatClick = (userDetails) => {
        setCurrentChat({ targetJid: `${userDetails.username}@${process.env.REACT_APP_XMPP_DOMAIN}`, chatId: `${userDetails.username}@${process.env.REACT_APP_XMPP_DOMAIN}`, targetName: userDetails.name, presence: presence ? (presence.show || 'unavailable') : 'unavailable', photo_url: userDetails.photoUrl, bannerImage: userDetails.bannerImage, type: 'chat' });
        history.push('/chats');

    }
    const handleOnCameraClick = () => {
        document.getElementById("hiddenFileInputForProfilePic").click();
    };
    const handleOnCameraClick1 = () => {
        document.getElementById("hiddenFileInputForProfilePic1").click();
    };

    const handlePhotoChange = (event, field) => {
        let files = event.target.files;
        if (files && files.length) {
            var formData = new FormData();
            if (field === 'photo_url') {
                setUploadingImage(true);
            } else { setUploadingImage1(true) }
            formData.append("file", files[0]);
            uploadFilesToS3({ formData, onUploadSuccessCB: (res) => onUploadSuccessCB(res, field), onUploadErrorCB });
        }
        setIsEdited(true);
    };
    const onUploadSuccessCB = (result, field) => {
        let reqobjdub = {
            "about": playerDetails.about,
            "birthDate": playerDetails.dateOfBirth,
            "gender": playerDetails.gender,
            "hobbies": playerDetails.hobbies,
            "location": playerDetails.location,
            "name": playerDetails.name,
            "privacy": playerDetails.privacy,
            "phoneNo": playerDetails.phoneNo,
            "photo_url": playerDetails.photoUrl,
            "bannerImage": playerDetails.bannerImage
        }
        if (field === 'photo_url') {
            reqobjdub.photo_url = result.url
        } else {
            reqobjdub.bannerImage = result.url
        }
        updateBasicProfile({
            requestObj: reqobjdub,
            employeeId: playerDetails.employeeId,
            onBasicProfileUpdateSuccess, onBasicProfileUpdateError
        })
    };

    const onUploadErrorCB = result => {
        setUploadingImage(false);
        setUploadingImage1(false);
    };

    const onBasicProfileUpdateSuccess = (result) => {
        setUploadingImage(false);
        setUploadingImage1(false);
        setEdit(prevState => ({ ...prevState, about: false, name: false }));
    }
    const onBasicProfileUpdateError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.errors[0] : 'Failed to update details.' ? error.response.data.errors.length > 0 : error.response.data.errors[0], {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleChange = (event) => {
        let editedDetailsDup = JSON.parse(JSON.stringify(editedDetails));
        let id = event.target.id;
        let value = event.target.value;
        switch (id) {
            case 'name':
                if (value.length <= 100 && !/[~`!@#$%\^&*()+=\-\[\]\\';,./{}|\\":<>\?]/g.test(value)) {
                    editedDetailsDup.name = value;
                }
                break;
            case 'info':
                if (value.length <= 300) {
                    editedDetailsDup.about = value;
                }
                break;
            case 'location':
                editedDetailsDup.location = value;
                break;
            case 'phoneNo':
                if (value.length <= 10) {
                    editedDetailsDup.phoneNo = value;
                }
                break;
            case 'hobbies':
                editedDetailsDup.hobby = value;
                break;
            case 'field':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], fieldOfStudy: value };
                break;
            case 'school':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], schoolOrCollege: value };
                break;
            case 'degree':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], degree: value };
                break;
            case 'company':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], company: value };
                break;
            case 'position':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], position: value };
                break;

            default:
                break;
        }
        setEditedDetails({ ...editedDetailsDup });
    }

    const handleUpdate = (type) => {
        let requestObj = {};
        switch (type) {
            case 'about':
            case 'name':
                requestObj = {
                    "about": editedDetails.about,
                    "birthDate": editedDetails.dateOfBirth || defaultBirthDate,
                    "gender": editedDetails.gender,
                    "hobbies": editedDetails.hobbies,
                    "location": editedDetails.location,
                    "name": editedDetails.name,
                    "privacy": editedDetails.privacy,
                    "phoneNo": editedDetails.phoneNo,
                    "photo_url": editedDetails.photoUrl,
                    "bannerImage": editedDetails.bannerImage
                }
                updateBasicProfile({
                    requestObj,
                    employeeId: editedDetails.employeeId,
                    onBasicProfileUpdateSuccess, onBasicProfileUpdateError
                })
                break;
            case 'education':
                requestObj = {
                    "educationId": editedDetails.educations[0].educationId || editedDetails.educationId,
                    "educations": [
                        {
                            "degree": editedDetails.educations[0].degree,
                            "fieldOfStudy": editedDetails.educations[0].fieldOfStudy,
                            "schoolOrCollege": editedDetails.educations[0].schoolOrCollege,
                            "startYear": new Date(editedDetails.educations[0].startYear).getFullYear(),
                            "endYear": new Date(editedDetails.educations[0].endYear).getFullYear()
                        }
                    ]
                }
                updateEducationList({
                    requestObj,
                    username: playerDetails.username,
                    employeeId: editedDetails.employeeId,
                    onEducationUpdateSuccess, onEducationUpdateError
                })
                break;
            case 'workExperience':
                requestObj = {
                    "experienceId": editedDetails.workExperience[0].experienceId || editedDetails.experienceId,
                    "workExperience": [
                        {
                            "company": editedDetails.workExperience[0].company,
                            "position": editedDetails.workExperience[0].position,
                            "startYear": {
                                "month": new Date(editedDetails.workExperience[0].startYear).getMonth() + 1,
                                "year": new Date(editedDetails.workExperience[0].startYear).getFullYear()
                            },
                            "endYear": {
                                "month": new Date(editedDetails.workExperience[0].endYear).getMonth() + 1,
                                "year": new Date(editedDetails.workExperience[0].endYear).getFullYear()
                            },
                        }
                    ]
                }
                updateWorkList({
                    requestObj,
                    username: playerDetails.username,
                    employeeId: editedDetails.employeeId,
                    onWorkUpdateSuccess, onWorkUpdateError
                })
                break;

            default:
                break;
        }
    }


    const onEducationUpdateSuccess = result => {
        setEdit(prevState => ({ ...prevState, education: false }));
    };
    const onEducationUpdateError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to update Education details.', {
            variant: 'error',
            preventDuplicate: true
        });

    };

    const onWorkUpdateSuccess = result => {
        setEdit(prevState => ({ ...prevState, workExperience: false }));
    };
    const onWorkUpdateError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to update Work-Experience details.', {
            variant: 'error',
            preventDuplicate: true
        });
    };

    const handleConfirmationDialog = (value, text1, text2) => {
        if (text1 === "yes" && text2 === "Education") {
            setConfirmationDialog(false);
            deleteEducation({
                details: {
                    employeeId: details.employeeId,
                    educationId: details.educations[0].educationId || details.educationId,
                    updatedObj: { ...details, educations: [] }
                },
                onEducationDeleteSuccess, onEducationDeleteError
            })
        }
        else if (text1 === "yes" && text2 === "Experience") {
            setConfirmationDialog(false);
            deleteWorkExperience({
                details: {
                    employeeId: details.employeeId,
                    workExperienceId: details.workExperience[0].experienceId || details.experienceId,
                    updatedObj: { ...details, workExperience: [] }
                },
                onWorkExperienceDeleteSuccess, onWorkExperienceDeleteError
            })
        }
        else {
            setDeleteItem(text1)
            setConfirmationDialog(value)
        }
    }

    const handleEditClick = (type) => {
        setEdit(prevState => ({ ...prevState, [type]: true }));
        let detailsDup = JSON.parse(JSON.stringify(playerDetails));
        detailsDup.educations = (detailsDup.educations && detailsDup.educations.length > 0) ?
            detailsDup.educations.map(obj => ({
                ...obj, startYear: new Date((obj.startYear ? obj.startYear : new Date().getFullYear()), 0, 1), endYear: new Date((obj.endYear ? obj.endYear : new Date().getFullYear()), 0, 1)
            }))
            :
            detailsDup.educations = [{
                "educationId": "",
                "degree": "",
                "fieldOfStudy": "",
                "schoolOrCollege": "",
                "startYear": new Date(),
                "endYear": new Date()
            }];
        detailsDup.workExperience = (detailsDup.workExperience && detailsDup.workExperience.length > 0) ?
            detailsDup.workExperience.map(obj => ({
                ...obj,
                startYear: new Date((obj.startYear.year ? obj.startYear.year : new Date().getFullYear()), (obj.startYear.month ? (obj.startYear.month - 1) : new Date().getMonth()), 1),
                endYear: new Date((obj.endYear.year ? obj.endYear.year : new Date().getFullYear()), (obj.endYear.month ? (obj.endYear.month - 1) : new Date().getMonth()), 1),
                // endYear: new Date(obj.endYear.year, obj.endYear.month, 1)
            }))
            :
            detailsDup.workExperience = [{
                "experienceId": "",
                "company": "",
                "position": "",
                "endYear": new Date(),
                "startYear": new Date()
            }]
        setEditedDetails({ ...detailsDup });
    }

    const handleDeleteClick = (type) => {
        switch (type) {
            case 'education':
                handleConfirmationDialog(true, "Education");
                break;
            case 'workExperience':
                handleConfirmationDialog(true, "Experience");
                break;
            default:
                break;
        }
    }


    const onEducationDeleteSuccess = (result) => { }
    const onEducationDeleteError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to delete Education details.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const onWorkExperienceDeleteSuccess = (result) => { }
    const onWorkExperienceDeleteError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to delete Work-Experience details.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const ActionButtons = ({ type, data, hasDelete, addNewIcon, ...others }) => (
        <div {...others}>
            {edit[type] ?
                <>
                    <IconButton title="Cancel" onClick={() => setEdit(prevState => ({ ...prevState, [type]: false }))} className={classes.actionButtonStyles}><Close /></IconButton>
                    <IconButton title="Save" onClick={() => handleUpdate(type)} className={classes.actionButtonStyles}><Check /></IconButton>
                </>
                :
                <>
                    <IconButton title="Edit" onClick={() => handleEditClick(type, data)} className={classes.editIconStyles}>{addNewIcon ? <Add fontSize="small" /> : <EditIcon />}</IconButton>
                    {
                        hasDelete &&
                        <IconButton title="Delete" onClick={() => { handleDeleteClick(type) }} className={classes.editIconStyles}><DeleteIcon className="delete-icon" /></IconButton>
                    }
                </>
            }
        </div>
    )
    const onFollowUser = (follow) => {
        if (follow) {
            let requestObj = {
                username: userId
            };
            followUser({
                requestObj,
                onFollowUserSuccess,
                onFollowUserError
            });
        }
        else {
            unFollowUser({
                username: userId,
                onUnFollowUserSuccess,
                onUnFollowUserError
            })
        }
    };

    const onFollowUserSuccess = success => {
        setIsFavourite(true);
    };
    const onFollowUserError = success => {
    };

    const onUnFollowUserSuccess = result => {
        setIsFavourite(false);
    }
    const onUnFollowUserError = error => {
    }




    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            aria-describedby={`${username}`}
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle disableTypography onClose={() => handleClose(false)} className={classes.dialogTitle}>
                {type === 'TEAM' ? 'Team Profile' : 'Player Profile'}
                <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={() => { handleClose(false) }}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent} id="nextListItem">
                {type === 'TEAM' ?
                    <div id="arena-list-root" className={classes.root}>
                        <div className={classes.gridContainer}>
                            <div className={classes.mainroot}>
                                <div className={classes.cardDisplay}>
                                    <div className={classes.profilePicRoot}>
                                        <div className={classes.BackgroundCvr}>

                                            <Avatar
                                                id="bannerImage"
                                                className={classes.avatarSize1}
                                                src={bannerImage}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => details.bannerImage && handlePreview([{ url: details.bannerImage, mimeType: 'IMAGE' }], 0)}
                                            >{getAvatarText(details.name)}</Avatar>

                                        </div>
                                        <div style={{ marginLeft: '20px' }}>

                                            <Avatar
                                                id="profileImg"
                                                className={classes.avatarSize}
                                                src={details.logo_url}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => details.photoUrl && handlePreview([{ url: details.photoUrl, mimeType: 'IMAGE' }], 0)}
                                            >{getAvatarText(details.name)}</Avatar>

                                        </div>
                                        <div className={classes.profileNameRoot}>
                                            <div style={{ position: 'relative' }}>

                                                <h3 className={classes.profileName}>
                                                    {formatUserInfoProperty(details.name)}
                                                </h3>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <TeamProfileSummary filtersData={filtersData} teamId={teamId} seasonId={seasonId} />
                                </div>
                                <div className={classes.tabroot}>
                                    <ProfileDetail seasonId={seasonId} filtersData={filtersData} teamId={teamId} setLoading={setLoading} />
                                </div>
                            </div>
                            <div className={classes.rightSectionRoot} >
                                <PlayersList playersList={playersList} fetchAll={fetchAll} setFetchAll={setFetchAll} filtersData={filtersData} />
                            </div>
                        </div>
                    </div>
                    :
                    <div id="arena-list-root" className={classes.root}>
                        <div className={classes.gridContainer}>
                            <div className={classes.mainroot}>
                                <div className={classes.cardDisplay}>
                                    <div className={classes.profilePicRoot}>
                                        <div className={classes.BackgroundCvr}>
                                            {isLoggedInUser() ?
                                                <>
                                                    <Badge
                                                        overlap={"circle"}
                                                        variant="circle"
                                                        color="primary"
                                                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                        classes={{ badge: classes.badge1 }}
                                                        style={{ width: '100%' }}
                                                        badgeContent={
                                                            <IconButton onClick={() => handleOnCameraClick1()}>
                                                                <CameraAltOutlined
                                                                    classes={{ root: classes.iconRootCam }}
                                                                    fontSize={"small"}
                                                                />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <Avatar
                                                            id="bannerImage"
                                                            className={classes.avatarSize1}
                                                            src={playerDetails.bannerImage}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => playerDetails.bannerImage && handlePreview([{ url: playerDetails.bannerImage, mimeType: 'IMAGE' }], 0)}
                                                        >{getAvatarText(playerDetails.name)}</Avatar>
                                                        <Backdrop className={classes.backdropImage1} open={uploadingImage1}>
                                                            <CircularProgress color="inherit" />
                                                        </Backdrop>
                                                    </Badge>
                                                    <input
                                                        type="file"
                                                        id="hiddenFileInputForProfilePic1"
                                                        name="bannerImage"
                                                        onChange={e => handlePhotoChange(e, 'bannerImage')}
                                                        style={{ display: "none" }}
                                                        accept="image/*"
                                                    />
                                                </>
                                                :
                                                <Avatar
                                                    id="bannerImage"
                                                    className={classes.avatarSize1}
                                                    src={playerDetails.bannerImage}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => playerDetails.bannerImage && handlePreview([{ url: playerDetails.bannerImage, mimeType: 'IMAGE' }], 0)}
                                                >{getAvatarText(playerDetails.name)}</Avatar>
                                            }
                                        </div>
                                        <div style={{ marginLeft: '20px' }}>
                                            {isLoggedInUser() ?
                                                <>
                                                    <Badge
                                                        overlap={"circle"}
                                                        variant="circle"
                                                        color="primary"
                                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                        classes={{ badge: classes.badge }}
                                                        badgeContent={
                                                            <IconButton onClick={() => handleOnCameraClick()}>
                                                                <CameraAltOutlined
                                                                    classes={{ root: classes.iconRootCam }}
                                                                    fontSize={"small"}
                                                                />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <Avatar
                                                            id="profileImg"
                                                            className={classes.avatarSize}
                                                            src={playerDetails.photoUrl}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => playerDetails.photoUrl && handlePreview([{ url: playerDetails.photoUrl, mimeType: 'IMAGE' }], 0)}
                                                        >{getAvatarText(playerDetails.name)}</Avatar>
                                                        <Backdrop className={classes.backdropImage} open={uploadingImage}>
                                                            <CircularProgress color="inherit" />
                                                        </Backdrop>
                                                    </Badge>
                                                    <input
                                                        type="file"
                                                        id="hiddenFileInputForProfilePic"
                                                        name="profileImg"
                                                        onChange={e => handlePhotoChange(e, 'photo_url')}
                                                        style={{ display: "none" }}
                                                        accept="image/*"
                                                    />
                                                </>
                                                :
                                                <Avatar
                                                    id="profileImg"
                                                    className={classes.avatarSize}
                                                    src={playerDetails.photoUrl}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => playerDetails.photoUrl && handlePreview([{ url: playerDetails.photoUrl, mimeType: 'IMAGE' }], 0)}
                                                >{getAvatarText(playerDetails.name)}</Avatar>
                                            }
                                        </div>
                                        <div className={classes.profileNameRoot}>
                                            <div style={{ position: 'relative' }}>
                                                {(edit.name && isLoggedInUser()) ?
                                                    <TextField
                                                        id="name"
                                                        autoFocus
                                                        placeholder="Enter Your Name.."
                                                        value={editedDetails.name}
                                                        error={!editedDetails.name && true}
                                                        helperText={!editedDetails.name && "Please enter your Name."}
                                                        onChange={handleChange}
                                                        style={{ margin: '10px 0 20px' }}
                                                        InputProps={{ classes: { input: classes.nameInputStyles, underline: classes.inputUnderine, underline: classes.inputUnderine } }}
                                                    />
                                                    :
                                                    <h3 className={classes.profileName}>
                                                        {formatUserInfoProperty(playerDetails.name)}
                                                    </h3>
                                                }
                                                {isLoggedInUser() && <ActionButtons type="name" className={classes.profileNameActions} style={{ marginTop: !edit.name && 10 }} />}
                                            </div>
                                            <div>
                                                {!isLoggedInUser() &&
                                                    <div style={{ position: 'relative' }}>
                                                        <Button title="Chat" className={classes.chatbutton} onClick={() => handleChatClick(details)} variant="outlined" color="primary"> Chat </Button>
                                                        {
                                                            !isFavourite ?
                                                                <MarkFavButton
                                                                    className={classes.followbutton}
                                                                    onClick={() => onFollowUser(true)}
                                                                />
                                                                :
                                                                <RemoveFavButton
                                                                    className={classes.followbutton}
                                                                    onClick={() => onFollowUser(false)}
                                                                />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.tabroot}>
                                    <PlayerProfileDetail username={userId} />
                                </div>
                            </div>
                            <div className={classes.rightSectionRoot} >
                                <FavouritesLists filtersData={filtersData} />
                            </div>
                        </div>
                    </div>
                }
            </DialogContent>
            <div className={classes.dialogueActions}>
            </div>
        </Dialog >
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    presence: state.presence[`${props.match.params.id}@${process.env.REACT_APP_XMPP_DOMAIN}`],
    ProfileDetails: state.game.filtersData,
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        getTeamProfile: (options) => dispatch(getTeamProfile(options)),
        getSeasonPlayers: (options) => dispatch(getSeasonPlayers(options)),
        getArenaProfile: value => dispatch(getArenaProfile(value)),
        getFavouriteStatus: options => dispatch(getFavouriteStatus(options)),
        setCurrentChat: (options) => dispatch(setCurrentChat(options)),
        uploadFilesToS3: options => dispatch(uploadFilesToS3(options)),
        updateBasicProfile: options => dispatch(updateBasicProfile(options)),
        updateEducationList: value => dispatch(updateEducationList(value)),
        updateWorkList: value => dispatch(updateWorkList(value)),
        deleteWorkExperience: options => dispatch(deleteWorkExperience(options)),
        deleteEducation: options => dispatch(deleteEducation(options)),
        followUser: options => dispatch(followUser(options)),
        unFollowUser: options => dispatch(unFollowUser(options)),










    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamProfileDialog));
