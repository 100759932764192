import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        padding: "14px",
        border: '1px solid #BCBCBD',
        borderRadius: 8,
        color: '#BCBCBD',
        backgroundColor: '#FFF',
        // backgroundColor: '#f0f2f5',
        margin: 0,
        height: 'calc(100% - 30px)',
        minInlineSize: 'auto'
    },
    legend: {
        color: "#000000de",
        textAlign: "left",
        fontSize: "14px",
        letterSpacing: "0px",
        opacity: 1,
        padding: '0 8px',
        backgroundColor: '#FFF',
        border: '1px solid #BCBCBD',
        borderRadius: 4,
        // new Styles
        // display: 'none'
    },
    body: {
        color: 'initial',
        '& > div:first-child': {
            padding: 0
        }
    }
}));

export default ({ title, children, rootStyles, legend }) => {
    const classes = useStyles();
    return (
        <>
            <fieldset className={classNames(classes.root, rootStyles)}>
                <legend className={classNames(classes.legend, legend)}>{title}</legend>
                <div className={classes.body}>
                    {children}
                </div>
            </fieldset>
        </>
    );
};