import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, MenuItem, Menu, Grid, Card, Typography } from "@material-ui/core";
import { CustomCarousel } from "../CustomCarousel";
import IllustrationView from "../../../LiveScores/IllustrationView";
import { ReactComponent as ArrowForward } from "../../../../assets/icons/Arrow-right.svg";
import qs from 'query-string';


const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginTop: 35,
    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        marginTop: 10
    },
    divisin: {
        padding: 10
    },
    divisions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    divisionList: {
        width: '100%',
        maxWidth: '45%',
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        height: 40,
        padding: 1,
        alignItems: 'center',
        marginBottom: 8
    },
    viewAllButton: {
        // margin: '0 10px',
        color: '#727272',
        fontSize: 13,
        float: 'right',
        cursor: 'pointer',
        border: '1px solid #D3D3D3',
        borderRadius: 4,
        padding: '6px 16px',
        textTransform: 'Capitalize',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    card: {
        // padding: '4px 3px',
        '& .card': {
            // width: 324,
            height: 35,
            background: '#FFFFFF',
            border: '1px solid #f0f2f5',
            borderTop: '0px',
            borderLeft: '0px',
            borderRadius: 0,
            padding: '5px 6px 5px 10px',
            '&:hover': {
                backgroundColor: '#F5F5F5'
            },
        },
        '& .cardTitle': {
            fontSize: 14,
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.9)',
            margin: '6px 0px',
            fontWeight: 500,

        },
        border: '1px solid #f0f2f5',
        borderLeft: '0px',
        borderRight: '0px'
    },
    cardHeader: {
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .titleHdr': {
            display: 'flex',
            alignItems: 'center',
            '& .rank': {
                background: '#fff',
                borderRadius: 4,
                textAlign: 'center',
                padding: '3px 8px 1px 8px',
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 13,
                marginRight: 12,
                '& span': {
                    marginLeft: '-3px',
                    fontWeight: 'bold'
                },
                width: 25,
                height: 22
            },
            '& p': {
                margin: 0
            }
        },
        cursor: 'pointer',


    },
    rankingContainer: {
        backgroundColor: '#EBEBEB',
        height: '20px',
        padding: '7px 8px 14px 12px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        '& span': {
        }
    }
}));
const colorsForRank = ['#D6AF36', '#A7A7AD', '#A77044', '#eeeeee', '#a2d6ec', '#e5ac53', '#ecd1a8', '#e4efa3', '#e88e85', '#b95b51']

const MyTeamKpiRanking = ({ history, loading, teams, seasonDetails, teamCoreKpiRanking, teamBonusKpiRanking, teamObj, setTeamObj }) => {
    const classes = useStyles();

    const handleDetailedRanking = (from) => {
        history.push(`/leaguess/c2/leaderboard/team/${from}${history.location?.search}`)
    };

    const handleDetailedKpiRanking = (from, item) => {
        history.push(`/leaguess/c2/leaderboard/team/${from}/${item.kpi_id}${history.location?.search}`)
    };

    const SummaryTable = ({ title, data, from }) => (
        < div className={classes.performance} >
            <div
                className={classes.rankingContainer}
            >
                <span onClick={() => handleDetailedRanking(from)} >{title}</span>
            </div>
            <div>

                <div className={classes.card}>
                    {data.length !== 0 ?
                        <Grid container >
                            {
                                data?.map((item, index) =>
                                    <Grid
                                        key={index}
                                        item
                                        lg={3} md={6} sm={6} xs={12}>
                                        <Card className='card' variant="outlined">
                                            <div className={classes.cardHeader} onClick={() => handleDetailedKpiRanking(from, item)}>
                                                <div className="titleHdr">
                                                    {item?.rank &&
                                                        <div className="rank" style={{ backgroundColor: item.rank === 1 ? '#D6AF36' : item.rank === 2 ? '#A7A7AD' : item.rank === 3 ? '#A77044' : '#f1f1f1', color: item.rank < 3 ? '#ffffff' : '#000' }}>
                                                            <span>{item.rank}{item?.rank === 1 ? 'st' : item?.rank === 2 ? 'nd' : item?.rank === 3 ? 'rd' : item?.rank > 3 ? 'th' : null}</span>
                                                            {/* <span>1st</span> */}
                                                        </div>
                                                    }
                                                    <Typography className='cardTitle'> {item.kpi_name}</Typography>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                )
                            }
                        </Grid>
                        : <p style={{ padding: '100px 24px', textAlign: 'center' }}>No Data Available</p>
                    }
                </div>
            </div>
        </div >
    );



    const onclickDiv = (value) => {
        setTeamObj(value);
    }

    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <h2>My Team Ranking</h2>
            </div>
            <div className={classes.performance}>
                <div className={classes.divisin}>
                    {(teams.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                        <>
                            <div className={classes.divisions}>
                                <div className={classes.divisionList}>
                                    <CustomCarousel data={teams} selectesData={teamObj ? teamObj : teams[0]} onclickDiv={onclickDiv} />
                                </div>

                            </div>
                            {(teamCoreKpiRanking.length > 0 || teamBonusKpiRanking.length > 0) ?

                                <div className="">
                                    <div>
                                        {
                                            <>
                                                <div className=''>
                                                    {teamCoreKpiRanking.length > 0 &&
                                                        <>
                                                            <SummaryTable
                                                                data={teamCoreKpiRanking}
                                                                title={`${seasonDetails?.labels_config?.kpis?.core} Ranking`}
                                                                from="core"
                                                            />
                                                            <p className="table-footer">
                                                                <span className={classes.viewAllButton} onClick={() => handleDetailedRanking("core")}>View detailed Ranking <ArrowForward /></span>
                                                            </p>
                                                        </>
                                                    }
                                                    {teamBonusKpiRanking.length > 0 &&
                                                        <>
                                                            <SummaryTable
                                                                data={teamBonusKpiRanking}
                                                                title={`${seasonDetails?.labels_config?.kpis?.bonus} Ranking`}
                                                                from="bonus"
                                                            />
                                                            <p className="table-footer">
                                                                <span className={classes.viewAllButton} onClick={() => handleDetailedRanking("bonus")}>View detailed Ranking <ArrowForward /></span>
                                                            </p>
                                                        </>
                                                    }


                                                </div>
                                            </>
                                        }

                                    </div>
                                </div >
                                :
                                < IllustrationView loading={loading} style={{ marginTop: '50px' }} />}
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    seasonDetails: state.game.seasonDetails

});


export default withRouter(connect(mapStateToProps, null)(MyTeamKpiRanking));