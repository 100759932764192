import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ArenaList from "../Arena/ArenaList";
import { withRouter } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f5f5f5",
  }
}));

const ViewProfilePosts = props => {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  // const getCurrentUsername = () => {
  //   if (props.match.params.id) {
  //     return props.match.params.id
  //   }
  //   else {
  //     return props.user.username;
  //   }
  // }

  useEffect(() => {
    if (props.username)
      setUsername(props.username);
    else
      setUsername(props.keycloak.preferred_username);
  }, [props.username]);

  return (
    <div className={classes.root}>
      {props.username && <ArenaList username={props.username} history={props.history} newPost={props.newPost} handleOnNewPost={props.handleOnNewPost} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  keycloak: state.keycloak.decoded_token
});


export default withRouter(connect(mapStateToProps, null)(ViewProfilePosts));
