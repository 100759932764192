import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import loading, { setLoading } from "../../ducks/loading";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat, decodeEnum } from "../../helpers";
import { getTeamMatchStatistics, getTeamMatchData } from "../../ducks/game";
import IllustrationView from "./IllustrationView";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0px 0 20px',
        '& .livescore-table': {
            // backgroundColor: '#f0f2f5',
            // borderRadius: 8
        },
        '& .livescore-table td div': {
            border: 'none !important'
        }
    },
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        margin: '0 0 10px',
        fontSize: 12,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    button: {
        // height: 47,
        margin: '0 10px',
        '&:last-child': {
            marginRight: 0
        }
    }
}));

const lastRow = {
    totalTeam1Golas: 0,
    totalTeam2Golas: 0,
}

const TeamsMatchSummary = ({ setLoading, loading, matchId = '', matchDetails, userDetails, getTeamMatchStatistics, getTeamMatchData, matchData }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        if (matchId) {
            setLoading(true);
            getTeamMatchStatistics({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
        }
        else {
            getTeamMatchData([]);
            setData([])
        }
    }, [matchId]);

    const isRuleBasedOnActuals = (kpi) => {
        return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
    }

    const getTotalScore = (data, teamId) => {
        const result = [...data].map(obj => obj.teams?.find(team => team.team_id === teamId)).reduce((a, b) => a + b.score, 0);
        return getNumberFormat(userDetails?.countryCode, result, decimalView) + ` ${decodeEnum(data[0]?.scoring_unit) + (result === 1 ? '' : 's')}`;
    }

    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = {
                    ...item,
                    kpi_long_name: item.kpi_name,
                    kpi_name: item.kpi_code || item.kpi_name
                    // , teams: item.teams.sort((a, b) => (a.team_id < b.team_id) ? 1 : -1)
                };
                obj.teams.forEach((el, i) => {
                    obj[`${el.team_id}-target`] = el.target;
                    obj[`${el.team_id}-actual`] = el.actual;
                    obj[`${el.team_id}-achieved`] = el.acheived;
                    obj[`${el.team_id}-unit_of_measurement`] = obj.unit_of_measurement;
                    obj[`${el.team_id}-unit`] = obj.unit;
                    obj[`${el.team_id}-scoring_rule`] = obj.scoring_rule;
                    obj[`${el.team_id}-goals`] = el.score;
                    obj[`${el.team_id}-based_on`] = obj.based_on;

                    if (index === 0) {
                        headers.push(el);
                    }

                    obj.columns = [...(obj.columns || []), {
                        title: `${el.team_name}`,//-target
                        field: `${el.team_id}-target`,
                        team_id: el.team_id,
                        editable: 'never',
                        // emptyValue: '--',
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        hidden: isRuleBasedOnActuals(el),
                        render: (rowData) => rowData[`${el.team_id}-target`] !== undefined ? (
                            (['SR4', 'SR5', 'SR6'].includes(rowData[`${el.team_id}-scoring_rule`]) && rowData[`${el.team_id}-based_on`] === 'ACTUALS') ? '0' :
                                rowData[`${el.team_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.team_id}-target`]} />
                                    : rowData[`${el.team_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.team_id}-target`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.team_id}-unit`], rowData[`${el.team_id}-target`], decimalView)
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.team_id}-target`], decimalView)} ${rowData[`${el.team_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.team_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.team_id}-target`], rowData[`${el.team_id}-unit`]) : ''}`
                        )
                            : (rowData.tableData.id === response.length - 1) ? '' : ''
                        // getNumberFormat(userDetails?.countryCode, rowData[`${el.team_id}-target`])
                    }, {
                        title: `${el.team_name}`, //-Actual
                        field: `${el.team_id}-actual`,
                        editable: 'never',
                        team_id: el.team_id,
                        // emptyValue: '--',
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        render: (rowData) => rowData[`${el.team_id}-actual`] !== undefined ? (
                            rowData[`${el.team_id}-unit_of_measurement`] === 'CURRENCY' ?
                                <GetCountryCurrencyFormat type="currency" value={rowData[`${el.team_id}-actual`]} />
                                : rowData[`${el.team_id}-unit_of_measurement`] === 'NUMBER' ?
                                    <GetCountryCurrencyFormat type="number" value={rowData[`${el.team_id}-actual`]} />
                                    // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.team_id}-unit`], rowData[`${el.team_id}-actual`], decimalView)
                                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.team_id}-actual`], decimalView)} ${rowData[`${el.team_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.team_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.team_id}-actual`], rowData[`${el.team_id}-unit`]) : ''}`
                        )
                            : (rowData.tableData.id === response.length - 1) ? '' : ''
                        // getNumberFormat(userDetails?.countryCode, rowData[`${el.team_id}-actual`])
                    }, {
                        title: `${el.team_name}`,//-Ach %
                        field: `${el.team_id}-achieved`,
                        editable: 'never',
                        team_id: el.team_id,
                        // emptyValue: '--',
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        hidden: isRuleBasedOnActuals(el),
                        render: (rowData) => rowData[`${el.team_id}-achieved`] !== undefined ? (
                            ((rowData[`${el.team_id}-scoring_rule`] === 'SR1' || (['SR4', 'SR5', 'SR6'].includes(rowData[`${el.team_id}-scoring_rule`]) && rowData[`${el.team_id}-based_on`] === 'ACHIEVEMENT')) ? getNumberFormat(userDetails?.countryCode, rowData[`${el.team_id}-achieved`], decimalView) + '%' : '0')
                        )
                            : (rowData.tableData.id === response.length - 1) ? '' : ''
                    }, {
                        title: `${el.team_name}`,//-goals
                        field: `${el.team_id}-goals`,
                        editable: 'never',
                        team_id: el.team_id,
                        // emptyValue: '--',
                        hideInTemplate: true,
                        align: 'center',
                        cellStyle: { whiteSpace: 'nowrap' },
                        render: (rowData) => rowData[`${el.team_id}-goals`] !== undefined ? (
                            getNumberFormat(userDetails?.countryCode, rowData[`${el.team_id}-goals`], decimalView) + ` ${decodeEnum(rowData.scoring_unit) + (rowData[`${el.team_id}-goals`] === 1 ? '' : 's')}`
                        )
                            : (rowData.tableData.id === response.length) ? <span style={{ fontWeight: 600 }}>{getTotalScore(response, el.team_id)}</span> : ''
                    }]
                });
                return [...result, { ...obj }];
            }, []);
            console.log('finalResult dynamicHeaders ', finalResult, headers);
            if (finalResult.length > 0) {
                setData((finalResult.length > 0) ? [...finalResult, { ...lastRow }] : finalResult);
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                getTeamMatchData({ resultGoals: { ...lastRow }, data: finalResult, headers: headers, columns: [...finalResult[0].columns] })
            }
            else {
                getTeamMatchData([]);
                setData([])
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const columns = [
        {
            title: "KPI",
            field: "kpi_name",
            render: (rowData) => {
                // console.log('props ', rowData, data, matchDetails);
                return ((data.length > 0) && rowData.tableData.id === data.length - 1) ? <span style={{ fontWeight: 600 }}>Total  </span> : (<Tooltip title={rowData.kpi_long_name}><span>{rowData.kpi_name}</span></Tooltip> || '--')
            }
        },
    ]

    return (
        <>
            {
                (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                    <div style={{ marginTop: 10 }}>
                        <p className="table-title decimal-switch-root">
                            <p>
                                <span>Match summary : <span style={{ color: '#000' }}>Team performances</span></span>
                            </p>
                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                        </p>
                        <div className={classes.root}>
                            {/* <div className="material-table"> */}
                            <LiveScoresMuiTable
                                title={'Match Summary'}
                                columns={[...columns, ...dynamicColumns]}
                                data={data}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                    toolbar: false,
                                    pageSizeOptions: [5, 10, 25],
                                    pageSize: 25,
                                    paging: false
                                }}
                                localization={{ body: { emptyDataSourceMessage: <p style={{ textAlign: 'center' }}>No data available</p> } }}
                                components={{
                                    Header: props => (
                                        <TableHead className="custom-headers">
                                            <TableRow>
                                                <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                                {/* {console.log('dynamicHeaders ', dynamicHeaders)} */}
                                                {
                                                    dynamicHeaders.map((obj, index) => (
                                                        <TableCell colSpan={isRuleBasedOnActuals(obj) ? 2 : 4} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                            {obj.name} {obj.captain && `[C: ${obj.captain}]`}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                            <TableRow>
                                                {
                                                    dynamicHeaders.map((obj) => (
                                                        <>
                                                            {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target </TableCell>}
                                                            <TableCell align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>
                                                            {!isRuleBasedOnActuals(obj) && <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                            <TableCell align="center">Goals</TableCell>
                                                        </>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                    )
                                }}
                            />
                            {/* </div> */}
                        </div>
                    </div>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    matchData: state.game.teamMatchData
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamMatchStatistics: options => dispatch(getTeamMatchStatistics(options)),
        getTeamMatchData: options => dispatch(getTeamMatchData(options))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamsMatchSummary));
