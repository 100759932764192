import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { Grid, Breadcrumbs, Tooltip } from '@material-ui/core';
import ProjectList from './ProjectList';
import KanbanBoard from './KanbanBoard';
import TaskDetail from './TaskDetail'
import IssueDialog from './IssueDialog';
import WorkLogDailog from './WorkLogDailog'
import moment from 'moment';
import ShareTaskToChat from './ShareTaskToChat';
import firebase from "firebase";
import CustomDialogComponent from '../ChaseMeet/CustomDialogComponent';
import BoardDetails from './BoardDetailsDrawer';
import { ReactComponent as NegativeAgeing } from "../../assets/icons/Red_ageing.svg";
import { ReactComponent as PositiveAgeing } from "../../assets/icons/Green_ageing.svg";
import { ReactComponent as CurrentAgeing } from "../../assets/icons/Orange_ageing.svg";
import { ReactComponent as CompletedAgeing } from "../../assets/icons/Grey_Ageing.svg";



const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: '#f5f5f5',
        width: '100%',
        maxHeight: 'calc(100vh - 95px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        // padding: '35px 35px 0px',
        display: 'flex',
        // padding: "0px 35px",
        padding: '35px 50px 0px 50px',
        [theme.breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 100px)',
            // maxWidth: `calc(100vw - 150px)`,
            padding: '20px 15px',
        },
    },
    gridContainer: {
        // width: '80%',
        // width: '92%',
        width: '100%',
        // margin: '0 9px',
        // [theme.breakpoints.down('md')]: {
        //     // width: '85%',
        //     width: '90%',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width: '90%',
        // },
        // [theme.breakpoints.down('xs')]: {
        //     width: '100%',
        // },
    },
    favoritesRoot: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    searchGrid: {
        background: "#ffffff",
        borderRadius: 6,
        marginBottom: 20
    }
}))
export const getAgeing = (targetDate) => {
    return targetDate ? Math.floor(moment(targetDate).endOf('day').diff(moment(new Date()), 'hours') / 24) : null
}
export const completedAge = (targetDate, completedDate) => {
    return (targetDate && completedDate) ? Math.floor(moment(targetDate).endOf('day').diff(moment(completedDate), 'hours') / 24) : null
}
export const ShowAgeingInfo = ({ targetDate }) => {
    const age = getAgeing(targetDate);
    return (<div style={{ display: 'inline-flex', alignItems: 'center' }}>
        {
            age != null ?
                age > 0 ?
                    <><Tooltip title="Age"><PositiveAgeing style={{ height: 15, width: 'inherit' }} /></Tooltip> <span style={{ margin: "0px 5px", fontSize: '13px' }}>{age}</span></> :
                    age === 0 ?
                        <><Tooltip title="Age"><CurrentAgeing style={{ height: 15, width: 'inherit' }} /></Tooltip>  <span style={{ margin: "0px 5px", fontSize: '13px' }}>{age}</span></> :
                        <><Tooltip title="Age"><NegativeAgeing style={{ height: 15, width: 'inherit' }} /></Tooltip> <span style={{ margin: "0px 5px", fontSize: '13px' }}>{age}</span></>
                : null
        }
    </div>
    )
}
export const CompletedItemAgeing = ({ targetDate, completedDate }) => {
    const age = completedAge(targetDate, completedDate);

    return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            {
                age != null ?
                    <><Tooltip title="Age">
                        <CompletedAgeing style={{ height: 15, width: 'inherit' }} /></Tooltip> <span style={{ margin: "0px 5px", fontSize: '13px' }}>{age}</span></>
                    : null
            }
        </div>)
}
export const KanbanLayout = (props) => {
    const classes = useStyles();
    const [newTask, setNewTask] = useState(null);
    const [workLog, setWorkLog] = useState(null);

    const { path, url, params } = useRouteMatch();

    useEffect(() => {
        if (firebase.messaging.isSupported()) {
            const analytics = firebase.analytics();
            analytics.logEvent('page_view', {
                page_location: window.location.href,
                page_path: window.location.pathname,
                page_title: 'PMS-Board'
            });
        }
    }, []);

    const handleNewTask = (id) => {
        setNewTask(id)
    }
    const hadleWorkLogs = (id) => {
        setWorkLog(id)
    }
    return (
        <>
            <div id="project-list-root" className={classes.root}>
                <Grid container className={classes.gridContainer}>
                    <Switch>
                        <Route path={`${path}/:id`} >
                            <Switch>
                                <Route path={`${path}/:id/board/:taskid/subtask/:subTaskId`} exact >
                                    <TaskDetail isSubTask={true} workLog={workLog} handleWorkLog={hadleWorkLogs} />
                                </Route>
                                <Route path={`${path}/:id/board/:taskid`} exact >
                                    <TaskDetail newTask={newTask} handleNewTask={handleNewTask}
                                        workLog={workLog} handleWorkLog={hadleWorkLogs} />
                                </Route>
                                <Route path={`${path}/:id/board`} exact >
                                    <KanbanBoard newTask={newTask} handleNewTask={handleNewTask} />
                                </Route>
                            </Switch>
                        </Route>
                        <Route path={path} exact >
                            <ProjectList />
                        </Route>
                        <Redirect to={'/'} />
                    </Switch>
                </Grid>
                <IssueDialog handleNewTask={handleNewTask} />
                <WorkLogDailog handleWorkLog={hadleWorkLogs} />
                <CustomDialogComponent />
                <ShareTaskToChat />
            </div>
            <BoardDetails />
        </>
    )
}
