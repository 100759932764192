import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getLiveMatches, getRoundsList, getMatchesList } from "../../ducks/game";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { setLoading } from "../../ducks/loading";
import { CustomCard } from './CustomCard';
import { decodeEnum, formatDate, getSearchValue, setSearchQuery } from "../../helpers";
import { RoundsListGroup } from '../LiveScores/RoundsListView';
import LiveMatches from './LiveMatches';
import MatchDetails from '../LiveScores/MatchDetails';
import { Grid } from '@material-ui/core';
import IllustrationView from '../LiveScores/IllustrationView';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fafafa',
        width: '100%',
        height: 'calc(100vh - 60px)',
        overflowY: 'auto',
    },
}));

const AllMatches = ({ filtersData, rounds, setFiltersData, userInfo, loading, history, getRounds }) => {
    const classes = useStyles();

    useEffect(() => {
        const searchValue = getSearchValue(history, 'round');
        // if (rounds.length === 0) {
        //     getRounds(filtersData?.season?._id)
        // }
        if (filtersData?.season?._id && rounds) {
            if (!filtersData?.round?._id && searchValue) {
                setFiltersData(prevState => ({ ...prevState, match: null, round: (searchValue ? rounds?.find(obj => obj._id === searchValue) : rounds[0]) }));
            }
        }
    }, [filtersData?.season?._id, rounds]);

    const handleRoundClick = (round) => {
        setSearchQuery(history, { round: round?._id });
        setFiltersData(prevState => ({ ...prevState, round }));
    }

    return (
        <div className={classes.root}>
            {(filtersData?.season?.season_format === 'HEAD_TO_HEAD' && filtersData?.round?._id && filtersData?.season?.fixture_format !== 'MANUAL_FORMAT_WITHOUT_ROUND') ?
                <>
                    <div >
                        <RoundsListGroup rounds={rounds} selectedRound={filtersData.round} onRoundClick={handleRoundClick} style={{ margin: '20px auto', maxWidth: 'fit-content' }} />
                    </div>
                    <div style={{ width: 'calc(100% - 100px)', padding: '0 50px', display: 'flex', marginTop: 25 }}>
                        <div style={{ minWidth: 250, maxWidth: 300, marginRight: 25 }}>
                            <Grid container spacing={3} style={{ alignItems: 'flex-end', position: 'relative' }} >
                                <LiveMatches isAllMatches={true} filtersData={filtersData} setFiltersData={setFiltersData} userInfo={userInfo} />
                            </Grid>
                        </div>
                        <div style={{ width: "calc(100% - 275px)" }}>
                            <MatchDetails filtersData={filtersData} setFiltersData={setFiltersData} match={{ params: { matchId: filtersData.match?._id } }} />
                        </div>
                    </div>
                </>
                :
                <IllustrationView loading={loading} style={{ marginTop: '100px' }} />
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading
});



const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllMatches));
