import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LiveMatches from "../LiveMatches";
import OverallOrgStats from "../OverallOrgStats";
import PlayerStandings from "../PlayerStandings";
import TeamStandings from "../TeamStandings";
import { setLoading } from "../../../ducks/loading";
import { CustomCard } from "../CustomCard";

const NonPlayerDashboard = ({ setLoading, filtersData, userInfo, natigateToAllStats, natigateToLiveScores, navigateToTeamLeaderboard, navigateToPlayerLeaderboard, navigateToAllMatches }) => {

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <CustomCard cardTitle={"Overall Org Stats"} linkText="Stats" onLinkClick={natigateToAllStats}>
                        <OverallOrgStats filtersData={filtersData} />
                    </CustomCard>
                </Grid>

                <Grid item md={6}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <CustomCard cardTitle="Team Standings" linkText="Full List" onLinkClick={navigateToTeamLeaderboard}>
                                <TeamStandings isSeasonLevel={true} filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                        <Grid item md={6}>
                            <CustomCard cardTitle="Player Standings" linkText="Full List" onLinkClick={navigateToPlayerLeaderboard} >
                                <PlayerStandings isSeasonLevel={true} filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    {/* <Grid container spacing={3} style={{ alignItems: 'flex-end', position: 'relative', marginTop: 20 }} > */}
                        {(filtersData?.season?.season_format === 'HEAD_TO_HEAD') &&
                            <LiveMatches filtersData={filtersData} userInfo={userInfo} navigateToAllMatches={navigateToAllMatches} />
                        }
                    {/* </Grid> */}
                </Grid>

            </Grid>

        </>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),



    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NonPlayerDashboard));
