import React from 'react';
import { Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { formatDate } from '../../helpers';

export const DurationTooltip = ({ trend, matchType = '' }) => (
    <Tooltip interactive arrow title={
        <p>
            <p style={{ fontSize: 12, fontWeight: 600 }}>{matchType} : {trend.duration}</p>
            {
                trend.durations ?
                    [
                        trend.durations?.map(obj => (
                            <p style={{ fontSize: 11 }}><span style={{ fontWeight: 600 }}>{obj.label}</span> : {formatDate(obj.startDate)} - {formatDate(obj.endDate)}</p>
                        ))
                    ] : <p>No Data Available</p>
            }
        </p>
    }>
        <Info fontSize="small" />
    </Tooltip>
);