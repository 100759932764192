import React from 'react';
import { Dialog, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import PDFViewer from './PDFViewer';
import { Cancel, Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: '100%',
    minWidth: '100%',
    margin: 0,
    borderRadius: 0
  },
  closeButton: {
    // position: 'absolute',
    // right: 10,
    // top: 12,
    // zIndex: 1000,
    position: 'absolute',
    right: 10,
    // left: 12,
    top: 13,
    color: '#FFF',
    backgroundColor: '#00000080',
    padding: 0
  },
  dialogTitle: {
    padding: '12px 24px',
    fontWeight: 600,
    borderBottom: '1px solid #F0F2F5'
  }
}));

const PDFViewerDialog = ({ open = false, file = '', postedBy = '', handleCloseDialog = () => { }, ...other }) => {
  const classes = useStyles();

  const handleClose = () => {
    handleCloseDialog();
  }

  return (
    <Dialog
      scroll={"body"}
      classes={{ paper: classes.dialogPaper, paperScrollPaper: classes.dialogPaperScrollPaper }}
      disableBackdropClick
      // maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      onClose={handleClose}
    >
      {postedBy && <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
        {postedBy}
      </DialogTitle>}
      <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={() => handleClose()}>
        <Cancel />
      </IconButton>
      <PDFViewer file={file} />
    </Dialog>
  );
}

export default PDFViewerDialog;