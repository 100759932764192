import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Checkbox, fade, IconButton, InputBase, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat, getSearchValue, seasonKpiPositionsSort } from "../../helpers";
import { setLoading } from "../../ducks/loading";
import { getRoundPlayerStatistics, getPlayerRaceLiveScore } from "../../ducks/game";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import IllustrationView from "./IllustrationView";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import { GetDates } from ".";
import { ChevronLeft } from "@material-ui/icons";
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";
import NotificationDialog from "../GlobalComponents/NotificationDialog";
import SearchIcon from '@material-ui/icons/Search';
import PlayerSummaryDivisionWise from "./PlayerSummaryDivisionWise";
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '10px 0',
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600
    },
    title: {
        // margin: '0 0 10px',
        fontSize: 11,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    viewAllButton: {
        margin: 0,
        color: 'blue', // #112386
        fontSize: 11,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    searchIcon: {
        color: '#0000004d',
        padding: '0px 8px 0px 25px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.35),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.5),
        },
        width: 'auto',
        maxWidth: 180,
        minWidth: 180,
    },
    inputRoot: {
        color: 'initial',
        height: '100%',
        width: 'inherit'
    },
    inputInput: {
        border: '1px solid #0000003a',
        borderRadius: '3px',
        height: 12,
        margin: '0 10px',
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        fontSize: '12px'
    },
    notification: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        height: '30px',
        fontSize: '12px',
    },
}));

const RoundPlayerSummaryViewAll = ({ setLoading, history, seasonFormat = '', roundId = '', matchId, filtersData, userDetails, getRoundPlayerStatistics, getPlayerRaceLiveScore, isTeamRacePlayerSprint, keycloak, Filter, roles, teams, seasonKpiPostions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataByDiv, setDataByDiv] = useState(null);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);
    const { path } = useRouteMatch();
    const [searchPlayer, setSearchPlayer] = useState([]);
    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');
    const [checkColumn, setCheckColumn] = React.useState([{ isChecked: false }]);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [selectedEmps, setSelectedEmps] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);

    useEffect(() => {
        if (tableData) {
            setSelectedEmps(tableData.filter(ele => ele.isChecked))
        }

    }, [tableData]);

    useEffect(() => {
        if (roundId) {
            setLoading(true);
            // if (seasonFormat === 'TEAM_RACE')
            //     getTeamRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
            // else
            getRoundPlayerStatistics({ roundId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, team_id: Filter.team?._id, role_id: Filter.role?.role_id } });
        }
    }, [roundId, Filter.role, Filter.team]);

    useEffect(() => {
        console.log(matchId)
        if (matchId) {
            setLoading(true);
            getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, team_id: Filter.team?._id, role_id: Filter.role?.role_id } });
        }
    }, [matchId, Filter.role, Filter.team])

    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, selected: item.employee_id === userDetails?.username, totalPoints: item.total_points, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'summary_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                // let totalPoints = 0;
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    // obj['totalPoints'] = totalPoints += el.points;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE') ? el.score || 0 : el.points || 0);
                    if (index === 0) {
                        headers.push(el);
                    }
                    if (index === 0) {
                        const mapped_headers = el.summary_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // hidden: isRuleBasedOnActuals(el),
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('TARGET'),
                            // cellStyle: { color: '#333' },
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`, //-Actual
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            cellStyle: el?.based_on === 'ACTUAL' ? { color: el.status_code } : {},
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: el?.based_on === 'ACHIEVEMENT' ? { color: el.status_code } : {},
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: el?.based_on === 'ACTUAL' ? { color: el.status_code } : {},
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: el?.based_on === 'ACHIEVEMENT' ? { color: el.status_code } : {},
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 9 },
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
            }, []);
            // console.log('finalResult dynamicHeaders ', finalResult[0]?.columns);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                // if (filtersData.season?.divisions_enabled)
                //     setDataByDiv(groupBy(finalResult, 'division_name'));
                // else
                setData(finalResult);
                setTableData(finalResult);
                setSearchPlayer(finalResult);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setSearchPlayer([]);
                setTableData([]);
                // setDataByDiv(null);
            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
            // setDataByDiv(null);
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setDataByDiv(null);
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }
    const handleChange = (row, rowIndex, type) => {
        if (type === 'close') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[0].isChecked = false
            tableDataDup.forEach(player => player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            setTableData([...tableDataDup])


        }
        else if (type === 'all') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[rowIndex].isChecked = !checkColumnDup[rowIndex].isChecked
            tableDataDup.forEach(player => checkColumnDup[rowIndex].isChecked === true ? player.isChecked = true : player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            setTableData([...tableDataDup])

        }
        else {
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            tableDataDup[rowIndex].isChecked = !tableDataDup[rowIndex].isChecked
            setTableData([...tableDataDup])
        }

    };

    const handleCloseNotificationDialog = () => {
        setOpenConfirmDialog(false);
        handleChange('', '', 'close');
        setSelectedEmps([]);
    }

    const handleKeySearch = (searchedVal) => {
        const filteredRows = searchPlayer.filter((row) => {
            return row.player_name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });

        if (searchedVal.length === 0) {
            setData(searchPlayer);
            setTableData(searchPlayer);
        }
        else {
            setData(filteredRows);
            setTableData(filteredRows);
        }
    };

    const onClickBuzz = () => {
        if (selectedEmps.length === 0) {
            enqueueSnackbar(` Make sure at least one user is selected`, {
                variant: "error",
                preventDuplicate: true
            });
        }
        else {
            setOpenConfirmDialog(true);
        }


    }


    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            cellStyle: { maxWidth: 200, width: 200 },
            render: (rowData) => rowData.player ? (
                <div style={{ fontSize: 12 }} className={classes.nameRoot}>
                    <AvatarComponent type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            hidden: seasonFormat === 'PLAYER_RACE',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--'
        },
        {
            title: "Points",
            field: "totalPoints",
            editable: 'never',
            // cellStyle: { color: '#333' },
            render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
        }
    ];

    const handleGoBack = () => {
        history.push(`/leaguess/c2/live-scores/player${history.location?.search}`);

    };

    const SummaryTable = ({ data, isAdmin, checkColumn, handleChange }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            isAdmin={isAdmin}
            checkColumn={checkColumn}
            handleChange={handleChange}
            options={{
                search: false,
                actionsColumnIndex: -1,
                showTitle: false,
                addRowPosition: 'first',
                emptyRowsWhenPaging: false,
                toolbar: false,
                pageSizeOptions: [5, 10, 25],
                pageSize: 25,
                paging: false
            }}
            // spanning={true}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {isAdmin &&

                                <TableCell rowSpan={2} style={{ maxWidth: 70, width: 70 }}>
                                    <Checkbox
                                        style={{
                                            width: 5,
                                            height: 5,
                                        }}
                                        checked={checkColumn[0].isChecked}
                                        onChange={() => handleChange('', 0, 'all')}
                                        color="primary"
                                        size="small"

                                    /></TableCell>}
                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>

                            {seasonFormat !== 'PLAYER_RACE' && <TableCell rowSpan={2}>{props.columns[3]?.title}</TableCell>}
                            <TableCell rowSpan={2}>{props.columns[4]?.title}</TableCell>
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                        <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                    </TableCell>
                                ))
                            }
                            {/* <TableCell rowSpan={2}>{props.columns[props.columns.length - 1]?.title}</TableCell> */}
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeaders.map((obj) => (
                                    <>
                                        {
                                            obj?.summary_attributes.length ?
                                                <>
                                                    {
                                                        obj?.summary_attributes.map(ele => (
                                                            ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                        ))
                                                    }
                                                    {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                </>
                                                :
                                                <>
                                                    {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                    {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" >Actual</TableCell>}
                                                    {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                </>
                                        }
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    )

    return (
        <div style={{ marginBottom: 20, width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
                {/* {<p style={{ margin: '0px 0px 0px 7px', color: '#000000c9', fontWeight: 600 }}>KPI : {kpi?.name.toUpperCase()}</p>} */}
            </div>
            {
                (data.length === 0 && !filtersData.season?.divisions_enabled) ? < IllustrationView loading={false} style={{ marginTop: '50px' }} /> :
                    [
                        (filtersData.season?.divisions_enabled) ?
                            <>
                                {
                                    [...filtersData.season?.divisions].filter(obj => obj._id === getSearchValue(history, 'division')).map((ele, index) => (
                                        <PlayerSummaryDivisionWise key={ele._id} index={index} divisionObj={ele} limit={5000} columns={columns} filtersData={filtersData} type="VIEWALL" decimalView={decimalView} setDecimalView={setDecimalView} roles={roles} teams={teams} Filter={Filter} divId={ele._id} />
                                    ))
                                }
                            </>
                            :
                            <>
                                <div className={classes.root}>
                                    <p className="table-title decimal-switch-root">
                                        <p>
                                            <span>Player performance : <span style={{ color: '#000' }}>{roundId ? filtersData.round?.name : isTeamRacePlayerSprint ? filtersData.playermatch?.title : filtersData.match?.title}</span> <GetDates filtersData={isTeamRacePlayerSprint ? { ...filtersData, match: filtersData.playermatch } : filtersData} /></span>
                                        </p>
                                        <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
                                        {isAdmin && <div>
                                            <Button variant="contained" className={classes.notification} disabled={tableData.filter(obj => obj.isChecked).length === 0} color="primary" onClick={() => setOpenConfirmDialog(true)}>
                                                Buzz
                                            </Button>
                                        </div>}
                                        <div className={classes.search}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase
                                                placeholder="Search Player.."
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                                inputProps={{ 'aria-label': 'search' }}
                                                onChange={(e) => { handleKeySearch(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    {data.length === 0 ? < IllustrationView loading={false} style={{ marginTop: '50px' }} /> :


                                        <SummaryTable
                                            data={tableData}
                                            checkColumn={checkColumn}
                                            handleChange={handleChange}
                                            isAdmin={isAdmin}
                                        />}
                                </div>
                                {
                                    openConfirmDialog && <NotificationDialog
                                        open={openConfirmDialog}
                                        handleClose={handleCloseNotificationDialog}
                                        selectedEmps={selectedEmps}
                                        setSelectedEmps={setSelectedEmps}
                                        handleUncheck={handleChange}
                                        from="LiveScores"
                                    />

                                }
                            </>
                    ]
            }

        </div>)
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    keycloak: state.keycloak,
    seasonKpiPostions: state.game.seasonKpiPostions

});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundPlayerStatistics: options => dispatch(getRoundPlayerStatistics(options)),
        getPlayerRaceLiveScore: options => dispatch(getPlayerRaceLiveScore(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoundPlayerSummaryViewAll));