import React from 'react';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import Moment from 'react-moment';
import { ReactComponent as Like } from "../../assets/icons/heart.svg";
import { ReactComponent as Liked } from "../../assets/icons/filled-heart.svg";
import { convertUnicode, highlightMentions, postCreatedAtFormat } from '../../helpers';
import UserProfileLink from '../UserProfileCard/UserProfileLink';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        padding: "15px 16px"
    },
    detailsRoot: {
        width: "100%",
    },
    comments: {
        position: 'relative',
        borderRadius: "10px",
        //   background: "#EEEEEE",
        // marginLeft: "10px",
        wordBreak: "break-word",
        color: "#262626",
        fontSize: "14px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "50px",
        whiteSpace: 'pre-line'
    },
    commentedBy: {
        textTransform: "capitalize",
        marginRight: "5px",
        fontWeight: "bold",
        cursor: 'pointer'
    },
    actionsRoot: {
        display: "flex",
        alignItems: "center",
        padding: "0px 15px",
        fontSize: 12,
        color: '#8e8e8e'
    },
    likeComment: {
        position: 'absolute',
        top: 6,
        right: 20,
        padding: 0,
        '&:hover': {
            background: 'none'
        },
        '& svg': {
            width: 15,
            height: 14
        }
    },
    likeCommentAction: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    commentDivider: {
        width: 1,
        margin: '0 20px',
        height: 15,
        backgroundColor: '#c1c1c1'
    },
    avatarStyles: {
        width: 32,
        height: 32,
        fontSize: 16
    },
    likesCount: {
        margin: '0px 10px 0px 25px',
        cursor: 'pointer'
    },
    replyButton: {
        fontSize: 'inherit',
        color: 'inherit',
        padding: 0
    },
    viewRepliesButton: {
        border: 'none',
        margin: '0px 15px',
        padding: 0,
        fontSize: 12,
        color: '#8e8e8e',
        background: 'none',
        textTransform: 'none',
        '&:hover': {
            background: 'none'
        },
    }
}));

const ArenaCommentCard = ({ comment, index, handleCommentLike, handlereplyLike, handleReplyToComment = () => { }, handleViewCommentReplies, onLikesClick = () => { }, postId, ...others }) => {

    let result = convertUnicode(comment.comment);
    const d = comment?.tags?.map(obj => { result = highlightMentions(convertUnicode(result), obj.name) })

    // console.log('ArenaCommentCard ', result, comment);
    const classes = useStyles();
    return (
        <>
            <div className={classes.root} {...others}>
                <div>
                    <AvatarComponent name={comment.commentedBy} username={comment.user_id} url={comment.photoUrl} className={classes.avatarStyles} />
                </div>
                <div className={classes.detailsRoot}>
                    <div className={classes.comments}>
                        <UserProfileLink username={comment.user_id} name={comment.commentedBy} />
                        <span dangerouslySetInnerHTML={{ __html: result }} />
                        <IconButton disableTouchRipple className={classes.likeComment} onClick={() => handleCommentLike(comment.commentId, index, "Like")} >
                            {comment.currentUserReaction ? <Liked /> : <Like />}
                        </IconButton>
                    </div>
                    <div className={classes.actionsRoot}>
                        <Moment fromNow ago format={postCreatedAtFormat(comment.created_at)}>{comment.created_at}</Moment>
                        {comment.reactionCount > 0 && <p className={classes.likesCount} onClick={() => onLikesClick(comment.commentId, 'COMMENT')}>{comment.reactionCount} {comment.reactionCount === 1 ? 'Like' : 'Likes'}</p>}
                        <Button
                            variant="text"
                            className={classes.replyButton}
                            onClick={() => {
                                handleReplyToComment({ commentId: comment.commentId, commentIndex: index });
                                let inputEl = document.getElementById('comment-input-box');
                                if (inputEl) {
                                    inputEl.focus();
                                    inputEl.placeholder = 'Reply to comment...';
                                    inputEl.addEventListener('blur', () => {
                                        handleReplyToComment(null);
                                    })
                                }
                            }}
                        >
                            Reply
                        </Button>
                    </div>
                    {comment.repliesCount > 0 &&
                        <Button
                            variant="text"
                            className={classes.viewRepliesButton}
                            onClick={() => handleViewCommentReplies(index)}
                        >
                            <div style={{ borderBottom: '1px solid #8e8e8e', display: 'inline-block', height: 0, marginRight: 16, verticalAlign: 'middle', width: 24 }}></div>
                            <span>{comment.isReplySectionOpen ? 'Hide replies' : 'View replies'} ({comment.repliesCount})</span>
                        </Button>}
                    {comment.isReplySectionOpen &&
                        comment.repliesList.map((reply, i) => {
                            let result = convertUnicode(reply.comment);
                            const d = reply?.tags?.map(obj => { result = highlightMentions(result, obj.name) })
                            return (
                                <div key={i} className={classes.root} style={{ padding: '15px 0px 15px 10px' }}>
                                    <div>
                                        <AvatarComponent name={reply.commentedBy} username={reply.user_id} url={reply.photoUrl} className={classes.avatarStyles} />
                                    </div>
                                    <div className={classes.detailsRoot}>
                                        <div className={classes.comments}>
                                            <UserProfileLink username={reply.user_id} name={reply.commentedBy} />
                                            <span dangerouslySetInnerHTML={{ __html: result }} />
                                            <IconButton disableTouchRipple className={classes.likeComment} onClick={() => handlereplyLike(reply.commentId, i, "Like", index)} >
                                                {reply.currentUserReaction ? <Liked /> : <Like />}
                                            </IconButton>
                                        </div>
                                        <div className={classes.actionsRoot}>
                                            <Moment fromNow ago format={postCreatedAtFormat(reply.created_at)}>{reply.created_at}</Moment>
                                            {reply.reactionCount > 0 && <p className={classes.likesCount} onClick={() => onLikesClick(reply.commentId, 'COMMENT')}>{reply.reactionCount} {reply.reactionCount === 1 ? 'Like' : 'Likes'}</p>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

export default ArenaCommentCard;