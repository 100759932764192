import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, makeStyles, IconButton } from "@material-ui/core";
import { Repeat, PlayCircleOutline } from "@material-ui/icons";
import { ArenaPreview } from "../Arena/ArenaPreview";
import { useSnackbar } from "notistack";
import { searchArenaMedia } from "../../ducks/search";
import Skeleton from "@material-ui/lab/Skeleton";
// import { getSearchArenaList } from "../../ducks/search";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ffffff",
    // minHeight: "100vh",
    paddingTop: 15
  },
  attachmentsRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  attachment: {
    width: 110,
    height: 90,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    textAlign: 'center',
    border: '1px solid #EEEEEE',
    position: 'relative',
    margin: '20px 15px 10px',
    // borderRadius: 7,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5,
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      // borderRadius: 7
    }
  },
  videoStyles: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#828282",
    position: "relative",
    textAlign: "center",
    color: "#fff",
    overflow: 'hidden',
    // borderRadius: 7,
    "&:hover": {
      cursor: "pointer"
    },
    '& button': {
      position: 'absolute'
    },
    "& img": {
      maxWidth: 'initial',
      [theme.breakpoints.down("xs")]: {
        maxWidth: '100% !important'
      }
    }
  },
  playIcon: {
    color: '#fff'
  },
  seeMoreActionRoot: {
    textAlign: 'center',
    margin: '15px 0 30px',
    '& button': {
      color: '#535353',
      borderColor: '#535353',
      maxWidth: 300
    }
  },
  emptyResultText: {
    textAlign: 'center',
    color: '#535353cc',
    fontSize: 15,
    fontWeight: 600,
    margin: 0,
    padding: 40,
    lineHeight: '25px'
  }
}));

const defaultObj = {
  page: 0,
  limit: 20,
  totalCount: 0,
  data: []
};

const Media = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchResult, setSearchResult] = useState(defaultObj);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [preview, setPreview] = useState({
    open: false,
    attachments: [],
  });

  useEffect(() => {
    if (props.search.query) {
      setSearchResult(defaultObj);
      getSearchResults(props.search.query, searchResult.limit, 0)
    }
  }, [props.search.query]);

  const onSearchSuccessCB = (result, headers) => {
    if (parseInt(headers.page) === 0)
      setSearchResult(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: [...result]
      }));
    else
      setSearchResult(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: [...prevState.data, ...result]
      }));
    setSkeletonLoading(false);
  };
  const onSearchErrorCB = error => {
    setSkeletonLoading(false);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Search Results.`, {
      variant: 'error',
      preventDuplicate: true
    });
  };

  const handleSeeMore = () => {
    getSearchResults(props.search.query, searchResult.limit, searchResult.page)
  }

  const getSearchResults = (query, limit, page) => {
    setSkeletonLoading(true);
    props.searchArenaMedia({
      params: {
        q: query,
        type: props.mediaType === 'IMAGE' ? 'photos' : 'videos',
        limit, page
      },
      onSearchSuccessCB,
      onSearchErrorCB
    });
  }

  const handlePreview = (attachments, attachmentIndex) => {
    setPreview({ open: true, attachments, attachmentIndex });
  }

  const handlePreviewClose = () => {
    setPreview({ open: false, attachments: [] });
  }

  return (
    <div className={classes.root}>
      <div className={classes.attachmentsRoot}>
        {searchResult.data.length > 0 ? (
          searchResult.data.map((item, index) => (
            <div key={index} className={classes.attachment}
              style={{ backgroundColor: '#f7f7f7' }}
              onClick={() => handlePreview(searchResult.data, index)}
            >
              {item.mimeType === "IMAGE" ? (
                <img
                  alt={item.attachmentUrl.split("-").pop()}
                  src={item.attachmentUrl}
                />
              ) : (
                  <div className={classes.videoStyles}>
                    <img
                      alt={item.thumbnailUrl && item.thumbnailUrl.split("-").pop()}
                      src={item.thumbnailUrl}
                    />
                    <IconButton>
                      <PlayCircleOutline
                        className={classes.playIcon}
                        fontSize="large"
                      />
                    </IconButton>
                  </div>
                )}
            </div>
          )))
          : (
            !skeletonLoading && <p className={classes.emptyResultText}>No result found...</p>
          )}
        {skeletonLoading && [0, 1, 2, 4].map(i => <Skeleton key={i} variant="rect" style={{ margin: '20px 15px 10px' }} width={110} height={90} />)}
      </div>
      <div className={classes.seeMoreActionRoot}>
        {
          (searchResult.data.length < searchResult.totalCount) &&
          <Button fullWidth variant="outlined" onClick={() => handleSeeMore()}>See More</Button>
        }
      </div>
      {preview.open && <ArenaPreview open={preview.open} attachmentIndex={preview.attachmentIndex} arena={{ attachments: preview.attachments }} handlePreviewClose={handlePreviewClose} isFromProfile={true} />}
    </div>

  );
};

const mapStateToProps = (state) => ({
  search: state.search.arena,
  user: state.user.username
});

const mapDispatchToProps = (dispatch) => ({
  searchArenaMedia: options => dispatch(searchArenaMedia(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Media);
