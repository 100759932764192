import { makeConstant } from "./_helpers";
const constant = makeConstant("chase/nudge");

export const GET_NUDGES = constant('GET_NUDGES');
export const UPDATE_NUDGE = constant('UPDATE_NUDGE');



export const getNudgesList = (payload) => ({
    type: GET_NUDGES,
    payload
})

export const updateNudgeNotify = (payload) => ({
    type: UPDATE_NUDGE,
    payload
})

const initialState = {};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {

        default:
            return state;
    }
};
