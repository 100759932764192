import React from 'react'
import PropTypes from 'prop-types'
import {InlineInput} from './Styles/Base'
import autosize from 'autosize'
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeLaneTitle } from '../../../ducks/pms';
import { withSnackbar } from 'notistack';

// const { enqueueSnackbar } = useSnackbar();

class InlineInputController extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      canShowButtons:false
    };
  }

  componentDidMount(){
    this.refInput.focus()
  }

  onFocus = (e) => {
    e.target.select()
    this.setState({canShowButtons:true})
  }
 
  // This is the way to select all text if mouse clicked
  onMouseDown = (e) => {
    if (document.activeElement != e.target) {
      this.setState({canShowButtons:false});
      e.preventDefault()
      this.refInput.focus()
    }
  }

  onBlur = () => {
    this.updateValue();
    this.setState({
      canShowButtons:false
    })
  }
 
  onKeyDown = (e) => {
    if(e.keyCode == 13) {
      this.handleChangeLaneTitle()
      e.preventDefault();
    }
    if(e.keyCode == 27) {
      this.setValue(this.props.value)
      this.handleChangeLaneTitle()
      e.preventDefault()
    }
    if(e.keyCode == 9) {
      if (this.getValue().length == 0) {
        // this.props.onCancel()
      }
      this.handleChangeLaneTitle()
      e.preventDefault()
    }
  }

  getValue = () => this.refInput.value.trim().toUpperCase()
  setValue = (value) => this.refInput.value=value

  updateValue = () => {
    if (this.getValue() != this.props.value) {
      this.props.onSave(this.getValue());
      this.props.handelLaneTitleEdit()
    }
  }

  setRef = (ref) => {
    this.refInput = ref
    if (this.props.resize != 'none') {
      autosize(this.refInput)
    }
  }
  handelClearTitle = () => {
    if (this.getValue() !== this.props.value) {
      this.props.onSave(this.props.value)
    }
    this.props.handelLaneTitleEdit();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setValue(nextProps.value)
  }
  onSuccessCB =(data)=>{
    this.onBlur();
    this.props.enqueueSnackbar(`Stage title updated.`, {
      variant: "success",
      preventDuplicate: true
  });
  }
  onErrorCB=(error)=>{
    if(error.response.data.error==='Stages title Already exists'){
      this.props.enqueueSnackbar(`Stage title already exists`, {
        variant: "error",
        preventDuplicate: true
    });
    }
    else {
      this.props.enqueueSnackbar(`Something went wrong.`, {
        variant: "error",
        preventDuplicate: true
    });
    }
    
  }
  handleChangeLaneTitle = () => {
    if ((this.getValue() != this.props.value) && this.getValue().length > 0) {
      let obj={label:this.getValue(),stageId:this.props.id}
      this.props.changeLaneTitle({requestObj:obj,id:this.props.project_id,onSuccessCB:this.onSuccessCB,
      onErrorCB:this.onErrorCB
    })
  }
  else{
    this.handelClearTitle()
  }

  
 
  }
  
  render() {
    const {autoFocus, border, value, placeholder,editLaneTitle} = this.props

    return <div style={{display:'flex'}}>
    <InlineInput
      ref={this.setRef}
      border={border}
      onMouseDown={this.onMouseDown}
      onFocus={this.onFocus}
      // onBlur={this.onBlur}
      onKeyDown={this.onKeyDown}
      placeholder={value.length == 0 ? undefined : placeholder}
      defaultValue={value}
      autocapitalize
      autoComplete="off"
      autoCorrect="off"
      maxLength={20}
      spellCheck="false"
      dataGramm="false"
      rows={1}
      autoFocus={autoFocus}
    />
   {
      editLaneTitle ?
        <>
          <span style={{ cursor: 'pointer', color: '#828282', marginLeft: 5 }}><CheckIcon onClick={this.handleChangeLaneTitle} /></span>
          <span style={{ cursor: 'pointer', color: '#828282', }}><ClearIcon onClick={this.handelClearTitle} /></span>
        </>
        : null
    }
    </div>
  }
}

InlineInputController.propTypes = {
  onSave: PropTypes.func,
  border: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  resize: PropTypes.oneOf(['none', 'vertical', 'horizontal']),
}

InlineInputController.defaultProps = {
  onSave: () => {},
  placeholder: '',
  value: '',
  border: false,
  autoFocus: false,
  resize: 'none'
}

const mapDispatchToProps = (dispatch, props) => {
    return {
      changeLaneTitle: (options) => dispatch(changeLaneTitle(options)),
    }
  }
  
  export default withRouter(connect(
    null,
    mapDispatchToProps
  )(withSnackbar(InlineInputController)))