import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';
import { deleteReaction, getArenaCommentsList, getCommentReplies, onCommentInput, openReactionsDialog, postArenaComments, postCommentReply, sendReaction } from '../../ducks/arena';
import ArenaCommentCard from './ArenaCommentCard';

const useStyles = makeStyles(theme => ({
    root: {
        overflowY: 'auto',
        borderTop: '1px solid #eeeeee',
        borderBottom: '1px solid #eeeeee',
        // width: 'calc(100% + 16px)' // to hide scroll bar
    }
}));

let commentReactionIndex = null;
let replyreactionIndex = null;
let pagination = {
    limit: 10,
    page: 0,
    totalCount: 0
}
const ArenaComments = ({ postId = '', postArenaComments, postCommentReply, getArenaCommentsList, sendReaction, deleteReaction, getCommentReplies, commentState, onCommentInput, newComment, openReactionsDialog, ...others }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [commentsList, setCommentsList] = useState([]);
    const [commentReplyDetails, setCommentReplyDetails] = useState(null);

    useEffect(() => {
        let scrollingElement = document.getElementById('arena-comments-root');
        scrollingElement.scrollTop = scrollingElement.scrollHeight

        pagination = {
            limit: 10,
            page: 0,
            totalCount: 0
        }
        getArenaCommentsList({
            params: { limit: pagination.limit, page: pagination.page, postId },
            onCommentListSuccess,
            onCommentListError
        });

        return () => {
            onCommentInput({
                entityId: null,
                entityType: 'POST',
                commentText: ''
            })
        }
    }, []);

    useEffect(() => {
        console.log('commentState ', commentState);
        if (commentState.commentText) {
            let requestObj = {
                comment: commentState.commentText,
                entity_Type: commentReplyDetails ? 'COMMENT' : commentState.entityType,
                entity_id: commentReplyDetails ? commentReplyDetails.commentId : commentState.entityId,
                tags: commentState.tags || []
            };
            postArenaComments({
                requestObj,
                onCommentSuccess: commentReplyDetails ? onCommentReplySuccessCB : onCommentSuccess,
                onCommentError
            });
        }
    }, [commentState]);

    const fetchMoreComments = e => {
        getArenaCommentsList({
            params: { limit: pagination.limit, page: pagination.page + 1, postId },
            onCommentListSuccess,
            onCommentListError
        });
    };

    const onCommentListError = error => {
        console.log(error);
    };

    const onCommentListSuccess = (success, headers) => {
        const { postId, commentsList, isSingleComment } = success;
        pagination = {
            ...pagination,
            page: parseInt(headers.page),
            totalCount: parseInt(headers.total_count),
        }
        if (parseInt(headers.page) === 0) {
            setCommentsList([...commentsList]);
        }
        else setCommentsList(prevState => ([...commentsList, ...prevState]));
    };

    const onCommentError = error => {
        console.log(error);
    };

    const onCommentSuccess = success => {
        // const { postId } = success;
        // const list = arenaList.map(arena => {
        //     if (arena.postId === postId) {
        //         arena.comment = "";
        //         arena.commentsCount += 1;
        //     }
        //     return arena;
        // });
        // setArenaList(list);

        getArenaCommentsList({
            params: { limit: pagination.limit, page: 0, postId, isSingleComment: true },
            onCommentListSuccess,
            onCommentListError
        });
        newComment(postId);
    };

    const onCommentReplySuccessCB = success => {
        setCommentReplyDetails(null);
        getCommentReplies({
            params: {
                limit: 100,
                page: 0,
                // postIndex: index,
                commentIndex: commentReplyDetails.commentIndex,
                // postId: arenaList[index].postId,
                commentId: commentReplyDetails.commentId,
                isSingleComment: false
            },
            onCommentRepliesSuccess,
            onCommentRepliesError
        });
        newComment(postId);
    }

    const handleOnCommentedCB = (commentText) => {
        // if (!isReply) {
        let requestObj = {
            comment: commentText,
            entity_Type: "POST",
            entity_id: postId
        };
        postArenaComments({
            requestObj,
            onCommentSuccess,
            onCommentError
        });
        // }
        // else {
        //     let requestObj = {
        //         comment: e.target.value,
        //         entity_Type: "COMMENT",
        //         entity_id: arenaList[index].commentsList[commentIndex].commentId
        //     };
        //     postCommentReply({
        //         requestObj,
        //         postIndex: index,
        //         commentIndex,
        //         onCommentReplySuccess,
        //         onCommentReplyError
        //     });
        // }
        return false;
    }

    const onCommentRepliesSuccess = (result) => {
        const { postIndex, commentIndex, repliesList } = result;
        let commentsListDup = JSON.parse(JSON.stringify(commentsList));
        commentsListDup[commentIndex].repliesList = repliesList;
        commentsListDup[commentIndex].isReplySectionOpen = true;
        commentsListDup[commentIndex].repliesCount = repliesList ? repliesList.length : commentsListDup[commentIndex].repliesCount;
        setCommentsList(commentsListDup);
    }

    const onCommentRepliesError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Replies.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleViewCommentReplies = (commentIndex) => {
        let commentsListDup = JSON.parse(JSON.stringify(commentsList));
        if (commentsListDup[commentIndex].isReplySectionOpen) {
            commentsListDup[commentIndex].isReplySectionOpen = false
            setCommentsList(commentsListDup);
        }
        else {
            getCommentReplies({
                params: {
                    limit: 100,
                    page: 0,
                    // postIndex: index,
                    commentIndex,
                    // postId: arenaList[index].postId,
                    commentId: commentsList[commentIndex].commentId,
                    isSingleComment: false
                },
                onCommentRepliesSuccess,
                onCommentRepliesError
            })
        }
    }

    const handleCommentLike = (commentId, commentIndex, type = "Like", arenaIndex) => {
        let commentsListDup = JSON.parse(JSON.stringify(commentsList));
        commentReactionIndex = commentIndex;
        if (commentsListDup.length > 0) {
            if (commentsListDup[commentIndex].currentUserReaction === type.toUpperCase()) {
                const commentLike = "commentLike"
                deleteReaction({ entityId: commentId, entityType: 'COMMENT', commentLike, onDeleteReactionSuccessCB: (...args) => onSendReactionSuccessCB(...args, true), onDeleteReactionErrorCB: (...args) => onSendReactionErrorCB(...args, true) })
            }
            else {
                const requestObj = {
                    entity_id: commentId,
                    entity_Type: "COMMENT",
                    reaction: type.toUpperCase()
                }
                const commentLike = "commentLike"
                sendReaction({ requestObj, commentLike, onSendReactionSuccessCB, onSendReactionErrorCB })
            }
        }

    }

    const handlereplyLike = (commentId, replyIndex, type = "Like", commentIndex) => {
        let commentsListDup = JSON.parse(JSON.stringify(commentsList));
        commentReactionIndex = commentIndex;
        replyreactionIndex = replyIndex
        if (commentsListDup[commentIndex].repliesList.length > 0) {
            if (commentsListDup[commentIndex].repliesList[replyIndex].currentUserReaction === type.toUpperCase()) {
                const commentLike = "replyLike"
                deleteReaction({ entityId: commentId, entityType: 'COMMENT', commentLike, onDeleteReactionSuccessCB: (...args) => onSendReactionSuccessCB(...args, true), onDeleteReactionErrorCB: (...args) => onSendReactionErrorCB(...args, true) })
            }
            else {
                const requestObj = {
                    entity_id: commentId,
                    entity_Type: "COMMENT",
                    reaction: type.toUpperCase()
                }
                const commentLike = "replyLike"
                sendReaction({ requestObj, commentLike, onSendReactionSuccessCB, onSendReactionErrorCB })
            }
        }
    }

    const onSendReactionSuccessCB = (result, payload, commentLike, isDelete) => {
        if (commentLike === "replyLike") {
            let commentsListDup = JSON.parse(JSON.stringify(commentsList));
            commentsListDup[commentReactionIndex].repliesList[replyreactionIndex].currentUserReaction = isDelete ? null : payload.reaction;
            commentsListDup[commentReactionIndex].repliesList[replyreactionIndex].reactionCount = result.reactionsCount;
            commentReactionIndex = null;
            replyreactionIndex = null;
            setCommentsList(commentsListDup);
        }
        else {
            let commentsListDup = JSON.parse(JSON.stringify(commentsList));
            commentsListDup[commentReactionIndex].currentUserReaction = isDelete ? null : payload.reaction;
            commentsListDup[commentReactionIndex].reactionCount = result.reactionsCount
            commentReactionIndex = null;
            replyreactionIndex = null;
            setCommentsList(commentsListDup);
        }
    }

    const onSendReactionErrorCB = (error, isDelete) => {
        commentReactionIndex = null;
        replyreactionIndex = null
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to ${isDelete ? 'delete' : 'send'} Reaction.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleLikesClick = (postId, entityType) => {
        openReactionsDialog({ open: true, postId, type: 'ALL', entityType })
    }

    const handleReplyToComment = (commentDetails) => {
        setCommentReplyDetails(commentDetails);
    }

    return (
        <div className={classes.root} {...others} id="arena-comments-root">
            <InfiniteScroll
                scrollThreshold={0.6}
                initialScrollY={100}
                dataLength={commentsList.length}
                next={fetchMoreComments}
                hasMore={commentsList.length !== pagination.total_count}
                // loader={skeletonLoading}
                scrollableTarget="arena-comments-root"
                inverse={true}
            >
                {commentsList.length > 0 &&
                    commentsList.map((comment, i) => (
                        <ArenaCommentCard key={i} postId={postId} comment={comment} index={i} handleCommentLike={handleCommentLike} handlereplyLike={handlereplyLike} handleViewCommentReplies={handleViewCommentReplies} onLikesClick={handleLikesClick} handleReplyToComment={handleReplyToComment} />
                    ))}
            </InfiniteScroll>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    commentState: state.arena.comment
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        postArenaComments: options => dispatch(postArenaComments(options)),
        getArenaCommentsList: value => dispatch(getArenaCommentsList(value)),
        postCommentReply: options => dispatch(postCommentReply(options)),
        getCommentReplies: (options) => dispatch(getCommentReplies(options)),
        sendReaction: (options) => dispatch(sendReaction(options)),
        deleteReaction: (options) => dispatch(deleteReaction(options)),
        onCommentInput: options => dispatch(onCommentInput(options)),
        openReactionsDialog: (options) => dispatch(openReactionsDialog(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArenaComments);