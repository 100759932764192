import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, useMediaQuery, useTheme, IconButton, Tabs, Tab, DialogTitle, DialogContent, ListItem, ListItemAvatar, Avatar, List, Divider, ListItemText, Button, ListItemSecondaryAction } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { openReactionsDialog, getReactionsOfPost } from '../../ducks/arena';
import { useSnackbar } from "notistack";
import { ReactComponent as LikeIcon } from '../../assets/icons/like.svg';
import { ReactComponent as ApplauseIcon } from '../../assets/icons/applause.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/support.svg';
import { ReactComponent as InsightfulIcon } from '../../assets/icons/insightful.svg';
import { ReactComponent as SurprisedIcon } from '../../assets/icons/surprised.svg';
import { ReactComponent as CuriousIcon } from '../../assets/icons/curious.svg';
import { ReactComponent as FavouriteIcon } from '../../assets/icons/add-favourite.svg';
import { ReactComponent as UnFavouriteIcon } from '../../assets/icons/remove-favourite.svg';
import { ReactComponent as FollowIcon } from '../../assets/icons/follow.svg';
import { ReactComponent as FollowingIcon } from '../../assets/icons/following.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { followUser, unFollowUser } from '../../ducks/favourites';
import { getAvatarText } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        minHeight: 470,
        borderRadius: 15
    },
    dialogTitle: {
        paddingBottom: 0
    },
    dialogContent: {
        padding: 0
    },
    profileTabsRoot: {
        minHeight: 'auto',
        display: 'inline-flex',
    },
    profileTabRoot: {
        margin: '0 5px',
        minWidth: 50,
        minHeight: 'auto',
        fontSize: 14,
        textTransform: 'capitalize',
        fontWeight: 600,
        padding: '4px 0',
        '& .MuiTab-wrapper': {
            display: 'flex',
            flexDirection: 'inherit',
            '& .reaction-count': {
                marginLeft: 8
            }
        },
        '& svg': {
            width: 24,
            height: 24,
        }
    },
    tabIndicatorProfile: {
        minWidth: 50,
        height: 3
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12
    },
    customListItem: {
        paddingTop: 15,
        paddingBottom: 15
    }
}))

const chaseonsTemplateStyles = makeStyles((theme) => ({
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    },
    listItemContent: {
        width: '100%',
        display: 'flex',
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 0px'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '3px',
        fontSize: 14,
        color: '#535353',
        cursor: 'pointer'
    },
    secondarytext: {
        margin: 0,
        fontSize: '11px',
        color: '#838383',
        whiteSpace: 'pre-wrap'
    },
    favButton: {
        '&:hover': {
            boxShadow: 'none'
        },
        '& .fav-button-text': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    }
}))

export const ChaseonsSkeleton = ({ secondary, actionButton, ...others }) => (
    <ListItem {...others} style={{ height: 65 }}>
        <ListItemAvatar>
            <Skeleton variant="circle" width={40} height={40} />
        </ListItemAvatar>
        <ListItemText
            primary={<Skeleton height={12} width="50%" style={{ marginBottom: 6 }} />}
            secondary={secondary ? <Skeleton height={10} width="30%" /> : null}
        />
        <div style={{ width: '25%' }}>
            {actionButton && <Skeleton height={50} />}
        </div>
    </ListItem>
)

export const MarkFavButton = (props) => <Button {...props} variant="contained" color="primary" startIcon={<FollowIcon />}><span className="fav-button-text">Follow</span></Button>
export const RemoveFavButton = (props) => <Button {...props} variant="contained" color="primary" startIcon={<FollowingIcon />}><span className="fav-button-text">Unfollow</span></Button>

export const ChaseonsTemplate = ({ details, uniqueKey, onChaseonsClick = () => { }, followUser, unFollowUser, noActionButtons, isLoggedInUser, ...others }) => {
    const classes = chaseonsTemplateStyles();
    return (
        <ListItem key={uniqueKey} {...others}>
            <ListItemAvatar>
                <Avatar
                    className="avatar"
                    classes={{ colorDefault: classes.avatarStyles }}
                    alt={details.name}
                    src={details.photoUrl || details.photo_url || '/'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onChaseonsClick(details)}
                >{details.name && getAvatarText(details.name)}</Avatar>
            </ListItemAvatar>
            <div className={classes.listItemContent}>
                <div>
                    <p className={classes.primaryText} onClick={() => onChaseonsClick(details)}>{details.name || details.userId}</p>
                    {
                        (noActionButtons || details.about) ?
                            <p className={classes.secondarytext}>{(details.about && details.about.length > 50 ? details.about.substring(0, 50) + '...' : details.about)}</p>
                            :
                            // <p className={classes.secondarytext}>{details.mutualCount || '0'} mutual favourite</p>
                            null

                    }
                </div>
                {
                    (noActionButtons || isLoggedInUser) ?
                        null
                        :
                        <div>
                            {details.isFavourite || details.follower ?
                                <RemoveFavButton className={classes.favButton} onClick={unFollowUser} />
                                : <MarkFavButton className={classes.favButton} onClick={followUser} />
                            }
                        </div>
                }
            </div>
        </ListItem>
    )
}

const reactionTypes = [
    'ALL',
    'LIKE',
    'APPLAUSE',
    'SUPPORT',
    'INSIGHTFUL',
    'SURPRISED',
    'CURIOUS'
]
const reactionIcons = {
    'LIKE': <LikeIcon />,
    'APPLAUSE': <ApplauseIcon />,
    'SUPPORT': <SupportIcon />,
    'INSIGHTFUL': <InsightfulIcon />,
    'SURPRISED': <SurprisedIcon />,
    'CURIOUS': <CuriousIcon />
}

const ReactionsDialog = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { reactions: { open, postId }, openReactionsDialog, getReactionsOfPost } = props;

    const [filterType, setFilterType] = useState(0);
    const [reactionsList, setReactionsList] = useState([]);
    const [reactionsListDup, setReactionsListDup] = useState([]);
    const [filteredReactions, setFilteredReactions] = useState([]);
    const [skeletonLoading, setSkeletonLoading] = useState(false);

    useEffect(() => {
        if (postId) {
            setSkeletonLoading(true);
            getReactionsOfPost({ entityType: 'POST', entityId: postId, onGetReactionsSuccessCB, onGetReactionsErrorCB })
        }
    }, [postId]);

    const onGetReactionsSuccessCB = (result) => {
        setReactionsList(result);
        setReactionsListDup(result);

        let counter = {}
        result.forEach((obj) => {
            counter[obj.reactionType] = counter[obj.reactionType] ? [...counter[obj.reactionType], obj] : [obj];
        })
        setFilteredReactions(counter);

        setSkeletonLoading(false);
    }

    const onGetReactionsErrorCB = (result) => {
        enqueueSnackbar(`Failed to fetch Reactions.`, {
            variant: "error",
            preventDuplicate: true
        });
        handleClose();
    }

    const handleClose = () => {
        setFilterType(0);
        setReactionsList([]);
        setReactionsListDup([]);
        setFilteredReactions({});
        openReactionsDialog({ open: false, postId: null });
    }

    const handleChangeType = (event, newValue) => {
        setFilterType(newValue);
    };

    useEffect(() => {
        // console.log('filterType ', filterType, Object.keys(filteredReactions)[filterType - 1]);
        if (filterType) {
            setReactionsList(filteredReactions[Object.keys(filteredReactions)[filterType - 1]]);
        }
        else
            setReactionsList([...reactionsListDup]);
    }, [filterType, filteredReactions, reactionsListDup])

    const onFollowUserSuccess = result => {
        let filteredReactionsDup = JSON.parse(JSON.stringify(filteredReactions));
        for (const key in filteredReactionsDup) {
            if (filteredReactionsDup.hasOwnProperty(key)) {
                const element = filteredReactionsDup[key];
                if (element.some(item => item.userId === result.username)) {
                    (element.find(item => item.userId === result.username)).favourites = true
                }
            }
        }
        let reactionsListDummy = JSON.parse(JSON.stringify(reactionsListDup));
        if (reactionsListDummy.some(item => item.userId === result.username)) {
            (reactionsListDummy.find(item => item.userId === result.username)).favourites = true
        }
        setFilteredReactions(filteredReactionsDup);
        setReactionsListDup(reactionsListDummy);
    };
    const onFollowUserError = error => {
        console.log('onFollowUserError ', error.response);
    };

    const onUnFollowUserSuccess = result => {
        let filteredReactionsDup = JSON.parse(JSON.stringify(filteredReactions));
        for (const key in filteredReactionsDup) {
            if (filteredReactionsDup.hasOwnProperty(key)) {
                const element = filteredReactionsDup[key];
                if (element.some(item => item.userId === result.username)) {
                    (element.find(item => item.userId === result.username)).favourites = false
                }

            }
        }
        let reactionsListDummy = JSON.parse(JSON.stringify(reactionsListDup));
        if (reactionsListDummy.some(item => item.userId === result.username)) {
            (reactionsListDummy.find(item => item.userId === result.username)).favourites = false
        }
        setFilteredReactions(filteredReactionsDup);
        setReactionsListDup(reactionsListDummy);
    }
    const onUnFollowUserError = error => {
        console.log('onUnFollowUserError ', error.response);
    }

    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="responsive-dialog-title"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    <Tabs
                        value={filterType}
                        onChange={handleChangeType}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        className={classes.profileTabsRoot}
                        classes={{ indicator: classes.tabIndicatorProfile }}
                    >
                        <Tab classes={{ root: classes.profileTabRoot }} label={`All ${reactionsListDup.length > 0 ? reactionsListDup.length : ''}`} />
                        {
                            Object.keys(filteredReactions).map(key => (
                                <Tab classes={{ root: classes.profileTabRoot }} label={<span className="reaction-count">{filteredReactions[key].length}</span>} icon={reactionIcons[key]} />
                            ))
                        }
                    </Tabs>
                    <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers className={classes.dialogContent}>
                    {skeletonLoading && [0, 1, 2, 4, 5].map(i => <ChaseonsSkeleton key={i} />)}
                    <List dense className={classes.root}>
                        {
                            reactionsList.length ? reactionsList.map((item, i) => (
                                <Fragment key={i}>
                                    <ChaseonsTemplate uniqueKey={item.userId}
                                        details={{ name: item.reactedBy, userId: item.userId, photoUrl: item.photoUrl, isFavourite: item.favourites }}
                                        isLoggedInUser={props.user.username === item.userId}
                                        onChaseonsClick={() => {
                                            handleClose();
                                            props.history.push(`/wall/profile/${item.userId}`);
                                        }}
                                        className={classes.customListItem}
                                        followUser={() => props.followUser({
                                            requestObj: { username: item.userId },
                                            onFollowUserSuccess,
                                            onFollowUserError
                                        })}
                                        unFollowUser={() => props.unFollowUser({
                                            username: item.userId,
                                            onUnFollowUserSuccess,
                                            onUnFollowUserError
                                        })}
                                    />
                                    <Divider variant="fullWidth" component="li" />
                                </Fragment>
                            ))
                                :
                                !skeletonLoading && <p style={{ textAlign: 'center' }}>No Results found</p>
                        }
                    </List>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const mapStateToProps = (state, props) => ({
    reactions: state.arena.reactions,
    user: state.user
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        openReactionsDialog: (options) => dispatch(openReactionsDialog(options)),
        getReactionsOfPost: (options) => dispatch(getReactionsOfPost(options)),
        followUser: options => dispatch(followUser(options)),
        unFollowUser: options => dispatch(unFollowUser(options)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ReactionsDialog);