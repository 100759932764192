import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import LineChart from "./Charts/LineChart";
import { setLoading } from "../../../ducks/loading";

const useStyles = makeStyles((theme) => ({
    root: {

    }
}));


const Trends = ({ kpiTrends }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LineChart kpiTrends={kpiTrends} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Trends));