import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Link, makeStyles } from '@material-ui/core';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFollowingUserList, getFollowUserList, getMutualFollowingUserList } from '../../ducks/favourites';
import { getMediaList } from '../../ducks/user';
import { getEllipsisText, getUTCFormattedDate } from '../../helpers';
import { useSnackbar } from 'notistack';
import { getMeetingsList } from '../../ducks/meetings';
import { Calendar, DatePicker, useStaticState } from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 16px',
        backgroundColor: '#FFF',
        borderRadius: 8,
        overflow: 'hidden',
        '& .MuiPickersCalendarHeader-switchHeader': {
            '& button': {
                padding: 5
            },
            '& .MuiTypography-alignCenter': {
                fontSize: 12,
            },
        },
        '& .MuiPickersCalendarHeader-daysHeader': {
            '& .MuiPickersCalendarHeader-dayLabel': {
                fontSize: 9
            }
        },
        '& .MuiPickersCalendar-transitionContainer': {
            minHeight: 135
        },
        '& .MuiPickersDay-day': {
            width: 24,
            height: 24,
            '& p': {
                fontSize: 10
            }
        }
    },
    dateDisplayStyles: {
        margin: '12px 0px',
        fontSize: 14,
        textTransform: 'uppercase'
    },
    meetingRoot: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 10,
        margin: '10px 0px'
    },
    timestamp: {
        color: 'rgba(38, 38, 38, 0.47)'
    },
    meetingDetailsRoot: {
        marginLeft: 12,
        fontSize: 12,
        width: '100%'
    },
    meetingTitle: {
        margin: 0,
        color: '#262626',
        whiteSpace: 'nowrap',
        width: '77%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    meetingLink: {
        margin: 0,
        color: '#112386',
        fontSize: 9,
        whiteSpace: 'nowrap',
        width: '77%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const defaultDate = new Date();
const StaticMeetingsList = ({ user, getMeetingsList, staticContext, orgDetails, ...others }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [meetingsList, setMeetingsList] = useState([]);
    const [startDate, setStartDate] = useState(defaultDate);
    const [endDate, setEndDate] = useState(defaultDate);

    const { pickerProps, wrapperProps } = useStaticState({
        value: startDate,
        onChange: (...args) => handleDateChange(...args),

    });

    useEffect(() => {
        const hasMeetingSubscription = orgDetails.modules?.some(module => module.feature === 'meet');
        if (hasMeetingSubscription)
            getMeetingsList({ params: { from: getUTCFormattedDate(startDate), to: getUTCFormattedDate(endDate) }, onSuccessCB, onErrorCB });
    }, [startDate]);

    const onSuccessCB = (result, headers) => {
        console.log("getMeetingsList ", headers, result);
        setMeetingsList(result);
        // setLoading(false);
    };

    const onErrorCB = error => {
        console.log(error);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed fetch Meetings.', {
            variant: 'error',
            preventDuplicate: true
        });
        // setLoading(false);
    };

    const handleDateChange = (date) => {
        setStartDate(date);
        setEndDate(date);
    }

    return (
        <div className={classes.root} {...others}>
            <Calendar {...pickerProps} />
            <p className={classes.dateDisplayStyles}>{moment(startDate).format('dddd, MMMM DD')}</p>
            <div>
                {
                    meetingsList.map((obj, i) => (
                        <Fragment key={i}>
                            <div className={classes.meetingRoot}>
                                <div className={classes.timestamp}>
                                    {moment(obj.schedule[0].start_at).format('HH:mm')}
                                </div>
                                <div className={classes.meetingDetailsRoot}>
                                    <p className={classes.meetingTitle}>{obj.title}</p>
                                    <p className={classes.meetingLink}><Link color="inherit" href={`${obj.meeting_link}/${user.name}/meet`} target="_blank">Join Meeting</Link></p>
                                </div>
                            </div>
                            <Divider variant="fullWidth" component="div" />
                        </Fragment>
                    ))
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    orgDetails: state.common.orgDetails
});

const mapDispatchToProps = (dispatch) => {
    return {
        getMeetingsList: (options) => dispatch(getMeetingsList(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StaticMeetingsList));