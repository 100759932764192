import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { ArrowDownward, ArrowForward, Email, Info, Share } from "@material-ui/icons";
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import { Button, Checkbox, FormControl, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { getCurrencyFormat, downloadFiles, getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort } from "../../helpers";
import loading, { setLoading } from "../../ducks/loading";
import { getRoundPlayerStatistics, getPlayerRaceLiveScore, shareLiveScoreDialog, getDivisionsList } from "../../ducks/game";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import IllustrationView from "./IllustrationView";
import { groupBy } from "underscore";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import { GetDates } from ".";
import { toJpeg } from "html-to-image";
import PerformanceTableTemplate from "../GlobalComponents/PerformanceTableTemplate";
import PerformanceTablePlayersEmailTemplate from "../GlobalComponents/PerformanceTablePlayersEmailTemplate";
import './TablesLayout.css';
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { Tooltip, Menu, MenuItem, IconButton } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";
import { resizeImageToSquare } from "../ArenaComponents/cropImage";
import { openCreateArenaDialog } from '../../ducks/arena';
import { ReactComponent as ReactArenaLogo } from "../../assets/icons/arena-1.svg";
import NotificationDialog from "../GlobalComponents/NotificationDialog";
import PlayerSummaryDivisionWise from "./PlayerSummaryDivisionWise";
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";
import { Autocomplete } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";


const useStyles = makeStyles((theme) => ({
    root: {
        // margin: '10px 0',
        '& .livescore-table td div': {
            border: 'none !important'
        }
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600
    },
    title: {
        // margin: '0 0 10px',
        fontSize: 11,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    viewAllButton: {
        margin: '0 10px',
        color: 'blue', // #112386
        fontSize: 11,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
        fontWeight: 600
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'center'
    },
    shareOptionStyles: {
        fontSize: 14,
        '& #share-to-wall': {
            width: 20,
            height: 20,
            marginRight: 10,
            // '& path': {
            //   fill: theme.palette.primary.main
            // },
            // '& path[fill="white"]': {
            //   fill: '#FFF',
            // },
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& #download': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        },
        '& #Email': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        }
    },
    notification: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        height: '30px',
        fontSize: '12px',
    },
    autocompleteRoot: {
        width: "200px",
        height: "33px",
        borderRadius: 3,
        overflow: "clip",
        whiteSpace: "nowrap",
        margin: '5px',
        "& .MuiAutocomplete-tagSizeSmall": {
            width: "65px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
        "& .MuiAutocomplete-input": {
            padding: '1.5px 4px !important'
        }


    },
}));

const pagination = {
    limit: 5,
    page: 0,
}
const TopFivePlayerSummary = ({ setLoading, keycloak, history, seasonFormat = '', roundId = '', matchId, filtersData, userDetails, getRoundPlayerStatistics, getPlayerRaceLiveScore, shareLiveScoreDialog, dialog, isTeamRacePlayerSprint, openCreateArenaDialog, uploadLogDate, roles, teams, handleChangeFilter, Filter, matchesList, getDivisionList, seasonKpiPostions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataByDiv, setDataByDiv] = useState(null);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [canDownload, setCanDownload] = useState(false);
    const [resData, setResData] = useState({ response: [] });
    const [decimalView, setDecimalView] = useState(false);
    const { path } = useRouteMatch();
    const [shareAnchorEl, setShareAnchorEl] = React.useState({
        el: null,
        arena: null
    });
    const isShareMenuOpen = Boolean(shareAnchorEl.el);

    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');
    const [tableData, setTableData] = React.useState([]);
    const [checkColumn, setCheckColumn] = React.useState([{ isChecked: false }]);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [selectedEmps, setSelectedEmps] = React.useState([]);
    const [divisions, setDivisions] = useState([]);


    useEffect(() => {
        if (tableData) {
            setSelectedEmps(tableData.filter(ele => ele.isChecked))
        }

    }, [tableData]);


    useEffect(() => {
        if (roundId && roundId !== 'ALL' && !filtersData.season?.divisions_enabled) {
            setLoading(true);
            getRoundPlayerStatistics({ roundId, onSuccessCB, onErrorCB, params: { limit: 5, page: 1, team_id: Filter.team?._id, role_id: Filter.role?.role_id } });
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
    }, [roundId, Filter.role, Filter.team]);

    useEffect(() => {
        if (matchId && matchId !== 'ALL' && !filtersData.season?.divisions_enabled) {
            setLoading(true);
            getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5, page: 1, team_id: Filter.team?._id, role_id: Filter.role?.role_id } });
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
    }, [matchId, Filter.role, Filter.team]);

    // useEffect(() => {
    //     if (resData.response?.length > 0) {
    //         onSuccessCB(resData.response, resData.headers, true);
    //     }
    // }, [decimalView]);

    useEffect(() => {
        if (filtersData?.season?._id) {
            setDivisions([]);
            getDivisionList({
                seasonId: filtersData?.season?._id,
                params: { limit: pagination.limit, page: 1 },
                onSuccessCB: onDivisionSuccessCB,
                onErrorCB: onDivisionErrorCB
            })
        }
        else setDivisions([]);
    }, [filtersData.season?._id])


    const getDivisions_List = () => {
        let seasonId = filtersData?.season?._id
        setLoading(true);
        getDivisionList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: pagination.page + 1, },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const onDivisionSuccessCB = (result, headers) => {
        if (result) {
            setDivisions(prevState => ([...prevState, ...result]));
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }
    const onDivisionErrorCB = () => { }

    const onSuccessCB = (response, headers, fromDecimalView) => {
        // if (!fromDecimalView) {
        //     setResData({ response, headers });
        // }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, selected: item.employee_id === userDetails?.username, totalPoints: item.total_points, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'summary_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                // let totalPoints = 0;
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    // obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    // obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    // obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE') ? el.score || 0 : el.points || 0);

                    if (index === 0) {
                        const mapped_headers = el.summary_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[
                            {
                                title: `${el.kpi_code || el.kpi_name}`,
                                field: `${el.kpi_id}-target`,
                                kpiname: `${el.kpi_name}`,
                                editable: 'never',
                                emptyValue: '--',
                                cellStyle: { maxWidth: 150, minWidth: 100 },
                                attribute: el.summary_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('TARGET'),
                                templateRender: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`,
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_code || el.kpi_name}`, //-Actual
                                field: `${el.kpi_id}-actual`,
                                kpiname: `${el.kpi_name}`,
                                editable: 'never',
                                emptyValue: '--',
                                cellStyle: el?.based_on === 'ACTUAL' ? { color: el.status_code, maxWidth: 150, minWidth: 100 } : { maxWidth: 150, minWidth: 100 },
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('ACTUAL'),
                                templateRender: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`,
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                                // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-achieved`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                cellStyle: el?.based_on === 'ACHIEVEMENT' ? { color: el.status_code } : {},
                                attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('ACHIEVEMENT'),
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                            },
                            // {
                            //     title: `${el.kpi_name}`,
                            //     field: `${el.kpi_id}-planned_target`,
                            //     editable: 'never',
                            //     emptyValue: '--',
                            //     align: 'center',
                            //     attribute: el.summary_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 9 },
                            //     hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            //     render: (rowData) =>
                            //         rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                            //             <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                            //             : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                            //                 <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                            //                 : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // },
                            // {
                            //     title: `${el.kpi_name}`,
                            //     field: `${el.kpi_id}-planned_actual`,
                            //     editable: 'never',
                            //     emptyValue: '--',
                            //     align: 'center',
                            //     cellStyle: el?.based_on === 'ACTUAL' ? { color: el.status_code } : {},
                            //     attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 9 },
                            //     hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            //     render: (rowData) =>
                            //         rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                            //             <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                            //             : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                            //                 <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                            //                 : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // },
                            // {
                            //     title: `${el.kpi_name}`,
                            //     field: `${el.kpi_id}-planned_achievement`,
                            //     editable: 'never',
                            //     emptyValue: '--',
                            //     align: 'center',
                            //     cellStyle: el?.based_on === 'ACHIEVEMENT' ? { color: el.status_code } : {},
                            //     attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 9 },
                            //     hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            //     render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                            // },
                            {
                                title: `${el.kpi_name}`,//-Points
                                field: `${el.kpi_id}-points`,
                                editable: 'never',
                                emptyValue: '--',
                                hidden: !mapped_headers.includes('POINTS'),
                                attribute: el.summary_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 9 },
                                align: 'center',
                                cellStyle: { whiteSpace: 'nowrap' },
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                            }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),

                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setCheckColumn([{ isChecked: false }])
                // setCheckColumn[0].isChecked = false
                // if (filtersData.season?.divisions_enabled)
                //     setDataByDiv(groupBy(finalResult, 'division_name'));
                // else
                setData(finalResult);
                setTableData(finalResult);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setDataByDiv(null);
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setDataByDiv(null);
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleShareScore = (id) => {
        shareLiveScoreDialog({ open: true, fixture: 'ROUND', notify: 'PLAYER', details: { seasonId: filtersData.season?._id, roundId: filtersData.round?._id, imageId: id }, filtersData: filtersData });
        handleShareMenuClose();
    }

    const handleDownloadPerformance = async (id, title, type) => {
        await setCanDownload(true);
        toJpeg(document.getElementById(id))
            .then((dataUrl) => {
                setCanDownload(false);
                try {
                    const img = new Image();
                    img.onload = async function () {
                        let high = (this.width > this.height) ? 'width' : 'height';
                        const resultImage = (this.width > 0 && this.height > 0) ? await resizeImageToSquare(dataUrl, { width: this[high], height: this[high] }) : null;
                        if (resultImage) {
                            if (type === 'download') {
                                downloadFiles(resultImage, title.replace(/\./g, ' '));
                                handleShareMenuClose()
                            }
                            else {
                                openCreateArenaDialog({
                                    open: true, arena: {}, stepType: 'crop', attachment: [
                                        { url: resultImage, mimeType: "IMAGE", width: 4, height: 4, fileName: "kpi_performance_HHD-49_kpi_ck_bk -2022-08-18T133448.437.png", fileType: "image/png" }],
                                    postDetails: {
                                        targetPage: "PLAYER_LIVE_SCORES",
                                        details: {
                                            league: filtersData?.league?._id,
                                            season: filtersData?.season?._id,
                                            round: filtersData?.season?.season_format === 'HEAD_TO_HEAD' ? filtersData?.round?._id : null,
                                            match: filtersData?.season?.season_format === 'TEAM_RACE' ? filtersData?.playermatch?._id : filtersData?.match?._id,

                                        }
                                    }
                                });
                                handleShareMenuClose()
                            }

                        }

                    }
                    img.src = dataUrl;
                    return img.onload();
                } catch (e) {
                }
                // downloadFiles(dataUrl, title.replace(".", ""));
            })
    }

    const handleChange = (row, rowIndex, type) => {
        if (type === 'close') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[0].isChecked = false
            tableDataDup.forEach(player => player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            setTableData([...tableDataDup])


        }
        else if (type === 'all') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[rowIndex].isChecked = !checkColumnDup[rowIndex].isChecked
            tableDataDup.forEach(player => checkColumnDup[rowIndex].isChecked === true ? player.isChecked = true : player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            setTableData([...tableDataDup])

        }
        else {
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            tableDataDup[rowIndex].isChecked = !tableDataDup[rowIndex].isChecked
            setTableData([...tableDataDup])
        }

    };

    const handleCloseNotificationDialog = () => {
        setOpenConfirmDialog(false)
        handleChange('', '', 'close');
        setSelectedEmps([])
    }

    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            cellStyle: { maxWidth: 200, width: 200 },
            templateRender: (rowData) => rowData.player?.name.toUpperCase(),
            render: (rowData) => rowData.player ? (
                <div style={{ fontSize: 12 }} className={classes.nameRoot}>
                    <AvatarComponent type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            hidden: seasonFormat === 'PLAYER_RACE',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--',
            templateRender: (rowData) => rowData.team ? (
                <div >
                    {rowData.team && rowData.team.name}
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap' }}>
                        {rowData.captain && <span><span style={{ fontWeight: 600 }}>C</span> : {rowData.captain}</span>}
                        {rowData.mentor && <span style={{ marginLeft: 10 }}><span style={{ fontWeight: 600 }}>M</span> : {rowData.mentor}</span>}
                    </div>
                </div>
            ) : '--',
        },
        {
            title: "Points",
            field: "totalPoints",
            editable: 'never',
            // cellStyle: { color: '#333' },
            templateRender: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>,
            render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
        }
    ]
    const SummaryTable = ({ data, checkColumn, handleChange, isAdmin }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns, {
                title: "Points",
                field: "totalPoints",
                editable: 'never',
                templateRender: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>,
                render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
            }]}
            data={data}
            checkColumn={checkColumn}
            handleChange={handleChange}
            isAdmin={isAdmin}
            options={{
                // search: false,
                // actionsColumnIndex: -1,
                // showTitle: false,
                // addRowPosition: 'first',
                // emptyRowsWhenPaging: false,
                // toolbar: false,
                // pageSizeOptions: [5, 10, 25],
                pageSize: 5,
                // paging: false
            }}
            // spanning={true}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {isAdmin &&

                                <TableCell rowSpan={2} style={{ maxWidth: 70, width: 70 }}>
                                    <Checkbox
                                        style={{
                                            width: 5,
                                            height: 5,
                                        }}
                                        checked={checkColumn[0].isChecked}
                                        onChange={() => handleChange('', 0, 'all')}
                                        color="primary"
                                        size="small"

                                    /></TableCell>
                            }
                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                            {seasonFormat !== 'PLAYER_RACE' && <TableCell rowSpan={2}>{props.columns[3]?.title}</TableCell>}
                            <TableCell rowSpan={2}>{props.columns[4]?.title}</TableCell>
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                        <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                    </TableCell>
                                ))
                            }
                            <TableCell rowSpan={2}>Points</TableCell>
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeaders.map((obj) => (
                                    <>
                                        {
                                            obj?.summary_attributes.length ?
                                                <>
                                                    {
                                                        obj?.summary_attributes.map(ele => (
                                                            ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                        ))
                                                    }
                                                    {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                </>
                                                :
                                                <>
                                                    {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                    {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: '2px solid #f0f2f5' }} >Actual</TableCell>}
                                                    {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                </>
                                        }
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    )
    const handleDetailedScore = () => {
        history.push(`${path}/detailed-player-performances${history.location?.search}`);
    };

    const handleViewAll = () => {
        history.push(`${path}/all-player-performances${history.location?.search}`);
    };

    const handleShareMenuOpen = (event) => {
        setShareAnchorEl({ el: event.currentTarget, arena: null });
    };

    const handleShareMenuClose = () => {
        setShareAnchorEl({ el: null, arena: null });
    };

    const onClickBuzz = () => {
        if (selectedEmps.length === 0) {
            enqueueSnackbar(`Make sure at least one user is selected`, {
                variant: "error",
                preventDuplicate: true
            });
        }
        else {
            setOpenConfirmDialog(true);
        }


    }




    const renderShareOptions = (

        <Menu
            anchorEl={shareAnchorEl.el}
            classes={{ paper: classes.profilePopup }}
            id="share-options-menu"
            keepMounted
            open={isShareMenuOpen}
            onClose={handleShareMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            style={{ zIndex: 1301 }}
        >
            <MenuItem onClick={() => handleDownloadPerformance('player-perfomances-table', `Player_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : (isTeamRacePlayerSprint ? filtersData.playermatch?.title : filtersData.match?.title)}`, 'download')} className={classes.shareOptionStyles} ><GetAppIcon id="download" />Download</MenuItem>
            <MenuItem onClick={() => handleDownloadPerformance('player-perfomances-table', `Player_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : (isTeamRacePlayerSprint ? filtersData.playermatch?.title : filtersData.match?.title)}`, 'share')} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-wall" />Share to Wall</MenuItem>
            <MenuItem onClick={() => handleShareScore('player-perfomances-table1')} className={classes.shareOptionStyles}><MailOutlineOutlinedIcon id="Email" />Email </MenuItem>
        </Menu>
    );

    const playerEmailTemplateColumns = columns.filter((obj, i) => filtersData?.season?.season_format === "PLAYER_RACE" ? obj.title !== 'Team' : true)
    return (
        <div style={{ marginBottom: 20 }}>
            {
                (matchesList.length === 0) ? < IllustrationView loading={false} style={{ marginTop: '50px' }} /> :


                    [
                        (filtersData.season?.divisions_enabled) ?
                            <>
                                <InfiniteScroll
                                    dataLength={divisions.length}
                                    next={getDivisions_List}
                                    hasMore={divisions.length !== pagination.total_count}
                                    // loader={skeletonLoading}
                                    scrollableTarget="livescoresRoot"
                                >
                                    {
                                        divisions.map((ele, index) => (
                                            <PlayerSummaryDivisionWise key={ele._id} index={index} divisionObj={ele} limit={5} columns={columns} filtersData={filtersData} type="TOPFIVE" decimalView={decimalView} setDecimalView={setDecimalView} uploadLogDate={uploadLogDate} roles={roles} teams={teams} handleChangeFilter={handleChangeFilter} Filter={Filter} divId={ele._id} />
                                        ))
                                    }
                                </InfiniteScroll >
                            </>
                            :
                            <>
                                <div className={classes.root}>
                                    {
                                        data.length > 0 && shareAnchorEl.el &&
                                        <>
                                            <PerformanceTableTemplate
                                                id="player-perfomances-table"
                                                filtersData={filtersData}
                                                belongsTo='Players'
                                                columns={columns}
                                                dynamicColumns={dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')}
                                                data={data}
                                                render={(canDownload || dialog?.open)}
                                                decimalView={decimalView}
                                                isUploadLogRequired={true}
                                                uploadLogDate={uploadLogDate}


                                            />
                                            <PerformanceTablePlayersEmailTemplate
                                                id="player-perfomances-table1"
                                                filtersData={filtersData}
                                                belongsTo='Players'
                                                columns={[...playerEmailTemplateColumns, ...dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')]}
                                                data={data}
                                                isUploadLogRequired={true}
                                                render={(canDownload || dialog?.open)}

                                            />

                                        </>
                                    }
                                    <p className="table-title decimal-switch-root">
                                        <p>
                                            <span>Player performance : <span style={{ color: '#000' }}>{filtersData.season?.season_format === 'HEAD_TO_HEAD' ? filtersData.round?.name : (isTeamRacePlayerSprint ? filtersData.playermatch?.title : filtersData.match?.title)}</span> <GetDates filtersData={isTeamRacePlayerSprint ? { ...filtersData, match: filtersData.playermatch } : filtersData} /></span>
                                        </p>
                                        <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            {(filtersData?.season?.season_format !== 'PLAYER_RACE' && isAdmin) && <FormControl fullWidth className={classes.formRoot}>
                                                <Autocomplete
                                                    id="teams"
                                                    size="small"
                                                    options={teams}
                                                    getOptionLabel={(option) => option.name || ''}
                                                    value={Filter?.team}
                                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                                    renderInput={(params) => <TextField {...params} label="Teams" variant="outlined" />}
                                                    onChange={(...args) => handleChangeFilter(args[1], 'team')}
                                                />

                                            </FormControl>}
                                            {isAdmin &&
                                                <FormControl fullWidth className={classes.formRoot}>
                                                    <Autocomplete
                                                        id="roles"
                                                        size="small"
                                                        options={roles}
                                                        getOptionLabel={(option) => option.name || ''}
                                                        value={Filter?.role}
                                                        classes={{ inputRoot: classes.autocompleteRoot }}
                                                        renderInput={(params) => <TextField {...params} label="Roles" variant="outlined" />}
                                                        onChange={(...args) => handleChangeFilter(args[1], 'role')}
                                                    />

                                                </FormControl>}
                                        </div>
                                        {isAdmin && data.length > 0 && <div className={classes.iconDiv}>
                                            <div>
                                                <Button variant="contained" className={classes.notification} disabled={tableData.filter(obj => obj.isChecked).length === 0} color="primary" onClick={() => setOpenConfirmDialog(true)}>
                                                    Buzz
                                                </Button>
                                            </div>
                                            <IconButton onClick={(e) => handleShareMenuOpen(e,)} >
                                                <ShareSharpIcon fontSize="small" />
                                            </IconButton>
                                        </div>}
                                        {renderShareOptions}
                                    </div>
                                    {
                                        (data.length === 0) ? < IllustrationView loading={false} style={{ marginTop: '50px' }} /> :

                                            <SummaryTable
                                                data={tableData}
                                                checkColumn={checkColumn}
                                                handleChange={handleChange}
                                                isAdmin={isAdmin} />}
                                    {
                                        data.length > 0 &&
                                        <p className="table-footer">
                                            <span className={classes.viewAllButton} onClick={() => handleDetailedScore()}>View Detailed score<ArrowForward /></span>
                                            <span className={classes.viewAllButton} onClick={() => handleViewAll()}>View all<ArrowForward /></span>
                                        </p>
                                    }

                                </div>
                                {
                                    openConfirmDialog && <NotificationDialog
                                        open={openConfirmDialog}
                                        handleClose={handleCloseNotificationDialog}
                                        selectedEmps={selectedEmps}
                                        setSelectedEmps={setSelectedEmps}
                                        handleUncheck={handleChange}
                                        from="LiveScores"
                                    />

                                }
                            </>
                    ]

            }

        </div >)
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundPlayerStatistics: options => dispatch(getRoundPlayerStatistics(options)),
        getPlayerRaceLiveScore: options => dispatch(getPlayerRaceLiveScore(options)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),
        getDivisionList: (options) => dispatch(getDivisionsList(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopFivePlayerSummary));