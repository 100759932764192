import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFollowingUserList, getFollowUserList, getMutualFollowingUserList } from '../../ducks/favourites';
import { getMediaList } from '../../ducks/user';
import { getEllipsisText } from '../../helpers';
import { useSnackbar } from 'notistack';
import ViewShortProfile from '../Profile/ViewShortProfile';
import FavouritesList from '../ArenaComponents/FavouritesList';

const useStyles = makeStyles(theme => ({
    root: {
        // position: 'fixed',
        width: 210,
        minWidth: 210,
        marginLeft: 'auto'
    },
}));

const ArenaLeftSection = ({ staticContext, ...others }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} {...others}>
            <ViewShortProfile />
            <FavouritesList />
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        // getFollowUserList: value => dispatch(getFollowUserList(value)),
        // getFollowingUserList: value => dispatch(getFollowingUserList(value)),
        // getMutualFollowingUserList: (options) => dispatch(getMutualFollowingUserList(options)),
        // getMediaList: value => dispatch(getMediaList(value))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArenaLeftSection));