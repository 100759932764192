import React, { useEffect, useState } from 'react'
import { List, ListItem, ListItemAvatar, Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import classNames from 'classnames';
import { ReactComponent as ReactSurvey } from '../../assets/icons/survey1.svg'
import { setCurrentSurvey, getSurveys, getSurveyDetails } from '../../ducks/surveys';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { selectSurveys } from '../../selectors';
import { getEllipsisText } from '../../helpers';
import qs from 'query-string';



const surveyStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        // maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
        // fontFamily: 'Roboto'
    },
    listItemContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0px',
        whiteSpace: 'nowrap',
        '& div': {
            width: '100%'
        }
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '7px',
        fontSize: 13,
        color: '#535353',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    secondarytext: {
        margin: 0,
        fontSize: '11px',
        color: '#838383'
    },
    statusText: {
        margin: 0,
        fontSize: '10px',
        fontWeight: 600,
        color: props => props.statusColor,
    },
    svgIconStyles: {
        '& circle': {
            fill: theme.palette.primary.main
        }
    },
}));

const surveyStatusList = [
    { status: 'expires', color: 'orange' },
    { status: 'responded', color: 'green' },
    { status: 'expired', color: 'red' }
]

const SurveyTemplate = ({ details, handleOnSurveyClick, setCurrentSurvey, history }) => {
    const classes = surveyStyles({ statusColor: surveyStatusList.find(item => item.status === details.status).color });
    let surveyIdfromWall = null;
    if (history.location.pathname.split('/')[2] === 'details') {
        let surveyfromWall = qs.parse(history.location.search)
        surveyIdfromWall = surveyfromWall?.surveyId


    }
    const handleClick = () => {
        setCurrentSurvey(details._id);
        handleOnSurveyClick(details);
    }

    return (
        <ListItem key={details._id} disabled={details.status === 'expired' || details.status === 'responded'} button selected={details.isCurrent || details?._id === surveyIdfromWall} onClick={() => handleClick(details)}>
            <ListItemAvatar>
                <ReactSurvey className={classes.svgIconStyles} />
            </ListItemAvatar>
            <div className={classes.listItemContent}>
                <div>
                    <p className={classes.primaryText}>{details.title && ((details.title.length > 18) ? details.title.substring(0, 18) + '...' : details.title) || details.id}<span className={classes.statusText}>{details.statusText && details.statusText}</span></p>
                    <p className={classes.secondarytext}>
                        {details.publish_date && `${getEllipsisText(details.created_by, 8)}. Created on ${moment(details.publish_date).format("DD MMM YYYY")}`}
                    </p>
                </div>
            </div>
        </ListItem>
    )
}

const useStyles = makeStyles((theme) => ({
    scroller: {
        maxHeight: '90vh',
        minHeight: '90vh',
        position: 'relative'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    noResults: {
        textAlign: 'center'
    }
}));


const SurveyList = React.memo((props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    let surveyIdfromWall = null;
    if (props.history.location.pathname.split('/')[2] === 'details') {
        let surveyfromWall = qs.parse(props.history.location.search)
        surveyIdfromWall = surveyfromWall?.surveyId


    }


    useEffect(() => {
        if (props.surveysList && Object.values(props.surveysList).some(survey => survey.isCurrent) && (!props.history.location.pathname.split('/')[2])) {
            const isCurrent = Object.values(props.surveysList).find(survey => survey.isCurrent);
            if (isCurrent) props.handleOnSurveyClick(isCurrent, true);
        }
    }, [props.surveysList])

    useEffect(() => {
        if (props.surveysList && Object.values(props.surveysList).find(survey => survey._id === surveyIdfromWall)) {
            const isCurrent = Object.values(props.surveysList).find(survey => survey._id === surveyIdfromWall)
            if (isCurrent) {
                props.setCurrent(isCurrent);
                props.setCurrentSurvey(isCurrent._id);


            }

        }
        else if (props.surveysList && !Object.values(props.surveysList).find(survey => survey._id === surveyIdfromWall)) {
            console.log('useffectcallinggggggggg2')
            props.setCurrentSurvey(null);


        }
    }, [surveyIdfromWall])

    useEffect(() => {
        const urlParams = new URLSearchParams(props.location.search);
        // console.log('SurveyList query ', urlParams, urlParams.has('s_id'), urlParams.get('s_id'), props);
        if (!props.surveysList || Object.values(props.surveysList).length === 0) {
            setLoading(true);
            props.getSurveys({ currentSurveyId: urlParams.get('s_id'), onGetSurveysSuccessCB, onGetSurveysErrorCB });
            // }
        }
        else {
            if (urlParams.has('s_id') && urlParams.get('s_id').length > 0 && !Object.values(props.surveysList).some(survey => survey._id === urlParams.get('s_id'))) {
                props.getSurveyDetails({ surveyId: urlParams.get('s_id'), onGetSurveysDetailsSuccessCB, onGetSurveysDetailsErrorCB })
            }
        }
        // if (urlParams.has('s_id') && urlParams.get('s_id').length > 0 && props.surveysList.some(survey => survey._id === urlParams.get('s_id'))) {
        //     console.log('SurveyList if');
        //     props.handleOnSurveyClick(props.surveysList.find(survey => survey._id === urlParams.get('s_id')), true);
        //     // props.setCurrentSurvey(urlParams.get('s_id'));
        // }
        // else if (urlParams.has('s_id') && urlParams.get('s_id').length > 0 && !props.surveysList.some(survey => survey._id === urlParams.get('s_id'))) {
        //     console.log('SurveyList else if');
        //     props.getSurveyDetails({ surveyId: urlParams.get('s_id'), onGetSurveysDetailsSuccessCB, onGetSurveysDetailsErrorCB })
        // }

        // if (props.surveysList && props.surveysList.some(survey => survey.isCurrent)) {
        //     console.log('SurveyList .some ');
        //     const isCurrent = props.surveysList.find(survey => survey.isCurrent);
        //     if (isCurrent) props.handleOnSurveyClick(isCurrent, true);
        // }
        // return () => props.setCurrentSurvey(null);
    }, []);


    const onGetSurveysDetailsSuccessCB = (result) => {
        // console.log('SurveyList Details result ', result);
        props.handleOnSurveyClick(result)
        setLoading(false)
    }

    const onGetSurveysDetailsErrorCB = (e) => {
        // console.log('SurveyList Details error ', e);
        if (e.status === 404) {
            enqueueSnackbar(e.data.error_description, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else
            enqueueSnackbar('Failed to fetch Survey Details', {
                variant: 'error',
                preventDuplicate: true
            });
        setLoading(false)
    }

    const onGetSurveysSuccessCB = (result) => {
        const urlParams = new URLSearchParams(props.location.search);
        if (urlParams.has('s_id') && urlParams.get('s_id').length > 0 && !(Object.values(result).some(survey => survey._id === urlParams.get('s_id')))) {
            props.getSurveyDetails({ surveyId: urlParams.get('s_id'), onGetSurveysDetailsSuccessCB, onGetSurveysDetailsErrorCB })
        }
        setLoading(false);
    }

    const onGetSurveysErrorCB = (e) => {
        setLoading(false)
    }

    return (
        <div className={classNames("scroller", classes.scroller)}>
            <List>
                {(props.surveysList && Object.values(props.surveysList).length > 0) ? Object.values(props.surveysList).map((survey, index) => (
                    <SurveyTemplate key={index} details={survey} handleOnSurveyClick={props.handleOnSurveyClick} setCurrentSurvey={props.setCurrentSurvey} history={props.history} />
                ))
                    :
                    <div className={classes.noResults}>
                        <p>No Surveys found</p>
                    </div>
                }
            </List>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
})

const mapStateToProps = (state, props) => ({
    surveysList: state.surveys,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getSurveys: (options) => dispatch(getSurveys(options)),
        setCurrentSurvey: (options) => dispatch(setCurrentSurvey(options)),
        getSurveyDetails: (options) => dispatch(getSurveyDetails(options)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);
