import { makeConstant } from "./_helpers";

const constant = makeConstant("chase/loading");

export const SET_LOADING = constant("SET_LOADING");

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload
});

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADING:
            return action.payload;
        default:
            return state;
    }
};
