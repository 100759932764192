import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/chats");

export const SET_OPEN = constant("SET_OPEN");

export const handleImageViewer = (payload) => ({
    type: SET_OPEN,
    payload
});

const initialState = {
    open: false,
    images: [],
    currentImageIndex: 0
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_OPEN: {
            console.log('SET_OPEN ', action)
            return {
                ...action.payload,
            }
        }

        default:
            return state;
    }
};
