import { makeConstant } from "./_helpers";
import { getStanzaMsgDetails } from "../helpers";

const constant = makeConstant("jchat/messages");

export const GET_CHAT_MESSAGES = constant("GET_CHAT_MESSAGES");
export const GET_SEARCH_MESSAGES = constant("GET_SEARCH_MESSAGES");
export const RECEIVED_MESSAGE = constant("RECEIVED_MESSAGE");
export const SEND_MESSAGE = constant("SEND_MESSAGE");
export const SEARCH_HISTORY = constant("SEARCH_HISTORY");
export const RECEIVED_HISTORY = constant("RECEIVED_HISTORY");
export const MARK_RECEIVED = constant("MARK_RECEIVED");
export const MARK_DISPLAYED = constant("MARK_DISPLAYED");
export const UPDATE_MESSAGE_STATUS = constant("UPDATE_MESSAGE_STATUS");

export const receivedMessage = msg => ({
  type: RECEIVED_MESSAGE,

  payload: msg
});

export const receivedHistory = (data, withJid, username, params) => ({
  type: RECEIVED_HISTORY,
  payload: { data, withJid, username, params }
})

export const sendMessage = msg => ({
  type: SEND_MESSAGE,
  payload: msg
});

export const searchHistory = opt => ({
  type: SEARCH_HISTORY,
  payload: opt
});

export const getChatMessages = payload => ({
  type: GET_CHAT_MESSAGES,
  payload
});

export const getSearchMessages = payload => ({
  type: GET_SEARCH_MESSAGES,
  payload
});

export const markAsReceived = payload => ({
  type: MARK_RECEIVED,
  payload
});

export const markAsDisplayed = (payload = []) => ({
  type: MARK_DISPLAYED,
  payload
});

export const updateMessageStatus = (payload = []) => ({
  type: UPDATE_MESSAGE_STATUS,
  payload
});

// reducer
export default (state = {}, action) => {
  switch (action.type) {

    case RECEIVED_HISTORY: {
      console.log('RECEIVED_HISTORY')

      // let messages = action.payload.data.results.reduce((messages, obj, index) => {
      //   if (!obj.item.message.stanzaIds && obj.item.message.type === 'chat' && obj.item.message.body) {
      //     // console.log('type chat ', messages, index);
      //     // if((messages.length > 0) && messages[index - 1].id === obj.item.message.id){
      //     //   return messages;
      //     // }
      //     messages.push({
      //       ...obj.item.message,
      //       time: (obj.item.delay && obj.item.delay.timestamp) || new Date(),
      //     });
      //   }
      //   if (obj.item.message.type === 'groupchat' && obj.item.message.body) {
      //     messages.push({
      //       ...obj.item.message,
      //       time: (obj.item.delay && obj.item.delay.timestamp) || new Date(),
      //     });
      //   }
      //   return messages;
      // }, []);
      // console.log('RECEIVED_HISTORY ', messages);

      const peerJid = action.payload.withJid;
      const page = action.payload.params ? action.payload.params.page : 0;
      const data = action.payload.data || [];
      let peer = (state[peerJid] && page !== 0) && state[peerJid] || {
        jid: peerJid,
        messages: []
      };
      // let resultData = data.reduce((arr, message) => {
      //   // const id = (message.stanza && (xml_to_js.xml2js(message.stanza)) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes.id;
      //   const { messageDisplayed, messageReceived, outgoing, id } = getStanzaMsgDetails(message.stanza, action.payload.username);

      //   if (!(arr.some(obj => obj.id === id) || peer.messages.some(obj => obj.id === id))) {
      //     // console.log('eventName resultData ', peer, id, message, peer.messages.some(obj => obj.id === id));
      //     arr.push({
      //       ...message,
      //       id,
      //       from: (messageDisplayed || messageReceived) ? message.toJid : message.fromJid,
      //       to: (messageDisplayed || messageReceived) ? message.fromJid : message.toJid,
      //       time: message.sentdate,
      //       messageStatus: messageDisplayed ? 'displayed' : messageReceived ? 'received' : 'sent',
      //       outgoing
      //     })
      //   }
      //   else {
      //     const arrIndex = arr.findIndex(obj => obj.id === id);
      //     const peerIndex = peer.messages.findIndex(obj => obj.id === id);
      //     console.log('eventName resultData index ', arrIndex, peerIndex);
      //     if (arrIndex >= 0) arr[arrIndex].time = message.sentdate;
      //     else
      //       peer.messages[peerIndex].time = message.sentdate;
      //   }
      //   return arr;
      // }, []);
      // resultData.sort((a, b) => (a.sentdate > b.sentdate) ? 1 : -1)
      // console.log('eventName resultData ', resultData, peer);

      // return state;

      return {
        ...state,
        [peer.jid]: {
          ...peer,
          history: true,
          messages: [
            ...action.payload.data,
            // ...resultData,
            ...peer.messages,
          ]
        }
      };
    }

    case RECEIVED_MESSAGE: {
      const msg = action.payload;
      console.log('RECEIVED_MESSAGE eventName ', msg);
      let peerJid;
      if (msg.msgStatus === 'sent') {
        peerJid = msg.to;
      }
      else {
        peerJid = msg.from.split('/')[0];
      }
      const peer = state[peerJid] || {
        jid: peerJid,
        messages: []
      };

      if (peer.messages.find(item => item.id && (item.id === msg.id))) {
        // console.log('peer.messages.find eventName ', peer.messages.find(item => item.id && (item.id === msg.id)));
        const findMsgIndex = peer.messages.findIndex(item => item.id && (item.id === msg.id));

        peer.messages[findMsgIndex].messageStatus = msg.messageStatus;
        peer.messages[findMsgIndex].outgoing = msg.outgoing;

        return {
          ...state,
          [peer.jid]: {
            ...peer,
            history: peer.messages && (peer.messages.length === 0 && !peer.history) ? false : true,
            messages: [
              ...peer.messages
            ]
          }
        };

        // return state;
      }
      else if (msg.messageStatus === 'displayed' || msg.messageStatus === 'received') {
        return state;
      }

      // Skip initial presence messages delivered when joining room
      if (msg.type === "available" || msg.type === 'unavailable' || !msg.body) {
        return state;
      }

      if (peer.messages && (peer.messages.length === 0 && !peer.history)) { //  && msg.msgStatus === 'sent'
        return {
          ...state,
          [peer.jid]: {
            ...peer,
            history: false,
            messages: []
          }
        };
      }
      else {
        return {
          ...state,
          [peer.jid]: {
            ...peer,
            history: peer.messages && (peer.messages.length === 0 && !peer.history) ? false : true,
            messages: [
              ...peer.messages,
              msg
            ]
          }
        };
      }

    }
    case UPDATE_MESSAGE_STATUS: {
      const msgList = action.payload;
      console.log('UPDATE_MESSAGE_STATUS eventName ', msgList);
      if (msgList && msgList.length > 0) {
        let peerJid = msgList[0].from.split('/')[0];
        const peer = state[peerJid];

        msgList.forEach(msg => {
          const findMsgIndex = peer.messages.findIndex(item => item.id && (item.id === msg.id));
          if (peer.messages[findMsgIndex]) {
            peer.messages[findMsgIndex].messageStatus = 'displayed';
            peer.messages[findMsgIndex].outgoing = msg.outgoing;
          }
        });

        console.log('UPDATE_MESSAGE_STATUS eventName peer', peer.messages);
        return {
          ...state,
          [peer.jid]: {
            ...peer,
          }
        };
      }
      else {
        return state;
      }
    }

    default:
      return state;
  }
};
