import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
} from "@material-ui/core";
import classNames from "classnames";
import { Close } from "@material-ui/icons";
import Details from './Details'
import Chat from "./Chat";
import Files from "./Files";
import { setDetails } from "../../../ducks/user";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    // backgroundColor: "#FFFFFF",
    // maxWidth: "390px",
    // marginLeft: "650px",
    // boxSizing: "border-box",
    // margin: 0,
    // padding: 0,
    // boxShadow: "0px 3px 6px #00000029"
  },
  title: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: 600,
    backgroundColor: "#f5f6f7",
    color: "#000000",
    position: "relative",
    padding: "10px 25px",
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 5,
  },
  pageButtonRoot: {
    display: "flex",
    justifyContent: "space-around",
    padding: '0px 20px',
    "& .isActive": {
      backgroundColor: "#828282",
      color: "#FFFFFF",
    },
  },
  pageButton: {
    height: "30px",
    width: "110px",
    borderRadius: "15px",
    margin: "15px 5px",
    backgroundColor: "#e6e6e6",
    padding: "8px 18px",
    fontSize: "15px",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: "#9b9b9b80",
    },
  },
}));

const MeetDetails = ({ id, title, occurrenceDate, isJoined = false }) => {

  const handleClose = () => { };

  const [currentPage, setCurrentPage] = useState("DETAILS");
  const [details, setDetails] = useState(null);
  const classes = useStyles();
  return (
    <div className={classes.mainRoot}>
      {/* <div className={classes.title}>
        <span>Development...</span>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </div> */}
      <div className={classes.pageButtonRoot}>
        <Button
          variant="text"
          className={classNames(
            classes.pageButton,
            currentPage === "DETAILS" && "isActive"
          )}
          onClick={() => setCurrentPage("DETAILS")}
        >
          Details
        </Button>
        {/* <Button
          disabled
          variant="text"
          className={classNames(
            classes.pageButton,
            currentPage === "CHAT" && "isActive"
          )}
          onClick={() => setCurrentPage("CHAT")}
        >
          Chat
        </Button> */}
        <Button
          variant="text"
          className={classNames(
            classes.pageButton,
            currentPage === "FILES" && "isActive"
          )}
          onClick={() => setCurrentPage("FILES")}
        >
          Files
        </Button>
      </div>
      { currentPage === "DETAILS" ? <Details id={id} title={title} isJoined={isJoined} occurrenceDate={occurrenceDate} setDetails={setDetails} /> : currentPage === "CHAT" ? <Chat /> : <Files details={details} />}
    </div>
  );
};

export default MeetDetails;