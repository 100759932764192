
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { setSidebarIndex } from '../../ducks/sidebar';
import LandingImage from '../../assets/images/surveyimage.svg';
import SurveyList from './SurveyList';
import SurveyDetails from './SurveyDetails';
import SurveyQuestions from './SurveyQuestions';
import { Redirect, Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import firebase from "firebase";
import { setCurrentSurvey } from '../../ducks/surveys';
import qs from 'query-string';



const useStyles = makeStyles((theme) => ({
    mainRoot: {
        width: '100%',
        maxWidth: 290,
        zIndex: 10,
        backgroundColor: '#fff',
        transition: 'width 0.25s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            // width: '0px',
            // minWidth: 0,
            position: 'fixed',
            borderRight: '1.5px solid #eeeeee'
        },
    },
    mainRootWidth: {
        width: 0,
        minWidth: 0
    },
    root: {
        flexGrow: 1,
        maxWidth: 330,

    },
    landingPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: 'inherit',
        '& img': {
            [theme.breakpoints.down('sm')]: {
                height: 225
            }
        }
    },
    surveyArea: {
        width: '100%',
        display: 'flex',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`,
        maxHeight: '500px',
        overflowY: 'auto'
    }

}));

const SurveyLayout = (props) => {
    const classes = useStyles();
    const [currentSurvey, setCurrent] = useState(null);
    const [questions, setQuestions] = useState(false);
    const [openList, setOpenList] = useState(false);
    const { path } = useRouteMatch();
    let surveyIdfromWall = null;
    if (props.history.location.pathname.split('/')[2] === 'details') {
        let surveyfromWall = qs.parse(props.history.location.search)
        surveyIdfromWall = surveyfromWall?.surveyId


    }

    useEffect(() => {
        setCurrent(Object.values(props.surveysList).find(survey => survey._id === surveyIdfromWall))
    }, [props.surveysList])


    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 960) {
                setOpenList(false);
            }
            else {
                props.setSidebarIndex(null);
            }
        });
        if (firebase.messaging.isSupported()) {
            const analytics = firebase.analytics();
            analytics.logEvent('page_view', {
                page_location: window.location.href,
                page_path: window.location.pathname,
                page_title: 'Survey'
            });
        }
    }, []);

    useEffect(() => {
        // console.log('sidebar survey index ', props.sidebar, openList)
        if (window.innerWidth <= 960) {
            if (props.sidebar.index !== null) {
                setOpenList(false);
            }
            else setOpenList(true);
        }
    }, [props.sidebar]);

    useEffect(() => {
        setQuestions(false);
    }, [currentSurvey]);

    const handleOnSurveyClick = (value, existingCurrent) => {
        setCurrent(value);
        props.history.push({
            pathname: `/survey/details`,
            search: `?surveyId=${value?._id}`
        })
        // props.history.push(`/survey/${value._id}`);
        if (window.innerWidth <= 960 && !existingCurrent) {
            setOpenList(true);
            props.setSidebarIndex(null);
        }
    }


    const handleStartSurvey = (id) => {
        setQuestions(true);
    }


    return (
        <>
            <div className={classNames(classes.mainRoot, openList && classes.mainRootWidth)}>
                <SurveyList handleOnSurveyClick={handleOnSurveyClick} setCurrent={setCurrent} {...props} />
            </div>
            <div className={classes.surveyArea}>
                <Switch>
                    <Route path={`${path}/details`}  >
                        {
                            questions ?
                                <SurveyQuestions {...currentSurvey} />
                                :
                                <SurveyDetails history={props.history} handleStartSurvey={handleStartSurvey} handleOnSurveyClick={handleOnSurveyClick} {...currentSurvey} />
                        }
                    </Route>
                    <Route path={path}  >
                        <div className={classes.landingPage}>
                            <img height="300" src={LandingImage} alt="Landing_Page"></img>
                        </div>
                    </Route>
                    <Redirect to={{ pathname: `${path}/details`, search: props.location.search }} />
                </Switch>
                {/* {
                    currentSurvey ?
                        [
                            questions ?
                                <SurveyQuestions {...currentSurvey} />
                                :
                                <SurveyDetails handleStartSurvey={handleStartSurvey} {...currentSurvey} />

                        ]

                        :
                        <div className={classes.landingPage}>
                            <img height="300" src={LandingImage} alt="Landing_Page"></img>
                        </div>
                } */}
            </div>
        </>

    );
}

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    surveysList: state.surveys,

});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setSidebarIndex: (options) => dispatch(setSidebarIndex(options)),
        setCurrentSurvey: (options) => dispatch(setCurrentSurvey(options)),



    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyLayout));