import React from 'react';
import SideNavBar from '../components/SideNavBar/SideNavBar';
import ImageViewer from '../components/ImageViewer';
import VideoPlayer from '../components/VideoPlayer';
import './CommonLayout.css'

const CommonLayout = ({ children, props }) => {
    console.log('CommonLayout ')
    return (
        <div className="root-layout">
            {/* <SideNavBar /> */}
            <div className="list-layout">
                {/* <div className="common-list"> */}
                {true && children}
                {/* </div> */}
            </div>
            <ImageViewer />
            <VideoPlayer />
        </div>
    )
}

export default CommonLayout;