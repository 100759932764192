import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GridList, GridListTile, GridListTileBar, Backdrop, CircularProgress, IconButton } from '@material-ui/core';
import { CameraAlt, PlayCircleOutline } from '@material-ui/icons';
import GroupImage from '../../../assets/images/group-image.png'
import GroupImageOne from '../../../assets/images/group-image-1.png'
import { getProfileMediaFiles } from '../../../ducks/common';
import { connect } from 'react-redux';
import moment from 'moment';
import { handleImageViewer } from '../../../ducks/imageViewer';
import { getImages, getSortedFiles } from '../../../selectors';
import { handleVideoPlayer } from '../../../ducks/videoPlayer';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '10px 12px',
        position: 'relative'
    },
    imageRoot: {
        width: '90%',
        height: 145,
        margin: '0 auto'
    },
    image: {
        width: '100%',
        height: '100%'
    },
    gridList: {
        marginBottom: '20px !important'
    },
    titleMonth: {
        margin: '10px 0 7px 3px',
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    listItem: {
        height: '100px !important',
        padding: '4px !important',
        cursor: 'pointer'
    },
    listItemTitle: {
        height: 20
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    videoStyles: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6A7279',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    playIcon: {
        color: '#fff'
    },
    imageStyle: {
        width: '100%',
        '&:hover': {
            transitionDuration: '500ms',
            width: '110%'
        }
    },
    emptyListText: {
        textAlign: 'center',
        margin: 30,
        fontSize: 14,
        color: '#58595B'
    }
}));

const imageMsgTypes = ['png', 'PNG', 'JPEG', 'JPG', 'SVG', 'jpeg', 'jpg', 'svg', 'gif', 'GIF'];
const videoTypes = ['mp4', 'MP4', 'm4a', 'M4A', 'm4v', 'M4V', 'f4v', 'F4V', 'f4a', 'F4A', 'm4b', 'M4B', 'm4r', 'f4b', 'mov', 'MOV', '3gp', 'ogg', 'OGG', 'wmv', 'WMV', 'webm', 'WEBM', 'flv', 'FLV'];

const MediaFiles = ({ media, currentChat, getProfileMediaFiles, handleImageViewer, getImagesList, handleVideoPlayer }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        console.log('MediaFiles media', media)
        setLoading(false);
    }, [media])

    useEffect(() => {
        console.log('MediaFiles targetJid', currentChat)
        if (currentChat.type === 'group') {
            let teamId = currentChat && currentChat.targetJid.split('@')[0];
            // if (teamId !== media.targetJid) {
            setLoading(true);
            getProfileMediaFiles({
                limit: 300,
                page: 0,
                target_jid: teamId,
                type: 'MEDIA',
                chat_type: 'team'
            });
            // }
            // else {
            //     setLoading(false);
            // }
        }
        else {
            let username = currentChat && currentChat.targetJid.split('@')[0];
            // if (username !== media.targetJid) {
            setLoading(true);
            getProfileMediaFiles({
                limit: 300,
                page: 0,
                target_jid: username,
                type: 'MEDIA',
                chat_type: 'employee'
            });
            // }
            // else {
            //     setLoading(false);
            // }
        }

    }, [currentChat.targetJid]);

    const getMediaType = (ext) => {
        if (imageMsgTypes.includes(ext)) return 'Photo';
        else if (videoTypes.includes(ext)) return 'Video';
        else return 'others';
    }

    return (
        <div id="MediaFiles" className="scroller" style={{ height: '70vh', maxHeight: '70vh', position: 'relative' }}>
            <div className={classes.root}>

                {
                    (media && media.length > 0) ? media.map(obj => (
                        <GridList cellHeight={180} className={classes.gridList}>
                            <GridListTile cols={2} style={{ height: 'auto' }}>
                                <p className={classes.titleMonth}>{obj.month}</p>
                            </GridListTile>
                            {
                                obj.files && obj.files.map((item, index) => (
                                    <GridListTile className={classes.listItem} key={index} onClick={getMediaType(item.mimeType) == 'Video' ? () => handleVideoPlayer({ open: true, video: item.sourceUrl }) : () => handleImageViewer({ open: true, images: [item.sourceUrl] })} >
                                        {
                                            getMediaType(item.mimeType) == 'Video' ?
                                                <div className={classes.videoStyles} >
                                                    <IconButton onClick={() => handleVideoPlayer({ open: true, video: item.sourceUrl })}>
                                                        <PlayCircleOutline className={classes.playIcon} />
                                                    </IconButton>
                                                </div>
                                                :
                                                <img className={classes.imageStyle} src={item.sourceUrl} alt={item.mimeType} />
                                        }
                                        <GridListTileBar
                                            className={classes.listItemTitle}
                                            // title={item.title}
                                            subtitle={<span>{getMediaType(item.mimeType)}</span>}
                                        // actionIcon={
                                        //     <CameraAlt fontSize="small" />
                                        // }
                                        />
                                    </GridListTile>
                                ))
                            }
                        </GridList>
                    ))
                    :
                    <p className={classes.emptyListText}>No Media Found</p>
                }
            </div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

const mapStateToProps = (state) => ({
    media: getSortedFiles(state.common.media.data),
    getImagesList: getImages(state.common.media)
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProfileMediaFiles: (options) => dispatch(getProfileMediaFiles(options)),
        handleImageViewer: (options) => dispatch(handleImageViewer(options)),
        handleVideoPlayer: (options) => dispatch(handleVideoPlayer(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaFiles);