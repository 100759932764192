import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, OutlinedInput, Switch, Button, List, ListItem, Backdrop, CircularProgress, useTheme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@material-ui/pickers';
import { ExpandMore, Check } from '@material-ui/icons';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromHTML, ContentState, } from 'draft-js';
import { useDropzone } from 'react-dropzone';
import { timeZonesList, timeOptionsList, repeatOptionsList, getFormattedDate, getLocalTime, getUTCFormattedDate, getLocalFormattedDate } from '../../helpers';
import { ReactComponent as TitleIcon } from '../../assets/icons/title.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/Location.svg';
import { ReactComponent as FileUploadIcon } from '../../assets/icons/file-upload.svg';
import { ReactComponent as AddUserIcon } from '../../assets/icons/add-usermeeting.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/time.svg';
import { ReactComponent as TimeZone } from '../../assets/icons/time-zone.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refreshmeeting.svg';
import { ReactComponent as AutolobbyIcon } from '../../assets/icons/auto-admit-to-lobby.svg';
import { ReactComponent as DescriptionIcon } from '../../assets/icons/Description.svg';
import { ReactComponent as PaperClipIcon } from '../../assets/icons/paperclip-2.svg';
import ChaseOnsAutocomplete from '../Arena/ChaseOnsAutocomplete';
import { setLoading } from '../../ducks/loading';
import { createScheduledMeeting, getMeetingDetails, updateScheduledMeeting } from '../../ducks/meetings';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { getFormatIcon } from './../../helpers';
import { uploadFilesToS3 } from '../../ducks/common';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '40px 0',
    },
    fieldRoot: {
        display: 'flex',
        alignItems: 'center',
        margin: '35px 0'
    },
    iconStyles: {
        marginRight: 16,
        "& .a": {
            fill: "#535353"
        }
    },
    timeZoneAutocompleteRoot: {
        borderRadius: 6,
        padding: 7
    },
    startTimeAutocompleteRoot: {
        borderRadius: 6,
        padding: '5px !important',
        width: 150
    },
    repeatutocompleteRoot: {
        borderRadius: 6,
        padding: '5px !important',
        width: 280
    },
    textFieldRoot: {
        borderRadius: 6
    },
    switchLabelRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    dateTimeRoot: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    datePickerStyles: {
        margin: '15px 30px',
        width: 150
    },
    datePickerInputStyles: {
        paddingLeft: 8,
        fontSize: 14,
        color: '#535353',
        height: '32px',
        cursor: 'pointer',
    },
    dateInputUnderine: {
        border: '1px solid #c2c2c2',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:before': {
            border: 'none'
        },
        '&:hover:not(.Mui-disabled):before': {
            border: 'none'
        },
        '& svg': {
            fill: '#535353'
        }
    },
    buttonRoot: {
        marginTop: 10,
        textAlign: 'right',
        '& button:nth-child(2)': {
            marginRight: 0
        }
    },
    button: {
        margin: '0 7px',
        width: 120,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    primarySvg: {
        '& path': {
            fill: theme.palette.primary.main
        }
    }
}));


const CustomizedSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        marginLeft: 15
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
        backgroundColor: '#FFFFFF'
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#58595b80',
        height: 'auto'
    },
    checked: {},
}))(Switch);

const baseStyle = {
    flex: 1,
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#CBCBCB',
    borderStyle: 'dashed',
    // backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: 170,
    textAlign: 'center',
    '& .a': {
        fill: "#535353"
    }
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


const listItemContent = {
    width: '100%',
    display: 'flex',
    wordBreak: 'break-all',
    justifyContent: 'space-between',
    margin: '10px 0px 10px 20px',
    alignItems: 'center'
}

const primaryText = {
    fontWeight: 600,
    margin: 0,
    paddingBottom: '7px',
    fontSize: 12,
    color: '#535353'
}

function StyledDropzone(props) {
    const theme = useTheme();
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        multiple: true,
        accept: ['.pdf', '.doc', '.xls', '.xlsx'],
        onDrop: acceptedFiles => {
            console.log('acceptedFiles ', acceptedFiles);
        },
        onDropAccepted: acceptedFiles => {
            console.log('onDropAccepted ', acceptedFiles);
        },
        // getFilesFromEvent: (e) => console.log('getFilesFromEvent ', e)
    });


    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            console.log('acceptedFiles outside ', acceptedFiles);
            props.handleChange(acceptedFiles);
        }
    }, [acceptedFiles]);

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);


    return (
        <div className="container" style={{ flexDirection: 'row', position: 'relative' }}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <div>
                    <FileUploadIcon className={props.classes && props.classes.primarySvg} />
                    <p>Choose a file or drag it here.</p>
                </div>
            </div>
            {
                props.attachments && props.attachments.length > 0 &&
                <div style={{ ...style, borderLeft: 'none' }}>
                    <div>
                        {props.attachments.map((file, index) => (
                            <div key={index}
                                style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}
                            //  onClick={() => downloadFiles(item.sourceUrl, item.sourceUrl && item.sourceUrl.split('-').pop())} 
                            >
                                <img width="25" src={getFormatIcon(file.mimeType)} alt={file.mimeType} />
                                <div style={listItemContent}>
                                    <div>
                                        <p style={primaryText}>{file.url && file.url.split('-').pop()}</p>
                                        {/* <p className={classes.secondarytext}>{item.size}</p> */}
                                    </div>
                                    <div style={{ marginLeft: 20 }}>
                                        <Check />
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            }
            <Backdrop style={{
                zIndex: theme.zIndex.drawer + 1,
                color: theme.palette.primary.main,
                opacity: 0.5,
                position: 'absolute'
            }} open={props.uploading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

const defaultStartDate = new Date();
const defaultEndDate = new Date();
const defaultStartTime = timeOptionsList.find(obj => obj.value === '10:00');
const defaultEndTime = timeOptionsList.find(obj => obj.value === '18:00');
const defaultRepeatValue = repeatOptionsList.find(obj => obj.value === 'NONE');

const ScheduleMeeting = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [details, setDetails] = useState({
        type: 'SCHEDULED',
        timeZone: null,
        title: '',
        participants: [],
        allDay: false,
        startDate: defaultStartDate,
        startTime: defaultStartTime,
        endDate: defaultEndDate,
        endTime: defaultEndTime,
        repeat: defaultRepeatValue,
        autoAdmit: false,
        location: '',
        description: '',
        attachments: []
    });

    const [selectedChaseOns, setSelectedChaseOns] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (props.fromEdit) {
            console.log('fromEdit ', props);
            props.getMeetingDetails({ onSuccessCB: onGetDetailsSuccessCB, onErrorCB: onGetDetailsErrorCB, id: props.match.params.id })
        }
    }, [props.fromEdit]);

    const onGetDetailsSuccessCB = (data) => {
        setDetails(prevState => ({
            ...prevState,
            id: data._id,
            type: data.type,
            timeZone: null,
            title: data.title,
            participants: data.participants && data.participants.filter(obj => !obj.organizer),
            allDay: data.schedule[0] ? data.schedule[0].all_day : false,
            startDate: getLocalFormattedDate(data.schedule[0].start_at),
            startTime: data.schedule[0] && timeOptionsList.find(obj => obj.value === `${getLocalTime(data.schedule[0].start_at)}`),
            endDate: getLocalFormattedDate(data.schedule[0].end_at),
            endTime: data.schedule[0] && timeOptionsList.find(obj => obj.value === `${getLocalTime(data.schedule[0].end_at)}`),
            repeat: repeatOptionsList.find(obj => obj.value === data.repeat),
            autoAdmit: data.auto_adimt,
            location: data.location,
            description: data.description,
            attachments: data.attachments
        }));
        const contentBlock = convertFromHTML(data.description);
        const contentState = ContentState.createFromBlockArray(contentBlock);
        const editorStateDefault = EditorState.createWithContent(contentState);
        setEditorState(editorStateDefault);
        setLoading(false);
    };
    const onGetDetailsErrorCB = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to get Meeting details.`, {
            variant: 'error',
            preventDuplicate: true
        });
        setLoading(false)
    };

    const onEditorStateChange = (data, a) => {
        console.log('onEditorStateChange ', data, a, data._immutable.currentContent);
        setEditorState(data);
    }
    const onEditorChange = (e, a) => {
        console.log('onEditorChange ', e.blocks[0].text, a);
        if (e.blocks[0])
            setDetails(prevState => ({ ...prevState, description: e.blocks[0].text }));
    }

    const handleChangeAutocomplete = (e, data, type) => {
        console.log('handleChangeAutocomplete ', e, data, type);
        switch (type) {
            case 'participants':
                if (data && data.length < 10)
                    setDetails(prevState => ({ ...prevState, participants: data }));
                break;
            case 'timeZone':
                setDetails(prevState => ({ ...prevState, timeZone: data }));
                break;
            case 'startTime':
                setDetails(prevState => ({ ...prevState, startTime: data }));
                break;
            case 'endTime':
                setDetails(prevState => ({ ...prevState, endTime: data }));
                break;
            case 'repeat':
                setDetails(prevState => ({ ...prevState, repeat: data }));
                break;
        }
    }

    const handleChangeInput = (e, type) => {
        // console.log('handleChangeInput ', e.target.value, type);
        const value = e.target.value;
        switch (type) {
            case 'title':
                setDetails(prevState => ({ ...prevState, title: value }))
                break;
            case 'location':
                setDetails(prevState => ({ ...prevState, location: value }))
                break;
            case 'description':
                setDetails(prevState => ({ ...prevState, description: value }))
                break;
        }
    }

    const handleChangeFiles = (files) => {
        console.log('handleChange ', files)
        files.forEach(file => {
            let formData = new FormData();
            formData.append("file", file);
            setUploading(true);
            props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file });
        });
    }

    const onUploadSuccessCB = (result, file) => {
        setDetails(prevState => ({ ...prevState, attachments: [...prevState.attachments, { url: result.url, thumbnailUrl: result.thumbnailUrl, mimeType: file.type.split('/').pop() }] }));
        setUploading(false);
    }

    const onUploadErrorCB = (result, file) => {
        console.log('onUploadErrorCB ', result);
        enqueueSnackbar(`Failed to Upload ${file.name}.`, {
            variant: 'error'
        });
        setUploading(false);
    }

    const handleSwitch = (type) => {
        setDetails(prevState => ({ ...prevState, [type]: !prevState[type] }))
    }

    const handleDateChange = (date, type) => {
        console.log('handleDateChange ', new Date(date._d).getFullYear(), new Date(date._d).getMonth());
        switch (type) {
            case 'startDate':
                setDetails(prevState => ({ ...prevState, startDate: date._d }));
                break;
            case 'endDate':
                setDetails(prevState => ({ ...prevState, endDate: date._d }))
                break;
        }
    }

    const handleCancel = () => {
        props.history.push('/meet');
    }

    const handleSubmitMeeting = () => {
        const requestObj = {
            "title": details.title,
            "type": details.type,
            "description": details.description,
            "location": details.location,
            "repeat": details.repeat && details.repeat.value,
            "auto_adimt": details.autoAdmit,
            "participants": details.participants && details.participants.map(obj => ({ username: obj.username, joining: 'maybe', email: obj.email })),
            // [
            //   {
            //     "email": "abc@gmail.com",
            //     "joining": "maybe"
            //   },
            //   {
            //     "username": "orgd1.vinodk",
            //     "joining": "maybe"
            //   }
            // ],
            "schedule": [
                {
                    "all_day": details.allDay,
                    "start_at": details.allDay ? getUTCFormattedDate(new Date(details.startDate).setHours(9, 0, 0, 0)) : getUTCFormattedDate(new Date(details.startDate).setHours(details.startTime.value.split(':')[0], details.startTime.value.split(':')[1], 0, 0)), // "2020-11-21T12:00:00"
                    "end_at": details.allDay ? getUTCFormattedDate(new Date(details.endDate).setHours(18, 0, 0, 0)) : getUTCFormattedDate(new Date(details.endDate).setHours(details.endTime.value.split(':')[0], details.endTime.value.split(':')[1], 0, 0)) // "2020-11-21T14:00:00"
                }
            ],
            "attachments": details.attachments
        }
        props.setLoading(true);
        props.createScheduledMeeting({
            requestObj, onSuccessCB, onErrorCB
        })
    }

    const onSuccessCB = (result, headers) => {
        props.setLoading(false);
        props.history.push('/meet');
    };

    const onErrorCB = error => {
        console.log(error);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to schedule Meeting.', {
            variant: 'error',
            preventDuplicate: true
        });
        props.setLoading(false);
    };

    const handleUpdateMeeting = () => {
        const requestObj = {
            "title": details.title,
            "type": details.type,
            "description": details.description,
            "location": details.location,
            "repeat": details.repeat && details.repeat.value,
            "auto_adimt": details.autoAdmit,
            "participants": details.participants && details.participants.map(obj => ({ username: obj.username, joining: 'maybe', email: obj.email })),
            // [
            //   {
            //     "email": "abc@gmail.com",
            //     "joining": "maybe"
            //   },
            //   {
            //     "username": "orgd1.vinodk",
            //     "joining": "maybe"
            //   }
            // ],
            "schedule": [
                {
                    "all_day": details.allDay,
                    "start_at": details.allDay ? getUTCFormattedDate(new Date(details.startDate).setHours(9, 0, 0, 0)) : getUTCFormattedDate(new Date(details.startDate).setHours(details.startTime.value.split(':')[0], details.startTime.value.split(':')[1], 0, 0)), // "2020-11-21T12:00:00"
                    "end_at": details.allDay ? getUTCFormattedDate(new Date(details.endDate).setHours(18, 0, 0, 0)) : getUTCFormattedDate(new Date(details.endDate).setHours(details.endTime.value.split(':')[0], details.endTime.value.split(':')[1], 0, 0)) // "2020-11-21T14:00:00"
                }
            ],
            "attachments": []
        }
        props.setLoading(true);
        props.updateScheduledMeeting({
            id: details.id, requestObj, onUpdateSuccessCB, onUpdateErrorCB
        })
    }

    const onUpdateSuccessCB = (result, headers) => {
        props.setLoading(false);
        props.history.push('/meet');
    };

    const onUpdateErrorCB = error => {
        console.log(error);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Update Meeting.', {
            variant: 'error',
            preventDuplicate: true
        });
        props.setLoading(false);
    };

    const CustomDatePicker = ({ views, value, onChange, ...others }) => {
        return (
            <DatePicker
                {...others}
                views={views}
                value={value}
                onChange={onChange}
                disableToolbar
                className={classes.datePickerStyles}
                InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <ExpandMore /> }}
            />
        )
    }

    return (
        <div className={classes.root}>
            {/* <div className={classes.fieldRoot}>
                <TimeZone className={classes.iconStyles} />
                <Autocomplete
                    id="combo-box-demo"
                    options={timeZonesList}
                    getOptionLabel={(option) => option.title}
                    fullWidth
                    value={details.timeZone}
                    classes={{ inputRoot: classes.timeZoneAutocompleteRoot }}
                    renderInput={(params) => <TextField {...params} placeholder="Time Zone" variant="outlined" />}
                    onChange={(...args) => handleChangeAutocomplete(args[0], args[1], 'timeZone')}
                />
            </div> */}
            <div className={classes.fieldRoot}>
                <TitleIcon className={classes.iconStyles} />
                <TextField
                    placeholder="Agenda Title"
                    fullWidth
                    variant="outlined"
                    value={details.title}
                    InputProps={{ classes: { root: classes.textFieldRoot } }}
                    onChange={(e) => handleChangeInput(e, 'title')}
                />
            </div>
            <div className={classes.fieldRoot}>
                <AddUserIcon className={classes.iconStyles} />
                <ChaseOnsAutocomplete openChaseons={true} inputLabelText="Add Participants"
                    selectedChaseOns={details.participants}
                    fromMeeting={true}
                    handleChangeAutocomplete={(...args) => handleChangeAutocomplete(args[0], args[1], 'participants')}
                />
            </div>
            <div className={classes.fieldRoot} style={{ alignItems: 'end' }}>
                <TimeIcon className={classes.iconStyles} />
                <div style={{ width: '100%' }}>
                    <div className={classes.switchLabelRoot} style={{ marginTop: 5 }}>
                        <span>All day</span><CustomizedSwitch id="all-day" checked={details.allDay} onChange={() => handleSwitch('allDay')} />
                    </div>
                    <div className={classes.dateTimeRoot}>
                        <p>Start</p>
                        <CustomDatePicker
                            views={["date"]}
                            format={"DD MMM YYYY"}
                            variant="inline"
                            // minDate={props.fromEdit ? details.startDate : defaultStartDate}
                            // minDate={defaultStartDate}
                            value={details.startDate || defaultStartDate}
                            onChange={(e) => handleDateChange(e, 'startDate')}
                        />
                        {
                            !details.allDay &&
                            <Autocomplete
                                id="start-time"
                                options={timeOptionsList}
                                value={details.startTime}
                                getOptionLabel={(option) => option.title}
                                classes={{ inputRoot: classes.startTimeAutocompleteRoot }}
                                renderInput={(params) => <TextField {...params} placeholder="Start Time" variant="outlined" />}
                                onChange={(...args) => handleChangeAutocomplete(args[0], args[1], 'startTime')}
                            />
                        }
                    </div>
                    <div className={classes.dateTimeRoot}>
                        <p>End</p>
                        <CustomDatePicker
                            views={["date"]}
                            format={"DD MMM YYYY"}
                            variant="inline"
                            // minDate={props.fromEdit ? details.endDate : defaultEndDate}
                            // minDate={defaultEndDate}
                            value={details.endDate || defaultEndDate}
                            onChange={(e) => handleDateChange(e, 'endDate')}
                        />
                        {
                            !details.allDay &&
                            <Autocomplete
                                id="end-time"
                                options={timeOptionsList}
                                getOptionLabel={(option) => option.title}
                                value={details.endTime}
                                classes={{ inputRoot: classes.startTimeAutocompleteRoot }}
                                renderInput={(params) => <TextField {...params} placeholder="End Time" variant="outlined" />}
                                onChange={(...args) => handleChangeAutocomplete(args[0], args[1], 'endTime')}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className={classes.fieldRoot}>
                <RefreshIcon className={classes.iconStyles} />
                <span>Repeat</span>
                <Autocomplete
                    id="repeat"
                    options={repeatOptionsList}
                    getOptionLabel={(option) => option.title}
                    value={details.repeat}
                    classes={{ inputRoot: classes.repeatutocompleteRoot }}
                    style={{ marginLeft: 15 }}
                    renderInput={(params) => <TextField {...params} placeholder="End Time" variant="outlined" />}
                    onChange={(...args) => handleChangeAutocomplete(args[0], args[1], 'repeat')}
                />
            </div>
            <div className={classes.fieldRoot}>
                <AutolobbyIcon style={{ marginRight: 16 }} />
                <span>Auto admit to lobby</span>
                <CustomizedSwitch id="auto-admit" checked={details.autoAdmit} onChange={() => handleSwitch('autoAdmit')} />
            </div>
            <div className={classes.fieldRoot}>
                <LocationIcon className={classes.iconStyles} width="20" height="auto" />
                <TextField
                    placeholder="Location"
                    fullWidth
                    variant="outlined"
                    value={details.location}
                    InputProps={{ classes: { root: classes.textFieldRoot } }}
                    onChange={(e) => handleChangeInput(e, 'location')}
                />
            </div>
            <div className={classes.fieldRoot} style={{ alignItems: 'end' }}>
                <DescriptionIcon className={classes.iconStyles} style={{ marginTop: 10 }} />
                {/* <TextField
                    placeholder="Type details for this meeting"
                    fullWidth
                    variant="outlined"
                    value={details.description}
                    InputProps={{ classes: { root: classes.textFieldRoot } }}
                    onChange={(e) => handleChangeInput(e, 'description')}
                /> */}
                <Editor
                    wrapperStyle={{ border: '1px solid #c2c2c2', padding: '5px 15px', width: '100%', height: 'calc(100% - 50px)' }}
                    placeholder="Type details for this meeting"
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    onChange={(e, a) => onEditorChange(e, a)}
                    toolbar={{
                        options: ['inline', 'colorPicker', 'textAlign', 'list', 'link'],
                        inline: {
                            options: ['bold', 'italic', 'underline']
                        },
                        list: {
                            options: ['unordered', 'ordered']
                        },
                    }}
                />
            </div>
            <div className={classes.fieldRoot} style={{ alignItems: 'end' }}>
                <PaperClipIcon className={classes.iconStyles} style={{ marginTop: 10 }} />
                <StyledDropzone
                    handleChange={(files) => handleChangeFiles(files)}
                    attachments={details.attachments}
                    uploading={uploading}
                    classes={classes}
                />
            </div>
            <div className={classes.buttonRoot} >
                <Button variant="text" size="medium" className={classes.button} onClick={() => handleCancel()}>Cancel</Button>
                {
                    props.fromEdit ?
                        <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleUpdateMeeting()}>Update</Button>
                        : <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleSubmitMeeting()}>Done</Button>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (options) => dispatch(setLoading(options)),
        createScheduledMeeting: (options) => dispatch(createScheduledMeeting(options)),
        getMeetingDetails: (option) => dispatch(getMeetingDetails(option)),
        updateScheduledMeeting: (option) => dispatch(updateScheduledMeeting(option)),
        uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleMeeting));