import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles, TextField, Tooltip } from '@material-ui/core';
import SeasonFilters from '../SeasonFilters';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getDivisionsList, getPlayersPointsList, getSeasonKpisList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import { TabBar } from '../TabBar';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import LeaderboardTable from './LeaderboardTable';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import { commonColumnOptions } from '../../PointsTables/TeamPointsTable';
import { getNumberFormat, decodeEnum, getCurrencyFormat, getDurationWithUnit, setSearchQuery, getSearchValue, downloadFiles, historyPushWithSearch, seasonKpiPositionsSort } from '../../../helpers';
import { Grid } from '@material-ui/core';
import IllustrationView from '../../LiveScores/IllustrationView';
import { CustomListGroup } from '../../LiveScores/RoundsListView';
import { DecimalViewSwitch } from '../../LiveScores/CustomSwitch';
import { toJpeg } from 'html-to-image';
import { resizeImageToSquare } from '../../ArenaComponents/cropImage';
import { openCreateArenaDialog } from '../../../ducks/arena';
import PlayerLeaderboardTemplate from '../../GlobalComponents/PlayerLeaderboardTemplate';
import TeamLeaderboardTemplate from '../../GlobalComponents/TeamLeaderboardTemplate';
import GetCountryCurrencyFormat from '../../GlobalComponents/GetCountryCurrencyFormat';
import { FlashAuto, Info } from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import SeasonLabelsCommon from '../../GlobalComponents/SeasonLabelsCommon';
import { Autocomplete } from '@material-ui/lab';




const useStyles = makeStyles((theme) => ({
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    filterSec: {
        marginTop: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    autocompleteRoot: {
        width: "220px",
        height: "40px",
        marginRight: "10px",
        borderRadius: 5,
        overflow: "clip",
        whiteSpace: "nowrap",
        background: 'white',
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
    },
}));

const defaultFilters = {
    league: null,
    season: null,
    division: null
};
const pagination = {
    limit: 5,
    page: 0,
}
const subTabs = [{ label: 'Core KPI', value: 0, icon: '', path: 'core' }, { label: 'Bonus KPI', value: 1, icon: '', path: 'bonus' }, { label: 'By Division', value: 2, icon: '', path: 'division' }]

const TeamLeaderboard = ({ history, filtersData, location, setLoading, loading, getSeasonKpisList, userDetails, openCreateArenaDialog, getDivisionsList, seasonDetails, seasonKpiPostions }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const [kpis, setKpis] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [decimalView, setDecimalView] = useState(false);
    const [kpiTabData, setKpiTabData] = useState([])
    const [kpiData, setKpiData] = useState([])
    const [canDownload, setCanDownload] = useState(false);
    const [divisions, setDivisions] = useState([]);
    const [divisionsData, setDivisionsData] = useState([]);
    const [viewPageType, setViewPageType] = useState('CARD');
    const [leaderboardFilter, setLeaderboardFilter] = useState(subTabs[0]);

    const handleDownloadClick = (kpiTabData, kpiData, type, handleShareMenuClose, tabType) => {
        setKpiTabData(kpiTabData)
        setKpiData(kpiData)
        handleDownloadPerformance(`kpi-perfomances-table-${kpiTabData._id}`, `kpi_performance_${filtersData.season?.uid}_${kpiTabData.name}`, type, handleShareMenuClose, kpiTabData._id, tabType);
    }
    const handleDownloadPerformance = async (id, title, type, handleShareMenuClose, kpiId, tabType) => {

        await setCanDownload(true);
        toJpeg(document.getElementById(id))
            .then((dataUrl) => {
                setCanDownload(false);
                try {
                    const img = new Image();
                    img.onload = async function () {
                        let high = (this.width > this.height) ? 'width' : 'height';
                        // console.log('IMage 2 ', high, this[high], this.width, this.height);
                        const resultImage = (this.width > 0 && this.height > 0) ? await resizeImageToSquare(dataUrl, { width: this[high], height: this[high] }) : null;
                        if (resultImage) {
                            if (type === 'download') {
                                downloadFiles(resultImage, title.replace(".", ""));
                                handleShareMenuClose()
                            }
                            else {
                                openCreateArenaDialog({
                                    open: true, arena: {}, stepType: 'crop', attachment: [
                                        { url: resultImage, mimeType: "IMAGE", width: 4, height: 4, fileName: "kpi_performance_HHD-49_kpi_ck_bk -2022-08-18T133448.437.png", fileType: "image/png" }],
                                    postDetails: {
                                        targetPage: "TEAM_LEADERBOARD",
                                        details: {
                                            league: filtersData?.league?._id,
                                            season: filtersData?.season?._id,
                                            round: null,
                                            match: null,
                                            subTab: {
                                                tab: tabType === 'CORE KPI' ? 'CORE_KPI' : tabType === 'BONUS KPI' ? 'BONUS_KPI' : tabType, // CORE_KPI, BONUS_KPI, TEAM, ROLE and REGION 
                                                value: kpiId, // KPI_ID, TEAM_ID, ROLE_ID and REGION_ID
                                            },
                                            division: tabType === 'BY DIVISION' ? kpiId : null,
                                        }
                                    }
                                });
                                handleShareMenuClose()
                            }
                        }
                    }
                    img.src = dataUrl;
                    return img.onload();
                } catch (e) {
                }
                // downloadFiles(dataUrl, title.replace(".", ""));
            })
    }

    useEffect(() => {
        if (filtersData.season && filtersData.season?.season_format !== 'PLAYER_RACE') {
            if (currentTab === 2) {
                if (filtersData.season?.divisions_enabled) getDivisions(filtersData.season._id);
                else setKpis([]);
            }
            else {
                getSeasonKpis(filtersData.season?._id, currentTab);
            }
        }
        else {
            setKpis([]);
        }
    }, [filtersData.season, currentTab]);

    useEffect(() => {
        if (location.pathname) {
            // setCurrentTab(location.pathname.split('/')[4] === 'core' ? 0 : 1)
            setCurrentTab(subTabs.findIndex(tab => tab.path === location.pathname.split('/')[5]));
            setLeaderboardFilter(subTabs.find(tab => tab.path === location.pathname.split('/')[5]));
        }
    }, [location.pathname]);

    const handleSubTabChange = (newValue) => {
        if (newValue.value !== currentTab) {
            historyPushWithSearch(`${path}/${newValue?.path}`, history, location.search);
            setCurrentTab(newValue?.value);
            setKpis([]);
            setViewPageType('CARD');
            setLeaderboardFilter(newValue);
        }

    }

    const handleViewChange = (viewType, kpi, type) => {
        if (viewType === 'PAGE') {
            setViewPageType('PAGE');
            historyPushWithSearch(`${path}/${type}/${kpi?._id}`, history, location.search);
        }
        else {
            setViewPageType('CARD');
            historyPushWithSearch(`${path}/${type}`, history, location.search);
        }
    }

    const getSeasonKpis = (seasonId, currentTab) => {
        setLoading(true);
        getSeasonKpisList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1, tags: currentTab === 0 ? 'TEAM_MATCH' : 'TEAM_BONUS' },
            onSuccessCB: (result, headers) => {
                result = result.map(ele => {
                    ele.label = ele.name
                    return ele;
                })
                let kpis_attributes = seasonKpiPositionsSort(result, seasonKpiPostions, '_id', 'detailed_hidden');
                setKpis(kpis_attributes);
                setLoading(false);
            }, onErrorCB: (error) => {
                setKpis([]);
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }
    const getDivisions = (seasonId) => {
        setLoading(true);
        setKpis([]);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: 1 },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const getDivisionMore = () => {
        let seasonId = filtersData?.season?._id;
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: pagination.page + 1 },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const onDivisionSuccessCB = (result, headers) => {
        if (result) {
            result = result.map(ele => {
                ele.label = ele.name
                return ele;
            })
            setKpis((prev) => ([...prev, ...result]));
            setDivisionsData([]);
            setDivisions([...result]);
            setLoading(false);
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }

    const onDivisionErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Divisions', {
            variant: 'error',
            preventDuplicate: true
        });
    }


    const columns = (kpi, tableData) => [
        {
            name: "rank", label: "Rank", options: { ...commonColumnOptions }
        },
        {
            name: "team", label: "Team", options: {
                customBodyRender: (team, tableMeta) => (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                        <div className={classes.nameRoot}>
                            <AvatarComponent type='TEAM' name={team?.name} username={team?._id} url={team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                        </div>
                        <div style={{ textAlign: 'left', fontWeight: 600 }}>
                            {team && team.name}
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                                {tableData[tableMeta.rowIndex]?.captain && <span style={{ marginRight: 10 }}>C : {tableData[tableMeta.rowIndex]?.captain}</span>}
                                {tableData[tableMeta.rowIndex]?.mentor && <span>M : {tableData[tableMeta.rowIndex]?.mentor}</span>}
                            </div>
                        </div>
                    </div>

                )
            }
        },
        // { name: "captain", label: "Captain" },
        // { name: "mentor", label: "Mentor" },
        {
            name: "target", label: "Target", options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? (!(kpi?.copy_detailed_attributes?.includes('TARGET')) || (currentTab >= 2) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true

                // customBodyRender: target => (target ? kpi.unit_of_measurement === 'CURRENCY' ?
                //     <GetCountryCurrencyFormat type="currency" value={target} />
                //     : kpi.unit_of_measurement === 'NUMBER' ?
                //         <GetCountryCurrencyFormat type="number" value={target} />
                //         // getCurrencyFormat(userDetails?.countryCode, kpi.unit, target)
                //         : `${getNumberFormat(userDetails?.countryCode, target)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(target, kpi.unit) : ''}`
                //     : 0)
            }
        },
        {
            name: "actual", label: "Actual", options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('ACTUAL')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true

                // customBodyRender: actual => (actual ? kpi.unit_of_measurement === 'CURRENCY' ?
                //     <GetCountryCurrencyFormat type="currency" value={actual} />
                //     : kpi.unit_of_measurement === 'NUMBER' ?
                //         <GetCountryCurrencyFormat type="number" value={actual} />
                //         // getCurrencyFormat(userDetails?.countryCode, kpi.unit, actual)
                //         : `${getNumberFormat(userDetails?.countryCode, actual)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(actual, kpi.unit) : ''}`
                //     : 0)
            }
        },
        {
            name: "pending_actual", label: "Pending Actual", options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('PENDING_ACTUAL')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
            }
        },
        {
            name: "achieved", label: "Achievement %", options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('ACHIEVEMENT')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true,
                customBodyRender: achieved => getNumberFormat(userDetails.countryCode, achieved || 0) + '%'
            }
        },
        {
            name: "planned_target", label: kpi?.achievement_plan_enabled && kpi?.frequency === 'WEEKLY' ? 'WTD Target' : 'MTD Target',
            options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? (!(kpi?.copy_detailed_attributes?.includes('PLANNED_TARGET')) || (currentTab >= 2) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
            }
        },
        {
            name: "planned_actual", label: kpi?.achievement_plan_enabled && kpi?.frequency === 'WEEKLY' ? 'WTD Actual' : 'MTD Actual', options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('PLANNED_ACTUAL')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
            }
        },
        {
            name: "planned_pending_actual", label: kpi?.achievement_plan_enabled && kpi?.frequency === 'WEEKLY' ? 'WTD Pending Actual' : 'MTD Pending Actual', options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('PLANNED_PENDING_ACTUAL')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
            }
        },
        {
            name: "planned_achievement", label: kpi?.achievement_plan_enabled && kpi?.frequency === 'WEEKLY' ? 'WTD Ach%' : 'MTD Ach%', options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('PLANNED_ACHIEVEMENT')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true,
                customBodyRender: achieved => getNumberFormat(userDetails.countryCode, achieved || 0) + '%'
            }
        },
        {
            name: "totalpoints",
            label: ((subTabs[currentTab]?.label.toUpperCase()) === 'CORE KPI' && filtersData.season?.season_format === 'HEAD_TO_HEAD') ? 'Score' : ((subTabs[currentTab]?.label.toUpperCase()) === 'CORE KPI' ? `${seasonDetails?.labels_config?.kpis?.core} Pts` : `${seasonDetails?.labels_config?.kpis?.bonus} Pts`),
            options: {
                ...commonColumnOptions, display: (currentTab !== 2),
                customBodyRender: (totalpoints) => ((subTabs[currentTab].label.toUpperCase()) === 'CORE KPI' && filtersData.season?.season_format === 'HEAD_TO_HEAD') ? `${getNumberFormat(userDetails?.countryCode, totalpoints, decimalView)} ${decodeEnum(filtersData.season?.scoring_unit) + (totalpoints === 1 ? '' : 's')}` : getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
            }
        },
        { name: "match_points", label: `${seasonDetails?.labels_config?.kpis?.core} Pts`, options: { ...commonColumnOptions, display: currentTab === 2, customBodyRender: (match_points) => getNumberFormat(userDetails?.countryCode, match_points, decimalView) } },
        { name: "bonus_points", label: `${seasonDetails?.labels_config?.kpis?.bonus} Pts`, options: { ...commonColumnOptions, display: currentTab === 2, customBodyRender: (bonus_points) => getNumberFormat(userDetails?.countryCode, bonus_points, decimalView) } },
        {
            name: "totalpoints", label: "Total Pts", options: {
                ...commonColumnOptions, display: currentTab === 2,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
                customHeadRender: () =>
                    <th className="MuiTableCell-head" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #f0f2f5' }}>
                        <span>Total Pts</span>
                        <Tooltip interactive arrow title={<p>Total Pts = Match/Core Pts + Bonus Pts</p>}>
                            <Info style={{ fontSize: 16, marginLeft: 5 }} />
                        </Tooltip>
                    </th>
            }
        },
    ];

    const cardsList = (kpi, type) => (
        <div>
            {
                kpi.length > 0 ?
                    <>
                        {type !== "division" ?
                            <Grid container spacing={2} style={{ marginTop: 0 }} >

                                {
                                    kpi.map((kpi, i) => (
                                        <>
                                            <TeamLeaderboardTemplate
                                                id={`kpi-perfomances-table-${kpiTabData?._id}`}
                                                filtersData={filtersData}
                                                belongsTo='Players'
                                                columns={columns(kpiTabData)}
                                                tabData={kpiTabData}
                                                userDetails={userDetails}
                                                data={kpiData}
                                                render={(canDownload)}
                                                tabType={subTabs[currentTab]?.label.toUpperCase()}
                                                decimalView={decimalView}

                                            />

                                            <Grid key={i} item md={kpis.length === 1 ? 12 : 6} xs={12} style={{ marginTop: 10 }}>
                                                <LeaderboardTable setViewPageType={setViewPageType} type={type} viewType={"CARD"} filtersData={filtersData} groupTab={currentTab} columns={columns} kpi={kpi} handleViewChange={handleViewChange} loading={loading} handleDownloadClick={handleDownloadClick} tabType={subTabs[currentTab]?.label.toUpperCase()} />
                                            </Grid>
                                        </>
                                    ))
                                }
                            </Grid>
                            :
                            < InfiniteScroll
                                dataLength={kpi.length}
                                next={getDivisionMore}
                                hasMore={kpi.length !== pagination.total_count}
                                scrollableTarget="leaderboard_data"
                                style={{ overflow: 'hidden' }}
                            >
                                <Grid container spacing={2} style={{ marginTop: 0 }} >

                                    {
                                        kpi.map((kpi, i) => (
                                            <>
                                                <TeamLeaderboardTemplate
                                                    id={`kpi-perfomances-table-${kpiTabData?._id}`}
                                                    filtersData={filtersData}
                                                    belongsTo='Players'
                                                    columns={columns(kpiTabData)}
                                                    tabData={kpiTabData}
                                                    userDetails={userDetails}
                                                    data={kpiData}
                                                    render={(canDownload)}
                                                    tabType={subTabs[currentTab]?.label.toUpperCase()}
                                                    decimalView={decimalView}

                                                />

                                                <Grid key={i} item md={kpis.length === 1 ? 12 : 6} xs={12} style={{ marginTop: 10 }}>
                                                    <LeaderboardTable setViewPageType={setViewPageType} type={type} viewType={"CARD"} filtersData={filtersData} groupTab={currentTab} columns={columns} kpi={kpi} handleViewChange={handleViewChange} loading={loading} handleDownloadClick={handleDownloadClick} tabType={subTabs[currentTab]?.label.toUpperCase()} />
                                                </Grid>
                                            </>
                                        ))
                                    }
                                </Grid>
                            </InfiniteScroll>
                        }
                    </>
                    :
                    < IllustrationView loading={loading} style={{ marginTop: '50px' }} />
            }
        </div >
    )

    return (
        <>
            {
                filtersData.season?.season_format === 'PLAYER_RACE' ?
                    <div style={{ textAlign: 'center', marginTop: 40 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>No team data is available, since selected season is of the format ‘Player race’</p>
                    </div> :
                    <div style={{ margin: '10px 0' }}>
                        {(viewPageType === 'CARD') &&
                            <div className={classes.filterSec}>
                                <Autocomplete
                                    id="filterBy"
                                    options={subTabs.map(ele => { return { ...ele, label: (ele.path === 'core') ? seasonDetails?.labels_config?.kpis[ele.path] : (ele.path === 'bonus') ? seasonDetails?.labels_config?.kpis[ele.path] : ele.label } })}
                                    getOptionLabel={(option) => option.label}
                                    value={leaderboardFilter}
                                    disableClearable
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => <TextField {...params} label={'Filter By'} variant="outlined" size="small" />}
                                    onChange={(...args) => handleSubTabChange(args[1])}
                                />
                                {/* <CustomListGroup data={subTabs.map(ele => { return { ...ele, label: (ele.path === 'core') ? seasonDetails?.labels_config?.kpis[ele.path] : (ele.path === 'bonus') ? seasonDetails?.labels_config?.kpis[ele.path] : ele.label } })} selectedItem={subTabs.find((option) => option.path === location.pathname.split('/')[5])} onClick={handleSubTabChange} style={{ marginLeft: 0, maxWidth: 'max-content' }} /> */}
                                <p className="table-title decimal-switch-root" style={{ marginBottom: 0 }}>
                                    <p></p>
                                    <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                </p>
                            </div>
                        }
                        <Switch>
                            <Route exact path={`${path}/core/:id`}>
                                <LeaderboardTable type="core" setViewPageType={setViewPageType} viewType={"PAGE"} filtersData={filtersData} groupTab={currentTab} columns={columns} kpis={kpis} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} />
                            </Route>
                            <Route exact path={`${path}/core`}>
                                {cardsList(kpis, "core")}
                            </Route>
                            <Route exact path={`${path}/bonus/:id`}>
                                <LeaderboardTable type="bonus" setViewPageType={setViewPageType} viewType={"PAGE"} filtersData={filtersData} groupTab={currentTab} columns={columns} kpis={kpis} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} />
                            </Route>
                            <Route exact path={`${path}/bonus`}>
                                {cardsList(kpis, "bonus")}
                            </Route>
                            <Route exact path={`${path}/division/:id`}>
                                <LeaderboardTable setViewPageType={setViewPageType} type="division" viewType={"PAGE"} filtersData={filtersData} groupTab={currentTab} columns={columns} kpis={kpis} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} />
                            </Route>
                            <Route exact path={`${path}/division`}>
                                {cardsList(kpis, "division")}
                            </Route>
                            <Redirect to={{ pathname: `${path}/core`, search: location.search }} />
                        </Switch>
                    </div>
            }

        </>
    );
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading,
    seasonDetails: state.game.seasonDetails,
    seasonKpiPostions: state.game.seasonKpiPostions
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options)),
        getSeasonKpisList: (options) => dispatch(getSeasonKpisList(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options))

    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamLeaderboard));
