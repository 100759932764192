import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { ReactComponent as FollowIcon } from '../../assets/icons/follow.svg';
import { ReactComponent as FollowingIcon } from '../../assets/icons/following.svg';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import Moment from 'react-moment';
import classNames from 'classnames';
import { ReactComponent as FeaturedIcon } from "../../assets/icons/arena-FeaturedIcon.svg";
import { ReactComponent as LearnIcon } from "../../assets/icons/arena-LearningHubIcon.svg";
import moment from 'moment';
import { postCreatedAtFormat } from '../../helpers';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    profileName: {
        margin: "2px 10px 0px",
        // color: "#58595B",
        color: '#262626',
        fontSize: 14, // 16
        fontWeight: 600,
        cursor: 'pointer'
    },
    featuredBadge: {
        backgroundColor: '#585963',
        borderRadius: 5,
        color: '#FFFFFF',
        margin: '4px 10px 0',
        padding: '2px 4px',
        fontSize: 12,
        width: 'fit-content'
    },
    timestamp: {
        margin: "5px 10px 0px",
        fontSize: 12,
        color: "#838383",
        display: 'flex',
    },
    followActionsRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    followButtonStyles: {
        padding: 0,
        margin: 12,
        '&:hover': {
            background: 'none'
        }
    }
}));

const FollowButton = (props) => (
    <IconButton disableTouchRipple {...props} >
        <FollowIcon />
    </IconButton>
)
const FollowingButton = (props) => (
    <IconButton disableTouchRipple {...props} >
        <FollowingIcon />
    </IconButton>
)

const UserProfileCard = ({ index = 0, name = '', username = '', avatarUrl = '/', onProfileClick = () => { }, follower = false, showFollowStatus = false, followActions, followUser, unFollowUser, isLoggedInUser, featured = false, timestamp = '', avatarStyles, learningHub = false, profileNameStyles, secondaryTextStyles, ...others }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} {...others}>
            <AvatarComponent
                name={name}
                username={username}
                url={avatarUrl}
                onClick={onProfileClick}
                className={avatarStyles}
            />
            <div className={(followActions || featured || learningHub) && classes.followActionsRoot}>
                <div >
                    <p key={'name'} className={classNames(classes.profileName, profileNameStyles)} onClick={onProfileClick}>{name || username}</p>
                    {
                        timestamp ?
                            <p className={classNames(classes.timestamp, secondaryTextStyles)}><Moment fromNow ago
                                format={postCreatedAtFormat(timestamp)}>
                                {timestamp}</Moment> {follower && <span style={{ paddingLeft: 8 }}> following</span>}
                                {featured && (
                                    <Tooltip title="Featured">
                                        <FeaturedIcon width="15px" height="15px" style={{ marginLeft: "10px" }} />
                                    </Tooltip>
                                )}
                                {learningHub && (
                                    <Tooltip title="LearnHub">
                                        <LearnIcon width="15px" height="15px" style={{ marginLeft: "10px" }} />
                                    </Tooltip>
                                )}
                            </p>
                            :
                            [
                                showFollowStatus &&
                                <p key={'follow-status'} className={classNames(classes.timestamp, secondaryTextStyles)} style={{ marginTop: 0 }}>{follower ? 'Following' : isLoggedInUser ? 'You' : 'Follow'}</p>
                            ]
                    }
                </div>
                {
                    (followActions && !isLoggedInUser) && (follower ? <FollowingButton title="Unfollow" className={classes.followButtonStyles} onClick={() => unFollowUser(index)} /> : <FollowButton title="follow" className={classes.followButtonStyles} onClick={() => followUser(index)} />)
                }
            </div>
        </div>
    )
}

export default UserProfileCard;