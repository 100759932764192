import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Avatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as NegativeAgeing } from "../../../assets/icons/Red_ageing.svg";
import { ReactComponent as PositiveAgeing } from "../../../assets/icons/Green_ageing.svg";
import { ReactComponent as CurrentAgeing } from "../../../assets/icons/Orange_ageing.svg";
// import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BugReportIcon from '@material-ui/icons/BugReport';
import moment from 'moment';
import {ShowAgeingInfo,CompletedItemAgeing} from '../../ProductManagementSystem';

import {
  MovableCardWrapper,
  CardHeader,
  CardRightContent,
  CardTitle,
  Detail,
  Footer,
  DetailHigh,
  DetailMedium,
  DetailLow,
  IssueTypeBug,
  IssueTypeStory,
  IssueTypeTask,
  IssueTypeUnknown,
  avatarStyles
} from './Styles/Base'
// import Tag from './Card/Tag'
// import DeleteButton from 'rt/widgets/DeleteButton'
// import EditButton from './designTrello/Widgets/EditButton'
import { Grid, Tooltip } from '@material-ui/core'

import { DeleteWrapper, DelButton } from './Styles/Element'
import { getAvatarText } from '../../../helpers';

const DeleteButton = props => {
  return (
    <DeleteWrapper {...props}>
      <DelButton>&#10006;</DelButton>
    </DeleteWrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  avatarStyles: {
      fontSize:'0.65rem',
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
      height: 20,
      width: 20,
      marginRight:5,
    }
  }));
  
const Card = (props) =>{
     const {
      showDeleteButton,
      showEditButton,
      style,
      tagStyle,
      onClick,
      onDelete,
      className,
      id,
      laneId,
      task_type,
      title,
      label,
      priority,
      description,
      task_id,
      target_date,
      closed_at,
      assigneeName,
      assigneePhoto_url,
      laneKey,
      ageing,
      //   tags,
      cardDraggable
    } = props
    // onDelete = e => {
    //   props.onDelete();
    //   // e.stopPropagation();
    // }
    const classes = useStyles();

    return (
      <MovableCardWrapper
        data-id={id}
        style={style}
        className={className}
      >
        <CardHeader onClick={onClick}>
          <Grid container spacing={2}>
            <Grid item lg={9} sm={9} xs={9} md={9}>
              <CardTitle draggable={cardDraggable}>{title}</CardTitle>
            </Grid>
            {/* <CardRightContent>{label}</CardRightContent> */}
            <Grid item lg={3} sm={3} xs={3} md={3}>
              {
                laneKey==='DONE' ?
                <CompletedItemAgeing targetDate={target_date} completedDate={closed_at}/>
                :
                <ShowAgeingInfo targetDate={target_date}/>

              }
            </Grid>
            {/* <Grid item item lg={2} sm={2} xs={2} md={2}>
              {ageing}
            </Grid> */}
          </Grid>

          {/* {showDeleteButton && <DeleteButton onClick={this.onDelete} />} */}
          {/* { <EditButton onClick={this.onEdit} />} */}
        </CardHeader>
        <Grid container onClick={onClick} style={{ cursor: "pointer" ,display:'flex',alignItems:'center'}}>
          <Grid item xs={3}>
            {
              priority && priority === 2 ? <DetailHigh><Tooltip title="High">
                <ArrowUpwardIcon style={{ fontSize: "20px" }} /></Tooltip></DetailHigh> :
                priority === 1 ?
                  <DetailMedium><Tooltip title="Medium"><ArrowUpwardIcon style={{ fontSize: "20px" }} /></Tooltip></DetailMedium> :
                  priority === 0 ?
                    <DetailLow><Tooltip title="Low"><ArrowDownwardIcon style={{ fontSize: "20px" }} /></Tooltip></DetailLow>
                    : <Detail><Tooltip title="No Priority"><ArrowUpwardIcon style={{ fontSize: "20px" }} /></Tooltip></Detail>
            }
          </Grid>
          <Grid item xs={3}>
            {
              task_type && task_type.toLowerCase() === "story" ? <IssueTypeStory>
                <Tooltip title="Story">
                  <BookmarkIcon style={{ width: '0.9em', height: '0.9em' }} />
                </Tooltip>
              </IssueTypeStory> :
                task_type.toLowerCase() === 'task' ?
                  <IssueTypeTask>
                    <Tooltip title="Task">
                      <AssignmentTurnedInIcon style={{ width: '0.9em', height: '0.9em' }} />
                    </Tooltip>
                  </IssueTypeTask> :
                  task_type.toLowerCase() === "bug" ?
                    <IssueTypeBug>
                      <Tooltip title="Bug">
                        <BugReportIcon style={{ width: '0.9em', height: '0.9em' }} />
                      </Tooltip>
                    </IssueTypeBug> : <IssueTypeUnknown>{task_type}</IssueTypeUnknown>
            }

          </Grid>
          <Grid item xs={3}>
            {task_id && <Detail>{task_id}</Detail>}
          </Grid>
          <Grid item xs={3} style={{marginTop:8}}>
          <Tooltip title={`Assigned to ${assigneeName}`}>
            <Avatar
              id="assigneeNameImage"
              classes={{ colorDefault: classes.avatarStyles }}
              style={{ height: 20, width: 20,float:'right' }}
              src={assigneePhoto_url || ''}
            >
              {(assigneeName && getAvatarText(assigneeName))}
            </Avatar>
            </Tooltip>
          </Grid>
        </Grid>
        {/* {tags && tags.length> 0 && (
          <Footer>
            {tags.map(tag => (
              <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
            ))}
          </Footer>
        )} */}
      </MovableCardWrapper>
    )
  }

Card.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  priority: PropTypes.number,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
  task_id: PropTypes.string,
  task_type: PropTypes.string,
  ageing: PropTypes.number,
  title: PropTypes.string,
  assigneeName:PropTypes.string,
  assigneePhoto_url:PropTypes.string,
  target_date: PropTypes.string,
  closed_at:PropTypes.string,
}

Card.defaultProps = {
  showDeleteButton: true,
  onDelete: () => { },
  onClick: () => { },
  style: {},
  tagStyle: {},
  title: 'no title',
  description: '',
  label: '',
  tags: [],
  className: '',
  // priority: 0,
  task_id: '',
  task_type: '',
  title: '',
}

export default Card
