import { spawn } from "redux-saga/effects";

import xmpp from "./xmpp/index.js";
import apiServiceSaga from "./api-services/index.js";

function* rootSaga(token) {
  yield spawn(xmpp, token);
  yield spawn(apiServiceSaga, token);
}

export default rootSaga;
