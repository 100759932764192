import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { decodeEnum, formatDate, getNumberFormat } from '../../helpers';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import classNames from 'classnames';
import LeaguesIcon from "../../assets/images/leagues-logo-1.png";
import summaryBackground from "../../assets/images/match_summary_background.png";

const useStyles = makeStyles((theme) => ({
    root: {
        background: `url(${summaryBackground})`,
        backgroundSize: 'cover'
    },
    imageTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        margin: -25,
        '& div:first-child': {
            marginRight: 10,
            textAlign: 'right',
            '& p': {
                margin: 0,
                '&:last-child': {
                    fontSize: 11
                }
            }
        }
    },
    tableTitle: {
        fontSize: 30,
        margin: '30px 0 0',
        '& p': {
            margin: 0,
            '&:last-child': {
                fontSize: 16,
                marginTop: -10,
                '& .divider': {
                    margin: '0 10px',
                    color: 'orange',
                    fontSize: 30,
                    fontWeight: 500,
                    verticalAlign: 'middle'
                }
            }
        }
    },
    matchTypeTitle: {
        textAlign: 'center',
        textTransform: 'upperCase',
        marginTop: 20,
    },
    centerKpiName: {
        backgroundColor: '#318de1 !important',
        color: '#fff !important',
        borderRadius: 5,
        margin: '-5px 0px',
        height: 30,
        alignItems: 'center',
        display: 'flex',
        minWidth: 60,
        justifyContent: 'space-around'
    },
    teamsAvatars: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    teamsScoreCards: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10
    },
    individualTeamCard: {
        // height: 50,
        padding: 4,
        width: '47.5%',
        backgroundColor: '#6a336d',
        borderRadius: 5
    },
    vsTextStyles: {
        padding: 0,
        margin: 0,
        color: '#fcf005',
        fontSize: 50
    },
    teamAvatar: {
        fontSize: 50,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 85,
        width: 85,
        border: '2px solid #FFF',
        // marginRight: 8
    }, matchGoals: {
        margin: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }, teamMembers: {
        fontSize: 10,
        fontWeight: 200,
        '& span': {
            '&:last-child': {
                marginLeft: 5
            }
        }
    },
    playerRow: {
        backgroundColor: 'revert !important',
        color: '#fff !important'
    },
    tableBorder: {
        borderSpacing: '0px 8px !important',
        marginTop: 15
    },
    matchTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 18,
        fontWeight: 200,
        marginTop: '-18px',
        '& div': {
            width: 40,
            height: 4,
            borderRadius: 3,
            backgroundColor: '#fff'
        },
        '& span': {
            margin: '0px 10px'
        }
    },
    matchDates: {
        padding: '0px',
        margin: 0,
        fontSize: 12,
        fontWeight: 200,
        textAlign: 'center'
    },
    leaguesLogo: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 85,
        backgroundColor: '#fff',
        margin: '0px 20px',
        color: '#9262e2',
        padding: 5,
        fontSize: 12,
        borderRadius: 5,
        height: 12
    }
}));

const MatchPerformanceTemplate = ({ id = "", render, shareType = 'DOWNLOAD', userDetails, columns, components, teamMatchData, playerMatchData, match, theme }) => {
    const classes = useStyles();
    const [playersData, setPlayersData] = useState({});
    const getTotalScore = (data, teamId, type) => {
        const result = [...data].map(key => key.teams?.find(team => team.team_id === teamId)).reduce((a, b) => a + b?.score, 0);
        if (type === 'score') return getNumberFormat(userDetails?.countryCode, result);
        else if (type === 'unit') return `${decodeEnum(data[0]?.scoring_unit) + (result === 1 ? '' : 's')}`
        else return getNumberFormat(userDetails?.countryCode, result)` ${decodeEnum(data[0]?.scoring_unit) + (result === 1 ? '' : 's')}`
    }

    // useEffect(()=>{
    //     if(shareType==='DOWNLOAD'){
    //         let dataObj = {...playerMatchData};
    //         Object.keys(playerMatchData).map(key=>{
    //             dataObj[key].data = dataObj[key].data.length > 5 ? dataObj[key].data.slice(0, 9) : dataObj[key].data;
    //         });
    //         setPlayersData()
    //     }
    // },[shareType])

    const { background: { isGradiant, top, bottom, overAll }, heading, table_head_row, table_data_row } = theme;

    return (
        <div style={{ top: 0, left: 0, width: 'fit-content', position: 'absolute', zIndex: -1 }}> {/* to hide position: 'absolute', zIndex: -1 */}
            <div id={id} className={classNames("match-custom-table", classes.root)} style={{ background: isGradiant ? `linear-gradient(0deg, ${bottom}, ${top})` : overAll }} >
                <div className={classes.teamsAvatars}>
                    <AvatarComponent name={match.teams[0].name} onClick={() => { }} url={match.team1_logo} className={classes.teamAvatar} />
                    <div style={{ textAlign: 'center' }}>
                        <p className={classes.vsTextStyles}>VS</p>
                        <div className={classes.matchTitle} style={{ color: heading?.color, fontSize: heading?.size }}><div></div><span>{match.title}</span><div></div></div>

                        <p className={classes.matchDates}>Date : {formatDate(match?.start_date)} - {formatDate(match?.end_date)}</p>

                    </div>
                    <AvatarComponent name={match.teams[1].name} onClick={() => { }} url={match.team2_logo} className={classes.teamAvatar} />
                </div>
                {render &&
                    <>
                        <div className={classes.teamsScoreCards}>
                            <div className={classes.individualTeamCard}>
                                <p className={classes.matchGoals}>
                                    {match.teams[0].name}
                                    <span style={{ color: '#fcf005' }}>{getTotalScore(teamMatchData.data, match.teams[0].team_id, 'score')}</span>
                                </p>
                                <p className={classes.matchGoals}>
                                    <span style={{ marginRight: 10 }} className={classes.teamMembers}>
                                        {match.teams[0]?.captain && <span>C : {match.teams[0]?.captain}</span>}
                                        {match.teams[0]?.mentor && <span>M : {match.teams[0]?.mentor}</span>}
                                    </span>
                                    <span style={{ color: '#fcf005', textTransform: 'uppercase' }}>{getTotalScore(teamMatchData.data, match.teams[0].team_id, 'unit')}</span>
                                </p>

                            </div>
                            <div className={classes.individualTeamCard}>
                                <p className={classes.matchGoals}>
                                    <span style={{ color: '#fcf005' }}>{getTotalScore(teamMatchData.data, match.teams[1].team_id, 'score')}</span>
                                    {match.teams[1].name}
                                </p>
                                <p className={classes.matchGoals}>
                                    <span style={{ color: '#fcf005', textTransform: 'uppercase' }}>{getTotalScore(teamMatchData.data, match.teams[1].team_id, 'unit')}</span>
                                    <span style={{ marginLeft: 10 }} className={classes.teamMembers}>
                                        {match.teams[1]?.captain && <span>C : {match.teams[1]?.captain}</span>}
                                        {match.teams[1]?.mentor && <span>M : {match.teams[1]?.mentor}</span>}
                                    </span>
                                </p>
                                {/* <p className={classes.matchGoals}>
                        </p> */}
                            </div>
                        </div>
                        <div className={classes.matchTypeTitle} style={{ color: heading?.color, fontSize: heading?.size }}>
                            Team Performance
                </div>
                        <table>
                            {
                                components?.Header ?
                                    [components.Header({ columns })]
                                    :
                                    <thead>
                                        <tr style={{ color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>
                                            <th>Target </th>
                                            <th>Actual</th>
                                            <th>Ach %</th>
                                            <th></th>
                                            <th>Target </th>
                                            <th>Actual</th>
                                            <th>Ach %</th>
                                        </tr>

                                    </thead>
                            }
                            <tbody>
                                {
                                    teamMatchData.data.map((row, rowIndex) =>
                                        <tr key={rowIndex} style={{ color: table_data_row?.color || '#000', backgroundColor: table_data_row?.background_color, fontSize: table_data_row?.size }}>
                                            {
                                                teamMatchData.columns.filter(ele => ele.team_id === match?.teams[0].team_id).map((col, i) => (
                                                    !col.hideInTemplate && <td style={{ ...col?.cellStyle }} key={i} align={col.align}>{col.render ? col.render({ ...row, tableData: { id: rowIndex } }) : row[col.field]}</td>
                                                ))
                                            }
                                            <td className={classes.centerKpiName}>{row.kpi_code || row.kpi_name}</td>
                                            {
                                                teamMatchData.columns.filter(ele => ele.team_id === match?.teams[1].team_id).map((col, i) => (
                                                    !col.hideInTemplate && <td style={{ ...col?.cellStyle }} key={i} align={col.align}>{col.render ? col.render({ ...row, tableData: { id: rowIndex } }) : row[col.field]}</td>
                                                ))
                                            }
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                        {playerMatchData[match?.teams[0].team_id] &&
                            // Object.keys(playerMatchData).map((teamData, ind) => ( */}
                            <div style={{ marginTop: 20 }}>
                                <div className={classes.matchTypeTitle} style={{ color: heading?.color, fontSize: heading?.size }}>
                                    Player Performance ({match?.teams[0].name})
                            </div>
                                <table key={match?.teams[0].team_id} className={classNames("table", classes.tableBorder)}>
                                    {
                                        components?.Header ?
                                            [components.Header({ columns })]
                                            :
                                            <thead>
                                                <tr style={{ color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{playerMatchData[match?.teams[0].team_id]?.columns.map((col, i) => <th key={`${i + col.title}`}>{col.title}</th>)}</tr>
                                            </thead>
                                    }
                                    <tbody>
                                        {
                                            [...shareType === 'EMAIL' ? playerMatchData[match?.teams[0].team_id]?.data : playerMatchData[match?.teams[0].team_id]?.data.slice(0, 5)].map((row, rowIndex) =>
                                                <tr key={row.employee_id} style={{ color: table_data_row?.color || '#000', backgroundColor: table_data_row?.background_color, fontSize: table_data_row?.size }}>
                                                    {
                                                        playerMatchData[match?.teams[0].team_id]?.columns.map((col, i) => (
                                                            !col.hidden && <td style={{ ...col?.cellStyle }} key={`${i + row[col.field]}`} align={col.align}>{col.templateRender ? col.templateRender({ ...row, tableData: { id: rowIndex } }) : row[col.field]}</td>
                                                        ))
                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        {playerMatchData[match?.teams[1].team_id] &&
                            <div style={{ marginTop: 40 }}>
                                <div className={classes.matchTypeTitle} style={{ color: heading?.color, fontSize: heading?.size }}>
                                    Player Performance ({match?.teams[1].name})
                            </div>
                                <table key={match?.teams[1].team_id} className={classNames("table", classes.tableBorder)}>
                                    {
                                        components?.Header ?
                                            [components.Header({ columns })]
                                            :
                                            <thead>
                                                <tr style={{ color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>{playerMatchData[match?.teams[1].team_id]?.columns.map((col, i) => <th key={`${i + col.title}`}>{col.title}</th>)}</tr>
                                            </thead>
                                    }
                                    <tbody>
                                        {
                                            [...shareType === 'EMAIL' ? playerMatchData[match?.teams[1].team_id]?.data : playerMatchData[match?.teams[1].team_id]?.data.slice(0, 5)].map((row, rowIndex) =>
                                                <tr key={row.employee_id} style={{ color: table_data_row?.color || '#000', backgroundColor: table_data_row?.background_color, fontSize: table_data_row?.size }}>
                                                    {
                                                        playerMatchData[match?.teams[1].team_id]?.columns.map((col, i) => (
                                                            !col.hidden && <td style={{ ...col?.cellStyle }} key={`${i + row[col.field]}`} align={col.align}>{col.templateRender ? col.templateRender({ ...row, tableData: { id: rowIndex } }) : row[col.field]}</td>
                                                        ))
                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            // ))
                        }
                        <div style={{ direction: 'rtl', marginTop: 40 }}>
                            <p style={{ margin: 0, padding: 0, fontSize: 10, fontWeight: 500, color: '#fcf005' }}>For more Updates Download</p>
                            {/* <LeaguesIcon /> */}
                            <p className={classes.leaguesLogo}>Leagues.ai<img style={{ marginRight: 5 }} height="18" src={LeaguesIcon} alt='Leagues.ai' /></p>
                        </div>
                    </>}
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    teamMatchData: state.game?.teamMatchData,
    playerMatchData: state.game?.playerMatchData,
    theme: state.common.templateTheme
});

export default connect(mapStateToProps, null)(MatchPerformanceTemplate);