import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import { Divider, Drawer, IconButton, Tabs, Tab, SvgIcon, DialogTitle, DialogContent } from '@material-ui/core';
import { openDrawerComponent } from '../../../ducks/meetings';
import { connect } from 'react-redux';
import { getEllipsisText } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        zIndex: 10000
    },
    drawer: {
        // width: 310,
        // marginRight: 30,
        // flexShrink: 0,
        // padding: '20px 0'

        position: 'fixed',
        right: 0,
        height: '100%'
    },
    drawerPaperOpen: {
        width: 315,
        position: 'relative',
        transform: 'none !important',
        visibility: 'visible !important',
        border: 'none',
        transition: 'width 0.5s ease-in-out',
        maxHeight: '91vh',
        // padding: '20px 0',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`,
        zIndex: 1099,
        // paddingTop: 40,
        overflowY: 'hidden'
    },
    drawerPaperClose: {
        width: 0,
        position: 'relative',
        transform: 'none !important',
        visibility: 'visible !important',
        border: 'none',
        transition: 'width 0.2s ease-in-out',
        maxHeight: '91vh',
        // padding: '20px 0',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`,
        overflowY: 'hidden'
    },
    drawerTitle: {
        padding: "14px 50px 14px 25px",
        fontSize: 18,
        fontWeight: 600,
        backgroundColor: "#f5f6f7",
        color: "#000000",
        fontWeight: "bold",

    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 0,
    },
}));


const DrawerComponent = ({ drawer: { open, title, children }, openDrawerComponent }) => {
    const classes = useStyles();
    // const [profileValue, setProfileValue] = React.useState(0);
    const [drawerOverlay, setDrawerOverlay] = useState(false);

    const handleOpen = () => {
        openDrawerComponent({ open: true });
    };

    const handleClose = () => {
        // openDrawerComponent({ open: false, children: null, title: '' });
        openDrawerComponent({ open: false });
    };

    useEffect(() => {
        if (window.innerWidth < 1100) {
            handleClose();
            setDrawerOverlay(true);
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth < 1100) {
                // handleClose();
                setDrawerOverlay(true);
            }
            else {
                // handleOpen();
                setDrawerOverlay(false);
            }
        });

        return () => {
            window.removeEventListener("resize", () => { });
            openDrawerComponent({ open: false, children: null, title: 'Details' });
        }
    }, []);

    return (
        <div className={classes.root}>
            <Drawer
                className={drawerOverlay && classes.drawer}
                variant="persistent"
                anchor="right"
                classes={{
                    paper: open ? classes.drawerPaperOpen : classes.drawerPaperClose,
                }}
            >
                <DialogTitle
                    disableTypography
                    onClose={handleClose}
                    className={classes.drawerTitle}
                >
                    {getEllipsisText(title, 20)}
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    {open && children}
                </DialogContent>
            </Drawer>
        </div>
    )
}

const mapStateToProps = (state) => ({
    drawer: state.meetings.drawerComponent
});

const mapDispatchToProps = (dispatch) => {
    return {
        openDrawerComponent: (options) => dispatch(openDrawerComponent(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponent);