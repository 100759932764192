import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { withRouter} from 'react-router';
import { Button, IconButton } from '@material-ui/core';
import { useSnackbar } from "notistack";
import { getFollowUserList, getFollowingUserList, getMutualFollowingUserList } from "../../ducks/favourites";
import { getMediaList } from '../../ducks/user';
import { ArenaPreview } from './ArenaPreview';
import { PlayCircleOutline } from '@material-ui/icons';
import { getAvatarText } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#f7f7f7',
    padding: 1,
  },
  attachmentsRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  attachment: {
    height: 80,
    width: 80,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    textAlign: 'center',
    border: '1px solid #EEEEEE',
    position: 'relative',
    margin: '20px 5px 10px',
    borderRadius: 7,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5,
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      borderRadius: 7
    }
  },
  favourites: {
    position: 'fixed',
    width: 210,
    minWidth: 210,
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    // marginLeft: "50px",
    // padding: "16px",
    // height: "1000px"
  },
  favouriteName: {
    fontSize: "13px",
    textAlign: "center",
    color: "#535353",
    width: 80,
    margin: 'auto',
    wordBreak: 'break-all',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 600
    }
  },
  favouriteTitle: {
    marginLeft: "15px",
    color: "#535353",
    fontSize: 13
  },
  alternateNameText: {
    marginTop: 'calc(50% - 18px)',
    fontSize: 35,
    textTransform: 'uppercase'
  },
  seeMoreActionRoot: {
    textAlign: 'center',
    marginBottom: 40,
    '& button': {
      color: '#535353',
      borderColor: '#535353',
      maxWidth: 300
    }
  },

  videoStyles: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#828282",
    position: "relative",
    textAlign: "center",
    color: "#fff",
    overflow: 'hidden',
    borderRadius: 7,
    "&:hover": {
      cursor: "pointer"
    },
    '& button': {
      position: 'absolute'
    },
    "& img": {
      maxWidth: 'initial',
      [theme.breakpoints.down("xs")]: {
        maxWidth: '100% !important'
      }
    }
  },
  playIcon: {
    color: '#fff'
  },
  emptyResultText: {
    textAlign: 'center',
    color: '#53535380',
    fontSize: 14,
    fontWeight: 600,
    margin: '20px 0 50px'
  }
}))

const defaultObj = {
  page: 0,
  limit: 6,
  totalCount: 0,
  data: []
}

export const Favourites = props => {
  console.log("props",props);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [followerUserList, setFollowerUserList] = useState(defaultObj);
  const [followingUserList, setFollowingUserList] = useState(defaultObj);
  const [mutualFavouritesList, setMutualFavouritesList] = useState(defaultObj);
  const [mediaList, setMediaList] = useState(defaultObj);
  const [username, setUsername] = useState(null);
  const [preview, setPreview] = useState({
    open: false,
    attachments: [],
    attachmentIndex: 0
  });
  useEffect(() => {
    console.log(props);
    if (props.match.params.id) {
      setUsername(props.match.params.id);
      props.getFollowUserList({
        username: props.match.params.id,
        params: { limit: defaultObj.limit, page: defaultObj.page },
        onFollowerUserListSuccess,
        onFollowerUserListError
      });
      props.getFollowingUserList({
        username: props.match.params.id,
        params: {
          limit: defaultObj.limit, page: defaultObj.page,
        },
        onFollowingUserListSuccess,
        onFollowingUserListError
      });
      props.getMutualFollowingUserList({
        username: props.match.params.id,
        params: {
          limit: defaultObj.limit, page: defaultObj.page,
        },
        onMutualUserListSuccess,
        onMutualUserListError
      })
      props.getMediaList({
        params: {
          limit: defaultObj.limit, page: defaultObj.page,
          username: props.match.params.id
        },
        onMediaListSuccess,
        onMediaListError
      });
    }
    else {
      setUsername(props.keycloak.preferred_username);
      props.getFollowUserList({
        username: props.keycloak.preferred_username,
        params: { limit: defaultObj.limit, page: defaultObj.page },
        onFollowerUserListSuccess,
        onFollowerUserListError
      });
      props.getFollowingUserList({
        username: props.keycloak.preferred_username,
        params: {
          limit: defaultObj.limit, page: defaultObj.page,
        },
        onFollowingUserListSuccess,
        onFollowingUserListError
      });
      props.getMediaList({
        params: {
          limit: defaultObj.limit, page: defaultObj.page,
          username: props.keycloak.preferred_username
        },
        onMediaListSuccess,
        onMediaListError
      });
    }
  }, [props.match.params.id]);


  // useEffect(() => {
  //   console.log("useEffect props.user ", props.user);
  //   if (isLoggedInUser()) {
  //     console.log("useEffect isLoggedInUser ", props.user);
  //     setDetails(props.user);
  //   }
  // }, [props.user]);

  const isLoggedInUser = () => {
    if (props.match.params.id && props.match.params.id !== props.user.username) {
      return false
    }
    else {
      return true;
    }
  }

  const handleSeeMore = (type) => {
    switch (type) {
      case 'favourites':
        props.getFollowUserList({
          username: username,
          params: { limit: followerUserList.limit, page: followerUserList.page },
          onFollowerUserListSuccess,
          onFollowerUserListError
        });
        break;
      case 'favouritesTO':
        props.getFollowingUserList({
          username: username,
          params: { limit: followingUserList.limit, page: followingUserList.page },

          onFollowingUserListSuccess,
          onFollowingUserListError
        });
        break;
      case 'mutualFavourites':
        props.getFollowUserList({
          username: username,
          params: { limit: mutualFavouritesList.limit, page: mutualFavouritesList.page },
          onMutualUserListSuccess,
          onMutualUserListError
        });
        break
      case 'media':
      props.history.push(`/wall/profile/${props.match.params.id}/media`);
        break;
      default:
        break;
    }
  }

  const onFollowerUserListSuccess = (result, headers) => {
    if (parseInt(headers.page) === 0)
      setFollowerUserList(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: result
      }));
    else
      setFollowerUserList(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: [...prevState.data, ...result]
      }));
  }
  const onFollowerUserListError = (error) => {
    setFollowerUserList(defaultObj);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Favourites.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const onFollowingUserListSuccess = (result, headers) => {
    if (parseInt(headers.page) === 0)
      setFollowingUserList(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: result
      }));
    else
      setFollowingUserList(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: [...prevState.data, ...result]
      }));
  }
  const onFollowingUserListError = (error) => {
    setFollowingUserList(defaultObj);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Favourites.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const onMutualUserListSuccess = (result, headers) => {
    if (parseInt(headers.page) === 0)
      setMutualFavouritesList(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: result
      }));
    else
      setMutualFavouritesList(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: [...prevState.data, ...result]
      }));
  }
  const onMutualUserListError = (error) => {
    setMutualFavouritesList(defaultObj);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Mutual Favourites.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const onMediaListSuccess = (result, headers) => {
    if (parseInt(headers.page) === 0)
      setMediaList(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: result
      }));
    else
      setMediaList(prevState => ({
        ...prevState, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
        data: [...prevState.data, ...result]
      }));

  }
  const onMediaListError = (error) => {
    setMediaList(defaultObj);
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Media.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const handlePreview = (attachments, attachmentIndex) => {
    setPreview({ open: true, attachments, attachmentIndex });
  }

  const handlePreviewClose = () => {
    setPreview({ open: false, attachments: [] });
  }

  const FavouriteTemplate = ({ name, userId, photoUrl }) => (
    <div>
      <div title={name} onClick={() => props.history.push(`/wall/profile/${userId}`)} className={classes.attachment} style={{ backgroundColor: photoUrl && '#EEEEEE' }}>
        {photoUrl ?
          <img src={photoUrl} />
          :
          <div className={classes.alternateNameText}>
            {getAvatarText(name)}
          </div>
        }
      </div>
      <div className={classes.favouriteName} onClick={() => props.history.push(`/wall/profile/${userId}`)} >
        {name || '--'}
      </div>
    </div>
  )

  return (
    <div className={classes.favourites}>
      <div className={classes.root}>
        <p className={classes.favouriteTitle}>
          Favourites {`(${followerUserList.totalCount})`}
        </p>
      </div>
      {/* {followerUserList.data.length > 0 ?
        <>
          <div className={classes.attachmentsRoot}>
            {followerUserList.data.map((item, index) => (
              <FavouriteTemplate key={index} name={item.name} photoUrl={item.photoUrl} userId={item.userId} />
            ))}
          </div>
          <div className={classes.seeMoreActionRoot}>
            {
              (followerUserList.data.length < followerUserList.totalCount) &&
              <Button fullWidth variant="outlined" onClick={() => handleSeeMore('favourites')}>See More</Button>
            }
          </div>
        </>
        :
        <p className={classes.emptyResultText}>None marked as favourite</p>
      }
      <div className={classes.root}>
        <p className={classes.favouriteTitle}>
          Favourite to {`(${followingUserList.totalCount})`}
        </p>
      </div>
      {followingUserList.data.length > 0 ?
        <>
          <div className={classes.attachmentsRoot}>
            {followingUserList.data.map((item, index) => (
              <FavouriteTemplate key={index} name={item.name} photoUrl={item.photoUrl} userId={item.userId} />
            ))}
          </div>
          <div className={classes.seeMoreActionRoot}>
            {
              (followingUserList.data.length < followingUserList.totalCount) &&
              <Button fullWidth variant="outlined" onClick={() => handleSeeMore('favouritesTo')}>See More</Button>
            }
          </div>
        </>
        :
        <p className={classes.emptyResultText}>Not favourite to anyone</p>
      }
      {!isLoggedInUser() &&
        <>
          <div className={classes.root}>
            <p className={classes.favouriteTitle}>
              Mutual Favourites {`(${mutualFavouritesList.totalCount})`}
            </p>
          </div>
          {mutualFavouritesList.data.length > 0 ?
            <>
              <div className={classes.attachmentsRoot}>
                {mutualFavouritesList.data.map((item, index) => (
                  <FavouriteTemplate key={index} name={item.name} photoUrl={item.photoUrl} userId={item.userId} />
                ))}
              </div>
              <div className={classes.seeMoreActionRoot}>
                {
                  (mutualFavouritesList.data.length < mutualFavouritesList.totalCount) &&
                  <Button fullWidth variant="outlined" onClick={() => handleSeeMore('mutualFavourites')}>See More</Button>
                }
              </div>
            </>
            :
            <p className={classes.emptyResultText}>No Mutual favourites</p>
          }
        </>
      }
      {
        mediaList.data.length > 0 &&
        <>
          <div className={classes.root}>
            <p className={classes.favouriteTitle}>
              Media {`(${mediaList.totalCount})`}
            </p>
          </div>
          <div className={classes.attachmentsRoot}>
            {mediaList.data.map((item, index) => (
              <div key={index} className={classes.attachment}
                style={{ backgroundColor: '#f7f7f7' }}
                onClick={() => handlePreview(mediaList.data, index)}
              >
                {item.mimeType === "IMAGE" ? (
                  <img
                    alt={item.url.split("-").pop()}
                    src={item.url}
                  />
                ) : (
                    <div className={classes.videoStyles}>
                      <img
                        alt={item.thumbnailUrl && item.thumbnailUrl.split("-").pop()}
                        src={item.thumbnailUrl}
                      />
                      <IconButton>
                        <PlayCircleOutline
                          className={classes.playIcon}
                          fontSize="large"
                        />
                      </IconButton>
                    </div>
                    // <div className={classes.videoStyles}>
                    //   <IconButton>
                    //     <PlayCircleOutline
                    //       className={classes.playIcon}
                    //       fontSize="large"
                    //     />
                    //   </IconButton>
                    // </div>
                  )}
              </div>
              // <div key={index}>
              //   <div className={classes.attachment}>
              //     <img src={item.photoUrl} />
              //   </div>
              //   <div className={classes.favouriteName}>
              //     {(item.name && item.name.length) > 10 ? item.name.substring(0, 10) + '...' : item.name}
              //   </div>
              // </div>
            ))}
          </div>
         
          <div className={classes.seeMoreActionRoot}>
            {
              (mediaList.data.length < mediaList.totalCount) &&
              <Button fullWidth variant="outlined" onClick={() => handleSeeMore('media')}>See More</Button>
            }
          </div>
          {preview.open && <ArenaPreview open={preview.open} attachmentIndex={preview.attachmentIndex} arena={{ attachments: preview.attachments }} handlePreviewClose={handlePreviewClose} isFromProfile={true} />}
        </>
      } */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  keycloak: state.keycloak.decoded_token
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFollowUserList: value => dispatch(getFollowUserList(value)),
    getFollowingUserList: value => dispatch(getFollowingUserList(value)),
    getMutualFollowingUserList: (options) => dispatch(getMutualFollowingUserList(options)),
    getMediaList: value => dispatch(getMediaList(value))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Favourites));
