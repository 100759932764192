import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Backdrop, CircularProgress } from '@material-ui/core';
import DocFormat from '../../../assets/images/doc-format.png'
import PdfFormat from '../../../assets/images/pdf-format.png'
import ExcelFormat from '../../../assets/images/excel.svg';
import { getProfileMediaFiles } from '../../../ducks/common';
import { connect } from 'react-redux';
import { getSortedFiles } from '../../../selectors';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 0',
        position: 'relative'
    },
    titleMonth: {
        margin: 0,
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.primary.main,
        marginLeft: 16
    },
    listItemContent: {
        width: '100%',
        display: 'flex',
        wordBreak: 'break-all',
        justifyContent: 'space-between',
        margin: '10px 0px 10px 20px'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '7px',
        fontSize: 12,
        color: '#535353'
    },
    secondarytext: {
        margin: 0,
        fontSize: '12px',
        textTransform: 'capitalize',
        color: '#838383'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    emptyListText: {
        textAlign: 'center',
        margin: 30,
        fontSize: 14,
        color: '#58595B'
    }
}));

const Documents = ({ documents, currentChat, getProfileMediaFiles }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        console.log('Documents documents', documents)
        setLoading(false);
    }, [documents])

    useEffect(() => {
        console.log('Documents targetJid', currentChat)
        if (currentChat.type === 'group') {
            let teamId = currentChat && currentChat.targetJid.split('@')[0];
            // if (teamId !== documents.targetJid) {
            setLoading(true);
            getProfileMediaFiles({
                limit: 200,
                page: 0,
                target_jid: teamId,
                type: 'DOCUMENT',
                chat_type: 'team'
            });
            // }
            // else {
            //     setLoading(false);
            // }
        }
        else {
            let username = currentChat && currentChat.targetJid.split('@')[0];
            // if (username !== documents.targetJid) {
            setLoading(true);
            getProfileMediaFiles({
                limit: 200,
                page: 0,
                target_jid: username,
                type: 'DOCUMENT',
                chat_type: 'employee'
            });
            // }
            // else {
            //     setLoading(false);
            // }
        }

    }, [currentChat.targetJid]);

    const getFormatIcon = (type) => {
        switch (type) {
            case 'doc':
            case 'docx':
                return DocFormat;
            case 'pdf':
                return PdfFormat;
            case 'xlsx':
            case 'xls':
                return ExcelFormat;
            default:
                break;
        }
    }

    function downloadFiles(uri, name) {
        console.log('downloadFiles ', uri, name);
        var link = document.createElement("a");
        // link.onclick = `javascript:document.execCommand('SaveAs','true', ${name})`;
        link.download = name;
        link.href = uri;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div id="DocFiles" className="scroller" style={{ height: '70vh', maxHeight: '70vh', position: 'relative' }}>
            {
                (documents && documents.length > 0) ? documents.map(obj => (
                    <div className={classes.root}>
                        <p className={classes.titleMonth}>{obj.month}</p>
                        <List dense>
                            {obj.files && obj.files.map((item, index) => {
                                return (
                                    <ListItem key={index} button onClick={() => downloadFiles(item.sourceUrl, item.sourceUrl && item.sourceUrl.split('-').pop())} >
                                        <img width="25" src={getFormatIcon(item.mimeType)} alt={item.sourceUrl} />
                                        <div className={classes.listItemContent}>
                                            <div>
                                                <p className={classes.primaryText}>{item.sourceUrl && item.sourceUrl.split('-').pop()}</p>
                                                {/* <p className={classes.secondarytext}>{item.size}</p> */}
                                            </div>
                                        </div>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                ))
                    :
                    <p className={classes.emptyListText}>No Documents Found</p>
            }
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

const mapStateToProps = (state) => ({
    documents: getSortedFiles(state.common.documents.data)
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProfileMediaFiles: (options) => dispatch(getProfileMediaFiles(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);