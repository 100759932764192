import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { historyPushWithSearch } from '../../helpers';
import { CustomListGroup } from '../LiveScores/RoundsListView';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import RewardsList from './RewardsList';
import { getRewardsList } from '../../ducks/game';
import { setLoading } from '../../ducks/loading';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import RewardsWinning from './RewardsWinning';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f0f2f5',
        width: '100%',
    },
    contentRoot: {
        padding: '10px 10px 10px 20px',
        backgroundColor: '#ffffff',
        overflowY: 'auto',
        minHeight: 'calc(100vh - 280px)',
        margin: '25px'
        // width: 'calc(100% - 90px)'
    },
    tabsRoot: {
        // position: 'fixed',
        top: 60,
        left: 0,
        backgroundColor: '#FFF',
        width: '100%',
        maxWidth: '100%',
        minHeight: 45,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
    },
    tabRoot: {
        minHeight: 45,
        textTransform: 'capitalize',
        color: '#262626 !important',
        minWidth: 'auto',
        margin: '0px 12px',
        padding: '6px 2px',
        [theme.breakpoints.up("sm")]: {
            // minWidth: 125
        }
    },
    flexContainerStyles: {
        // justifyContent: 'center',
        marginLeft: 34
    },
    scrollButtons: {
        // width: 25
    }
}))

const MenuOptions = [
    { label: 'My Winnings', value: 'winnings', pathname: 'winnings' },
    { label: 'Rewards', value: 'player', pathname: 'rewards' }
];
const Rewards = ({ filtersData, location, history, getRewardsList, setFiltersData }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [step, setStep] = useState('');


    const handleTabChange = (newValue) => {
        if (newValue.value !== step) {
            setStep(newValue.value)
            historyPushWithSearch(`${path}/${newValue.pathname}`, history, location.search);
        }
    }

    return (
        <div className={classes.root} id="leagues-root1">
            <div>
                <div>
                    <CustomListGroup data={MenuOptions} selectedItem={MenuOptions.find((option) => option.pathname === location.pathname.split('/')[4])} onClick={handleTabChange} style={{ marginLeft: 20 }} />
                </div>
                <div className={classes.contentRoot} >
                    <Switch>
                        <Route path={`${path}/winnings`}>
                            <RewardsWinning filtersData={filtersData} step={step} setFiltersData={setFiltersData} />
                        </Route>
                        <Route path={`${path}/rewards`}>
                            <RewardsList filtersData={filtersData} step={step} setFiltersData={setFiltersData} />
                        </Route>
                        <Redirect to={{ pathname: `${path}/winnings`, search: location.search }} />
                    </Switch>
                </div>
            </div>
        </div>


    )
}
const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rewards));