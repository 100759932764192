import React, { useEffect, useState } from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import attachmentList from './attachments.json';
import { Image as KonvaImage, Layer, Line, Stage, Text, Rect, Transformer } from 'react-konva';
import { downloadFiles } from '../../helpers';
import { ReactComponent as TypeText } from "../../assets/icons/type-text.svg";
import { ReactComponent as Stickers } from "../../assets/icons/sticker.svg";
import { ReactComponent as Scribble } from "../../assets/icons/scribble.svg";
import { ReactComponent as Undo } from "../../assets/icons/undo.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-1.svg";
import Gallery from 'react-photo-gallery';
import Photo from './Photo';
import { getFirstImage } from './CreatePost';
import Autocomplete from '@material-ui/lab/Autocomplete';

const images = importAll(require.context('../../assets/stickers', false, /\.png/));

function importAll(r) {
    let images = {};
    return r.keys().map((item) => ({ url: r(item) }));
    return images;
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFF',
        // backgroundColor: '#F1F2F5',
        position: 'relative',
        display: 'flex',
        // minHeight: 'calc(100vh - 64px)',
        // maxHeight: 'calc(100vh - 64px)',
        maxHeight: 550,
        minHeight: 550,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            // maxHeight: 400,
            // minHeight: 'calc(100vh - 64px)',
            minHeight: 550
        },
        [theme.breakpoints.down('xs')]: {
            // maxHeight: 400,
            // minHeight: '100vh'
        },
    },
    attachmentList: {
        width: '10%',
        // paddingTop: 10,
        // backgroundColor: '#FFF',
        borderRight: '1px solid #EEEEEE',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            minWidth: '20%',
            justifyContent: 'flex-start'
        }
    },
    editorRoot: {
        width: '100%',
        display: 'flex'
    },
    imageRoot: {
        display: 'flex',
        margin: '7px 0 7px',
        '& img': {
            maxWidth: '45%',
            margin: 'auto',
            cursor: 'pointer',
        },
        '& video': {
            maxWidth: '45%',
            margin: 'auto',
            cursor: 'default'
        }
    },
    editOptionsSection: {
        backgroundColor: '#F1F2F5',
        margin: 24,
        overflowX: 'hidden',
        width: '100%',
        // padding: 24,
        display: 'flex',
        flexDirection: 'column'
    },
    editActionsRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& div': {
            cursor: 'pointer',
            backgroundColor: '#FFF',
            padding: 10,
            textAlign: 'center',
            margin: '0px 2px',
            border: '1px solid #4e4e5066',
            width: 52,
            borderRadius: 6,
            '& svg': {
                width: 18,
                height: 18
            },
            '& p': {
                margin: 0,
                fontSize: 11
            },
        },
        '& .selected': {
            fontWeight: 600,
            borderColor: '#000000de',
            '& path': {
                fill: '#000'
            }
        }
    },
    editOptionsContent: {
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'space-between',
        margin: '10px 2px 0px',
        padding: '0 16px 16px',
        borderRadius: '6px 6px 0px 0px',


        height: '-webkit-fill-available',
        height: '100%',
        overflowY: 'auto',
        // margin: '10px 2px',
        backgroundColor: '#FFF',
        '& .title': {
            fontSize: 14,
            marginTop: 20
        },
        '& .addNew': {
            margin: '4px 4px 0px',
            cursor: 'pointer',
            color: '#112386',
            fontSize: 11,
            textAlign: 'right',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        '& .react-photo-gallery--gallery': {
            '& div': {
                justifyContent: 'space-between'
            }
        }
    },
    inputRoot: {
        borderRadius: 6
    },
    colorsListRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 2px'
    },
    colorButton: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        cursor: 'pointer'
    },
    actionsRoot: {

        backgroundColor: '#FFF',
        margin: '0px 2px',
        borderRadius: '0px 0px 6px 6px',
        padding: '10px 0',

        display: 'flex',
        justifyContent: 'space-around',
        // margin: '10px 0 5px',
        '& button': {
            height: 30,
            width: 80
        },
        '& .next-arrow': {
            '& path': {
                fill: '#FFF'
            }
        }
    },
    addTextRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    fontSizeAutocomplete: {
        borderRadius: 6,
        padding: '0px 3px !important',
        width: 60,
        '& input': {
            paddingTop: '5px !important',
            paddingBottom: '5px !important'
        }
    },
}));

class URLImage extends React.Component {
    state = {
        image: null
    };
    componentDidMount() {
        this.loadImage();
    }
    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }
    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }
    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }
    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image
        });
        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    };
    render() {
        return (
            <KonvaImage
                onTransform
                key={this.props.key}
                x={this.props.x}
                y={this.props.y}
                draggable={this.props.draggable}
                image={this.state.image}
                ref={node => {
                    this.imageNode = node;
                }}
                width={this.props.width}
                height={this.props.height}
            />
        );
    }
}


const scalePreserveAspectRatio = (imgW, imgH, maxW, maxH) => {
    return (Math.min((maxW / imgW), (maxH / imgH)));
}

const Rectangle = ({ image, shapeProps, isSelected, onSelect, onChange, draggable }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const [fillImg, setFillImg] = useState(null);

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    useEffect(() => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        const imageNew = new Image();
        imageNew.onload = () => {
            canvas.width = shapeProps.width;
            canvas.height = shapeProps.height;
            let w = imageNew.width;
            let h = imageNew.height;
            let sizer = scalePreserveAspectRatio(w, h, canvas.width, canvas.height);
            ctx.drawImage(imageNew, 0, 0, w, h, 0, 0, w * sizer, h * sizer);
            // console.log('image ', shapeProps, imageNew.width, imageNew.height);
            setFillImg(canvas);
        }
        imageNew.src = image;
    }, [shapeProps]);

    return (
        <React.Fragment>
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                fillPatternImage={fillImg}
                fillPatternRepeat={"no-repeat"}
                {...shapeProps}
                draggable={draggable}
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    anchorCornerRadius={6}
                    enabledAnchors={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
                    // borderEnabled={false}
                    // borderStrokeWidth={2}
                    borderStroke={'#000'}
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

const initialRectangles = [
    {
        x: 10,
        y: 10,
        width: 100,
        height: 100,
        fill: 'red',
        id: 'rect1',
    },
    {
        x: 150,
        y: 150,
        width: 150,
        height: 150,
        // fill: 'green',
        id: 'rect2',
    },
];

const defaultShapeProps = {
    x: 200,
    y: 200,
    width: 150,
    height: 150
}

// const canvasWidth = 550;
// const canvasHeight = 550;

const colorsList = ['#000000', '#FFFFFF', '#15DDB9', '#25C6E9', '#0728D4', '#FFA011', '#EA3737'];
let scribbleColor = '#000000';
let defaultTextObj = { text: '', color: '#FFF' };
let imageNode = null;
let actionsPerformed = [];
let prevTextIndex = null;
const EditMedia = ({ attachments, cropType, handleEditSubmit = () => { } }) => {
    const classes = useStyles();
    const [imageEditor, toggleImageEditor] = useState({ open: false, src: '' });
    const [attachmentsList, setAttachmentsList] = useState(attachments);
    const [selectedImage, setSelectedImage] = useState({ ...getFirstImage(attachments) });
    const [editType, setEditType] = useState('text');
    const [textList, setTextList] = useState([{ ...defaultTextObj }]);
    const [addedStickers, setAddedStickers] = useState([]);
    // const [actionsList, setActions]

    const [rectangles, setRectangles] = React.useState(initialRectangles);
    const [selectedId, selectShape] = React.useState(null);

    const checkDeselect = (e) => {
        // deselect onMouseLeave
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectShape(null);
        }
    };

    // const [tool, setTool] = React.useState('pen');
    const [lines, setLines] = React.useState([]);
    const isDrawing = React.useRef(false);

    const stageRef = React.useRef(null);

    const handleMouseDown = (e) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        // console.log('actionsPerformed onMouseDown ');
        actionsPerformed = [...actionsPerformed, 'line'];
        setLines([...lines, { tool: 'pen', color: scribbleColor, points: [pos.x, pos.y] }]);

    };

    const handleMouseMove = (e) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // replace last
        lines.splice(lines.length - 1, 1, lastLine);
        // actionsPerformed = [...actionsPerformed, 'line'];
        setLines(lines.concat());
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };

    useEffect(() => {
        // console.log('EditMedia ', JSON.stringify(attachments));
        actionsPerformed = [];
        prevTextIndex = null;
    }, []);

    const handleImageSelect = (imageObj, index) => {
        const uri = stageRef.current.toDataURL();
        // handleExport();
        let attachmentsListDup = JSON.parse(JSON.stringify(attachmentsList));
        attachmentsListDup[selectedImage.index].url = uri;
        setAttachmentsList([...attachmentsListDup]);
        setTextList([{ ...defaultTextObj }]);
        setAddedStickers([]);
        setLines([]);
        setSelectedImage({ ...imageObj, index });
        actionsPerformed = [];
        prevTextIndex = null;
    }

    const handleTextChange = (e, index) => {
        let textListDup = JSON.parse(JSON.stringify(textList));
        textListDup[index].text = e.target.value;
        setTextList([...textListDup]);
    }

    const handleTextOnBlur = (e, index) => {
        // console.log('handleTextOnBlur ', e.target.value, index);
        if (e.target.value && e.target.value.length > 0 && index !== prevTextIndex) {
            prevTextIndex = index;
            actionsPerformed = [...actionsPerformed, 'text'];
        }
    }

    const handleTextColorChange = (color, index) => {
        let textListDup = JSON.parse(JSON.stringify(textList));
        textListDup[index].color = color;
        setTextList([...textListDup]);
    }
    const handleFontSizeChange = (size, index) => {
        let textListDup = JSON.parse(JSON.stringify(textList));
        textListDup[index].size = size;
        setTextList([...textListDup]);
    }

    const handleStickerClick = (event, { index, photo }, a) => {
        // console.log('handlePhotoClick ', event, index, photo);
        setAddedStickers([...addedStickers, { ...photo, shapeProps: defaultShapeProps }]);
        actionsPerformed = [...actionsPerformed, 'stickers'];
    }

    const handleUndoClick = (type) => {
        // console.log('actionsPerformed undo ', actionsPerformed);
        const lastAction = actionsPerformed[actionsPerformed.length - 1];
        if (lastAction === 'text') {
            if (textList.length > 1) {
                let textListDup = JSON.parse(JSON.stringify(textList));
                if (textListDup[textListDup.length - 1].text && textListDup[textListDup.length - 1].text.length > 0) {
                    setTextList([...textListDup.slice(0, textListDup.length - 1)]);
                }
                else {
                    setTextList([...textListDup.slice(0, textListDup.length - 2)]);
                }
            }
            else {
                setTextList([{ ...defaultTextObj }]);
            }

        }
        else if (lastAction === 'stickers') {
            if (addedStickers.length > 0) {
                let addedStickersDup = JSON.parse(JSON.stringify(addedStickers));
                setAddedStickers([...addedStickersDup.slice(0, addedStickersDup.length - 1)]);
            }
        }
        else {
            if (lines.length > 0) {
                let linesDup = JSON.parse(JSON.stringify(lines));
                setLines([...linesDup.slice(0, linesDup.length - 1)]);
            }
        }
        actionsPerformed.splice(actionsPerformed.length - 1, 1);
    }

    const handleNext = (index) => {
        selectShape(null);
        setTimeout(() => {
            const uri = stageRef.current.toDataURL();
            let attachmentsListDup = JSON.parse(JSON.stringify(attachmentsList));
            if (attachmentsListDup[index]) attachmentsListDup[index].url = uri;
            // downloadFiles(attachmentsListDup[index].url, 'stage');
            // setAttachmentsList([...attachmentsListDup]);
            handleEditSubmit([...attachmentsListDup]);
        }, 500);
    }

    const handleExport = () => {
        const uri = stageRef.current.toDataURL();
        // console.log(uri);
        // we also can save uri as file
        // but in the demo on Konva website it will not work
        // because of iframe restrictions
        // but feel free to use it in your apps:
        downloadFiles(uri, 'stage');
    };

    // console.log('actionsPerformed ', actionsPerformed);
    return (
        <div className={classes.root}>
            <div className={classes.attachmentList}>
                {
                    attachmentsList.map((obj, index) => (
                        <div key={index} className={classes.imageRoot}>
                            {
                                obj.mimeType === 'IMAGE' ?
                                    <img src={obj.url} alt="image" onClick={() => handleImageSelect(obj, index)} />
                                    :
                                    <video src={obj.url} autoPlay={false} />
                            }
                        </div>
                    ))
                }
            </div>
            <div className={classes.editorRoot}>
                {
                    selectedImage &&
                    <Stage width={cropType?.size?.width} height={cropType?.size?.height} style={{ display: 'flex', alignItems: 'center' }}
                        ref={stageRef}
                        onMouseDown={editType === 'scribble' ? handleMouseDown : () => { }}
                        onMousemove={editType === 'scribble' ? handleMouseMove : () => { }}
                        onMouseup={editType === 'scribble' ? handleMouseUp : () => { }}
                        onMouseLeave={checkDeselect}
                        onTouchStart={checkDeselect}
                    >
                        <Layer>
                            <URLImage src={selectedImage.url} x={0} y={0} />
                            {/* {
                                addedStickers.length > 0 ?
                                    addedStickers.map((sticker, i) => <URLImage key={i} draggable src={sticker.url} x={200} y={200} width={100} height={100} />)
                                    : null
                            } */}
                            {addedStickers.map((sticker, i) => {
                                return (
                                    <Rectangle
                                        key={i}
                                        shapeProps={sticker.shapeProps}
                                        image={sticker.url}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selectShape(i);
                                        }}
                                        draggable={editType !== 'scribble'}
                                        onChange={(newAttrs) => {
                                            // const rects = rectangles.slice();
                                            const rects = addedStickers.slice();
                                            rects[i].shapeProps = newAttrs;
                                            setAddedStickers(rects);
                                            // setRectangles(rects);
                                        }}
                                    />
                                );
                            })}
                            {textList.length > 0 ?
                                textList.map((text, i) => (
                                    <Text key={i} draggable={editType !== 'scribble'} onTransform={(e, a) => console.log('onTransform ', e, a)} text={text.text} fontSize={text.size || 40} fill={text.color} x={10} y={50} wrap="char" align="center" width={cropType?.size?.width - 20} />
                                )) : null
                            }
                            {lines.map((line, i) => (
                                <Line
                                    key={i}
                                    points={line.points}
                                    stroke={line.color}
                                    strokeWidth={5}
                                    tension={0.5}
                                    lineCap="round"
                                    globalCompositeOperation={
                                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                    }
                                />
                            ))}
                        </Layer>
                    </Stage>
                }
                {/* <button onClick={handleExport}>Click here to download</button> */}
                <div style={{ backgroundColor: '#F1F2F5', width: '100%', display: 'flex' }}>
                    <div className={classes.editOptionsSection}>
                        <div className={classes.editActionsRoot}>
                            <div className={editType === 'text' && 'selected'} onClick={() => setEditType('text')}>
                                <TypeText />
                                <p>Type Text</p>
                            </div>
                            <div className={editType === 'stickers' && 'selected'} onClick={() => setEditType('stickers')}>
                                <Stickers />
                                <p>Stickers</p>
                            </div>
                            <div className={editType === 'scribble' && 'selected'} onClick={() => setEditType('scribble')}>
                                <Scribble />
                                <p>Scribble</p>
                            </div>
                        </div>
                        <div className={classes.editOptionsContent} style={editType === 'stickers' ? { width: 'calc(100% + 12px)', padding: 0 } : {}}>
                            {editType === 'text' ?
                                <>
                                    <div style={{ marginBottom: 15 }}>
                                        <p className="title">Choose Text Color</p>
                                        <div className={classes.colorsListRoot}>
                                            {
                                                colorsList.map((color, index) => (
                                                    <div onClick={() => handleTextColorChange(color, textList.length - 1)} className={classes.colorButton} style={{ backgroundColor: color, border: color === '#FFFFFF' && '1px solid #000' }} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.addTextRoot}>
                                            <p className="title">Add Text</p>
                                            <Autocomplete
                                                title="Font size"
                                                id="font-size"
                                                disableClearable
                                                options={['10', '20', '30', '40', '50', '60', '70', '80', '90', '100']}
                                                value={textList[textList.length - 1].size || '40'}
                                                getOptionLabel={(option) => option}
                                                classes={{ inputRoot: classes.fontSizeAutocomplete }}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                onChange={(...args) => handleFontSizeChange(args[1], textList.length - 1)}
                                            />
                                        </div>
                                        <TextField
                                            id="outlined"
                                            fullWidth
                                            placeholder="Type text here"
                                            multiline
                                            variant="outlined"
                                            rows={3}
                                            rowsMax={3}
                                            value={textList[textList.length - 1].text}
                                            onChange={(e) => handleTextChange(e, textList.length - 1)}
                                            InputProps={{ classes: { root: classes.inputRoot } }}
                                            onBlur={(e) => handleTextOnBlur(e, textList.length - 1)}
                                        />
                                        <p className="addNew" onClick={() => { if (textList[textList.length - 1].text && textList[textList.length - 1].text.length > 0) setTextList([...textList, { ...defaultTextObj }]) }}>Add New</p>
                                    </div>
                                </>
                                : editType === 'stickers' ?
                                    <>
                                        <p className="title" style={{ paddingLeft: 16 }}>Add Sticker</p>
                                        {
                                            <div>
                                                <Gallery margin={8} photos={images} onClick={handleStickerClick} renderImage={props =>
                                                    <Photo key={props.index} {...props} width={70} height={70} />
                                                }
                                                />
                                            </div>
                                        }
                                        {/* <img src={images[0].url} atl="sticker" /> */}
                                    </>
                                    : <>
                                        <div style={{ marginBottom: 15 }}>
                                            <p className="title">Choose Scribble Color</p>
                                            <div className={classes.colorsListRoot}>
                                                {
                                                    colorsList.map((color, index) => (
                                                        <div onClick={() => { scribbleColor = color }} className={classes.colorButton} style={{ backgroundColor: color, border: color === '#FFFFFF' && '1px solid #000' }} />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        {/* <div></div> */}
                                    </>

                            }
                        </div>
                        <div className={classes.actionsRoot}>
                            <Button title="Undo" variant="contained" color="secondary" size="large" onClick={() => handleUndoClick(editType)}><Undo /></Button>
                            <Button title="Next" variant="contained" color="primary" size="large" onClick={() => handleNext(selectedImage.index)}><ArrowRight className="next-arrow" /></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

export default EditMedia;