import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getLeaguesList, getSeasonsList, getLiveMatches, getPlayersRankBySeason, getSeasonKpisList, getRoundTeamStatistics, getRoundPlayerStatistics, getPlayersStandingBySeason, getTeamRankBySeason, getTeamStandingBySeason, getPlayersPointsList, getPlayerCoreKpisStats, getMatchesList, getPlayersPerformance } from "../../../ducks/game";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getNumberFormat, decodeEnum, getCurrencyFormat, getCurrencyFormatSuffix, getDurationWithUnit, setSearchQuery, getSearchValue, historyPushWithSearch } from '../../../helpers';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import { setLoading } from '../../../ducks/loading';
import { useSnackbar } from 'notistack';
import { RankLabelLatest } from '../../PointsTables/RankLabel';
import GetCountryCurrencyFormat from '../../GlobalComponents/GetCountryCurrencyFormat';
import { FormControl, InputBase, ListItemAvatar, Tab, Tabs, TextField, fade } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import IllustrationView from '../../LiveScores/IllustrationView';
import { Autocomplete } from '@material-ui/lab';
import PlayerProfile from '../../PlayerProfile';
import redIndicator from '../../../assets/images/pwc-images/red.svg';
import greenIndicator from '../../../assets/images/pwc-images/green.svg';
import amberIndicator from '../../../assets/images/pwc-images/amber.svg';
import goldMedal from '../../../assets/images/pwc-images/gold.svg';
import silverMedal from '../../../assets/images/pwc-images/silver.svg';
import bronzeMedal from '../../../assets/images/pwc-images/bronze.svg';
import { CustomCard } from '../../Leagues/CustomCard';






const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F2F5",
    width: "100%",
    maxHeight: "calc(100vh - 116px)",
    // maxHeight: 'calc(100vh - 100px)',
    overflowY: "auto",
    padding: "56px 24px 0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // maxHeight: 'calc(100vh - 80px)',
      // maxWidth: `calc(100vw - 150px)`,
      padding: "56px 0px 0px",
    },
    '& .MuiTab-fullWidth': {
      flexGrow: 0
    }
  },
  gridContainer: {
    // width: '80%',
    // width: '90%',
    display: "flex",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1250,
    [theme.breakpoints.down("md")]: {
      // width: '85%',
      // width: '90%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
    },
    [theme.breakpoints.down("xs")]: {
      // width: '100%',
    },
  },
  cardStyle: {
    height: "143px",
    width: "300px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },

  cardHeadertitle: {
    color: "#A0A0A0",
    fontSize: "13px",
    lineHeight: "19.5px",
    fontWeight: "500",
  },
  avatarStyles: {
    width: 36,
    height: 36,
    fontSize: 16,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: "0.785714px solid #C4C4C4",
  },
  orgCardStyle: {
    height: "430px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  playerCardStyle: {
    height: "392px",
    width: "318px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  performanceCardStyle: {
    height: "392px",
    width: "546px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  Box: {
    height: "138px",
    width: "160px",
    //left: "51px",
    //top: "228px",
    //marginTop: "17px",
    //borderRadius: "10px",
    border: "1px solid #EDEDED",
    // borderColor: '#EDEDED'
    margin: 10,
    marginTop: 16,
    boxShadow: "none"
  },

  cardHeader: {
    fontSize: 13, lineHeight: "19.5px", color: "#000000", marginLeft: 15, fontWeight: '600'
  },
  input: {
    color: "#CCCDFD",
    backgroundColor: "#CCCDFD",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#CCCDFD",
      boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
      background: "rgba(0,0,0,0)"
    }
  },
  autocompleteRoot: {
    borderRadius: 30,
    padding: '5px 5px !important',
    fontSize: 25,
    backgroundColor: '#F1F0FD',
    // width: 280
    margin: '10px',
    marginTop: '10px',
    borderColor: '#EBEBFF',
    marginLeft: '45px',
    color: "#5D5FEF",
  },

  nameRoot: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 600,
    '& .name': {
      fontWeight: 500
    }
  },
  scoreCardsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .scoreCard': {
      // minWidth: 150,
      // minHeight: 125,
      width: '279px',
      height: '155px',
      border: '1px solid rgba(0, 0, 0, 0.16)',
      borderRadius: 3,
      margin: '6px 6px 6px 6px',
      // cursor: 'pointer'

    },
    '& .title': {
      color: '#4B4B4B',
      fontWeight: 600,
      fontSize: 13,
      // margin: '10px 0',
      backgroundColor: 'rgba(93, 95, 239, 0.24)',
      height: '35px',
      margin: '0px',
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal !important',
      lineHeight: '20px !important',
      fontFamily: 'Poppins !important',
      justifyContent: 'space-between',
    },
    '& .ach': {
      color: '#362F32',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      paddingBottom: '12px'
    },
    '& .value': {
      color: '#362F32',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '24px'
    },
    '& .rankRoot': {
      fontSize: 11,
      color: '#000',
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .rank': {
        fontSize: 28,
      },
    },
    '& .rank': {
      fontSize: 28,
    },
    '& .scoreRoot': {
      fontSize: 11,
      color: '#000',
      margin: 0,
      '& .score': {
        fontSize: 28,
      },
      '& .scoreUnit': {
        fontSize: 12,
        marginLeft: 5
      }
    },
    '& .achievment': {
      fontSize: 12,
      color: '#000',
      marginBottom: 0
    }
  },
  scoreCardsRoot2: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .scoreCard': {
      // minWidth: 150,
      // minHeight: 125,
      width: '279px',
      height: '155px',
      border: '1px solid rgba(0, 0, 0, 0.16)',
      borderRadius: 3,
      margin: '6px 6px 6px 6px',

    },
    '& .title': {
      color: '#4B4B4B',
      fontWeight: 600,
      fontSize: 13,
      // margin: '10px 0',
      backgroundColor: 'rgba(93, 95, 239, 0.24)',
      height: '35px',
      margin: '0px',
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal !important',
      lineHeight: '20px !important',
      fontFamily: 'Poppins !important',
      flexDirection: 'column'

    },
    '& .ach': {
      color: '#362F32',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      paddingBottom: '12px'
    },
    '& .value': {
      color: '#362F32',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '24px'
    },
    '& .rankRoot': {
      fontSize: 11,
      color: '#000',
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .rank': {
        fontSize: 28,
      },
    },
    '& .rank': {
      fontSize: 28,
    },
    '& .scoreRoot': {
      fontSize: 11,
      color: '#000',
      margin: 0,
      '& .score': {
        fontSize: 28,
      },
      '& .scoreUnit': {
        fontSize: 12,
        marginLeft: 5
      }
    },
    '& .achievment': {
      fontSize: 12,
      color: '#000',
      marginBottom: 0
    }
  },
  leaderBoardCardsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .scoreCard': {
      // minWidth: 150,
      // minHeight: 125,
      width: '281px',
      height: '125px',
      border: '1px solid rgba(0, 0, 0, 0.16)',
      borderRadius: 3,
      margin: '5px 5px 5px 5px',

    },
    '& .title': {
      color: '#4B4B4B',
      fontWeight: 600,
      fontSize: 13,
      // margin: '10px 0',
      backgroundColor: 'rgba(93, 95, 239, 0.24)',
      height: '20px',
      margin: '0px',
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal !important',
      lineHeight: '20px !important',
      fontFamily: 'Poppins !important',
      justifyContent: 'space-between',
    },
    '& .ach': {
      color: '#362F32',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    '& .value': {
      color: '#362F32',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '24px'
    },
    '& .rankRoot': {
      fontSize: 11,
      color: '#000',
      // marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '-15px',
      '& .rank': {
        fontSize: 28,
      },
    },
    '& .rankdiff': {
      color: '#000000',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px'
    },
    '& .rank': {
      color: '#362F32',
      fontWeight: 400,
      fontSize: '30px',
      lineHeight: '45px'
    },
    '& .scoreRoot': {
      fontSize: 11,
      color: '#000',
      margin: 0,

      '& .scoreUnit': {
        fontSize: 12,
        marginLeft: 5
      }
    },
    '& .score': {
      color: '#4B4B4B',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '22px',
    },
    '& .achievment': {
      fontSize: 12,
      color: '#000',
      marginBottom: 0
    }
  },
  profileTabRoot: {
    minWidth: 100,
    minHeight: "auto",
    fontSize: 15,
    textTransform: "capitalize",
    color: "#535353",
    padding: "6px 0",
    opacity: 0.9,
    flexGrow: 0,
    fontWeight: 600,
    // borderBottom: "1px solid #e8e8e8 !important",
    // borderTop: "1px solid #e8e8e8 !important",
  },
  search: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderRadius: 4,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: fade("#F1F2F5", 0.75),
    },
    // width: 'calc(100% - 795px)',
    width: '350px',
    border: '1px solid #c4c4c4',
    height: '38px'


    // width: "auto",
    // marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    // maxWidth: 250,
    // minWidth: 175,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#6d6666",
  },
  searchInputRoot: {
    color: "inherit",
    width: "100%",
  },
  searchInputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: 20,
    fontSize: 12,

  },
  autocompleteRoot: {
    width: "200px",
    height: "39px",
    borderRadius: 3,
    overflow: "clip",
    whiteSpace: "nowrap",
    "& .MuiAutocomplete-tagSizeSmall": {
      width: "65px !important",
    },
    "& .MuiInputBase-root": {
      overflow: "hidden !important",
    },



  },
  formRoot: {
    marginLeft: "5px",
    '& .MuiFormControl-root': { '& label': { top: '0px' }, backgroundColor: '#ffffff !important' },
    '& .MuiFormLabel-root': { color: '#6d6666 !important' }
    // width: 200
  },
  overallBoard: {
    height: '40px',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '0px',
    borderRadius: 3,
    margin: '5px 5px 5px 5px',
    padding: '10px',
    backgroundColor: 'rgba(93, 95, 239, 0.24)',
    display: 'flex',
    justifyContent: 'space-between',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',

    '& .title': {
      color: '#4B4B4B',
      fontWeight: 600,
      fontSize: 13,
      margin: '0px',
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal !important',
      lineHeight: '20px !important',
      fontFamily: 'Poppins !important',
      justifyContent: 'space-between',
    },

  },
  overallBoard2: {
    height: '40px',
    borderRight: '1px solid rgba(0, 0, 0, 0.16)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.16)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    margin: '-5px 5px 5px 5px',
    padding: '10px',
    // backgroundColor: 'rgba(93, 95, 239, 0.24)',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '2px',

    '& .value': {
      color: '#4B4B4B',
      fontWeight: 600,
      fontSize: 15,
      margin: '0px',
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal !important',
      lineHeight: '20px !important',
      fontFamily: 'Poppins !important',
      justifyContent: 'space-between',
      width: '100px'
    },
    '& .valueLeaderboard': {
      color: '#4B4B4B',
      fontSize: 15,
      margin: '0px',
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal !important',
      lineHeight: '20px !important',
      fontFamily: 'Poppins !important',
      justifyContent: 'space-between',
    },


  }


}));

export const formattedValue = (userDetails, value, item, units = true) => {
  return item.unit_of_measurement === 'CURRENCY' ?
    <GetCountryCurrencyFormat type="currency" value={value} />
    : item.unit_of_measurement === 'NUMBER' ?
      <GetCountryCurrencyFormat type="number" value={value} />
      // getCurrencyFormatSuffix(userDetails?.countryCode, item.unit, value, units)
      : (units ? `${getNumberFormat(userDetails?.countryCode, value)}${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? ` ${getDurationWithUnit(value, item.unit)}` : ''}` : getNumberFormat(userDetails?.countryCode, value));
}

const MyPerformance = ({ isSeasonLevel, roundId, getPlayersPointsList, getPlayerCoreKpisStats, getSeasonKpisList, filtersData, userDetails, setLoading, getRoundPlayerStatistics, getMatchesList, getPlayersPerformance, getSeasonsList, setFiltersData, history }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [playerDetails, setPlayerDetails] = useState({});
  const [playerRank, setPlayerRank] = useState({});
  const [playerKpiStats, setPlayerKpiStats] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [query, setQuery] = useState('');
  const [copyPlayerKpiStats, setCopyPlayerKpiStats] = useState([]);
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [currentMatch, setCurrentMatch] = useState({});
  const [prevMatch, setPrevMatch] = useState({});
  const [prevPlayerKpiStats, setPrevPlayerKpiStats] = useState([]);
  const { path } = useRouteMatch();
  const [searchResults, setSearchResults] = useState([]);





  useEffect(() => {
    console.log('use effect performance', filtersData)
    if (filtersData?.season?._id && (isSeasonLevel ? true : roundId)) {

      let seasonId = filtersData.season?._id;
      if (isSeasonLevel)
        getPlayerKpiStats(seasonId);
      getSeasons(filtersData.league?._id);
    }
  }, [filtersData?.season?._id, roundId]);


  useEffect(() => {
    console.log('matchesuseffect', prevMatch, selectedSeason)
    if (selectedSeason) {
      getPlayerKpiStats(selectedSeason?._id);

    }
    if (prevMatch) {
      getPlayerKpiStats(prevMatch?._id, '', 'previous');

    }

  }, [selectedSeason, prevMatch])


  const getSeasons = (leagueId) => {
    setLoading(true);
    getSeasonsList({
      leagueId: leagueId,
      params: { limit: 5000, page: 1, status: "live" },
      onSuccessCB: (result, headers) => {
        if (result.length > 0) {
          const currentMatch = result.find(obj => obj._id === filtersData.season._id)
          const currentMatchIndex = new Date(currentMatch?.start_date).getMonth()
          const prevMatch = result.find(obj => new Date(obj?.start_date).getMonth() == currentMatchIndex - 1)
          setSelectedSeason(currentMatch);
          setPrevMatch(prevMatch);
          setSeasons([...result]);
        }
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);


      }
    })
  }






  const getPlayerKpiStats = (seasonId, matchId, from) => {
    setLoading(true);
    getPlayersPerformance({
      params: { limit: 5000, page: 1, match_id: matchId, season_id: seasonId }, // employee_id: userDetails.username,
      onSuccessCB: (result, headers) => {
        if (result) {
          console.log('statssss', from, result)
          const playerPoints = result.find(obj => obj.employee_id === userDetails.username);
          if (playerPoints) {
            setPlayerDetails({ name: playerPoints.player_name, username: playerPoints.employee_id, logo_url: playerPoints.player_photo_url });

          }
          const playerStats = result[0];
          if (playerStats) {
            if (from === 'previous') {
              setPrevPlayerKpiStats(playerStats.kpis.sort((a, b) => (a.kpi_id < b.kpi_id ? 1 : -1))
              );
            }
            else {
              setPlayerKpiStats(
                playerStats.kpis.sort((a, b) => (a.kpi_id < b.kpi_id ? 1 : -1))
              );
              setCopyPlayerKpiStats(playerStats.kpis);

            }


          }
        }
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch player stats', {
          variant: 'error',
          preventDuplicate: true
        });
      }
    });
  }



  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };



  const handleKeySearch = (searchedVal) => {
    const filteredRows = copyPlayerKpiStats.filter((row) => {
      return row.kpi_name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });

    if (searchedVal.length === 0) {
      setSearchResults([]);
    }
    else {
      setSearchResults(filteredRows);
    }
  };

  const handleChangeFilter = (season) => {
    if (season) {
      const currentMatch = seasons.find(obj => obj._id === season._id)
      const currentMatchIndex = new Date(currentMatch?.start_date).getMonth()
      const prevMatch = seasons.find(obj => new Date(obj?.start_date).getMonth() == currentMatchIndex - 1)
      // setCurrentMatch(currentMatch)
      setPrevMatch(prevMatch);
      setSelectedSeason(currentMatch);
      let setObj = { season };
      setFiltersData(prevState => ({ ...prevState, ...setObj }));

    }
    else {
      setSelectedSeason(null);
    }


  }

  const handleCardClick = (item) => {

    if (item.kpi_id && (item.kpi_name !== "CME" && item.kpi_name !== "Brand Exposure")) {
      historyPushWithSearch(`${path}/${item.kpi_id}`, history, history.location.search);

    }
  }

  const LeaderboardArray = ['#2 in Leaderboard', '#4 in Leaderboard', '#3 in Leaderboard', '#4 in Leaderboard']
  console.log('curenrrrr', currentMatch, prevMatch)
  return (
    <>
      {playerDetails.username ?
        <div style={{ flexGrow: 1, margin: '0px 10px 0px 0px' }} >
          <div style={{ display: 'flex' }}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                placeholder="Search by KPI"
                classes={{
                  root: classes.searchInputRoot,
                  input: classes.searchInputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => { handleKeySearch(e.target.value) }}
              />
            </div>
            <div>
              <FormControl className={classes.formRoot}>
                <Autocomplete
                  id="season"
                  size="small"
                  options={seasons}
                  getOptionLabel={(option) => option.name || ''}
                  value={selectedSeason ? selectedSeason : filtersData.season}
                  classes={{ inputRoot: classes.autocompleteRoot }}
                  renderInput={(params) => <TextField {...params} label="Select Month" variant="outlined" />}
                  onChange={(...args) => handleChangeFilter(args[1])}
                />

              </FormControl>
            </div>
          </div>
          <CustomCard>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
              className={classes.profileTabsRoot}
              classes={{ indicator: classes.tabIndicatorProfile }}
            >
              <Tab classes={{ root: classes.profileTabRoot }} label="KPI" />
              <Tab classes={{ root: classes.profileTabRoot }} label="Leaderboard" />
            </Tabs>
            {tabValue === 0 ? (
              <>
                {playerDetails.username ?
                  <>
                    <div className={classes.overallBoard}>
                      {playerKpiStats?.map((item, index) =>
                        (item.kpi_name === "Overall Ach%" || item.kpi_name === "Focus Product" || item.kpi_name === "New Product" || item.kpi_name === "Process Parameters") &&
                        <p className={'title'}>{`${item.kpi_name}`}</p>

                      )}
                    </div>
                    <div className={classes.overallBoard2}>
                      {playerKpiStats?.map((item, index) =>
                        (item.kpi_name === "Overall Ach%" || item.kpi_name === "Focus Product" || item.kpi_name === "New Product" || item.kpi_name === "Process Parameters") &&
                        <span className={'value'}>{`${getNumberFormat(userDetails?.countryCode, item.achievement)}%`}</span>
                      )}
                    </div>
                    <div className={classes.scoreCardsRoot}>

                      {
                        (searchResults.length > 0 ? searchResults :
                          playerKpiStats.slice(4, 17)).map((item, index) =>
                            <div key={index} className={'scoreCard'} onClick={() => handleCardClick(item)} style={{
                              cursor: (item.kpi_name === "CME" || item.kpi_name ===
                                "Brand Exposure") ? 'auto' : 'pointer'
                            }}>
                              <span className={'title'}>{`${item.kpi_name}`}
                                {prevMatch && prevPlayerKpiStats.filter(obj => obj.kpi_code === item.kpi_code).map((prevItem, index) =>
                                  item.based_on === 'ACTUALS' ?
                                    item.actual > prevItem.actual ?
                                      <img src={greenIndicator} />
                                      :
                                      item.actual < prevItem.actual ?

                                        <img src={redIndicator} />
                                        :
                                        <img src={amberIndicator} />
                                    :
                                    item.achievement > prevItem.achievement ?
                                      <img src={greenIndicator} />
                                      :
                                      item.achievement < prevItem.achievement ?

                                        <img src={redIndicator} />
                                        :

                                        <img src={amberIndicator} />

                                )}


                              </span>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                  <span className={'ach'}>{selectedSeason ? month[new Date(selectedSeason?.start_date).getMonth()] : month[new Date(filtersData?.season?.start_date).getMonth()]} :</span>

                                  {item.based_on === 'ACTUALS' ?
                                    item.unit_of_measurement === 'DURATION' ?
                                      <span className={'value'}>{formattedValue(userDetails, item.actual, item, true)}<sub>Sec</sub></span> :
                                      <span className={'value'}>{formattedValue(userDetails, item.actual, item, true)}</span> :

                                    <span className={'value'}>{`${getNumberFormat(userDetails?.countryCode, item.achievement)}%`}</span>
                                  }
                                </div>
                                {prevMatch && prevPlayerKpiStats.filter(obj => obj.kpi_code === item.kpi_code).map((prevItem, index) =>

                                  < div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className={'ach'}>{prevMatch ? month[new Date(prevMatch?.start_date).getMonth()] : month[new Date(filtersData?.season?.start_date).getMonth()]} :</span>
                                    {prevItem.based_on === 'ACTUALS' ?
                                      prevItem.unit_of_measurement === 'DURATION' ?
                                        <span className={'value'}>{formattedValue(userDetails, prevItem.actual, item, true)}<sub>Sec</sub></span> :

                                        <span className={'value'}>{formattedValue(userDetails, prevItem.actual, item, true)}</span> :

                                      <span className={'value'}>{`${getNumberFormat(userDetails?.countryCode, prevItem.achievement)}%`}</span>
                                    }                              </div>

                                )}
                              </div>

                            </div>
                          )
                      }
                    </div>
                  </>
                  : <p style={{ padding: '100px 24px', textAlign: 'center' }}>No Data Available</p>}
              </>) :
              (
                <>
                  {playerDetails.username ?
                    <>
                      <div className={classes.overallBoard}>
                        {playerKpiStats?.map((item, index) =>
                          (item.kpi_name === "Overall Ach%" || item.kpi_name === "Focus Product" || item.kpi_name === "New Product" || item.kpi_name === "Process Parameters") &&
                          <p className={'title'}>{`${item.kpi_name}`}</p>





                        )}


                      </div>
                      <div className={classes.overallBoard2}>
                        {LeaderboardArray?.map((item, index) =>
                          // (item.kpi_name === "Overall Ach%" || item.kpi_name === "Focus Product" || item.kpi_name === "New Product" || item.kpi_name === "Process Parameters") &&
                          <span className={'valueLeaderboadr'}>{item}</span>






                        )}


                      </div>
                      <div className={classes.leaderBoardCardsRoot}>
                        {
                          (searchResults.length > 0 ? searchResults :
                            playerKpiStats.slice(4, 17)).map((item, index) =>
                              <div key={index} className={'scoreCard'}>
                                <span className={'title'}>{`${item.kpi_name}`}
                                  <span className={'score'}>{`${getNumberFormat(userDetails?.countryCode, item.points, true)}pts`}</span>


                                </span>

                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '3px 12px 12px 12px', alignItems: 'center' }}>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className={'rankRoot'}><span className={'rank'}>{item.rank}<span style={{ fontSize: 12 }}>{item.rank === 1 ? 'st' : item.rank === 2 ? 'nd' : item.rank === 3 ? 'rd' : 'th'}</span></span><span><RankLabelLatest rank={item.rank} prevRank={item.previous_rank} /></span></span>
                                    {item.rank !== item.previous_rank &&
                                      item.rank < item.previous_rank ?
                                      <span className={'rankdiff'}>{`Increased by +${item.previous_rank - item.rank}`}</span>
                                      :
                                      item.rank > item.previous_rank ?
                                        <span className={'rankdiff'}>{`Decreased by -${item.rank - item.previous_rank}`}</span>
                                        :
                                        ''

                                    }

                                  </div>
                                  <div>
                                    {item.rank === 1 ?
                                      <img src={goldMedal} />
                                      :
                                      item.rank === 2 || ListItemAvatar.rank === 3 ?
                                        <img src={silverMedal} />
                                        :
                                        <img src={bronzeMedal} />
                                    }



                                  </div>
                                </div>


                              </div>
                            )
                        }
                      </div>
                    </>
                    :
                    <p style={{ padding: '100px 24px', textAlign: 'center' }}>No Data Available</p>}
                </>)
            }
          </CustomCard>
        </div >
        : < IllustrationView loading={false} style={{ marginTop: '50px' }} />
      }

    </>

  )
}

const mapStateToProps = (state) => ({
  userDetails: state.user
});



const mapDispatchToProps = dispatch => {
  return {
    getSeasonKpisList: (options) => dispatch(getSeasonKpisList(options)),
    setLoading: value => dispatch(setLoading(value)),
    getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options)),
    getPlayerCoreKpisStats: (options) => dispatch(getPlayerCoreKpisStats(options)),
    getRoundPlayerStatistics: (options) => dispatch(getRoundPlayerStatistics(options)),
    getMatchesList: (options) => dispatch(getMatchesList(options)),
    getPlayersPerformance: (options) => dispatch(getPlayersPerformance(options)),
    getSeasonsList: (options) => dispatch(getSeasonsList(options)),


  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPerformance));

