import React, { Component, useState, useEffect } from 'react';
// import { DayPilot, DayPilotCalendar, DayPilotNavigator } from "daypilot-pro-react";
import "./CalendarStyles.css";
import { getMeetingsList, openCustomDialog } from '../../ducks/meetings';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTheme, Popover } from '@material-ui/core';
import MeetDetails from './MeetDetails';

import moment from 'moment';
import WeekCalendar from 'react-week-calendar';

// import 'react-week-calendar/dist/style.less';
// or import css file
import 'react-week-calendar/dist/style.css';
import { getUTCFormattedDate } from '../../helpers';

const styles = {
    left: {
        float: "left",
        width: "220px"
    },
    main: {
        marginLeft: "220px"
    }
};

let calendar;

const CalendarComponent = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();

    const [openModal, setOpenModal] = useState({
        open: false,
        anchorEl: null,
    })
    const [eventsData, setEventsData] = useState({
        startDate: moment().weekday(1),
        data: []
    })
    const handleClose = () => {
        setOpenModal({ open: false, anchorEl: null });
    };

    const open = Boolean(openModal.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [calendarConfig, setCalendarConfig] = useState({
        viewType: "Week",
        durationBarVisible: false,
        timeRangeSelectedHandling: "Enabled",
        // onTimeRangeSelected: args => {
        //     let dp = calendar;
        //     DayPilot.Modal.prompt("Create a new event:", "Event 1").then(function (modal) {
        //         dp.clearSelection();
        //         if (!modal.result) { return; }
        //         dp.events.add(new DayPilot.Event({
        //             start: args.start,
        //             end: args.end,
        //             id: DayPilot.guid(),
        //             text: modal.result
        //         }));
        //     });
        // },
        eventDeleteHandling: "Update",
        onEventClick: args => {
            props.openCustomDialog({ open: true, children: <MeetDetails id={args.e.data.id} />, title: args.e.data.text });
            // let dp = calendar;
            // DayPilot.Modal.prompt("Update event text:", args.e.text()).then(function (modal) {
            //     if (!modal.result) { return; }
            //     args.e.data.text = modal.result;
            //     dp.events.update(args.e);
            // });
        },
    })

    useEffect(() => {
        console.log('meetingsList ', props.meetingsList);
        if (props.meetingsList && props.meetingsList.length > 0) {
            setEventsData(prevState => ({
                ...prevState,
                data: props.meetingsList.map(obj => ({
                    id: obj._id,
                    start: moment(obj.schedule[0].start_at),
                    end: moment(obj.schedule[0].end_at),
                    text: obj.title,
                    organizer: obj.participants && obj.participants.some(user => user.organizer) && obj.participants.find(user => user.organizer).username,
                    backColor: theme.palette.primary.main,
                    color: '#FFF'
                }))
            }))
            setCalendarConfig(prevState => ({
                ...prevState,
                events: props.meetingsList.map(obj => ({
                    id: obj._id,
                    start: obj.schedule[0].start_at,
                    end: obj.schedule[0].end_at,
                    text: obj.title,
                    organizer: obj.participants && obj.participants.some(user => user.organizer) && obj.participants.find(user => user.organizer).username,
                    backColor: theme.palette.primary.main
                }))
                // [
                //     {
                //         // id: 1,
                //         text: "Event 1",
                //         start: "2020-11-18T11:30:00",
                //         end: "2020-11-18T13:00:00"
                //     },
                //     {
                //         // id: 2,
                //         text: "Event 213",
                //         start: "2020-11-19T12:00:00",
                //         end: "2020-11-19T14:00:00",
                //         backColor: "#38761d"
                //     }
                // ]

            }))
        }
    }, [props.meetingsList]);

    useEffect(() => {
        // setCalendarConfig({
        //     startDate: "2020-11-23",
        //     events: [
        //         {
        //             // id: 1,
        //             text: "Event 1",
        //             start: "2020-11-23T11:30:00",
        //             end: "2020-11-23T13:00:00"
        //         },
        //         {
        //             // id: 2,
        //             text: "Event 2",
        //             start: "2020-11-24T12:00:00",
        //             end: "2020-11-24T14:00:00",
        //             backColor: "#38761d"
        //         }
        //     ]
        // });
        if (props.startDate) {
            setEventsData(prevState => ({ ...prevState, startDate: moment(getUTCFormattedDate(props.startDate)).weekday(1) }))
            // setCalendarConfig(prevState => ({ ...prevState, startDate: props.startDate }));
            props.getMeetingsList({ params: { from: getUTCFormattedDate(props.startDate), to: getUTCFormattedDate(props.endDate) }, onSuccessCB, onErrorCB });
        }
    }, [props.startDate]);

    const onSuccessCB = (result, headers) => {
        console.log("getMeetingsList ", headers, result);

        // setLoading(false);
    };

    const onErrorCB = error => {
        console.log(error);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed fetch Meetings.', {
            variant: 'error',
            preventDuplicate: true
        });
        // setLoading(false);
    };

    const { ...config } = calendarConfig;
    console.log(config, "config")
    return (
        <div>
            <WeekCalendar
                scaleUnit={30}
                cellHeight={28}
                // startTime={moment({ h: 0, m: 0 })}
                eventSpacing={10}
                // selectedIntervals={[{ start: moment(getUTCFormattedDate('2021-01-20T10:00:00.000Z')), end: moment(getUTCFormattedDate("2021-01-20T12:00:00.000Z")), backColor: 'red' }]}
                selectedIntervals={eventsData.data}
                dayFormat={'DD dddd'}
                firstDay={eventsData.startDate}
                headerCellComponent={(data, e, a) => {
                    // console.log('headerCellComponent ', new Date(data.date).getDay(), e, a);
                    return <div style={{ textAlign: 'left', opacity: moment(data.date).isSame(new Date(), 'day') ? 1 : 0.3 }}>
                        <span style={{ fontSize: 22, fontWeight: 600 }}>{moment(data.date).format('DD')}</span><br />
                        <span style={{ fontSize: 16 }}>{moment(data.date).format('dddd')}</span>
                    </div>
                }}
                useModal={false}
                onEventClick={(data, e) => props.openCustomDialog({ open: true, children: <MeetDetails id={data.id} occurrenceDate={data.start} />, title: data.text })}
                eventComponent={(data) => <div className="event" style={{ padding: 5, cursor: 'pointer', backgroundColor: data.backColor, color: data.color }} id={data.id}>
                    <span style={{ fontSize: 14, fontWeight: 600 }}>{data.text}</span><br />
                </div>}
            // onEventClick={(data, e) => console.log('onEventClick ', data, e)}
            // onIntervalUpdate={() => { }}
            />

            {/* <div style={styles.left}>
                    <DayPilotNavigator
                        selectMode={"week"}
                        showMonths={2}
                        skipMonths={2}
                        onTimeRangeSelected={args => {
                            this.setState({
                                startDate: args.day
                            });
                        }}
                    />
                </div> */}
            {/* <div style={styles.main}> */}
            {/* <DayPilotCalendar
                {...config}
                timeFormat="Clock24Hours"
                // bubble={(args) => console.log('bubble ', args)}
                bubble={new DayPilot.Bubble({
                    zIndex: 500,
                    onLoad: (args) => {
                        console.log('bubble ', args);
                        args.html = args.source.data.text
                    }
                })}

                // eventRightClickHandling="Bubble"
                // onEventRightClick={(args) => {
                //     let element = document.getElementById(args.e.data.id);
                //     console.log('onEventRightClick ', args, element);
                //     // args.e.div = <p>Heyyyy</p>
                //     new DayPilot.Bubble().showHtml('html', <p>HellooHeeeyyy</p>)
                //     setOpenModal({ open: true, anchorEl: element.target })
                // }}
                eventDeleteHandling="Disabled"
                eventMoveHandling="Disabled"
                // contextMenu={new DayPilot.Menu({

                //     items: [
                //         {
                //             html: "<span>Hello</span>",
                //             onClick: (args) => console.log('contextMenu ', args)

                //         }
                //     ]
                // })}
                headerDateFormat="d dddd"
                onBeforeHeaderDomAdd={(args) => {
                    console.log('onBeforeHeaderDomAdd ', args);
                    args.element = <div>
                        <span style={{ fontSize: 22, fontWeight: 600 }}>{args.column.name.split(' ').shift()}</span><br />
                        <span style={{ fontSize: 16 }}>{args.column.name.split(' ').pop()}</span>
                    </div>
                }}
                onBeforeEventDomAdd={(args) => {
                    console.log('onBeforeEventDomAdd ', args);
                    args.element = <div id={args.e.data.id}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>{args.e.data.text}</span><br />
                    </div>
                }}
                cellDuration="60"
                cellHeight="60"
                businessBeginsHour="10"
                weekStarts={1}
                headerHeight="80"
                // showHeader={true}
                ref={component => {
                    calendar = component && component.control;
                }}
            /> */}
            {/* </div> */}
            <Popover
                id={id}
                open={openModal.open}
                anchorEl={openModal.anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <p>The content of the Popover.</p>
            </Popover>
        </div>
    )
}

const mapStateToProps = (state) => ({
    meetingsList: state.meetings.meetingsList
});


const mapDispatchToProps = (dispatch) => {
    return {
        getMeetingsList: (options) => dispatch(getMeetingsList(options)),
        openCustomDialog: (options) => dispatch(openCustomDialog(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalendarComponent));