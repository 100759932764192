import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getLeaguesList, getSeasonsList, getDivisionsList } from '../../ducks/game';
import { setLoading } from "../../ducks/loading";
import FieldSet from '../GlobalComponents/FieldSet';
import { getSearchValue, setSearchQuery } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    filtersRoot: {
        // position: 'absolute',
        // top: 120,
        // width: '100%',

        // padding: '0px 35px'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 5px !important',
        fontSize: 13,
        backgroundColor: '#FFF'
        // width: 280
    },
    label: {
        fontSize: 13,
    },
    roundIconButton: {
        fontSize: 12,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: '0 4px',
        pointerEvents: 'auto !important'
    },
    roundIconButtonSelected: {
        color: 'black',
        backgroundColor: '#C4C4C4'
    },
    roundsLableStyle: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
        // color: '#0000008a'
    },
    avatarStyles: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 26,
        width: 26,
        border: '2px solid #FFF'
        // marginRight:5,
    },
    cardHeights: {
        maxWidth: '100%',
        // minHeight: '24vh',
        maxHeight: '60px',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            background: "#EEEEEE",
            borderBottom: `1px solid ${theme.palette.primary.main}`,

        }
        // minHeight: 150,
        // maxHeight: 153
    },
    vsStyles: {
        margin: '0px',
        fontSize: 12,
        // fontStyle: 'oblique',
        padding: '0px',
        // backgroundColor: '#c4c4c4',
        // borderRadius: 10,
        // height: 34,
        // width: 34,
        textAlign: 'center',
        color: '#0000008a'
    },
    teamName: {
        margin: 0,
        width: '75px',
        padding: 5,
        overflow: 'hidden',
        fontSize: '13px',
        // fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            // width: 100
        }
    },
    matchTitle: {
        fontSize: 14,
        margin: '5px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        color: '#000',
        [theme.breakpoints.down('xs')]: {
            margin: '5px 0',
            // display: 'inline-grid'
        }
    },
    matchView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #c4c4c48c',
        borderRadius: 30,
        cursor: 'pointer',
        maxWidth: 270,
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'black'
        }
    },
    selectedMatch: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    filedSetRootStyles: {
        padding: 20
    },
    legendRootStyles: {
        fontSize: 14
    }
}));


const SeasonFilters = ({ getLeaguesList, getSeasonsList, getDivisionsList, filtersData = {}, setFiltersData, defaultFilters = {}, setLoading, hasDivision = false, loading = true, history }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [divisions, setDivisions] = useState([]);

    useEffect(() => {
        getLeagues();
    }, []);

    useEffect(() => {
        if (filtersData.league) getSeasons(filtersData.league._id);
    }, [filtersData.league]);

    useEffect(() => {
        if (filtersData.season && hasDivision) getDivisions(filtersData.season._id)
    }, [filtersData.season]);


    const handleChangeAutocomplete = (data, field) => {
        setSearchQuery(history, { [field]: data._id });
        setFiltersData(prevState => ({ ...prevState, [field]: data }));
    };

    const getLeagues = () => {
        setLoading(true);
        getLeaguesList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setLeagues([...result]);
                const searchValue = getSearchValue(history, 'league');
                !searchValue && setSearchQuery(history, { league: result[0]?._id });
                setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0] }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getSeasons = (leagueId) => {
        setLoading(true);
        getSeasonsList({
            leagueId: leagueId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setSeasons([...result]);
                    const searchValue = getSearchValue(history, 'season');
                    !searchValue && setSearchQuery(history, { season: result[0]?._id });
                    setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0] }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                    setSeasons([]);
                    setDivisions([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getDivisions = (seasonId) => {
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                // if (result.length > 0) {
                setDivisions([...result]);
                // setFiltersData(prevState => ({ ...prevState, division: result[0] }));
                // }
                // else {
                //     setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                //     setDivisions([]);
                // }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    return (
        <div className={classes.root}>
            <FieldSet title={"Season Selection"} rootStyles={classes.filedSetRootStyles} legend={classes.legendRootStyles}>
                <Grid container spacing={2} >
                    <Grid item md={3} xs={6}>
                        <Autocomplete
                            id="List-of-leagues"
                            options={leagues}
                            getOptionLabel={(option) => option.name || ''}
                            value={filtersData?.league}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="League" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                            onChange={(...args) => handleChangeAutocomplete(args[1], 'league')}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Autocomplete
                            id="List-of-seasons"
                            options={seasons}
                            getOptionLabel={(option) => option.name || ''}
                            value={seasons.length && filtersData?.season}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Season" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                            onChange={(...args) => handleChangeAutocomplete(args[1], 'season')}
                        />
                    </Grid>
                    {
                        hasDivision &&
                        <Grid item md={3} xs={6}>
                            <Autocomplete
                                id="List-of-divisions"
                                options={divisions}
                                getOptionLabel={(option) => option.name || ''}
                                value={divisions.length && filtersData?.division}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                // disableClearable
                                renderInput={(params) => <TextField {...params} label="Division" variant="outlined" size="small" InputLabelProps={{ classes: { outlined: classes.label } }} />}
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'division')}
                            />
                        </Grid>
                    }

                </Grid>
            </FieldSet>
        </div >
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SeasonFilters));
