import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableHead, TableRow, MenuItem, Menu, IconButton, Checkbox, Button, fade, InputBase, FormControl, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import { downloadFiles, getCurrencyFormat, getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort, setSearchQuery } from "../../helpers";
import { getMyTeamLivePerformance, getPlayerRaceLiveScore, getRoundPlayerStatistics, getRoundTeamStatistics, getTeamRaceLiveScore, shareLiveScoreDialog } from "../../ducks/game";
import IllustrationView from "./IllustrationView";
import { ArrowDownward, ArrowForward, Email, Share } from "@material-ui/icons";
import { useRouteMatch, withRouter } from "react-router-dom";
import { GetDates } from ".";
import { toJpeg } from "html-to-image";
import PerformanceTableTemplate from "../GlobalComponents/PerformanceTableTemplate";
import PerformanceTableEmailTemplate from "../GlobalComponents/PerformanceTableEmailTemplate";
import './TablesLayout.css';
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { isRuleBasedOnActuals } from "./RaceSummary";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";
import { resizeImageToSquare } from "../ArenaComponents/cropImage";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import { ReactComponent as ReactArenaLogo } from "../../assets/icons/arena-1.svg";
import { openCreateArenaDialog } from '../../ducks/arena';
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import PerformanceTablePlayersEmailTemplate from "../GlobalComponents/PerformanceTablePlayersEmailTemplate";
import GetAppIcon from '@material-ui/icons/GetApp';
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";
import NotificationDialog from "../GlobalComponents/NotificationDialog";
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from "@material-ui/lab";








const useStyles = makeStyles((theme) => ({
    root: {
        // margin: '15px 0 0px',
        minHeight: 285,
        marginBottom: 20,
        border: '1px solid #DCDCDC',
        borderRadius: '4px 4px 0px 0px',
        background: 'white',
        paddingBottom: 10,
        // '& .material-table td div': {
        //     border: 'none !important'
        // }
        '& p': { margin: 0 },
        '& .table-title': {
            background: theme.palette.primary.light,
            padding: '10px 15px',
        },
        '& .table-footer': {
            background: 'white'
        }
    },
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12
    },
    title: {
        fontSize: 18,
        // margin: '25px 0 0',
        margin: 0,
    },
    button: {
        // height: 47,
        margin: '0 10px',
        '&:last-child': {
            marginRight: 0
        }
    },
    viewAllButton: {
        margin: '0 10px',
        color: 'blue', // #112386
        fontSize: 11,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    radioGroup: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .viewByText': {
            fontWeight: 600,
            marginRight: 10,
            fontSize: 12
        },
        '& label': {
            height: 30,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    radioLabel: {
        '& span': {
            fontSize: 12
        }
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        background: 'white'
    },
    shareOptionStyles: {
        fontSize: 14,
        '& #share-to-wall': {
            width: 20,
            height: 20,
            marginRight: 10,
            // '& path': {
            //     fill: theme.palette.primary.main
            // },
            // '& path[fill="white"]': {
            //     fill: '#FFF',
            // },
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& #download': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        },
        '& #Email': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        }
    },
    searchIcon: {
        color: '#0000004d',
        padding: '0px 8px 0px 25px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.35),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.5),
        },
        width: 'auto',
        maxWidth: 180,
        minWidth: 180,
    },
    inputRoot: {
        color: 'initial',
        height: '100%',
        width: 'inherit'
    },
    inputInput: {
        border: '1px solid #0000003a',
        borderRadius: '3px',
        height: 12,
        margin: '0 10px',
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        fontSize: '12px'
    },
    autocompleteRoot: {
        width: "200px",
        height: "33px",
        borderRadius: 3,
        overflow: "clip",
        whiteSpace: "nowrap",
        margin: '5px',
        "& .MuiAutocomplete-tagSizeSmall": {
            width: "65px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
        "& .MuiAutocomplete-input": {
            padding: '1.5px 4px !important'
        }


    },
}));

const PlayerSummaryDivisionWise = ({ key, index, limit, type = "", keycloak, seasonFormat, divisionObj = {}, filtersData, getRoundTeamStatistics, getTeamRaceLiveScore, shareLiveScoreDialog, setLoading, userDetails, loading, dialog, history, decimalView, openCreateArenaDialog, setDecimalView = () => { }, uploadLogDate, getPlayerRaceLiveScore, getRoundPlayerStatistics, isTeamRacePlayerSprint, roles, teams, Filter, handleChangeFilter, divId, seasonKpiPostions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [canDownload, setCanDownload] = useState(false);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [shareAnchorEl, setShareAnchorEl] = React.useState({
        el: null,
        arena: null
    });
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [selectedEmps, setSelectedEmps] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [checkColumn, setCheckColumn] = React.useState([{ isChecked: false }]);
    const [searchPlayer, setSearchPlayer] = useState([]);



    useEffect(() => {
        if (tableData) {
            setSelectedEmps(tableData.filter(ele => ele.isChecked))
        }

    }, [tableData]);


    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            cellStyle: { maxWidth: 200, width: 200 },
            templateRender: (rowData) => rowData.player?.name.toUpperCase(),
            render: (rowData) => rowData.player ? (
                <div style={{ fontSize: 12 }} className={classes.nameRoot}>
                    <AvatarComponent type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            hidden: filtersData.season?.season_format === 'PLAYER_RACE',
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--',
            templateRender: (rowData) => rowData.team ? (
                <div >
                    {rowData.team && rowData.team.name}
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap' }}>
                        {rowData.captain && <span><span style={{ fontWeight: 600 }}>C</span> : {rowData.captain}</span>}
                        {rowData.mentor && <span style={{ marginLeft: 10 }}><span style={{ fontWeight: 600 }}>M</span> : {rowData.mentor}</span>}
                    </div>
                </div>
            ) : '--',
        },
        {
            title: "Points",
            field: "totalPoints",
            editable: 'never',
            // cellStyle: { color: '#333' },
            templateRender: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>,
            render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
        }
    ]
    const isShareMenuOpen = Boolean(shareAnchorEl.el);
    // const [decimalView, setDecimalView] = useState(false);
    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');

    useEffect(() => {
        if (filtersData?.round) {
            if (index === 0) setLoading(true);
            getRoundPlayerStatistics({ roundId: filtersData?.round?._id, onSuccessCB: type === 'DETAILED' ? onSuccessCBDetailed : onSuccessCB, onErrorCB, params: { division_id: divisionObj?._id, limit: limit, page: 1, team_id: Filter[divId]?.team?._id, role_id: Filter[divId]?.role?.role_id } })
        }
    }, [filtersData?.round?._id]); //Filter[divId]


    useEffect(() => {
        if ((filtersData?.match || filtersData?.playermatch) && filtersData.season?.season_format !== 'HEAD_TO_HEAD') {
            setLoading(true);
            getPlayerRaceLiveScore({ matchId: filtersData?.season?.season_format === 'PLAYER_RACE' ? filtersData.match?._id : filtersData.playermatch?._id, onSuccessCB: type === 'DETAILED' ? onSuccessCBDetailed : onSuccessCB, onErrorCB, params: { division_id: divisionObj._id, limit: limit, page: 1, team_id: Filter[divId]?.team?._id, role_id: Filter[divId]?.role?.role_id } })
        }
    }, [filtersData?.match?._id, filtersData?.playermatch?._id]); //Filter[divId]

    useEffect(() => {
        if (resData.response?.length > 0) {
            if (type === 'DETAILED')
                onSuccessCBDetailed(resData.response, resData.headers, true);
            else
                onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCBDetailed = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_score || item.total_points || 0, selected: item.employee_id === userDetails?.username, hilighted: index === 0, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'detailed_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE') ? el.score || 0 : el.points || 0);

                    if (index === 0) {
                        const mapped_headers = el.detailed_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-target`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                // hidden: isRuleBasedOnActuals(el),
                                attribute: el.detailed_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('TARGET'),
                                // cellStyle: { color: '#333' },
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                                // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                            }, {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-actual`,
                                kpiname: `${el.kpi_name}`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                // cellStyle: { color: '#333' },
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('ACTUAL'),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                                // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                            }, {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-achieved`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                // cellStyle: { color: '#333' },
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 9 },
                                hidden: !mapped_headers.includes('ACHIEVEMENT'),
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_target`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                attribute: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 9 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_actual`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                // cellStyle: { color: '#333' },
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 9 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                                render: (rowData) =>
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            },
                            {
                                title: `${el.kpi_name}`,
                                field: `${el.kpi_id}-planned_achievement`,
                                editable: 'never',
                                emptyValue: '--',
                                align: 'center',
                                attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 9 },
                                hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                            },
                            {
                                title: `${el.kpi_name}`,//-Points
                                field: `${el.kpi_id}-points`,
                                editable: 'never',
                                emptyValue: '--',
                                hidden: !mapped_headers.includes('POINTS'),
                                attribute: el.detailed_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 9 },
                                align: 'center',
                                cellStyle: { whiteSpace: 'nowrap' },
                                render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                            }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData(finalResult);
                setTableData(finalResult);
                setSearchPlayer(finalResult);



            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setSearchPlayer([]);
                setTableData([]);


            }
        }
        setLoading(false);
    }


    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response.length) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points, selected: item.employee_id === userDetails?.username, hilighted: index === 0, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'summary_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE') ? el.score || 0 : el.points || 0);

                    if (index === 0) {
                        const mapped_headers = el.summary_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // hidden: isRuleBasedOnActuals(el),
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('TARGET'),
                            // cellStyle: { color: '#333' },
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                        }, {
                            title: `${el.kpi_code || el.kpi_name}`,
                            field: `${el.kpi_id}-actual`,
                            kpiname: `${el.kpi_name}`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 9 },
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData([...finalResult])
                setTableData(finalResult);
                setSearchPlayer(finalResult);
                setCheckColumn([{ isChecked: false }])




            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setSearchPlayer([]);
                setTableData([]);


            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
            setTableData([]);

        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
        // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
        //     variant: 'error',
        //     preventDuplicate: true
        // }); 
    }


    const SummaryTable = ({ data, checkColumn, handleChange, isAdmin }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            checkColumn={checkColumn}
            handleChange={handleChange}
            isAdmin={isAdmin}
            options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
            }}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {isAdmin &&

                                <TableCell rowSpan={2} style={{ maxWidth: 70, width: 70 }}>
                                    <Checkbox
                                        style={{
                                            width: 5,
                                            height: 5,
                                        }}
                                        checked={checkColumn[0].isChecked}
                                        onChange={() => handleChange('', 0, 'all')}
                                        color="primary"
                                        size="small"

                                    /></TableCell>
                            }
                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            <TableCell rowSpan={2}>{props.columns[1]?.title}</TableCell>
                            < TableCell rowSpan={2}>{props.columns[2]?.title}</TableCell>
                            {filtersData.season?.season_format !== 'PLAYER_RACE' && <TableCell rowSpan={2}>{props.columns[3]?.title}</TableCell>}
                            <TableCell rowSpan={2}>{props.columns[4]?.title}</TableCell>
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <>
                                        {type === 'DETAILED' ?
                                            <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                            </TableCell>
                                            :
                                            <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                            </TableCell>
                                        }
                                    </>
                                ))
                            }
                        </TableRow >
                        {
                            type === 'DETAILED' ?
                                <TableRow>
                                    {
                                        dynamicHeaders.map((obj) => (
                                            <>
                                                {
                                                    obj?.detailed_attributes.length ?
                                                        <>
                                                            {
                                                                obj?.detailed_attributes.map(ele => (
                                                                    ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                ))
                                                            }
                                                            {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                        </>
                                                        :
                                                        <>
                                                            {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                            {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }} >Actual</TableCell>}
                                                            {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                        </>
                                                }
                                            </>
                                        ))
                                    }
                                </TableRow>
                                :
                                <TableRow>
                                    {
                                        dynamicHeaders.map((obj) => (
                                            <>
                                                {
                                                    obj?.summary_attributes.length ?
                                                        <>
                                                            {
                                                                obj?.summary_attributes.map(ele => (
                                                                    ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                ))
                                                            }
                                                            {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                        </>
                                                        :
                                                        <>
                                                            {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                            {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }} >Actual</TableCell>}
                                                            {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                        </>
                                                }
                                            </>
                                        ))
                                    }
                                </TableRow>
                        }
                    </TableHead >
                )
            }}
        />
    );
    const handleViewAll = (divisionId) => {
        if (filtersData?.season?.divisions_enabled == true) {
            setSearchQuery(history, { division: divisionId });
        }
        history.push(`${path}/all-player-performances${history.location?.search}`);
    };

    const handleDetailedScore = (divisionId) => {
        if (filtersData?.season?.divisions_enabled == true) {
            setSearchQuery(history, { division: divisionId });
        }
        history.push(`${path}/detailed-player-performances${history.location?.search}`);
    };

    const handleShareScore = (id) => {
        shareLiveScoreDialog({ open: true, fixture: 'ROUND', notify: 'PLAYER', details: { seasonId: filtersData.season?._id, roundId: filtersData.round?._id, divisionId: divisionObj._id, imageId: id }, filtersData: filtersData });
        handleShareMenuClose();
    }

    const handleDownloadPerformance = async (id, title, type) => {
        console.log('heloooooooooo')
        await setCanDownload(true);
        toJpeg(document.getElementById(id))
            .then((dataUrl) => {
                setCanDownload(false);
                try {
                    const img = new Image();
                    img.onload = async function () {
                        let high = (this.width > this.height) ? 'width' : 'height';
                        const resultImage = (this.width > 0 && this.height > 0) ? await resizeImageToSquare(dataUrl, { width: this[high], height: this[high] }) : null;
                        if (resultImage) {
                            if (type === 'download') {
                                downloadFiles(resultImage, title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-'));
                                handleShareMenuClose()
                            }
                            else {
                                openCreateArenaDialog({
                                    open: true, arena: {}, stepType: 'crop', attachment: [
                                        { url: resultImage, mimeType: "IMAGE", width: 4, height: 4, fileName: "kpi_performance_HHD-49_kpi_ck_bk -2022-08-18T133448.437.png", fileType: "image/png" }],
                                    postDetails: {
                                        targetPage: "PLAYER_LIVE_SCORES",
                                        details: {
                                            league: filtersData?.league?._id,
                                            season: filtersData?.season?._id,
                                            round: filtersData?.season?.season_format === 'HEAD_TO_HEAD' ? filtersData?.round?._id : null,
                                            match: filtersData?.season?.season_format === 'TEAM_RACE' ? filtersData?.playermatch?._id : filtersData?.match?._id,
                                            division: divisionObj._id,


                                        }
                                    }
                                });
                                handleShareMenuClose()
                            }

                        }

                    }
                    img.src = dataUrl;
                    return img.onload();
                } catch (e) {
                }
                // downloadFiles(dataUrl, title.replace(".", ""));
            })
    }

    const handleShareMenuOpen = (event) => {
        setShareAnchorEl({ el: event.currentTarget, arena: null });
    };

    const handleShareMenuClose = () => {
        setShareAnchorEl({ el: null, arena: null });
    };
    const renderShareOptions = (

        <Menu
            anchorEl={shareAnchorEl.el}
            classes={{ paper: classes.profilePopup }}
            id="share-options-menu"
            keepMounted
            open={isShareMenuOpen}
            onClose={handleShareMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            style={{ zIndex: 1301 }}
        >
            <MenuItem onClick={() => handleDownloadPerformance(`player-perfomances-table-${divisionObj?.name}`, `Player_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : (isTeamRacePlayerSprint ? filtersData.playermatch?.title : filtersData.match?.title)}`, 'download')} className={classes.shareOptionStyles} ><GetAppIcon id="download" />Download</MenuItem>
            <MenuItem onClick={() => handleDownloadPerformance(`player-perfomances-table-${divisionObj?.name}`, `Player_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : (isTeamRacePlayerSprint ? filtersData.playermatch?.title : filtersData.match?.title)}`, 'share')} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-wall" />Share to Wall</MenuItem>
            <MenuItem onClick={() => handleShareScore(`player-perfomances-table1-${divisionObj?.name}`)} className={classes.shareOptionStyles}><MailOutlineOutlinedIcon id="Email" />Email </MenuItem>
        </Menu>
    );

    const handleCloseNotificationDialog = () => {
        setOpenConfirmDialog(false)
        setSelectedEmps([])
    }

    const handleChange = (row, rowIndex, type) => {
        if (type === 'close') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[0].isChecked = false
            tableDataDup.forEach(player => player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            setTableData([...tableDataDup])


        }
        else if (type === 'all') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[rowIndex].isChecked = !checkColumnDup[rowIndex].isChecked
            tableDataDup.forEach(player => checkColumnDup[rowIndex].isChecked === true ? player.isChecked = true : player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            setTableData([...tableDataDup])

        }
        else {
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            tableDataDup[rowIndex].isChecked = !tableDataDup[rowIndex].isChecked
            setTableData([...tableDataDup])
        }

    };

    const handleKeySearch = (searchedVal) => {
        const filteredRows = searchPlayer.filter((row) => {
            return row.player_name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });

        if (searchedVal.length === 0) {
            setData(searchPlayer);
            setTableData(searchPlayer);
        }
        else {
            setData(filteredRows);
            setTableData(filteredRows);
        }
    };

    const playerEmailTemplateColumns = columns.filter((obj, i) => filtersData?.season?.season_format === "PLAYER_RACE" ? obj.title !== 'Team' : true)
    return (
        <div>

            <>
                <div key={key} className={classes.root}>
                    <p className="table-title decimal-switch-root">
                        <p>
                            <span>{divisionObj?.name} </span><span>Player performance : <span>{filtersData.round?._id ? filtersData.round?.name : filtersData.season?.season_format !== 'PLAYER_RACE' ? filtersData.playermatch?.title : filtersData.match?.title}</span><GetDates filtersData={filtersData} /></span>
                        </p>
                        {index === 0 && <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />}
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 8, background: 'white', paddingTop: '15px', }}>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', contentVisibility: type === 'TOPFIVE' ? 'visible' : 'hidden' }}>
                            {(teams?.length > 0 && isAdmin) && <FormControl className={classes.formRoot}>
                                <Autocomplete
                                    id="teams"
                                    size="small"
                                    options={teams}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={Filter[divId]?.team}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => <TextField {...params} label="Teams" variant="outlined" />}
                                    onChange={(...args) => handleChangeFilter(args[1], 'team', divisionObj._id)}
                                />

                            </FormControl>}
                            {(roles?.length > 0 && isAdmin) && <FormControl className={classes.formRoot}>
                                <Autocomplete
                                    id="roles"
                                    size="small"
                                    options={roles}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={Filter[divId]?.role}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => <TextField {...params} label="Roles" variant="outlined" />}
                                    onChange={(...args) => handleChangeFilter(args[1], 'role', divisionObj._id)}
                                />

                            </FormControl>}
                        </div>
                        {
                            isAdmin && (data.length) > 0 ? <div className={classes.iconDiv}>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
                                    <Button variant="contained" className={classes.notification} disabled={tableData.filter(obj => obj.isChecked).length === 0} color="primary" onClick={() => setOpenConfirmDialog(true)}>
                                        Buzz
                                    </Button>
                                    {type !== 'TOPFIVE' && <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search Player.."
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={(e) => { handleKeySearch(e.target.value) }}
                                        />
                                    </div>}
                                </div>
                                {type === 'TOPFIVE' && data.length > 0 &&
                                    <IconButton onClick={(e) => handleShareMenuOpen(e,)} >
                                        <ShareSharpIcon fontSize="small" />
                                    </IconButton>}
                            </div> : null
                        }
                        {renderShareOptions}
                    </div >
                    {
                        (data.length === 0 && index === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                            <div style={{ background: 'white', padding: '0px 12px' }}>


                                <SummaryTable
                                    data={tableData}
                                    checkColumn={checkColumn}
                                    handleChange={handleChange}
                                    isAdmin={isAdmin}
                                />
                            </div>

                    }

                    {
                        type === 'TOPFIVE' && <>
                            {
                                shareAnchorEl.el &&
                                <>
                                    <PerformanceTableTemplate
                                        id={`player-perfomances-table-${divisionObj?.name}`}
                                        filtersData={filtersData}
                                        belongsTo='Players'
                                        columns={columns}
                                        dynamicColumns={dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')}
                                        data={data}
                                        render={(canDownload || dialog?.open)}
                                        decimalView={decimalView}
                                        uploadLogDate={uploadLogDate}
                                        isUploadLogRequired={true}
                                        divisionName={divisionObj?.name}



                                    />
                                    <PerformanceTableEmailTemplate
                                        id={`player-perfomances-table1-${divisionObj?.name}`}
                                        belongsTo="Players"
                                        filtersData={filtersData}
                                        columns={[...playerEmailTemplateColumns, ...dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')]}
                                        data={data}
                                        divisionName={divisionObj?.name}
                                        divisionObj={divisionObj}
                                        isUploadLogRequired={true}
                                        render={(canDownload || dialog?.open) && limit === 5}


                                    />
                                </>
                            }
                            {(data.length > 0 && limit === 5) &&
                                <p className="table-footer">
                                    <span className={classes.viewAllButton} onClick={() => handleDetailedScore(divisionObj._id)}>View Detailed score<ArrowForward /></span>
                                    <span className={classes.viewAllButton} onClick={() => handleViewAll(divisionObj._id)}>View all<ArrowForward /></span>
                                </p>
                            }
                        </>
                    }
                </div >
                {
                    openConfirmDialog && <NotificationDialog
                        open={openConfirmDialog}
                        handleClose={handleCloseNotificationDialog}
                        selectedEmps={selectedEmps}
                        setSelectedEmps={setSelectedEmps}
                        handleUncheck={handleChange}
                        from="LiveScores"
                    />


                }
            </>

        </div >
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundPlayerStatistics: options => dispatch(getRoundPlayerStatistics(options)),
        getTeamRaceLiveScore: options => dispatch(getTeamRaceLiveScore(options)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getMyTeamLivePerformance: (options) => dispatch(getMyTeamLivePerformance(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),
        getPlayerRaceLiveScore: (options) => dispatch(getPlayerRaceLiveScore(options)),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerSummaryDivisionWise));
