import { all, call, takeEvery, put } from "redux-saga/effects";
import Axios from "axios";
import { GET_TASK_DETAILS_API, GET_PROJECT_LIST_API, GET_KANBANBORAD_DATA_API, CREATE_TASK_API, UPDATE_TASK_API, CREATE_COMMENT_API, GET_COMMENTS_API, UPDATE_COMMENT_API, DETETE_COMMENT_API, GET_PROJECT_MEMBERS_API, CREATE_SUB_TASK_API, GET_SUB_TASK_API, DELETE_TASK_API, CREATE_WORK_LOG_API, UPDATE_WORK_LOG_API, GET_WORK_LOGS_LIST_API, DELETE_WORK_LOG_API, EXPORT_TASKS_STATUSASON_API, EXPORT_TASKS_AGING_API, GET_PROJECT_DETAILS_API,ADD_NEW_LANE_API,CHANGE_LANE_TITLE_API,CHANGE_LANE_POSITIONS_API,DELETE_STAGE_API } from '../../config'
import { GET_TASK_DETAILS, GET_PROJECT_LIST, GET_KANBANBOARD_DATA, CREATE_TASK, UPDATE_TASK, CREATE_COMMENT, GET_COMMENTS, UPDATE_COMMENT, DELETE_COMMENT, CREATE_SUB_TASK, CREATE_WORK_LOG, UPDATE_WORK_LOG, GET_WORK_LOGS_LIST, DELETE_WORK_LOG, DELETE_TASK, EXPORT_TASKS_STATUSASON, EXPORT_TASKS_AGING, GET_PROJECT_MEMBERS, GET_PROJECT_DETAILS,ADD_NEW_LANE,CHANGE_LANE_TITLE,CHANGE_LANE_POSITIONS,DELETE_STAGE } from '../../ducks/pms';
const token = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJPaHJEZkhEMkloVjUzcEROR0dRWW0xaTF3TW5KYkJqenJvbm1WdUMxR0ZrIn0.eyJleHAiOjE2MDg1NjQyNDksImlhdCI6MTYwODUyODI0OSwianRpIjoiNjAyNjY0YzAtYmJiMy00NTBhLWJiMDctNzZlYWVhM2Q0NGY2IiwiaXNzIjoiaHR0cDovL2Rldi5jaGFzZS53YXZlbGFicy5pbi9hdXRoL3JlYWxtcy9jaGFzZS1kZXYiLCJhdWQiOlsicmVhbG0tbWFuYWdlbWVudCIsImFjY291bnQiXSwic3ViIjoiYmJmMWUxNDgtYjhkYS00NWQwLTg4YjgtZDlkNDY1ODhkNWU4IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiY2hhdF93ZWJfYXBwIiwic2Vzc2lvbl9zdGF0ZSI6Ijg2YTkzNjJhLWIwOWYtNGFhYi04OTkzLTllOWIwZWIyOTA5MyIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsibWFuYWdlX2ZlYXR1cmVkX3Bvc3RzIiwibWFuYWdlX2VtcGxveWVlcyIsIm9mZmxpbmVfYWNjZXNzIiwiYWRtaW4iLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7InJlYWxtLW1hbmFnZW1lbnQiOnsicm9sZXMiOlsidmlldy1yZWFsbSIsInZpZXctaWRlbnRpdHktcHJvdmlkZXJzIiwibWFuYWdlLWlkZW50aXR5LXByb3ZpZGVycyIsImltcGVyc29uYXRpb24iLCJyZWFsbS1hZG1pbiIsImNyZWF0ZS1jbGllbnQiLCJtYW5hZ2UtdXNlcnMiLCJxdWVyeS1yZWFsbXMiLCJ2aWV3LWF1dGhvcml6YXRpb24iLCJxdWVyeS1jbGllbnRzIiwicXVlcnktdXNlcnMiLCJtYW5hZ2UtZXZlbnRzIiwibWFuYWdlLXJlYWxtIiwidmlldy1ldmVudHMiLCJ2aWV3LXVzZXJzIiwidmlldy1jbGllbnRzIiwibWFuYWdlLWF1dGhvcml6YXRpb24iLCJtYW5hZ2UtY2xpZW50cyIsInF1ZXJ5LWdyb3VwcyJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJlbWFpbCBwcm9maWxlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoicmFuYSBkIiwicHJlZmVycmVkX3VzZXJuYW1lIjoicmFuYSIsImdpdmVuX25hbWUiOiJyYW5hIGQiLCJlbWFpbCI6ImphZ2FkaXNoLnJlZGR5Mys2NkBnbWFpbC5jb20ifQ.NwYcUSh0MVe24YrKPMrHx5sQUzndCa65Sg7Bvc78TiNKCJk5M7r1pGPJb2baMqVxZY_DNq67KWsxAk81YuXKxh65pa0SxTT7b3OL0gS6RCdddmezQnAS21oUaX4S1rnb5zJ-ZHQHr7rmjfk4BxfmpBZVGRekJq2mVR1Dh8LuH9BfzohqNay7ely3BpCCY2S7CpIlBG1B1wbLt7drnMynUnXdMtjYy0pI_QCf-E_4s867aFZPd1CcUJgwTndrLEolEVrPhtS6UYt65TvvVhWcmMUGHtoRsrzez9I53MqOasHAM0VxQkpNNN2WNFELzHWchqUrPuJ8PPGQt4O7XDVsXg`

const headers = { Authorization: token };

const getKanbanBoardData = (project_key) => {
  return Axios.get(GET_KANBANBORAD_DATA_API(project_key), {})
}
// comment apis
const createComment = (reqObject) => {
  return Axios.post(CREATE_COMMENT_API, reqObject, {})
}
const updateComment = (reqObject, id) => {
  return Axios.put(UPDATE_COMMENT_API(id), reqObject, {})
}
const deleteComment = (id, requestObj) => {
  return Axios.delete(DETETE_COMMENT_API(id), { data: requestObj, })
}
const getComments = (taskId, params) => {
  return Axios.get(GET_COMMENTS_API(taskId), { params })
}
// get taskdetails 
const getProjectMembers = (id) => {
  return Axios.get(GET_PROJECT_MEMBERS_API(id), {})
}

const getTaskDetails = (params) => {
  return Axios.get(GET_TASK_DETAILS_API(params.task_id), {})
}
const createTask = (reqObject) => {
  return Axios.post(CREATE_TASK_API, reqObject, {})
}
const getProjectList = (params) => {
  return Axios.get(GET_PROJECT_LIST_API(), { params, })
}

const getProjectDetailsRequest = (projectId) => {
  return Axios.get(GET_PROJECT_DETAILS_API(projectId));
}

const updateTaskStatus = (id, reqObject) => {
  return Axios.put(UPDATE_TASK_API(id), reqObject, {})
}
const createSubTask = (reqObject) => {
  return Axios.post(CREATE_SUB_TASK_API, reqObject, {})
}
const getSubtask = (taskid) => {
  return Axios.get(GET_SUB_TASK_API(taskid), {})
}
const deleteTask = (id) => {
  return Axios.delete(DELETE_TASK_API(id), {})
}
// work log
const createWorkLog = (reqObject) => {
  return Axios.post(CREATE_WORK_LOG_API, reqObject, {})
}
const updateWorkLog = (reqObject, id) => {
  return Axios.put(UPDATE_WORK_LOG_API(id), reqObject, {})
}
const getWorkLogsList = (params) => {
  return Axios.get(GET_WORK_LOGS_LIST_API, { params })
}
const deleteWorkLog = (requestObj, id) => {
  return Axios.delete(DELETE_WORK_LOG_API(id), { data: requestObj })
}
const exportTaskAsOnStatus = (boardId) => {
  return Axios.get(EXPORT_TASKS_STATUSASON_API(boardId), { responseType: 'arraybuffer' })
}
const exportTaskAgeing = (boardId) => {
  return Axios.get(EXPORT_TASKS_AGING_API(boardId), { responseType: 'arraybuffer' })
}
//Board
const addNewLane = (reqObject,boardId) =>{
  return Axios.put(ADD_NEW_LANE_API(boardId), reqObject, {})
} 
const changeLaneTitle =(reqObject,boardId) =>{
  return Axios.put(CHANGE_LANE_TITLE_API(boardId), reqObject, {})
} 
const changeLanePostions =(reqObject,boardId) =>{
  return Axios.put(CHANGE_LANE_POSITIONS_API(boardId), reqObject, {})
} 
const deleteStage = (id, requestObj) => {
  return Axios.delete(DELETE_STAGE_API(id), { data: requestObj, })
}

function* WatchForKanbanBoardData() {
  yield takeEvery(GET_KANBANBOARD_DATA, function* getBoardData(action) {
    const { params, onSuccessCB, onErrorCB } = action.payload;
    try {
      let data = yield call(getKanbanBoardData, params.project_key);
      if (data) {
        console.log("WatchForKanbanBoard Data success cb", data.data)
        yield call(onSuccessCB, data.data);
      }
    }
    catch (e) {
      yield call(onErrorCB, e);
      console.log("WatchForKanbanBoard Data  catch err ", e);
    }
  })
}


// taskdetails  
function* watchForGetTasksDetails() {
  yield takeEvery(GET_TASK_DETAILS, function* getTaskDetailsdata(action) {
    const { params, onSuccessCB, onErrorCB } = action.payload;
    try {
      let data = yield call(getTaskDetails, params);
      if (data) {
        console.log("watchForGetTasksDetails success cb", data)
        yield call(onSuccessCB, data);
      }
    }
    catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForGetTasksDetails catch err ", e);
    }
  })
}

function* watchForGetProjectList() {
  yield takeEvery(GET_PROJECT_LIST, function* getProjectListData(action) {
    const { params, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data, headers } = yield call(getProjectList, params);
      if (data) {
        console.log("watchForGetProject List success cb", data, headers)
        yield call(onSuccessCB, data, headers);
      }
    }
    catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForGet Project List catch err ", e);
    }
  })
}

function* watchForGetProjectDetails() {
  yield takeEvery(GET_PROJECT_DETAILS, function* getProjectDetails(action) {
    const { id, getProjectDetailsSuceessCB, getProjectDetailsErrorCB } = action.payload;
    try {
      let { data, headers } = yield call(getProjectDetailsRequest, id);
      if (data) {
        console.log("watchForGetProjectDetails success");
        yield call(getProjectDetailsSuceessCB, data, headers);
      }
    }
    catch (e) {
      yield call(getProjectDetailsErrorCB, e);
      console.log("watchForGetProjectDetails catch err ", e);
    }
  })
}

function* watchForCreateTask() {
  yield takeEvery(CREATE_TASK, function* postProjectTask(action) {
    const { requestObj, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(createTask, requestObj);
      if (data) {
        console.log("watchForPost Create Task data success");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena catch err ", e);
    }
  });
}

function* watchForCreateSubTask() {
  yield takeEvery(CREATE_SUB_TASK, function* postProjectTask(action) {
    const { requestObj, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(createSubTask, requestObj);
      if (data) {
        console.log("watchFor Create sub Task data success");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor subtask catch err ", e);
    }
  });
}
function* watchForGetSubTask() {
  yield takeEvery(GET_SUB_TASK_API, function* getSubTaskData(action) {
    const { sub_Task_id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(getSubtask, sub_Task_id);
      if (data) {
        console.log("watchForPost Task details update success");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena Task details update catch err ", e);
    }
  });
}

//To update task details/status
function* watchForTaskUpdate() {
  yield takeEvery(UPDATE_TASK, function* updateTask(action) {
    const { requestObj, id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(updateTaskStatus, id, requestObj);
      if (data) {
        console.log("watchForPost Task details update success");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena Task details update catch err ", e);
    }
  });
}
function* watchForDeleteTask() {
  yield takeEvery(DELETE_TASK, function* deleteTaskInProject(action) {
    const { id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(deleteTask, id);
      if (data) {
        console.log("watchFor delete Task");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor delete Task  catch err ", e);
    }
  });
}

// comments

function* watchForCreateComment() {
  yield takeEvery(CREATE_COMMENT, function* createCommentData(action) {
    console.log(action.payload)
    const { requestObj, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(createComment, requestObj);
      if (data) {
        console.log("watchForPost Create comment data success");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena catch err ", e);
    }
  });
}
function* watchForCommentsLits() {
  yield takeEvery(GET_COMMENTS, function* getCommentsList(action) {
    console.log(action.payload)
    const { taskId, params, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data, headers } = yield call(getComments, taskId, params);
      if (data) {
        console.log("watchForPost Create comment data success");
        yield call(onSuccessCB, data, headers);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena catch err ", e);
    }
  });
}
function* watchForCommentUpdate() {
  yield takeEvery(UPDATE_COMMENT, function* updateCommentdata(action) {
    console.log("action payload-----", action.payload);
    const { requestObj, id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(updateComment, requestObj, id);
      if (data) {
        console.log("watchForPost comment update");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena update comment catch err ", e);
    }
  });
}
function* watchForCommentDelete() {
  yield takeEvery(DELETE_COMMENT, function* deleteCommentData(action) {
    const { _id, requestObj, onSuccessCB, onErrorCB } = action.payload;
    try {
      console.log("request Obje-------------", requestObj);
      let { data } = yield call(deleteComment, _id, requestObj);
      if (data) {
        console.log("watchForPost comment update");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena update comment catch err ", e);
    }
  });
}
function* watchForProjectAssignees() {
  yield takeEvery(GET_PROJECT_MEMBERS, function* watchProjectMembers(action) {
    const { id, memberDetails, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(getProjectMembers, id);
      if (data) {
        console.log("watchFor getProjectsAssignes comment update");
        let members = { members: data, memberDetails: memberDetails }
        yield call(onSuccessCB, members);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor getProjectsAssignes update comment catch err ", e);
    }
  });
}
function* watchForCreateWorkLog() {
  yield takeEvery(CREATE_WORK_LOG, function* createWorkLogData(action) {
    console.log(action.payload)
    const { requestObj, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(createWorkLog, requestObj);
      if (data) {
        console.log("watchForPost Create comment data success");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForPostArena catch err ", e);
    }
  });
}


function* watchForUpdateWorkLog() {
  yield takeEvery(UPDATE_WORK_LOG, function* updateWorkLogData(action) {
    console.log("action payload-----", action.payload);
    const { requestObj, id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(updateWorkLog, requestObj, id);
      if (data) {
        console.log("watchFor worklog update");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor update worklog catch err ", e);
    }
  });
}
function* watchForDeleteWorkLog() {
  yield takeEvery(DELETE_WORK_LOG, function* deleteWorkLogData(action) {
    const { requestObj, id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(deleteWorkLog, requestObj, id);
      if (data) {
        console.log("watchFor delete worlog");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor delete worklog  catch err ", e);
    }
  });
}

function* watchForWorkLogsList() {
  yield takeEvery(GET_WORK_LOGS_LIST, function* getWorkLogsListdata(action) {
    const { params, onSuccessCB, onErrorCB } = action.payload;
    console.log("action.payload---------", action.payload);
    try {
      let { data, headers } = yield call(getWorkLogsList, params);
      if (data) {
        console.log("watchFor get workLog list Data");
        yield call(onSuccessCB, data, headers);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor get workLog listdata  catch err ", e);
    }
  });
}
function* watchForExportTaskAsOnStatus() {
  yield takeEvery(EXPORT_TASKS_STATUSASON, function* getExportTaskAsOnStatus(action) {
    const { boardId, onSuccessCB, onErrorCB } = action.payload;
    try {
      let data = yield call(exportTaskAsOnStatus, boardId);
      if (data) {
        console.log("watchFor get export task status list Data");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor export task err ", e);
    }
  });
}
function* watchForExportTaskAgeing() {
  yield takeEvery(EXPORT_TASKS_AGING, function* getExportTaskAgeing(action) {
    const { boardId, onSuccessCB, onErrorCB } = action.payload;
    try {
      let data = yield call(exportTaskAgeing, boardId);
      if (data) {
        console.log("watchFor get export task ageing");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor export task ageing err ", e);
    }
  });
}
//Board
function* watchForAddNewLane() {
  yield takeEvery(ADD_NEW_LANE, function* addBoardNewLane(action) {
    console.log("action payload-----", action.payload);
    const { requestObj, id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(addNewLane, requestObj, id);
      if (data) {
        console.log("watchFor add new lane");
        yield call(onSuccessCB, data);
      }
    } catch (error) {
      yield call(onErrorCB, error);
      console.log("watchFor add new lane catch err ", error);
    }
  });
};

function* watchForChangeLaneTitle() {
  yield takeEvery(CHANGE_LANE_TITLE, function* laneTitleChange(action) {
    console.log("action payload-----", action.payload);
    const { requestObj, id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(changeLaneTitle, requestObj, id);
      if (data) {
        console.log("watchFor change lane title");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor change lane title catch err ", e);
    }
  });
}

function* watchForChangeLanePositions() {
  yield takeEvery(CHANGE_LANE_POSITIONS, function* lanePositionChange(action) {
    console.log("action payload-----", action.payload);
    const { requestObj, id, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(changeLanePostions, requestObj, id);
      if (data) {
        console.log("watchFor change lane positions");
        yield call(onSuccessCB, data);
      }
    } catch (error) {
      yield call(onErrorCB, error);
      console.log("watchFor change lane positions catch err ", error);
    }
  });
}
function* watchForDeleteStage() {
  yield takeEvery(DELETE_STAGE, function* deleteBoardStage(action) {
    const { id,reqObject, onSuccessCB, onErrorCB } = action.payload;
    console.log("callleddddddddddddddddddddddddddddddddddddddddddddd")
    try {
      let { data } = yield call(deleteStage, id,reqObject);
      if (data) {
        console.log("watchFor delete Stage");
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchFor delete Stage catch err ", e);
    }
  });
}

export default function* () {
  yield all([
    watchForGetTasksDetails(),
    watchForGetProjectList(),
    WatchForKanbanBoardData(),
    watchForCreateTask(),
    watchForTaskUpdate(),
    watchForCreateComment(),
    watchForCommentsLits(),
    watchForCommentUpdate(),
    watchForCommentDelete(),
    watchForProjectAssignees(),
    watchForCreateSubTask(),
    watchForGetSubTask(),
    watchForCreateWorkLog(),
    watchForUpdateWorkLog(),
    watchForDeleteWorkLog(),
    watchForWorkLogsList(),
    watchForDeleteTask(),
    watchForExportTaskAsOnStatus(),
    watchForExportTaskAgeing(),
    watchForGetProjectDetails(),
    watchForAddNewLane(),
    watchForChangeLaneTitle(),
    watchForChangeLanePositions(),
    watchForDeleteStage()
  ])
}