import firebase from "firebase";

export const firebaseConfig = {
    apiKey: "AIzaSyC3zYGnabiCOwGNBQBa7Oh4SSXwwJVL5ko",
    authDomain: "fir-89094.firebaseapp.com",
    databaseURL: "https://fir-89094.firebaseio.com",
    projectId: "fir-89094",
    storageBucket: "fir-89094.appspot.com",
    messagingSenderId: "1064004104953",
    appId: "1:1064004104953:web:ab569307c62c9b5cb72828",
    measurementId: "G-6X305799Y2"
}

if (firebase.messaging.isSupported()) {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }
}

export default firebase;




// <!-- TODO: Add SDKs for Firebase products that you want to use
//      https://firebase.google.com/docs/web/setup#available-libraries -->

// <script>
  // Your web app's Firebase configuration
//   var firebaseConfig = {
//     apiKey: "AIzaSyDxLjzXqYhBXzMsYRK5Lr6pOFu6sXWLjQM",
//     authDomain: "chase-web.firebaseapp.com",
//     databaseURL: "https://chase-web.firebaseio.com",
//     projectId: "chase-web",
//     storageBucket: "chase-web.appspot.com",
//     messagingSenderId: "842189162976",
//     appId: "1:842189162976:web:f8edbc4cae04c7d93fc385"
//   };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
// </script>