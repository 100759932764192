import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ArrowForward, ChevronLeft } from '@material-ui/icons';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getTeamBonusLeaderBoard, getTeamMatchLeaderBoard, getTeamPointsList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import CustomDataTable from '../../GlobalComponents/CustomDataTable';
import FieldSet from '../../GlobalComponents/FieldSet';
import { ReactComponent as ReactArenaLogo } from "../../../assets/icons/arena-1.svg";
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { divindentValue, getDurationWithUnit, getNumberFormat, numberFormater } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
    tabsRoot: {
        width: '100%',
        maxWidth: '100%',
        minHeight: 26,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
        marginTop: 20
    },
    tabRoot: {
        minHeight: 26,
        textTransform: 'capitalize',
        minWidth: 'auto',
        margin: '0px 8px',
        padding: '0px 15px',
        borderRadius: 13,
        backgroundColor: '#FFF',
        '&:first-child': {
            marginLeft: 0
        }
    },
    selectedTab: {
        color: '#FFF !important',
        backgroundColor: theme.palette.primary.main
    },
    disabledScrollBtn: {
        display: 'none'
    },

    legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    },
    filedSetRootStyles: {
        padding: '10px 20px 20px',
    },
    viewAllButton: {
        margin: '15px 2px 0px 0',
        color: 'blue', // #112386
        fontSize: 15,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 18,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    shareOptionStyles: {
        fontSize: 14,
        '& #share-to-wall': {
            width: 20,
            height: 20,
            marginRight: 10,
            // '& path': {
            //   fill: theme.palette.primary.main
            // },
            // '& path[fill="white"]': {
            //   fill: '#FFF',
            // },
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& #download': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },

        }
    },
}))

const LeaderboardTable = ({ columns = () => [], kpi = {}, kpis = [], seasonDetails, seasonDetailsViewAll, type, filtersData, viewType = "", handleViewChange, setLoading, loading, getTeamMatchLeaderBoard, getTeamBonusLeaderBoard, keycloak, handleDownloadClick, match, tabType, getTeamPointsList, userDetails, setViewPageType }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [selectedValue, setSelectedValue] = useState(null);
    const [data, setData] = useState([]);
    const [showViewAllButton, setShowViewAllButton] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (viewType === "PAGE" && setViewPageType) {
            setViewPageType('PAGE');
        }
    })
    useEffect(() => {
        if (viewType === "PAGE" && kpis.length > 0 && match.params?.id && !kpi?._id) {
            setSelectedValue(kpis.find(obj => obj._id === match.params.id));
        }
    }, [kpis]);

    useEffect(() => {
        if (viewType === "CARD" && kpi?._id) {
            setSelectedValue(kpi);
        }
    }, [kpi]);

    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');
    const [shareAnchorEl, setShareAnchorEl] = React.useState({
        el: null,
        arena: null
    });
    const isShareMenuOpen = Boolean(shareAnchorEl.el);

    const intlNumberFormat = (type, value) => {
        let props = {
            type: type,
            locale: seasonDetails?.country?.locale
        }
        if (seasonDetailsViewAll?.numaration !== 'DEFAULT') {
            const formatter = numberFormater(props, seasonDetailsViewAll?.country)
            const { divisor, label } = divindentValue(seasonDetailsViewAll?.numaration);
            return (divisor && label) ? `${props?.type === "currency" ? seasonDetailsViewAll?.country.currency_symbol : ''}${formatter.format(value / divisor)}${label}` : formatter.format(value);
        }
        else {
            return new Intl.NumberFormat(seasonDetailsViewAll ? seasonDetailsViewAll?.country?.locale : 'en-IN', {
                style: type === "currency" ? "currency" : undefined,
                notation: 'compact',
                currency: seasonDetailsViewAll ? seasonDetailsViewAll?.country?.currency_code : 'INR',
                maximumFractionDigits: 2
            }).format(value);
        }
    }


    useEffect(() => {
        if (selectedValue && filtersData.season && seasonDetailsViewAll) {
            if (type === 'core') {
                getTeamMatchData(filtersData.season._id, kpi._id);
            }
            else if (type === 'bonus') {
                getTeamBonusData(filtersData.season._id, kpi._id);
            }
            else if (type === 'division') {
                if (filtersData.season?.divisions_enabled) {
                    getTeamDivisionData(filtersData.season._id, kpi._id);
                }
                else {
                    setData([]);
                }
            }
        }
    }, [selectedValue, seasonDetailsViewAll._id]);

    const getTeamMatchData = (seasonId, kpiId) => {
        setLoading(true);
        getTeamMatchLeaderBoard({
            params: { season_id: seasonId, kpi_id: kpiId || match.params.id, limit: viewType === 'CARD' ? 5 : 5000, page: 1 },
            onSuccessCB: (res, headers) => {
                const result = res.map(item => ({
                    ...item, selected: item._id === headers?.my_team_id,
                    target: item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.target)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.target)
                            : `${getNumberFormat(userDetails?.countryCode, item.target)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.target, kpi.unit) : ''}`,
                    actual: item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.actual)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.actual)
                            : `${getNumberFormat(userDetails?.countryCode, item.actual)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.actual, kpi.unit) : ''}`,
                    pending_actual: item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.pending_actual)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, item.pending_actual)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.pending_actual, kpi.unit) : ''}`,
                    planned_target: item.planned_target ? item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.planned_target)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.planned_target)
                            : `${getNumberFormat(userDetails?.countryCode, item.planned_target)} ${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.planned_target, item.unit) : ''}`
                        : 0,
                    planned_actual: item.planned_actual ? item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.planned_actual)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.planned_actual)
                            : `${getNumberFormat(userDetails?.countryCode, item.planned_actual)} ${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.planned_actual, item.unit) : ''}`
                        : 0,
                    planned_pending_actual: item.planned_pending_actual ? item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.planned_pending_actual)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.planned_pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, item.planned_pending_actual)} ${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.planned_pending_actual, item.unit) : ''}`
                        : 0

                }));
                setData([...result]);
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                // setLoading(false);
                // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                //     variant: 'error',
                //     preventDuplicate: true
                // });
            }
        });
    }

    const getTeamBonusData = (seasonId, kpiId) => {
        setLoading(true);
        getTeamBonusLeaderBoard({
            params: { season_id: seasonId, kpi_id: kpiId || match.params.id, limit: viewType === 'CARD' ? 5 : 5000, page: 1 },
            onSuccessCB: (res, headers) => {
                const result = res.map(item => ({
                    ...item, selected: item._id === headers?.my_team_id,
                    target: item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.target)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.target)
                            : `${getNumberFormat(userDetails?.countryCode, item.target)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.target, kpi.unit) : ''}`,
                    actual: item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.actual)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.actual)
                            : `${getNumberFormat(userDetails?.countryCode, item.actual)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.actual, kpi.unit) : ''}`,
                    pending_actual: item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.pending_actual)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, item.pending_actual)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.pending_actual, kpi.unit) : ''}`,
                    planned_target: item.planned_target ? item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.planned_target)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.planned_target)
                            : `${getNumberFormat(userDetails?.countryCode, item.planned_target)} ${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.planned_target, item.unit) : ''}`
                        : 0,
                    planned_actual: item.planned_actual ? item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.planned_actual)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.planned_actual)
                            : `${getNumberFormat(userDetails?.countryCode, item.planned_actual)} ${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.planned_actual, item.unit) : ''}`
                        : 0,
                    planned_pending_actual: item.planned_pending_actual ? item.unit_of_measurement === 'CURRENCY' ? intlNumberFormat("currency", item.planned_pending_actual)
                        : item.unit_of_measurement === 'NUMBER' ? intlNumberFormat("number", item.planned_pending_actual)
                            : `${getNumberFormat(userDetails?.countryCode, item.planned_pending_actual)} ${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? getDurationWithUnit(item.planned_pending_actual, item.unit) : ''}`
                        : 0

                }));
                setData([...result]);
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                // console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv');
                // setLoading(false);
                // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                //     variant: 'error',
                //     preventDuplicate: true
                // });
            }
        })
    }

    const getTeamDivisionData = (seasonId, kpiId) => {
        setLoading(true);
        getTeamPointsList({
            params: { season_id: seasonId, division_id: kpiId || match.params.id, limit: viewType === 'CARD' ? 5 : 5000, page: 1 },
            onSuccessCB: (res, headers) => {
                const result = res.map(item => ({
                    ...item, selected: item._id === headers?.my_team_id,
                }));
                setData([...result]);
                setShowViewAllButton(parseInt(headers?.total_count) > 5 ? true : false);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const handleShareMenuClose = () => {
        setShareAnchorEl({ el: null, arena: null });
    };

    const handleShareMenuOpen = (event) => {
        setShareAnchorEl({ el: event.currentTarget, arena: null });
    };

    const renderShareOptions = (

        <Menu
            anchorEl={shareAnchorEl.el}
            classes={{ paper: classes.profilePopup }}
            id="share-options-menu"
            keepMounted
            open={isShareMenuOpen}
            onClose={handleShareMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            style={{ zIndex: 1301 }}
        >
            <MenuItem onClick={() => handleDownloadClick(kpi, data, 'download', handleShareMenuClose, tabType)} className={classes.shareOptionStyles} ><GetAppOutlinedIcon id="download" />Download</MenuItem>
            <MenuItem onClick={() => handleDownloadClick(kpi, data, 'share', handleShareMenuClose, tabType)} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-wall" />Share to Wall</MenuItem>
        </Menu>
    );

    return (
        <>
            {
                (viewType === 'CARD') ?
                    <FieldSet
                        title={selectedValue?.name}
                        rootStyles={classes.filedSetRootStyles}
                    >
                        {isAdmin && data.length > 0 ? <div className={classes.iconDiv}>
                            <IconButton onClick={(e) => handleShareMenuOpen(e,)} >
                                <ShareSharpIcon fontSize='small' />
                            </IconButton>
                        </div> : null}
                        {renderShareOptions}
                        <CustomDataTable columns={columns(kpi, data)} data={data} options={{ pagination: false }} rootProps={{ style: { marginTop: 20 } }} loading={loading} />
                        {showViewAllButton && <span className={classes.viewAllButton} onClick={() => handleViewChange('PAGE', kpi, type)}>View all<ArrowForward /></span>}
                    </FieldSet>
                    :
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                            <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleViewChange('CARD', null, type)}><ChevronLeft /></IconButton>
                            {<p style={{ margin: '0px 0px 0px 7px', color: '#000000c9', fontWeight: 600 }}>KPI : {selectedValue?.name?.toUpperCase()}</p>}
                        </div>
                        <CustomDataTable columns={columns(kpi, data)} data={data} rootProps={{ style: { marginTop: 10 } }} loading={loading} />
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    // loading: state.loading
    keycloak: state.keycloak,
    seasonDetailsViewAll: state.game.seasonDetails
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamMatchLeaderBoard: (options) => dispatch(getTeamMatchLeaderBoard(options)),
        getTeamBonusLeaderBoard: (options) => dispatch(getTeamBonusLeaderBoard(options)),
        getTeamPointsList: (options) => dispatch(getTeamPointsList(options)),

    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderboardTable));
