import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MatchSummary from './TeamsMatchSummary';
import { Avatar, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { groupBy } from 'underscore';
import { getLeaguesList, getSeasonsList, getMatchesList, getRoundsList, shareLiveScoreDialog, getDivisionsList, getSeasonHierarchiePlayersRoles } from '../../ducks/game';
import { formatDate, getAvatarText, getBase64FromUrl, getSearchValue, historyPushWithSearch, QueryTypes, setSearchQuery } from "../../helpers";
import classNames from 'classnames';
import { setLoading } from "../../ducks/loading";
import RoundsListView, { CustomListGroup, RoundsListGroup, roundStatuses } from "./RoundsListView";
import gameIllustration from '../../assets/images/game_Illustration.png';
import FieldSet from '../GlobalComponents/FieldSet';
import MatchListView from '../PerformanceStats/MatchListView';
import RaceSummary from './RaceSummary';
import IllustrationView from './IllustrationView';
import { TabBar } from '../Leaderboards/TabBar';
import RoundSummary from './RoundSummary';
import MaterialUITable from '../GlobalComponents/MaterialUITable';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { Chip } from '@material-ui/core';
import MatchDetails from './MatchDetails';
import TopFiveTeamSummary from './TopFiveTeamSummary';
import RoundPlayerSummaryViewAll from './RoundPlayerSummaryViewAll';
import RoundTeamSummaryViewAll from './RoundTeamSummaryViewAll';
import TopFivePlayerSummary from './TopFivePlayerSummary';
import RoundPlayerSummary from './RoundPlayerSummary';
import MyPerformanceCards from './MyPerformanceCards';
import RoundMatchesCard from './RoundMatchesCard';
import { FiberManualRecord } from '@material-ui/icons';
import TeamLiveScores from './TeamLiveScores';
import PlayerLiveScores from './PlayerLiveScores';
import { getSeasonPlayersRoles, getSeasonTeams } from '../../ducks/game';
import DetailedRolePlayerSummary from './version1/DetailedRolePlayerSummary';
import DetailedDivisionPlayerSummary from './version1/DetailedDivisionPlayerSummary';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fafafa',
        width: '100%',
        height: 'calc(100vh - 60px)',
        overflowY: 'auto',
        paddingBottom: 15,
        // display: 'flex',
        // [theme.breakpoints.down('xs')]: {
        //     maxHeight: 'calc(100vh - 80px)',
        //     maxWidth: `calc(100vw - 150px)`,
        //     padding: '20px 15px 0px',
        // },
    },
    filtersRoot: {
        marginBottom: 15,
        padding: '20px 50px 0px',
        width: 'calc(100% - 100px)'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 5px !important',
        fontSize: 13,
        backgroundColor: '#FFF'
    },
    label: {
        fontSize: 13,
    },
    roundIconButton: {
        fontSize: 12,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: '0 4px',
        pointerEvents: 'auto !important'
    },
    roundIconButtonSelected: {
        color: 'black',
        backgroundColor: '#C4C4C4'
    },
    roundsLableStyle: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
        // color: '#0000008a'
    },
    avatarStyles: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 26,
        width: 26,
        border: '2px solid #FFF'
        // marginRight:5,
    },
    cardHeights: {
        maxWidth: '100%',
        // minHeight: '24vh',
        maxHeight: '60px',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            background: "#EEEEEE",
            borderBottom: `1px solid ${theme.palette.primary.main}`,

        }
        // minHeight: 150,
        // maxHeight: 153
    },
    vsStyles: {
        margin: '0px',
        fontSize: 12,
        // fontStyle: 'oblique',
        padding: '0px',
        // backgroundColor: '#c4c4c4',
        // borderRadius: 10,
        // height: 34,
        // width: 34,
        textAlign: 'center',
        color: '#0000008a'
    },
    teamName: {
        margin: 0,
        width: '75px',
        padding: 5,
        overflow: 'hidden',
        fontSize: '13px',
        // fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            // width: 100
        }
    },
    matchTitle: {
        fontSize: 14,
        margin: '5px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        color: '#000',
        [theme.breakpoints.down('xs')]: {
            margin: '5px 0',
            // display: 'inline-grid'
        }
    },
    matchView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #c4c4c48c',
        borderRadius: 30,
        cursor: 'pointer',
        maxWidth: 270,
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'black'
        }
    },
    selectedMatch: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    filedSetRootStyles: {
        padding: '10px 20px 20px',
    },
    legendRootStyles: {
        fontSize: 14
    },
    viewDetailsButton: {
        // margin: '15px 2px 0px 0',
        color: 'blue', // #112386
        fontSize: 12,
        // float: 'right',
        cursor: 'pointer',
        // fontWeight: 600,
        '&:hover': {
            textDecoration: 'underline',
        },
        '& svg': {
            fontSize: 18,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    },
}));

export const matchStatusList = [
    {
        name: 'Fixture',
        id: 'fixtures'
    },
    {
        name: 'Live',
        id: 'live'
    },
    {
        name: 'Awaiting',
        id: 'completed'
    },
    {
        name: 'Closed',
        id: 'closed'
    },
];

const defaultFilters = {
    league: null,
    season: null,
    round: null,
    status: 'LIVE',
    match: null
};

export const GetDates = ({ filtersData }) => {
    return (
        <span style={{ marginLeft: 10 }}>
            {(filtersData.season?.season_format === 'HEAD_TO_HEAD' && filtersData.round) ?
                <span style={{ color: '#0000008a', fontSize: 9, fontWeight: 400 }}>({formatDate(filtersData.round?._id === 'ALL' ? filtersData.season?.start_date : filtersData.round?.start_date)} - {formatDate(filtersData.round?._id === 'ALL' ? filtersData.season?.end_date : filtersData.round?.end_date)})</span>
                :
                filtersData.match ?
                    <span style={{ color: '#0000008a', fontSize: 9, fontWeight: 400 }}>({formatDate(filtersData.match?._id === 'ALL' ? filtersData.season?.start_date : filtersData.match?.start_date)} - {formatDate(filtersData.match?._id === 'ALL' ? filtersData.season?.end_date : filtersData.match?.end_date)})</span>
                    : null}
        </span>
    )
}

const MenuOptions = [
    { label: 'Team', value: 'team', pathname: 'team' },
    { label: 'Player', value: 'player', pathname: 'player' }
];

const subTabs = [{ label: 'All', icon: '', path: 'all' }, { label: 'By Match', icon: '', path: 'byMatch' }]

const LiveScores = ({ filtersData, setFiltersData, setLoading, history, location, getSeasonPlayersRoles, getSeasonTeams, getDivisionsList, keycloak, getSeasonHierarchiePlayersRoles }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { path } = useRouteMatch();
    const [currentTab, setCurrentTab] = useState(0);
    const [viewType, setViewType] = useState('LIST');
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [step, setStep] = useState('');
    const [roles, setRoles] = useState([]);
    const [teams, setTeams] = useState([]);
    const [divisions, setDivisions] = useState([]);

    const isAdmin =
        keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes("admin");


    useEffect(() => {
        const searchValue = getSearchValue(history, 'match');
        !searchValue && setSearchQuery(history, { match: filtersData?.liveMatchType });
    }, [])

    useEffect(() => {
        if (filtersData.season?._id) {
            setLoading(true);
            getRolesList(filtersData.season?._id);
            getTeamsList(filtersData.season?._id);
            getDivisions(filtersData.season?._id)
        }

    }, [filtersData.season?._id]);

    useEffect(() => {
        // if ((filtersData.season?.season_format === 'PLAYER_RACE') && (!location.pathname.includes('detailed-player-performances') && !location.pathname.includes('all-player-performances') && !location.pathname.includes('detailed-scorecard'))) {
        //     historyPushWithSearch(`${path}/player`, history, location.search);
        //     setStep('player')


        // }
        // if ((filtersData.season?.season_format === 'TEAM_RACE' && (!filtersData.season?.auto_player_sprints)) && (!location.pathname.includes('detailed-team-performances') && !location.pathname.includes('all-team-performances') && !location.pathname.includes('detailed-scorecard'))) {
        //     historyPushWithSearch(`${path}/team`, history, location.search);
        //     setStep('team')


        // }
    }, [filtersData.season?._id]);


    const groupByValue = getSearchValue(history, 'viewBy');

    useEffect(() => {
        if (groupByValue) {
            setCurrentTab(groupByValue === 'match' ? 1 : 0);
        }
    }, [groupByValue])



    const getRolesList = (seasonId) => {
        setLoading(true);
        getSeasonHierarchiePlayersRoles({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const searchValue = getSearchValue(history, 'role');
                    // setFiltersData(prevState => ({ ...prevState, role: result?.find(obj => obj.role_id === searchValue) }));
                    setRoles([...result]);
                }
                else {
                    setRoles([]);
                }
                // setLoading(false);
            }, onErrorCB: (error) => {
                // setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const getTeamsList = (seasonId) => {
        setLoading(true);
        getSeasonTeams({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const searchValue = getSearchValue(history, 'team');
                    // setFiltersData(prevState => ({ ...prevState, team: result?.find(obj => obj._id === searchValue) }));
                    setTeams([...result]);
                }
                else {
                    setTeams([]);
                }
                // setLoading(false);
            }, onErrorCB: (error) => {
                // setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    // ----------get divisions------
    const getDivisions = (seasonId) => {
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                setDivisions([...result]);
                setFiltersData(prev => ({ ...prev, division: result[0]?._id }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Divisions', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const handleTabChange = (newValue) => {
        if (newValue.value !== step) {
            setStep(newValue.value)
            historyPushWithSearch(`${path}/${newValue.pathname}`, history, location.search);
        }
    }



    return (
        <div className={classes.root} style={{ height: 'auto', overflow: 'auto' }} id='livescoresRoot' >
            <div>
                {(filtersData.season?.season_format === 'HEAD_TO_HEAD') &&
                    <CustomListGroup data={MenuOptions} selectedItem={MenuOptions.find((option) => option.pathname === location.pathname.split('/')[4])} onClick={handleTabChange} />
                }
                <div id="leaderboards-data" className={classes.contentRoot} >
                    <Switch>
                        <Route path={`${path}/team`} >
                            <TeamLiveScores isAdmin={isAdmin} filtersData={filtersData} divisions={divisions} setFiltersData={setFiltersData} roles={roles} teams={teams} />
                        </Route>
                        <Route path={`${path}/player`} >
                            <PlayerLiveScores isAdmin={isAdmin} filtersData={filtersData} divisions={divisions} setFiltersData={setFiltersData} roles={roles} teams={teams} />
                        </Route>
                        {filtersData.season?.season_format === 'TEAM_RACE' ?
                            <Redirect to={{ pathname: `${path}/team`, search: location.search }} /> :
                            <Redirect to={{ pathname: `${path}/player`, search: location.search }} />
                        }
                    </Switch>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading,
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),
        getSeasonTeams: (options) => dispatch(getSeasonTeams(options)),
        getSeasonPlayersRoles: (options) => dispatch(getSeasonPlayersRoles(options)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options)),
        getSeasonHierarchiePlayersRoles: (options) => dispatch(getSeasonHierarchiePlayersRoles(options))

    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveScores));
