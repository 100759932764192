import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import UserProfileCard from '../UserProfileCard/UserProfileCard';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        justifyContent: 'space-between'
    },
    moreButton: {
        padding: 8
    }
}));

const ArenaPostHeader = ({ name = '', username = '', avatarUrl = '/', showMoreButton = false, onProfileClick = () => { }, follower = false, learningHub = false, featured = false, timestamp = '', handleMoreClick = () => { }, user, ...others }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} {...others}>
            <UserProfileCard
                name={name}
                username={username}
                avatarUrl={avatarUrl}
                onProfileClick={onProfileClick}
                follower={follower}
                featured={featured}
                learningHub={learningHub}
                timestamp={timestamp}
            />
            {
                showMoreButton &&
                <IconButton
                    onClick={handleMoreClick}
                    className={classes.moreButton}
                    aria-label="menu"
                >
                    <MoreVert />
                </IconButton>
            }
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(ArenaPostHeader);