import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getProfileUrl } from '../Arena';

const useStyles = makeStyles(() => ({
    userLink: {
        color: '#262626',
        fontWeight: 600,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
}));

const UserProfileLink = ({ name = '', username = '', onClick = () => history.push(getProfileUrl(username)), history, staticContext, ...others }) => {
    const classes = useStyles();
    return (
        <span {...others} className={classes.userLink} onClick={() => onClick()}>{name || username} </span>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

export default withRouter(UserProfileLink);