
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import {
  formatDate,
  getBase64WithUrl,
  decodeEnum,
  downloadFiles,
  getCurrencyFormat,
  getDurationWithUnit,
  getNumberFormat,
} from "../../helpers";
import { useSnackbar } from "notistack";
import moment from "moment";
import { setLoading } from "../../ducks/loading";
import {
  getMyTeamLivePerformance,
  getRoundTeamStatistics,
  getTeamRaceLiveScore,
  shareLiveScoreDialog,
  getUploadLogsInfo,
  getRoundPlayerStatistics, getPlayerRaceLiveScore
} from "../../ducks/game";
import TeamLogo from "../../assets/images/team_logo.png"
import GetCountryCurrencyFormat from "./GetCountryCurrencyFormat";




const useStyles = makeStyles((theme) => ({
  imageTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '& div:first-child': {
      marginRight: 10,
      // margin: '-3px -9px 0px 0px',
      textAlign: 'right',
      '& p': {
        margin: 0,
        '&:last-child': {
          fontSize: 11
        }
      }
    }
  },
  image1Title: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    margin: '-60px 0px',
    '& div:first-child': {
      textAlign: 'right',
      '& p': {
        margin: 0,
        padding: 0,
        '&:last-child': {
          fontSize: 11
        }
      }
    }
  },
  tableTitle: {
    fontSize: 30,
    margin: '80px 0 0px',
    '& p': {
      margin: 0,
      '&:last-child': {
        fontSize: 20,
        '& .divider': {
          margin: '0 10px',
          color: 'orange',
          fontSize: 40,
          fontWeight: 600,
          verticalAlign: 'middle'
        }
      }
    }
  },
  table: {
    width: '100%',

  },
}));

const PerformanceTablePlayersEmailTemplate = ({
  roundId,
  render = false,
  id = "",
  filtersData,
  data,
  columns,
  components,
  orgDetails,
  divisionName = "",
  belongsTo = "Players",
  getRoundTeamStatistics,
  getTeamRaceLiveScore,
  getRoundPlayerStatistics, getPlayerRaceLiveScore,
  shareLiveScoreDialog,
  setLoading,
  matchId = "",
  userDetails,
  loading,
  seasonFormat,
  dialog,
  history,
  theme,
  isUploadLogRequired,
  divisionObj,
  roleObj,
  getUploadLogsInfo,
}) => {
  const classes = useStyles();

  const [orgLogo, setOrgLogo] = useState("");

  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicHeaders, setDynamicHeaders] = useState([]);
  const [data_, setData_] = useState([]);

  const [resData, setResData] = useState({ response: [], headers: null });
  const [decimalView, setDecimalView] = useState(false);
  const [dataByDiv, setDataByDiv] = useState(null);
  const [uploadLogDate, setUploadLogDate] = useState(null)
  const [leagueLogo, setLeagueLogo] = useState("");
  const [teamLogo, setTeamLogo] = useState("")


  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (orgDetails?.logo_url) {
      getBase64WithUrl(orgDetails?.logo_url)
        .then((res) => {
          setOrgLogo(res);
        })
        .catch((err) => { });
    }
  }, [orgDetails?.logo_url]);

  useEffect(() => {
    if (filtersData?.league?.logo_url) {
      getBase64WithUrl(filtersData?.league?.logo_url).then((res) => {
        setLeagueLogo(res);
      }).catch((err) => console.log('league error', err.response));
    }
  }, [filtersData?.league?.logo_url]);


  useEffect(() => {
    if (filtersData?.season?.season_format === 'HEAD_TO_HEAD' && belongsTo == 'Players') {
      let roundId = filtersData?.round?._id;
      getRoundPlayerStatistics({ roundId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });
    }
    else {
      if (filtersData?.season?.season_format === 'TEAM_RACE' && belongsTo == 'Players' && filtersData?.playermatch?._id) {
        let matchId = filtersData?.playermatch?._id;
        if (roleObj) {
          getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, role_id: roleObj?.role_id } });

        }
        else if (divisionObj) {
          getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, division_id: divisionObj._id } });

        }
        else {
          getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });

        }
      }
      else {
        let matchId = filtersData?.match?._id;
        if (roleObj) {
          getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, role_id: roleObj?.role_id } });

        }
        else if (divisionObj) {
          getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1, division_id: divisionObj._id } });

        }
        else {
          getPlayerRaceLiveScore({ matchId, onSuccessCB, onErrorCB, params: { limit: 5000, page: 1 } });

        }
      }
    }
  }, [filtersData.round?._id, filtersData.match?._id, filtersData.playermatch?._id]);





  // Players Statistics

  const onSuccessCB = (response, headers, fromDecimalView) => {
    // if (!fromDecimalView) {
    //     setResData({ response, headers });
    // }
    if (response) {
      let headers = [];
      const finalResult = response.reduce((result, item, index) => {
        let obj = { ...item, selected: item.employee_id === userDetails?.username, totalPoints: item.total_points, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1), rank: item.position };
        // let totalPoints = 0;
        obj.kpis.forEach((el, i) => {
          obj[`${el.kpi_id}-actual`] = el.actual;
          obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
          obj[`${el.kpi_id}-unit`] = el.unit;
          obj[`${el.kpi_id}-points`] = el.points;
          // obj['totalPoints'] = totalPoints += el.points;

          if (index === 0) {
            headers.push(el);
          }
          if (index === 0) {
            obj.columns = [...(obj.columns || []), {
              title: `${el.kpi_code || el.kpi_name}`, //-Actual
              field: `${el.kpi_id}-actual`,
              editable: 'never',
              emptyValue: '--',
              cellStyle: { maxWidth: 150, minWidth: 100 },
              templateRender: (rowData) =>
                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                  <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                  // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                  : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                    <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`,
              render: (rowData) =>
                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                  <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                  // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                  : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                    <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                    : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
              // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
            }]
          }
        });
        return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role } }];
      }, []);
      if (finalResult.length > 0) {
        setDynamicColumns(finalResult[0].columns || []);
        setDynamicHeaders([...headers]);
        // if (filtersData.season?.divisions_enabled)
        //     setDataByDiv(groupBy(finalResult, 'division_name'));
        // else
        setData_(finalResult);
      }
      else {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData_([]);
        setDataByDiv(null);
      }
    }
    setLoading(false);
  }

  const onErrorCB = (error) => {
    setDynamicColumns([]);
    setDynamicHeaders([]);
    setData_([]);
    setDataByDiv(null);
    setLoading(false);
    // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
    //   variant: 'error',
    //   preventDuplicate: true
    // });
  }

  useEffect(() => {
    if (isUploadLogRequired && filtersData?.season?.season_format !== 'HEAD_TO_HEAD') {
      if (belongsTo === "Players" && filtersData?.season?.season_format === 'TEAM_RACE') {
        getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { round_id: "", match_id: filtersData.playermatch?._id, type: "PLAYER_SPRINT" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })

      }
      else {
        getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { round_id: "", match_id: filtersData.match?._id, type: "PLAYER_SPRINT" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })

      }


    }
    else if (isUploadLogRequired) {

      getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { round_id: filtersData?.round?._id, match_id: filtersData.match?._id, type: "PLAYER_ROUND" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })

    }


  }, [filtersData?.season?._id, filtersData?.round?._id, filtersData?.match?._id, filtersData?.playermatch?._id])

  const onSuccessUploadLogsInfo = (response) => {
    if (response) {
      setUploadLogDate(response)
    }

  }

  const onErrorUploadLogsInfo = (error) => {
    console.log('UploadLogs Error', error)
    setUploadLogDate(null)

  }


  const { background: { isGradiant, top, bottom, overAll }, heading, table_head_row, table_data_row } = theme;

  return (
    <div style={{ position: 'absolute', zIndex: -1, top: 0, left: 0 }}>
      {" "}
      {/* to hide position: 'absolute', zIndex: -1 */}
      <div id={id} className="custom-table" style={{ background: isGradiant ? `linear-gradient(0deg, ${bottom}, ${top})` : overAll, fontFamily: 'BarlowCondensed' }}>
        {filtersData?.league?.logo_url ?
          <>
            <div className={classes.imageTitle} style={{ color: heading?.color }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '5px' }}>
                <div><img src={leagueLogo} alt="logo" width="60" height="60" /></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <p style={{ fontSize: '25px' }}>{filtersData.season?.name}</p>
                  <p style={{ fontSize: '25px' }}>Season {filtersData.season?.no}</p>
                </div>
              </div>
            </div>
            <div className={classes.image1Title} >
              <div><img src={orgLogo} alt="logo" width="55" height="55" /></div>

            </div></>
          : <>
            <div className={classes.imageTitle} style={{ color: heading?.color }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '5px' }}>
                <div><img src={TeamLogo} alt="logo" width="60" height="60" /></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <p style={{ fontSize: '25px' }}>{filtersData.season?.name}</p>
                  <p style={{ fontSize: '25px' }}>Season {filtersData.season?.no}</p>
                </div>
              </div>
            </div>
            <div className={classes.image1Title} >
              <div><img src={orgLogo} alt="logo" width="55" height="55" /></div>

            </div></>
        }
        {
          render && (
            <>
              <div style={{ margin: '30px 30px 30px 30px' }}>
                <div className={classes.tableTitle} style={{ color: heading?.color, fontSize: heading?.size, fontFamily: 'BarlowCondensed' }}>
                  <p>
                    {filtersData?.season?.season_format === "HEAD_TO_HEAD"
                      ? `Round- ${belongsTo}`
                      : `Sprint- ${belongsTo}`}{" "}
                    {uploadLogDate && <span>Till - {formatDate(uploadLogDate?.created_at)}</span>}
                  </p>
                  <p style={{ fontSize: '30px' }}>
                    <span>
                      {filtersData.round && filtersData.season?.season_format === 'HEAD_TO_HEAD'
                        ? `Round - ${filtersData.round?.no}`
                        : filtersData.season?.season_format === 'TEAM_RACE' && belongsTo === 'Players'
                          ? filtersData.playermatch?.title
                          : filtersData.match?.title}
                    </span>
                    <span>
                      <span style={{ color: heading?.color }} className="divider">|</span>
                      {formatDate(
                        filtersData.round && filtersData.season?.season_format === 'HEAD_TO_HEAD'
                          ? filtersData.round?.start_date
                          : filtersData.season?.season_format === 'TEAM_RACE' && belongsTo === 'Players' ? filtersData.playermatch?.start_date : filtersData.match?.start_date
                      )}{" "}
                      -{" "}
                      {formatDate(
                        filtersData.round && filtersData.season?.season_format === 'HEAD_TO_HEAD'
                          ? filtersData.round?.end_date
                          : filtersData.season?.season_format === 'TEAM_RACE' && belongsTo === 'Players' ? filtersData.playermatch?.end_date : filtersData.match?.end_date
                      )}
                    </span>
                    {(filtersData.season?.divisions_enabled && divisionName) && <span><span style={{ color: heading?.color }} className="divider">|</span>{divisionName}</span>}

                  </p>
                </div>
                <table className={classes.table}>
                  {components?.Header ? (
                    [components.Header({ columns })]
                  ) : (
                    <thead>
                      <tr style={{ color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size }}>
                        {columns.map((col, i) =>
                        (
                          <th key={i}>{col.title}</th>
                        ))}
                      </tr>
                    </thead>
                  )}
                  <tbody>

                    {data_.map((row, rowIndex) => (
                      <tr key={rowIndex} style={{ color: table_data_row?.color || '#000', backgroundColor: table_data_row?.background_color, fontSize: table_data_row?.size }}>
                        {columns.map(
                          (col, i) =>

                          (
                            <td
                              style={{ ...col?.cellStyle }}
                              key={i}
                              align={col.align}
                            >
                              {col.templateRender
                                ? col.templateRender({
                                  ...row,
                                  tableData: { id: rowIndex },
                                })
                                : row[col.field]}
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orgDetails: state.common.orgDetails,
  theme: state.common.templateTheme
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(setLoading(value)),
    getRoundTeamStatistics: (options) => dispatch(getRoundTeamStatistics(options)),
    getTeamRaceLiveScore: (options) => dispatch(getTeamRaceLiveScore(options)),
    getRoundPlayerStatistics: options => dispatch(getRoundPlayerStatistics(options)),
    getPlayerRaceLiveScore: options => dispatch(getPlayerRaceLiveScore(options)),
    getUploadLogsInfo: (options) => dispatch(getUploadLogsInfo(options))

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceTablePlayersEmailTemplate);
