import { all, call, select, take, takeEvery, put, takeLatest } from "redux-saga/effects";
// import shortid from "shortid";


import {
  receivedMessage,
  SEND_MESSAGE,
  SEARCH_HISTORY,
  receivedHistory,
  MARK_RECEIVED,
  markAsReceived,
  MARK_DISPLAYED,
  updateMessageStatus
} from "../../ducks/messages";
// import { incrementUnreadCount } from "../../ducks/rooms";
import { showNotification } from '../../ducks/notification';

import { makeChannel } from "../_helpers";
import { updateRecentChat } from "../../ducks/chats";
import { getJsonMsgDetails } from "../../helpers";

const markReceived = (client, msg) => sendMarker(client, msg, 'received');
const markDisplayed = (client, msgList) => {
  if (msgList && msgList.length > 0) {
    msgList.forEach(message => {
      sendMarker(client, { ...message, marker: { type: 'markable' } }, 'displayed');
    });
  }
};
const markAcknowledged = (client, msg) => sendMarker(client, msg, 'acknowledged');

const isMarkable = (msg, client) => msg.marker && msg.marker.type === 'markable' && client.config.chatMarkers !== false;

const sendMarker = (client, msg, marker) => {
  if (isMarkable(msg, client)) {
    // const to = msg.type === 'groupchat' ? toBare(msg.from) : msg.from;

    // without resourse
    // const to = msg.type === 'groupchat' ? msg.from.split('/').shift() : msg.from.split('/').shift();

    const to = msg.type === 'groupchat' ? msg.from : msg.from;
    client.sendMessage({
      id: msg.id,
      body: msg.body,
      marker: {
        id: msg.id,
        type: marker
      },
      to,
      type: msg.type || 'chat'
    });
  }
}

function* watchForMessageStatus(client) {
  const messageStatus = makeChannel(client, {
    //   'marker:acknowledged': (emit, msg) => {
    //     emit(msg);
    //   },
    'marker:displayed': (emit, msg) => {
      emit(msg);
    },
    'marker:received': (emit, msg) => {
      emit(msg);
    },
    // 'receipt': (emit, msg) => {
    //   emit(msg);
    // },
  })

  yield takeEvery(messageStatus, function* eachMessageStatus(msg) {
    console.log('eachMessage Status ', msg);

    const { messageDisplayed, messageReceived, outgoing } = getJsonMsgDetails(msg, client.jid.split('@').shift());

    // const messageDisplayed = msg.marker && (msg.marker.type === 'displayed');
    // const messageReceived = msg.marker && (msg.marker.type === 'received');
    // const outgoing = (messageDisplayed || messageReceived) ? (msg.to).startsWith(client.jid.split('@').shift()) : (msg.from).startsWith(client.jid.split('@').shift()) ? true : false;

    // yield put(updateRecentChat({
    //   ...msg,
    //   messageStatus: messageDisplayed ? 'displayed' : messageReceived ? 'received' : 'sent',
    //   outgoing
    // }));

    yield put(
      receivedMessage({
        ...msg,
        time: (msg.delay && msg.delay.timestamp) || new Date(),
        messageStatus: messageDisplayed ? 'displayed' : messageReceived ? 'received' : 'sent',
        outgoing
      })
    );
  })
}

function* watchForMessages(client) {

  const messageChannel = makeChannel(client, {
    groupchat: (emit, msg) => {
      emit(msg);
    },
    chat: (emit, msg) => {
      emit(msg);
    },
    // 'message': (emit, msg) => {
    //   emit(msg);
    // },
    'muc:subject': (emit, msg) => {
      // msg.id = shortid.generate();
      emit(msg);
    }
  });

  yield takeEvery(messageChannel, function* eachMessage(msg) {
    console.log('eachMessage ', msg, client.jid);
    // Skip delayed messages (for now)
    // if(msg.delay) {
    //   return;
    // }

    // yield put(fetchNewChats(msg));

    const { messageDisplayed, messageReceived, outgoing } = getJsonMsgDetails(msg, client.jid.split('@').shift());

    // const messageDisplayed = msg.marker && (msg.marker.type === 'displayed');
    // const messageReceived = msg.marker && (msg.marker.type === 'received');
    // const outgoing = (messageDisplayed || messageReceived) ? (msg.to).startsWith(client.jid.split('@').shift()) : (msg.from).startsWith(client.jid.split('@').shift()) ? true : false;

    if (!msg.delay) {
      yield put(updateRecentChat({
        ...msg,
        messageStatus: messageDisplayed ? 'displayed' : messageReceived ? 'received' : 'sent',
        outgoing
      }));

      if (msg.type !== 'groupchat')
        yield put(markAsReceived(msg));

    }

    yield put(
      receivedMessage({
        ...msg,
        time: (msg.delay && msg.delay.timestamp) || new Date(),
        direction: msg.to.bare === client.jid.bare ? "incoming" : "outgoing",
        messageStatus: messageDisplayed ? 'displayed' : messageReceived ? 'received' : 'sent',
        outgoing
      })
    );

  });
}

function* sendMessages(client) {
  const message = makeChannel(client, {
    // chat: (emit, msg) => {
    //   emit(msg);
    // },
    'message:sent': (emit, msg) => {
      emit(msg);
    },
  });


  while (true) {
    const { payload } = yield take(SEND_MESSAGE);
    // console.log('client sendMessage payload ', payload);


    yield takeEvery(message, function* eachMsg(msg) {
      console.log('message chat eventName', msg);
      // let message = msg;
      if (msg.body && !msg.marker) {
        // yield call(payload.onMessageSentSuccess, payload.msg);
        // console.log('client sendMessage msg ', msg, payload.msg);
        yield call(payload.onMessageSentSuccess, msg);

      }

      if (msg && (msg.type === 'chat') && !msg.marker) {

        const { messageDisplayed, messageReceived, outgoing } = getJsonMsgDetails(msg, client.jid.split('@').shift());

        // const messageDisplayed = msg.marker && (msg.marker.type === 'displayed');
        // const messageReceived = msg.marker && (msg.marker.type === 'received');
        // const outgoing = (messageDisplayed || messageReceived) ? (msg.to).startsWith(client.jid.split('@').shift()) : (msg.from).startsWith(client.jid.split('@').shift()) ? true : false

        yield put(updateRecentChat({
          ...msg,
          isNewChat: false,
          from: msg.to,
          to: msg.from,
          delay: {
            timestamp: (msg.delay && msg.delay.timestamp) || new Date()
          },
          direction: msg.to.bare === client.jid.bare ? "incoming" : "outgoing",
          msgStatus: 'sent',
          messageStatus: 'sent',
          outgoing
        }));
        // yield put(fetchNewChats(msg));
        if (msg.type === 'chat') {
          yield put(
            receivedMessage({
              ...msg,
              // from: message.to,
              // to: message.from,
              time: (msg.delay && msg.delay.stamp) || new Date(),
              direction: msg.to.bare === client.jid.bare ? "incoming" : "outgoing",
              msgStatus: 'sent',
              messageStatus: 'sent',
              outgoing
            })
          );
        }
      }

    });


    try {
      yield call([client, client.sendMessage], payload.msg);
    } catch (e) {
      console.error("Could not send message", e);
      yield call(payload.onMessageSentFailed, e);
    }
  }
}

function fetchHistory(client, payload) {
  return client.searchHistory(payload);
}

function* searchHistory(client) {
  yield takeLatest(SEARCH_HISTORY, function* searchHistory(action) {
    try {
      let history = yield call(fetchHistory, client, action.payload)
      console.log('fetchHistory data success');
      yield put(receivedHistory(history, action.payload.with));
    } catch (e) {
      console.log('fetchHistory catch err ', e);
    }
  });
}

function* markMsgAsReceived(client) {
  yield takeLatest(MARK_RECEIVED, function* markMsgReceived(action) {
    console.log('eachMessage marking received ', action, client);
    try {
      // if(marker && marker.type === '')
      yield call(markReceived, client, { ...action.payload, marker: { type: 'markable' } });

      // yield put(updateMessageStatus(action.payload));
      // yield call(markDisplayed, client, { ...action.payload, marker: { type: 'markable' } });
      // yield call([client, client.markReceived], { ...action.payload, marker: { type: 'markable' } });
      // yield call([client, client.markDisplayed], { ...action.payload, marker: { type: 'markable' } });
    } catch (e) {
      console.log('eachMessage markMsgAsReceived catch err ', e);
    }
  });
}

function* markMsgAsDisplayed(client) {
  yield takeLatest(MARK_DISPLAYED, function* markMsgDisplayed(action) {
    console.log('eachMessage marking displayed eventName ', action, client);
    try {
      yield call(markDisplayed, client, action.payload);
      yield put(updateMessageStatus(action.payload));
    } catch (e) {
      console.log('eachMessage markMsgAsDisplayed catch err ', e);
    }
  });
}

export default function* (client) {
  yield all([watchForMessages(client), sendMessages(client), searchHistory(client), markMsgAsReceived(client), markMsgAsDisplayed(client), watchForMessageStatus(client)]);
}
