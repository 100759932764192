import React from "react";
import { useState, useEffect } from "react";
import MyKpiRanking from "./MyKpiRanking";
import MyTeamKpiRanking from "./MyTeamKpiRanking";
import { setLoading } from "../../../../ducks/loading";
import { getPlayerBonusKpiRanking, getPlayerCoreKpiRanking, getPlayerRoleRanking, getTeamBonusKpiRanking, getTeamCoreKpiRanking } from "../../../../ducks/game";
import qs from 'query-string';
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";



const RankingSection = ({ filtersData, teams, getPlayerCoreKpiRanking, getPlayerBonusKpiRanking, getPlayerRoleRanking, setLoading, history, getTeamBonusKpiRanking, getTeamCoreKpiRanking, kpiRanking }) => {
    const [playerCoreKpiRanking, setPlayerCoreKpiRanking] = useState([]);
    const [playerBonusKpiRanking, setPlayerBonusKpiRanking] = useState([]);
    const [playerRoleRanking, setPlayerRoleRanking] = useState([]);
    const [teamCoreKpiRanking, setTeamCoreKpiRanking] = useState([]);
    const [teamBonusKpiRanking, setTeamBonusKpiRanking] = useState([]);
    const [teamObj, setTeamObj] = useState([]);
    const qryparams = qs.parse(history.location.search);



    useEffect(() => {
        if (filtersData?.season?._id && teams.length > 0 && kpiRanking === 'Team') {
            let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
            let teamId = teams[0]?._id
            setTeamObj(teams[0]);
            getTeamCoreKpiRanking({ seasonId, onSuccessTeamCoreCB, onErrorTeamCoreCB, params: { team_id: teamId, limit: 5, page: 1 } })
            getTeamBonusKpiRanking({ seasonId, onSuccessTeamBonusCB, onErrorTeamBonusCB, params: { team_id: teamId, limit: 5, page: 1 } })

        }
    }, [filtersData?.season?._id]);

    useEffect(() => {
        if (filtersData?.season?._id && teamObj?._id && kpiRanking === 'Team') {
            let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
            getTeamCoreKpiRanking({ seasonId, onSuccessTeamCoreCB, onErrorTeamCoreCB, params: { team_id: teamObj?._id ? teamObj?._id : teams[0]?._id, limit: 5, page: 1 } })
            getTeamBonusKpiRanking({ seasonId, onSuccessTeamBonusCB, onErrorTeamBonusCB, params: { team_id: teamObj?._id ? teamObj?._id : teams[0]?._id, limit: 5, page: 1 } })
        }
    }, [teamObj?._id]);

    const onSuccessTeamCoreCB = (response) => {
        if (response.length) {
            setTeamCoreKpiRanking(response)
        }
        else {
            setTeamCoreKpiRanking([]);
        }
        setLoading(false);
    };

    const onErrorTeamCoreCB = (error) => {
        setTeamCoreKpiRanking([]);
        setLoading(false);
    }

    const onSuccessTeamBonusCB = (response) => {
        if (response.length) {
            setTeamBonusKpiRanking(response)
        }
        else {
            setTeamBonusKpiRanking([]);
        }
        setLoading(false);
    };

    const onErrorTeamBonusCB = (error) => {
        setTeamBonusKpiRanking([]);
        setLoading(false);
    }

    useEffect(() => {
        if (filtersData?.season?._id && kpiRanking === 'Player') {
            let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
            getPlayerCoreKpiRanking({ seasonId, onSuccessPlayerCoreCB, onErrorPlayerCoreCB, params: { limit: 5, page: 1 } })
        }
    }, [filtersData?.season?._id]);

    useEffect(() => {
        if (filtersData?.season?._id && kpiRanking === 'Player') {
            let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
            getPlayerBonusKpiRanking({ seasonId, onSuccessPlayerBonusCB, onErrorPlayerBonusCB, params: { limit: 5, page: 1 } })
        }
    }, [filtersData?.season?._id]);

    useEffect(() => {
        if (filtersData?.season?._id && kpiRanking === 'Player') {
            let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
            getPlayerRoleRanking({ seasonId, onSuccessPlayerRoleCB, onErrorPlayerRoleCB, params: { limit: 5, page: 1 } })
        }
    }, [filtersData?.season?._id]);

    const onSuccessPlayerCoreCB = (response) => {
        if (response.length) {
            setPlayerCoreKpiRanking(response)
        }
        else {
            setPlayerCoreKpiRanking([]);
        }
        setLoading(false);
    };

    const onErrorPlayerCoreCB = (error) => {
        setPlayerCoreKpiRanking([]);
        setLoading(false);
    }

    const onSuccessPlayerBonusCB = (response) => {
        if (response.length) {
            setPlayerBonusKpiRanking(response)
        }
        else {
            setPlayerBonusKpiRanking([]);
        }
        setLoading(false);
    };

    const onErrorPlayerBonusCB = (error) => {
        setPlayerBonusKpiRanking([]);
        setLoading(false);
    }

    const onSuccessPlayerRoleCB = (response) => {
        if (response.length) {
            const roleRes = response.map(ele => {
                return { ...ele, kpi_name: ele.role_name }
            })
            setPlayerRoleRanking(roleRes)
        }
        else {
            setPlayerRoleRanking([]);
        }
        setLoading(false);
    };

    const onErrorPlayerRoleCB = (error) => {
        setPlayerRoleRanking([]);
        setLoading(false);
    }


    return (
        <>
            {(playerCoreKpiRanking.length > 0 || playerBonusKpiRanking.length > 0 || playerRoleRanking.length > 0) &&
                <MyKpiRanking filtersData={filtersData} playerCoreKpiRanking={playerCoreKpiRanking} playerBonusKpiRanking={playerBonusKpiRanking} playerRoleRanking={playerRoleRanking} />}
            {(teams?.length > 0) &&
                <MyTeamKpiRanking filtersData={filtersData} teams={teams} teamCoreKpiRanking={teamCoreKpiRanking} teamBonusKpiRanking={teamBonusKpiRanking} teamObj={teamObj} setTeamObj={setTeamObj} />}
        </>

    )

}



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayerCoreKpiRanking: (options) => dispatch(getPlayerCoreKpiRanking(options)),
        getPlayerBonusKpiRanking: (options) => dispatch(getPlayerBonusKpiRanking(options)),
        getPlayerRoleRanking: (options) => dispatch(getPlayerRoleRanking(options)),
        getTeamCoreKpiRanking: (options) => dispatch(getTeamCoreKpiRanking(options)),
        getTeamBonusKpiRanking: (options) => dispatch(getTeamBonusKpiRanking(options)),


    };
};
export default withRouter(connect(null, mapDispatchToProps)(RankingSection));

