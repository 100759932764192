
import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { connect } from "react-redux";
import { handleImageViewer } from "../../ducks/imageViewer";

function Viewer({ imagesState, handleImageViewer }) {
    const [currentImage, setCurrentImage] = useState(0);

    // useEffect(() => {
    //     console.log('Viewer imagesState ', imagesState);
    // }, [imagesState]);

    const closeImageViewer = () => {
        handleImageViewer({ open: false, images: [], currentImage: 0 })
        setCurrentImage(0);
    };

    return (
        <div>
            {(imagesState && imagesState.open) && (
                <ImageViewer
                    src={imagesState.images}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                        zIndex: 1201
                    }}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    imagesState: state.imageViewer
});

const mapDispatchToProps = (dispatch) => {
    return {
        handleImageViewer: (options) => dispatch(handleImageViewer(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);