import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Box, makeStyles, Paper, styled, Tooltip } from '@material-ui/core';
import { getTeamPointsList } from '../../ducks/game';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import CustomDataTable from '../GlobalComponents/CustomDataTable';
import { Info } from '@material-ui/icons';
import { getNumberFormat } from '../../helpers';
import RankLabel from '../PointsTables/RankLabel';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { Grid } from "@material-ui/core";




const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 15,
        color: '#262626',
        fontWeight: '600',
        margin: '0',
        paddingBottom: '8px'
    },
    card: {
        '&.MuiPaper-elevation1': {
            padding: '0px !important'
        }
    }


}))

export const commonColumnOptions = { setCellProps: () => ({ align: 'center' }), setCellHeaderProps: () => ({ align: 'center' }) };
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const TeamProfileSummary = ({ filtersData, seasonId, teamId, getTeamPointsList, userDetails }) => {
    const classes = useStyles();
    const [seasonWisePoints, setSeasonWisePoints] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { params } = useRouteMatch();
    const [decimalView, setDecimalView] = useState(false);




    useEffect(() => {
        if (seasonId && teamId) {
            getTeamPoints(seasonId, teamId)
        }
    }, [seasonId, teamId])

    const getTeamPoints = (seasonId, teamId, divIndex) => {
        setLoading(true);
        getTeamPointsList({
            params: { season_id: seasonId, team_id: teamId, limit: 5000, page: 1 },
            onSuccessCB: (res, headers) => {
                const result = res.map(item => ({ ...item, selected: item._id === headers?.my_team_id }));

                setSeasonWisePoints([...result]);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }


    const card1Columns = [{ name: 'played', label: 'Played' }, { name: 'won', label: 'Won' }, { name: 'lost', label: 'Lost' }, { name: 'draw', label: 'Drawn' }]
    const card2Columns = [{ name: "match_points", label: "Match/Core Pts" }, { name: "bonus_points", label: "Bonus Pts" },
    { name: "totalpoints", label: "Total Pts" }]


    return (
        <div>
            <div style={{ width: '99%' }}>
                <p className={classes.title}>Summary</p>
                {/* <CustomDataTable columns={columns(seasonWisePoints)} data={seasonWisePoints} loading={loading} /> */}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} >
                        {filtersData.season?.season_format === 'HEAD_TO_HEAD' &&
                            <Grid item xs={6} >
                                <Item className={classes.card}>
                                    <div className="card-table">
                                        <table>
                                            <thead>
                                                <tr style={{ minWidth: 200 }}>{card1Columns.map((col, i) => <th key={i}>{col.label}</th>)}</tr>
                                            </thead>
                                            <tbody>
                                                {seasonWisePoints.map((data) => (
                                                    <tr
                                                    >
                                                        {
                                                            card1Columns.map((col) => (
                                                                <td>
                                                                    {getNumberFormat(userDetails?.countryCode, data[col.name], decimalView)}

                                                                </td>

                                                            ))
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Item>
                            </Grid>}

                        <Grid item xs={6}>
                            <Item className={classes.card}>
                                <div className="card-table">
                                    <table>
                                        <thead>
                                            <tr style={{ minWidth: 200 }}>{card2Columns.map((col, i) => <th style={{ padding: '8px 10px' }} key={i}>{col.label}</th>)}</tr>
                                        </thead>
                                        <tbody>
                                            {seasonWisePoints.map((data) => (
                                                <tr
                                                >
                                                    {
                                                        card2Columns.map((col) => (
                                                            <td style={{ padding: '8px 10px' }}>
                                                                {getNumberFormat(userDetails?.countryCode, data[col.name], decimalView)}

                                                            </td>

                                                        ))
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </Item>

                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div >
    )
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getTeamPointsList: (options) => dispatch(getTeamPointsList(options)),
    }
};
export default withRouter(connect(mapStateToProps,
    mapDispatchToProps
)(TeamProfileSummary));