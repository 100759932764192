import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemAvatar, Avatar, Badge, withStyles, Backdrop, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { getAvatarText, statusList } from '../../helpers';
import { getContactList } from '../../selectors';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TeamIcon from '../../assets/images/team-icon-4.svg';
import { setCurrentChat } from '../../ducks/chats';
import { getContactSearchResult, setGlobalSearchQuery } from '../../ducks/search';
import ContactSearch from './ContactSearch';
import { getContacts } from '../../ducks/contacts';

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);

const templateStyles = makeStyles((theme) => ({
    listItemContent: {
        width: '100%',
        display: 'flex',
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
        margin: '10px 0px'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '7px',
        fontSize: 13,
        color: '#58595B'
    },
    secondarytext: {
        margin: 0,
        fontSize: '11px',
        color: '#838383',
        textTransform: 'capitalize'
    },
    statusBadge: {
        border: '2px solid #fff',
        height: '14px',
        width: '14px',
        borderRadius: '7px'
    },
    statusColor: {
        backgroundColor: props => props.statusColor,
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    }
}));

export const ContactTemplate = ({ contactDetails, setCurrentChat, handleChat, setGlobalSearchQuery, fromGlobalSearch }) => {
    const classes = templateStyles({ statusColor: statusList.find(item => item.show === contactDetails.show).color });

    const handleClick = (user) => {
        setCurrentChat({ chatId: user.chat_id, targetName: user.name, presence: user.show, photo_url: user.photo_url, type: user.type });
        handleChat({ ...user, targetJid: user.chat_id });
        setGlobalSearchQuery('');
        // if (chatType === 'group') {
        //     history.push(`/chats/group/${chatId}`)
        // }
        // else {
        //     history.push(`/chats/${chatId}`);
        // }
    }

    return (
        <ListItem key={contactDetails.chat_id} button selected={contactDetails.isCurrent} onClick={() => handleClick(contactDetails)} >
            {!fromGlobalSearch &&
                <ListItemAvatar>
                    {(contactDetails.type === 'group') ?
                        <Badge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={<SmallAvatar alt="Remy Sharp" src={TeamIcon} />}
                        >
                            <Avatar
                                alt={contactDetails.name}
                                src={(contactDetails.logo_url && contactDetails.logo_url.length) ? contactDetails.logo_url : '/'}
                                classes={{ colorDefault: classes.avatarStyles }}
                            >
                                {contactDetails.name && (contactDetails.name.includes('@') ? contactDetails.name.substring(0, 1) : getAvatarText(contactDetails.name))}
                            </Avatar>
                        </Badge>
                        :
                        <Badge color="primary" classes={{ badge: classes.statusBadge, colorPrimary: classes.statusColor }} overlap={'circle'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} variant="dot">
                            <Avatar
                                classes={{ colorDefault: classes.avatarStyles }}
                                alt={contactDetails.name}
                                src={(contactDetails.photo_url && contactDetails.photo_url.length) ? contactDetails.photo_url : contactDetails.photoUrl ? contactDetails.photoUrl : '/'}
                            >{contactDetails.name && getAvatarText(contactDetails.name)}</Avatar>
                        </Badge>
                    }
                </ListItemAvatar>
            }
            <div className={classes.listItemContent}>
                <div>
                    <p className={classes.primaryText}>{contactDetails.name}</p>
                    <p className={classes.secondarytext}>{contactDetails.type === 'group' ? (contactDetails.description ? contactDetails.description : '') : contactDetails.about ? (contactDetails.about.length > 35 ? contactDetails.about.substring(0, 35) + '...' : contactDetails.about) : (contactDetails.show === 'dnd' ? 'Busy' : contactDetails.show)}</p>
                </div>
            </div>
        </ListItem>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        // fontFamily: 'Roboto'
    },
    scroller: {
        maxHeight: '70vh',
        minHeight: '70vh',
        position: 'relative'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
}));

const ContactList = (props) => {
    console.log('ContactList props');
    const classes = useStyles();
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        setLoading(false);
    }, [props.contacts]);

    useEffect(() => {
        console.log('ContactList');
        setLoading(true);
        props.getContacts();
    }, []);

    const onSearchSuccessCB = (result) => {
        setLoading(false);
        setSearchResults(result);
    }

    const onSearchErrorCB = (result) => {
        setLoading(false);
        setSearchResults([]);
    }

    const handleSearch = (query) => {
        setQuery(query);
        if (query.length > 0) {
            setLoading(true);
            props.getContactSearchResult({ query, onSearchSuccessCB, onSearchErrorCB });
        }
        else {
            setSearchResults([]);
        }
    }

    return (
        <div className={classes.root}>
            <ContactSearch handleSearch={handleSearch} />
            <div className={classNames("scroller", classes.scroller)}>

                <List dense className={classes.root}>
                    {
                        (query.length > 0) ?
                            searchResults.map((contact, index) => {
                                return (
                                    <ContactTemplate key={index} contactDetails={contact} {...props} setCurrentChat={props.setCurrentChat} />
                                );
                            })
                            :
                            props.contacts.map((contact, index) => {
                                return (
                                    <ContactTemplate key={index} contactDetails={contact} {...props} setCurrentChat={props.setCurrentChat} />
                                );
                            })
                    }
                </List>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    contacts: getContactList(state.contacts, state.client.jid),
    searchResult: state.search.contacts
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setCurrentChat: (options) => dispatch(setCurrentChat(options)),
        getContactSearchResult: (options) => dispatch(getContactSearchResult(options)),
        setGlobalSearchQuery: (options) => dispatch(setGlobalSearchQuery(options)),
        getContacts: (options) => dispatch(getContacts(options))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);