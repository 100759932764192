import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItemAvatar, ListItem, Badge, Avatar, SvgIcon, Backdrop, CircularProgress, TextField, IconButton, TextareaAutosize, Button } from '@material-ui/core';
import { EditOutlined, CameraAltOutlined, Check } from '@material-ui/icons';
import moment from 'moment';

// import TeamIcon from '../../../assets/images/team-icon-4.svg';
import TeamIcon from '../../../assets/icons/team-icon-6.png';
import { ReactComponent as profileIcon } from '../../../assets/icons/user-1.svg';
import { ReactComponent as aboutIcon } from '../../../assets/icons/information.svg';
import { ReactComponent as phoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as deptIcon } from '../../../assets/icons/department.svg';
import { ReactComponent as designationIcon } from '../../../assets/icons/business.svg'
import { getProfileDetails, getTeamDetails, updateTeamDetails, uploadFilesToS3 } from '../../../ducks/common';
import logo from '../../../assets/images/logo-2.svg';
import { updateProfile } from '../../../ducks/user';
import { setCurrentChat } from '../../../ducks/chats';
import { getAvatarText, statusList } from '../../../helpers';
import { withRouter } from 'react-router-dom';

const useTemplateStyles = makeStyles((theme) => ({
    statusColor: {
        backgroundColor: props => props.statusColor,
        // backgroundColor: '#bdbdbd',
    },
    statusBadge: {
        border: '2px solid #fff',
        height: '14px',
        width: '14px',
        borderRadius: '7px'
    },
    listItem: {
        paddingTop: 8,
        paddingBottom: 8
    },
    listItemContent: {
        width: '100%',
        display: 'flex',
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
        margin: '10px 0px'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        fontSize: 13,
        color: '#58595B'
    },
    timestamp: {
        margin: 0,
        paddingBottom: '5px',
        fontSize: '12px',
        color: theme.palette.primary.main
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    }
}))

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '24px 0 10px',
        position: 'relative'
    },
    imageRoot: {
        width: '90%',
        height: 145,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main,
        position: 'relative',
        border: `1px solid ${theme.palette.secondary.main}`,
        '&:hover': {
            cursor: 'pointer',
            '& div': {
                visibility: 'visible'
            }
        }
    },
    image: {
        width: 'auto',
        height: '100%',
        maxWidth: '100%'
    },
    content: {
        margin: '0 0 20px'
    },
    contentItem: {
        margin: '18px 20px'
    },
    lable: {
        fontSize: '13px',
        color: '#58595B',
        fontWeight: '600',
        margin: 0,
        marginBottom: '5px'
    },
    value: {
        color: '#838383',
        fontSize: '11px',
        margin: 0,
    },
    membersLable: {
        fontSize: '13px',
        color: '#58595B',
        fontWeight: '600',
        margin: '0 20px 5px',
    },
    profileContent: {
        margin: '20px 0'
    },
    listItemProfile: {
        padding: '15px 22px 12px 25px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    nonEditableLable: {
        fontSize: 12,
        color: '#838383',
        margin: 0,
        marginBottom: '6px'
    },
    profileValue: {
        color: '#58595B',
        fontSize: 13,
        fontWeight: '600',
        margin: 0,
    },
    listItemProfileContent: {
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-all'
    },
    iconRoot: {
        marginRight: 25
    },
    svgIcon: {
        overflow: 'inherit',
        fontSize: '1.5rem',
        '& path': {
            fill: theme.palette.primary.main,
            // color: theme.palette.primary.main,
            // stroke: theme.palette.primary.main,
        }
    },
    avatarSize: {
        width: '122px',
        height: '122px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    inputStyles: {
        fontSize: 13,
        fontWeight: 600,
        color: '#58595B'
    },
    editableNameRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    hiddenIconRoot: {
        visibility: 'hidden',
        width: '100%',
        height: '145px',
        position: 'absolute',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0000004d',
        textAlign: 'center',
        color: '#fff',
        '& p': {
            margin: 0,
            fontSize: 12
        }
    },
    camIcon: {
        color: '#fff'
    },
    textareaStyles: {
        border: 'none',
        fontSize: 11,
        color: '#838383',
        outline: 'none',
        width: 200,
        resize: 'none',
        padding: '0 0 7px'
    },
    textareaStylesName: {
        border: 'none',
        fontSize: 13,
        color: '#58595B',
        fontWeight: 600,
        outline: 'none',
        width: 200,
        resize: 'none',
        padding: '0 0 5px'
    },
    buttonRoot: {
        textAlign: 'center',
        marginTop: 10
    },
    button: {
        width: '70%',
        '&:hover': {
            boxShadow: 'none'
        },
    },
}));

const ContactTemplate = ({ person, index, handleSetCurrentChat }) => {
    const classes = useTemplateStyles({ statusColor: statusList.find(item => item.show === person.show).color });
    return (
        <ListItem key={index} className={classes.listItem} button onClick={() => handleSetCurrentChat(person)} >
            <ListItemAvatar>
                <Badge color="primary" classes={{ badge: classes.statusBadge, colorPrimary: classes.statusColor }} overlap={'circle'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} variant="dot">
                    <Avatar
                        classes={{ colorDefault: classes.avatarStyles }}
                        alt={person.name}
                        src={(person.photo_url && person.photo_url.length) ? person.photo_url : '/'}
                    >{getAvatarText(person.name)}</Avatar>
                </Badge>
            </ListItemAvatar>
            <div className={classes.listItemContent}>
                <div>
                    <p className={classes.primaryText}>{person.name || person.user}</p>
                </div>
                <div>
                    <p className={classes.timestamp}>{person.role === 'PLAYER' ? 'Player' : person.role === 'CAPTAIN' ? 'Captain' : person.role === 'MENTOR' ? 'Mentor' : 'Vice Captain'}</p>
                </div>
            </div>
        </ListItem>
    )
}

const ProfileInfo = ({ userProfile, currentChat, getProfileDetails, getTeamDetails, updateTeamDetails, uploadFilesToS3, setCurrentChat, handleChat, client, history }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [focusedName, setFocusedName] = React.useState(false);
    const [focusedDescription, setFocusedDescription] = React.useState(false);
    const [details, setDetails] = React.useState({
        name: "",
        photoUrl: null,
        description: ''
    });
    const nameRef = useRef();
    const descriptionRef = useRef();

    useEffect(() => {
        if (userProfile.type === 'group') {
            setDetails({ name: userProfile.name, description: userProfile.description, photoUrl: userProfile.logo_url ? userProfile.logo_url : userProfile.image_url });
        }
        else {
            setDetails({
                name: "",
                photoUrl: null,
                description: ''
            })
        }
        setLoading(false);
    }, [userProfile])

    useEffect(() => {
        if (currentChat.type === 'group') {
            let teamId = currentChat && currentChat.targetJid.split('@')[0];
            if (teamId !== userProfile.team_id) {
                setLoading(true);
                getTeamDetails({ teamId, onFetchSuccessCB, onFetchErrorCB });
            }
            else {
                setLoading(false);
            }
        }
        else {
            let username = currentChat && currentChat.targetJid.split('@')[0];
            if (username !== userProfile.username) {
                setLoading(true);
                getProfileDetails({ username, onFetchSuccessCB, onFetchErrorCB });
            }
            else {
                setLoading(false);
            }
        }

    }, [currentChat.targetJid]);

    const onFetchSuccessCB = (result) => {
        setLoading(false);
    }

    const onFetchErrorCB = (e) => {
        setLoading(false);
        setDetails({
            name: "",
            photoUrl: null,
            description: ''
        })
    }

    // useEffect(() => {
    //     let username = props.currentChat && props.currentChat.targetJid.split('@')[0];
    //     if(username !== props.userProfile.username){
    //         props.getProfileDetails(username);
    //     }
    // }, []);

    const handleSetCurrentChat = (user) => {
        if (client.jid !== user.chat_id) {
            setCurrentChat({ chatId: user.chat_id, targetName: user.name, presence: user.show, photo_url: user.photo_url, type: user.type });
            handleChat({ ...user, targetJid: user.chat_id });
        }
    }

    const handleEditClick = (fieldType) => {
        switch (fieldType) {
            case 'name':
                nameRef.current.focus();
                setFocusedName(true);
                break;
            case 'description':
                descriptionRef.current.focus();
                setFocusedDescription(true);
                break;
            default:
                setFocusedName(false);
                setFocusedDescription(false);
                break;
        }
    };

    const handleChangeDescription = (event, a) => {
        let id = event.target.id;
        let value = event.target.value;
        if (value.length <= 300) {
            setDetails(prevState => {
                return { ...prevState, [id]: value };
            })
        }
    }

    const handleChange = (event, a) => {
        let id = event.target.id;
        let value = event.target.value;
        if (value.length <= 50) {
            setDetails(prevState => {
                return { ...prevState, [id]: value };
            })
        }
    }

    const handleOnPhotoChangeClick = () => {
        document.getElementById('hiddenFileInputForUploadLogo').click();
    }

    const onUploadSuccessCB = (result) => {
        setDetails(prevState => {
            return { ...prevState, photoUrl: result.url }
        })
        let postObj = {
            username: userProfile.team_id,
            details: {
                logo_url: result.url,
                name: details.name && details.name,
                description: details.description && details.description
            },
            previousDetails: {
                ...userProfile,
                name: details.name && details.name,
                logo_url: result.url,
                description: details.description && details.description,
                targetJid: currentChat.targetJid
            }
        }
        setLoading(true);
        updateTeamDetails(postObj);
        setFocusedName(false);
        setFocusedDescription(false);
    }

    const onUploadErrorCB = (result) => {
        console.log('onUploadErrorCB ');
        setLoading(false);
    }

    const handleSubmit = () => {
        let postObj = {
            username: userProfile.team_id,
            details: {
                logo_url: details.photoUrl,
                name: details.name && details.name,
                description: details.description && details.description
            },
            previousDetails: {
                ...userProfile,
                name: details.name && details.name,
                logo_url: details.photoUrl,
                targetJid: currentChat.targetJid,
                description: details.description && details.description
            }
        }
        setLoading(true);
        updateTeamDetails(postObj);
        setFocusedName(false);
        setFocusedDescription(false);
    }

    const handlePhotoChange = (event) => {
        let files = event.target.files;
        if (files && files.length) {
            var formData = new FormData();
            formData.append("file", files[0]);
            setLoading(true);
            uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB });
        }
    }

    return (
        <div className={classes.root}>
            <div id="Profiledetails" className="scroller" style={{ height: '70vh', maxHeight: '70vh' }}>
                {
                    currentChat.type !== 'group' ?
                        <>
                            <div className={classes.imageRoot}>
                                {
                                    userProfile.photoUrl ?
                                        <img className={classes.image} src={userProfile.photoUrl} alt={userProfile.name} />
                                        :
                                        <Avatar
                                            id="profileImg"
                                            className={classes.avatarSize}
                                            src={currentChat.type === 'group' && TeamIcon}
                                        />
                                }
                            </div>
                            <div className={classes.profileContent}>
                                <div className={classes.listItemProfile}>
                                    <div className={classes.listItemProfileContent}>
                                        <div className={classes.iconRoot}>
                                            <SvgIcon className={classes.svgIcon} component={profileIcon} />
                                        </div>
                                        <div>
                                            <p className={classes.nonEditableLable}>
                                                Name
                                            </p>
                                            <p className={classes.profileValue}>
                                                {userProfile.name ? userProfile.name : '--'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.listItemProfile}>
                                    <div className={classes.listItemProfileContent}>
                                        <div className={classes.iconRoot}>
                                            <SvgIcon className={classes.svgIcon} component={aboutIcon} />
                                        </div>
                                        <div>
                                            <p className={classes.nonEditableLable}>
                                                About
                                            </p>
                                            <p className={classes.profileValue}>
                                                {userProfile.about ? userProfile.about : '--'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.listItemProfile}>
                                    <div className={classes.listItemProfileContent}>
                                        <div className={classes.iconRoot}>
                                            <SvgIcon className={classes.svgIcon} component={phoneIcon} />
                                        </div>
                                        <div>
                                            <p className={classes.nonEditableLable}>
                                                Phone
                                            </p>
                                            <p className={classes.profileValue}>
                                                {userProfile.phoneNo ? userProfile.phoneNo : '--'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.listItemProfile}>
                                    <div className={classes.listItemProfileContent}>
                                        <div className={classes.iconRoot}>
                                            <SvgIcon className={classes.svgIcon} component={deptIcon} />
                                        </div>
                                        <div>
                                            <p className={classes.nonEditableLable}>
                                                Department
                                            </p>
                                            <p className={classes.profileValue}>
                                                {userProfile.departmentName ? userProfile.departmentName : '--'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.listItemProfile}>
                                    <div className={classes.listItemProfileContent}>
                                        <div className={classes.iconRoot}>
                                            <SvgIcon className={classes.svgIcon} component={designationIcon} />
                                        </div>
                                        <div>
                                            <p className={classes.nonEditableLable}>
                                                Designation
                                            </p>
                                            <p className={classes.profileValue}>
                                                {userProfile.designation ? userProfile.designation : '--'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.buttonRoot}>
                                    <Button variant="contained" color="primary" onClick={() => history.push(`/profile/${userProfile.username}`)} className={classes.button}>View Profile</Button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className={classes.imageRoot}>
                                {
                                    details.photoUrl ?
                                        <img className={classes.image} src={details.photoUrl} alt={details.name} />
                                        :
                                        // <img width="60" height="60" src={logo} alt="logo" />
                                        <Avatar
                                            id="profileImg"
                                            className={classes.avatarSize}
                                            src={currentChat.type === 'group' && TeamIcon}
                                        />
                                }
                                {
                                    ((userProfile.members && userProfile.members[client.jid]) && (userProfile.members[client.jid].role === 'CAPTAIN' || userProfile.members[client.jid].role === 'VICE_CAPTAIN')) &&
                                    <div className={classes.hiddenIconRoot} onClick={() => handleOnPhotoChangeClick()}>
                                        <div>
                                            <CameraAltOutlined classes={{ root: classes.camIcon }} fontSize={'small'} />
                                            <p>Change Logo</p>
                                            <input type="file"
                                                id="hiddenFileInputForUploadLogo"
                                                onChange={(e) => handlePhotoChange(e)}
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={classes.content}>
                                <div className={classes.contentItem}>
                                    <div className={classes.editableNameRoot}>
                                        <div>
                                            <TextareaAutosize
                                                color="primary"
                                                id="name"
                                                placeholder="Enter Group Name"
                                                readOnly={!focusedName}
                                                ref={nameRef}
                                                value={details.name}
                                                className={classes.textareaStylesName}
                                                onChange={handleChange}
                                            />
                                            {/* <TextField
                                                color="primary"
                                                id="name"
                                                placeholder="Enter Group Name"
                                                inputRef={nameRef}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: !focusedName,
                                                    // onBlur: () => setFocusedName(false),
                                                    classes: { input: classes.inputStyles }

                                                }}
                                                value={details.name}
                                                onChange={handleChange}
                                            /> */}
                                        </div>
                                        {
                                            ((userProfile.members && userProfile.members[client.jid]) && (userProfile.members[client.jid].role === 'CAPTAIN' || userProfile.members[client.jid].role === 'VICE_CAPTAIN')) &&
                                            <div>
                                                {
                                                    focusedName ?
                                                        <IconButton title="Update" size="small" onClick={() => handleSubmit()}><Check fontSize="small" /></IconButton>
                                                        :
                                                        <IconButton title="Edit Name" size="small" onClick={() => handleEditClick('name')}><EditOutlined fontSize="small" /></IconButton>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <p className={classes.value}>
                                        Created by {userProfile.created_by} on {userProfile.createdAt ? moment(userProfile.createdAt).format("DD/MM/YYYY") : '--'}
                                    </p>
                                </div>
                                <div className={classes.contentItem}>
                                    <div className={classes.editableNameRoot}>
                                        <div>
                                            <p className={classes.lable}>
                                                Group Description
                                            </p>
                                        </div>
                                        {
                                            ((userProfile.members && userProfile.members[client.jid]) && (userProfile.members[client.jid].role === 'CAPTAIN' || userProfile.members[client.jid].role === 'VICE_CAPTAIN')) &&
                                            <div>
                                                {
                                                    focusedDescription ?
                                                        <IconButton title="Update" size="small" onClick={() => handleSubmit()}><Check fontSize="small" /></IconButton>
                                                        :
                                                        <IconButton title="Edit Description" size="small" onClick={() => handleEditClick('description')}><EditOutlined fontSize="small" /></IconButton>
                                                }
                                            </div>
                                        }
                                    </div>

                                    <p className={classes.value}>
                                        <TextareaAutosize
                                            color="primary"
                                            id="description"
                                            placeholder="Description"
                                            readOnly={!focusedDescription}
                                            ref={descriptionRef}
                                            value={details.description}
                                            className={classes.textareaStyles}
                                            onChange={handleChangeDescription}
                                        />
                                    </p>
                                </div>
                                <div>
                                    <p className={classes.membersLable}>
                                        <span>{userProfile.members && Object.keys(userProfile.members).length}</span> Members
                                    </p>
                                    <List dense>
                                        {
                                            (userProfile.members && Object.keys(userProfile.members).length > 0) && Object.values(userProfile.members).map((person, index) => (
                                                <ContactTemplate person={person} index={index} handleSetCurrentChat={handleSetCurrentChat} />
                                            ))
                                        }
                                    </List>
                                </div>
                            </div>
                        </>
                }

            </div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    )
}


const mapStateToProps = (state) => ({
    userProfile: state.common.profile,
    client: state.client
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProfileDetails: (options) => dispatch(getProfileDetails(options)),
        getTeamDetails: (username) => dispatch(getTeamDetails(username)),
        updateTeamDetails: (options) => dispatch(updateTeamDetails(options)),
        uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options)),
        setCurrentChat: (options) => dispatch(setCurrentChat(options))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileInfo));