import { makeConstant } from "./_helpers";

const constant = makeConstant("chase/snackbar");

export const SNACKBAR = constant("SNACKBAR");

export const setSnackbar = (payload) => ({
    type: SNACKBAR,
    payload
});

const INITIAL_STATE = { open: false, message: '' };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SNACKBAR:
            return { ...action.payload };
        default:
            return state;
    }
};
