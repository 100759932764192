import { makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomDialogComponent from '../ChaseMeet/CustomDialogComponent';
import RewardsDetails from '../Leagues/RewardsDetails';
import RewardsBannerComponent from '../Leagues/RewardsBanner';
import { openCustomDialog } from '../../ducks/meetings';
import { getRewardsList, getRewardStandings } from '../../ducks/game';
import { setLoading } from '../../ducks/loading';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import gameIllustration from '../../assets/images/game_Illustration.png'

const useStyles = makeStyles((theme) => ({
    rewardsContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    disabled: {
        opacity: 0.5,
        backgroundColor: '#FFF !important'

    }
}));


let pagination = {
    page: 1,
    limit: 10,
    total_count: 0
};

const RewardsList = ({ filtersData, openCustomDialog, getRewardsList, getRewardStandings, step, location }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [rewards, setRewards] = useState([]);

    useEffect(() => {
        pagination = {
            page: 1,
            limit: 10,
            total_count: 0
        };
        if (filtersData?.season?._id) {
            if (location.pathname.split('/')[4] === 'winnings') {
                getRewardsList({ seasonId: filtersData?.season?._id, params: { page: pagination.page, limit: pagination.limit, type: 'winner' }, onSuccessCB, onErrorCB });

            }
            else {
                getRewardsList({ seasonId: filtersData?.season?._id, params: { page: pagination.page, limit: pagination.limit, type: 'others' }, onSuccessCB, onErrorCB });

            }

        }
    }, [filtersData?.season?._id, location.pathname.split('/')[4]]);

    const onSuccessCB = (result, headers) => {
        if (result.length > 0 && headers) {
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
            const rewardsData = result.map(reward => ({ ...reward, expired: moment(reward.end_date).isBefore(new Date(), 'day') }))
            setRewards(pagination.page === 1 ? rewardsData : [...rewards, ...rewardsData]);
        }
        else {
            setLoading(false);
            setRewards([])

        }
    }

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rewards List', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleRewardSelect = (index) => {
        openCustomDialog({ open: true, children: <RewardsDetails reward={rewards[index]} rewardIndex={index} getRewardStandings={getRewardStandings} setLoading={setLoading} filtersData={filtersData} />, title: "Rewards", from: "standings" });
    }

    const getMoreRewards = () => {
        if (filtersData?.season?._id) {
            getRewardsList({ seasonId: filtersData?.season?._id, params: { page: pagination.page + 1, limit: pagination.limit }, onSuccessCB, onErrorCB })

        }
    }

    return (
        <div>
            {rewards.length > 0 ?
                <InfiniteScroll
                    dataLength={rewards.length}
                    next={getMoreRewards}
                    hasMore={rewards.length !== pagination.total_count}
                    scrollableTarget="leagues-root1"
                    style={{ overflow: "unset" }}
                    className={classes.infiniteScrollRoot}
                >
                    <div className={classes.rewardsContainer}>

                        {
                            [...rewards].map((obj, i) => (
                                (obj?.reward_status.search("Expired") === -1) ?

                                    <div onClick={() => handleRewardSelect(i)} style={{ cursor: 'pointer' }} >
                                        <RewardsBannerComponent obj={obj} style={{ marginLeft: 10 }} />
                                    </div>
                                    :
                                    <div className={classes.disabled} >
                                        <RewardsBannerComponent obj={obj} style={{ marginLeft: 10 }} />
                                    </div>
                            )

                            )
                        }
                    </div>
                    {rewards?.length > 0 && <CustomDialogComponent />}
                </InfiniteScroll>

                :
                <div style={{ textAlign: 'center', marginTop: 60 }}>
                    <img src={gameIllustration} />
                    <p style={{ fontSize: 14, color: '#0000008A' }}>We do not have any Rewards to show here</p>
                </div>
            }
        </div>
    )
}
const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        openCustomDialog: (options) => dispatch(openCustomDialog(options)),
        getRewardsList: (options) => dispatch(getRewardsList(options)),
        getRewardStandings: (options) => dispatch(getRewardStandings(options)),


    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RewardsList));