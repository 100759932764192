import { Button, Fade, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { KeyboardArrowDownOutlined } from '@material-ui/icons';
import React from 'react'
import { connect } from 'react-redux';
import { useRouteMatch, withRouter } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { getLeaguesList, getSeasonsList, getRoundsList, getMatchesList, getLoggedInUserRole } from "../../ducks/game";
import { getSearchValue, setSearchQuery } from "../../helpers";
import { setLoading } from "../../ducks/loading";
import NonPlayerPerformanceDashboard from './NonPlayerPerformanceDashboard';
import PlayerPerformanceDashboard from './PlayerPerformanceDashboard';
import MentorPerformanceDashboard from './MentorPerformanceDashboard';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        borderRadius: 30,
        color: "#5D5FEF",
        backgroundColor: "#EBEBFF",
        padding: "5px 14px",
        fontSize: "20",
        fontWeight: 500,
    },
    rightIcon: {
        padding: "0px 0 0 20px",
    },
    detailsbox: {
        borderRadius: 3,
        backgroundColor: '#FFF',
        boxShadow: "0px 0px 3px 1px #EDEDED",
        padding: '10px',
        "& p": {
            margin: 0,
            padding: 0
        },
        "& h6": {
            margin: 0,
            padding: 0
        }
    },
    avatar_sec: {
        display: 'flex',
        alignItems: 'center'
    }
}))


const defaultFilters = {
    league: null,
    season: null,
    round: null,
    status: 'LIVE',
    match: null
};
const PlayerPerformance = ({ history, user, location, getLeaguesList, getRoundsList, getMatchesList, getOverallOrgStats, getLoggedInUserRole, getSeasonsList, username }) => {
    const classes = useStyles();
    const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [leagues, setLeagues] = useState([]);
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const [seasons, setSeasons] = useState([]);
    const [rounds, setRounds] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [menuPosition, setMenuPosition] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);

    useEffect(() => {
        getLeagues();
        setSearchQuery(history, { match: "" });
        setFiltersData(prevState => ({ ...prevState, match: null }));
    }, []);

    useEffect(() => {
        if (filtersData.season?._id) {
            let seasonId = filtersData.season?._id;
            getLoggedInUserRole({ seasonId, onSuccessCB, onErrorCB });
            getRounds(seasonId);
        }
    }, [filtersData.season?._id]);

    useEffect(() => {
        if (filtersData.league) getSeasons(filtersData.league._id);
    }, [filtersData.league]);

    const getLeagues = () => {
        setLoading(true);
        getLeaguesList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setLeagues([...result]);
                const searchValue = getSearchValue(history, 'league');
                !searchValue && setSearchQuery(history, { league: result[0]?._id });
                setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters }));

            }
        })
    };

    const getSeasons = (leagueId) => {
        // setLoading(true);
        setLoadingSeasons(true);
        getSeasonsList({
            leagueId: leagueId,
            params: { limit: 5000, page: 1, status: "live" },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setSeasons([...result]);
                    const searchValue = getSearchValue(history, 'season');
                    !searchValue && setSearchQuery(history, { season: result[0]?._id });
                    if (!(filtersData.season?._id)) { setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null })); }
                    setSelectedSeason(searchValue ? result.find(obj => obj._id === searchValue) : result[0]);
                }
                else {
                    // setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                    setSeasons([]);
                    setRounds([]);
                }
                setLoadingSeasons(false);
            }, onErrorCB: (error) => {
                setLoadingSeasons(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));

            }
        })
    }

    const getRounds = (seasonId) => {
        setLoading(true);
        getRoundsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setRounds([...result]);
                    if (location.pathname.split('/')[3] === 'dashboard') {
                        setFiltersData(prevState => ({ ...prevState, match: null, round: null }));
                        const searchValue = getSearchValue(history, 'round');
                        !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
                    }
                    else {
                        const searchValue = getSearchValue(history, 'round');
                        !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
                        setFiltersData(prevState => ({ ...prevState, match: null, round: (searchValue ? result?.find(obj => obj._id === searchValue) : result?.find(obj => obj._id === headers?.latest_round_id)) }));
                    }
                }
                else {
                    setRounds([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rounds', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const onSuccessCB = (response) => {
        if (response) {
            setUserInfo({ ...response });
        }
    }

    const onErrorCB = (error) => {
    }

    const handleRightClick = (event) => {
        event.preventDefault();
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition({
            top: event.pageY,
            left: event.pageX
        });
    };

    const handleSeasonClick = (season) => {
        setAnchorEl(null);
        setMenuPosition(null);
        setSelectedSeason(season);
        setFiltersData(prevState => ({ ...prevState, season }));
        setSearchQuery(history, { season: season?._id });
    }

    const [loadingSeasons, setLoadingSeasons] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleItemClick = (event, league) => {
        setAnchorEl(event.currentTarget);
        setFiltersData(prevState => ({ ...prevState, league }));
        setSearchQuery(history, { league: league?._id });
        // if (league) getSeasons(league._id);
    };


    const natigateToAllStats = () => {
        const historyPath = `${history.location?.search}&round_id=ALL`;
        history.push(`/leaguess/c2/stats/overall${historyPath}`)
    }
    const natigateToLiveScores = () => {
        history.push(`/leaguess/c2/live-scores${history.location?.search}`)
    }

    const navigateToTeamLeaderboard = () => {
        history.push(`/leaguess/c2/points-table/team${history.location?.search}`)
    }

    const navigateToPlayerLeaderboard = () => {
        history.push(`/leaguess/c2/points-table/player${history.location?.search}`)
    }

    const navigateToAllMatches = () => {
        history.push(`/leaguess/c2/matches${history.location?.search}`);
    }

    return (
        <div className={classes.root}>
            <div style={{ width: '100%' }}>
                <div style={{ margin: '10px 0px' }}>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="outlined"
                        color="primary"
                        onClick={handleRightClick}
                        className={classes.button}
                    >
                        {selectedSeason ? selectedSeason.name : filtersData.league?.name}
                        <KeyboardArrowDownOutlined className={classes.rightIcon} />
                    </Button>
                    <Menu
                        id="simple-menu"
                        open={!!menuPosition}
                        onClose={() => setMenuPosition(null)}
                    >
                        {leagues.length > 0 && leagues.map((league) => (
                            <MenuItem onClick={(e) => handleItemClick(e, league)} style={{ justifyContent: "space-between" }}>{league.name}
                                <ChevronRightIcon className={classes.rightIcon} />
                            </MenuItem>
                        ))
                        }
                    </Menu>
                    {<Menu
                        id="fade-menu"
                        MenuListProps={{
                            "aria-labelledby": "fade-button"
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        TransitionComponent={Fade}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{ vertical: "center", horizontal: "left" }}
                        anchorPosition="right"
                        style={{ minWidth: 200 }}
                        classes={{ paper: classes.paperRoot }}
                    >
                        {loadingSeasons ? <p style={{ margin: 16, textAlign: 'center' }}>Loading....</p> : [
                            (seasons.length > 0) ? seasons.map((season) => (
                                <MenuItem onClick={() => handleSeasonClick(season)}>{season.name}</MenuItem>
                            ))
                                : <p style={{ margin: 16, textAlign: 'center' }}>No Seasons Found</p>
                        ]}
                    </Menu>}
                </div>
            </div>
            <div>
                {
                    userInfo?.role === 'PLAYER' ?
                        <PlayerPerformanceDashboard username={username} filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToAllMatches={navigateToAllMatches} />
                        : userInfo?.role === 'MENTOR' ? <MentorPerformanceDashboard username={username} rounds={rounds} filtersData={filtersData} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToAllMatches={navigateToAllMatches} />
                            : <NonPlayerPerformanceDashboard username={username} filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToAllMatches={navigateToAllMatches} />
                }
            </div>
        </div>
    )
}


const mapStateToProps = (state, props) => ({
    user: state.user,

});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLoggedInUserRole: options => dispatch(getLoggedInUserRole(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerPerformance));

