// import orderBy from "lodash/orderBy";
// import differenceBy from "lodash/differenceBy";

import { finalAccessModules, finalAdminAccessModules, finalPwcAccessModules } from "../components/SideNavBar/SideNavBar";
import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/chats");

export const UPLOAD_TO_S3 = constant("UPLOAD_TO_S3");
export const SAVE_MEDIA_FILE = constant("SAVE_MEDIA_FILE");
export const GET_MEDIA_FILES = constant("GET_MEDIA_FILES");
export const SET_MEDIA = constant("SET_MEDIA");
export const SET_DOCUMENTS = constant("SET_DOCUMENTS");
export const SET_LINKS = constant("SET_LINKS");
export const UPDATE_TEAM_DETAILS = constant("UPDATE_TEAM_DETAILS");
export const GET_PROFILE = constant("GET_PROFILE");
export const GET_TEAM_DETAILS = constant("GET_TEAM_DETAILS");
export const GET_TEAM_MEMBERS = constant("GET_TEAM_MEMBERS");
export const SET_PROFILE = constant("SET_PROFILE");
export const GET_ORG_DETAILS = constant("GET_ORG_DETAILS");
export const SET_ORG_DETAILS = constant("SET_ORG_DETAILS");
export const GET_GROUPS_LIST = constant("GET_GROUPS_LIST");
export const GET_TEMPLATE_THEME = constant("GET_TEMPLATE_THEME");
export const SET_TEMPLATE_THEME = constant("SET_TEMPLATE_THEME");

export const saveMediaDetails = (details) => ({
    type: SAVE_MEDIA_FILE,
    payload: details
});

export const getProfileMediaFiles = (payload) => ({
    type: GET_MEDIA_FILES,
    payload: payload
});

export const setProfileMedias = (data) => ({
    type: SET_MEDIA,
    payload: data
});

export const setProfileDocuments = (data) => ({
    type: SET_DOCUMENTS,
    payload: data
});

export const setProfileLinks = (data) => ({
    type: SET_LINKS,
    payload: data
});

export const getProfileDetails = (payload) => ({
    type: GET_PROFILE,
    payload
});

export const updateTeamDetails = (details) => ({
    type: UPDATE_TEAM_DETAILS,
    payload: details
})

export const uploadFilesToS3 = (payload) => ({
    type: UPLOAD_TO_S3,
    payload: payload
});

export const getTeamDetails = (username) => ({
    type: GET_TEAM_DETAILS,
    payload: username
});

export const getTeamMembers = (payload) => ({
    type: GET_TEAM_MEMBERS,
    payload
});

export const setProfileDetails = (payload) => ({
    type: SET_PROFILE,
    payload
});

export const getOrgDetails = (payload) => ({
    type: GET_ORG_DETAILS,
    payload
});

export const setOrgDetails = (payload) => ({
    type: SET_ORG_DETAILS,
    payload
});

export const getGroupsList = (payload) => ({
    type: GET_GROUPS_LIST,
    payload
});

export const getTemplateTheme = (payload) => ({
    type: GET_TEMPLATE_THEME,
    payload
});

export const setTemplateTheme = (payload) => ({
    type: SET_TEMPLATE_THEME,
    payload
});

const initialState = {
    profile: {},
    media: {
        data: []
    },
    documents: {
        data: []
    },
    links: {
        data: []
    },
    orgDetails: {
        modules: []
    },
    templateTheme: {}
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE: {
            return {
                ...state,
                profile: {
                    ...action.payload,
                }
            }
        }
        case SET_MEDIA: {
            return {
                ...state,
                media: {
                    targetJid: action.payload.targetJid,
                    data: [
                        ...action.payload.data,
                    ]
                }
            }
        }
        case SET_DOCUMENTS: {
            return {
                ...state,
                documents: {
                    targetJid: action.payload.targetJid,
                    data: [
                        ...action.payload.data,
                    ]
                }
            }
        }
        case SET_LINKS: {
            return {
                ...state,
                links: {
                    targetJid: action.payload.targetJid,
                    data: [
                        ...action.payload.data,
                    ]
                }
            }
        }
        case SET_ORG_DETAILS: {
            const pwcModules = {
                modules: [
                    "dashboard", "performance", "wall", "action", "review", "buzz", "survey", "incentive", "rewards"
                ]
            }
            return {
                ...state,
                orgDetails: {
                    ...action.payload,
                    // logo_url: '',
                    adminModules: finalAdminAccessModules(action.payload || {}) || [],
                    // Test with dynamic modules response manipulation 'Admin'
                    // adminModules: finalAdminAccessModules({ modules: ["board", "arena", "game", "call", "survey", "chat", "meet"] }) || [],
                    modules: finalAccessModules(action.payload || {}) || [],
                    pwcModules: finalPwcAccessModules(pwcModules || {}) || [],
                    // theme: { ...action.payload.theme, view: 'PWC' }
                    // Test with dynamic modules response manipulation
                    // modules: finalAccessModules({ modules: ["board", "arena", "game", "call", "survey", "chat", "meet"] }) || []
                }
            }
        }
        case SET_TEMPLATE_THEME: {
            return {
                ...state,
                templateTheme: {
                    ...action.payload
                }
            }
        }

        default:
            return state;
    }
};
