import { makeConstant } from "./_helpers";
import data from '../components/ProductManagementSystem/data.json'
const constant = makeConstant("chase/pms");

export const GET_TASKS = constant("GET_TASKS");
export const OPEN_TASK_DIALOG = constant("OPEN_TASK_DIALOG");
export const GET_KANBANBOARD_DATA = constant("GET_KANBANBOARD_DATA");
export const CREATE_TASK = constant("CREATE_TASK");
export const UPDATE_TASK = constant("UPDATE_TASK");
export const CREATE_SUB_TASK = constant("CREATE_SUB_TASK");
export const GET_SUB_TASK = constant("GET_SUB_TASK")
export const DELETE_TASK = constant("DELETE_TASK")
// task details constants
export const GET_TASK_DETAILS = constant("GET_TASK_DETAILS");
export const GET_PROJECT_LIST = constant("GET_PROJECT_LIST");
export const GET_PROJECT_DETAILS = constant("GET_PROJECT_DETAILS");
export const EXPORT_TASKS_STATUSASON = constant("EXPORT_TASKS_STATUSASON");
export const EXPORT_TASKS_AGING = constant("EXPORT_TASKS_AGING");
//share task
export const OPEN_SHARE_TASK_DIALOG = constant("OPEN_SHARE_TASK_DIALOG")
// task comment  
export const CREATE_COMMENT = constant("CREATE_COMMENT");
export const UPDATE_COMMENT = constant("UPDATE_COMMENT");
export const DELETE_COMMENT = constant("DELET_COMMENT");
export const GET_COMMENTS = constant("GET_COMMENTS");
export const GET_PROJECT_MEMBERS = constant("GET_PROJECT_MEMBERS");
// work logs  
export const OPEN_WORK_LOG_DIALOG = constant("OPEN_WORK_LOG_DIALOG");
export const CREATE_WORK_LOG = constant("CREATE_WORK_LOG");
export const UPDATE_WORK_LOG = constant("UPDATE_WORK_LOG");
export const DELETE_WORK_LOG = constant("DELETE_WORK_LOG");
export const GET_WORK_LOGS_LIST = constant("GET_WORK_LOGS_LIST");
//breadcrumbs
export const GET_PMS_BREADCRUMBS = constant("GET_PMS_BREADCRUMBS")
export const PROJECT_DETAILS = constant("PROJECT_DETAILS")
//Board
export const ADD_NEW_LANE = constant("ADD_NEW_LANE");
export const CHANGE_LANE_TITLE = constant("CHANGE_LANE_TITLE");
export const CHANGE_LANE_POSITIONS = constant("CHANGE_LANE_POSITIONS");
export const DELETE_STAGE = constant("DELETE_STAGE")
export const VIEW_BOARD_DETAILS = constant("VIEW_BOARD_DETAILS")
//actions

export const getTasks = (payload) => ({
    type: GET_TASKS,
    payload
})
export const openTaskDialog = (payload) => ({
    type: OPEN_TASK_DIALOG,
    payload
})
export const openWorkLogDialog = (payload) => ({
    type: OPEN_WORK_LOG_DIALOG,
    payload
})
export const getKanbandBoardData = (payload) => ({
    type: GET_KANBANBOARD_DATA,
    payload
})
export const createTask = (payload) => ({
    type: CREATE_TASK,
    payload
});

export const createSubTask = (payload) => ({
    type: CREATE_SUB_TASK,
    payload
});
export const getSubTask = (payload) => ({
    type: GET_SUB_TASK,
    payload
})
export const deleteTask = (payload) => ({
    type: DELETE_TASK,
    payload
})
export const exportTaskStatusAson = (payload) => ({
    type: EXPORT_TASKS_STATUSASON,
    payload
})
export const exportTasksAging = (payload) => ({
    type: EXPORT_TASKS_AGING,
    payload
})
// task comment actions
export const createComment = (payload) => ({
    type: CREATE_COMMENT,
    payload
})
export const updateComment = (payload) => ({
    type: UPDATE_COMMENT,
    payload
})
export const deleteComment = (payload) => ({
    type: DELETE_COMMENT,
    payload
})
export const getComments = (payload) => ({
    type: GET_COMMENTS,
    payload
})
// task details  actions
export const getTaskDetails = (payload) => ({
    type: GET_TASK_DETAILS,
    payload
});

export const getProjectList = (payload) => ({
    type: GET_PROJECT_LIST,
    payload
});

export const getProjectDetails = (payload) => ({
    type: GET_PROJECT_DETAILS,
    payload
});

export const updateTask = (payload) => ({
    type: UPDATE_TASK,
    payload
})
export const getProjectMembers = (payload) => ({
    type: GET_PROJECT_MEMBERS,
    payload
})
export const createWorkLog = (payload) => ({
    type: CREATE_WORK_LOG,
    payload
})
export const updateWorkLog = (payload) => ({
    type: UPDATE_WORK_LOG,
    payload
})
export const getWorkLogsList = (payload) => ({
    type: GET_WORK_LOGS_LIST,
    payload
})
export const deleteWorkLog = (payload) => ({
    type: DELETE_WORK_LOG,
    payload
})

//breadcrumbs
export const projectDetails = (payload) => ({
    type: PROJECT_DETAILS,
    payload
})
export const setBreadCrumbsData = (payload) => ({
    type: GET_PMS_BREADCRUMBS,
    payload
})
//Board
export const addNewLane = (payload) => ({
    type:ADD_NEW_LANE,
    payload
});

export const boardDetails = (payload) => ({
    type:VIEW_BOARD_DETAILS,
    payload
});

export const changeLaneTitle = (payload) => ({
    type:CHANGE_LANE_TITLE,
    payload
});

export const changeLanePositions = (payload) => ({
    type:CHANGE_LANE_POSITIONS,
    payload
});

export const deleteStage = (payload) => ({
    type:DELETE_STAGE,
    payload
});

//share
export const openShareTaskDialog = (payload) => ({
    type: OPEN_SHARE_TASK_DIALOG,
    payload
});


const initialState = {
    taksDialog: {
        open: false,
        details: {},
        isEdit: false,

    },
    workLogDialog: {
        open: false,
        details: {},
        isEdit: false
    },
    shareTaskToChat: {
        open: false,
        details: {}
    },
    project: {
        name: '',
        id: '',
        key: ''
    },
    boardDetails:{
        open:false,
        type:'',
        noOfBoards:0,
        details:{
            members:[],
            boardOwners:[],
            stages:[]
        }
    }
    ,
    kanbanBoardData: {

    },
    breadcrumbs: []
};
//Reducers
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TASKS: {
            return {
                ...state.lanes.forEach(ele => {
                    ele.label = `${ele.cards.length}`
                })
            }
        }
        case OPEN_TASK_DIALOG: {
            return {
                ...state,
                taksDialog: {
                    ...action.payload
                }
            }
        }

        case OPEN_WORK_LOG_DIALOG: {
            return {
                ...state,
                workLogDialog: {
                    ...action.payload
                }
            }
        }
        case GET_PMS_BREADCRUMBS: {
            return {
                ...state,
                breadcrumbs: [...state.breadcrumbs, action.payload]
            }
        }
        case PROJECT_DETAILS: {
            return {
                ...state,
                project: {
                    ...action.payload
                }
            }
        }
        case OPEN_SHARE_TASK_DIALOG:
            return {
                ...state,
                shareTaskToChat: {
                    ...action.payload
                }
            }
        case VIEW_BOARD_DETAILS :
           return {
                ...state,
                boardDetails:{
                    ...action.payload
                }
            }
        default: return state
    }
}