import React from 'react';
import { Button } from '@material-ui/core';
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-1.svg";
import PDFViewer from '../PDFViewer/PDFViewer';
import { Close } from '@material-ui/icons';

const PdfPostView = ({ classes, handlePdfUploadClick, handlePdfFileChange, attachments, handleNext, handleDeletePdf, canvas }) => {

    return (
        <>
            <div id="cropper-root" className={classes.previewSection} >
                <PDFViewer file={attachments[0]?.url} fromCreatePost={true} />
            </div>
            <div className={classes.postDetails}>
                <div className={classes.uploadButtonRoot}>
                    <Button variant="outlined" color="primary" size="medium" onClick={() => handlePdfUploadClick()}>Upload PDF</Button>
                    <input type="file"
                        id={`hiddenFileInputForPdfPost`}
                        onChange={(e) => handlePdfFileChange(e)}
                        style={{ display: 'none' }}
                        accept=".pdf"
                    />
                </div>
                <div style={{ overflowY: 'auto', height: '-webkit-fill-available', height: '100%', display: 'flex', justifyContent: 'center', marginTop: 24 }}>
                    {attachments?.length > 0 &&
                        <>
                            <div className={classes.imageRoot} style={{ width: 100, height: 100, border: '1px solid #00000069' }}>
                                <img src={attachments[0]?.thumbnailUrl} width={100} height={100} style={{ objectFit: 'contain' }} alt="img" />
                                <span id="delete-icon" className={classes.deleteIcon} style={{ right: 0, top: -5 }}>
                                    <Close fontSize={'small'} onClick={(e) => handleDeletePdf(e, 0)} />
                                </span>
                            </div>
                        </>
                    }
                    <canvas style={{ display: 'none' }} width={canvas.width} height={canvas.height} id="pdf-canvas"></canvas>
                </div>
                {(attachments?.length > 0) && <div className={classes.netxButtonRoot} style={{ justifyContent: 'flex-end' }}>
                    <Button title="Next" variant="contained" color="primary" size="medium" onClick={() => handleNext('pdf')}>Next<ArrowRight style={{ marginLeft: 5 }} className="next-arrow" /></Button>
                </div>}
            </div>
        </>
    )
}

export default PdfPostView;
