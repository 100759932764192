import React, { useEffect, useState } from 'react';
import { Button, ClickAwayListener, IconButton, makeStyles, TextareaAutosize, useTheme } from '@material-ui/core';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { InsertEmoticon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { onCommentInput } from '../../ducks/arena';
import { Picker } from 'emoji-mart';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { Mention, MentionsInput } from 'react-mentions';
import defaultStyles from "./ArenaCommentInputStyles";
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        borderRadius: 25,
        background: "#EEEEEE",
        width: "90%",
        margin: "5px 16px",

        // margin: '0px 10px 0 10px'
        // border: "solid 1px red",
        // minHeight: "40px"
    },
    input: {
        width: "90%",
        margin: '0px 20px 0px 4px',
        fontSize: 14,
        flex: 1,
        color: "inherit",
        background: "inherit",
        border: "none",
        fontFamily: "inherit",
        resize: "none",
        "&:focus": {
            outline: "none"
        }
    },
    iconButtonEmoji: {
        padding: 5,
    },
    emojiPicker: {
        position: "absolute",
        bottom: 60,
        padding: "0 15px",
        zIndex: 1,
        "& .emoji-mart": {
            width: "auto !important"
        },
        "& .emoji-mart-scroll": {
            // transition: '0.5s ease-in-out',
            height: 170
            // '&:hover': {
            //     height: 300
            // }
        }
    },
    avatarStyles: {
        width: 24,
        height: 24,
        margin: 2,
        fontSize: 12
    },
    mentionInputRoot: {
        '& .mention-input__suggestions': {
            maxHeight: 200,
            height: 90,
            overflowY: 'auto',
            border: '1px solid #EEE'
        }
    },
    suggestion: {
        border: '1px solid black !important',
        backgroundColor: 'white !important ',
        color: 'black',
        fontSize: '12px',
        cursor: 'pointer',
        margin: '3px 3px 3px 3px',
        borderRadius: '15px',
        height: '25px',
        // width: '500px !important',
        // textOverflow: 'ellipsis !important',
        whiteSpace: 'nowrap',
        // overflow: 'hidden',


    },
}));

const htmlRegex = new RegExp("<([A-Za-z][A-Za-z0-9]*)\\b[^>]*>(.*?)</\\1>");

let emojiPicker = null;
const ArenaCommentInput = ({ postId = '', onCommentedCB = () => { }, autoFocus = true, withAvatar = false, readOnly = false, user, postArenaComments, postCommentReply, onCommentInput, commentState, usersList = [], isDisplaySuggestions, suggestionsList, isDisplayPostIcon, ...others }) => {
    const commentRef = React.useRef()
    const classes = useStyles();
    const [comment, setComment] = useState('');
    const [showEmojis, setShowEmojis] = useState(false);
    const [tags, setTags] = useState([]);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [showSuggestions, setShowSuggestions] = useState(false);

    const handleAddUsers = (id) => {
        // console.log('ArenaCommentCard onAdd ', id);
        setTags(prevState => ([...prevState, id]))
    }

    useEffect(() => {
        setShowSuggestions(isDisplaySuggestions)
    }, [isDisplaySuggestions]);

    const handleKeyDown = (e) => {
        // Prevent new line in text area when return pressed unless shift is also pressed
        if (e.nativeEvent.keyCode === 13) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();


            }

        }


    };


    const handleKeyPress = (e, index, commentIndex, isReply) => {
        if (htmlRegex.test(comment)) {
            enqueueSnackbar(`Tags are not allowed.`, {
                variant: 'error',
                preventDuplicate: true
            });
        } else {
            setShowSuggestions(true)
            if (e.nativeEvent.keyCode === 13) {
                if (!e.nativeEvent.shiftKey) {
                    e.preventDefault();
                    // handleSubmit();
                    // onCommentedCB(comment);
                    e.target.placeholder = 'Add a comment...';

                    onCommentInput({
                        entityId: commentState.entityId || postId,
                        entityType: commentState.entityType || 'POST',
                        commentText: comment ? comment.replaceAll('@_', '') : '',
                        tags: tags,
                        // focus: false
                    })
                    setComment('');
                    setTags([]);
                }
            }
        }
    };

    const handleShowEmojis = e => {
        setShowEmojis(true);
    };



    const addEmoji = (e, index) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach(el => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        // this.setState({
        //     text: this.state.text + emoji
        // })
        setComment(comment + emoji);
    };

    const handleClickAway = e => {
        setShowEmojis(false);
    };



    const handleBlur = (e) => {

        console.log('handleBlur ', e.target);
        if (e.target) {
            e.target.placeholder = 'Add a comment...'

        }
        onCommentInput({
            entityId: postId,
            entityType: 'POST',
            commentText: '',
            // focus: false
        })
    }

    const handleShowSuggestions = (e) => {
        commentRef.current.focus()
        setComment(e.target.value)
        setShowSuggestions(false)
    }


    const handlePost = () => {
        if (htmlRegex.test(comment)) {
            enqueueSnackbar(`Tags are not allowed.`, {
                variant: 'error',
                preventDuplicate: true
            });
        } else {
            commentRef.current.focus()
            setShowSuggestions(true)
            onCommentInput({
                entityId: commentState.entityId || postId,
                entityType: commentState.entityType || 'POST',
                commentText: comment ? comment.replaceAll('@_', '') : '',
                tags: tags,
                // focus: false
            })
            setComment('');
            setTags([]);
        }
    }

    return (
        <div>
            {showSuggestions && isDisplayPostIcon && <div className="invisible-scrollbar" style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
                {suggestionsList.map((suggestion) => (
                    <button className={classes.suggestion} value={suggestion.text} onClick={(e) => handleShowSuggestions(e)}>{suggestion.text}</button>

                ))}
            </div>}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div className={classes.root} {...others}>
                    {
                        withAvatar ?
                            <AvatarComponent name={user.name} username={user.username} url={user.photoUrl} className={classes.avatarStyles} onClick={() => { }} />
                            :
                            <IconButton
                                onClick={() => handleShowEmojis()}
                                className={classes.iconButtonEmoji}
                                aria-label="menu"
                            >
                                <InsertEmoticon />
                            </IconButton>
                    }
                    <div style={{ width: '100%' }} className={classes.mentionInputRoot}>
                        <MentionsInput
                            readOnly={readOnly}
                            placeholder="Add a comment..."
                            inputRef={commentRef}
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            allowSuggestionsAboveCursor
                            // forceSuggestionsAboveCursor
                            id="CommentInput"
                            style={defaultStyles}
                            rows={3}
                            onKeyDown={e => handleKeyDown(e)}
                            onKeyUp={e => handleKeyPress(e)}
                            onBlur={(e) => handleBlur(e)}
                            autoFocus={autoFocus || commentState.focus}
                            className="mention-input"
                        >

                            <Mention
                                trigger="@"
                                data={[...usersList]}
                                markup="@@___display__@_"
                                displayTransform={(id) => `@${id}`}
                                onAdd={(id) => handleAddUsers(id)}
                            />
                        </MentionsInput>

                    </div>

                    {showEmojis && (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <span
                                className={classes.emojiPicker}
                                ref={el => (emojiPicker = el)}
                            >
                                <Picker
                                    onSelect={e => addEmoji(e)}
                                    emojiTooltip={true}
                                    title=""
                                    color={theme.palette.primary.main}
                                    exclude={['flags']}
                                />
                            </span>
                        </ClickAwayListener>
                    )}
                </div>
                {isDisplayPostIcon &&
                    <div >
                        <IconButton

                            style={{ backgroundColor: theme.palette.primary.main, color: '#ffff', height: '40px', width: '40px', borderRadius: '25px', bottom: 0, right: 0 }}
                            onClick={(e) => handlePost(e)}
                        ><SendIcon fontSize='small' /></IconButton>
                    </div>}
            </div>
        </div >
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    commentState: state.arena.comment
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        // postArenaComments: options => dispatch(postArenaComments(options)),
        onCommentInput: options => dispatch(onCommentInput(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArenaCommentInput);