import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { AccessTime, Class, Clear, Edit, Search } from '@material-ui/icons';
import { FormControl, Grid, InputBase, TextField, fade, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import ChatList from '../../CommonListSection/ChatList';
import ContactList from '../../CommonListSection/ContactList';
import GroupChatSection from '../../ChatSection/GroupChatSection';
import ChatSection from '../../ChatSection/ChatSection';
import LandingImage from '../../../assets/images/chase-chimp.png';
// import { ReactComponent as NewChatIcon } from '../../assets/icons/edit-2.svg';
import { ReactComponent as NewChatIcon } from '../../../assets/icons/new-chat-1.svg';
import ProfileDetails from '../../CommonListSection/ProfileDetails';
import { connect } from 'react-redux';
import { setSidebarIndex } from '../../../ducks/sidebar';
import Snackbar from '../../Snackbar';
import { useSnackbar } from 'notistack';
import { getChats } from '../../../ducks/chats';
import { getChatList } from '../../../selectors';
import { CustomCard } from '../../Leagues/CustomCard';
import BuzzlistSection from './BuzzlistSection';
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from '@material-ui/lab';




const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    mainRoot: {
        width: '100%',
        maxWidth: 290,
        backgroundColor: '#fff',
        transition: 'width 0.25s ease-in-out',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            // width: '0px',
            // minWidth: 0,
            position: 'fixed',
            borderRight: '1.5px solid #eeeeee'
        },
    },
    rootb: {
        // backgroundColor: '#f5f5f5',
        width: '100%',
        maxHeight: 'calc(100vh - 95px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        // padding: '35px 35px 0px',
        display: 'flex',
        // padding: "0px 35px",
        padding: '20px 20px 0px 20px',
        [theme.breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 100px)',
            // maxWidth: `calc(100vw - 150px)`,
            padding: '20px 15px',
        },
    },
    mainRootWidth: {
        width: 0,
        minWidth: 0
    },
    root: {
        flexGrow: 1,
        maxWidth: 320,
        // backgroundColor: '#fff',
        // transition: 'width 0.5s ease-in-out',
        // [theme.breakpoints.down('sm')]: {
        //     width: '0px',
        // },
    },
    tabsRoot: {
        // [theme.breakpoints.down('sm')]: {
        //     padding: '14px 0'
        // },
        padding: '14px 20px'
    },
    tabRoot: {
        borderBottom: '1px solid #0000003a',
        padding: 0,
        textTransform: 'capitalize',
        fontSize: 13,
        fontWeight: 600,
        minWidth: 100,
        color: '#838383'
    },
    tabWrapper: {
        flexDirection: 'row',
        '& > *:first-child': {
            // fontSize: '21px',
            margin: '0 6px !important'
        }
    },
    labelIcon: {
        minHeight: '50px'
    },
    tabIndicator: {
        height: '3px',
    },
    chatArea: {
        width: '100%',
        // backgroundColor: '#F3F2F1',
        display: 'flex',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`
    },
    landingPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: 'inherit',
        '& img': {
            [theme.breakpoints.down('sm')]: {
                // height: 260
            }
        }
    },
    landingPageContent: {
        textAlign: 'center',
        maxWidth: '90%'
    },
    landingPageText: {
        marginTop: 0,
        // fontFamily: 'cursive',
        fontSize: 25,
        fontWeight: 600,
        color: '#58595b'
        // color: theme.palette.primary.main
    },
    svgIconStyle: {
        '& path': {
            // fill: 'currentColor'
            // color: theme.palette.primary.main,
            // stroke: theme.palette.primary.main,
        }
    },
    selectedStyles: {
        '& path': {
            stroke: theme.palette.primary.main,
        }
    },
    landingImg: {
        width: '70%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    search: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: 4,
        backgroundColor: "#ffffff",
        "&:hover": {
            backgroundColor: fade("#F1F2F5", 0.75),
        },
        // width: 'calc(100% - 795px)',
        width: '350px',
        border: '1px solid #bbb5b5',
        height: '38px'


        // width: "auto",
        // marginRight: theme.spacing(1),
        // marginLeft: theme.spacing(1),
        // maxWidth: 250,
        // minWidth: 175,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#6d6666",
    },
    searchInputRoot: {
        color: "inherit",
        width: "100%",
    },
    searchInputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        height: 20,
        fontSize: 12,

    },
    autocompleteRoot: {
        width: "200px",
        height: "39px",
        borderRadius: 3,
        overflow: "clip",
        whiteSpace: "nowrap",
        "& .MuiAutocomplete-tagSizeSmall": {
            width: "65px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },



    },
    formRoot: {
        marginLeft: "5px",
        '& .MuiFormControl-root': { '& label': { top: '0px' }, backgroundColor: '#ffffff !important' },
        '& .MuiFormLabel-root': { color: '#6d6666 !important' }
        // width: 200
    },
}));

let pagination = {
    page: 0,
    limit: 15
};

const PwcBuzzList = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [currentChat, setCurrentChat] = useState(null);
    const [openList, setOpenList] = useState(false);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [query, setQuery] = useState('');


    const onSuccessCB = (result, headers) => {
        console.log("headers ", result, headers.page, pagination);
        pagination.page = parseInt(headers.page);
        pagination.total_count = parseInt(headers.total_count);
    };

    const onErrorCB = error => {
        console.log(error);
        enqueueSnackbar(`Failed to fetch Chat list.`, {
            variant: "error",
            preventDuplicate: true
        });
    };

    useEffect(() => {
        console.log('ChatList ');
        props.getChats({
            params: { limit: pagination.limit, page: 0, },
            onSuccessCB,
            onErrorCB
        });
    }, [])

    useEffect(() => {
        if (props.chats.length > 0) {
            let firstchat = props.chats.find(obj => obj.targetJid === "orgs1.917735570520@xmpp.uat.letschase.io")
            setCurrentChat(props.chats[0])
        }
    }, [props.chats])

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setValue(newValue);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 960) {
                setOpenList(false);
            }
            else {
                props.setSidebarIndex(null);
            }
        });
    }, []);

    useEffect(() => {
        console.log('sidebar chat index ', props.sidebar, openList)
        if (window.innerWidth <= 960) {
            if (props.sidebar.index !== null) {
                setOpenList(false);
            }
            else setOpenList(true);
        }
    }, [props.sidebar])

    useEffect(() => {
        setValue(0);
    }, [props])

    const handleChat = (chatDetails, existingCurrent) => {
        console.log('sidebar handleChat index ', props.sidebar, openList)
        setCurrentChat(chatDetails);
        if (value !== 0) {
            setValue(0);
        }
        if (window.innerWidth <= 960 && !existingCurrent) {
            setOpenList(true);
            props.setSidebarIndex(null);
        }
    }


    return (
        <div style={{ display: "flex", flexDirection: 'column', backgroundColor: "#F1F0FD", width: '100%', height: "calc(100vh - 60px)", padding: '30px 20px 20px 20px' }}
        >
            {currentChat ?
                <div>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <Search />
                            </div>
                            <InputBase
                                placeholder="Search Message"
                                classes={{
                                    root: classes.searchInputRoot,
                                    input: classes.searchInputInput,
                                }}
                                inputProps={{ "aria-label": "search" }}
                            // value={query}
                            // onChange={handleChangeQuery}
                            // onKeyUp={handleKeyPress}
                            />
                            {query.length > 0 && (
                                <Clear
                                    // onClick={() => clearSearch()}
                                    style={{ padding: "0px 12px", opacity: 0.6, cursor: "pointer" }}
                                />
                            )}
                        </div>
                        {/* <FormControl className={classes.formRoot}>
                            <Autocomplete
                                id="category"
                                size="small"
                                // options={regionsList}
                                getOptionLabel={(option) => option.name || ''}
                                // value={PlayerFilter?.regions}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                            // onChange={(...args) => handleChangeFilter(args[1], 'regions')}
                            />

                        </FormControl> */}
                    </div>
                    < BuzzlistSection {...currentChat} />

                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p>Fetching Nudges..........</p>
                </div>

            }
        </div >
    );
}

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    chats: getChatList(state.chats, state.client.jid),

});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setSidebarIndex: (options) => dispatch(setSidebarIndex(options)),
        getChats: (options) => dispatch(getChats(options)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PwcBuzzList);