import React, { useEffect, useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import SeasonFilters from '../SeasonFilters';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getDivisionsList, getTeamPointsList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import CustomDataTable from '../../GlobalComponents/CustomDataTable';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import RankLabel from '../RankLabel';
import { getNumberFormat, getSearchValue, setSearchQuery } from '../../../helpers';
import { Info } from '@material-ui/icons';
import { TabBar } from '../../Leaderboards/TabBar';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import IllustrationView from '../../LiveScores/IllustrationView';
import { sortBy } from 'underscore';
import { CustomListGroup } from '../../LiveScores/RoundsListView';
import { DecimalViewSwitch } from '../../LiveScores/CustomSwitch';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const defaultFilters = {
    league: null,
    season: null,
    division: null
};

const pagination = {
    limit: 5,
    page: 0,
}

export const commonColumnOptions = { setCellProps: () => ({ align: 'center' }), setCellHeaderProps: () => ({ align: 'center' }) };

const TeamPointsTable = ({ setLoading, filtersData, setFiltersData, getTeamPointsList, loading, userDetails, getDivisionsList, history, seasonDetails, seasonDivisions }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [seasonWisePoints, setSeasonWisePoints] = useState([]);
    const [divisionsData, setDivisionsData] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [divisions, setDivisions] = useState([]);
    const [decimalView, setDecimalView] = useState(false);
    const [dubDivisions, setDubDivisions] = useState([]);

    const subTabs = (seasonDivisions?.length !== 0) ?
        [{ label: 'By Season', value: 0, icon: '', path: 'seasonWise' }, { label: 'By Division', value: 1, icon: '', path: 'divisionWise' }]
        : [{ label: 'By Season', value: 0, icon: '', path: 'seasonWise' }]


    useEffect(() => {
        if (filtersData.season?.season_format !== 'PLAYER_RACE' && filtersData.season) {
            getTeamPoints(filtersData.season?._id);
            if (filtersData.season?.divisions_enabled) {
                getDivisions(filtersData.season._id);
                setCurrentTab(1);
            }
            else {
                getTeamPoints(filtersData.season?._id);
                setDivisions([]);
            }
            setSearchQuery(history, { viewBy: filtersData.season?.divisions_enabled ? 'division' : 'season' });
        }
        else {
            setSeasonWisePoints([]);
            setDivisionsData([]);
            setDubDivisions([]);
            setDivisions([]);
        }
    }, [filtersData.season]);

    useEffect(() => {
        if (divisions?.length > 0) {
            divisions.map((division, i) => {
                getTeamPoints(filtersData.season?._id, division, i);
            })
        }
        else {
            setDivisionsData([]);
            setDubDivisions([]);
        }
    }, [divisions]);

    const groupByValue = getSearchValue(history, 'viewBy');

    useEffect(() => {
        if (groupByValue) {
            setCurrentTab(groupByValue === 'division' ? 1 : 0);
        }
    }, [groupByValue]);

    const handleSubTabChange = (newValue) => {
        setSearchQuery(history, { viewBy: newValue?.value === 1 ? 'division' : 'season' });
        setCurrentTab(newValue?.value || 0);
    }

    const getDivisions = (seasonId) => {
        setLoading(true);
        setDivisions([]);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: 1 },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const getDivisionMore = () => {
        let seasonId = filtersData?.season?._id;
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: pagination.page + 1 },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const onDivisionSuccessCB = (result, headers) => {
        if (result) {
            setDivisions(prevState => ([...result]));
            setLoading(true);
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }
    const onDivisionErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Divisions', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const getTeamPoints = (seasonId, division, divIndex) => {
        setLoading(true);
        getTeamPointsList({
            params: { season_id: seasonId, division_id: division?._id, limit: 5000, page: 1 },
            onSuccessCB: (res, headers) => {
                const result = res.map(item => ({ ...item, selected: item._id === headers?.my_team_id }));
                if (division) {
                    setDivisionsData(prevState => ([...prevState, ({ ...division, data: result })]));
                    setDubDivisions(prevState => ([...prevState, ({ ...division, data: result })]))
                }
                else {
                    setSeasonWisePoints([...result]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Leagues', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const columns = (tableData) => [
        {
            name: "rank", label: "Rank", options: {
                customBodyRender: (rank, tableMeta) => {
                    const previous_rank = tableData[tableMeta.rowIndex]?.previous_rank;
                    return <RankLabel rank={rank} prevRank={previous_rank} />;
                },
            }
        },
        {
            name: "team", label: "Team", options: {
                customBodyRender: (team, tableMeta) => (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                        <div className={classes.nameRoot}>
                            <AvatarComponent type='TEAM' name={team?.name} username={team?._id} url={team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                        </div>
                        <div style={{ textAlign: 'left', fontWeight: 600 }}>
                            {team && team.name}
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                                {tableData[tableMeta.rowIndex]?.captain && <span style={{ marginRight: 10 }}>C : {tableData[tableMeta.rowIndex]?.captain}</span>}
                                {tableData[tableMeta.rowIndex]?.mentor && <span>M : {tableData[tableMeta.rowIndex]?.mentor}</span>}
                            </div>
                        </div>
                    </div>

                )
            }
        },
        // { name: "captain", label: "Captain" },
        // { name: "mentor", label: "Mentor" },
        { name: "played", label: "Played", options: { ...commonColumnOptions, display: filtersData.season?.season_format !== 'TEAM_RACE' } },
        { name: "won", label: "Won", options: { ...commonColumnOptions, display: filtersData.season?.season_format !== 'TEAM_RACE' } },
        { name: "lost", label: "Lost", options: { ...commonColumnOptions, display: filtersData.season?.season_format !== 'TEAM_RACE' } },
        { name: "draw", label: "Drawn", options: { ...commonColumnOptions, display: filtersData.season?.season_format !== 'TEAM_RACE' } },
        { name: "score", label: "Goals For", options: { ...commonColumnOptions, display: filtersData.season?.season_format !== 'TEAM_RACE', customBodyRender: (score) => getNumberFormat(userDetails?.countryCode, score?.for, decimalView) } },
        { name: "score", label: "Goals Against", options: { ...commonColumnOptions, display: filtersData.season?.season_format !== 'TEAM_RACE', customBodyRender: (score) => getNumberFormat(userDetails?.countryCode, score?.against, decimalView) } },
        {
            name: "score", label: "Goal Difference", options: {
                ...commonColumnOptions,
                customBodyRender: (score) => getNumberFormat(userDetails?.countryCode, score.difference, decimalView),
                display: filtersData.season?.season_format !== 'TEAM_RACE'
            }
        },
        { name: "match_points", label: `Match/${seasonDetails?.labels_config?.kpis?.core} Pts`, options: { ...commonColumnOptions, customBodyRender: (match_points) => getNumberFormat(userDetails?.countryCode, match_points, decimalView) } },
        { name: "bonus_points", label: `${seasonDetails?.labels_config?.kpis?.bonus} Points`, options: { ...commonColumnOptions, customBodyRender: (bonus_points) => getNumberFormat(userDetails?.countryCode, bonus_points, decimalView) } },
        {
            name: "totalpoints", label: "Total Pts", options: {
                ...commonColumnOptions,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
                customHeadRender: () =>
                    <th className="MuiTableCell-head" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>Total Pts</span>
                        <Tooltip interactive arrow title={<p>Total Pts = Match/Core Pts + Bonus Pts</p>}>
                            <Info style={{ fontSize: 16, marginLeft: 5 }} />
                        </Tooltip>
                    </th>
            }
        },
    ];

    return (
        <>
            {/* <SeasonFilters filtersData={filtersData} setFiltersData={setFiltersData} defaultFilters={defaultFilters} hasDivision={false} /> */}
            {
                filtersData.season?.season_format === 'PLAYER_RACE' ?
                    <div style={{ textAlign: 'center', marginTop: 40 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>Since selected season is of the format 'Player race', we do not have any team stats to show here</p>
                    </div> :
                    <>
                        {/* <TabBar tabs={subTabs} subTabVal={currentTab} handleSubTabChange={handleSubTabChange} /> */}
                        <CustomListGroup data={subTabs} selectedItem={subTabs.find((option) => option.value === currentTab)} onClick={handleSubTabChange} style={{ marginLeft: 0 }} />
                        {!loading &&
                            [
                                (currentTab === 0) ?
                                    <div style={{ width: '99%' }}>
                                        <p className="table-title decimal-switch-root">
                                            <p style={{ marginBottom: 5, fontWeight: 600, color: '#000000c9' }}>Season : {filtersData.season?.name}</p>
                                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                                        </p>
                                        <CustomDataTable columns={columns(seasonWisePoints)} data={seasonWisePoints} loading={loading} />
                                    </div>
                                    :
                                    (
                                        dubDivisions.length > 0 ?
                                            <InfiniteScroll
                                                dataLength={dubDivisions.length}
                                                next={getDivisionMore}
                                                hasMore={dubDivisions.length !== pagination.total_count}
                                                // loader={skeletonLoading}
                                                scrollableTarget="points_tabledata"
                                            >
                                                {
                                                    sortBy(dubDivisions, 'name', 'asc').map((data, i) => (
                                                        <div style={{ width: '99%' }}>
                                                            <p className="table-title decimal-switch-root">
                                                                <p style={{ marginBottom: 5, fontWeight: 600, color: '#000000c9' }}>Division : {data.name}</p>
                                                                {i === 0 && < DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />}
                                                            </p>
                                                            <CustomDataTable columns={columns(data.data)} data={data.data} loading={loading} />
                                                        </div>
                                                    ))
                                                }
                                            </InfiniteScroll >
                                            :
                                            < IllustrationView loading={loading} style={{ marginTop: '91px' }} />
                                    )
                            ]

                        }
                    </>
            }

        </>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading,
    seasonDetails: state.game.seasonDetails
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamPointsList: (options) => dispatch(getTeamPointsList(options)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamPointsTable));