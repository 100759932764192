import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import classNames from "classnames";
import { ExpandMore } from '@material-ui/icons';
import { timezonesList } from '../../helpers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ScheduleMeeting from './ScheduleMeeting';

const useStyles = makeStyles((theme) => ({
    mainRoot: {
        backgroundColor: '#f5f5f5',
        padding: 35,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    root: {
        backgroundColor: '#FFFFFF',
        padding: '35px 40px',
        borderRadius: 6,
        color: '#535353',
        [theme.breakpoints.down('xs')]: {
            padding: '35px 20px',
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 600
    },
    pageButtonRoot: {
        display: "flex",
        justifyContent: "center",
        marginTop: 38,
        "& .isActive": {
            backgroundColor: "#828282",
            color: "#FFFFFF"
        }
    },
    pageButton: {
        borderRadius: 22,
        margin: "0 17px",
        backgroundColor: "#e6e6e6",
        padding: '8px 18px',
        fontSize: 17,
        "&:hover": {
            backgroundColor: "#9b9b9b80"
        },
    },
}));

const customizedSelectStyles = makeStyles((theme) => ({
    privacySelect: {
        padding: '4px 32px 4px 8px',
        fontSize: 14,
        '& svg': {
            marginRight: 8
        }
    },
}));

const CustomizedSelect = ({ value, handleChange = () => { }, options = [], label, ...others }) => {
    const classes = customizedSelectStyles();
    return (
        // <Autocomplete
        //     id="combo-box-demo"
        //     options={top100Films}
        //     getOptionLabel={(option) => option.title}
        //     style={{ width: 300 }}
        //     renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
        // />
        <Select
            id="select-outlined"
            value={value}
            onChange={handleChange}
            // label={label}
            fullWidth
            {...others}
            variant="outlined"
            classes={{ outlined: classes.privacySelect }}
            style={{ borderRadius: 6, height: 53 }}
            IconComponent={ExpandMore}
        >
            {
                options.map((option, i) => (
                    <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
                ))
            }
        </Select>
    )
}

const NewMeeting = (props) => {
    const classes = useStyles();

    const [currentPage, setCurrentPage] = useState('MEETING_DETAILS');

    const handlePageChange = value => {
        if (currentPage !== value) setCurrentPage(value);
    };

    return (
        <div className={classes.mainRoot}>
            <div className={classes.root}>
                <span className={classes.title}>New Meeting</span>
                <div className={classes.pageButtonRoot}>
                    <Button variant="text" className={classNames(classes.pageButton, currentPage === "MEETING_DETAILS" && "isActive")} onClick={() => handlePageChange("MEETING_DETAILS")}>
                        Meeting Details
                    </Button>
                    {/* <Button disabled variant="text" className={classNames(classes.pageButton, currentPage === "SCHEDULING_ASSISTANT" && "isActive")} onClick={() => handlePageChange("SCHEDULING_ASSISTANT")}>
                        Scheduling Assistant
                    </Button> */}
                </div>
                <ScheduleMeeting {...props} />
            </div>
        </div>
    )
}

export default NewMeeting;