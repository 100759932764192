import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFollowingUserList, getFollowUserList } from '../../ducks/favourites';
import { useSnackbar } from 'notistack';
import { ChaseonsSkeleton, } from '../Arena/ReactionsDialog';
import UserProfileCard from '../UserProfileCard/UserProfileCard';
import { getProfileUrl } from '../Arena';//
import { getTeamProfileUrl } from '../Arena';
import ProfileLogo from '../AvatarComponent/ProfileLogo';


const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 3,
    },
    listRoot: {
        boxShadow: "0px 0px 3px 1px #EDEDED",
        padding: '8px 0px 0px 0px',
        backgroundColor: '#FFF',
    },
    titleRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontSize: 15,
        color: '#262626',
        fontWeight: '600',
        margin: '0',
        paddingBottom: '8px'
    },
    seeAllButton: {
        fontSize: 10,
        color: '#2626266E',
        padding: 0,
        textTransform: 'none'
    },
    avatarStyles: {
        width: 28,
        height: 28,
        fontSize: 16
    },
    profileName: {
        fontSize: 14,
        fontWeight: 600,

    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px', padding: '9px', borderBottom: '1px solid #eaeaea'


    },
    avatarStyles: {
        width: 28,
        height: 28,
        fontSize: 16,
        marginRight: 14
    }
}));

const PlayersList = ({ user, keycloak, getFollowUserList, getFollowingUserList, history, staticContext, playersList, setFetchAll, fetchAll, filtersData, ...others }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.root} {...others}>
                <div className={classes.titleRoot}>
                    <p className={classes.title}>Players ({playersList ? playersList.totalCount : ''})</p>
                    {
                        playersList.totalCount === playersList.data.length && fetchAll === false ? null :
                            fetchAll === false ?
                                <Button
                                    variant="text"
                                    className={classes.seeAllButton}
                                    onClick={() => setFetchAll(!fetchAll)}

                                >
                                    See all
                                </Button>
                                : <Button
                                    variant="text"
                                    className={classes.seeAllButton}
                                    onClick={() => setFetchAll(!fetchAll)}
                                >
                                    See less
                                </Button>

                    }
                </div>
                <div className={classes.listRoot}>
                    {!playersList.loading && [0].map(i => <ChaseonsSkeleton secondary={false} key={i} />) ?

                        playersList.data.length && playersList.data.map((item, i) => (
                            <div className={classes.nameRoot}>

                                <ProfileLogo type="PLAYER" from="player" name={item.name} username={item.employee_id} url={item.photoUrl} onClick={() => { }}
                                    filtersData={filtersData} />
                                <span className={classes.profileName}>{item && item.name}</span>
                            </div>

                        )) : playersList.loading && [0, 1, 2, 3].map(i => <ChaseonsSkeleton secondary={false} key={i} />)
                    }
                </div>
            </div>

        </>
    )
}


export default withRouter(PlayersList);