import React, { useState } from 'react';
import { Button, ButtonGroup, makeStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from '@material-ui/icons';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#F0F2F5'
    },
    document: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 50px)',
        minWidth: 250,
        '& #button-group': {
            visibility: 'hidden',
            '&:hover': {
                visibility: 'visible'
            }
        },
        '&:hover': {
            '& #button-group': {
                visibility: 'visible'
            }
        },
        '& canvas': {
            maxWidth: '100%'
        },
        '& .react-pdf__Page__textContent': {
            maxWidth: '100%'
        }
    },
    documentOnCard: {
        minHeight: 600,
        display: 'flex',
        alignItems: 'center',
        '& canvas': {
            maxWidth: '100%',
            maxHeight: 600,
            height: 'auto !important',
            width: 'auto !important',
        },
        '& .react-pdf__Page__textContent': {
            maxWidth: '100%',
            maxHeight: 600,
            height: 'auto !important',
            width: 'auto !important',
        }
    },
    documentFromCreate: {
        minHeight: 573,
        display: 'flex',
        alignItems: 'center',
        '& canvas': {
            maxWidth: '100%',
            maxHeight: 573,
            height: 'auto !important',
            width: 'auto !important',
        },
        '& .react-pdf__Page__textContent': {
            maxWidth: '100%',
            maxHeight: 573,
            height: 'auto !important',
            width: 'auto !important',
        }
    },
    buttonGroup: {
        backgroundColor: '#FFF',
        position: 'fixed',
        bottom: 10,
        border: '1px solid #e0cfcf'
    }
}));

const PDFViewer = ({ file = '', fromCard = false, fromCreatePost = false }) => {
    const classes = useStyles();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [height, setHeight] = useState(600);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const zoomIn = () => {
        setScale(prevScale => prevScale + 0.1);
    }

    const zoomOut = () => {
        setScale(prevScale => prevScale - 0.1);
    }

    return (
        <div className={classes.root} style={fromCard ? { maxHeight: 600, minHeight: 600, backgroundColor: '#FFF' } : {}}>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                className={classNames(classes.document, { [classes.documentOnCard]: fromCard, [classes.documentFromCreate]: fromCreatePost })}
            >
                <Page
                    pageNumber={pageNumber}
                    scale={scale}
                    onLoadSuccess={(e) => setHeight((e.originalHeight > 600) ? 600 : e.originalHeight)}
                    loading={<div style={{ minHeight: height, display: 'flex', alignItems: 'center' }}>Loading page…</div>}
                />
                <ButtonGroup id="button-group" className={classes.buttonGroup} size="small" aria-label="small outlined button group" style={{ position: fromCreatePost && 'absolute' }}>
                    <Button disabled={scale <= 0} onClick={zoomOut}><ZoomOut /></Button>
                    <Button disabled={pageNumber <= 1} onClick={previousPage}><ChevronLeft /></Button>
                    <p style={{ fontSize: 14, padding: '0px 8px' }}>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p>
                    <Button disabled={pageNumber >= numPages} onClick={nextPage}><ChevronRight /></Button>
                    <Button disabled={scale >= 2} onClick={zoomIn}><ZoomIn /></Button>
                </ButtonGroup>
            </Document>
        </div>

    );
}

export default PDFViewer;