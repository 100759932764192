import React, { useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ReactComponent as AddUser } from "../../../assets/icons/add-user.svg";
import { ReactComponent as MuteAudio } from "../../../assets/icons/mute-audio.svg";
import { ReactComponent as UnmuteAudio } from "../../../assets/icons/unmute-audio.svg";
import { ReactComponent as MuteVideo } from "../../../assets/icons/video-mute.svg";
import { ReactComponent as UnmuteVideo } from "../../../assets/icons/video-unmute.svg";
import { ReactComponent as Accept } from "../../../assets/icons/close-7.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/correct.svg";
import { getAvatarText } from "../../../helpers";

const nonParicipants = [{ url: "", name: "Manish R patel" }];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    maxWidth: "500px",
    marginLeft: "650px",
  },
  title: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: 600,
    backgroundColor: "#f5f6f7",
    color: "#000000",
    position: "relative",
    padding: "10px 25px",
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 5,
    color:"#BEC2C9",
  },
  avatarStyles: {
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    marginRight: "0.4rem",
    marginTop: "0.3rem",
    fontSize: "20px",
    lineHeight: "23px",
    fontWeight: "bold",
    width: "38px",
    height: "38px",
    color: "#535353",
  },
  both: {
    display: "flex",
    fontSize: "14px",
    lineHeight: "22px",
    justifyContent: "center",
    alignItems: "center",
    margin: "0.5rem 0 0.8rem",
    padding: "0 25px",
  },
  addUser: {
    padding: "0 25px",
    "& span": {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: "normal",
      color: "#000000",
    },
    "& path": {
      fill: "#000000",
      background: "transparent",
    },
  },
  name: {
    display: "block",
    lineHeight: "19px",
    flex: 1,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#535353",
  },
  calling: {
    fontSize: "15px",
    lineHeight: "20px",
    color: "#535353",
  },
  lobbyparticipants: {
    color: "#000000",
    fontSize: "14px",
    lineHeight: "19px",
    padding: "0 25px",
    "& svg": {
      "& path": {
        fill: "#000000",
      },
    },
  },
  participants: {
    color: "#000000",
    fontSize: "14px",
    lineHeight: "19px",
    padding: "0 25px",
  },
  participateNum: {
    flex: 1,
    fontWeight: "bold",
  },
  mute: {
    display: "flex",
  },
  details: {
    flex: 1,
  },
  people: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "0.6rem",
    "& span": {
      flex: 1,
    },
  },
  icon: {
    "& svg": {
      width: "15px",
      height: "19px",
    },
  },
  join: {
    color: "#000000",
    fontSize: "15px",
    lineHeight: "18px",
  },
  inMeeting: {
    color: "#535353",
    "& span": {
      color: "#A8A8A8",
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
}));
const handleClose = () => {};
const Participants = () => {
  const classes = useStyles();
  const [participants, setParticipants] = useState([
    {
      url: "",
      name: "Ashish Gupta",
      role: "host",
      muteAudio: false,
      muteVideo: false,
    },
    { url: "", name: "m.khan@gmail.com", muteAudio: false, muteVideo: false },
    { url: "", name: "Vimla", muteAudio: false, muteVideo: false },
  ]);
  const lobbyMembers = [{ url: "", name: "m.khan@gmail.com" }];
  const handleVideo = (e, index) => {
    const participantsdup = [...participants];
    const muteVideoIndex = index;
    participantsdup[muteVideoIndex].muteVideo = !participantsdup[muteVideoIndex]
      .muteVideo;
    setParticipants(participantsdup);
  };
  const handleAudio = (e, index) => {
    const participantsdup = [...participants];
    const muteAudioIndex = index;
    participantsdup[muteAudioIndex].muteAudio = !participantsdup[muteAudioIndex]
      .muteAudio;
    setParticipants(participantsdup);
  };

  const handleMuteAll = () => {
    let participantsDup = participants.map((item) => {
      if (item.muteAudio || item.muteVideo)
        return { ...item, muteAudio: false, muteVideo: false };
      return item;
    });
    setParticipants(participantsDup);
  };
  // const handleMuteAll = () => {};
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <span>Participants</span>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </div>
      <div className={classes.addUser}>
        <IconButton>
          <AddUser />
        </IconButton>
        <span>Add participants</span>
      </div>
      <Divider />
      <div className={classes.both}>
        <Avatar
          classes={{
            colorDefault: classes.avatarStyles,
            root: classes.avatarStyles,
          }}
          alt={"Adreson Berth"}
          src={""}
        >
          {"Adreson Berth" &&
            getAvatarText("Adreson Berth")}
        </Avatar>

        <span className={classes.name}>{"Adreson Berth"}</span>
        <span className={classes.calling}>calling...</span>
      </div>
      <Divider />
      <div className={classes.lobbyparticipants}>
        <div className={classes.mute}>
          <p className={classes.participateNum}>
            In the lobby ({lobbyMembers.length})
          </p>
        </div>
        {lobbyMembers.map((people) => (
          <>
            <div className={classes.people}>
              <Avatar
                classes={{
                  colorDefault: classes.avatarStyles,
                  root: classes.avatarStyles,
                }}
                alt={people.name}
                src={people.url}
              >
                {people.name &&
                  getAvatarText(people.name)}
              </Avatar>

              <div className={classes.details}>
                <span className={classes.name}>{people.name}</span>
              </div>
              <IconButton>
                <Accept />
              </IconButton>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </div>
          </>
        ))}
      </div>
      <Divider />
      <div className={classes.participants}>
        <div className={classes.mute}>
          <p className={classes.participateNum}>
            In the meeeting ({participants.length})
          </p>
          <p style={{ cursor: "pointer" }} onClick={() => handleMuteAll()}>
            Mute all
          </p>
        </div>

        {participants.map((people, index) => (
          <>
            <div className={classes.people}>
              <Avatar
                classes={{ colorDefault: classes.avatarStyles }}
                alt={people.name}
                src={people.url}
              >
                {people.name &&
                  getAvatarText(people.name)}
              </Avatar>
              <span className={classes.inMeeting}>
                {people.name}{" "}
                <span>{people.role === "host" ? "(Host,me)" : ""}</span>
              </span>
              {people.muteVideo ? (
                <IconButton
                  className={classes.icon}
                  onClick={(e) => handleVideo(e, index, "Video")}
                >
                  <MuteVideo />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.icon}
                  onClick={(e) => handleVideo(e, index, "video")}
                >
                  <UnmuteVideo />
                </IconButton>
              )}
              {people.muteAudio ? (
                <IconButton
                  className={classes.icon}
                  onClick={(e) => handleAudio(e, index)}
                >
                  <MuteAudio />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.icon}
                  onClick={(e) => handleAudio(e, index)}
                >
                  <UnmuteAudio />
                </IconButton>
              )}
            </div>
            <Divider />
          </>
        ))}
        <p className={classes.participateNum}>
          Yet to join the meeting ({nonParicipants.length})
        </p>
        {nonParicipants.map((people) => (
          <div className={classes.people}>
            <Avatar
              classes={{
                colorDefault: classes.avatarStyles,
                root: classes.avatarStyles,
              }}
              alt={people.name}
              src={people.url}
            >
              {people.name &&
                getAvatarText(people.name)}
            </Avatar>
            <span className={classes.join}>{people.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Participants;
