import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Backdrop, CircularProgress } from '@material-ui/core';
import { getFormatIcon } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 25,
        position: 'relative'
    },
    titleMonth: {
        margin: 0,
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.primary.main,
        marginLeft: 16
    },
    listItemContent: {
        width: '100%',
        display: 'flex',
        wordBreak: 'break-all',
        justifyContent: 'space-between',
        margin: '10px 0px 10px 20px'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '7px',
        fontSize: 14,
        lineHeight: "19px",
        color: '#535353'
    },
    secondarytext: {
        margin: 0,
        fontSize: '12px',
        textTransform: 'capitalize',
        color: '#838383'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    emptyListText: {
        textAlign: 'center',
        margin: 30,
        fontSize: 14,
        color: '#58595B'
    }
}));

const Files = ({ details }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    function downloadFiles(uri, name) {
        console.log('downloadFiles ', uri, name);
        var link = document.createElement("a");
        // link.onclick = `javascript:document.execCommand('SaveAs','true', ${name})`;
        link.download = name;
        link.href = uri;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div id="DocFiles" className="scroller" style={{ height: '70vh', maxHeight: '70vh', position: 'relative' }}>
            {
                (details.attachments && details.attachments.length > 0) ?
                    <div className={classes.root}>
                        {/* <p className={classes.titleMonth}>{obj.month}</p> */}
                        <List>
                            {details.attachments.map((item, index) => {
                                return (
                                    <ListItem key={index} button onClick={() => downloadFiles(item.url, item.url && item.url.split('-').pop())} >
                                        <img width="25" src={getFormatIcon((item.url.split('-').pop()).split('.').pop())} alt={'file'} />
                                        <div className={classes.listItemContent}>
                                            <div>
                                                <p className={classes.primaryText}>{item.url && item.url.split('-').pop()}</p>
                                                {/* <p className={classes.secondarytext}>{item.size}</p> */}
                                            </div>
                                        </div>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                    :
                    <p className={classes.emptyListText}>No Attachments Found</p>
            }
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Files;