import React from 'react';
// import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
// import Grow from '@material-ui/core/Grow';
import { connect } from 'react-redux';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { setSnackbar } from '../../ducks/snackbar';

function SlideTransition(props) {
    return <Slide {...props} direction="up" timeout={50} />;
}

// function GrowTransition(props) {
//     return <Grow {...props} />;
// }

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarComponent = ({ snackbar, setSnackbar }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ open: false, message: '', type: '' });
    };

    return (
        <Snackbar
            open={snackbar.open}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            // message={snackbar.message}
            key={'snackbar-bottom-left'}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            style={{ opacity: 0.8 }}
            autoHideDuration={snackbar.duration || null}
        // action={
        //     <IconButton
        //         aria-label="close"
        //         color="inherit"
        //         // className={classes.close}
        //         onClick={handleClose}
        //     >
        //         <Close />
        //     </IconButton>
        // }
        >
            <SnackbarContent style={{ backgroundColor: snackbar.status && '#43a047' }} message={snackbar.message} />
        </Snackbar>
    );
}


const mapStateToProps = (state) => ({
    snackbar: state.snackbar
});

const mapDispatchToProps = (dispatch) => ({
    setSnackbar: (options) => dispatch(setSnackbar(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);