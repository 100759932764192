import { makeStyles } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PlayerProfileMedia from './PlayerProfileMedia';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    mediaView: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: 20,
        boxShadow: "0px 0px 4px 1px #EDEDED",
        background: '#ffff'

    },
    attachment: {
        width: 110,
        height: 90,
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        textAlign: 'center',
        border: '1px solid #EEEEEE',
        position: 'relative',
        margin: '20px 15px 10px',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.5,
        },
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
            height: '100%',
        }
    },
    videoStyles: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#828282",
        position: "relative",
        textAlign: "center",
        color: "#fff",
        overflow: 'hidden',
        "&:hover": {
            cursor: "pointer"
        },
        '& button': {
            position: 'absolute'
        },
        "& img": {
            maxWidth: 'initial',
            [theme.breakpoints.down("xs")]: {
                maxWidth: '100% !important'
            }
        }
    },
    playIcon: {
        color: '#fff'
    },
    seeMoreActionRoot: {
        textAlign: 'center',
        margin: '15px 0 30px',
        '& button': {
            color: '#535353',
            borderColor: '#535353',
            maxWidth: 300
        }
    },
    emptyResultText: {
        textAlign: 'center',
        color: '#535353cc',
        fontSize: 15,
        fontWeight: 600,
        margin: 0,
        padding: 40,
        lineHeight: '25px'
    }
}))

const PlayerMedia = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.about}>
                <p>Photos</p>
            </div>
            <PlayerProfileMedia mediaType={"IMAGE"}  {...props} />
            <div className={classes.about}>
                <p>Videos</p>
            </div>
            <PlayerProfileMedia mediaType={"VIDEO"}  {...props} />
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch, props) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerMedia));

