import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, IconButton, Badge, Backdrop, CircularProgress, SvgIcon, TextareaAutosize, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Chip, Select, MenuItem, Button, Menu } from "@material-ui/core";
import { connect } from "react-redux";
import { CameraAltOutlined, Close, Check, TagFaces, ExpandMore, DeleteForeverOutlined, Add } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { DatePicker } from "@material-ui/pickers";
import { updateProfile, updateWorkList, updateEducationList, getArenaProfile, updateBasicProfile, deleteEducation, deleteWorkExperience } from "../../ducks/user";
import { getFavouriteStatus, followUser, unFollowUser } from '../../ducks/favourites';
import { uploadFilesToS3 } from "../../ducks/common";
import { ReactComponent as AboutIcon } from "../../assets/icons/about.svg";
import { ReactComponent as CakeIcon } from "../../assets/icons/Cake.svg";
import { ReactComponent as GenderIcon } from "../../assets/icons/gender-icon.svg";
import { ReactComponent as FemaleIcon } from "../../assets/icons/female-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg";
import { ReactComponent as MaleIcon } from "../../assets/icons/male-icon.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/Location.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/Phone-3.svg";
import { ReactComponent as HobbiesIcon } from "../../assets/icons/hobbies-icon.svg";
import { ReactComponent as SchoolIcon } from "../../assets/icons/school-2.svg";
import { ReactComponent as BookIcon } from "../../assets/icons/book.svg";
import { ReactComponent as CalenderIcon } from "../../assets/icons/Calendar-4.svg";
import { ReactComponent as JobIcon } from "../../assets/icons/Job.svg";
import { ReactComponent as CompanyIcon } from "../../assets/icons/company.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg";
import { ReactComponent as PublicIcon } from "../../assets/icons/public.svg";
import { ReactComponent as OnlyMeIcon } from "../../assets/icons/only-me.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat-1.svg";
import moment from "moment";
import { withRouter } from 'react-router';
import { red } from "@material-ui/core/colors";
import { MarkFavButton, RemoveFavButton } from "../Arena/ReactionsDialog";
import { setCurrentChat } from "../../ducks/chats";
import { ArenaPreview } from "../Arena/ArenaPreview";
import { ConfirmationDialog } from "../Arena/CreateArenaPost";
import { getAvatarText } from "../../helpers";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f5f5f5",
    paddingBottom: 15
  },
  cardDisplay: {
    backgroundColor: "#FFFFFF"
  },
  badge: {
    border: "2px solid #fff",
    height: "35px",
    width: "35px",
    borderRadius: "20px"
  },
  profilePicRoot: {
    // height: "238px",
    textAlign: "center",
    padding: "24px 0 12px"
  },
  iconRootCam: {
    color: "#fff"
  },
  avatarSize: {
    width: "122px",
    height: "122px",
    margin: '0 auto',
    fontSize: 60,
    backgroundColor: theme.palette.primary.main,
  },
  profileNameRoot: {
    position: 'relative',
    display: 'table',
    margin: 'auto'
  },
  profileNameActions: {
    position: 'absolute',
    marginLeft: 20,
    top: 0,
    right: -100
  },
  profileName: {
    fontSize: 16,
    color: "#141414",
  },
  backdropImage: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    borderRadius: "50%"
  },
  about: {
    backgroundColor: "#f7f7f7",
    padding: "0 18px",
    height: 45,
    margin: "24px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      margin: 0,
      fontSize: 14,
      fontWeight: 600,
      color: '#535353'
    }
  },
  svgIcon: {
    overflow: "inherit",
    // fontSize: "1.7rem"
  },
  listItem: {
    padding: "12px 22px 12px 18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  listItemContent: {
    display: "flex",
    alignItems: "center",
    padding: '0 10px 0 0'
  },
  iconRoot: {
    marginRight: 5
  },
  cardDisplay1: {
    backgroundColor: "#FFFFFF",
    marginTop: "15px",
    padding: "10px 25px"
  },
  actionRoot: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 22px 12px 18px",
    flexWrap: 'wrap'
  },
  profileDetails: {
    fontSize: "15px",
    color: "#535353",
    textTransform: "capitalize"
  },

  editIconStyles: {
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    margin: '0 5px',
    '& svg': {
      '& path': {
        fill: '#FFF'
      }
    },
    '& .delete-icon': {
      height: 14,
      '& path': {
        // fill: '#535353'
      }
    }
  },
  actionButtonStyles: {
    padding: 8
  },
  favAlign: {
    display: "flex",
    justifyContent: "center",
    marginTop: "8px"
  },
  favourite: {
    width: "270px",
    height: "41px",
    borderRadius: "6px",
    backgroundColor: "#ffcc00",
    marginBottom: 10,
    border: 'none'
  },
  favouriteText: {
    fontSize: "14px",
    color: "#535353"
  },

  // Edit about 
  fieldRoot: {
    padding: '12px 18px',
    display: 'flex',
    marginBottom: 30,
  },
  textareaStyles: {
    border: 'none',
    fontSize: 15,
    color: '#535353',
    outline: 'none',
    width: '100%',
    resize: 'none',
    lineHeight: '20px',
    borderBottom: '1px solid #535353',
    marginTop: 6,
    padding: 0,
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    '&:focus': {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  fieldLabel: {
    marginLeft: 12,
    width: '100%',
    '& p:first-child': {
      margin: 0,
      fontSize: 14,
      color: '#535353',
      fontWeight: 600,
      '& .mandatory': {
        color: '#ff0202'
      }
    },
  },
  birthDateField: {
    display: 'flex',
    marginLeft: 12,
    width: '80%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& p': {
      margin: 0,
      fontSize: 14,
      color: '#535353',
      fontWeight: 600,
    },
  },
  radioLabel: {
    color: '#535353',
    marginTop: 15,
    '& .icon-label': {
      display: 'flex',
      alignItems: 'center',
      width: 80,
      fontSize: 14,
      '& svg': {
        marginRight: 15
      }
    }
  },
  inputStyles: {
    fontSize: 15,
    color: '#535353'
  },
  nameInputStyles: {
    textAlign: 'center',
    color: '#141414',
    fontSize: 16,
    fontWeight: 600
  },
  inputUnderine: {
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  chipsRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '0 18px',
    display: 'flex',
    marginBottom: 30,
  },
  chip: {
    margin: theme.spacing(0.5),
    // color: '#535353'
  },
  datePickerStyles: {
    margin: '10px 25px 0 0',
    width: 90
  },
  datePickerInputStyles: {
    paddingLeft: 8,
    fontSize: 14,
    color: '#535353',
    height: '11px',
    cursor: 'pointer',
  },
  dateInputUnderine: {
    border: '1px solid #0000006b',
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:before': {
      border: 'none'
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'none'
    },
    '& svg': {
      fill: '#535353'
    }
  },

  // Fav/UnFav Button

  favButton: {
    height: 36,
    '& path': {
      fill: '#FFF'
    },
    '&:hover': {
      boxShadow: 'none'
    },
    '& .fav-button-text': {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  },

  // Privacy Select
  privacySelect: {
    padding: '4px 32px 4px 8px',
    fontSize: 14,
    '& svg': {
      marginRight: 8
    }
  },

  chatButton: {
    position: 'absolute',
    height: 36,
    minWidth: 55,
    marginLeft: 25,
    backgroundColor: '#a8a8a8',
    '&:hover': {
      boxShadow: 'none'
    },
    '& .MuiButton-startIcon': {
      margin: 0
    }
  },
  chatIconStyle: {
    width: 25,
    height: 25,
    '& path[stroke]': {
      fill: '#FFFFFF',
      stroke: '#FFFFFF'
    }
  },
  primarySvg: {
    '& path': {
      fill: theme.palette.primary.main
    }
  }
}));

let pagination = {
  page: 0,
  limit: 10
};

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultBirthDate = new Date(new Date().getFullYear() - 1, 11, 31);

const ViewProfileDetails = props => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    photoUrl: null,
    email: "",
    phoneNo: "",
    designation: "",
    about: "",
    departmentName: null
  });
  const [editedDetails, setEditedDetails] = useState({})
  const [edit, setEdit] = useState({
    name: false,
    about: false,
    education: false,
    workExperience: false
  });
  const [isFavourite, setIsFavourite] = useState(false);
  const [preview, setPreview] = useState({
    open: false,
    attachments: [],
    attachmentIndex: 0
  });
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState("")
  useEffect(() => {
    console.log("useEffect props.user ", props.user);
    if (isLoggedInUser()) {
      console.log("useEffect isLoggedInUser ", props.user);
      setDetails(props.user);
    }
  }, [props.user]);


  useEffect(() => {
    if (!isLoggedInUser() && props.match.params.id) {
      props.getArenaProfile({
        username: props.match.params.id,
        onEmployeeDetailsSuccess,
        onEmployeeDetailsError
      });
      props.getFavouriteStatus({
        username: props.match.params.id,
        onFavouriteStatusSuccess,
        onFavouriteStatusError
      })
    }
    else {
      setDetails(props.user);
    }
  }, [props.match.params.id]);

  const handleOnCameraClick = () => {
    document.getElementById("hiddenFileInputForProfilePic").click();
  };

  const handlePhotoChange = event => {
    let files = event.target.files;
    if (files && files.length) {
      var formData = new FormData();
      setUploadingImage(true);
      formData.append("file", files[0]);
      props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB });
    }
    setIsEdited(true);
  };

  const onUploadSuccessCB = result => {
    console.log("onUploadSuccessCB ");
    props.updateBasicProfile({
      requestObj: {
        "about": details.about,
        "birthDate": details.dateOfBirth,
        "gender": details.gender,
        "hobbies": details.hobbies,
        "location": details.location,
        "name": details.name,
        "privacy": details.privacy,
        "phoneNo": details.phoneNo,
        "photo_url": result.url
      },
      employeeId: details.employeeId,
      onBasicProfileUpdateSuccess, onBasicProfileUpdateError
    })

    // setDetails(prevState => {
    //   return { ...prevState, photoUrl: result.url };
    // });
  };

  const onUploadErrorCB = result => {
    setUploadingImage(false);
    console.log("onUploadErrorCB ", result);
  };

  const formatUserInfoProperty = userInfo => {
    if (userInfo) {
      return userInfo;
    } else {
      return "--";
    }
  };

  const handleChange = (event) => {
    let editedDetailsDup = JSON.parse(JSON.stringify(editedDetails));
    let id = event.target.id;
    let value = event.target.value;
    console.log('handleChange ', id, value);
    switch (id) {
      case 'name':
        if (value.length <= 100 && !/[~`!@#$%\^&*()+=\-\[\]\\';,./{}|\\":<>\?]/g.test(value)) {
          editedDetailsDup.name = value;
        }
        break;
      case 'info':
        if (value.length <= 300) {
          editedDetailsDup.about = value;
        }
        break;
      case 'location':
        editedDetailsDup.location = value;
        break;
      case 'phoneNo':
        if (value.length <= 10) {
          editedDetailsDup.phoneNo = value;
        }
        break;
      case 'hobbies':
        editedDetailsDup.hobby = value;
        break;
      case 'field':
        editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], fieldOfStudy: value };
        break;
      case 'school':
        editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], schoolOrCollege: value };
        break;
      case 'degree':
        editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], degree: value };
        break;
      case 'company':
        editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], company: value };
        break;
      case 'position':
        editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], position: value };
        break;

      default:
        break;
    }
    setEditedDetails({ ...editedDetailsDup });
  }

  const handleChangePrivacy = (event, id) => {
    let editedDetailsDup = JSON.parse(JSON.stringify(editedDetails));
    let value = event.target.value;
    switch (id) {
      case 'birth-date-privacy':
        if (editedDetailsDup.privacy)
          editedDetailsDup.privacy.date = value
        else
          editedDetailsDup.privacy = { date: value }
        break;
      case 'birth-year-privacy':
        if (editedDetailsDup.privacy)
          editedDetailsDup.privacy.year = value
        else
          editedDetailsDup.privacy = { year: value }
        break;
      default:
        break;
    }
    setEditedDetails({ ...editedDetailsDup });
  }

  const handleChangeRadio = (value) => {
    console.log(value);
    setEditedDetails(prevState => ({ ...prevState, gender: value }));
  }

  const handleChangeHobbiesList = (e) => {
    if (e.nativeEvent.keyCode === 13 && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      setEditedDetails(prevState => ({ ...prevState, hobbies: prevState.hobby ? [...(prevState.hobbies || []), prevState.hobby] : prevState.hobbies, hobby: '' }));
    }
  }

  const handleDeleteHobby = (hobbyToDelete) => {
    setEditedDetails(prevState => ({ ...prevState, hobbies: prevState.hobbies.filter(hobby => hobby !== hobbyToDelete) }))
  }

  const handleDateChange = (date, type) => {
    let editedDetailsDup = JSON.parse(JSON.stringify(editedDetails));
    console.log('handleDateChange ', new Date(date._d).getFullYear(), new Date(date._d).getMonth());
    switch (type) {
      case 'education-from':
        editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], startYear: date._d };
        break;
      case 'education-to':
        editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], endYear: date._d };
        break;
      case 'work-experience-from':
        editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], startYear: date._d };
        break;
      case 'work-experience-to':
        editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], endYear: date._d };
        break;
      case 'birth-month':
      case 'birth-date':
      case 'birth-year':
        editedDetailsDup.dateOfBirth = date._d
        break;
    }
    console.log('handleDateChange after ', editedDetailsDup);
    setEditedDetails({ ...editedDetailsDup });
  }

  const handleUpdate = (type) => {
    console.log('handleUpdate ', type);
    let requestObj = {};
    switch (type) {
      case 'about':
      case 'name':
        requestObj = {
          "about": editedDetails.about,
          "birthDate": editedDetails.dateOfBirth || defaultBirthDate,
          "gender": editedDetails.gender,
          "hobbies": editedDetails.hobbies,
          "location": editedDetails.location,
          "name": editedDetails.name,
          "privacy": editedDetails.privacy,
          "phoneNo": editedDetails.phoneNo,
          "photo_url": editedDetails.photoUrl
        }
        // console.log('requestObj ', requestObj, editedDetails.employeeId);
        props.updateBasicProfile({
          requestObj,
          employeeId: editedDetails.employeeId,
          onBasicProfileUpdateSuccess, onBasicProfileUpdateError
        })
        break;
      case 'education':
        requestObj = {
          "educationId": editedDetails.educations[0].educationId || editedDetails.educationId,
          "educations": [
            {
              "degree": editedDetails.educations[0].degree,
              "fieldOfStudy": editedDetails.educations[0].fieldOfStudy,
              "schoolOrCollege": editedDetails.educations[0].schoolOrCollege,
              "startYear": new Date(editedDetails.educations[0].startYear).getFullYear(),
              "endYear": new Date(editedDetails.educations[0].endYear).getFullYear()
            }
          ]
        }
        props.updateEducationList({
          requestObj,
          username: details.username,
          employeeId: editedDetails.employeeId,
          onEducationUpdateSuccess, onEducationUpdateError
        })
        break;
      case 'workExperience':
        requestObj = {
          "experienceId": editedDetails.workExperience[0].experienceId || editedDetails.experienceId,
          "workExperience": [
            {
              "company": editedDetails.workExperience[0].company,
              "position": editedDetails.workExperience[0].position,
              "startYear": {
                "month": new Date(editedDetails.workExperience[0].startYear).getMonth() + 1,
                "year": new Date(editedDetails.workExperience[0].startYear).getFullYear()
              },
              "endYear": {
                "month": new Date(editedDetails.workExperience[0].endYear).getMonth() + 1,
                "year": new Date(editedDetails.workExperience[0].endYear).getFullYear()
              },
            }
          ]
        }
        props.updateWorkList({
          requestObj,
          username: details.username,
          employeeId: editedDetails.employeeId,
          onWorkUpdateSuccess, onWorkUpdateError
        })
        break;

      default:
        break;
    }
  }

  const onBasicProfileUpdateSuccess = (result) => {
    setUploadingImage(false);
    setEdit(prevState => ({ ...prevState, about: false, name: false }));
  }
  const onBasicProfileUpdateError = (error) => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.errors[0] : 'Failed to update details.' ? error.response.data.errors.length > 0 : error.response.data.errors[0], {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const onEducationUpdateSuccess = result => {
    setEdit(prevState => ({ ...prevState, education: false }));
  };
  const onEducationUpdateError = error => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to update Education details.', {
      variant: 'error',
      preventDuplicate: true
    });

  };

  const onWorkUpdateSuccess = result => {
    setEdit(prevState => ({ ...prevState, workExperience: false }));
  };
  const onWorkUpdateError = error => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to update Work-Experience details.', {
      variant: 'error',
      preventDuplicate: true
    });
  };
  const handleConfirmationDialog = (value, text1, text2) => {
    if (text1 === "yes" && text2 === "Education") {
      setConfirmationDialog(false);
      props.deleteEducation({
        details: {
          employeeId: details.employeeId,
          educationId: details.educations[0].educationId || details.educationId,
          updatedObj: { ...details, educations: [] }
        },
        onEducationDeleteSuccess, onEducationDeleteError
      })
    }
    else if (text1 === "yes" && text2 === "Experience") {
      setConfirmationDialog(false);
      props.deleteWorkExperience({
        details: {
          employeeId: details.employeeId,
          workExperienceId: details.workExperience[0].experienceId || details.experienceId,
          updatedObj: { ...details, workExperience: [] }
        },
        onWorkExperienceDeleteSuccess, onWorkExperienceDeleteError
      })
    }
    else {
      setDeleteItem(text1)
      setConfirmationDialog(value)
    }
  }
  const handleEditClick = (type) => {
    setEdit(prevState => ({ ...prevState, [type]: true }));
    let detailsDup = JSON.parse(JSON.stringify(details));
    detailsDup.educations = (detailsDup.educations && detailsDup.educations.length > 0) ?
      detailsDup.educations.map(obj => ({
        ...obj, startYear: new Date((obj.startYear ? obj.startYear : new Date().getFullYear()), 0, 1), endYear: new Date((obj.endYear ? obj.endYear : new Date().getFullYear()), 0, 1)
      }))
      :
      detailsDup.educations = [{
        "educationId": "",
        "degree": "",
        "fieldOfStudy": "",
        "schoolOrCollege": "",
        "startYear": new Date(),
        "endYear": new Date()
      }];
    detailsDup.workExperience = (detailsDup.workExperience && detailsDup.workExperience.length > 0) ?
      detailsDup.workExperience.map(obj => ({
        ...obj,
        startYear: new Date((obj.startYear.year ? obj.startYear.year : new Date().getFullYear()), (obj.startYear.month ? (obj.startYear.month - 1) : new Date().getMonth()), 1),
        endYear: new Date((obj.endYear.year ? obj.endYear.year : new Date().getFullYear()), (obj.endYear.month ? (obj.endYear.month - 1) : new Date().getMonth()), 1),
        // endYear: new Date(obj.endYear.year, obj.endYear.month, 1)
      }))
      :
      detailsDup.workExperience = [{
        "experienceId": "",
        "company": "",
        "position": "",
        "endYear": new Date(),
        "startYear": new Date()
      }]
    setEditedDetails({ ...detailsDup });
  }

  const handleDeleteClick = (type) => {
    switch (type) {
      case 'education':
        handleConfirmationDialog(true, "Education");
        break;
      case 'workExperience':
        handleConfirmationDialog(true, "Experience");
        break;
      default:
        break;
    }
  }

  const onEducationDeleteSuccess = (result) => { }
  const onEducationDeleteError = (error) => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to delete Education details.', {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const onWorkExperienceDeleteSuccess = (result) => { }
  const onWorkExperienceDeleteError = (error) => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to delete Work-Experience details.', {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const ActionButtons = ({ type, data, hasDelete, addNewIcon, ...others }) => (
    <div {...others}>
      {edit[type] ?
        <>
          <IconButton title="Cancel" onClick={() => setEdit(prevState => ({ ...prevState, [type]: false }))} className={classes.actionButtonStyles}><Close /></IconButton>
          <IconButton title="Save" onClick={() => handleUpdate(type)} className={classes.actionButtonStyles}><Check /></IconButton>
        </>
        :
        <>
          <IconButton title="Edit" onClick={() => handleEditClick(type, data)} className={classes.editIconStyles}>{addNewIcon ? <Add fontSize="small" /> : <EditIcon />}</IconButton>
          {
            hasDelete &&
            <IconButton title="Delete" onClick={() => { handleDeleteClick(type) }} className={classes.editIconStyles}><DeleteIcon className="delete-icon" /></IconButton>
          }
        </>
      }
    </div>
  )

  const onFollowUser = (follow) => {
    if (follow) {
      let requestObj = {
        username: props.match.params.id
      };
      props.followUser({
        requestObj,
        onFollowUserSuccess,
        onFollowUserError
      });
    }
    else {
      props.unFollowUser({
        username: props.match.params.id,
        onUnFollowUserSuccess,
        onUnFollowUserError
      })
    }
  };

  const onFollowUserSuccess = success => {
    setIsFavourite(true);
  };
  const onFollowUserError = success => {
  };

  const onUnFollowUserSuccess = result => {
    setIsFavourite(false);
  }
  const onUnFollowUserError = error => {
    console.log('onUnFollowUserError ', error.response);
  }

  const isLoggedInUser = () => {
    if (props.match.params.id && props.match.params.id !== props.user.username) {
      return false
    }
    else {
      return true;
    }
  }

  const onEmployeeDetailsSuccess = result => {
    setDetails(result);
  };
  const onEmployeeDetailsError = error => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch details.', {
      variant: 'error',
      preventDuplicate: true
    });
  };

  const onFavouriteStatusSuccess = result => {
    setIsFavourite(result.follower);
  }
  const onFavouriteStatusError = error => {
    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch favourite status.', {
      variant: 'error',
      preventDuplicate: true
    });
  }

  const handleChatClick = (userDetails) => {
    props.setCurrentChat({ targetJid: `${userDetails.username}@${process.env.REACT_APP_XMPP_DOMAIN}`, chatId: `${userDetails.username}@${process.env.REACT_APP_XMPP_DOMAIN}`, targetName: userDetails.name, presence: props.presence ? (props.presence.show || 'unavailable') : 'unavailable', photo_url: userDetails.photoUrl, type: 'chat' });
    props.history.push('/chats');
  }

  const handlePreview = (attachments, attachmentIndex) => {
    setPreview({ open: true, attachments, attachmentIndex });
  }

  const handlePreviewClose = () => {
    setPreview({ open: false, attachments: [] });
  }

  const CustomDatePicker = ({ views, value, onChange, ...others }) => {
    return (
      <DatePicker
        {...others}
        views={views}
        value={value}
        onChange={onChange}
        className={classes.datePickerStyles}
        InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <ExpandMore /> }}
      />
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.cardDisplay}>
        <div className={classes.profilePicRoot}>
          {isLoggedInUser() ?
            <>
              <Badge
                overlap={"circle"}
                variant="circle"
                color="primary"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                classes={{ badge: classes.badge }}
                badgeContent={
                  <IconButton onClick={() => handleOnCameraClick()}>
                    <CameraAltOutlined
                      classes={{ root: classes.iconRootCam }}
                      fontSize={"small"}
                    />
                  </IconButton>
                }
              >
                <Avatar
                  id="profileImg"
                  className={classes.avatarSize}
                  src={details.photoUrl}
                  style={{ cursor: 'pointer' }}
                  onClick={() => details.photoUrl && handlePreview([{ url: details.photoUrl, mimeType: 'IMAGE' }], 0)}
                >{getAvatarText(details.name)}</Avatar>
                <Backdrop className={classes.backdropImage} open={uploadingImage}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Badge>
              <input
                type="file"
                id="hiddenFileInputForProfilePic"
                onChange={e => handlePhotoChange(e)}
                style={{ display: "none" }}
                accept="image/*"
              />
            </>
            :
            <Avatar
              id="profileImg"
              className={classes.avatarSize}
              src={details.photoUrl}
              style={{ cursor: 'pointer' }}
              onClick={() => details.photoUrl && handlePreview([{ url: details.photoUrl, mimeType: 'IMAGE' }], 0)}
            >{getAvatarText(details.name)}</Avatar>
          }
          <div className={classes.profileNameRoot}>
            {(edit.name && isLoggedInUser()) ?
              <TextField
                id="name"
                autoFocus
                placeholder="Enter Your Name.."
                value={editedDetails.name}
                error={!editedDetails.name && true}
                helperText={!editedDetails.name && "Please enter your Name."}
                onChange={handleChange}
                style={{ margin: '10px 0 20px' }}
                InputProps={{ classes: { input: classes.nameInputStyles, underline: classes.inputUnderine, underline: classes.inputUnderine } }}
              />
              :
              <h3 className={classes.profileName}>
                {formatUserInfoProperty(details.name)}
              </h3>
            }
            {isLoggedInUser() && <ActionButtons type="name" className={classes.profileNameActions} style={{ marginTop: !edit.name && 10 }} />}
          </div>
          {!isLoggedInUser() &&
            <div style={{ position: 'relative' }}>
              {
                !isFavourite ?
                  <MarkFavButton
                    className={classes.favButton}
                    onClick={() => onFollowUser(true)}
                  />
                  :
                  <RemoveFavButton
                    className={classes.favButton}
                    onClick={() => onFollowUser(false)}
                  />
              }
              <Button title="Chat" className={classes.chatButton} onClick={() => handleChatClick(details)} variant="contained" startIcon={<ChatIcon className={classes.chatIconStyle} />} />
            </div>
          }
        </div>
      </div>
      <div className={classes.cardDisplay1}>
        <div className={classes.about}><p>About</p>{isLoggedInUser() && <ActionButtons type="about" />}</div>
        {
          (edit.about && isLoggedInUser()) ?
            <>
              {/* <div className={classes.fieldRoot}>
                <div><AboutIcon /></div>
                <div className={classes.fieldLabel}>
                  <p>Name</p>
                  <TextField
                    id="name"
                    placeholder="Enter Your Name.."
                    fullWidth
                    value={editedDetails.name}
                    error={!editedDetails.name && true}
                    helperText={!editedDetails.location && "Please enter your Name."}
                    onChange={handleChange}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine, underline: classes.inputUnderine } }}
                  />
                </div>
              </div> */}
              <div className={classes.fieldRoot}>
                <div><AboutIcon /></div>
                <div className={classes.fieldLabel}>
                  <p>Info</p>
                  <TextareaAutosize
                    color="primary"
                    id="info"
                    placeholder="Information about you.."
                    value={editedDetails.about}
                    className={classes.textareaStyles}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div className={classes.fieldRoot} style={{ width: '100%' }}>
                  <div><CakeIcon /></div>
                  <div className={classes.birthDateField} >
                    <p>Birthday</p>
                    <CustomDatePicker
                      views={["month"]}
                      format={"MMMM"}
                      maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                      value={editedDetails.dateOfBirth || defaultBirthDate}
                      onChange={(e) => handleDateChange(e, 'birth-month')}
                      style={{ margin: 0 }}
                    />
                    <CustomDatePicker
                      views={["date"]}
                      format={"DD"}
                      maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                      value={editedDetails.dateOfBirth || defaultBirthDate}
                      onChange={(e) => handleDateChange(e, 'birth-date')}
                      style={{ margin: 0, width: 60 }}
                    />
                  </div>
                </div>
                <div className={classes.fieldRoot}>
                  <Select
                    id="birth-date-privacy"
                    value={(editedDetails.privacy && editedDetails.privacy.hasOwnProperty('date')) ? editedDetails.privacy.date : true}
                    onChange={(e) => handleChangePrivacy(e, 'birth-date-privacy')}
                    variant={"outlined"}
                    classes={{ outlined: classes.privacySelect }}
                    style={{ borderRadius: 4, width: 110, height: 26 }}
                    IconComponent={ExpandMore}
                  >
                    <MenuItem value={true}><PublicIcon style={{ marginRight: 8 }} />Public</MenuItem>
                    <MenuItem value={false}><OnlyMeIcon style={{ marginRight: 8 }} /><span style={{ color: '#53535380' }}>Only me</span></MenuItem>
                  </Select>
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div className={classes.fieldRoot} style={{ width: '100%' }}>
                  <div><CakeIcon /></div>
                  <div className={classes.birthDateField} style={{ width: '50%' }}>
                    <p>Birth Year</p>
                    <CustomDatePicker
                      views={["year"]}
                      maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                      value={editedDetails.dateOfBirth || defaultBirthDate}
                      onChange={(e) => handleDateChange(e, 'birth-year')}
                      style={{ margin: 0 }}
                    />
                  </div>
                </div>
                <div className={classes.fieldRoot}>
                  <Select
                    id="birth-year-privacy"
                    value={(editedDetails.privacy && editedDetails.privacy.hasOwnProperty('year')) ? editedDetails.privacy.year : true}
                    onChange={(e) => handleChangePrivacy(e, 'birth-year-privacy')}
                    variant={"outlined"}
                    classes={{ outlined: classes.privacySelect }}
                    style={{ borderRadius: 4, width: 110, height: 26 }}
                    IconComponent={ExpandMore}
                  >
                    <MenuItem value={true}><PublicIcon style={{ marginRight: 8 }} />Public</MenuItem>
                    <MenuItem value={false}><OnlyMeIcon style={{ marginRight: 8 }} /><span style={{ color: '#53535380' }}>Only me</span></MenuItem>
                  </Select>
                </div>
              </div>
              <div className={classes.fieldRoot}>
                <div><GenderIcon /></div>
                <div className={classes.fieldLabel}>
                  {/* <p>Gender<span className="mandatory"> *</span></p> */}
                  <FormControl component="fieldset" className={classes.formControl}>
                    <RadioGroup aria-label="gender" name="gender1" onChange={(e, value) => handleChangeRadio(value)}>
                      <FormControlLabel labelPlacement="start"
                        className={classes.radioLabel}
                        value={"female"}
                        checked={editedDetails.gender === 'female'}
                        control={<Radio icon={<CheckIcon style={{ visibility: 'hidden' }} />} checkedIcon={<CheckIcon />} />}
                        label={<span className={'icon-label'}><FemaleIcon /> Female</span>}
                      />
                      <FormControlLabel labelPlacement="start"
                        className={classes.radioLabel}
                        checked={editedDetails.gender === 'male'}
                        value={"male"}
                        control={<Radio icon={<CheckIcon style={{ visibility: 'hidden' }} />} checkedIcon={<CheckIcon />} />}
                        label={<span className={'icon-label'}><MaleIcon /> Male</span>}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className={classes.fieldRoot}>
                <div><LocationIcon /></div>
                <div className={classes.fieldLabel}>
                  <p>Location
                    {/* <span className="mandatory"> *</span> */}
                  </p>
                  <TextField
                    id="location"
                    value={editedDetails.location}
                    fullWidth
                    placeholder="Location"
                    // error={!editedDetails.location && true}
                    // helperText={!editedDetails.location && "Please enter location."}
                    onChange={handleChange}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine, underline: classes.inputUnderine } }}
                  />
                </div>
              </div>
              <div className={classes.fieldRoot}>
                <div><PhoneIcon /></div>
                <div className={classes.fieldLabel}>
                  <p>Phone
                   {/* <span className="mandatory"> *</span> */}
                  </p>
                  <TextField
                    id="phoneNo"
                    value={editedDetails.phoneNo}
                    fullWidth
                    placeholder="Phone number"
                    // error={(editedDetails.phoneNo && editedDetails.phoneNo.length === 10) ? false : true}
                    // helperText={(editedDetails.phoneNo && editedDetails.phoneNo.length === 10) ? '' : "Please enter valid Phone number."}
                    type="number"
                    onChange={handleChange}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                  />
                </div>
              </div>
              <div className={classes.fieldRoot}>
                <div><HobbiesIcon /></div>
                <div className={classes.fieldLabel}>
                  <p>Hobbies</p>
                  <TextField
                    fullWidth
                    placeholder="Type your hobby and press enter."
                    id="hobbies"
                    value={editedDetails.hobby}
                    onChange={handleChange}
                    onKeyUp={handleChangeHobbiesList}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                  />
                </div>
              </div>
              <ul className={classes.chipsRoot}>
                {editedDetails.hobbies && editedDetails.hobbies.map((hobby, index) => (
                  <li key={index}>
                    <Chip
                      label={hobby}
                      onDelete={() => handleDeleteHobby(hobby)}
                      className={classes.chip}
                      color="primary"
                    />
                  </li>
                ))}
              </ul>
            </>
            :
            <>
              <div className={classes.listItem}>
                <div className={classes.listItemContent}>
                  <div className={classes.iconRoot}>
                    <SvgIcon className={classes.svgIcon} component={AboutIcon} />
                  </div>
                  <div className={classes.profileDetails}>
                    {formatUserInfoProperty(details.about)}
                  </div>
                </div>
              </div>
              <div className={classes.actionRoot}>
                {
                  ((details.publicDetails && Object.values(details.publicDetails).some(item => item !== null)) || (details.username === props.user.username)) &&
                  <div className={classes.listItemContent}>
                    <div className={classes.iconRoot}>
                      <SvgIcon className={classes.svgIcon} component={CakeIcon} />
                    </div>
                    <div className={classes.profileDetails}>
                      {(details.username === props.user.username)
                        ? (details.dateOfBirth ? moment(details.dateOfBirth).format("D MMM YYYY") : '--')
                        :
                        details.publicDetails &&
                        `${details.publicDetails.dob_day ? details.publicDetails.dob_day : '-- /'}
                      ${details.publicDetails.dob_month ? months[details.publicDetails.dob_month - 1] : '--- /'}
                      ${details.publicDetails.dob_year ? details.publicDetails.dob_year : ''}`
                      }
                    </div>
                  </div>
                }

                <div className={classes.listItemContent}>
                  <div className={classes.iconRoot}>
                    <SvgIcon className={classes.svgIcon} component={details.gender === 'male' ? MaleIcon : details.gender === 'female' ? FemaleIcon : GenderIcon} />
                  </div>
                  <div className={classes.profileDetails}>
                    {formatUserInfoProperty(details.gender)}
                  </div>
                </div>

                <div className={classes.listItemContent}>
                  <div className={classes.iconRoot}>
                    <SvgIcon className={classes.svgIcon} component={LocationIcon} />
                  </div>
                  <div className={classes.profileDetails}>
                    {formatUserInfoProperty(details.location)}
                  </div>
                </div>
                <div className={classes.listItemContent}>
                  <div className={classes.iconRoot}>
                    <SvgIcon className={classes.svgIcon} component={PhoneIcon} />
                  </div>
                  <div className={classes.profileDetails}>
                    {formatUserInfoProperty(details.phoneNo)}
                  </div>
                </div>
              </div>
              <div className={classes.listItem}>
                <div className={classes.listItemContent}>
                  <div className={classes.iconRoot}>
                    <SvgIcon className={classes.svgIcon} component={HobbiesIcon} />
                  </div>
                  <div className={classes.profileDetails}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {details.hobbies ? details.hobbies.map((hobby, index) => (
                        <div key={index} style={{ margin: '0 25px 10px 0' }}>
                          {hobby}
                        </div>
                      ))
                        :
                        '--'
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
        }
      </div>
      <div className={classes.cardDisplay1}>
        <div className={classes.about}><p>Education</p>{isLoggedInUser() && <ActionButtons type="education" addNewIcon={(details.educations && details.educations.length === 0) && true} hasDelete={(details.educations && details.educations.length > 0) && true} />}</div>
        {
          (edit.education && isLoggedInUser()) ?
            <>
              <div className={classes.fieldRoot}>
                <div><SchoolIcon className={classes.primarySvg} /></div>
                <div className={classes.fieldLabel}>
                  <p>School/College<span className="mandatory"> *</span></p>
                  <TextField
                    id="school"
                    fullWidth
                    value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].schoolOrCollege}
                    onChange={handleChange}
                    placeholder="School/College"
                    error={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].schoolOrCollege) && true}
                    helperText={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].schoolOrCollege) && "Please enter School/College."}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                  />
                </div>
              </div>
              <div className={classes.fieldRoot}>
                <div><BookIcon className={classes.primarySvg} /></div>
                <div className={classes.fieldLabel}>
                  <p>Field of study<span className="mandatory"> *</span></p>
                  <TextField
                    id="field"
                    fullWidth
                    value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].fieldOfStudy}
                    onChange={handleChange}
                    placeholder="Field of study"
                    error={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].fieldOfStudy) && true}
                    helperText={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].fieldOfStudy) && "Please enter Field of study."}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                  />
                </div>
              </div>
              <div className={classes.fieldRoot}>
                <div><SchoolIcon className={classes.primarySvg} /></div>
                <div className={classes.fieldLabel}>
                  <p>Degree<span className="mandatory"> *</span></p>
                  <TextField
                    id="degree"
                    fullWidth
                    value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].degree}
                    onChange={handleChange}
                    placeholder="Degree"
                    error={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].degree) && true}
                    helperText={!(editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].degree) && "Please enter degree."}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className={classes.fieldRoot}>
                  <div><CalenderIcon /></div>
                  <div className={classes.fieldLabel}>
                    <p>From</p>
                    <CustomDatePicker
                      views={["year"]}
                      maxDate={new Date()}
                      value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].startYear}
                      onChange={(e) => handleDateChange(e, 'education-from')}
                    />
                  </div>
                </div>
                <div className={classes.fieldRoot}>
                  <div><CalenderIcon /></div>
                  <div className={classes.fieldLabel}>
                    <p>To</p>
                    <CustomDatePicker
                      views={["year"]}
                      minDate={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].startYear}
                      maxDate={new Date()}
                      value={editedDetails.educations && editedDetails.educations[0] && editedDetails.educations[0].endYear}
                      onChange={(e) => handleDateChange(e, 'education-to')}
                    />
                  </div>
                </div>
              </div>
            </>
            :
            <>
              {details.educations && details.educations.map(educationDetails => {
                return (
                  <React.Fragment>
                    <div className={classes.listItem}>
                      <div className={classes.listItemContent}>
                        <div className={classes.iconRoot}>
                          <SvgIcon
                            className={classes.primarySvg}
                            component={SchoolIcon}
                          />
                        </div>
                        <div className={classes.profileDetails}>
                          {formatUserInfoProperty(educationDetails.schoolOrCollege)}
                        </div>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.listItemContent}>
                        <div className={classes.iconRoot}>
                          <SvgIcon className={classes.primarySvg} component={BookIcon} />
                        </div>
                        <div className={classes.profileDetails}>
                          {(educationDetails.degree || educationDetails.fieldOfStudy) ?
                            <React.Fragment>
                              {formatUserInfoProperty(educationDetails.degree)},
                    {formatUserInfoProperty(educationDetails.fieldOfStudy)}
                            </React.Fragment>
                            : "--"
                          }

                        </div>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.listItemContent}>
                        <div className={classes.iconRoot}>
                          <SvgIcon
                            className={classes.svgIcon}
                            component={CalenderIcon}
                          />
                        </div>
                        <div className={classes.profileDetails}>
                          {(educationDetails.startYear || educationDetails.endYear) ? (
                            <React.Fragment>
                              {educationDetails.startYear
                                ? educationDetails.startYear
                                : "--"}
                        -
                              {educationDetails.endYear
                                ? educationDetails.endYear
                                : "--"}
                            </React.Fragment>
                          ) : (
                              "--"
                            )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
        }
      </div >
      <div className={classes.cardDisplay1}>
        <div className={classes.about}><p>Work Experience</p>{isLoggedInUser() && <ActionButtons type="workExperience" addNewIcon={(details.workExperience && details.workExperience.length === 0) && true} hasDelete={(details.workExperience && details.workExperience.length > 0) && true} />}</div>
        {
          (edit.workExperience && isLoggedInUser()) ?
            <>
              <div className={classes.fieldRoot}>
                <div><CompanyIcon className={classes.primarySvg} /></div>
                <div className={classes.fieldLabel}>
                  <p>Company/Organization<span className="mandatory"> *</span></p>
                  <TextField
                    id="company"
                    fullWidth
                    placeholder="Organization name"
                    value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].company}
                    onChange={handleChange}
                    error={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].company) && true}
                    helperText={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].company) && "Please enter Company/Organization."}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine } }}
                  />
                </div>
              </div>
              <div className={classes.fieldRoot}>
                <div><JobIcon style={{ margin: '0 5px' }} /></div>
                <div className={classes.fieldLabel}>
                  <p>Position<span className="mandatory"> *</span></p>
                  <TextField
                    id="position"
                    fullWidth
                    placeholder="Position"
                    value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].position}
                    onChange={handleChange}
                    error={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].position) && true}
                    helperText={!(editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].position) && "Please enter Position."}
                    InputProps={{ classes: { input: classes.inputStyles, underline: classes.inputUnderine, underline: classes.inputUnderine } }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className={classes.fieldRoot}>
                  <div><CalenderIcon /></div>
                  <div className={classes.fieldLabel}>
                    <p>From</p>
                    <div style={{ display: 'flex' }}>
                      <CustomDatePicker
                        views={["year"]}
                        maxDate={new Date()}
                        value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].startYear}
                        onChange={(e) => handleDateChange(e, 'work-experience-from')}
                      />
                      <CustomDatePicker
                        disableToolbar
                        format={"MMMM"}
                        views={["month"]}
                        maxDate={new Date()}
                        value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].startYear}
                        onChange={(e) => handleDateChange(e, 'work-experience-from')}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.fieldRoot}>
                  <div><CalenderIcon /></div>
                  <div className={classes.fieldLabel}>
                    <p>To</p>
                    <div style={{ display: 'flex' }}>
                      <CustomDatePicker
                        views={["year"]}
                        minDate={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].startYear}
                        maxDate={new Date()}
                        value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].endYear}
                        onChange={(e) => handleDateChange(e, 'work-experience-to')}
                      />
                      <CustomDatePicker
                        disableToolbar
                        format={"MMMM"}
                        views={["month"]}
                        minDate={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].startYear}
                        maxDate={new Date()}
                        value={editedDetails.workExperience && editedDetails.workExperience[0] && editedDetails.workExperience[0].endYear}
                        onChange={(e) => handleDateChange(e, 'work-experience-to')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              {details.workExperience && details.workExperience.map(workDetails => {
                return (
                  <React.Fragment>
                    <div className={classes.listItem}>
                      <div className={classes.listItemContent}>
                        <div className={classes.iconRoot}>
                          <SvgIcon
                            className={classes.primarySvg}
                            component={CompanyIcon}
                          />
                        </div>
                        <div className={classes.profileDetails}>
                          {formatUserInfoProperty(workDetails.company)}
                        </div>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.listItemContent}>
                        <div className={classes.iconRoot}>
                          <SvgIcon className={classes.svgIcon} component={JobIcon} />
                        </div>
                        <div className={classes.profileDetails}>
                          {formatUserInfoProperty(workDetails.position)}
                        </div>
                      </div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.listItemContent}>
                        <div className={classes.iconRoot}>
                          <SvgIcon
                            className={classes.svgIcon}
                            component={CalenderIcon}
                          />
                        </div>
                        <div className={classes.profileDetails}>
                          {(workDetails.startYear || workDetails.endYear) ? (
                            <React.Fragment>
                              {(workDetails.startYear.year && workDetails.startYear.month) ? moment(new Date(workDetails.startYear.year, workDetails.startYear.month - 1, 1)).format("YYYY MMM") : "--"}-
                              {(workDetails.endYear.year && workDetails.endYear.month) ? moment(new Date(workDetails.endYear.year, workDetails.endYear.month - 1, 1)).format("YYYY MMM") : "--"}
                            </React.Fragment>) : "--"
                          }
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
        }
      </div>
      {preview.open && <ArenaPreview open={preview.open} attachmentIndex={preview.attachmentIndex} arena={{ attachments: preview.attachments }} handlePreviewClose={handlePreviewClose} isFromProfile={true} />}
      <ConfirmationDialog open={openConfirmationDialog} content={`Are you sure you want to Delete ${deleteItem}?`} handleConfirmationDialog={handleConfirmationDialog} deleteItem={deleteItem} />
    </div >
  );
};

const mapStateToProps = (state, props) => ({
  user: state.user,
  presence: state.presence[`${props.match.params.id}@${process.env.REACT_APP_XMPP_DOMAIN}`]
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateBasicProfile: options => dispatch(updateBasicProfile(options)),
    updateEducationList: value => dispatch(updateEducationList(value)),
    updateWorkList: value => dispatch(updateWorkList(value)),
    getArenaProfile: value => dispatch(getArenaProfile(value)),
    followUser: options => dispatch(followUser(options)),
    unFollowUser: options => dispatch(unFollowUser(options)),
    updateProfile: options => dispatch(updateProfile(options)),
    uploadFilesToS3: options => dispatch(uploadFilesToS3(options)),
    getFavouriteStatus: options => dispatch(getFavouriteStatus(options)),
    deleteEducation: options => dispatch(deleteEducation(options)),
    deleteWorkExperience: options => dispatch(deleteWorkExperience(options)),
    setCurrentChat: (options) => dispatch(setCurrentChat(options)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewProfileDetails));
