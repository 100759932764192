import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MessageInput from '../ChatSection/MessageInput';
import { connect } from 'react-redux';
import { searchHistory, getChatMessages, getSearchMessages } from '../../ducks/messages';
import { ExpandMore, PlayCircleOutline, ArrowBack } from '@material-ui/icons';
import { IconButton, Avatar, Paper, Menu, MenuItem, Badge, ListItem, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { getUser } from '../../selectors';
import '../ChatSection/ChatSection.css';
import DocFormat from '../../assets/images/doc-format.png';
import PdfFormat from '../../assets/images/pdf-format.png';
import ExcelFormat from '../../assets/images/excel.svg';
import LinkIcon from '../../assets/images/link.png';
import loadingGif from '../../assets/images/eclipse-loading.gif';
import { updateChatViewStatus, setCurrentChat } from '../../ducks/chats';
import { handleImageViewer } from '../../ducks/imageViewer';
import { handleVideoPlayer } from '../../ducks/videoPlayer';
import { compose } from 'redux';
import { setCurrentSurvey } from '../../ducks/surveys';
import { convertUnicode, getAvatarText, statusList } from '../../helpers';
import { SendToChatTemplate } from '../Arena/ShareToChatDialog';
import { meetingMessage } from '../ChatSection/ChatSection';


const useStyles = makeStyles((theme) => ({
    root: {
        width: 'inherit',
        position: 'relative'
    },
    headerRoot: {
        height: '82px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eeeeee'
        // justifyContent: 'space-between'
    },
    profileDetails: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        padding: '0px 12px'
    },
    inputRoot: {
        padding: 10,
        // display: 'flex',
        alignItems: 'flex-end',
        // height: '-webkit-fill-available',
    },
    messageLayout: {
        margin: '5px 25px 5px 25px',
        padding: '12px 20px 7px',
        width: 'auto',
        maxWidth: '65%',
        fontSize: '13px',
        position: 'relative',
        wordBreak: 'break-all',
        // borderRadius: '0 15px 15px 15px',
        borderRadius: '0 8px 8px 8px',
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
        // backgroundColor: theme.palette.secondary.light,
        '&:hover #more-icon': {
            visibility: 'visible',
        }
    },
    messageRoot: {
        display: 'flex',
        // justifyContent: 'flex-end',
        // textAlign: 'right',
        // '& > div': {
        //     width: '100%'
        // }
    },
    messageRootRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        '& > div': {
            backgroundColor: theme.palette.primary.light,
            // borderRadius: '15px 15px 0 15px',
            borderRadius: '8px 8px 0 8px',
        }
    },
    timestamp: {
        // margin: '0 10px',
        color: '#838383',
        fontSize: '11px',
        float: 'right',
        margin: '10px -10px 0 20px',
    },
    expandIcon: {
        position: 'absolute',
        right: '2px',
        top: '2px',
        visibility: 'hidden',
        cursor: 'pointer'
    },
    profilePopup: {
        width: '150px'
    },
    statusBadge: {
        border: '2px solid #fff',
        height: '14px',
        width: '14px',
        borderRadius: '7px',
        zIndex: 0
    },
    statusColor: {
        backgroundColor: props => props.statusColor,
    },
    userNameRoot: {
        marginLeft: 5,
    },
    userName: {
        margin: '0 0 5px',
        color: '#58595B',
        fontSize: 20
    },
    aboutUser: {
        margin: 0,
        color: '#838383',
        fontSize: 13
    },
    imageStyles: {
        maxWidth: '95%',
        maxHeight: '250px',
        cursor: 'pointer'
    },
    imageBody: {
        margin: '10px 0 0'
    },
    videoStyles: {
        width: '150px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6A7279',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    playIcon: {
        color: '#fff'
    },
    quotedMsgRoot: {
        margin: '-12px -20px 18px',
        padding: '9px 20px 12px',
        border: '1.5px solid #E3E3E3',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.primary.main,
        // backgroundColor: theme.palette.primary.light,
        backgroundColor: '#FFF',
        // borderRadius: '0 15px 0 0'
        borderRadius: '0 8px 0 0',
        borderTopLeftRadius: 'inherit'
    },
    quotedMsgRootAlt: {
        margin: '-12px -20px 18px',
        padding: '9px 20px 12px',
        border: '1.5px solid #E3E3E3',
        borderBottom: '2px solid',
        borderBottomColor: theme.palette.primary.main,
        // backgroundColor: theme.palette.secondary.light,
        backgroundColor: '#FFF',
        // borderRadius: '15px 15px 0 0'
        borderRadius: '8px 8px 0 0',
        borderTopLeftRadius: 'inherit'
    },
    quoteMsg: {
        margin: '5px 0',
        whiteSpace: 'pre-wrap'
    },
    quoteMsgOf: {
        fontSize: 10,
        color: '#272523'
    },
    fileRoot: {
        display: 'flex',
        // justifyContent: 'space-around',
        alignItems: 'center',
        cursor: 'pointer',
        '& span': {
            marginLeft: 15,
            color: '#00000080'
        }
    },
    linkItemRoot: {
        padding: 0,
        margin: '10px 0',
        height: 80,
        overflow: 'hidden'
    },
    linkItemContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        wordBreak: 'break-all',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#F0F0F0',
        alignItems: 'center'
    },
    linkIconRoot: {
        minWidth: 90,
        backgroundColor: '#E6E6E6',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    primaryText: {
        margin: 0,
        paddingBottom: '7px',
        fontSize: 12,
        color: '#535353'
    },
    linkTag: {
        textDecoration: 'none',
        color: 'inherit'
    },
    quotedLinkRoot: {
        padding: 0,
        margin: '10px 0',
        height: 40,
        overflow: 'hidden'
    },
    quotedImageStyle: {
        width: 50,
        height: 50
    },
    quotedVideoStyle: {
        width: 60,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6A7279',
        position: 'relative',
    },
    surveyRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    surveyButton: {
        border: '2px solid orange',
        borderRadius: 20,
        marginLeft: 30,
        marginRight: 30,
        whiteSpace: 'nowrap',
        '& span': {
            color: 'orange',
            // fontWeight: 600
        }
    },
    surveyExpired: {
        border: '2px solid red',
        '& span': {
            color: 'red',
        }
    },
    surveyResponded: {
        border: '2px solid green',
        '& span': {
            color: 'green',
        }
    },
    textBelowQuoteMsg: {
        fontSize: 14,
        marginBottom: 0,
        marginTop: 10
    },
    messageText: {
        margin: 0
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    },
    surveyTitle: {
        margin: 0
    },
    fullWidth: {
        width: '100%'
    },
    prevLoadingImageRoot: {
        textAlign: "center",
        margin: 0
    },
    nextLoadingImageRoot: {
        textAlign: "center",
        margin: 0,
        position: 'absolute',
        left: '50%',
        marginLeft: - 25,
        zIndex: 1
    },
    loadingImage: {
        width: 50,
        borderRadius: 25
    },
    backButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
            padding: 0,
            display: 'block'
        },
    }
}));

const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

let prevPagination = {
    page: 0,
    limit: 15
}
let nextPagination = {
    page: 0,
    limit: 15
}

let lastPrevPage = 0;
let lastNextPage = 0;

const SearchChatSection = (props) => {
    console.log('SearchChatSection ', props);
    const classes = useStyles({ statusColor: statusList.find(item => item.show === props.show).color });

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMsgDup, setSelectedMsgDup] = useState(null);
    const [selectedMsg, setSelectedMsg] = useState(null);
    const [prevLoading, setPrevLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [messages, setMessages] = useState([])

    const isMenuOpen = Boolean(anchorEl);
    let scrollListener = null;
    useEffect(() => {
    }, [props.userStatus])

    const previousjid = usePrevious(props.targetJid);
    const previousMessageId = usePrevious(props.messageid);

    let prevScrollHeight = 0;

    const handleScroll = () => {
        let lastLi = document.getElementById(`${props.targetJid}-${props.messageid}`);

        // console.log('SearchChatSection handleScroll if 1 lastPrevPage', lastPrevPage, prevPagination.page + 1, lastPrevPage !== prevPagination.page + 1)
        // console.log('SearchChatSection handleScroll if 1 lastNextPage', lastNextPage, nextPagination.page + 1, lastNextPage !== nextPagination.page + 1)
        if (lastLi && lastLi.scrollTop === 0 && (lastPrevPage !== prevPagination.page + 1)) {
            if ((prevPagination.total_pages - 1) > prevPagination.page) {
                lastPrevPage = prevPagination.page + 1
                setPrevLoading(true);
                setTimeout(() => {
                    props.getSearchMessages({
                        timestamp: props.sentdate, targetJid: props.targetJid,
                        type: props.type,
                        params: { step: 'previous', limit: 15, page: prevPagination.page + 1 },
                        onGetPrevMessagesSuccessCB, onGetPrevMessagesErrorCB
                    });
                }, 1000);
            }
        }
        else if (lastLi && (lastLi.scrollHeight - Math.round(lastLi.scrollTop) - lastLi.clientHeight <= 0) && (lastNextPage !== nextPagination.page + 1)) {
            console.log('SearchChatSection handleScroll if 2 ', lastLi.scrollTop)
            if ((nextPagination.total_pages - 1) > nextPagination.page) {
                lastNextPage = nextPagination.page + 1
                console.log('SearchChatSection handleScroll if 3 ', lastLi.scrollTop)
                setNextLoading(true);
                setTimeout(() => {
                    props.getSearchMessages({
                        timestamp: props.sentdate, targetJid: props.targetJid,
                        type: props.type,
                        params: { step: 'next', limit: 15, page: nextPagination.page + 1 },
                        onGetNextMessagesSuccessCB, onGetNextMessagesErrorCB
                    });
                }, 1000);
            }
        }
    };

    useEffect(() => {
        setMessages([]);
        prevPagination = {
            page: 0,
            limit: 15
        }
        nextPagination = {
            page: 0,
            limit: 15
        }
        lastPrevPage = 0;
        lastNextPage = 0;
        let messageList = document.getElementById(`${props.targetJid}-${props.messageid}`);
        if (previousjid && previousjid !== props.targetJid && previousMessageId) {
            props.updateChatViewStatus(previousjid.split('@')[0]);
        }
        if (props.targetJid) {
            props.getSearchMessages({ timestamp: props.sentdate, targetJid: props.targetJid, type: props.type, params: { step: 'previous', limit: 15, page: prevPagination.page }, onGetPrevMessagesSuccessCB, onGetPrevMessagesErrorCB });

            scrollListener = messageList.addEventListener("scroll", e => {
                // console.log('onScroll Listener', e, messageList);
                handleScroll(e);
            });
        }
        props.updateChatViewStatus(props.targetJid.split('@')[0]);
    }, [props.targetJid, props.messageid]);

    useEffect(() => {
        return () => {
            props.updateChatViewStatus(props.targetJid.split('@')[0]);
        }
    }, []);

    const onGetPrevMessagesSuccessCB = (data, headers) => {
        console.log('onGetPrevMessagesSuccessCB ', data, headers);

        prevPagination = {
            limit: parseInt(headers.limit),
            page: parseInt(headers.page),
            total_count: parseInt(headers.total_count),
            total_pages: parseInt(headers.total_pages),
        }
        setMessages(prevState => ([...data, ...prevState]));
        setPrevLoading(false);
        let searchedMessage = document.getElementById(props.messageid);
        if (parseInt(headers.page) === 0) {
            props.getSearchMessages({ timestamp: props.sentdate, targetJid: props.targetJid, type: props.type, params: { step: 'next', limit: 15, page: nextPagination.page }, onGetNextMessagesSuccessCB, onGetNextMessagesErrorCB });
            if (searchedMessage) {
                searchedMessage.style.backgroundColor = 'yellow';
                searchedMessage.scrollIntoView();
            }
        }
        else {
            if (searchedMessage) {
                searchedMessage.removeAttribute("style");
            }
        }
        let lastLi = document.getElementById(`${props.targetJid}-${props.messageid}`);
        lastLi.scrollTop = lastLi.scrollHeight - prevScrollHeight;
        prevScrollHeight = lastLi.scrollHeight;
    }

    const onGetPrevMessagesErrorCB = (error) => {
        console.log('Failed to load messages ', error);
    }

    const onGetNextMessagesSuccessCB = (data, headers) => {
        console.log('onGetNextMessagesSuccessCB ', data, headers);

        nextPagination = {
            limit: parseInt(headers.limit),
            page: parseInt(headers.page),
            total_count: parseInt(headers.total_count),
            total_pages: parseInt(headers.total_pages),
        }
        setMessages(prevState => ([...prevState, ...data]));
        setNextLoading(false);
    }

    const onGetNextMessagesErrorCB = (error) => {
        console.log('Failed to load messages ', error);
    }

    const handleOnMessageSent = () => {
        setTimeout(() => {
            props.setCurrentChat({
                active: true, targetJid: props.targetJid,
                isCurrent: false, name: props.name,
                sentdate: new Date().getTime(), show: props.show,
                type: props.type, image_url: props.image_url,
                count: 0
            });
            props.history.push('/chats');
        }, 500);
    }

    const handleMoreMenuOpen = (event, msg) => {
        setSelectedMsgDup(msg);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleQuote = () => {
        setSelectedMsg(selectedMsgDup);
        handleMenuClose();
    }

    const handleOnClearQuote = () => {
        setSelectedMsg(null);
    }

    const menuId = 'expand-more-menu';
    const renderMore = (
        <Menu
            anchorEl={anchorEl}
            classes={{ paper: classes.profilePopup }}
            id={menuId}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => handleQuote()}>Respond</MenuItem>
        </Menu>
    );

    function downloadFiles(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const getFormatIcon = (type) => {
        switch (type) {
            case 'doc':
            case 'docx':
                return DocFormat;
            case 'pdf':
                return PdfFormat;
            case 'xls':
            case 'xlsx':
                return ExcelFormat;
            default:
                break;
        }
    }

    const handleOnSurveyClick = (surveyId) => {
        props.setCurrentSurvey(surveyId);
        props.history.push('survey');
    }

    const linkMessage = (msg) => (
        <>
            <a href={msg.url} target="_blank" className={classes.linkTag}>
                <ListItem className={classes.linkItemRoot} button >
                    <div className={classes.linkIconRoot}>
                        <img width="25" src={LinkIcon} alt={'Link'} />
                    </div>
                    <div className={classes.linkItemContent}>
                        <div>
                            <p className={classes.primaryText}>{msg.url.length > 100 ? msg.url.substring(0, 100) + '...' : msg.url}</p>
                        </div>
                    </div>
                </ListItem>
            </a>
            {convertUnicode(msg.body)}
        </>
    )

    const videoMessage = (msg) => (
        <>
            <div className={classes.videoStyles} >
                <IconButton onClick={() => props.handleVideoPlayer({ open: true, video: msg.url })}><PlayCircleOutline className={classes.playIcon} /></IconButton>
            </div>
            <p className={classes.imageBody}>{convertUnicode(msg.body)}</p>
        </>
    )

    const imageMessage = (msg) => (
        <>
            <img className={classes.imageStyles} src={msg.url} alt={msg.body} onClick={() => props.handleImageViewer({ open: true, images: [msg.url] })} />
            <p className={classes.imageBody}>{convertUnicode(msg.body)}</p>
        </>
    )

    const quoteMessage = (msg) => {
        const quoteMsg = convertUnicode(msg.quoteMsg);
        return (
            <>
                <div className={msg.targetJid === props.userStatus.targetJid ? classes.quotedMsgRootAlt : classes.quotedMsgRoot} >
                    <p className={classes.quoteMsgOf}>{msg.targetJid === props.userStatus.targetJid ? props.name : 'You'}</p>
                    {
                        (msg.hasOwnProperty('quoteType') && msg.hasOwnProperty('url')) ?
                            (
                                msg.quoteType === 'image' ?
                                    <><img className={classes.quotedImageStyle} src={msg.url} alt={msg.body} /><br />
                                        {quoteMsg && <p className={classes.textBelowQuoteMsg}>{quoteMsg}</p>}
                                    </>
                                    : (msg.quoteType === 'file') ?
                                        <>
                                            <div className={classes.fileRoot}>
                                                <img width="25" src={getFormatIcon(msg.url.split('.').pop())} alt={msg.fileName} />
                                                <span>{msg.url.split('-').pop()}</span>
                                            </div>
                                            {quoteMsg && <p className={classes.textBelowQuoteMsg}>{quoteMsg}</p>}
                                        </>
                                        : (msg.quoteType === 'link') ?
                                            <ListItem className={classes.quotedLinkRoot} button >
                                                <div className={classes.linkIconRoot}>
                                                    <img width="25" src={LinkIcon} alt={'Link'} />
                                                </div>
                                                <div className={classes.linkItemContent}>
                                                    <div>
                                                        <p className={classes.primaryText}>{msg.url.length > 60 ? msg.url.substring(0, 60) + '...' : msg.url}</p>
                                                    </div>
                                                </div>
                                            </ListItem>
                                            : (msg.quoteType === 'video') ?
                                                <>
                                                    <div className={classes.quotedVideoStyle} >
                                                        <IconButton ><PlayCircleOutline className={classes.playIcon} /></IconButton>
                                                    </div>
                                                    {quoteMsg && <p className={classes.textBelowQuoteMsg}>{quoteMsg}</p>}
                                                </>
                                                : (msg.quoteType === 'post') ?
                                                    <SendToChatTemplate
                                                        photoUrl={msg.url}
                                                        text={msg.quoteMsg}
                                                        postedBy={msg.createdBy}
                                                        textLimit={35}
                                                        quotedTemplate
                                                    />
                                                    :
                                                    < p className={classes.quoteMsg}>{quoteMsg}</p>
                            )
                            :
                            < p className={classes.quoteMsg}>{quoteMsg}</p>
                    }
                </div>
                {convertUnicode(msg.body)}
            </>
        )
    }

    const fileMessage = (msg) => (
        <>
            <div>
                <div className={classes.fileRoot} onClick={() => downloadFiles(msg.url, msg.url.split('-').pop())}>
                    <img width="25" src={getFormatIcon(msg.fileType)} alt={msg.fileName} />
                    <span>{msg.url.split('-').pop()}</span>
                </div>
                {msg.body && <p className={classes.imageBody}>{convertUnicode(msg.body)}</p>}
            </div>
        </>
    )

    const surveyMessage = (msg) => {
        const expired = (new Date(msg.expire_date).getTime()) < (new Date().getTime());
        // const responded = (msg.responded === "true") ? true : false;
        const responded = msg.responded;
        return (
            <>
                <div className={classes.surveyRoot} onClick={() => (!expired) ? handleOnSurveyClick(msg.surveyId) : {}}>
                    <p className={classes.surveyTitle} >{msg.title}</p>
                    {
                        expired ?
                            <Button disabled className={classNames(classes.surveyButton, classes.surveyExpired)} variant="outlined">Expired</Button>
                            :
                            <Button className={classes.surveyButton} variant="outlined">Respond</Button>
                    }
                </div>
            </>
        )
    }

    const arenaPostMessage = (msg) => (
        <SendToChatTemplate
            photoUrl={msg.thumbnailUrl}
            text={msg.text}
            postedBy={msg.createdBy}
            textLimit={125}
            clickable
            onClick={() => props.history.push(`/wall/view/${msg.postId}`)}
        />
    )

    return (
        <div className={classes.root}>
            <div className={classes.headerRoot}>
                <IconButton title="Back to search" className={classes.backButton} onClick={() => props.handleSearchResults()} >
                    <ArrowBack />
                </IconButton>
                <div className={classes.profileDetails}>
                    <div className={classes.avatar}>
                        <Badge color="primary" classes={{ badge: classes.statusBadge, colorPrimary: classes.statusColor }} overlap={'circle'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} variant="dot">
                            <Avatar
                                classes={{ colorDefault: classes.avatarStyles }}
                                alt={props.contactName}
                                src={(props.image_url && props.image_url.length) ? props.image_url : props.photo_url ? props.photo_url : '/'}
                            >{props.contactName && (props.contactName.includes('@') ? props.contactName.substring(0, 1) : getAvatarText(props.contactName)) || props.name && (props.name ? getAvatarText(props.name) : props.name.substring(0, 1))}</Avatar>
                        </Badge>
                    </div>
                    <div className={classes.userNameRoot}>
                        <h2 className={classes.userName}>{props.contactName || props.name}</h2>
                        <p className={classes.aboutUser}>{props.about ? props.about : (props.userStatus && (props.userStatus.show === 'dnd' ? 'Busy' : props.userStatus.show))}</p>
                    </div>

                </div>
            </div>
            <div id={`${props.targetJid}-${props.messageid}`} className="scroller chat-section-scroller" style={{ height: '70vh', minHeight: (!props.hasOwnProperty('active') || props.active === true) ? '70vh' : '78vh' }}>
                {
                    prevLoading &&
                    <p className={classes.prevLoadingImageRoot}><img className={classes.loadingImage} src={loadingGif} alt="Loading" /></p>
                }
                {
                    nextLoading &&
                    <p className={classes.nextLoadingImageRoot}><img className={classes.loadingImage} src={loadingGif} alt="Loading" /></p>
                }
                {messages && messages.map((message, index, list) => {
                    let past = (index > 0) ? new Date(list[index - 1].time).getTime() : new Date().getTime();
                    let sameday = (new Date(past).toDateString() == new Date(message.time).toDateString());
                    let isToday = new Date(message.time).toDateString() == new Date().toDateString();

                    let newMsg = {};
                    if (message.body.startsWith('%%%')) {
                        try {
                            newMsg = JSON.parse(message.body.replace(/%%%/gi, ''));
                        }
                        catch {
                            message.body = 'Invalid Message Body';
                        }
                    }
                    return (
                        <div key={index}>
                            {!sameday && <div className="separator">{isToday ? 'Today' : moment(message.time).format("DD-MMM-YYYY")}</div>}
                            {
                                newMsg && newMsg.msgType === 'call' ?
                                    null
                                    :
                                    <div key={index} className={message.from.startsWith(props.client.jid?.split('/')[0]) ? classes.messageRootRight : classes.messageRoot}>
                                        <Paper id={message.messageId} square elevation={0} className={classNames(classes.messageLayout, (newMsg.msgType && newMsg.msgType === 'survey') && classes.fullWidth)}>
                                            {
                                                newMsg.msgType === 'image' ?
                                                    imageMessage(newMsg)
                                                    : (newMsg.msgType === 'quote') ?
                                                        quoteMessage(newMsg)
                                                        : (newMsg.msgType === 'file') ?
                                                            fileMessage(newMsg)
                                                            : (newMsg.msgType === 'link') ?
                                                                linkMessage(newMsg)
                                                                : (newMsg.msgType === 'video') ?
                                                                    videoMessage(newMsg)
                                                                    : (newMsg.msgType === 'survey') ?
                                                                        surveyMessage(newMsg)
                                                                        : (newMsg.msgType === 'post') ?
                                                                            arenaPostMessage(newMsg)
                                                                            : (newMsg.msgType === 'meet') ?
                                                                                meetingMessage(newMsg)
                                                                                : convertUnicode(message.body)
                                                // <p className={classes.messageText}>{message.body}</p>

                                            }
                                            <p className={classes.timestamp}>{moment(message.time).format("hh:mm A")}</p>
                                            {
                                                (newMsg.msgType !== 'survey') &&
                                                <span id="more-icon" className={classes.expandIcon}>
                                                    <IconButton onClick={(e) => handleMoreMenuOpen(e, message)} size="small"><ExpandMore /></IconButton>
                                                </span>
                                            }
                                        </Paper>
                                    </div>
                            }
                        </div>
                    )
                })}
                {renderMore}
            </div>
            {
                (!props.hasOwnProperty('active') || props.active === true) &&
                <div className={classes.inputRoot}>
                    <MessageInput selectedMsg={selectedMsg} handleOnMessageSent={handleOnMessageSent}
                        contact={props.targetJid}
                        targetDetails={{
                            targetJid: props.targetJid,
                            name: props.name,
                            type: props.type === 'group' ? '1' : '0',
                            active: `${props.active}`,
                            image_url: `${props.image_url}`
                        }}
                        handleOnClearQuote={handleOnClearQuote}
                    />
                </div>
            }
        </div >
    );
}

const mapStateToProps = (state, props) => ({
    userStatus: getUser(state.chats, props.targetJid),
    xmpp: window.client,
    client: state.client,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getSearchMessages: (options) => dispatch(getSearchMessages(options)),
        setCurrentChat: (options) => dispatch(setCurrentChat(options)),
        searchHistory: (options) => dispatch(searchHistory(options)),
        getChatMessages: (options) => dispatch(getChatMessages(options)),
        updateChatViewStatus: (options) => dispatch(updateChatViewStatus(options)),
        handleImageViewer: (options) => dispatch(handleImageViewer(options)),
        handleVideoPlayer: (options) => dispatch(handleVideoPlayer(options)),
        setCurrentSurvey: (options) => dispatch(setCurrentSurvey(options))
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SearchChatSection);
