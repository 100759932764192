
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles, Tab, Tabs, Typography } from '@material-ui/core';

const useTabBarStyles = makeStyles(theme => ({
    enhancedToolbar: {
        marginTop: 20,
        // position: 'absolute',
        top: 1,
        // right: 135,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // width: 'calc(100% - 140px)',
        [theme.breakpoints.down("xs")]: {
            position: 'relative',
            width: 'auto'
        }
    },
    tabsRoot: {
        // padding: '14px 0px',
        minHeight: 38,
        // minWidth: 300,
        // maxWidth: 400,
        // margin: 'auto'
        // borderBottom: '1px solid #0000003a',
        paddingBottom: 0,
        // marginBottom: 20
    },
    tabsFixed: {
        maxWidth: 500,
        backgroundColor: '#FFF',
        // borderRadius: 15
    },
    tabsFixedKpis: {
        maxWidth: 1000
    },
    tabRoot: {
        // borderBottom: '1px solid #0000003a',
        padding: '0 20px',
        textTransform: 'capitalize',
        fontSize: 13,
        fontWeight: 600,
        // minWidth: 125,
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        color: '#838383',
        // borderRadius: 15,
        minHeight: 38
    },
    tabsRoot: {
        minHeight: 25,
        paddingBottom: 0
    },
    tabRoot1: {
        padding: '0 10px',
        margin: '0 8px',
        textTransform: 'capitalize',
        fontSize: 11,
        fontWeight: 600,
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        color: '#838383',
        minHeight: 25,
        borderRadius: 15,
        border: `1px solid ${theme.palette.primary.main} !important`
    },
    tabWrapper: {
        // flexDirection: 'row',
        // justifyContent: 'flex-start',
        '& > *:first-child': {
            // fontSize: '21px',
            margin: '0 10px 0 6px !important'
        }
    },
    labelIcon: {
        minHeight: '50px',
        border: 'none',
    },
    tabIndicator: {
        height: '0',
    },
    selectedTab: {
        color: '#FFF !important',
        backgroundColor: theme.palette.primary.main
    }
}));

export const TabBar = ({ tabs = [], subTabVal, handleSubTabChange, styles = {}, isKpis = false }) => {
    const classes = useTabBarStyles();
    return (
        <div className={classes.enhancedToolbar} style={styles}>
            <Tabs
                value={subTabVal}
                onChange={handleSubTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
                className={classes.tabsRoot}
                classes={{ indicator: classes.tabIndicator, fixed: isKpis ? classes.tabsFixedKpis : classes.tabsFixed }}
            >
                {tabs.map(obj => (
                    <Tab title={obj.name} className={classes.tabRoot}
                        classes={{ wrapper: classes.tabWrapper, labelIcon: classes.labelIcon, selected: classes.selectedTab }}
                        // icon={<Group />}
                        label={obj.label}
                    />
                ))}
            </Tabs>
        </div>
    )
}

export const CustomTabBar = ({ tabs = [], subTabVal, handleSubTabChange, styles = {}, isKpis = false }) => {
    const classes = useTabBarStyles();
    return (
        <div className={classes.enhancedToolbar} style={styles}>
            <Tabs
                value={subTabVal}
                onChange={handleSubTabChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
                className={classes.tabsRoot}
                classes={{ indicator: classes.tabIndicator, fixed: isKpis ? classes.tabsFixedKpis : classes.tabsFixed }}
            >
                {tabs.map(obj => (
                    <Tab title={obj.name} className={classes.tabRoot1}
                        classes={{ wrapper: classes.tabWrapper, labelIcon: classes.labelIcon, selected: classes.selectedTab }}
                        // icon={<Group />}
                        label={obj.label}
                    />
                ))}
            </Tabs>
        </div>
    )
}