import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setLoading } from "../../../ducks/loading";
import { Avatar, Button, Grid } from '@material-ui/core';
import { getAvatarText } from '../../../helpers';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '10px 0px'
    },
    avatar: {
        textAlign: 'center',
        display: 'grid',
        justifyContent: 'center',
    },
    colorDefault: {
        width: 110,
        height: 110,
        border: '1px dashed #B9B9B9'
    },
    details: {
        padding: '6px',
        // minHeight: 115,
        borderRadius: 8,
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        '& .sub_details': {
            minHeight: 95,
            background: '#00000005',
            padding: '8px 15px',
            borderRadius: 5,
            alignContent: 'center',
            alignItems: 'center',
        }
    },
    names: {
        alignItems: 'center',
        alignContent: 'center',
        borderRight: '1px solid #0000001F',
        display: 'grid',
        justifyContent: 'center',
        '& h4': {
            color: '#000000E5',
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
        },
        '& h6': {
            color: '#00000080',
            fontSize: 13,
            margin: 0,
            fontWeight: 500,
        },
        '& .btn': {
            borderRadius: 4,
            padding: '8px 20px',
            background: '#0000000F',
            textTransform: 'uppercase',
            fontSize: 12,
            color: '#00000066',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: 'white'
            },
        }
    },

}));


const PlayerDetails = ({ playerDetails, profileClick = () => { } }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} >
            <Grid container spacing={0}>
                <Grid item xs={2}>
                    <div className={classes.avatar}>
                        <Avatar alt={playerDetails?.name} src={playerDetails?.photo_url} classes={classes.colorDefault} style={{ cursor: 'pointer', width: 110, height: 110, border: '1px dashed #B9B9B9', color: 'white' }}  >
                            {getAvatarText(playerDetails?.name)}
                        </Avatar>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div className={classes.details}>
                        <Grid container spacing={0} className='sub_details'>
                            <Grid item xs={3}>
                                <div className={classes.names}>
                                    <h6>Full Name</h6>
                                    <h4>{playerDetails?.name}</h4>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.names}>
                                    <h6>Employee Number</h6>
                                    <h4>{playerDetails?.emp_no}</h4>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.names}>
                                    <h6>Role</h6>
                                    <h4>{playerDetails?.role_name}</h4>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.names}>
                                    <h6>Rank</h6>
                                    <h4>{playerDetails?.rank}</h4>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.names} style={{ borderRight: 'none' }}>
                                    <Button onClick={() => profileClick(playerDetails?.username)} className='btn'>View Profile  <ArrowForward style={{ fontSize: 18, paddingLeft: 5 }} /></Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loading,
    userDetails: state.user,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerDetails));
