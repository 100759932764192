import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { useSnackbar } from 'notistack';
import { getTeamKpisTrends } from '../../ducks/game';
import { formatDate } from '../../helpers';
import FieldSet from '../GlobalComponents/FieldSet';
import PerformanceGraph from '../PerformanceStats/OverallTrends/PerformanceGraph';
import { DurationTooltip } from '../PerformanceStats/DurationTooltip';
import IllustrationView from '../LiveScores/IllustrationView';
import gameIllustration from '../../assets/images/game_Illustration.png';
import DummyGraph from '../../assets/images/dummy-graph.png';
import { setLoading } from "../../ducks/loading";






const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 15,
        color: '#262626',
        fontWeight: '600',
        margin: '0',
        paddingBottom: '8px'
    },


}))
const TeamPerformance = ({ seasonId, teamId, filtersData, setFiltersData, defaultFilters, getTeamKpisTrends, setLoading, seasonDetails }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [trends, setTrends] = useState([])


    useEffect(() => {
        if (seasonId && teamId) getKpiPerformanceTrends(seasonId, teamId)
    }, [seasonId, teamId])

    const getKpiPerformanceTrends = (seasonId, teamId) => {
        getTeamKpisTrends({
            seasonId: seasonId,
            params: { team_id: teamId, limit: 5000, page: 1 }, // kpi_id: kpi._id, kpi_type: kpi.tag, 
            onSuccessCB: (result) => {
                if (result.length > 0) {
                    const resultData = result.map(obj => ({
                        labels: obj.stats?.map((item) => `${formatDate(item.end_date)}`), // (item, i) => `${item.pre_fix}${i + 1}`
                        targets: obj.stats?.map(item => item.target),
                        actuals: obj.stats?.map(item => item.actual),
                        duration: obj.stats[0]?.duration,
                        durations: obj.stats?.map((item, i) => ({ label: `${item.pre_fix}${i + 1}`, startDate: item.start_date, endDate: item.end_date })),
                        kpiName: obj.kpi_name,
                        // kpiCode: obj.kpi_code || obj.kpi_name,
                        kpiType: obj.kpi_type,
                        unitOfMeasurement: obj.unit_of_measurement,
                        unit: obj.unit
                    }))
                    // console.log('finalResult ', resultData);
                    setTrends(resultData)
                    setLoading(false);

                }

            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }


    return (
        <div> {
            (filtersData.season?.season_format !== 'PLAYER_RACE') ?
                <div style={{ margin: '10px 0' }}>
                    <Grid container spacing={2} >
                        {
                            [
                                trends?.length > 0 ? trends.map((trend, i) => (
                                    <Grid item md={6} xs={12} style={{ marginTop: 10 }}>
                                        <FieldSet
                                            key={i}
                                            title={<span className={classes.legendStyles}>{trend.kpiName} <span style={{ fontSize: 11, margin: '0 4px', color: '#575d63' }}>({trend?.kpiType === 'Core KPI' ? `${seasonDetails?.labels_config?.core}` : `${seasonDetails?.labels_config?.bonus}`})</span> {filtersData.team?.name ? `- ${filtersData.team?.name} ${filtersData.team?.captain ? `[C: ${filtersData.team?.captain?.fname}]` : ''}` : ''} {(trend?.durations) && <DurationTooltip trend={trend} matchType={trend?.kpiType === 'Core KPI' ? ((filtersData.season?.season_format === 'HEAD_TO_HEAD') ? 'Match Duration' : 'Sprint Duration') : `Team ${seasonDetails?.labels_config?.bonus}`} />}</span>}
                                            rootStyles={classes.filedSetRootStyles}
                                        >
                                            {
                                                (trend.labels?.length > 0) ?
                                                    <PerformanceGraph {...trend} />
                                                    :
                                                    <div className={classes.emptyData}>
                                                        {/* <img src={DummyGraph} alt="graph" /> */}
                                                        <p >** Data yet to be made available **</p>
                                                    </div>
                                            }
                                        </FieldSet>
                                    </Grid>
                                ))
                                    :
                                    < IllustrationView style={{ marginTop: '50px' }} />
                                // <p style={{ textAlign: 'center', fontSize: 12, color: '#000000a1', width: '100%', marginTop: 50 }}>** No data available **</p>
                            ]
                        }
                    </Grid>
                </div>
                :
                <div style={{ textAlign: 'center', marginTop: 35 }}>
                    <img src={gameIllustration} />
                    <p style={{ fontSize: 14, color: '#0000008A' }}>Since selected season is of the format 'Player race', we do not have any team trends to show here</p>
                </div>
        }
        </div>
    )


}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails,

})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getTeamKpisTrends: (options) => dispatch(getTeamKpisTrends(options)),
    }
};
export default withRouter(connect(mapStateToProps,
    mapDispatchToProps
)(TeamPerformance));