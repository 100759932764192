import { IconButton } from '@material-ui/core';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { ArrowDownward, ChevronLeft, Email } from '@material-ui/icons';
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import { toJpeg } from 'html-to-image';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shareLiveScoreDialog } from '../../ducks/game';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { getMatchDetails } from '../../ducks/game';
import { setLoading } from '../../ducks/loading';
import { historyPushWithSearch, downloadFiles, getBase64WithUrl } from "../../helpers"
import PlayersMatchSummary from './PlayersMatchSummary';
import TeamsMatchSummary from './TeamsMatchSummary';
import MatchPerformanceTemplate from '../../components/GlobalComponents/MatchPerformanceTemplate';
import { resizeImageToSquare } from '../ArenaComponents/cropImage';
import { openCreateArenaDialog } from '../../ducks/arena';
import { ReactComponent as ReactArenaLogo } from "../../assets/icons/arena-1.svg";

const useStyles = makeStyles((theme) => ({
	fieldSetRoot: {
		margin: '15px 0 20px'
	},
	fieldSetChildRoot: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 20px 10px',
		flexWrap: 'wrap',
		'& div': {
			margin: '0 20px 10px'
		}
	},
	fieldLabel: {
		color: '#58595b',
		fontSize: 12,
		margin: 0
	},
	fieldValue: {
		color: '#58595b',
		fontSize: 15,
		fontWeight: 600,
		marginTop: 0,
		marginBottom: 5
	},
	backButton: {
		backgroundColor: '#FFF',
		border: '1px solid #bcbcbd !important',
	}, viewAllButton: {
		margin: '0 10px',
		color: 'blue', // #112386
		fontSize: 11,
		float: 'right',
		cursor: 'pointer',
		textDecoration: 'underline',
		// fontWeight: 600,
		'& svg': {
			fontSize: 14,
			marginLeft: 4,
			verticalAlign: 'sub'
		}
	},
	shareOptionStyles: {
		fontSize: 14,
		'& #share-to-wall': {
			width: 20,
			height: 20,
			marginRight: 10,
			// '& path': {
			// 	fill: theme.palette.primary.main
			// },
			// '& path[fill="white"]': {
			// 	fill: '#FFF',
			// },
			'& path': {
				stroke: theme.palette.primary.main
			}
		},
		'& #download': {
			width: 20,
			height: 20,
			marginRight: 10,
			'& path': {
				fill: theme.palette.primary.main
			},
			'& path[fill="white"]': {
				fill: '#FFF',
			},
			// '& path': {
			// 	stroke: theme.palette.primary.main
			// },
			// '& path[fill]': {
			// 	fill: theme.palette.primary.main,
			// },
		},
		'& #Email': {
			width: 20,
			height: 20,
			marginRight: 10,
			'& path': {
				fill: theme.palette.primary.main
			},
			'& path[fill="white"]': {
				fill: '#FFF',
			}
			// '& path': {
			// 	stroke: theme.palette.primary.main
			// },
			// '& path[fill]': {
			// 	fill: theme.palette.primary.main,
			// },
		}
	},
}));

export const matchTypes = [
	{
		name: 'Team Match',
		id: 'TEAM_MATCH'
	},
	{
		name: 'Team Race',
		id: 'TEAM_SPRINT'
	},
	{
		name: 'Player Race',
		id: 'PLAYER_SPRINT'
	},
	{
		name: 'Team Bonus',
		id: 'TEAM_BONUS'
	},
	{
		name: 'Player Bonus',
		id: 'INDIVIDUAL_BONUS'
	},
];

const MatchDetails = ({ match, dialog, keycloak, history, location, userDetails, loading, setLoading, getMatchDetails, filtersData, shareLiveScoreDialog, openCreateArenaDialog }) => {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [canDownload, setCanDownload] = useState(false);
	const [matchDetails, setMatchDetails] = useState(null);
	const [shareType, setShareType] = useState('EMAIL');
	const [title, setTitle] = useState("")
	const [dataUrl, setDataUrl] = useState("")
	const [shareAnchorEl, setShareAnchorEl] = React.useState({
		el: null,
		arena: null
	});
	const isShareMenuOpen = Boolean(shareAnchorEl.el);

	const isAdmin = keycloak.decoded_token.realm_access.roles &&
		keycloak.decoded_token.realm_access.roles.length > 0 &&
		keycloak.decoded_token.realm_access.roles.includes('admin');

	const handleDownloadPerformance = async (id, type) => {

		setLoading(true);
		await setCanDownload(true);
		await setShareType('DOWNLOAD');
		toJpeg(document.getElementById(id))
			.then((dataUrl) => {
				setDataUrl(dataUrl)
				setCanDownload(false);
				setLoading(false);


				try {
					const img = new Image();
					img.onload = async function () {
						let high = (this.width > this.height) ? 'width' : 'height';
						// console.log('IMage 2 ', less, this[high], this.width, this.height);
						const resultImage = (this.width > 0 && this.height > 0) ? await resizeImageToSquare(dataUrl, { width: this[high], height: this[high] }) : null;
						let title = `match_Performances_${filtersData.season?.uid}_${matchDetails?.title}`;
						setTitle(title)
						if (resultImage) {
							if (type === 'download') {
								downloadFiles(resultImage, title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-'));
								handleShareMenuClose();

							}
							else {
								openCreateArenaDialog({
									open: true, arena: {}, stepType: 'crop', attachment: [
										{ url: resultImage, mimeType: "IMAGE", width: 4, height: 4, fileName: "kpi_performance_HHD-49_kpi_ck_bk -2022-08-18T133448.437.png", fileType: "image/png" }],
									postDetails: {
										targetPage: "MATCH_DETAILS",
										details: {
											league: filtersData?.league?._id,
											season: filtersData?.season?._id,
											round: filtersData?.round?._id,
											match: filtersData?.match?._id,
											subTab: {
												tab: "", // CORE_KPI, BONUS_KPI, TEAM, ROLE and REGION 
												value: "", // KPI_ID, TEAM_ID, ROLE_ID and REGION_ID
											},
										}
									}
								});
								handleShareMenuClose();

							}
						}
					}
					img.src = dataUrl;
					return img.onload();
				} catch (e) {
					console.error('index', e)
				}

			})

	}

	const handleShareScore = (id) => {
		setShareType('EMAIL');
		shareLiveScoreDialog({ open: true, fixture: 'MATCH', type: 'TEAM', matchDetails: matchDetails, details: { seasonId: filtersData.season?._id, roundId: filtersData.round?._id, matchId: filtersData?.match?._id, imageId: id }, filtersData: filtersData });
		handleShareMenuClose();
	}

	// console.log('filtersData ', filtersData, match);
	// useEffect(() => {
	// 	if (match.params?.matchId)
	// 		getMatchDetailsData(match.params?.matchId);
	// }, [match.params?.matchId]);

	useEffect(() => {
		if (filtersData?.match?._id)
			getMatchDetailsData(filtersData?.match?._id);
	}, [filtersData?.match?._id]);

	const getMatchDetailsData = (matchId) => {
		setLoading(true);
		getMatchDetails({
			matchId,
			onSuccessCB: (result) => {
				if (result.teams?.length > 0) {
					Promise.all([getBase64WithUrl(result.teams[0]?.logo_url), getBase64WithUrl(result.teams[1]?.logo_url)]).then((res) => {
						console.log('res match 2 ', res);
						setMatchDetails({ ...result, team1_logo: res[0], team2_logo: res[1] });
					}).catch((err) => console.log(err.response));
				}
				setLoading(false);
			}, onErrorCB: (error) => {
				setLoading(false);
				setMatchDetails({});
				enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to Matche details', {
					variant: 'error',
					preventDuplicate: true
				});
			}
		})
	}

	const handleViewChange = () => {
		historyPushWithSearch(`/arena/live-scores`, history, location.search);
	}

	const handleShareMenuOpen = (event) => {
		setShareAnchorEl({ el: event.currentTarget, arena: null });
	};

	const handleShareMenuClose = () => {
		setShareAnchorEl({ el: null, arena: null });
	};


	const renderShareOptions = (

		<Menu
			anchorEl={shareAnchorEl.el}
			classes={{ paper: classes.profilePopup }}
			id="share-options-menu"
			keepMounted
			open={isShareMenuOpen}
			onClose={handleShareMenuClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			style={{ zIndex: 1301 }}
		>
			<MenuItem onClick={() => handleDownloadPerformance('match-perfomances-table', 'download')} className={classes.shareOptionStyles}><GetAppOutlinedIcon id="download" />Download</MenuItem>
			<MenuItem onClick={() => handleDownloadPerformance('match-perfomances-table', 'share')} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-wall" />Share to Wall</MenuItem>
			<MenuItem onClick={() => handleShareScore('match-perfomances-table')} className={classes.shareOptionStyles}><MailOutlineOutlinedIcon id="Email" />Email </MenuItem>
		</Menu>
	);

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 8 }}>
				{/* {matchDetails && <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleViewChange()}><ChevronLeft /></IconButton>} */}
				<div></div>

				{isAdmin && matchDetails ? <div className={classes.iconDiv}>
					<IconButton onClick={(e) => handleShareMenuOpen(e,)} >
						<ShareSharpIcon fontSize='small' />
					</IconButton>
				</div> : null}
				{renderShareOptions}
			</div>
			{matchDetails && <MatchPerformanceTemplate
				id="match-perfomances-table"
				filtersData={filtersData}
				belongsTo='Match'
				match={matchDetails}
				shareType={shareType}
				render={(canDownload || dialog?.open)}
			/>}
			<TeamsMatchSummary loading={loading} matchId={filtersData?.match?._id} matchDetails={matchDetails} filtersData={filtersData} />
			<PlayersMatchSummary loading={loading} matchId={filtersData?.match?._id} matchDetails={matchDetails} filtersData={filtersData} />
		</div>
	)
}

const mapStateToProps = (state) => ({
	userDetails: state.user,
	loading: state.loading,
	keycloak: state.keycloak,
	dialog: state.game.liveScoresShare
});

const mapDispatchToProps = (dispatch, props) => {
	return {
		setLoading: value => dispatch(setLoading(value)),
		getMatchDetails: (options) => dispatch(getMatchDetails(options)),
		shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
		openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),

	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchDetails));
