import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Divider, Drawer, IconButton, Tabs, Tab, SvgIcon, Avatar } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { boardDetails } from '../../../ducks/pms'
import { Close } from '@material-ui/icons';
import {GetTasksProgress} from '../ProjectList'
import { getAvatarText } from '../../../helpers';
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    drawer: {
        // width: 310,
        // marginRight: 30,
        // flexShrink: 0,
        // padding: '20px 0'
        // flexShrink: 0,
        position: 'fixed',
        right: 0,
        transition: '0.5s ease-in-out',
        height: '100%'
    },
    drawerPaperOpen: {
        width: 250,
        overflowX:'hidden',
        position: 'relative',
        transform: 'none !important',
        visibility: 'visible !important',
        border: 'none',
        transition: 'width 0.5s ease-in-out',
        height: '84vh',
        padding: '20px 0',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`,
        zIndex: 1099,
        // paddingTop: 40
    },
    drawerPaperClose: {
        width: 0,
        position: 'relative',
        transform: 'none !important',
        visibility: 'visible !important',
        border: 'none',
        transition: 'width 0.5s ease-in-out',
        maxHeight: '84vh',
        padding: '20px 0',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    profileButton: {
        position: 'absolute',
        left: '-48px',
        height: 'fit-content',
        marginTop: 8,
        paddingRight: 0,
        paddingLeft: 0,
        zIndex: 10,
        transition: '0.5s ease-in-out',
        '&:hover': {
            backgroundColor: 'inherit',
        },
        [theme.breakpoints.down('xs')]: {
            top: -12,
        }
    },
    svgIcon: {
        width: 48,
        overflow: 'inherit',
        fontSize: '1.5rem',
        '& rect': {
            fill: theme.palette.primary.main,
        }
    },
    profileTabsRoot: {
        minHeight: '30px',
        // margin: '30px 10px 0px'
    },
    profileTabRoot: {
        display:'flex',
        alignItems:'center',
        minWidth: 50,
        minHeight: 'auto',
        fontSize: 15,
        textTransform: 'capitalize',
        fontWeight: 600,
        padding: '5px',
        // [theme.breakpoints.down('sm')]: {
        //     padding: '14px 0'
        // },
    },
    tabIndicatorProfile: {
        minWidth: 65,
    },
    buttonOnOverlay: {
        left: -338,
        [theme.breakpoints.down('xs')]: {
            top: -12,
            left: -302
        },

        // right: 310
    },
    boardTitle: {
        whiteSpace: 'nowrap',
        width: '165px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#58595B',
        fontSize: '16px',
        fontWeight: 600,
        padding: 6
    },
    avatarStyles: {
        fontSize: '1rem',
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 30,
        width: 30,
        // marginRight:5,
    },
    closeButton: {
        position: 'absolute',
        right: -5,
        top: 0
    },
    memberNameStyles: {
        color: " #58595B",
        margin: 0,
        fontSize: "14px",
        fontWeight: 500,
        paddingLeft: 5
    },
    stageCountStyles: {
        marginLeft: 5,
        fontSize: 9,
        fontWeight: 700,
        color: "#535353",
        // marginBottom: 1
    },
    stageSequenceStyles:{
        fontSize: 13,
        fontWeight: 700,
        color: "#535353",
        marginBottom: 1
    }
}));
const MembersInfo = (props) => {
    const classes = useStyles();
    const { fromSearch, board } = props;
    const users = board.type === 'Owners' ? board.details.boardOwners : board.details.members;
    return (

        <div>
            <div className={classes.profileTabRoot}>{board.type} <p className={classes.stageCountStyles}>({users.length})</p></div>
            {
                users.length > 0 ?
                    users.map((member, ind) => (
                        <div style={{ padding: "5px 10px", display: 'flex', alignItems: 'center' }} key={ind}>
                            <Avatar
                                // alt={user.name}
                                src={member.photo_url}
                                classes={{ colorDefault: classes.avatarStyles }}
                                style={{ height: 25, width: 25,fontSize:'0.7rem' }}
                            >
                                {(member.name && getAvatarText(member.name))}
                            </Avatar>
                            <p style={{paddingBottom:5}} className={classes.memberNameStyles}>{member.name}</p>
                            {/* <span className={classes.memberRoletStyles}>(Member)</span> */}
                        </div>
                    ))
                    : null
            }
        </div>
    )
};

const StagesInfo = (props) => {
    const classes = useStyles();
    const { fromSearch, board } = props;
    const stages = board.details.stages || [];
    return (
        stages.length > 0 ?
        <div>
        <div className={classes.profileTabRoot}>Stages <p className={classes.stageCountStyles}>({stages.length})</p></div>
            {
                stages.map((stage, ind) => (
                    <div style={{ padding: "5px 10px", display: 'flex', alignItems: 'center' }} key={ind}>
                        <span className={classes.stageSequenceStyles}>{stage.sequence})</span>
                        <p className={classes.memberNameStyles}>{stage.label}</p>
                        <span className={classes.stageCountStyles}>({stage.count})</span>
                    </div>
                ))
                
            }
        </div>
        : null
    )
}

const BoardDetails = (props) => {
    const classes = useStyles();
    const [drawerOverlay, setDrawerOverlay] = useState(false);

    useEffect(() => {
        if(props.board.noOfBoards){
        if (window.innerWidth < 1100 || props.board.noOfBoards <= 3) {
            setDrawerOverlay(true);
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth < 1100) {
                setDrawerOverlay(true);
            }
            else {
                setDrawerOverlay(false);
            }
        });
        return () => {
            window.removeEventListener("resize", () => { });
        }
    }
    }, [props.board.noOfBoards]);

    const handleClose = () => {
        props.boardDetails({ open: false, type: '',noOfBoards:0, details: {stages:[]} })
    }
    return (
        <div className={classes.root}>
            <Drawer
                className={drawerOverlay && classes.drawer}
                variant="persistent"
                anchor="right"
                open={props.board.open}
                classes={{
                    paper: props.board.open ? classes.drawerPaperOpen : classes.drawerPaperClose,
                }}
            >
                <div style={{display:'flex',alignItems:'center', margin: "-27px 0px -10px 5px" }}>
                    <Avatar
                        // alt={user.name}
                        src={props.board.details.photoUrl}
                        classes={{ colorDefault: classes.avatarStyles }}
                        style={{ height: 30, width: 30 }}
                    >
                        {(props.board.details.name && getAvatarText(props.board.details.name))}
                    </Avatar>
                    <p className={classes.boardTitle}>{props.board.details.name}</p>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </div>
                <div style={{padding:5}}>

                <GetTasksProgress stages={props.board.details.stages}/>
                </div>
                <Divider />
                <div style={{ overflowX: 'auto' }}>
                    <div>
                        {props.board.open &&
                        <StagesInfo {...props} />}

                    </div>
                    <div>
                        {
                            props.board.open &&
                            <MembersInfo {...props} />
                        }
                    </div>
                </div>
            </Drawer>
        </div>

    )
}

const mapStateToProps = (state) => ({
    board: state.pms.boardDetails
})

const mapDispatchToProps = (dispatch) => {
    return {
        boardDetails: (options) => dispatch(boardDetails(options)),
    }
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardDetails));