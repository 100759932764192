
import { makeStyles } from '@material-ui/core';
import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import ArenaList from '../Arena/ArenaList';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "38px",
    height: "38px",
    paddingLeft: "1rem"
  },
  header: {
    // paddingTop: 1,
    backgroundColor: '#f7f7f7',
    '& div': {
      marginTop: 0,
      // borderRadius: 0
    }
  },
  root: {
    background: "#ffffff",
  },
}));

const Posts = (props) => {
  const classes = useStyles();

  useEffect(() => {
    if (props.search.query) {
      // getSearchResults(props.search.query, searchResult.limit, 0)
    }
  }, [props.search.query]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {
          <ArenaList newPost={props.newPost} handleOnNewPost={props.handleOnNewPost} fromSearch query={props.search.query} />
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  search: state.search.arena
});

export default connect(mapStateToProps, null)(Posts)

