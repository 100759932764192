import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, TableRow, TableCell, Tooltip, TableHead, Avatar } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { setLoading } from "../../../ducks/loading";
import { getDivisionWiseKpi, getSeasonKpiPositionList } from "../../../ducks/game";
import { getAvatarText, getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort } from "../../../helpers";
import IllustrationView from "../../LiveScores/IllustrationView";
import GetCountryCurrencyFormat from "../../GlobalComponents/GetCountryCurrencyFormat";
import { isRuleBasedOnActuals } from "../../LiveScores/RaceSummary";
import AvatarComponent from "../../AvatarComponent/AvatarComponent";
import LiveScoresMuiTable from "../../LiveScores/LiveScoresMuiTable";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import qs from 'query-string';


const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginBottom: 35,
        '& thead': {
            '& tr': {
                backgroundColor: '#EBEBEB !important',
                '& th': {
                    backgroundColor: '#EBEBEB !important',
                }
            }
        },
        '& .table-footer': {
            background: 'white',
            fontSize: 12,
            padding: '10px 0px !important'
        },
        '& table': {
            '& thead': {
                '& tr:nth-child(1)': {
                    '& th:nth-last-child(1)': {
                        position: 'sticky !important',
                        right: 0,
                        zIndex: 1,
                        backgroundColor: '#EBEBEB !important',
                    },
                    '& th:nth-child(1)': {
                        position: 'sticky !important',
                        left: 0,
                        zIndex: 1,
                    },
                    '& th:nth-child(2)': {
                        position: 'sticky !important',
                        left: 51,
                        zIndex: 1,
                    },
                },
            },
            '& tbody': {
                '& tr': {
                    '& td:nth-last-child(1)': {
                        position: 'sticky !important',
                        right: 0,
                        zIndex: 1,
                        background: 'white'
                    },
                    '& td:nth-child(1)': {
                        position: 'sticky !important',
                        left: 0,
                        zIndex: 1,
                        background: 'white'
                    },
                    '& td:nth-child(2)': {
                        position: 'sticky !important',
                        left: 51,
                        zIndex: 1,
                        background: 'white'
                    },
                }
            },
        },
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        // paddingBottom: 10
    },
    divisin: {
        padding: 10
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12
    },
    colorDefault: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer'
    },
}));

const PlayerReportees = ({ history, data, dynamicColumns, dynamicHeaders, filtersData, setLoading, loading, userDetails, seasonFormat, seasonKpiPostions, getDivisionWiseKpi, getSeasonKpiPositionList, handleProfileClick }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const [decimalView, setDecimalView] = useState(false);


    const SummaryTable = ({ data, dynamicColumns, dynamicHeaders }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns, {
                title: "Points",
                field: "totalPoints",
                editable: 'never',
                templateRender: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>,
                render: (rowData) => <div style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</div>
            }]}
            data={data}
            options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
            }}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            <TableCell rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            <TableCell align='left' style={{ textAlign: 'left' }} rowSpan={2}>{props.columns[1]?.title}</TableCell>
                            <TableCell align='left' style={{ textAlign: 'left' }} rowSpan={2}>{props.columns[2]?.title}</TableCell>
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <>
                                        <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                            <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                        </TableCell>
                                    </>
                                ))
                            }
                            <TableCell rowSpan={2}>Points</TableCell>
                        </TableRow>
                        <TableRow>
                            {
                                dynamicHeaders.map((obj) => (
                                    <>
                                        {
                                            obj?.summary_attributes.length ?
                                                <>
                                                    {
                                                        obj?.summary_attributes.map(ele => (
                                                            ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                        ))
                                                    }
                                                </>
                                                :
                                                <>
                                                    {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                    {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>}
                                                    {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                </>
                                        }
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    );

    const columns = [
        {
            title: "Rank",
            field: "rank",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            emptyValue: '--',
            cellStyle: { maxWidth: 200, width: 200, minWidth: 160 },
            templateRender: (rowData) => rowData.player?.name.toUpperCase(),
            render: (rowData) => rowData.player ? (
                <div style={{ fontSize: 12 }} className={classes.nameRoot}>
                    <Avatar
                        alt={rowData?.player_name}
                        src={rowData?.player_photo_url}
                        classes={classes.colorDefault}
                        style={{ cursor: 'pointer', marginRight: 8 }}
                        onClick={(e) => handleProfileClick(rowData?.employee_id)}
                    >
                        {getAvatarText(rowData?.player_name)}
                    </Avatar>
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            cellStyle: { minWidth: 150, textAlign: 'left' },
            emptyValue: '--',
            width: 150,
        },
    ]

    return (
        <div className={classes.root}>
            <div className={classes.performance}>
                <div className={classes.divisin}>
                    <div className=''>
                        <SummaryTable
                            data={data}
                            dynamicColumns={dynamicColumns}
                            dynamicHeaders={dynamicHeaders}
                        />
                    </div>
                </div >
            </div >
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getDivisionWiseKpi: (options) => dispatch(getDivisionWiseKpi(options)),
        getSeasonKpiPositionList: (options) => dispatch(getSeasonKpiPositionList(options))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerReportees));
