import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/chats");

export const SET_INDEX = constant("SET_INDEX");

export const setSidebarIndex = (payload) => ({
    type: SET_INDEX,
    payload
});

const initialState = {
    index: null
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_INDEX: {
            // console.log('SET_INDEX ', action)
            return {
                index: action.payload
            }
        }

        default:
            return state;
    }
};
