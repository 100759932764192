import React from 'react';
import { TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ExpandMore } from '@material-ui/icons';

export const TextComponent = ({ type = "text", ...otherProps }) => (
    <>
        <TextField type={type} {...otherProps} maxLenth={25} />
    </>
)

export const DateComponent = ({ ...otherProps }) => (
    <>
        <DatePicker {...otherProps} />
    </>
)

export const AutocompleteComponent = ({ ...otherProps }) => (
    <>
        <Autocomplete {...otherProps} popupIcon={<ExpandMore />} />
    </>
)
