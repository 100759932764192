import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { AccessTime, Edit } from '@material-ui/icons';
import { useTheme } from '@material-ui/core';
import classNames from 'classnames';
import ChatList from './ChatList';
import ContactList from './ContactList';
import GroupChatSection from '../ChatSection/GroupChatSection';
import ChatSection from '../ChatSection/ChatSection';
import LandingImage from '../../assets/images/chase-chimp.png';
// import { ReactComponent as NewChatIcon } from '../../assets/icons/edit-2.svg';
import { ReactComponent as NewChatIcon } from '../../assets/icons/new-chat-1.svg';
import ProfileDetails from './ProfileDetails';
import { connect } from 'react-redux';
import { setSidebarIndex } from '../../ducks/sidebar';
import Snackbar from '../Snackbar';
import firebase from "firebase";


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    mainRoot: {
        width: '100%',
        maxWidth: 290,
        backgroundColor: '#fff',
        transition: 'width 0.25s ease-in-out',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            // width: '0px',
            // minWidth: 0,
            position: 'fixed',
            borderRight: '1.5px solid #eeeeee'
        },
    },
    mainRootWidth: {
        width: 0,
        minWidth: 0
    },
    root: {
        flexGrow: 1,
        maxWidth: 320,
        // backgroundColor: '#fff',
        // transition: 'width 0.5s ease-in-out',
        // [theme.breakpoints.down('sm')]: {
        //     width: '0px',
        // },
    },
    tabsRoot: {
        // [theme.breakpoints.down('sm')]: {
        //     padding: '14px 0'
        // },
        padding: '14px 20px'
    },
    tabRoot: {
        borderBottom: '1px solid #0000003a',
        padding: 0,
        textTransform: 'capitalize',
        fontSize: 13,
        fontWeight: 600,
        minWidth: 100,
        color: '#838383'
    },
    tabWrapper: {
        flexDirection: 'row',
        '& > *:first-child': {
            // fontSize: '21px',
            margin: '0 6px !important'
        }
    },
    labelIcon: {
        minHeight: '50px'
    },
    tabIndicator: {
        height: '3px',
    },
    chatArea: {
        width: '100%',
        // backgroundColor: '#F3F2F1',
        display: 'flex',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`
    },
    landingPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: 'inherit',
        '& img': {
            [theme.breakpoints.down('sm')]: {
                // height: 260
            }
        }
    },
    landingPageContent: {
        textAlign: 'center',
        maxWidth: '90%'
    },
    landingPageText: {
        marginTop: 0,
        // fontFamily: 'cursive',
        fontSize: 25,
        fontWeight: 600,
        color: '#58595b'
        // color: theme.palette.primary.main
    },
    svgIconStyle: {
        '& path': {
            // fill: 'currentColor'
            // color: theme.palette.primary.main,
            // stroke: theme.palette.primary.main,
        }
    },
    selectedStyles: {
        '& path': {
            stroke: theme.palette.primary.main,
        }
    },
    landingImg: {
        width: '70%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

const CommonListLayout = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [currentChat, setCurrentChat] = useState(null);
    const [openList, setOpenList] = useState(false);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setValue(newValue);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 960) {
                setOpenList(false);
            }
            else {
                props.setSidebarIndex(null);
            }
        });
        if (firebase.messaging.isSupported()) {
            const analytics = firebase.analytics();
            analytics.logEvent('page_view', {
                page_location: window.location.href,
                page_path: window.location.pathname,
                page_title: 'Chat'
            });
        }
    }, []);

    useEffect(() => {
        console.log('sidebar chat index ', props.sidebar, openList)
        if (window.innerWidth <= 960) {
            if (props.sidebar.index !== null) {
                setOpenList(false);
            }
            else setOpenList(true);
        }
    }, [props.sidebar])

    useEffect(() => {
        setValue(0);
    }, [props])

    const handleChat = (chatDetails, existingCurrent) => {
        console.log('sidebar handleChat index ', props.sidebar, openList)
        setCurrentChat(chatDetails);
        if (value !== 0) {
            setValue(0);
        }
        if (window.innerWidth <= 960 && !existingCurrent) {
            setOpenList(true);
            props.setSidebarIndex(null);
        }
    }

    return (
        <>
            <div className={classNames(classes.mainRoot, openList && classes.mainRootWidth)}>
                <div className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="icon label tabs example"
                        className={classes.tabsRoot}
                        classes={{ indicator: classes.tabIndicator }}
                    >
                        <Tab className={classes.tabRoot}
                            classes={{ wrapper: classes.tabWrapper, labelIcon: classes.labelIcon }}
                            icon={<AccessTime />}
                            label="Recent"
                        />
                        <Tab className={classes.tabRoot} classes={{ wrapper: classes.tabWrapper, labelIcon: classes.labelIcon, selected: classes.selectedStyles }} icon={<NewChatIcon className={classes.svgIconStyle} />} label="New Chat" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <ChatList handleChat={handleChat} {...props} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ContactList handleChat={handleChat} {...props} />
                    </TabPanel>
                </div>
            </div>
            <div className={classes.chatArea}>
                {currentChat ? (currentChat.type === 'group' ?
                    <GroupChatSection {...currentChat} />
                    :
                    <ChatSection {...currentChat} />)
                    :
                    <div className={classes.landingPage}>
                        <div className={classes.landingPageContent}>
                            <img className={classes.landingImg} src={LandingImage} alt="Landing_Page"></img>
                            <p className={classes.landingPageText}>Stay connected, make conversation happen...</p>
                        </div>
                    </div>
                }
                {
                    currentChat && <ProfileDetails handleChat={handleChat} currentChat={currentChat} />
                }
                <Snackbar />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    sidebar: state.sidebar
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setSidebarIndex: (options) => dispatch(setSidebarIndex(options))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonListLayout);