import React, { Component, useEffect, useState } from "react";
import { Dialog, IconButton, DialogTitle, DialogContent, ListItem, ListItemAvatar, Avatar, List, Divider, ListItemText, Button, ListItemSecondaryAction, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from "react-redux";
import { ChaseonsSkeleton, ChaseonsTemplate } from "./ReactionsDialog";
import { getSharesList, openSharesListDialog } from "../../ducks/arena";
import { Close } from '@material-ui/icons';
import { followUser, unFollowUser } from "../../ducks/favourites";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: 6,
  },
  dialogPaper: {
    minHeight: 468,
    borderRadius: 15,
    maxWidth: 600
  },
  dialogTitle: {
    padding: "21px 40px",
    fontSize: 22,
    fontWeight: 600,
    backgroundColor: "#f5f6f7",
    color: "#535353",
  },
  dialogContent: {
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
}));

const ShareListDialog = ({ numberOfShareFaviourite: { open, postId }, openSharesListDialog, getSharesList, user, followUser, unFollowUser, history }) => {
  const [filteredShareList, setFilteredShareList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles();
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (postId) {
      setSkeletonLoading(true);
      getSharesList({ onSuccessData, postId, onErrorCB })
    }
  }, [postId])
  const onSuccessData = (data) => {
    setFilteredShareList([])
    console.log("onSuccessdata", data);
    if (data) {
      setSkeletonLoading(false)
      setFilteredShareList(data)
    }

    else {
      setFilteredShareList([])
    }

  }
  const handleClose = () => {

    openSharesListDialog({ open: false, postId: null });
  }
  const onErrorCB = (result) => {
    enqueueSnackbar(`Failed to fetch ShareList.`, {
      variant: "error",
      preventDuplicate: true
    });
    handleClose();
  }

  const onFollowUserSuccess = result => {
    let filterSharedListDup = JSON.parse(JSON.stringify(filteredShareList));
    const element = filterSharedListDup
    if (element.some(item => item.userId === result.username)) {
      (element.find(item => item.userId === result.username)).follower = true
    }
    setFilteredShareList(filterSharedListDup)
  }
  const onFollowUserError = error => {
    console.log('onFollowUserError ', error.response);
  };
  const onUnFollowUserSuccess = result => {
    let filterSharedListDup = JSON.parse(JSON.stringify(filteredShareList));
    const element = filterSharedListDup
    if (element.some(item => item.userId === result.username)) {
      (element.find(item => item.userId === result.username)).follower = false
    }
    setFilteredShareList(filterSharedListDup)
  }
  const onUnFollowUserError = error => {
    console.log('onUnFollowUserError ', error.response);
  }

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="share-arena-dialog"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle
          disableTypography
          onClose={handleClose}
          className={classes.dialogTitle}
        >
          Chaseons who shared this post
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {!skeletonLoading && [0].map(i => <ChaseonsSkeleton key={i} />) ?

            filteredShareList.length && filteredShareList.map((detailsItem, i) => (

              <ChaseonsTemplate key={i}
                details={detailsItem} isLoggedInUser={user.username === detailsItem.userId}
                onChaseonsClick={() => {
                  handleClose();
                  history.push(`/wall/profile/${detailsItem.userId}`);
                }}
                followUser={() => followUser({
                  requestObj: { username: detailsItem.userId },
                  onFollowUserSuccess,
                  onFollowUserError
                })}
                unFollowUser={() => unFollowUser({
                  username: detailsItem.userId,
                  onUnFollowUserSuccess,
                  onUnFollowUserError
                })
                } />
            )) : skeletonLoading && [0, 1, 2, 3].map(i => <ChaseonsSkeleton key={i} />)
          }
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  numberOfShareFaviourite: state.arena.numberOfShareFaviourite,
  user: state.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    openSharesListDialog: (option) => dispatch(openSharesListDialog(option)),
    followUser: options => dispatch(followUser(options)),
    unFollowUser: options => dispatch(unFollowUser(options)),
    getSharesList: (option) => dispatch(getSharesList(option))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareListDialog));
