import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, useMediaQuery, useTheme, IconButton, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Close, CodeSharp } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { setLoading } from '../../ducks/loading';
import { sendMessage } from '../../ducks/messages';
import { requestPushNotification } from '../../ducks/notification';
import { convertUnicode } from '../../helpers';
import { getTeams } from '../../ducks/teams';
import { openShareTaskDialog } from '../../ducks/pms';
import ChaseOnsAutocomplete from '../Arena/ChaseOnsAutocomplete';
import CustomAutocomplete from '../GlobalComponents/CustomAutocomplete';

const useShareTaskToChatTemplateStyles = makeStyles((theme) => ({
    root: {
        border: '1px solid #dedede',
        padding: 11,
        fontSize: 14,
        color: '#535353'
    },
    text: {
        marginTop: 5
    },
    link: {
        color: "#112386",
        textDecoration: "none",
        marginTop: "12px",
        fontSize: "12px",
        lineHeight: "15px",
        flex: 1,
        //   paddingLeft: "0.6rem",
        paddingTop: "4px"
    },
    headingLabel: {
        fontSize: 14,
        color: '#535353',
        fontWeight: "bold"
    },
    valueStyle: {
        fontWeight: 600
    }
}));

export const TaskTemplate = ({ taskLink, board, taskId, targetDate, taskTitle, assigneId, assigneName, onClick = () => { }, clickable = false }) => {
    const classes = useShareTaskToChatTemplateStyles();
    return (
        <div className={classes.root} style={{ cursor: clickable ? 'pointer' : 'default' }} onClick={() => onClick()}>
            <p className={classes.text}>Title:<span className={classes.valueStyle}>{taskTitle}</span></p>
            {/* <p className={classes.text}><span className={classes.link}>{taskLink}</span></p> */}
            <p className={classes.text}>Board:<span className={classes.valueStyle}>{board}</span></p>
            <p className={classes.text}>Assigned To: <span className={classes.valueStyle}>{assigneName}</span></p>
            <p className={classes.text}>Target Date: <span className={classes.valueStyle}>{targetDate}</span></p>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    },
    dialogPaper: {
        minHeight: 470,
        borderRadius: 15,
        maxWidth: 825
    },
    dialogTitle: {
        padding: '21px 40px',
        fontSize: 22,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogContent: {
        padding: '13px 46px'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 10
    },
    content: {
        marginTop: 15
    },
    buttonRoot: {
        margin: '30px 0 20px',
        textAlign: 'center',
        '& button:nth-child(2)': {
            marginRight: 0
        }
    },
    button: {
        margin: '0 7px',
        width: 120,
        height: 43,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    templateRoot: {
        margin: '20px 0 20px'
    }
}))

const ShareTaskToChat = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { shareTaskToChat: { open, details }, openShareTaskDialog, setLoading, sendMessage, currentUser, requestPushNotification } = props;
    const [selectedChaseOns, setSelectedChaseOns] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [teamsList, setTeamsList] = useState([]);
    console.log('shareTaskToChat ', props);

    useEffect(() => {
        props.getTeams({ onSuccessCB, onErrorCB })
    }, [])

    const onSuccessCB = (data) => {
        setTeamsList([...data])
        console.log(data)
    }

    const onErrorCB = (error) => {
        console.log(error)
    }

    const handleClose = () => {
        setSelectedChaseOns([]);
        setSelectedTeams([])
        openShareTaskDialog({ open: false, details: {} });
    }

    const handleSharePost = () => {
        let sampleMsg = {
            msgType: 'link',
            url: details.taskLink,
            body: `Title : ${details.taskTitle}\nBoard : ${details.board}\nAssignee : ${details.assigneName}\nTarget Date : ${details.targetDate}`
            // taskTitle: details.taskTitle,
            // taskId: details.taskId,
            // assigneId: details.assigneId,
            // assigneName: details.assigneName,
            // targetDate: details.targetDate
        }
        // console.log(selectedTeams)

        let generatedMsg = `%%%${JSON.stringify(sampleMsg)}%%%`;
        [...selectedChaseOns, ...selectedTeams].forEach(user => {
            setLoading(true);
            sendMessage({
                msg: {
                    to: user.username ? `${user.username}@${process.env.REACT_APP_XMPP_DOMAIN}` : user.targetJid,
                    type: user.username ? 'chat' : 'groupchat',
                    body: generatedMsg,
                    from: `${currentUser.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                },
                onMessageSentSuccess, onMessageSentFailed
            });
        });
    }

    const onMessageSentSuccess = (msgObj) => {
        // console.log('onMessageSentSuccess ', msgObj, targetDetails, currentUser);
        requestPushNotification({
            event_type: "new_message",
            members: [
                msgObj.to.split('@')[0]
            ],
            data: {
                body: msgObj.body,
                active: 'true',
                target_type: '0',
                targetJid: `${currentUser.username}@${process.env.REACT_APP_XMPP_DOMAIN}`,
                name: currentUser.name,
                image_url: currentUser.photoUrl,
                sender: currentUser.name
            }
        });
        setLoading(false);
        handleClose();
        enqueueSnackbar("Shared successfully", {
            variant: "success",
            preventDuplicate: true
        });
    }
    const onMessageSentFailed = (data) => {
        console.log('onMessageSentFailed ', data);
        enqueueSnackbar('Failed to send Message.', {
            variant: 'error',
            preventDuplicate: true
        });
        setLoading(false);
        handleClose();
    }

    const handleChangeAutocomplete = (e, data) => {
        console.log("data change ", data)
        setSelectedTeams(data);

    }
    const handleChangeEmployeeAutoComplete = (e, data) => {
        setSelectedChaseOns(data)
    }

    console.log('ShareToChatDialog ', details);
    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="share-arena-dialog"
                style={{ zIndex: 1301 }}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    Share in chat
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.content}>
                        <div className={classes.templateRoot}>
                            <TaskTemplate taskLink={details.taskLink} board={details.board} targetDate={details.targetDate} taskTitle={details.taskTitle} taskId={details.taskId} assigneId={details.assigneId} assigneName={details.assigneName} />
                        </div>
                        <CustomAutocomplete
                            multiple
                            openAutocomplete={true}
                            inputLabelText="Teams"
                            selectedChaseOns={selectedTeams}
                            handleChangeAutocomplete={handleChangeAutocomplete}
                            options={teamsList}
                            noOptionsText="No Teams found"
                            style={{ padding: 0 }}
                        />
                        <ChaseOnsAutocomplete openChaseons={true} inputLabelText="Employees" selectedChaseOns={selectedChaseOns} handleChangeAutocomplete={handleChangeEmployeeAutoComplete} />

                        <div className={classes.buttonRoot} >
                            <Button variant="text" size="medium" className={classes.button} onClick={() => handleClose()}>Cancel</Button>
                            <Button variant="contained" color="primary" size="medium" className={classes.button} onClick={() => handleSharePost()}>Send</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog >
        </div >
    )
}

const mapStateToProps = (state) => ({
    shareTaskToChat: state.pms.shareTaskToChat,
    currentUser: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        openShareTaskDialog: (options) => dispatch(openShareTaskDialog(options)),
        sendMessage: (msg) => dispatch(sendMessage(msg)),
        requestPushNotification: (msg) => dispatch(requestPushNotification(msg)),
        setLoading: (value) => dispatch(setLoading(value)),
        getTeams: (options) => dispatch(getTeams(options))
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ShareTaskToChat);