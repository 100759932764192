import React, { useEffect, useState } from "react";
import { getLeaguesList, getSeasonsList, getRoundsList, getMatchesList, getLoggedInUserRole, getRewardsList } from "../../../ducks/game";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoading } from "../../../ducks/loading";
import { makeStyles, Tabs, Tab, Grid } from "@material-ui/core";
import RewardsBannerComponent from "../PwcRewards/RewardsBanner";
import { Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { openCustomDialog } from "../../../ducks/meetings";
import CustomDialogComponent from "../../ChaseMeet/CustomDialogComponent";
import moment from 'moment';
import PwcDashboard from "./PwcDashboard";
import { CustomCard } from "../../Leagues/CustomCard";
import RewardsDetails from "../PwcRewards/RewardsDetails";

const useStyles = makeStyles((theme) => ({
    tabsContainerStyles: {
        // marginLeft: 34,
        justifyContent: 'center'
    },
    tabsRoot: {
        // width: '100%',
        // maxWidth: '100%',
        zIndex: 11,
        margin: '-8px -46px 10px',
    },
    tabStyles: {
        minHeight: 40,
        textTransform: 'capitalize',
        color: '#FFF',
        minWidth: `calc(${100 / 3}% - 20px)`,
        padding: '6px 16px 0px',
        fontSize: 13,
        textAlign: 'left',
        opacity: 1,
        margin: '0 10px',
        borderRadius: 5,
        minHeight: 40,
        transition: 'top 0.2s ease-in-out',
        boxShadow: '-4px 3px 10px -7px #000',
        backgroundColor: '#1C0C49',
        color: '#FFF',
        "& .label": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px 0',
            minWidth: 260,
            width: '100%',
            "& p": {
                margin: 0,
                wordBreak: 'break-all',
                maxWidth: 220,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                "&:first-child": {
                    fontWeight: 600
                },
                "&:nth-child(2)": {
                    fontSize: 11,
                    color: '#D6AF36',
                    fontWeight: 600,
                },
                "&:nth-child(3)": {
                    fontSize: 11,
                    marginTop: 5
                },
            }
        }
    },
    expiredLabel: {
        color: 'red',
        fontSize: 11,
        fontWeight: 600,
        borderRadius: 4,
        position: 'absolute',
        top: 7,
        left: 17,
    },
    tabStyleDiv: {
        minWidth: `calc(${100 / 3}% - 20px)`,
        minHeight: 40,
        textTransform: 'capitalize',
        padding: '6px',
        fontSize: 13,
        textAlign: 'left',
        opacity: 1,
        margin: '0 5px',
        borderRadius: 5,
        transition: 'top 0.2s ease-in-out',
        fontFamily: 'Poppins !impotant',
    },
}));

const Dashboard = ({ history, location, filtersData, rounds, userInfo, getOverallOrgStats, getLoggedInUserRole, getSeasonsList, getRewardsList, openCustomDialog }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [rewards, setRewards] = useState([]);

    useEffect(() => {
        if (filtersData?.season?._id)
            getRewardsList({ seasonId: filtersData?.season?._id, onSuccessCB, onErrorCB });
    }, [filtersData?.season?._id]);

    const onSuccessCB = (result, headers) => {
        if (result) {
            setRewards(result.map(reward => ({ ...reward, expired: moment(reward.end_date).isBefore(new Date(), 'day') })));
        }
        setLoading(false);
    }
    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const natigateToAllStats = () => {
        const historyPath = `${history.location?.search}&round_id=ALL`;
        history.push(`/leagues/stats/overall${historyPath}`)
    }
    const natigateToLiveScores = () => {
        history.push(`/leagues/live-scores${history.location?.search}`)
    }

    const navigateToTeamLeaderboard = () => {
        history.push(`/leagues/points-table/team${history.location?.search}`)
    }

    const navigateToPlayerLeaderboard = () => {
        history.push(`/leagues/points-table/player${history.location?.search}`)
    }

    const navigateToAllMatches = () => {
        history.push(`/leagues/matches${history.location?.search}`);
    }

    const handleRewardSelect = (index) => {
        openCustomDialog({ open: true, children: <RewardsDetails reward={rewards[index]} rewardIndex={index} filtersData={filtersData} />, title: "Rewards", from: "standings" });

        // openCustomDialog({ open: true, children: <RewardsDetails rewards={rewards} rewardIndex={index} />, title: "Season Rewards" });
    }


    return (
        <div style={{ padding: '10px 7px 10px 20px' }}>

            <PwcDashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToAllMatches={navigateToAllMatches} />
            <Grid container spacing={2}>

                <Grid item md={12} >
                    <CustomCard cardTitle="Contests" from="Rewards">
                        {rewards.length > 0 &&
                            <>
                                <Tabs
                                    id="filter-tabs"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="on"
                                    aria-label="scrollable auto tabs example"
                                    className={classes.tabsRoot}
                                    classes={{ flexContainer: rewards.length < 3 && classes.tabsContainerStyles }}

                                >
                                    {console.log('rewards list ', rewards)}
                                    {
                                        [...rewards].map((obj, i) =>
                                            (obj?.reward_status.search("Expired") === -1) ?

                                                <Tab className={classes.tabStyleDiv} key={i} onClick={() => handleRewardSelect(i)} label={

                                                    <div onClick={() => handleRewardSelect(i)} style={{ cursor: 'pointer' }} >
                                                        <RewardsBannerComponent obj={obj} style={{ marginLeft: 10 }} />
                                                    </div>


                                                } />
                                                :
                                                <Tab style={{ cursor: 'auto' }} className={classes.tabStyleDiv} key={i} label={
                                                    <div>
                                                        <RewardsBannerComponent obj={obj} style={{ marginLeft: 10 }} />
                                                    </div>
                                                } />
                                        )
                                    }
                                </Tabs>
                                {rewards?.length > 0 && <CustomDialogComponent />}
                            </>
                        }
                    </CustomCard>
                </Grid>
            </Grid>


        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLoggedInUserRole: options => dispatch(getLoggedInUserRole(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),
        getRewardsList: (options) => dispatch(getRewardsList(options)),
        openCustomDialog: (options) => dispatch(openCustomDialog(options))
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));