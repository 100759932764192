import { eventChannel, buffers } from "redux-saga";
import { actionChannel, take } from "redux-saga/effects";
import { v4 as uuid } from "uuid";

export function* takeEverySerially(actionType, saga) {
  const channel = yield actionChannel(actionType, buffers.expanding(10));

  while (true) {
    const action = yield take(channel);
    yield saga(action);
  }
}

export function makeChannel(client, events) {
  return eventChannel(emitter => {
    const group = uuid();

    for (const eventName of Object.keys(events)) {
      // client.on(eventName, group, (...args) => {
        client.on(eventName, (...args) => {
        console.log('client eventName ', eventName, args);
        events[eventName](emitter, ...args);
      });
    }

    // return () => client.releaseGroup(group);
    return () => client
  });


  // eventChannel(emitter => {
  //   const iv = setInterval(() => {
  //     console.log('countdown', secs)
  //     secs -= 1
  //     if (secs > 0) {
  //       emitter(secs)
  //     } else {
  //       emitter(END)
  //       clearInterval(iv)
  //       console.log('countdown terminated')
  //     }
  //   }, 1000);
  //   return () => {
  //     clearInterval(iv)
  //     console.log('countdown cancelled')
  //   }
  // }
  // )
}
