import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import defaultBanner from '../../assets/images/default-banner.png';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RewardsBannerComponent from './RewardsBanner';
import { decodeEnum, formatDate, getCurrencyFormat, getEllipsisText, getNumberFormat } from '../../helpers';
import gift from '../../assets/icons/gift.svg';
import moment from 'moment';
import { getRegionsList, getRewardStandings, getSeasonPlayersRoles } from '../../ducks/game';
import { connect } from 'react-redux';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomDataTable from '../GlobalComponents/CustomDataTable';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import RankLabel from '../PointsTables/RankLabel';
import IllustrationView from '../LiveScores/IllustrationView';
import MaterialTable from 'material-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import gameIllustration from '../../assets/images/game_Illustration.png'
import GetCountryCurrencyFormat from '../GlobalComponents/GetCountryCurrencyFormat';



const useStyles = makeStyles((theme) => ({
    mainRoot: {
    },
    expiredLabel: {
        color: 'red',
        fontSize: 10,
        fontWeight: 600,
        marginLeft: 12,
        whiteSpace: 'nowrap'
    },
    rewardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    rewardInfo: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    standings: {
        padding: '10px'
    },
    showMoreButton: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
        color: '#5D5FEF',
        fontWeight: 500,
        cursor: 'pointer',
        margin: '10px'

    },
    table: {
        '& table': {
            overflowX: 'auto',
            width: '100%',
            borderSpacing: '0px',
            '& thead': {

                // backgroundColor: theme.palette.primary.main,

                backgroundColor: `${theme.palette.primary.main}1A !important`,
                lineHeight: '2.2rem',
                '& th': {
                    padding: ' 5px 10px!important',
                    fontWeight: 600,
                    fontSize: '12px',
                    color: '#333',
                    textAlign: 'center',
                    '&:first-child': {
                        borderTopLeftRadius: '5px !important',

                    },
                    '&:last-child': {
                        borderTopRightRadius: '5px !important',

                    }


                },
            },
            '& tbody': {
                '& td': {
                    padding: '3px 10px',
                    fontSize: '12px !important',
                    borderWidth: '0px 0px 1px 0px!important',
                    color: '#333333',
                    textAlign: 'center',
                    border: '0.3px solid #f3eded !important',

                }
            }
        }
    },
}));

const Levels = [
    { title: 'Season Award', id: 'SEASON' },
    { title: 'Round Award', id: 'ROUND' },
    { title: 'Sprint Award', id: 'MATCH' },
    { title: 'Sprint Award', id: 'SPRINT' },
    { title: 'Onspot Award', id: 'ONSPOT' },
]

const RewardsDetails = ({ reward = [], rewardIndex, getRewardStandings, setLoading, userDetails, history, filtersData, getSeasonPlayersRoles, getRegionsList }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(rewardIndex || 0);
    const [currentRewardStandings, setCurrentRewardStandings] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { path } = useRouteMatch();
    const [rolesList, setRolesList] = useState([]);
    const [regionsList, setRegionsList] = useState([]);


    useEffect(() => {
        if (filtersData.season?._id) {
            getRolesList(filtersData.season._id);
            getListOfRegions(filtersData.season._id);


        }
    }, [filtersData.season?._id]);

    const getRolesList = (seasonId) => {
        setLoading(true);
        getSeasonPlayersRoles({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setRolesList([...result]);
                }
                else {
                    setRolesList([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const getListOfRegions = () => {
        setLoading(true);
        getRegionsList({
            params: { limit: 5000, page: 0 },
            onSuccessCB: (result, headers) => {
                setRegionsList([...result]);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    useEffect(() => {
        if (reward._id)
            getRewardStandings({ rewardId: reward._id, params: { limit: 100, page: 1 }, onSuccessRewardStandingsCB, onErrorRewardStandingsCB });

    }, [reward._id])

    const onSuccessRewardStandingsCB = (result) => {
        if (result.length > 0) {
            setCurrentRewardStandings(result);

        }
        else {
            setLoading(false);

            setCurrentRewardStandings([])
        }
    }

    const onErrorRewardStandingsCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Reward Standings', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const suffixForPosition = (i) => {
        const j = i % 10;
        const k = i % 100;
        if (j == 1 && k != 11) {
            return "st";
        }
        if (j == 2 && k != 12) {
            return "nd";
        }
        if (j == 3 && k != 13) {
            return "rd";
        }
        return "th";


    }

    const handleViewAllStandings = (reward) => {
        if (reward.level === 'SEASON' && reward.kpi_id) {
            if (reward.category === 'TEAM') {
                history.push(`/leaguess/c2/leaderboard/team${history.location?.search}`);

            }
            else {
                history.push(`/leaguess/c2/leaderboard/player${history.location?.search}`);

            }


        }
        else if (reward.level === 'SEASON') {
            const historyPath = `${history.location?.search}&match=ALL`;
            if (reward.category === 'TEAM') {
                history.push(`/leaguess/c2/stats/team${historyPath}`);

            }
            else {
                history.push(`/leaguess/c2/stats/player${historyPath}`);

            }

        }
        else if ((reward.level === 'SPRINT' || reward.level === 'ROUND')) {
            if (reward.category === 'TEAM') {
                if (reward.level === 'ROUND') {
                    const historyPath = `${history.location?.search}&match=${reward.round_id}`;
                    history.push(`/leaguess/c2/stats/team${historyPath}`);

                }
                else {
                    const historyPath = `${history.location?.search}&match=${reward.match_id}`;
                    history.push(`/leaguess/c2/stats/team${historyPath}`);
                }

            }
            else {
                if (reward.level === 'ROUND') {
                    const historyPath = `${history.location?.search}&match=${reward.round_id}`;
                    history.push(`/leaguess/c2/stats/player${historyPath}`);

                }
                else {
                    const historyPath = `${history.location?.search}&match=${reward.match_id}`;
                    history.push(`/leaguess/c2/stats/player${historyPath}`);
                }

            }

        }

    }


    const columns = (tableData) =>

        [
            {
                field: "rank",
                title: "Rank",
                cellStyle: { maxWidth: 70, width: 50 },
                cellStyle2: { backgroundColor: 'yellow' },
                options: {
                    customBodyRender: (rank, tableMeta) => (
                        <div className={classes.nameRoot}>
                            {rank}
                        </div>
                    ),
                },
            },
            {
                field: "name",
                title: "Player",
                hidden: reward.category === 'TEAM',
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                render: (tableData) => (
                    <div className={classes.nameRoot}>
                        {console.log('tableeeee', tableData)}

                        <AvatarComponent data={tableData} type="PLAYER" name={tableData.name} username={tableData.employee_id} url={tableData.player_logo_url} onClick={() => { }} style={{ marginRight: 14, width: '35px', height: '35px' }} from="standings" filtersData={filtersData} />
                        {tableData.name}
                    </div>

                )
            },
            {
                field: "team_name",
                title: "Team",
                align: "center",
                cellStyle: { minWidth: 150, maxWidth: 250 },
                hidden: reward.season_format === 'PLAYER_RACE',

                render: (tableData) => (
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                        <div className={classes.nameRoot}>
                            <AvatarComponent type='TEAM' name={tableData.team_name} username={tableData.team_id} url={tableData.team_logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8, width: '35px', height: '35px' }} from="standings" filtersData={filtersData} />
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            {tableData.team_name}
                        </div>
                    </div>

                )
            },
            {
                field: "actual",
                title: "Actual",
                hidden: reward.reward_criteria === "ACHIEVEMENT" || reward.reward_criteria === "POINTS" || reward.reward_criteria === "RANK",
                render: (tableData) =>
                    reward?.unit_of_measurement === 'CURRENCY' ?
                        <GetCountryCurrencyFormat type="currency" value={tableData?.actual} />
                        : reward?.unit_of_measurement === 'NUMBER' ?
                            <GetCountryCurrencyFormat type="number" value={tableData?.actual} />
                            : getNumberFormat(userDetails?.countryCode, tableData?.actual)

            },
            {
                field: "achieved",
                title: "Ach %",
                hidden: reward.reward_criteria === "ACTUAL" || reward.reward_criteria === "POINTS" || reward.reward_criteria === "RANK",
                render: (tableData) => getNumberFormat(userDetails.countryCode, tableData.achievement || 0) + '%'

            },
            {
                field: "points",
                title: "Points",
                hidden: reward.reward_criteria === "ACHIEVEMENT" || reward.reward_criteria === "ACTUAL",
                render: (tableData) => getNumberFormat(userDetails.countryCode, tableData.points)

            },

        ];


    const StandingsTable = ({ data, columns }) => (
        <div className={classes.table}>
            <table>
                <thead>
                    <tr>

                        {[...columns].map((column, index) => (
                            !column.hidden && <th key={index} align={column.align}>{column.title}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {(data
                    ).map((row, rowIndex) => (
                        <tr key={row.desc} selected={row.selected || false}>
                            {
                                [...columns].map((col, i) => (
                                    !col.hidden && <td style={{ ...col?.cellStyle }} key={i} align={col.align}>{col.render ? col.render({ ...row, tableData: { id: rowIndex } }) : row[col.field]}</td>
                                ))
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >


    )

    return (
        <div className={classes.mainRoot}>
            <div className={classes.rewardDetails}>
                <div style={{ padding: '10px' }}>
                    {Levels.find((el) => el.id === reward?.level)['title']}
                    {reward.expired && <span className={classes.expiredLabel}>Expired</span>}
                </div>
                <div className={classes.rewardInfo}>
                    <div style={{ marginRight: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {reward?.banner ? <img src={reward?.banner} height="130" width="130" alt={"banner_img"} style={{ borderRadius: '100px' }} /> : <img src={gift} alt={"banner_img"} height="130" width="130" />
                        }
                        {/* {reward.title && <p style={{ fontWeight: 600, margin: 'auto', textAlign: 'center' }}>{reward.title}</p>} */}

                    </div>
                    <div style={{ fontSize: '13px' }}>
                        <p style={{ fontWeight: 600 }}>Reward Title: <span style={{ fontWeight: 500, marginLeft: 5 }}>{reward.awardee === 'NA' ? "NA" : decodeEnum(reward.title)}</span></p>

                        <p style={{ fontWeight: 600, wordBreak: 'break-all' }}>Reward Value: <span style={{ fontWeight: 500, marginLeft: 5 }}>{reward.actual_reward}</span></p>
                        <p style={{ fontWeight: 600 }}>Reward Criteria: <span style={{ fontWeight: 500, marginLeft: 5 }}>{reward.awardee === 'NA' ? "NA" : decodeEnum(reward.awardee)}</span></p>
                        <p style={{ fontWeight: 600 }}>Reward for: <span style={{ fontWeight: 500, marginLeft: 5 }}>{decodeEnum(reward.category)}</span></p>
                        {reward.kpi_name && <p style={{ fontWeight: 600 }}>KPI Name: <span style={{ fontWeight: 500, marginLeft: 5 }}>{reward.kpi_name}</span></p>}
                        {reward.division_id === null || reward.division_id && <p style={{ fontWeight: 600 }}>Division: <span style={{ fontWeight: 500, marginLeft: 5 }}>{reward.division_name ? reward.division_name : 'All Divisions'}</span></p>}
                        {(reward.role_id && reward.role_id.length > 0) && <p style={{ fontWeight: 600 }}>Roles: <span style={{ fontWeight: 500, marginLeft: 5 }}>{rolesList.filter(ele => reward?.role_id.map(role => role).includes(ele.role_id)).map((ele, i) => `${ele.name} ${i === reward.role_id.length - 1 ? '' : ','}`)}</span></p>}
                        {(reward.region_id && reward.region_id.length > 0) && <p style={{ fontWeight: 600 }}>Regions: <span style={{ fontWeight: 500, marginLeft: 5 }}>{regionsList.filter(ele => reward?.region_id.map(role => role).includes(ele._id)).map((ele, i) => `${ele.name} ${i === reward.region_id.length - 1 ? '' : ','}`)}</span></p>}



                        {reward.level && reward.level === 'SPRINT' ? <p style={{ fontWeight: 600 }}>Sprint: <span style={{ fontWeight: 500, marginLeft: 5 }}>{reward.sprint_title}</span></p> : reward.level === 'SEASON' ? <p style={{ fontWeight: 600 }}>Season: <span style={{ fontWeight: 500, marginLeft: 5 }}>{reward.season_name}</span></p> : reward.level === 'ONSPOT' ? <p style={{ fontWeight: 600 }}>Onspot: <span style={{ fontWeight: 500, marginLeft: 5 }}>Onspot</span></p> : reward.level === 'ROUND' ? <p style={{ fontWeight: 600 }}>Round: <span style={{ fontWeight: 500, marginLeft: 5 }}>{reward.sprint_title}</span></p> : null}

                        <p style={{ fontWeight: 600 }}>Reward Duration: <span style={{ fontWeight: 500, marginLeft: 5, fontSize: 12 }}>
                            {reward?.winning_criteria?.start_date ? formatDate(reward?.winning_criteria?.start_date) : formatDate(reward.start_date)} - {reward?.winning_criteria?.end_date ? formatDate(reward?.winning_criteria?.end_date) : formatDate(reward.end_date)}
                        </span></p>



                        {reward.description && <p style={{
                            fontWeight: 600, wordBreak: 'break-all',
                        }}>Reward Description: <span style={{ fontWeight: 500, marginLeft: 5 }}>{getEllipsisText(reward?.description, 100)}</span></p>}
                    </div>
                </div>
                <div style={{ borderTop: '1px solid rgba(0, 0, 0, 0.16)' }}>
                    {currentRewardStandings.length > 0 ?
                        <div className={classes.standings}>
                            <p style={{
                                color: '#363636', fontSize: "16px", fontWeight: 600,
                            }}>{reward.category === 'PLAYER' ? 'Player Standings' : 'Team Standings'}</p>
                            <div>

                            </div>
                            <StandingsTable
                                columns={columns(currentRewardStandings)}
                                data={currentRewardStandings.slice(0, 10)}
                            />
                            {(currentRewardStandings.length > 10 && reward.level !== 'ONSPOT') && <span className={classes.showMoreButton} onClick={() => handleViewAllStandings(reward)}>Show More<NavigateNextIcon fontSize="small" /></span>}


                        </div> :
                        <div style={{ textAlign: 'center', margin: '10px 10px 10px 10px' }}>
                            <img src={gameIllustration} />
                            <p style={{ fontSize: 14, color: '#0000008A' }}>We do not have any Standings to show here,Please Upload the Data and Check back</p>
                        </div>
                    }
                </div>


            </div>

        </div >
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        getRewardStandings: (options) => dispatch(getRewardStandings(options)),
        getRegionsList: (options) => dispatch(getRegionsList(options)),
        getSeasonPlayersRoles: (options) => dispatch(getSeasonPlayersRoles(options)),




    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RewardsDetails));
