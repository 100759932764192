import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardContent, CardHeader, Grid, makeStyles } from "@material-ui/core";
import { setLoading } from "../../ducks/loading";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import IllustrationView from "../LiveScores/IllustrationView";
import BuzzNotificationCard from "./BuzzNotificationCard";
import { getNudgesList } from "../../ducks/nudge";
import { Skeleton } from "@material-ui/lab";
import { buzzTimeStamp, buzzTimeStampUTC } from "../../helpers";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '15px 25px 3px 25px',
        width: 'auto',
    },
    skeletonRoot: {
        padding: '10px 0px',
        height: 90,
        marginBottom: 150,
        "& .card": {
            bordeRadius: 8,
            padding: '10px 8px',
            borderRadius: 4,
            '& .cardd': {
                display: 'flex',
                justifyContent: 'space-between',
                [theme.breakpoints.down('md')]: {
                    display: 'block',
                },
                [theme.breakpoints.down('xs')]: {
                    display: 'block',
                },
            }
        },
    },
}));

const pagination = {
    limit: 10,
    page: 0,
}

const BuzzNotificationList = ({ filtersData, history, userInfo, location, isAdmin, getNudgesList }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [buzzData, setBuzzData] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        getNudgesList({
            params: { limit: pagination.limit, page: 0 },
            onSuccessCB: onBuzzSuccessCB,
            onErrorCB: onBuzzErrorCB
        });
    }, []);

    const getBuzzDataMore = () => {
        setLoading(true);
        getNudgesList({
            params: { limit: pagination.limit, page: pagination.page + 1, },
            onSuccessCB: onBuzzSuccessCB,
            onErrorCB: onBuzzErrorCB
        })
    }

    const onBuzzSuccessCB = (data, headers) => {
        if (data) {
            data = data.map((ele) => {
                ele['created_time'] = buzzTimeStamp(ele.created_at);
                ele['update_time'] = ele.data_updated_at ? `Data Updated as on ${buzzTimeStampUTC(ele.data_updated_at)}` : '';
                return ele;
            })
            setBuzzData(prevState => ([...prevState, ...data]));
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }
    const onBuzzErrorCB = () => {
        setLoading(false);
    }

    const skeletonLoading = (
        <Grid container spacing={2} className={classes.skeletonRoot}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((ld, id) => (
                <Grid key={id} item xs={12}>
                    <Card className="card">
                        <CardContent className="cardd" style={{ padding: 0 }}>
                            <div className="" style={{ display: 'block', width: '80%' }}>
                                <Skeleton height={40} width="80%" />
                                <Skeleton height={20} width="20%" />
                            </div>
                            <div style={{ display: 'inline-flex', width: '20%' }}>
                                <Skeleton height={40} width="15%" style={{ marginRight: 10 }} />
                                <Skeleton height={40} width="15%" style={{ marginRight: 10 }} />
                                <Skeleton height={40} width="50%" />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );

    return (
        <div className={classes.root}>
            <div className="details">
                {loading && skeletonLoading}
                {buzzData.length > 0 ?
                    <InfiniteScroll
                        dataLength={buzzData.length}
                        next={getBuzzDataMore}
                        hasMore={buzzData.length !== pagination.total_count}
                        scrollableTarget="buzz-data"
                        style={{ overflow: 'initial !important' }}
                    >
                        {
                            buzzData.length > 0 && buzzData.map((ele, index) => (
                                <BuzzNotificationCard key={ele._id} index={index} buzz={ele} buzzData={buzzData} setBuzzData={setBuzzData} />
                            ))
                        }
                    </InfiniteScroll>
                    :
                    < IllustrationView loading={loading} style={{ marginTop: '50px' }} />
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getNudgesList: (options) => dispatch(getNudgesList(options)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuzzNotificationList));