import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Favorites from './Favorites';
import { Grid } from '@material-ui/core';
import ArenaList from './ArenaList';
import './Arena.css';
import ReactionsDialog from './ReactionsDialog';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import ViewProfileDetails from '../Profile/ViewProfileDetails';
import ViewProfilePosts from '../Profile/ViewProfilePosts';
import ArenaSearch from '../ArenaSearch'
import Notifications from '../notificationsArena/Notifications';
import ShareToArenaDialog from './ShareToArenaDialog';
import ShareListDialog from './ShareListDialog';
import EditArenaPost from './EditArenaPost';
import firebase from "firebase";
import { ArenaIndividualPostView } from './ArenaIndividualPostView';
import ShareToChatDialog from './ShareToChatDialog';
// import ViewProfileMedia from '../Profile/ViewProfileMedia';
import ViewProfileMedia from '../UserMedia/ViewProfileMedia'
import ArenaLeftSection from './ArenaLeftSection';
import CreatePost from '../ArenaComponents/CreatePost';
import TaggedUsersDialog from '../ArenaComponents/TaggedUsersDialog';
import LikedUsersDialog from '../ArenaComponents/LikedUsersDialog';
import ArenaRightSection from './ArenaRightSection';
import { connect } from 'react-redux';
import { orgRealm } from '../..';

export const getProfileUrl = (username) => `/profile/${username}`;
export const getTeamProfileUrl = (username, seasonId) => `/teamProfile/${username}/seasons/${seasonId}`

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F0F2F5',
        width: '100%',
        maxHeight: 'calc(100vh - 116px)',
        // maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        padding: '56px 24px 0px',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            // maxHeight: 'calc(100vh - 80px)',
            // maxWidth: `calc(100vw - 150px)`,
            padding: '56px 0px 0px',
        },
    },
    gridContainer: {
        // width: '80%',
        // width: '90%',
        display: 'flex',
        width: '100%',
        margin: '0 auto',
        maxWidth: 1250,
        [theme.breakpoints.down('md')]: {
            // width: '85%',
            // width: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            // width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            // width: '100%',
        },
    },
    favoritesRoot: {
        flexGrow: 0,
        maxWidth: '27.5%',
        flexBasis: '27.5%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    searchGrid: {
        background: "#ffffff",
        borderRadius: 6,
        marginBottom: 20
    },
    rightSectionRoot: {
        flexGrow: 0,
        maxWidth: '27.5%',
        flexBasis: '27.5%',
        // display: 'flex',
        // justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    listRoot: {
        // maxWidth: 600
        padding: '0px 40px',
        flexGrow: 0,
        maxWidth: '45%',
        flexBasis: '45%',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            maxWidth: '100%',
            flexBasis: '100%',
        },
    }
}))

export const SampleProfileSection = () => (
    <p>
        Profile section
    </p>
)

export const SampleProfileSectionWithId = () => (
    <p>
        Profile section with id
    </p>
)

const ArenaLayout = ({ user }) => {
    const classes = useStyles();
    const [newPost, setNewPost] = useState(null);
    const { path, url, params } = useRouteMatch();

    useEffect(() => {
        if (firebase.messaging.isSupported()) {
            const analytics = firebase.analytics();
            analytics.logEvent('page_view', {
                page_location: window.location.href,
                page_path: window.location.pathname,
                page_title: 'Arena',
                org_id: orgRealm,
                username: user.username
            });
        }
    }, []);

    const handleOnNewPost = (id) => {
        setNewPost(id);
    }

    return (
        <div id="arena-list-root" className={classes.root}>
            <div className={classes.gridContainer}>
                <Switch>
                    {/* <Route path={`${path}/profile/:id`} >
                        <Grid item md={3} className={classes.favoritesRoot}>
                            <ArenaLeftSection />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Switch>
                                <Route path={`${path}/profile/:id/media`} exact >
                                    <ViewProfileMedia />
                                </Route>
                                <Route path={`${path}/profile/:id`} exact >
                                    <ViewProfileDetails />
                                    <ViewProfilePosts />
                                </Route>
                                <Redirect to={`${path}/profile/:id`} />
                            </Switch>
                        </Grid>
                        <Grid item md={3} className={classes.rightSectionRoot} >
                            <ArenaRightSection />
                        </Grid>
                    </Route> */}
                    {/* <Route path={`${path}/search`} exact>
                        <Grid item md={3} className={classes.favoritesRoot}>
                            <ArenaLeftSection />
                        </Grid>
                        <Grid className={classes.searchGrid} item md={6} xs={12} >
                            <ArenaSearch newPost={newPost} handleOnNewPost={handleOnNewPost} />
                        </Grid>
                        <Grid item md={3} className={classes.rightSectionRoot} >
                            <ArenaRightSection />
                        </Grid>
                    </Route> */}
                    {/* <Route path={`${path}/notifications`} exact >
                        <Grid item md={3} className={classes.favoritesRoot}>
                            <ArenaLeftSection />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Notifications />
                        </Grid>
                        <Grid item md={3} className={classes.rightSectionRoot} >
                            <ArenaRightSection />
                        </Grid>
                    </Route> */}
                    {/* <Route path={`${path}/view/:postId`} exact >
                        <Grid item md={3} className={classes.favoritesRoot}>
                            <ArenaLeftSection />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ArenaIndividualPostView newPost={newPost} handleOnNewPost={handleOnNewPost} />
                        </Grid>
                        <Grid item md={3} className={classes.rightSectionRoot} >
                            <ArenaRightSection />
                        </Grid>
                    </Route> */}
                    <Route path={path} >
                        <div className={classes.favoritesRoot}>
                            <ArenaLeftSection />
                        </div>
                        <div className={classes.listRoot}>
                            <Switch>
                                <Route path={`${path}/profile/:id`} >
                                    <ViewProfileDetails />
                                    <ViewProfilePosts />
                                </Route>
                                <Route path={`${path}/search`} exact>
                                    <ArenaSearch newPost={newPost} handleOnNewPost={handleOnNewPost} />
                                </Route>
                                <Route path={`${path}/notifications`} exact >
                                    <Notifications />
                                </Route>
                                <Route path={`${path}/view/:postId`} exact >
                                    <ArenaIndividualPostView newPost={newPost} handleOnNewPost={handleOnNewPost} />
                                </Route>
                                <Route path={path} exact >
                                    <ArenaList newPost={newPost} handleOnNewPost={handleOnNewPost} />
                                </Route>
                            </Switch>
                        </div>
                        <div className={classes.rightSectionRoot} >
                            <ArenaRightSection />
                        </div>
                    </Route>
                    <Redirect to={'/'} />
                </Switch>
            </div>
            <EditArenaPost handleOnNewPost={handleOnNewPost} />
            <CreatePost handleOnNewPost={handleOnNewPost} />
            {/* <ReactionsDialog /> */}
            <LikedUsersDialog />
            <ShareToArenaDialog handleOnNewPost={handleOnNewPost} />
            <ShareToChatDialog handleOnNewPost={handleOnNewPost} />
            <ShareListDialog />
            <TaggedUsersDialog />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, null)(ArenaLayout);
