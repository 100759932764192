import { all, select, call, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { AUTHENTICATED } from "../../ducks/client";
import { receivedContacts, GET_CONTACTS, } from "../../ducks/contacts";
import { fetchEmployees } from "../../config";



// const fetchTeamsAPI = () => {
//     return Axios.get(fetchTeams, {
//         params: {
//             limit: 100,
//             page: 0
//         }
//     })
// }

const fetchContactsAPI = (payload, token) => {
    return Axios.get(fetchEmployees, {
        params: {
            limit: 1000,
            page: 0
        },
        headers: {
            // Authorization: 'Bearer ' + token
        }
    });
}

function* watchForContacts(client) {
    // const { payload, } = yield take(AUTHENTICATED);
    // const token = yield select((state) => {
    //     return state.keycloak.token
    // });
    yield takeEvery(GET_CONTACTS, function* getContacts(action) {
        try {
            let { data } = yield call(fetchContactsAPI);
            // const [employees, teams] = yield all([call(fetchContactsAPI, 'userName'), call(fetchTeamsAPI)]);
            const presenceList = yield select((state) => {
                return state.presence;
            });
            if (data) {
                let employeesResult = data.reduce((obj, item) => {
                    let jid = `${item.username}@${process.env.REACT_APP_XMPP_DOMAIN}`
                    obj[jid] = {
                        ...item,
                        chat_id: jid,
                        show: presenceList[jid] ? presenceList[jid].show : 'unavailable',
                        isCurrent: false
                    };
                    return obj;
                }, {});
                // let teamsResult = teams.data.reduce((obj, item) => {
                //     let jid = `${item.team_id}@chaseservice.${process.env.REACT_APP_XMPP_DOMAIN}`
                //     obj[jid] = {
                //         ...item,
                //         type: 'group',
                //         show: 'unavailable',
                //         chat_id: jid,
                //         isCurrent: false
                //     };
                //     return obj;
                // }, {});
                // yield put(receivedTeams(teamsResult));
                if (action.payload && action.payload.fromArena) {
                    yield call(action.payload.onGetContactsSuccessCB, data);
                }
                else { yield put(receivedContacts(employeesResult)); }

                console.log('fetchContacts & Teams success ');
            }
        } catch (e) {
            if (action.payload && action.payload.fromArena) {
                yield call(action.payload.onGetContactsErrorCB, e);
            }
            console.log('fetchContacts catch err ', e);
        }
    });
}

export default function* (client) {
    yield all([watchForContacts(client)]);
}
