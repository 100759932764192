import React, { useEffect } from 'react';
import { Table, Column, AutoSizer, InfiniteLoader, List } from 'react-virtualized'

const generateRandomItem = (idx) => ({
    id: idx,
    name: 'Harish ===' + idx,
    email: 'Harish@gmail ===' + idx,
})

const listHeight = 520;
const rowHeight = 50;
const rowWidth = 800;

class MessageWindow extends React.Component {
    constructor() {
        super()
        this.loadMore = this.loadMore.bind(this)
        // fake data
        let items = []
        for (let i = 0, l = 100; i < l; i++) {
            items.push(generateRandomItem(i))
        }
        this.state = {
            items: items
        }
    }

    loadMore({ startIndex, stopIndex }) {
        console.log(startIndex, stopIndex);
        // simulate a request
        setTimeout(() => { this.actuallyLoadMore() }, 500)
        // we need to return a promise
        return new Promise((resolve, reject) => {
            this.promiseResolve = resolve;
        })
    }

    actuallyLoadMore() {
        // fake new data
        let newItems = []
        let s = this.state.items.length + 1
        for (let i = 0, l = 100; i < l; i++) {
            newItems.push(generateRandomItem(s + i))
        }
        this.setState({ items: this.state.items.concat(newItems) })
        // resolve the promise after data where fetched
        this.promiseResolve();
    }

    renderRow({ index, key, style }) {
        return (
            <div key={key} style={style} className="row">
                <div className="image">
                    <img src={'this.state.items[index].image'} alt="" />
                </div>
                <div className="content">
                    <div>{'this.state.items[index].name' + index}</div>
                    <div>{'this.state.items[index].text'}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                {/* <div className="container"> */}
                <InfiniteLoader
                    isRowLoaded={({ index }) => !!this.state.items[index]}

                    threshold={1}
                    loadMoreRows={this.loadMore}
                    rowCount={1000}
                >
                    {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                            {({ width }) => (

                                <div className="list">
                                    <List
                                        width={width}
                                        height={listHeight}
                                        rowHeight={rowHeight}
                                        rowRenderer={this.renderRow}
                                        rowCount={this.state.items.length}
                                        ref={registerChild}
                                        onRowsRendered={onRowsRendered}
                                        scrollToAlignment="end"
                                        scrollToIndex={this.state.items.length - 1}

                                    />
                                </div>

                                // <Table
                                //     ref={registerChild}
                                //     onRowsRendered={onRowsRendered}
                                //     rowClassName='table-row'
                                //     headerHeight={40}
                                //     width={width}
                                //     height={300}
                                //     rowHeight={40}
                                //     scrollToIndex={this.state.items.length - 1}
                                //     scrollToAlignment="end"
                                //     rowCount={this.state.items.length}
                                //     rowGetter={({ index }) => this.state.items[index]}
                                // >
                                //     <Column
                                //         label='Id'
                                //         dataKey='id'
                                //         width={width * 0.2}
                                //     />
                                //     <Column
                                //         label='Name'
                                //         dataKey='name'
                                //         width={width * 0.4}
                                //     />
                                //     <Column
                                //         label='E.mail'
                                //         dataKey='email'
                                //         width={width * 0.4}
                                //     />
                                // </Table>
                            )}
                        </AutoSizer>
                    )}
                </InfiniteLoader>
            </>
        )
    }
}

export default MessageWindow;

// ReactDOM.render(
//     <App />,
//     document.getElementById('app')
// )