import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/chats");

export const SET_VIDEO = constant("SET_VIDEO");

export const handleVideoPlayer = (payload) => ({
    type: SET_VIDEO,
    payload
});

const initialState = {
    open: false,
    video: ""
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_VIDEO: {
            console.log('SET_VIDEO ', action)
            return {
                ...action.payload,
            }
        }

        default:
            return state;
    }
};
