import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import InlineInput from './InlineInput';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Title, LaneHeader, RightContent } from './Styles/Base';
import { ReactComponent as DeleteStage } from "../../../assets/icons/stage_delete_icon.svg";
import { Tooltip } from '@material-ui/core'
import { deleteStage } from '../../../ducks/pms';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useSnackbar } from "notistack";


// import LaneMenu from './LaneHeader/LaneMenu'

const useStyles = makeStyles((theme) => ({


}));

const LaneHeaderComponent = (props) => {
  const [editLane, setLaneEdit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { updateTitle, canAddLanes, onDelete, showDeleteIcon, onDoubleClick, id, editLaneTitle, project_id, label, title, titleStyle, labelStyle, t, laneDraggable, stageKey } = props

  const handelLaneTitleEdit = () => {
    if (editLaneTitle) setLaneEdit(!editLane);
  }

  const handleDelete = (e, stageId, project_id, key) => {
    if (key.split('-')[key.split('-').length - 1] === 'TODO') {
      enqueueSnackbar(`First lane can only be renamed`, {
        variant: "error",
        preventDuplicate: true
      });
      e.stopPropagation();
    }
    else if (key.split('-')[key.split('-').length - 1] === 'DONE') {
      enqueueSnackbar(`Last lane can only be renamed`, {
        variant: "error",
        preventDuplicate: true
      });
      e.stopPropagation();
    }
    else {
      props.deleteStage({ id: project_id, reqObject: { stageId: stageId }, onSuccessCB, onErrorCB })
      e.stopPropagation();
    }
  }
  const onSuccessCB = (data) => {
    onDelete()
    enqueueSnackbar(`Stage deleted successfully`, {
      variant: "success",
      preventDuplicate: true
    });
  }
  const onErrorCB = (error) => {
    enqueueSnackbar(error.response.data.error ? error.response.data.error : `Something went wrong`, {
      variant: "error",
      preventDuplicate: true
    });
  }
  return (
    <LaneHeader onDoubleClick={onDoubleClick} editLaneTitle={editLaneTitle} >
      {/* <Tooltip title={title}> */}


      {/* </Tooltip> */}
      {editLane ?
        <InlineInput
          value={title}
          border placeholder={t('placeholder.title')}
          resize='vertical'
          onSave={updateTitle}
          editLaneTitle={editLane}
          project_id={project_id}
          id={id}
          handelLaneTitleEdit={handelLaneTitleEdit} /> :
        <Title draggable={laneDraggable} style={titleStyle} onClick={handelLaneTitleEdit}>
          {title} <span style={{ marginLeft: 5, fontSize: 9, fontWeight: 700, color: "#535353", marginBottom: 1 }}>{`(${label})`}</span>
          {label && (
            <RightContent>
              {showDeleteIcon ? <><Tooltip title={`Delete`}><DeleteStage onClick={(e) => handleDelete(e, id, project_id, stageKey)} style={{ height: 16, margin: "0px -10px -3px 0px", cursor: 'pointer' }} /></Tooltip></> : null}

              {/* <span style={labelStyle}></span> */}
            </RightContent>
          )}
        </Title>

      }


    </LaneHeader>
  )
}

LaneHeaderComponent.propTypes = {
  updateTitle: PropTypes.func,
  editLaneTitle: PropTypes.bool,
  canAddLanes: PropTypes.bool,
  laneDraggable: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  onDelete: PropTypes.func,
  id: PropTypes.string,
  onDoubleClick: PropTypes.func,
  titleStyle: PropTypes.object,
  t: PropTypes.func.isRequired,
  project_id: PropTypes.string,
  showDeleteIcon: PropTypes.bool,
  stageKey: PropTypes.string
}

LaneHeaderComponent.defaultProps = {
  updateTitle: () => { },
  editLaneTitle: false,
  canAddLanes: false
}
const mapDispatchToProps = (dispatch) => {
  return {
    deleteStage: (options) => dispatch(deleteStage(options)),
  }
};


export default withRouter(connect(
  null,
  mapDispatchToProps
)(LaneHeaderComponent));