import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { handleVideoPlayer } from '../../ducks/videoPlayer';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 'auto',
        backgroundColor: 'black'
    },
}))

const VideoPlayer = ({ videoPlayerState, handleVideoPlayer }) => {
    const classes = useStyles();

    const handleClose = () => {
        handleVideoPlayer({
            open: false
        })
    }

    return (
        <>
            <Dialog
                open={videoPlayerState.open}
                fullWidth={true}
                maxWidth={'xs'}
                scroll="paper"
                // TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                classes={{ paper: classes.dialogPaper }}
            >
                <ReactPlayer
                    url={videoPlayerState.video}
                    controls={true}
                    playing={true}
                />
                {/* <ReactPlayer
                    style={{ maxWidth: '100%' }}
                    url={item.attachmentUrl || item.url}
                    controls={true}
                    playing={false}
                    light={item.thumbnailUrl}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload nofullscreen noremoteplayback',
                                disablePictureInPicture: true,
                                onContextMenu: e => e.preventDefault()
                            }
                        }
                    }}
                /> */}
            </Dialog>
        </>
    );
}

const mapStateToProps = (state) => ({
    videoPlayerState: state.videoPlayer
});

const mapDispatchToProps = (dispatch) => {
    return {
        handleVideoPlayer: (options) => dispatch(handleVideoPlayer(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
