import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { setLoading } from "../../../ducks/loading";
import { getDivisionsList, shareLiveScoreDialog } from "../../../ducks/game";
import IllustrationView from "../../LiveScores/IllustrationView";
import InfiniteScroll from "react-infinite-scroll-component";
import TopFiveDivisionTeamSummary from "./TopFiveDivisionTeamSummary";


const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        // marginTop: 35,
    }
}));


const pagination = {
    limit: 5,
    page: 0,
}

const TeamDivisionSummaryLists = ({ history, loading, divisions, filtersData, setLoading, getDivisionsList, isAdmin }) => {
    const classes = useStyles();
    const [divs, setDivs] = useState([]);


    useEffect(() => {
        if (filtersData?.season?._id) {
            setDivs([]);
            setLoading(true);
            getDivisionsList({
                seasonId: filtersData?.season?._id,
                params: { limit: pagination.limit, page: 1 },
                onSuccessCB: onRoleSuccessCB,
                onErrorCB: onRoleErrorCB
            })
        }
        else setDivs([]);
    }, [filtersData.season?._id])

    const getRole_List = () => {
        let seasonId = filtersData?.season?._id
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: pagination.page + 1, },
            onSuccessCB: onRoleSuccessCB,
            onErrorCB: onRoleErrorCB
        })
    }

    const onRoleSuccessCB = (result, headers) => {
        if (result) {
            setDivs(prevState => ([...prevState, ...result]));
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }
    const onRoleErrorCB = () => { }




    return (
        <div className={classes.root}>
            <div>
                <h5 style={{ margin: '15px 0 0 0', fontSize: 16, color: '#363636' }}>Divisions</h5>
            </div>
            {divisions.length > 0 ?
                <InfiniteScroll
                    dataLength={divs.length}
                    next={getRole_List}
                    hasMore={divs.length !== pagination.total_count}
                    scrollableTarget="livescoresRoot"
                >
                    {
                        divs.map((ele, index) => (
                            <TopFiveDivisionTeamSummary key={ele._id} index={index} role={ele} filtersData={filtersData} isAdmin={isAdmin} />
                        ))
                    }
                </InfiniteScroll>
                :
                < IllustrationView loading={loading} style={{ marginTop: '50px' }} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamDivisionSummaryLists));