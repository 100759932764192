import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Media from '../UserMedia/Media'
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: "1rem",
  },
  root: {
    background: "#ffffff",
  },
  box: {
    margin: "15px 25px 0",
    borderRadius: 6,
    backgroundColor: "#f7f7f7",
    color: "#535353",
    fontWeight: 600,
    fontSize: 14,
  },
  text: {
    padding: 14,
  },
  break: {
    height: "10px",
    backgroundColor: "#f5f5f5",
    // width: "600px",
    marginBottom: "1rem",
  },
}));

const All = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Media />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  search: state.search.arena,
});
export default withRouter(connect(mapStateToProps, null)(All));
