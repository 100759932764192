import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, TextareaAutosize, Button, DialogActions, TextField,Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Moment from "react-moment";
import { connect } from "react-redux";
import { getWorkLogsList, createWorkLog, openWorkLogDialog,deleteWorkLog } from "../../ducks/pms";
import TaskDetail from "./TaskDetail";
import { blue } from "@material-ui/core/colors";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { getAvatarText, postCreatedAtFormat } from "../../helpers";

const useStyles = makeStyles((theme) => ({
    title: {
        margin: 0,
        fontSize: 17,
        marginBottom: '0',
        textBold: '1%',
        fontWeight: '550',
        fontStyle: 'inherit',
        color: '#415877;',
        marginTop: "2%"
    },
    avatarImageAlign: {
        // display: 'flex',
        marginTop: '3%'
    },
    workLogInput: {
        marginTop: '2%',
        width: "80%",
        // marginLeft: theme.spacing(1),
        fontSize: 14,
        "&:focus": {
            outline: "none"
        },
        border: 'solid 1px #b4b4b4',
        borderRadius: "10px",
        background: "#EEEEEE",
        wordBreak: "break-word",
        color: "#535353",
        fontSize: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "11px",
        paddingRight: "25px",
        whiteSpace: 'pre-line'
    },
    input: {
        width: "80%",
        fontSize: 14,
        "&:focus": {
            outline: "none"
        },
        border: 'solid 1px #b4b4b4',
        borderRadius: "10px",
        background: "#EEEEEE",
        wordBreak: "break-word",
        color: "#535353",
        fontSize: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "11px",
        paddingRight: "25px",
        whiteSpace: 'pre-line'
    },
    saveEditButton: {
        margin: '2%',
        marginLeft: "8%",
        background: 'rgb(0, 82, 204)',
        padding: 0,
        color: "#F5F5F5"
    },
    button: {
        margin: '0 7px',
        width: 120,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    CommenterName: {
        color: '#42526e',
        fontWeight: 500,
        margin: 0,
        marginLeft: 11,
        marginTop: 8
    },
    commentStyle: {
        margin: 0,
        marginLeft: '8%'
    },
    // edit stylee
    textButton: {
        marginTop: '2%',
        background: 'none',
        border: 'none',
        margin: 0,
        padding: 0,
        cursor: 'pointer'
    },
    loggerName: {
            color: '#58595b',
            fontWeight: 600,
            fontSize: 15,
            margin: '0px 0px 0px 40px',
            display:'flex',
            // alignItems:'center'
    },
    textfieldwidth: {
        width: "200px",
        marginTop: "20px"
    },
    textColor:{
            fontSize: '14px',
            color: '#172b4db3'
    },
    linkStyle:{
        color:theme.palette.primary.main,
        fontSize: "15px",
        textAlign: "center",
        textDecoration: "none" 
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer'
      },
      actionIcons: {
        marginLeft: 5,
        height: 15,
        width: 15,
        cursor: "pointer"
    },
    
    timeStyles: {
        fontWeight: 500,
        fontSize: 12,
        marginLeft: 10
    },
    loggedTimeStyles:{
            color: " #535353",
            fontSize: 15,
            margin: "5px 0px 0px 43px",
            wordWrap: 'break-word',
            maxWidth: 580,
            whiteSpace: 'pre-line'
    }

}))

const WorkLogs = (props) => {
    const classes = useStyles();
    console.log(props)
    const [initialWorkLogList, setInitialWorkLogList] = useState([]);
    const { enqueueSnackbar } = useSnackbar()
    const [showEditWorkLog, setShowEditWorkLog] = useState({ editButton: false, index: '' });

    useEffect(() => {
        let limit = 50;
        let page = 1;
        setInitialWorkLogList([])
        props.getWorkLogsList({
            params: {task_id:props.task_id,page: page, limit: limit },
            onSuccessCB,
            onErrorCB
        });
    }, [ props.workLog || props.task_id])

    const onSuccessCB = (result) => {
        setInitialWorkLogList([...result]);
    };
    //Error Callback
    const onErrorCB = error => {
        console.log(error);
        enqueueSnackbar(`Failed to fetch worklog list data.`, {
            variant: "error",
            preventDuplicate: true
        });
        // setLoading(false);
    };
    const openCreateWorkLog = () => {
     props.openWorkLogDialog({open:true,details:{task_id:props.task_id}})
        // openCreatePop(true)
    }
    const handleShowEditWorklog = (worklog) => {
        props.openWorkLogDialog({open:true,details:{...worklog,task_id:props.task_id},isEdit:true})
    }
    const handleDeleteWorkLog=(worklog,index)=>{
        let requestObj = {task_id:props.task_id}
        props.deleteWorkLog({requestObj,id:worklog._id,onSuccessCB:deleteSuccessCB,onErrorCB:deleteErrorCB});
        setInitialWorkLogList(prevState=>([...prevState.filter((ele, i) => i !== index)]))
    }
    const deleteSuccessCB = (res)=>{
        enqueueSnackbar(`Work log deleted.`, {
            variant: "success",
            preventDuplicate: true
        });
    }
    const deleteErrorCB = (error)=>{
        console.log('error');
    }
    return (
        <div>
            <div>
                    {
                    <p className={classes.linkStyle}>
                        <span style={{ cursor: "pointer" }} onClick={() => { openCreateWorkLog() }}>Log Time</span>
                    </p> 
                    }
                {
                    initialWorkLogList && initialWorkLogList.length > 0 ?
                        initialWorkLogList.map((worklog, index) => (
                            <div key={index}>
                                <div>
                                    <div className={classes.avatarImageAlign}>
                                        <Avatar style={{float:"left" ,height:30,width:30}}
                                        alt={worklog.name}
                                        src={worklog.photo_url}
                                        classes={{ colorDefault: classes.avatarStyles }}
                                        //   onClick={() => history.push(`/wall/profile/${user.username}`)}
                                        >
                                        {(worklog.name && getAvatarText(worklog.name)) || (worklog.logged_by && getAvatarText(worklog.logged_by))}
                                        </Avatar>
                                        <p className={classes.loggerName} >
                                            {`${worklog.name} `}
                                            {worklog.logged_by===props.user ?
                                        <>
                                            <span><Tooltip title="Edit"><EditOutlinedIcon className={classes.actionIcons} onClick={() => { handleShowEditWorklog(worklog) }} /></Tooltip></span>
                                            <span><Tooltip title="Delete"><DeleteOutlineIcon className={classes.actionIcons} onClick={() => { handleDeleteWorkLog(worklog,index) }} /></Tooltip></span>
                                        </>
                                        : null
                                    }
                                             <Moment className={classes.timeStyles} fromNow ago format={postCreatedAtFormat(worklog.logged_at)}>{worklog.logged_at}</Moment> </p>                                                                           
                                           <p className={classes.loggedTimeStyles}> <span>{ `logged ${worklog.logged_time}  ${worklog.logged_time <= 1 ? 'hr' :'hrs'}`}</span></p>
                                           <p className={classes.loggedTimeStyles}>
                                             {worklog.comment ? <span >{`Comment: ${worklog.comment}`}</span>:''}
                                            {showEditWorkLog.index == `${index}`}</p>
                                        
                                    </div>
                                    
                                </div>
                                {/* } */}
                            </div>
                        )) : null
                        }
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    user: state.user.username,
    name:state.user.name,
    photo_url:state.user.photoUrl
})

const mapDispatchToProps = (dispatch, props) => {
    return {
        getWorkLogsList: (options) => dispatch(getWorkLogsList(options)),
        createWorkLog: (options) => dispatch(createWorkLog(options)),
        openWorkLogDialog:(options) => dispatch(openWorkLogDialog(options)),
        deleteWorkLog:(options)=>dispatch(deleteWorkLog(options))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkLogs);
