import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import Moment from "react-moment";
const useStyles = makeStyles((theme) => ({
    avatarImageAlign: {
        display: 'flex',
        marginTop: '3%'
    },
    userName: {
        color: '#42526e',
        fontWeight: 500,
        margin: 0,
        marginLeft: 11,
        marginTop: 8
    },
}))

const TaskHistory = (props) => {
    const [historyList, setHistoryList] = useState([{ name: "jagadesh" }, { name: "jagadesh" }]);
    const classes = useStyles();
    return (
        <div style={{ marginTop: '2%' }}>
            {historyList.length > 0 ?
                historyList.map((history, index) => (
                    <div key={index}>
                        <div className={classes.avatarImageAlign}>
                            <Avatar
                                // alt={user.name}
                                // src={user.photoUrl}
                                //   classes={{ colorDefault: classes.avatarStyles }}
                                //   onClick={() => history.push(`/wall/profile/${user.username}`)}
                                style={{ cursor: 'pointer' }}
                            >
                            </Avatar>
                            <p className={classes.userName}>{history.name} <span><Moment>{history.createdAt} </Moment>   </span></p> </div>

                    </div>)) : ""}
        </div>
    )
}
export default TaskHistory