import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { setLoading } from "../../../ducks/loading";
import { getKanbandBoardData } from "../../../ducks/pms";
import { useSnackbar } from "notistack";
const useStyles = makeStyles((theme) => ({
    buttonDiv: {
        borderRadius: '5px',
        height: '35px',
        width: '110px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #5D5FEF',
        // margin: '5px',
        color: '#5D5FEF',
        '&:hover': {
            backgroundColor: '#5D5FEF'
        },
    },
    msgBody: {
        color: '#666666',
        fontSize: '13px',
        fontWeight: 400,
        fontStyle: 'normal',
        fontFamily: 'Poppins !important',
        lineHeight: '20px'
    },
    msgTitle: {
        color: '#000000',
        fontSize: '15px',
        fontWeight: 600,
        fontStyle: 'normal',
        fontFamily: 'Poppins !important',
        lineHeight: '22px'

    },
    messageRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '5px',
        alignItems: 'center'
    },
    pwcButton: {

        whiteSpace: 'nowrap',
        '& span': {
            color: '#5D5FEF',
            fontWeight: 600,
            '&:hover': {
                color: '#ffff',
                fontWeight: 600
            }
        },
    },

}));
const DashboardActions = ({ getKanbandBoardData, orgDetails }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [actions, setActions] = useState([]);





    useEffect(() => {
        getTasksList()

    }, []);

    const getTasksList = () => {
        getKanbandBoardData({
            params: { project_key: 'DC' },
            onSuccessCB,
            onErrorCB
        });
    }

    const onSuccessCB = (result) => {

        // let data = []

        // result.lanes.forEach((lane) => {
        //     if (lane.cards.length > 0) {
        //         lane.cards.forEach((card) => {
        //             data.push(card)
        //         })
        //     }
        // })
        let row = {};

        if (result.lanes.some(ele => ele.cards.length > 0)) {

            let data = []
            result.lanes.forEach(ele => {
                if (ele.cards?.length > 0) {
                    ele.cards.forEach(card => {
                        row = {};
                        row['task_id'] = card.task_id;
                        row["Id"] = Number(card.task_id.split("-")[1]);
                        row['title'] = card.title;
                        row['description'] = card.description;
                        row['task_type'] = card.task_type;
                        row['status'] = ele.title;
                        row['sortableDate'] = new Date(card.tableTargetDate).getTime();
                        row['priority'] = card.priority;
                        row['reporterPhoto_url'] = card.reporterPhoto_url;
                        row['reporterName'] = card.reporterName;
                        row['assigneePhoto_url'] = card.assigneePhoto_url;
                        row['assigneeName'] = card.assigneeName;
                        if (orgDetails?.theme?.view === 'PWC') {
                            row['doctorName'] = card.custom_fields.doctor_name
                            row['speciality'] = card.custom_fields.speciality
                            row['dateOfVisit'] = card.custom_fields.date_of_visit
                            row['comments'] = card.custom_fields.comments
                            row['lastVisit'] = card.custom_fields.last_6_visits
                        }

                        row = { ...row }
                        data.push(row)
                        setActions([...data])


                    })

                }
            })



        }

        // const actionsList = result.lanes[0].cards
        // setActions(actionsList)

    }

    const onErrorCB = (error) => {
        enqueueSnackbar(`Failed to fetch Actions Data.`, {
            variant: "error",
            preventDuplicate: true
        });
        console.log('actionErr', error)

    }



    return (
        <>

            {actions && actions.map((action, index, list) => (
                <>

                    <div className={classes.messageRoot}>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={classes.msgTitle}>{action.title}</span>
                            <span className={classes.msgBody}>{action.description}</span>
                        </div>
                        <div className={classes.buttonDiv}>
                            <Button className={classes.pwcButton} variant="outlined">Take Action</Button>
                        </div>



                    </div >
                    <hr style={{ border: '0.1px solid #EEEDED' }} />
                </>

            ))
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails

});



const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getKanbandBoardData: (options) => dispatch(getKanbandBoardData(options)),




    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardActions));
