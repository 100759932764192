import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { setLoading } from "../../../ducks/loading";
import { getPlayerPerformanceGroups, shareLiveScoreDialog } from "../../../ducks/game";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import qs from 'query-string';
import HealthPerformanceDialog from "./HealthPerformanceDialog";
import MyTeamPlayersPerformanceHealthTable from "./MyTeamPlayersPerformanceHealthTable";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginBottom: 35,
        '& thead': {
            height: 40,
            '& tr': {
                backgroundColor: '#EBEBEB !important',
                '& th': {
                    backgroundColor: '#EBEBEB !important',

                }
            }
        },
        '& tbody': {
            '& tr': {
                height: 40,
                '& td': {
                    fontSize: '14px !important',
                },
                '& td:hover': {
                    background: '#f6f6f6 !important',
                },
                '& td:nth-child(1):hover': {
                    background: 'none !important',
                },
            },
        },
        '& .table-footer': {
            background: 'white',
            fontSize: 12
        }
    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        marginBottom: 10,
        // paddingBottom: 10
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    divisin: {
        padding: 10
    },
    userHierarchieRoles: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    divisionList: {
        width: '100%',
        maxWidth: '45%',
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        height: 40,
        padding: 1,
        alignItems: 'center',
        marginBottom: 8
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        // marginBottom: 5
    },
}));

const MyTeamPlayersPerformanceHealth = ({ history, userHierarchieRoles, matches, filtersData, getDivisionsList, setLoading, shareLiveScoreDialog, isTeamRacePlayerSprint, loading, getPlayerCoreKpisStats, getPlayerPerformanceGroups, userDetails, isAdmin, seasonFormat, type, dialog, limit, uploadLogDate, seasonKpiPostions, seasonDetails }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [dynamicColumnsCore, setDynamicColumnsCore] = useState([]);
    const [dynamicHeadersCore, setDynamicHeadersCore] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [roleObjHealth, setRoleObjHealth] = useState(userHierarchieRoles[0]);
    const qryparams = qs.parse(history.location.search);
    const [openHealthDialog, setOpenHealthDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [selectdRole, setSelectedRole] = useState();
    const [tableDataBonus, setTableDataBonus] = useState([]);


    useEffect(() => {
        if (filtersData?.season?._id) {
            const selectRole = userHierarchieRoles?.find(obj => obj._id === filtersData?.role);
            let role = roleObjHealth ? roleObjHealth?._id : filtersData?.role ? filtersData.role : qryparams?.role;
            let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
            // setRoleObjHealth(selectRole);
            if (role) {
                getPlayerPerformanceGroups({ seasonId, onSuccessCB: onSuccesCore, onErrorCB: onErrorCore, params: { type: 'CORE', role_id: role, limit: 500, page: 1 } });
                getPlayerPerformanceGroups({ seasonId, onSuccessCB: onSuccesBonus, onErrorCB: onErrorBonus, params: { type: 'BONUS', role_id: role, limit: 500, page: 1 } });
            }
        }
    }, [filtersData?.season?._id, filtersData?.role, roleObjHealth?._id]);

    // useEffect(() => {
    //     if (roleObjHealth?._id) {
    //         let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
    //         let role = roleObjHealth?._id
    //         setLoading(true);
    //         getPlayerPerformanceGroups({ seasonId, onSuccessCB: onSuccesCore, onErrorCB: onErrorCore, params: { type: 'CORE', role_id: role, limit: 500, page: 1 } });
    //         getPlayerPerformanceGroups({ seasonId, onSuccessCB: onSuccesBonus, onErrorCB: onErrorBonus, params: { type: 'BONUS', role_id: role, limit: 500, page: 1 } });
    //     }
    // }, [roleObjHealth?._id]);


    const onSuccesCore = (response, resheaders, fromDecimalView) => {
        if (response.length) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, performance: item.kpi_name, colors: item?.colors.map(e => ({ ...e, kpi_id: item.kpi_id, kpi_name: item.kpi_name })) };
                obj.colors.forEach((el, i) => {
                    obj[`${el.color}-count`] = el.count;
                    obj[`${el.color}-color`] = el.status_code;
                    obj[`kpi_id`] = el.kpi_id;
                    obj[`kpi_name`] = el.kpi_name;
                    obj[`kpi_type`] = 'CORE';
                    if (index === 0) {
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        {
                            title: `${el.color}`,
                            field: `${el.color}-count`,
                            editable: 'never',
                            kpi_id: `${el.kpi_id}`,
                            kpi_name: `${el.kpi_name}`,
                            emptyValue: '--',
                            align: 'left',
                            status_code: el.status_code,
                            cellStyle: { textAlign: 'left', color: `${el.status_code}`, whiteSpace: 'nowrap' },
                            render: (rowData) => rowData[`${el.color}-count`]
                        },
                        ]
                    }
                });
                return [...result, { ...obj }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumnsCore(finalResult[0]?.columns || []);
                setDynamicHeadersCore([...headers]);
                setData([...finalResult])
                setTableData([...finalResult]);


            }
            else {
                setDynamicHeadersCore([]);
                setDynamicColumnsCore([]);
                setData([]);
                setTableData([]);

            }
        }
        else {
            setDynamicHeadersCore([]);
            setDynamicColumnsCore([]);
            setData([]);
            setTableData([]);
        }
        setLoading(false);
    };

    const onSuccesBonus = (response, resheaders, fromDecimalView) => {
        if (response.length) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, performance: item.kpi_name, colors: item?.colors.map(e => ({ ...e, kpi_id: item.kpi_id, kpi_name: item.kpi_name })) };
                obj.colors.forEach((el, i) => {
                    obj[`${el.color}-count`] = el.count;
                    obj[`${el.color}-color`] = el.status_code;
                    obj[`kpi_id`] = el.kpi_id;
                    obj[`kpi_name`] = el.kpi_name;
                    obj[`kpi_type`] = 'BONUS';
                    if (index === 0) {
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        {
                            title: `${el.color}`,
                            field: `${el.color}-count`,
                            editable: 'never',
                            kpi_id: `${el.kpi_id}`,
                            kpi_name: `${el.kpi_name}`,
                            emptyValue: '--',
                            align: 'left',
                            status_code: el.status_code,
                            cellStyle: { textAlign: 'left', color: `${el.status_code}`, whiteSpace: 'nowrap' },
                            render: (rowData) => rowData[`${el.color}-count`]
                        },
                        ]
                    }
                });
                return [...result, { ...obj }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setTableDataBonus([...finalResult]);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
                setTableDataBonus([]);

            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
            setTableDataBonus([]);
        }
        setLoading(false);
    };

    const onErrorCore = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
    }
    const onErrorBonus = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
    }

    const onclickDivHealth = (value) => {
        if (roleObjHealth?._id !== value?._id) {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
            setTableData([]);
            setTableDataBonus([])
            setRoleObjHealth(value);
            setSelectedRole(value);
        }
    }

    const onclickRowHealth = (e, col, row) => {
        if (col?.status_code && row[col.field] !== 0) {
            setOpenHealthDialog(true);
            setSelectedRow({ ...col, ...row });
            setSelectedRole(roleObjHealth);
        }
        else {
            setSelectedRow();
            setSelectedRole();
        }
    }

    const handleCloseTemplateDialog = (value) => {
        setOpenHealthDialog(false);
        setSelectedRow();
        setSelectedRole();
    };

    return (
        <div className=''>
            {(userHierarchieRoles.length > 0) &&
                <MyTeamPlayersPerformanceHealthTable
                    // data={data}
                    tableData={tableData}
                    tableDataBonus={tableDataBonus}
                    userHierarchieRoles={userHierarchieRoles}
                    roleObjHealth={roleObjHealth}
                    filtersData={filtersData}
                    isAdmin={isAdmin}
                    handleCloseTemplateDialog={handleCloseTemplateDialog}
                    onclickRowHealth={onclickRowHealth}
                    onclickDivHealth={onclickDivHealth}
                    dynamicColumns={dynamicColumns}
                    dynamicHeaders={dynamicHeaders}
                    dynamicColumnsCore={dynamicColumnsCore}
                    dynamicHeadersCore={dynamicHeadersCore}
                />
            }
            {openHealthDialog && (
                <HealthPerformanceDialog
                    open={openHealthDialog}
                    handleClose={handleCloseTemplateDialog}
                    setLoading={setLoading}
                    selectedRow={selectedRow}
                    roleObjHealth={selectdRole}
                    filtersData={filtersData}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions,
    seasonDetails: state.game.seasonDetails
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getPlayerPerformanceGroups: (options) => dispatch(getPlayerPerformanceGroups(options)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTeamPlayersPerformanceHealth));