import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SearchResultList from './SearchResultList';
import SearchGroupChatSection from './SearchGroupChatSection';
import SearchChatSection from './SearchChatSection';
import LandingImage from '../../assets/images/chase-chimp.png';
import ProfileDetails from '../CommonListSection/ProfileDetails';
import { connect } from 'react-redux';
import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
    mainRoot: {
        width: '100%',
        maxWidth: 290,
        zIndex: 11,
        backgroundColor: '#fff',
        transition: 'width 0.25s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            borderRight: '1.5px solid #eeeeee'
        },
    },
    mainRootWidth: {
        width: 0,
        minWidth: 0,
        border: 'none',
        visibility: 'hidden'
    },
    root: {
        flexGrow: 1,
        // maxWidth: 320,
        // backgroundColor: '#fff',
        // transition: 'width 0.5s ease-in-out',
        // [theme.breakpoints.down('sm')]: {
        //     width: '0px',
        // },
    },
    tabsRoot: {
        [theme.breakpoints.down('sm')]: {
            padding: '14px 0'
        },
        padding: '14px 20px'
    },
    tabRoot: {
        borderBottom: '1px solid #0000003a',
        padding: 0,
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 600,
        minWidth: 100,
        color: '#838383'
    },
    tabWrapper: {
        flexDirection: 'row',
        '& > *:first-child': {
            // fontSize: '21px',
            margin: '0 6px !important'
        }
    },
    labelIcon: {
        minHeight: '50px'
    },
    tabIndicator: {
        height: '3px',
    },
    chatArea: {
        width: '100%',
        // backgroundColor: '#F3F2F1',
        display: 'flex',
        borderLeft: `1.5px solid ${theme.palette.secondary.main}`
    },
    landingPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: 'inherit',
        '& img': {
            [theme.breakpoints.down('sm')]: {
                // height: 260
            }
        }
    },
    landingPageContent: {
        textAlign: 'center',
        maxWidth: '90%'
    },
    landingPageText: {
        marginTop: 0,
        // fontFamily: 'cursive',
        fontSize: 25,
        fontWeight: 600,
        color: '#58595b'
        // color: theme.palette.primary.main
    },
    svgIconStyle: {
        '& path': {
            fill: 'currentColor'
            // color: theme.palette.primary.main,
            // stroke: theme.palette.primary.main,
        }
    },
    landingImg: {
        width: '70%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

const SearchListLayout = (props) => {
    console.log('SearchListLayout ', props)
    const classes = useStyles();
    const [currentChat, setCurrentChat] = useState(null);
    const [openList, setOpenList] = useState(false);

    useEffect(() => {
        console.log('SearchListLayout [] ');
        if (firebase.messaging.isSupported()) {
            const analytics = firebase.analytics();
            analytics.logEvent('page_view', {
                page_location: window.location.href,
                page_path: window.location.pathname,
                page_title: 'Search'
            });
        }
    }, []);

    useEffect(() => {
        if (!props.globalSearchQuery) {
            setCurrentChat(null);
            setOpenList(false);
        }
    }, [props.globalSearchQuery])

    const handleChat = (chatDetails) => {
        setCurrentChat(chatDetails);
        if (window.innerWidth <= 960) {
            setOpenList(true);
        }
    }

    const handleSearchResults = () => {
        if (window.innerWidth <= 960) {
            setOpenList(!openList);
        }
        else setOpenList(false)
    }

    return (
        <>
            <div className={classNames(classes.mainRoot, openList && classes.mainRootWidth)}>
                <div className={classes.root}>
                    <SearchResultList handleChat={handleChat} currentChat={currentChat} {...props} />
                </div>
            </div>
            <div className={classes.chatArea}>
                {(currentChat && props.globalSearchQuery) ? (currentChat.type === 'group' ?
                    <SearchGroupChatSection handleSearchResults={handleSearchResults} {...currentChat} />
                    :
                    <SearchChatSection handleSearchResults={handleSearchResults} {...currentChat} />)
                    :
                    <div className={classes.landingPage}>
                        <div className={classes.landingPageContent}>
                            <img className={classes.landingImg} src={LandingImage} alt="Landing_Page"></img>
                            <p className={classes.landingPageText}>Stay connected, make conversation happen...</p>
                        </div>
                    </div>
                }
                {
                    (currentChat && props.globalSearchQuery) && <ProfileDetails fromSearch={true} handleChat={handleChat} currentChat={currentChat} />
                }
            </div>
        </>
    );
}

const mapStateToProps = (state, props) => ({
    globalSearchQuery: state.search.searchQuery
});

export default connect(mapStateToProps, null)(SearchListLayout);
