import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, IconButton, Badge, Backdrop, CircularProgress, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { getTeamProfile, updateBasicProfile, deleteEducation, deleteWorkExperience } from "../../ducks/user";
import { withRouter } from 'react-router';
import { getAvatarText } from "../../helpers";
import PlayersList from "./PlayersList";
import { getLeaguesList } from "../../ducks/game";
import { getSearchValue, setSearchQuery } from "../../helpers";
import { getSeasonsList } from "../../ducks/game";
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import { Menu, MenuItem } from "@material-ui/core";
import { Fade } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getRoundsList } from "../../ducks/game";
import { getSeasonPlayers } from "../../ducks/game";
import ProfileDetail from "./ProfileDetail";
import TeamProfileSummary from "./TeamProfileSummary";
import loading from "../../ducks/loading";
import qs from 'query-string';
import { getSeasonDetails } from "../../ducks/game";
import { getLeagueDetails } from "../../ducks/game";









const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxHeight: 'calc(100vh - 116px)',
        overflowY: 'auto',
        padding: '40px 24px',
        display: 'flex',
        backgroundColor: "#f5f5f5",
        paddingBottom: 15,
    },
    gridContainer: {
        display: 'flex',
        width: '100%',
        margin: '0 auto',
        maxWidth: 1250,
    },
    rightSectionRoot: {
        marginTop: '-10px',
        flexGrow: 0,
        maxWidth: '27.5%',
        flexBasis: '27.5%',
    },
    mainroot: {
        padding: '0px 20px',
        flexGrow: 0,
        maxWidth: '72.5%',
        flexBasis: '72.5%',
    },
    cardDisplay: {
        backgroundColor: "#FFFFFF",
        padding: '0px',
        borderRadius: 3,
        height: '296px',
        boxShadow: "0px 0px 4px 1px #EDEDED",
        position: 'relative',
    },
    profilePicRoot: {
        width: '100%',
        padding: "24px 0 12px",
        position: 'absolute',
        bottom: 0,

    },
    iconRootCam: {
        color: "#fff"
    },
    avatarSize: {
        width: "122px",
        height: "122px",
        fontSize: 60,
        backgroundColor: theme.palette.primary.main,
        border: '2px solid white',
        '& img': {
            objectFit: 'fill'
        }
    },
    avatarSize1: {
        width: "100%",
        height: "180px",
        margin: '0 auto',
        fontSize: 60,
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        '& img': {
            objectFit: 'fill'
        }
    },
    profileNameRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto',
        alignItems: 'center',
        padding: '0px 20px'
    },
    profileNameActions: {
        position: 'absolute',
        marginLeft: 20,
        top: 4,
        right: -110
    },
    profileName: {
        fontSize: 16,
        color: "#141414",
    },
    backdropImage: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        position: "absolute",
        borderRadius: "50%",

    },
    backdropImage1: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        position: "absolute",
    },
    BackgroundCvr: {
        background: '#eefbff',
        height: '180px',
        textAlign: 'center',
        position: 'absolute',
        top: '-84px',
        width: '100%',
    },
    svgIcon: {
        overflow: "inherit",
    },
    iconRoot: {
        marginRight: 5
    },
    favourite: {
        width: "270px",
        height: "41px",
        borderRadius: "6px",
        backgroundColor: "#ffcc00",
        marginBottom: 10,
        border: 'none'
    },
    inputStyles: {
        fontSize: 15,
        color: '#535353'
    },
    nameInputStyles: {
        textAlign: 'center',
        color: '#141414',
        fontSize: 16,
        fontWeight: 600
    },
    inputUnderine: {
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    },

    favButton: {
        height: 36,
        minWidth: 100,
        '& path': {
            fill: '#FFF'
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '& .fav-button-text': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    followbutton: {
        borderRadius: 35,
        height: 30,
        '& path': {
            fill: '#FFF'
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '& .fav-button-text': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    tabStyles: {
        minHeight: 40,
        textTransform: 'capitalize',
        color: '#262626',
        minWidth: 'auto',
        // margin: '0px 12px',
        padding: '6px 12px 0px',
        fontSize: 13,
    },
    selectedTab: {
        fontWeight: 600
    },
    tabsRoot: {
        width: '100%',
        maxWidth: '100%',
        minHeight: 40,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        boxShadow: '-4px 3px 10px -7px #000'
    },
    paperRoot: {
        minWidth: 200
    },
    rightIcon: {
        padding: "0px 0 0 20px",
    },
    button: {
        borderRadius: 30,
        color: "#5D5FEF",
        backgroundColor: "#EBEBFF",
        padding: "5px 14px",
        fontSize: "20",
        fontWeight: 500,
        marginLeft: '-5px',

    },
}));
const defaultObj = {
    page: 1,
    limit: 10,
    totalCount: 0,
    data: [],
    loading: false
}
const defaultFilters = {
    league: null,
    season: null,
    round: null,
    status: 'LIVE',
    match: null
};

const defaultBirthDate = new Date(new Date().getFullYear() - 1, 11, 31);

const TeamProfile = props => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [rounds, setRounds] = useState([]);
    const [loadingSeasons, setLoadingSeasons] = useState(false);
    const [menuPosition, setMenuPosition] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [playersList, setPlayersList] = useState(defaultObj)
    const [Loading, setLoading] = useState(false)
    const open = Boolean(anchorEl);
    const [details, setDetails] = useState([]);
    const [preview, setPreview] = useState(false);
    const [fetchAll, setFetchAll] = useState(false);
    const bannerImage = 'https://s3.ap-south-1.amazonaws.com/profilepics.prod/leagues_team_profile.png'



    useEffect(() => {
        if (props.match.params.seasonId) {
            props.getSeasonDetails({
                seasonId: props.match.params.seasonId,
                params: { limit: 5000, page: 1 },
                onSuccessSeasonDetails,
                onErrorSeasonDetails
            });

        }
    }, [props.match.params.seasonId]);

    const getLeaguesData = (leagueId) => {
        if (leagueId) {
            props.getLeagueDetails({
                leagueId: leagueId,
                params: { limit: 5000, page: 1 },
                onSuccessLeagueDetails,
                onErrorLeagueDetails
            });
        }

    }

    const onSuccessLeagueDetails = (result) => {
        if (result) {
            setFiltersData(prevState => ({ ...prevState, league: result }));

        }

    }
    const onErrorLeagueDetails = (error) => {
        console.log('error', error)
    }

    const onSuccessSeasonDetails = (result) => {
        if (result) {
            setFiltersData(prevState => ({ ...prevState, season: result }));
            getLeaguesData(result.league_id);

        }

    }

    const onErrorSeasonDetails = (error) => {
        console.log('error', error)
    }


    useEffect(() => {
        if (props.match.params.teamId && props.match.params.seasonId) {
            props.getTeamProfile({
                seasonId: props.match.params.seasonId,
                teamId: props.match.params.teamId,
                onTeamDetailsSuccess,
                onTeamDetailsError
            });
        }
        else {
            setDetails([]);
        }
    }, [props.match.params.teamId, props.match.params.seasonId]);

    useEffect(() => {
        if (props.match.params.teamId && props.match.params.seasonId) {
            if (fetchAll === false) {
                setPlayersList(prevState => ({ ...prevState, loading: true }))
                props.getSeasonPlayers({
                    seasonId: props.match.params.seasonId,
                    params: { limit: defaultObj.limit, page: defaultObj.page, team_id: props.match.params.teamId },
                    onSuccessCB,
                    onErrorCB
                })
            }
            else if (fetchAll === true) {
                setPlayersList(prevState => ({ ...prevState, loading: true }))
                props.getSeasonPlayers({
                    seasonId: props.match.params.seasonId,
                    params: { limit: 100, page: defaultObj.page, team_id: props.match.params.teamId },
                    onSuccessCB,
                    onErrorCB
                })
            }






        }

    }, [props.match.params.teamId && filtersData.season?._id, fetchAll])


    const onSuccessCB = (result, headers) => {
        if (result.length > 0) {
            const searchValue = getSearchValue(props.history, 'player');
            setFiltersData(prevState => ({ ...prevState, player: result?.find(obj => obj.employee_id === searchValue) }));
            setPlayersList(prevState => ({
                ...prevState, loading: false, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
                data: [...result]
            }));

        }
        else {
            setPlayersList(defaultObj)
            setLoading(false);
        }
        setLoading(false);
    }


    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Players', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const onTeamDetailsSuccess = result => {
        setDetails(result);
    };

    const onTeamDetailsError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch Team Details.', {
            variant: 'error',
            preventDuplicate: true
        });
    };

    const formatUserInfoProperty = (userInfo) => {
        if (userInfo) {
            return userInfo;
        } else {
            return "--";
        }
    };

    const handlePreview = (attachments, attachmentIndex) => {
        setPreview({ open: true, attachments, attachmentIndex });
    }

    const handlePreviewClose = () => {
        setPreview({ open: false, attachments: [] });
    }




    return (
        <div id="arena-list-root" className={classes.root}>
            <div className={classes.gridContainer}>
                <div className={classes.mainroot}>
                    <div className={classes.cardDisplay}>
                        <div className={classes.profilePicRoot}>
                            <div className={classes.BackgroundCvr}>

                                <Avatar
                                    id="bannerImage"
                                    className={classes.avatarSize1}
                                    src={bannerImage}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => details.bannerImage && handlePreview([{ url: details.bannerImage, mimeType: 'IMAGE' }], 0)}
                                >{getAvatarText(details.name)}</Avatar>

                            </div>
                            <div style={{ marginLeft: '20px' }}>

                                <Avatar
                                    id="profileImg"
                                    className={classes.avatarSize}
                                    src={details.logo_url}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => details.photoUrl && handlePreview([{ url: details.photoUrl, mimeType: 'IMAGE' }], 0)}
                                >{getAvatarText(details.name)}</Avatar>

                            </div>
                            <div className={classes.profileNameRoot}>
                                <div style={{ position: 'relative' }}>

                                    <h3 className={classes.profileName}>
                                        {formatUserInfoProperty(details.name)}
                                    </h3>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <TeamProfileSummary filtersData={filtersData} teamId={props.match.params.teamId} seasonId={props.match.params.seasonId} />
                    </div>
                    <div className={classes.tabroot}>
                        <ProfileDetail seasonId={props.match.params.seasonId} filtersData={filtersData} teamId={props.match.params.teamId} setFiltersData={setFiltersData} defaultFilters={defaultFilters} props={props} loading={loading} setLoading={setLoading} />
                    </div>
                </div>
                <div className={classes.rightSectionRoot} >
                    <PlayersList playersList={playersList} fetchAll={fetchAll} setFetchAll={setFetchAll} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    user: state.user,
    presence: state.presence[`${props.match.params.id}@${process.env.REACT_APP_XMPP_DOMAIN}`],
    filtersData: state.game.filtersData,
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        getTeamProfile: (options) => dispatch(getTeamProfile(options)),
        getSeasonPlayers: (options) => dispatch(getSeasonPlayers(options)),
        getSeasonDetails: (options) => dispatch(getSeasonDetails(options)),
        getLeagueDetails: (options) => dispatch(getLeagueDetails(options)),



    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamProfile));


