import { Tab, Tabs, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { setLoading } from '../../ducks/loading';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getSeasonIndicatorSlabs } from '../../ducks/game';
import { useSnackbar } from 'notistack';
import gameIllustration from '../../assets/images/game_Illustration.png'


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fafafa',
        paddingBottom: '20px'
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '4px',
        marginTop: 10,
        minHeight: '255px',

    },
    rankingContainer: {
        backgroundColor: '#EBEBEB',
        height: '20px',
        padding: '7px 8px 14px 12px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',

    },
    tabIndicator: {
        height: '3px',
    },
    tabsFixed: {
        maxWidth: 1300
    },
    tabsRoot: {
        minWidth: 280,
        paddingBottom: 0,
        borderBottom: '1px solid #0000003a',
        marginBottom: '10px'
    },
    tabRoot: {
        padding: '0 20px',
        textTransform: 'capitalize',
        fontSize: 15,
        fontWeight: 500,
        minWidth: 'auto',
        color: '#838383',
    },
    scoringContainer: {
        backgroundColor: '#EBEBEB',
        height: '20px',
        padding: '7px 8px 14px 12px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between'

    },
    scoringTab: {
        background: '#F6F6F6',
        borderRadius: '4px',
        margin: '16px 24px 16px 24px',

    },
    basedon: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14,
            textTransform: 'capitalize'
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    ruleDetails: {
        padding: '10px',
        minHeight: '75px',
        maxHeight: '135px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
    },
    colorIndicator: {
        backgroundColor: '#D9D9D9',
        height: '18px',
        width: '18px',
        borderRadius: '17px',
        marginLeft: 5
    },
    scoringCondition: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            fontSize: '15px',
            marginBottom: '5px',

        }
    },
    noData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export const colorOptions = [{ name: "Green", id: "#218D21" }, { name: "Red", id: "#E11919" }, { name: "Amber", id: "#C98E09" }];
export const slabTypes = [{ name: 'Between', id: 'BETWEEN' }, { name: 'Above', id: 'ABOVE' }]

const SeasonPerformanceHealthList = ({ filtersData, seasonDetails, getSeasonIndicatorSlabs, subTab }) => {
    const classes = useStyles();
    const [seasonIndicatorsList, setSeasonIndicatorsList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const rulesTabs = [{ label: `Team ${seasonDetails?.labels_config?.kpis?.core}`, name: 'TEAM_MATCH', id: 0 }, { label: ` Team ${seasonDetails?.labels_config?.kpis?.bonus}`, name: 'TEAM_BONUS', id: 1 }, { label: `Player ${seasonDetails?.labels_config?.kpis?.core}`, name: 'PLAYER_MATCH', id: 2 }, { label: `Player ${seasonDetails?.labels_config?.kpis?.bonus}`, name: 'PLAYER_BONUS', id: 3 }]

    useEffect(() => {
        if (filtersData?.season?._id && subTab === 0) {
            getSeasonIndicatorSlabs({ seasonId: filtersData?.season?._id, params: { page: 1, limit: 5000 }, onSuccessSeasonIndicatorsCB, onErrorSeasonIndicatorsCB });
        }
    }, [filtersData?.season?._id]);

    const onSuccessSeasonIndicatorsCB = (result, headers) => {
        setLoading(true);
        if (result.length > 0 && headers) {
            setLoading(false);
            const CopyOfKpisList = JSON.parse(JSON.stringify(result))
            let Response = CopyOfKpisList.map((kpi, index) => {
                let obj = {
                    name: kpi.kpi_name,
                    unit_of_measurement: kpi.unit_of_measurement,
                    tags: kpi.tags?.map(ele => ele),
                    tabValue: 0,
                    kpi_id: kpi?.kpi_id,
                    indicators_enabled: kpi?.indicators_enabled

                }
                if (kpi.tags?.some(ele => ele === 'TEAM_MATCH') && kpi.team_match_rule) {
                    let teamMatchObj = { basedOn: kpi.team_match_rule.based_on };
                    if (seasonDetails?.season_format === 'HEAD_TO_HEAD') {
                        teamMatchObj.indicator_slabs = kpi.team_match_rule?.indicator_slabs?.map(ele => {
                            ele.status_code = colorOptions.find(cop => cop.id === ele.status_code);
                            ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                            return ele
                        });
                        obj['team_match_rule'] = teamMatchObj
                    }
                    else {
                        teamMatchObj.indicator_slabs = kpi.team_match_rule?.indicator_slabs?.map(ele => {
                            ele.status_code = colorOptions.find(cop => cop.id === ele.status_code)
                            ele.type = ele.type ? slabTypes.find(s => s.id === ele.type) : { name: 'Between', id: 'BETWEEN' };
                            return ele
                        });
                        obj['team_race_rule'] = teamMatchObj
                    }

                }
                if (kpi.tags?.some(ele => ele === 'TEAM_BONUS') && kpi.team_bonus_rule) {
                    let bonusMatchObj = {
                        basedOn: kpi.team_bonus_rule.based_on,

                    }
                    bonusMatchObj.indicator_slabs = kpi.team_bonus_rule?.indicator_slabs?.length > 0 ? kpi?.team_bonus_rule?.indicator_slabs.map(slab => ({ ...slab, status_code: colorOptions.find(cop => cop.id === slab.status_code), type: slabTypes.find(s => s.id === slab.type) })) : []
                    obj['team_bonus_rule'] = bonusMatchObj
                }
                if (kpi.tags?.some(ele => ele === 'PLAYER_MATCH') && kpi.player_match_rule) {
                    let bonusMatchObj = {
                        basedOn: kpi.player_match_rule.based_on,
                        default_role: kpi.player_match_rule.default_role,
                        rules: kpi.player_match_rule.rules.map(rule => ({
                            ...rule, indicator_slabs: rule?.indicator_slabs?.length > 0 ? rule?.indicator_slabs.map(slab => ({ ...slab, status_code: colorOptions.find(cop => cop.id === slab.status_code), type: slabTypes.find(s => s.id === slab.type) })) : []
                        }))

                    }
                    obj['player_match_rule'] = bonusMatchObj
                }
                if (kpi.tags?.some(ele => ele === 'PLAYER_BONUS') && kpi.player_bonus_rule) {
                    let bonusMatchObj = {
                        basedOn: kpi.player_bonus_rule.based_on,
                        default_role: kpi.player_bonus_rule.default_role,
                        rules: kpi.player_bonus_rule.rules.map(rule => ({
                            ...rule, indicator_slabs: rule?.indicator_slabs?.length > 0 ? rule?.indicator_slabs.map(slab => ({ ...slab, status_code: colorOptions.find(cop => cop.id === slab.status_code), type: slabTypes.find(s => s.id === slab.type) })) : []
                        }))

                    }
                    obj['player_bonus_rule'] = bonusMatchObj
                }
                return obj;
            })
            setSeasonIndicatorsList([...Response])
        }
        else {
            setLoading(false);
            setSeasonIndicatorsList([])

        }
    }

    const onErrorSeasonIndicatorsCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rewards List', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleChangeTab = (event, newValue, kpi) => {
        const CopyOfKpisList = JSON.parse(JSON.stringify(seasonIndicatorsList))
        const selectedKpi = CopyOfKpisList.find(item => item.kpi_id === kpi.kpi_id)
        selectedKpi['tabValue'] = newValue
        setSeasonIndicatorsList([...CopyOfKpisList])
    };

    const KpiRuleTab = ({ data, kpi, type }) => {
        return (
            <div>
                <div className={classes.scoringTab}>
                    <div
                        className={classes.scoringContainer}
                    >
                        <span>Scoring Condition</span>
                        {data?.basedOn &&
                            <div className={classes.basedon}>
                                <button>
                                    <Typography className="seasnHdng" title={"Based On"}>

                                        {data?.basedOn === 'PLANNED_ACHIEVEMENT' ? 'MTD/WTD Achievement' : `${data?.basedOn.toLowerCase()} ${data?.basedOn === 'ACHIEVEMENT' ? '%' : ''}`}
                                    </Typography>
                                </button>
                            </div>}
                    </div>
                    {
                        ((data?.rules) ? ((data?.rules?.length > 0) ? data?.rules[0]?.indicator_slabs : []) : data?.indicator_slabs)?.length > 0 ?
                            <div className={classes.ruleDetails}>
                                {((data?.rules) ? ((data?.rules?.length > 0) ? data?.rules[0]?.indicator_slabs : []) : data?.indicator_slabs).map((slab) => (
                                    slab?.type?.id === 'ABOVE' ? <div className={classes.scoringCondition}>
                                        <span>{`${slab?.type?.name} ${(data?.basedOn === 'ACHIEVEMENT' || data?.basedOn === 'PLANNED_ACHIEVEMENT') ? slab?.min + '%' : slab?.min} = ${slab?.status_code?.name}`}</span>
                                        <div className={classes.colorIndicator} style={{ backgroundColor: slab?.status_code?.id }}></div>
                                    </div>
                                        :
                                        <div className={classes.scoringCondition}>
                                            <span>{`${slab?.type?.name} ${(data?.basedOn === 'ACHIEVEMENT' || data?.basedOn === 'PLANNED_ACHIEVEMENT') ? slab?.min + '%' : slab?.min} to ${(data?.basedOn === 'ACHIEVEMENT' || data?.basedOn === 'PLANNED_ACHIEVEMENT') ? slab?.max + '%' : slab?.max} = ${slab?.status_code?.name}`}</span>
                                            <div className={classes.colorIndicator} style={{ backgroundColor: slab?.status_code?.id }}></div>
                                        </div>

                                ))}
                            </div>
                            :
                            <div className={classes.noData}>
                                <p>No Data Available</p>

                            </div>

                    }
                </div>
            </div>
        )

    }

    return (
        <div className={classes.root}>
            {
                (seasonIndicatorsList.filter((kpi) => !(kpi?.indicators_enabled))).length === seasonIndicatorsList.length ?
                    <div style={{ textAlign: 'center', marginTop: 60 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>No Data Available</p>
                    </div> :
                    seasonIndicatorsList.map((kpi, index) => (
                        <div className={classes.performance} key={index}>
                            {console.log('seassssssss', seasonIndicatorsList)}

                            <div
                                className={classes.rankingContainer}
                            >
                                <span>{kpi.name}</span>
                            </div>
                            <Tabs
                                value={kpi.tabValue}
                                onChange={(event, newValue) => handleChangeTab(event, newValue, kpi)}
                                variant="fullWidth"
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="icon label tabs example"
                                className={classes.tabsRoot}
                                classes={{ indicator: classes.tabIndicator, fixed: classes.tabsFixed }}
                            >
                                {
                                    rulesTabs.map(tab => {
                                        if ((kpi.tags && kpi.tags.includes(tab.name))) {
                                            return (
                                                <Tab className={classes.tabRoot} classes={{ wrapper: classes.tabWrapper, labelIcon: classes.labelIcon }} label={tab.label} />
                                            )
                                        }
                                    })
                                }
                            </Tabs>
                            {
                                (((kpi.tags) && (kpi.tags.includes('TEAM_MATCH') && kpi.tabValue === kpi.tags.indexOf('TEAM_MATCH'))) && seasonDetails?.season_format === 'HEAD_TO_HEAD') &&
                                <KpiRuleTab
                                    data={kpi.team_match_rule}
                                    kpi={kpi}
                                    type='TEAM_MATCH'
                                />
                            }
                            {
                                (((kpi.tags) && (kpi.tags.includes('TEAM_MATCH') && kpi.tabValue === kpi.tags.indexOf('TEAM_MATCH'))) && seasonDetails?.season_format === 'TEAM_RACE') &&
                                <KpiRuleTab
                                    data={kpi.team_race_rule}
                                    kpi={kpi}
                                    type='TEAM_MATCH'
                                />

                            }

                            {
                                (((kpi.tags) && (kpi.tags.includes('TEAM_BONUS') && kpi.tabValue === kpi.tags.indexOf('TEAM_BONUS')))) &&

                                <KpiRuleTab
                                    data={kpi.team_bonus_rule}
                                    kpi={kpi}
                                    type='TEAM_BONUS'
                                />

                            }
                            {
                                (((kpi.tags) && (kpi.tags.includes('PLAYER_MATCH') && kpi.tabValue === kpi.tags.indexOf('PLAYER_MATCH')))) &&

                                <KpiRuleTab
                                    data={kpi.player_match_rule}
                                    kpi={kpi}
                                    type='PLAYER_MATCH'
                                />

                            }
                            {
                                (((kpi.tags) && (kpi.tags.includes('PLAYER_BONUS') && kpi.tabValue === kpi.tags.indexOf('PLAYER_BONUS')))) &&

                                <KpiRuleTab
                                    data={kpi.player_bonus_rule}
                                    kpi={kpi}
                                    type='PLAYER_BONUS'

                                />

                            }


                        </div>
                    ))}
        </div >


    )
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getSeasonIndicatorSlabs: (options) => dispatch(getSeasonIndicatorSlabs(options)),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeasonPerformanceHealthList));