import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, TableRow, TableCell, Tooltip, TableHead } from "@material-ui/core";
import { setLoading } from "../../../ducks/loading";
import { CustomCarousel } from "./CustomCarousel";
import LiveScoresMuiTable from "../../LiveScores/LiveScoresMuiTable";
import IllustrationView from "../../LiveScores/IllustrationView";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginBottom: 35,
        '& thead': {
            height: 40,
            '& tr': {
                backgroundColor: '#EBEBEB !important',
                '& th': {
                    backgroundColor: '#EBEBEB !important',

                }
            }
        },
        '& tbody': {
            '& tr': {
                height: 40,
                '& td': {
                    fontSize: '14px !important',
                    width: '120px !important'
                },
                '& td:hover': {
                    background: '#f6f6f6 !important',
                },
                '& td:nth-child(1)': {
                    width: '200px !important'
                },
                '& td:nth-child(1):hover': {
                    background: 'none !important',
                },
            },
        },
        '& .table-footer': {
            background: 'white',
            fontSize: 12
        }
    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        marginBottom: 10,
        // paddingBottom: 10
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    divisin: {
        padding: 10
    },
    userHierarchieRoles: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 8,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    divisionList: {
        width: '100%',
        maxWidth: '45%',
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        height: 40,
        padding: 1,
        alignItems: 'center',
        marginBottom: 8
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        // marginBottom: 5
    },
}));

const MyTeamPlayersPerformanceHealthTable = ({ userHierarchieRoles, loading, seasonDetails, roleObjHealth, data, tableData, tableDataBonus, dynamicColumns, onclickRowHealth, onclickDivHealth, dynamicHeaders, dynamicColumnsCore, dynamicHeadersCore }) => {
    const classes = useStyles();

    const SummaryTable = ({ data, dynamicColumns, dynamicHeaders, checkColumn, handleChange, isAdmin, onclickRowHealth, }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            from='Health'
            checkColumn={checkColumn}
            handleChange={handleChange}
            isAdmin={isAdmin}
            onclickRowHealth={onclickRowHealth}
            options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
            }}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            <TableCell align="left" style={{ textAlign: 'left' }} rowSpan={2}>{props.columns[0]?.title}</TableCell>
                            {
                                dynamicHeaders.map((obj, index) => (
                                    <>
                                        <TableCell align="left" style={{ textAlign: 'left', color: `${obj.status_code}`, borderLeft: '2px solid #f0f2f5' }}>
                                            <Tooltip title={obj.color}><span>{obj.color}</span></Tooltip>
                                        </TableCell>
                                    </>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                )
            }}
        />
    );

    const columns = [
        {
            title: "KPI",
            field: "performance",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { textAlign: 'left' }
        }
    ]


    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <h2>{seasonDetails?.player_role === 'VIEWER' ? 'Performance Health' : 'My Reportees Performance Health'}</h2>
            </div>
            <div className={classes.performance}>
                <div className={classes.divisin}>
                    <div className={classes.userHierarchieRoles}>
                        <div className={classes.divisionList}>
                            <CustomCarousel data={userHierarchieRoles} selectesData={roleObjHealth ? roleObjHealth : userHierarchieRoles[0]} onclickDiv={onclickDivHealth} />
                        </div>
                    </div>
                    <div className="">
                        {
                            tableData?.length > 0 &&
                            <div>
                                <div className={classes.season_head}>
                                    <h5>{`${seasonDetails?.labels_config?.kpis?.core}`}</h5>
                                </div>
                                <div style={{ padding: '10px 0px', marginBottom: 10 }}>
                                    <div className=''>
                                        <SummaryTable
                                            data={tableData}
                                            dynamicColumns={dynamicColumnsCore}
                                            dynamicHeaders={dynamicHeadersCore}
                                            onclickRowHealth={onclickRowHealth}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            tableDataBonus?.length > 0 &&
                            <div>
                                <div className={classes.season_head}>
                                    <h5>{`${seasonDetails?.labels_config?.kpis?.bonus}`}</h5>
                                </div>
                                <div style={{ padding: '10px 0px' }}>
                                    <div className=''>
                                        <SummaryTable
                                            data={tableDataBonus}
                                            dynamicColumns={dynamicColumns}
                                            dynamicHeaders={dynamicHeaders}
                                            onclickRowHealth={onclickRowHealth}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (tableData?.length === 0 && tableDataBonus?.length === 0) &&
                            < IllustrationView loading={loading} />
                        }
                    </div >
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions,
    seasonDetails: state.game.seasonDetails
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTeamPlayersPerformanceHealthTable));