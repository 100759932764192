import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFollowingUserList, getFollowUserList, getMutualFollowingUserList } from '../../ducks/favourites';
import { getMediaList } from '../../ducks/user';
import { getEllipsisText } from '../../helpers';
import { useSnackbar } from 'notistack';
import { ChaseonsSkeleton, ChaseonsTemplate } from '../Arena/ReactionsDialog';
import UserProfileCard from '../UserProfileCard/UserProfileCard';
import { getProfileUrl } from '../Arena';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 12,
        padding: '10px 16px',
        backgroundColor: '#FFF',
        borderRadius: 8,
    },
    titleRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontSize: 14,
        color: '#262626',
    },
    seeAllButton: {
        fontSize: 10,
        color: '#2626266E',
        padding: 0,
        textTransform: 'none'
    },
    avatarStyles: {
        width: 28,
        height: 28,
        fontSize: 16
    },
    profileName: {
        fontSize: 12,
        fontWeight: 500,

    }
}));

const defaultObj = {
    page: 0,
    limit: 5,
    totalCount: 0,
    data: [],
    loading: false
}
const FavouritesList = ({ user, keycloak, getFollowUserList, getFollowingUserList, history, staticContext, ...others }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [followersUserList, setFollowersUserList] = useState(defaultObj);
    const [followingUserList, setFollowingUserList] = useState(defaultObj);

    useEffect(() => {
        setFollowingUserList(prevState => ({ ...prevState, loading: true }))
        getFollowUserList({
            username: keycloak.preferred_username,
            params: { limit: defaultObj.limit, page: defaultObj.page },
            onFollowerUserListSuccess,
            onFollowerUserListError
        });
        setFollowersUserList(prevState => ({ ...prevState, loading: true }));
        getFollowingUserList({
            username: keycloak.preferred_username,
            params: { limit: defaultObj.limit, page: defaultObj.page },
            onFollowingUserListSuccess,
            onFollowingUserListError
        });
    }, []);

    const onFollowerUserListSuccess = (result, headers) => {
        if (parseInt(headers.page) === 0)
            setFollowingUserList(prevState => ({
                ...prevState, loading: false, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
                data: result
            }));
        else
            setFollowingUserList(prevState => ({
                ...prevState, loading: false, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
                data: [...prevState.data, ...result]
            }));
    }

    const onFollowerUserListError = (error) => {
        setFollowingUserList(defaultObj);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Favourites.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const onFollowingUserListSuccess = (result, headers) => {
        if (parseInt(headers.page) === 0)
            setFollowersUserList(prevState => ({
                ...prevState, loading: false, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
                data: result
            }));
        else
            setFollowersUserList(prevState => ({
                ...prevState, loading: false, page: parseInt(headers.page) + 1, totalCount: parseInt(headers.total_count),
                data: [...prevState.data, ...result]
            }));
    }
    const onFollowingUserListError = (error) => {
        setFollowersUserList(defaultObj);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to fetch Favourites.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const fetchAll = (type) => {
        if (type == 'followers') {
            setFollowersUserList(prevState => ({ ...prevState, loading: true }));
            getFollowingUserList({
                username: user.username,
                params: { limit: 100, page: defaultObj.page },
                onFollowingUserListSuccess,
                onFollowingUserListError
            });
        }
        else {
            setFollowingUserList(prevState => ({ ...prevState, loading: true }))
            getFollowUserList({
                username: user.username,
                params: { limit: 100, page: defaultObj.page },
                onFollowerUserListSuccess,
                onFollowerUserListError
            });
        }
    }

    return (
        <>
            <div className={classes.root} {...others}>
                <div className={classes.titleRoot}>
                    <p className={classes.title}>Followers</p>
                    {
                        (followersUserList.totalCount !== followersUserList.data.length) &&
                        <Button
                            variant="text"
                            className={classes.seeAllButton}
                            onClick={() => fetchAll('followers')}
                        >
                            See all
                        </Button>
                    }
                </div>
                <div className={classes.listRoot}>
                    {!followersUserList.loading && [0].map(i => <ChaseonsSkeleton secondary={false} key={i} />) ?

                        followersUserList.data.length && followersUserList.data.map((item, i) => (
                            <UserProfileCard
                                key={i}
                                name={item.name}
                                username={item.userId}
                                avatarUrl={item.photoUrl}
                                avatarStyles={classes.avatarStyles}
                                profileNameStyles={classes.profileName}
                                style={{ margin: '9px 0px' }}
                                onProfileClick={() => history.push(getProfileUrl(item.userId))}
                            />
                        )) : followersUserList.loading && [0, 1, 2, 3].map(i => <ChaseonsSkeleton secondary={false} key={i} />)
                    }
                </div>
            </div>
            <div className={classes.root} {...others}>
                <div className={classes.titleRoot}>
                    <p className={classes.title}>Following</p>
                    {
                        (followingUserList.totalCount !== followingUserList.data.length) &&
                        <Button
                            variant="text"
                            className={classes.seeAllButton}
                            onClick={() => fetchAll('following')}
                        >
                            See all
                        </Button>
                    }
                </div>
                <div className={classes.listRoot}>
                    {!followingUserList.loading && [0].map(i => <ChaseonsSkeleton secondary={false} key={i} />) ?

                        followingUserList.data.length && followingUserList.data.map((item, i) => (
                            <UserProfileCard
                                key={i}
                                name={item.name}
                                username={item.userId}
                                avatarUrl={item.photoUrl}
                                avatarStyles={classes.avatarStyles}
                                profileNameStyles={classes.profileName}
                                style={{ margin: '9px 0px' }}
                                onProfileClick={() => history.push(getProfileUrl(item.userId))}
                            />
                        )) : followingUserList.loading && [0, 1, 2, 3].map(i => <ChaseonsSkeleton secondary={false} key={i} />)
                    }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    keycloak: state.keycloak.decoded_token
});

const mapDispatchToProps = (dispatch) => {
    return {
        getFollowUserList: value => dispatch(getFollowUserList(value)),
        getFollowingUserList: value => dispatch(getFollowingUserList(value)),
        // getMutualFollowingUserList: (options) => dispatch(getMutualFollowingUserList(options)),
        // getMediaList: value => dispatch(getMediaList(value))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavouritesList));