import React from 'react';
import { Avatar, makeStyles, Popover, Typography } from '@material-ui/core';
import { getAvatarText, setSearchQuery } from '../../helpers';
import { withRouter } from 'react-router-dom';
import { getTeamProfileUrl, getProfileUrl } from '../Arena';
import { connect } from 'react-redux';
import { getFiltersInfo } from '../../ducks/game';
import { useEffect } from 'react';
import { useState } from 'react';
import TeamProfileDailog from './TeamProfileDailog';


const useStyles = makeStyles(theme => ({
  colorDefault: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
  colorDefault2: {
    backgroundColor: `${theme.palette.primary.main}1A`,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: '10px'
  },
}));

const AvatarComponent = ({ name = '', username = '', type, url = '/', onClick = () => history.push(getTeamProfileUrl(username)), history, staticContext, getFiltersInfo, filtersData, from, data, ...others }) => {
  const classes = useStyles();
  const [openProfileDailog, setOpenProfileDialog] = useState(false);


  useEffect(() => {
    if (filtersData?.season) getFiltersInfo(filtersData)
  })

  const onClickAvatar = (event) => {
    if (['PLAYER', 'standings'].includes(from) && type !== 'TEAM') {
      // setSearchQuery(history, { role: data?.role_id });
      history.push(`/player-performance/${username}${history.location?.search}`);
    }
    else setOpenProfileDialog(true)

  }

  const handleCloseProfileDialogue = (type) => {
    setOpenProfileDialog(type)
  }



  return (
    <>
      {type ?
        <Avatar
          alt={name}
          src={url}
          classes={{ colorDefault: from !== "standings" ? classes.colorDefault : classes.colorDefault2 }}
          style={{ cursor: 'pointer' }}
          onClick={(e) => onClickAvatar(e)}
          {...others}
        >
          {getAvatarText(name)}
        </Avatar> :
        <Avatar
          alt={name}
          src={url}
          classes={{ colorDefault: classes.colorDefault }}
          style={{ cursor: 'pointer' }}
          {...others}
        >
          {getAvatarText(name)}
        </Avatar>
      }
      {openProfileDailog &&
        <TeamProfileDailog
          open={openProfileDailog}
          handleClose={handleCloseProfileDialogue}
          filtersData={filtersData}
          username={username}
          profileType={type}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  userDetails: state.user,
  loading: state.loading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getFiltersInfo: (options) => dispatch(getFiltersInfo(options)),


  }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarComponent));