import findIndex from "lodash/findIndex";
import findKey from "lodash/findKey";
import find from "lodash/find";
import moment from 'moment';

export const getAuthenticated = state => state.client.authenticated;

export const getCurrentRoomJid = (state) => {

  let jid = findKey(state.rooms, function (room) {
    return room.isCurrent === true;
  });

  if (jid) {
    return jid;
  }

  return "";
}

export const getContactList = (allContacts, clientJid) => {
  let contacts = JSON.parse(JSON.stringify(allContacts))
  delete contacts[clientJid];
  return Object.values(contacts);
};

export const getChatList = (allChats, clientJid) => {
  let chats = JSON.parse(JSON.stringify(allChats))
  // let teams = JSON.parse(JSON.stringify(allTeams))
  delete chats[clientJid];

  //   let sortedTeams = (Object.values(teams)).reduce((obj, item) => {
  //     if (allChats[targetJid]) {
  //         return obj;
  //     }
  //     obj[jid] = {
  //         ...item,
  //         show: 'unavailable',
  //         targetJid: jid,
  //         type: 'group',
  //         isCurrent: false
  //     };
  //     return obj;
  // }, {});

  // console.log('getChatList ', Object.values(chats).sort((a, b) => (a.sentdate < b.sentdate) ? 1 : -1));
  return (Object.values(chats)).sort((a, b) => (a.sentdate < b.sentdate) ? 1 : -1);

  // let chats = JSON.parse(JSON.stringify(allChats))
  // delete chats[clientJid];
  // let newChat = (Object.keys(chats)).find(key => chats[key].isNewChat === true);
  // if(chats[newChat]){
  //   let sortedChatList = (Object.values(chats)).sort((a, b) => (a.sentdate < b.sentdate) ? 1 : -1);
  //   delete chats[newChat];
  //   return [
  //     {...chats[newChat]},
  //     ...sortedChatList
  //   ]
  // }
};

const imageMsgTypes = ['png', 'PNG', 'JPEG', 'JPG', 'SVG', 'jpeg', 'jpg', 'svg', 'gif', 'GIF'];
const videoTypes = ['mp4', 'MP4', 'm4a', 'M4A', 'm4v', 'M4V', 'f4v', 'F4V', 'f4a', 'F4A', 'm4b', 'M4B', 'm4r', 'f4b', 'mov', 'MOV', '3gp', 'ogg', 'OGG', 'wmv', 'WMV', 'webm', 'WEBM', 'flv', 'FLV'];

const getMediaType = (ext) => {
  if (imageMsgTypes.includes(ext)) return 'Photo';
  else if (videoTypes.includes(ext)) return 'Video';
  else return 'others';
}

export const getImages = (media) => {
  // console.log('getImages ', media);
  if (media.data) {
    let mediaDup = media.data.reduce((result, item) => {
      if (getMediaType(item.mimeType) === 'Photo') {
        return [...result, item.source_url];
      }
      return result;
    }, [])
    return mediaDup;
  }
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const getSortedFiles = (array) => {
  var hash = Object.create(null),
    result = [];

  array.forEach(function (file) {
    var [year, month] = moment(file.sentDate).format("YYYYMM").split(/(?=..$)/);
    var period = moment(file.sentDate).format("YYYYMM");
    if (!hash[period]) {
      hash[period] = { period, files: [], month: '' };
      result.push(hash[period]);
    }

    hash[period].files = [file, ...hash[period].files]
    hash[period].month = `${months[parseInt(month) - 1]} ${new Date().getFullYear() === parseInt(year) ? '' : year}`
  });

  return result.sort((a, b) => (a.period < b.period) ? 1 : -1);
}

export const getUser = (allChats, targetJid) => {
  // console.log('getUser ', allChats, targetJid);
  let user = allChats[targetJid];
  // console.log('getUser user', user);
  return user;
}

export const surveysDetails = (surveys, id) => {
  return surveys[id];
}

export const selectSurveys = (surveys) => {
  // console.log('selectSurveys ', surveys);
  return Object.values(surveys);
}

export const getRoomInfo = (state, { roomJid }) => {
  const info = (state.rooms[roomJid]) || {};
  return info;
};

export const getRoomMessages = (state, { roomJid }) => {
  const messages = (state.messages[roomJid] && state.messages[roomJid].messages) || [];
  return messages;
};

export const getRoomMembers = (state, { roomJid }) => {
  // console.log('getRoomMembers ', state, roomJid);
  const members = (state.presence[roomJid] && state.presence[roomJid].members) || [];
  return members;
};

export const getRoomForms = (state, { roomJid }) => {
  const forms = (state.forms[roomJid] && state.forms[roomJid].forms) || [];
  return forms;
};

export const getRoomFiles = (state, { roomJid }) => {
  const files = (state.files[roomJid] && state.files[roomJid].files) || [];
  return files;
};

export const isRoomBookmarked = (state, { roomJid }) => {

  let index = findIndex(state.bookmarks.conferences, function (bookmark) {
    return bookmark.jid.bare === roomJid;
  });

  if (index !== -1) {
    return true;
  }
  return false;

}

export const getTemplateOptions = (state) => {

  let opts = [];

  Object.keys(state.forms.templates).forEach((node) => {
    if (state.forms.templates[node]) {
      opts.push({
        label: state.forms.templates[node].title,
        value: node
      })
    }
  });

  return opts;

}

export const getPublishedForm = (state, { formId }) => {

  let roomJid = getCurrentRoomJid(state);
  if (!roomJid) {
    return null;
  }

  let form = find(state.forms[roomJid].forms, function (form) {
    return form.id === formId;
  });

  return form;

}

export const getPublishedFile = (state, { fileId }) => {
  let roomJid = getCurrentRoomJid(state);
  // console.log('getPublishedFile state ', roomJid, fileId, state);
  let file = find(state.files[roomJid].files, function (file) {
    return file.id === fileId;
  });

  return file;

}

export const getKeywords = (state) => {

  let parsedKeywords = [];
  state.settings.keywords.forEach((keyword) => {
    parsedKeywords.push(keyword.value)
  });

  return parsedKeywords;

}