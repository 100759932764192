
import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { connect } from 'react-redux';
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from '../../../helpers';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

// Chart.register(ChartDataLabels);

const PerformanceGraph = ({ labels, userDetails, targets = [], actuals = [], unitOfMeasurement, unit }) => (
    <Line
        style={{ maxHeight: 400 }}
        data={{
            labels: labels,
            datasets: [{
                axis: 'y',
                label: 'Target',
                data: targets,
                fill: false,
                backgroundColor: '#9a81fc',
                borderColor: '#9a81fc',
                borderWidth: 2,
                pointRadius: 1.5,
                tension: .5,
                // datalabels: {
                //     align: 'left',
                //     anchor: 'start',
                // }
            }, {
                axis: 'y',
                label: 'Actual',
                data: actuals,
                fill: false,
                backgroundColor: '#50d4fb',
                borderColor: '#50d4fb',
                borderWidth: 2,
                pointRadius: 1.5,
                tension: .5,
                // datalabels: {
                //     align: 'left',
                //     anchor: 'start',
                // }
            }]
        }}
        options={{
            plugins: {
                legend: {
                    position: 'right',
                    align: 'start',
                    fullSize: false,
                    labels: {
                        boxHeight: 10,
                        boxWidth: 10,

                    }
                },
                // datalabels: {
                //     backgroundColor: 'transparent',
                //     borderRadius: 4,
                //     color: '#000000',
                //     font: {
                //         weight: 'bold'
                //     },

                //     formatter: (value) => unitOfMeasurement === 'CURRENCY' ?
                //         getCurrencyFormat(userDetails?.countryCode, unit, value)
                //         : `${getNumberFormat(userDetails?.countryCode, value)} ${unitOfMeasurement === 'PERCENTAGE' ? '%' : unitOfMeasurement === 'DURATION' ? getDurationWithUnit(value, unit) : ''}`,
                //     padding: 6
                // }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        // display: false,
                    },
                    grid: {
                        display: false
                    },
                    // display: false,
                },
                x: {
                    grid: {
                        display: false
                    },
                    offset: true
                }
            }
        }}
    />
)



const mapStateToProps = (state) => ({
    userDetails: state.user
});


export default connect(mapStateToProps, null)(PerformanceGraph);

const pwclabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const PerformanceLineGraphGreen = ({ labels, targets = [], actuals = [], achievement }) => (


    <Line
        style={{ maxHeight: 40, margin: '-10px 8px -3px', maxWidth: 160 }}
        data={{
            labels: labels,
            datasets: [
                {
                    axis: 'y',
                    label: 'Actual',
                    data: actuals,
                    fill: false,
                    backgroundColor: '#1CBF67',
                    borderColor: '#1CBF67',
                    borderWidth: 2,
                    pointRadius: 1.5,
                    tension: .5
                }]
        }}
        options={{
            plugins: {
                legend: {
                    display: false
                },
            },
            scales: {
                y: {
                    offset: true,
                    display: false,
                },
                x: {
                    offset: true,
                    display: false,
                }
            }
        }}
    />
)


export const PerformanceLineGraphAmber = ({ labels, targets = [], actuals = [], achievement }) => (
    console.log('Actuals PerformanceLineGraph', achievement,),

    <Line
        style={{ maxHeight: 40, margin: '-10px 8px -3px', maxWidth: 160 }}
        data={{
            labels: labels,
            datasets: [
                {
                    axis: 'y',
                    label: 'Actual',
                    data: actuals,
                    fill: false,
                    backgroundColor: '#BF9B1C',
                    borderColor: '#BF9B1C',
                    borderWidth: 2,
                    pointRadius: 1.5,
                    tension: .5
                }]
        }}
        options={{
            plugins: {
                legend: {
                    display: false
                },
            },
            scales: {
                y: {
                    offset: true,
                    display: false,
                },
                x: {
                    offset: true,
                    display: false,
                }
            }
        }}
    />
)

export const PerformanceLineGraphRed = ({ labels, targets = [], actuals = [], achievement }) => (
    console.log('Actuals PerformanceLineGraph', achievement,),

    <Line
        style={{ maxHeight: 40, margin: '-10px 8px -3px', maxWidth: 160 }}
        data={{
            labels: labels,
            datasets: [
                {
                    axis: 'y',
                    label: 'Actual',
                    data: actuals,
                    fill: false,
                    backgroundColor: '#D52424',
                    borderColor: '#D52424',
                    borderWidth: 2,
                    pointRadius: 1.5,
                    tension: .5
                }]
        }}
        options={{
            plugins: {
                legend: {
                    display: false
                },
            },
            scales: {
                y: {
                    offset: true,
                    display: false,
                },
                x: {
                    offset: true,
                    display: false,
                }
            }
        }}
    />
)

export const PwcPerformanceGraph = ({ labels, userDetails, targets = [], actuals = [], unitOfMeasurement, unit }) => (
    <Line
        style={{ maxHeight: 400 }}
        data={{
            labels: pwclabels,
            datasets: [{
                axis: 'y',
                label: 'Target',
                data: targets,
                fill: false,
                backgroundColor: '#9a81fc',
                borderColor: '#9a81fc',
                borderWidth: 2,
                pointRadius: 1.5,
                tension: .5,
                // datalabels: {
                //     align: 'left',
                //     anchor: 'start',
                // }
            }, {
                axis: 'y',
                label: 'Actual',
                data: actuals,
                fill: false,
                backgroundColor: '#50d4fb',
                borderColor: '#50d4fb',
                borderWidth: 2,
                pointRadius: 1.5,
                tension: .5,
                // datalabels: {
                //     align: 'left',
                //     anchor: 'start',
                // }
            }]
        }}
        options={{
            plugins: {
                legend: {
                    position: 'right',
                    align: 'start',
                    fullSize: false,
                    labels: {
                        boxHeight: 10,
                        boxWidth: 10,

                    }
                },
                // datalabels: {
                //     backgroundColor: 'transparent',
                //     borderRadius: 4,
                //     color: '#000000',
                //     font: {
                //         weight: 'bold'
                //     },

                //     formatter: (value) => unitOfMeasurement === 'CURRENCY' ?
                //         getCurrencyFormat(userDetails?.countryCode, unit, value)
                //         : `${getNumberFormat(userDetails?.countryCode, value)} ${unitOfMeasurement === 'PERCENTAGE' ? '%' : unitOfMeasurement === 'DURATION' ? getDurationWithUnit(value, unit) : ''}`,
                //     padding: 6
                // }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        // display: false,
                    },
                    grid: {
                        display: false
                    },
                    // display: false,
                },
                x: {
                    grid: {
                        display: false
                    },
                    offset: true
                }
            }
        }}
    />
)


