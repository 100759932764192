import React, { useState, useEffect } from "react";
import Board from "react-trello";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import data from './data.json'
import { Link, Breadcrumbs, Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { openTaskDialog, getKanbandBoardData, updateTask, deleteTask, exportTaskStatusAson, exportTasksAging, projectDetails, changeLanePositions } from '../../ducks/pms';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { MenuItem, Menu } from '@material-ui/core';
import Card from './TrelloCard/Card';
import { getAgeing } from '../ProductManagementSystem';
import LaneHeaderComponent from './TrelloCard/LaneHeaderComponent'
import { connect } from "react-redux";
import Breadcrumb from "./Breadcrumbs";
import { green } from "@material-ui/core/colors";
import MaterialTable from "material-table";
import ViewListIcon from '@material-ui/icons/ViewList';
import TableChartIcon from '@material-ui/icons/TableChart';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TablePagination, TextField, Avatar } from "@material-ui/core";
// import "./ProjectListLayout.css"
import { TramOutlined } from "@material-ui/icons";
import { ScrollableLane } from './TrelloCard/Styles/Base';
import NewLaneSection from './TrelloCard/NewLaneSection';
import NewLane from './TrelloCard/NewLane';
import Loader from './Loader';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import moment from "moment";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { getAvatarText, getEllipsisText } from '../../helpers';
import { setLoading } from "../../ducks/loading";
import queryString from "query-string";
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        },
    },
    avatarStyles: {
        fontSize: '0.75rem',
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 20,
        width: 20,
        marginRight: 5,
    },
    buttonAlign: {
        display: 'flex',
        alignitems: 'center'
    },
    kanbanAlign: {
        width: "100% !important",
        padding: "0px !important",
        // maxHeight: "calc(100vh - 61px)",
    },
    buttonAndHeading: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // position: "fixed",
        zIndex: "999",
        width: "100%",
        boxShadow: "1px 1px 0px #e3e2e23d",
    },
    headingStyles: {
        position: "relative",
        color: '#58595B',
        fontSize: 22,
        fontWeight: 600,
    },
    excelExport: {
        cursor: 'pointer',
        fontSize: 13,
        marginRight: 5,
        color: 'green'
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    exportButton: {
        marginRight: 3,
        height: 28,
        minWidth: 10,
        width: 28
    },
    backgroundColor: {
        '& th': {
            // backgroundColor: `${theme.palette.primary.main} !important`
        }
    },
    exportOptionStyles: {
        fontSize: 14,
        '& #share-to-arena': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
        },
        '& #send-in-chat': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                stroke: theme.palette.primary.main
            }
        }
    },
    //priority field styles
    priorityHigh: {
        fontSize: "large",
        color: "#ff4d4d",
        marginRight: 5
    },
    priorityMedium: {
        fontSize: "large",
        color: "#ff9933",
        marginRight: 5
    },
    priorityLow: {
        fontSize: "large",
        color: "#3a8736",
        marginRight: 5

    }
}));

const NewCard = () => {
    const [cardForm, setCardForm] = useState({ taskName: "", description: "" });
    return (
        <div>
            <input type="text" placeholder="Task Name" value={cardForm.taskName} onChange={e => setCardForm({ taskName: e.target.value, description: cardForm.description })} />
            <br /><br />
            <input type="text" placeholder="description" value={cardForm.description} onChange={e => setCardForm({ taskName: cardForm.taskName, description: e.target.value })} />
            <br />
            <Button variant="contained" color="primary" style={{ backgroundColor: "#FDCD2F" }}> Add </Button>
            <Button variant="contained" color="secondary" > Cancel </Button>
        </div>
    )
};

const colors = ['F8CD3C', '8185D6', 'E96177', 'DB66AE', 'ABDCD6', '5FA9EC', 'BCC658', '5DCCD5', 'EEB1A2', 'D697C1', 'F6AC5A', '80B26B'];

export const getColor = (i) => {
    if (i === 0) return colors[0];
    else if (i < colors.length - 1) return colors[i];
    else if (i === colors.length - 1) return colors[colors.length - 1]
    else if (i > colors.length - 1) return colors[i - (colors.length - 1) * (Math.floor(i / colors.length))]
}
let eventBus = undefined;
const KanbanBoard = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [tasksData, setTasksData] = useState({ custom_fields: [], lanes: [], boardOwners: [], stages: [] });
    const [copyLanes, setCopyLanes] = useState({ lanes: [] })
    const [duplicateLanes, setDulpicateLane] = useState({ lanes: [] })
    const [isEditable, setEditable] = useState(false);
    const [tableView, setTableView] = useState(null)
    const [tableData, setTableData] = useState([])
    const [isExportOpen, setExportOpen] = useState(false)
    const [exportAnchorEl, setExportAnchorEl] = React.useState(null);
    const classes = useStyles();
    const handleClickOpen = () => {
        props.openTaskDialog({ open: true, details: { _id: tasksData._id, name: tasksData.project_name } })
    };

    const hanleCardClick = (cardId, metadata, laneId) => {
        let cardObj = {};
        let cardsData = [...tasksData.lanes]
        cardsData.forEach((ele) => {
            if (ele.cards.length > 0 && ele.id === laneId) {
                cardObj = ele.cards.find(card => card._id === cardId);
                console.log(cardObj)
            }
        })
        props.history.push(`/product-management/${props.match.params.id}/board/${cardObj.task_id}`)
    }
    const updateTaskSuccessCB = (result) => {
        console.log(result);
        setTimeout(() => {
            getTasksList()

        })
        // handleTableData()
    }
    const updateTaskErrorCB = (e) => {
        console.log(e)
    }

    useEffect(() => {
        if (!props.newTask && props.userName) {
            props.setLoading(true);
            getTasksList()
        }
    }, [props.userName]);

    useEffect(() => {
        if (props.location.search) {
            let query = queryString.parse(props.location.search);
            setTableView(query.view === 'table' ? true : false);
        }
        else setTableView(false)
    }, [props.location.search]);

    useEffect(() => {
        if (props.newTask) {
            props.setLoading(true);
            getTasksList()
        }
        props.handleNewTask(null);
    }, [props.newTask]);

    const getTasksList = () => {
        props.getKanbandBoardData({
            params: { project_key: props.match.params.id },
            onSuccessCB,
            onErrorCB
        });
    }
    //Sucess callback 
    const onSuccessCB = (result) => {
        const CanDelete = (result.boardOwners && result.boardOwners.length > 0) ? result.boardOwners.some(owner => owner === props.userName) : false;
        props.projectDetails({ name: result.project_key, id: result._id, key: result.project_key });
        result.lanes.forEach((ele, index) => {
            console.log(ele)
            ele.project_id = result._id;
            ele.refKey = ele.key ? ele.key : ""
            ele.stageKey = ele.key ? `${index}-${ele.key}` : '';
            ele.label = `${ele.cards && ele.cards.length ? ele.cards.length : 0}`;
            ele.id = `${ele._id}`;
            ele.style = { width: 258, color: '#000', fontSize: '12px', backgroundColor: `#f5f5f5` }
            ele.titleStyle = { width: 258, justifyContent: "space-between", paddingLeft: 5, display: 'flex', alignItems: 'center', borderBottom: `solid 3px #${getColor(index)}` }
            ele.labelStyle = { color: '#000' };
            ele.cardStyle = { backgroundColor: '#ffffff', borderLeft: `solid 5px #${getColor(index)}` };
            ele.cards = ele.cards && ele.cards.length ? getSortedCards(ele) : [];
            ele.disallowAddingCard = true;
            ele.showDeleteIcon = CanDelete;
            delete ele.key
        })
        console.log(result)
        setDulpicateLane({ lanes: result.lanes })
        handleTableData(result);
        setTasksData(prevState => ({ ...prevState, ...result }));
        setEditable(CanDelete)
        props.setLoading(false);
        // setTimeout(()=>{
        // },1000)


    };
    const getBoardAccess = (owners) => {
        console.log(owners.some(owner => owner === props.userName), props.userName)
        if (owners.length > 0 && owners.some(owner => owner === props.userName)) {
            setEditable(true)
        }
        else setEditable(false);
    }
    const getSortedCards = (lane) => {
        if (lane.cards && lane.cards.length > 0) {
            lane.cards.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at)) ? -1 : ((new Date(b.created_at) > new Date(a.created_at)) ? 1 : 0));
            lane.cards = lane.cards.map((ele, i) => {
                ele.tableTargetDate = ele.target_date;
                ele.laneKey = lane.key ? lane.key : '';
                ele.id = ele._id;
                ele.leftBorderColor = `#${getColor(i)}`
                return ele;
            })
            console.log(lane.cards)
            return lane.cards
        }
        else return []
    }
    //Error Callback
    const onErrorCB = error => {
        console.log(error);
        props.setLoading(false);
        enqueueSnackbar(`Failed to fetch Borad Data.`, {
            variant: "error",
            preventDuplicate: true
        });
        // setLoading(false);
    };
    const handelDataChange = (changedData) => {
        console.log(changedData)
        changedData.lanes.forEach((ele, i) => {
            ele.stageKey = ele.refKey ? `${i}-${ele.refKey}` : '';
            ele.label = `${ele.cards && ele.cards.length ? ele.cards.length : 0}`;
            ele.disallowAddingCard = true;
            ele.id = ele._id ? ele._id : '';
            ele.style = { width: 258, color: '#000', fontSize: '12px', backgroundColor: `#f5f5f5` }
            ele.titleStyle = { width: 258, justifyContent: "space-between", paddingLeft: 5, display: 'flex', alignItems: 'center', borderBottom: `solid 3px #${getColor(i)}` }
            ele.labelStyle = { color: '#000' };
            ele.cardStyle = { backgroundColor: '#ffffff', borderLeft: `solid 5px #${getColor(i)}` };
            // ele.showDeleteIcon = isEditable;
            if (!ele.cards) ele['cards'] = [];
            // if (ele.cards && ele.cards.length) ele.cards.forEach(card => {  })
            // ele.cards = ele.cards && ele.cards.length ? getSortedCards(copyLanes.lanes[i]) : [];
        })
        setTasksData(prevState => ({ ...prevState, ...changedData }));
        setDulpicateLane({ lanes: changedData.lanes })
    }
    const handelDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        console.log("card", cardId, sourceLaneId, targetLaneId, position, cardDetails);
        if (sourceLaneId != targetLaneId) {
            let obj = { current_stage: cardDetails.laneId };
            props.updateTask({
                requestObj: obj, id: cardDetails._id,
                onSuccessCB: updateTaskSuccessCB,
                onErrorCB: updateTaskErrorCB
            })
        }
    };

    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        // arr.forEach((ele,ind)=>ele.sequence=ind+1)   
        return arr; // for testing
    }
    const handleLaneDrage = (removedIndex, addedIndex, payload) => {
        let newLaneData = [...tasksData.lanes];
        let copyLanes = JSON.parse(JSON.stringify(duplicateLanes.lanes))
        if (removedIndex === 0 || addedIndex === 0 || payload.stageKey.includes('TODO')) {
            eventBus.publish({ type: 'UPDATE_LANES', lanes: copyLanes })
            enqueueSnackbar(`First lane can only be renamed`, {
                variant: "error",
                preventDuplicate: true
            });
        }
        else if (copyLanes.length - 1 === removedIndex || copyLanes.length - 1 === addedIndex || payload.stageKey.includes('DONE')) {
            eventBus.publish({ type: 'UPDATE_LANES', lanes: copyLanes })
            enqueueSnackbar(`Last lane can only be renamed`, {
                variant: "error",
                preventDuplicate: true
            });
        }
        else {
            eventBus.publish({ type: 'UPDATE_LANES', lanes: array_move(newLaneData, removedIndex, addedIndex) })
            let lanes = JSON.parse(JSON.stringify(newLaneData));
            lanes = lanes.map((lane, ind) => {
                return {
                    _id: lane._id ? lane._id : '',
                    label: lane.title,
                    sequence: ind + 1,
                    key: lane.stageKey ? lane.stageKey.split('-')[lane.stageKey.split('-').length - 1] : ''
                }
            })
            let obj = { stages: lanes }
            props.changeLanePositions({
                requestObj: obj, id: tasksData._id, onSuccessCB: (data) => {
                    console.log(data)
                }, onErrorCB: (error) => {
                    console.log(error)
                }
            })
        }

    }
    const setEventBus = (handle) => {
        eventBus = handle
    }

    const getCustomData = (obj, custom_fields) => {
        let row = {}
        if (custom_fields.length > 0) {
            custom_fields.filter(ele => ele.placeholder === 'context').forEach(field => {
                if (field.type === 'date') {
                    console.log(obj && obj[field.fieldKey] ? new Date(obj[field.fieldKey]).getTime() : "")
                    row[field.fieldKey] = obj && obj[field.fieldKey] ? new Date(obj[field.fieldKey]).getTime() : "";
                }
                else if (field.type === 'number') {
                    row[field.fieldKey] = obj && obj[field.fieldKey] ? Number(obj[field.fieldKey]) : null;
                }
                else row[field.fieldKey] = obj && obj[field.fieldKey] ? obj[field.fieldKey] : "";
            })
        }
        return row;
    }
    const handleTableData = (tableData) => {
        let row = {};
        if (tableData.lanes.some(ele => ele.cards.length > 0)) {
            let data = [];
            tableData.lanes.forEach(ele => {
                if (ele.cards.length > 0) {
                    ele.cards.forEach((card) => {
                        row = {};
                        row['task_id'] = card.task_id;
                        row["Id"] = Number(card.task_id.split("-")[1]);
                        row['title'] = card.title;
                        row['task_type'] = card.task_type;
                        row['status'] = ele.title;
                        row['ageing'] = getAgeing(card.target_date);
                        row['targetDate'] = card.tableTargetDate ? moment(card.tableTargetDate).format('DD-MM-YYYY') : '';
                        row['sortableDate'] = new Date(card.tableTargetDate).getTime();
                        row['priority'] = card.priority;
                        row['reporterPhoto_url'] = card.reporterPhoto_url;
                        row['reporterName'] = card.reporterName;
                        row['assigneePhoto_url'] = card.assigneePhoto_url;
                        row['assigneeName'] = card.assigneeName;
                        if (props.orgDetails?.theme?.view === 'PWC') {
                            row['doctorName'] = card.custom_fields.doctor_name
                            row['speciality'] = card.custom_fields.speciality
                            row['dateOfVisit'] = card.custom_fields.date_of_visit
                            row['comments'] = card.custom_fields.comments
                            row['lastVisit'] = card.custom_fields.last_6_visits
                        }









                        row = { ...row, ...getCustomData(card.custom_fields, tableData.custom_fields || []) }
                        data.push(row)
                    })
                }
            })
            setTableData([...data])
        }

    }
    const getPriority = (no) => (
        <span >
            {
                no === 2 ?
                    <p style={{ display: 'flex' }}><ArrowUpwardIcon className={classes.priorityHigh} /> High</p> :
                    no === 1 ?
                        <p style={{ display: 'flex' }}><ArrowUpwardIcon className={classes.priorityMedium} /> Medium</p> :
                        <p style={{ display: 'flex' }}><ArrowDownwardIcon className={classes.priorityLow} />Low </p>
            }
        </span>);

    let columnPwc = [
        {
            title: "Doctor Name",
            field: "doctorName",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    <Avatar
                        id="assigneeNameImage"
                        classes={{ colorDefault: classes.avatarStyles }}
                        style={{ height: 30, width: 30, marginRight: 5 }}
                        src={rowData.assigneePhoto_url || ''}
                    >
                        {(rowData.doctorName && getAvatarText(rowData.doctorName))}
                    </Avatar>
                    {rowData.doctorName}
                </div>
            )
        },
        {
            title: "Speciality",
            field: "speciality",
            render: (rowData) => (
                <div className={classes.nameRoot}>

                    {rowData.speciality}
                </div>
            )

        },

        {
            title: "Date Of Visit",
            field: "dateOfVisit",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {rowData.dateOfVisit}
                </div>
            )
        },
        {
            title: "Comments",
            field: "comments",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {rowData.comments}
                </div>
            )
        },
        {
            title: "Last 6 Visits",
            field: "lastVisit",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {rowData.lastVisit}
                </div>
            )
        },
    ]

    let column = [
        {
            title: "WorkItem Id",
            field: "Id",
            cellStyle: (e, rowData) => {
                return {
                    borderLeft: `solid 5px #${getColor(tasksData.lanes.findIndex(ele => ele.title.toLowerCase() === rowData.status.toLowerCase()))}`
                }
            },
            render: (rowData) => (
                <div className={classes.nameRoot} style={{}}>
                    {rowData.task_id}
                </div>
            )
        },
        {
            title: "Assignee",
            field: "assigneeName",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    <Avatar
                        id="assigneeNameImage"
                        classes={{ colorDefault: classes.avatarStyles }}
                        style={{ height: 30, width: 30, marginRight: 5 }}
                        src={rowData.assigneePhoto_url || ''}
                    >
                        {(rowData.assigneeName && getAvatarText(rowData.assigneeName))}
                    </Avatar>
                    {rowData.assigneeName}
                </div>
            )
        },
        {
            title: "Reporter",
            field: "reporterName",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    <Avatar
                        id="assigneeNameImage"
                        classes={{ colorDefault: classes.avatarStyles }}
                        style={{ height: 30, width: 30, marginRight: 5 }}
                        src={rowData.reporterPhoto_url || ''}
                    >
                        {(rowData.reporterName && getAvatarText(rowData.reporterName))}
                    </Avatar>
                    {rowData.reporterName}
                </div>
            )

        },

        {
            title: "Title",
            field: "title",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {getEllipsisText(rowData.title, 30)}
                </div>
            )
        },
        {
            title: "Priority",
            field: "priority",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {getPriority(rowData.priority)}
                </div>
            )
        },
        {
            title: "Type",
            field: "task_type",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {rowData.task_type}
                </div>
            )
        },
        {
            title: "Age",
            field: "sortableDate",
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {rowData.ageing}
                </div>
            )
        },
        {
            title: "Status",
            field: "status",
            render: (rowData) => (
                <div className={classes.nameRoot} style={{ width: 'fit-content', backgroundColor: `#${getColor(tasksData.lanes.findIndex(ele => ele.title.toLowerCase() === rowData.status.toLowerCase()))}`, whiteSpace: 'nowrap', borderRadius: 4, alignItems: 'center', padding: '0px 3px' }}>
                    {rowData.status}
                </div>
            )
        },
        {
            title: "Target Date",
            field: "sortableDate",
            cellStyle: { minWidth: 150 },
            render: (rowData) => (
                <div className={classes.nameRoot}>
                    {rowData.targetDate}
                </div>
            )
        }
    ]
    tasksData && tasksData.custom_fields.length > 0 && tasksData.custom_fields.filter(ele => ele.placeholder === 'context').map(field => {
        let obj = {
            title: field.label,
            field: field.fieldKey,
        }
        if (field.type === 'date') {
            obj = {
                ...obj, cellStyle: { minWidth: 150 },
                render: (rowData) => (
                    <div className={classes.nameRoot}>
                        {rowData[field.fieldKey] != "" ? moment(rowData[field.fieldKey]).format('DD-MM-YYYY') : ""}
                    </div>
                )
            }
        }
        else {
            obj = {
                ...obj,
                render: (rowData) => (
                    <div className={classes.nameRoot}>
                        {rowData[field.fieldKey]}
                    </div>
                )
            }
        }
        column.push(obj)
    })
    const exportSuccessCB = (data) => {
        const dirtyFileName = data.headers['content-disposition'];
        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
        var fileName = dirtyFileName.match(regex)[2];
        var blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, fileName);
        enqueueSnackbar(`Exported Successfully`, {
            variant: "success",
            preventDuplicate: true
        });
    }
    const exportErrorCB = () => {
        enqueueSnackbar(`Failed to export`, {
            variant: "error",
            preventDuplicate: true
        });
    }
    const handleExportMenuOpen = (event) => {
        setExportOpen(true)
        setExportAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = () => {
        setExportOpen(false)
        setExportAnchorEl(null);
    };
    const handleExportExcel = (type) => {
        setExportOpen(false)
        console.log(tasksData._id, '----------', type)
        if (type === 'aging') {
            props.exportTasksAging({ boardId: tasksData._id, onSuccessCB: exportSuccessCB, onErrorCB: exportErrorCB })
        }
        else if (type === 'status') {
            props.exportTaskStatusAson({ boardId: tasksData._id, onSuccessCB: exportSuccessCB, onErrorCB: exportErrorCB })
        }

    }
    const handleUpdateLaneTitle = (title) => {
        console.log("title")
    }
    // const deletecard = (callback) => {
    //     callback()
    //     console.log(callback)
    // }
    const cardDeleted = (cardId, laneId) => {
        props.deleteTask({ id: cardId, onSuccessCB: deleteSuccessCB, onErrorCB: onDeleteErrorCB });
        console.log("card deleted", cardId, laneId)
    }
    const deleteSuccessCB = (result) => {
        enqueueSnackbar(`Task deleted`, {
            variant: "success",
            preventDuplicate: true
        });
        console.log(result)
    }
    const handelLaneAdd = (lane) => {
        console.log(lane);
        getTasksList()
    }
    const onDeleteErrorCB = (error) => {
        console.log(error)
    }
    const manageViews = () => {
        const query = {
            view: tableView ? "board" : "table",
        };
        const viewString = queryString.stringify(query);
        props.history.push({
            pathname: `/product-management/${props.match.params.id}/board`,
            search: viewString
        });
        // setTableView(prevState=> !prevState)
    }
    const renderExportOptions = (
        <Menu
            anchorEl={exportAnchorEl}
            classes={{ paper: classes.profilePopup }}
            id="export-options-menu"
            keepMounted
            open={isExportOpen}
            onClose={handleExportMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{ zIndex: 1301 }}
        >
            <MenuItem onClick={() => handleExportExcel('aging')} className={classes.exportOptionStyles} >Ageing Report</MenuItem>
            <MenuItem onClick={() => handleExportExcel('status')} className={classes.exportOptionStyles} >Status Report</MenuItem>
        </Menu>
    );
    const FilterByAssignes = ({ members }) => {
        return (
            <AvatarGroup max={4}>
                {
                    members && members.map(member => {
                        return (
                            <Avatar alt={member.name} src={member.photo_ulr} />

                        )

                    })
                }

            </AvatarGroup>
        )
    }
    return (
        <div className={classes.kanbanAlign}>
            <div style={{ marginBottom: '-15px' }}>
                <Breadcrumb component='board' projectName={tasksData.project_name} history={props.history} params={props.match.params} />
            </div>
            <div className={classes.buttonAndHeading}>
                <p className={classes.headingStyles}>{tasksData && tasksData.project_name}</p>
                <div>
                    {/* <FilterByAssignes members={}/> */}
                </div>
                <div className={classes.buttonAlign}>
                    <Tooltip title="Export to Excel"><Button
                        variant="contained"
                        // color="primary"
                        className={classes.exportButton}
                        onClick={(e) => handleExportMenuOpen(e)}
                    >
                        <GetAppIcon /></Button>
                    </Tooltip>
                    {renderExportOptions}
                    <Button
                        variant="contained"
                        // color="primary"
                        className={classes.exportButton}
                        onClick={manageViews}
                    >
                        {tableView ?
                            <Tooltip title="Board View"><TableChartIcon ></TableChartIcon></Tooltip>
                            : <Tooltip title="Table View"><ViewListIcon></ViewListIcon></Tooltip>}
                    </Button>
                    <Tooltip title="Create"><Button className={classes.exportButton} variant="contained" color="primary" onClick={handleClickOpen} >
                        <span style={{ fontSize: "xx-large" }}>+</span>
                    </Button></Tooltip>
                </div>
            </div>
            <div >
                {tableView ?
                    <div className={classNames("material-table", classes.backgroundColor)} >

                        <MaterialTable title="List Of Tasks"
                            columns={props.orgDetails?.theme?.view === 'PWC' ? columnPwc : column}
                            data={tableData}
                            options={{
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                emptyRowsWhenPaging: false,
                                // search: false,
                                // paging: false
                            }}

                            onRowClick={(e, rowData) => props.history.push(`/product-management/${props.match.params.id}/board/${rowData.task_id}`)
                            }
                        //  components={{
                        //     Pagination: props => <TablePagination {...props} rowsPerPageOptions={[5, 10, 25]} />
                        //   }}
                        >
                        </MaterialTable>
                    </div>
                    :
                    <Board data={tasksData}
                        onDataChange={handelDataChange}
                        onCardClick={hanleCardClick}
                        draggable={isEditable}
                        editable={isEditable}
                        editLaneTitle={isEditable}
                        // hideCardDeleteIcon={false}
                        // eventBusHandle={handleEvent}
                        handleDragEnd={handelDragEnd}
                        // onBeforeCardDelete={deletecard}
                        handleLaneDragEnd={handleLaneDrage}
                        onCardDelete={cardDeleted}
                        onLaneAdd={handelLaneAdd}
                        eventBusHandle={setEventBus}
                        updateTitle={handleUpdateLaneTitle}
                        canAddLanes={isEditable}
                        components={{ Card: Card, LaneHeader: LaneHeaderComponent, ScrollableLane: ScrollableLane, NewLaneSection: NewLaneSection, NewLaneForm: NewLane, Loader: Loader }}
                        labelStyle={{ color: 'blue' }}
                        style={{ backgroundColor: "#ffffff", overflowX: 'auto', width: "100%", height: "70vh" }} />


                }
            </div>
        </div>
    )
}
const mapStateToProps = (state) => (
    {
        dialogDetails: state.pms.taksDialog,
        breadcrumbs: state.pms.breadcrumbs,
        userName: state.user.username,
        orgDetails: state.common.orgDetails,

    })
const mapDispatchToProps = (dispatch, props) => {
    return {
        getKanbandBoardData: (options) => dispatch(getKanbandBoardData(options)),
        projectDetails: (options) => dispatch(projectDetails(options)),
        setLoading: value => dispatch(setLoading(value)),
        openTaskDialog: (options) => dispatch(openTaskDialog(options)),
        updateTask: (options) => dispatch(updateTask(options)),
        deleteTask: (options) => dispatch(deleteTask(options)),
        exportTaskStatusAson: (options) => dispatch(exportTaskStatusAson(options)),
        exportTasksAging: (options) => dispatch(exportTasksAging(options)),
        changeLanePositions: (options) => dispatch(changeLanePositions(options))

    }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(KanbanBoard);

