import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableHead, TableRow, MenuItem, Menu, IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import { downloadFiles, getCurrencyFormat, getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort, setSearchQuery } from "../../helpers";
import { getMyTeamLivePerformance, getRoundTeamStatistics, getTeamRaceLiveScore, shareLiveScoreDialog } from "../../ducks/game";
import IllustrationView from "./IllustrationView";
import { ArrowDownward, ArrowForward, Email, Share } from "@material-ui/icons";
import { useRouteMatch, withRouter } from "react-router-dom";
import { GetDates } from ".";
import { toJpeg } from "html-to-image";
import PerformanceTableTemplate from "../GlobalComponents/PerformanceTableTemplate";
import PerformanceTableEmailTemplate from "../GlobalComponents/PerformanceTableEmailTemplate";
import './TablesLayout.css';
import LiveScoresMuiTable from "./LiveScoresMuiTable";
import { isRuleBasedOnActuals } from "./RaceSummary";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "./CustomSwitch";
import { resizeImageToSquare } from "../ArenaComponents/cropImage";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import { ReactComponent as ReactArenaLogo } from "../../assets/icons/arena-1.svg";
import { openCreateArenaDialog } from '../../ducks/arena';
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";






const useStyles = makeStyles((theme) => ({
    root: {
        // margin: '15px 0 0px',
        minHeight: 285,
        marginBottom: 20
        // '& .material-table td div': {
        //     border: 'none !important'
        // }
    },
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12
    },
    title: {
        fontSize: 18,
        // margin: '25px 0 0',
        margin: 0,
    },
    button: {
        // height: 47,
        margin: '0 10px',
        '&:last-child': {
            marginRight: 0
        }
    },
    viewAllButton: {
        margin: '0 10px',
        color: 'blue', // #112386
        fontSize: 11,
        float: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        // fontWeight: 600,
        '& svg': {
            fontSize: 14,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    radioGroup: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .viewByText': {
            fontWeight: 600,
            marginRight: 10,
            fontSize: 12
        },
        '& label': {
            height: 30,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    radioLabel: {
        '& span': {
            fontSize: 12
        }
    },
    avatarStyles: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
    },
    iconDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    shareOptionStyles: {
        fontSize: 14,
        '& #share-to-wall': {
            width: 20,
            height: 20,
            marginRight: 10,
            // '& path': {
            //     fill: theme.palette.primary.main
            // },
            // '& path[fill="white"]': {
            //     fill: '#FFF',
            // },
            '& path': {
                stroke: theme.palette.primary.main
            }
        },
        '& #download': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        },
        '& #Email': {
            width: 20,
            height: 20,
            marginRight: 10,
            '& path': {
                fill: theme.palette.primary.main
            },
            '& path[fill="white"]': {
                fill: '#FFF',
            }
            // '& path': {
            //     stroke: theme.palette.primary.main
            // },
            // '& path[fill]': {
            //     fill: theme.palette.primary.main,
            // },
        }
    },
}));

const TeamSummaryDivisionWise = ({ key, index, limit, type = "", keycloak, seasonFormat, divisionObj = {}, filtersData, getRoundTeamStatistics, getTeamRaceLiveScore, shareLiveScoreDialog, setLoading, userDetails, loading, dialog, history, decimalView, openCreateArenaDialog, setDecimalView = () => { }, uploadLogDate, seasonKpiPostions }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [canDownload, setCanDownload] = useState(false);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [shareAnchorEl, setShareAnchorEl] = React.useState({
        el: null,
        arena: null
    });
    const columns = [
        {
            title: "Position",
            field: "position",
            emptyValue: '--',
            editable: 'never',
            cellStyle: { maxWidth: 70, width: 70 },
            // render: (rowData) => <span style={{ fontSize: 20 }}>{rowData.position}</span>
        },
        {
            title: "Team",
            field: "team",
            editable: "onAdd",
            cellStyle: { minWidth: 200 },
            emptyValue: '--',
            hidden: (seasonFormat === 'PLAYER_RACE'),
            editable: 'never',
            render: (rowData) => rowData.team ? (
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={rowData.team?.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 8 }} filtersData={filtersData} />
                    </div>
                    <div style={{ textAlign: 'left', fontWeight: 600 }}>
                        {rowData.team && rowData.team.name}
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 9, fontWeight: 400 }}>
                            {rowData.captain && <span style={{ marginRight: 10 }}>C : {rowData.captain}</span>}
                            {rowData.mentor && <span>M : {rowData.mentor}</span>}
                        </div>
                    </div>
                </div>
            ) : '--',
            templateRender: (rowData) => rowData.team ? (
                <div >
                    {rowData.team && rowData.team.name}
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap' }}>
                        {rowData.captain && <span><span style={{ fontWeight: 600 }}>C</span> : {rowData.captain}</span>}
                        {rowData.mentor && <span style={{ marginLeft: 10 }}><span style={{ fontWeight: 600 }}>M</span> : {rowData.mentor}</span>}
                    </div>
                </div>
            ) : '--',
        },
        {
            title: "Score",
            field: "totalPoints",
            hidden: filtersData?.season?.season_format !== 'HEAD_TO_HEAD',
            editable: 'never',
            cellStyle: { minWidth: 100 },
            render: (rowData) => <span style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</span>
        },
        {
            title: "Points",
            field: "totalPoints",
            editable: 'never',
            hidden: filtersData?.season?.season_format === 'HEAD_TO_HEAD',
            cellStyle: { minWidth: 100 },
            render: (rowData) => <span style={{ fontWeight: 600 }}>{getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)}</span>
        }
    ]
    const isShareMenuOpen = Boolean(shareAnchorEl.el);
    // const [decimalView, setDecimalView] = useState(false);
    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');

    useEffect(() => {
        if (filtersData.round) {
            if (index === 0) setLoading(true);
            getRoundTeamStatistics({ roundId: filtersData.round?._id, onSuccessCB: type === 'DETAILED' ? onSuccessCBDetailed : onSuccessCB, onErrorCB, params: { division_id: divisionObj._id, limit: limit, page: 1 } })
        }
    }, [filtersData.round?._id]);

    useEffect(() => {
        if (filtersData?.match && filtersData.season?.season_format === 'TEAM_RACE') {
            setLoading(true);
            getTeamRaceLiveScore({ matchId: filtersData.match?._id, onSuccessCB: type === 'DETAILED' ? onSuccessCBDetailed : onSuccessCB, onErrorCB, params: { division_id: divisionObj._id, limit: limit, page: 1 } })
        }
    }, [filtersData.match?._id]);

    useEffect(() => {
        if (resData.response?.length > 0) {
            if (type === 'DETAILED')
                onSuccessCBDetailed(resData.response, resData.headers, true);
            else
                onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCBDetailed = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_score || item.total_points || 0, selected: item._id === resheaders?.my_team_id, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'detailed_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id > b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.score;
                    if (index === 0) {
                        const mapped_headers = el.detailed_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // hidden: isRuleBasedOnActuals(el),
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('TARGET'),
                            // cellStyle: { color: '#333' },
                            render: (rowData) =>
                                (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`

                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])
                        }, {
                            title: `${el.kpi_code || el.kpi_name}`, //-Actual
                            field: `${el.kpi_id}-actual`,
                            kpiname: `${el.kpi_name}`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                            // getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            attribute: el?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: el.detailed_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 9 },
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),

                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj._id } }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData(finalResult);
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
            }
        }
        setLoading(false);
    }


    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response.length) {
            let headers = [];
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_score, selected: item._id === resheaders?.my_team_id, hilighted: index === 0, kpis_attributes: seasonKpiPositionsSort(item.kpis, seasonKpiPostions, 'kpi_id', 'summary_hidden'), kpis: item.kpis.sort((a, b) => (a.kpi_id > b.kpi_id) ? 1 : -1) };
                obj.kpis_attributes.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = ((seasonFormat === 'TEAM_RACE') ? el.score || 0 : el.points || 0);
                    // obj['totalPoints'] = totalPoints += el.score;
                    if (index === 0) {
                        const mapped_headers = el.summary_attributes.map(ele => { return ele.attribute });
                        el['mapped_headers'] = mapped_headers;
                        headers.push(el);
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // hidden: isRuleBasedOnActuals(el),
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('TARGET'),
                            // cellStyle: { color: '#333' },
                            render: (rowData) =>
                                (['SR4', 'SR5', 'SR6'].includes(el.scoring_rule) && el.based_on === 'ACTUALS') ? 'NA' :
                                    rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-target`]} />
                                        : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-target`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-target`])
                                            : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        }, {
                            title: `${el.kpi_code || el.kpi_name}`,
                            kpiname: `${el.kpi_name}`,
                            field: `${el.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-actual`]} />
                                        // getCurrencyFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-unit`], rowData[`${el.kpi_id}-actual`])
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        }, {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-achieved`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_target`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_target`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_target`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_target`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            // cellStyle: { color: '#333' },
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${el.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                    <GetCountryCurrencyFormat type="currency" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                    : rowData[`${el.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                        <GetCountryCurrencyFormat type="number" value={rowData[`${el.kpi_id}-planned_actual`]} />
                                        : `${getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_actual`])} ${rowData[`${el.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${el.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${el.kpi_id}-planned_actual`], rowData[`${el.kpi_id}-unit`]) : ''}`
                        },
                        {
                            title: `${el.kpi_name}`,
                            field: `${el.kpi_id}-planned_achievement`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            attribute: el?.summary_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 9 },
                            hidden: (!el.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-planned_achievement`]) + ' %'
                        },
                        {
                            title: `${el.kpi_name}`,//-Points
                            field: `${el.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: el.summary_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 9 },
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData[`${el.kpi_id}-points`], decimalView)
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),

                        ]
                    }
                });
                return [...result, { ...obj, team: { name: (obj.name || obj.team_name), logo_url: (obj.logo_url || obj.team_logo_url), _id: obj._id } }];
            }, []);
            if (finalResult.length > 0) {
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
                setData([...finalResult])
            }
            else {
                setDynamicColumns([]);
                setDynamicHeaders([]);
                setData([]);
            }
        }
        else {
            setDynamicColumns([]);
            setDynamicHeaders([]);
            setData([]);
        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setDynamicColumns([]);
        setDynamicHeaders([]);
        setData([]);
        setLoading(false);
        // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
        //     variant: 'error',
        //     preventDuplicate: true
        // });
    }

    const SummaryTable = ({ data }) => (
        <LiveScoresMuiTable
            columns={[...columns, ...dynamicColumns]}
            data={data}
            options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
            }}
            components={{
                Header: props => (
                    <TableHead className="custom-headers">
                        <TableRow>
                            {
                                filtersData.season?.season_format === 'TEAM_RACE' ?
                                    <>
                                        <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[1]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[3]?.title}</TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell rowSpan={2}>{columns[0]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[1]?.title}</TableCell>
                                        <TableCell rowSpan={2}>{columns[2]?.title}</TableCell>
                                    </>
                            }

                            {
                                dynamicHeaders.map((obj, index) => (
                                    <>
                                        {type === 'DETAILED' ?
                                            <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                            </TableCell>
                                            :
                                            <TableCell colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                <Tooltip title={obj.kpi_name}><span>{obj.kpi_name}</span></Tooltip>
                                            </TableCell>
                                        }
                                    </>
                                ))
                            }
                        </TableRow>
                        {
                            type === 'DETAILED' ?
                                <TableRow>
                                    {
                                        dynamicHeaders.map((obj) => (
                                            <>
                                                {
                                                    filtersData.season?.season_format === 'TEAM_RACE' ?
                                                        <>
                                                            {
                                                                obj?.detailed_attributes.length ?
                                                                    <>
                                                                        {
                                                                            obj?.detailed_attributes.map(ele => (
                                                                                ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                            ))
                                                                        }
                                                                        {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" >Actual</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                obj?.detailed_attributes.length ?
                                                                    <>
                                                                        {
                                                                            obj?.detailed_attributes.map(ele => (
                                                                                ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" >Actual</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </>
                                        ))
                                    }
                                </TableRow>
                                :
                                <TableRow>
                                    {
                                        dynamicHeaders.map((obj) => (
                                            <>
                                                {
                                                    filtersData.season?.season_format === 'TEAM_RACE' ?
                                                        <>
                                                            {
                                                                obj?.summary_attributes.length ?
                                                                    <>
                                                                        {
                                                                            obj?.summary_attributes.map(ele => (
                                                                                ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                            ))
                                                                        }
                                                                        {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" >Actual</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                obj?.summary_attributes.length ?
                                                                    <>
                                                                        {
                                                                            obj?.summary_attributes.map(ele => (
                                                                                ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" >Actual</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </>
                                        ))
                                    }
                                </TableRow>
                        }
                    </TableHead>
                )
            }}
        />
    );
    const handleViewAll = (divisionId) => {
        if (filtersData?.season?.divisions_enabled == true) {
            setSearchQuery(history, { division: divisionId });
        }
        history.push(`${path}/all-team-performances${history.location?.search}`);
    };

    const handleDetailedScore = (divisionId) => {
        if (filtersData?.season?.divisions_enabled == true) {
            setSearchQuery(history, { division: divisionId });
        }
        history.push(`${path}/detailed-team-performances${history.location?.search}`);
    };

    const handleShareScore = (id) => {
        shareLiveScoreDialog({ open: true, fixture: 'ROUND', notify: 'TEAM', details: { seasonId: filtersData.season?._id, roundId: filtersData.round?._id, divisionId: divisionObj._id, imageId: id, historyDetails: history }, filtersData: filtersData });
        handleShareMenuClose();
    }

    const handleDownloadPerformance = async (id, title, type, divisionObj) => {
        await setCanDownload(true);
        toJpeg(document.getElementById(id))
            .then((dataUrl) => {
                setCanDownload(false);

                try {
                    const img = new Image();
                    img.onload = async function () {
                        let high = (this.width > this.height) ? 'width' : 'height';
                        const resultImage = (this.width > 0 && this.height > 0) ? await resizeImageToSquare(dataUrl, { width: this[high], height: this[high] }) : null;
                        if (resultImage) {
                            if (type === 'download') {
                                downloadFiles(resultImage, title.replace(/\./g, ' '));
                                handleShareMenuClose();
                            }
                            else {
                                openCreateArenaDialog({
                                    open: true, arena: {}, stepType: 'crop', attachment: [
                                        { url: resultImage, mimeType: "IMAGE", width: 4, height: 4, fileName: "kpi_performance_HHD-49_kpi_ck_bk -2022-08-18T133448.437.png", fileType: "image/png" }],
                                    postDetails: {
                                        targetPage: "TEAM_LIVE_SCORES",
                                        details: {
                                            league: filtersData?.league?._id,
                                            season: filtersData?.season?._id,
                                            round: filtersData?.round?._id,
                                            match: filtersData?.match?._id,
                                            division: divisionObj._id,

                                        }
                                    }
                                });
                                handleShareMenuClose();
                            }
                        }
                    }
                    img.src = dataUrl;
                    return img.onload();
                } catch (e) {
                    console.error('index', e)
                }
            })
    }

    const handleShareMenuOpen = (event) => {
        setShareAnchorEl({ el: event.currentTarget, arena: null });
    };

    const handleShareMenuClose = () => {
        setShareAnchorEl({ el: null, arena: null });
    };
    const renderShareOptions = (

        <Menu
            anchorEl={shareAnchorEl.el}
            classes={{ paper: classes.profilePopup }}
            id="share-options-menu"
            keepMounted
            open={isShareMenuOpen}
            onClose={handleShareMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            style={{ zIndex: 1301 }}
        >
            <MenuItem onClick={() => handleDownloadPerformance(`team-perfomances-table-${divisionObj?.name}`, `Team_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : filtersData.match?.title}_${divisionObj?.name}`, 'download', divisionObj)} className={classes.shareOptionStyles} ><GetAppOutlinedIcon id="download" />Download</MenuItem>
            <MenuItem onClick={() => handleDownloadPerformance(`team-perfomances-table-${divisionObj?.name}`, `Team_performance_${filtersData.season?.uid}_${filtersData.round ? filtersData.round?.name : filtersData.match?.title}_${divisionObj?.name}`, 'share', divisionObj)} className={classes.shareOptionStyles} ><ReactArenaLogo id="share-to-wall" />Share to Wall</MenuItem>
            <MenuItem onClick={() => handleShareScore(`team-perfomances-table1-${divisionObj?.name}`)} className={classes.shareOptionStyles}><MailOutlineOutlinedIcon id="Email" />Email </MenuItem>
        </Menu>
    );
    const teamEmailTemplateColumns = columns.filter((obj, i) => filtersData?.season?.season_format === "HEAD_TO_HEAD" ? obj.title !== 'Points' : obj.title !== 'Score')


    return (
        <div>
            {
                <div key={key} className={classes.root}>
                    <p className="table-title decimal-switch-root">
                        <p>
                            <span>(Division : {divisionObj?.name}) </span><span>{(!filtersData.round?._id && filtersData.match?.type === 'PLAYER_SPRINT') ? 'Player' : 'Team'} performance : <span>{filtersData.round?._id ? filtersData.round?.name : filtersData.match?.title}</span><GetDates filtersData={filtersData} /></span>
                        </p>
                        {index === 0 && <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />}
                    </p>
                    {isAdmin && type === 'TOPFIVE' && data.length > 0 && <div className={classes.iconDiv}>
                        <IconButton onClick={(e) => handleShareMenuOpen(e,)} >
                            <ShareSharpIcon fontSize="small" />
                        </IconButton>
                    </div>}
                    {renderShareOptions}
                    {
                        (data.length === 0 && index === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                            <SummaryTable data={data} />

                    }
                    {
                        type === 'TOPFIVE' && <>
                            {
                                shareAnchorEl.el &&
                                <>
                                    <PerformanceTableTemplate
                                        id={`team-perfomances-table-${divisionObj?.name}`}
                                        belongsTo='Teams'
                                        filtersData={filtersData}
                                        columns={columns}
                                        dynamicColumns={dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')}
                                        data={data}
                                        divisionName={divisionObj?.name}
                                        render={(canDownload || dialog?.open) && limit === 5}
                                        uploadLogDate={uploadLogDate}
                                        isUploadLogRequired={true}

                                    />
                                    <PerformanceTableEmailTemplate
                                        id={`team-perfomances-table1-${divisionObj?.name}`}
                                        belongsTo="Teams"
                                        filtersData={filtersData}
                                        columns={[...teamEmailTemplateColumns, ...dynamicColumns.filter(obj => obj.field.split("-")[1] === 'actual')]}
                                        data={data}
                                        divisionName={divisionObj?.name}
                                        divisionObj={divisionObj}
                                        render={(canDownload || dialog?.open) && limit === 5}
                                        isUploadLogRequired={true}

                                    />
                                </>
                            }
                            {(data.length > 0 && limit === 5) &&
                                <p className="table-footer">
                                    <span className={classes.viewAllButton} onClick={() => handleDetailedScore(divisionObj._id)}>View Detailed score<ArrowForward /></span>
                                    <span className={classes.viewAllButton} onClick={() => handleViewAll(divisionObj._id)}>View all<ArrowForward /></span>
                                </p>
                            }

                        </>
                    }
                </div>

            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
    keycloak: state.keycloak,
    dialog: state.game.liveScoresShare,
    seasonKpiPostions: state.game.seasonKpiPostions

});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getRoundTeamStatistics: options => dispatch(getRoundTeamStatistics(options)),
        getTeamRaceLiveScore: options => dispatch(getTeamRaceLiveScore(options)),
        shareLiveScoreDialog: options => dispatch(shareLiveScoreDialog(options)),
        getMyTeamLivePerformance: (options) => dispatch(getMyTeamLivePerformance(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamSummaryDivisionWise));
