import React from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { CustomListGroup } from '../LiveScores/RoundsListView';
import { useState } from 'react';
import TeamPerformance from './TeamPerformance';
import TeamMatches from './TeamMatches';
import TeamMatchTab from './TeamMatches/TeamMatchTab';
import { getSearchValue, setSearchQuery } from '../../helpers';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 20,
        paddingBottom: '50px'
    },
}))

const MenuOptions = [
    { label: 'Performance', value: 0, pathname: 'performance' },
    { label: 'Matches', value: 1, pathname: 'matches' },
];



const ProfileDetail = ({ seasonId, history, location, filtersData, teamId, setFiltersData, defaultFilters, loading, setLoading }) => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const { path } = useRouteMatch();
    const { params } = useRouteMatch();



    const handleTabChange = (newValue) => {
        setCurrentTab(newValue?.value);

    }


    return (
        <div className={classes.root}>
            <div>
                <CustomListGroup data={MenuOptions} selectedItem={MenuOptions.find((option) => option.value === currentTab)} onClick={handleTabChange} style={{ maxWidth: 'max-content', marginLeft: 0 }} />

                {currentTab === 0 ?
                    <TeamPerformance seasonId={seasonId} filtersData={filtersData} teamId={teamId} setFiltersData={setFiltersData} defaultFilters={defaultFilters} loading={loading} setLoading={setLoading} />
                    :

                    <TeamMatches seasonId={seasonId} filtersData={filtersData} teamId={teamId} setFiltersData={setFiltersData} defaultFilters={defaultFilters} loading={loading} setLoading={setLoading} />}

            </div>
        </div>
    )
}

export default withRouter(ProfileDetail);
