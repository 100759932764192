import { all, call, takeEvery, put, select } from "redux-saga/effects";
import Axios from "axios";
import {
  GET_MEETINGS,
  setMeetingsList,
  CREATE_SCHEDULED_MEETING,
  GET_MEETING_DETAILS,
  UPDATE_MEETING_STATUS,
  UPDATE_SCHEDULED_MEETING,
  DELETE_MEETING,
} from "../../ducks/meetings";
import {
  GET_INDIVIDUAL_MEETING_API,
  GET_MEETINGS_LIST_API,
  UPDATE_MEEETING_STATUS_API,
  UPDATE_SCHEDULED_MEETING_API,
} from "../../config";

const createScheduledMeetingRequest = (payload) => {
  return Axios.post(GET_MEETINGS_LIST_API, payload);
};

const updateScheduledMeetingRequest = (id, payload) => {
  return Axios.put(UPDATE_SCHEDULED_MEETING_API(id), payload);
};

const getMeetingsListRequest = (params) => {
  return Axios.get(GET_MEETINGS_LIST_API, { params });
};

const getIndividualMeetingRequest = (meetingId) => {
  return Axios.get(GET_INDIVIDUAL_MEETING_API(meetingId));
}

const deleteMeetingRequest = (meetingId) => {
  return Axios.delete(GET_INDIVIDUAL_MEETING_API(meetingId))
}

const updateMeetingStatusRequest = (
  meetingId,
  participantId,
  status,
  payload
) => {
  console.log(meetingId, participantId, status, payload)
  return Axios.put(
    UPDATE_MEEETING_STATUS_API(meetingId, participantId, status),
    { ...payload }
  );
};
function* watchForGetMeetingsList() {
  yield takeEvery(GET_MEETINGS, function* getMeetingsList(action) {
    const { params, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(getMeetingsListRequest, params);
      console.log("watchForGetMeetingsList data ");
      if (data) {
        yield put(setMeetingsList(data));
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForGetMeetingsList catch err ", e);
    }
  });
}


function* watchForCreateScheduledMeeting() {
  yield takeEvery(CREATE_SCHEDULED_MEETING, function* scheduleMeeting(action) {
    const { requestObj, onSuccessCB, onErrorCB } = action.payload;
    try {
      let { data } = yield call(createScheduledMeetingRequest, requestObj);
      console.log("watchForCreateScheduledMeeting data ");
      if (data) {
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForCreateScheduledMeeting catch err ", e);
    }
  });
}

function* watchForUpdateScheduledMeeting() {
  yield takeEvery(UPDATE_SCHEDULED_MEETING, function* updateScheduleMeeting(action) {
    const { id, requestObj, onUpdateSuccessCB, onUpdateErrorCB } = action.payload;
    try {
      let { data } = yield call(updateScheduledMeetingRequest, id, requestObj);
      console.log("watchForUpdateScheduledMeeting data ");
      if (data) {
        yield call(onUpdateSuccessCB, data);
      }
    } catch (e) {
      yield call(onUpdateErrorCB, e);
      console.log("watchForUpdateScheduledMeeting catch err ", e);
    }
  });
}

function* watchForIndividualMeetingDetails() {
  yield takeEvery(GET_MEETING_DETAILS, function* getMeetingsList(action) {
    const { onSuccessCB, onErrorCB, id } = action.payload;
    console.log(action.payload, "action.payload");
    try {
      let { data } = yield call(getIndividualMeetingRequest, id);
      console.log("watchtIndividualmeeting data ");
      if (data) {
        yield call(onSuccessCB, data);
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchIndividualmeeting catch err ", e);
    }
  });
}

function* watchForDeleteMeeting() {
  yield takeEvery(DELETE_MEETING, function* getMeetingsList(action) {
    let meetingsList = yield select(state => state.meetings.meetingsList);
    const { id, onDeleteSuccessCB, onDeleteErrorCB } = action.payload;
    try {
      let { data } = yield call(deleteMeetingRequest, id);
      if (data) {
        yield call(onDeleteSuccessCB, data);
        const meetingIndex = meetingsList.findIndex(obj => obj._id === id);
        meetingsList.splice(meetingIndex, 1);
        yield put(setMeetingsList([...meetingsList]));
        console.log("watchForDeleteMeeting data ", meetingsList);
      }
    } catch (e) {
      yield call(onDeleteErrorCB, e);
      console.log("watchForDeleteMeeting catch err ", e);
    }
  });
}

function* watchForUpdateMeetingStatus() {
  yield takeEvery(UPDATE_MEETING_STATUS, function* meetingStatus(action) {
    const {
      meetingId,
      participantId,
      status,
      reqObject,
      onSuccessCB,
      onErrorCB,
    } = action.payload;
    try {
      let { data } = yield call(
        updateMeetingStatusRequest,
        meetingId,
        participantId,
        status,
        reqObject
      );
      if (data) {
        yield call(onSuccessCB, data);
        console.log("watchForUpdateMeetingStatus Success");
      }
    } catch (e) {
      yield call(onErrorCB, e);
      console.log("watchForUpdateMeetingStatus catch err ", e);
    }
  });
}
export default function* () {
  yield all([
    watchForGetMeetingsList(),
    watchForCreateScheduledMeeting(),
    watchForUpdateScheduledMeeting(),
    watchForIndividualMeetingDetails(),
    watchForUpdateMeetingStatus(),
    watchForDeleteMeeting()
  ])
}