import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Link, makeStyles } from '@material-ui/core';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFollowingUserList, getFollowUserList, getMutualFollowingUserList } from '../../ducks/favourites';
import { getMediaList } from '../../ducks/user';
import { getEllipsisText, getUTCFormattedDate } from '../../helpers';
import { useSnackbar } from 'notistack';
import { getMeetingsList } from '../../ducks/meetings';
import { Calendar, DatePicker, useStaticState } from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 16px',
        backgroundColor: '#FFF',
        borderRadius: 8,
        marginTop: 12,
        textAlign: 'center',
        fontSize: 8,
        color: '#26262680'
    },
    details: {
        margin: '40px 0px'
    }
}));

const AppFooter = ({ ...others }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} {...others}>
            <div className={classes.details}>
                <p>Powered by Invente</p>
                <p>© Leagues.ai 2021</p>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        getMeetingsList: (options) => dispatch(getMeetingsList(options)),
    };
};

export default AppFooter;