import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { setLoading } from "../../ducks/loading";
import { CustomCard } from '../Leagues/CustomCard';
import { CustomListGroup } from '../LiveScores/RoundsListView';
import MyPerformance from '../Leagues/MyPerformance';
import MyTeamPerformance from '../Leagues/MyTeamPerformance';
import MyProfilePerformance from './MyProfilePerformance';
import MyProfileTeamPerformance from './MyProfileTeamPerformance';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        borderRadius: 30,
        color: "#5D5FEF",
        backgroundColor: "#EBEBFF",
        padding: "5px 14px",
        fontSize: "20",
        fontWeight: 500,
    },
    rightIcon: {
        padding: "0px 0 0 20px",
    },
    detailsbox: {
        borderRadius: 3,
        backgroundColor: '#FFF',
        boxShadow: "0px 0px 3px 1px #EDEDED",
        padding: '10px',
        "& p": {
            margin: 0,
            padding: 0
        },
        "& h6": {
            margin: 0,
            padding: 0
        }
    },
    avatar_sec: {
        display: 'flex',
        alignItems: 'center'
    }
}))



const MentorPerformanceDashboard = ({ setLoading, filtersData, rounds, userInfo, natigateToAllStats, natigateToLiveScores, navigateToTeamLeaderboard, navigateToPlayerLeaderboard, navigateToAllMatches, history }) => {
    const classes = useStyles();
    const [selectedPlayerOption, setSelectedPlayerOption] = useState(null);
    const [selectedTeamOption, setSelectedTeamOption] = useState(null);
    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        if (rounds.length > 0 || filtersData?.season?._id) {
            const liveRound = rounds.find(obj => obj.round_status === 'LIVE');
            if (liveRound) {
                setMenuOptions([
                    { label: (liveRound?.name === 'QUARTER_FINALS') ? 'QF' : (liveRound?.name === 'SEMI_FINALS') ? 'SF' : liveRound?.name, value: liveRound?._id, pathname: 'round' },
                    { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' }
                ]);
                setSelectedPlayerOption({ label: liveRound?.name, value: liveRound?._id, pathname: 'round' });
                setSelectedTeamOption({ label: liveRound?.name, value: liveRound?._id, pathname: 'round' });
            }
            else {
                setMenuOptions([
                    { label: 'Season Level', value: filtersData?.season?._id, pathname: 'season', disabled: true }
                ]);
                setSelectedPlayerOption({ label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' });
                setSelectedTeamOption({ label: 'Season Level', value: filtersData?.season?._id, pathname: 'season' });
            }
        }
    }, [rounds, filtersData?.season?._id]);

    const handlePlayerOptionChange = (newValue) => {
        setSelectedPlayerOption(newValue);
    }

    const handleTeamOptionChange = (newValue) => {
        setSelectedTeamOption(newValue);
    }

    const isPlayerSeasonLevel = selectedPlayerOption?.pathname === 'season';
    const isTeamSeasonLevel = selectedTeamOption?.pathname === 'season';

    return (
        <div className={classes.root}>
            <div className=''>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <CustomCard cardTitle="My Team Performance" style={{ marginTop: '20px' }}
                                filterTabs={<CustomListGroup selectedItem={selectedTeamOption} onClick={handleTeamOptionChange} style={{ margin: 0, marginTop: 12 }} />}>
                                <MyProfileTeamPerformance isSeasonLevel={isTeamSeasonLevel} roundId={!isTeamSeasonLevel && selectedTeamOption?.value} filtersData={filtersData} />
                            </CustomCard>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {/* <div className=''>
                <h5>Contests</h5>
                <div className={classes.detailsbox}>

                </div>
            </div>
            <div className=''>
                <h5>Challenges</h5>
                <div className={classes.detailsbox}>

                </div>
            </div> */}
        </div>
    )
}


const mapStateToProps = (state, props) => ({
    user: state.user,

});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MentorPerformanceDashboard));

