import { Divider, fade, InputBase, makeStyles } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ChaseonsSkeleton } from "../Arena/ReactionsDialog";
import UserProfileCard from "../UserProfileCard/UserProfileCard";
import SearchIcon from "@material-ui/icons/Search";



export const PublishTo = ({ classes, loading, leaguesList, isAll = true, handleGroupsCheckbox, handleSubmitGroups, setLeaguesList, leagueSearch, setLeagueSearch, copyOfLeaguesList, setSearchResults, searchResults }) => {
    const checkedLeaguesLength = leaguesList.filter(obj => obj.checked).length;

    const handleChangeQuery = (searchedVal) => {
        setLeagueSearch(searchedVal);
        if (searchedVal.length === 0) {
            setLeaguesList(leaguesList);
            setSearchResults([]);
        }
        else {
            getLeaguesResult(searchedVal);

        }



    };


    const getLeaguesResult = (searchedVal) => {
        const filteredRows = leaguesList.filter((group) => {
            return group.name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        setSearchResults([...filteredRows])
    }

    const handleKeyPress = e => {
        if (e.nativeEvent.keyCode === 13 && leagueSearch.length > 0) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
            }
        }
    }

    return (
        <div className={classes.tagUsersListRoot}>
            <div className="title" >
                <p>Publish to</p>
                <span onClick={() => handleSubmitGroups()}>Done</span>
            </div>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search League"
                    classes={{
                        root: classes.searchInputRoot,
                        input: classes.searchInputInput,
                    }}
                    value={leagueSearch}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => handleChangeQuery(e.target.value)}
                    onKeyUp={(e) => handleKeyPress(e)}
                />
            </div>
            {leaguesList.length > 0 ? <div className={classes.usersList} style={{ marginTop: 0 }}>
                <div key={"00010"} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ margin: "2px 10px 0px", color: '#262626', fontSize: 13 }}>All Leagues</p>
                    <Checkbox style={{ display: 'inline-table' }} checked={searchResults.length > 0 ? isAll && searchResults.filter(obj => obj.checked).length === searchResults.length : checkedLeaguesLength === leaguesList.length} onChange={() => handleGroupsCheckbox('ALL', isAll)} color="primary" />
                </div>
                {
                    loading ?
                        [0, 1, 2, 3].map(i => <ChaseonsSkeleton secondary={true} key={i} />)
                        :
                        (leagueSearch.length > 0 ? searchResults.length > 0 ? searchResults : [] : leaguesList).map((group, i) => (
                            <div key={i}>
                                <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <UserProfileCard
                                        key={`group-${i}`}
                                        name={group.name}
                                        avatarUrl={group.logo_url}
                                        avatarStyles={classes.avatarStyles}
                                        profileNameStyles={classes.profileName}
                                        timestamp={false}
                                        style={{ margin: '12px 2px', alignItems: 'center' }}
                                    />
                                    <Checkbox style={{ display: 'inline-table' }} checked={group.checked} onChange={() => handleGroupsCheckbox(i)} color="primary" />
                                </div>
                                <Divider style={{ margin: '0 12px 0 38px' }} variant="fullWidth" component="div" />
                            </div>
                        ))

                }

            </div>
                : <p style={{ fontSize: 14, textAlign: 'center' }}>No Groups found</p>}

        </div>
    )
}