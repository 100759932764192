
import React, { useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { connect } from 'react-redux';
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from '../../../helpers';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


// Chart.register(ChartDataLabels);

const PerformanceGraph = ({ userDetails, unitOfMeasurement, unit }) => {
    const targets = [8, 15, 8, 4, 7, 20]
    const actuals = [2000, 2500, 3000, 3500, 4000, 4500, 5000]

    const labels = ['Apr', 'Jun', 'Augu', 'Oct', 'December', 'Feb'];
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false

            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    // display: false,
                },
                grid: {
                    display: false
                },
                // display: false,
            },
            x: {
                grid: {
                    display: false
                },
                offset: true
            }
        }
    };
    const data = {
        labels,
        datasets: [
            {
                label: 'Target',
                data: targets,
                borderColor: '#9FA1FF',
                backgroundColor: ' #303180',
                fill: false,
                borderWidth: 1,


            },
        ],
    };
    return (
        <Line
            style={{ maxHeight: 200 }}
            options={options}
            data={data}



        />
    )
}



const mapStateToProps = (state) => ({
    userDetails: state.user
});


export default connect(mapStateToProps, null)(PerformanceGraph);

export const BarGraph = ({ }) => {
    const targets = [45, 80, 60, 100, 50]

    const labels = ['Brand 3', 'Brand 1', 'Brand 4', 'Brand 7', 'Brand 5'];
    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
                height: 900,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'left',
                display: false
            },
            title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart',
            },
        },
        scales: {
            y: {
                grid: {
                    display: false
                },
            },
            x: {
                grid: {
                    display: false
                },
            }
        }
    };
    const data = {
        labels,
        datasets: [
            {
                label: 'Target',
                data: targets,
                borderColor: '#9FA1FF',
                backgroundColor: ' #303180',
                fill: false,
                borderWidth: 1,


            },
        ],
    };
    return (
        <Bar
            style={{ minHeight: 50 }}
            options={options}
            data={data}



        />
    )
}











