import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Moment from "react-moment";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import BugReportIcon from '@material-ui/icons/BugReport';
import { Avatar, Grid, Button, MenuItem, TextareaAutosize, List, ListItem, ListItemIcon, ListItemText, IconButton, NativeSelect, FormControl, TextField, CircularProgress, Backdrop, InputLabel, DialogActions, Table, TableRow, TableCell } from '@material-ui/core';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CommentIcon from '@material-ui/icons/Comment';
import TimerIcon from '@material-ui/icons/Timer';
import LibraryAddCheckOutlinedIcon from '@material-ui/icons/LibraryAddCheckOutlined';
import Comment from './comment';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Tooltip, FormHelperText } from '@material-ui/core'
import { imageMsgTypes, fileMsgTypes, videoMsgTypes } from '../ChatSection/MessageInput';
import { getFormatIcon, downloadFiles, getEllipsisText, getUTCFormattedDate, getLocalFormattedDate, getFormattedDate, getAvatarText, postCreatedAtFormat } from '../../helpers'
import { getColor } from './KanbanBoard'
import { DatePicker } from '@material-ui/pickers';
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as Share } from "../../assets/icons/share-arena.svg";
import moment from 'moment'
import { ShowAgeingInfo, CompletedItemAgeing } from '../ProductManagementSystem';

// ../../../assets/icons/copy.svg
//  ------- subtask imports
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// file uload --------
import imageCompression from 'browser-image-compression';
import { uploadFilesToS3 } from '../../ducks/common';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { Close, Add, PlayCircleOutline, ExpandMore, CodeSharp } from '@material-ui/icons';
import classNames from "classnames";
import WorkLogs from "./WorkLogs";
import TaskHistory from "./TaskHistory";
import { getTaskDetails, updateTask, createSubTask, openShareTaskDialog, getProjectMembers } from "../../ducks/pms";
import { getArenaProfile } from '../../ducks/user';
import Breadcrumb from './Breadcrumbs'
import { ArenaPreview } from '../Arena/ArenaPreview'
import { DateComponent, AutocompleteComponent, TextComponent } from './CustomFields';
import { setLoading } from "../../ducks/loading";

// import {stateToHTML} from 'draft-js-export-html'; 
// import { convertFromRaw } from 'draft-js';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import DOMPurify from 'dompurify';
// import { convertToHTML } from 'draft-convert';
// import { EditorState } from 'draft-js';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    width: '100%',
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    padding: '35px 35px 0px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh - 80px)',
      maxWidth: `calc(100vw - 150px)`,
      padding: '20px 15px 0px',
    },
  },
  fieldBorder: {
    border: 'none',
    borderRadius: 6,
    borderWidth: 1,
    height: 27,
    fontSize: 14,
    // marginLeft: '-5px',
    '& input': {
      padding: "5px 4px !important"
    }
  },
  gridContainer: {
    width: '100%',
    margin: '0 auto',
    paddingBottom: 10,
  },
  title: {
    margin: 0,
    fontSize: 20,
    textBold: '1%',
    fontWeight: '550',
    fontSize: '1.4em',
    fontStyle: 'inherit',
    lineHeight: 1.4,
    fontWeight: 500,
    letterSpacing: '-0.01em',
    color: '#172b4d',
    "&:hover": {
      width: "100%",
      background: "#efefef",
      borderRadius: 5,
      cursor: 'text'
    }
  },
  actionButton: {
    alignItems: "center",
    color: '#42526e',
    fontWeight: 550,
    fontSize: 'inherit',
    padding: "0px 10px",
    marginRight: 10,
    height: 30,
    fontSize: 12,
    lineHeight: '2.28571em',
    backgroundColor: '#091e420a',
    whiteSpace: 'nowrap',
    '& svg': {
      width: 20,
      height: 20
    },
    "&:hover": {
      background: "#efefef"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 5px",
      minWidth: 20,
      "& span": {
        marginRight: 0,

      }
    }
  },
  lables: {
    color: "#58595b",
    fontSize: "15px",
    marginTop: 12,
    fontWeight: "600",
    marginBottom: "10px",
  },
  tableLable: {
    color: "#58595b",
    fontSize: "15px",
    marginTop: 5,
    marginBottom: 5,
    fontWeight: "600",
  },
  valueStyles: {
    marginTop: 10,
    color: "#535353",
    fontSize: 15,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    "&:hover": {
      background: "#efefef"
    },
    cursor: 'text'
  },
  timeValueStyles: {
    marginTop: 10,
    color: "#535353",
    fontSize: 15,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  estimationValueStyles: {
    marginTop: 10,
    color: "#535353",
    fontSize: 15,
    marginTop: 5,
    wordBreak: 'break-word',
    "&:hover": {
      background: "#efefef",
      padding: '5px 108px 5px 0px',
      borderRadius: '5px'
    },
    cursor: 'pointer'
  },
  list: {
    display: 'flex',
    justifyContent: 'end'
  },
  descriptionData: {
    fontSize: 14,
    fontWeight: "300",
    marginTop: '2%'
  },
  select: {
    fontSize: 14,
    fontWeight: '400',
    borderRadius: 4,
    height: 26,
    background: '#fff',
  },
  selectElement: {
    fontWeight: 550,
    fontSize: '14px',
    "&:hover": {
      background: "#deebfe"
    },
  },
  avatarAgjustment: {
    alignItems: 'center',
    display: 'flex'
  },
  // seting scroll for grid
  scrollCss: {
    // paddingLeft: 1,
    width: "100%",
    overflowY: 'auto',
    // [theme.breakpoints.down('xs')]: {
    //   maxHeight: 'calc(100vh - 80px)',
    //   maxWidth: `calc(100vw - 150px)`,
    // },
  },
  subtaskfields: {
    borderBottom: 'solid 1px #b4b4b4',
  },
  // condition button css
  filterButton: {
    height: 26,
    margin: "0 7px",
    fontWeight: 500,
    backgroundColor: "#e6e6e6",
    width: 100,
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      margin: "0 4px",
      width: 50,
      minWidth: 50,
      "& span": {
        marginRight: 0
      }
    },
    "&:hover": {
      backgroundColor: "#9b9b9b80"
    },
    "& svg": {
      "& .a": {
        fill: "#9b9b9b"
      }
    }
  },
  filtersRoot: {
    marginTop: 18,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
    "& .isActive": {
      backgroundColor: "#828282",
      color: "#FFFFFF",
      "& svg": {
        "& .a": {
          fill: "#FFFFFF"
        }
      }
    }
  },
  //priority field styles
  priorityHigh: {
    fontSize: "medium",
    color: "#ff4d4d",
    marginRight: 5
  },
  priorityMedium: {
    fontSize: "medium",
    color: "#ff9933",
    marginRight: 5
  },
  priorityLow: {
    fontSize: "medium",
    color: "#3a8736",
    marginRight: 5

  },
  // description input css 
  fieldWidth: {
    borderRadius: 6,
    '& input': {
      padding: "12px 14px !important"
    }
  },
  //file upload
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: 'absolute'
  },
  uploadingRoot: {
    position: 'relative',
    height: 100,
    width: 120
  }, attachmentPickerSection: {
    height: 100,
    width: 115,
    border: '2px dashed #58595B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      width: 95,
    },
    '&:hover': {
      opacity: 0.3
    }
  }, attachment: {
    height: 100,
    width: 115,
    backgroundColor: '#EEEEEE',
    textAlign: 'center',
    border: '1px solid #EEEEEE',
    position: 'relative',
    marginRight: 10,
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      width: 95,
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5,
      '& #delete-icon': {
        visibility: 'visible',
        '& svg': {
          fontSize: '0.85rem',
          backgroundColor: '#000',
          color: '#FFF'
        },
      }
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
    }
  },
  attachName: {
    width: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    marginTop: "-5px",
    color: "#838383",
    fontSize: 14
  },
  deleteIcon: {
    position: 'absolute',
    right: '2px',
    top: '1px',
    visibility: 'hidden',
    cursor: 'pointer'
  },
  videoStyles: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#828282",
    position: "relative",
    textAlign: "center",
    color: "#fff",
    overflow: 'hidden',
    "&:hover": {
      cursor: "pointer"
    },
    '& button': {
      position: 'absolute'
    }
  },
  playIcon: {
    color: '#fff'
  },
  timestamp: {
    fontSize: 14,
    color: "#838383"
  },
  privacySelect: {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    padding: "0px !important",
    color: "#535353",
    fontSize: 15,
    maxWidth: 200,
    minWidth: 100,
    '&:hover': {
      backgroundColor: "#f0f0f0"
    }
    //   [theme.breakpoints.down('xs')]: {
    //     maxWidth:75,
    // }
  },
  selectMenuStyles: {
    whiteSpace: 'normal'
  },
  subtaskStatusSelect: {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    padding: "5px !important",
    color: "#535353",
    fontSize: 12
  },
  subtaskStatusStyles: {
    width: 'auto',
    maxWidth: 140,
    borderRadius: 5,
    padding: 5,
    height: 26,
    [theme.breakpoints.down('xs')]: {
      width: 80,
    },
    //   [theme.breakpoints.up('sm')]: {
    //     width:"100%",
    // },
    '& fieldset': {
      border: 'none'
    }
  },
  selectIcon: {
    display: 'none', right: 0
  },
  issueTypeTask: {
    fontSize: "medium",
    color: "#0377fc",
    margin: "0px 5px 0px 0px"
  },
  issueTypeStory: {
    fontSize: "medium",
    color: "green",
    margin: "0px 5px 0px 0px"
  },
  priorityHigh: {
    fontSize: "medium",
    color: "#ff4d4d",
    margin: "0px 5px 0px 0px"
  },
  commentsPlaceholder: {
    display: "flex",
    borderRadius: "10px",
    width: "100%",
    minHeight: "40px"
  },
  descriptionFieldView: {
    alignItems: "center",
    borderRadius: "10px",
    minHeight: "40px",
    width: "99%", marginTop: 3
  },
  input: {
    resize: 'none',
    width: "calc(100% - 17px)",
    marginLeft: theme.spacing(1),
    fontSize: 14,
    "&:focus": {
      outline: "none"
    },
    border: 'solid 1px #b4b4b4',
    borderRadius: "10px",
    marginLeft: 0,
    wordBreak: "break-word",
    color: "#535353",
    fontSize: "15px",
    padding: "10px",
    whiteSpace: 'pre-line'
  },
  avatarStyles: {
    fontSize: '0.75rem',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    height: 20,
    width: 20,
    marginRight: 5,
  },
  datePickerInputStyles: {
    fontSize: 14,
    color: '#535353',
    height: '32px',
    cursor: 'pointer',
  }, dateInputUnderine: {
    height: 26,
    border: 'none',
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      border: 'none',
      // border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:before': {
      border: 'none',
      content: 'none'
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'none'
    },
    '& svg': {
      display: 'none',
      fill: "#0000008a"
    }, '&:after': {
      border: 'none'
    }
  }, autocompleteRoot: {
    padding: "0px !important",
    width: 125,
    borderRadius: 5,
    fontSize: '14px',
    color: "#000000de",
    '& input': {
      padding: "3.5px 4px !important"
    },
    '& button': {
      display: 'none'
    }

  },
  numberField: {
    fontSize: '14px',
    '& input': {
      fontSize: '14px',
      padding: "5px 4px !important",
      '&:focus': {
        border: `solid 1px ${theme.palette.primary.main}`,
        borderRadius: 5,
      }
    }
  },
  alignSubtasks: {
    width: '58%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  viewSubtaskIcon: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  buttonText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  contentValueStyles: {
    color: "#535353",
    fontSize: 15,
    wordBreak: 'break-word',
  },
  contextContainer: {
    padding: "0px 85px 0px 85px",
    [theme.breakpoints.down('md')]: {
      padding: "0px 40x 0px 40px",

    },
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
    '& fieldset': {
      border: 'none'
    }
  },
  alignActionItems: {
    display: 'flex',
    marginTop: 5,
    [theme.breakpoints.down('xs')]: {
      // gridGap: "5px",
      // display: "grid",
      // gridTemplateColumns: "auto auto auto",
    }
  },
  selectPadding: {
    padding: 0
  },
  itemsGridView: {
    //     display: "flex",
    //     flexWrap:'wrap',
    //     gridGap: "5px",
    //   [theme.breakpoints.down('sm')]: {
    //    gridGap: "10px"
    //  }
  }
}))
let attachmentsCount = 0;
let totalAttachements = []
let finalAttachmentsCount = 0;
const CancelToken = Axios.CancelToken;
const TaskDetails = (props) => {
  const classes = useStyles();
  const initialState = {
    stages: [],
    taskDetails: {
      attachments: [],
      newAttachments: [],
      comments: [],
      subTasks: [],
      timelogs: [],
      title: "",
      description: "",
      assigned_to: "",
      project_id: "",
      priority: 1,
      task_type: "",
      reported_by: "",
      created_by: "",
      current_stage: "",
      task_id: "",
      estimation: 0,
      target_date: '',
      projectName: '',
      parent_target_date: '',
      custom_fields: {},
      editorText: null

    },
  };

  const [taskDetailsData, setTaskDetailsData] = useState({ ...initialState });
  const [customFields, setCustomFields] = useState([]);
  const [openedCustomField, setOpenCustomField] = useState(null)
  const [showEstimationField, setShowEstimationField] = useState(false)
  const [subTaskInput, setSubTaskInput] = useState(false);
  const [subTaskData, setSubTaskData] = useState({ title: '', hasError: false });
  const [statusColor, setStatusColor] = useState({ background: "", color: "" });
  const [memberImage, setMemberImage] = useState({ assigne: { photoUrl: '', name: '' }, reporte: { photoUrl: '', name: '' } })
  // file upload
  const [initialTaskDetails, setInitialTaskDetails] = useState({});
  const [uploading, setUploading] = useState(false);
  const [source, setSource] = useState(CancelToken.source());
  const { enqueueSnackbar } = useSnackbar();
  ///-----------
  const [showComment, setShowComment] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [file, setFile] = useState(null)
  const [showWorkLogs, setShowWorkLogs] = useState(false);
  const [showWebLink, setShowwebLink] = useState(false);
  const [showDescription, setShowDescription] = useState(false)
  const [showTitle, setShowtitle] = useState(false)
  const [projectMembers, setProjectMembers] = useState([])
  const [preview, setPreview] = useState({
    open: false,
    attachments: [],
    attachmentIndex: 0
  });
  const [currentStage, setCurrentStage] = useState({ key: '' });
  // const [editorState, setEditorState] = useState(
  //   () => EditorState.createEmpty(),
  // );
  // const  [convertedContent, setConvertedContent] = useState(null);

  //  use effect calling  api
  useEffect(() => {
    setMemberImage({ assigne: { photoUrl: '', name: '' }, reporte: { photoUrl: '', name: '' } })
    if (props.match.params.taskid || props.match.params.subTaskId || props.newTask) {
      props.setLoading(true)
      props.getTaskDetails({ params: { task_id: props.isSubTask ? props.match.params.subTaskId : props.match.params.taskid }, onSuccessCB, onErrorCB })
    }
    handleFilterClick("Comments", 'showComment')
  }, [props.match.params.taskid, props.match.params.subTaskId, props.newTask])

  useEffect(() => {
    let itemField = document.getElementById('nextSubtaskField');
    if (itemField) {
      itemField.scrollIntoView();
      itemField.focus();
    }
  }, [subTaskInput]);

  const getCurrentStageKey = (taskDetailsData) => {
    let stage = taskDetailsData.stages && taskDetailsData.stages.find(stage => stage._id === taskDetailsData.taskDetails.current_stage)
    setCurrentStage(prevState => ({ ...prevState, ...stage }))
  }
  const changeMembersImage = (users, memberObj) => {
    if (users.length > 0) {
      if (memberObj.assignee != '') {
        let assineeUser = users.find(ele => ele.username === memberObj.assignee);
        if (assineeUser) setMemberImage(prevState => ({ ...prevState, assigne: { photoUrl: assineeUser.photo_url, name: assineeUser.name } }));
      }
    }
  }
  //Sucess callback
  const onSuccessCB = (result) => {
    let resultDup = result.data.taskDetails.custom_fields || {};
    let fieldsDup = result.data.taskDetails.config.custom_fields;
    (Object.keys(resultDup).length > 0 && fieldsDup.length > 0) && Object.keys(resultDup).forEach(key => {
      if (fieldsDup.find(obj => obj.fieldKey === key) && fieldsDup.find(obj => obj.fieldKey === key).type === 'select')
        resultDup[key] = fieldsDup.find(obj => obj.fieldKey === key).options.find(obj => obj.value === resultDup[key])
    });
    // setEditorState(JSON.parse(result.data.taskDetails.editorText))
    // setConvertedContent(convertToHTML(JSON.parse(result.data.taskDetails.editorText)))
    // resultDup.taskDetails.config.custom_fields
    setTaskDetailsData({
      ...result.data,
      taskDetails: { ...result.data.taskDetails, custom_fields: resultDup }
    });
    setCustomFields([...result.data.taskDetails.config.custom_fields])
    setInitialTaskDetails({
      ...result.data,
      taskDetails: { ...result.data.taskDetails, custom_fields: resultDup }
    })
    props.getArenaProfile({ username: result.data.taskDetails.reported_by, onEmployeeDetailsSuccess, onEmployeeDetailsError })
    getCurrentStageKey(result.data);
    // console.log(JSON.parse(result.data.taskDetails.editorText))
    props.getProjectMembers({
      id: result.data.taskDetails.project_id,
      memberDetails: { assignee: result.data.taskDetails.assigned_to, reporte: result.data.taskDetails.reported_by }
      , onSuccessCB: getProjectsMembersCB,
      onErrorCB: getProjectsMembersErrorCB
    })
    props.setLoading(false)
  };
  const onEmployeeDetailsError = (error) => {
    enqueueSnackbar(`Failed to fetch Reporter details.`, {
      variant: "error",
      preventDuplicate: true
    });
  }
  const onEmployeeDetailsSuccess = (result) => {
    if (result) {
      setMemberImage(prevState => ({ ...prevState, reporte: { photoUrl: result.photoUrl, name: result.name } }))
    }
  }
  //Error Callback
  const onErrorCB = error => {
    enqueueSnackbar(`Failed to fetch Taskdetails data.`, {
      variant: "error",
      preventDuplicate: true
    });
    setLoading(false);
  };
  const getProjectsMembersCB = (result) => {
    if (result.members.length > 0) {
      changeMembersImage(result.members, result.memberDetails);
      setProjectMembers([...result.members])
    }
  }

  const getProjectsMembersErrorCB = (error) => {
    enqueueSnackbar(`Failed to fetch Project Members.`, {
      variant: 'error',
      preventDuplicate: true
    });
  }
  // condition button 
  const [filter, setFilter] = useState("Comments");
  const handlePreview = ([...attachments], url) => {
    const FilteredAttachments = attachments.filter(item => getMsgType(item.url && item.url.split('.').pop()) === 'IMAGE' || getMsgType(item.url && item.url.split('.').pop()) === 'VIDEO');
    setPreview({ open: true, attachments: FilteredAttachments, attachmentIndex: FilteredAttachments.findIndex(ele => ele.url == url) });
  }

  const handlePreviewClose = () => {
    setPreview({ open: false, attachments: [] });
  }

  const handleSubTaskCreation = (e) => {
    if (e.target.value.trim() === '') setSubTaskData({ title: e.target.value.trim(), hasError: true })
    else setSubTaskData({ title: e.target.value.trim(), hasError: false })
  }

  const handleSubTaskRequest = () => {
    if (subTaskData.title.trim() === '') setSubTaskData({ ...subTaskData, hasError: true })
    else {
      let custom_fields_obj = {}
      if (Object.keys(taskDetailsData.taskDetails.custom_fields).length > 0) {
        Object.keys(taskDetailsData.taskDetails.custom_fields).forEach((ele) => {
          custom_fields_obj[ele] = ''
        })
      }
      let reqObj = {
        project_id: taskDetailsData.taskDetails.project_id,
        parent_task: taskDetailsData.taskDetails.task_id,
        current_stage: taskDetailsData.stages[0]._id,
        title: subTaskData.title,
        task_type: taskDetailsData.taskDetails.task_type,
        custom_fields: custom_fields_obj
      }
      props.createSubTask({ requestObj: reqObj, onSuccessCB: onSubTaskSuccessCB, onErrorCB: onSubTaskErrorCB })
    }
  }

  const onSubTaskSuccessCB = (result) => {
    props.handleNewTask(new Date().getTime())
    handleCancelButtom('subTaskInput')
  };
  //Error Callback
  const onSubTaskErrorCB = error => {
    console.log(error);
    enqueueSnackbar(`Failed to fetch sub task Taskdetails data.`, {
      variant: "error",
      preventDuplicate: true
    });
    // setLoading(false);
  };
  const setSubTaskToTaskComponent = (subTaskId) => {
    props.history.push(`/product-management/${props.match.params.id}/board/${props.match.params.taskid}/subtask/${subTaskId}`)
  }

  //  const onSucessCB 
  const handleDescriptionChage = (e) => {
    let detailsObj = { ...taskDetailsData.taskDetails }
    detailsObj['description'] = e.target.value;
    setTaskDetailsData({ ...taskDetailsData, taskDetails: detailsObj })
  }
  const handleTitleChageData = (e) => {
    let detailsObj = { ...taskDetailsData.taskDetails }
    detailsObj['title'] = e.target.value;
    setTaskDetailsData({ ...taskDetailsData, taskDetails: detailsObj })
  }

  const handleshowWebLink = (showWebLink) => {
    setShowwebLink(!showWebLink)
  }
  //file upload-------

  const handleAddPhoto = () => {
    const element = document.getElementById(`hiddenFileInputForTask`);
    element.value = '';
    element.click();
  }
  const getMsgType = (ext) => {
    if (imageMsgTypes.includes(ext)) return 'IMAGE';
    else if (videoMsgTypes.includes(ext)) return 'VIDEO';
    else if (fileMsgTypes.includes(ext)) return 'FILE';
    else return 'link';
  }
  const handleFileChange = (event) => {
    attachmentsCount = 0;
    finalAttachmentsCount = 0;
    let files = event.target.files;
    if (files && files.length > 0) {
      attachmentsCount = files.length;
      setUploading(true);
      for (let index = 0; index < files.length; index++) {
        let fileSize = files[index].size / 1024 / 1024; // in MB
        if ((files[index].type).startsWith('image/')) {
          const imageCompressionOptions = {
            maxSizeMB: 3,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          }
          imageCompression(files[index], imageCompressionOptions)
            .then((compressedFile) => {
              let resultFile = new File([compressedFile], compressedFile.name, { type: compressedFile.type, lastModified: compressedFile.lastModified });
              let formData = new FormData();
              formData.append("file", resultFile);
              props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: files[index], cancelToken: source.token });
            })
            .catch((error) => {
              console.log(error.message);
              setUploading(false);
            });
        }
        else if (files[index].type && (files[index].type).startsWith('video/')) {
          if (fileSize > 100) {
            enqueueSnackbar('Video size cannot exceed 100 MB.', {
              variant: 'error',
              preventDuplicate: true
            });
            setUploading(false);
            if (event.target) event.target.value = "";
          }
          else {
            var formData = new FormData();
            formData.append("file", files[index]);
            setUploading(true);
            props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: files[index], cancelToken: source.token });
          }
        }
        else {
          if (fileMsgTypes.includes(files[index].name && files[index].name.split('.').pop())) {
            setFile({ name: files[index].name });
            var reader = new FileReader();
            reader.onload = function (e) {
              setFile({ base64: e.target.result, name: files[index].name, content: files[index] })
            }
            reader.readAsDataURL(files[index]); // convert to base64 string
            var formData = new FormData();
            formData.append("file", files[index]);
            // props.setLoading(true);
            props.uploadFilesToS3({ formData, file: files[index], onUploadSuccessCB, onUploadErrorCB });
            // if (event.target) event.target.value = "";
          }
          else {
            setUploading(false)
            enqueueSnackbar(`Text File cannot be upload`, {
              variant: 'error',
              preventDuplicate: true
            });
          }
        }
      }
    }
  }
  const onUploadSuccessCB = (result, file) => {
    let attachmentObj = { url: result.url, thumbnailUrl: result.thumbnailUrl, mimeType: (file.type.split('/').shift()).toUpperCase() };
    totalAttachements.push(attachmentObj)
    enqueueSnackbar(`Attachments Uploaded.`, {
      variant: 'success',
      preventDuplicate: true
    });
    finalAttachmentsCount++;
    if (result.url) {
      if (props.fromEdit) {
        setTaskDetailsData(prevState => ({ ...prevState, taskDetails: { ...prevState.taskDetails, newAttachments: [...prevState.taskDetails.newAttachments, { url: result.url, thumbnailUrl: result.thumbnailUrl, mimeType: (file.type.split('/').shift()).toUpperCase() }] } }));
      }
      else
        setTaskDetailsData(prevState => ({ ...prevState, taskDetails: { ...prevState.taskDetails, attachments: [...prevState.taskDetails.attachments, { url: result.url, thumbnailUrl: result.thumbnailUrl, mimeType: (file.type.split('/').shift()).toUpperCase() }] } }));
    }
    if (finalAttachmentsCount === attachmentsCount) {
      const newAttachArray = [...taskDetailsData.taskDetails.attachments, ...totalAttachements];
      props.updateTask({ requestObj: { attachments: newAttachArray }, id: taskDetailsData.taskDetails._id, onSuccessCB: onAttachUploadSuccessCB, onErrorCB: onAttachUploadErrorCB })
      setUploading(false);
    }
  }
  const onAttachUploadSuccessCB = (result) => {
    totalAttachements = [];
  }
  const onAttachUploadErrorCB = (error) => {
    totalAttachements = [];
    console.log(error)
  }
  const onUploadErrorCB = (result, file) => {
    finalAttachmentsCount++;
    enqueueSnackbar(`Failed to Upload ${file.name}.`, {
      variant: 'error'
    });
    if (finalAttachmentsCount === attachmentsCount) {
      setUploading(false);
    }
  }

  const getAttachmentsList = (
    <>
      {taskDetailsData.taskDetails.attachments && taskDetailsData.taskDetails.attachments.length > 0 &&
        <>
          <p className={classes.lables} style={{ marginBottom: 5 }}>Attachments ({taskDetailsData.taskDetails.attachments.length})</p>
          <div className={classes.attachmentsList}>
            {taskDetailsData.taskDetails.attachments.map((item, index) => (

              <div key={index} className={classes.attachment}>
                {
                  getMsgType(item.url && item.url.split('.').pop()) === 'IMAGE' ?
                    <img alt={(item.url || item.attachmentUrl || '').split('-').pop()} src={item.url || item.attachmentUrl} onClick={() => handlePreview(taskDetailsData.taskDetails.attachments, item.url)} />
                    :
                    getMsgType(item.url && item.url.split('.').pop()) === 'VIDEO' ?
                      <div className={classes.videoStyles} >
                        <img style={{ maxWidth: 'initial' }} alt={item.thumbnailUrl && item.thumbnailUrl.split("-").pop()} src={item.thumbnailUrl} onClick={() => handlePreview(taskDetailsData.taskDetails.attachments, item.url)} />
                        <IconButton><PlayCircleOutline className={classes.playIcon} onClick={() => handlePreview(taskDetailsData.taskDetails.attachments, item.url)} /></IconButton>
                      </div> :
                      getMsgType(item.url && item.url.split('.').pop()) === 'FILE' ?
                        <div onClick={() => downloadFiles(item.url, item.url.split('-').pop())} style={{ padding: item.url && item.url.split('.').pop() === 'pdf' ? 10 : item.url && item.url.split('.').pop().includes('doc') ? 20 : 35 }}>
                          <img alt={getFormatIcon(item.url && item.url.split('.').pop())} src={getFormatIcon(item.url && item.url.split('.').pop())} /></div>
                        : ""
                }
                <span id="delete-icon" className={classes.deleteIcon}>
                  <Close fontSize={'small'} onClick={(e) => handleDeletePhoto(e, index)} />
                </span>
                <span className={classes.attachName}>{item.url.split('-').pop()}</span>
              </div>
            ))}

            {props.fromEdit && taskDetailsData.taskDetails.newAttachments && taskDetailsData.taskDetails.newAttachments.map((item, index) => (
              <div key={index} className={classes.attachment}>
                {
                  getMsgType(item.url && item.url.split('.').pop()) === 'IMAGE' ?
                    <img alt={(item.url || item.attachmentUrl || '').split('-').pop()} src={item.url || item.attachmentUrl} />
                    :
                    getMsgType(item.url && item.url.split('.').pop()) === 'VIDEO' ?
                      <div className={classes.videoStyles} >
                        <img style={{ maxWidth: 'initial' }} alt={item.thumbnailUrl && item.thumbnailUrl.split("-").pop()} src={item.thumbnailUrl} onClick={() => handlePreview(taskDetailsData.taskDetails.attachments, item.url)} />
                        <IconButton><PlayCircleOutline className={classes.playIcon} /></IconButton>
                      </div> :
                      getMsgType(item.url && item.url.split('.').pop()) === 'FILE' ?
                        <div style={{ padding: item.url && item.url.split('.').pop() === 'pdf' ? 10 : item.url && item.url.split('.').pop().includes('doc') ? 20 : 35 }}>
                          <img alt={getFormatIcon(item.url && item.url.split('.').pop())} src={getFormatIcon(item.url && item.url.split('.').pop())} /></div>
                        : ""
                }
                <span id="delete-icon" className={classes.deleteIcon}>
                  <Close fontSize={'small'} onClick={(e) => handleDeletePhoto(e, index, true)} />
                </span>
                <span className={classes.attachName}>{item.url.split('-').pop()}</span>
              </div>
            ))}
            {
              uploading ?
                <div className={classes.uploadingRoot}>
                  <Backdrop className={classes.backdrop} open={uploading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
                :
                taskDetailsData.taskDetails.attachments && taskDetailsData.taskDetails.attachments.length > 0 &&
                <div title="Upload" className={classes.attachmentPickerSection} onClick={() => handleAddPhoto()}>
                  <Add fontSize="large" />
                </div>
            }
          </div>
        </>
      }
      {preview.open && <ArenaPreview open={preview.open} attachmentIndex={preview.attachmentIndex} arena={{ attachments: preview.attachments }} handlePreviewClose={handlePreviewClose} isFromProfile={true} />}
    </>
  );

  const hadleCustomCancelButton = (field) => {
    let obj = { ...taskDetailsData.taskDetails };
    obj.custom_fields[field.fieldKey] = initialTaskDetails.taskDetails.custom_fields[field.fieldKey];
    setTaskDetailsData(prevState => ({ ...prevState, custom_fields: obj }))
    setOpenCustomField(null)
  }

  const handleChangeInput = (e, field) => {
    let maxLengthCheck = true
    if (field.type === 'number') {
      maxLengthCheck = e.target.value >= 0 && e.target.value <= 99999999999999
    }
    if (maxLengthCheck) {
      let fields = JSON.parse(JSON.stringify(taskDetailsData.taskDetails));
      fields.custom_fields[field.fieldKey] = e.target.value;
      setTaskDetailsData(prevState => ({ ...prevState, taskDetails: fields }))
    }

  }

  const handleChangeAutocomplete = (data, fieldKey) => {
    let fields = { ...taskDetailsData.taskDetails }
    fields.custom_fields[fieldKey] = data;
    setTaskDetailsData(prevState => ({ ...prevState, taskDetails: fields }))
    if (data) updateCustomFiledData(fields.custom_fields)
  }

  const handleChangeDate = (data, fieldKey) => {
    let fields = { ...taskDetailsData.taskDetails }
    fields.custom_fields[fieldKey] = data;
    setTaskDetailsData(prevState => ({ ...prevState, taskDetails: fields }))
    if (data) updateCustomFiledData(fields.custom_fields)
  }

  const handelCloseButtoView = (field) => {
    let obj = JSON.parse(JSON.stringify(taskDetailsData.taskDetails));
    obj.custom_fields[field.fieldKey] = initialTaskDetails.taskDetails.custom_fields[field.fieldKey];
    let fields = JSON.parse(JSON.stringify(customFields));
    const index = fields.findIndex(ele => ele.fieldKey === field.fieldKey);
    fields[index].isOpen = false;
    setCustomFields([...fields]);
    setTaskDetailsData(prevState => ({ ...prevState, taskDetails: obj }))
  }

  const handelCustomNumber = (field) => {
    let fields = JSON.parse(JSON.stringify(customFields));
    const index = fields.findIndex(ele => ele.fieldKey === field.fieldKey);
    fields[index].isOpen = false;
    setCustomFields([...fields]);
    updateCustomFiledData(taskDetailsData.taskDetails.custom_fields)
  }

  const handelFocus = (key) => {
    let fields = JSON.parse(JSON.stringify(customFields));
    const index = fields.findIndex(ele => ele.fieldKey === key);
    fields[index].isOpen = true;
    setCustomFields([...fields]);
  }

  const handelUnFocus = (key) => {
    let fields = JSON.parse(JSON.stringify(customFields));
    const index = fields.findIndex(ele => ele.fieldKey === key);
    fields[index].isOpen = false;
    setCustomFields([...fields]);
  }

  const updateCustomFiledData = (obj) => {
    let customFieldsObj = {};
    customFields && customFields.forEach(element => {
      customFieldsObj[element.fieldKey] = element.type === 'select' ? (obj[element.fieldKey] ? obj[element.fieldKey].value : '') : obj[element.fieldKey];
    });
    props.updateTask({
      requestObj: { "custom_fields": customFieldsObj }, id: initialTaskDetails.taskDetails._id, onSuccessCB: () => {
        setOpenCustomField(null);
        setInitialTaskDetails(prevState => ({ ...prevState, taskDetails: { ...prevState.taskDetails, custom_fields: obj } }))
        enqueueSnackbar(`Updated Successfully.`, {
          variant: 'success',
          preventDuplicate: true
        });
      }, onErrorCB: () => {
        enqueueSnackbar(`Failed to update.`, {
          variant: 'error',
          preventDuplicate: true
        });
      }
    })
  }

  const handleOpenCustomField = (field, index) => {
    setOpenCustomField(index)
  }

  const getCustomComponent = (field, index) => {
    switch (field.type) {
      case 'number':
        return <>
          <div style={{ display: 'flex' }}>
            <TextComponent
              fullWidth
              id={field.label}
              style={{ border: 'inherit' }}
              type="number"
              variant="outlined"
              placeholder={field.label}
              required={!field.optional}
              value={taskDetailsData.taskDetails.custom_fields[field.fieldKey]}
              onChange={(e) => handleChangeInput(e, field)}
              onClick={() => handelFocus(field.fieldKey)}
              // onBlur={()=>handelUnFocus(field.fieldKey)}
              // inputProps={{focus}}
              inputProps={{ min: 0, max: 99999999999999 }}
              InputProps={{ classes: { root: classes.numberField } }}
            />
            {
              (field.type === 'number' && field.isOpen) ?
                <div style={{ display: 'flex' }}>
                  <span style={{ cursor: 'pointer', color: '#828282' }}><ClearIcon onClick={() => handelCloseButtoView(field)} /></span>
                  <span style={{ cursor: 'pointer', color: '#828282' }}><CheckIcon onClick={() => handelCustomNumber(field)} /></span>
                </div> : null
            }
          </div>
        </>

      case 'text':
        return < >
          <span style={{ display: 'flex' }}>
            <TextComponent
              fullWidth
              id={field.label}
              // label={field.placeholder === 'context' ? field.label : ''}
              type={field.type}
              variant="outlined"
              maxLength={25}
              placeholder={field.placeholder === 'descriptive' ? field.label : ''}
              required={!field.optional}
              value={taskDetailsData.taskDetails.custom_fields[field.fieldKey]}
              onChange={(e) => handleChangeInput(e, field)}
              style={{ width: '50%' }}
              inputProps={{ maxLength: 25 }}
              InputProps={{ classes: { root: classes.fieldWidth } }}
            />
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ cursor: 'pointer', color: '#828282' }}><ClearIcon onClick={() => hadleCustomCancelButton(field)} /></span>
              <span style={{ cursor: 'pointer', color: '#828282' }}><CheckIcon onClick={() => updateCustomFiledData(taskDetailsData.taskDetails.custom_fields)} /></span>
            </span>
          </span>
        </>
      case 'date':
        return <DateComponent
          views={["date"]}
          format={"DD MMM yyyy"}
          variant="inline"
          autoOk
          fullWidth

          id={field.label}
          // label={field.label}
          placeholder={"DD MM YYYY"}
          disableToolbar
          className={classes.datePickerStyles}
          required={!field.optional}
          InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <ExpandMore /> }}
          // minDate={props.fromEdit ? details.start_date : defaultStartDate}
          // minDate={defaultStartDate}
          value={taskDetailsData.taskDetails.custom_fields[field.fieldKey] || null}
          onChange={(date) => handleChangeDate(date, field.fieldKey)}
        />
      case 'paragraph':
        return <TextComponent
          fullWidth
          id={field.label}
          label={field.placeholder === 'context' ? field.label : ''}
          placeholder={field.placeholder === 'descriptive' ? field.label : ''}
          multiline
          variant="outlined"
          rowsMin={3}
          // rows={3}
          // rowsMax={4}
          maxLength={500}
          required={!field.optional}
          InputProps={{ classes: { root: classes.fieldWidth } }}
          inputProps={{ maxLength: 500 }}
          value={taskDetailsData.taskDetails.custom_fields[field.fieldKey]}
          onChange={(e) => handleChangeInput(e, field)}
        />
      case 'select':
        return <AutocompleteComponent
          fullWidth
          id={field.label}
          options={field.options}
          getOptionLabel={(option) => option.label || ''}
          classes={{ inputRoot: classes.autocompleteRoot }}
          // endAdornment={() => { null }}
          value={taskDetailsData.taskDetails.custom_fields[field.fieldKey] || {}}
          onChange={(...args) => handleChangeAutocomplete(args[1], field.fieldKey)}
          InputProps={{ endAdornment: () => null }}
          renderInput={(params) => <TextField placeholder="select"
            {...params} required={!field.optional} variant="outlined" style={{ cursor: 'pointer' }} />}
        />
      default:
        return null;
    }
  }
  const getCustomFields = (
    <>
      {
        customFields.length > 0 && customFields.filter(ele => ele.placeholder === 'descriptive').map((field, index) => (
          <div>
            <p className={classes.lables} onClick={() => setOpenCustomField(index)}>{field.label.charAt(0).toUpperCase() + field.label.slice(1)}</p>
            <p className={classes.valueStyles} onClick={() => handleOpenCustomField(field, index)}> {openedCustomField !== index ? taskDetailsData.taskDetails.custom_fields[field.fieldKey] : ''}
            </p>
            {
              openedCustomField === index ?
                <>
                  {getCustomComponent(field, index)}
                  {
                    field.type != 'text' ?
                      <div style={{ textAlign: "right", marginTop: 2 }}>
                        <span style={{ cursor: 'pointer', color: '#828282', }}><ClearIcon onClick={() => hadleCustomCancelButton(field)} /></span>
                        <span style={{ cursor: 'pointer', color: '#828282', marginLeft: 4 }}><CheckIcon onClick={() => updateCustomFiledData(taskDetailsData.taskDetails.custom_fields)} /></span>
                      </div>
                      : null
                  }

                </>
                : ''}

          </div>
        ))

      }
    </>
  )

  const handleDeletePhoto = (e, index, isNewAttachement) => {
    let detailsDup = JSON.parse(JSON.stringify(taskDetailsData.taskDetails));
    if (props.fromEdit && isNewAttachement)
      detailsDup.newAttachments.splice(index, 1);
    else {
      if (props.fromEdit && detailsDup.attachments[index].id && !isNewAttachement)
        detailsDup.deleteAttachmentIds = [...(detailsDup.deleteAttachmentIds || []), detailsDup.attachments[index].id]
      detailsDup.attachments.splice(index, 1);
    }
    props.updateTask({ requestObj: { attachments: detailsDup.attachments }, id: detailsDup._id, onSuccessCB: onAttachUploadSuccessCB, onErrorCB: onAttachUploadErrorCB })
    enqueueSnackbar(`Attachment Deleted.`, {
      variant: 'success',
      preventDuplicate: true
    });
    setTaskDetailsData(prevState => ({ ...prevState, taskDetails: detailsDup }));
  }
  ///---- condition buttons

  const handleFilterClick = (value, showData) => {
    if (filter !== value) setFilter(value);
    switch (showData) {
      case 'showComment':
        setShowComment(true)
        setShowHistory(false)
        setShowWorkLogs(false)
        break;
      case 'showHistory':
        setShowComment(false)
        setShowHistory(true)
        setShowWorkLogs(false)
        break;
      case 'showWorkLogs':
        setShowComment(false)
        setShowWorkLogs(true)
        setShowHistory(false)
        break;

      default:
        break;
    }
  };
  // handle cancle  
  const handleCancelButtom = (stateKeyName) => {
    let detailsObj = { ...taskDetailsData.taskDetails }
    if (stateKeyName == 'showDescription') {
      detailsObj['description'] = initialTaskDetails.taskDetails.description
      setTaskDetailsData({ ...taskDetailsData, taskDetails: detailsObj })
      setShowDescription(false)
    }
    else if (stateKeyName == 'subTaskInput') {
      setSubTaskInput(false)
      setSubTaskData({ ...subTaskData, hasError: false })
    }
    else if (stateKeyName == 'showTitle') {
      detailsObj['title'] = initialTaskDetails.taskDetails.title
      setTaskDetailsData({ ...taskDetailsData, taskDetails: detailsObj })
      setShowtitle(false)
    }
    else if (stateKeyName == 'estimation') {
      detailsObj['estimation'] = initialTaskDetails.taskDetails.estimation
      setTaskDetailsData({ ...taskDetailsData, taskDetails: detailsObj })
      setShowEstimationField(false)
    }
  }

  const checkForm = (filedVal) => {
    if (typeof filedVal != 'number') {
      if (filedVal.trim() === '') return false
      else return true
    }
    else return filedVal >= 0;
  }

  const handleUpdateTaskDetails = (type, value) => {
    let taskObject = {};
    // task_id:props.match.params.taskid
    if (type == 'description') {
      taskObject.description = value;
      setShowDescription(!showDescription)
    }
    else if (type == 'title') {
      taskObject.title = value;
    }
    else if (type === 'status') {
      taskObject.priority = value;
    }
    else if (type === 'current_stage') {
      const stage = taskDetailsData.stages.find(stage => stage._id === value);
      setCurrentStage({ key: stage.key })
      taskObject.current_stage = value;
    }
    else if (type === 'reported_by') {
      taskObject.reported_by = value;
      changeMembersImage(projectMembers, { assignee: '', reporte: value })
    }
    else if (type === 'assigned_to') {
      taskObject.assigned_to = value;
      changeMembersImage(projectMembers, { assignee: value, reporte: '' })
    }
    else if (type === 'estimation') {
      taskObject.estimation = value;
    }
    else if (type === 'targetDate') {
      taskObject.target_date = value;
    }
    console.log(taskObject)
    if ((type === 'description' || type === 'targetDate') || checkForm(value)) {
      props.updateTask({ requestObj: taskObject, id: taskDetailsData.taskDetails._id, onSuccessCB: onTaskUpdateSuccessCB, onErrorCB: onTaskUpdateErrorCB })
    }
  }
  const onTaskUpdateErrorCB = () => {

  }
  const handleDateChange = (date) => {
    let dateData = { ...taskDetailsData.taskDetails };
    dateData['target_date'] = moment(date._d).endOf('day');
    setTaskDetailsData(prevState => ({ ...prevState, taskDetails: dateData }))
    handleUpdateTaskDetails('targetDate', moment(date._d).endOf('day'))
  }
  const handleSubTaskStatusChange = (e, index) => {
    let subTaskObj = { ...taskDetailsData.taskDetails.subTasks[index] };
    subTaskObj['current_stage'] = e.target.value;
    props.updateTask({ requestObj: { "current_stage": e.target.value }, id: subTaskObj.id, onSuccessCB: onTaskUpdateSuccessCB, onErrorCB: onTaskUpdateErrorCB })
    let subTasks = [...taskDetailsData.taskDetails.subTasks];
    subTasks[index]['current_stage'] = e.target.value;
    setTaskDetailsData(prevState => ({ ...prevState, subTasks: subTasks }));
  }

  const handlePriorityChange = (e) => {
    handleUpdateTaskDetails('status', e.target.value)
    let detailsObj = { ...taskDetailsData.taskDetails }
    detailsObj['priority'] = e.target.value
    setTaskDetailsData(prevState => ({ ...prevState, taskDetails: detailsObj }));
  }
  const handleAssineeChange = (e) => {
    if (e.target.value != '') {
      handleUpdateTaskDetails('assigned_to', e.target.value)
      let detailsObj = { ...taskDetailsData.taskDetails }
      detailsObj['assigned_to'] = e.target.value
      setTaskDetailsData(prevState => ({ ...prevState, taskDetails: detailsObj }));
    }
  }
  const handleEstimationChange = (e) => {
    let detailsObj = { ...taskDetailsData.taskDetails }
    detailsObj['estimation'] = e.target.value;
    setTaskDetailsData(prevState => ({ ...prevState, taskDetails: detailsObj }));
  }

  const handleEstimationUpdate = () => {
    if (taskDetailsData.taskDetails.estimation > 0) {
      handleUpdateTaskDetails('estimation', taskDetailsData.taskDetails.estimation);
      setShowEstimationField(false)
    }
  }

  const getStoryLogos = (type) => {
    switch (type) {
      case 'Story':
        return <Tooltip title="Story"><BookmarkIcon className={classes.issueTypeStory} /></Tooltip>
      case 'Task':
        return <Tooltip title="Task"><AssignmentTurnedInIcon className={classes.issueTypeTask} /></Tooltip>
      default:
        return <Tooltip title="Bug"><BugReportIcon className={classes.priorityHigh} /></Tooltip>
    }
  }

  const onTaskUpdateSuccessCB = () => {
    enqueueSnackbar(`Updated Successfully.`, {
      variant: 'success',
      preventDuplicate: true
    });
    setInitialTaskDetails({ ...taskDetailsData })
    if (showTitle) setShowtitle(false)
    else if (showDescription) setShowDescription(false)
  }

  const handleStatusChange = (e) => {
    handleUpdateTaskDetails('current_stage', e.target.value)
    let detailsObj = { ...taskDetailsData.taskDetails };
    detailsObj['current_stage'] = e.target.value;
    setTaskDetailsData(prevState => ({ ...prevState, taskDetails: detailsObj }));
  }

  const handelCopyLink = (taskId, isSubTask) => {
    const text = `${process.env.REACT_APP_PUBLIC_BASE_URL}s/${props.match.params.id}/tasks/${taskId}`
    window.navigator.clipboard.writeText(text);
    enqueueSnackbar("Copied to clipboard", {
      variant: "success",
      preventDuplicate: true
    });
  }

  const shareTaskToChat = (details) => {
    const link = `${process.env.REACT_APP_PUBLIC_BASE_URL}s/${props.match.params.id}/tasks/${details.task_id}`
    props.openShareTaskDialog({
      open: true, details: {
        board: details.projectName,
        taskLink: link,
        taskTitle: details.title,
        taskId: details.task_id,
        assigneId: details.assigned_to ? details.assigned_to : '',
        targetDate: details.target_date ? moment(details.target_date).format('DD MMM YYYY') : '--',
        assigneName: details.assigneeDetails ? details.assigneeDetails.name : '',

      }
    })
  }

  const contextFieldsView = () => (
    <>
      <Grid container spacing={2} className={classes.contextContainer}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <p className={classes.tableLable}>Stage</p>
          <Select
            className={classes.select}
            value={taskDetailsData.taskDetails.current_stage ? taskDetailsData.taskDetails.current_stage : ""}
            onChange={handleStatusChange}
            variant={"outlined"}
            // style={{backgroundColor: `#${getColor(taskDetailsData.stages.findIndex(ele=>ele._id===taskDetailsData.taskDetails.current_stage))}`}}
            classes={{ outlined: classes.privacySelect, iconOutlined: classes.selectIcon, notchedOutline: classes.fieldBorder, selectMenu: classes.selectMenuStyles }}
            IconComponent={ExpandMore}
          >
            {
              taskDetailsData.stages && taskDetailsData.stages.length > 0 ?
                taskDetailsData.stages.map((stage, ind) => (
                  <MenuItem key={ind} value={stage._id} style={{ backgroundColor: `#${getColor(ind)}`, borderRadius: 5, margin: "3px 0px" }} className={classes.selectElement} >{stage.label}</MenuItem>
                )) : <MenuItem value={-1} style={{ color: '#dfe1e6' }} className={classes.selectElement} >Back logger</MenuItem>
            }
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} >
          <p className={classes.tableLable}>Assignee</p>
          <span className={classes.avatarAgjustment}>
            {taskDetailsData.taskDetails.assigned_to ?
              <Avatar
                id="profileImg1"
                classes={{ colorDefault: classes.avatarStyles }}
                style={{ height: 20, width: 20, marginRight: 5 }}
                src={memberImage.assigne.photoUrl || ''}
              >
                {(memberImage.assigne.name && getAvatarText(memberImage.assigne.name))}
              </Avatar>
              : null}
            <Select
              className={classes.select}
              label="Select assignee"
              value={taskDetailsData.taskDetails.assigned_to ? taskDetailsData.taskDetails.assigned_to : ""}
              onChange={handleAssineeChange}
              variant={"outlined"}
              classes={{ outlined: classes.privacySelect, iconOutlined: classes.selectIcon, selectMenu: classes.selectMenuStyles }}
              IconComponent={ExpandMore}
            >
              {
                projectMembers && projectMembers.length > 0 ?
                  projectMembers.map((member, index) => (
                    <MenuItem key={index} value={member.username} className={classes.selectElement}>{member.name}</MenuItem>
                  ))
                  : <MenuItem value={''} className={classes.selectElement}><em>None</em></MenuItem>
              }
            </Select>
          </span>
        </Grid>


        <Grid item xs={12} sm={6} md={6} lg={6} >
          <p className={classes.tableLable}> Priority</p>
          <Select
            id="id- Priority"
            value={taskDetailsData.taskDetails.priority}
            onChange={handlePriorityChange}
            variant={"outlined"}
            classes={{ outlined: classes.privacySelect, iconOutlined: classes.selectIcon }}
            style={{ borderRadius: 4, height: 26 }}
            IconComponent={ExpandMore}
          >
            <MenuItem value={2}><ArrowUpwardIcon className={classes.priorityHigh} /> High</MenuItem>
            <MenuItem value={1}><ArrowUpwardIcon className={classes.priorityMedium} /> Medium</MenuItem>
            <MenuItem value={0}><ArrowDownwardIcon className={classes.priorityLow} />Low</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} >
          <p className={classes.tableLable}>Reporter</p>
          <span className={classes.avatarAgjustment}>
            <Avatar
              id="profileImg2"
              style={{ height: 20, width: 20, marginRight: 5 }}
              classes={{ colorDefault: classes.avatarStyles }}
              src={memberImage.reporte.photoUrl || ''}
            // onClick={() => details.photoUrl && handlePreview([{ url: details.photoUrl, mimeType: 'IMAGE' }], 0)}
            >
              {(memberImage.reporte.name && getAvatarText(memberImage.reporte.name))}

            </Avatar>
            <span className={classes.contentValueStyles}>{memberImage.reporte.name}</span>
          </span>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} >
          <p style={{ width: '100px' }} className={classes.tableLable}> Estimation</p>
          <span >
            {/* { */}
            <FormControl style={{ width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  value={taskDetailsData.taskDetails.estimation}
                  id="outlined"
                  placeholder="In Hrs"
                  onChange={(e) => handleEstimationChange(e)}
                  // onBlur={()=>setShowEstimationField(false)}
                  // onKeyPress={(e) => handleEstimationUpdate(e)}
                  focused={showEstimationField}
                  variant="outlined"
                  fullWidth
                  required
                  onClick={() => setShowEstimationField(true)}
                  type="number"
                  // error={taskDetailsData.taskDetails.estimation == '' || taskDetailsData.taskDetails.estimation <= 0}
                  // helperText={taskDetailsData.taskDetails.estimation <= 0 && 'Must be greater than 0'}
                  InputProps={{ classes: { root: classes.numberField } }}

                />
                {
                  showEstimationField ?
                    <>
                      <span style={{ cursor: 'pointer', color: '#828282' }}><ClearIcon onClick={() => handleCancelButtom('estimation')} /></span>
                      <span style={{ cursor: 'pointer', color: '#828282', marginRight: 5 }}><CheckIcon onClick={() => handleEstimationUpdate()} /></span>
                    </> : null
                }
              </div>

            </FormControl>


            {/* // : <span className={!showEstimationField ? classes.estimationValueStyles : {}} onClick={() => setShowEstimationField(true)}>{taskDetailsData.taskDetails.estimation}</span> */}
            {/* } */}
          </span>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} >
          <p className={classes.tableLable}>Target Date</p>
          <DatePicker
            views={["date"]}
            format={"DD MMM YYYY"}
            // formatDate={(date)=>{return }}
            variant="inline"
            placeholder={"DD MM YYYY"}
            value={(taskDetailsData.taskDetails.target_date) ? taskDetailsData.taskDetails.target_date : null}
            onChange={handleDateChange}
            disabled={currentStage.key === 'DONE'}
            disableToolbar
            autoOk
            helperText=""
            className={classes.datePickerStyles}
            InputProps={{ error: false, classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine, iconOutlined: classes.selectIcon }, endAdornment: <ExpandMore /> }}
          />
        </Grid>
        {
          customFields.length > 0 && customFields.filter(ele => ele.placeholder === 'context').map((field, index) => (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <p className={classes.tableLable}>{field.label}</p>
              {
                getCustomComponent(field, index)
              }
            </Grid>
          ))
        }
      </Grid>
      <Grid container className={classes.contextContainer} style={{ marginTop: 20, marginLeft: 0 }}>
        <Grid xs={12} sm={12} md={12} lg={12}>
          <p className={classes.timeValueStyles}>
            Created : <Moment fromNow className={classes.contentValueStyles} format={postCreatedAtFormat(taskDetailsData?.taskDetails?.created_at)}>
              {taskDetailsData.taskDetails.created_at}
            </Moment>
          </p>
          <p className={classes.timeValueStyles}>
            Last Updated : <Moment fromNow className={classes.contentValueStyles} format={postCreatedAtFormat(taskDetailsData?.taskDetails?.updated_at)}>
              {taskDetailsData.taskDetails.updated_at}
            </Moment>
          </p>
          {
            (currentStage.key === 'DONE' && taskDetailsData.taskDetails.closed_at) ?
              <p className={classes.timeValueStyles}>
                Closed on : <Moment format="DD MMM YYYY" className={classes.contentValueStyles}>
                  {taskDetailsData.taskDetails.closed_at}
                </Moment>
              </p>
              : null
          }
        </Grid>
        {/* <Grid  xs={12} sm={12} md={12} lg={12}>
          
          
        </Grid> */}
      </Grid>
    </>
  );

  const descriptiveFieldsView = () => (
    <>
      <p className={classes.lables} onClick={() => { setShowDescription(true) }}>Description</p>

      {showDescription ?
        <div style={{ marginTop: 5 }}>
          <TextField
            maxLength={500}
            id="description"
            value={taskDetailsData.taskDetails.description ? taskDetailsData.taskDetails.description : ''}
            placeholder="Description"
            fullWidth
            variant="outlined"
            multiline
            placeholder="Description"
            InputProps={{ classes: { root: classes.fieldWidth } }}
            onChange={
              handleDescriptionChage
            }
          />
          <div style={{ textAlign: "right", marginTop: 2 }}>
            <span style={{ cursor: 'pointer', color: '#828282', }}><ClearIcon onClick={() => handleCancelButtom('showDescription')} /></span>
            <span style={{ cursor: 'pointer', color: '#828282', marginLeft: 4 }}><CheckIcon onClick={(e) => handleUpdateTaskDetails("description", taskDetailsData.taskDetails.description)} /></span>
          </div>
        </div> :
        // <div>
        //   {
        //     taskDetailsData.taskDetails.editorText ?
        //     <div className="preview" dangerouslySetInnerHTML={{__html:convertCommentFromJSONToHTML(editorState)}}/>  
        //     :null
        //   }
        // </div>
        <div>
          <p className={classes.valueStyles} onClick={() => {
            setShowDescription(true)
          }}> {taskDetailsData.taskDetails.description}
          </p>
        </div>
      }
      {getCustomFields}

      {getAttachmentsList}

    </>
  );
  //  const convertCommentFromJSONToHTML = (text) => {
  //       return stateToHTML(convertFromRaw(text));
  //   }
  // const createMarkup = (html) => {
  //   console.log(editorState)
  //   console.log(html)
  //   return  {
  //     __html: DOMPurify.sanitize(html)
  //   }
  // }
  // ageing part end
  const { path, url, params } = useRouteMatch();
  return (
    <>
      <Grid container className={classes.gridContainer}>
        {/* <Route path={`/product-management`} > */}
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 5 }}>
          <Breadcrumb component='taksdetails' history={props.history} projectName={taskDetailsData.taskDetails.projectName || "Board"} params={props.match.params} type={taskDetailsData.taskDetails.task_type} isSubTask={props.isSubTask} />
        </div>
        <Grid item className={classes.scrollCss}>
          {showTitle ?
            <span>
              <div className={classes.commentsPlaceholder}>
                {getStoryLogos(taskDetailsData.taskDetails.task_type)}
                {/* <FormControl fullWidth error={taskDetailsData.taskDetails.title.trimStart() === ''}> */}
                <TextField
                  fullWidth
                  value={taskDetailsData.taskDetails.title}
                  onChange={handleTitleChageData}
                  required
                  variant="outlined"
                  InputProps={{ classes: { root: classes.fieldWidth } }}
                  multiline
                  id="outlined-required"
                  placeholder="Title"
                />
              </div>

              <div style={{ textAlign: "right", marginTop: 2 }}>
                <span style={{ cursor: 'pointer', color: '#828282', }}><ClearIcon onClick={() => handleCancelButtom('showTitle')} /></span>
                <span style={{ cursor: 'pointer', color: '#828282', marginLeft: 4 }}><CheckIcon onClick={(e) => handleUpdateTaskDetails("title", taskDetailsData.taskDetails.title)} /></span>
              </div>
            </span> :
            <div style={{ display: 'flex' }}>
              <span style={{ marginTop: 3, fontSize: 'x-large' }}>{getStoryLogos(taskDetailsData.taskDetails.task_type)}</span>
              <p className={classes.title} onClick={() => { setShowtitle(true) }}>
                {taskDetailsData.taskDetails.title}
              </p>
            </div>
          }
        </Grid>
      </Grid >


      <Grid container spacing={2}>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <div className={classes.alignActionItems}>
            {/* <p style={{ margin: "5px 5px 5px 0px", display: 'flex' }}> */}
            <span style={{ alignItems: 'center', display: 'flex' }}>
              {currentStage.key === 'DONE' ?
                <CompletedItemAgeing targetDate={taskDetailsData.taskDetails.target_date} completedDate={taskDetailsData.taskDetails.closed_at} />
                :
                <ShowAgeingInfo targetDate={taskDetailsData.taskDetails.target_date} />
              }
            </span>
            {/* </p>  */}
            {/* <div className={classes.itemsGridView}> */}
            <input type="file"
              multiple
              id={`hiddenFileInputForTask`}
              onChange={(e) => handleFileChange(e)}
              style={{ display: 'none' }}
              accept=".xlsx,.xls,.csv,image/*,.doc,.docx,.pdf" //video/*
            />
            <Button variant="text" onClick={() => handleAddPhoto()} className={classes.actionButton}><AttachmentIcon style={{ marginRight: 5, transform: 'rotate(270deg)' }} /><span className={classes.viewSubtaskIcon} >Attach</span></Button>
            {!props.isSubTask && <Button variant="text" className={classes.actionButton} onClick={() => { setSubTaskInput(true) }}>
              <LibraryAddCheckOutlinedIcon style={{ marginRight: 5 }} /> <span className={classes.viewSubtaskIcon} >Add child work item</span>
            </Button>}
            <Button variant="text" className={classes.actionButton} onClick={() => handelCopyLink(taskDetailsData.taskDetails.task_id, props.isSubTask)}>
              <CopyIcon style={{ width: 13, marginRight: 5 }} /><span className={classes.viewSubtaskIcon} > Copy </span>
            </Button>
            <Button variant="text" className={classes.actionButton} onClick={() => shareTaskToChat(taskDetailsData.taskDetails)}>
              <Share style={{ marginRight: 5 }} /><span className={classes.viewSubtaskIcon} >Share</span></Button>
            {/* </div> */}
          </div>

          {showWebLink ? <div>
            <p className={classes.lables}>Web links</p>
            <p className={classes.descriptionData}></p>
            <Button onClick={() => { handleshowWebLink(showWebLink) }}> cancle</Button>
          </div> : ''}
          {descriptiveFieldsView()}
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          {contextFieldsView()}
        </Grid>
      </Grid>
      <Grid container>
        <div className={classes.alignSubtasks}>
          {taskDetailsData.taskDetails.subTasks.length > 0 ?
            <>
              <p className={classes.lables}>Subtasks</p>
              <List component="nav" style={{ border: "solid 1px #b4b4b4", borderBottom: "none", borderRadius: 3, marginTop: 15, padding: 0 }}>
                {taskDetailsData.taskDetails.subTasks.map((subTaskData, index) => (
                  <ListItem button className={classes.subtaskfields} key={index} onClick={() => setSubTaskToTaskComponent(subTaskData.task_id)}>
                    <div style={{ display: "flex", width: "100%", justifyContent: 'space-between' }} >
                      <div style={{ display: 'flex', alignItems: 'center' }} >
                        <ListItemIcon style={{ minWidth: 35 }}>
                          <ListOutlinedIcon />
                        </ListItemIcon>
                        <p style={{ margin: 0, color: '#8993a4' }}>
                          <span className={classes.lables} style={{ color: '#8993a4', fontSize: 12, marginRight: 5 }}>{subTaskData.task_id}</span>
                          <span className={classes.viewSubtaskIcon} style={{ width: "100%", fontSize: 14, color: '#172b4db3' }}> {getEllipsisText(subTaskData.title, 40)}</span>
                        </p>
                      </div>
                      <div >
                        <Select
                          id="select-stage"
                          value={subTaskData.current_stage && subTaskData['current_stage'] ? subTaskData['current_stage'] : 0}
                          onChange={(e) => handleSubTaskStatusChange(e, index)}
                          onClick={(e) => e.stopPropagation()}
                          variant="outlined"
                          style={{ backgroundColor: `#${getColor(taskDetailsData.stages.length && taskDetailsData.stages.findIndex(ele => ele._id === subTaskData.current_stage))}` }}
                          className={classes.subtaskStatusStyles}
                          endAdornment={null}
                          classes={{ outlined: classes.subtaskStatusSelect, iconOutlined: classes.selectIcon }}
                        // IconComponent={ExpandMore}
                        >
                          {
                            taskDetailsData.stages && taskDetailsData.stages.length > 0 ?
                              taskDetailsData.stages.map((stage, ind) => (
                                <MenuItem key={ind} value={stage._id} style={{ backgroundColor: `#${getColor(ind)}`, borderRadius: 5, margin: "3px 0px" }} className={classes.selectElement} >{stage.label}</MenuItem>
                              )) : <MenuItem value={-1} style={{ color: '#dfe1e6' }} className={classes.selectElement} >Back logger</MenuItem>
                          }
                        </Select>
                      </div>
                    </div>
                  </ListItem>
                ))}
              </List>
            </>
            : null}
          {subTaskInput ?
            <>{
              taskDetailsData.taskDetails.subTasks.length == 0 ?
                <p style={{ marginBottom: 10 }} className={classes.lables}>Subtasks</p>
                : ''
            }
              <div className={classes.descriptionFieldView}>
                {/* <p className={classes.lables}>Subtasks</p> */}
                <TextareaAutosize
                  id="nextSubtaskField"
                  aria-label="Message Input textarea"
                  className={classes.input}
                  rowsMax={2}
                  placeholder="Child work item title"
                  onChange={(e) => {
                    handleSubTaskCreation(e)
                  }}
                />
                <div style={{ textAlign: "right", marginTop: 2 }}>
                  <span style={{ cursor: 'pointer', color: '#828282', }}><ClearIcon onClick={() => handleCancelButtom('subTaskInput')} /></span>
                  <span style={{ cursor: 'pointer', color: '#828282', marginLeft: 4 }}><CheckIcon onClick={(e) => handleSubTaskRequest()} /></span>
                </div>
              </div>
            </> : ""}
        </div>
      </Grid>
      {/* <br/> */}
      <div className={classes.alignSubtasks}>
        <p className={classes.lables} >Activities</p>
        <div className={classes.filtersRoot} >
          <span> Show:</span>
          <Button variant="text" className={classNames(classes.filterButton, filter === "Comments" && "isActive")}
            onClick={() => handleFilterClick("Comments", 'showComment')}>
            <CommentIcon style={{ height: 16, width: 16 }} />
            <span className={classes.buttonText}>Comments</span>
          </Button>
          <Button
            variant="text"
            className={classNames(
              classes.filterButton,
              filter === "WorkLogs" && "isActive"
            )}
            onClick={() => handleFilterClick("WorkLogs", 'showWorkLogs')}>
            <TimerIcon style={{ height: 16, width: 16 }} />
            <span className={classes.buttonText}>TimeLog</span>
          </Button>
        </div>

        {showComment ? <Comment handleNewComment={props.handleNewTask} task_id={props.match.params.subTaskId ? props.match.params.subTaskId : props.match.params.taskid} /> : ''}
        {showHistory ? <TaskHistory  {...props} /> : ''}
        {showWorkLogs ? <WorkLogs workLog={props.workLog} handleWorkLog={props.handleWorkLog} task_id={props.match.params.subTaskId ? props.match.params.subTaskId : props.match.params.taskid} /> : ''}
      </div>
    </>

  );
};

const mapStateToProps = props => {
  return {
    // tasks: (value) => props.getTasks(value)
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    getTaskDetails: (options) => dispatch(getTaskDetails(options)),
    setLoading: value => dispatch(setLoading(value)),
    getProjectMembers: (options) => dispatch(getProjectMembers(options)),
    uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options)),
    updateTask: (options) => dispatch(updateTask(options)),
    createSubTask: (options) => dispatch(createSubTask(options)),
    getArenaProfile: (options) => dispatch(getArenaProfile(options)),
    openShareTaskDialog: (options) => dispatch(openShareTaskDialog(options))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskDetails));;
