import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { groupBy } from 'underscore';
import { getLeaguesList, getSeasonsList, getMatchesList, getRoundsList, getMatchesListV2 } from '../../ducks/game';
import { formatDate, getBase64FromUrl, getSearchValue, setSearchQuery } from "../../helpers";
import { setLoading } from "../../ducks/loading";
import RoleSummaryLists from './version1/RoleSummaryLists';
import DivisionSummaryLists from './version1/DivisionSummaryLists';
import DetailedRolePlayerSummary from './version1/DetailedRolePlayerSummary';
import DetailedDivisionPlayerSummary from './version1/DetailedDivisionPlayerSummary';
import { Tab, Tabs } from '@material-ui/core';
import { CustomListGroup, RoundsListGroup } from './RoundsListView';
import RoundPlayerSummary from './RoundPlayerSummary';
import RaceSummary from './RaceSummary';
import RoundPlayerSummaryViewAll from './RoundPlayerSummaryViewAll';
import MatchDetails from './MatchDetails';
import { Grid } from 'react-virtualized';
import TopFivePlayerSummary from './TopFivePlayerSummary';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fafafa',
        // width: '100%',
        // height: 'calc(100vh - 60px)',
        // overflowY: 'auto',
        // display: 'flex',
        // [theme.breakpoints.down('xs')]: {
        //     maxHeight: 'calc(100vh - 80px)',
        //     maxWidth: `calc(100vw - 150px)`,
        //     padding: '20px 15px 0px',
        // },
    },
    filtersRoot: {
        marginBottom: 15,
        padding: '20px 50px 0px',
        width: 'calc(100% - 100px)'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '5px 5px !important',
        fontSize: 13,
        backgroundColor: '#FFF'
    },
    label: {
        fontSize: 13,
    },
    roundIconButton: {
        fontSize: 12,
        padding: '0 12px',
        height: 20,
        borderRadius: 10,
        margin: '0 4px',
        pointerEvents: 'auto !important'
    },
    roundIconButtonSelected: {
        color: 'black',
        backgroundColor: '#C4C4C4'
    },
    roundsLableStyle: {
        fontWeight: 500,
        fontSize: 18,
        margin: '0 20px 0 0',
        // color: '#0000008a'
    },
    avatarStyles: {
        fontSize: 13,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 26,
        width: 26,
        border: '2px solid #FFF'
        // marginRight:5,
    },
    cardHeights: {
        maxWidth: '100%',
        // minHeight: '24vh',
        maxHeight: '60px',
        cursor: 'pointer',
        boxShadow: 'none',
        border: '1px solid #b4b4b4',
        "&:hover": {
            background: "#EEEEEE",
            borderBottom: `1px solid ${theme.palette.primary.main}`,

        }
        // minHeight: 150,
        // maxHeight: 153
    },
    vsStyles: {
        margin: '0px',
        fontSize: 12,
        // fontStyle: 'oblique',
        padding: '0px',
        // backgroundColor: '#c4c4c4',
        // borderRadius: 10,
        // height: 34,
        // width: 34,
        textAlign: 'center',
        color: '#0000008a'
    },
    teamName: {
        margin: 0,
        width: '75px',
        padding: 5,
        overflow: 'hidden',
        fontSize: '13px',
        // fontWeight: '600',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
            // width: 100
        }
    },
    matchTitle: {
        fontSize: 14,
        margin: '5px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        color: '#000',
        [theme.breakpoints.down('xs')]: {
            margin: '5px 0',
            // display: 'inline-grid'
        }
    },
    matchView: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #c4c4c48c',
        borderRadius: 30,
        cursor: 'pointer',
        maxWidth: 270,
        backgroundColor: '#FFF',
        "&:hover": {
            backgroundColor: `${theme.palette.primary.light}`,
            color: 'black'
        }
    },
    selectedMatch: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    filedSetRootStyles: {
        padding: '10px 20px 20px',
    },
    legendRootStyles: {
        fontSize: 14
    },
    viewDetailsButton: {
        // margin: '15px 2px 0px 0',
        color: 'blue', // #112386
        fontSize: 12,
        // float: 'right',
        cursor: 'pointer',
        // fontWeight: 600,
        '&:hover': {
            textDecoration: 'underline',
        },
        '& svg': {
            fontSize: 18,
            marginLeft: 4,
            verticalAlign: 'sub'
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    legendStyles: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 8,
            cursor: 'pointer',
            fontSize: 16

        }
    },
    leagueFilter: {
        margin: '20px 0px 0px 0px',
        '& .MuiTab-textColorPrimary.Mui-selected': {
            background: theme.palette.primary.main,
            borderRadius: 6,
            color: 'white'
        }
    },
    profileTabsRoot: {
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: '4px',
        padding: 5,
        width: 'max-content',
        height: 'auto',
        minHeight: 'auto'
    },
    tabIndicatorProfile: {
        display: 'none'
    },
    profileTabRoot: {
        minWidth: 55,
        fontSize: 14,
        color: "#535353",
        textTransform: 'capitalize',
        minHeight: '30px',
        padding: '2px 10px'
    },
}));

export const matchStatusList = [
    {
        name: 'Fixture',
        id: 'fixtures'
    },
    {
        name: 'Live',
        id: 'live'
    },
    {
        name: 'Awaiting',
        id: 'completed'
    },
    {
        name: 'Closed',
        id: 'closed'
    },
];

const defaultFilters = {
    league: null,
    season: null,
    round: null,
    status: 'LIVE',
    match: null
};

export const GetDates = ({ filtersData }) => {
    return (
        <span style={{ marginLeft: 10 }}>
            {(filtersData.season?.season_format === 'HEAD_TO_HEAD' && filtersData.round) ?
                <span style={{ color: '#0000008a', fontSize: 9, fontWeight: 400 }}>({formatDate(filtersData.round?._id === 'ALL' ? filtersData.season?.start_date : filtersData.round?.start_date)} - {formatDate(filtersData.round?._id === 'ALL' ? filtersData.season?.end_date : filtersData.round?.end_date)})</span>
                :
                filtersData.match ?
                    <span style={{ color: '#0000008a', fontSize: 9, fontWeight: 400 }}>({formatDate(filtersData.match?._id === 'ALL' ? filtersData.season?.start_date : filtersData.match?.start_date)} - {formatDate(filtersData.match?._id === 'ALL' ? filtersData.season?.end_date : filtersData.match?.end_date)})</span>
                    : null}
        </span>
    )
}

const subTabs = [{ label: 'All', icon: '', path: 'all' }, { label: 'By Match', icon: '', path: 'byMatch' }]
const liveScoreTabs = [{ id: 'role', name: 'Role', value: 0 }, { id: 'division', name: 'Division', value: 1 },]

const PlayerLiveScores = ({ getLeaguesList, keycloak, filtersData, setFiltersData, getSeasonsList, getMatchesList, getRoundsList, setLoading, loading = true, history, location, userDetails, orgDetails, roles, teams, getMatchesListV2, divisions, isAdmin }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { path } = useRouteMatch();
    const [tenantLogoDataUrl, setTenantLogoDataUrl] = useState(null);
    const [rounds, setRounds] = useState([]);
    const [matches, setMatches] = useState([]);
    const [matchesByDiv, setMatchesByDiv] = useState(null);
    const [sprintMatches, setSprintMatches] = useState([]);
    const [playerSprintMatches, setPlayerSprintMatches] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [viewType, setViewType] = useState('LIST');
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [Filter, setFilter] = useState({});
    const [livescrTab, setLivescrTab] = useState(0);


    useEffect(() => {
        if (['role', 'division'].includes(location?.pathname.split('/')[5])) {
            setLivescrTab(liveScoreTabs.find(el => el?.id === location?.pathname.split('/')[5])?.value);
        }
    }, []);

    useEffect(() => {
        const searchValue = getSearchValue(history, 'match');
        !searchValue && setSearchQuery(history, { match: filtersData?.liveMatchType });
    }, [])

    useEffect(() => {
        if (orgDetails?.logo_url) {
            getBase64FromUrl(orgDetails?.logo_url).then(imageData => {
                setTenantLogoDataUrl(imageData)
            })
        }
    }, [orgDetails?.logo_url])

    useEffect(() => {
        if (filtersData.season?._id) {
            setLoading(true);
            if (filtersData.season.season_format === 'HEAD_TO_HEAD') getRounds(filtersData.season._id);
            else getMatches(filtersData.season?._id, null, 'PLAYER_SPRINT');
            setViewType('LIST');
        }
    }, [filtersData.season?._id]);

    useEffect(() => {
        if (filtersData.round?._id && filtersData.round?._id !== 'ALL') {
            if (['ROUND_ROBIN', 'MANUAL_FORMAT_WITH_ROUND'].includes(filtersData.season?.fixture_format) ? (currentTab === 1) : true) {
                getMatches(filtersData.season?._id, filtersData.round._id, 'TEAM_MATCH');
            }
            setViewType('LIST');
        }
        else {
            setMatches([]);
            setMatchesByDiv(null);
            setSprintMatches([]);
            setPlayerSprintMatches([]);
            setViewType('LIST');
            // setFiltersData(prevState => ({ ...prevState, match: null }));
        }
    }, [filtersData.round, currentTab]);

    const groupByValue = getSearchValue(history, 'viewBy');

    useEffect(() => {
        if (groupByValue) {
            setCurrentTab(groupByValue === 'match' ? 1 : 0);
        }
    }, [groupByValue])


    const getRounds = (seasonId) => {
        setLoading(true);
        getRoundsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setRounds([...result]);
                    const searchValue = getSearchValue(history, 'round');
                    // !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
                    setFiltersData(prevState => ({ ...prevState, match: null, round: ((searchValue && searchValue !== 'ALL') ? result?.find(obj => obj._id === searchValue) : result?.find(obj => obj._id === headers?.latest_round_id) || result[0]) }));
                }
                else {
                    setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                    setRounds([]);
                    setMatches([]);
                    setMatchesByDiv(null);
                    setSprintMatches([]);
                    setPlayerSprintMatches([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league, season: prevState.season }));
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rounds', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getMatches = (seasonId, roundId, type) => {
        setLoading(true);
        getMatchesListV2({
            seasonId: seasonId,
            params: { limit: 5000, page: 1, type, round_id: roundId },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const searchValue = getSearchValue(history, 'match');
                    // const searchValuePlayerMatch = getSearchValue(history, 'playermatch');
                    const finalResult = result.map(obj => ({ ...obj, label: obj?.title, value: obj?._id, pathname: 'match' }));
                    if (type === 'TEAM_MATCH') {
                        if (filtersData.season?.divisions_enabled)
                            setMatchesByDiv(groupBy(result.map(obj => ({
                                ...obj,
                                team1: obj.teams[0],
                                team2: obj.teams[1],
                            })), 'division_name'));
                        else
                            setMatches(result.map(obj => ({
                                ...obj,
                                team1: obj.teams[0],
                                team2: obj.teams[1],
                            })));

                        const groupByValue = getSearchValue(history, 'viewBy');
                        if (groupByValue) {
                            if (searchValue) {
                                setViewType('DETAILS');
                                setSelectedMatch(result?.find(obj => obj._id === searchValue));
                            }
                            else {
                                setViewType('LIST');
                                setSelectedMatch(null);
                            }
                            // setCurrentTab(groupByValue === 'match' ? 1 : 0);
                        }
                    }
                    else {
                        if (filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints) {
                            const teamMatches = finalResult?.filter(obj => obj.type === 'TEAM_SPRINT');
                            const playerMatches = finalResult?.filter(obj => obj.type === 'PLAYER_SPRINT');
                            setSprintMatches([...teamMatches]);
                            setPlayerSprintMatches([...playerMatches]);
                            // !searchValue && setSearchQuery(history, { match: (teamMatches?.find(obj => obj.status === 'live') || teamMatches[0])?._id });
                            // !searchValuePlayerMatch && setSearchQuery(history, { playermatch: (playerMatches?.find(obj => obj.status === 'live') || playerMatches[0])?._id, });
                            let finalSearchValue = (teamMatches?.find(obj => obj._id === searchValue));
                            let finalSearchValuePlayer = (playerMatches?.find(obj => obj._id === searchValue))
                            setFiltersData(prevState => ({ ...prevState, match: finalSearchValue == undefined ? (teamMatches?.find(obj => obj.status === 'live') || teamMatches[0]) : finalSearchValue, playermatch: finalSearchValuePlayer == undefined ? (playerMatches?.find(obj => obj.status === 'live') || playerMatches[0]) : finalSearchValuePlayer }));
                            // setFiltersData(prevState => ({ ...prevState, match: searchValue ? (teamMatches?.find(obj => obj._id === searchValue)) : (teamMatches?.find(obj => obj.status === 'live') || teamMatches[0]), playermatch: searchValue ? (playerMatches?.find(obj => obj._id === searchValue)) : (playerMatches?.find(obj => obj.status === 'live') || playerMatches[0]) }));
                            return;
                        }
                        else {
                            setSprintMatches([...finalResult]);
                            // !searchValue && setSearchQuery(history, { match: (finalResult?.find(obj => obj.status === 'live') || finalResult[0])?._id });
                        }
                    }
                    setFiltersData(prevState => ({ ...prevState, match: searchValue ? finalResult?.find(obj => obj._id === searchValue) : (finalResult?.find(obj => obj.status === 'live') || finalResult[0]) }));
                }
                else {
                    setMatches([]);
                    setMatchesByDiv(null);
                    setSprintMatches([]);
                    setPlayerSprintMatches([]);
                    setFiltersData(prevState => ({ ...prevState, match: null }));
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setMatches([]);
                setMatchesByDiv(null);
                setSprintMatches([]);
                setPlayerSprintMatches([]);
                // enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                //     variant: 'error',
                //     preventDuplicate: true
                // });
            }
        })
    }




    const handleChangeFilter = (data, field, divisionId) => {
        if (divisionId) {
            setFilter((prev) => {
                return { ...prev, [divisionId]: { ...prev[divisionId], [field]: data } };
            });
        }
        else {
            setFilter((prev) => {
                return { ...prev, [field]: data };
            });
        }

    };


    const isTeamRacePlayerSprint = filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints;

    const handleChangeTab = (event, newValue) => {
        if (newValue !== livescrTab) {
            setLivescrTab(newValue);
            setLoading(true);
            history.push(`${path}/${liveScoreTabs[newValue].id}${history.location?.search}`);
        }
    };

    return (
        <div className={classes.root}>
            {filtersData.season?.season_format === 'HEAD_TO_HEAD' ?
                <>
                    <div style={{ padding: '0px 50px' }}>
                        {
                            filtersData.season?.season_format === 'HEAD_TO_HEAD' ?
                                <RoundsListGroup rounds={rounds} selectedRound={filtersData.round} onRoundClick={(round) => {
                                    setFiltersData(prevState => ({ ...prevState, round }));
                                    setSearchQuery(history, { round: round?._id });
                                }} style={{ margin: '20px auto', maxWidth: 'fit-content' }} />
                                :
                                filtersData.season?.season_format === 'PLAYER_RACE' ?
                                    [
                                        !(isTeamRacePlayerSprint && (location.pathname.includes('detailed-player-performances') || location.pathname.includes('all-player-performances'))) &&
                                        <CustomListGroup data={sprintMatches} selectedItem={filtersData.match} onClick={(match) => {
                                            setFiltersData(prevState => ({ ...prevState, match }));
                                            setSearchQuery(history, { match: match?._id });
                                        }} style={{ margin: '20px auto', maxWidth: 'fit-content' }} />
                                    ]

                                    :

                                    [
                                        !(isTeamRacePlayerSprint && (location.pathname.includes('detailed-player-performances') || location.pathname.includes('all-player-performances'))) &&
                                        <CustomListGroup data={playerSprintMatches} selectedItem={filtersData.playermatch} onClick={(match) => {
                                            setFiltersData(prevState => ({ ...prevState, playermatch: match }));
                                            setSearchQuery(history, { match: match?._id });
                                        }} style={{ margin: '20px auto', maxWidth: 'fit-content' }} />
                                    ]
                        }
                    </div>
                    <div style={{ width: 'calc(100% - 100px)', padding: '0 50px' }}>
                        <Switch>
                            <Route path={`${path}/detailed-player-performances`} exact>
                                {(filtersData.season?.season_format === 'HEAD_TO_HEAD') &&
                                    <div>
                                        <RoundPlayerSummary roundId={filtersData.round?._id} filtersData={filtersData} loading={loading} roles={roles} teams={teams} Filter={Filter} />
                                    </div>
                                }
                                {(filtersData.playermatch || filtersData.match) && (filtersData.season?.season_format !== 'HEAD_TO_HEAD' &&
                                    <>
                                        {
                                            isTeamRacePlayerSprint &&
                                            <CustomListGroup data={playerSprintMatches} selectedItem={filtersData.playermatch} onClick={(match) => setFiltersData(prevState => ({ ...prevState, playermatch: match }))} style={{ margin: '20px auto', maxWidth: 'fit-content' }} />
                                        }
                                        <RaceSummary TeamPerformance={false} isPlayer={true} loading={loading} seasonFormat={filtersData.season?.season_format} seasonId={filtersData.season?._id} isTeamRacePlayerSprint={isTeamRacePlayerSprint} matchId={isTeamRacePlayerSprint ? filtersData.playermatch?._id : filtersData.match?._id} filtersData={filtersData} roles={roles} teams={teams} Filter={Filter} />
                                    </>
                                )}
                            </Route>
                            <Route path={`${path}/all-player-performances`} exact>
                                {
                                    isTeamRacePlayerSprint &&
                                    <CustomListGroup data={playerSprintMatches} selectedItem={filtersData.playermatch} onClick={(match) => setFiltersData(prevState => ({ ...prevState, playermatch: match }))} style={{ margin: '20px auto', maxWidth: 'fit-content' }} />
                                }
                                <RoundPlayerSummaryViewAll roundId={filtersData.round?._id} isTeamRacePlayerSprint={isTeamRacePlayerSprint} matchId={isTeamRacePlayerSprint ? filtersData.playermatch?._id : filtersData.match?._id} filtersData={filtersData} seasonFormat={filtersData.season?.season_format} roles={roles} teams={teams} Filter={Filter} />
                            </Route>
                            <Route path={`${path}/match/:matchId`} exact>
                                <MatchDetails filtersData={filtersData} />
                            </Route>
                            <Route path={`${path}`}>
                                {filtersData.season?.season_format === 'HEAD_TO_HEAD' ?
                                    // <Grid container spacing={2} >
                                    //     <Grid item xs={12}>
                                    <div>
                                        <Switch>
                                            <Route path={`${path}`} exact>
                                                <TopFivePlayerSummary tenantLogoDataUrl={tenantLogoDataUrl} roundId={filtersData.round?._id} filtersData={filtersData} matchId={isTeamRacePlayerSprint ? filtersData.playermatch?._id : filtersData.match?._id} seasonFormat={filtersData.season?.season_format} roles={roles} teams={teams} handleChangeFilter={handleChangeFilter} Filter={Filter} isTeamRacePlayerSprint={isTeamRacePlayerSprint} matchesList={rounds} />

                                            </Route>
                                            <Redirect to={`${path}`} />
                                        </Switch>
                                    </div>
                                    //     </Grid>
                                    // </Grid> 
                                    :
                                    // <Grid container spacing={2} >
                                    //     <Grid item xs={12}>
                                    <div>
                                        <Switch>
                                            <Route path={`${path}`} exact>
                                                <TopFivePlayerSummary roundId={filtersData.round?._id} tenantLogoDataUrl={tenantLogoDataUrl} filtersData={filtersData} matchId={isTeamRacePlayerSprint ? filtersData.playermatch?._id : filtersData.match?._id} seasonFormat={filtersData.season?.season_format} roles={roles} teams={teams} handleChangeFilter={handleChangeFilter} Filter={Filter} isTeamRacePlayerSprint={isTeamRacePlayerSprint} matchesList={filtersData.season?.season_format === 'HEAD_TO_HEAD' ? rounds : isTeamRacePlayerSprint ? playerSprintMatches : sprintMatches} />

                                            </Route >
                                            <Redirect to={`${path}`} />
                                        </Switch >
                                    </div >
                                    //     </Grid >
                                    // </Grid >
                                }

                            </Route >
                            <Redirect to={`${path}`} />
                        </Switch >
                    </div >
                </>
                :
                <>
                    {
                        !history?.location?.pathname.includes('/detailed-scorecard') &&
                        <div className={classes.livescores_new} style={{ padding: '0px 50px' }}>
                            <div className={classes.leagueFilter}>
                                <Tabs
                                    value={livescrTab}
                                    onChange={(event, newValue) => handleChangeTab(event, newValue)}
                                    textColor="primary"
                                    aria-label="full width tabs example"
                                    className={classes.profileTabsRoot}
                                    classes={{ indicator: classes.tabIndicatorProfile }}
                                >
                                    <Tab classes={{ root: classes.profileTabRoot }} label="Role" />
                                    {divisions?.length !== 0 && <Tab classes={{ root: classes.profileTabRoot }} label="Division" />}
                                </Tabs>
                            </div>
                        </div>
                    }
                    <div id='livescore-roles' style={{ width: 'calc(100% - 100px)', padding: '0 50px' }}>
                        <Switch>
                            <Route path={`${path}/role`} exact>
                                <RoleSummaryLists isAdmin={isAdmin} roundId={filtersData.round?._id} tenantLogoDataUrl={tenantLogoDataUrl} filtersData={filtersData} matchId={isTeamRacePlayerSprint ? filtersData.playermatch?._id : filtersData.match?._id} seasonFormat={filtersData.season?.season_format} roles={roles} teams={teams} handleChangeFilter={handleChangeFilter} Filter={Filter} isTeamRacePlayerSprint={isTeamRacePlayerSprint} matchesList={filtersData.season?.season_format === 'HEAD_TO_HEAD' ? rounds : isTeamRacePlayerSprint ? playerSprintMatches : sprintMatches} />
                            </Route >
                            <Route path={`${path}/division`} exact>
                                <DivisionSummaryLists isAdmin={isAdmin} roundId={filtersData.round?._id} tenantLogoDataUrl={tenantLogoDataUrl} filtersData={filtersData} matchId={isTeamRacePlayerSprint ? filtersData.playermatch?._id : filtersData.match?._id} seasonFormat={filtersData.season?.season_format} divisions={divisions} teams={teams} handleChangeFilter={handleChangeFilter} Filter={Filter} isTeamRacePlayerSprint={isTeamRacePlayerSprint} matchesList={filtersData.season?.season_format === 'HEAD_TO_HEAD' ? rounds : isTeamRacePlayerSprint ? playerSprintMatches : sprintMatches} />
                            </Route >
                            <Route path={`${path}/role/detailed-scorecard`} exact>
                                <DetailedRolePlayerSummary filtersData={filtersData} seasonFormat={filtersData.season?.season_format} roles={roles} teams={teams} />
                            </Route>
                            <Route path={`${path}/division/detailed-scorecard`} exact>
                                <DetailedDivisionPlayerSummary filtersData={filtersData} seasonFormat={filtersData.season?.season_format} divisions={divisions} roles={roles} teams={teams} />
                            </Route>
                            {!history?.location?.pathname.includes('/detailed-scorecard') && <Redirect to={{ pathname: `${path}/role`, search: location.search }} />}
                        </Switch>
                    </div >
                </>
            }
        </div >

    )
}

const mapStateToProps = (state) => ({
    loading: state.loading,
    userDetails: state.user,
    orgDetails: state.common.orgDetails,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),
        getMatchesListV2: (options) => dispatch(getMatchesListV2(options)),

    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerLiveScores));
