import React, { useEffect } from 'react';
import { getUser } from "../../selectors";
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Badge, withStyles, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { ArrowBack } from '@material-ui/icons';
import { GroupAvatar } from './../CommonListSection/ChatList';

const useStyles = makeStyles((theme) => ({
    headerRoot: {
        height: '82px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eeeeee'
        // justifyContent: 'space-between'
    },
    profileDetails: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        padding: '0px 12px'
    },
    groupName: {
        color: '#58595B',
        fontSize: 20
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    },
    backButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
            padding: 0,
            display: 'block'
        },
    },
    groupIconBadge: {
        zIndex: 0
    }
}))

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        zIndex: 0
    },
}))(Avatar);

const GroupHeader = ({ user, handleSearchResults, fromSearch }) => {
    const classes = useStyles();

    useEffect(() => {

    }, [user])

    return (
        <div className={classes.headerRoot}>
            {
                fromSearch &&
                <IconButton title="Back to search" className={classes.backButton} onClick={() => handleSearchResults()} >
                    <ArrowBack />
                </IconButton>
            }
            <div className={classes.profileDetails}>
                <div className={classes.avatar}>
                    <GroupAvatar details={user} />
                    {/* <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        classes={{badge: classes.groupIconBadge}}
                        badgeContent={<SmallAvatar alt="Remy Sharp" src={TeamIcon} />}
                    >
                        <Avatar
                            classes={{ colorDefault: classes.avatarStyles }}
                            alt={user.contactName}
                            src={(user.photoUrl && user.photoUrl.length) ? user.photoUrl : (user.image_url) ? user.image_url : defaultTeamAvatar}
                        >
                            {user.contactName && (user.contactName.includes('@') ? user.contactName.substring(0, 1) : getAvatarText(user.contactName))}
                        </Avatar>
                    </Badge> */}
                </div>
                <h2 className={classes.groupName}>
                    {user.contactName ? (user.contactName && (user.contactName.length > 50 ? user.contactName.substring(0, 50) + '...' : user.contactName)) : (user.name && (user.name.length > 52 ? user.name.substring(0, 52) + '...' : user.name))}
                </h2>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    user: getUser(state.chats, props.targetJid)
});

export default connect(mapStateToProps, null)(GroupHeader);