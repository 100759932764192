import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { CustomListGroup } from '../../LiveScores/RoundsListView';
import { useState } from 'react';
import TeamMatchTab from './TeamMatchTab';
import { CustomCard } from '../../Leagues/CustomCard';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 20,
        paddingBottom: '50px'
    },
}))

const MatchOptions = [
    { label: 'Fixtures', value: 0, pathname: 'fixtures' },
    { label: 'Live', value: 1, pathname: 'live' },
    { label: 'Awaiting Results', value: 2, pathname: 'completed' },
    { label: 'Results', value: 3, pathname: 'closed' },


];


const TeamMatches = ({ history, location, teamId, seasonId, loading, setLoading, filtersData, setFiltersData, defaultFilters }) => {
    const classes = useStyles();
    const [matchTab, setMatchTab] = useState(0);
    const { path } = useRouteMatch();
    const { params } = useRouteMatch();
    const searchQuery = history.location.search

    const handleMatchTabChange = (newValue) => {
        setMatchTab(newValue?.value);
    }




    return (
        <div className={classes.root}>
            {filtersData.season?.season_format !== 'HEAD_TO_HEAD' ?
                <CustomCard linkText={null} onLinkClick={() => { }} >
                    <p style={{ padding: 24, textAlign: 'center' }}>No Data Available</p>
                </CustomCard> :
                <div>
                    <CustomListGroup data={MatchOptions} selectedItem={MatchOptions.find((option) => option.value === matchTab)} onClick={handleMatchTabChange} style={{ maxWidth: 'max-content', marginLeft: 0 }} />
                    {matchTab === 0 ?
                        <TeamMatchTab seasonId={seasonId} filtersData={filtersData} teamId={teamId} setFiltersData={setFiltersData} defaultFilters={defaultFilters} tab="fixtures" matchTab={matchTab} />
                        : matchTab === 1 ?

                            <TeamMatchTab seasonId={seasonId} filtersData={filtersData} teamId={teamId} setFiltersData={setFiltersData} defaultFilters={defaultFilters} tab="live" matchTab={matchTab} />
                            : matchTab === 2 ?

                                <TeamMatchTab seasonId={seasonId} filtersData={filtersData} teamId={teamId} setFiltersData={setFiltersData} defaultFilters={defaultFilters} tab="completed" matchTab={matchTab} loading={loading} setLoading={setLoading} />
                                :

                                <TeamMatchTab seasonId={seasonId} filtersData={filtersData} teamId={teamId} setFiltersData={setFiltersData} defaultFilters={defaultFilters} tab="closed" matchTab={matchTab} />}

                </div>
            }
        </div>
    )
}

export default withRouter(TeamMatches);
