
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { Button, useTheme, TextareaAutosize, FormControl, RadioGroup, Radio, FormControlLabel, Checkbox, FormGroup, Select, MenuItem, CircularProgress, Backdrop, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getQuestions, sendSurveyResponse } from '../../ducks/surveys';
import successImage from '../../assets/images/success.svg';
import { ReactComponent as CheckboxIcon } from '../../assets/icons/check-box-outline.svg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            key={index}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{ width: '97%' }}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    rootPosition: {
        width: '100%',
        position: 'relative'
    },
    root: {
        margin: '25px 0',
    },
    questionsRoot: {
        // textAlign: 'center',
        height: '80%',
        position: 'relative',
        marginTop: 70
    },
    buttonRoot: {
        textAlign: 'center',
        marginTop: '12px'
    },
    button: {
        color: '#fff',
        borderRadius: 4,
        width: '110px',
        margin: 0
    },
    previousButton: {
        color: '#fff',
        borderRadius: 4,
        width: '110px',
        margin: '0 20px 0 0',
        backgroundColor: '#838383'
    },
    surveyTitle: {
        color: theme.palette.primary.main,
        fontSize: 25,
        fontWeight: 600,
        margin: '0 30px'
    },
    swipeable: {
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: 320,
        [theme.breakpoints.down('sm')]: {
            width: '85%',
            minHeight: 250,
        },
    },
    questionName: {
        fontWeight: 600,
        color: '#535353'
    },
    freeText: {
        // marginLeft: 10
    },
    freeTextArea: {
        width: '90%',
        borderColor: '#E5E5E5',
        color: '#58595B',
        resize: 'none',
        padding: 10,
        lineHeight: '20px',
        marginLeft: 25,
        '&:focus': {
            outlineColor: '#E5E5E5'
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
    singleSelect: {
        marginLeft: 10,
    },
    radioLabel: {
        color: '#535353',
        '& span': {
            fontSize: 15,
        }
    },
    questionNumber: {
        marginTop: 50,
        textAlign: 'center'
    },
    selectRating: {
        marginLeft: 30,
        width: 90,
        maxWidth: 90,
        textAlign: 'center',
        color: theme.palette.primary.main
    },
    ratingFields: {
        width: 'fit-content',
        display: 'inline-table',
        marginLeft: 25
    },
    ratingRoot: {
        // margin: '5px 0',
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: 15,
        color: '#535353',
        justifyContent: 'space-between'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    successImageRoot: {
        textAlign: 'center',
        marginTop: 110
    },
    successText: {
        fontSize: 20,
        fontWeight: 600,
        color: '#535353',
        marginTop: 35
    },
    successGreetingText: {
        fontSize: 14,
        color: '#838383'
    },
    checkboxStyle: {
        '& svg': {
            width: 32,
            height: 32,
        }
    },
    checkedStyle: {
        width: '24px !important',
        height: '24px !important',
        margin: 4,
        '& path': {
            fill: theme.palette.primary.main
        }

    },
    clearAll: {
        cursor: 'pointer',
        float: 'right',
        fontSize: 15,
        marginRight: 5
    },
    freeTextCounter: {
        margin: '5px 25px',
        fontSize: 13
    },
    formControl: {
        marginLeft: 24
    },
    mandatoryStyles: {
        color: 'red'
    },
    optionalStyles: {
        visibility: "hidden"
    },
    alineOptions: {
        display: "flex",
        flexWrap: 'wrap',
        //    paddingLeft:"47px"
    },
    optionTextStyle: {
        display: "grid"
    },
    imageSize: {
        width: 200,
        height: 200,
        borderRadius: "4px",
        objectFit: 'contain',
        backgroundColor: '#F0F2F5'
    },
    ratingRootForImage: {
        // margin: '5px 0',
        // display: 'flex',
        alignItems: 'flex-start',
        fontSize: 15,
        color: '#535353',
        justifyContent: 'space-between',
        paddingTop: "14px",
        paddingLeft: "25px",
        width: 200,
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column"
    },
    optionAligneCss: {
        paddingLeft: 25,
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column"
    },
    questionImageSize: {
        width: "calc(100% - 25px)",
        height: "280px",
        marginLeft: "25px",
        marginTop: "14px",
        borderRadius: "8px",
        backgroundColor: "#eeeeee",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    questionImageWidth: {
        width: "auto",
        height: "280px",
        maxWidth: "100%"
    },
}));

const SurveyQuestions = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successfullyPosted, setSuccessfullyPosted] = useState(false);

    const theme = useTheme();

    useEffect(() => {
        console.log('getSurveysQuestions ');
        setLoading(true);
        props.getQuestions({ surveyId: props._id, onSuccessCB, onErrorCB });
    }, [])

    const onSuccessCB = (result) => {
        if (result) {
            let resultDup = result.map((item, i) => {
                if (item.type === 'ranking' || item.type === 'rankingAndImage') {
                    return {
                        ...item,
                        options: item.options.map((opt, i, options) => ({
                            ...opt,
                            rankOptions: Array.from(Array(options.length), (_, i) => i + 1)
                        }))
                    }

                }
                else {
                    if ((item.type === 'singleSelect' || item.type === 'singleSelectAndImage') && item.other_option) {
                        return {
                            ...item,
                            options: [...item.options, { label: 'Other', value: 'OTHER' }]
                        }
                    }
                    else if ((item.type === 'multiSelect' || item.type === 'multiSelectAndImage') && item.other_option) {
                        return {
                            ...item,
                            options: [...item.options, { label: 'Other', value: 'OTHER' }]
                        }
                    }
                    else {
                        return item;
                    }
                }
            });
            setQuestions(resultDup);
        }
        setLoading(false);
    }

    const onErrorCB = () => {
        setLoading(false);
    }

    const handleChangeIndex = (index) => {
        setCurrentQuestionIndex(index);
    };

    const handleOtherTextArea = (e, index) => {
        let value = e.target.value;
        let questionsDup = JSON.parse(JSON.stringify(questions));
        questionsDup[index].otherValue = value;
        setQuestions(questionsDup);
    };

    const handleTextArea = (e, index) => {
        let value = e.target.value;
        let questionsDup = JSON.parse(JSON.stringify(questions));
        questionsDup[index].value = value;
        setQuestions(questionsDup);
    }

    const handleChangeRadio = (e, value, index) => {
        let questionsDup = JSON.parse(JSON.stringify(questions));
        questionsDup[index].value = value;
        questionsDup[index].url = questionsDup[index].options.find(obj => obj.value === value)?.url;
        setQuestions(questionsDup);
    }

    const handleCheckbox = (e, value, index, questionIndex) => {
        let questionsDup = JSON.parse(JSON.stringify(questions));
        questionsDup[questionIndex].options[index].selected = value;
        setQuestions(questionsDup);
    }

    const handleSelectable = (event, index, questionIndex) => {
        let value = event.target.value;
        let questionsDup = JSON.parse(JSON.stringify(questions));
        questionsDup[questionIndex].options[index].rank = value;
        console.log("questionsDup---", questionsDup);
        setQuestions(questionsDup);
    }

    const handleSubmitAnswers = () => {
        let questionsDup = JSON.parse(JSON.stringify(questions));
        let requestObj = {
            answers: questionsDup.map(item => {

                if (item.type === 'freetext' || item.type === 'freetextAndImage') {
                    return {
                        question_id: item._id,
                        question_type: item.type,
                        text: item.value,
                        optional: item.optional
                    }
                }
                else if (item.type === 'singleSelect' || item.type === 'singleSelectAndImage') {
                    return {
                        question_id: item._id,
                        question_type: item.type,
                        option: item.value !== 'OTHER' ? item.value : (item.otherValue || 'Other'),
                        option_with_image: item.type === 'singleSelectAndImage' ? { label: (item.value !== 'OTHER' ? item.value : (item.otherValue || 'Other')), url: item.url } : null,
                        optional: item.optional
                    }
                }
                else if (item.type === 'multiSelect' || item.type === 'multiSelectAndImage') {
                    let selectedOptions = item.options.reduce((arr, option) => {
                        if (option.selected) {
                            if (item.type === 'multiSelect') {
                                if (option.value !== 'OTHER')
                                    arr.push(option.value);
                                else if (item.otherValue)
                                    arr.push(item.otherValue);
                                else
                                    arr.push('Other');
                            }
                            else {
                                if (option.value !== 'OTHER')
                                    arr.push({ label: option.value, url: option.url });
                                else if (item.otherValue)
                                    arr.push({ label: item.otherValue });
                                else
                                    arr.push({ label: 'Other' });
                            }
                        }
                        return arr;
                    }, []);
                    return {
                        question_id: item._id,
                        question_type: item.type,
                        options: item.type === 'multiSelectAndImage' ? selectedOptions.map(obj => obj.label) : selectedOptions,
                        options_with_image: item.type === 'multiSelectAndImage' ? selectedOptions : null,
                        optional: item.optional
                    }
                }
                else if (item.type === 'ranking' || item.type === 'rankingAndImage') {
                    let ranks = item.options.reduce((arr, item) => {
                        if (item.rank) {
                            if (item.type === 'ranking') {
                                arr.push({
                                    value: item.value,
                                    rank: `${item.rank}`
                                })
                            }
                            else {
                                arr.push({
                                    value: item.value,
                                    rank: `${item.rank}`,
                                    url: item.url
                                })
                            }
                        }
                        return arr;
                    }, []);
                    return {
                        question_id: item._id,
                        question_type: item.type,
                        rank: item.type === 'rankingAndImage' ? [] : ranks,
                        rank_with_image: item.type === 'rankingAndImage' ? ranks : null,
                        optional: item.optional
                    }
                }
            })
        }
        let respondedAllQuestions = true;
        const finalRequestObj = {
            answers: requestObj.answers.reduce((result, answer) => {
                let resultDup = JSON.parse(JSON.stringify(result));
                if (!answer.optional || (answer.optional && ((answer.text && answer.text.length) || (answer.option?.length || answer.option_with_image?.label) || (answer.options?.length || answer.options_with_image?.length) || (answer.rank?.length || answer.rank_with_image?.length)))) {
                    delete answer.optional;
                    resultDup = [...resultDup, answer]
                }
                return resultDup
            }, [])
        }

        if (respondedAllQuestions) {
            console.log('respondedAllQuestions ', finalRequestObj);
            setLoading(true);
            props.sendSurveyResponse({ surveyId: props._id, requestObj: finalRequestObj, onPostResponseSuccessCB, onPostResponseErrorCB })
        }
        else {
            console.log('Not respondedAllQuestions ', finalRequestObj);
            enqueueSnackbar('Please Respond to all Questions.', {
                variant: 'error',
                preventDuplicate: true
            });
        }
    }

    const onPostResponseSuccessCB = () => {
        setSuccessfullyPosted(true);
        setLoading(false);
    }

    const onPostResponseErrorCB = () => {
        setSuccessfullyPosted(false);
        setLoading(false);
    }

    const handleClearRanking = (questionIndex) => {
        let questionsDup = JSON.parse(JSON.stringify(questions));
        let newOptions = questionsDup[questionIndex].options.map(option => ({
            ...option,
            rank: null,
            rankOptions: Array.from(Array(questionsDup[questionIndex].options.length), (_, i) => i + 1)
        }));
        questionsDup[questionIndex].options = newOptions;
        setQuestions(questionsDup);
    }

    const handleNextDisabled = (index) => {
        let questionsDup = JSON.parse(JSON.stringify(questions));
        const currentQuestion = questionsDup[index];
        if (currentQuestion.optional) {
            return false
        }
        else {
            if ((currentQuestion.type === 'freetext' || currentQuestion.type === "freetextAndImage") && !(currentQuestion.value && currentQuestion.value.length)) {
                return true
            }
            else if ((currentQuestion.type === 'singleSelect' || currentQuestion.type === 'singleSelectAndImage') && !(currentQuestion.value && currentQuestion.value.length)) {
                return true
            }
            else if ((currentQuestion.type === 'multiSelect' || currentQuestion.type === 'multiSelectAndImage') && !(currentQuestion.options && currentQuestion.options.some(obj => obj.selected))) {
                return true
            }
            else if ((currentQuestion.type === 'ranking' || currentQuestion.type === 'rankingAndImage') && !(currentQuestion.options && currentQuestion.options.every(obj => obj.rank))) {
                return true
            }
            else {
                return false
            }
        }
    }

    const freeText = (question, questionIndex) => {
        return (
            <>
                {/* <p className={classes.questionName}>{`${!question.optional ? <span>*</span> : ''}${questionIndex + 1}. ${question.title}`}</p> */}
                <TextareaAutosize maxLength={500} className={classes.freeTextArea} aria-label="freeText" value={question.value} rowsMin={11} rowsMax={11} onChange={(e) => handleTextArea(e, questionIndex)} />
                <p className={classes.freeTextCounter}>{(question.value ? question.value.length : 0) + '/' + 500}</p>
            </>
        )
    }

    const singleSelect = (question, questionIndex) => (
        <>
            {/* <p className={classes.questionName}>{`${!question.optional ? <span>*</span> : ''}${questionIndex + 1}. ${question.title}`}</p> */}


            {
                question.options &&
                <>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup aria-label="gender" name="gender1" className={classes.imageOptions} value={question.value} onChange={(e, value) => handleChangeRadio(e, value, questionIndex)}>
                            {
                                question.options.map((item, index) => (
                                    <FormControlLabel className={classes.radioLabel} key={index} value={item.value} control={<Radio color="primary" />} label={item.label} />
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                    <br />
                    {(question.value === 'OTHER') && <TextareaAutosize maxLength={500} style={{ marginLeft: 60, width: '65%' }} className={classes.freeTextArea} aria-label="freeText" value={question.otherValue} rowsMin={2} rowsMax={2} onChange={(e) => handleOtherTextArea(e, questionIndex)} />}
                </>
            }

        </>
    )

    const multiSelect = (question, questionIndex) => (
        <>
            {/* <p className={classes.questionName}>{`${!question.optional ? <span>*</span> : ''}${questionIndex + 1}. ${question.title}`}</p> */}
            {
                question.options &&
                <>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                            {
                                question.options.map((item, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={<Checkbox checked={item.selected} className={classes.checkboxStyle} checkedIcon={<CheckboxIcon className={classes.checkedStyle} />} color="primary" name={item.value} />}
                                        label={item.label}
                                        className={classes.radioLabel}
                                        value={item.value}
                                        onChange={(e, value) => handleCheckbox(e, value, index, questionIndex)}
                                    />
                                ))
                            }
                        </FormGroup>
                    </FormControl>
                    <br />
                    {(question.options.find(obj => obj.value === 'OTHER' && obj.selected)) && <TextareaAutosize maxLength={500} style={{ marginLeft: 60, width: '65%' }} className={classes.freeTextArea} aria-label="freeText" value={question.otherValue} rowsMin={2} rowsMax={2} onChange={(e) => handleOtherTextArea(e, questionIndex)} />}
                </>
            }
        </>
    )

    const ranking = (question, questionIndex) => (
        <>
            {/* <p className={classes.questionName}>{`${!question.optional ? '*' : ''}${questionIndex + 1}. ${question.title}`}</p> */}
            <div className={classes.ratingFields}>
                {
                    question.options && question.options.map((item, index, list) => {
                        return (
                            <div key={index} className={classes.ratingRoot}>
                                <p>{item.label}</p>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={item.rank || ''}
                                    onChange={(e) => handleSelectable(e, index, questionIndex)}
                                    className={classes.selectRating}
                                >
                                    {item.rankOptions.map((value, i) =>
                                        <MenuItem disabled={list.some(obj => obj.rank === value)} key={i} value={value}>{value}</MenuItem>
                                    )}
                                </Select>
                            </div>
                        )
                    })
                }
                <Link className={classes.clearAll} onClick={() => handleClearRanking(questionIndex)} >Clear all</Link>
            </div>
        </>
    )
    const freeTextWithImage = (question, questionIndex) => {
        return (

            <>
                {(question.attachments.length > 0) ? (
                    <div className={classes.questionImageSize}><img src={question.attachments[0]['url']} alt="option image" className={classes.questionImageWidth} /></div>) : ""}
                {/* <p className={classes.questionName}>{`${!question.optional ? <span>*</span> : ''}${questionIndex + 1}. ${question.title}`}</p> */}
                <TextareaAutosize maxLength={500} style={{ marginTop: "19px", height: "182px", marginLeft: "25px" }} className={classes.freeTextArea} aria-label="freeText" value={question.value} rowsMin={11} rowsMax={11} onChange={(e) => handleTextArea(e, questionIndex)} />
                <p className={classes.freeTextCounter}>{(question.value ? question.value.length : 0) + '/' + 500}</p>
            </>
        )
    }

    const singleSelectWithImage = (question, questionIndex) => (
        <>
            {(question.attachments.length > 0) ? (<div className={classes.questionImageSize}><img src={question.attachments[0]['url']} alt="option image" className={classes.questionImageWidth} /></div>) : ""}

            {/* <p className={classes.questionName}>{`${!question.optional ? <span>*</span> : ''}${questionIndex + 1}. ${question.title}`}</p> */}
            {
                question.options &&
                < >
                    <FormControl component="fieldset" className={classes.formControl, classes.alineOptions}>
                        <RadioGroup aria-label="gender" name="gender1" value={question.value} onChange={(e, value) => handleChangeRadio(e, value, questionIndex)}>
                            <div className={classes.alineOptions}>   {
                                question.options.map((item, index) => (<div className={classes.optionAligneCss}>
                                    <p style={{ marginBottom: 0 }}>{item.url ? <img src={item['url']} alt="option image" className={classes.imageSize} /> : ""}
                                    </p>
                                    <FormControlLabel className={classes.radioLabel} key={index} value={item.value} control={<Radio color="primary" />} label={item.label} />
                                </div>
                                ))
                            }
                            </div>
                        </RadioGroup>
                    </FormControl>
                    <br />
                    {(question.value === 'OTHER') && <TextareaAutosize maxLength={500} style={{ marginLeft: 60, width: '65%' }} className={classes.freeTextArea} aria-label="freeText" value={question.otherValue} rowsMin={2} rowsMax={2} onChange={(e) => handleOtherTextArea(e, questionIndex)} />}
                </>
            }
        </>
    )
    // const multiSelectWithImage = (question, questionIndex) => (
    //     <>
    //         {(question.attachments.length > 0) ? (<img src={question.attachments[0]['url']} alt="option image" className={classes.questionImageSize} />) : ""}
    //         {/* <p className={classes.questionName}>{`${!question.optional ? <span>*</span> : ''}${questionIndex + 1}. ${question.title}`}</p> */}
    //         {
    //             question.options &&
    //             <>
    //                 <FormControl component="fieldset" style={{ display: 'flex', flexWrap: 'wrap' }} className={classes.formControl}>
    //                     <FormGroup>
    //                         {
    //                             question.options.map((item, index) => (
    //                                 <div> {item.url ? <img src={item['url']} alt="option image" className={classes.imageSize} /> : ""}
    //                                     <FormControlLabel
    //                                         key={index}
    //                                         control={<Checkbox checked={item.selected} className={classes.checkboxStyle} checkedIcon={<CheckboxIcon className={classes.checkedStyle} />} color="primary" name={item.value} />}
    //                                         label={item.label}
    //                                         className={classes.radioLabel}
    //                                         value={item.value}
    //                                         onChange={(e, value) => handleCheckbox(e, value, index, questionIndex)}
    //                                     />
    //                                 </div>
    //                             ))
    //                         }
    //                     </FormGroup>
    //                 </FormControl>
    //                 <br />
    //                 {(question.options.find(obj => obj.value === 'OTHER' && obj.selected)) && <TextareaAutosize maxLength={500} style={{ marginLeft: 60, width: '65%' }} className={classes.freeTextArea} aria-label="freeText" value={question.otherValue} rowsMin={2} rowsMax={2} onChange={(e) => handleOtherTextArea(e, questionIndex)} />}
    //             </>
    //         }
    //     </>
    // )
    const multiSelectWithImage = (question, questionIndex) => (
        <>
            {(question.attachments.length > 0) ? (<div className={classes.questionImageSize}><img src={question.attachments[0]['url']} alt="option image" className={classes.questionImageWidth} /></div>) : ""}
            {/* <p className={classes.questionName}>{`${!question.optional ? <span>*</span> : ''}${questionIndex + 1}. ${question.title}`}</p> */}
            {
                question.options &&
                <>
                    <FormControl component="fieldset" className={classes.formControl, classes.alineOptions}>
                        <FormGroup>
                            <div className={classes.alineOptions}>     {
                                question.options.map((item, index) => (
                                    <div className={classes.optionAligneCss}>
                                        <p style={{ marginBottom: 0 }}>{item.url ? <img src={item['url']} alt="option image" className={classes.imageSize} /> : ""}
                                        </p> <FormControlLabel
                                            key={index}
                                            control={<Checkbox checked={item.selected} className={classes.checkboxStyle} checkedIcon={<CheckboxIcon className={classes.checkedStyle} />} color="primary" name={item.value} />}
                                            label={item.label}
                                            className={classes.radioLabel}
                                            value={item.value}
                                            onChange={(e, value) => handleCheckbox(e, value, index, questionIndex)}
                                        />
                                    </div>
                                ))
                            }
                            </div>
                        </FormGroup>
                    </FormControl>
                    <br />
                    {(question.options.find(obj => obj.value === 'OTHER' && obj.selected)) && <TextareaAutosize maxLength={500} style={{ marginLeft: 60, width: '65%' }} className={classes.freeTextArea} aria-label="freeText" value={question.otherValue} rowsMin={2} rowsMax={2} onChange={(e) => handleOtherTextArea(e, questionIndex)} />}
                </>
            }
        </>
    )
    const rankingWithImage = (question, questionIndex) => (
        <>
            {(question.attachments.length > 0) ? (<div className={classes.questionImageSize}><img src={question.attachments[0]['url']} alt="option image" className={classes.questionImageWidth} /></div>) : ""}
            {/* <p className={classes.questionName}>{`${!question.optional ? '*' : ''}${questionIndex + 1}. ${question.title}`}</p> */}
            <div className={classes.alineOptions} >
                {
                    question.options && question.options.map((item, index, list) => {
                        return (
                            <div key={index} className={classes.ratingRootForImage}>
                                {item.url ? <img src={item['url']} alt="option image" className={classes.imageSize} /> : ""}
                                <div style={{ display: "flex" }}><span style={{ marginTop: 14 }} >{item.label}  </span>      <Select
                                    labelId={"demo-simple-select-label" + index}
                                    id={"demo-simple-select" + index}
                                    value={item.rank || ''}
                                    onChange={(e) => handleSelectable(e, index, questionIndex)}
                                    className={classes.selectRating}
                                >
                                    {item.rankOptions && item.rankOptions.map((value, i) =>
                                        <MenuItem disabled={list.some(obj => obj.rank === value)} key={i} value={value}>{value}</MenuItem>
                                    )}
                                </Select>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div >
                <Link className={classes.clearAll} onClick={() => handleClearRanking(questionIndex)} >Clear all</Link>
            </div>
        </>
    )


    return (
        <div className={classes.rootPosition}>
            <div className={classes.root}>
                <p className={classes.surveyTitle}>{props.title}</p>
                {(questions && !successfullyPosted) ?
                    <div className={classes.questionsRoot}>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={currentQuestionIndex}
                            onChangeIndex={handleChangeIndex}
                            className={classes.swipeable}
                        >
                            {
                                questions.map((question, i) => (
                                    <TabPanel key={i} value={currentQuestionIndex} index={i} dir={theme.direction}>
                                        <p className={classes.questionName}><span className={question.optional ? classes.optionalStyles : classes.mandatoryStyles}>*</span>{`${i + 1}. ${question.title}`}</p>
                                        {
                                            question.type === 'freetext' ?
                                                freeText(question, i)
                                                :
                                                question.type === 'singleSelect' ?
                                                    singleSelect(question, i)
                                                    :
                                                    question.type === 'multiSelect' ?
                                                        multiSelect(question, i)
                                                        :
                                                        question.type === 'ranking' ?
                                                            ranking(question, i)
                                                            :
                                                            question.type === 'freetextAndImage' ?
                                                                freeTextWithImage(question, i)
                                                                :
                                                                question.type === 'singleSelectAndImage' ?
                                                                    singleSelectWithImage(question, i)
                                                                    :
                                                                    question.type === 'multiSelectAndImage' ?
                                                                        multiSelectWithImage(question, i)
                                                                        :
                                                                        question.type === 'rankingAndImage' ?
                                                                            rankingWithImage(question, i)
                                                                            :
                                                                            null
                                        }
                                    </TabPanel>
                                ))
                            }
                        </SwipeableViews>
                        <div className={classes.buttonRoot}>
                            {
                                currentQuestionIndex > 0 &&
                                <Button variant="contained" color="secondary" size="large" className={classes.previousButton} onClick={() => handleChangeIndex(currentQuestionIndex - 1)}>
                                    Prev
                        </Button>
                            }
                            {
                                (questions && (questions.length - 1 === currentQuestionIndex)) ?
                                    <Button variant="contained" color="primary" size="large" disabled={handleNextDisabled(currentQuestionIndex)} className={classes.button} onClick={() => handleSubmitAnswers()}>
                                        Submit
                                    </Button>
                                    :
                                    <Button variant="contained" color="primary" size="large" disabled={handleNextDisabled(currentQuestionIndex)} className={classes.button} onClick={() => handleChangeIndex(currentQuestionIndex + 1)}>
                                        Next
                                    </Button>
                            }
                        </div>
                        {
                            questions &&
                            <div className={classes.questionNumber}>
                                <p>{`${currentQuestionIndex + 1} / ${questions.length}`}</p>
                            </div>
                        }
                    </div>
                    :
                    null
                }
                {
                    successfullyPosted &&
                    <div className={classes.successImageRoot}>
                        <img src={successImage} alt="success" />
                        <p className={classes.successText}>Successfully Completed</p>
                        <p className={classes.successGreetingText}>Thank you for your participation.</p>
                    </div>
                }
            </div >
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        getQuestions: (options) => dispatch(getQuestions(options)),
        sendSurveyResponse: (options) => dispatch(sendSurveyResponse(options))
    };
};

export default connect(null, mapDispatchToProps)(SurveyQuestions);