import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/arena/favourites");

export const GET_FAVOURITE_STATUS = constant("GET_FAVOURITE_STATUS");
export const SET_FAVOURITE_TO = constant("SET_FAVOURITE_TO");
export const SET_FAVOURITES = constant("SET_FAVOURITES");

export const GET_FOLLOWER_USER_LIST = constant("GET_FOLLOWER_USER_LIST");
export const GET_FOLLOWING_USER_LIST = constant(" GET_FOLLOWING_USER_LIST");
export const GET_MUTUAL_FOLLOWING_USER_LIST = constant("GET_MUTUAL_FOLLOWING_USER_LIST");
export const FOLLOW_USER = constant("FOLLOW_USER");
export const UN_FOLLOW_USER = constant("UN_FOLLOW_USER");

export const getFollowUserList = (payload) => ({
    type: GET_FOLLOWER_USER_LIST,
    payload
});

export const getFollowingUserList = (payload) => ({
    type: GET_FOLLOWING_USER_LIST,
    payload
});

export const getMutualFollowingUserList = (payload) => ({
    type: GET_MUTUAL_FOLLOWING_USER_LIST,
    payload
});

export const followUser = (payload) => ({
    type: FOLLOW_USER,
    payload
});

export const unFollowUser = (payload) => ({
    type: UN_FOLLOW_USER,
    payload
});

export const getFavouriteStatus = (payload) => ({
    type: GET_FAVOURITE_STATUS,
    payload
})

// export const setFavourites = (payload) => ({
//     type: SET_FAVOURITES,
//     payload
// });

// export const setFavouriteTo = (payload) => ({
//     type: SET_FAVOURITE_TO,
//     payload
// });

const initialState = {
    faviourites: [],
    faviouriteTo: []
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        // case SET_FAVOURITES:
        //     return {
        //         ...state,
        //         faviourites: {
        //             ...action.payload
        //         }
        //     }
        // case SET_FAVOURITE_TO:
        //     return {
        //         ...state,
        //         faviouriteTo: {
        //             ...action.payload
        //         }
        //     }

        default:
            return state;
    }
};