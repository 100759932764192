import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { Button, Chip, fade, IconButton, InputBase, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { CSVLink } from "react-csv";
import FilterListIcon from "@material-ui/icons/FilterList";
import MUILink from "@material-ui/core/Link";
import SearchIcon from '@material-ui/icons/Search';
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import MaterialUITable from "../GlobalComponents/MaterialUITable";
import IllustrationView from "../LiveScores/IllustrationView";
import { setLoading } from "../../ducks/loading";
import { getPlayerCoreKpisStats } from "../../ducks/game";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../helpers";
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";
import _ from 'underscore';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0px',
        '& .material-table td div': {
            border: 'none !important',
        },
        '& .material-table td': {
            textAlign: 'left'
        },
        '& .material-table th': {
            textAlign: 'left',
            backgroundColor: `${theme.palette.primary.light} !important`,
            borderColor: `${theme.palette.primary.light} !important`,
        },
    },
    activefiltertext: {
        fontSize: "14px",
        fontWeight: "500",
        marginRight: 14
    },
    chiparea: {
        margin: "0 14px 5px 0",
        color: "#58595B",
        backgroundColor: "#F1F1F1",
        height: '25px',
        fontSize: '12px',
    },
    clear: {
        fontSize: "14px",
        cursor: "pointer",
        color: "#FFC407",
        display: "inline-flex"
    },
    FilterListIconborder:
        { border: "1px solid black", borderRadius: "5px" },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },

}));

export const formattedActuals = (userDetails, value, item, units = true) => {
    return item.unit_of_measurement === 'CURRENCY' ?
        <GetCountryCurrencyFormat type="currency" value={value} />
        : item.unit_of_measurement === 'NUMBER' ?
            <GetCountryCurrencyFormat type="number" value={value} />
            : (units ? `${getNumberFormat(userDetails?.countryCode, value)}${item.unit_of_measurement === 'PERCENTAGE' ? '%' : item.unit_of_measurement === 'DURATION' ? ` ${getDurationWithUnit(value, item.unit)}` : ''}` : getNumberFormat(userDetails?.countryCode, value));
}

const MyTeamPlayers = ({ isSeasonLevel, setLoading, filtersData, loading, seasonId = '', seasonFormat = '', roundId = '', matchId = '', playerId, teamId, roleId, userDetails, getPlayerCoreKpisStats, roles, teams, isAdmin, setPlayerData }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        if (filtersData?.season?._id) {
            let seasonId = filtersData.season?._id;
            getPlayerCoreKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { team_id: teamId, limit: 10, page: 1 } });
        }
    }, [filtersData?.season?._id]);


    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            // ---------get unique kpi ids from response --------
            const uniquIds = _.uniq(_.pluck(_.flatten(_.pluck(response, 'kpis')), 'kpi_id'));
            let kpisResultes = _.flatten(_.pluck(response, 'kpis'));
            let uniqKpis = [];
            uniquIds.forEach(ele => { uniqKpis.push(kpisResultes.find(ukpi => ukpi.kpi_id === ele)) });
            let uniqKpiHeaders = _.compact(uniqKpis);
            // ---------get unique kpi ids from response --------
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, totalPoints: item.total_points, selected: item.employee_id === userDetails?.username, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id ? 1 : -1)), };
                if (index === 0) {
                    uniqKpiHeaders.forEach(headerEle => {
                        headers.push(headerEle);
                        obj.columns = [...(obj.columns || []),
                        {
                            title: `${headerEle.kpi_name}`, //-Actual
                            field: `${headerEle.kpi_id}-actual`,
                            editable: "never",
                            emptyValue: "--",
                            align: "center",
                            cellStyle: { whiteSpace: "nowrap" },
                            // render: (rowData) =>
                            //     rowData[`${headerEle.kpi_id}-unit_of_measurement`] === "CURRENCY" ?
                            //         <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-actual`]} />
                            //         : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === "NUMBER" ?
                            //             <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-actual`]} />
                            //             : [
                            //                 `${getNumberFormat(
                            //                     userDetails?.countryCode,
                            //                     rowData[`${headerEle.kpi_id}-actual`]
                            //                 )} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] ===
                            //                     "PERCENTAGE"
                            //                     ? "%"
                            //                     : rowData[`${headerEle.kpi_id}-unit_of_measurement`] ===
                            //                         "DURATION"
                            //                         ? getDurationWithUnit(
                            //                             rowData[`${headerEle.kpi_id}-actual`],
                            //                             rowData[`${headerEle.kpi_id}-unit`]
                            //                         )
                            //                         : ""
                            //                 }`,
                            //             ]
                        },
                        ];
                    })
                }
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-actual`] = formattedActuals(userDetails, el.actual, el)
                    // el.unit_of_measurement === 'CURRENCY' ?
                    //     <GetCountryCurrencyFormat type="currency" value={el.actual} />
                    //     : el.unit_of_measurement === 'NUMBER' ?
                    //         <GetCountryCurrencyFormat type="number" value={el.actual} />
                    //         : `${getNumberFormat(userDetails?.countryCode, el.actual)} ${el.unit_of_measurement === 'PERCENTAGE' ? '%' : el.unit_of_measurement === 'DURATION' ? getDurationWithUnit(el.actual, el.unit) : ''}`
                });
                return [...result, { ...obj, team: { name: obj.team_name, logo_url: obj.team_logo_url, _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role, }, isChecked: false },];
            }, []);
            if (finalResult.length > 0) {
                setData(finalResult);
                setPlayerData(finalResult);
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers]);
            } else {
                setData([]);
                setDynamicColumns([]);
                setDynamicHeaders([]);
            }
        }
        setLoading(false);
    };

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Statistics', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const isRuleBasedOnActuals = (kpi) => {
        return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
    }

    const columns = [
        {
            title: "Rank",
            field: "rank",
            width: 100,
            editable: 'never',
            emptyValue: '--',
        },
        {
            title: "Player",
            field: "player",
            editable: "never",
            width: 150,
            emptyValue: '--',
            render: (rowData) => rowData.player ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent data={rowData} from='PLAYER' type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Team",
            field: "team",
            width: 150,
            editable: 'never',
            emptyValue: '--',
            render: (rowData) => rowData.team ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent type='TEAM' name={rowData.team.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} filtersData={filtersData} />
                    {rowData.team && rowData.team.name}
                </div>
            ) : '--'
        },
        {
            title: "Total Pts",
            width: 100,
            field: "totalPoints",
            editable: 'never',
            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)
        }
    ]
    return (
        <div>
            {(data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                <div className={classes.root}>
                    <div className="material-table">
                        <MaterialUITable

                            title="Round Summary"
                            columns={[...columns, ...dynamicColumns]}
                            data={data}
                            options={{
                                search: false,
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                emptyRowsWhenPaging: false,
                                toolbar: false,
                                pageSizeOptions: [5, 10, 25],
                                pageSize: 25,
                                paging: data.length > 5 ? true : false,
                                // fixedColumns: {
                                //     left: 4,
                                // }
                            }}
                            components={{
                                Header: props => (
                                    <TableHead className="custom-headers" >
                                        <TableRow>
                                            <TableCell>{props.columns[0]?.title}</TableCell>
                                            <TableCell>{props.columns[1]?.title}</TableCell>
                                            <TableCell>{props.columns[2]?.title}</TableCell>
                                            <TableCell>{props.columns[3]?.title}</TableCell>
                                            {
                                                dynamicHeaders.map((obj, index) => (
                                                    <TableCell key={index} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                        {obj.kpi_name}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                )
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
});
const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayerCoreKpisStats: options => dispatch(getPlayerCoreKpisStats(options))

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTeamPlayers));
