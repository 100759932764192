import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogActions from '@material-ui/core/DialogActions';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import { useSnackbar } from "notistack";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { DialogTitle, IconButton, useMediaQuery, CircularProgress, TextareaAutosize, Backdrop, useTheme, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BugReportIcon from '@material-ui/icons/BugReport';
import { openTaskDialog, createTask, getProjectList, getProjectMembers, getProjectDetails } from '../../ducks/pms';
import { imageMsgTypes, fileMsgTypes, videoMsgTypes } from '../ChatSection/MessageInput'
import { getFormatIcon } from '../../helpers'
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromHTML, ContentState, convertToRaw, convertFromRaw, } from 'draft-js';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { compose } from "redux";
// file uload --------
import imageCompression from 'browser-image-compression';
import { uploadFilesToS3 } from '../../ducks/common';
import Axios from 'axios';
import { Close, Add, PlayCircleOutline, ExpandMore } from '@material-ui/icons';

// import Newcard from './NewCard'
import { connect } from "react-redux";
import { DateComponent, AutocompleteComponent, TextComponent } from './CustomFields';
import CustomAutocomplete from '../GlobalComponents/CustomAutocomplete';
import { orgRealm } from '../..';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 6,
    }, dialogPaper: {
        minHeight: 470,
        borderRadius: 15,
        maxWidth: 825
    },
    dialogTitle: {
        padding: '21px 40px',
        fontSize: 22,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogContent: {
        padding: '13px 40px'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 10
    },
    fieldRoot: {
        // padding: '12px 0px',
        borderRadius: '5px'
    },
    fieldLabel: {
        marginLeft: 12,
        width: '100%',
        '& p:first-child': {
            margin: 0,
            fontSize: 14,
            color: '#535353',
            fontWeight: 600,
            '& .mandatory': {
                color: '#ff0202'
            }
        },
    },
    inputStyles: {
        fontSize: 15,
        color: '#535353'
    },
    nameInputStyles: {
        textAlign: 'center',
        color: '#141414',
        fontSize: 16,
        fontWeight: 600
    },
    inputUnderine: {
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    },
    descriptionFieldView: {
        alignItems: "center",
        borderRadius: "10px",
        minHeight: "40px",
        width: "99%", marginTop: 3
    },
    input: {
        resize: 'none',
        width: "100%",
        marginLeft: theme.spacing(1),
        fontSize: 14,
        "&:focus": {
            outline: "none"
        },
        border: 'solid 1px #b4b4b4',
        borderRadius: "5px",
        marginLeft: 0,
        wordBreak: "break-word",
        color: "#535353",
        fontSize: "15px",
        paddingTop: "15px",
        paddingBottom: "7px",
        whiteSpace: 'pre-line'
    },
    formControl: {
        // margin: theme.spacing(1),
        // margin: 11,
        // marginLeft: 0,
        minWidth: 120,
    },
    buttonRoot: {
        margin: '30px 0 20px',
        textAlign: 'center',
        '& button:nth-child(2)': {
            marginRight: 0
        },
    },
    selectStyles: {
        border: 'none',
        outline: 'none',
        width: '50%',
        resize: 'none',
        lineHeight: '20px',
        padding: '8px 0',
        display: "flex"
    }, textBoxStyles: {
        border: 'none',
        outline: 'none',
        width: '100%',
        resize: 'none',
        lineHeight: '20px',
        padding: '8px 0',
        display: "flex"
    },
    button: {
        margin: '0 7px',
        width: 120,
        height: 43,
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            width: 80
        },
    },
    priorityHigh: {
        fontSize: "medium",
        marginRight: 5,
        color: "#ff4d4d"
    }, priorityMedium: {
        fontSize: "medium",
        marginRight: 5,
        color: "#ff9933"
    },
    priorityLow: {
        fontSize: "medium",
        marginRight: 5,
        color: "#3a8736"
    }, issueTypeTask: {
        fontSize: "medium",
        marginRight: 5,
        color: "#0377fc"
    },
    issueTypeStory: {
        fontSize: "medium",
        marginRight: 5,
        color: "green"
    },

    //file upload
    attachmentsList: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    uploadingRoot: {
        position: 'relative',
        height: 100,
        width: 120
    }, attachmentPickerSection: {
        height: 100,
        width: 120,
        border: '2px dashed #58595B',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            opacity: 0.3
        }
    }, attachment: {
        height: 100,
        width: 120,
        backgroundColor: '#EEEEEE',
        textAlign: 'center',
        border: '1px solid #EEEEEE',
        position: 'relative',
        marginRight: 10,
        marginBottom: 20,
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.5,
            '& #delete-icon': {
                visibility: 'visible',
                '& svg': {
                    fontSize: '0.85rem',
                    backgroundColor: '#000',
                    color: '#FFF'
                },
            }
        },
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
            height: '100%',
        }
    },
    attachmentPickerSection: {
        height: 100,
        width: 120,
        border: '2px dashed #58595B',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            opacity: 0.3
        }
    },
    attachName: {
        width: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block",
        marginTop: "-5px"
    },
    uploadingRoot: {
        position: 'relative',
        height: 100,
        width: 120
    },

    deleteIcon: {
        position: 'absolute',
        right: '2px',
        top: '1px',
        visibility: 'hidden',
        cursor: 'pointer'
    },
    videoStyles: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#828282",
        position: "relative",
        textAlign: "center",
        color: "#fff",
        overflow: 'hidden',
        "&:hover": {
            cursor: "pointer"
        },
        '& button': {
            position: 'absolute'
        }
    },
    playIcon: {
        color: '#fff'
    },
    nameInputStyles: {
        textAlign: 'center',
        color: '#141414',
        fontSize: 16,
        fontWeight: 600
    },
    inputUnderine: {
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    },
    startTimeAutocompleteRoot: {
        borderRadius: 6,
        padding: '5px !important',
        padding: '12px 4px'
        // width: 366
    },
    fieldWidth: {
        borderRadius: 6
    },
    select: {
        fontSize: 14,
        fontWeight: '700',
        // color: '#F5F5F5',
        width: 100,
        background: 'rgb(223, 225, 230)'
    },
    privacySelect: {
        display: 'flex',
        alignItems: 'center',
        padding: '25px 32px 4px 8px',
        borderRadius: 6,
        fontSize: 14,
        '& svg': {
            marginRight: 8
        }
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main,
        color: '#000000',
        width: 40,
        height: 40,
        // fontSize: 13,
        fontWeight: 600,
        margin: '0 auto',
        marginRight: 10

        //   cursor: 'pointer'
    },
    selectItemsAlign: {
        alignItems: 'center',
        display: 'flex'
    },
    datePickerInputStyles: {
        paddingLeft: 14,
        // fontSize: 14,
        // color: '#535353',
        height: 41,
        cursor: 'pointer',
    },
    dateInputUnderine: {
        border: '1px solid #c2c2c2',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:before': {
            border: 'none'
        },
        '&:hover:not(.Mui-disabled):before': {
            border: 'none'
        },
        '& svg': {
            fill: '#535353',
            marginRight: 8
        }
    },
    autocompleteRoot: {
        borderRadius: 6,
        // padding: '3px 7px !important',
        // width: 280
    },
    autocompleteStyle: {
        '& .MuiInputBase-root': {
            borderRadius: 6
        }
    },
}));
let attachmentsCount = 0;
let finalAttachmentsCount = 0;
const CancelToken = Axios.CancelToken;
export const taskRedirectUrl = (apiBaseUrl = '', orgCode = '', path = '') => `${apiBaseUrl.replace('api', orgCode)}/${path}`;


const IssueDialog = (props) => {
    let classes = useStyles();
    const { taksDialog: { open, details, isEdit }, openTaskDialog, createTask, handleNewTask } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const [projectList, setProjectList] = useState([]);
    const [projectDetails, setProjectDetails] = useState({});
    const [file, setFile] = useState(null)
    const [projectMembers, setProjectMembers] = useState([])
    let initialState = {
        project_id: {},
        task_type: '',
        title: '',
        description: '',
        priority: null,
        estimation: '',
        reported_by: {},
        product: '',
        benifits: '',
        action_plans: '',
        assigned_to: null,
        attachments: [],
        newAttachments: []
    };
    const [taskForm, setTaskForm] = useState({ ...initialState });
    const [validationEror, setValidation] = useState({ hasError: false, field: "" });
    const { enqueueSnackbar } = useSnackbar();
    // file upload
    const [uploading, setUploading] = useState(false);
    const [source, setSource] = useState(CancelToken.source());
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        let limit = 100;
        let page = 1;
        // props.getProjectList({
        //     params: { limit: limit, page: page }, onSuccessCB: getProjectsListSuceessCB, onErrorCB: getProjectsListErrorCB
        // });
        if (details._id) {
            props.getProjectDetails({
                id: details._id,
                getProjectDetailsSuceessCB,
                getProjectDetailsErrorCB
            })

            setTaskForm(prevState => ({ ...prevState, project_id: details }))
            props.getProjectMembers({
                id: details._id, memberDetails: {},
                onSuccessCB: getProjectsMembersCB,
                onErrorCB: getProjectsMembersErrorCB
            })
        }
    }, [details._id]);

    const getProjectsListSuceessCB = (result) => {
        if (result) {
            setProjectList([...result])
        }
    }
    const getProjectsListErrorCB = (error) => {
        enqueueSnackbar(`Failed to fetch Project List.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const getProjectDetailsSuceessCB = (result) => {
        if (result) {
            setProjectDetails({ ...result, customFields: (result.config && result.config.custom_fields) ? result.config.custom_fields : [] });
        }
    }
    const getProjectDetailsErrorCB = (error) => {
        enqueueSnackbar(`Failed to fetch Project Details.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const getProjectsMembersCB = (result) => {
        setProjectMembers([...result.members])
    }
    const getProjectsMembersErrorCB = (error) => {
        console.log(error)
        enqueueSnackbar(`Failed to fetch Project Members.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }
    const handleChange = (event, data, type) => {
        if (type === 'project') {
            if (data) {
                setTaskForm(prevState => ({ ...prevState, project_id: data }))
                props.getProjectMembers({
                    id: data._id, memberDetails: {}, onSuccessCB: getProjectsMembersCB,
                    onErrorCB: getProjectsMembersErrorCB
                })
            }
            else {
                setTaskForm(prevState => ({ ...prevState, project_id: {} }))
            }
            setValidation({ hasError: false, field: 'project' });
        }
        else if (type === 'task_type') {
            setValidation({ hasError: false, field: 'task_type' });
            setTaskForm(prevState => ({ ...prevState, task_type: event.target.value }))
        }
        else if (type === 'description') {
            let object = { ...taskForm }
            object['description'] = event.target.value
            setValidation({ hasError: false, field: 'description' });
            setTaskForm(object)
        }
        else if (type === 'priority') {
            setValidation({ hasError: false, field: 'priority' });
            setTaskForm(prevState => ({ ...prevState, priority: event.target.value }))
        }
        else if (type === 'assigned_to') {
            setValidation({ hasError: false, field: 'assigned_to' });
            setTaskForm(prevState => ({ ...prevState, assigned_to: data }))
        }
        else if (type === 'reported_by') {
            setValidation({ hasError: false, field: 'reported_by' });
            setTaskForm(prevState => ({ ...prevState, reported_by: data }))
        }
        else if (type === 'estimation') {
            let object = { ...taskForm }
            object['estimation'] = event.target.value
            setTaskForm(object)
        }
        else if (type === 'title') {
            let object = { ...taskForm }
            object['title'] = event.target.value
            setValidation({ hasError: false, field: 'title' });
            setTaskForm(object)
        }
        else if (type === 'title') {
            let object = { ...taskForm }
            object['title'] = event.target.value
            setValidation({ hasError: false, field: 'title' });
            setTaskForm(object)
        }
        else if (type === 'product') {
            let object = { ...taskForm }
            object['product'] = event.target.value
            setTaskForm(object)
        }
        else if (type === 'benifits') {
            let object = { ...taskForm }
            object['benifits'] = event.target.value
            setTaskForm(object)
        }
        else if (type === 'actionPlans') {
            let object = { ...taskForm }
            object['action_plans'] = event.target.value
            setTaskForm(object)
        }
    }

    const handleChangeInput = (e, field) => {
        let maxLengthCheck = true
        let value = e.target.value
        if (field.type === 'text') {
            maxLengthCheck = value.length <= 25;
        }
        else if (field.type === 'paragraph') {
            maxLengthCheck = value.length <= 500;
        }
        if (maxLengthCheck) {
            setTaskForm(prevState => ({ ...prevState, [field.fieldKey]: value }));
        }
    }

    const handleChangeDate = (date, key) => {
        setTaskForm(prevState => ({ ...prevState, [key]: date._d }));
    }

    const handleChangeAutocomplete = (data, key) => {
        setTaskForm(prevState => ({ ...prevState, [key]: data }));
    }

    const handleClose = () => {
        openTaskDialog({ open: false, details: {}, isEdit: false });
        setValidation({ hasError: false, field: '' })
        setTimeout(() => {
            let newState = {
                project_id: {},
                task_type: '',
                title: '',
                description: '',
                priority: null,
                reported_by: {},
                assigned_to: null,
                attachments: [],
                newAttachments: [],
                product: '', benifits: '', action_plans: ''
            };
            setProjectMembers([])
            setTaskForm(newState)
        })
    }
    const handleCreateIssue = () => {
        if (!taskForm.project_id._id || taskForm.project_id.name === '') setValidation({ hasError: true, field: 'project' })
        else if (!taskForm.task_type) setValidation({ hasError: true, field: 'task_type' });
        else if (taskForm.assigned_to == null) setValidation({ hasError: true, field: 'assigned_to' });
        else if (taskForm.priority == null) setValidation({ hasError: true, field: 'priority' });
        else if (!taskForm.title.trim()) setValidation({ hasError: true, field: 'title' });
        else {
            let customFieldsObj = {};
            projectDetails.customFields && projectDetails.customFields.forEach(element => {
                customFieldsObj[element.fieldKey] = element.type === 'select' ? (taskForm[element.fieldKey] ? taskForm[element.fieldKey].value : '') : taskForm[element.fieldKey];
                delete taskForm[element.fieldKey]
            });
            // const converted = convertToRaw(editorState.getCurrentContent());
            // description:JSON.stringify(converted)
            let requestObj = { ...taskForm, project_id: taskForm.project_id._id, reported_by: taskForm.reported_by.username, assigned_to: taskForm.assigned_to.username, custom_fields: customFieldsObj };
            requestObj.url = taskRedirectUrl(process.env.REACT_APP_BASE_URL, orgRealm, `product-management/${projectDetails.uid}/board/`)
            createTask({
                requestObj,
                onSuccessCB,
                onErrorCB
            })
        }

    }
    const onSuccessCB = (result) => {
        handleClose();
        handleNewTask(new Date().getTime())
        enqueueSnackbar(`Task Created successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
    }

    const onErrorCB = (error) => {
        handleClose();
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Create Issue.`, {
            variant: 'error',
            preventDuplicate: true
        });
    }
    //file upload-------

    const handleAddPhoto = () => {
        const element = document.getElementById(`createTaskFileUpload`);
        element.value = '';
        element.click();
    }
    const handleFileChange = (event) => {
        attachmentsCount = 0;
        finalAttachmentsCount = 0;
        let files = event.target.files;
        if (files && files.length > 0) {
            attachmentsCount = files.length;
            setUploading(true);
            for (let index = 0; index < files.length; index++) {
                // const element = array[index];
                let fileSize = files[index].size / 1024 / 1024; // in MB
                if ((files[index].type).startsWith('image/')) {
                    const imageCompressionOptions = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    imageCompression(files[index], imageCompressionOptions)
                        .then((compressedFile) => {
                            let resultFile = new File([compressedFile], compressedFile.name, { type: compressedFile.type, lastModified: compressedFile.lastModified });
                            let formData = new FormData();
                            formData.append("file", resultFile);
                            props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: files[index], cancelToken: source.token });
                        })
                        .catch((error) => {
                            console.log(error.message);
                            setUploading(false);
                        });
                    // if (event.target) event.target.value = "";
                }
                else if (files[index].type && (files[index].type).startsWith('video/')) {
                    if (fileSize > 100) {
                        enqueueSnackbar('Video size cannot exceed 100 MB.', {
                            variant: 'error',
                            preventDuplicate: true
                        });
                        setUploading(false);
                        if (event.target) event.target.value = "";
                    }
                    else {
                        // setFile({ name: files[index].name });
                        // var reader = new FileReader();
                        // reader.onload = function (e) {
                        // setFile({ base64: e.target.result, name: files[index].name, content: files[index] })
                        // }
                        // reader.readAsDataURL(files[index]); // convert to base64 string
                        var formData = new FormData();
                        formData.append("file", files[index]);
                        setUploading(true);
                        props.uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: files[index], cancelToken: source.token });
                        // if (event.target) event.target.value = "";
                    }
                }
                else {
                    if (fileMsgTypes.includes(files[index].name && files[index].name.split('.').pop())) {
                        setFile({ name: files[index].name });
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            setFile({ base64: e.target.result, name: files[index].name, content: files[index] })
                        }
                        reader.readAsDataURL(files[index]); // convert to base64 string
                        var formData = new FormData();
                        formData.append("file", files[index]);
                        // setLoading(true);
                        props.uploadFilesToS3({ formData, file: files[index], onUploadSuccessCB, onUploadErrorCB });
                        // if (event.target) event.target.value = "";
                    }
                    else {
                        setUploading(false)
                        enqueueSnackbar(`Text File cannot be upload`, {
                            variant: 'error',
                            preventDuplicate: true
                        });
                    }
                }
            }
        }
    }
    const onUploadSuccessCB = (result, file) => {
        finalAttachmentsCount++;
        if (result.url) {
            if (props.fromEdit)
                setTaskForm(prevState => ({ ...prevState, newAttachments: [...prevState.newAttachments, { url: result.url, thumbnailUrl: result.thumbnailUrl, mimeType: (file.type.split('/').shift()).toUpperCase() }] }));
            else
                setTaskForm(prevState => ({ ...prevState, attachments: [...prevState.attachments, { url: result.url, thumbnailUrl: result.thumbnailUrl, mimeType: (file.type.split('/').shift()).toUpperCase() }] }));
            // setUploadErrorText(''); 
        }
        // else setUploadErrorText('Failed');
        if (finalAttachmentsCount === attachmentsCount) {
            setUploading(false);
        }
    }

    const onUploadErrorCB = (result, file) => {
        finalAttachmentsCount++;
        enqueueSnackbar(`Failed to Upload ${file.name}.`, {
            variant: 'error'
        });
        if (finalAttachmentsCount === attachmentsCount) {
            setUploading(false);
        }
    }
    const getMsgType = (ext) => {
        if (imageMsgTypes.includes(ext)) return 'IMAGE';
        else if (videoMsgTypes.includes(ext)) return 'VIDEO';
        else if (fileMsgTypes.includes(ext)) return 'FILE';
        else return 'link';
    }
    const getAttachmentsList = (
        <>
            {taskForm.attachments && taskForm.attachments.map((item, index) => (
                <div key={index} className={classes.attachment}>
                    {
                        getMsgType(item.url && item.url.split('.').pop()) === 'IMAGE' ?
                            <img alt={(item.url || item.attachmentUrl || '').split('-').pop()} src={item.url || item.attachmentUrl} />
                            :
                            getMsgType(item.url && item.url.split('.').pop()) === 'VIDEO' ?
                                <div className={classes.videoStyles} >
                                    <img style={{ maxWidth: 'initial' }} alt={item.thumbnailUrl && item.thumbnailUrl.split("-").pop()} src={item.thumbnailUrl} />
                                    <IconButton><PlayCircleOutline className={classes.playIcon} /></IconButton>
                                </div> :
                                getMsgType(item.url && item.url.split('.').pop()) === 'FILE' ?
                                    <div style={{ padding: item.url && item.url.split('.').pop() === 'pdf' ? 10 : item.url && item.url.split('.').pop().includes('doc') ? 20 : 35 }}>{console.log("items=====", item)}
                                        <img alt={getFormatIcon(item.url && item.url.split('.').pop())} src={getFormatIcon(item.url && item.url.split('.').pop())} /></div>
                                    : ""

                    }
                    <span id="delete-icon" className={classes.deleteIcon}>
                        <Close fontSize={'small'} onClick={(e) => handleDeletePhoto(e, index)} />
                    </span>
                    <span className={classes.attachName}>{item.url.split('-').pop()}</span>
                </div>

            ))}
            {props.fromEdit && taskForm.newAttachments && taskForm.newAttachments.map((item, index) => (
                <div key={index} className={classes.attachment}>
                    {
                        getMsgType(item.url && item.url.split('.').pop()) === 'IMAGE' ?
                            <img alt={(item.url || item.attachmentUrl || '').split('-').pop()} src={item.url || item.attachmentUrl} />
                            :
                            getMsgType(item.url && item.url.split('.').pop()) === 'VIDEO' ?
                                <div className={classes.videoStyles} >
                                    <img style={{ maxWidth: 'initial' }} alt={item.thumbnailUrl && item.thumbnailUrl.split("-").pop()} src={item.thumbnailUrl} />
                                    <IconButton><PlayCircleOutline className={classes.playIcon} /></IconButton>
                                </div> :
                                getMsgType(item.url && item.url.split('.').pop()) === 'FILE' ?
                                    <div style={{ padding: item.url && item.url.split('.').pop() === 'pdf' ? 10 : item.url && item.url.split('.').pop() === 'doc' || 'docx' ? 20 : 35 }}>{console.log("item-------", item)}
                                        <img alt={getFormatIcon(item.url && item.url.split('.').pop())} src={getFormatIcon(item.url && item.url.split('.').pop())} /></div>
                                    : ""
                    }
                    <span id="delete-icon" className={classes.deleteIcon}>
                        <Close fontSize={'small'} onClick={(e) => handleDeletePhoto(e, index, true)} />
                    </span>
                </div>
            ))}
            {
                uploading ?
                    <div className={classes.uploadingRoot}>
                        <Backdrop className={classes.backdrop} open={uploading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                    :
                    taskForm.attachments && taskForm.attachments.length > 0 &&
                    <div title="Upload" className={classes.attachmentPickerSection} onClick={() => handleAddPhoto()}>
                        <Add fontSize="large" />
                    </div>
            }
        </>
    )

    const handleDeletePhoto = (e, index, isNewAttachement) => {
        let detailsDup = JSON.parse(JSON.stringify(taskForm));
        if (props.fromEdit && isNewAttachement)
            detailsDup.newAttachments.splice(index, 1);
        else {
            if (props.fromEdit && detailsDup.attachments[index].id && !isNewAttachement)
                detailsDup.deleteAttachmentIds = [...(detailsDup.deleteAttachmentIds || []), detailsDup.attachments[index].id]

            detailsDup.attachments.splice(index, 1);
        }
        setTaskForm(detailsDup);
    }
    //file upload End

    //Editor section 
    const onEditorStateChange = (data, a) => {
        setEditorState(data);
    }
    const onEditorChange = (e, a) => {
        // if (e.blocks[0])
        // setDetails(prevState => ({ ...prevState, description: e.blocks[0].text }));
    }
    //Editor section ends

    const getCustomComponent = (field) => {
        switch (field.type) {
            case 'number': case 'text':
                return <TextComponent
                    fullWidth
                    id={field.label}
                    label={field.label}
                    type={field.type}
                    variant="outlined"
                    rowsMin={3}
                    rows={3}
                    rowsMax={4}
                    required={!field.optional}
                    value={taskForm[field.fieldKey]}
                    onChange={(e) => handleChangeInput(e, field)}
                    InputProps={{ classes: { root: classes.fieldWidth } }}
                />
            case 'date':
                return <DateComponent
                    views={["date"]}
                    format={"DD MMM yyyy"}
                    variant="inline"
                    autoOk
                    fullWidth
                    id={field.label}
                    // label={field.label}
                    placeholder={field.label}
                    // disableToolbar
                    // className={classes.datePickerStyles}
                    required={!field.optional}
                    InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <ExpandMore /> }}
                    // minDate={props.fromEdit ? details.start_date : defaultStartDate}
                    // minDate={defaultStartDate}
                    value={taskForm[field.fieldKey] || null}
                    onChange={(date) => handleChangeDate(date, field.fieldKey)}
                />
            case 'paragraph':
                return <TextComponent
                    fullWidth
                    id={field.label}
                    label={field.label}
                    multiline="true"
                    variant="outlined"
                    // rowsMin={3}
                    rows={3}
                    rowsMax={4}
                    required={!field.optional}
                    InputProps={{ classes: { root: classes.fieldWidth } }}
                    value={taskForm[field.fieldKey]}
                    onChange={(e) => handleChangeInput(e, field)}
                />
            case 'select':
                return <AutocompleteComponent
                    fullWidth
                    id={field.label}
                    options={field.options}
                    getOptionLabel={(option) => option.label || ''}
                    classes={{ inputRoot: classes.autocompleteRoot }}
                    value={taskForm[field.fieldKey] || {}}
                    onChange={(...args) => handleChangeAutocomplete(args[1], field.fieldKey)}
                    renderInput={(params) => <TextField {...params} required={!field.optional} label={field.label} variant="outlined" />}
                />
            default:
                return null;
        }
    }

    return (
        <div className={classes.root}>
            <Dialog fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='md'
                open={open}
                disableBackdropClick
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="share-arena-dialog"
                style={{ zIndex: 1300 }}
                classes={{ paper: classes.dialogPaper }}>

                <DialogTitle disableTypography onClose={handleClose} className={classes.dialogTitle}>
                    {!isEdit ? 'Create' : 'Edit'}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <form autoComplete="off">
                        <Grid container spacing={4} style={{ marginTop: 10 }}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl style={{ width: '100%' }}>
                                    {/* <TextField
                                        fullWidth
                                        id="project"
                                        label="Project"
                                        variant="outlined"
                                        disabled
                                        value={projectDetails.name}
                                        InputProps={{ classes: { root: classes.fieldWidth } }}
                                    /> */}
                                    <Autocomplete
                                        id="Projects"
                                        options={projectList}
                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                        value={{ name: projectDetails.name, id: projectDetails.name }}
                                        getOptionSelected={(option, value) => option._id === value}
                                        disabled={details._id ? true : false}
                                        classes={{ inputRoot: classes.startTimeAutocompleteRoot }}
                                        renderInput={(params) => <TextField {...params}
                                            required
                                            error={validationEror.hasError && validationEror.field === 'project'}
                                            helperText={validationEror.hasError && validationEror.field === 'project' && "Please Select Project"}
                                            label="Project " variant="outlined" />}
                                    // onChange={(...args) => handleChange(args[0], args[1], 'project',)}
                                    />
                                </FormControl >
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} error={validationEror.hasError && validationEror.field === 'task_type'}>
                                    <InputLabel id="demo-simple-select-outlined-label">Type *</InputLabel>
                                    <Select
                                        style={{ borderRadius: "6px" }}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Type *"
                                        variant={"outlined"}
                                        classes={{ outlined: classes.selectItemsAlign }}
                                        value={taskForm.task_type}
                                        onChange={(e) => handleChange(e, {}, 'task_type')}>
                                        <MenuItem value={"Story"} ><BookmarkIcon className={classes.issueTypeStory} />Story</MenuItem>
                                        <MenuItem value={"Task"} ><AssignmentTurnedInIcon className={classes.issueTypeTask} />Task</MenuItem>
                                        <MenuItem value={"Bug"} ><BugReportIcon className={classes.priorityHigh} />Bug</MenuItem>
                                    </Select>
                                    {validationEror.hasError && validationEror.field === 'task_type' && <FormHelperText>Type required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl style={{ width: "100%" }} >
                                    <CustomAutocomplete
                                        openAutocomplete={true}
                                        inputLabelText="Assignee"
                                        selectedChaseOns={taskForm.assigned_to}
                                        handleChangeAutocomplete={(...args) => handleChange(args[0], args[1], 'assigned_to')}
                                        options={projectMembers}
                                        noOptionsText="No Assignees found"
                                        style={{ padding: 0 }}
                                        className={classes.autocompleteStyle}
                                        error={validationEror.hasError && validationEror.field === 'assigned_to'}
                                        helperText={validationEror.hasError && validationEror.field === 'assigned_to' && "Please Select Assignee"}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} error={validationEror.hasError && validationEror.field === 'priority'}>
                                    <InputLabel id="demo-simple-select-outlined-label">Priority *</InputLabel>
                                    <Select
                                        style={{ borderRadius: "6px" }}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Priority *"
                                        variant={"outlined"}
                                        classes={{ outlined: classes.selectItemsAlign }}
                                        value={taskForm.priority}
                                        onChange={(e) => handleChange(e, {}, 'priority')}>
                                        <MenuItem value={2}><ArrowUpwardIcon className={classes.priorityHigh} /> High</MenuItem>
                                        <MenuItem value={1}><ArrowUpwardIcon className={classes.priorityMedium} /> Medium</MenuItem>
                                        <MenuItem value={0}><ArrowDownwardIcon className={classes.priorityLow} /> Low</MenuItem>
                                    </Select>
                                    {validationEror.hasError && validationEror.field === 'priority' && <FormHelperText>Priority required!</FormHelperText>}
                                </FormControl>
                            </Grid>
                            {
                                projectDetails.customFields && projectDetails.customFields.filter(obj => obj.placeholder === 'context').map(field =>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <div className={classes.fieldRoot}>
                                            {getCustomComponent(field)}
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl>
                                    <div className={classes.fieldRoot}>
                                        <input type="file"
                                            multiple
                                            id={`createTaskFileUpload`}
                                            onChange={(e) => handleFileChange(e)}
                                            style={{ display: 'none' }}
                                            accept=".xlsx,.xls,.csv,image/*,.doc,.docx,.pdf" //video/*
                                        />
                                        {/* <Button variant="text"    ><span className={classes.buttonText}>Add Photo/Video</span></Button>style={{ marginRight: 8, marginTop: 5 }} */}
                                        <Button variant="text" onClick={() => handleAddPhoto()} className={classes.actionButton}><AttachmentIcon /><span >Attach</span></Button>
                                    </div>
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <div style={{ margin: '0px 0 12px 12px' }} className={classes.attachmentsList}>
                                {getAttachmentsList}
                            </div>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div style={{ minHeight: 30 }} className={classes.fieldRoot}>
                                    <FormControl style={{ width: '100%' }}>
                                        <TextField
                                            label="Title *"
                                            multiline="true"
                                            rowsMin={1.5}
                                            InputProps={{ classes: { root: classes.fieldWidth } }}
                                            rowsMax={2}
                                            variant="outlined"
                                            value={taskForm.title}
                                            onChange={(e) => handleChange(e, {}, 'title')}
                                            id="outlined"
                                        />
                                    </FormControl>
                                    {
                                        validationEror.hasError && validationEror.field === 'title' ?
                                            <span style={{ paddingLeft: 5, color: '#dc1c1c', fontSize: 'smaller' }}>Please enter Title</span> : ''
                                    }
                                    <span></span>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.fieldRoot}>
                                    <FormControl style={{ width: "100%" }}>
                                        <TextField
                                            id="outlined"
                                            label="Description"
                                            multiline="true"
                                            variant="outlined"
                                            // rowsMin={3}
                                            rows={3}
                                            rowsMax={4}
                                            InputProps={{ classes: { root: classes.fieldWidth } }}
                                            value={taskForm.description}
                                            onChange={(e) => handleChange(e, {}, 'description')}
                                        // InputProps={{ classes: { root: classes.fieldWidth } }}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            {
                                projectDetails.customFields && projectDetails.customFields.filter(obj => obj.placeholder === 'descriptive').map(field =>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <div className={classes.fieldRoot}>
                                            {getCustomComponent(field)}
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                        {/* <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Editor
                                    wrapperStyle={{ border: '1px solid #c2c2c2', width: '100%',borderRadius:6 ,marginTop:5 }}
                                    placeholder="Description"
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                    onChange={(e, a) => onEditorChange(e, a)}
                                    toolbar={{
                                        options: ['inline', 'colorPicker', 'textAlign', 'list', 'link'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline']
                                        },
                                        list: {
                                            options: ['unordered', 'ordered']
                                        },
                                    }}
                                />
                                </Grid>
                            </Grid> */}
                    </form>
                </DialogContent>
                <div className={classes.buttonRoot} >
                    <Button variant="text" size="medium" className={classes.button} onClick={() => handleClose()}>Cancel</Button>
                    {
                        isEdit ?
                            <Button variant="contained" color="primary" size="medium" className={classes.button}>Edit</Button>
                            :
                            <Button variant="contained" color="primary" size="medium" onClick={() => handleCreateIssue()} className={classes.button}>Create</Button>
                    }
                </div>
            </Dialog>
        </div>
    )
};
const mapStateToProps = (state) => ({
    taksDialog: state.pms.taksDialog
})
const mapDispatchToProps = (dispatch, props) => {
    return {
        createTask: (options) => dispatch(createTask(options)),
        getProjectMembers: (options) => dispatch(getProjectMembers(options)),
        openTaskDialog: (options) => dispatch(openTaskDialog(options)),
        uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options)),
        getProjectList: (options) => dispatch(getProjectList(options)),
        getProjectDetails: (options) => dispatch(getProjectDetails(options)),
    }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(IssueDialog);
