import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { setLoading } from "../../../../ducks/loading";
import { Chart } from "chart.js";
import { getDurationWithUnit, getNumberFormat, getSearchValue } from "../../../../helpers";
import { intlNumberFormat } from "../..";

let chart;

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));



const LineCharts = ({ history, kpiTrends, seasonDetails, userDetails }) => {
    const classes = useStyles();
    const uom = getSearchValue(history, 'UOM');
    const unit = getSearchValue(history, 'unit');

    const config = {
        type: 'line',
        data: kpiTrends,
        options: {
            scales: {
                x: {
                    ticks: {
                        display: false
                    },
                    grid: { display: false }
                },
                y: {
                    ticks: {
                        callback: function (value) {
                            return uom === 'CURRENCY' ? intlNumberFormat(seasonDetails, "currency", value) :
                                uom === 'NUMBER' ? intlNumberFormat(seasonDetails, "number", value) :
                                    `${getNumberFormat(userDetails?.countryCode, value, null)} ${uom === 'PERCENTAGE' ? '%' : uom === 'DURATION' ? getDurationWithUnit(value, unit) : ''}`
                        }
                    },
                    grid: {
                        display: false
                    }
                }
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            let labelValue;
                            if (uom === 'CURRENCY' && tooltipItem?.parsed?.y !== null) {
                                labelValue = intlNumberFormat(seasonDetails, "currency", tooltipItem.parsed?.y);
                            } else if (uom === 'NUMBER' && tooltipItem?.parsed?.y !== null) {
                                labelValue = intlNumberFormat(seasonDetails, "number", tooltipItem.parsed?.y);
                            } else {
                                labelValue = `${getNumberFormat(userDetails?.countryCode, tooltipItem.parsed?.y, null)} ${uom === 'PERCENTAGE' ? '%' : uom === 'DURATION' ? getDurationWithUnit(tooltipItem.parsed?.y, unit) : ''}`;
                            }
                            return `${tooltipItem?.dataset?.label}: ${labelValue}`
                        }
                    }
                },
            }
        }
    };

    const ctx = document.getElementById('lineChart');

    if (chart) chart.destroy();
    chart = new Chart(ctx, config);


    return (
        <div className={classes.root}>
            <canvas id="lineChart"></canvas>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LineCharts));