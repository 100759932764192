import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, makeStyles, IconButton, Grid } from "@material-ui/core";
import { PlayCircleOutline } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import Skeleton from "@material-ui/lab/Skeleton";
import { getMediaList } from "../../ducks/user";
import { ArenaPreview } from "../Arena/ArenaPreview";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#ffffff",
        boxShadow: "0px 0px 4px 1px #EDEDED",
    },
    attachmentsRoot: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        marginBottom: 20,
    },
    attachment: {
        height: '200px',
        backgroundColor: theme.palette.primary.main,
        color: "#FFF",
        textAlign: "center",
        border: "1px solid #EEEEEE",
        position: "relative",
        margin: "20px 15px 10px",
        "&:hover": {
            cursor: "pointer",
            opacity: 0.8,
        },
        "& img": {
            width: "100%",
            height: "200px",
        },
    },
    videoStyles: {
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#828282",
        position: "relative",
        textAlign: "center",
        color: "#fff",
        overflow: "hidden",
        "&:hover": {
            cursor: "pointer",
        },
        "& button": {
            position: "absolute",
        },
        "& img": {
            maxWidth: "initial",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "100% !important",
            },
        },
    },
    playIcon: {
        color: "#fff",
    },
    seeMoreActionRoot: {
        textAlign: "center",
        margin: "15px 0 30px",
        "& button": {
            color: "#535353",
            borderColor: "#535353",
            maxWidth: 300,
        },
    },
    emptyResultText: {
        textAlign: "center",
        color: "#535353cc",
        fontSize: 15,
        fontWeight: 600,
        margin: 0,
        padding: 40,
        lineHeight: "25px",
    },
}));

const defaultObj = {
    page: 0,
    limit: 10,
    totalCount: 0,
    data: [],
};

const PlayerProfileMedia = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [mediaData, setMediaData] = useState({ data: [] });
    const [preview, setPreview] = useState({
        open: false,
        attachments: [],
    });

    useEffect(() => {
        getMediaListResult(defaultObj.limit, 0);
    }, []);

    const onMediaListSuccess = (result, headers) => {
        if (parseInt(headers.page) === 0) {
            setMediaData((prevState) => ({
                ...prevState,
                page: parseInt(headers.page) + 1,
                totalCount: parseInt(headers.total_count),
                data: [...result],
            }));
        } else
            setMediaData((prevState) => ({
                ...prevState,
                page: parseInt(headers.page) + 1,
                totalCount: parseInt(headers.total_count),
                data: [...prevState.data, ...result],
            }));
        setSkeletonLoading(false);
    };
    const onMediaListError = (error) => {
        setSkeletonLoading(false);
        enqueueSnackbar(
            error.response && error.response.data
                ? error.response.data.error
                : `Failed to fetch Search Results.`,
            {
                variant: "error",
                preventDuplicate: true,
            }
        );
    };

    const handleSeeMore = () => {
        getMediaListResult(mediaData.limit, mediaData.page);
    };

    const getMediaListResult = (limit, page) => {
        setSkeletonLoading(true);
        props.getMediaList({
            params: {
                type: props.mediaType === "IMAGE" ? "IMAGE" : props.mediaType === "VIDEO" ? "VIDEO" : "",
                username: props.username,
                limit,
                page,
            },
            onMediaListSuccess,
            onMediaListError,
        });
    };

    const handlePreview = (attachments, attachmentIndex) => {
        setPreview({ open: true, attachments, attachmentIndex });
    };

    const handlePreviewClose = () => {
        setPreview({ open: false, attachments: [] });
    };

    return (
        <div className={classes.root}>
            <div className={classes.attachmentsRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container >
                            {mediaData.data.length > 0 ? mediaData.data.map((item, index) => (
                                <Grid item key={index} xs={4}>
                                    <div
                                        className={classes.attachment}
                                        style={{ backgroundColor: "#f7f7f7" }}
                                        onClick={() => handlePreview(mediaData.data, index)}
                                    >
                                        {item.mimeType === "IMAGE" ? (
                                            <img
                                                alt={item.url.split("-").pop()}
                                                src={item.url}
                                            />
                                        ) : (
                                            <div className={classes.videoStyles}>
                                                <img
                                                    alt={
                                                        item.thumbnailUrl && item.thumbnailUrl.split("-").pop()
                                                    }
                                                    src={item.thumbnailUrl}
                                                />
                                                <IconButton>
                                                    <PlayCircleOutline
                                                        className={classes.playIcon}
                                                        fontSize="large"
                                                    />
                                                </IconButton>
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            ))
                                : !skeletonLoading && (
                                    <p className={classes.emptyResultText}>No result found...</p>
                                )}
                        </Grid>
                    </Grid>
                </Grid>
                {skeletonLoading &&
                    [0, 1, 2, 4].map((i) => (
                        <Skeleton
                            key={i}
                            variant="rect"
                            style={{ margin: "20px 15px 10px" }}
                            width={110}
                            height={90}
                        />
                    ))}
            </div>
            <div className={classes.seeMoreActionRoot}>
                {mediaData.data.length < mediaData.totalCount && (
                    <Button variant="outlined" onClick={() => handleSeeMore()}>
                        See More
                    </Button>
                )}
            </div>
            {preview.open && (
                <ArenaPreview
                    open={preview.open}
                    attachmentIndex={preview.attachmentIndex}
                    arena={{ attachments: preview.attachments }}
                    handlePreviewClose={handlePreviewClose}
                    isFromProfile={true}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.username,
    keycloak: state.keycloak.decoded_token,
});

const mapDispatchToProps = (dispatch) => ({
    getMediaList: (value) => dispatch(getMediaList(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerProfileMedia));
