import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat } from "../../../helpers";
import { setLoading } from "../../../ducks/loading";
import { getOverallTeamBonusKpisStats } from "../../../ducks/game";
import { TableCell, TableRow } from "@material-ui/core";
import IllustrationView from "../../LiveScores/IllustrationView";
import { Tooltip } from "@material-ui/core";
import GetCountryCurrencyFormat from "../../GlobalComponents/GetCountryCurrencyFormat";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '15px 0 30px',
        '& .material-table td div': {
            border: 'none !important'
        }
    },
    toolbarRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        margin: '0 0 10px',
        fontSize: 12,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    button: {
        // height: 47,
        margin: '0 10px',
        '&:last-child': {
            marginRight: 0
        }
    }
}));

const TeamBonusKpisStats = ({ setLoading, loading, seasonId = '', matchId = '', userDetails, getOverallTeamBonusKpisStats, seasonDetails }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (matchId) {
            setLoading(true);
            getOverallTeamBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, limit: 5000, page: 1 } });
        }
    }, [matchId]);

    const onSuccessCB = (response) => {
        setData(response);
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    // const isRuleBasedOnActuals = (kpi) => {
    //     return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2') ? kpi.based_on === "ACTUALS" : false);
    // }

    const columns = [
        {
            title: `${seasonDetails?.labels_config?.kpis?.bonus}`,
            field: "kpi_name",
            emptyValue: '--',
            // render: (rowData) => <Tooltip title={rowData.kpi_name}><span>{rowData.kpi_code || rowData.kpi_name}</span></Tooltip>
        },
        {
            title: "Target",
            field: "target",
            align: 'center',
            emptyValue: '--',
            render: (rowData) => (rowData.target ? rowData.unit_of_measurement === 'CURRENCY' ?
                <GetCountryCurrencyFormat type="currency" value={rowData.target} />
                : rowData.unit_of_measurement === 'NUMBER' ?
                    <GetCountryCurrencyFormat type="number" value={rowData.target} />
                    // getCurrencyFormat(userDetails?.countryCode, rowData.unit, rowData.target)
                    : `${getNumberFormat(userDetails?.countryCode, rowData.target)} ${rowData.unit_of_measurement === 'PERCENTAGE' ? '%' : rowData.unit_of_measurement === 'DURATION' ? getDurationWithUnit(rowData.target, rowData.unit) : ''}`
                : 0)
        },
        {
            title: "Actuals",
            field: "actual",
            align: 'center',
            emptyValue: '--',
            render: (rowData) => (rowData.actual ? rowData.unit_of_measurement === 'CURRENCY' ?
                <GetCountryCurrencyFormat type="currency" value={rowData.actual} />
                : rowData.unit_of_measurement === 'NUMBER' ?
                    <GetCountryCurrencyFormat type="number" value={rowData.actual} />
                    // getCurrencyFormat(userDetails?.countryCode, rowData.unit, rowData.actual)
                    : `${getNumberFormat(userDetails?.countryCode, rowData.actual)} ${rowData.unit_of_measurement === 'PERCENTAGE' ? '%' : rowData.unit_of_measurement === 'DURATION' ? getDurationWithUnit(rowData.actual, rowData.unit) : ''}`
                : 0),
        },
        {
            title: "Ach %",
            field: "achieved",
            align: 'center',
            emptyValue: '--',
            render: (rowData) => getNumberFormat(userDetails.countryCode, rowData.achieved) + '%'
        },
    ]

    return (
        <>
            {(data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                <div className={classes.root}>
                    <div className="material-table">
                        <MaterialTable
                            title={'Match Summary'}
                            columns={[...columns]}
                            data={data}
                            options={{
                                search: false,
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                emptyRowsWhenPaging: false,
                                toolbar: false,
                                pageSizeOptions: [5, 10, 25],
                                pageSize: 25,
                                paging: false
                            }}
                            localization={{ body: { emptyDataSourceMessage: <p style={{ textAlign: 'center' }}>No data available</p> } }}
                            components={{
                                Row: (rowData, a, b) => (
                                    // (rowData.data?.unit_of_measurement === 'PERCENTAGE' || rowData.data?.unit_of_measurement === 'DURATION') ?
                                    //     <TableRow>
                                    //         <TableCell>{rowData.data?.kpi_name}</TableCell>
                                    //         <TableCell colSpan={3} align="center">No data available</TableCell>
                                    //     </TableRow>
                                    //     :
                                    <TableRow>
                                        <TableCell>{rowData.data?.kpi_name}</TableCell>
                                        {/* <TableCell align="center">{rowData.columns[0]?.render(rowData.data)}</TableCell> */}
                                        <TableCell align="center">{rowData.columns[1]?.render(rowData.data)}</TableCell>
                                        <TableCell align="center">{rowData.columns[2]?.render(rowData.data)}</TableCell>
                                        <TableCell align="center">{rowData.columns[3]?.render(rowData.data)}</TableCell>
                                    </TableRow>
                                )
                            }}
                        />
                    </div>
                </div>}
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails

});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getOverallTeamBonusKpisStats: options => dispatch(getOverallTeamBonusKpisStats(options))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamBonusKpisStats));