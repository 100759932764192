import { all, select, call, put, take, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import xml_to_js from 'xml-js';
import { fetchChatMessages, fetchSearchMessagesAPI } from "../../config";
import { GET_CHAT_MESSAGES, receivedHistory, GET_SEARCH_MESSAGES } from "../../ducks/messages";
import { getStanzaMsgDetails } from "../../helpers";
import JwtDecode from "jwt-decode";
import { setSnackbar } from "../../ducks/snackbar";
// import { joinRoom } from "../../ducks/rooms";

const fetchMessages = (username, params) => {
    return Axios.get(fetchChatMessages(username), {
        params
    });
}

const fetchSearchMessages = (timestamp, targetJid, params) => {
    return Axios.get(fetchSearchMessagesAPI(timestamp, targetJid), {
        params
    });
}

function* watchForGetSearchMessages() {
    yield takeEvery(GET_SEARCH_MESSAGES, function* getMessages(action) {
        console.log('watchForGetSearchMessages action', action);
        const { timestamp, targetJid, type, params, onGetPrevMessagesSuccessCB, onGetPrevMessagesErrorCB, onGetNextMessagesSuccessCB, onGetNextMessagesErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchSearchMessages, timestamp, targetJid.split('@')[0], params);
            if (data) {
                if (type === 'group') {
                    let resultData = data.map(message => {
                        const stanzaFromJid = (message.stanza && (xml_to_js.xml2js(message.stanza)) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes.from;
                        console.log('stanzaFromJid ', stanzaFromJid);
                        return {
                            ...message,
                            from: stanzaFromJid,
                            to: message.toJid,
                            time: message.sentdate,
                            type: 'groupchat'
                        }
                    })
                    resultData.sort((a, b) => (a.sentdate > b.sentdate) ? 1 : -1);
                    if (params.step === 'previous') {
                        yield call(onGetPrevMessagesSuccessCB, resultData, headers);
                    }
                    else {
                        yield call(onGetNextMessagesSuccessCB, resultData, headers);
                    }

                    // yield put(receivedHistory(resultData, targetJid));
                }
                else {
                    let resultData = data.map(message => {
                        // const id = (message.stanza && (xml_to_js.xml2js(message.stanza)) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes.id;
                        return {
                            ...message,
                            // id,
                            from: message.fromJid,
                            to: message.toJid,
                            time: message.sentdate
                        }
                    })
                    resultData.sort((a, b) => (a.sentdate > b.sentdate) ? 1 : -1)
                    if (params.step === 'previous') {
                        yield call(onGetPrevMessagesSuccessCB, resultData, headers);
                    }
                    else {
                        yield call(onGetNextMessagesSuccessCB, resultData, headers);
                    }
                    // yield put(receivedHistory(resultData, targetJid));
                }
            }
            console.log('watchForGetSearchMessages data success', data, headers);

        } catch (e) {
            if (params.step === 'previous') {
                yield call(onGetPrevMessagesErrorCB, e);
            }
            else {
                yield call(onGetNextMessagesErrorCB, e);
            }
            console.log('watchForGetSearchMessages catch err ', e);
        }
    });
}

function* watchForGetMessages() {
    yield takeEvery(GET_CHAT_MESSAGES, function* getMessages(action) {
        const { preferred_username } = yield select(state => state.keycloak.tokenParsed);
        console.log('watchForGetMessages ', preferred_username);
        // console.log('watchForGetMessages action', action);
        const { targetJid, params, onGetMessagesSuccessCB, onGetMessagesErrorCB, type, isReconnected } = action.payload;
        if (isReconnected)
            yield put(setSnackbar({ open: false, message: '', type: null }));
        try {
            let { data, headers } = yield call(fetchMessages, targetJid.split('@')[0], params);
            if (data) {

                if (type === 'group') {
                    let resultData = data.map(message => {
                        const stanzaFromJid = (message.stanza && (xml_to_js.xml2js(message.stanza)) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes.from;
                        // console.log('stanzaFromJid ', stanzaFromJid);
                        return {
                            ...message,
                            from: stanzaFromJid,
                            to: message.toJid,
                            time: message.sentdate,
                            type: 'groupchat'
                        }
                    })
                    resultData.sort((a, b) => (a.sentdate > b.sentdate) ? 1 : -1)
                    yield put(receivedHistory(resultData, targetJid, preferred_username, params));
                }
                else {

                    const prevMessagesList = yield select(state => state.messages[targetJid] && state.messages[targetJid].messages.map(obj => obj.id));
                    console.log('watchForGetMessages ', preferred_username, prevMessagesList);
                    // let resultData = data.reduce((arr, message) => {
                    //     const id = (message.stanza && (xml_to_js.xml2js(message.stanza)) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes.id;
                    //     const { messageDisplayed, messageReceived, outgoing } = getStanzaMsgDetails(message.stanza, preferred_username);

                    //     if (!(prevMessagesList && prevMessagesList.includes(id))) {
                    //         arr.push({
                    //             ...message,
                    //             id,
                    //             from: (messageDisplayed || messageReceived) ? message.toJid : message.fromJid,
                    //             to: (messageDisplayed || messageReceived) ? message.fromJid : message.toJid,
                    //             time: message.sentdate,
                    //             messageStatus: messageDisplayed ? 'displayed' : messageReceived ? 'received' : 'sent',
                    //             outgoing
                    //         })
                    //     }
                    //     return arr;
                    // }, []);

                    let resultData = data.map(message => {
                        const id = (message.stanza && (xml_to_js.xml2js(message.stanza)) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes) && (xml_to_js.xml2js(message.stanza)).elements[0].attributes.id;

                        const { messageDisplayed, messageReceived, outgoing } = getStanzaMsgDetails(message.stanza, preferred_username);
                        // if (prevMessagesList && prevMessagesList.includes(id)) return;
                        return {
                            ...message,
                            id,
                            from: message.fromJid,
                            to: message.toJid,
                            time: message.sentdate,
                            messageStatus: messageDisplayed ? 'displayed' : messageReceived ? 'received' : 'sent',
                            outgoing: (message.fromJid).startsWith(preferred_username)
                        }
                    })

                    resultData.sort((a, b) => (a.sentdate > b.sentdate) ? 1 : -1);
                    yield put(receivedHistory(resultData, targetJid, preferred_username, params));
                    // yield put(receivedHistory([...new Map(resultData.map(item => [item['id'], item])).values()], targetJid, preferred_username));
                }
                yield call(onGetMessagesSuccessCB, headers);
                console.log('watchForGetMessages data success');
            }

        } catch (e) {
            yield call(onGetMessagesErrorCB, e);
            console.log('watchForGetMessages catch err ', e);
        }
    });
}

export default function* (token) {
    yield all([watchForGetMessages(), watchForGetSearchMessages()]);
}
