import * as XMPP from "stanza";
import configureClient from "../../xmpp";
import { v4 as uuid } from "uuid";

function setupClient(credentials) {
  const resourceId = uuid();
  if (!localStorage.getItem('resourceId')) {
    localStorage.setItem('resourceId', resourceId);
  }
  console.log('setupClient resourceId ', resourceId, localStorage.getItem('resourceId'));

  const client = XMPP.createClient({
    ...credentials,
    // resource: 'stanza',
    resource: `web_${localStorage.getItem('resourceId') || resourceId}`,
    transports: {
      websocket: process.env.REACT_APP_XMPP_WS_URL,
      // bosh: 'http://45.33.44.230:7070/http-bind'
    },
    // If chatMarkers is false, client can't get marked status, it can br any value other that false
    // chatMarkers : true
  });

  window.client = client;
  configureClient(client);

  client.on('auth:success', msg => {
    console.log('auth:success ', msg);
  })

  client.on('subscribe', msg => {
    console.log('subscribe ', msg);
    // client.acceptSubscription(msg.from);
    // client.subscribe(msg.from);
  });

  client.on('subscribed', msg => {
    console.log('subscribed ', msg);
  });

  client.on('unsubscribe', msg => {
    console.log('unsubscribe ', msg);
    // client.unsubscribe(msg.from);
    // client.acceptSubscription(msg.from);
  });

  client.on('unsubscribed', msg => {
    console.log('unsubscribed ', msg);
  });

  client.on('carbon:received', msg => {
    console.log('carbon:received', msg);
  });

  client.on('carbon:sent', msg => {
    console.log('carbon:sent', msg);
  });

  // client.on('presence', msg => {
  //   console.log('presence ', msg);
  // });

  client.on("session:started", (session) => {
    console.log('session:started ', session)
    // client.getRoster()
    // client.sendPresence()
    // let friend_jid = credentials.jid === '9899858742@45.33.44.230' ? '9899858741@45.33.44.230' : '9899858742@45.33.44.230';
    client.getRoster()
      .then(res => {
        console.log('getRoster ', res);
        client.sendPresence();

        // let contact = res.items.find(obj => obj.jid === friend_jid)

        // //var subscriptionRequested = (roster_entry) ? (roster_entry.subscriptionRequested ) : true;
        // let sendSubscribe = true;
        // if (contact) {
        //   sendSubscribe = contact.subscription !== 'both' && !contact.subscriptionRequested;
        // }
        // console.log('source subscription request', sendSubscribe);

        // client.on('subscribe', (pres) => {
        //   console.log('subscribe ', pres);
        //   client.acceptSubscription(pres.from);

        //   if (sendSubscribe) {
        //     client.subscribe(pres.from);
        //   }
        // });


        // client.on('unsubscribe', msg => {
        //   console.log('unsubscribe ', msg);
        //   client.getRoster()
        //     .then(res => {
        //       console.log('getRoster---2 ', res);
        //     })
        //     .catch(err => {
        //       console.log('catch getRoster err ', err);
        //     })

        //   // client.unsubscribe(msg.from);
        //   // client.acceptSubscription(msg.from);
        // });

        // client.sendPresence();
        // client.subscribe(friend_jid);
      })
      .catch(err => {
        console.log('catch getRoster---2 err ', err);
      })
  });

  // client.on("raw:incoming", console.log);
  // client.on("raw:outgoing", console.log);


  // client.connect();

  return client;
}

export default setupClient;
