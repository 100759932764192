import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { ExpandMore, Videocam, Add, KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { ReactComponent as ScheduledCalendarIcon } from '../../assets/icons/scheduled-calendar.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { IconButton, Button } from '@material-ui/core';
import Calendar from './Calendar';
import moment from 'moment';
import { getFormattedDate } from '../../helpers';

const useStyles = makeStyles((theme) => ({
    mainRoot: {
        backgroundColor: '#f5f5f5',
        // padding: 35,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    root: {
        backgroundColor: '#FFFFFF',
        padding: '35px 40px',
        borderRadius: 6,
        color: '#535353',
        [theme.breakpoints.down('xs')]: {
            padding: '35px 20px',
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 600
    },
    calendarHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        flexWrap: 'wrap',
        marginTop: 30, // 60
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            textAlign: 'center',
            marginTop: 40
        },
    },
    datePickerStyles: {
        // margin: '15px 30px',
        width: 140
    },
    datePickerInputStyles: {
        paddingLeft: 8,
        fontSize: 14,
        color: '#535353',
        height: '32px',
        cursor: 'pointer',
    },
    dateInputUnderine: {
        border: '1px solid #c2c2c2',
        cursor: 'pointer',
        borderRadius: 4,
        height: 32,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:before': {
            border: 'none'
        },
        '&:hover:not(.Mui-disabled):before': {
            border: 'none'
        },
        '& svg': {
            fill: '#535353'
        }
    },
    actionButtons: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            margin: '25px 0px 10px',
            justifyContent: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '25px 0px 10px',
            justifyContent: 'center'
        },
    },
    actionButtonRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0px 35px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            margin: '0px 10px',
        },
        '&:hover': {
            opacity: 0.7,
        },
        '& p': {
            fontSize: 14,
            marginTop: 10,
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            }
        }
    },
    actionButtonIcon: {
        width: 50,
        height: 50,
        borderRadius: 13,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fill: '#FFF'
    },
    navigatorsRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .navigatorText': {
            margin: '0 25px'
        }
    },
    calendarContent: {
        marginTop: 35
    },
    button: {
        // margin: '0 25px',
        // width: 120,
        '&:hover': {
            boxShadow: 'none'
        },
        // [theme.breakpoints.down('xs')]: {
        //     width: 80
        // },
    },
}));

const addDays = (date, value, isStartDate) => {
    if (isStartDate)
        return getMonday(new Date(new Date(date).getTime() + value * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0));
    else
        return new Date(new Date(date).getTime() + value * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 999);
}

const getMonday = (date) => {
    let dt = new Date(date); // current date of week
    let currentWeekDay = dt.getDay();
    let lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
    return (new Date(new Date(dt).setDate(dt.getDate() - lessDays)));
}

const defaultDate = getFormattedDate(getMonday(new Date().setHours(0, 0, 0, 0)));
const defaultEndDate = getFormattedDate(new Date(new Date().setDate(new Date(defaultDate).getDate() + 6)).setHours(23, 59, 59, 999));

const MeetingCalendar = (props) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [details, setDetails] = useState({
        date: defaultDate,
        endDate: defaultEndDate
    });

    useEffect(() => {

    }, []);

    const handleDateChange = (date) => {
        setDetails(prevState => ({
            ...prevState,
            date: getFormattedDate(addDays(date, 0, true)),
            endDate: getFormattedDate(addDays(getMonday(date), 6, false))
        }))
    }

    const handleWeekChange = (value) => {
        setDetails(prevState => ({
            ...prevState,
            date: getFormattedDate(addDays(details.date, value, true)),
            endDate: getFormattedDate(addDays(details.date, value + 6, false))
        }))
    }

    const CustomDatePicker = ({ views, value, onChange, ...others }) => {
        return (
            <DatePicker
                {...others}
                disableToolbar
                views={views}
                value={value}
                onChange={onChange}
                className={classes.datePickerStyles}
                InputProps={{ classes: { input: classes.datePickerInputStyles, underline: classes.dateInputUnderine }, endAdornment: <ExpandMore /> }}
            />
        )
    }

    console.log('handleWeekChange state ', details.date, '---', details.endDate);

    return (
        <div className={classes.mainRoot}>
            <div className={classes.root}>
                {/* <span className={classes.title}>Chase Meet</span> */}
                <div className={classes.calendarHeader}>
                    <CustomDatePicker
                        id="meet-calendar"
                        views={["date"]}
                        format={"MMMM YYYY"}
                        variant="inline"
                        value={details.date || defaultDate}
                        onChange={(e) => handleDateChange(e)}
                    />
                    <div className={classes.actionButtons}>
                        {/* <div className={classes.actionButtonRoot}>
                            <div title="Instant meeting" className={classes.actionButtonIcon}>
                                <Videocam />
                            </div>
                            <p>Instant meeting</p>
                        </div>
                        <div className={classes.actionButtonRoot} onClick={() => { props.history.push(`${path}/jointocode`) }}>
                            <div title="Join with code" className={classes.actionButtonIcon} >
                                <AddIcon />
                            </div>
                            <p>Join with code</p>
                        </div> */}
                        <div className={classes.actionButtonRoot} onClick={() => props.history.push('/meet/schedule-meeting')}>
                            <div title="Schedule meeting" className={classes.actionButtonIcon}>
                                <ScheduledCalendarIcon />
                            </div>
                            <p>Schedule meeting</p>
                        </div>
                    </div>
                    <div className={classes.navigatorsRoot}>
                        <IconButton onClick={() => handleWeekChange(-7)}><KeyboardArrowLeft /></IconButton>
                        <Button variant="text" size="medium" className={classes.button} onClick={() => handleDateChange(defaultDate)}>Today</Button>
                        {/* <span className="navigatorText">Today </span> */}
                        <IconButton onClick={() => handleWeekChange(7)}><KeyboardArrowRight /></IconButton>
                    </div>
                </div>
                <div className={classes.calendarContent}>
                    <Calendar startDate={details.date} endDate={details.endDate} />
                </div>
            </div>
        </div>
    )
}

export default withRouter(MeetingCalendar);