import React from 'react';
import { withRouter } from "react-router-dom";
import { Avatar, makeStyles } from '@material-ui/core';
import { getAvatarText } from '../../helpers';
import { getFiltersInfo } from '../../ducks/game';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    colorDefault: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        cursor: 'pointer',
        width: '120px',
        height: '120px',
        marginRight: '7px',
        borderRadius: '60px',
        color: 'black',
        border: '1px solid black'

    },
    colorDefault2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        borderRadius: '60px',
        color: 'white',
        border: '1px solid white',
        width: 28,
        height: 28,
        fontSize: 16,
        marginRight: 7
    }
}));

const ProfileLogo = ({ from, name = '', username = '', type, url = '/', history, filtersData, getFiltersInfo, ...others }) => {
    const classes = useStyles();
    const handleProfileClick = () => {
        if (filtersData?.season) {
            getFiltersInfo({ type, username, filtersData })
        }
        else {
            history.push(`/profile/${username}`)


        }

    }
    return (
        <Avatar
            className={from ? classes.colorDefault2 : classes.colorDefault}
            onClick={handleProfileClick}
            src={url}
        >
            {getAvatarText(name).toUpperCase()}
        </Avatar>
    )

}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getFiltersInfo: (options) => dispatch(getFiltersInfo(options)),


    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileLogo));