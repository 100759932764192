import React from 'react';
// import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
// import Grow from '@material-ui/core/Grow';
import { connect } from 'react-redux';
import { Backdrop, IconButton, makeStyles, Snackbar, SnackbarContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { setNetworkStatus } from '../../ducks/network';
import { ReactComponent as NoInternetIcon } from '../../assets/icons/no-wifi.svg'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 1401,
        color: theme.palette.primary.main,
        // position: 'absolute',
        backgroundColor: '#ffffffe6',
        flexDirection: 'column'
    },
    iconStyles: {
        fill: theme.palette.primary.main
    }
}));

function SlideTransition(props) {
    return <Slide {...props} direction="up" timeout={50} />;
}

const NetworkComponent = ({ network, setNetworkStatus }) => {
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNetworkStatus({ offline: false, message: '' });
    };

    return (
        <Backdrop className={classes.backdrop} open={network.offline}>
            <NoInternetIcon className={classes.iconStyles} width="50" height="50" />
            <p>{network.message}</p>
            {/* <Snackbar
                open={network.offline}
                onClose={handleClose}
                TransitionComponent={SlideTransition}
                // message={network.message}
                key={'network-bottom-left'}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                style={{ opacity: 0.8 }}
                autoHideDuration={network.duration || null}
            // action={
            //     <IconButton
            //         aria-label="close"
            //         color="inherit"
            //         // className={classes.close}
            //         onClick={handleClose}
            //     >
            //         <Close />
            //     </IconButton>
            // }
            >
                <SnackbarContent style={{ backgroundColor: network.status && '#43a047' }} message={network.message} />
            </Snackbar> */}
        </Backdrop>
    );
}


const mapStateToProps = (state) => ({
    network: state.network
});

const mapDispatchToProps = (dispatch) => ({
    setNetworkStatus: (options) => dispatch(setNetworkStatus(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(NetworkComponent);