import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemAvatar, Avatar, Badge, withStyles, SvgIcon } from '@material-ui/core';
import { DoneAll, Done } from '@material-ui/icons';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { setCurrentChat, getChats } from '../../ducks/chats';
import { statusList, highlightText, convertUnicode, getEllipsisText, getAvatarText } from '../../helpers';
import { getChatList } from '../../selectors';
import moment from 'moment';
import TeamIcon from '../../assets/icons/team-icon-6.png';
import { setCurrentTeam } from '../../ducks/teams';
import DocFormat from '../../assets/images/doc-format.png';
import PdfFormat from '../../assets/images/pdf-format.png';
import ExcelFormat from '../../assets/images/excel.svg';
import LinkIcon from '../../assets/images/link.png'
import ImageIcon from '../../assets/images/image-icon.svg'
import VideoIcon from '../../assets/images/video-icon.svg'
import SurveyIcon from "../../assets/icons/survey-1.svg";
import ArenaIcon from "../../assets/icons/arena-1.svg";
import PaperClipIcon from '../../assets/icons/paperclip-2.svg';
import { ContactTemplate } from './ContactList';
import { setGlobalSearchQuery } from '../../ducks/search';
import xml_to_js from 'xml-js';
import { getSearchMessages } from '../../ducks/messages';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSnackbar } from 'notistack';

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: theme.palette.primary.main
    },
}))(Avatar);

const GroupAvatarStyles = makeStyles((theme) => ({
    svgIconStyles: {
        '& circle': {
            fill: theme.palette.primary.main
        }
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    }
}));

export const GroupAvatar = ({ details }) => {
    const classes = GroupAvatarStyles();
    return (
        <Badge
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={
                <SmallAvatar alt="Group icon" src={'/'} >
                    <img src={TeamIcon} width="18px" height="20px" />
                </SmallAvatar>
            }
        >
            <Avatar
                alt={details.contactName}
                src={(details.photoUrl && details.photoUrl.length) ? details.photoUrl : (details.image_url) ? details.image_url : '/'}
                classes={{ colorDefault: classes.avatarStyles }}
            // src={'/'}
            >
                {details.name ? getAvatarText(details.name || details.contactName) : ''}
                {/* <DefaultTeamAvatar className={classes.svgIconStyles} width="100%" height="100%" /> */}
            </Avatar>
        </Badge>
    )
}

const templateStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        // fontFamily: 'Roboto'
    },
    listItemContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0px',
        whiteSpace: 'nowrap'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '7px',
        fontSize: 13,
        color: '#58595B'
    },
    secondarytext: {
        margin: 0,
        fontSize: '11px',
        color: '#838383',
        display: 'flex',
        alignItems: 'center'
    },
    timestamp: {
        margin: 0,
        paddingBottom: '5px',
        fontSize: 11,
        color: '#838383'
    },
    unreadCount: {
        margin: 0,
        textAlign: "right",
        paddingRight: '14px'
    },
    unreadCountColor: {
        color: '#fff',
        // backgroundColor: 'orange'
    },
    statusBadge: {
        border: '2px solid #fff',
        height: '14px',
        width: '14px',
        borderRadius: '7px'
    },
    statusColor: {
        backgroundColor: props => props.statusColor,
    },
    readStatusIcon: {
        fontSize: '1rem',
        verticalAlign: 'middle',
        paddingRight: '5px'
    },
    imageBody: {
        marginLeft: 10
    },
    svgIcon: {
        overflow: 'inherit',
        fontSize: '1.7rem',
        '& path': {
            fill: theme.palette.primary.main,
            // color: theme.palette.primary.main,
            // stroke: theme.palette.primary.main,
        }
    },
    avatarStyles: {
        backgroundColor: theme.palette.primary.main
    }
}));

const commonMsgBody = (body, defaultMsg, substringL) => {
    return <span style={{ marginLeft: defaultMsg === 'Quoted' ? 0 : 10 }}>{body ? (convertUnicode(body).length > substringL ? convertUnicode(body).substring(0, substringL) + '...' : convertUnicode(body)) : defaultMsg}</span>
}

const getParsedMsg = (msg) => {
    try {
        let newMsg = JSON.parse(msg.replace(/%%%/gi, ''));
        switch (newMsg.msgType) {
            case 'quote':
                return <>{commonMsgBody(newMsg.body, 'Quoted', 22)}</>
            case 'image':
                return <><img src={ImageIcon} alt='image' />{commonMsgBody(newMsg.body, 'Photo', 17)}</>
            case 'video':
                return <><img src={VideoIcon} alt='video' />{commonMsgBody(newMsg.body, 'Video', 17)}</>
            case 'file':
                if (newMsg.fileType === 'pdf')
                    return <><img width="10" src={PdfFormat} alt='image' />{commonMsgBody(newMsg.body, 'File', 17)}</>
                else if (newMsg.fileType === 'doc' || newMsg.fileType === 'docx')
                    return <><img width="10" src={DocFormat} alt='image' />{commonMsgBody(newMsg.body, 'File', 17)}</>
                else if (newMsg.fileType === 'xls' || newMsg.fileType === 'xlsx')
                    return <><img width="12" src={ExcelFormat} alt='image' />{commonMsgBody(newMsg.body, 'File', 17)}</>
            case 'link':
                return <><img width="18" src={LinkIcon} alt='link' />{commonMsgBody(newMsg.body, 'Link', 17)}</>
            case 'survey':
                return <><img width="12" height="14" src={SurveyIcon} alt='link' /><span style={{ marginLeft: 10 }}>{newMsg.title ? (newMsg.title.length > 17 ? newMsg.title.substring(0, 17) + '...' : newMsg.title) : 'Survey'}</span></>
            case 'post':
                return <><img width="12" height="14" src={ArenaIcon} alt='link' /><span style={{ marginLeft: 10 }}> Post Shared</span></> // {commonMsgBody(newMsg.text, 'Post Shared', 17)}
            case 'meet':
                return <><img width="12" height="14" src={PaperClipIcon} alt='link' />{commonMsgBody(null, 'Meeting link shared', 17)}</>
            case 'call':
                return <span>{getEllipsisText('Call feature is under construction', 25)}</span>
            default:
                break;
        }
    }
    catch {
        return "Invalid Message..."
    }
}

const ChatTemplate = ({ details, value, setCurrentChat, history, handleChat, setGlobalSearchQuery, fromGlobalSearch, globalSearchQuery, getSearchMessages }) => {
    const classes = templateStyles({ statusColor: statusList.find(item => item.show === details.show).color });

    const handleListItemClick = (user) => {
        // if(user.body === undefined && user.type === 'group'){
        //     setCurrentTeam(user);
        // }
        if (globalSearchQuery) {
            const messageId = (user.stanza && (xml_to_js.xml2js(user.stanza)) && (xml_to_js.xml2js(user.stanza)).elements[0].attributes) && (xml_to_js.xml2js(user.stanza)).elements[0].attributes.id;
            const chatType = (user.stanza && (xml_to_js.xml2js(user.stanza)) && (xml_to_js.xml2js(user.stanza)).elements[0].attributes) && (xml_to_js.xml2js(user.stanza)).elements[0].attributes.type;
            getSearchMessages({ timestamp: user.sentdate, targetJid: user.targetJid, type: chatType === 'groupchat' ? 'group' : 'individual', params: { step: 'previous' } });
            localStorage.setItem('messageId', messageId);
            localStorage.setItem('globalSearchQuery', globalSearchQuery);
            setCurrentChat(user);
            handleChat({ ...user, messageId, globalSearchQuery, fromSearchResult: true });
            setGlobalSearchQuery('');

        }
        else {
            localStorage.setItem('messageId', '');
            localStorage.setItem('globalSearchQuery', '');
            setCurrentChat(user);
            handleChat({ ...user, globalSearchQuery });
            setGlobalSearchQuery('');
        }
    }

    let time = new Date(details.sentdate)
    let isToday = time.toDateString() == new Date().toDateString();

    const msgBody = convertUnicode(details.body || '');

    return (
        <ListItem key={value} button selected={details.isCurrent} onClick={() => handleListItemClick(details)}>
            {!fromGlobalSearch &&
                <ListItemAvatar>
                    {
                        details.type === 'group' ?
                            <GroupAvatar details={details} />
                            :
                            <Badge color="primary" classes={{ badge: classes.statusBadge, colorPrimary: classes.statusColor }} overlap={'circle'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} variant="dot">
                                <Avatar
                                    classes={{ colorDefault: classes.avatarStyles }}
                                    alt={details.name}
                                    src={(details.image_url && details.image_url.length) ? details.image_url : '/'}
                                >{details.name && (details.name.includes('@') ? details.name.substring(0, 1) : getAvatarText(details.name))}</Avatar>
                            </Badge>
                    }
                </ListItemAvatar>
            }
            <div className={classes.listItemContent}>
                <div>
                    <p className={classes.primaryText}>{details.name && ((details.name.length > 18) ? details.name.substring(0, 18) + '...' : details.name) || details.targetJid}</p>
                    <p className={classNames(classes.secondarytext, 'messageBodyClass')}>
                        {/* {details.outgoing && (details.messageStatus === 'displayed' ? <DoneAll color="primary" className={classes.readStatusIcon} /> : details.messageStatus === 'received' ? <DoneAll className={classes.readStatusIcon} /> : <Done className={classes.readStatusIcon} />)} */}
                        {details.body ? (details.body.startsWith('%%%') ? getParsedMsg(details.body) : (msgBody.length > 22 ? msgBody.substring(0, 22) + '...' : msgBody)) : <p style={{ visibility: "visible", margin: 0 }}>{(details.type === 'group') ? details.description : 'New_Chat'}</p>}
                        {/* {details.body ? (details.body.length > 22 ? details.body.substring(0, 22) + '...' : details.body) : <p style={{ visibility: "visible", margin: 0 }}>{(details.type === 'group') ? details.description : 'New_Chat'}</p>} */}
                    </p>
                </div>
                <div>
                    <p className={classes.timestamp}>{details.sentdate && (isToday ? moment(details.sentdate).format("hh:mm A") : moment(details.sentdate).format("DD-MMM"))}</p>
                    <p className={classes.unreadCount}>
                        {details.body &&
                            <Badge badgeContent={details.count} invisible={details.count < 1} color="primary" classes={{ colorPrimary: classes.unreadCountColor }} max={99}></Badge>
                        }
                    </p>
                </div>
            </div>
        </ListItem>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        // width: '100%'
    },
    listRoot: {
        // width: '100%',
        // maxWidth: '350px',
        // minWidth: '350px',
        // backgroundColor: '#fff',
        // height: '100%',
        //     -webkit-transition: 'width 1s ease-in-out'
        // -moz-transition:width 1s ease-in-out;
        // -o-transition: width 1s ease-in-out;
        // transition: 'width 0.5s ease-in-out',
        // [theme.breakpoints.down('sm')]: {
        //     width: '0px',
        // },
    },
    scroller: {
        maxHeight: '78vh',
        minHeight: '78vh',
    },
    chatRoot: {
        width: '100%',
        backgroundColor: '#F3F2F1'
    },


    // Chat Template
    listItemContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '8px 0px',
        whiteSpace: 'nowrap'
    },
    primaryText: {
        fontWeight: 600,
        margin: 0,
        paddingBottom: '4px',
    },
    secondarytext: {
        margin: 0,
        fontSize: '12px',
    },
    timestamp: {
        margin: 0,
        paddingBottom: '5px',
        fontSize: '13px',
    },
    statusBadge: {
        border: '2px solid #fff',
        height: '14px',
        width: '14px',
        borderRadius: '7px'
    },


    unreadCount: {
        margin: 0,
        textAlign: "right",
        paddingRight: '14px'
    },
    unreadCountColor: {
        color: '#fff',
        // backgroundColor: 'orange'
    },
    readStatusIcon: {
        fontSize: '1rem',
        verticalAlign: 'middle',
        paddingRight: '5px'
    },
    teamsTitle: {
        margin: 0,
        color: '#444444',
        backgroundColor: '#F3F2F1',
        padding: '0 20px'
    },
    noResults: {
        textAlign: 'center'
    }
}));

let pagination = {
    page: 0,
    limit: 15
};

const ChatList = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    console.log('ChatList props');

    useEffect(() => {

        if (props.chats && props.chats.some(survey => survey.isCurrent)) {
            const isCurrent = props.chats.find(survey => survey.isCurrent);
            if (isCurrent) props.handleChat(isCurrent, true);
        }
    }, [props.chats]);

    useEffect(() => {
        if (props.globalSearchQuery && props.globalSearchQuery.length) {
            // highlightText(props.globalSearchQuery, 'messageBodyClass');
        }
    });

    useEffect(() => {
        console.log('ChatList ');
        if (props.chats && props.chats.length === 0)
            props.getChats({
                params: { limit: pagination.limit, page: 0, },
                onSuccessCB,
                onErrorCB
            });
    }, [])

    const fetchMoreData = e => {
        props.getChats({
            params: { limit: pagination.limit, page: pagination.page + 1, },
            onSuccessCB,
            onErrorCB
        });
    };

    const onSuccessCB = (result, headers) => {
        console.log("headers ", result, headers.page, pagination);
        pagination.page = parseInt(headers.page);
        pagination.total_count = parseInt(headers.total_count);
    };

    const onErrorCB = error => {
        console.log(error);
        enqueueSnackbar(`Failed to fetch Chat list.`, {
            variant: "error",
            preventDuplicate: true
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.listRoot}>
                <div id="chat-list-scroll" className={classNames("scroller", classes.scroller)}>

                    {
                        (props.globalSearchQuery && props.globalSearchQuery.length) ?
                            [
                                <List dense>
                                    {
                                        props.globalSearchResult.length ?
                                            props.globalSearchResult.map((result, index) => {
                                                return (
                                                    <>
                                                        {
                                                            (result.index === 'messages' || result.index === 'teams') ?
                                                                <ChatTemplate fromGlobalSearch={true} globalSearchQuery={props.globalSearchQuery} key={index} details={result} value={index} {...props} getSearchMessages={props.getSearchMessages} />
                                                                :
                                                                result.index === 'employees' ?
                                                                    <ContactTemplate fromGlobalSearch={true} globalSearchQuery={props.globalSearchQuery} key={index} contactDetails={result} {...props} setCurrentChat={props.setCurrentChat} />
                                                                    :
                                                                    null

                                                        }
                                                    </>
                                                )
                                            })
                                            :
                                            <div className={classes.noResults}>
                                                <p>No results found</p>
                                            </div>
                                    }
                                </List>
                            ]
                            :
                            <>
                                <List dense>
                                    <InfiniteScroll
                                        dataLength={props.chats.length}
                                        next={fetchMoreData}
                                        hasMore={props.chats.length !== pagination.total_count}
                                        // loader={skeletonLoading}
                                        scrollableTarget="chat-list-scroll"
                                    >
                                        {
                                            props.chats && props.chats.map((value, index) => {
                                                return (
                                                    <ChatTemplate key={index} details={value} value={index} {...props} />
                                                );
                                            })
                                        }

                                    </InfiniteScroll >
                                </List>
                                {/* {props.teams.length > 0 && <p className={classes.teamsTitle}>Teams</p>}
                                <List dense>
                                    {props.teams && props.teams.map((value, index) => {
                                        return (
                                            <ChatTemplate key={index} details={value} value={index} {...props} />
                                        );
                                    })}
                                </List> */}
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    chats: getChatList(state.chats, state.client.jid),
    teams: Object.values(state.teams),
    // globalSearchResult: state.search.global,
    // globalSearchQuery: state.search.searchQuery
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        getSearchMessages: (options) => dispatch(getSearchMessages(options)),
        setCurrentChat: (options) => dispatch(setCurrentChat(options)),
        setCurrentTeam: (options) => dispatch(setCurrentTeam(options)),
        setGlobalSearchQuery: (options) => dispatch(setGlobalSearchQuery(options)),
        getChats: (options) => dispatch(getChats(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatList);