import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/surveys");

export const SURVEYS_RECEIVED = constant("SURVEYS_RECEIVED");
export const SET_CURRENT_SURVEY = constant("SET_CURRENT_SURVEY");
export const GET_SURVEYS = constant("GET_SURVEYS");
export const GET_SURVEY_DETAILS = constant("GET_SURVEY_DETAILS");
export const GET_SURVEY_QUESTIONS = constant("GET_SURVEY_QUESTIONS");
export const SET_SURVEY_QUESTIONS = constant("SET_SURVEY_QUESTIONS");
export const SEND_SURVEY_RESPONSE = constant("SEND_SURVEY_RESPONSE");
export const UPDATE_SURVEYS_STATUS = constant("UPDATE_SURVEYS_STATUS");

export const sendSurveyResponse = (payload) => ({
    type: SEND_SURVEY_RESPONSE,
    payload
})

export const updateSurveyStatus = (payload) => ({
    type: UPDATE_SURVEYS_STATUS,
    payload
})

export const setSurveyQuestions = (payload) => ({
    type: SET_SURVEY_QUESTIONS,
    payload
})

export const getQuestions = (payload) => ({
    type: GET_SURVEY_QUESTIONS,
    payload
})

export const getSurveys = (payload) => ({
    type: GET_SURVEYS,
    payload
})

export const getSurveyDetails = (payload) => ({
    type: GET_SURVEY_DETAILS,
    payload
})

export const setCurrentSurvey = (surveyDetails) => ({
    type: SET_CURRENT_SURVEY,
    payload: surveyDetails
})

export const receivedSurveys = payload => ({
    type: SURVEYS_RECEIVED,
    payload: payload
});

const initialState = {
    // "5efb2ce3a2c3f70aa5e5f66c": {
    //     "_id": "5efb2ce3a2c3f70aa5e5f66c",
    //     "type": "open",
    //     "question_ids": [
    //         "5eec93811be8b8002a2dc2d1",
    //         "5eec93e61be8b8002a2dc2d2"
    //     ],
    //     "name": "new survey C",
    //     "description": "testing the survey C",
    //     "org_id": "Chase",
    //     "tenant_id": "chase-dev",
    //     "user_id": "jagadishwarg",
    //     "created_by": "jagadishwarg@wavelabs.ai",
    //     "publish_date": "2020-06-30T12:15:31.036Z",
    //     "expire_date": "2020-07-05T12:15:31.036Z",
    //     "created_at": "2020-06-30T12:15:31.049Z",
    //     "updated_at": "2020-06-30T12:15:31.049Z",
    //     "__v": 0,
    //     isCurrent: false,
    //     status: 'expires',
    //     statusText: `Expires in ${Math.floor(((new Date('2020-07-05T12:15:31.036Z')) - (new Date())) / (1000 * 60 * 60 * 24))} days`
    // },
    // "5efb2ce3a2c3f70aa5e5f66d": {
    //     "_id": "5efb2ce3a2c3f70aa5e5f66d",
    //     "type": "open",
    //     "question_ids": [
    //         "5eec93811be8b8002a2dc2d1",
    //         "5eec93e61be8b8002a2dc2d2"
    //     ],
    //     "name": "new survey D",
    //     "description": "testing the survey D",
    //     "org_id": "Chase",
    //     "tenant_id": "chase-dev",
    //     "user_id": "jagadishwarg",
    //     "created_by": "jagadishwarg@wavelabs.ai",
    //     "publish_date": "2020-06-30T12:15:31.036Z",
    //     "expire_date": "2020-07-05T12:15:31.036Z",
    //     "created_at": "2020-06-28T12:15:31.049Z",
    //     "updated_at": "2020-06-30T12:15:31.049Z",
    //     "__v": 0,
    //     isCurrent: false,
    //     status: 'expired',
    //     statusText: 'Expired'
    // },
    // "5efb2ce3a2c3f70aa5e5f66e": {
    //     "_id": "5efb2ce3a2c3f70aa5e5f66d",
    //     "type": "open",
    //     "question_ids": [
    //         "5eec93811be8b8002a2dc2d1",
    //         "5eec93e61be8b8002a2dc2d2"
    //     ],
    //     "name": "new survey D",
    //     "description": "testing the survey E",
    //     "org_id": "Chase",
    //     "tenant_id": "chase-dev",
    //     "user_id": "jagadishwarg",
    //     "created_by": "jagadishwarg@wavelabs.ai",
    //     "publish_date": "2020-06-30T12:15:31.036Z",
    //     "expire_date": "2020-07-05T12:15:31.036Z",
    //     "created_at": "2020-06-25T12:15:31.049Z",
    //     "updated_at": "2020-06-30T12:15:31.049Z",
    //     "__v": 0,
    //     isCurrent: false,
    //     status: 'responded',
    //     statusText: 'Responded'
    // },
}

// reducer
export default (state = initialState, action) => {
    switch (action.type) {

        case SET_CURRENT_SURVEY: {
            console.log('SET_CURRENT_SURVEY ', action);
            const surveyId = action.payload;
            let newState = JSON.parse(JSON.stringify(state));

            const prevSurvey = Object.keys(newState).find(key => newState[key].isCurrent === true);

            if (newState[prevSurvey]) {
                newState[prevSurvey].isCurrent = false;
            }
            if (newState[surveyId]) {
                newState[surveyId].isCurrent = true;
            }
            return newState;
        }

        case SURVEYS_RECEIVED: {

            console.log('SURVEYs ', action);

            return { ...action.payload };
        }

        case UPDATE_SURVEYS_STATUS: {
            const surveyId = action.payload;
            const surveyDetails = state[surveyId];

            console.log('SURVEYs ', action);

            return {
                ...state,
                [surveyId]: {
                    ...surveyDetails,
                    isCurrent: false,
                    status: 'responded',
                    statusText: 'Responded'
                }
            };
        }

        case SET_SURVEY_QUESTIONS: {
            const surveyId = action.payload.surveyId;
            const surveyDetails = state[surveyId];
            return {
                ...state,
                [surveyId]: {
                    ...surveyDetails,
                    questions: [...action.payload.data]
                }
            }
        }

        default:
            return state;
    }
};
