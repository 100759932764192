import { Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getRewardStandings, getRewardsWinnings } from '../../ducks/game';
import { setLoading } from '../../ducks/loading';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import gameIllustration from '../../assets/images/game_Illustration.png'
import qs from 'query-string';
import RewardWinningCard from './RewardWinningCard';
import RewardsBannerComponent from '../Leagues/RewardsBanner';
import { openCustomDialog } from '../../ducks/meetings';
import RewardsDetails from '../Leagues/RewardsDetails';
import CustomDialogComponent from '../ChaseMeet/CustomDialogComponent';

const useStyles = makeStyles((theme) => ({
    rewardsContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    disabled: {
        // opacity: 0.5,
        // backgroundColor: '#FFF !important'

    },

}));


let pagination = {
    page: 1,
    limit: 10,
};

const RewardsWinning = ({ filtersData, getRewardsWinnings, history, openCustomDialog, getRewardStandings }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [rewards, setRewards] = useState([]);
    const qryparams = qs.parse(history.location.search);

    useEffect(() => {
        let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
        if (seasonId) {
            setLoading(true);
            getRewardsWinnings({ seasonId: seasonId, params: { page: 1, limit: pagination.limit }, onSuccessCB, onErrorCB });
        }
    }, []);

    const getMoreRewards = () => {
        let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
        getRewardsWinnings({ seasonId: seasonId, params: { page: pagination.page + 1, limit: pagination.limit }, onSuccessCB, onErrorCB })
    }

    const onSuccessCB = (result, headers) => {
        if (result.length > 0 && headers) {
            // setRewards(prevState => ([...prevState, ...result]));
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
            const rewardsData = result.map(reward => ({ ...reward, expired: moment(reward.end_date).isBefore(new Date(), 'day') }))
            setRewards(pagination.page === 1 ? rewardsData : [...rewards, ...rewardsData]);
        }
        else {
            setLoading(false);
            setRewards([])
        }
    }

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rewards List', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleRewardSelect = (index) => {
        openCustomDialog({ open: true, children: <RewardsDetails reward={rewards[index]} rewardIndex={index} getRewardStandings={getRewardStandings} setLoading={setLoading} filtersData={filtersData} />, title: "Rewards", from: "standings" });
    }

    return (
        <div>
            {rewards?.length > 0 ?
                <InfiniteScroll
                    dataLength={rewards.length}
                    next={getMoreRewards}
                    hasMore={rewards.length !== pagination.total_count}
                    scrollableTarget="leagues-root1"
                    style={{ overflow: "unset" }}
                    className={classes.infiniteScrollRoot}
                >
                    <div className={classes.rewardsContainer}>
                        <Grid container spacing={1}>
                            {
                                [...rewards]?.map((obj, i) => (
                                    <Grid key={i} item xs={12} md={6} lg={4} xl={3}>
                                        {
                                            obj?.coupon_code ?
                                                <div >
                                                    <RewardWinningCard index={i} obj={obj} rewards={rewards} setRewards={setRewards} />
                                                </div>
                                                :
                                                <div className={classes.disabled} onClick={() => handleRewardSelect(i)} style={{ cursor: 'pointer' }} >
                                                    <RewardsBannerComponent obj={obj} rewardFrom='WINNING' />
                                                </div>
                                        }
                                    </Grid>
                                )

                                )
                            }
                        </Grid>
                    </div>
                    {rewards?.length > 0 && <CustomDialogComponent />}
                </InfiniteScroll>

                :
                <div style={{ textAlign: 'center', marginTop: 60 }}>
                    <img src={gameIllustration} />
                    <p style={{ fontSize: 14, color: '#0000008A' }}>We do not have any Rewards to show here</p>
                </div>
            }
        </div >
    )
}
const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        getRewardsWinnings: (options) => dispatch(getRewardsWinnings(options)),
        openCustomDialog: (options) => dispatch(openCustomDialog(options)),
        getRewardStandings: (options) => dispatch(getRewardStandings(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RewardsWinning));