import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLoading } from '../../ducks/loading';
import AvatarComponent from '../AvatarComponent/AvatarComponent';
import { getMatchesList } from '../../ducks/game';
import { useSnackbar } from 'notistack';
import { getNumberFormat } from '../../helpers';
import { ListItem } from '@material-ui/core';
import { GetDates } from '.';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    teamCard: {
        border: '1px solid #F0F2F5',
        // borderRadius: 6,
        minWidth: 200,
        // margin: '0 0 24px',
        backgroundColor: '#FFF',
        height: 'auto',
        '& .title': {
            margin: 0,
            fontSize: 16,
            fontWeight: 600,
            borderBottom: '1px solid #F0F2F5',
            // color: '#0000008f',
            // backgroundColor: '#dfe1e4',
            padding: 10,
            '& .liveLable': {
                fontSize: 12,
                color: 'red',
                float: 'right',
                padding: 10,
                fontWeight: 400,
            }
        }
    },
    teamAvatar: {
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        height: 24,
        width: 24,
        border: '2px solid #FFF',
        // marginRight: 8
    },
    matchesRoot: {
        // margin: '0px 0 45px',
        '& .date': {
            margin: '0 0 5px',
            fontSize: 12,
            fontWeight: 600,
            color: '#0000008f',
            textAlign: 'center'
        },
        '& .Mui-selected': {
            // background: 'linear-gradient(270deg,#ff2882,#963cff 45%)',
            backgroundColor: `${theme.palette.primary.main}1A`,
            color: '#000 !important',
            '&:hover': {
                color: '#535353 !important',
            }
        },
        '& .match': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
            borderBottom: '1px solid #F0F2F5',
            color: '#535353',
            fontSize: 12,
            '& .name': {
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                margin: 0,
                width: '100%',
                textAlign: 'center',
                // textTransform: 'uppercase',
                // fontWeight: 600,
                '&:last-child': {
                    marginLeft: 5
                }
            },
            '& .score': {
                // border: '1px solid #bcbcbd69',
                display: 'flex',
                alignItems: 'center',
                height: 20,
                backgroundColor: '#381a3c',
                color: '#FFF',
                fontWeight: 600,
                margin: '0 5px',
                '& span': {
                    padding: 5
                }
            }
        }
    }
}));

const RoundMatchesCard = ({ filtersData, getMatchesList, setLoading, userDetails, handleViewChange }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [matches, setMatches] = useState([]);
    const [matchesByDiv, setMatchesByDiv] = useState(null);

    useEffect(() => {
        if (filtersData.season?._id && filtersData.round?._id) {
            getMatches();
        }
    }, [filtersData.round]);

    const getMatches = (type) => {
        setLoading(true);
        getMatchesList({
            seasonId: filtersData.season?._id,
            params: { limit: 5000, page: 1, type, round_id: filtersData.round?._id },
            onSuccessCB: (result, resHeaders) => {
                if (result.length > 0) {
                    // const searchValue = getSearchValue(history, 'match');
                    // if (type === 'TEAM_MATCH') {
                    // if (filtersData.season?.divisions_enabled)
                    //     setMatchesByDiv(groupBy(result.map(obj => ({
                    //         ...obj,
                    //         team1: obj.teams[0],
                    //         team2: obj.teams[1],
                    //     })), 'division_name'));
                    // else
                    let currentUserMatch = result.find(m => m?._id === resHeaders?.my_match_id);
                    if (currentUserMatch) {
                        currentUserMatch['selected'] = true;
                        let matchesFIl = result.filter(m => {
                            return m._id !== resHeaders?.my_match_id
                        });
                        console.log(currentUserMatch, matchesFIl)
                        setMatches([currentUserMatch, ...matchesFIl].map(obj => ({
                            ...obj,
                            team1: obj.teams[0],
                            team2: obj.teams[1],
                        })));
                    }
                    else {
                        setMatches(result.map(obj => ({
                            ...obj,
                            selected: false,
                            team1: obj.teams[0],
                            team2: obj.teams[1],
                        })));
                    }


                    // const groupByValue = getSearchValue(history, 'viewBy');
                    // if (groupByValue) {
                    //     if (searchValue) {
                    //         setViewType('DETAILS');
                    //         setSelectedMatch(result?.find(obj => obj._id === searchValue));
                    //     }
                    //     else {
                    //         setViewType('LIST');
                    //         setSelectedMatch(null);
                    //     }
                    // }
                    // }
                    // else {
                    //     setSprintMatches([...result]);
                    //     !searchValue && setSearchQuery(history, { match: (result?.find(obj => obj.status === 'live') || result[0])?._id });
                    // }
                    // setFiltersData(prevState => ({ ...prevState, match: searchValue ? result?.find(obj => obj._id === searchValue) : (result?.find(obj => obj.status === 'live') || result[0]) }));
                }
                else {
                    setMatches([]);
                    setMatchesByDiv(null);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setMatches([]);
                setMatchesByDiv(null);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const MatchCard = ({ match }) => {
        return (
            <ListItem button className="match" selected={match?.selected} classes={{ selected: 'Mui-selected' }} onClick={() => handleViewChange('DETAILS', match)}>
                <p title={match.teams[0].name} className="name">{(match.teams[0].name)}</p>
                <AvatarComponent name={match.teams[0].name} onClick={() => { }} url={match.teams[0].logo_url} className={classes.teamAvatar} />
                <div className="score">
                    <span>{getNumberFormat(userDetails?.countryCode, match.teams[0]?.score || 0)}</span>-<span>{getNumberFormat(userDetails?.countryCode, match.teams[1]?.score || 0)}</span>
                </div>
                <AvatarComponent name={match.teams[1].name} onClick={() => { }} url={match.teams[1].logo_url} className={classes.teamAvatar} />
                <p title={match.teams[1].name} className="name">{(match.teams[1].name)}</p>
                <ArrowForward style={{ fontSize: '1.2rem', marginLeft: 3 }} />
            </ListItem>
        )
    }

    return (
        <div className={classes.teamCard}>
            <p className="title">Matches
            <GetDates filtersData={filtersData} />
                <span className="liveLable">Live</span>
            </p>
            <div className={classes.matchesRoot}>
                {matches?.map(match => <MatchCard match={match} />)}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (options) => dispatch(setLoading(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options))
    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RoundMatchesCard));