import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, IconButton, Badge, Backdrop, CircularProgress, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { CameraAltOutlined, Close, Check, Add } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { updateProfile, updateWorkList, updateEducationList, getArenaProfile, updateBasicProfile, deleteEducation, deleteWorkExperience } from "../../ducks/user";
import { getFavouriteStatus, followUser, unFollowUser } from '../../ducks/favourites';
import { uploadFilesToS3 } from "../../ducks/common";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat-1.svg";
import moment from "moment";
import { withRouter } from 'react-router';
import { MarkFavButton, RemoveFavButton } from "../Arena/ReactionsDialog";
import { setCurrentChat } from "../../ducks/chats";
import { getAvatarText } from "../../helpers";
import FavouritesLists from './FavouritesLists';
import ProfileDetail from "./ProfileDetail";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxHeight: 'calc(100vh - 116px)',
        overflowY: 'auto',
        padding: '40px 24px',
        display: 'flex',
        backgroundColor: "#f5f5f5",
        paddingBottom: 15,
    },
    gridContainer: {
        display: 'flex',
        width: '100%',
        margin: '0 auto',
        maxWidth: 1250,
    },
    rightSectionRoot: {
        flexGrow: 0,
        maxWidth: '27.5%',
        flexBasis: '27.5%',
    },
    mainroot: {
        padding: '0px 20px',
        flexGrow: 0,
        maxWidth: '72.5%',
        flexBasis: '72.5%',
    },
    cardDisplay: {
        backgroundColor: "#FFFFFF",
        padding: '0px',
        borderRadius: 3,
        height: '296px',
        boxShadow: "0px 0px 4px 1px #EDEDED",
        position: 'relative',
    },
    badge: {
        border: "2px solid #fff",
        height: "35px",
        width: "35px",
        borderRadius: "20px",
    },
    badge1: {
        border: "2px solid #fff",
        height: "35px",
        width: "35px",
        borderRadius: "20px",
        right: '18px',
        top: '17px'
    },
    profilePicRoot: {
        width: '100%',
        padding: "24px 0 12px",
        position: 'absolute',
        bottom: 0,

    },
    iconRootCam: {
        color: "#fff"
    },
    avatarSize: {
        width: "122px",
        height: "122px",
        fontSize: 60,
        backgroundColor: theme.palette.primary.main,
        border: '2px solid white',
        '& img': {
            objectFit: 'fill'
        }
    },
    avatarSize1: {
        width: "100%",
        height: "180px",
        margin: '0 auto',
        fontSize: 60,
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        '& img': {
            objectFit: 'fill'
        }
    },
    profileNameRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto',
        alignItems: 'center',
        padding: '0px 20px'
    },
    profileNameActions: {
        position: 'absolute',
        marginLeft: 20,
        top: 4,
        right: -110
    },
    profileName: {
        fontSize: 16,
        color: "#141414",
    },
    backdropImage: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        position: "absolute",
        borderRadius: "50%",

    },
    backdropImage1: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        position: "absolute",
    },
    BackgroundCvr: {
        background: '#eefbff',
        height: '180px',
        textAlign: 'center',
        position: 'absolute',
        top: '-84px',
        width: '100%',
    },

    about: {
        backgroundColor: "#f7f7f7",
        padding: "0 18px",
        height: 45,
        margin: "24px 0px",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            margin: 0,
            fontSize: 14,
            fontWeight: 600,
            color: '#535353'
        }
    },
    svgIcon: {
        overflow: "inherit",
    },
    iconRoot: {
        marginRight: 5
    },
    editIconStyles: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        margin: '0 5px',
        '& svg': {
            '& path': {
                fill: '#FFF'
            }
        },
        '& .delete-icon': {
            height: 14,
            '& path': {
            }
        }
    },
    actionButtonStyles: {
        padding: 8
    },
    favourite: {
        width: "270px",
        height: "41px",
        borderRadius: "6px",
        backgroundColor: "#ffcc00",
        marginBottom: 10,
        border: 'none'
    },
    inputStyles: {
        fontSize: 15,
        color: '#535353'
    },
    nameInputStyles: {
        textAlign: 'center',
        color: '#141414',
        fontSize: 16,
        fontWeight: 600
    },
    inputUnderine: {
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    },

    favButton: {
        height: 36,
        minWidth: 100,
        '& path': {
            fill: '#FFF'
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '& .fav-button-text': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
    chatbutton: {
        borderRadius: 35,
        height: 30,
        minWidth: 100,
        marginRight: '8px',
        '&:hover': {
            boxShadow: 'none'
        },
    },
    followbutton: {
        borderRadius: 35,
        height: 30,
        '& path': {
            fill: '#FFF'
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '& .fav-button-text': {
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            }
        }
    },
}));

const defaultBirthDate = new Date(new Date().getFullYear() - 1, 11, 31);

const PlayerProfile = props => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [uploadingImage1, setUploadingImage1] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [details, setDetails] = useState({
        name: "",
        photoUrl: null,
        bannerImage: null,
        email: "",
        phoneNo: "",
        designation: "",
        about: "",
        departmentName: null,
        role: '',
        region: ''
    });
    const [editedDetails, setEditedDetails] = useState({})
    const [edit, setEdit] = useState({
        name: false,
        about: false,
        education: false,
        workExperience: false
    });
    const [isFavourite, setIsFavourite] = useState(false);
    const [preview, setPreview] = useState({
        open: false,
        attachments: [],
        attachmentIndex: 0
    });
    const [openConfirmationDialog, setConfirmationDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState("")

    useEffect(() => {
        if (isLoggedInUser()) {
            setDetails(props.user);
        }
    }, [props.user]);


    useEffect(() => {
        if (!isLoggedInUser() && props.match.params.id) {
            props.getArenaProfile({
                username: props.match.params.id,
                onEmployeeDetailsSuccess,
                onEmployeeDetailsError
            });
            props.getFavouriteStatus({
                username: props.match.params.id,
                onFavouriteStatusSuccess,
                onFavouriteStatusError
            })
        }
        else {
            setDetails(props.user);
        }
    }, [props.match.params.id]);

    const handleOnCameraClick = () => {
        document.getElementById("hiddenFileInputForProfilePic").click();
    };
    const handleOnCameraClick1 = () => {
        document.getElementById("hiddenFileInputForProfilePic1").click();
    };

    const handlePhotoChange = (event, field) => {
        let files = event.target.files;
        if (files && files.length) {
            var formData = new FormData();
            if (field === 'photo_url') {
                setUploadingImage(true);
            } else { setUploadingImage1(true) }
            formData.append("file", files[0]);
            props.uploadFilesToS3({ formData, onUploadSuccessCB: (res) => onUploadSuccessCB(res, field), onUploadErrorCB });
        }
        setIsEdited(true);
    };

    const onUploadSuccessCB = (result, field) => {
        let reqobjdub = {
            "about": details.about,
            "birthDate": details.dateOfBirth,
            "gender": details.gender,
            "hobbies": details.hobbies,
            "location": details.location,
            "name": details.name,
            "privacy": details.privacy,
            "phoneNo": details.phoneNo,
            "photo_url": details.photoUrl,
            "bannerImage": details.bannerImage,
            "roleId": details?.role?.id,
            'regionId': details?.region?.id
        }
        if (field === 'photo_url') {
            reqobjdub.photo_url = result.url
        } else {
            reqobjdub.bannerImage = result.url
        }
        props.updateBasicProfile({
            requestObj: reqobjdub,
            employeeId: details.employeeId,
            onBasicProfileUpdateSuccess, onBasicProfileUpdateError
        })
    };

    const onUploadErrorCB = result => {
        setUploadingImage(false);
        setUploadingImage1(false);
    };

    const formatUserInfoProperty = userInfo => {
        if (userInfo) {
            return userInfo;
        } else {
            return "--";
        }
    };

    const handleChange = (event) => {
        let editedDetailsDup = JSON.parse(JSON.stringify(editedDetails));
        let id = event.target.id;
        let value = event.target.value;
        switch (id) {
            case 'name':
                if (value.length <= 100 && !/[~`!@#$%\^&*()+=\-\[\]\\';,./{}|\\":<>\?]/g.test(value)) {
                    editedDetailsDup.name = value;
                }
                break;
            case 'info':
                if (value.length <= 300) {
                    editedDetailsDup.about = value;
                }
                break;
            case 'location':
                editedDetailsDup.location = value;
                break;
            case 'phoneNo':
                if (value.length <= 10) {
                    editedDetailsDup.phoneNo = value;
                }
                break;
            case 'hobbies':
                editedDetailsDup.hobby = value;
                break;
            case 'field':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], fieldOfStudy: value };
                break;
            case 'school':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], schoolOrCollege: value };
                break;
            case 'degree':
                editedDetailsDup.educations[0] = { ...editedDetailsDup.educations[0], degree: value };
                break;
            case 'company':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], company: value };
                break;
            case 'position':
                editedDetailsDup.workExperience[0] = { ...editedDetailsDup.workExperience[0], position: value };
                break;

            default:
                break;
        }
        setEditedDetails({ ...editedDetailsDup });
    }

    const handleUpdate = (type) => {
        let requestObj = {};
        switch (type) {
            case 'about':
            case 'name':
                requestObj = {
                    "about": editedDetails.about,
                    "birthDate": editedDetails.dateOfBirth || defaultBirthDate,
                    "gender": editedDetails.gender,
                    "hobbies": editedDetails.hobbies,
                    "location": editedDetails.location,
                    "name": editedDetails.name,
                    "privacy": editedDetails.privacy,
                    "phoneNo": editedDetails.phoneNo,
                    "photo_url": editedDetails.photoUrl,
                    "bannerImage": editedDetails.bannerImage,
                    "roleId": details?.role?.id,
                    'regionId': details?.region?.id
                }
                props.updateBasicProfile({
                    requestObj,
                    employeeId: editedDetails.employeeId,
                    onBasicProfileUpdateSuccess, onBasicProfileUpdateError
                })
                break;
            case 'education':
                requestObj = {
                    "educationId": editedDetails.educations[0].educationId || editedDetails.educationId,
                    "educations": [
                        {
                            "degree": editedDetails.educations[0].degree,
                            "fieldOfStudy": editedDetails.educations[0].fieldOfStudy,
                            "schoolOrCollege": editedDetails.educations[0].schoolOrCollege,
                            "startYear": new Date(editedDetails.educations[0].startYear).getFullYear(),
                            "endYear": new Date(editedDetails.educations[0].endYear).getFullYear()
                        }
                    ]
                }
                props.updateEducationList({
                    requestObj,
                    username: details.username,
                    employeeId: editedDetails.employeeId,
                    onEducationUpdateSuccess, onEducationUpdateError
                })
                break;
            case 'workExperience':
                requestObj = {
                    "experienceId": editedDetails.workExperience[0].experienceId || editedDetails.experienceId,
                    "workExperience": [
                        {
                            "company": editedDetails.workExperience[0].company,
                            "position": editedDetails.workExperience[0].position,
                            "startYear": {
                                "month": new Date(editedDetails.workExperience[0].startYear).getMonth() + 1,
                                "year": new Date(editedDetails.workExperience[0].startYear).getFullYear()
                            },
                            "endYear": {
                                "month": new Date(editedDetails.workExperience[0].endYear).getMonth() + 1,
                                "year": new Date(editedDetails.workExperience[0].endYear).getFullYear()
                            },
                        }
                    ]
                }
                props.updateWorkList({
                    requestObj,
                    username: details.username,
                    employeeId: editedDetails.employeeId,
                    onWorkUpdateSuccess, onWorkUpdateError
                })
                break;

            default:
                break;
        }
    }

    const onBasicProfileUpdateSuccess = (result) => {
        setUploadingImage(false);
        setUploadingImage1(false);
        setEdit(prevState => ({ ...prevState, about: false, name: false }));
    }
    const onBasicProfileUpdateError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.errors[0] : 'Failed to update details.' ? error.response.data.errors.length > 0 : error.response.data.errors[0], {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const onEducationUpdateSuccess = result => {
        setEdit(prevState => ({ ...prevState, education: false }));
    };
    const onEducationUpdateError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to update Education details.', {
            variant: 'error',
            preventDuplicate: true
        });

    };

    const onWorkUpdateSuccess = result => {
        setEdit(prevState => ({ ...prevState, workExperience: false }));
    };
    const onWorkUpdateError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to update Work-Experience details.', {
            variant: 'error',
            preventDuplicate: true
        });
    };
    const handleConfirmationDialog = (value, text1, text2) => {
        if (text1 === "yes" && text2 === "Education") {
            setConfirmationDialog(false);
            props.deleteEducation({
                details: {
                    employeeId: details.employeeId,
                    educationId: details.educations[0].educationId || details.educationId,
                    updatedObj: { ...details, educations: [] }
                },
                onEducationDeleteSuccess, onEducationDeleteError
            })
        }
        else if (text1 === "yes" && text2 === "Experience") {
            setConfirmationDialog(false);
            props.deleteWorkExperience({
                details: {
                    employeeId: details.employeeId,
                    workExperienceId: details.workExperience[0].experienceId || details.experienceId,
                    updatedObj: { ...details, workExperience: [] }
                },
                onWorkExperienceDeleteSuccess, onWorkExperienceDeleteError
            })
        }
        else {
            setDeleteItem(text1)
            setConfirmationDialog(value)
        }
    }
    const handleEditClick = (type) => {
        setEdit(prevState => ({ ...prevState, [type]: true }));
        let detailsDup = JSON.parse(JSON.stringify(details));
        detailsDup.educations = (detailsDup.educations && detailsDup.educations.length > 0) ?
            detailsDup.educations.map(obj => ({
                ...obj, startYear: new Date((obj.startYear ? obj.startYear : new Date().getFullYear()), 0, 1), endYear: new Date((obj.endYear ? obj.endYear : new Date().getFullYear()), 0, 1)
            }))
            :
            detailsDup.educations = [{
                "educationId": "",
                "degree": "",
                "fieldOfStudy": "",
                "schoolOrCollege": "",
                "startYear": new Date(),
                "endYear": new Date()
            }];
        detailsDup.workExperience = (detailsDup.workExperience && detailsDup.workExperience.length > 0) ?
            detailsDup.workExperience.map(obj => ({
                ...obj,
                startYear: new Date((obj.startYear.year ? obj.startYear.year : new Date().getFullYear()), (obj.startYear.month ? (obj.startYear.month - 1) : new Date().getMonth()), 1),
                endYear: new Date((obj.endYear.year ? obj.endYear.year : new Date().getFullYear()), (obj.endYear.month ? (obj.endYear.month - 1) : new Date().getMonth()), 1),
                // endYear: new Date(obj.endYear.year, obj.endYear.month, 1)
            }))
            :
            detailsDup.workExperience = [{
                "experienceId": "",
                "company": "",
                "position": "",
                "endYear": new Date(),
                "startYear": new Date()
            }]
        setEditedDetails({ ...detailsDup });
    }

    const handleDeleteClick = (type) => {
        switch (type) {
            case 'education':
                handleConfirmationDialog(true, "Education");
                break;
            case 'workExperience':
                handleConfirmationDialog(true, "Experience");
                break;
            default:
                break;
        }
    }

    const onEducationDeleteSuccess = (result) => { }
    const onEducationDeleteError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to delete Education details.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const onWorkExperienceDeleteSuccess = (result) => { }
    const onWorkExperienceDeleteError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to delete Work-Experience details.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const ActionButtons = ({ type, data, hasDelete, addNewIcon, ...others }) => (
        <div {...others}>
            {edit[type] ?
                <>
                    <IconButton title="Cancel" onClick={() => setEdit(prevState => ({ ...prevState, [type]: false }))} className={classes.actionButtonStyles}><Close /></IconButton>
                    <IconButton title="Save" onClick={() => handleUpdate(type)} className={classes.actionButtonStyles}><Check /></IconButton>
                </>
                :
                <>
                    <IconButton title="Edit" onClick={() => handleEditClick(type, data)} className={classes.editIconStyles}>{addNewIcon ? <Add fontSize="small" /> : <EditIcon />}</IconButton>
                    {
                        hasDelete &&
                        <IconButton title="Delete" onClick={() => { handleDeleteClick(type) }} className={classes.editIconStyles}><DeleteIcon className="delete-icon" /></IconButton>
                    }
                </>
            }
        </div>
    )

    const onFollowUser = (follow) => {
        if (follow) {
            let requestObj = {
                username: props.match.params.id
            };
            props.followUser({
                requestObj,
                onFollowUserSuccess,
                onFollowUserError
            });
        }
        else {
            props.unFollowUser({
                username: props.match.params.id,
                onUnFollowUserSuccess,
                onUnFollowUserError
            })
        }
    };

    const onFollowUserSuccess = success => {
        setIsFavourite(true);
    };
    const onFollowUserError = success => {
    };

    const onUnFollowUserSuccess = result => {
        setIsFavourite(false);
    }
    const onUnFollowUserError = error => {
    }

    const isLoggedInUser = () => {
        if (props.match.params.id && props.match.params.id !== props.user.username) {
            return false
        }
        else {
            return true;
        }
    }

    const onEmployeeDetailsSuccess = result => {
        setDetails(result);
    };
    const onEmployeeDetailsError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch details.', {
            variant: 'error',
            preventDuplicate: true
        });
    };

    const onFavouriteStatusSuccess = result => {
        setIsFavourite(result.follower);
    }
    const onFavouriteStatusError = error => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch favourite status.', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleChatClick = (userDetails) => {
        props.setCurrentChat({ targetJid: `${userDetails.username}@${process.env.REACT_APP_XMPP_DOMAIN}`, chatId: `${userDetails.username}@${process.env.REACT_APP_XMPP_DOMAIN}`, targetName: userDetails.name, presence: props.presence ? (props.presence.show || 'unavailable') : 'unavailable', photo_url: userDetails.photoUrl, bannerImage: userDetails.bannerImage, type: 'chat' });
        props.history.push('/chats');
    }

    const handlePreview = (attachments, attachmentIndex) => {
        setPreview({ open: true, attachments, attachmentIndex });
    }

    const handlePreviewClose = () => {
        setPreview({ open: false, attachments: [] });
    }

    return (
        <div id="arena-list-root" className={classes.root}>
            <div className={classes.gridContainer}>
                <div className={classes.mainroot}>
                    <div className={classes.cardDisplay}>
                        <div className={classes.profilePicRoot}>
                            <div className={classes.BackgroundCvr}>
                                {isLoggedInUser() ?
                                    <>
                                        <Badge
                                            overlap={"circle"}
                                            variant="circle"
                                            color="primary"
                                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                            classes={{ badge: classes.badge1 }}
                                            style={{ width: '100%' }}
                                            badgeContent={
                                                <IconButton onClick={() => handleOnCameraClick1()}>
                                                    <CameraAltOutlined
                                                        classes={{ root: classes.iconRootCam }}
                                                        fontSize={"small"}
                                                    />
                                                </IconButton>
                                            }
                                        >
                                            <Avatar
                                                id="bannerImage"
                                                className={classes.avatarSize1}
                                                src={details.bannerImage}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => details.bannerImage && handlePreview([{ url: details.bannerImage, mimeType: 'IMAGE' }], 0)}
                                            >{getAvatarText(details.name)}</Avatar>
                                            <Backdrop className={classes.backdropImage1} open={uploadingImage1}>
                                                <CircularProgress color="inherit" />
                                            </Backdrop>
                                        </Badge>
                                        <input
                                            type="file"
                                            id="hiddenFileInputForProfilePic1"
                                            name="bannerImage"
                                            onChange={e => handlePhotoChange(e, 'bannerImage')}
                                            style={{ display: "none" }}
                                            accept="image/*"
                                        />
                                    </>
                                    :
                                    <Avatar
                                        id="bannerImage"
                                        className={classes.avatarSize1}
                                        src={details.bannerImage}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => details.bannerImage && handlePreview([{ url: details.bannerImage, mimeType: 'IMAGE' }], 0)}
                                    >{getAvatarText(details.name)}</Avatar>
                                }
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                {isLoggedInUser() ?
                                    <>
                                        <Badge
                                            overlap={"circle"}
                                            variant="circle"
                                            color="primary"
                                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                            classes={{ badge: classes.badge }}
                                            badgeContent={
                                                <IconButton onClick={() => handleOnCameraClick()}>
                                                    <CameraAltOutlined
                                                        classes={{ root: classes.iconRootCam }}
                                                        fontSize={"small"}
                                                    />
                                                </IconButton>
                                            }
                                        >
                                            <Avatar
                                                id="profileImg"
                                                className={classes.avatarSize}
                                                src={details.photoUrl}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => details.photoUrl && handlePreview([{ url: details.photoUrl, mimeType: 'IMAGE' }], 0)}
                                            >{getAvatarText(details.name)}</Avatar>
                                            <Backdrop className={classes.backdropImage} open={uploadingImage}>
                                                <CircularProgress color="inherit" />
                                            </Backdrop>
                                        </Badge>
                                        <input
                                            type="file"
                                            id="hiddenFileInputForProfilePic"
                                            name="profileImg"
                                            onChange={e => handlePhotoChange(e, 'photo_url')}
                                            style={{ display: "none" }}
                                            accept="image/*"
                                        />
                                    </>
                                    :
                                    <Avatar
                                        id="profileImg"
                                        className={classes.avatarSize}
                                        src={details.photoUrl}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => details.photoUrl && handlePreview([{ url: details.photoUrl, mimeType: 'IMAGE' }], 0)}
                                    >{getAvatarText(details.name)}</Avatar>
                                }
                            </div>
                            <div className={classes.profileNameRoot}>
                                <div style={{ position: 'relative' }}>
                                    {(edit.name && isLoggedInUser()) ?
                                        <TextField
                                            id="name"
                                            autoFocus
                                            placeholder="Enter Your Name.."
                                            value={editedDetails.name}
                                            error={!editedDetails.name && true}
                                            helperText={!editedDetails.name && "Please enter your Name."}
                                            onChange={handleChange}
                                            style={{ margin: '10px 0 20px' }}
                                            InputProps={{ classes: { input: classes.nameInputStyles, underline: classes.inputUnderine, underline: classes.inputUnderine } }}
                                        />
                                        :
                                        <h3 className={classes.profileName}>
                                            {formatUserInfoProperty(details.name)}
                                        </h3>
                                    }
                                    {isLoggedInUser() && <ActionButtons type="name" className={classes.profileNameActions} style={{ marginTop: !edit.name && 10 }} />}
                                </div>
                                <div>
                                    {!isLoggedInUser() &&
                                        <div style={{ position: 'relative' }}>
                                            {/* <Button title="Chat" className={classes.chatbutton} onClick={() => handleChatClick(details)} variant="outlined" color="primary"> Chat </Button> */}
                                            {
                                                !isFavourite ?
                                                    <MarkFavButton
                                                        className={classes.followbutton}
                                                        onClick={() => onFollowUser(true)}
                                                    />
                                                    :
                                                    <RemoveFavButton
                                                        className={classes.followbutton}
                                                        onClick={() => onFollowUser(false)}
                                                    />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.tabroot}>
                        <ProfileDetail username={props.match.params.id} />
                    </div>
                </div>
                <div className={classes.rightSectionRoot} >
                    <FavouritesLists type="playerProfile" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    user: state.user,
    presence: state.presence[`${props.match.params.id}@${process.env.REACT_APP_XMPP_DOMAIN}`]
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        updateBasicProfile: options => dispatch(updateBasicProfile(options)),
        updateEducationList: value => dispatch(updateEducationList(value)),
        updateWorkList: value => dispatch(updateWorkList(value)),
        getArenaProfile: value => dispatch(getArenaProfile(value)),
        followUser: options => dispatch(followUser(options)),
        unFollowUser: options => dispatch(unFollowUser(options)),
        updateProfile: options => dispatch(updateProfile(options)),
        uploadFilesToS3: options => dispatch(uploadFilesToS3(options)),
        getFavouriteStatus: options => dispatch(getFavouriteStatus(options)),
        deleteEducation: options => dispatch(deleteEducation(options)),
        deleteWorkExperience: options => dispatch(deleteWorkExperience(options)),
        setCurrentChat: (options) => dispatch(setCurrentChat(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerProfile));


