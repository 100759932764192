import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Avatar,
  Button,
  makeStyles,
} from "@material-ui/core";
import Moment from "react-moment";
const NotificationStyles = makeStyles((theme) => ({
  avatar: {
    marginLeft: "1rem",
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      // width: '85%',
    },
  },
  avatarStyles: {
    backgroundColor: theme.palette.primary.main,
  },
  timestamp: {
    margin: "5px 10px 0px",
    fontSize: 14,
    color: "#838383",
  },
  button: {
    width: "178px",
    height: "30px",
    border: "solid 1px #ffcd2e",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "178px",
      marginBottom: "1rem",
    },
  },
  buttonStyle: {
    border: "none",
    outline: "none",
    color: "#535353",
    fontSize: "15px",
    lineHeight: 1.33,
    "&:hover": {
      border: "none",
      outline: "none",
    },
  },
  text: {
    minHeight: "20px",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.33,
    flex: 1,
    marginBottom: "5px",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      // width: '85%',
    },
  },
  both: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5rem",
    flexWrap: "wrap"
  },
  content: {
    flex: 1,
    marginLeft: "1rem",
  },
}));
moment.updateLocale("en", {
  relativeTime: {
    dd: "%d d",
    ww: "%d w",
    MM: "%d m",
    yy: "%d y",
    M: "%d mo",
    y: "%d y",
    m: "#d m",
    mm: "%d m",
    H: "%d h",
    HH: "%d,h",
    s: "%d s",
    ss: "%d s"
  },
});
const SayBirthday = () => {
  const classes = NotificationStyles();
  return (
    <>
      <div className={classes.both}>
        <div className={classes.avatar}>
          <Avatar
            classes={{ colorDefault: classes.avatarStyles }}
            alt="saivivek"
            src="hi.jpeg"
          >
            SR
          </Avatar>
        </div>
        <div className={classes.content}>
          {" "}
          <p className={classes.text}>Wish John Keelen happy birthday</p>
          <div className={classes.button}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonStyle}
            >
              Say Happy Birthday
            </Button>
          </div>
        </div>

        <p className={classes.timestamp}>
          <Moment fromNow ago>
            2020-09-04T15:15:26.415+00:00
          </Moment>
        </p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SayBirthday);
