import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LaneTitle, NewLaneButtons, Section } from './Styles/Base'
import { AddButton, CancelButton } from './Styles/Element'
import NewLaneTitleEditor from './NewLaneTitleEditor';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { addNewLane,projectDetails } from '../../../ducks/pms';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { setLoading } from "../../../ducks/loading";

// import uuidv1 from 'uuid/v1'

class NewLane extends Component {
  

  getValue = () => this.refInput.getValue().trim().toUpperCase() ;

  onSuccessCB =(data)=>{
    this.props.onAdd({ 
      id: '',
      title: this.getValue().toUpperCase() 
  })
    this.props.enqueueSnackbar(`New stage Created.`, {
      variant: "success",
      preventDuplicate: true
  });
  }

  onErrorCB=(error)=>{
    this.props.setLoading(false)
    if(error.response.data.error==='Stages title Already exists'){
      this.props.enqueueSnackbar(`Stage title already exists`, {
        variant: "error",
        preventDuplicate: true
    });
    }
    else {
      this.props.enqueueSnackbar(`Something went wrong.`, {
        variant: "error",
        preventDuplicate: true
    });
    }
   
  }
  handleSubmit = () => {
    this.props.setLoading(true)
    if(this.getValue().length > 0){
      let obj ={label:this.getValue(),key:"INTERMEDIATE"}
      // this.onSuccessCB()
      this.props.addNewLane({requestObj:obj,id:this.props.project_id,onSuccessCB:this.onSuccessCB,
        onErrorCB:this.onErrorCB});
    }
    else {
      this.props.onCancel()
    }
  }

  onClickOutside = (e) => {
    if (this.getValue().length > 0) {
      this.handleSubmit()
    } else {
      // e.preventDefault()
      this.props.onCancel()
    }
  }

  render() {
    const { onCancel, t } = this.props
    return (
        <Section style={{display:'flex'}}>
          <LaneTitle>
            <NewLaneTitleEditor
              ref={ref => (this.refInput = ref)}
              placeholder={t('placeholder.title')}
              onCancel={this.props.onCancel}
              onSave={this.handleSubmit}
              resize='vertical'
              // autoCapitalize
              border
              autoFocus
            />
            <>
            <span style={{ cursor: 'pointer', color: '#828282', marginLeft: 5 }}><CheckIcon onClick={this.handleSubmit} /></span>
            <span style={{ cursor: 'pointer', color: '#828282', }}><ClearIcon onClick={onCancel} /></span>
          </>
          </LaneTitle>
          
          {/* <NewLaneButtons>

          <CancelButton onClick={onCancel}>{t('button.Cancel')}</CancelButton>
             <AddButton onClick={this.handleSubmit}>{t('button.Add lane')}</AddButton>
          </NewLaneButtons> */}
        </Section>
    )
  }
}

NewLane.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

NewLane.defaultProps = {}

const mapStateToProps = (state) => (
  {
      project_id: state.pms.project.id,
  })


const mapDispatchToProps = (dispatch, props) => {
  return {
      addNewLane: (options) => dispatch(addNewLane(options)),
      setLoading: value => dispatch(setLoading(value))
  }
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(NewLane))) ;