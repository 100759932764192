import orderBy from "lodash/orderBy";
import differenceBy from "lodash/differenceBy";

import { makeConstant } from "./_helpers";

const constant = makeConstant("jchat/presence");


export const PRESENCE_AVAILABLE = constant("PRESENCE_AVAILABLE");
export const PRESENCE_UNAVAILABLE = constant("PRESENCE_UNAVAILABLE");

export const INDIVIDUAL_PRESENCE = constant("INDIVIDUAL_PRESENCE");

export const receivedIndividualPresence = presence => ({
    type: INDIVIDUAL_PRESENCE,
    payload: presence
  });

export const receivedPresenceAvailable = presence => ({
  type: PRESENCE_AVAILABLE,
  payload: presence
});

export const receivedPresenceUnavailable = presence => ({
  type: PRESENCE_UNAVAILABLE,
  payload: presence
});

const initialState = {}

// reducer
export default (state = initialState, action) => {
  switch (action.type) {

    case INDIVIDUAL_PRESENCE: {

        const presence = action.payload;
        const peerJid = presence.from.split('/')[0];
        const peer = state[peerJid] || {
            targetJid: peerJid
        };

        let show = presence.type;
        if (presence.show) {
            show = (presence.show === 'xa') ? 'away' : (presence.show === 'chat') ? 'available' : presence.show;
        }


        let status = "";
        if (presence.status) {
            status = presence.status;
            if (!presence.show) {
                show = presence.status === 'Online' ? 'available' : presence.status === 'Away' ? 'away' : presence.status === 'Busy' ? 'dnd' : 'unavailable'
            }
        }

        if (!presence.status && !presence.type && !presence.show) {
            show = 'available'
        }

        return {
            ...state,
            [peer.targetJid]: {
                ...peer,
                show: show,
                status: status
            }
        };

    }

    case PRESENCE_AVAILABLE: {

        const presence = action.payload;
        const peerJid = presence.from.split('/')[0];
        const peer = state[peerJid] || {
            jid: peerJid,
            members: []
        };

        let show = presence.type;
        if(presence.show) {
            show = presence.show
        }

        let status = "";
        if(presence.status) {
            status = presence.status;
        }


        if(state[peer.jid] && state[peer.jid].members) {

            var currentMembers = differenceBy(state[peer.jid].members, [{ 'resource': presence.from.split('/')[1] }], 'resource');

            currentMembers.push({
                resource: presence.from.split('/')[1], 
                role: presence.muc.role,
                presence: show,
                status: status
            });

            return {
                ...state,
                [peer.jid]: {
                ...peer,
                members: orderBy(currentMembers, ['resource'], ['asc'])
                }
            };

        }

        return {
            ...state,
            [peer.jid]: {
            ...peer,
            members: [
                // ...peer.members,
                {
                    resource: presence.from.split('/')[1], 
                    role: presence.muc.role,
                    presence: show,
                    status: status
                }
            ]}
        };

        
    }

    case PRESENCE_UNAVAILABLE: {

        const presence = action.payload;
        const peerJid = presence.from.bare;
        const peer = state[peerJid];

        let currentMembers = [];
        if(peer.members !== undefined) {
            currentMembers = peer.members;
        }
        
        let remainingMembers = currentMembers.filter((member) => {
            return member.resource !== presence.from.resource;
        });

        return {
            ...state,
            [peer.jid]: {
                ...peer,
                members: remainingMembers
            }
        };

    }

    default:
      return state;
  }
};
