import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { makeStyles, TextField, Backdrop, CircularProgress, IconButton, InputAdornment, FormControl, OutlinedInput } from '@material-ui/core';
import { changePassword } from '../../ducks/user';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '35px'
    },
    title: {
        fontSize: '24px',
        fontWeight: '600',
        color: theme.palette.primary.main,
        margin: '0 0 30px'
    },
    lable: {
        marginBottom: '15px',
        fontSize: '17px',
        color: '#141414',
        margin: '0 0 15px'
    },
    textField: {
        marginBottom: '20px'
    },
    fieldPasswordNote: {
        marginBottom: 5
    },
    passwordNote: {
        fontSize: 11
    },
    extraTop: {
        marginTop: 15
    },
    inputHeight: {
        height: '0.6876em'
    },
    buttonRoot: {
        textAlign: 'center',
        marginTop: '12px'
    },
    button: {
        // backgroundColor: '#F77D1D',
        color: '#fff',
        // borderRadius: 0,
        width: '123px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    errorMsg: {
        color: 'red',
    },
    successMsg: {
        color: 'green',
    },
    closeButton: {
        position: 'absolute',
        right: 5,
        top: 5
    },
    paper: {
        borderRadius: 0
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ChangePasswordDialog = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open);
    const [details, setDetails] = useState({
        currentPassword: "",
        newPassword: "",
        confirmation: ""
    });
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.handleChangePasswordDialog();
        }, 1000);
    };

    const handleChangeinput = (event) => {
        let id = event.target.id;
        let value = event.target.value;
        setDetails(prevState => {
            return { ...prevState, [id]: value }
        })
    }

    const onSuccessCB = (result) => {
        console.log('onSuccessCB ');
        setErrorMsg('Updated Successfully');
        setDetails({
            currentPassword: "",
            newPassword: "",
            confirmation: ""
        })
        setLoading(false);
        handleClose();
    }

    const onErrorCB = (err) => {
        console.log('onSuccessCB ', err);
        if (err.data && err.data.error_message) {
            setErrorMsg(err.data.error_message);
        }
        else {
            setErrorMsg('Failed to update');
        }
        setLoading(false);
    }

    const handleSubmit = () => {
        setLoading(true);
        let requestObj = {
            currentPassword: details.currentPassword,
            newPassword: details.newPassword,
            confirmation: details.confirmation
        }
        props.changePassword({ details: requestObj, onSuccessCB, onErrorCB });
    }


    const handleClickShowCurrentPassword = () => {
        setDetails(prevState => ({ ...prevState, showCurrentPassword: !prevState.showCurrentPassword }));
    };

    const handleClickShowNewPassword = () => {
        setDetails(prevState => ({ ...prevState, showNewPassword: !prevState.showNewPassword }));
    };

    const handleClickShowConfirmPassword = () => {
        setDetails(prevState => ({ ...prevState, showConfirmPassword: !prevState.showConfirmPassword }));
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
                scroll="paper"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                classes={{ paper: classes.paper }}
            >

                <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose()}>
                    <Close />
                </IconButton>
                <div className={classes.root}>
                    <h3 className={classes.title}>Change Password</h3>
                    {
                        errorMsg &&
                        <p className={classNames(errorMsg.includes('Success') ? classes.successMsg : classes.errorMsg)}><small>{errorMsg}</small></p>
                    }
                    <div>
                        <p className={classes.lable}>Type your current password</p>
                        <TextField
                            fullWidth
                            id="currentPassword"
                            variant="outlined"
                            color="primary"
                            InputLabelProps={{ shrink: false }}
                            type={details.showCurrentPassword ? 'text' : 'password'}
                            InputProps={{
                                classes: { input: classes.inputHeight }, endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowCurrentPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {details.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            className={classes.textField}
                            value={details.currentPassword}
                            onChange={handleChangeinput}
                        />
                    </div>
                    <div>
                        <p className={classes.lable}>Type your new password</p>
                        <TextField
                            fullWidth
                            id="newPassword"
                            variant="outlined"
                            color="primary"
                            InputLabelProps={{ shrink: false }}
                            type={details.showNewPassword ? 'text' : 'password'}
                            InputProps={{
                                classes: { input: classes.inputHeight }, endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {details.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            className={classNames(classes.textField, classes.fieldPasswordNote)}
                            value={details.newPassword}
                            onChange={handleChangeinput}
                        />
                        <span className={classes.passwordNote}>* At least 8 characters. Should contain at least 1 capital letter, 1 symbol and 1 number.</span>
                    </div>
                    <div>
                        <p className={classNames(classes.lable, classes.extraTop)}>Retype your new password</p>
                        <TextField
                            fullWidth
                            type="password"
                            id="confirmation"
                            variant="outlined"
                            color="primary"
                            InputLabelProps={{ shrink: false }}
                            type={details.showConfirmPassword ? 'text' : 'password'}
                            InputProps={{
                                classes: { input: classes.inputHeight }, endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {details.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            className={classes.textField}
                            value={details.confirmation}
                            onChange={handleChangeinput}
                        />
                    </div>
                    <div className={classes.buttonRoot}>
                        <Button variant="contained" color="primary" size="large" className={classes.button} onClick={() => handleSubmit()}>
                            Save
                        </Button>
                    </div>
                </div>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </div>
    );
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        changePassword: (options) => dispatch(changePassword(options))
    };
};

export default connect(null, mapDispatchToProps)(ChangePasswordDialog);