import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import { getCurrencyFormat, getDurationWithUnit, getNumberFormat, seasonKpiPositionsSort } from "../../../helpers";
import { setLoading } from "../../../ducks/loading";
import { getExportPlayerKpisStats, getPlayerBonusKpisStats } from "../../../ducks/game";
import AvatarComponent from "../../AvatarComponent/AvatarComponent";
import { Info } from "@material-ui/icons";
import { Button, Checkbox, Chip, fade, FormControl, IconButton, InputAdornment, InputBase, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import IllustrationView from "../../LiveScores/IllustrationView";
import MaterialUITable from "../../GlobalComponents/MaterialUITable";
import { Tooltip } from "@material-ui/core";
import { DecimalViewSwitch } from "../../LiveScores/CustomSwitch";
import { CSVLink } from "react-csv";
import FilterListIcon from "@material-ui/icons/FilterList";
import MUILink from "@material-ui/core/Link";
import StatsPlayerFilterModal from "./StatsPlayerFilterModal";
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NotificationDialog from "../../GlobalComponents/NotificationDialog";
import ArrowRight from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'underscore';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export.svg'
import GetCountryCurrencyFormat from "../../GlobalComponents/GetCountryCurrencyFormat";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '-10px 0 0px',
        '& .material-table td div': {
            border: 'none !important'
        },
        '& .Mui-selected': {
            '& td': {
                color: '#000',
                backgroundColor: 'white !important',
            }
        },
    },
    formRoot1: {
        width: 250,
        position: 'relative',
        '& .close_icn': {
            margin: 0,
            position: 'absolute',
            top: '11px',
            right: ' 35px',
            color: '#565050',
            cursor: 'pointer'
        }
    },
    baseWidth: { cursor: 'pointer', borderRadius: 3 },
    activefiltertext: {
        fontSize: "14px",
        fontWeight: "500",
        marginRight: 14
    },
    chiparea: {
        margin: "0 14px 5px 0",
        color: "#58595B",
        backgroundColor: "#F1F1F1",
        height: '25px',
        fontSize: '12px',
    },
    clear: {
        fontSize: "14px",
        cursor: "pointer",
        color: "#FFC407",
        display: "inline-flex"
    },
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    FilterListIconborder:
        { border: "1px solid black", borderRadius: "5px" },
    title: {
        // margin: '0 0 10px',
        fontSize: 11,
        color: '#58595B',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 20,
            width: 23,
            color: '#142552',
            marginRight: 5
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.35),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.5),
        },
        width: 'auto',
        maxWidth: 180,
        minWidth: 180,
    },
    notification: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        height: '40px',
        marginBottom: '-2px',
        fontSize: '12px'
    },
    searchIcon: {
        color: '#0000004d',
        padding: '0px 8px 0px 25px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    inputRoot: {
        color: 'initial',
        height: '100%',
        width: 'inherit'
    },
    inputInput: {
        border: '1px solid #0000003a',
        borderRadius: '3px',
        height: 22,
        margin: '0 10px',
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        fontSize: '12px'
    },
    formRoot: {
        marginRight: '10px',
        width: 180
    },
    autocompleteRoot: {
        width: 180,
        height: '40px',
        borderRadius: 3,
        overflow: 'clip',
        whiteSpace: 'nowrap',
        "& .MuiAutocomplete-tagSizeSmall": {
            width: '65px !important'
        },
        "& .MuiInputBase-root": {
            overflow: 'hidden !important'
        },
    },
    exportButtn: {
        '& .MuiButton-outlinedPrimary': {
            border: '1px solid #0000003a !important'
        }
    }
}));

const PlayerBonusKpisStats = ({ setLoading, loading, seasonId = '', seasonFormat = '', matchId = '', playerId, teamId, roleId, userDetails, getPlayerBonusKpisStats, roles, teams, kpis, filtersData, isAdmin, seasonKpiPostions, seasonDetails, getExportPlayerKpisStats }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicHeaders, setDynamicHeaders] = useState([]);
    const [resData, setResData] = useState({ response: [], headers: null });
    const [decimalView, setDecimalView] = useState(false);
    const [downloadPlayerBonusStatData, setDownloadPlayerBonusStatData] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [clearFilterDisplay, setClearFilterDisplay] = useState(false)
    const [statsPlayerFilter, setStatsPlayerFilter] = useState({
        teams: [],
        roles: [],
        kpi: []
    });
    const [rankLimit, setRankLimit] = useState({ low: "", high: "" });
    const [achivementsSelects, setAchivementsSelects] = useState([{ kpiName: null, percentageRange: [0, 0], percentageHighest: false }]);
    const [actualsSelects, setActualsSelects] = useState([{ kpiName: null, actualMin: 0, actualMax: 0, actualMeasurement: null }]);
    const [plannedAchivementsSelects, setPlannedAchivementsSelects] = useState([{ kpiName: null, percentageRange: [0, 0], percentageHighest: false }]);
    const [basedOn, setBasedOn] = useState(0);
    const [searchPlayer, setSearchPlayer] = useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [checkColumn, setCheckColumn] = React.useState([{ isChecked: false }]);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [selectedEmps, setSelectedEmps] = React.useState([]);
    const [selectedEmplyeIds, setSelectedEmplyeIds] = useState([]);

    useEffect(() => {
        if (tableData) {
            setSelectedEmps(tableData.filter(ele => ele.isChecked))
        }

    }, [tableData]);



    useEffect(() => {
        if (matchId) {
            if (actualsSelects[0].kpiName !== null || rankLimit.low !== '' || rankLimit.high !== '' || achivementsSelects[0].kpiName !== null || plannedAchivementsSelects[0].kpiName !== null) {
                let finalParams = {
                    based_on: basedOn == 0 ? 'RANK' : basedOn == 1 ? 'ACHIEVEMENT' : basedOn == 2 ? 'ACTUAL' : null,
                    performance: basedOn == 0 ? { min: rankLimit.low, max: rankLimit.high } :
                        basedOn == 1 ? achivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) :
                            basedOn == 2 ? actualsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.actualMin, max: d?.actualMax })) :
                                basedOn == 3 ? plannedAchivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) : null
                }
                setLoading(true);
                getPlayerBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, employee_id: playerId, role_id: statsPlayerFilter?.roles?.map(d => d.id) || roleId, team_id: statsPlayerFilter?.teams?.map(d => d.id) || teamId, filters: JSON.stringify(finalParams), limit: 5000, page: 1 } });
            }
            else {
                setLoading(true);
                getPlayerBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, employee_id: playerId, role_id: statsPlayerFilter?.roles?.map(d => d.id) || roleId, team_id: statsPlayerFilter?.teams?.map(d => d.id) || teamId, limit: 5000, page: 1 } });
            }
        }
    }, [matchId, playerId, teamId, roleId]);

    useEffect(() => {
        if (resData.response?.length > 0) {
            onSuccessCB(resData.response, resData.headers, true);
        }
    }, [decimalView]);

    const onSuccessCB = (response, resheaders, fromDecimalView) => {
        if (!fromDecimalView) {
            setResData({ response, headers: resheaders });
        }
        if (response) {
            let headers = [];
            // ---------get unique kpi ids from response --------
            const uniquIds = _.uniq(_.pluck(_.flatten(_.pluck(response, 'kpis')), 'kpi_id'));
            let kpisResultes = _.flatten(_.pluck(response, 'kpis'));
            let uniqKpis = [];
            uniquIds.forEach(ele => { uniqKpis.push(kpisResultes.find(ukpi => ukpi.kpi_id === ele)) });
            let uniqKpiHeaders = _.compact(uniqKpis);
            const kpis_attributes = seasonKpiPositionsSort(uniqKpis, seasonKpiPostions, 'kpi_id', 'detailed_hidden');
            // ---------get unique kpi ids from response --------
            const finalResult = response.reduce((result, item, index) => {
                let obj = { ...item, selected: item.employee_id === userDetails?.username, kpis: item.kpis.sort((a, b) => (a.kpi_id < b.kpi_id) ? 1 : -1) };
                let totalPoints = 0;
                if (index === 0) {
                    kpis_attributes.filter(el => uniquIds.includes(el.kpi_id)).forEach(headerEle => {
                        headers.push(headerEle);
                        const mapped_headers = headerEle.detailed_attributes.map(ele => { return ele.attribute });
                        headerEle['mapped_headers'] = mapped_headers;
                        obj.columns = [...(obj.columns || []),
                        ...[{
                            title: `${headerEle.kpi_name}`,//-target
                            field: `${headerEle.kpi_id}-target`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            // hidden: isRuleBasedOnActuals(headerEle),
                            hidden: !mapped_headers.includes('TARGET'),
                            attribute: headerEle.detailed_attributes.find(ele => ele?.attribute === 'TARGET') || { sequence_no: 10 },
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-target`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-target`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-target`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-unit`], rowData[`${headerEle.kpi_id}-target`])
                                            :
                                            [
                                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                                //     `No data available`
                                                //     : 
                                                `${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-target`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-target`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`
                                            ]
                                    : ' '
                            // getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-target`])
                        }, {
                            title: `${headerEle.kpi_name}`, //-Actual
                            field: `${headerEle.kpi_id}-actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACTUAL'),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-actual`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-actual`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-actual`]} />
                                            // getCurrencyFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-unit`], rowData[`${headerEle.kpi_id}-actual`])
                                            :
                                            [
                                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                                //     (isRuleBasedOnActuals(el) ? `No data available` : '')
                                                //     : 
                                                `${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-actual`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-actual`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`
                                            ]
                                    : ' '
                            // getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-actual`])
                        },
                        {
                            title: `${headerEle.kpi_name}`,
                            field: `${headerEle.kpi_id}-pending_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-pending_actual`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-pending_actual`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-pending_actual`]} />
                                            :
                                            [`${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-pending_actual`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-pending_actual`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`]
                                    : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`,//-Ach %
                            field: `${headerEle.kpi_id}-achieved`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('ACHIEVEMENT'),
                            render: (rowData) =>
                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                //     '' : 
                                rowData[`${headerEle.kpi_id}-achieved`] !== undefined ? getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-achieved`]) + ' %' : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`,
                            field: `${headerEle.kpi_id}-planned_target`,
                            editable: "never",
                            emptyValue: "--",
                            align: "center",
                            cellStyle: { whiteSpace: "nowrap" },
                            attribute: headerEle.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_TARGET') || { sequence_no: 10 },
                            hidden: (!headerEle.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_TARGET')),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-planned_target`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === "CURRENCY" ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-planned_target`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === "NUMBER" ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-planned_target`]} />
                                            // getCurrencyFormat(
                                            //   userDetails?.countryCode,
                                            //   rowData[`${headerEle.kpi_id}-unit`],
                                            //   rowData[`${headerEle.kpi_id}-planned_target`]
                                            // )
                                            : [
                                                // (roundId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                                //     `No data available`
                                                //     :
                                                `${getNumberFormat(
                                                    userDetails?.countryCode,
                                                    rowData[`${headerEle.kpi_id}-planned_target`]
                                                )} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] ===
                                                    "PERCENTAGE"
                                                    ? "%"
                                                    : rowData[`${headerEle.kpi_id}-unit_of_measurement`] ===
                                                        "DURATION"
                                                        ? getDurationWithUnit(
                                                            rowData[`${headerEle.kpi_id}-planned_target`],
                                                            rowData[`${headerEle.kpi_id}-unit`]
                                                        )
                                                        : ""
                                                }`,
                                            ]
                                    : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`, //-Actual
                            field: `${headerEle.kpi_id}-planned_actual`,
                            editable: "never",
                            emptyValue: "--",
                            align: "center",
                            cellStyle: { whiteSpace: "nowrap" },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACTUAL') || { sequence_no: 10 },
                            hidden: (!headerEle.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACTUAL')),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-planned_actual`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === "CURRENCY" ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-planned_actual`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === "NUMBER" ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-planned_actual`]} />
                                            // getCurrencyFormat(
                                            //   userDetails?.countryCode,
                                            //   rowData[`${headerEle.kpi_id}-unit`],
                                            //   rowData[`${headerEle.kpi_id}-planned_actual`]
                                            // )
                                            : [
                                                // (roundId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                                //     (isRuleBasedOnplanned_actuals(el) ? `No data available` : '')
                                                //     :
                                                `${getNumberFormat(
                                                    userDetails?.countryCode,
                                                    rowData[`${headerEle.kpi_id}-planned_actual`]
                                                )} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] ===
                                                    "PERCENTAGE"
                                                    ? "%"
                                                    : rowData[`${headerEle.kpi_id}-unit_of_measurement`] ===
                                                        "DURATION"
                                                        ? getDurationWithUnit(
                                                            rowData[`${headerEle.kpi_id}-planned_actual`],
                                                            rowData[`${headerEle.kpi_id}-unit`]
                                                        )
                                                        : ""
                                                }`,
                                            ]
                                    : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`,
                            field: `${headerEle.kpi_id}-planned_pending_actual`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_PENDING_ACTUAL') || { sequence_no: 10 },
                            hidden: !mapped_headers.includes('PLANNED_PENDING_ACTUAL'),
                            render: (rowData) =>
                                rowData[`${headerEle.kpi_id}-planned_pending_actual`] !== undefined ?
                                    rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'CURRENCY' ?
                                        <GetCountryCurrencyFormat type="currency" value={rowData[`${headerEle.kpi_id}-planned_pending_actual`]} />
                                        : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'NUMBER' ?
                                            <GetCountryCurrencyFormat type="number" value={rowData[`${headerEle.kpi_id}-planned_pending_actual`]} />
                                            :
                                            [`${getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-planned_pending_actual`])} ${rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE' ? '%' : rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION' ? getDurationWithUnit(rowData[`${headerEle.kpi_id}-planned_pending_actual`], rowData[`${headerEle.kpi_id}-unit`]) : ''}`]
                                    : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`, //-Ach %
                            field: `${headerEle.kpi_id}-planned_achievement`,
                            editable: "never",
                            emptyValue: "--",
                            align: "center",
                            cellStyle: { whiteSpace: "nowrap" },
                            attribute: headerEle?.detailed_attributes.find(ele => ele?.attribute === 'PLANNED_ACHIEVEMENT') || { sequence_no: 10 },
                            hidden: (!headerEle.achievement_plan_enabled ? true : !mapped_headers.includes('PLANNED_ACHIEVEMENT')),
                            render: (rowData) =>
                                // (roundId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                //     '' :
                                rowData[`${headerEle.kpi_id}-planned_achievement`] !== undefined ? getNumberFormat(
                                    userDetails?.countryCode,
                                    rowData[`${headerEle.kpi_id}-planned_achievement`]
                                ) + " %"
                                    : ' '
                        },
                        {
                            title: `${headerEle.kpi_name}`,//-Points
                            field: `${headerEle.kpi_id}-points`,
                            editable: 'never',
                            emptyValue: '--',
                            align: 'center',
                            cellStyle: { whiteSpace: 'nowrap' },
                            hidden: !mapped_headers.includes('POINTS'),
                            attribute: headerEle.detailed_attributes.find(ele => ele?.attribute === 'POINTS') || { sequence_no: 10 },
                            render: (rowData) =>
                                // (matchId === 'ALL' && ((rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'PERCENTAGE') || rowData[`${headerEle.kpi_id}-unit_of_measurement`] === 'DURATION')) ?
                                //     '' : 
                                rowData[`${headerEle.kpi_id}-points`] !== undefined ? getNumberFormat(userDetails?.countryCode, rowData[`${headerEle.kpi_id}-points`], decimalView) : ' '
                        }
                        ].sort((a, b) => a?.attribute?.sequence_no - b?.attribute?.sequence_no),
                        ]
                    })
                }
                obj.kpis.forEach((el, i) => {
                    obj[`${el.kpi_id}-target`] = el.target;
                    obj[`${el.kpi_id}-actual`] = el.actual;
                    obj[`${el.kpi_id}-pending_actual`] = el.pending_actual;
                    obj[`${el.kpi_id}-achieved`] = el.achieved;
                    obj[`${el.kpi_id}-planned_target`] = el.planned_target;
                    obj[`${el.kpi_id}-planned_actual`] = el.planned_actual;
                    obj[`${el.kpi_id}-planned_pending_actual`] = el.planned_pending_actual;
                    obj[`${el.kpi_id}-planned_achievement`] = el.planned_achievement;
                    obj[`${el.kpi_id}-unit_of_measurement`] = el.unit_of_measurement;
                    obj[`${el.kpi_id}-unit`] = el.unit;
                    obj[`${el.kpi_id}-points`] = el.points;
                    obj['totalPoints'] = totalPoints += el.points;
                });
                return [...result, { ...obj, team: { name: obj.team_name, logo_url: obj.team_logo_url, _id: obj.team_id }, player: { name: obj.player_name, logo_url: obj.player_photo_url, _id: obj.employee_id, role: obj.role }, isChecked: false }];
            }, []);
            if (finalResult.length > 0) {
                setData(finalResult);
                setTableData(finalResult);
                setSelectedEmplyeIds([]);
                setSearchPlayer(finalResult);
                setDynamicColumns(finalResult[0].columns || []);
                setDynamicHeaders([...headers])
                setDownloadPlayerBonusStatData(finalResult.map(obj => {
                    return getRowData(obj);
                }))
            }
            else {
                setData([]);
                setTableData([]);
                setSelectedEmplyeIds([]);
                setSearchPlayer([]);
                setDynamicColumns([]);
                setDynamicHeaders([]);
            }
        }
        setLoading(false);
    }

    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch summary', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const isRuleBasedOnActuals = (kpi) => {
        return ((kpi.scoring_rule === 'TBSR1' || kpi.scoring_rule === 'TBSR2' || kpi.scoring_rule === 'TBSR4') ? kpi.based_on === "ACTUALS" : false);
    }

    const getRowData = (empObject) => {
        if (seasonFormat === 'PLAYER_RACE') {
            let obj = { 'Rank': empObject.rank, 'Employee No': empObject.emp_no, 'Player': empObject.player_name, 'Role': empObject.role, 'Total Pts': getNumberFormat(userDetails?.countryCode, empObject.totalPoints, decimalView) };
            empObject.kpis.map((ele) => {
                if (isRuleBasedOnActuals(ele)) {
                    obj[`${ele.kpi_code}-actual`] = ele.actual;
                    obj[`${ele.kpi_code}-points`] = getNumberFormat(userDetails?.countryCode, ele.points, decimalView);
                }
                else {
                    if (ele.achievement_plan_enabled) {
                        obj[`${ele.kpi_code}-target`] = ele.target;
                        obj[`${ele.kpi_code}-actual`] = ele.actual;
                        obj[`${ele.kpi_code}-achieved`] = ele.achieved + " %";
                        obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-target`] = ele?.planned_target;
                        obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-actual`] = ele?.planned_actual;
                        obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-achieved`] = ele?.planned_achievement + " %";
                        obj[`${ele.kpi_code}-points`] = getNumberFormat(userDetails?.countryCode, ele.points, decimalView);
                    } else {
                        obj[`${ele.kpi_code}-target`] = ele.target;
                        obj[`${ele.kpi_code}-actual`] = ele.actual;
                        obj[`${ele.kpi_code}-achieved`] = ele.achieved + " %";
                        obj[`${ele.kpi_code}-points`] = getNumberFormat(userDetails?.countryCode, ele.points, decimalView);
                    }
                }
            });
            return obj;
        } else {
            let obj = { 'Rank': empObject.rank, 'Employee No': empObject.emp_no, 'Player': empObject.player_name, 'Role': empObject.role, 'Team Name': empObject.team_name, 'Total Pts': getNumberFormat(userDetails?.countryCode, empObject.totalPoints, decimalView) };
            empObject.kpis.map((ele) => {
                if (isRuleBasedOnActuals(ele)) {
                    obj[`${ele.kpi_code}-actual`] = ele.actual;
                    obj[`${ele.kpi_code}-points`] = getNumberFormat(userDetails?.countryCode, ele.points, decimalView);
                }
                else {
                    if (ele.achievement_plan_enabled) {
                        obj[`${ele.kpi_code}-target`] = ele.target;
                        obj[`${ele.kpi_code}-actual`] = ele.actual;
                        obj[`${ele.kpi_code}-achieved`] = ele.achieved + " %";
                        obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-target`] = ele?.planned_target;
                        obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-actual`] = ele?.planned_actual;
                        obj[`${ele.kpi_code}-${ele?.frequency === 'WEEKLY' ? 'WTD' : 'MTD'}-achieved`] = ele?.planned_achievement + " %";
                        obj[`${ele.kpi_code}-points`] = getNumberFormat(userDetails?.countryCode, ele.points, decimalView);
                    } else {
                        obj[`${ele.kpi_code}-target`] = ele.target;
                        obj[`${ele.kpi_code}-actual`] = ele.actual;
                        obj[`${ele.kpi_code}-achieved`] = ele.achieved + " %";
                        obj[`${ele.kpi_code}-points`] = getNumberFormat(userDetails?.countryCode, ele.points, decimalView);
                    }
                }
            });
            return obj;
        }
    }

    const filterOnCancel = e => {
        setOpenFilter(false);
    };
    const filterOnOk = (filterData) => {
        setClearFilterDisplay(true);
        let params = {
            team_id: filterData ? filterData.teams?.map(d => d?.id) : statsPlayerFilter.teams?.map(d => d?.id),
            role_id: filterData ? filterData.roles?.map(d => d?.id) : statsPlayerFilter.roles?.map(d => d?.id),
            // kpi: filterData ? filterData.kpi?.map(d => d?.id) : statsPlayerFilter.kpi?.map(d => d?.id),
            limit: 5000
        }
        let finalParams = {
            based_on: basedOn == 0 ? 'RANK' : basedOn == 1 ? 'ACHIEVEMENT' : basedOn == 2 ? 'ACTUAL' : basedOn == 3 ? 'PLANNED_ACHIEVEMENT' : null,
            performance: basedOn == 0 ? { min: rankLimit.low, max: rankLimit.high } :
                basedOn == 1 ? achivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) :
                    basedOn == 2 ? actualsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.actualMin, max: d?.actualMax })) :
                        basedOn == 3 ? plannedAchivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) : null
        }
        setLoading(true);
        if (actualsSelects[0].kpiName !== null || rankLimit.low !== '' || rankLimit.high !== '' || achivementsSelects[0].kpiName !== null || plannedAchivementsSelects[0].kpiName !== null) {
            getPlayerBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, employee_id: playerId, ...params, filters: JSON.stringify(finalParams), page: 1 } });
        }
        else {
            getPlayerBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, employee_id: playerId, ...params, page: 1 } });
        }
        setOpenFilter(false);
    };

    const filterOnChange = (event) => {
        let statsPlayerFilterDup = JSON.parse(JSON.stringify(statsPlayerFilter));
        statsPlayerFilterDup[event.target.name] = event.target.value;
        setStatsPlayerFilter(statsPlayerFilterDup);
    };

    const handleFilterChange = (obj) => {
        setStatsPlayerFilter(prev => { return { ...prev, ...obj } });
    }
    const chipEmptyTeam = (e, d) => {
        const newTm = statsPlayerFilter.teams.filter((team) => team.id !== d.id);
        setStatsPlayerFilter({ ...statsPlayerFilter, teams: newTm });
        filterOnOk({ ...statsPlayerFilter, teams: newTm });
    }
    const chipEmptyRole = (e, d) => {
        const newRl = statsPlayerFilter.roles.filter((role) => role.id !== d.id);
        setStatsPlayerFilter({ ...statsPlayerFilter, roles: newRl });
        filterOnOk({ ...statsPlayerFilter, roles: newRl });
    }
    const handleClear = e => {
        setClearFilterDisplay(false);
        setLoading(true);
        e.preventDefault();
        setStatsPlayerFilter({
            teams: [],
            roles: [],
            kpi: []
        });
        getPlayerBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, employee_id: playerId, role_id: roleId, team_id: teamId, limit: 5000, page: 1 } });
    };

    // -------filters apply ------
    //   -----rank filters----
    const handleRankChange = (e, field) => {
        setRankLimit({ ...rankLimit, [field]: e.target.value });
    };
    //   -----rank filters----
    const valuetext = (value) => { return (value) }
    //   -----achievments filters----
    const handleAchieveChange = (e, i, value, field) => {
        let newAchiveValues = [...achivementsSelects];
        let prevRangeValues = JSON.parse(JSON.stringify(newAchiveValues[i]));
        if (field == "percentageMin") {
            prevRangeValues.percentageRange[0] = e.target.value;
            newAchiveValues[i] = prevRangeValues;
            setAchivementsSelects(newAchiveValues);
        }
        else if (field == "percentageMax") {
            prevRangeValues.percentageRange[1] = e.target.value;
            newAchiveValues[i] = prevRangeValues;
            setAchivementsSelects(newAchiveValues);
        }
        else {
            newAchiveValues[i][field] = value;
            setAchivementsSelects(newAchiveValues);
        }
    };
    const addAchievmntFields = () => {
        setAchivementsSelects([...achivementsSelects, { kpiName: null, percentageRange: [0, 0], percentageHighest: false }])
    }
    const addAllAchievmntFields = () => {
        setAchivementsSelects(kpis.map((data) => ({ kpiName: { id: data.kpi_id, label: data.kpi_name }, percentageRange: [0, 0], actualMax: 0, percentageHighest: false })))
    }
    const removeAchievmntFields = (i) => {
        let newAchiveRemoveValues = [...achivementsSelects];
        newAchiveRemoveValues.splice(i, 1);
        setAchivementsSelects(newAchiveRemoveValues);
    }
    //   -----achievments filters----
    //   -----planned achievments filters----
    const handlePlannedAchieveChange = (e, i, value, field) => {
        let newAchiveValues = [...plannedAchivementsSelects];
        let prevRangeValues = JSON.parse(JSON.stringify(newAchiveValues[i]));
        if (field == "percentageMin") {
            prevRangeValues.percentageRange[0] = e.target.value;
            newAchiveValues[i] = prevRangeValues;
            setPlannedAchivementsSelects(newAchiveValues);
        }
        else if (field == "percentageMax") {
            prevRangeValues.percentageRange[1] = e.target.value;
            newAchiveValues[i] = prevRangeValues;
            setPlannedAchivementsSelects(newAchiveValues);
        }
        else {
            newAchiveValues[i][field] = value;
            setPlannedAchivementsSelects(newAchiveValues);
        }
    };
    const addPlannedAchievmntFields = () => {
        setPlannedAchivementsSelects([...plannedAchivementsSelects, { kpiName: null, percentageRange: [0, 0], percentageHighest: false }])
    }
    const addAllPlannedAchievmntFields = () => {
        setPlannedAchivementsSelects(kpis.map((data) => ({ kpiName: { id: data.kpi_id, label: data.kpi_name }, percentageRange: [0, 0], actualMax: 0, percentageHighest: false })))
    }
    const removePlannedAchievmntFields = (i) => {
        let newAchiveRemoveValues = [...plannedAchivementsSelects];
        newAchiveRemoveValues.splice(i, 1);
        setPlannedAchivementsSelects(newAchiveRemoveValues);
    }
    //   -----planned achievments filters----

    //   -----actuals filters----
    const handleActualChange = (e, i, value, field) => {
        let newActualValues = [...actualsSelects];
        if (field === "kpiName") {
            newActualValues[i][field] = value;
            setActualsSelects(newActualValues);
        }
        else {
            newActualValues[i][field] = e.target.value;
            setActualsSelects(newActualValues);
        }
    };
    const addActualFields = () => {
        setActualsSelects([...actualsSelects, { kpiName: null, actualMin: 0, actualMax: 0, actualMeasurement: null }])
    }
    const addAllActualFields = () => {
        setActualsSelects(kpis.map((data) => ({ kpiName: { id: data.kpi_id, label: data.kpi_name }, actualMin: 0, actualMax: 0, actualMeasurement: data.unit_of_measurement })))
    }
    const removeActualFields = (i) => {
        let newActualRemoveValues = [...actualsSelects];
        newActualRemoveValues.splice(i, 1);
        setActualsSelects(newActualRemoveValues);
    }
    //   -----actuals filters----
    const handleClearBasedon = () => {
        setRankLimit({ low: "", high: "" });
        setAchivementsSelects([{ kpiName: null, percentageRange: [0, 0], percentageHighest: false }]);
        setPlannedAchivementsSelects([{ kpiName: null, percentageRange: [0, 0], percentageHighest: false }]);
        setActualsSelects([{ kpiName: null, actualMin: 0, actualMax: 0, actualMeasurement: null }]);
        setBasedOn('');
        if (matchId) {
            setLoading(true);
            getPlayerBonusKpisStats({ seasonId, onSuccessCB, onErrorCB, params: { match_id: matchId === 'ALL' ? '' : matchId, employee_id: playerId, role_id: statsPlayerFilter?.roles?.map(d => d.id) || roleId, team_id: statsPlayerFilter?.teams?.map(d => d.id) || teamId, limit: 5000, page: 1 } });
        }
    }

    const handleKeySearch = (searchedVal) => {
        const filteredRows = searchPlayer.filter((row) => {
            return row.player_name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
        });
        if (searchedVal.length === 0) {
            setData(searchPlayer);
            setTableData(searchPlayer);
            setDownloadPlayerBonusStatData(searchPlayer.map(obj => {
                return getRowData(obj);
            }))
        }
        else {
            setData(filteredRows);
            setTableData(filteredRows);
            setDownloadPlayerBonusStatData(filteredRows.map(obj => {
                return getRowData(obj);
            }))
        }
    };
    const rolesList = roles.map((data) => ({ id: data.role_id, label: data.name }));
    const teamList = teams.map((data) => ({ id: data.team_id, label: data.name }));
    const kpiList = kpis.map((data) => ({ id: data._id, label: data.name }));

    const handleChange = (data, field, filterData) => {
        handleChangeCheckbox('', '', 'close');
        if (field !== "basedon" && field !== "kpi") {
            const uniqueStats = [...new Map(data.map((item) => [item["id"], item])).values()];
            handleFilterChange({ [field]: uniqueStats });
            setStatsPlayerFilter({ ...statsPlayerFilter, [field]: uniqueStats });
            filterOnOk({ ...statsPlayerFilter, [field]: uniqueStats });
        } else {
            setOpenFilter(true);
        }
    }

    const handleChangeCheckbox = (row, rowIndex, type) => {
        if (type === 'close') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[0].isChecked = false
            tableDataDup.forEach(player => player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            // setTableData([...tableDataDup])
            setSelectedEmplyeIds([]);
        }
        else if (type === 'all') {
            let checkColumnDup = JSON.parse(JSON.stringify(checkColumn));
            let tableDataDup = JSON.parse(JSON.stringify(tableData));
            checkColumnDup[rowIndex].isChecked = !checkColumnDup[rowIndex].isChecked
            tableDataDup.forEach(player => checkColumnDup[rowIndex].isChecked === true ? player.isChecked = true : player.isChecked = false);
            setCheckColumn([...checkColumnDup])
            // setTableData([...tableDataDup])
            setSelectedEmplyeIds(checkColumnDup[0]?.isChecked ? _.pluck(tableData, 'employee_id') : [])
        }
        else {
            // let tableDataDup = JSON.parse(JSON.stringify(tableData));
            // tableDataDup[rowIndex].isChecked = !tableDataDup[rowIndex].isChecked
            // setTableData([...tableDataDup])
            let dubselectedEmps = selectedEmplyeIds;
            if (dubselectedEmps.includes(row.employee_id)) dubselectedEmps = dubselectedEmps.filter((emp) => emp !== row.employee_id)
            else dubselectedEmps.push(row.employee_id)
            setSelectedEmplyeIds([...dubselectedEmps])
        }

    };

    const handleCloseNotificationDialog = () => {
        setOpenConfirmDialog(false);
        handleChangeCheckbox('', '', 'close');
        setSelectedEmps([])
    }

    const onClickBuzz = () => {
        if (selectedEmplyeIds.length === 0) { //selectedEmps
            enqueueSnackbar(` Make sure at least one user is selected`, {
                variant: "error",
                preventDuplicate: true
            });
        }
        else {
            setOpenConfirmDialog(true);
        }


    }

    // ---------season players download------
    const downloadStatsData = () => {
        let type = 'PLAYER_BONUS'
        if (actualsSelects[0].kpiName !== null || rankLimit.low !== '' || rankLimit.high !== '' || achivementsSelects[0].kpiName !== null || plannedAchivementsSelects[0].kpiName !== null) {
            let finalParams = {
                based_on: basedOn == 0 ? 'RANK' : basedOn == 1 ? 'ACHIEVEMENT' : basedOn == 2 ? 'ACTUAL' : basedOn == 3 ? 'PLANNED_ACHIEVEMENT' : null,
                performance: basedOn == 0 ? { min: rankLimit.low, max: rankLimit.high } :
                    basedOn == 1 ? achivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) :
                        basedOn == 2 ? actualsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.actualMin, max: d?.actualMax })) :
                            basedOn == 3 ? plannedAchivementsSelects?.map((d) => ({ kpi_id: d?.kpiName?.id, min: d?.percentageHighest ? '100' : d?.percentageRange[0], max: d?.percentageHighest ? null : d?.percentageRange[1] })) : null
            }
            setLoading(true);
            getExportPlayerKpisStats({
                seasonId, type, params: {
                    match_id: matchId === "ALL" ? "" : matchId,
                    employee_id: playerId,
                    role_id: statsPlayerFilter?.roles?.map((d) => d.id) || roleId,
                    team_id: statsPlayerFilter?.teams?.map((d) => d.id) || teamId,
                    filters: JSON.stringify(finalParams)
                },
                onSuccessCB: (result) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `playerBonusKpiStats.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setLoading(false);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    if (error.response)
                        enqueueSnackbar((error.response.data && error.response.data.message) ? error.response.data.message : "Failed to export, Please try again", {
                            variant: "error",
                            preventDuplicate: true
                        });
                },
            });
        } else {
            setLoading(true);
            getExportPlayerKpisStats({
                seasonId, type, params: {
                    match_id: matchId === "ALL" ? "" : matchId,
                    employee_id: playerId,
                    role_id: statsPlayerFilter?.roles?.map((d) => d.id) || roleId,
                    team_id: statsPlayerFilter?.teams?.map((d) => d.id) || teamId,
                }, onSuccessCB: (result) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `playerBonusKpiStats.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setLoading(false);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    if (error.response)
                        enqueueSnackbar((error.response.data && error.response.data.message) ? error.response.data.message : "Failed to export, Please try again", {
                            variant: "error",
                            preventDuplicate: true
                        });
                },
            });
        }
    }

    const columns = [
        {
            title: "Rank",
            field: "rank",
            emptyValue: '--',
            editable: "onAdd",
            cellStyle: {
                minWidth: 60, position: "sticky",
                left: isAdmin ? '60px' : '0px',
                background: "white"
            },
        },
        {
            title: "Employee No",
            field: "emp_no",
            cellStyle: {
                minWidth: 80, position: "sticky",
                left: isAdmin ? '140px' : '81px',
                background: "white"
            },
            width: 80,
            editable: 'never',
            emptyValue: '--',
        },
        {
            title: "Player",
            field: "player",
            cellStyle: {
                minWidth: 150, position: "sticky",
                left: isAdmin ? '240px' : '181px',
                background: "white"
            },
            editable: "never",
            emptyValue: '--',
            width: 150,
            render: (rowData) => rowData.player ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent data={rowData} from='PLAYER' type="PLAYER" name={rowData.player.name} username={rowData.player._id} url={rowData.player.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} filtersData={filtersData} />
                    {rowData.player && rowData.player.name}
                </div>
            ) : '--'
        },
        {
            title: "Role",
            field: "role",
            cellStyle: {
                minWidth: 100, position: "sticky",
                left: isAdmin ? '410px' : '352px',
                background: "white"
            },
            editable: "onAdd",
            emptyValue: '--',
            width: 100
        },
        {
            title: "Team",
            field: "team",
            cellStyle: {
                minWidth: 150, position: "sticky",
                left: isAdmin ? '530px' : '472px',
                background: "white"
            },
            editable: 'never',
            width: 150,
            emptyValue: '--',
            hidden: seasonFormat === 'PLAYER_RACE',
            render: (rowData) => rowData.team ? (
                <div className={classes.nameRoot}>
                    <AvatarComponent type='TEAM' name={rowData.team.name} username={rowData.team?._id} url={rowData.team?.logo_url} className={classes.avatarStyles} onClick={() => { }} style={{ marginRight: 14 }} filtersData={filtersData} />
                    {rowData.team && rowData.team.name}
                </div>
            ) : '--'
        },
        {
            title: "Total Pts",
            field: "totalPoints",
            cellStyle: seasonFormat === 'PLAYER_RACE' ? {
                minWidth: 80, position: "sticky",
                left: isAdmin ? '530px' : '472px',
                background: "white"
            } : {
                minWidth: 80, position: "sticky",
                left: isAdmin ? '700px' : '642px',
                background: "white"
            },
            editable: 'never',
            width: 80,
            render: (rowData) => getNumberFormat(userDetails?.countryCode, rowData.totalPoints, decimalView)
        }
    ]

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ margin: '8px 0px', display: 'flex' }}>
                    < FormControl fullWidth className={classes.formRoot}>
                        <Autocomplete
                            id="teams"
                            multiple
                            options={teamList}
                            size="small"
                            disableListWrap
                            disableCloseOnSelect
                            getOptionLabel={option => (option.label || '')}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            value={statsPlayerFilter?.teams}
                            limitTags={1}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        className={classes.checkClass}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color="primary"
                                    />
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="Teams"
                                    size="small"
                                />
                            }
                            onChange={(...args) => handleChange(args[1], 'teams')}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formRoot}>
                        <Autocomplete
                            id="roles"
                            multiple
                            size="small"
                            options={rolesList}
                            limitTags={1}
                            disableCloseOnSelect
                            getOptionLabel={option => (option.label || '')}
                            getOptionSelected={(option, value) => option.id === value.id}
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            value={statsPlayerFilter?.roles}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        className={classes.checkClass}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color="primary"
                                    />
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="Roles"
                                    size="small"
                                />
                            }
                            onChange={(...args) => handleChange(args[1], 'roles')}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formRoot1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Filter by"
                            size="small"
                            InputProps={{
                                readOnly: true,
                                classes: { root: classes.baseWidth }, endAdornment: (
                                    <InputAdornment position="end">
                                        <ArrowRight />
                                    </InputAdornment>
                                )
                            }}
                            value={basedOn == 0 ? 'Rank' : basedOn == 1 ? 'Achievement' : basedOn == 2 ? 'Actuals' : basedOn == 3 ? 'Mtd/Wtd Achievement' : ''}
                            onClick={(e) => handleChange({}, "basedon")}
                        />
                        {actualsSelects[0].kpiName !== null || rankLimit.low !== '' || rankLimit.high !== '' || achivementsSelects[0].kpiName !== null || plannedAchivementsSelects[0].kpiName !== null ? <p onClick={() => handleClearBasedon()} className='close_icn'><CloseIcon style={{ fontSize: '18px' }} /></p> : ''}
                    </FormControl>
                </div>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    {isAdmin &&
                        <div>
                            <Button variant="contained" className={classes.notification} color="primary" onClick={() => onClickBuzz()}>
                                Buzz
                            </Button>
                        </div>}
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search Player.."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => { handleKeySearch(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className={classes.exportButtn}>
                        {isAdmin &&
                            <Button startIcon={<ExportIcon />}
                                onClick={e => downloadStatsData(e)}
                                variant="outlined" color='primary' style={{ fontSize: '15px', margin: '0px 8px', padding: '0px 12px', height: 40 }}>
                                Export
                            </Button>
                        }
                    </div>
                </div>
            </div>
            {
                (data.length === 0) ? < IllustrationView loading={loading} style={{ marginTop: '50px' }} /> :
                    <div className={classes.root}>
                        <div className="material-table">
                            {/* <p className="table-title decimal-switch-root">
                            <p style={{ fontSize: 11, color: '#58595B', margin: '5px 0 10px', textAlign: 'end' }}>*Note: Points will appear only after match is closed.</p>
                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                        </p> */}
                            <MaterialUITable
                                title={'Match Summary'}
                                columns={[...columns, ...dynamicColumns]}
                                data={tableData}
                                checkColumn={checkColumn}
                                selectedEmplyeIds={selectedEmplyeIds}
                                handleChange={handleChangeCheckbox}
                                isAdmin={isAdmin}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                    toolbar: false,
                                    pageSizeOptions: [10, 25, 50, 100],
                                    pageSize: 25,
                                    paging: data.length > 5 ? true : false,
                                    // fixedColumns: {
                                    //     left: 4,
                                    // }
                                }}
                                components={{
                                    Header: props => (
                                        <TableHead className="custom-headers">
                                            <TableRow>
                                                {isAdmin &&
                                                    <TableCell style={{ position: "sticky", left: 0, background: "white" }} rowSpan={2}><Checkbox
                                                        checked={checkColumn[0].isChecked}
                                                        onChange={() => handleChangeCheckbox('', 0, 'all')}
                                                        color="primary"
                                                        size="small"

                                                    /></TableCell>}
                                                <TableCell style={{ position: "sticky", left: isAdmin ? '60px' : '0px', background: "white" }} rowSpan={2}>{props.columns[0]?.title}</TableCell>
                                                <TableCell style={{ position: "sticky", left: isAdmin ? '140px' : '81px', background: "white" }} rowSpan={2}>{props.columns[1]?.title}</TableCell>
                                                <TableCell style={{ position: "sticky", left: isAdmin ? '240px' : '181px', background: "white" }} rowSpan={2}>{props.columns[2]?.title}</TableCell>
                                                <TableCell style={{ position: "sticky", left: isAdmin ? '410px' : '352px', background: "white" }} rowSpan={2}>{props.columns[3]?.title}</TableCell>
                                                {
                                                    (seasonFormat !== 'PLAYER_RACE') &&
                                                    <TableCell style={{ position: "sticky", left: isAdmin ? '530px' : '472px', background: "white" }} rowSpan={2}>{props.columns[4]?.title}</TableCell>
                                                }
                                                {
                                                    seasonFormat !== 'PLAYER_RACE' ?
                                                        <TableCell style={{ position: "sticky", left: isAdmin ? '700px' : '642px', background: "white" }} rowSpan={2}>{props.columns[5]?.title}</TableCell> :
                                                        <TableCell style={{ position: "sticky", left: isAdmin ? '530px' : '472px', background: "white" }} rowSpan={2}>{props.columns[5]?.title}</TableCell>

                                                }
                                                {
                                                    dynamicHeaders.map((obj, index) => (
                                                        <TableCell key={index} colSpan={obj?.mapped_headers.length} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>
                                                            {/* <Tooltip title={obj.kpi_name}><span>{obj.kpi_code || obj.kpi_name}</span></Tooltip> */}
                                                            {obj.kpi_name}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow >
                                            <TableRow>
                                                {
                                                    dynamicHeaders.map((obj) => (
                                                        <>
                                                            {
                                                                obj?.detailed_attributes.length ?
                                                                    <>
                                                                        {
                                                                            obj?.detailed_attributes.map(ele => (
                                                                                ele?.view && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>{ele.display_name}</TableCell>
                                                                            ))

                                                                        }
                                                                        {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {obj?.mapped_headers.includes('TARGET') && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACTUAL') && <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell>}
                                                                        {obj?.mapped_headers.includes('ACHIEVEMENT') && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>}
                                                                    </>
                                                            }
                                                            {/* {!isRuleBasedOnActuals(obj) && <TableCell key={0} align="center" style={{ borderLeft: '2px solid #f0f2f5' }}>Target</TableCell>} */}
                                                            {/* <TableCell key={1} align="center" style={{ borderLeft: isRuleBasedOnActuals(obj) && '2px solid #f0f2f5' }}>Actual</TableCell> */}
                                                            {/* {!isRuleBasedOnActuals(obj) && <TableCell key={2} align="center" style={{ whiteSpace: 'nowrap' }}>Ach %</TableCell>} */}
                                                            {/* <TableCell key={3} align="center">Points</TableCell> */}
                                                        </>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableHead >
                                    )
                                }}
                            />
                        </div >
                    </div >
            }
            {openFilter && (
                <StatsPlayerFilterModal
                    open={openFilter}
                    onChange={filterOnChange}
                    onClickCancel={filterOnCancel}
                    onClickOk={filterOnOk}
                    statsPlayerFilter={statsPlayerFilter}
                    handleFilterChange={handleFilterChange}
                    roles={roles}
                    teams={teams}
                    kpis={kpis}
                    title={filtersData?.season?.name}
                    dynamicHeaders={dynamicHeaders}
                    basedOn={basedOn}
                    setBasedOn={setBasedOn}                           //based on active tab
                    handleChange={handleChange}
                    handleRankChange={handleRankChange}               //rank handlechanges
                    rankLimit={rankLimit}                             //rank selected fields
                    handleAchieveChange={handleAchieveChange}         //achievement fields onchange 
                    achivementsSelects={achivementsSelects}           //achievement selkected fields
                    valuetext={valuetext}
                    actualsSelects={actualsSelects}                   //actuals selkected fields 
                    handleActualChange={handleActualChange}           //actuals fields onchange 
                    addAchievmntFields={addAchievmntFields}           //achievement add fields
                    addAllAchievmntFields={addAllAchievmntFields}     //all add achvmnts fields
                    removeAchievmntFields={removeAchievmntFields}     //achievement remove fields
                    addActualFields={addActualFields}                 //actual add fields
                    addAllActualFields={addAllActualFields}           //all actual add fields
                    removeActualFields={removeActualFields}           //actual remove fields
                    handlePlannedAchieveChange={handlePlannedAchieveChange} //planned achievement onchange
                    addPlannedAchievmntFields={addPlannedAchievmntFields}  //planned achievement fields onchange
                    addAllPlannedAchievmntFields={addAllPlannedAchievmntFields} //add all planned achievement fields
                    removePlannedAchievmntFields={removePlannedAchievmntFields}  //remove planned ach fields
                    plannedAchivementsSelects={plannedAchivementsSelects}  //planned achievement selects
                />
            )}
            {
                openConfirmDialog && <NotificationDialog
                    open={openConfirmDialog}
                    handleClose={handleCloseNotificationDialog}
                    selectedEmps={tableData.filter(emp => selectedEmplyeIds.map(el => el).includes(emp?.employee_id))}  //{selectedEmps}
                    setSelectedEmps={setSelectedEmps}
                    handleUncheck={handleChangeCheckbox}
                    from="stats"

                />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonKpiPostions: state.game.seasonKpiPostions,
    seasonDetails: state.game.seasonDetails

});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayerBonusKpisStats: options => dispatch(getPlayerBonusKpisStats(options)),
        getExportPlayerKpisStats: (options) => dispatch(getExportPlayerKpisStats(options)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerBonusKpisStats));