import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  mainRoot: {
    minHeight: "calc(100vh - 60.5px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  root: {
    width: "432px",
    height: "287px",
    background: "#E9E8E8",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    alignItems: "center",
  },
  textField: {
    width: "331px",
    height: "55px",
    borderRadius: "4px",
    background: "#FFFFFF",
  },
  button: {
    width: "153px",
    height: "47px",
    borderRadius: "6px",
  },
  heading: {
    color: "#000000",
    fontSize: "22px",
    lineHeight: "22px",
    fontWeight: "bold",
  },
  dialogPaper: {
    height: 173,
    borderRadius: "6px",
    width: 330,
  },
  error: {
    color: "#B53438",
    fontSize: "28px",
    lineHeight: "15px",
    fontWeight:"normal",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
  },
  errorMessage:{
    fontSize: "14px",
    lineHeight: "15px",
    color:"#535353",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    margin:"1.5rem 0 1rem "
  },
  buttonDialog:{
    color:"#2771D2",
    border:"none",
    outline:"none",
     marginLeft:"13rem"
  }
}));

const code = "1234";

export const DialogErrorCode = ({ open ,setOpen}) => {
  const classes=useStyles();
  const handleClose=()=>setOpen(false)
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        <h1 className={classes.error}>Oops!</h1>
        <div className={classes.errorMessage}><span>Meeting code is incorrect</span>
         <span>please try gain </span></div>
         <Button className={classes.buttonDialog} variant="outlined" onClick={handleClose}>Dismiss</Button>
      </DialogContent>
    </Dialog>
  );
};
const JoinToCodeScreen = () => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const handleChange = (e) => setValue(e.target.value);
  const handleSubmit = () => {
    if (value !== code) {
      setOpen(true);
      setValue("")
    }
  };

  return (
    <div className={classes.mainRoot}>
      <div className={classes.root}>
        <p className={classes.heading}>Join with a code</p>
        <TextField
          variant="outlined"
          placeholder="Enter meeting coding here"
          InputProps={{ className: classes.textField }}
          onChange={handleChange}
          value={value}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            disabled={value.length === 0}
            onClick={handleSubmit}
          >
            Join Now
          </Button>
        </div>
      </div>
      <DialogErrorCode open={open}   setOpen={setOpen}/>
    </div>
  );
};

export default JoinToCodeScreen;
